import { getPlanCableColor, getPlanClosureStyle } from '../../Autosplicing/helpers';

const getCableLength = (cables: any, edge_id: string) => {
  const targetedCable: any = cables.find((cable: any) => cable.id === edge_id);

  if (targetedCable) {
    return String(Number(targetedCable.length).toFixed(0)) + 'm';
  } else {
    return 'Length unknown';
  }
};

const renderLoopData = (loopNumber: string | undefined) => {
  if (loopNumber && loopNumber !== '0') {
    return `LOOP ${loopNumber} - `;
  } else {
    return '';
  }
};

export function filterCableDiagramData(cableDiagramData: any, uniqueLoopIds: any[]) {
  let graphEdges = [];
  let graphNodes = [];

  // Get an array of unique edges, helps the G6 graph rendering
  let uniqueEdges = cableDiagramData.edges;
  uniqueEdges = cableDiagramData.edges.filter(
    (v: any, i: any, a: any) => a.findIndex((v2: any) => v2.id === v.id) === i,
  );

  for (const edge of uniqueEdges) {
    let loopNumber: string | undefined = undefined;

    if (uniqueLoopIds.includes(edge.loopChainId)) {
      loopNumber = String(uniqueLoopIds.indexOf(edge.loopChainId) + 1);
    }

    const cableLength = getCableLength(cableDiagramData.cables, edge.id);

    graphEdges.push({
      source: String(edge.source),
      target: String(edge.target),
      label: `${edge.targetOnHold ? 'On Hold' : String(edge.id)} - ${edge.type} - ${renderLoopData(
        loopNumber,
      )} ${cableLength || ''}`,
      size: 4,
      labelCfg: {
        autoRotate: true,
        refY: 18,
        refX: -25,
      },
      style: {
        lineWidth: loopNumber ? 8 : 4,
        stroke: edge.targetOnHold ? '#A7A7A7' : getPlanCableColor(edge.type),
        opacity: 1,
        fontSize: 7,
        lineDash: edge.targetOnHold ? [25] : undefined,
      },
    });
  }

  for (const node of cableDiagramData.nodes) {
    graphNodes.push({
      id: String(node.id),
      label: `${node.type}\n${node.isOnHold ? 'On Hold' : node.id}`,
      type: node.isOnHold ? 'image' : node.type,
      img: '/onhold.png',
      shape: 'image',
      size: 140,
      clipCfg: {
        show: true,
        type: 'circle',
        r: 110,
      },

      style: node.isOnHold ? {} : getPlanClosureStyle(node.type),
      labelCfg: {
        position: 'center',
        style: {
          fill: node.isOnHold ? '#000' : getPlanClosureStyle(node.type).color,
        },
      },
    });
  }

  return {
    nodes: graphNodes,
    edges: graphEdges,
  };
}
