import { Col, ListProps, Row } from 'antd';
import { VariableSizeList as List, VariableSizeList, VariableSizeListProps } from 'react-window';
import { FC, ReactChild, ReactChildren, ReactNode, forwardRef } from 'react';
import './index.scss';

interface Props extends VariableSizeListProps<any> {
  // agents: any[];
  // renderItem: (item: any) => ReactChild;
  // ref: any
}

const AgentList = forwardRef<VariableSizeList<any>, Props>(
  (props: Props, ref) => {
    return (
      <List ref={ref} {...props}>
        {props.children}
      </List>
    );
  },
);

export default AgentList;
