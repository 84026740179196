import React, { FC, useContext } from 'react';
import { Col, Row } from 'antd';
import { DetailViewContext } from '../../../../components/DetailViewContextProvider';
import HeaderDetailView from '../../../../components/HeaderDetailView';
import RecordStageSequentialPipeline_V2 from '../../../../components/RecordStageSequentialPipeline_V2';

const GanttProgramDetailView: FC = () => {
  const { record, schema, pipeline } = useContext(DetailViewContext);

  return (
    <Row style={{ marginTop: 1 }}>
      <Col span={24}>
        <HeaderDetailView record={record!} />

        {record?.stage && pipeline?.isSequential && (
          <RecordStageSequentialPipeline_V2
            className="record-pipeline"
            record={record}
            pipeline={pipeline}
          />
        )}
      </Col>
    </Row>
  );
};

export default GanttProgramDetailView;
