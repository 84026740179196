import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';

import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

import { useDispatch, useSelector } from 'react-redux';
import { updateWorkItems } from '@netomnia/modules/ProjectModule/Map/store/actions';
import RecordQuickViewDrawer from '@legacy/core/records/components/RecordQuickViewDrawer';
import WorkItemQuickView from '../Map/MapSideBar/MapSidebarWorkItems/FeatureQuickView_V2';

export const VendorDashboardQuickView = () => {
  const version = 'v1';

  const dispatch = useDispatch();
  const workItems = useSelector((state: any) => state.mapReducer.workItems);

  const closeDrawer = () => dispatch(updateWorkItems({
    quickViewRecord: undefined,
    quickViewVisible: false,
  }));

  if (version === 'v1')
    return (
      <RecordQuickViewDrawer
        recordId={workItems.quickViewRecord?.id}
        moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
        entityName={SchemaModuleEntityTypeEnums.FEATURE}
        visible={workItems.quickViewVisible}
        onClose={closeDrawer}
      />
    );

  return <WorkItemQuickView />;
};
