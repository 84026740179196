import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import JobsListView from '@legacy/core/jobs/components/ListView';
import RecordMainContentWide from '@legacy/core/records/components/DetailViewWide/RecordMainContentWide';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getAllSchemaAssociationSchemas, getRecordFromShortListById } from '../../../../core/helpers/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../../../core/helpers/schemaHelpers';

const { confirm } = Modal;

const { NOTE } = SchemaModuleEntityTypeEnums;

type PathParams = {
  url: string;
  recordId: string;
};

type State = {
  confirmValue: string;
  detailsColumnExpanded: boolean;
  spliceConfirmModalVisible: boolean;
};

type PropsType = RouteComponentProps<PathParams> & {
  match?: any;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  disableClone?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  excludeRelations?: string[];
  defaultTabKey?: string;
  alertMessage: any;
};

class ProjectModuleCableConnectionDetailView extends React.Component<PropsType, State> {
  render() {
    const {
      schemaReducer,
      recordReducer,
      match,
      excludeRelations,
      disableClone,
      disableEdit,
      disableDelete,
      defaultTabKey,
    } = this.props;
    const record = getRecordFromShortListById(recordReducer.shortList, match?.params?.recordId);
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    const relatedSchemas = getAllSchemaAssociationSchemas(
      schema?.associations,
      excludeRelations ? [NOTE, ...excludeRelations] : [NOTE],
    );

    return (
      <>
        <RecordMainContentWide
          disableClone={disableClone}
          disableEdit={disableEdit}
          disableDelete={disableDelete}
          defaultTabKey={defaultTabKey}
          record={record}
          schema={schema}
          relatedSchemas={relatedSchemas}
          customTabs={[
            {
              tab: 'Odin Processes',
              key: 'Jobs',
            },
          ]}
          customContent={{
            Jobs: (
              <JobsListView
                summaryView
                filters={{
                  metadataOr: [{ connectionId: `${record?.id}` }],
                }}
              />
            ),
          }}
        />
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default withRouter(connect(mapState, mapDispatch)(ProjectModuleCableConnectionDetailView));
