import { FC, useState } from 'react';
import data from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { Icon, Popover, Tag } from '@blueprintjs/core';
import './styles.scss';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { connect } from 'react-redux';
import { Col } from 'antd';

interface Props {
  noteRecord: DbRecordEntityTransform;
  disabled?: boolean;
  userReducer: any;
  onReactionAdded?: (emojiId: string) => void;
}

const NoteReactionsPicker: FC<Props> = (props: Props) => {
  const { disabled, onReactionAdded } = props;
  const [isPickerVisible, setIsPickerVisible] = useState<boolean>(false);

  const handleEmojiClick = (emoji: any) => {
    // Add reaction to note
    if (onReactionAdded) {
      onReactionAdded(emoji.id);
    }
    setIsPickerVisible(false);
  };

  return (
    <Col style={{ marginLeft: 5 }}>
      <Popover
        className="noteEmojiPickerPopover"
        usePortal={true}
        disabled={disabled}
        position="bottom"
        isOpen={isPickerVisible}
        onClose={() => setIsPickerVisible(false)}
        hasBackdrop
        canEscapeKeyClose
        captureDismiss
        content={
          <Picker
            onEmojiSelect={handleEmojiClick}
            previewPosition="none"
            emojiButtonSize={24}
            disabled={disabled}
            emojiSize={20}
            theme="light"
            data={data}
          />
        }
      >
        <Tag
          round
          intent="primary"
          minimal={!isPickerVisible}
          style={{
            cursor: disabled ? 'not-allowed' : 'pointer',
            padding: '2px 7px',
            marginBottom: 2,
            backgroundColor: !isPickerVisible ? '#679de73b' : '#2D72D2',
          }}
          icon={<Icon icon="emoji" size={10} />}
          onClick={() => setIsPickerVisible(!isPickerVisible)}
        >
          <span>+</span>
        </Tag>
      </Popover>
    </Col>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(NoteReactionsPicker);
