import React from 'react';
import { connect } from 'react-redux';
import { Alert, Button, NonIdealState, NonIdealStateIconSize, Tag } from '@blueprintjs/core';
import { Col, Row, Table } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';

import {
  DeletePipelineStage,
  deletePipelineStageRequest,
} from '@legacy/core/pipelines/store/actions';
import FormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { httpDelete, httpPut } from '@core/http/requests';

import { renderBooleanValue } from '@core/helpers/UIHelpers';

import { pipelineStageFormFields } from '../FormFields';

interface Props {
  schema: SchemaEntity | undefined;
  pipeline: PipelineEntity;
  formReducer: SharedFormReducer;
  initializeForm: any;
  deleteStage: (params: any) => void;
  onUpdate?: () => void;
}

interface State {
  deleteStageAlertIsVisible: boolean;
  stageIdToDelete: any;
  stageToUpdate: PipelineStageEntity | undefined;
  uuid: any;
}

class PipelineStagesList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      deleteStageAlertIsVisible: false,
      stageIdToDelete: null,
      stageToUpdate: undefined,
      uuid: uuidv4(),
    };
  }

  showStageUpdateForm = (stage: PipelineStageEntity) => {
    this.setState({ stageToUpdate: { ...stage, id: stage.key } });
    const { initializeForm, schema, pipeline } = this.props;

    console.log('debug: updating stage', stage);

    initializeForm({
      schema: schema,
      isUpdateReq: true,
      showModal: true,
      selected: pipeline,
      formUUID: this.state.uuid,
      title: 'Edit Stage',
      formFields: pipelineStageFormFields(stage),
      entityName: 'Stage',
      pipelineId: pipeline?.id,
    });
  };

  // Update Stage
  handleFormSubmit(params: FormReducerSubmitEvt) {
    const { pipeline, formReducer, onUpdate } = this.props;

    if (params.data && formReducer.isUpdateReq && this.state.stageToUpdate) {
      let allowedNextStages: PipelineStageEntity[] = [];

      if (params.data.allowedNextStages?.length! > 0) {
        allowedNextStages =
          pipeline?.stages?.filter((stage: PipelineStageEntity) => {
            return params.data.allowedNextStages.includes(stage.id);
          }) || [];
      }

      const body = {
        name: params.data.name,
        description: params.data.description,
        isDefault: params.data.isDefault,
        key: params.data.key,
        isFail: params.data.isFail,
        isTriage: params.data.isTriage,
        isSuccess: params.data.isSuccess,
        position: params.data.position,
        color: params.data.color,
        allowedNextStages: allowedNextStages.length > 0 ? allowedNextStages : null,
      };

      httpPut(`SchemaModule/v1.0/stages/${this.state.stageToUpdate?.id}`, body).then(() => {
        onUpdate && onUpdate();
        this.setState({ stageToUpdate: undefined });
      });
    }
  }

  handleDelete = () => {
    const { pipeline, onUpdate } = this.props;
    const stageId = this.state.stageIdToDelete;

    if (stageId) {
      if (pipeline) {
        httpDelete(`SchemaModule/v1.0/stages/${stageId}`).then(() => {
          this.closeModal();
          setTimeout(() => {
            onUpdate && onUpdate();
          }, 500);
        });
      }
    } else {
      setTimeout(() => {
        onUpdate && onUpdate();
      }, 500);
      this.closeModal();
    }
  };

  closeModal = () => {
    this.setState({ deleteStageAlertIsVisible: false, stageIdToDelete: null });
  };

  render() {
    const { pipeline } = this.props;

    const columns = [
      { title: 'Position', dataIndex: 'position', align: 'center', width: '5%' },
      {
        title: 'Stage Name',
        dataIndex: 'name',
        width: '17%',
      },
      { title: 'Description', dataIndex: 'description', width: '20%' },
      { title: 'Key', dataIndex: 'stageKey', width: '20%' },
      {
        title: 'Allowed Next Stages',
        dataIndex: 'allowedNextStages',
        render: (text: any) =>
          text ? (
            text.map((stage: any, i: number) => (
              <Tag minimal intent="success" style={{ marginRight: i === text.length - 1 ? 0 : 8 }}>
                {stage.name}
              </Tag>
            ))
          ) : (
            <></>
          ),
      },
      {
        title: 'Color',
        dataIndex: 'color',
        align: 'center',
        width: '7%',
        render: (text: any, record: any) =>
          record.color ? (
            <Row style={{ textAlign: 'center', width: '100%' }} justify="center">
              <Col
                style={{
                  height: 20,
                  width: 20,
                  borderRadius: 3,
                  backgroundColor: record.color,
                  margin: 0,
                }}
              />
            </Row>
          ) : (
            <></>
          ),
      },
      {
        title: 'Is Default',
        dataIndex: 'isDefault',
        align: 'center',
        width: '7%',
        render: (text: any) => renderBooleanValue(String(text)),
      },
      {
        title: 'Is Success',
        dataIndex: 'isSuccess',
        render: (text: any) => renderBooleanValue(String(text)),
        align: 'center',
        width: '7%',
      },
      {
        title: 'Is Fail',
        dataIndex: 'isFail',
        render: (text: any) => renderBooleanValue(String(text)),
        align: 'center',
        width: '7%',
      },
      {
        title: 'Is Triage',
        dataIndex: 'isTriage',
        render: (text: any) => renderBooleanValue(String(text)),
        align: 'center',
        width: '7%',
      },
      {
        title: 'Action',
        dataIndex: 'operation',
        align: 'right',
        width: '7%',
        render: (text: any, record: any) => (
          <>
            <Button
              small
              minimal
              icon="annotation"
              style={{ marginRight: 5 }}
              onClick={() => this.showStageUpdateForm(record)}
            />
            <Button
              small
              intent="danger"
              minimal
              icon="trash"
              onClick={() => {
                this.setState({ deleteStageAlertIsVisible: true, stageIdToDelete: record.key });
              }}
            />
          </>
        ),
      },
    ];

    const data = pipeline?.stages?.map((stage: PipelineStageEntity) => ({
      key: stage.id,
      name: stage.name,
      stageKey: stage.key,
      description: stage.description,
      position: stage.position,
      isDefault: stage.isDefault,
      isSuccess: stage.isSuccess,
      isFail: stage.isFail,
      // @ts-ignore
      color: stage.color,
      isTriage: stage.isTriage,
      allowedNextStages: stage.allowedNextStages,
    }));

    return (
      <>
        <Alert
          intent="danger"
          canEscapeKeyCancel
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={this.state.deleteStageAlertIsVisible}
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onConfirm={this.handleDelete}
        >
          <p>Are you sure you want to delete this pipeline stage? This action cannot be undone.</p>
        </Alert>

        <FormModal
          formUUID={this.state.uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => this.handleFormSubmit(params)}
        />

        {pipeline?.stages?.length === 0 ? (
          <div style={{ paddingTop: 10 }}>
            <NonIdealState
              title="No Stages Found"
              description={'Create first stage for this pipeline'}
              icon="search"
              iconSize={NonIdealStateIconSize.SMALL}
            />
          </div>
        ) : (
          <Table
            size="small"
            bordered={true}
            pagination={false}
            dataSource={data}
            columns={columns as any}
          />
        )}
      </>
    );
  }
}

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  deleteStage: (params: DeletePipelineStage) => dispatch(deletePipelineStageRequest(params)),
});

export default connect(mapState, mapDispatch)(PipelineStagesList);
