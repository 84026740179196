import { Col, Descriptions, Divider, Row, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import '../../styles.scss';

const { Title } = Typography;

interface Props {}

const AutosplicingRulesList: FunctionComponent<Props> = (props) => {
  return (
    <>
      <Row>
        <Col
          span={24}
          style={{
            padding: 10,
            borderRadius: 8,
          }}
        >
          <Row>
            <Title level={5}>How cable connections work</Title>
            <ol>
              <li>
                In the Trace tab, search an L0 closure and a Spine cable OR an
                L2 closure and Access cable.
              </li>
              <li>Press "Submit" to run the trace</li>
              <li>
                The trace step will take all the closures and cables from cosmos
                and create a cable connection template file.
              </li>
              <li>
                A diff check will validate all the features have the correct
                types and models and that the correct cables are used to connect
                closures. It will then compare the previous cable connections
                and the new cable connections from the trace step to see what
                has changed.
              </li>
              <li>
                You can view and confirm all the changes in the "Difference" tab
              </li>
              <li>
                If there are any validation errors i.e (invalid models or
                types). They need to be fixed before you can save the new design
                changes.
              </li>
            </ol>
          </Row>
        </Col>
      </Row>

      {/** Creating features **/}
      <Row>
        <Col
          span={24}
          style={{
            padding: 10,
            borderRadius: 8,
          }}
        >
          <Row>
            <Descriptions
              size="small"
              title="Valid closure models"
              column={1}
              layout="horizontal"
            >
              <Descriptions.Item label="L0">fist72:commscope</Descriptions.Item>
              <Descriptions.Item label="L1">fist72:commscope</Descriptions.Item>
              <Descriptions.Item label="L2">fist72:commscope</Descriptions.Item>
              <Descriptions.Item label="L3">cmj144:prysmian</Descriptions.Item>
              <Descriptions.Item label="L4">
                mst8:commscope, optitap8:corning, FST-s (squid)
              </Descriptions.Item>
              <Descriptions.Item label="LM">mdu</Descriptions.Item>
              <Descriptions.Item label="LM">lc</Descriptions.Item>
            </Descriptions>
          </Row>

          <Divider />

          <Row>
            <Descriptions
              size="small"
              title="Valid cable models"
              column={1}
              layout="horizontal"
            >
              <Descriptions.Item label="Spine">
                288micro:sterlite
              </Descriptions.Item>
              <Descriptions.Item label="Distribution">
                288micro:sterlite
              </Descriptions.Item>
              <Descriptions.Item label="Access">
                48ulw:sterlite
              </Descriptions.Item>
              <Descriptions.Item label="Feed">
                1feed:commscope, 8feed:corning, 48ulw:sterlite
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <Divider />

          <Row>
            <Descriptions
              size="small"
              title="Valid closure and cable models"
              column={1}
              layout="horizontal"
            >
              <Descriptions.Item label="L4">
                An L4 closure with a model of mst8:commscope must be connected
                with a Feed cable and model 1feed:commscope
              </Descriptions.Item>
              <Descriptions.Item label="L4">
                An L4 closure with a model of optitap8:corning must be connected
                with a Feed cable and model 8feed:commscope
              </Descriptions.Item>
              <Descriptions.Item label="LM">
                An LM closure with a model of mdu must be connected with a Feed
                cable and model 48ulw:sterlite
              </Descriptions.Item>
              <Descriptions.Item label="LC">
                An LC closure with a model of lc must be connected with a Feed
                cable and model 48ulw:sterlite
              </Descriptions.Item>
            </Descriptions>
          </Row>

          <Divider />

          <Row>
            <Descriptions
              size="small"
              title="Valid connections"
              column={1}
              layout="horizontal"
            >
              <Descriptions.Item label="L0 and L1">
                Spine cable
              </Descriptions.Item>
              <Descriptions.Item label="L1 and L1">
                Spine cable
              </Descriptions.Item>
              <Descriptions.Item label="L1 and L2">
                Distribution cable
              </Descriptions.Item>
              <Descriptions.Item label="L2 and L2">
                Distribution cable
              </Descriptions.Item>
              <Descriptions.Item label="L2 and L3">
                Access cable
              </Descriptions.Item>
              <Descriptions.Item label="L3 and L3">
                Access cable
              </Descriptions.Item>
              <Descriptions.Item label="L3 and L4">
                Feed cable
              </Descriptions.Item>
              <Descriptions.Item label="L3 and LM">
                Feed cable
              </Descriptions.Item>
              <Descriptions.Item label="L3 and LC">
                Feed cable
              </Descriptions.Item>
            </Descriptions>
          </Row>
        </Col>
      </Row>

      <Divider />

      <Row>
        <Col
          span={24}
          style={{
            padding: 10,
            borderRadius: 8,
          }}
        >
          <Row>
            <Title style={{ color: 'red' }} level={5}>
              Deleting a closure in QGIS
            </Title>
            <ol>
              <li>the closure will be deleted</li>
              <li>cable connections will be deleted</li>
              <li>fiber connections will be deleted</li>
              <li>re-splicing is required</li>
            </ol>
          </Row>
          <Row>
            <Title style={{ color: 'red' }} level={5}>
              Deleting a cable in QGIS
            </Title>
            <ol>
              <li>the cable will be deleted</li>
              <li>cable connections will be deleted</li>
              <li>fiber connections will be deleted</li>
              <li>re-splicing is required</li>
            </ol>
          </Row>
          <Row>
            <Title style={{ color: 'red' }} level={5}>
              Deleting a cable in QGIS
            </Title>
            <ol>
              <li>the cable will be deleted</li>
              <li>cable connections will be deleted</li>
              <li>fiber connections will be deleted</li>
              <li>re-splicing is required</li>
            </ol>
          </Row>
          <Row>
            <Title style={{ color: 'red' }} level={5}>
              Changing the closure model in QGIS
            </Title>
            <ol>
              <li>
                the closure ports, seal interfaces, slots will be replaced
              </li>
              <li>cable connections will be deleted</li>
              <li>fiber connections will be deleted</li>
              <li>re-splicing is required</li>
            </ol>
          </Row>
          <Row>
            <Title style={{ color: 'red' }} level={5}>
              Changing the cable model in QGIS
            </Title>
            <ol>
              <li>the cable tubes and fibers will be replaced</li>
              <li>cable connections will be deleted</li>
              <li>fiber connections will be deleted</li>
              <li>re-splicing is required</li>
            </ol>
          </Row>
          <Row>
            <Title style={{ color: 'red' }} level={5}>
              Moving a cable to a different closure
            </Title>
            <ol>
              <li>the existing cable connection will be updated</li>
              <li>all fiber connections will be deleted</li>
              <li>re-splicing is required</li>
            </ol>
          </Row>
          <Row>
            <Title style={{ color: 'red' }} level={5}>
              Loop path is changed in QGIS
            </Title>
            <ol>
              <li>the existing cable connection will be updated</li>
              <li>all loop fiber connections will be deleted</li>
              <li>re-splicing is required</li>
            </ol>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default AutosplicingRulesList;
