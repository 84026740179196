import { FileDoneOutlined } from '@ant-design/icons';
import {
  QGISCableTypeEnum,
  QGISClosureTypeEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Drawer, Row, Tag, Typography } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { MapReducerUpdate, updateMapState } from '@netomnia/modules/ProjectModule/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../core/helpers/schemaHelpers';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import CoreLegacyEmbeddedForm from '@legacy/core/records/components/Forms/CoreLegacyEmbeddedForm';

interface OwnProps {
  mapReducer: MapReducer;
  recordReducer: any;
  schemaReducer: any;
  updateMap: Function;
  initializeForm: Function;
}

type Props = OwnProps;

const { Title } = Typography;
const uuid = uuidv4();

const FeatureBulkUpdates: FunctionComponent<Props> = (props) => {
  const { recordReducer, mapReducer, schemaReducer, updateMap, initializeForm } = props;
  const { bulkUpdateDrawerVisible } = mapReducer;

  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  // Get schema from shortlist and set to state
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      'ProjectModule',
      'Feature',
    );

    if (shortlistSchema && recordReducer.list[shortlistSchema?.id] && bulkUpdateDrawerVisible) {
      setSchema(shortlistSchema);
      initializeForm({
        selected: null,
        formUUID: uuid,
        showFormModal: false,
        isBulkUpdateReq: true,
        schema: shortlistSchema,
        recordType: mapReducer.queryLayer?.toUpperCase(),
        sections: [
          {
            name: shortlistSchema.name,
            schema: shortlistSchema,
            associations: [],
          },
        ],
      });
    }
  }, [bulkUpdateDrawerVisible]);

  const featureTag = (feature: DbRecordEntityTransform) => {
    if (feature.type === 'CLOSURE') {
      const closureType = QGISClosureTypeEnum[getProperty(feature, 'ClosureType')];
      return <Tag>{`${closureType} - ${getProperty(feature, 'ExternalRef')}`}</Tag>;
    } else if (feature.type === 'CABLE') {
      const cableType = QGISCableTypeEnum[getProperty(feature, 'CableType')];
      return <Tag>{`${cableType} - ${getProperty(feature, 'ExternalRef')}`}</Tag>;
    }
  };

  const handleSubmit = () => {
    updateMap({
      bulkUpdateDrawerVisible: false,
    });
  };

  return (
    <Drawer
      forceRender
      title={
        <div>
          <FileDoneOutlined style={{ marginRight: 10 }} />
          Bulk update features
        </div>
      }
      placement="right"
      width={isMobile ? '100%' : 450}
      onClose={() => updateMap({ bulkUpdateDrawerVisible: false })}
      open={bulkUpdateDrawerVisible}
    >
      {schema && recordReducer.list[schema?.id] && uuid ? (
        <Row>
          <Col span={24} style={{ paddingLeft: 5 }}>
            <Title level={5}>Features</Title>
          </Col>
          <Col span={24} style={{ paddingLeft: 5 }}>
            {recordReducer.list[schema!.id].map((record: any) => {
              return featureTag(record);
            })}
          </Col>

          <Col span={24}>
            {
              <CoreLegacyEmbeddedForm
                formDirection="vertical"
                showFormActions
                formUUID={uuid}
                onCloseEvent={() => {
                  updateMap({ bulkUpdateDrawerVisible: false });
                }}
                onSubmitEvent={() => {
                  handleSubmit();
                }}
              />
            }
          </Col>
        </Row>
      ) : (
        <span>Schema or records not available</span>
      )}
    </Drawer>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(FeatureBulkUpdates);
