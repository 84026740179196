import {
  Button,
  Callout,
  Checkbox,
  FormGroup,
  HTMLTable,
  Icon,
  InputGroup,
  Popover,
  Spinner,
  Switch,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import {
  RelationTypeEnum,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import {
  SchemaColumnOptionEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Pagination, PaginationProps, Row } from 'antd';
import { capitalCase } from 'change-case';
import dayjs from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import { DateTime } from 'luxon';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getSchemaByModuleAndEntityRequest } from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { IOpenRecordDrawer, openRecordDrawer } from '@legacy/core/userInterface/store/actions';
import { httpGet, httpPost } from '@core/http/requests';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import TableCellStageTag from '@core/components/TableCellStageTag';
import TableColumnFilter from '@core/components/TableColumnFilter';
import { useInterval } from '@core/hooks/useInterval';
import {
  ColumnFilter,
  getAssignedEngineerOptions,
  getAssignedOwnerOptions,
  getAssignedTeamOptions,
  getClassNameForRemediationRow,
  useMultiSelectFilter,
} from './helpers';
import { INavigationReducer } from '@legacy/core/navigation/store/reducer';
import { SortableColumnIcon } from '@core/components/SortableColumnIcon';
import './styles.scss';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';

interface Props {
  userReducer: any;
  openDrawer: (payload: IOpenRecordDrawer) => void;
  schemaReducer: ISchemaReducer;
  getSchema: (params: any, cb: any) => void;
  navigationReducer: INavigationReducer;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;
const LAST_ACTIVITY_COMMENT_TRUNC_SIZE = 60;

dayjs.extend(isToday);

const WorkOrderRemediationView: FC<Props> = (props: Props) => {
  const { navigationReducer, openDrawer, schemaReducer, getSchema } = props;

  const [permissionToFailList, setPermissionToFailList] = useState<DbRecordEntityTransform[]>([]);
  const [loadingPermissionToFailList, setLoadingPermissionToFailList] = useState<boolean>(false);
  const [loadingRemediationWorkOrders, setLoadingRemediationWorkOrderList] =
    useState<boolean>(false);
  const [remediationWorkOrders, setRemediationWorkOrderList] = useState<DbRecordEntityTransform[]>(
    [],
  );
  const [lastRefreshTimeStamp, setLastRefreshTimestamp] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [workOrderSchema, setWorkOrderSchema] = useState<SchemaEntity | undefined>(undefined);
  const [onHoldFilterEnabled, enableOnHoldFilter] = useState<boolean>(false);
  const [activeOrderFilterEnabled, enableActiveOrderFilter] = useState<boolean>(false);
  const [selectedRAGFilters, setSelectedRAGFilters] = useState<string[]>([]);
  const [selectedTypeFilters, setSelectedTypeFilters] = useState<string[]>(['REMEDIATION']);
  const [selectedDescriptionFilters, setSelectedDescriptionFilters] = useState<string[]>([]);
  const [selectedStageFilters, setSelectedStageFilters] = useState<string[]>([]);
  const [selectedGroupFilters, setSelectedGroupFilters] = useState<string[]>([]);
  const [VIPFilter, setVIPFilter] = useState<boolean>(false);
  const [REMFilter, setREMFilter] = useState<boolean>(false);
  const [unblockedFilter, setUnblockedFilter] = useState<boolean>(false);
  const [filterSearchQuery, setFilterSearchQuery] = useState<string>('');
  const [workOrderStageList, setWorkOrderStageFilters] = useState<
    {
      label: string;
      value: string;
    }[]
  >([]);
  const [pageSize, setPageSize] = useState<number>(80);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useInterval(() => {
    if (!document.hidden) {
      fetchWorkOrders(true);
      setLastRefreshTimestamp(DateTime.now().toISO());
      fetchPermissionToFail(true);
    }
  }, 1000 * 15);

  useEffect(() => {
    fetchPermissionToFail(false);
    // fetchWorkOrders(false);
    setLastRefreshTimestamp(DateTime.now().toISO());
  }, []);

  // Fetch Work Order Schema on component mount
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      WORK_ORDER,
    );

    if (shortlistSchema) {
      setWorkOrderSchema(shortlistSchema);
    } else {
      getSchema(
        { moduleName: FIELD_SERVICE_MODULE, entityName: WORK_ORDER },
        (responseSchema: SchemaEntity) => {
          if (responseSchema) {
            setWorkOrderSchema(responseSchema);
          }
        },
      );
    }
  }, []);

  const clearFilterSearchQuery = () => {
    setFilterSearchQuery('');
  };

  const getViewportHeight = () => {
    return 'calc(100vh - 260px)';
    // if (navigationReducer.tabHistory?.length > 0) {
    //   return '64vh';
    // } else {
    //   return '70vh';
    // }
  };

  // Search work orders whenever type filter is updated
  useEffect(() => {
    if (selectedTypeFilters) {
      fetchWorkOrders(false);
    }
  }, [selectedTypeFilters]);

  const areFiltersApplied = () => {
    return (
      searchQuery.length > 0 ||
      onHoldFilterEnabled ||
      activeOrderFilterEnabled ||
      VIPFilter ||
      REMFilter ||
      unblockedFilter
    );
  };

  // Fetch Work Order Pipeline once Work Order Schema is available
  useEffect(() => {
    if (workOrderSchema && selectedTypeFilters?.length === 1) {
      httpGet(
        `SchemaModule/v1.0/pipelines/list/${workOrderSchema?.moduleName}/${workOrderSchema.entityName}`,
      ).then((res) => {
        if (res?.data?.data?.length > 0) {
          const filtered =
            res?.data?.data?.find((elem: PipelineEntity) =>
              elem?.schemaTypes?.includes(selectedTypeFilters[0]),
            ) || [];
          if (filtered && filtered?.stages) {
            const stageList = filtered.stages.map((stage: any) => ({
              label: stage.name,
              value: stage.key,
            }));

            setWorkOrderStageFilters(stageList);
          } else {
            setWorkOrderStageFilters([
              {
                label: 'Select a work order Type',
                value: '',
              },
            ]);
          }
        }
      });
    } else {
      setWorkOrderStageFilters([
        {
          label: 'Select a work order Type',
          value: '',
        },
      ]);
    }
  }, [workOrderSchema, selectedTypeFilters]);

  const getDurationSinceLastRefresh = () => {
    if (lastRefreshTimeStamp) {
      return `${DateTime.fromISO(String(lastRefreshTimeStamp)).toFormat('dd/MM/yyyy hh:mm:ss')}`;
    }
  };

  // Fetch queues and set the initial state
  // Replace this with your actual API call or data retrieval logic
  const fetchPermissionToFail = async (loadInBackground: boolean) => {
    if (!loadInBackground) {
      setLoadingPermissionToFailList(true);
    }
    // Example fetch using the Fetch API
    const response = await httpPost('FieldServiceModule/v1.0/db/dynamicQuery', {
      entity: 'FieldServiceModule:PermissionToFail',
      createdAt: {
        gte: 'now/d',
        lt: 'now+1d/d',
      },
      pagination: {
        size: 1000,
        page: 1,
      },
    });
    const data = await response.data.data.records;
    setPermissionToFailList(data);

    setLoadingPermissionToFailList(false);
  };

  // Fetch cases based on the selected queue
  // Replace this with your actual API call or data retrieval logic
  const fetchWorkOrders = async (loadInBackground: boolean) => {
    if (!loadInBackground) {
      setLoadingRemediationWorkOrderList(true);
    }

    const response = await httpPost('FieldServiceModule/v1.0/db/dynamicQuery', {
      entity: 'FieldServiceModule:WorkOrder',
      type: selectedTypeFilters[0],
      stageNames: [
        'Approval',
        'Accepted',
        'In Progress',
        'Remediation Required',
        'Purchase Order',
        'Need Information',
        'Scheduled',
        'En Route',
        'New',
        'NA Raised',
        'NA Rejected',
        'In Review',
        'Awaiting PTD',
        'More Information Required',
        'Design In Progress',
        'Design QC',
        'On-hold',
        'Submitted',
      ],
      fieldsToReturn: [
        // Work Order
        'id',
        'title',
        'recordNumber',
        'type',
        'entity',
        'stageUpdatedAt',
        'createdAt',
        'updatedAt',
        // Work Order Properties
        'properties.EngineerId',
        'properties.EngineerName',
        'properties.HasActiveOrder',
        'properties.InstallRequiringActionReason',
        'properties.IsVIP',
        'properties.LatestProcessActivityComment',
        'properties.LatestProcessActivityDate',
        'properties.OwnerId',
        'properties.OwnerName',
        'properties.SLAEndDate',
        'properties.SLARagStatus',
        'properties.Status',
        'properties.TeamId',
        'properties.TeamName',
        'stage.*',
        'groups',
        // Associated Work Order
        'WorkOrder.dbRecords.id',
        'WorkOrder.dbRecords.updatedAt',
        'WorkOrder.dbRecords.title',
        'WorkOrder.dbRecords.recordNumber',
        'WorkOrder.dbRecords.type',
        'WorkOrder.dbRecords.stage.*',
        'WorkOrder.dbRecords.dbRecordAssociation',
        'WorkOrder.dbRecords.properties.Status',
        'WorkOrder.dbRecords.properties.SLAEndDate',
        'WorkOrder.dbRecords.properties.SLARagStatus',
        'WorkOrder.dbRecords.properties.OwnerName',

        // Associated ServiceAppointment
        'ServiceAppointment.dbRecords.properties.Date',

        // Change reason
        'ChangeReason.dbRecords.id',
      ],
      properties: [
        {
          columnName: 'SLARagStatus',
          operator: 'IS NOT NULL',
        },
      ],
      pagination: {
        size: 3000,
        page: 1,
      },
    });
    const data = await response.data.data.records;
    setRemediationWorkOrderList(data);
    // console.log('%cdebug: DATA', 'color:orange', data);

    setLoadingRemediationWorkOrderList(false);
  };

  const getCaseIntent = (workOrder: DbRecordEntityTransform) => {
    switch (getProperty(workOrder, 'SLARagStatus')) {
      case 'RED':
        return 'danger';
      case 'AMBER':
        return 'warning';
      case 'GREEN':
        return 'success';
      default:
        return 'none';
    }
  };

  const sortByServiceAppointmentDate = (a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
    const now = new Date().getTime() * 100;
    const dateA = getProperty(getFirstRelation(a, 'ServiceAppointment'), 'Date') ?? now;
    const dateB = getProperty(getFirstRelation(b, 'ServiceAppointment'), 'Date') ?? now;

    if (sortingParams['ServiceAppointment'] === 'asc')
      return new Date(dateA).getTime() - new Date(dateB).getTime();

    return new Date(dateB).getTime() - new Date(dateA).getTime();
  };

  const handleViewWorkOrder = (caseRecord: DbRecordEntityTransform) => {
    openDrawer({
      moduleName: 'FieldServiceModule',
      entityName: 'WorkOrder',
      recordId: caseRecord.id,
    });
  };

  const assignedEngineerOptions = getAssignedEngineerOptions(remediationWorkOrders);
  const assignedTeamOptions = getAssignedTeamOptions(remediationWorkOrders);
  const assignedOwnerOptions = getAssignedOwnerOptions(remediationWorkOrders);

  const assignedEngineerFilter = useMultiSelectFilter({ options: assignedEngineerOptions });
  const assignedTeamFilter = useMultiSelectFilter({ options: assignedTeamOptions });
  const assignedOwnerFilter = useMultiSelectFilter({ options: assignedOwnerOptions });

  const renderAssignedEngineerFilterOptions = () => {
    let filteredEngineers = Object.assign([], assignedEngineerOptions);
    if (filterSearchQuery.length > 0) {
      filteredEngineers = filteredEngineers.filter((owner: { label: string; value: string }) => {
        return owner.label?.toLowerCase().includes(filterSearchQuery?.toLowerCase());
      });
    }

    return (
      <FormGroup>
        <InputGroup
          placeholder="Search Engineers"
          leftIcon="search"
          round
          value={filterSearchQuery}
          onChange={(e: any) => setFilterSearchQuery(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        {filteredEngineers.map((option: { label: string; value: string }) => {
          const checked = assignedEngineerFilter.selectedValues.includes(option.value);

          const handleClick = (evt: any) => {
            if (evt.target.checked)
              assignedEngineerFilter.setSelectedValues([
                ...assignedEngineerFilter.selectedValues.filter((v) => v !== 'unassigned'),
                option.value,
              ]);
            else
              assignedEngineerFilter.setSelectedValues(
                assignedEngineerFilter.selectedValues.filter((val) => val !== option.value),
              );
          };

          return <Checkbox checked={checked} label={option.label} onClick={handleClick} />;
        })}
        <hr />
        <Checkbox
          checked={assignedEngineerFilter.selectedValues[0] === 'unassigned'}
          label="Unassigned"
          onClick={(evt: any) =>
            assignedEngineerFilter.setSelectedValues(evt.target.checked ? ['unassigned'] : [])
          }
        />
      </FormGroup>
    );
  };

  const renderAssignedTeamFilterOptions = () => {
    return (
      <FormGroup>
        {assignedTeamOptions.map((option) => {
          const checked = assignedTeamFilter.selectedValues.includes(option.value);

          const handleClick = (evt: any) => {
            if (evt.target.checked)
              assignedTeamFilter.setSelectedValues([
                ...assignedTeamFilter.selectedValues.filter((v) => v !== 'unassigned'),
                option.value,
              ]);
            else
              assignedTeamFilter.setSelectedValues(
                assignedTeamFilter.selectedValues.filter((val) => val !== option.value),
              );
          };

          return <Checkbox checked={checked} label={option.label} onClick={handleClick} />;
        })}
        <hr />
        <Checkbox
          checked={assignedTeamFilter.selectedValues[0] === 'unassigned'}
          label="Unassigned"
          onClick={(evt: any) =>
            assignedTeamFilter.setSelectedValues(evt.target.checked ? ['unassigned'] : [])
          }
        />
      </FormGroup>
    );
  };

  const renderAssignedOwnerFilterOptions = () => {
    let filteredOwners = Object.assign([], assignedOwnerOptions);
    if (filterSearchQuery.length > 0) {
      filteredOwners = filteredOwners.filter((owner: { label: string; value: string }) => {
        return owner.label?.toLowerCase().includes(filterSearchQuery?.toLowerCase());
      });
    }

    return (
      <FormGroup>
        <InputGroup
          placeholder="Search Owners"
          leftIcon="search"
          round
          value={filterSearchQuery}
          onChange={(e: any) => setFilterSearchQuery(e.target.value)}
          style={{ marginBottom: 10 }}
        />
        {filteredOwners.map((option: { label: string; value: string }) => {
          const checked = assignedOwnerFilter.selectedValues.includes(option.value);

          const handleClick = (evt: any) => {
            if (evt.target.checked)
              assignedOwnerFilter.setSelectedValues([
                ...assignedOwnerFilter.selectedValues.filter((v) => v !== 'unassigned'),
                option.value,
              ]);
            else
              assignedOwnerFilter.setSelectedValues(
                assignedOwnerFilter.selectedValues.filter((val) => val !== option.value),
              );
          };

          return <Checkbox checked={checked} label={option.label} onClick={handleClick} />;
        })}
        <hr />
        <Checkbox
          checked={assignedOwnerFilter.selectedValues[0] === 'unassigned'}
          label="Unassigned"
          onClick={(evt: any) =>
            assignedOwnerFilter.setSelectedValues(evt.target.checked ? ['unassigned'] : [])
          }
        />
      </FormGroup>
    );
  };

  const getLinkedWorkOrders = (wo: DbRecordEntityTransform) => {
    return (
      getAllRelations(wo, SchemaModuleEntityTypeEnums.WORK_ORDER)?.filter(
        (elem) =>
          //@ts-ignore
          elem?.dbRecordAssociation?.relationType === RelationTypeEnum.CHILD &&
          !elem?.stage?.isSuccess &&
          !elem?.stage?.isFail,
      ) || []
    );
  };

  const getRAGStatusFilterOptions = (): { value: string; label: string }[] => {
    if (workOrderSchema) {
      const col = workOrderSchema.columns.find((col) => col.name === 'SLARagStatus');

      if (col) {
        return col?.options?.map((option: SchemaColumnOptionEntity) => ({
          label: option.label,
          value: option.value,
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getWorkOrderTypeFilterOptions = (): {
    value: string;
    label: string;
  }[] => {
    if (workOrderSchema) {
      if (workOrderSchema.types) {
        return workOrderSchema.types?.map((elem) => ({
          label: elem.name,
          value: elem.name,
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const getWorkOrderDescriptionFilterOptions = (): {
    value: string;
    label: string;
  }[] => {
    if (workOrderSchema) {
      const col: SchemaColumnEntity | undefined = workOrderSchema.columns.find(
        (col) => col.name === 'InstallRequiringActionReason',
      );

      if (col) {
        return col.options?.map((option: SchemaColumnOptionEntity) => ({
          label: option.label,
          value: option.value,
        }));
      } else {
        return [];
      }
    } else {
      return [];
    }
  };

  const toggleOnHoldWorkOrders = () => {
    if (onHoldFilterEnabled) {
      enableOnHoldFilter(false);
    } else {
      enableOnHoldFilter(true);
    }
  };

  const toggleActiveOrderWorkOrders = () => {
    if (activeOrderFilterEnabled) {
      enableActiveOrderFilter(false);
    } else {
      enableActiveOrderFilter(true);
    }
  };

  const handleRAGStatusFilterChange = (value: string) => {
    if (selectedRAGFilters.includes(value)) {
      setSelectedRAGFilters(selectedRAGFilters.filter((val) => val !== value));
    } else {
      setSelectedRAGFilters([...selectedRAGFilters, value]);
    }
  };
  const handleTypeFilterChange = (value: string) => {
    setSelectedStageFilters([]);

    // Don't allow deselecting everything, we need at least one type.
    if (selectedTypeFilters.includes(value) && selectedTypeFilters.length === 1) {
      return false;
    } else if (selectedTypeFilters.includes(value)) {
      setSelectedTypeFilters(selectedTypeFilters.filter((val) => val !== value));
    } else {
      setSelectedTypeFilters([value]);
    }
  };

  const handleDescriptionFilterChange = (value: string) => {
    setSelectedDescriptionFilters([]);
    if (selectedDescriptionFilters.includes(value)) {
      setSelectedDescriptionFilters(selectedDescriptionFilters.filter((val) => val !== value));
    } else {
      setSelectedDescriptionFilters([...selectedDescriptionFilters, value]);
    }
  };

  const handleStageFilterChange = (value: string) => {
    if (selectedStageFilters.includes(value)) {
      setSelectedStageFilters(selectedStageFilters.filter((val) => val !== value));
    } else {
      setSelectedStageFilters([...selectedStageFilters, value]);
    }
  };

  // WORK ORDER FILTERING
  let filteredWorkOrders: DbRecordEntityTransform[] = Object.assign([], remediationWorkOrders);

  // Filter by VIP
  if (VIPFilter) {
    filteredWorkOrders = filteredWorkOrders.filter((workOrder) => {
      return getProperty(workOrder, 'IsVIP');
    });
  }

  // Filter by Description / InstallRequiringActionReason
  if (selectedDescriptionFilters.length > 0) {
    filteredWorkOrders = filteredWorkOrders.filter((workOrder) => {
      return selectedDescriptionFilters.includes(
        getProperty(workOrder, 'InstallRequiringActionReason'),
      );
    });
  }

  // Filter by all REMEDIATION work orders created today, and linked to either an INSTALL or SERVICE work order
  if (REMFilter) {
    filteredWorkOrders = filteredWorkOrders.filter((remediatedWO: DbRecordEntityTransform) => {
      const linkedWOs = remediatedWO[WORK_ORDER]?.dbRecords;
      if (
        remediatedWO.type === 'REMEDIATION' &&
        dayjs(remediatedWO.createdAt).isToday() &&
        linkedWOs?.some((wo: DbRecordEntityTransform) => ['INSTALL', 'SERVICE'].includes(wo.type!))
      ) {
        return remediatedWO;
      }
    });
  }

  // Filter by Unblocked RemediationWork Orders
  if (unblockedFilter) {
    filteredWorkOrders = filteredWorkOrders.filter((remediatedWO: DbRecordEntityTransform) => {
      const isBlocked = getLinkedWorkOrders(remediatedWO)?.length > 0;
      return !isBlocked;
    });
  }

  // Filter by search query, if search query is set, filter by assigned name, recordNumber and title (Address in this case)
  if (searchQuery.length > 0) {
    filteredWorkOrders = filteredWorkOrders.filter((workOrder) => {
      const assignedEngineerName =
        assignedEngineerOptions.find((elem) => elem.value === getProperty(workOrder, 'EngineerId'))
          ?.label || '';

      const assignedTeamName =
        assignedTeamOptions.find((elem) => elem.value === getProperty(workOrder, 'TeamId'))
          ?.label || '';

      const assignedOwnerName =
        assignedOwnerOptions.find((elem) => elem.value === getProperty(workOrder, 'OwnerId'))
          ?.label || '';

      return (
        assignedTeamName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        assignedOwnerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        assignedEngineerName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        workOrder.recordNumber?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        workOrder.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        getProperty(workOrder, 'InstallRequiringActionReason')
          ?.toLowerCase()
          .includes(searchQuery.toLowerCase())
      );
    });
  }

  // Filter by Column filter -> Assigned engineers
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (
      assignedEngineerFilter.selectedValues.length === 1 &&
      assignedEngineerFilter.selectedValues[0] === 'undefined'
    ) {
      const assignedUser = getProperty(workOrder, 'EngineerId');
      return assignedUser === undefined || assignedUser === null;
    } else if (assignedEngineerFilter.selectedValues.length > 0) {
      return assignedEngineerFilter.selectedValues.includes(getProperty(workOrder, 'EngineerId'));
    } else {
      return true;
    }
  });

  // Filter by Column filter -> Assigned team
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (
      assignedTeamFilter.selectedValues.length === 1 &&
      assignedTeamFilter.selectedValues[0] === 'unassigned'
    ) {
      const assignedUser = getProperty(workOrder, 'TeamId');
      return assignedUser === undefined || assignedUser === null;
    } else if (assignedTeamFilter.selectedValues.length > 0) {
      return assignedTeamFilter.selectedValues.includes(getProperty(workOrder, 'TeamId'));
    } else {
      return true;
    }
  });

  // Filter by Column filter -> Assigned owner
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (
      assignedOwnerFilter.selectedValues.length === 1 &&
      assignedOwnerFilter.selectedValues[0] === 'undefined'
    ) {
      const assignedUser = getProperty(workOrder, 'OwnerId');
      return assignedUser === undefined || assignedUser === null;
    } else if (assignedOwnerFilter.selectedValues.length > 0) {
      return assignedOwnerFilter.selectedValues.includes(getProperty(workOrder, 'OwnerId'));
    } else {
      return true;
    }
  });

  // Filter by Column filter -> RAG Status
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (selectedRAGFilters.length > 0) {
      return selectedRAGFilters.includes(getProperty(workOrder, 'SLARagStatus'));
    } else {
      return true;
    }
  });

  // Filter by Column filter -> Stage
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (selectedStageFilters.length > 0) {
      return selectedStageFilters.includes(workOrder.stage?.key!);
    } else {
      return true;
    }
  });

  // D19-3453 - Removed type filtering for now, we are fetching WOs by type dynamically
  //
  // Filter by Column filter -> Type
  // filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
  //   if (selectedTypeFilters.length > 0) {
  //     return selectedTypeFilters.includes(String(workOrder.type));
  //   } else {
  //     return true;
  //   }
  // });

  // Filter by Column filter -> Type
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (selectedGroupFilters.length > 0) {
      if (workOrder?.groups) {
        const commonElements: string[] = selectedGroupFilters.filter((item) =>
          workOrder?.groups?.map((grp) => grp.name).includes(item),
        );
        if (commonElements?.length > 0) {
          return true;
        } else {
          return false;
        }
      } else if (selectedGroupFilters?.includes('Unassigned')) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });

  // Filter by Column filter -> Status
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (onHoldFilterEnabled) {
      return getProperty(workOrder, 'Status') === 'ON_HOLD';
    } else {
      return true;
    }
  });

  // Filter by Column filter -> HasActiveOrder
  filteredWorkOrders = filteredWorkOrders.filter((workOrder: DbRecordEntityTransform) => {
    if (activeOrderFilterEnabled) {
      return getProperty(workOrder, 'HasActiveOrder') === 'true';
    } else {
      return true;
    }
  });

  const [sortingParams, setSortingParams] = useState<{ [key: string]: 'asc' | 'desc' | 'none' }>(
    {},
  );
  const toggleSorting = (key: string) => {
    setSortingParams((sortingParams) => {
      const currentSort = sortingParams[key] ?? 'none';

      if (['none', 'desc'].includes(currentSort))
        return {
          [key]: 'asc',
        };

      return {
        [key]: 'desc',
      };
    });
  };

  if (sortingParams['ServiceAppointment'] !== 'none') {
    filteredWorkOrders = filteredWorkOrders.sort(sortByServiceAppointmentDate);
  }

  // Sort by LatestProcessActivityDate if sorting is enabled
  if (sortingParams['LatestProcessActivityDate'] !== 'none') {
    filteredWorkOrders = filteredWorkOrders?.sort(
      (a: DbRecordEntityTransform, b: DbRecordEntityTransform) => {
        const sorting = sortingParams['LatestProcessActivityDate'];
        const dateA = getProperty(a, 'LatestProcessActivityDate');
        const dateB = getProperty(b, 'LatestProcessActivityDate');

        if (sorting === 'asc') {
          if (!dateA) return 1;
          if (!dateB) return -1;
          return dayjs(dateA).isBefore(dayjs(dateB)) ? -1 : 1;
        } else if (sorting === 'desc') {
          if (!dateA) return 1;
          if (!dateB) return -1;
          return dayjs(dateB).isBefore(dayjs(dateA)) ? -1 : 1;
        }
        return 0;
      },
    );
  }

  let tableData: any[] = Object.assign([], filteredWorkOrders);
  // apply pagination to tabledata
  if (!areFiltersApplied()) {
    tableData = tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize);
  } else {
    tableData = tableData.slice(0, 500);
  }

  const onHoldCount =
    remediationWorkOrders?.filter((elem) => getProperty(elem, 'Status') === 'ON_HOLD')?.length || 0;
  const hasActiveOrderCount =
    remediationWorkOrders?.filter((elem) => getProperty(elem, 'HasActiveOrder') === 'true')
      ?.length || 0;

  const onPaginationChange = (e: number) => {
    setCurrentPage(e);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const truncateComment = (comment: string) => {
    return comment.length > LAST_ACTIVITY_COMMENT_TRUNC_SIZE
      ? `${comment.slice(0, LAST_ACTIVITY_COMMENT_TRUNC_SIZE)}...`
      : comment;
  };

  return (
    <div className="remediation-work-order-list-view">
      <Row>
        <Col span={12}>
          <div style={{ display: 'flex' }}>
            <Callout intent="primary" title="WO Total" compact icon={null}>
              <h3 style={{ margin: 0 }}>{filteredWorkOrders?.length}</h3>
            </Callout>
            <Callout intent="success" title="WO Green" compact icon={null}>
              <h3 style={{ margin: 0 }}>
                {
                  filteredWorkOrders?.filter(
                    (elem) => getProperty(elem, 'SLARagStatus') === 'GREEN',
                  )?.length
                }
              </h3>
            </Callout>
            <Callout intent="warning" title="WO Amber" compact icon={null}>
              <h3 style={{ margin: 0 }}>
                {
                  filteredWorkOrders?.filter(
                    (elem) => getProperty(elem, 'SLARagStatus') === 'AMBER',
                  )?.length
                }
              </h3>
            </Callout>
            <Callout intent="danger" title="WO Red" compact icon={null}>
              <h3 style={{ margin: 0 }}>
                {
                  filteredWorkOrders?.filter((elem) => getProperty(elem, 'SLARagStatus') === 'RED')
                    ?.length
                }
              </h3>
            </Callout>
          </div>
        </Col>
        <Col span={12}>
          <div style={{ display: 'flex' }}>
            <Callout intent="primary" title="PTF Total" compact icon={null}>
              <h3 style={{ margin: 0 }}>{permissionToFailList?.length}</h3>
            </Callout>
            <Callout intent="warning" title="PTF Pending" compact icon={null}>
              <h3 style={{ margin: 0 }}>
                {
                  permissionToFailList?.filter((elem) => !getProperty(elem, 'ApprovalStatus'))
                    ?.length
                }
              </h3>
            </Callout>
            <Callout intent="success" title="PTF Approved" compact icon={null}>
              <h3 style={{ margin: 0 }}>
                {
                  permissionToFailList?.filter(
                    (elem) => getProperty(elem, 'ApprovalStatus') === 'APPROVED',
                  )?.length
                }
              </h3>
            </Callout>
            <Callout intent="danger" title="PTF Rejected" compact icon={null}>
              <h3 style={{ margin: 0 }}>
                {
                  permissionToFailList?.filter(
                    (elem) => getProperty(elem, 'ApprovalStatus') === 'REJECTED',
                  )?.length
                }
              </h3>
            </Callout>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row align="middle" justify="start" style={{ padding: 10 }}>
            {/* LEFT PANEL */}
            <Col span={20}>
              <Row align="middle">
                {/*Search Query*/}
                <Col span={5}>
                  <InputGroup
                    disabled={loadingPermissionToFailList || loadingRemediationWorkOrders}
                    round
                    leftIcon="search"
                    placeholder="Search by Assignee, WO# or Address"
                    value={searchQuery}
                    onChange={(e: any) => setSearchQuery(e.target.value)}
                    rightElement={
                      searchQuery.length! > 0 ? (
                        <Button
                          minimal
                          intent="danger"
                          icon="cross"
                          onClick={() => setSearchQuery('')}
                        />
                      ) : (
                        <></>
                      )
                    }
                  />
                </Col>

                {/* On-Hold Toggle */}
                <Col style={{ paddingLeft: 10 }}>
                  <Button
                    style={{ marginLeft: 8 }}
                    intent={onHoldFilterEnabled ? 'primary' : undefined}
                    onClick={() => toggleOnHoldWorkOrders()}
                    disabled={loadingRemediationWorkOrders}
                  >
                    On-Hold
                    <Tag
                      round
                      intent={onHoldCount > 0 ? 'warning' : 'success'}
                      style={{
                        marginLeft: 5,
                      }}
                    >
                      {onHoldCount}
                    </Tag>
                  </Button>
                </Col>

                {/* Active Orders Toggle */}
                <Col>
                  <Button
                    disabled={loadingRemediationWorkOrders}
                    style={{ marginLeft: 8 }}
                    intent={activeOrderFilterEnabled ? 'primary' : undefined}
                    onClick={() => toggleActiveOrderWorkOrders()}
                  >
                    Active Orders
                    <Tag
                      round
                      intent={hasActiveOrderCount > 0 ? 'danger' : 'success'}
                      style={{
                        marginLeft: 4,
                      }}
                    >
                      {hasActiveOrderCount}
                    </Tag>
                  </Button>
                </Col>

                {/* VIP Toggle */}
                <Col style={{ paddingLeft: 20 }}>
                  <span style={{ fontSize: 10 }}>VIP</span>
                  <br />
                  <Switch
                    large
                    disabled={loadingRemediationWorkOrders}
                    checked={VIPFilter}
                    onChange={() => setVIPFilter(!VIPFilter)}
                  />
                </Col>

                {/* Switch - New REM */}
                <Col style={{ paddingLeft: 10 }}>
                  <span style={{ fontSize: 10 }}>New REM</span>
                  <br />
                  <Tooltip
                    position="top"
                    content={
                      'Show all remediation work orders created today, and linked to either an Install or Service work order'
                    }
                    hoverOpenDelay={1400}
                  >
                    <Switch
                      disabled={loadingRemediationWorkOrders}
                      checked={REMFilter}
                      onChange={() => setREMFilter(!REMFilter)}
                      large
                    />
                  </Tooltip>
                </Col>

                {/* Switch - Unblocked */}
                <Col style={{ paddingLeft: 10 }}>
                  <span style={{ fontSize: 10 }}>Unblocked</span>
                  <br />
                  <Tooltip
                    position="top"
                    content={'Show only unblocked Remediation Work Orders'}
                    hoverOpenDelay={1400}
                  >
                    <Switch
                      disabled={loadingRemediationWorkOrders}
                      checked={unblockedFilter}
                      onChange={() => setUnblockedFilter(!unblockedFilter)}
                      large
                    />
                  </Tooltip>
                </Col>
              </Row>
            </Col>

            {/* Last Refreshed */}
            <Col span={4} style={{ textAlign: 'right' }}>
              <span style={{ fontSize: 10 }}>Last Refreshed</span>
              <br />
              <span style={{ opacity: 0.8, fontSize: '0.8em' }}>
                {getDurationSinceLastRefresh()}
              </span>
            </Col>

            {/* Filter tags */}
            <Col span={24}>
              {selectedTypeFilters?.map((type: string) => (
                <Tag round key={type} intent="primary" minimal>
                  <Icon icon="filter" size={12} style={{ marginRight: 5 }} />
                  <span>{type}</span>
                </Tag>
              ))}
            </Col>

            <Col
              span={24}
              style={{
                marginTop: 10,
                padding: 2,
                height: getViewportHeight(),
                overflowY: 'auto',
                overflowX: 'scroll',
                boxShadow: 'inset 20px 0 20px -20px #D5D6D8',
              }}
            >
              <HTMLTable
                className={'remediation-work-order-table'}
                bordered
                style={{
                  width: '100%',
                  border: '1px solid #D5D6D8',
                  fontSize: 12,
                }}
              >
                <thead>
                <tr>
                  {/* RAG Status */}
                  <th>
                    RAG{' '}
                    <TableColumnFilter
                      disabled={loadingRemediationWorkOrders}
                      options={getRAGStatusFilterOptions()}
                      selectedValues={selectedRAGFilters}
                      onChange={handleRAGStatusFilterChange}
                    />
                  </th>

                  {/* WO Type */}
                  <th>
                    Type{' '}
                    <TableColumnFilter
                      disabled={loadingRemediationWorkOrders}
                      options={getWorkOrderTypeFilterOptions()}
                      selectedValues={selectedTypeFilters}
                      onChange={handleTypeFilterChange}
                    />
                  </th>

                  {/* Work Order Number */}
                  <th>WO #</th>

                  {/* Description */}
                  <th>
                    Description
                    <TableColumnFilter
                      disabled={loadingRemediationWorkOrders}
                      options={getWorkOrderDescriptionFilterOptions()}
                      selectedValues={selectedDescriptionFilters}
                      onChange={handleDescriptionFilterChange}
                    />
                  </th>
                  {/* Stage */}
                  <th>
                    Stage{' '}
                    <TableColumnFilter
                      disabled={loadingRemediationWorkOrders}
                      options={workOrderStageList}
                      selectedValues={selectedStageFilters}
                      onChange={handleStageFilterChange}
                    />
                  </th>

                  {/* Scheduled Apt. */}
                  <th onClick={() => toggleSorting('ServiceAppointment')}>
                    Scheduled Apt.
                    <SortableColumnIcon sort={sortingParams['ServiceAppointment']} />
                  </th>

                  {/* SLA End Date */}
                  <th>SLA End Date</th>
                  <th>
                    <ColumnFilter
                      label="Team"
                      renderOptions={renderAssignedTeamFilterOptions}
                      hasFilters={assignedTeamFilter.selectedValues.length > 0}
                    />
                  </th>

                  {/* Owner */}
                  <th>
                    <ColumnFilter
                      label="Owner"
                      renderOptions={renderAssignedOwnerFilterOptions}
                      hasFilters={assignedOwnerFilter.selectedValues.length > 0}
                      onClose={clearFilterSearchQuery}
                    />
                  </th>

                  {/* Engineer */}
                  <th>
                    <ColumnFilter
                      label="Engineer"
                      renderOptions={renderAssignedEngineerFilterOptions}
                      hasFilters={assignedEngineerFilter.selectedValues.length > 0}
                      onClose={clearFilterSearchQuery}
                    />
                  </th>

                  {/* Activity */}
                  <th onClick={() => toggleSorting('LatestProcessActivityDate')}>
                    <span>Activity</span>
                    <SortableColumnIcon sort={sortingParams['LatestProcessActivityDate']} />
                  </th>
                  <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                {loadingRemediationWorkOrders ? (
                  <tr>
                    <Spinner />
                  </tr>
                ) : (
                  tableData?.map((wo: DbRecordEntityTransform) => (
                    <tr key={wo.id} className={getClassNameForRemediationRow(wo)}>
                      <td style={{ textAlign: 'left' }}>
                        {/* RAG - First row */}
                        <Tag intent={getCaseIntent(wo)}>{getProperty(wo, 'SLARagStatus')}</Tag>
                        {(getAllRelations(wo, 'ChangeReason') || [])?.length > 1 && (
                          <Icon
                            icon="issue"
                            color="red"
                            size={14}
                            style={{ paddingLeft: 4, paddingBottom: 1 }}
                          />
                        )}
                        {wo?.properties?.IsVIP && (
                          <Icon
                            icon="star"
                            color="orange"
                            size={14}
                            style={{ paddingLeft: 4, paddingBottom: 1 }}
                          />
                        )}
                      </td>

                      {/* Type */}
                      <td>{wo.type}</td>

                      {/* Record Number */}
                      <td>{wo.recordNumber}</td>

                      {/* Description */}
                      <td style={{ width: '15%' }}>
                        <div>
                          <strong>
                            {capitalCase(getProperty(wo, 'InstallRequiringActionReason') || '')}
                          </strong>
                          <p style={{ fontSize: 12 }}>{wo.title}</p>
                          <div>
                            <div>
                              {getLinkedWorkOrders(wo)?.length > 0 && (
                                <h5 style={{ color: 'red', marginBottom: 5 }}>BLOCKED BY</h5>
                              )}
                              <Row>
                                {getLinkedWorkOrders(wo)?.map((elem: any, i: number) => (
                                  <Col
                                    span={24}
                                    style={{
                                      background: '#f1eae4',
                                      border:
                                        getProperty(wo, 'Status') === 'ON_HOLD'
                                          ? '1px solid #ff9592'
                                          : 'transparent',
                                      padding: 8,
                                      borderRadius: 6,
                                      marginBottom:
                                        i === getLinkedWorkOrders(wo).length - 1 ? 0 : 10,
                                      fontSize: 10,
                                    }}
                                  >
                                    <div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          marginBottom: 8,
                                          fontWeight: 500,
                                        }}
                                      >
                                        <a onClick={() => handleViewWorkOrder(elem)}>
                                            <span
                                              style={{
                                                fontWeight: 500,
                                              }}
                                            >
                                              [{elem?.type}]
                                            </span>{' '}
                                          <span>{elem?.recordNumber}</span>
                                        </a>
                                      </div>
                                      <div style={{ display: 'flex' }}>
                                        <div
                                          style={{
                                            marginRight: 14,
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                            <span style={{ fontWeight: 500, marginBottom: 3 }}>
                                              RAG:
                                            </span>
                                          <span>
                                              <Tag intent={getCaseIntent(elem)}>
                                                {getProperty(elem, 'SLARagStatus') || 'Unknown'}
                                              </Tag>
                                            </span>
                                        </div>
                                        <div
                                          style={{
                                            marginRight: 14,
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                            <span style={{ fontWeight: 500, marginBottom: 3 }}>
                                              SLA Date:
                                            </span>
                                          <span>
                                              {getProperty(elem, 'SLAEndDate')
                                                ? dayjs(getProperty(elem, 'SLAEndDate')).format(
                                                  'DD/MM/YYYY',
                                                )
                                                : 'N/A'}
                                            </span>
                                        </div>
                                        <div>
                                          <div
                                            style={{
                                              marginRight: 14,
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                              <span style={{ fontWeight: 500, marginBottom: 3 }}>
                                                Stage:
                                              </span>
                                            <TableCellStageTag record={elem} small>
                                              {elem.stage?.name}
                                            </TableCellStageTag>
                                          </div>
                                        </div>
                                        <div>
                                          <div
                                            style={{
                                              marginRight: 14,
                                              display: 'flex',
                                              flexDirection: 'column',
                                            }}
                                          >
                                              <span style={{ fontWeight: 500, marginBottom: 3 }}>
                                                Owner:
                                              </span>
                                            <span>{getProperty(elem, 'OwnerName')}</span>
                                          </div>
                                        </div>
                                        <div
                                          style={{
                                            marginRight: 14,
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                            <span style={{ fontWeight: 500, marginBottom: 3 }}>
                                              Last Update:
                                            </span>
                                          <span>
                                              {dayjs(elem.updatedAt).format('DD/MM/YYYY')}
                                            </span>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                ))}
                              </Row>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style={{ verticalAlign: 'middle' }}>
                        <Row justify="center" align="middle">
                          <Col span={23}>
                            <TableCellStageTag record={wo} truncate={20}>
                              {wo.stage?.name}
                            </TableCellStageTag>
                          </Col>
                        </Row>
                      </td>
                      <td style={{ textAlign: 'center' }}>
                        {!getProperty(getFirstRelation(wo, 'ServiceAppointment'), 'Date') ? (
                          <span>NO APT.</span>
                        ) : (
                          dayjs(
                            getProperty(getFirstRelation(wo, 'ServiceAppointment'), 'Date'),
                          ).format('DD/MM/YYYY')
                        )}
                      </td>
                      <td>{dayjs(getProperty(wo, 'SLAEndDate')).format('DD/MM/YYYY')}</td>
                      <td>{getProperty(wo, 'TeamName')}</td>
                      <td>{getProperty(wo, 'OwnerName')}</td>
                      <td>{getProperty(wo, 'EngineerName')}</td>
                      {/* Last process activity date / comment */}
                      <td>
                        {getProperty(wo, 'LatestProcessActivityDate')
                          ? dayjs(getProperty(wo, 'LatestProcessActivityDate')).format(
                            'MM/DD/YYYY',
                          )
                          : ''}
                        {getProperty(wo, 'LatestProcessActivityComment') && (
                          <Popover
                            disabled={
                              getProperty(wo, 'LatestProcessActivityComment')?.length! <
                              LAST_ACTIVITY_COMMENT_TRUNC_SIZE
                            }
                            content={
                              <Row style={{ padding: 15, maxWidth: 300 }}>
                                <Col span={24}>
                                  {getProperty(wo, 'LatestProcessActivityComment')}
                                </Col>
                              </Row>
                            }
                            position="left"
                            fill
                          >
                            <div
                              className={`lastProcessComment ${
                                getProperty(wo, 'LatestProcessActivityComment')?.length! >=
                                LAST_ACTIVITY_COMMENT_TRUNC_SIZE
                                  ? 'clickable'
                                  : ''
                              }`}
                              style={{
                                cursor:
                                  getProperty(wo, 'LatestProcessActivityComment')?.length! >=
                                  LAST_ACTIVITY_COMMENT_TRUNC_SIZE
                                    ? 'pointer'
                                    : 'auto',
                              }}
                            >
                              <i
                                className="bi bi-chat-left-text"
                                style={{ marginRight: 3, color: '#184A90', width: 3 }}
                              />

                              {truncateComment(getProperty(wo, 'LatestProcessActivityComment'))}
                            </div>
                          </Popover>
                        )}
                      </td>

                      {/* Actions */}
                      <td style={{ textAlign: 'right' }}>
                        <Button rightIcon="eye-open" onClick={() => handleViewWorkOrder(wo)} />
                      </td>
                    </tr>
                  ))
                )}
                </tbody>
              </HTMLTable>
            </Col>
            <Col span={24} style={{ textAlign: 'right', marginTop: 15 }}>
              <Pagination
                size="small"
                disabled={loadingRemediationWorkOrders || areFiltersApplied()}
                current={currentPage}
                pageSize={pageSize}
                total={filteredWorkOrders.length}
                onChange={onPaginationChange}
                pageSizeOptions={['80', '100', '200', '500']}
                onShowSizeChange={onShowSizeChange}
                showSizeChanger
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
  getSchema: (params: any, cb: any) => dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(WorkOrderRemediationView);
