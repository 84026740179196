import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Layout, Tabs } from 'antd';
import Title from 'antd/lib/typography/Title';
import React from 'react';
import { connect } from 'react-redux';

import { httpGet, httpPost } from '../../../../../../core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import PianoiForm from '../NoticeOfIntent/PianoiForm';
import { FeatureList } from './FeatureList';

interface Props {
  alertMessage: any;
}

interface State {
  isLoading: boolean;
  noiRef: string;
  noiRefData: any;
  expandedErrors: string[];
  expandedSubmitted: string[];
  expandedUnsubmitted: string[];
}

const { TabPane } = Tabs;

class BulkBuildComplete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isLoading: false,
      noiRef: '',
      noiRefData: null,
      expandedErrors: [],
      expandedSubmitted: [],
      expandedUnsubmitted: [],
    };
  }

  fetchPianoi = async (noiRef: string) => {
    const { alertMessage } = this.props;
    this.setState({ isLoading: true, noiRef });

    await httpGet(`ProjectModule/v1.0/openreach/buildcomplete/bulk/${noiRef}`)
      .then((res) => {
        if (res.data.data && res.data.data.error) {
          alertMessage({
            body: res.data.data.message,
            type: 'error',
          });
          this.setState({ isLoading: false });
        } else {
          this.setState({ noiRefData: res.data.data, isLoading: false });
        }
      })
      .catch((err) => {
        const error = err.response ? err.response.data : undefined;
        console.log('ERROR_MESSAGE', error);
        alertMessage({
          body: (error && error.message) || 'error processing request',
          type: 'error',
        });

        console.error('Error while fetching: ', err);
        this.setState({ isLoading: false });
      });
  };

  submitFeatures = async () => {
    const { alertMessage } = this.props;
    const { noiRef, noiRefData } = this.state;
    const unsubmitted: DbRecordEntityTransform[] = noiRefData?.unsubmitted ?? [];
    const unsubmittedIds = unsubmitted.map(feature => feature.id);

    this.setState({ isLoading: true });
    const result = await httpPost(`ProjectModule/v1.0/openreach/buildcomplete/bulk/${noiRef}`, { unsubmitted: unsubmittedIds })
      .then(res => {
        if (res.data.data.errors.length) {
          alertMessage({
            body: 'Some items failed submission.',
            type: 'error',
          });
        } else {
          alertMessage({
            body: 'All items submitted!',
            type: 'success',
          });
        }
        return res.data.data;
      })
      .catch(error => {
        alertMessage({
          body: (error && error.message) || 'error submitting data',
          type: 'error',
        });
      });
    await this.fetchPianoi(noiRef);
    this.setState({ isLoading: false });
    return result;
  };

  render() {
    const {
      isLoading,
      noiRefData,
    } = this.state;

    return (
      <Layout
        style={{
          padding: 8,
          border: '1px solid #dadada',
          background: '#fafafa',
          overflow: 'auto',
        }}
      >
        <Title level={2}>Build Complete</Title>

        <PianoiForm isLoading={isLoading} onSubmit={this.fetchPianoi} />

        <Tabs defaultActiveKey="errors" className="scroll-tabs" type="card">
          <TabPane tab={`Errors ${Array.isArray(noiRefData?.errors) ? `(${noiRefData.errors.length})` : ''}`}
                   key="errors">
            <FeatureList features={noiRefData?.errors} />
          </TabPane>
          <TabPane
            tab={`Unsubmitted ${Array.isArray(noiRefData?.unsubmitted) ? `(${noiRefData.unsubmitted.length})` : ''}`}
            key="unsubmitted">
            <FeatureList
              features={noiRefData?.unsubmitted}
              extraActions={
                <Button
                  type="primary"
                  disabled={!noiRefData?.unsubmitted?.length}
                  onClick={this.submitFeatures}
                >
                  Submit
                </Button>
              }
            />
          </TabPane>
          <TabPane tab={`Submitted ${Array.isArray(noiRefData?.submitted) ? `(${noiRefData.submitted.length})` : ''}`}
                   key="submitted">
            <FeatureList features={noiRefData?.submitted} />
          </TabPane>
        </Tabs>
      </Layout>
    );
  }
}

const mapState = (_state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(BulkBuildComplete);
  