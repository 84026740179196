import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

const AFP_APPROVAL_LOAD_ALL_FPES_REQUEST = 'AFP_APPROVAL_LOAD_ALL_FPES_REQUEST';
const AFP_APPROVAL_LOAD_ALL_FPES_RESPONSE = 'AFP_APPROVAL_LOAD_ALL_FPES_RESPONSE';
const AFP_APPROVAL_LOAD_ALL_FPES_ERROR = 'AFP_APPROVAL_LOAD_ALL_FPES_ERROR';

type LoadAllFpesRequest = {
  type: typeof AFP_APPROVAL_LOAD_ALL_FPES_REQUEST;
  payload: undefined;
};

type LoadAllFpesResponse = {
  type: typeof AFP_APPROVAL_LOAD_ALL_FPES_RESPONSE;
  payload: DbRecordEntityTransform[];
};

type LoadAllFpesError = {
  type: typeof AFP_APPROVAL_LOAD_ALL_FPES_ERROR;
  payload: { message: string };
};

export type LoadAllFpesActions = LoadAllFpesRequest | LoadAllFpesResponse | LoadAllFpesError;

export const loadAllFpesRequest = (): LoadAllFpesRequest => ({
  type: AFP_APPROVAL_LOAD_ALL_FPES_REQUEST,
  payload: undefined,
});

export const loadAllFpesResponse = (payload: DbRecordEntityTransform[]): LoadAllFpesResponse => ({
  type: AFP_APPROVAL_LOAD_ALL_FPES_RESPONSE,
  payload,
});

export const loadAllFpesError = (payload: { message: string }): LoadAllFpesError => ({
  type: AFP_APPROVAL_LOAD_ALL_FPES_ERROR,
  payload,
});
