import { Col, Row, Tag } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { SlotInfo, useCalendarState } from './hooks/useCalendarState';

type DateAvailabilityProps = {
  slotInfo: SlotInfo;
  calendarService: ReturnType<typeof useCalendarState>['service'];
};

const styles: Record<string, React.CSSProperties> = {
  entry: {
    marginTop: 2,
    lineHeight: isMobile ? 0 : undefined,
  },

  titleTag: {
    fontSize: isMobile ? '0.7em' : undefined,
    padding: isMobile ? '2px 4px' : undefined,
    margin: isMobile ? 0 : undefined,
    lineHeight: isMobile ? 1 : undefined,
    minWidth: isMobile ? 20 : 25,
    width: isMobile ? 20 : 40,
    marginRight: 3,
  },

  valueTag: {
    fontSize: isMobile ? '0.7em' : undefined,
    padding: isMobile ? '2px 2px' : undefined,
    margin: isMobile ? 0 : undefined,
    lineHeight: isMobile ? 1 : undefined,
    minWidth: isMobile ? 20 : 40,
    width: isMobile ? 20 : 45,
    textAlign: 'center',
  },
};

export const DateAvailability = ({ slotInfo, calendarService }: DateAvailabilityProps) => {
  const { date, AMScheduled, AMTotal, PMScheduled, PMTotal, Utilization } = slotInfo;
  const timeBlocks = ['AM', 'PM'];

  return (
    <Row
      style={{ marginTop: 0, overflow: isMobile ? 'hidden' : undefined }}
      className={`{dayCellBackground} dayCell`}
      onClick={() => calendarService.startSlotEdit(slotInfo)}
    >
      <Col span={24}>
        <div>
          <ul className="events">
            {timeBlocks.map((timeBlock) => (
              <li key={`${date}_${timeBlock}`} style={styles.entry}>
                <Tag style={styles.titleTag} className={`${timeBlock.toLocaleLowerCase()}TimeSlot`}>
                  {timeBlock}
                </Tag>
                <Tag style={styles.valueTag} className="timeSlotCount">
                  {timeBlock === 'AM' ? `${AMScheduled}/${AMTotal}` : `${PMScheduled}/${PMTotal}`}
                </Tag>
              </li>
            ))}
            <li style={styles.entry}>
              <Tag style={styles.titleTag} className={`utilizationTimeSlot`}>
                <span title="Utilization">Util.</span>
              </Tag>
              <Tag style={styles.valueTag} className="timeSlotCount">
                {Utilization?.toFixed(0)}%
              </Tag>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
};
