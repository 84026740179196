import { Col, Layout, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import DetailPanelLeft from '@legacy/core/records/components/DetailPanelLeft';
import RawDataRecordProperties from '@legacy/core/records/components/RawData/RawDataRecordProperties';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { renderDynamicAssociationTables } from '@legacy/core/recordsAssociations/helpers/component-helpers';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import CardWithTabs from '../../../components/CardWithTabs';
import { renderCreateUpdateDetails } from '../../../components/RecordCreateUpdateDetails';
import {
  getAllSchemaAssociationSchemas,
  getRecordFromShortListById,
} from '@core/helpers/recordHelpers';
import {
  getJSONColumnsFromSchema,
  getSchemaFromShortListBySchemaId,
} from '@core/helpers/schemaHelpers';

interface Props {
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  match: any;
}

class OfferDetailView extends React.Component<Props> {
  render() {
    const { schemaReducer, recordReducer, match } = this.props;
    const record = getRecordFromShortListById(recordReducer.shortList, match.params.recordId);
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    const relatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, ['Note']);

    const JSONColumns = getJSONColumnsFromSchema(schema);

    return (
      <Layout className="record-detail-view">
        <Row gutter={{ xs: 8, sm: 14, md: 14, lg: 14 }}>
          <Col xs={24} sm={24} md={24} lg={6}>
            <div className="record-detail-left-panel">
              <DetailPanelLeft record={record}>
                <RecordProperties columns={1} record={record} size="small" />
                {renderCreateUpdateDetails(record)}
              </DetailPanelLeft>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={18}>
            <div className="record-detail-left-panel">
              <CardWithTabs
                title="Options"
                defaultTabKey="Related"
                tabList={[
                  // {
                  //   key: 'Activity',
                  //   tab: 'Activity',
                  // },
                  {
                    key: JSONColumns?.length > 0 ? 'JSON' : '',
                    tab: JSONColumns?.length > 0 ? 'JSON' : '',
                  },
                ]}
                tabContents={{
                  // Activity: <ActivityCenter record={record!} schema={schema!} hideEmailTab />,
                  JSON: <RawDataRecordProperties record={record!} schema={schema!} />,
                }}
                relatedAssociations={renderDynamicAssociationTables(record, relatedSchemas)}
              />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

export default withRouter(connect(mapState)(OfferDetailView));
