import { SchemaAssociationEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity";

export interface BaseFormProps {
  link?: SchemaAssociationEntity;

  schemaProps: {
    value: TPanelDataItem | null;
    onChange: (value: TPanelDataItem | null) => void;
    initialValue?: TPanelDataItem | null;
  };

  cardinalityProps: {
    value: Cardinality;
    onChange: (value: Cardinality) => void;
  };

  actionsProps: {
    value: Action;
    onChange: (value: Action) => void;
  };
}

export type TPanelDataItem = {
  id: string;
  name: string;
  description: string;
  status?: string;
};

export type Cardinality = "One" | "Many";
export type Action = 'LOOKUP_AND_CREATE' | 'LOOKUP_ONLY' | 'CREATE_ONLY' | 'READ_ONLY';

export const ActionLabel: Record<Action, string> = {
  LOOKUP_AND_CREATE:  'Look up and create',
  LOOKUP_ONLY: 'Look up only',
  CREATE_ONLY: 'Create only',
  READ_ONLY: 'Read only',
};
