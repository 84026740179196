import { DbRecordAssociationCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Button, Checkbox, Col, Divider, List, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '../Forms/CoreForm';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '@core/helpers/schemaHelpers';
import {
  getDefaultFields,
  setSearchQuery,
  setSortQuery,
} from '@core/helpers/searchHelpers';
import { listUsers } from '../../../identity/store/actions';
import { getPipelinesByModuleAndEntity } from '../../../pipelines/store/actions';
import { IRecordAssociationsReducer } from '../../../recordsAssociations/store/reducer';
import { TableReducer } from '../DynamicTable/store/reducer';
import RecordSearch from '../RecordSearch';
import RecordAssociationSearch from '../../../recordsAssociations/components/Search';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../recordsAssociations/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import { ISearchRecords, searchRecordsRequest, setDbRecordSearchQuery } from '../../store/actions';
import { IRecordReducer } from '../../store/reducer';
import { initializeRecordForm } from '../Forms/store/actions';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { getRecordListFromShortListById } from '@core/helpers/recordHelpers';
import { Schema } from 'inspector';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '../../../schemas/store/actions';

interface Props {
  record?: DbRecordEntityTransform;
  entityName: string;
  moduleName: string;
  recordTableReducer: TableReducer;
  recordAssociationReducer?: IRecordAssociationsReducer;
  isNextDisabled?: Function;
  checkboxItemSelect: (item: DbRecordEntityTransform) => void;
  getAssociations: any;
  setSchemaAssociationData?: any;
  initializeForm: any;
  getUsers: any;
  getPipelines: any;
  associations?: DbRecordAssociationCreateUpdateDto[];
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  searchRecords: any;
  skipAssociationSelect?: any;
  lookupCreateComponent?: boolean;
  preselectedItem?: string;
  getSchema: Function;
}

interface State {
  selectedItem: any;
  schemaAssociation: any;
  schema: SchemaEntity | undefined;
  premiseSchema: any;
  isLoaded: boolean;
}

const uuid = uuidv4();

const { ADDRESS, DISCOUNT } = SchemaModuleEntityTypeEnums;

class LookUpCreateLegacy extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedItem: {},
      schemaAssociation: undefined,
      schema: undefined,
      premiseSchema: undefined,
      isLoaded: false,
    };
  }

  componentDidMount() {
    const { moduleName, entityName, getSchema } = this.props;

    getSchema({ moduleName, entityName }, (response: SchemaEntity) => {
      if (response) {
        this.fetchData();
      }
    });
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.entityName !== this.props.entityName) {
      this.fetchData();
    }
    if (this.props.entityName === DISCOUNT) {
      const schema = getSchemaFromShortListByModuleAndEntity(
        this.props.schemaReducer.shortList,
        this.props.moduleName,
        this.props.entityName,
      );
      if (
        schema &&
        getRecordListFromShortListById(this.props.recordReducer.list, schema.id) &&
        !this.state.isLoaded
      ) {
        this.preselectDiscountListItem();
      }
    }
  }

  preselectDiscountListItem() {
    const {
      recordReducer,
      schemaReducer,
      moduleName,
      entityName,
      preselectedItem,
      checkboxItemSelect,
    } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (schema) {
      const listItems = getRecordListFromShortListById(recordReducer.list, schema.id);
      const preselectedDiscount = listItems?.find(
        (item) => getProperty(item, 'Code') === preselectedItem,
      );
      checkboxItemSelect(preselectedDiscount as DbRecordEntityTransform);
      this.setState({
        selectedItem: { id: preselectedDiscount?.id },
        isLoaded: true,
      });
    }
  }

  loadInitialList() {
    const { schemaReducer, recordReducer, moduleName, entityName, searchRecords } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          terms: setSearchQuery(schemaReducer, recordReducer, moduleName, entityName),
          fields: getDefaultFields(schema, moduleName, entityName),
          schemas: schema.id,
          sort: setSortQuery(schemaReducer, recordReducer, moduleName, entityName),
          boolean: undefined,
        },
      });
    }
  }

  fetchData() {
    const {
      moduleName,
      record,
      entityName,
      getAssociations,
      setSchemaAssociationData,
      lookupCreateComponent,
      schemaReducer,
    } = this.props;

    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );

    if (schema) {
      this.setState({ schema: schema });

      if (entityName === 'Premise') {
        this.setState({
          premiseSchema: schema,
        });
        this.loadInitialList();
      } else if (lookupCreateComponent) {
        this.loadInitialList();
      } else {
        const recordSchema = getSchemaFromShortListBySchemaId(
          schemaReducer.shortList,
          record?.schemaId,
        );
        getAssociations(
          {
            recordId: record?.id,
            key: entityName,
            schema: recordSchema,
            entities: [entityName],
          },
          (schemaAssociation: any) => {
            this.setState({
              schemaAssociation: schemaAssociation?.results?.[entityName],
            });

            if (setSchemaAssociationData) {
              setSchemaAssociationData({
                schemaAssociation: schemaAssociation?.results?.[entityName],
              });
            }
          },
        );
      }
    }
  }

  async initializeCreateForm() {
    const { initializeForm, getUsers, getPipelines, associations } = this.props;

    getUsers();

    if (this.state.schema) {
      getPipelines({ schema: this.state.schema });

      initializeForm({
        formUUID: uuid,
        title: 'Initializing',
        showInitializing: true,
      });

      initializeForm({
        formUUID: uuid,
        title: `Create ${this.state.schema.entityName}`,
        showFormModal: true,
        isCreateReq: true,
        schema: this.state.schema,
        selected: null,
        sections: [{ name: this.state.schema.name, schema: this.state.schema }],
        modified: [
          {
            schemaId: this.state.schema.id,
            associations: associations,
          },
        ],
      });
    } else {
      return initializeForm({
        title: 'Create',
        showFormModal: true,
      });
    }
  }

  private renderListItemTitle(item: DbRecordEntityTransform) {
    if (item.title && item.recordNumber) {
      return `${item.recordNumber} ${item.title}`;
    } else if (item.title && !item.recordNumber) {
      return item.title;
    }
  }

  addRemoveItem(item: DbRecordEntityTransform) {
    const { isNextDisabled, checkboxItemSelect } = this.props;
    this.setState({
      selectedItem: item,
    });
    if (isNextDisabled) {
      isNextDisabled(false);
    }
    checkboxItemSelect(item);
  }

  isChecked(item: any) {
    const { entityName } = this.props;
    if (entityName === 'Premise') {
      if (item?.properties?.id === this.state.selectedItem?.properties?.id) {
        return true;
      } else {
        return false;
      }
    } else {
      if (item.id === this.state.selectedItem?.id) {
        return true;
      } else {
        return false;
      }
    }
  }

  renderRelatedRecordsList = () => {
    const { recordAssociationReducer, entityName, recordReducer, lookupCreateComponent } =
      this.props;
    return (
      <>
        <List
          style={{ height: '400px', overflow: 'scroll', width: '100%' }}
          loading={recordAssociationReducer?.isSearching || recordReducer?.isSearching}
          itemLayout="horizontal"
          dataSource={
            entityName === 'Premise'
              ? recordReducer?.list[this.state.premiseSchema?.id]
                ? recordReducer?.list[this.state.premiseSchema?.id]
                : []
              : lookupCreateComponent && this.state.schema
              ? recordReducer?.list[this.state.schema.id]
              : (recordAssociationReducer?.list as any[])
          }
          renderItem={(item: DbRecordEntityTransform) => (
            <List.Item
              actions={[
                <Checkbox
                  checked={this.isChecked(item) ? true : false}
                  onChange={(e: any) => this.addRemoveItem(item)}
                >
                  Add
                </Checkbox>,
              ]}
            >
              <List.Item.Meta
                title={this.renderListItemTitle(item)}
                description={getProperty(item, 'Description')}
              />
            </List.Item>
          )}
        />
      </>
    );
  };

  private handleFormSubmit(params: { event: string; results: DbRecordEntityTransform }) {
    const { skipAssociationSelect } = this.props;
    skipAssociationSelect(params.results);
  }

  render() {
    const { record, entityName, recordAssociationReducer, moduleName, lookupCreateComponent } =
      this.props;
    return (
      <div>
        <CoreForm
          type="MODAL"
          formUUID={uuid}
          onSubmitEvent={(params: { event: string; results: any }) => this.handleFormSubmit(params)}
        />
        <Row>
          <Col span={24}>
            {this.state.schemaAssociation !== undefined ? (
              <RecordAssociationSearch
                record={record}
                relation={this.state.schemaAssociation}
                hideActions
              />
            ) : entityName === 'Premise' || lookupCreateComponent ? (
              <RecordSearch moduleName={moduleName} entityName={entityName} noReset hideFilters />
            ) : (
              <></>
            )}
            {(recordAssociationReducer?.search !== null &&
              recordAssociationReducer?.search?.terms !== '*') ||
            (lookupCreateComponent && entityName !== ADDRESS) ? (
              <Button
                onClick={() => {
                  this.initializeCreateForm();
                }}
              >
                Create {entityName}
              </Button>
            ) : (
              <></>
            )}
            <Divider />
          </Col>
          {this.renderRelatedRecordsList()}
        </Row>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  recordAssociationReducer: state.recordAssociationReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordTableReducer: state.recordTableReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  setSearchQuery: (params: ISearchRecords) => dispatch(setDbRecordSearchQuery(params)),
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(LookUpCreateLegacy);
