import { QGISCableTypeEnum } from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { GraphDisplayMode } from './store/types';

export const highlightGraphChain = (model: any, graphContainer: any) => {
  if (model && graphContainer && model.loopChainId) {
    const matchedEdges = graphContainer?.findAll('edge', (edge: any) => {
      return edge.get('model').loopChainId !== model.loopChainId;
    });

    if (matchedEdges?.length > 0) {
      matchedEdges.map((edge: any) => {
        graphContainer?.updateItem(edge, {
          style: {
            opacity: 0.1,
          },
          labelCfg: {
            style: {
              opacity: 0.1,
            },
          },
        });
      });
    }
  }
};

export const resetGraphChainsHighlight = (graphContainer: any) => {
  const allEdges = graphContainer?.getEdges();

  if (allEdges?.length > 0) {
    allEdges.map((edge: any) => {
      graphContainer?.updateItem(edge, {
        style: {
          opacity: 1,
        },
        labelCfg: {
          style: {
            opacity: 1,
          },
        },
      });
    });
  }
};

export const copyTextToClipboard = (text: any, message: string, alertMessage: Function) => {
  navigator.clipboard.writeText(text);
  alertMessage({ body: message, type: 'success' });
};

export const getTemplateVersionFromPathName = (pathname: string) => {
  if (pathname) {
    let version = pathname.split('/')[3];
    version = version.split('-')[3];
    return version;
  } else {
    return '';
  }
};

export const constructCableTitle = (
  edge: any,
  loopNumber: string | undefined,
  graphDisplayMode: GraphDisplayMode,
) => {
  const renderLoopData = (loopNumber: string | undefined) => {
    if (loopNumber && loopNumber !== '0') {
      return `LOOP ${loopNumber} - `;
    } else {
      return '';
    }
  };

  if (graphDisplayMode === 'PLAN') {
    return `${String(edge.cable)} - ${QGISCableTypeEnum[edge.cableType]} - ${renderLoopData(
      loopNumber,
    )} ${edge.cableLength ? String(edge.cableLength.toFixed(2) + 'm') : ''}`;
  } else {
    return `${String(edge.cable)} - ${QGISCableTypeEnum[edge.cableType]} - ${renderLoopData(
      loopNumber,
    )}BS:${edge.cableBuildStatus || 'none'} - ${
      edge.cableLength ? String(edge.cableLength.toFixed(2) + 'm') : ''
    }`;
  }
};

export const getPlanClosureStyle = (ClosureType: string) => {
  switch (ClosureType) {
    case 'L0':
      return {
        fill: '#ff0000',
        color: '#fff',
        stroke: '#000',
      };
    case 'L1':
      return {
        fill: '#0000ff',
        color: '#fff',
        stroke: '#000',
      };
    case 'L2':
      return {
        fill: '#00ff00',
        color: '#000',
        stroke: '#000',
      };
    case 'L3':
      return {
        fill: '#ffff00',
        color: '#000',
        stroke: '#000',
      };
    case 'L4':
      return {
        fill: '#ffa500',
        color: '#000',
        stroke: '#000',
      };
    case 'LM':
      return {
        fill: '#ff00ff',
        color: '#fff',
        stroke: '#000',
      };
    case 'LC':
      return {
        fill: '#00d0ff',
        color: '#000',
        stroke: '#000',
      };
    default:
      return { fill: '#515151', color: '#fff', stroke: '#000' };
  }
};

export const getBuildClosureStyle = (BuildStatus: number) => {
  switch (BuildStatus) {
    case 9:
      return {
        fill: '#7e7e7e',
        color: '#fff',
        stroke: '#000',
      };
    case 1:
      return {
        fill: '#f254a8',
        color: '#fff',
        stroke: '#000',
      };
    case 2:
      return {
        fill: '#de1520',
        color: '#fff',
        stroke: '#000',
      };
    case 3:
      return {
        fill: '#fd721e',
        color: '#fff',
        stroke: '#000',
      };
    case 4:
      return {
        fill: '#ffdc39',
        color: '#000',
        stroke: '#000',
      };
    case 5:
      return {
        fill: '#c2fd37',
        color: '#000',
        stroke: '#000',
      };
    case 6:
      return {
        fill: '#319431',
        color: '#fff',
        stroke: '#000',
      };
    case 7:
      return {
        fill: '#15cbfc',
        color: '#000',
        stroke: '#000',
      };
    case 8:
      return {
        fill: '#0022f9',
        color: '#fff',
        stroke: '#000',
      };
    default:
      return { fill: '#000000', color: '#fff', stroke: '#000' };
  }
};

export const getPlanCableColor = (cableType: string, loopChainId?: number) => {
  switch (cableType) {
    case 'Spine':
      return '#ff0000';
    case 'Distribution':
      return '#0000ff';
    case 'Access':
      return '#00ff00';
    case 'Feed':
      return '#e61ee0';
    case 'Temp':
      return '#00d0ff';
    case 'Cablelink':
      return '#1f78b4';
    default:
      return '#000';
  }
};

export {};
