import React from 'react';
import { Button, Col, Input, Layout, Row, Select, Table } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { CSVLink } from 'react-csv';
import dayjs from 'dayjs';

import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';

import { IRecordReducer } from '@legacy/core/records/store/reducer';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { httpGet, httpPost } from '@core/http/requests';
import { PageHeader } from '../../../components/PageHeader';
import { displayMessage } from '@legacy/core/messages/store/reducers';

const { Option } = Select;
const { Search } = Input;
const { Content } = Layout;

const columns = [
  {
    title: 'Address',
    dataIndex: 'order_title',
    key: 'order_title',
  },
  {
    title: 'First',
    dataIndex: 'contact_first_name',
    key: 'contact_first_name',
  },
  {
    title: 'Last',
    dataIndex: 'contact_last_name',
    key: 'contact_last_name',
  },
  {
    title: 'Email',
    dataIndex: 'contact_email_address',
    key: 'contact_email_address',
  },
  {
    title: 'Phone',
    dataIndex: 'contact_phone',
    key: 'contact_phone',
  },
  {
    title: 'L2 Polygon',
    dataIndex: 'l2_polygon_id',
    key: 'l2_polygon_id',
  },
  {
    title: 'Olt IP',
    dataIndex: 'c_ont_oltip',
    key: 'c_ont_oltip',
  },
  {
    title: 'ONT Serial',
    dataIndex: 'c_ont_serial',
    key: 'c_ont_serial',
  },
  {
    title: 'Router Serial',
    dataIndex: 'c_router_serial',
    key: 'c_router_serial',
  },
  {
    title: 'ONT Status',
    dataIndex: 'c_ont_oper_status',
    key: 'c_ont_oper_status',
  },
  {
    title: 'Last Check',
    dataIndex: 'c_ont_check_date',
    key: 'c_ont_check_date',
  },
];

interface Props {
  recordReducer: IRecordReducer;
  match: any;
  schemaReducer: any;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface State {
  isLoading: boolean;
  filterKey: 'exPolygonId' | 'l2PolygonId' | 'oltIp';
  filterValue: string | null;
  data: IOutageData[];
}

export interface IOutageData {
  order_id: string;
  order_title: string;
  order_stage: string;
  ex_polygon_id: string;
  l2_polygon_id: string;
  provider: string;
  order_item_id: string;
  order_item_title: string;
  ont_id: string;
  router_id: string;
  c_ont_oltip: string;
  c_ont_oper_status: string;
  c_ont_check_date: string;
  c_ont_serial: string;
  c_router_serial: string;
  contact_id: string;
  contact_first_name: string;
  contact_last_name: string;
  contact_email_address: string;
  contact_phone: string;
}

class OutageList extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      filterKey: 'l2PolygonId',
      filterValue: null,
      data: [],
    };
  }

  private async onSubmit() {
    this.setState({
      isLoading: true,
    });
    // fiberConflict
    await httpGet(
      `ServiceModule/v1.0/network/reporting/active-customers-outage?${this.state.filterKey}=${this.state.filterValue}`,
    )
      .then(async (res) => {
        this.setState({
          isLoading: false,
          data: res.data.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log('err.response.data', err.response.data);
          alert(err.response.data?.message);
          this.setState({
            isLoading: false,
          });
        } else {
          alert('error processing your request.');
          this.setState({
            isLoading: false,
          });
        }
      });
  }

  private async handleCreateCase() {
    const { alertMessage } = this.props;

    const caseDto: DbRecordCreateUpdateDto = {
      entity: 'SupportModule:Case',
      type: 'OUTAGE',
      title: `Outage on ${this.state.filterKey} ${this.state.filterValue}`,
      properties: {
        // BusinessArea: 'NETWORK',
        Description: `Outage on ${this.state.filterKey} ${this.state.filterValue}`,
        Impact: 'HIGH',
        Origin: 'YOUFIBRE'
      }
    }

    const filter = `${this.state.filterKey}=${this.state.filterValue}`;

    try  {
      await httpPost(`ServiceModule/v1.0/network/outage/case?${filter}`, caseDto);
      alertMessage({ body: 'Case Created!', type: 'success' });
    } catch(err: any) {
      alertMessage({ body: err.message, type: 'error' })
    }
  }

  render() {
    return (
      <Layout style={{ padding: 20 }}>
        <Content>
          <PageHeader
            style={{ borderRadius: 6, border: '1px solid #dddbda' }}
            title={
              <Row>
                <Col style={{ marginTop: 8 }}>
                  <ModuleEntityIcon
                    moduleName={'ServiceModule'}
                    entityName="Outage"
                    style={{ padding: '0px 2px' }}
                  />
                </Col>
                <Col style={{ paddingLeft: 8 }}>
                  <Row>
                    <Col span={24}>
                      <span>Outage</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24} style={{ lineHeight: '15px', margin: 0 }}>
                      <span
                        style={{
                          fontSize: '14px',
                          color: '#b9b9b9',
                          fontWeight: 400,
                        }}
                      >
                        Search impacted customers
                      </span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
            className="dataSetBuilderHeader"
            extra={[
              <Button
                disabled={!this.state.data?.length}
                onClick={() => this.handleCreateCase()}
              >
                Create Case
              </Button>,
              this.state.data?.length > 0 ? (
                <CSVLink
                  filename={`outage-list-${this.state.filterKey}-${
                    this.state.filterValue
                  }-${dayjs().format('YYYY-MM-DD')}.csv`?.toLowerCase()}
                  data={this.state.data}
                >
                  <DownloadOutlined /> Export
                </CSVLink>
              ) : (
                <Button type="link" disabled>
                  <DownloadOutlined /> Export
                </Button>
              )
            ]}
          >
            <div style={{ display: 'flex', width: 400 }}>
              <Select
                defaultValue={this.state.filterKey}
                style={{ width: '30%' }}
                onSelect={(value) => {
                  this.setState({
                    filterKey: value,
                  });
                }}
              >
                <Option value="exPolygonId">EX Polygon</Option>
                <Option value="l2PolygonId">L2 Polygon</Option>
                <Option value="oltIp">Olt Ip</Option>
              </Select>
              <Search
                placeholder="search text"
                loading={this.state.isLoading}
                onChange={(e) => {
                  this.setState({
                    filterValue: e.target.value,
                  });
                }}
                enterButton={
                  <Button
                    disabled={!this.state.filterValue}
                    type="primary"
                    loading={this.state.isLoading}
                    onClick={() => this.onSubmit()}
                  >
                    Search
                  </Button>
                }
              />
            </div>
          </PageHeader>
          <Table
            loading={this.state.isLoading}
            size="small"
            dataSource={this.state.data}
            columns={columns}
          />
        </Content>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default withRouter(connect(mapState, mapDispatch)(OutageList));
