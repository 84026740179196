import { Button, Dialog, DialogBody, DialogFooter, Switch } from '@blueprintjs/core';
import { OrganizationUserGroupEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/group/organization.user.group.entity';
import { OrganizationUserEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.entity';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { DualPanelAssignment } from '@core/components/DualPanelAssignment';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  group: OrganizationUserGroupEntity;
  users: OrganizationUserEntity[];
  onUpdate: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

type TGroup = {
  id: string;
  name: string;
  description: string;
};

const ManageGroupUsersDialog: FC<Props> = (props: Props) => {
  const { group, alertMessage, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [allUsers, setAllUsers] = useState<TGroup[]>([]);
  const [groupUsers, setGroupUsers] = useState<TGroup[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isShowingOnlyActive, setIsShowingOnlyActive] = useState<boolean>(true);

  const closeModal = () => {
    setIsDialogOpen(false);
    setAllUsers([]);
    setGroupUsers([]);
  };

  const openModal = () => {
    loadAllUsers();
    getExistingUsersOnTheGroup();
  };

  const getExistingUsersOnTheGroup = () => {
    if (props.users) {
      const users: any[] =
        props.users?.map((u: any) => ({
          id: u.id,
          name: `${u.firstName} ${u.lastName}`,
          description: u.email,
          status: u.status,
        })) || [];
      setGroupUsers(users);
    }
  };

  const loadAllUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const res = await httpGet('IdentityModule/v2.0/users?size=10000');
      const users: TGroup[] =
        res.data.data?.map((u: any) => ({
          id: u.id,
          name: `${u.firstname} ${u.lastname}`,
          description: u.email,
          status: u.status,
        })) || [];

      // Remove users that are already added to the role
      const existingUsersOnGroupIds = props.users?.map((u: any) => u.id) || [];
      const filteredUsers = users.filter((u) => !existingUsersOnGroupIds.includes(u.id));

      setAllUsers(filteredUsers);
      setIsLoadingUsers(false);
      setIsDialogOpen(true);
    } catch (e: any) {
      setIsLoadingUsers(false);
    }
  };

  const addUsers = async () => {
    const userIds =
      groupUsers
        .filter((u) => !props.users?.map((ru) => ru.id).includes(u.id))
        ?.map((p: any) => p.id) || [];

    if (!userIds.length || !group) return;
    // console.log('%cdebug: Adding Users', 'color:limegreen', permissionIds);
    try {
      await httpPost(`IdentityModule/v2.0/groups/${group.id}/users`, {
        userIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not add users to a group. ' + message, type: 'error' });
    }
  };

  const removeUsers = async () => {
    const userIds =
      props.users
        ?.filter((u) => !groupUsers.map((gu) => gu.id).includes(u.id))
        ?.map((u: any) => u.id) || [];

    if (!userIds.length || !group) return;
    // console.log('%cdebug: Removing Users', 'color:salmon', permissionIds);
    try {
      await httpDelete(`IdentityModule/v2.0/groups/${group.id}/users`, {
        userIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not remove users from a group. ' + message, type: 'error' });
    }
  };

  const updateUsersOnGroup = async () => {
    setIsSaving(true);

    const addUsersPromise = addUsers();
    const removeUsersPromise = removeUsers();

    try {
      await Promise.all([addUsersPromise, removeUsersPromise]);
      alertMessage({ body: 'Users updated successfully', type: 'success' });
      setIsSaving(false);
      closeModal();
      onUpdate();
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update users on a group. ' + message,
        type: 'error',
      });
      setIsSaving(false);
    }
  };

  const clearUsersFromGroup = () => {
    const newUsers = [...allUsers, ...groupUsers].sort((a: TGroup, b: TGroup) => a.name.localeCompare(b.name));
    setAllUsers(newUsers);
    setGroupUsers([]);
  }

  const isSaveButtonDisabled = () => {
    const existingGroupUsersIds =
      props.users?.map((u: any) => u.id)?.sort((a: any, b: any) => a.localeCompare(b)) || [];
    const groupUsersIds = groupUsers.map((u) => u.id)?.sort((a: any, b: any) => a.localeCompare(b));
    return JSON.stringify(existingGroupUsersIds) === JSON.stringify(groupUsersIds);
  };

  // Add User to Group, remove from the right side list
  const onUserPanelChange = (userId: string) => {
    const user = allUsers.find((u) => u.id === userId);
    if (user) {
      setGroupUsers([...groupUsers, user]);
      setAllUsers(allUsers.filter((u) => u.id !== userId));
    }
  };

  // Remove user from Group, add back to the left side list
  const onGroupPanelChange = (userId: string) => {
    const user: TGroup | undefined = groupUsers?.find((u) => u.id === userId);
    if (user) {
      setGroupUsers(groupUsers.filter((u) => u.id !== userId));
      setAllUsers([...allUsers, user]);
    }
  };

  let filteredAllUsers = Object.assign([], allUsers);
  let filteredGroupUsers = Object.assign([], groupUsers);

  if (isShowingOnlyActive) {
    filteredAllUsers = filteredAllUsers.filter(
      (u: OrganizationUserEntity) => u.status === 'ACTIVE',
    );
    filteredGroupUsers = filteredGroupUsers.filter(
      (u: OrganizationUserEntity) => u.status === 'ACTIVE',
    );
  }

  return (
    <>
      <Button
        small
        minimal
        intent="primary"
        text="Manage"
        loading={isLoadingUsers}
        onClick={openModal}
      />
      <Dialog
        title="Manage Users"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ width: '80%' }}
      >
        <DialogBody>
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="Users"
            leftPanelIcon="person"
            leftPanelSubtitle="All available Users"
            leftPanelData={filteredAllUsers}
            onLeftPanelChange={onUserPanelChange}
            // Right Panel
            rightPanelTitle={`${group.name}`}
            rightPanelIcon="people"
            rightPanelSubtitle="All Users assigned to this Group"
            rightPanelData={filteredGroupUsers}
            onRightPanelChange={onGroupPanelChange}
            onRightPanelClear={clearUsersFromGroup}
            rightPanelLink='#users'
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="Close" text="Close" onClick={closeModal} />,
            <Button
              key="SaveChanges"
              text="Save Changes"
              disabled={isSaveButtonDisabled()}
              intent="primary"
              onClick={updateUsersOnGroup}
              loading={isSaving}
            />,
          ]}
        >
          <Switch
            disabled={allUsers.length === 0}
            label="Show Active users only"
            checked={isShowingOnlyActive}
            style={{ marginTop: 4 }}
            onChange={() => setIsShowingOnlyActive(!isShowingOnlyActive)}
          />
        </DialogFooter>
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ManageGroupUsersDialog);
