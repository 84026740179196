import { useState } from 'react';
import { Card, CardList, InputGroup, Section, SectionCard } from '@blueprintjs/core';

import { SchemaDetailsProps } from '../types';
import { Empty } from '../Empty';

export const PermissionsSection = ({ schema }: SchemaDetailsProps) => {
  const [search, setSearch] = useState('');

  if (!schema) return null;

  const filteredPermissions =
    schema.permissions?.filter((permission) => {
      return permission.name.toLocaleLowerCase().includes(search?.toLowerCase());
    }) || [];

  return (
    <>
      <Section
        className="permissions-section"
        title="Permissions"
        rightElement={
          <>
            <InputGroup
              type="search"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {filteredPermissions.length === 0 ? (
            <Empty icon="search" text="No permissions" />
          ) : (
            <CardList bordered={false}>
              {filteredPermissions.map((permission) => (
                <Card key={permission.id} interactive>
                  <span style={{ fontWeight: 600 }}>{permission.name}</span>
                  <span style={{ minWidth: '50%', marginLeft: 'auto', opacity: 0.6 }}>
                    {permission.description}
                  </span>
                </Card>
              ))}
            </CardList>
          )}
        </SectionCard>
      </Section>
    </>
  );
};
