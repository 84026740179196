import { WarningOutlined } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  getSplicingShorthandByClosureId,
} from '../../../store/actions';
import {
  FeatureSource,
  IAutosplicingReducer,
  IGetSplicingShorthandByClosureId,
} from '../../../store/types';
import './styles.scss';

interface AppProps {
  autosplicingReducer: IAutosplicingReducer;
  getSplicingShorthand: any;
  data: {
    source: FeatureSource;
    closureId: string;
    x: number;
    y: number;
  };
}

const SplicingTooltip: React.FC<AppProps> = (props) => {
  const [splicingData, setSplicingData] = useState<any>(null);
  const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
  const { autosplicingReducer, getSplicingShorthand } = props;

  useEffect(() => {
    if (props.data?.closureId) {
      setTooltipVisible(true);
      fetchSplicingData(props.data?.closureId!);
    } else {
      setTooltipVisible(false);
      setSplicingData(null);
    }
  }, [props.data]);

  // Fetch splicing data for closure id
  const fetchSplicingData = async (closureId: string) => {
    if (closureId) {
      getSplicingShorthand({ closureId: closureId }, (res: any) => {
        setSplicingData(res.data.data);
      });
    }
  };

  // Convert splicing API response to shorthand in/out code
  const parseSplicingData = (splicingData: any) => {
    let output: string = '';

    const {
      in_cable,
      in_tube_fibre_first,
      in_tube_fibre_last,
      out_cable,
      out_tube_fibre_first,
      out_tube_fibre_last,
      type,
    } = splicingData;

    // In
    if (in_cable && in_tube_fibre_first && in_tube_fibre_last) {
      output += `${in_cable} (${in_tube_fibre_first}-${in_tube_fibre_last}) `;
    }

    // Out
    if (out_cable && out_tube_fibre_first && out_tube_fibre_last) {
      output += `${type} ${out_cable} (${out_tube_fibre_first}-${out_tube_fibre_last}) `;
    } else {
      if (type) {
        output += 'available';
      }
    }

    return output;
  };

  // Render available splicing data or show "No splicing data available"
  const renderSplicingData = () => {
    if (splicingData?.length > 0) {
      return splicingData?.map((splicing: any) => (
        <Col span={24} style={{fontSize:'0.7em'}}>{parseSplicingData(splicing)}</Col>
      ));
    } else {
      return (
        <>
          <Col span={24} style={{ paddingTop: 4, textAlign: 'center' }}>
            No splicing data available.
          </Col>
          <Col span={24} style={{ paddingTop: 8, textAlign: 'center' }}>
            <WarningOutlined style={{ fontSize: '1.5em', color: 'red' }} />
          </Col>
        </>
      );
    }
  };

  return (
    <Row
      className="splicingTooltip"
      style={{
        top: `${props.data?.y}px`,
        left: `${props.data?.x}px`,
        opacity: tooltipVisible ? '1' : '0',
      }}
    >
      <Col span={24}>
        {autosplicingReducer.loadingIndicators?.isLoadingShorthandSplicing ? (
          <Row style={{ textAlign: 'center' }}>
            <Col span={24} style={{ paddingTop: 4 }}>
              Loading splicing data...
            </Col>
            <Col span={24}>
              <Spin style={{ paddingTop: 8 }} size="small" />
            </Col>
          </Row>
        ) : (
          <Row>{renderSplicingData()}</Row>
        )}
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  getSplicingShorthand: (params: IGetSplicingShorthandByClosureId, cb: any) =>
    dispatch(getSplicingShorthandByClosureId(params, cb)),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState, mapDispatch)(SplicingTooltip);
