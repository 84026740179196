import dayjs from 'dayjs';
import React, { SetStateAction } from 'react';
import { connect } from 'react-redux';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '../../../messages/store/reducers';
import {
  updateAutosplicingLoadingIndicators,
  updateAutosplicingReducerState,
} from '@netomnia/modules/ProjectModule/Autosplicing/store/actions';
import {
  IAutosplicingLoadingIndicators,
  IAutosplicingReducer,
} from '@netomnia/modules/ProjectModule/Autosplicing/store/types';
import duration from 'dayjs/plugin/duration';

dayjs.extend(duration);

type Props = {
  alertMessage: any;
  filters: any;
  autosplicingReducer: IAutosplicingReducer;
  updateAutosplicingReducer: Function;
  updateLoadingIndicators: Function;
};

interface State {
  polling: NodeJS.Timeout | undefined;
  isPolling: boolean;
  isLoading: boolean;
  jobs: any[];
  statusFilters: string[];
}

class JobsStatus extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      polling: undefined,
      isPolling: false,
      isLoading: false,
      jobs: [],
      statusFilters: ['PROCESSING', 'ERROR', 'CREATED'],
    };
  }

  componentDidMount() {
    this.poll();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>,
    snapshot?: any,
  ) {
    if (prevState.statusFilters.length !== this.state.statusFilters.length) {
      this.fetchData();
    }

    if (
      prevProps.filters.metadataOr.length !==
      this.props.filters.metadataOr.length
    ) {
      this.fetchData();
    }

    if (prevProps.filters.metadata !== this.props.filters.metadata) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.state.polling && clearInterval(this.state.polling);
  }

  poll() {
    // you should keep track of the timeout scheduled and
    // provide a cleanup if needed
    this.state.polling && clearInterval(this.state.polling);

    if (this.state.isPolling) {
      this.setState({ isPolling: false });
      return;
    }

    const polling = setInterval(() => {
      if(!document.hidden){
        this.fetchData();
      }
    }, 3000);

    this.setState({
      polling,
      isPolling: true,
    });
  }

  /**
   *
   * @param closureType
   * @private
   */
  private async fetchData() {
    const {
      alertMessage,
      filters,
      updateAutosplicingReducer,
      updateLoadingIndicators,
    } = this.props;

    if (filters) {
      this.setState({ isLoading: true });

      updateLoadingIndicators({
        isLoadingJobs: true,
      });

      await httpPost(`SchemaModule/v1.0/jobs/search`, {
        ...filters,
        ...(this.state.statusFilters.length > 0
          ? { status: this.state.statusFilters }
          : {}),
      })
        .then((res) => {
          this.setState({
            jobs: res.data.data || [],
            isLoading: false,
          });

          updateLoadingIndicators({
            isLoadingJobs: false,
          });

          // We should exclude jobs with ERROR status so that the UI is not blocked by these.
          updateAutosplicingReducer({
            allJobs: res.data.data
              ? res.data.data.filter((job: any) => job.status !== 'ERROR')
              : [],
          });
        })
        .catch((err) => {
          alertMessage({ body: `${err.message}`, type: 'error' });
        });
    }
  }

  render() {
    return <></>;
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  autosplicingReducer: state.autosplicingReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) =>
    dispatch(displayMessage(params)),
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  updateLoadingIndicators: (params: IAutosplicingLoadingIndicators) =>
    dispatch(updateAutosplicingLoadingIndicators(params)),
});

export default connect(mapState, mapDispatch)(JobsStatus);
