import React from 'react';
import { Button, Space } from 'antd';

type JSONTableActionsProps = {
  title: string;
  onClick: () => void;
}
export const JSONTableActions = (props: JSONTableActionsProps) => {
  return (
    <Space size="middle">
    <Button
      type="text"
      style={{
        color: '#1890FF',
      }}
      onClick={props.onClick}
    >
      {props.title}
    </Button>
  </Space>
  )
};
