export type TInvoiceSummary = {
  Adjustment?: any;
  Subtotal: string;
  TotalDiscounts: string;
  TotalTaxAmount: string;
  TotalPrice: string;
};

export type TProductQuota = {
  type: 'BASE_PRODUCT' | 'ADD_ON_PRODUCT';
  maxAmount: number;
};

export const productQuotas: TProductQuota[] = [
  {
    type: 'BASE_PRODUCT',
    maxAmount: 1,
  },
  {
    type: 'ADD_ON_PRODUCT',
    maxAmount: 3,
  },
];
