import React, { FC, useState } from 'react';
import { Card, Col, Divider, Row, Typography } from 'antd';
import Search from 'antd/lib/input/Search';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SearchResults } from './SearchResults';

type RecordSelectPanelProps = {
  records: DbRecordEntityTransform[];
  selectedRecord?: DbRecordEntityTransform;
  hilight?: DbRecordEntityTransform[];
  onRecordSelected: (selectedRecord?: DbRecordEntityTransform) => void;
}

export const WorkListSelectPanel: FC<RecordSelectPanelProps> = ({ records, selectedRecord, onRecordSelected, hilight = [] }) => {
  const [textFilter, setTextFilter] = useState('');

  return (
    <Card
      className="DataSetBuilderPanelRow"
      title={<Typography.Title level={5}>WorkList</Typography.Title>}
    >
      <Row>
        <Col span={24}>
          <Search
            allowClear
            value={textFilter}
            onChange={(e: any) => {
              setTextFilter(e?.target?.value);
            }}
            onSearch={(e: any) => {
              setTextFilter(e?.target?.value);
            }}
            placeholder={`Search WorkList`}
            disabled={records.length === 1}
          />
        </Col>
        <Col span={24}>
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
        </Col>
      </Row>
      <SearchResults
        records={records}
        selectedRecord={selectedRecord}
        textFilter={textFilter}
        onRecordSelected={onRecordSelected}
      />
    </Card>
  )
}
