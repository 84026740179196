import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Row } from 'antd';
import React from 'react';
import HourlyViewAssignedWorkOrder from '../HourlyViewAssignedWorkOrder';
import '../styles.scss';

interface Props {
  WO: any;
  WOType?: SchemaTypeEntity;
  WOStage?: string;
  searchQuery?: string;
  engineerId?: string;
}

const HourlyViewWorkOrderRow: React.FC<Props> = (props: Props) => {
  const { WO, searchQuery, WOType, engineerId, WOStage } = props;
  let workOrders = WO.workOrders || [];

  // Show only WO with matching Type
  if (WOType) {
    workOrders = workOrders.filter((workOrder: any) => {
      return workOrder.type === WOType.name;
    });
  }

  // Show only WO with matching Stage
  if (WOStage) {
    workOrders = workOrders.filter((workOrder: any) => {
      return workOrder.stage?.name === WOStage;
    });
  }

  return (
    <Row
      key={'workOrderMasterRow' + engineerId}
      style={{
        height: 78,
        padding: 5,
        borderBottom: '1px solid #c4c4c4',
        width: '100%',
      }}
      align="middle"
    >
      {workOrders.map((workOrder: any, i: number) => {
        const isHighlighted =
          searchQuery &&
          workOrder.recordNumber?.toLowerCase().indexOf(searchQuery?.toLowerCase()) > -1
            ? true
            : false;

        return (
          <HourlyViewAssignedWorkOrder
            workOrder={workOrder}
            isHighlighted={isHighlighted}
            key={WO.id}
          />
        );
      })}
    </Row>
  );
};
export default HourlyViewWorkOrderRow;
