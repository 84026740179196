import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Layout, Row, Spin } from 'antd';
import { isMobile } from 'react-device-detect';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';

import { PageHeader } from '../../../../../legacy/components/PageHeader';
import { hasPermissions } from '../../../../../core/helpers/rbacRules';
import { getBrowserPath } from '../../../../../core/helpers/recordHelpers';

import { useAFPApprovalState } from './context';
import { groupFpesByStatusAndType } from './context/helpers';
import { FPESelectPanel } from './FPESelectPanel';
import FPEViewPanel from './FPEViewPanel';
import { CreatePaymentNotice } from '../CreatePaymentNotice';

export const AFPApprovalHome = () => {
  const userReducer = useSelector((state: any) => state.userReducer);

  const { records: { afp, allFpes } } = useAFPApprovalState();

  const { fpesByStatus, fpesByType } = groupFpesByStatusAndType(allFpes.data);

  const { todo, approved, rejected } = fpesByStatus;
  const { workItems, adjustments } = fpesByType;

  return (
    <Layout style={{ padding: 15, marginTop: 5 }}>
      <Row gutter={12}>
        <Col span={24}>
          <Spin spinning={afp.status === 'loading'}>
            <PageHeader
              style={{ borderRadius: 8, border: '1px solid #dddbda', background: 'white' }}
              title={
                <Row>
                  <Col style={{ marginTop: 8 }}>
                    <ModuleEntityIcon
                      moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
                      entityName="ApplicationForPayment"
                      style={{ padding: '0px 2px' }}
                    />
                  </Col>
                  <Col style={{ paddingLeft: 8 }}>
                    <Row>
                      <Col span={24}>
                        <span>
                          Application For Payment - Approval Mode (
                          <Link to={getBrowserPath(afp.data!)}>
                            {afp.data?.recordNumber}
                          </Link>
                          )
                        </span>
                      </Col>
                      <Col span={24} style={{ lineHeight: '15px', margin: 0 }}>
                        <span style={{ fontSize: '14px', color: '#b9b9b9', fontWeight: 400 }}>
                          {afp.data?.title}
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }
              extra={
                <CreatePaymentNotice
                  record={afp.data!}
                  workItems={workItems}
                  adjustments={adjustments}
                  disabled={fpesByStatus.todo.length > 0 || !hasPermissions(userReducer, ['projectmodule.paymentnotice.create'])}
                />
              }
              className="dataSetBuilderHeader"
            />
          </Spin>
        </Col>
        <Col span={24} style={{ marginTop: 15 }}>
          <Row gutter={16}>
            {/* Left Panel */}
            <Col xs={24} sm={24} md={12} lg={12} xl={9}>
              <FPESelectPanel />
            </Col>

            {/* Right Panel */}
            <Col xs={24} sm={24} md={12} lg={12} xl={15} style={{ marginTop: isMobile ? 20 : 0 }}>
              <FPEViewPanel />
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};
