import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Image, Row } from 'antd';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet } from '@core/http/requests';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Spinner, Tooltip } from '@blueprintjs/core';

interface Props {
  fileIds: string;
  thumbSize?: number; // <- pixels for the width and height of the thumbnail
  justify?: 'start' | 'end';
}

const MyCaseAttachmentList: React.FC<Props> = (props: Props) => {
  const { fileIds, thumbSize, justify } = props;
  const [fileList, setFileList] = useState<DbRecordEntityTransform[]>([]);
  const [isLoadingFiles, setIsLoadingFiles] = useState<boolean>(false);

  useEffect(() => {
    if (fileIds.length > 0) {
      fetchFilesByIds();
    }
  }, [fileIds]);

  const fetchFilesByIds = async () => {
    if (fileIds.length > 0) {
      setIsLoadingFiles(true);
      try {
        httpGet(`SchemaModule/v1.0/db/File/many?ids=${fileIds}`).then((response: any) => {
          const newFileList = response.data?.data?.map((file: DbRecordEntityTransform) => {
            return file;
          });
          setIsLoadingFiles(false);
          setFileList(newFileList);
        });
      } catch (e: any) {
        setIsLoadingFiles(false);
      }
    }
  };

  const getThumbByMimeType = (mimeType: string) => {
    switch (mimeType) {
      case 'application/pdf':
        return <i className="bi bi-filetype-pdf" style={{ color: 'white', fontSize: '2em' }} />;
      default:
        return (
          <i className="bi bi-file-earmark-text" style={{ color: 'white', fontSize: '2em' }} />
        );
    }
  };

  const getThumbColorByMimeType = (mimeType: string) => {
    switch (mimeType) {
      case 'application/pdf':
        return '#f32929';
      default:
        return '#2D71D2';
    }
  };

  return (
    <Row justify={justify || 'end'} key={`Row0-Files-${fileIds}`}>
      {isLoadingFiles &&
        fileIds.length > 0 &&
        fileIds.split(',')?.map((fileId: string, i: number) => (
          <Col
            key={`Col1-loading-${fileId}`}
            style={{
              width: thumbSize || 80,
              height: thumbSize || 80,
              border: '1px solid lightgrey',
              borderRadius: 5,
              verticalAlign: 'middle',
              marginRight: i === fileIds.length - 1 ? 0 : '10px',
            }}
          >
            <Row
              key={`Row1-loading-${fileId}`}
              style={{ height: '100%' }}
              align="middle"
              justify="center"
            >
              <Col key={`Col2-loading-${fileId}`}>
                <Spinner
                  key={`Spinner-loading-${fileId}`}
                  size={18}
                  style={{ verticalAlign: 'middle' }}
                />
              </Col>
            </Row>
          </Col>
        ))}

      {!isLoadingFiles &&
        fileList.map((file: any, i: number) => (
          <Col
            key={`Col1-thumb-${file.id}`}
            style={{
              marginRight: i === fileIds.length - 1 ? 0 : '10px',
            }}
          >
            {getProperty(file, 'Mimetype').indexOf('image') > -1 ? (
              <Image
                key={`Image-thumb-${file.id}`}
                src={getProperty(file, 'Url')}
                width={`${thumbSize || 80}px`}
                height={`${thumbSize || 80}px`}
                style={{ borderRadius: 5 }}
              />
            ) : (
              <Tooltip content={file.title} hoverOpenDelay={1000} key={`Tooltip-thumb-${file.id}`}>
                <a
                  href={getProperty(file, 'Url')}
                  target="_blank"
                  rel="noreferrer"
                  key={`Link-thumb-${file.id}`}
                >
                  <div
                    key={`Div1-thumb-${file.id}`}
                    style={{
                      width: thumbSize || 80,
                      height: thumbSize || 80,
                      borderRadius: 5,
                      cursor: 'pointer',
                      background: getThumbColorByMimeType(getProperty(file, 'Mimetype')),
                      verticalAlign: 'middle',
                    }}
                  >
                    <Row style={{ height: '100%' }} align="middle" key={`Row2-thumb-${file.id}`}>
                      <Col span={24} style={{ textAlign: 'center' }} key={`Col2-thumb-${file.id}`}>
                        {getThumbByMimeType(getProperty(file, 'Mimetype'))}
                      </Col>
                    </Row>
                  </div>
                </a>
              </Tooltip>
            )}
          </Col>
        ))}
    </Row>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCaseAttachmentList);
