export const ADD_INVOICE_ITEM = 'ADD_INVOICE_ITEM';
export const REMOVE_INVOICE_ITEM = 'REMOVE_INVOICE_ITEM';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_BILLING_ADJUSTMENT = 'SET_BILLING_ADJUSTMENT';
export const SET_BILLING_PERIOD_END_DATE = 'SET_BILLING_PERIOD_END_DATE';
export const SET_BILLING_PERIOD_START_DATE = 'SET_BILLING_PERIOD_START_DATE';
export const SET_BILLING_START_DATE = 'SET_BILLING_START_DATE';
export const SET_BILLING_TERMS = 'SET_BILLING_TERMS';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const SET_DUE_DATE = 'SET_DUE_DATE';
export const SET_INVOICE_CREATED = 'SET_INVOICE_CREATED';
export const SET_INVOICE_ID = 'SET_INVOICE_ID';
export const SET_INVOICE_ITEMS = 'SET_INVOICE_ITEMS';
export const SET_INVOICE_TYPE = 'SET_INVOICE_TYPE';
export const SET_IS_BILLING_ADJUSTMENT_APPLIED = 'SET_IS_BILLING_ADJUSTMENT_APPLIED';
export const SET_IS_DISCOUNT_APPLIED = 'SET_IS_DISCOUNT_APPLIED';
export const SET_IS_INVOICE_CREATING = 'SET_IS_INVOICE_CREATING';
export const SET_LOADING_OFFERS = 'SET_LOADING_OFFERS';
export const SET_LOADING_PARENT_RECORD_ASSOCIATIONS = 'SET_LOADING_PARENT_RECORD_ASSOCIATIONS';
export const SET_LOADING_PRODUCTS = 'SET_LOADING_PRODUCTS';
export const SET_OFFER_LIST = 'SET_OFFER_LIST';
export const SET_OFFER_PRODUCT_LIST = 'SET_OFFER_PRODUCT_LIST';
export const SET_ORDER_RECORD = 'SET_ORDER_RECORD';
export const SET_PARENT_RECORD = 'SET_PARENT_RECORD';
export const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER';
