import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Button } from '@blueprintjs/core';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { MyCasesContext } from '../../../../index';
import { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';
import {
  getRecordByIdRequest,
  IGetRecordById,
} from '@legacy/core/records/store/actions';
import { MY_CASES_SET_CONTACT } from '../../../../store/constants';

interface Props {
  contactSchema: SchemaEntity;
  initializeForm: (params: any) => void;
  getRecordById: (payload: IGetRecordById, cb: any) => void;
}

const MyCasesCreateContactButton: React.FC<Props> = (props: Props) => {
  const { contactSchema, initializeForm, getRecordById } = props;
  const { state, dispatch } = useContext(MyCasesContext);

  const initializeCreateContactForm = () => {
    initializeForm({
      formUUID: `${contactSchema!.id}_Create`,
      title: `Create Contact`,
      showFormModal: true,
      isCreateReq: true,
      isUpdateReq: false,
      schema: contactSchema,
      selected: null,
      sections: [{ name: contactSchema.name, schema: contactSchema }],
      modified: [
        {
          associations: [
            // Associate the Case record
            {
              entity: state.selectedCase?.entity,
              recordId: state.selectedCase?.id,
            },
          ],
          schemaId: contactSchema?.id,
        },
      ],
    });
  };

  const handleFormSubmit = (params: any) => {
    const recordId = params.results?.id;
    if (recordId && contactSchema) {
      getRecordById({ schema: contactSchema, recordId: recordId }, (res: any) => {
        dispatch({ type: MY_CASES_SET_CONTACT, payload: res });
      });
    }
  };

  return (
    <>
      <CoreForm
        type="MODAL"
        formUUID={`${contactSchema!.id}_Create`}
        onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
      />
      <Button
        icon="plus"
        intent="success"
        text="Create Contact"
        style={{ borderRadius: 5 }}
        onClick={initializeCreateContactForm}
      />
    </>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(MyCasesCreateContactButton);
