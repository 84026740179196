import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { Col, Form, Input, Switch, Row, Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { toggleSchemaActionCreateEditDrawer } from '@legacy/core/userInterface/store/actions';
import { connect } from 'react-redux';
import { IUserInterfaceReducer } from '@legacy/core/userInterface/store/types';
import {
  ISchemaActionCreate,
  ISchemaActionUpdate,
  createSchemaAction,
  getSchemaByIdRequest,
  updateSchemaAction,
} from '@legacy/core/schemas/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { httpGet } from '@core/http/requests';
interface Props {
  schemaId: string | undefined;
  schemaAction?: SchemaActionEntity;
  userInterfaceReducer: IUserInterfaceReducer;
  isCreate: boolean;
  actionToUpdate?: any;
  toggleDrawer: () => void;
  createAction: (payload: ISchemaActionCreate, cb?: any) => void;
  updateAction: (payload: ISchemaActionUpdate, cb: any) => void;
  onSuccess?: any;
  onCancel?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  schemaReducer: any;
  getSchema: (payload: any, cb: any) => void;
}

export const SchemaActionCreateUpdateModal: FC<Props> = (props) => {
  const [form] = Form.useForm();
  const {
    userInterfaceReducer,
    toggleDrawer,
    isCreate,
    createAction,
    schemaId,
    onSuccess,
    onCancel,
    actionToUpdate,
    updateAction,
    alertMessage,
    schemaReducer,
    getSchema,
  } = props;
  const { schemaActionCreateEditDrawerVisible } = userInterfaceReducer;

  const [isCreatingOrUpdating, setIsCreatingOrUpdating] = useState<boolean>(false);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);

  useEffect(() => {
    if (schemaId) {
      const shortListSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.schemaShortList,
        schemaId,
      );
      if (shortListSchema) {
        setSchema(shortListSchema);
      } else {
        getSchema({ schemaId }, (res: any) => {
          if (res) {
            setSchema(res);
          }
        });
      }
    }
  }, [schemaId]);

  // Fetch pipeline on component mount for DEFAULT or UPDATING action schema type
  useEffect(() => {
    if (userInterfaceReducer.schemaActionCreateEditDrawerVisible && schema) {
      if (actionToUpdate) {
        getPipelines(actionToUpdate.schemaTypeId);
      } else {
        getPipelines(null);
      }
    }
  }, [userInterfaceReducer.schemaActionCreateEditDrawerVisible]);

  const getPipelines = (typeId: string | null) => {
    if (schema) {
      const schemaType = schema.types.find((type: any) => type.id === typeId);

      httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${schema?.moduleName}/${schema?.entityName}${
          schemaType ? `?schemaType=${schemaType.name}` : '?schemaType='
        }`,
      ).then((res: any) => {
        const pipelines = res.data?.data || [];
        if (pipelines.length > 0) {
          setPipeline(pipelines[0]);
        }
      });
    }
  };

  const resetPipelineOnTypeUpdate = (typeId: string | null) => {
    form.setFieldsValue({ stages: [], targetStages: [] });
    getPipelines(typeId);
  };

  // On Drawer open, preset values if updating
  useEffect(() => {
    if (userInterfaceReducer.schemaActionCreateEditDrawerVisible && !isCreate && actionToUpdate) {
      form.setFieldsValue({
        actionName: actionToUpdate.name,
        actionDescription: actionToUpdate.description,
        actionType: getActionType(actionToUpdate),
        actionSchemaTypeId: actionToUpdate.schemaTypeId || '',
        defaultForm: actionToUpdate.defaultForm || false,
        userAction: actionToUpdate.userAction || false,
        stages: actionToUpdate.stages || [],
        targetStages: actionToUpdate.targetStages || [],
      });
    }
  }, [userInterfaceReducer.schemaActionCreateEditDrawerVisible]);

  const onFinish = (values: any) => {
    setIsCreatingOrUpdating(true);

    // Create Request
    if (isCreate && schemaId) {
      createAction(
        {
          schemaId: schemaId,
          name: values.actionName,
          description: values.actionDescription,
          isCreate: values.actionType === 'CREATE' ? true : false,
          isUpdate: values.actionType === 'UPDATE' ? true : false,
          isStepFlow: values.actionType === 'FLOW' ? true : false,
          defaultForm: values.defaultForm || false,
          userAction: values.userAction || false,
          stages: values.stages || false,
          targetStages: values.targetStages || false,
          schemaTypeId: values.actionSchemaTypeId || null,
        },
        (res: any) => {
          if (res) {
            setIsCreatingOrUpdating(false);
            form.resetFields();
            toggleDrawer();
            alertMessage({ body: 'Schema Action Created', type: 'success' });
            if (onSuccess) onSuccess(res.id);
          } else {
            alertMessage({ body: 'Schema Action could not be Created', type: 'error' });
            setIsCreatingOrUpdating(false);
            form.resetFields();
            toggleDrawer();
          }
        },
      );
    } else if (!isCreate && actionToUpdate && schemaId) {
      updateAction(
        {
          schemaActionId: actionToUpdate.id,
          name: values.actionName,
          description: values.actionDescription,
          isCreate: values.actionType === 'CREATE' ? true : false,
          isUpdate: values.actionType === 'UPDATE' ? true : false,
          isStepFlow: values.actionType === 'FLOW' ? true : false,
          schemaId: actionToUpdate.schemaId,
          schemaTypeId: values.actionSchemaTypeId || null,
          definition: actionToUpdate.definition as any,
          defaultForm: values.defaultForm || false,
          userAction: values.userAction || false,
          stages: values.stages || false,
          targetStages: values.targetStages || false,
        },
        (res: any) => {
          if (res) {
            alertMessage({ body: 'Schema Action Updated', type: 'success' });
            setIsCreatingOrUpdating(false);
            form.resetFields();
            toggleDrawer();
            if (onSuccess) onSuccess(res.id);
          } else {
            alertMessage({ body: 'Schema Action could not be Updated', type: 'error' });
            setIsCreatingOrUpdating(false);
            form.resetFields();
            toggleDrawer();
          }
        },
      );
    }
  };

  const getActionType = (action: SchemaActionEntity) => {
    if (action.isCreate) return 'CREATE';
    else if (action.isUpdate) return 'UPDATE';
    else if (action.isStepFlow) return 'FLOW';
  };

  const onClose = () => {
    setIsCreatingOrUpdating(false);
    toggleDrawer();
    if (onCancel) onCancel();
    // wait one second and then reset fields
    setTimeout(() => {
      form.resetFields();
    }, 500);
  };

  return (
    <Dialog
      title={isCreate ? 'Create Schema Action' : 'Update Schema Action'}
      isOpen={schemaActionCreateEditDrawerVisible}
      onClose={onClose}
    >
      <DialogBody>
        <Row>
          <Col span={24}>
            <Form
              disabled={isCreatingOrUpdating}
              form={form}
              name="basic"
              layout="vertical"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 24 }}
              style={{ maxWidth: 600 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={() => {}}
              autoComplete="off"
            >
              {/* Action Name */}
              <Form.Item
                label="Action Name"
                name="actionName"
                rules={[{ required: true, message: 'Please enter Action name' }]}
              >
                <Input />
              </Form.Item>

              {/* Action Description */}
              <Form.Item
                label="Description"
                name="actionDescription"
                rules={[{ required: true, message: 'Please enter Description' }]}
              >
                <Input />
              </Form.Item>

              {/* Action Type */}
              <Form.Item
                label="Action Type"
                name="actionType"
                initialValue={'CREATE'}
                rules={[{ required: true, message: 'Please select Action Type' }]}
              >
                <Select>
                  <Select.Option value="CREATE">Create</Select.Option>
                  <Select.Option value="UPDATE">Update</Select.Option>
                  <Select.Option value="FLOW">Flow</Select.Option>
                </Select>
              </Form.Item>

              {/* Schema Type */}
              <Form.Item label="Schema Type" name="actionSchemaTypeId">
                <Select
                  allowClear
                  disabled={!schema || !schema.types.length}
                  onChange={resetPipelineOnTypeUpdate}
                  onClear={() => resetPipelineOnTypeUpdate(null)}
                  placeholder={
                    schema?.types?.length! > 0 ? 'Select Schema Type' : 'Schema has no types'
                  }
                >
                  {schema?.types.map((type: any) => (
                    <Select.Option value={type.id}>{type.name}</Select.Option>
                  ))}
                </Select>
              </Form.Item>

              {/* Stages */}
              <Form.Item label="Stages" name="stages">
                <Select
                  allowClear
                  maxTagCount={3}
                  mode="multiple"
                  disabled={!pipeline}
                  placeholder={
                    pipeline?.stages?.length! > 0 ? 'Select Stage' : 'Schema has no stages'
                  }
                >
                  {pipeline &&
                    pipeline?.stages?.map((stage: any) => (
                      <Select.Option value={stage.key}>{stage.key}</Select.Option>
                    ))}
                </Select>
              </Form.Item>

              {/* Target Stages */}
              <Form.Item label="Target Stages" name="targetStages">
                <Select
                  maxTagCount={3}
                  allowClear
                  mode="multiple"
                  disabled={!pipeline}
                  placeholder={
                    pipeline?.stages?.length! > 0 ? 'Select Stage' : 'Schema has no stages'
                  }
                >
                  {pipeline &&
                    pipeline?.stages?.map((stage: any) => (
                      <Select.Option value={stage.key}>{stage.name}</Select.Option>
                    ))}
                </Select>
              </Form.Item>

              <Row>
                <Col span={12}>
                  {/* Default Form */}
                  <Form.Item label="Default Form" name="defaultForm" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  {/* User Action */}
                  <Form.Item label="User Action" name="userAction" valuePropName="checked">
                    <Switch />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </DialogBody>
      <DialogFooter
        actions={[
          <Button text="Cancel" onClick={onClose} />,
          <Button
            text="Submit"
            intent="primary"
            onClick={form.submit}
            loading={isCreatingOrUpdating}
            disabled={
              form.getFieldsError().filter(({ errors }) => errors.length).length > 0 ||
              isCreatingOrUpdating
            }
          />,
        ]}
      />
    </Dialog>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  toggleDrawer: () => dispatch(toggleSchemaActionCreateEditDrawer()),
  createAction: (payload: ISchemaActionCreate, cb: any) =>
    dispatch(createSchemaAction(payload, cb)),
  updateAction: (payload: ISchemaActionUpdate, cb: any) =>
    dispatch(updateSchemaAction(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(SchemaActionCreateUpdateModal);
