import { AimOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  Button,
  Card,
  Col,
  Empty,
  Form,
  Input,
  InputRef,
  Row,
  Select,
  Space,
  Spin,
  Table,
  Tag,
} from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  IMapSetWorkItemQuickView,
  IMapUpdateWorkItems,
  MapReducerUpdate,
  resetWorkItemForm,
  setWorkItemQuickView,
  updateMapState,
  updateWorkItems,
} from '@netomnia/modules/ProjectModule/Map/store/actions';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '@legacy/core/schemas/store/actions';
import {
  IAddRecordToShortList,
  ISearchRecords,
  addRecordToShortList,
  searchRecordsRequest,
} from '@legacy/core/records/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../core/helpers/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import './styles.scss';
import {
  IGetRecordAssociations,
  getRecordAssociationsRequest,
} from '@legacy/core/recordsAssociations/store/actions';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { ColumnsType } from 'antd/lib/table';
import {
  QGISBuildStatusEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { getFeatureByIdAndZoom } from '../../helpers';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { FilterConfirmProps } from 'antd/lib/table/interface';

interface Props {
  updateMap: Function;
  getSchema: Function;
  searchRecords: Function;
  mapReducer: MapReducer;
  schemaReducer: any;
  getAssociations: Function;
  alertMessage: Function;
  shortListRecord: Function;
  setQuickView: Function;
  resetForm: Function;
  updateWI: (payload: IMapUpdateWorkItems) => void;
}

interface TableDataType {
  key: string;
  featureType: string;
  recordId: string;
  buildStatus: string;
  actions: DbRecordEntityTransform;
}

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, PROJECT } = SchemaModuleEntityTypeEnums;

const MapSidebarWorkItems: FC<Props> = (props: Props) => {
  const {
    setQuickView,
    getSchema,
    searchRecords,
    schemaReducer,
    getAssociations,
    mapReducer,
    updateMap,
    alertMessage,
    resetForm,
    updateWI,
  } = props;

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchingWorkItems, setSearchingWorkItems] = useState<boolean>(false);
  const [loadingFeatureList, setLoadingFeatureList] = useState<boolean>(false);
  const [workItemsSchema, setWorkItemsSchema] = useState<SchemaEntity | undefined>(undefined);
  const [featureSchema, setFeatureSchema] = useState<SchemaEntity | undefined>(undefined);

  const [searchedColumn, setSearchedColumn] = useState<TableDataType | undefined>(undefined);
  const searchInput = useRef<InputRef>(null);
  const [workItemsForm] = Form.useForm();

  const workItems = mapReducer.workItems;

  useEffect(() => {
      if (!workItems.workItemsForm) {
        updateWI({
          workItemsForm: workItemsForm,
        });
      }
    },
    [workItemsForm]);


  // Clear Form
  const clearSearch = () => {
    workItemsForm?.resetFields();
    setSearchingWorkItems(false);
    setSearchTerm('');
    resetForm();
  };

  // On component mount fetch Work Item schema from shortlist or fallback to API request
  useEffect(() => {
    if (!workItemsSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        PROJECT_MODULE,
        'WorkList',
      );

      if (shortlistSchema) {
        setWorkItemsSchema(shortlistSchema);
      } else {
        getSchema(
          { moduleName: PROJECT_MODULE, entityName: 'WorkList' },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setWorkItemsSchema(responseSchema);
            }
          },
        );
      }
    }

    if (!featureSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        PROJECT_MODULE,
        FEATURE,
      );

      if (shortlistSchema) {
        setFeatureSchema(shortlistSchema);
      } else {
        getSchema(
          { moduleName: PROJECT_MODULE, entityName: FEATURE },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setFeatureSchema(responseSchema);
            }
          },
        );
      }
    }
  }, []);

  // Search for Work Items via Elastic endpoint using string query on record title
  const searchWorkItems = (term: string) => {
    setSearchTerm(term);

    if (term.length > 2 && workItemsSchema) {
      setSearchingWorkItems(true);

      searchRecords(
        {
          schema: workItemsSchema,
          searchQuery: {
            terms: searchTerm,
            fields: ['title', 'recordNumber'],
            schemas: workItemsSchema?.id,
          },
        },
        (searchResults: any) => {
          if (searchResults?.data?.data?.length! > 0) {

            setSearchingWorkItems(false);
            updateWI({ workLists: searchResults.data.data });
          } else {
            setSearchingWorkItems(false);
            updateWI({ workLists: [] });
          }
        },
      );
    } else {
      setSearchingWorkItems(false);
      updateWI({ workLists: [] });
    }
  };

  // When Work Item is selected, fetch the associated Features.
  useEffect(() => {
    const selectedWorkItemsRecord = workItems.selectedWorkList;

    if (selectedWorkItemsRecord && workItemsSchema) {
      setLoadingFeatureList(true);
      getAssociations(
        {
          recordId: selectedWorkItemsRecord?.id,
          key: FEATURE,
          schema: workItemsSchema,
          entities: [FEATURE, PROJECT],
        },
        (res: any) => {

          // Filter out related features and set to local state
          if (res && res.results?.[FEATURE]?.dbRecords?.length > 0) {
            const features = res.results?.[FEATURE]?.dbRecords;
            updateWI({ workItems: features });
          } else {
            updateWI({ workItems: [] });
          }

          // Filter out related Projects and set to mapReducer
          if (res && res.results?.[PROJECT]?.dbRecords?.length > 0) {
            const relatedProject = getFirstRelation(res.results, PROJECT);
            updateWI({ selectedProject: relatedProject });
          }

          setLoadingFeatureList(false);
        },
      );
    }
  }, [workItems.selectedWorkList, workItemsSchema]);

  // Handle search in feature tables
  const handleSearch = (
    selectedKeys: string[],
    confirm: (param?: FilterConfirmProps) => void,
    dataIndex: TableDataType,
  ) => {
    confirm();
    setSearchedColumn(dataIndex);
  };

  // Column search for Feature list table
  const getColumnSearchProps = (dataIndex: any): any => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }: any) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters('');
              handleSearch([''], confirm, dataIndex);
            }}
            size="small"
            style={{ width: 90 }}
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value: any, record: any) =>
      record[dataIndex]
        .toString()
        .toLowerCase()
        .includes((value as string).toLowerCase()),
    onFilterDropdownOpenChange: (visible: any) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text: any) => text,
  });

  // Get selected Work item id and set the work item record to state
  const handleWorkItemSelection = (selectedWorkItemId: string) => {
    const selected = workItems.workLists.find(
      (item: DbRecordEntityTransform) => item.id === selectedWorkItemId,
    );
    if (selected) {
      updateWI({
        selectedWorkList: selected,
      });
    }
  };

  // Construct columns for Work item related features
  const getTableColumns = () => {
    const { queryLayer } = mapReducer;

    const columns: ColumnsType<TableDataType> = [
      {
        title: 'Build status',
        dataIndex: 'buildStatus',
        key: 'buildStatus',
        render: (text) => <span>{text}</span>,
        sorter: (a: any, b: any) => a.buildStatus.length - b.buildStatus.length,
        onFilter: (value: any, record) => record.buildStatus.indexOf(value) === 0,
        filters: Object.keys(QGISBuildStatusEnum).map((key: any, i: number) => ({
          text: QGISBuildStatusEnum[key],
          value: QGISBuildStatusEnum[key],
        })),
      },
      {
        title: 'Type',
        dataIndex: 'featureType',
        key: 'featureType',
        render: (text) => <span>{text}</span>,
        sorter: (a: any, b: any) => a.featureType.length - b.featureType.length,
      },
      {
        title: 'Id',
        dataIndex: 'recordId',
        key: 'recordId',
        render: (id: string) => <span>{id}</span>,
        sorter: (a: any, b: any) => Number(a.recordId) - Number(b.recordId),
        ...getColumnSearchProps('recordId'),
      },

      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'right',
        render: (record: DbRecordEntityTransform) => (
          <div key={record.id}>
            <Button
              key={`zoomInBtn-${record.id}`}
              style={{ marginRight: 8 }}
              icon={<AimOutlined />}
              onClick={() => {
                const query = `type=${record.type?.toLowerCase()}&featureId=${getProperty(
                  record,
                  'ExternalRef',
                )}`;
                updateMap({
                  mapSidebarVisible: !isMobile,
                });
                getFeatureByIdAndZoom(mapReducer, updateMap, alertMessage, query);
              }}
            />
            <Button
              key={`quickViewBtn-${record.id}`}
              icon={
                <EyeOutlined
                  onClick={() =>
                    setQuickView({
                      record: record,
                      visible: true,
                    })
                  }
                />
              }
            />
          </div>
        ),
      },
    ];

    return columns;
  };

  const getTableData = (): TableDataType[] => {
    return workItems.workItems.map((feature: DbRecordEntityTransform, i: number) => ({
      key: String(i),
      featureType: feature.type || '-',
      recordId: getProperty(feature, 'ExternalRef'),
      buildStatus: String(QGISBuildStatusEnum[getProperty(feature, 'BuildStatus')]),
      actions: feature,
    }));
  };

  return (
    <Row>
      {/* WorkList Search Form */}
      <Col span={24} style={{ marginBottom: 15 }}>
        <Card
          size="small"
          title={
            <span>
              <SearchOutlined style={{ marginRight: '5px' }} />
              Search Work Lists
            </span>
          }
          extra={
            <Button
              type="primary"
              ghost
              size="small"
              style={{ borderRadius: 3 }}
              onClick={() => clearSearch()}
            >
              {isMobile ? 'Clear' : 'Clear Search'}
            </Button>
          }
        >
          <Form name="workItemsForm" form={workItemsForm} onFinish={(values: any) => {
          }}>
            <Form.Item name="workItemsSelect" style={{ marginBottom: 3 }}>
              <Select
                showSearch
                defaultActiveFirstOption={false}
                notFoundContent={null}
                loading={searchingWorkItems}
                placeholder="Search Work Items"
                onChange={handleWorkItemSelection}
                onSearch={(e: any) => searchWorkItems(e)}
                style={{ width: '100%', marginTop: 5 }}
                filterOption={(input: string, option: any) =>
                  option?.label?.toLowerCase().indexOf(input.toLowerCase()) > -1 ||
                  option?.key?.toLowerCase().indexOf(input.toLowerCase()) > -1
                }
              >
                {workItems.workLists.length > 0 ? (
                  workItems.workLists.map((item: DbRecordEntityTransform) => {
                    return (
                      <Select.Option
                        className="workItemSelectInput"
                        label={item?.title}
                        value={item?.id}
                        key={item.recordNumber}
                      >
                        <Row>
                          <Col span={24} style={{ fontWeight: 500 }}>
                            {item?.title}
                          </Col>
                          <Col span={24} style={{ color: '#b0b0b0', fontSize: '0.8em' }}>
                            <span>
                              {/* Contract Type */}
                              {item.recordNumber ? (
                                <Tag
                                  style={{
                                    marginRight: 5,
                                    fontSize: '0.8em',
                                    padding: '0px 4px',
                                    lineHeight: '15px',
                                  }}
                                >
                                  {item.recordNumber || '-'}
                                </Tag>
                              ) : (
                                <></>
                              )}

                              {/* Category */}
                              {getProperty(item, 'Category') ? (
                                <Tag
                                  style={{
                                    marginRight: 5,
                                    fontSize: '0.8em',
                                    padding: '0px 4px',
                                    lineHeight: '15px',
                                  }}
                                >
                                  {getProperty(item, 'Category') || '-'}
                                </Tag>
                              ) : (
                                <></>
                              )}

                              {/* L1PolygonId */}
                              {getProperty(item, 'L1PolygonId') ? (
                                <Tag
                                  style={{
                                    marginRight: 5,
                                    fontSize: '0.8em',
                                    padding: '0px 4px',
                                    lineHeight: '15px',
                                  }}
                                >
                                  L1: {getProperty(item, 'L1PolygonId') || '-'}
                                </Tag>
                              ) : (
                                <></>
                              )}

                              {/* L2PolygonId */}
                              {getProperty(item, 'L2PolygonId') ? (
                                <Tag
                                  style={{
                                    marginRight: 5,
                                    fontSize: '0.8em',
                                    padding: '0px 4px',
                                    lineHeight: '15px',
                                  }}
                                >
                                  L1: {getProperty(item, 'L2PolygonId') || '-'}
                                </Tag>
                              ) : (
                                <></>
                              )}
                            </span>
                          </Col>
                        </Row>
                      </Select.Option>
                    );
                  })
                ) : (
                  <></>
                )}
              </Select>
            </Form.Item>
          </Form>
        </Card>
      </Col>
      {/* WorkList Search Results */}
      <Col span={24}>
        {/* No Work Item is selected -> Show instruction */}
        {!workItems.selectedWorkList && !loadingFeatureList ? (
          <div style={{ textAlign: 'center', opacity: 0.3, marginTop: 80 }}>
            Search for Work item to find related features.
          </div>
        ) : (
          <></>
        )}

        {/* There are no associated features -> Show Empty */}
        {workItems.selectedWorkList &&
        !loadingFeatureList &&
        workItems.workItems.length === 0 ? (
          <Empty style={{ marginTop: 50 }} description="No associated Features found" />
        ) : (
          <></>
        )}

        {/* There are associated features -> render table */}
        {workItems.workItems.length > 0 && !loadingFeatureList ? (
          <Table
            bordered
            className="featureListTable"
            size="small"
            columns={getTableColumns()}
            dataSource={getTableData()}
          />
        ) : (
          <></>
        )}

        {/* Loading feature list... */}
        {loadingFeatureList ? (
          <div style={{ textAlign: 'center', paddingTop: 50 }}>
            <Spin size="large" style={{ marginBottom: 20 }} />
            <br />
            <span>Loading ...</span>
          </div>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
  setQuickView: (params: IMapSetWorkItemQuickView) => dispatch(setWorkItemQuickView(params)),
  resetForm: () => dispatch(resetWorkItemForm()),
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
});

export default connect(mapState, mapDispatch)(MapSidebarWorkItems);
