export const debugLog = (message: string, data?: any) => {
  const title = `%cdebug%c`;
  if (message && data) {
    console.log(
      `${title} ${message}`,
      'background-color:yellow; color:black; padding:2px 4px 1px 4px; border-radius:12px',
      'color:white',
      ...data,
    );
  } else if (message) {
    console.log(
      `${title} ${message}`,
      'background-color:yellow; color:black; padding:2px 4px 1px 4px; border-radius:12px',
      'color:white',
    );
  } else {
  }
};
