import { Button, Icon } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React from 'react';
import { connect } from 'react-redux';
import { addRecordToShortList, IAddRecordToShortList } from '@legacy/core/records/store/actions';
import Typography from '@core/components/Typography';

interface Props {
  discounts: DbRecordEntityTransform[];
  shortListRecord: (params: IAddRecordToShortList) => void;
  isLoading: boolean;
}

const SHOW_EMPTY_TABLE = true;

const InvoiceOverviewDiscountList: React.FC<Props> = (props: Props) => {
  const { discounts, shortListRecord, isLoading } = props;

  interface DataType {
    key: string;
    discountItem: string;
    discountValue: string;
    discountType: string;
    discountUnit: string;
    record: DbRecordEntityTransform;
  }

  const tableColumns: ColumnsType<DataType> = [
    {
      title: 'Item',
      dataIndex: 'discountItem',
      key: 'discountItem',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Value',
      dataIndex: 'discountValue',
      key: 'discountValue',
      align: 'center',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Type',
      dataIndex: 'discountType',
      key: 'discountType',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Unit',
      dataIndex: 'discountUnit',
      key: 'discountUnit',
      render: (text: any, record: any) => text,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      align: 'right',
      render: (text: any, record: any) => (
        <Tooltip title="Quick View" mouseEnterDelay={1.2}>
          <Button
            small
            icon={<Icon icon="eye-open" />}
            onClick={() =>
              shortListRecord({
                showPreview: true,
                record: record.record,
              })
            }
          />
        </Tooltip>
      ),
    },
  ];

  let tableData = discounts.map((discount: DbRecordEntityTransform) => {
    return {
      key: discount.id,
      discountItem: discount.title || '-',
      discountValue: getProperty(discount, 'DiscountValue') || '-',
      discountType: getProperty(discount, 'DiscountType') || '-',
      discountUnit: getProperty(discount, 'DiscountUnit') || '-',
      record: discount,
    };
  });

  return discounts.length > 0 || SHOW_EMPTY_TABLE ? (
    <div style={{ marginTop: 20 }}>
      <Row style={{ marginBottom: 15 }}>
        <Col span={24}>
          <Typography stronger style={{ fontSize: 14, verticalAlign: 'middle' }}>
            Discounts
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Table
            style={{ opacity: isLoading ? 0.3 : 1 }}
            size="small"
            bordered={false}
            loading={isLoading}
            columns={tableColumns}
            dataSource={tableData}
            pagination={false}
          />
        </Col>
      </Row>
    </div>
  ) : (
    <></>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
});

export default connect(mapState, mapDispatch)(InvoiceOverviewDiscountList);
