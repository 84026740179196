import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, MenuItem, NonIdealState, Section, Spinner, Tabs } from '@blueprintjs/core';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getRecordAssociationsRequest,
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociations,
  IGetRecordAssociationWithNestedEntities,
} from '@legacy/core/recordsAssociations/store/actions';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import ContactAccountCaseTable from './tables/ContactAccountCaseTable';
import ContactAccountOrderTable from './tables/ContactAccountOrderTable';
import ContactAccountWorkOrderTable from './tables/ContactAccountWorkOrderTable';
import ContactAccountInvoiceTable from './tables/ContactAccountInvoiceTable';
import ContactAccountLeadTable from './tables/ContactAccountLeadTable';
import ContactAccountAddressTable from './tables/ContactAccountAddressTable';
import ContactAccountPaymentMethodTable from './tables/ContactAccountPaymentMethodTable';
import ActivityCenter from '@legacy/core/records/components/ActivityCenter';
import ContactAccountBillingRequestTable from './tables/ContactAccountBillingRequestTable';

interface Props {
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) => void;
}

const {
  ACCOUNT,
  WORK_ORDER,
  CONTACT,
  INVOICE,
  ORDER,
  LEAD,
  PAYMENT_METHOD,
  ADDRESS,
  BILLING_REQUEST,
} = SchemaModuleEntityTypeEnums;
const CASE = 'Case';

interface IAccount {
  title: string;
  id: string;
}

const ContactAccountAssociations: React.FC<Props> = (props: Props) => {
  const { getAssociations, getNestedAssociations } = props;
  const { record, schema } = useContext(DetailViewContext); // Contact record / schema

  const [isSearchingContactLinks, setIsSearchingContactLinks] = useState<boolean>(false);
  const [isSearchingAccountLinks, setIsSearchingAccountLinks] = useState<boolean>(false);
  const [accountSchema, setAccountSchema] = useState<SchemaEntity | undefined>(undefined);
  const [accounts, setAccounts] = useState<DbRecordEntityTransform[]>([]);
  const [selectedAccount, setSelectedAccount] = useState<IAccount | null>(null);

  // Associated records
  const [orders, setOrders] = useState<DbRecordEntityTransform[]>([]);
  const [invoices, setInvoices] = useState<DbRecordEntityTransform[]>([]);
  const [cases, setCases] = useState<DbRecordEntityTransform[]>([]);
  const [leads, setLeads] = useState<DbRecordEntityTransform[]>([]);
  const [workOrders, setWorkOrders] = useState<DbRecordEntityTransform[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<DbRecordEntityTransform[]>([]);
  const [addresses, setAddresses] = useState<DbRecordEntityTransform[]>([]);
  const [billingRequests, setBillingRequests] = useState<DbRecordEntityTransform[]>([]);

  const [selectedTab, setSelectedTab] = useState<'Links' | 'Activity'>('Links');

  // Get associated accounts on component mount
  useEffect(() => {
    getAccountSchema();
    getAssociatedAccountsForContact();
    getContactLinks();
  }, [record]);

  // On account change, fetch all associated records
  useEffect(() => {
    if (selectedAccount?.id && accountSchema) {
      getAccountLinks();
    }
  }, [selectedAccount, accountSchema]);

  const getAccountSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity('CrmModule', ACCOUNT);
      if (schema) {
        setAccountSchema(schema);
      }
    } catch (e: any) {
      // Couldn't fetch Account schema
    }
  };

  // Contact -> Cases, Leads, Addresses, Payment Method
  const getContactLinks = () => {
    setIsSearchingContactLinks(true);
    getAssociations(
      {
        recordId: record.id,
        key: CONTACT,
        schema: schema,
        entities: [CASE, LEAD, PAYMENT_METHOD, ADDRESS, BILLING_REQUEST],
      },
      (res: any) => {
        const cases = res.results?.[CASE]?.dbRecords || [];
        const leads = res.results?.[LEAD]?.dbRecords || [];
        const paymentMethods = res.results?.[PAYMENT_METHOD]?.dbRecords || [];
        const addresses = res.results?.[ADDRESS]?.dbRecords || [];
        const billingRequests = res.results?.[BILLING_REQUEST]?.dbRecords || [];
        setCases(cases);
        setLeads(leads);
        setPaymentMethods(paymentMethods);
        setAddresses(addresses);
        setBillingRequests(billingRequests);

        setIsSearchingContactLinks(false);

        console.log('🛠️ debug: Contact -> Cases', cases);
        console.log('🛠️ debug: Contact -> Leads', leads);
        console.log('🛠️ debug: Contact -> Payment methods', paymentMethods);
        console.log('🛠️ debug: Contact -> Addresses', addresses);
        console.log('🛠️ debug: Contact -> Billing Requests', billingRequests);
      },
    );
  };

  const getAccountLinks = () => {
    setIsSearchingAccountLinks(true);

    // 1. Account -> Invoices
    getAssociations(
      {
        recordId: selectedAccount?.id,
        key: ACCOUNT,
        schema: accountSchema!,
        entities: [INVOICE],
      },
      (res: any) => {
        const invoices = res.results?.[INVOICE]?.dbRecords || [];
        setInvoices(invoices);
        console.log('🛠️ debug: Account -> Invoices', invoices);
      },
    );

    // 2. Account -> Orders -> Work Orders
    getNestedAssociations(
      {
        recordId: selectedAccount?.id!,
        key: ACCOUNT,
        schema: accountSchema!,
        entity: ORDER,
        nestedEntities: [WORK_ORDER],
      },
      (response: any) => {
        setIsSearchingAccountLinks(false);

        const orders = response.results?.[ORDER]?.dbRecords || [];
        let workOrders: DbRecordEntityTransform[] = [];

        console.log('🛠️ debug: Account -> Orders', orders);
        setOrders(orders);
        if (orders.length > 0) {
          // Map through each order record, and get the associated work orders
          orders.forEach((order: DbRecordEntityTransform) => {
            const orderWorkOrders = order.WorkOrder?.dbRecords || [];
            workOrders = [...workOrders, ...orderWorkOrders];
          });
          setWorkOrders(workOrders);
          console.log('🛠️ debug: Account -> Orders -> WorkOrders', workOrders);
        } else {
          setWorkOrders([]);
        }
      },
    );
  };

  const getAssociatedAccountsForContact = () => {
    getAssociations(
      {
        recordId: record.id,
        key: ACCOUNT,
        schema: schema,
        entities: [ACCOUNT],
      },
      (res: any) => {
        const accounts = res.results?.[ACCOUNT]?.dbRecords || [];
        if (accounts.length > 0) {
          setAccounts(accounts);
          setSelectedAccount({
            title: accounts[0].title,
            id: accounts[0].id,
          });
        }
      },
    );
  };

  const renderAccountSelection = () => {
    const items: IAccount[] = accounts.map((a: DbRecordEntityTransform) => ({
      title: a.title || '(No Title)',
      id: a.id || '',
    }));

    const renderAccount: ItemRenderer<IAccount> = (
      account,
      { handleClick, handleFocus, modifiers, query },
    ) => {
      if (!modifiers.matchesPredicate) {
        return null;
      }
      return (
        <MenuItem
          active={account.id === selectedAccount?.id}
          disabled={modifiers.disabled}
          key={account.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="listoption"
          text={`${account.title}`}
        />
      );
    };

    const buttonText = () => {
      if (selectedAccount) {
        return selectedAccount.title;
      } else if (!selectedAccount && isSearchingContactLinks) {
        return 'Searching...';
      } else if (!isSearchingContactLinks && !accounts.length) {
        return 'No Accounts found';
      } else {
        return '';
      }
    };

    return (
      <Select<IAccount>
        items={items}
        fill
        filterable={false}
        disabled={!accounts.length}
        itemRenderer={renderAccount}
        noResults={<MenuItem disabled={true} text="No results." roleStructure="listoption" />}
        onItemSelect={setSelectedAccount}
      >
        <Button
          style={{ maxWidth: 480 }}
          fill
          disabled={!accounts.length || isSearchingAccountLinks}
          text={buttonText()}
          rightIcon="double-caret-vertical"
          placeholder="Select an Account"
          ellipsizeText
        />
      </Select>
    );
  };

  const renderActivityTab = () => {
    return (
      <Row
        style={{
          height: 'calc(100vh - 180px)',
          padding: 15,
          alignContent: 'flex-start',
          overflowY: 'auto',
        }}
        align="middle"
      >
        <Col span={24}>
          <ActivityCenter record={record} schema={schema} maxHeight={'auto'} />
        </Col>
      </Row>
    );
  };

  const thereAreContactLinks: boolean =
    cases.length > 0 ||
    leads.length > 0 ||
    paymentMethods.length > 0 ||
    addresses.length > 0 ||
    billingRequests.length > 0;

  const thereAreAccountLinks: boolean =
    invoices.length > 0 || orders.length > 0 || workOrders.length > 0;

  const renderLinksTab = () => {
    return (
      <Row
        style={{
          minHeight: 'calc(100vh - 180px)',
          padding: 15,
          alignContent: 'flex-start',
          overflowY: 'auto',
        }}
        align="middle"
      >
        {/* CONTACT LINKS ------------------------------------*/}

        <Col span={24} style={{ marginBottom: 25 }}>
          <Row justify="space-between">
            <Col>
              <h2 style={{ margin: '5px 0' }}>Contact Links</h2>
            </Col>
          </Row>
        </Col>

        {/* Loader */}
        {isSearchingContactLinks && (
          <Col span={24} style={{ height: '80%' }}>
            <NonIdealState icon={<Spinner size={34} />} description="Searching..." />
          </Col>
        )}

        {/* No Records */}
        {!isSearchingContactLinks && !thereAreContactLinks && (
          <Col span={24} style={{ height: '80%' }}>
            <NonIdealState icon="search" title="No Contact Links Found" />
          </Col>
        )}

        {/* Association Tables */}
        {!isSearchingContactLinks && thereAreContactLinks && (
          <>
            {/* 1. Contact -> Cases */}
            {cases.length > 0 && (
              <Col span={24} style={{ marginBottom: 25 }}>
                <h3 style={{ margin: '5px 3px 15px 5px', fontWeight: 500 }}>Cases</h3>
                <ContactAccountCaseTable cases={cases} />
              </Col>
            )}

            {/* 2. Contact -> Leads */}
            {leads.length > 0 && (
              <Col span={24} style={{ marginBottom: 25 }}>
                <h3 style={{ margin: '5px 3px 15px 2px', fontWeight: 500 }}>Leads</h3>
                <ContactAccountLeadTable leads={leads} />
              </Col>
            )}

            {/* 3. Contact -> Addresses */}
            {addresses.length > 0 && (
              <Col span={24} style={{ marginBottom: 25 }}>
                <h3 style={{ margin: '5px 3px 15px 2px', fontWeight: 500 }}>Addresses</h3>
                <ContactAccountAddressTable addresses={addresses} />
              </Col>
            )}

            {/* 4. Contact -> Payment Methods */}

            <Col span={24} style={{ marginBottom: 25 }}>
              <h3 style={{ margin: '5px 3px 15px 2px', fontWeight: 500 }}>Payment Methods</h3>
              <ContactAccountPaymentMethodTable paymentMethods={paymentMethods} />
            </Col>

            {/* 5. Contact -> Billing Requests */}
            {billingRequests.length > 0 && (
              <Col span={24} style={{ marginBottom: 25 }}>
                <h3 style={{ margin: '5px 3px 15px 2px', fontWeight: 500 }}>Billing Requests</h3>
                <ContactAccountBillingRequestTable billingRequests={billingRequests} />
              </Col>
            )}

            {/* ACCOUNT LINKS ------------------------------------*/}
            <Col span={24} style={{ marginTop: 35, marginBottom: 25 }}>
              <Row justify="space-between" align="middle">
                <Col>
                  <h2 style={{ margin: '5px 0' }}>Account Links</h2>
                </Col>
                <Col style={{ textAlign: 'right' }}>{renderAccountSelection()}</Col>
              </Row>
            </Col>

            {/* Account Links Loader */}
            {isSearchingAccountLinks && (
              <Col span={24} style={{ height: '80%' }}>
                <NonIdealState icon={<Spinner size={34} />} description="Searching..." />
              </Col>
            )}

            {/* No Account Links */}
            {!isSearchingAccountLinks && !thereAreAccountLinks && (
              <Col span={24} style={{ height: '80%', margin: '20px 0' }}>
                <NonIdealState icon="search" title="No Account Links Found" />
              </Col>
            )}

            {/* 6. Account -> Invoices */}
            {invoices.length > 0 && (
              <Col span={24} style={{ marginBottom: 25 }}>
                <h3 style={{ margin: '5px 3px 15px 5px', fontWeight: 500 }}>Invoices</h3>
                <ContactAccountInvoiceTable invoices={invoices} />
              </Col>
            )}

            {/* 7. Account -> Orders */}
            {orders.length > 0 && (
              <Col span={24} style={{ marginBottom: 25 }}>
                <h3 style={{ margin: '5px 3px 15px 2px', fontWeight: 500 }}>Orders</h3>
                <ContactAccountOrderTable orders={orders} />
              </Col>
            )}

            {/* 8. Account -> Orders -> Work Orders */}
            {workOrders.length > 0 && (
              <Col span={24} style={{ marginBottom: 25 }}>
                <h3 style={{ margin: '5px 3px 15px 2px', fontWeight: 500 }}>Work Orders</h3>
                <ContactAccountWorkOrderTable workOrders={workOrders} />
              </Col>
            )}
          </>
        )}
      </Row>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Section
          title={
            <Tabs
              defaultSelectedTabId={selectedTab}
              selectedTabId={selectedTab}
              onChange={(e: any) => setSelectedTab(e)}
            >
              <Tabs.Tab id="Links" title="Links" />
              <Tabs.Tab id="Activity" title="Activity" />
            </Tabs>
          }
        >
          {selectedTab === 'Links' && renderLinksTab()}
          {selectedTab === 'Activity' && renderActivityTab()}
        </Section>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(ContactAccountAssociations);
