import { DoubleLeftOutlined, DoubleRightOutlined } from '@ant-design/icons';
import { Button, Icon, Section } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Descriptions, Divider, Row, Skeleton, Tooltip } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBrowserPath, splitModuleAndEntityName } from '@core/helpers/recordHelpers';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import Typography from '@core/components/Typography';
import ListActionMenu from '../../../recordsAssociations/components/ListActions/ListActionMenu';
import ListItemActionMenu from '../../../recordsAssociations/components/ListActions/ListItemActionMenu';
import {
  getRecordAssociationsRequest,
  getRecordAssociationWithNestedEntitiesRequest,
  IGetRecordAssociations,
  IGetRecordAssociationWithNestedEntities,
} from '../../../recordsAssociations/store/actions';
import ModuleEntityIcon from '../../../theme/ModuleEntityIcon';
import { IOpenRecordDrawer, openRecordDrawer } from '../../../userInterface/store/actions';
import { getRecordByIdRequest, IGetRecordById, ISearchRecords, searchRecordsRequest } from '../../store/actions';
import RecordCardMissing from './RecordCardMissing';
import './styles.scss';
import { renderBooleanValue } from '@core/helpers/UIHelpers';

interface Props {
  associatedRecordCondition?: string;
  associatedRecordEntityName?: string;
  associatedRecordModuleName?: string;
  borderless?: boolean;
  cardBodyOpacity?: number;
  customCardBody?: JSX.Element;
  customTitle?: JSX.Element;
  disableTitleLink?: boolean;
  displayQuickView?: boolean;
  footerElements?: JSX.Element[] | undefined;
  getAssociations: Function;
  getOnlyFirstAssociation?: boolean;
  headerElement?: JSX.Element;
  hideIcon?: boolean;
  invertIcon?: boolean;
  hideRecordNumber?: boolean;
  hideRecordTitle?: boolean;
  onListActionSuccess?: Function;
  onCreateActionSuccess?: any;
  openTitleLinkInNewTab?: boolean;
  propertiesLayout?: 'horizontal' | 'vertical';
  propertyColumns?: number;
  record: DbRecordEntityTransform;
  showActionMenu?: boolean;
  showItemActionMenu?: boolean;
  showRecordStage?: boolean;
  showCreatedBy?: boolean;
  showRecordTitleAsProperty?: boolean;
  showWithoutPagination?: boolean;
  title?: string;
  visibleProperties: string[];
  shouldPollData?: boolean;
  pollingIntervalSeconds?: number; // in seconds
  paymentMethodAddressId?: string; // we pass this to payment method form in the list action menu
  fullHeightCard?: boolean;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

// Polling data
let timer: NodeJS.Timeout | undefined = undefined;

const RecordCard: React.FC<Props> = (props: Props) => {
  const {
    record,
    associatedRecordEntityName,
    associatedRecordModuleName,
    visibleProperties,
    getAssociations,
    displayQuickView,
    hideRecordNumber,
    associatedRecordCondition,
    showWithoutPagination,
    headerElement,
    showActionMenu,
    showItemActionMenu,
    showCreatedBy,
    hideIcon,
    invertIcon,
    footerElements,
    hideRecordTitle,
    customTitle,
    shouldPollData,
    pollingIntervalSeconds,
    paymentMethodAddressId,
    onCreateActionSuccess,
    fullHeightCard,
    openDrawer,
  } = props;

  const [recordId, setRecordId] = useState<string | undefined>(undefined);
  const [association, setAssociation] = useState<any>(undefined);
  const [targetRecord, setTargetRecord] = useState<DbRecordEntityTransform[]>([]);
  const [currentRecord, setCurrentRecord] = useState<number>(0);
  const [isLoadingTargetRecord, setIsLoadingTargetRecord] = useState<boolean>(true);
  const [parentRecordSchema, setParentRecordSchema] = useState<SchemaEntity | undefined>(undefined);
  const [associatedRecordSchema, setAssociatedRecordSchema] = useState<SchemaEntity | undefined>(
    undefined,
  );

  const startTimer = (interval: number) => {
    timer = setInterval(() => {
      if (!document.hidden) {
        getAssociatedRecord(true);
      }
    }, interval);
  };
  const clearTimer = () => {
    clearInterval(timer);
    timer = undefined;
  };

  // Set timer on mount by schema availability
  useEffect(() => {
    if (shouldPollData && parentRecordSchema && !timer) {
      startTimer(pollingIntervalSeconds ? pollingIntervalSeconds * 1000 : 10000);
    }
  }, [parentRecordSchema]);

  // Unmount timer
  useEffect(() => {
    return () => {
      clearTimer();
    };
  }, []);

  const previousRecordId = usePrevious(recordId);

  const splitCamelCase = (str: string) => {
    return str?.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
  };

  // Get associated record
  const getAssociatedRecord = (withoutLoader?: boolean) => {
    if (!withoutLoader) setIsLoadingTargetRecord(true);

    if (associatedRecordEntityName && associatedRecordModuleName) {
      getAssociations(
        {
          recordId: record?.id,
          schema: parentRecordSchema,
          entities: [associatedRecordEntityName],
          associatedRecordCondition,
        },
        (res: any) => {
          if (res) {
            setIsLoadingTargetRecord(false);
            setAssociation(res?.results?.[associatedRecordEntityName]);
            const associations = res.results?.[associatedRecordEntityName]?.dbRecords;
            if (associations?.length > 0) {
              if (props.getOnlyFirstAssociation) {
                const firstRelation = associations[0];
                setTargetRecord([firstRelation]);
              } else {
                setTargetRecord(associations);
              }
            } else {
              setTargetRecord([]);
            }
          } else {
            setIsLoadingTargetRecord(false);
          }
        },
      );
    } else {
      setIsLoadingTargetRecord(false);
    }
  };

  // Custom hook to leverage previous state monitoring in react hooks
  function usePrevious<T>(value: T): T {
    const ref: any = useRef<T>();
    useEffect(() => {
      ref.current = value;
    }, [value]);
    return ref.current;
  }

  // If the previous record id is not the same as the current one, fetch the associations again.
  // We introduced this because when switching the in-app tabs, component stays mounted but the
  // record changes. This way we can monitor the record prop and fetch associations.
  useEffect(() => {
    if (previousRecordId && recordId !== previousRecordId && !isLoadingTargetRecord) {
      getAssociatedRecord();
    }
  }, [recordId]);

  // When parent record is passed, fetch either record or the associations
  useEffect(() => {
    // If the record is passed, store the id in the local state
    if (record && record?.id !== recordId) {
      setRecordId(record?.id);
    }

    // Only record is passed, use this as the targetRecord
    if (
      record &&
      !targetRecord.length &&
      !associatedRecordEntityName &&
      !associatedRecordModuleName &&
      parentRecordSchema
    ) {
      setTargetRecord([record]);
      setIsLoadingTargetRecord(false);
    }

    // Associated module and entity names are passed, use these to fetch the targetRecord
    else if (
      record &&
      !targetRecord.length &&
      associatedRecordEntityName &&
      associatedRecordModuleName &&
      parentRecordSchema
    ) {
      getAssociatedRecord();
    }
  }, [parentRecordSchema, record]);

  // When record is available, get parent and associated schema
  useEffect(() => {
    if (record) {
      getSchemas();
    }
  }, [record]);

  // Get parent and associated schemas
  const getSchemas = async () => {
    const parentRecordEntity = record?.entity;
    const { moduleName, entityName } = splitModuleAndEntityName(parentRecordEntity!);

    if (!parentRecordSchema && moduleName && entityName) {
      const schema = await getOdinSchemaByEntity(moduleName, entityName);
      setParentRecordSchema(schema);
    }
    if (
      !associatedRecordSchema &&
      associatedRecordEntityName &&
      associatedRecordModuleName &&
      entityName
    ) {
      const schema = await getOdinSchemaByEntity(
        associatedRecordModuleName,
        associatedRecordEntityName,
      );
      setAssociatedRecordSchema(schema);
    }
  };

  const renderRecordProperty = (property: string) => {
    // "Email Address" is just too long...
    if (property === 'EmailAddress') {
      return 'Email';
    } else {
      return property ? splitCamelCase(property) : '';
    }
  };

  const renderRecordPropertyValue = (targetRecord: DbRecordEntityTransform, property: string) => {
    const isColumnBoolean = (property: string) => {
      let schema =
        associatedRecordModuleName && associatedRecordEntityName
          ? associatedRecordSchema
          : parentRecordSchema;

      if (schema) {
        const column = schema?.columns?.find(
          (column: SchemaColumnEntity) => column.name === property,
        );
        if (column && column.type === 'BOOLEAN') {
          return true;
        } else return false;
      } else {
        return false;
      }
    };

    const isColumnDateTime = (property: string) => {
      let schema =
        associatedRecordModuleName && associatedRecordEntityName
          ? associatedRecordSchema
          : parentRecordSchema;

      if (schema) {
        const column = schema?.columns?.find(
          (column: SchemaColumnEntity) => column.name === property,
        );
        if (column && column.type === 'DATE_TIME') {
          return true;
        } else return false;
      } else {
        return false;
      }
    };

    const isColumnDate = (property: string) => {
      let schema =
        associatedRecordModuleName && associatedRecordEntityName
          ? associatedRecordSchema
          : parentRecordSchema;

      if (schema) {
        const column = schema?.columns?.find(
          (column: SchemaColumnEntity) => column.name === property,
        );
        if (column && column.type === 'DATE') {
          return true;
        } else return false;
      } else {
        return false;
      }
    };

    const isColumnPhoneNumber = (property: string) => {
      return property === 'Phone' || property === 'Mobile';
    };

    // BOOLEAN
    if (isColumnBoolean(property)) {
      return renderBooleanValue(getProperty(targetRecord, property));
    }
    // DATE_TIME
    else if (isColumnDateTime(property)) {
      return dayjs(getProperty(targetRecord, property)).format('DD/MM/YYYY HH:mm:ss');
    }
    // DATE
    else if (isColumnDate(property)) {
      return dayjs(getProperty(targetRecord, property)).format('DD/MM/YYYY');
    }

    // ELSE
    else {
      return getProperty(targetRecord, property) || '-';
    }
  };

  // Render record properties, or show error if none are passed.
  const renderRecordProperties = (targetRecord: DbRecordEntityTransform | undefined) => {
    if (props.customCardBody) {
      return <Col span={24}>{props.customCardBody}</Col>;
    } else if (targetRecord && visibleProperties.length > 0 && !props.customCardBody) {
      return (
        <Descriptions
          bordered
          size="small"
          layout={props.propertiesLayout || 'horizontal'}
          column={isMobile ? 1 : props.propertyColumns || 1}
          className="recordCardDescriptions"
          style={{ opacity: props.cardBodyOpacity || 1, width: '100%' }}
          labelStyle={{ color: 'black', fontWeight: 400, fontSize: 12, padding: '2px 7px' }}
          contentStyle={{ fontSize: 12, padding: '2px 7px' }}
        >
          {targetRecord.recordNumber && !hideRecordNumber ? (
            <Descriptions.Item key={targetRecord.recordNumber} label="Record number">
              {targetRecord.recordNumber}
            </Descriptions.Item>
          ) : (
            <></>
          )}

          {/* Show Record title as Property? */}
          {props.showRecordTitleAsProperty ? (
            <Descriptions.Item key={targetRecord.title || 1} label="Title">
              {targetRecord.title || '-'}
            </Descriptions.Item>
          ) : (
            <></>
          )}

          {/* All specified visible properties */}
          {visibleProperties.map((property: string) => {
            return (
              <Descriptions.Item
                key={property}
                contentStyle={{ marginBottom: props.propertiesLayout === 'vertical' ? 15 : 0 }}
                label={renderRecordProperty(property)}
              >
                {renderRecordPropertyValue(targetRecord, property)}
              </Descriptions.Item>
            );
          })}

          {/* Show Record stage? */}
          {targetRecord.stage?.name ? (
            <Descriptions.Item key="stage" label="Stage">
              {targetRecord.stage?.name || '-'}
            </Descriptions.Item>
          ) : (
            <></>
          )}

          {/* Show CreatedBy */}
          {targetRecord.createdBy?.fullName && showCreatedBy ? (
            <Descriptions.Item key={targetRecord.createdBy?.fullName} label="Created by">
              {targetRecord.createdBy?.fullName}
            </Descriptions.Item>
          ) : (
            <></>
          )}
        </Descriptions>
      );
    } else {
      return <></>;
    }
  };

  const shouldShowActionMenu = () => {
    if (showActionMenu) {
      return true;
    } else if (
      associatedRecordEntityName &&
      associatedRecordModuleName &&
      targetRecord.length === 0 &&
      showActionMenu
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onRecordCreate = (recordId: string) => {
    if (onCreateActionSuccess) {
      onCreateActionSuccess(recordId);
    }
    getAssociatedRecord();
  };

  const recordInformation = (record: DbRecordEntityTransform, i?: number) => {
    return (
      <>
        <div
          style={{ padding: '10px 15px' }}
          key={associatedRecordEntityName}
          // bordered={false}
          className={`recordCard ${
            targetRecord.length > 1 ? 'manyRecordCard' : 'singleRecordCard'
          }`}
        >
          {
            <Skeleton
              loading={isLoadingTargetRecord}
              active={isLoadingTargetRecord}
              key={associatedRecordEntityName}
            >
              {targetRecord.length > 0 ? (
                <>
                  <Row
                    justify="space-around"
                    className="accentedRow"
                    style={{ opacity: props.cardBodyOpacity || 1 }}
                  >
                    {hideRecordTitle ? (
                      <></>
                    ) : (
                      <Col span={displayQuickView || showItemActionMenu ? 17 : 24}>
                        <span style={{ fontWeight: 500, fontSize: '0.9em' }}>
                          {props.disableTitleLink ? (
                            <span>{record?.title}</span>
                          ) : (
                            <Link
                              to={getBrowserPath(record)}
                              target={props.openTitleLinkInNewTab ? '_blank' : '_self'}
                            >
                              <Typography strong>{record?.title}</Typography>
                            </Link>
                          )}
                        </span>
                      </Col>
                    )}

                    {displayQuickView || showItemActionMenu ? (
                      <Col span={7} style={{ textAlign: 'right' }}>
                        {displayQuickView ? (
                          <Button
                            small
                            outlined
                            style={{ verticalAlign: 'middle' }}
                            icon={<Icon icon="eye-open" />}
                            onClick={() =>
                              openDrawer({
                                recordId: record?.id,
                                moduleName: record?.entity?.split(':')[0] || '',
                                entityName: record?.entity?.split(':')[1] || '',
                              })
                            }
                          />
                        ) : (
                          <></>
                        )}
                        {showItemActionMenu ? (
                          <div style={{ marginLeft: 7, display: 'inline-block' }}>
                            <ListItemActionMenu
                              onListItemActionSuccess={listActionSuccess}
                              relatedRecord={record}
                              record={props.record}
                              relation={association}
                            />
                          </div>
                        ) : (
                          <></>
                        )}
                      </Col>
                    ) : (
                      <></>
                    )}
                  </Row>
                  <Row>{renderRecordProperties(record)}</Row>
                  {footerElements ? (
                    <div style={{ opacity: props.cardBodyOpacity || 1 }}>
                      <Col span={24}>
                        <Divider style={{ marginTop: 15, marginBottom: 10 }} />
                      </Col>
                      <Col span={24}>{footerElements.map((element: any) => element)}</Col>
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <Row>
                  <Col span={24} style={{ textAlign: 'center', padding: 10 }}>
                    <RecordCardMissing
                      record={props.record}
                      associatedRecordEntityName={associatedRecordEntityName}
                      associatedRecordModuleName={associatedRecordModuleName}
                      onSuccess={onRecordCreate}
                    />
                  </Col>
                </Row>
              )}
            </Skeleton>
          }
        </div>
      </>
    );
  };

  // Callback for list actions.
  const listActionSuccess = () => {
    getAssociatedRecord();

    if (props.onListActionSuccess) {
      props.onListActionSuccess();
    }
  };

  const renderRecordCard = () => {
    const handlePreviousRecord = () => {
      if (currentRecord > 0) {
        setCurrentRecord(currentRecord - 1);
      }
    };

    const handleNextRecord = () => {
      if (currentRecord < targetRecord.length - 1) {
        setCurrentRecord(currentRecord + 1);
      }
    };

    let { moduleName, entityName } = splitModuleAndEntityName(targetRecord[0]?.entity!);

    return (
      <Section
        style={{ marginBottom: 10, height: fullHeightCard ? '100%' : 'auto' }}
        className="recordCardSection"
        icon={
          !hideIcon ? (
            <ModuleEntityIcon
              iconContainerStyle={{ padding: '3px 7px', marginRight: 0 }}
              style={{ fontSize: '0.9em' }}
              inverted={invertIcon}
              moduleName={moduleName || associatedRecordModuleName || ''}
              entityName={entityName || associatedRecordEntityName || ''}
            />
          ) : (
            <></>
          )
        }
        rightElement={
          <>
            {shouldShowActionMenu() ? (
              <div style={{ marginLeft: 0, paddingTop: -2 }}>
                <ListActionMenu
                  onActionSuccess={listActionSuccess}
                  compact
                  borderlessButton
                  record={props.record}
                  relation={association}
                  paymentMethodAddressId={paymentMethodAddressId}
                />
              </div>
            ) : (
              <></>
            )}

            {headerElement && targetRecord.length > 0 ? headerElement : <></>}
          </>
        }
        title={
          <span>
            {customTitle}
            {entityName && !associatedRecordEntityName && !customTitle
              ? splitCamelCase(entityName)
              : ''}
            {associatedRecordEntityName && !customTitle
              ? splitCamelCase(associatedRecordEntityName)
              : ''}
          </span>
        }
      >
        {showWithoutPagination && targetRecord.length > 0
          ? targetRecord.map((record: DbRecordEntityTransform, i: number) => {
            return recordInformation(record, i + 1);
          })
          : recordInformation(targetRecord[currentRecord])}

        {
          <Row
            style={{
              marginTop: 10,
              marginLeft: 10,
              marginRight: 10,
              marginBottom: 5,
              display: targetRecord.length > 1 && !showWithoutPagination ? 'flex' : 'none',
            }}
          >
            <Col span={12}>
              <span>{`${currentRecord + 1} / ${targetRecord.length}`}</span>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Tooltip title="Previous Record" mouseEnterDelay={1}>
                <Button
                  small
                  outlined
                  icon={<DoubleLeftOutlined />}
                  style={{
                    border: 'none',
                    marginRight: 5,
                    display:
                      targetRecord.length > 1 && !showWithoutPagination ? 'inline-block' : 'none',
                  }}
                  disabled={currentRecord === 0 || targetRecord.length === 1}
                  onClick={() => handlePreviousRecord()}
                />
              </Tooltip>
              <Tooltip title="Next Record" mouseEnterDelay={1}>
                <Button
                  small
                  outlined
                  style={{
                    border: 'none',
                    display:
                      targetRecord.length > 1 && !showWithoutPagination ? 'inline-block' : 'none',
                  }}
                  icon={<DoubleRightOutlined />}
                  disabled={currentRecord === targetRecord.length - 1 || targetRecord.length === 1}
                  onClick={() => handleNextRecord()}
                />
              </Tooltip>
            </Col>
          </Row>
        }
      </Section>
    );
  };

  return renderRecordCard();
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getNestedAssociations: (params: IGetRecordAssociationWithNestedEntities, cb: any) =>
    dispatch(getRecordAssociationWithNestedEntitiesRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(RecordCard);
