export const GET_ROLES_DATA_REQUEST = 'GET_ROLES_DATA_REQUEST';
export const GET_ROLES_DATA_SUCCESS = 'GET_ROLES_DATA_SUCCESS';
export const GET_ROLES_DATA_ERROR = 'GET_ROLES_DATA_ERROR';

export const SET_ASSIGN_ROLE_MODAL_VISIBLE = 'SET_ASSIGN_ROLE_MODAL_VISIBLE';

export const CREATE_ROLE_REQUEST = 'CREATE_ROLE_REQUEST';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_ERROR = 'CREATE_ROLE_ERROR';

export const GET_ROLE_BY_ID_REQUEST = 'GET_ROLE_BY_ID_REQUEST';
export const GET_ROLE_BY_ID_SUCCESS = 'GET_ROLE_BY_ID_SUCCESS';
export const GET_ROLE_BY_ID_ERROR = 'GET_ROLE_BY_ID_ERROR';

export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR';

export const GET_ROLES_LINKS_REQUEST = 'GET_ROLES_LINKS_REQUEST';
export const GET_ROLES_LINKS_SUCCESS = 'GET_ROLES_LINKS_SUCCESS';
export const GET_ROLES_LINKS_ERROR = 'GET_ROLES_LINKS_ERROR';

export const ASSIGN_PERMISSIONS_TO_ROLE_REQUEST = 'ASSIGN_PERMISSIONS_TO_ROLE_REQUEST';
export const ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS = 'ASSIGN_PERMISSIONS_TO_ROLE_SUCCESS';
export const ASSIGN_PERMISSIONS_TO_ROLE_ERROR = 'ASSIGN_PERMISSIONS_TO_ROLE_ERROR';

export const ASSIGN_USERS_TO_ROLE_REQUEST = 'ASSIGN_USERS_TO_ROLE_REQUEST';
export const ASSIGN_USERS_TO_ROLE_SUCCESS = 'ASSIGN_USERS_TO_ROLE_SUCCESS';
export const ASSIGN_USERS_TO_ROLE_ERROR = 'ASSIGN_USERS_TO_ROLE_ERROR';

export const ASSIGN_ROLE_TO_ROLE_REQUEST = 'ASSIGN_ROLE_TO_ROLE_REQUEST';
export const ASSIGN_ROLE_TO_ROLE_SUCCESS = 'ASSIGN_ROLE_TO_ROLE_SUCCESS';
export const ASSIGN_ROLE_TO_ROLE_ERROR = 'ASSIGN_ROLE_TO_ROLE_ERROR';

export const DELETE_ROLE_LINK_REQUEST = 'DELETE_ROLE_LINK_REQUEST';
export const DELETE_ROLE_LINK_SUCCESS = 'DELETE_ROLE_LINK_SUCCESS';
export const DELETE_ROLE_LINK_ERROR = 'DELETE_ROLE_LINK_ERROR';
