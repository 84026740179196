import React from 'react';
import { Button, Dialog } from '@blueprintjs/core';

interface Props {
  conversation: any;
  showEndConversationAlert: boolean;
  setShowEndConversationAlert: (value: boolean) => void;
  handleConfirmEndConversation: () => void;
  isEndingConversation: boolean;
}

const ChatTopBar = ({ conversation, showEndConversationAlert, setShowEndConversationAlert, handleConfirmEndConversation, isEndingConversation }: Props) => {
  return (
    <div className="chat-top-bar-container">
      <div className="chat-top-bar-items-container">
        <div className="chat-top-bar-left-side">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z"></path>
          </svg>
          <span style={{ fontWeight: 500 }}>{conversation?.title || '-'}</span>
        </div>
        {/* <div className="chat-top-bar-right-side">
          <button className="shadcn-btn btn-danger" onClick={() => setShowEndConversationAlert(true)}>End Chat</button>
        </div> */}
        {/* <div className="chat-top-bar-right-side">
                              <span
                                className="chat-button mr-20"
                                onClick={() => setOpenParticipantDrawer(true)}
                              >
                                <PersonPlus />
                              </span>
                              <span className="chat-button">
                                <InformationIcon />
                              </span>
                            </div> */}
      </div>
      <Dialog
        className="conversation-details-alert"
        isOpen={showEndConversationAlert}
        onClose={() => setShowEndConversationAlert(false)}
      >
        <div className="conversation-details-alert-body">
          <div className="conversation-details-alert-title">
            <h2 className="dialog-title">Are you sure absolutely sure?</h2>
            <p>
              This action cannot be undone. You are about to end this conversation.
            </p>
          </div>
        </div>
        <div className="conversation-details-alert-footer">
          <Button
            className="conversation-details-alert-footer-cancel-btn"
            onClick={() => setShowEndConversationAlert(false)}
          >
            Cancel
          </Button>
          <Button
            className="conversation-details-alert-footer-submit-btn"
            intent="danger"
            onClick={handleConfirmEndConversation}
            style={{
              opacity: isEndingConversation ? '.5' : '',
            }}
            disabled={isEndingConversation}
          >
            Delete
          </Button>
        </div>
      </Dialog>
    </div>
  );
};

export default ChatTopBar;
