import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { WorkOrderBundle } from '../types';
import {
  ADD_WORK_ORDERS_TO_ENGINEER,
  SET_ALL_WORK_ORDERS,
  SET_ASSIGN_MODAL_VISIBLE,
  SET_ASSIGNING_TO_ENGINEER_ID,
  SET_REMOTE_STATS,
  SET_UNASSIGNED_WORK_ORDERS,
  SET_WORK_ORDERS_TO_ASSIGN,
  TOGGLE_ASSIGN_MODE,
} from './constants';

type TRemoteStats = {
  totalCancellations: number;
  totalReschedules: number;
  totalDelays: number;
  requestedBySupplier: number;
  requestedByTenant: number;
  rescheduleReasonSummary: any;
  cancelReasonSummary: any;
  delayReasonSummary: any;
  dateShiftSummary: any;
};

export interface IHourlyViewReducer {
  allWorkOrders: any[];
  unassignedWorkOrders: any[];
  remoteStats: TRemoteStats | undefined;
  assigningToEngineerId: string | undefined;
  assignModalVisible: boolean;
  workOrdersToAssign: DbRecordEntityTransform[];
  isAssigningWorkOrders: boolean;
}

export const hourlyViewInitialState: IHourlyViewReducer = {
  allWorkOrders: [],
  unassignedWorkOrders: [],
  remoteStats: undefined,
  assigningToEngineerId: undefined,
  assignModalVisible: false,
  workOrdersToAssign: [],
  isAssigningWorkOrders: false,
};

export function hourlyViewReducer(
  state: IHourlyViewReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    // lists
    case SET_ALL_WORK_ORDERS:
      return {
        ...state,
        allWorkOrders: action.payload,
      };
    case SET_UNASSIGNED_WORK_ORDERS:
      return {
        ...state,
        unassignedWorkOrders: action.payload,
      };

    // assigning
    case SET_ASSIGNING_TO_ENGINEER_ID:
      return {
        ...state,
        assigningToEngineerId: action.payload,
      };

    case SET_ASSIGN_MODAL_VISIBLE:
      return {
        ...state,
        assignModalVisible: action.payload,
      };

    case TOGGLE_ASSIGN_MODE:
      return {
        ...state,
        isAssigningWorkOrders: !state.isAssigningWorkOrders,
      };

    case SET_WORK_ORDERS_TO_ASSIGN:
      return {
        ...state,
        workOrdersToAssign: action.payload,
      };

    // stats
    case SET_REMOTE_STATS:
      return {
        ...state,
        remoteStats: action.payload,
      };

    // optimistic reducer updates
    case ADD_WORK_ORDERS_TO_ENGINEER:
      /*
              workOrdersToAssign: DbRecordEntityTransform[]
              engineerId: string
            */
      const engineerId = action.payload.engineerId;
      const workOrdersToAssign: DbRecordEntityTransform[] = action.payload.workOrdersToAssign;

      let newUnassignedWorkOrders = state.unassignedWorkOrders.filter((wo: any) => {
        const ids = workOrdersToAssign.map((wo) => wo.id);
        return !ids.includes(wo.id);
      });

      // Add to new engineer and remove from the old one, if needed
      let newAllWorkOrders = Object.assign([], state.allWorkOrders);
      newAllWorkOrders.map((WO: WorkOrderBundle) => {
        if (WO.engineer.id === engineerId) {
          WO.workOrders = [...WO.workOrders, ...workOrdersToAssign];
        }

        if (WO.engineer.id !== engineerId) {
          WO.workOrders = WO.workOrders.filter(
            (wo: DbRecordEntityTransform) => !workOrdersToAssign.includes(wo),
          );
        }
      });
      return {
        ...state,
        allWorkOrders: newAllWorkOrders,
        unassignedWorkOrders: newUnassignedWorkOrders,
      };

    default:
      return state;
  }
}
