import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { Input, Modal, Space } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';

const { CREDIT_NOTE } = SchemaModuleEntityTypeEnums;

interface Props {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  alertMessage: any;
  hidden?: string[];
  refundSource?: string;
}

class RefundTransaction extends React.Component<Props> {
  state = { visible: false, isLoading: false, amount: 0, refundId: null };

  showModal = () => {
    const { record } = this.props;

    this.setState({
      visible: true,
      amount: getProperty(record, 'Amount'),
    });
  };

  handleOk = async (e: any) => {
    const { schemaReducer, record, alertMessage, refundSource } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    this.setState({
      isLoading: true,
    });

    if (record && schema) {
      // ODN-1545 set path to refund api depending on refundSource
      const path =
        refundSource === CREDIT_NOTE
          ? `BillingModule/v1.0/transactions/credit-note/${record.id}/refund`
          : `BillingModule/v1.0/transactions/${record.id}/refund`;

      await httpPost(path, {
        amount: this.state.amount,
        refundId: this.state.refundId,
      })
        .then((res) => {
          this.setState({
            visible: false,
            isLoading: false,
          });

          alertMessage({ body: 'refund transaction created', type: 'success' });
        })
        .catch((error) => {
          this.setState({
            isLoading: false,
            visible: false,
          });

          alertMessage({ body: error.response.data.message, type: 'error' });
        });
    }
  };

  handleCancel = (e: any) => {
    this.setState({
      visible: false,
      isLoading: false,
    });
  };

  render() {
    return (
      <div>
        <Button intent="primary" outlined onClick={this.showModal}>
          Refund
        </Button>

        <Dialog
          title="Refund Transaction"
          isOpen={this.state.visible}
          onClose={this.handleCancel}
          isCloseButtonShown={!this.state.isLoading}
        >
          <DialogBody>
            <Input
              width={300}
              type="number"
              disabled={this.state.isLoading}
              placeholder="amount"
              value={this.state.amount}
              onChange={(e) => this.setState({ amount: e.target.value })}
            />
          </DialogBody>
          <DialogFooter
            actions={
              <>
                <Button text="Cancel" disabled={this.state.isLoading} onClick={this.handleCancel} />
                <Button
                  text="OK"
                  intent="primary"
                  disabled={this.state.isLoading}
                  onClick={this.handleOk}
                />
              </>
            }
          />
        </Dialog>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(RefundTransaction);
