import { Button, Callout, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row, Spin } from 'antd';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { sendConfirmationEmail } from '@legacy/core/notifications/email/store/actions';
import { getRecordByIdRequest, IGetRecordById } from '@legacy/core/records/store/actions';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
interface Props {
  record: DbRecordEntityTransform;
  schemaReducer: any;
  alertMessage: (params: { body: string; type: string }) => void;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
}

const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { BILLING_REQUEST } = SchemaModuleEntityTypeEnums;

const BillingRequestCancel: React.FC<Props> = (props: Props) => {
  const { schemaReducer, record, alertMessage, getRecordById } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [isCancellationStarted, setIsCancellationStarted] = useState<boolean>(false);
  const [error, setError] = useState<any>(undefined);

  const closeModal = () => {
    setError(undefined);
    setIsDialogOpen(false);
  };

  const startBillingRequestCancellation = () => {
    setIsDialogOpen(true);

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (record && schema) {
      setIsCancellationStarted(true);

      httpPost(`${BILLING_MODULE}/v1.0/billing-requests/${record.id}/cancel`, {})
        .then(() => {
          setIsCancellationStarted(false);
          closeModal();
          getRecordById({ schema: schema, recordId: record.id });
          alertMessage({
            body: 'Billing request cancelled',
            type: 'success',
          });
        })
        .catch((err: any) => {
          const error = err.response ? err.response.data : undefined;
          setIsCancellationStarted(false);
          setError(error);
        });
    }
  };

  return (
    <>
      <Button text="Cancel" intent="danger" outlined onClick={() => setIsDialogOpen(true)} />
      <Dialog
        title="Cancel Billing Request"
        icon="info-sign"
        isOpen={isDialogOpen}
        onClose={closeModal}
        isCloseButtonShown={!isCancellationStarted}
      >
        <DialogBody>
          {/* Confirmation */}
          {!isCancellationStarted && !error && (
            <p>Please confirm that you would like to cancel this billing request.</p>
          )}

          {/* Is Sending... */}
          {isCancellationStarted && !error && (
            <Row style={{ textAlign: 'center' }}>
              <Col span={24} style={{ marginTop: 20 }}>
                <Spin size="large" />
              </Col>
              <Col span={24} style={{ marginTop: 20 }}>
                <p>Sending...</p>
              </Col>
            </Row>
          )}

          {/* Error state */}
          {error && (
            <Callout intent="danger">
              <span>{error}</span>
            </Callout>
          )}
        </DialogBody>
        <DialogFooter
          actions={
            <>
              <Button
                text="Cancel"
                disabled={isCancellationStarted}
                onClick={() => setIsDialogOpen(false)}
              />
              <Button
                intent="primary"
                text="Confirm"
                disabled={isCancellationStarted || error}
                onClick={startBillingRequestCancellation}
              />
            </>
          }
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(BillingRequestCancel);
