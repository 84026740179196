export const GET_PIPELINES_BY_MODULE_REQUEST = 'GET_PIPELINES_BY_MODULE_REQUEST';
export const GET_PIPELINES_BY_MODULE_SUCCESS = 'GET_PIPELINES_BY_MODULE_SUCCESS';
export const GET_PIPELINES_BY_MODULE_ERROR = 'GET_PIPELINES_BY_MODULE_ERROR';

export const GET_PIPELINES_BY_MODULE_AND_ENTITY_REQUEST =
  'GET_PIPELINES_BY_MODULE_AND_ENTITY_REQUEST';
export const GET_PIPELINES_BY_MODULE_AND_ENTITY_SUCCESS =
  'GET_PIPELINES_BY_MODULE_AND_ENTITY_SUCCESS';
export const GET_PIPELINES_BY_MODULE_AND_ENTITY_ERROR = 'GET_PIPELINES_BY_MODULE_AND_ENTITY_ERROR';

export const GET_PIPELINE_BY_STAGE_ID_REQUEST = 'GET_PIPELINE_BY_STAGE_ID_REQUEST';
export const GET_PIPELINE_BY_STAGE_ID_SUCCESS = 'GET_PIPELINE_BY_STAGE_ID_SUCCESS';
export const GET_PIPELINE_BY_STAGE_ID_ERROR = 'GET_PIPELINE_BY_STAGE_ID_ERROR';

export const CREATE_PIPELINE_REQUEST = 'CREATE_PIPELINE_REQUEST';
export const CREATE_PIPELINE_SUCCESS = 'CREATE_PIPELINE_SUCCESS';
export const CREATE_PIPELINE_ERROR = 'CREATE_PIPELINE_ERROR';

export const DELETE_PIPELINE_REQUEST = 'DELETE_PIPELINE_REQUEST';
export const DELETE_PIPELINE_SUCCESS = 'DELETE_PIPELINE_SUCCESS';
export const DELETE_PIPELINE_ERROR = 'DELETE_PIPELINE_ERROR';

export const CREATE_PIPELINE_STAGE_REQUEST = 'CREATE_PIPELINE_STAGE_REQUEST';
export const CREATE_PIPELINE_STAGE_SUCCESS = 'CREATE_PIPELINE_STAGE_SUCCESS';
export const CREATE_PIPELINE_STAGE_ERROR = 'CREATE_PIPELINE_STAGE_ERROR';

export const DELETE_PIPELINE_STAGE_REQUEST = 'DELETE_PIPELINE_STAGE_REQUEST';
export const DELETE_PIPELINE_STAGE_SUCCESS = 'DELETE_PIPELINE_STAGE_SUCCESS';
export const DELETE_PIPELINE_STAGE_ERROR = 'DELETE_PIPELINE_STAGE_ERROR';

export const SET_PIPELINE_REDUCER_STATE = 'SET_PIPELINE_REDUCER_STATE';
