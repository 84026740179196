import { Button, Col, Descriptions, Divider, Drawer, Row, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import {
  IMapSetWorkItemQuickView,
  IMapUpdateWorkItems,
  MapReducerUpdate,
  resetWorkItemForm,
  setWorkItemQuickView,
  updateMapState,
  updateWorkItems,
} from '@netomnia/modules/ProjectModule/Map/store/actions';
import { connect } from 'react-redux';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { Link } from 'react-router-dom';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { getBrowserPath } from '../../../../../../../core/helpers/recordHelpers';
import './styles.scss';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { EditOutlined, ExportOutlined, QuestionOutlined } from '@ant-design/icons';
import {
  QGISBuildStatusEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { v4 as uuidv4 } from 'uuid';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getRecordByIdRequest, IGetRecordById } from '@legacy/core/records/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../../core/helpers/schemaHelpers';
import { getSchemaByIdRequest } from '@legacy/core/schemas/store/actions';
import {
  createUserAuditEventsRequest,
  ICreateUserAuditEvents,
} from '@legacy/core/userAudit/store/actions';
import { LogsUserAuditEventTypesEnum } from '@d19n/temp-fe-d19n-models/dist/logs/audit/logs.user.audit.event.types';
import FeatureRecordBody from './FeatureBody';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import DefaultRecordBody from './DefaultBody';
import { PageHeader } from '../../../../../../../legacy/components/PageHeader';
import { hasPermissions } from '../../../../../../../core/helpers/rbacRules';
import CoreLegacyEmbeddedForm from '@legacy/core/records/components/Forms/CoreLegacyEmbeddedForm';

interface Props {
  setQuickView: Function;
  mapReducer: MapReducer;
  initializeForm: Function;
  updateMap: (params: MapReducerUpdate) => {};
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
  getSchema: Function;
  schemaReducer: any;
  updateWI: (payload: IMapUpdateWorkItems) => void;
  resetForm: Function;
  logUserRecordViewEvent: (params: ICreateUserAuditEvents, cb?: any) => void;
  userReducer: any;
}

const uuid = uuidv4();
const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, OPENREACH_NERWORK_ADJUSTMENT, PROJECT } = SchemaModuleEntityTypeEnums;

const WorkItemQuickView: FC<Props> = (props) => {
  const {
    mapReducer,
    initializeForm,
    updateMap,
    getRecordById,
    getSchema,
    schemaReducer,
    updateWI,
    logUserRecordViewEvent,
    userReducer,
  } = props;

  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isViewingMore, setIsViewingMore] = useState<boolean>(false);

  const [record, setRecord] = useState<DbRecordEntityTransform | undefined>(undefined);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  const workItems = mapReducer.workItems;
  const drawerVisible = workItems.quickViewVisible;

  // Get record from APIs using schema and id
  const getRecord = (schema: SchemaEntity, id: string) => {
    if (schema && id) {
      getRecordById({ schema: schema, recordId: id }, (res: any) => {
        setRecord(res);
      });
    }
  };

  // When Drawer opens up -> get the schema first and then the record/feature
  useEffect(() => {
    if (drawerVisible && !schema && workItems.quickViewRecord) {
      setRecord(workItems.quickViewRecord);

      console.log('workItems.quickViewRecord', workItems.quickViewRecord);
      // User is viewing a legacy feature details with V2 quick view component
      if (isV2QuickViewApplied()) {
        logUserRecordViewEvent({
          items: [
            {
              type: LogsUserAuditEventTypesEnum.DB_RECORD_VIEWED,
              recordId: workItems.quickViewRecord?.id,
              data: {
                recordNumber: workItems.quickViewRecord?.recordNumber,
                title: workItems.quickViewRecord?.title,
                quickViewVersion: 'V2',
                componentName: 'WorkItemQuickView',
              },
            },
          ],
        });
      }

      const moduleName = workItems.quickViewRecord?.entity?.split(':')[0];
      const entityName = workItems.quickViewRecord?.entity?.split(':')[1];

      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        moduleName,
        entityName,
      );
      if (shortlistSchema) {
        setSchema(shortlistSchema);
      } else {
        getSchema(
          { schemaId: workItems.quickViewRecord?.schemaId! },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setSchema(responseSchema);
            }
          },
        );
      }
    }
  }, [drawerVisible]);

  const closeDrawer = () => {
    setIsViewingMore(false);
    setRecord(undefined);
    setSchema(undefined);
    setIsEditing(false);
    // setActiveTab('ACTIONS');

    updateWI({
      quickViewRecord: undefined,
      quickViewVisible: false,
    });

    updateMap({
      recordId: undefined,
    });
  };

  const truncate = (str: any) => {
    return (!isMobile && str?.length > 23) || (isMobile && str?.length > 13) ? (
      <Tooltip title={str} mouseEnterDelay={0.7}>
        {str.substring(0, isMobile ? 8 : 20) + '...'}
      </Tooltip>
    ) : (
      str
    );
  };

  const getInitialRecordProperties = (record: DbRecordEntityTransform) => {
    if (record?.entity === `${PROJECT_MODULE}:${OPENREACH_NERWORK_ADJUSTMENT}`) {
      return ['OrderState', 'SubmittedNoiReference', 'OpenreachOrderReference'];
    } else if (record?.type === 'SURVEY_STRUCTURE') {
      return ['Resolved', 'L1PolygonId', 'L2PolygonId'];
    } else {
      return ['BuildStatus', 'L1PolygonId', 'L2PolygonId'];
    }
  };

  let initialRecordProperties = getInitialRecordProperties(record!);
  let recordPropertyKeys: string[] = [];

  if (record && record?.properties) {
    recordPropertyKeys = Object.keys(record.properties);
    recordPropertyKeys = recordPropertyKeys.filter(
      (key: string) => !initialRecordProperties.includes(key),
    );
  }

  const onEditSuccess = () => {
    setIsEditing(false);
    if (schema && record) {
      getRecord(schema, record.id);
    }
  };

  const showRecordInQuickView = (record: DbRecordEntityTransform) => {
    if (record && schema) {
      setIsEditing(false);
      setIsViewingMore(false);
      setRecord(record);
    }
  };

  const renderDescriptionItem = (key: string, value: any) => {
    if (key === 'BuildStatus') {
      return (
        <Descriptions.Item label={key} key={key}>
          {QGISBuildStatusEnum[getProperty(record, key)] || 'No Build Status'}
        </Descriptions.Item>
      );
    } else {
      return (
        <Descriptions.Item label={truncate(key)} key={key}>
          {value || '-'}
        </Descriptions.Item>
      );
    }
  };

  const isV2QuickViewApplied = () => {
    return localStorage.getItem('ProjectModuleV2QuickView');
  };

  const revertToV1QuickView = () => {
    const recordToSet = record;

    setIsViewingMore(false);
    setRecord(undefined);
    setSchema(undefined);
    setIsEditing(false);
    updateWI({
      quickViewRecord: undefined,
      quickViewVisible: false,
    });

    localStorage.removeItem('ProjectModuleV2QuickView');

    setTimeout(() => {
      updateMap({
        recordId: recordToSet?.id,
      });
    }, 500);
  };

  const bodyLoader = () => {
    if (schema && record) {
      const moduleEntity = `${schema.moduleName}:${schema.entityName}`;
      if (moduleEntity === `${PROJECT_MODULE}:${FEATURE}`) {
        return (
          <FeatureRecordBody
            record={record!}
            schema={schema!}
            closeDrawer={closeDrawer}
            showRecordInQuickView={showRecordInQuickView}
          />
        );
      } else {
        return (
          <DefaultRecordBody
            record={record!}
            schema={schema!}
            closeDrawer={closeDrawer}
            showRecordInQuickView={showRecordInQuickView}
          />
        );
      }
    }
  };

  return (
    <Drawer
      destroyOnClose
      key={uuid}
      className="workItemQuickViewDrawer"
      open={workItems.quickViewVisible}
      width={isMobile ? '100%' : '38vw'}
      onClose={() => closeDrawer()}
      title={
        <Row>
          <Col span={12}>Feature Overview</Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {isV2QuickViewApplied() && mapReducer.recordId ? (
              <Button
                style={{ padding: 0, height: 'auto' }}
                type="link"
                onClick={() => revertToV1QuickView()}
              >
                Revert to V1
              </Button>
            ) : (
              <></>
            )}
            <Tooltip title="Help" mouseEnterDelay={0.8}>
              <a
                href={`https://app.prod.netomnia.com/SupportModule/KnowledgeArticle/b1572d71-a9b7-4784-9241-34dd4a75345e/view`}
                target="_blank"
              >
                <Button
                  icon={<QuestionOutlined />}
                  shape="circle"
                  size="small"
                  style={{ marginLeft: 10 }}
                />
              </a>
            </Tooltip>
          </Col>
        </Row>
      }
    >
      <>
        {/* age Header */}
        <Row>
          <Col span={24}>
            <PageHeader
              style={{ borderRadius: 6, border: '1px solid #dddbda', padding: '8px 10px' }}
              className="workItemQuickViewHeader"
            >
              {/* Header */}
              <Row justify={isMobile ? 'start' : 'space-around'}>
                {/* Record Icon */}
                <Col span={isMobile ? 4 : 2} style={{ marginTop: 8 }}>
                  <ModuleEntityIcon
                    moduleName={workItems?.quickViewRecord?.entity?.split(':')[0] || PROJECT_MODULE}
                    entityName={workItems?.quickViewRecord?.entity?.split(':')[1] || FEATURE}
                    style={{ padding: '4px 2px', fontSize: '1.3em' }}
                  />
                </Col>
                {/* Record name and ID */}
                <Col span={isMobile ? 20 : 17} style={{ paddingTop: 2 }}>
                  <Row>
                    <Col span={24} style={{ fontSize: '1.2em', fontWeight: 600 }}>
                      <span>
                        {record?.entity === `${PROJECT_MODULE}:${FEATURE}`
                          ? `${record?.type} (${record?.recordNumber || '-'})`
                          : record?.recordNumber}
                      </span>
                    </Col>
                    <Col span={24} style={{ lineHeight: '13px', margin: 0 }}>
                      <span style={{ fontSize: '0.9em', color: '#636363', fontWeight: 'normal' }}>
                        ID:{' '}
                        {record?.entity === `${PROJECT_MODULE}:${FEATURE}`
                          ? getProperty(record, 'ExternalRef') || '-'
                          : record?.id}
                      </span>
                    </Col>
                  </Row>
                </Col>

                {/* Record Actions - Desktop */}
                {!isMobile ? (
                  <Col span={4} style={{ textAlign: 'right' }}>
                    <Tooltip title="Go to Record" mouseEnterDelay={1.5}>
                      <Link to={getBrowserPath(record!)} target="_blank">
                        <Button
                          ghost
                          size="large"
                          type="link"
                          disabled={!record}
                          icon={<ExportOutlined />}
                        />
                      </Link>
                    </Tooltip>
                    <Tooltip title="Edit Feature" mouseEnterDelay={1.5}>
                      <Button
                        ghost
                        size="large"
                        type="link"
                        disabled={
                          !record ||
                          isEditing ||
                          !hasPermissions(userReducer, ['master.record.editor'])
                        }
                        onClick={() => {
                          setIsEditing(true);
                          setIsViewingMore(false);
                        }}
                        icon={<EditOutlined />}
                      />
                    </Tooltip>
                  </Col>
                ) : (
                  <></>
                )}
              </Row>

              {isEditing ? (
                // Edit Form
                <Row>
                  <Col span={24}>
                    <CoreLegacyEmbeddedForm
                      formUUID={uuid}
                      record={record}
                      showFormActions
                      onCancelEvent={() => setIsEditing(false)}
                      onSubmitEvent={() => onEditSuccess()}
                      initializeCb={() => {
                        initializeForm({
                          title: `Edit ${schema?.entityName}`,
                          formUUID: uuid,
                          selected: record,
                          recordType: record?.type,
                          showFormModal: true,
                          showInitializing: false,
                          isCreateReq: false,
                          isUpdateReq: true,
                          schema: schema,
                          sections: [
                            {
                              name: schema?.name,
                              schema: schema,
                              associations: [],
                            },
                          ],
                        });
                      }}
                    />
                  </Col>
                </Row>
              ) : (
                <>
                  <Row justify="space-between">
                    {/* Mobile Only Actions */}
                    {isMobile ? (
                      <>
                        <Col span={24}>
                          <Divider style={{ marginTop: 18, marginBottom: 18 }} />
                        </Col>
                        <Col span={12} style={{ textAlign: 'center' }}>
                          <Link to={getBrowserPath(record!)} target="_blank">
                            <Button disabled={!record} type="link" icon={<ExportOutlined />}>
                              Go to Record
                            </Button>
                          </Link>
                        </Col>
                        <Col span={12} style={{ textAlign: 'center' }}>
                          <Button
                            disabled={!record}
                            onClick={() => {
                              setIsEditing(!isEditing);
                              setIsViewingMore(false);
                            }}
                            type="link"
                            icon={<EditOutlined />}
                          >
                            Edit Record
                          </Button>
                        </Col>
                      </>
                    ) : (
                      <></>
                    )}

                    <Col span={24}>
                      <Divider style={{ marginTop: 18, marginBottom: isMobile ? 10 : 18 }} />
                    </Col>

                    <Col span={24}>
                      <Descriptions
                        size="small"
                        style={{ width: '100%' }}
                        column={3}
                        bordered
                        layout="vertical"
                        contentStyle={{ fontSize: isMobile ? '0.8em' : '0.9em' }}
                        labelStyle={{
                          fontSize: isMobile ? '0.8em' : '0.9em',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        {/* Get dynamic record props */}
                        {initialRecordProperties?.map((key: string) =>
                          renderDescriptionItem(key, getProperty(record, key)),
                        )}
                      </Descriptions>
                    </Col>
                  </Row>

                  {/* Show more items */}
                  {isViewingMore ? (
                    <Col span={24} style={{ marginTop: 10 }}>
                      <Descriptions
                        size="small"
                        style={{ width: '100%' }}
                        column={3}
                        bordered
                        layout="vertical"
                        contentStyle={{ fontSize: isMobile ? '0.8em' : '0.9em', width: '33%' }}
                        labelStyle={{
                          fontSize: isMobile ? '0.8em' : '0.9em',
                          width: '33%',
                          fontWeight: 500,
                          color: 'black',
                        }}
                      >
                        {recordPropertyKeys.map((key: string) =>
                          renderDescriptionItem(key, getProperty(record, key)),
                        )}
                      </Descriptions>
                    </Col>
                  ) : (
                    <></>
                  )}

                  {/* Show More / Show Less */}
                  <Row>
                    <Col span={24} style={{ textAlign: 'center', marginTop: 15 }}>
                      <Button
                        type="link"
                        style={{ fontWeight: isViewingMore ? 'normal' : 500 }}
                        onClick={() => setIsViewingMore(!isViewingMore)}
                      >
                        {isViewingMore ? 'Show Less' : 'Show More'}
                      </Button>
                    </Col>
                  </Row>
                </>
              )}
            </PageHeader>
          </Col>
        </Row>

        {bodyLoader()}
      </>
    </Drawer>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  setQuickView: (params: IMapSetWorkItemQuickView) => dispatch(setWorkItemQuickView(params)),
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  resetForm: () => dispatch(resetWorkItemForm()),
  logUserRecordViewEvent: (params: ICreateUserAuditEvents, cb?: any) =>
    dispatch(createUserAuditEventsRequest(params, cb)),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(WorkItemQuickView);
