import React from 'react';
import { connect } from 'react-redux';
import { sendConfirmationEmail, SendgridEmailEntity } from '@legacy/core/notifications/email/store/actions';
import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';

interface Props {
  email: SendgridEmailEntity;
  sendConfirmation: any;
  buttonText?: string;
  fill?: boolean;
}

interface State {
  showModal: boolean;
  isSending: boolean;
}

class SendDynamicEmail extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      showModal: false,
      isSending: false,
    };
  }

  sendEmail() {
    const { sendConfirmation, email } = this.props;

    this.setState({ isSending: true });
    sendConfirmation(`NotificationModule/v1.0/sendgrid/dynamic_template`, email, () => {
      this.setState({ showModal: false, isSending: false });
    });
  }

  render() {
    const { email, buttonText, fill } = this.props;

    return (
      <>
        <Button
          fill={fill}
          intent="primary"
          outlined
          onClick={() => this.setState({ showModal: true })}
        >
          {buttonText || 'Email'}
        </Button>
        <Dialog
          title="Setup Mandate"
          icon="info-sign"
          isOpen={this.state.showModal}
          onClose={() => this.setState({ showModal: false })}
        >
          <DialogBody>
            <span>
              <p>
                <span style={{ fontWeight: 500 }}>To:</span> {email.to}
              </p>
              <pre
                className="bp5-background"
                style={{ fontSize: '0.8em', padding: 10, whiteSpace: 'pre-wrap' }}
              >
                {JSON.stringify(email.dynamicTemplateData, null, 2)}
              </pre>
            </span>
          </DialogBody>
          <DialogFooter
            actions={
              <>
                <Button outlined text="Close" onClick={() => this.setState({ showModal: false })} />
                <Button
                  text="Send"
                  intent="primary"
                  onClick={() => this.sendEmail()}
                  disabled={this.state.isSending}
                  loading={this.state.isSending}
                />
              </>
            }
          />
        </Dialog>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  sendConfirmation: (payload: any, body: SendgridEmailEntity, cb: any) =>
    dispatch(sendConfirmationEmail(payload, body, cb)),
});

export default connect(mapState, mapDispatch)(SendDynamicEmail);
