import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import GoCardlessActivityFeed from '@legacy/core/billing/GocardlessActivityFeed';
import RecordCard from '@legacy/core/records/components/RecordCard';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import DetailView from '@core/views/DetailView';
import TransactionCancel from '../../components/TransactionCancel';

interface Props {
  recordReducer: IRecordReducer;
  match: any;
  schemaReducer: any;
}

const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { NOTE, INVOICE, PAYMENT_METHOD, TRANSACTION } = SchemaModuleEntityTypeEnums;

const TransactionDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);
  const recordType = getProperty(record, 'Type');

  return (
    <DetailView
      showCollaborators
      headerExtras={[<TransactionCancel record={record} hidden={[NOTE]} />]}
      customTabs={[
        {
          key: 'GoCardless',
          tab: 'GoCardless',
        },
      ]}
      customTabBodies={{
        GoCardless: <GoCardlessActivityFeed record={record} filterBy={recordType} />,
      }}
      leftColumn={[
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={BILLING_MODULE}
          associatedRecordEntityName={INVOICE}
          visibleProperties={['Status', 'TotalDue', 'Balance']}
        />,
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={BILLING_MODULE}
          associatedRecordEntityName={PAYMENT_METHOD}
          visibleProperties={['Default', 'Status', 'BankAccountNumberEnding']}
        />,
        <RecordCard
         showActionMenu
         showItemActionMenu
         record={record}
         displayQuickView
         associatedRecordModuleName={BILLING_MODULE}
         associatedRecordEntityName={'BillingRequest'}
         visibleProperties={['Status', 'ExternalReference']}
        />
      ]}
    />
  );
};

export default TransactionDetailView;
