import React, { useEffect, useState } from 'react';
import { Section, Spinner } from '@blueprintjs/core';
import { Col, Row } from 'antd';

import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import { Empty } from './Empty';
import { DetailsSection } from './DetailsSection';
import { PropertiesSection } from './PropertiesSection';
import { ActionsSection } from './ActionsSection';
import { LinksSection } from './LinksSection';
import { SchemaTypesSelect } from './DetailsSection/SchemaTypesSelect';
import { PermissionsSection } from './PermissionsSection';
import TypesSection from './TypesSection';
import PipelineSection from '@core/modules/ControlPanelModule/containers/SchemaManager/SchemaDetailsView/SchemaDetails/PipelineSection';
import SchemaSettingsSection from './SchemaSettingsSection';

type SchemaDetailsProps = {
  schema?: SchemaEntity;
  loading: boolean;
  schemaDetailsRef: React.RefObject<HTMLDivElement>;
};

export const SchemaDetails = ({ schema, loading, schemaDetailsRef }: SchemaDetailsProps) => {
  const [schemaTypeId, setSchemaTypeId] = useState<string | null>(null);

  useEffect(() => {
    if (!schema?.id) return;

    if (schema.types?.length) {
      setSchemaTypeId('all');
    }
  }, [schema?.id]);

  if (loading || !schema)
    return (
      <div className="loading-page">
        <Spinner />
      </div>
    );

  return (
    <div
      ref={schemaDetailsRef}
      style={{
        padding: '20px 30px',
        background: 'white',
        display: 'grid',
        gap: '1rem',
        height: 'calc(100vh - 40px)',
        overflowY: 'auto',
      }}
    >
      {/* Top Section */}
      <Row>
        <Col span={24}>
          <DetailsSection
            schema={schema}
            rightElement={
              <SchemaTypesSelect
                schemaTypes={schema.types}
                selected={schemaTypeId}
                onChange={(val) => setSchemaTypeId(val)}
              />
            }
          />
        </Col>
      </Row>

      {/* Types */}
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <TypesSection schema={schema} schemaTypeId={schemaTypeId} />
        </Col>
      </Row>

      {/* Properties | Actions */}
      <Row gutter={24} style={{ marginTop: 15 }}>
        <Col span={12}>
          <PropertiesSection schema={schema} schemaTypeId={schemaTypeId} />
        </Col>
        <Col span={12}>
          <ActionsSection schema={schema} schemaTypeId={schemaTypeId} />
        </Col>
      </Row>

      {/* Links */}
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <LinksSection schema={schema} />
        </Col>
      </Row>

      {/* Permissions */}
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <PermissionsSection schema={schema} schemaTypeId={schemaTypeId} />
        </Col>
      </Row>

      {/* Pipelines */}
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <PipelineSection schema={schema} schemaTypeId={schemaTypeId} />
        </Col>
      </Row>

      {/* Data | Usage */}
      <Row gutter={24} style={{ marginTop: 15 }}>
        <Col span={15}>
          <Section title="Data">
            <Empty icon="th" text="No data available" />
          </Section>
        </Col>
        <Col span={9}>
          <Section title="Usage">
            <Empty icon="chart" text="No usage data available" />
          </Section>
        </Col>
      </Row>

      {/* Settings */}
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <SchemaSettingsSection data={schema} />
        </Col>
      </Row>
    </div>
  );
};
