import { LoadingOutlined } from '@ant-design/icons';
import { Tag } from '@blueprintjs/core';
import { Col, Input, Modal, Row, Spin } from 'antd';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createEditor, Editor, Element as SlateElement, Range, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, useSlate, useSlateStatic, withReact } from 'slate-react';
import { getUsersForMention } from '../api';
import { Button, Icon, Portal, Toolbar } from './SlateRichEditorComponents';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];

interface Props {
  onChange?: any;
  value?: any;
  isViewMode?: boolean;
  clear?: boolean;
  minHeight?: string;
}

const NoteEditor = (props: Props) => {
  const { onChange, isViewMode, value, clear } = props;
  const ref = useRef<any>(null);
  const [target, setTarget] = useState<any>(undefined);
  const [index, setIndex] = useState(0);
  const [search, setSearch] = useState('');
  const renderElement = useCallback((props) => <Element {...props} />, []);
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);
  const editor = useMemo(() => withMentions(withReact(withHistory(createEditor()))), []);
  const [userList, setUserList] = useState<any[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState<boolean>(false);

  let filteredUserList: any[] = [];

  const emptyNoteBody = [{ type: 'paragraph', children: [{ text: '' }] }];

  // Clear Editor input
  useEffect(() => {
    if (clear && editor) {
      onChange && onChange(emptyNoteBody);
      Transforms.delete(editor, { at: [0] });
      Transforms.insertNodes(editor, emptyNoteBody as any);
    }
  }, [clear]);

  // Filter users for mention list
  if (userList.length > 0) {
    filteredUserList = userList.filter(
      (user: any) =>
        user.firstname.toLowerCase().indexOf(search?.toLocaleLowerCase()) > -1 ||
        user.lastname.toLowerCase().indexOf(search?.toLowerCase()) > -1,
    );
  }

  const onKeyDown = useCallback(
    (event) => {
      if (target && filteredUserList.length > 0) {
        switch (event.key) {
          case 'ArrowDown':
            event.preventDefault();
            const prevIndex = index >= filteredUserList.length - 1 ? 0 : index + 1;
            setIndex(prevIndex);
            break;
          case 'ArrowUp':
            event.preventDefault();
            const nextIndex = index <= 0 ? filteredUserList.length - 1 : index - 1;
            setIndex(nextIndex);
            break;
          case 'Tab':
          case 'Enter':
            event.preventDefault();
            Transforms.select(editor, target);
            insertMention(editor, filteredUserList[index]);
            setTarget(null);
            break;
          case 'Escape':
            event.preventDefault();
            setTarget(null);
            break;
        }
      }
    },
    [filteredUserList, editor, index, target],
  );

  const handleChange = (value: any) => {
    if (props.onChange) {
      props.onChange(value);
    }
  };

  useEffect(() => {
    if (target && userList.length === 0 && !isLoadingUsers) {
      setIsLoadingUsers(true);
      getUsersForMention().then((res: any) => {
        setUserList(res);
        setIsLoadingUsers(false);
      });
    }
  }, [target]);

  useEffect(() => {
    if (target && ref.current) {
      const el: any = ref.current;
      const domRange = ReactEditor.toDOMRange(editor, target);
      const rect = domRange.getBoundingClientRect();
      el.style.top = `${rect.top + window.scrollY + 24}px`;
      el.style.left = `${rect.left + window.scrollX}px`;
    }
  }, [userList, editor, index, search, target, ref]);

  const MarkButton = ({ format, icon }: any) => {
    const editor = useSlate();
    return (
      <Button
        active={isMarkActive(editor, format)}
        onMouseDown={(event: any) => {
          event.preventDefault();
          toggleMark(editor, format);
        }}
      >
        <Icon className="editorButton">{icon}</Icon>
      </Button>
    );
  };

  const toggleMark = (editor: any, format: any) => {
    const isActive = isMarkActive(editor, format);

    if (isActive) {
      Editor.removeMark(editor, format);
    } else {
      Editor.addMark(editor, format, true);
    }
  };

  const isMarkActive = (editor: any, format: any) => {
    const marks: any = Editor.marks(editor);
    return marks ? marks[format] === true : false;
  };

  const BlockButton = ({ format, icon }: any) => {
    const editor = useSlate();

    return (
      <Button
        active={isBlockActive(editor!, format)}
        onMouseDown={(event: any) => {
          event.preventDefault();
          toggleBlock(editor!, format);
        }}
      >
        <Icon className="editorButton">{icon}</Icon>
      </Button>
    );
  };

  const isBlockActive = (editor: any, format: any) => {
    const { selection } = editor;
    if (!selection) {
      return false;
    }

    const [match] = Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
    });

    return !!match;
  };

  const toggleBlock = (editor: any, format: any) => {
    const isActive = isBlockActive(editor, format);
    const isList = LIST_TYPES.includes(format);

    Transforms.unwrapNodes(editor, {
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
      split: true,
    });
    const newProperties: Partial<SlateElement> = {
      type: isActive ? 'paragraph' : isList ? 'list-item' : format,
    };
    Transforms.setNodes<SlateElement>(editor, newProperties);

    if (!isActive && isList) {
      const block = { type: format, children: [] };
      Transforms.wrapNodes(editor, block);
    }
  };

  const isUrl = (str: string) => {
    return str.match(
      /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
    );
  };

  const InsertLinkButton = () => {
    const [urlModalVisible, setUrlModalVisible] = useState<boolean>(false);
    const [enteredUrl, setEnteredUrl] = useState<string>('');
    const [enteredLinkText, setEnteredLinkText] = useState<string>('');
    const editor = useSlateStatic();

    const insertLinkFromModal = () => {
      if (enteredUrl && isUrl(enteredUrl)) {
        insertLink(editor, enteredUrl, enteredLinkText);
        setUrlModalVisible(false);
        setEnteredLinkText('');
        setEnteredUrl('');
      } else {
        alert('Entered URL is not valid');
        setUrlModalVisible(false);
      }
    };

    return (
      <>
        <Button
          onMouseDown={(event: any) => {
            event.preventDefault();
            setUrlModalVisible(true);
          }}
        >
          <Icon className="editorButton">link</Icon>
        </Button>
        <Modal
          title="Insert Link"
          style={{ top: 20 }}
          open={urlModalVisible}
          onOk={() => insertLinkFromModal()}
          onCancel={() => {
            setEnteredLinkText('');
            setEnteredUrl('');
            setUrlModalVisible(false);
          }}
        >
          <Row style={{ padding: 10 }}>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Input
                addonBefore="https://"
                value={enteredUrl}
                onChange={(e: any) => setEnteredUrl(e.target.value)}
              />
            </Col>
            <Col span={24}>
              <Input
                value={enteredLinkText}
                onChange={(e: any) => setEnteredLinkText(e.target.value)}
                placeholder="Link Text in Article."
              />
            </Col>
          </Row>
        </Modal>
      </>
    );
  };

  const createLinkNode = (href: string, text: string) => ({
    type: 'link',
    href,
    children: [{ text }],
  });

  const insertLink = (editor: any, url: string, linkText: string) => {
    const { selection } = editor;
    const link: any = createLinkNode(url, linkText ? linkText : url);

    ReactEditor.focus(editor);

    if (!!selection)
      Editor.insertNode(editor, {
        type: 'paragraph',
        children: [link],
      });
  };

  const initialValue =
    value === 'Access Denied'
      ? [{ type: 'paragraph', children: [{ text: '[Access Denied]' }] }]
      : value;

  return (
    <Slate
      editor={editor}
      initialValue={initialValue}
      onChange={() => {
        const { selection } = editor;
        if (selection && Range.isCollapsed(selection)) {
          const [start] = Range.edges(selection);
          const wordBefore = Editor.before(editor, start, { unit: 'word' });
          const before = wordBefore && Editor.before(editor, wordBefore);
          const beforeRange = before && Editor.range(editor, before, start);
          const beforeText = beforeRange && Editor.string(editor, beforeRange);
          const beforeMatch = beforeText && beforeText.match(/^@(\w+)$/);
          const after = Editor.after(editor, start);
          const afterRange = Editor.range(editor, start, after);
          const afterText = Editor.string(editor, afterRange);
          const afterMatch = afterText.match(/^(\s|$)/);

          if (beforeMatch && afterMatch) {
            setTarget(beforeRange);
            setSearch(beforeMatch[1]);
            setIndex(0);
            return;
          }
        }
        handleChange(editor.children);
        setTarget(null);
      }}
    >
      {/* Toolbar */}
      {!props.isViewMode && (
        <Toolbar className="">
          <MarkButton format="bold" icon="format_bold" />
          <MarkButton format="italic" icon="format_italic" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <InsertLinkButton />
        </Toolbar>
      )}
      {/* Editor */}
      <Editable
        readOnly={isViewMode}
        renderElement={renderElement}
        renderLeaf={renderLeaf}
        onKeyDown={onKeyDown}
        className={'slate-rich-editor'}
      />
      {target && (
        <Portal>
          <div
            ref={ref}
            style={{
              top: '-9999px',
              left: '-9999px',
              position: 'absolute',
              zIndex: 1000,
              padding: 5,
              background: 'white',
              borderRadius: '4px',
              boxShadow: '0 1px 5px rgba(0,0,0,.2)',
              width: 350,
              cursor: 'pointer',
              maxHeight: 400,
              overflowY: 'auto',
              overflowX: 'hidden',
              fontSize: 13,
            }}
            data-cy="mentions-portal"
          >
            {isLoadingUsers && filteredUserList.length === 0 && (
              <div>
                <Spin
                  size="small"
                  style={{ marginRight: 8 }}
                  indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />}
                />
                <span>Loading users...</span>
              </div>
            )}

            {/* User List */}
            {filteredUserList?.map((user, i) => (
              <div
                key={user.id}
                onClick={() => {
                  Transforms.select(editor!, target);
                  insertMention(editor!, user);
                  setTarget(null);
                }}
                style={{
                  padding: '1px 3px',
                  borderRadius: '3px',
                  background: i === index ? '#B4D5FF' : 'transparent',
                }}
              >
                <div key={user.email} style={{ width: '300%' }}>
                  <span style={{ display: 'inline-block' }}>{user.firstname}</span> {user.lastname}{' '}
                  <span style={{ display: 'inline-block', opacity: 0.25, marginLeft: 4 }}>
                    ({user.email || 'no email'})
                  </span>
                </div>
              </div>
            ))}
          </div>
        </Portal>
      )}
    </Slate>
  );
};

const withMentions = (editor: any) => {
  const { isInline, isVoid, markableVoid } = editor;

  editor.isInline = (element: any) => {
    return element.type === 'mention' ? true : isInline(element);
  };

  editor.isVoid = (element: any) => {
    return element.type === 'mention' ? true : isVoid(element);
  };

  editor.markableVoid = (element: any) => {
    return element.type === 'mention' || markableVoid(element);
  };

  return editor;
};

const insertMention = (editor: any, user: any) => {
  const mention: any = {
    type: 'mention',
    character: `${user.firstname} ${user.lastname}`,
    uid: user.id,
    name: `${user.firstname} ${user.lastname}`,
    children: [{ text: '' }],
  };
  Transforms.insertNodes(editor, mention);
  Transforms.move(editor);
};

// Borrow Leaf renderer from the Rich Text example.
// In a real project you would get this via `withRichText(editor)` or similar.
const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const Link = ({ attributes, element, children }: any) => (
  <a {...attributes} href={'https://' + element.href} target="_blank">
    {children}
  </a>
);

const Element = (props: any) => {
  const { attributes, children, element } = props;
  switch (element.type) {
    case 'mention':
      return <Mention {...props} />;
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'link':
      return <Link {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Mention = ({ attributes, children, element }: any) => {
  return (
    <Tag
      {...attributes}
      contentEditable={false}
      data-cy={`mention-${element.character.replace(' ', '-')}`}
      intent="primary"
      minimal
      style={{
        borderRadius: 2,
        padding: '0px 3px',
        fontSize: 13,
        margin: '0 3px',
        fontWeight: 500,
      }}
    >
      @{element.character}
      {children}
    </Tag>
  );
};

export default NoteEditor;
