import { useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Button, Card, Col, Dropdown, Menu, Row } from 'antd';
import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  SchemaAssociationEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';

import { updateMapState, updateWorkItems } from '@netomnia/modules/ProjectModule/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import AssociationSimpleList from '@legacy/core/recordsAssociations/components/AssociationSimpleList';

import NoteFeed from '../../../../../../../core/components/NoteFeed';
import DetailsConfigurator from '../../../../../../../core/views/DetailView/DetailsConfigurator';
import SchemaActionFormLauncher from '../../../../../../../core/components/OutcomeFormLauncher';


import { FEATURE_NAMES } from '../../../../Map/constants';
import SplicingSummaryWidget from '../../../../Map/MapSideBar/MapSidebarWorkItems/SplicingSummaryWidget';
import PTAListAndCreateCard from '../../../../components/PTAListAndCreateCard';
import { FeatureMapWidget } from '../../../../Map/MapSideBar/MapSidebarWorkItems/FeatureMapWidget';
import { useAction } from '../../../../../../../core/hooks/useAction';
import { useViewConfigurations } from './useViewConfigurations';


const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, OPENREACH_NERWORK_ADJUSTMENT } = SchemaModuleEntityTypeEnums;

// Find schemas with OUTCOME_FORM but watch for schema type constraints
const getSchemasWithOutcomeForm = (record: DbRecordEntityTransform, schema: SchemaEntity) => {
  const schemasWithOutcomeForm: any[] =
    schema?.associations.filter(
      (assoc: SchemaAssociationEntity) =>
        assoc.childSchema?.types?.some((type: SchemaTypeEntity) => type.name === 'OUTCOME_FORM') &&
        assoc.schemaTypesConstraints?.find(
          (constraint: any) => constraint.parentSchemaType?.name === record?.type,
        ),
    ) || [];

  return schemasWithOutcomeForm.map((sch: any) => sch.childSchema);
};

type RecordDetailsProps = {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  setFlowVisible: (value: boolean) => void;
}

export const RecordDetails = ({ record, schema, setFlowVisible }: RecordDetailsProps) => {
  const viewConfigurations = useViewConfigurations(schema, record?.stage, record?.type);
  const mapReducer = useSelector((state: any) => state.mapReducer as MapReducer);
  const updateMap = useAction(updateMapState);
  const updateWI = useAction(updateWorkItems);

  const schemasWithOutcomeForm = getSchemasWithOutcomeForm(record, schema);
  // We disable RFC actions for features that are in build status 7 or 8.
  const isFeatureBS7or8 = (record: DbRecordEntityTransform | undefined) => {
    const BS = getProperty(record, 'BuildStatus');
    if (BS === '7' || BS === '8') {
      return true;
    } else {
      return false;
    }
  };

  // RFC - Enter mode to create Survey Structure on maps
  const enterRFCCreateSurveyStructure = () => {
    if (record) {
      updateMap({
        addEnabled: true,
        addPointFlow: false,
        creatingRFCFromIds: [record.id],
        isCreatingRFC: true,
        isRFCModalVisible: false,
        isRFCSelectFeatureTypeModalVisible: false,
        mapSidebarVisible: isMobile ? false : mapReducer.mapSidebarVisible,
      });

      updateWI({
        creatingBlockageFromId: undefined,
      });
    }
  };

  // RFC - Enter mode to create Survey Route on maps
  const enterRFCCreateSurveyRoute = () => {
    if (record) {
      updateMap({
        addLineEnabled: true,
        creatingRFCFromIds: [record.id],
        isCreatingRFC: true,
        isRFCModalVisible: false,
        mapSidebarVisible: isMobile ? false : mapReducer.mapSidebarVisible,
      });

      updateWI({
        creatingBlockageFromId: undefined,
      });
    }
  };

  // Enter mode to create Blockage on maps
  const createBlockage = () => {
    updateMap({
      createLinked: {
        fromType: record?.type?.toLowerCase() as FEATURE_NAMES,
        fromId: getProperty(record, 'ExtÂernalRef'),
        toType: FEATURE_NAMES.BLOCKAGE,
      },
      drawEnabled: false,
      addEnabled: true,
    });

    updateWI({
      creatingBlockageFromId: record?.id,
    });
  };
  return (
    <>
      <Row style={{ marginTop: 15 }}>
        <Col span={24}>
          <DetailsConfigurator record={record} viewConfiguration={viewConfigurations[0]} />
        </Col>

        {/* Closures in Build Status 8 should show Permit to Access Card */}
        {record?.type === 'CLOSURE' && getProperty(record, 'BuildStatus') === '8' ? (
          <Col span={24} style={{ marginTop: 17 }}>
            <PTAListAndCreateCard parentRecord={record!} />
          </Col>
        ) : (
          <></>
        )}

        {/* RFC List & Actions */}
        {!['SURVEY_ROUTE', 'SURVEY_STRUCTURE', 'BLOCKAGE'].includes(record?.type!) &&
        record?.entity !== `${PROJECT_MODULE}:${OPENREACH_NERWORK_ADJUSTMENT}` ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              extra={
                <>
                  <h3>Huvs</h3>
                  <Dropdown
                    disabled={isFeatureBS7or8(record)}
                    trigger={['click']}
                    overlay={
                      <Menu>
                        {/* Create Survey Route */}
                        {record?.type &&
                        ['duct', 'cable', 'rope'].includes(record?.type?.toLowerCase()) ? (
                          <Menu.Item
                            key={'createSurveyRoute'}
                            onClick={() => enterRFCCreateSurveyRoute()}
                          >
                            Create Survey Route
                          </Menu.Item>
                        ) : (
                          <></>
                        )}

                        {/* Create Survey Structure */}
                        <Menu.Item
                          key={'createSurveyStructure'}
                          onClick={() => enterRFCCreateSurveyStructure()}
                        >
                          Create Survey Structure
                        </Menu.Item>
                      </Menu>
                    }
                  >
                    <Button
                      icon={<CaretDownOutlined style={{ fontSize: '0.9em' }} />}
                    />
                  </Dropdown>
                </>
              }
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    overrideIcon="geo-alt-fill"
                    overrideIconColor="#1cc773"
                    moduleName=""
                    entityName=""
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>RFC</span>
                </>
              }
            >
              <Row>
                <Col span={24}>
                  <AssociationSimpleList
                    parentRecord={record}
                    associationEntityName={FEATURE}
                    associationModuleName={PROJECT_MODULE}
                    associationType="SURVEY_ROUTE"
                    recordColumns={['BuildStatus', 'type', 'ExternalRef']}
                  />
                </Col>
                <Col span={24}>
                  <AssociationSimpleList
                    parentRecord={record}
                    associationEntityName={FEATURE}
                    associationModuleName={PROJECT_MODULE}
                    associationType="SURVEY_STRUCTURE"
                    recordColumns={['BuildStatus', 'type', 'ExternalRef']}
                  />
                </Col>
              </Row>
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Associated Features */}
        {['SURVEY_STRUCTURE', 'SURVEY_ROUTE', 'BLOCKAGE'].includes(record?.type!) ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    moduleName={PROJECT_MODULE}
                    entityName={FEATURE}
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>Related Features</span>
                </>
              }
            >
              <AssociationSimpleList
                parentRecord={record}
                associationEntityName={FEATURE}
                associationModuleName={PROJECT_MODULE}
                recordColumns={['BuildStatus', 'type', 'ExternalRef']}
              />
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Blockages List & Actions */}
        {!['BLOCKAGE', 'SURVEY_STRUCTURE', 'SURVEY_ROUTE'].includes(record?.type!) ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              extra={
                <Dropdown
                  disabled={!['ROPE', 'CABLE', 'CHAMBER', 'CLOSURE'].includes(record?.type!)}
                  trigger={['click']}
                  overlay={
                    <Menu>
                      {/* Create Survey Route */}
                      {record?.type &&
                      ['ROPE', 'CABLE', 'CHAMBER', 'CLOSURE'].includes(record?.type!) ? (
                        <Menu.Item key={'createBlockage'} onClick={() => createBlockage()}>
                          Create Blockage
                        </Menu.Item>
                      ) : (
                        <></>
                      )}
                    </Menu>
                  }
                >
                  <Button icon={<CaretDownOutlined style={{ fontSize: '0.9em' }} />} />
                </Dropdown>
              }
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    overrideIcon="geo-alt-fill"
                    overrideIconColor="#1cc773"
                    moduleName=""
                    entityName=""
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>Blockage</span>
                </>
              }
            >
              <AssociationSimpleList
                parentRecord={record}
                associationEntityName={FEATURE}
                associationModuleName={PROJECT_MODULE}
                associationType="BLOCKAGE"
                recordColumns={['BuildStatus', 'type', 'ExternalRef']}
              />
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Blockages List & Actions */}
        {['BLOCKAGE'].includes(record?.type!) ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <Card
              size="small"
              extra={
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      <Menu.Item key={'createPIAOrder'} onClick={() => setFlowVisible(true)}>
                        Create PIA Order
                      </Menu.Item>
                    </Menu>
                  }
                >
                  <Button icon={<DownOutlined />} />
                </Dropdown>
              }
              title={
                <>
                  <ModuleEntityIcon
                    iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
                    moduleName={PROJECT_MODULE}
                    entityName={FEATURE}
                  />
                  <span style={{ fontSize: '1.1em', marginTop: 2 }}>Network Adjustments</span>
                </>
              }
            >
              <AssociationSimpleList
                parentRecord={record}
                associationEntityName={'OpenreachNetworkAdjustment'}
                associationModuleName={PROJECT_MODULE}
                recordColumns={['OrderState', 'recordNumber']}
              />
            </Card>
          </Col>
        ) : (
          <></>
        )}

        {/* Closures - Show Splicing Information */}
        {['CLOSURE'].includes(record?.type!) ? (
          <div style={{ marginTop: 15 }}>
            <SplicingSummaryWidget parentRecord={record} />
          </div>
        ) : (
          <></>
        )}

        {/* Outcome Forms Launcher */}
        {schemasWithOutcomeForm.length > 0 ? (
          <Col span={24} style={{ marginTop: 15 }}>
            <SchemaActionFormLauncher parentRecord={record!} parentSchema={schema!} />
          </Col>
        ) : (
          <></>
        )}

        <Col span={24}>
          <FeatureMapWidget feature={record} />
        </Col>
      </Row>
      <Col span={24}>
        <Card title="Notes" style={{ marginTop: 15 }}>
          <NoteFeed record={record} />
        </Card>
      </Col>
    </>
  );
};