import { Button } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
}

const { CRM_MODULE, FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { SERVICE_APPOINTMENT_CONFIG, NOTE } = SchemaModuleEntityTypeEnums;

const CustomDetailActions: React.FC<Props> = (props: Props) => {
  const { record, schema } = props;

  if (record && schema) {
    if (schema.moduleName === CRM_MODULE && schema.entityName === 'CrmDataset') {
      return (
        <>
          <Link to={`/${CRM_MODULE}/CrmDataset/${record?.id}/Build`}>
            <Button
              intent="primary"
              icon={<i className="bi bi-wrench" />}
              style={{ marginRight: 8 }}
            >
              Build
            </Button>
          </Link>
          <Link to={`/${CRM_MODULE}/CrmDataset/${record?.id}/Work`}>
            <Button intent="primary" icon={<i className="bi bi-person-workspace" />}>
              Work
            </Button>
          </Link>
        </>
      );
    } else {
      return <></>;
    }
  } else {
    return <></>;
  }
};
export default CustomDetailActions;
