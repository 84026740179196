import { DragOverlay } from '@dnd-kit/core';
import { FC } from 'react';
import DataSetItem from '../DataSetItem';

interface Props {
  items: any[];
}

const DragOverlayItems: FC<Props> = ({ items }: Props) => {
  return (
    <DragOverlay wrapperElement="div">
      {items.map((el) => (
        <DataSetItem
          key={el.id}
          checkable={false}
          style={{
            cursor: 'grabbing',
            listStyle: 'none',
            padding: '1em 1em',
            borderTop: '1px  solid rgba(17, 20, 24, 0.15)',
            borderLeft: '1px solid rgba(17, 20, 24, 0.15)',
            borderRight: '1px  solid rgba(17, 20, 24, 0.15)',
          }}
          id={el.id}
          dataset={el}
          agents={[]}
        />
      ))}
    </DragOverlay>
  );
};

export default DragOverlayItems;
