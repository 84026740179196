import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { FPEStatusFilter } from './context/hooks/useAFPApprovalReducer/types';

export const getRecordStatus = (record: DbRecordEntityTransform): FPEStatusFilter => {
  const isApproved = String(getProperty(record, 'IsApproved'));

  if (isApproved === 'true') return 'approved';
  if (isApproved === 'false') return 'rejected';

  return 'todo';
};
