export const DB_RECORD_TABLE_SET_COLUMNS = 'DB_RECORD_TABLE_SET_COLUMNS';
export const DB_RECORD_TABLE_ADD_COLUMN = 'DB_RECORD_TABLE_ADD_COLUMN';
export const DB_RECORD_TABLE_REMOVE_COLUMN = 'DB_RECORD_TABLE_REMOVE_COLUMN';

export const DB_RECORD_TABLE_SET_DATA = 'DB_RECORD_TABLE_SET_DATA';

export const RESET_DB_RECORD_TABLE_FILTERS = 'RESET_DB_RECORD_TABLE_FILTERS';
export const RESET_DB_RECORD_TABLE = 'RESET_DB_RECORD_TABLE';

export const DB_RECORD_CLEAR_SAVED_FILTER = 'DB_RECORD_CLEAR_SAVED_FILTER';
export const DB_RECORD_SAVE_FILTER = 'DB_RECORD_SAVE_FILTER';

export const DB_RECORD_TABLE_SELECT_ROW = 'DB_RECORD_TABLE_SELECT_ROW';
export const DB_RECORD_TABLE_DESELECT_ROW = 'DB_RECORD_TABLE_DESELECT_ROW';
export const DB_RECORD_TABLE_BULK_SELECT_ROWS = 'DB_RECORD_TABLE_BULK_SELECT_ROWS';
export const DB_RECORD_TABLE_BULK_DESELECT_ROWS = 'DB_RECORD_TABLE_BULK_DESELECT_ROWS';

export const DB_RECORD_TABLE_SET_CONFIG = 'DB_RECORD_TABLE_SET_CONFIG';

export const UPDATE_TABLE_ROW_PROPERTY = 'UPDATE_TABLE_ROW_PROPERTY';
export const UPDATE_TABLE_RECORD = 'UPDATE_TABLE_RECORD';
