import { DbRecordAssociationEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.entity.transform';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

export const getRecordLink = (record: DbRecordEntityTransform, entity: string): any => {
  if (record && record.links && record.links.length > 0) {
    return record.links.find((elem) => elem.entity === entity);
  }
};

export const getRecordRelatedRecordsFromShortList = (
  shortList: { [recordId: string]: any },
  recordId: string,
  entity: string,
): DbRecordEntityTransform[] | undefined => {
  const associationKey = `${recordId}_${entity}`;
  const associationObj: any = shortList[associationKey];

  return getAllRelations(associationObj, entity);
};

export const getRecordListFromShortListById = (
  shortList: { [schemaId: string]: DbRecordEntityTransform[] },
  schemaId: string,
): DbRecordEntityTransform[] => {
  return shortList[schemaId];
};

export const getRecordFromShortListById = (
  shortList: { [recordId: string]: DbRecordEntityTransform },
  recordId: string | null | undefined,
): DbRecordEntityTransform => {
  // @ts-ignore
  return shortList[recordId];
};

export const getRecordRelatedFromShortListById = (
  shortList: { [recordId: string]: any },
  dbRecordAssociationId: string,
  recordId: string,
): DbRecordEntityTransform => {
  return shortList[`${dbRecordAssociationId}_${recordId}`];
};

export const splitModuleAndEntityName = (entity: string) => {
  let moduleName;
  let entityName;
  if (entity) {
    const split = entity.split(':');
    moduleName = split[0];
    entityName = split[1];
  }

  return { moduleName, entityName };
};

export const getModuleAndEntityNameFromRecord = (record: DbRecordEntityTransform) => {
  let moduleName = '';
  let entityName = '';

  const split = record?.entity?.split(':');
  if (split) {
    moduleName = split[0];
    entityName = split[1];
  }

  return { moduleName, entityName };
};

export const getEntityNameFromRecord = (record: DbRecordEntityTransform) => {
  if (record) {
    const moduleEntity = getModuleAndEntityNameFromRecord(record);
    return moduleEntity?.entityName || undefined;
  } else {
    return undefined;
  }
};

export const getTabKeyFromBrowserPath = (urlHash: string, tabPane: string, defaultKey?: string) => {
  if (urlHash) {
    if (urlHash.indexOf(tabPane) > -1) {
      return urlHash;
    }
  }

  return defaultKey;
};

export const getBrowserPath = (record: DbRecordEntityTransform) => {
  if (record) {
    return `/${getModuleAndEntityNameFromRecord(record).moduleName}/${
      getModuleAndEntityNameFromRecord(record).entityName
    }/${record.id}`;
  } else {
    return '';
  }
};

export const getRelatedRecordBrowserPath = (
  record: DbRecordEntityTransform,
  dbRecordAssociation: DbRecordAssociationEntityTransform | null | undefined,
) => {
  return `/${getModuleAndEntityNameFromRecord(record).moduleName}/related/${
    getModuleAndEntityNameFromRecord(record).entityName
  }/${dbRecordAssociation?.id}/${record.id}`;
};

export const parseParams = (querystring: string) => {
  // parse query string
  const params = new URLSearchParams(querystring);

  const obj = {};

  // iterate over all keys
  // @ts-ignore
  for (const key of params.keys()) {
    if (params.getAll(key).length > 1) {
      // @ts-ignore
      obj[key] = params.getAll(key);
    } else {
      // @ts-ignore
      obj[key] = params.get(key);
    }
  }

  return obj;
};

/**
 * Find and return FILE association in ALL associated schemas
 * @param schema
 */
export const getFileSchemaAssociation = (schema: SchemaEntity) => {
  const { FILE } = SchemaModuleEntityTypeEnums;

  // If schema has associations at all...
  if (schema && schema?.associations?.length > 0) {
    // Get ALL schema associations
    const allRelatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, []);

    // Find FILE schema association among ALL associations
    const fileSchema = allRelatedSchemas.find(
      (schemaAssociation: SchemaEntity) => schemaAssociation.entityName === FILE,
    );

    // If FILE is present among ALL schema associations...
    if (fileSchema) {
      // Find schema association with childSchema id of FILE
      return schema?.associations.find((schema: any) => schema.childSchemaId === fileSchema?.id);
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getChildSchemaTypes = (
  schemaAssociation: SchemaAssociationEntity,
  parentRecordType: string,
) => {
  if (schemaAssociation) {
    // 1. If there are no schema constraints...

    if (schemaAssociation?.schemaTypesConstraints?.length === 0) {
      // Show all child schema types, or show everything.
      if (schemaAssociation?.childSchema?.types?.length > 0) {
        return schemaAssociation?.childSchema?.types?.map((schemaType: any) => schemaType.name!);
      } else {
        return [];
      }
    }

    // 2. If there are schema constraints...
    else {
      let matchedConstraints: any = [];
      schemaAssociation?.schemaTypesConstraints?.map((constraint: any) => {
        if (
          constraint.parentSchemaType?.name! === parentRecordType ||
          !constraint.parentSchemaType?.name
        ) {
          matchedConstraints.push(constraint.childSchemaType?.name!);
        }
      });

      return matchedConstraints;
    }
  }
};

/**
 * Find and return FILE association in ALL associated schemas
 * @param schema
 */
export const getSchemaAssociationByEntity = (schema: SchemaEntity, associatedEntity: string) => {
  // If schema has associations at all...
  if (schema && schema?.associations?.length > 0) {
    // ... get ALL schema associations...
    const allRelatedSchemas = getAllSchemaAssociationSchemas(schema?.associations, []);

    // ...find targeted schema association among ALL associations...
    const associatedSchema = allRelatedSchemas.find(
      (schemaAssociation: SchemaEntity) => schemaAssociation.entityName === associatedEntity,
    );

    // ...if targeted childSchema association is found
    if (associatedSchema) {
      return schema?.associations.find(
        (schema: any) => schema.childSchemaId === associatedSchema?.id,
      );
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const getAllSchemaAssociationEntities = (
  associations: SchemaAssociationEntity[],
  hidden: string[] | undefined,
): string[] => {
  const entityNames = [];
  for (const elem of associations) {
    if (elem.childSchema) {
      if (hidden && !hidden.includes(elem.childSchema.entityName)) {
        entityNames.push(elem.childSchema.entityName);
      } else if (!hidden) {
        entityNames.push(elem.childSchema.entityName);
      }
    } else if (elem.parentSchema) {
      if (hidden && !hidden.includes(elem.parentSchema.entityName)) {
        entityNames.push(elem.parentSchema.entityName);
      } else if (!hidden) {
        entityNames.push(elem.parentSchema.entityName);
      }
    }
  }

  return entityNames;
};

/**
 * ODN-2307 - we should pass the full schema into this function
 * TODO: Pass in the full schema instead of just the schemaAssociations
 * Check 1: Then add support for self relations elem.label EntityName_EntityName
 * Check 2: handle schema.entityName !== elem.childSchema.entityName (return the childSchema)
 * Check 3: handle schema.entityName !== elem.parentSchema.entityName (return the parentSchema)
 *
 * @param associations
 * @param hidden
 * @param excludeParentRelations
 * @param excludeChildRelations
 */
export const getAllSchemaAssociationSchemas = (
  // sourceSchema: SchemaEntity,
  associations?: SchemaAssociationEntity[],
  hidden?: string[] | undefined,
  excludeParentRelations?: boolean,
  excludeChildRelations?: boolean,
): SchemaEntity[] => {
  const schemas: SchemaEntity[] = [];
  const uniqueSchemas = [];

  if (associations) {
    for (const elem of associations) {
      if (elem.childSchema && !excludeChildRelations) {
        if (hidden && !hidden.includes(elem.childSchema.entityName)) {
          schemas.push(elem.childSchema);
        } else if (!hidden) {
          schemas.push(elem.childSchema);
        }
      } else if (elem.parentSchema && !excludeParentRelations) {
        if (hidden && !hidden.includes(elem.parentSchema.entityName)) {
          schemas.push(elem.parentSchema);
        } else if (!hidden) {
          schemas.push(elem.parentSchema);
        }
      }
    }
    // Filter unique schemas
    const flags: { [key: string]: boolean } = {};

    for (const schema of schemas) {
      if (!flags[schema.entityName]) {
        flags[schema.entityName] = true;
        uniqueSchemas.push(schema);
      }
    }
  }

  return uniqueSchemas?.filter((schema: SchemaEntity) => schema.isVisibleInTabs);
};

/**
 * ODN-1640 Checks if the record can be locked and is locked
 *
 * @param record
 * @returns
 */
export const checkRecordIsLocked = (record: DbRecordEntityTransform | undefined): boolean => {
  if (
    record?.entity ===
    `${SchemaModuleTypeEnums.BILLING_MODULE}:${SchemaModuleEntityTypeEnums.INVOICE}`
  ) {
    const isLocked = getProperty(record, 'IsLocked');
    if (isLocked?.toLowerCase() === 'false') {
      return false;
    } else if (isLocked?.toLowerCase() === 'true') {
      return true;
    }
  }
  return false;
};
