import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Button } from '@blueprintjs/core';
import { HexColorPicker } from 'react-colorful';

interface Props {
  onChange: (color: string | undefined) => void;
  initialValue: any;
}

const FormColorPicker: React.FC<Props> = (props: Props) => {
  const { initialValue, onChange } = props;

  const [color, setColor] = useState<any>(initialValue || undefined);

  const handleColorChange = (color: string | undefined) => {
    setColor(color);
    onChange(color);
  };

  const resetColor = () => {
    handleColorChange(undefined);
  };

  const undoColor = () => {
    handleColorChange(initialValue);
  };

  const isColorUpdated = () => {
    return color !== initialValue;
  };

  return (
    <>
      <Row style={{ margin: '10px 0' }} gutter={12}>
        <Col span={12}>
          <Button icon="reset" text="Undo" fill disabled={!isColorUpdated()} onClick={undoColor} />
        </Col>
        <Col span={12}>
          <Button
            icon="delete"
            text="Remove Color"
            fill
            onClick={resetColor}
            disabled={!color}
            outlined
            intent="danger"
          />
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ padding: 6 }}>
          <HexColorPicker color={color} onChange={handleColorChange} style={{ width: '100%' }} />
        </Col>
      </Row>
    </>
  );
};
export default FormColorPicker;
