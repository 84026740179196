import { Button } from '@blueprintjs/core';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import CableDiagram from '../../components/CableDiagram';
import RecordCard from '@legacy/core/records/components/RecordCard';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import { DetailViewContext } from '../../../../../core/components/DetailViewContextProvider';
import DetailView from '../../../../../core/views/DetailView';

const ProjectDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  const renderCustomButtons = () => {
    /* Build Pack Button - only L2 for now. */
    if (record && ['L1', 'L2'].includes(getProperty(record, 'PolygonType'))) {
      return [
        <Link target="_blank" to={`/ProjectModule/BuildPack/${record?.id}`}>
          <Button>Generate Build Pack</Button>
        </Link>,
      ];
    }
    return [];
  };

  return (
    <Layout>
      <DetailView
        showCollaborators
        headerExtras={[renderCustomButtons()]}
        customTabs={[
          {
            key: 'CableDiagram',
            tab: 'Cable Diagram',
          },
        ]}
        customTabBodies={{
          CableDiagram: (
            <CableDiagram
              recordId={record?.id}
              polygonId={Number(getProperty(record, 'ExternalRef'))}
              // columnFullscreen={this.state.detailsColumnExpanded}
            />
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={SchemaModuleEntityTypeEnums.PROGRAM}
            visibleProperties={['Contractor']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            showWithoutPagination
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={'WorkList'}
            visibleProperties={[]}
          />,
          <AssociationDataTable
            thumbnailSize={12}
            title="Files"
            record={record}
            showFileCategoryForType="DEFAULT"
            moduleName="SchemaModule"
            entityName="File"
            location="sidebar"
            collapsedByDefault
          />,
        ]}
      />
    </Layout>
  );
};

export default ProjectDetailView;
