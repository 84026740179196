import { DbRecordAssociationCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Col, Divider, Drawer, Empty, Row, Select, Switch, Tag, Tooltip } from 'antd';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { sendConfirmationEmail } from '@legacy/core/notifications/email/store/actions';
import RecordCard from '@legacy/core/records/components/RecordCard';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { closeProductReplacementDrawer } from '@legacy/core/userInterface/store/actions';
import { IUserInterfaceReducer } from '@legacy/core/userInterface/store/types';
import { httpPatch } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import './styles.scss';
import { Callout } from '@blueprintjs/core';

interface Props {
  userInterfaceReducer: IUserInterfaceReducer;
  schemaReducer: any;
  getSchema: Function;
  getAssociations: Function;
  closeReplacementDrawer: Function;
  searchRecords: Function;
  alertMessage: Function;
  sendConfirmation: Function;
  onSuccess?: () => void;
}

const { PRODUCT_MODULE, CRM_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { OFFER, PRODUCT, ADDRESS, ORDER, ORDER_ITEM } = SchemaModuleEntityTypeEnums;

const ReplaceOrderItemProduct: FC<Props> = (props: Props) => {
  const {
    schemaReducer,
    getSchema,
    userInterfaceReducer,
    getAssociations,
    closeReplacementDrawer,
    searchRecords,
    alertMessage,
    sendConfirmation,
    onSuccess,
  } = props;

  const { productReplacementDrawerVisible, parentRecord, orderItemRecord } =
    userInterfaceReducer?.productReplacement;

  const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(undefined);
  const [orderItemSchema, setOrderItemSchema] = useState<SchemaEntity | undefined>(undefined);
  const [offerSchema, setOfferSchema] = useState<SchemaEntity | undefined>(undefined);
  const [customerType, setCustomerType] = useState<'RESIDENTIAL' | 'BUSINESS' | undefined>(
    undefined,
  );
  const [offerList, setOfferList] = useState<DbRecordEntityTransform[]>([]);
  const [productList, setProductList] = useState<DbRecordEntityTransform[]>([]);
  const [selectedOffer, setSelectedOffer] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [selectedProduct, setSelectedProduct] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [isLoadingOffers, setIsLoadingOffers] = useState<boolean>(false);
  const [isLoadingProducts, setIsLoadingProducts] = useState<boolean>(false);
  const [isReplacingProduct, setIsReplacingProduct] = useState<boolean>(false);
  const [emailConfirmation, setEmailConfirmation] = useState<boolean>(false);

  // When both parent and order item records are available, fetch schemas. Wait for when
  // drawer is visible so we don't overkill the API requests in the UI.
  useEffect(() => {
    if (
      parentRecord &&
      orderItemRecord &&
      productReplacementDrawerVisible &&
      !parentSchema &&
      !orderItemSchema
    ) {
      getSchemas();
    }
  }, [productReplacementDrawerVisible, schemaReducer.list]);

  // When Order Item schema and record are available, fetch associated address.
  // Address will tell us if it's Residential or Business, and we use this for offers.
  useEffect(() => {
    if (parentRecord && parentSchema && !customerType) {
      getAssociations(
        {
          recordId: parentRecord.id,
          schema: parentSchema,
          entities: [ADDRESS],
        },
        (res: any) => {
          const relatedAddress = getFirstRelation(res.results, ADDRESS);
          if (relatedAddress) {
            setCustomerType(getProperty(relatedAddress, 'Classification') || 'RESIDENTIAL');
          } else {
            setCustomerType('RESIDENTIAL');
          }
        },
      );
    }
  }, [parentRecord, parentSchema]);

  // When offer is selected, fetch the list of offer products.
  useEffect(() => {
    if (selectedOffer && offerSchema) {
      setIsLoadingProducts(true);
      getAssociations(
        {
          recordId: selectedOffer?.id,
          key: OFFER,
          schema: offerSchema,
          entities: [PRODUCT],
        },
        (res: any) => {
          if (res && res.results?.[PRODUCT]?.dbRecords?.length > 0) {
            const products = res.results?.[PRODUCT]?.dbRecords;
            setProductList(products);
          }
          setIsLoadingProducts(false);
        },
      );
    }
  }, [selectedOffer, offerSchema]);

  // When customer type and offer schema are available, fetch the list of offers.
  useEffect(() => {
    if (offerSchema && customerType) {
      setIsLoadingOffers(true);
      searchRecords(
        {
          schema: offerSchema,
          searchQuery: {
            terms: '*',
            schemas: offerSchema?.id,
            must: [
              {
                query_string: {
                  fields: ['properties.CustomerType'],
                  query: customerType,
                  lenient: true,
                  default_operator: 'AND',
                },
              },
            ],
            sort: [
              {
                createdAt: {
                  order: 'desc',
                },
              },
            ],
            pageable: {
              page: 1,
              size: 200,
            },
          },
        },
        (res: any) => {
          if (res.data?.data) {
            setOfferList(res.data.data);
          }
          setIsLoadingOffers(false);
        },
      );
    }
  }, [offerSchema, customerType]);

  const getSchemas = async () => {
    try {
      // WorkOrders are currently not supported, if the day comes, update this piece
      // to retrieve schema for whatever record was passed as a parent record
      const shortlistParentRecordSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        ORDER_MODULE,
        ORDER,
      );
      if (shortlistParentRecordSchema) {
        setParentSchema(shortlistParentRecordSchema);
      } else {
        getSchema(
          { moduleName: ORDER_MODULE, entityName: ORDER },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setParentSchema(responseSchema);
            }
          },
        );
      }

      // Get Order item Schema
      const shortlistOrderItemSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        ORDER_MODULE,
        ORDER_ITEM,
      );
      if (shortlistOrderItemSchema) {
        setOrderItemSchema(shortlistOrderItemSchema);
      } else {
        getSchema(
          { moduleName: ORDER_MODULE, entityName: ORDER_ITEM },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setOrderItemSchema(responseSchema);
            }
          },
        );
      }

      // Get Offer Schema
      const shortlistOfferSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        PRODUCT_MODULE,
        OFFER,
      );
      if (shortlistOfferSchema) {
        setOfferSchema(shortlistOfferSchema);
      } else {
        getSchema(
          { moduleName: PRODUCT_MODULE, entityName: OFFER },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setOfferSchema(responseSchema);
            }
          },
        );
      }
    } catch (error) {}
  };

  const handleOfferSelection = (selectedOfferId: string) => {
    const selected = offerList.find(
      (offer: DbRecordEntityTransform) => offer.id === selectedOfferId,
    );
    if (selected) {
      setSelectedOffer(selected);
    }
  };

  const renderProductList = () => {
    /*
      ** Corresponding properties *****************
      OI ProductCustomerType = PRODUCT CustomerType
      OI ProductCategory = PRODUCT Category
      OI ProductType = PRODUCT ProductType
      */
    const ProductCustomerType = getProperty(orderItemRecord, 'ProductCustomerType');
    const ProductCategory = getProperty(orderItemRecord, 'ProductCategory');
    const ProductType = getProperty(orderItemRecord, 'ProductType');

    // Match only those products that satisfy current order item constraints
    let filteredProducts = productList.filter(
      (product: DbRecordEntityTransform) =>
        getProperty(product, 'CustomerType') === ProductCustomerType &&
        getProperty(product, 'Category') === ProductCategory &&
        getProperty(product, 'Type') === ProductType,
    );

    return (
      <Row style={{ marginTop: 30, opacity: isLoadingOffers || isLoadingProducts ? 0.3 : 1 }}>
        <Col span={24}>
          {filteredProducts.map((product: DbRecordEntityTransform) => (
            <div style={{ marginBottom: 10 }} key={product.id}>
              <RecordCard
                openTitleLinkInNewTab
                propertyColumns={2}
                record={product}
                visibleProperties={[
                  'Type',
                  'Category',
                  'UnitPrice',
                  'DiscountType',
                  'DiscountValue',
                  'TrialUnit',
                  'TrialLength',
                ]}
                headerElement={
                  <Button
                    type="primary"
                    disabled={isLoadingOffers || isLoadingProducts}
                    onClick={() => setSelectedProduct(product)}
                  >
                    Select
                  </Button>
                }
              />
            </div>
          ))}
        </Col>
      </Row>
    );
  };

  // This will show when the user selected a product.
  const renderSelectedProductView = () => {
    return (
      <Row>
        {/* Order Item to be replaced */}
        <Col span={24}>
          {
            <RecordCard
              openTitleLinkInNewTab
              propertyColumns={2}
              record={orderItemRecord!}
              visibleProperties={[
                'Quantity',
                'UnitPrice',
                'TotalDiscounts',
                'TotalPrice',
                'BillingStartDate',
                'NextInvoiceDate',
                'NextBillingDate',
                'BillingPeriodType',
              ]}
            />
          }
        </Col>

        {/* Replace Arrows */}
        <Col span={24} style={{ textAlign: 'center', marginBottom: 10 }}>
          <i className="bi bi-arrow-down" style={{ fontSize: '1.8em' }} />
          <i className="bi bi-arrow-up" style={{ fontSize: '1.8em' }} />
        </Col>

        {/* Selected product */}
        <Col span={24}>
          <RecordCard
            openTitleLinkInNewTab
            propertyColumns={2}
            record={selectedProduct!}
            visibleProperties={[
              'Type',
              'Category',
              'UnitPrice',
              'DiscountType',
              'DiscountValue',
              'TrialUnit',
              'TrialLength',
            ]}
          />
        </Col>

        {/* Replace form dialog */}
        <Col span={24}>
          <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        </Col>

        {/* Email Confirmation Switch */}
        <Tooltip
          mouseEnterDelay={1.5}
          title="Enabling this option will send an order confirmation email to the customer."
        >
          <Col span={12} style={{ textAlign: 'left', paddingTop: 4 }}>
            <Switch
              checked={emailConfirmation}
              onChange={() => setEmailConfirmation(!emailConfirmation)}
              disabled={isReplacingProduct}
              style={{ marginRight: 5 }}
            />{' '}
            {/* Line Break for Mobile viewport */}
            {isMobile ? (
              <div>
                <br style={{ lineHeight: '0.6em' }} />
              </div>
            ) : (
              <></>
            )}
            <span className="clickable" onClick={() => setEmailConfirmation(!emailConfirmation)}>
              Email Confirmation
            </span>
          </Col>
        </Tooltip>

        {/* Cancel Button */}
        <Col span={12} style={{ textAlign: 'right' }}>
          <Button
            type="default"
            style={{ marginRight: isMobile ? 0 : 12 }}
            disabled={isReplacingProduct}
            onClick={() => setSelectedProduct(undefined)}
          >
            Cancel
          </Button>

          {/* Line Break for Mobile Viewport */}
          {isMobile ? <br /> : <></>}

          {/* Replace Button */}
          <Button
            style={{ marginTop: isMobile ? 10 : 0 }}
            type="primary"
            onClick={() => onReplace()}
            loading={isReplacingProduct}
            disabled={isReplacingProduct}
          >
            Replace
          </Button>
        </Col>
      </Row>
    );
  };

  const onDrawerClose = () => {
    closeReplacementDrawer();
    setOfferList([]);
    setProductList([]);
    setOfferSchema(undefined);
    setParentSchema(undefined);
    setOrderItemSchema(undefined);
    setSelectedOffer(undefined);
    setSelectedProduct(undefined);
  };

  const areOfferAndOrderItemOfSameCustomerType = () => {
    if (selectedOffer && orderItemRecord) {
      return getProperty(selectedOffer, 'CustomerType') ===
        getProperty(orderItemRecord, 'ProductCustomerType')
        ? true
        : false;
    } else {
      return true;
    }
  };

  // Replace product and, if requested - send order confirmation email.
  const onReplace = async () => {
    setIsReplacingProduct(true);

    const body: DbRecordAssociationCreateUpdateDto[] = [];
    body.push({
      relatedAssociationId: selectedProduct?.dbRecordAssociation?.relatedAssociationId,
      entity: `${PRODUCT_MODULE}:${PRODUCT}`,
      recordId: selectedProduct?.id!,
      additionalParams: { offerId: selectedOffer?.id },
    });

    await httpPatch(
      `OrderModule/v1.0/orders/items/${orderItemRecord?.id}/productAmendment`,
      body[0],
    )
      .then((res: any) => {
        onDrawerClose();
        onSuccess && onSuccess();
        setIsReplacingProduct(false);
        alertMessage({
          body: 'Product replaced successfully!',
          type: 'success',
        });

        // Send email order confirmation if requested
        if (emailConfirmation) {
          sendConfirmation(
            `OrderModule/v1.0/orders/${parentRecord?.id}/email/SENDGRID_ORDER_CONFIRMATION_V2`,
          );
        }
      })
      .catch((err) => {
        console.log('%ReplaceOrderItemProduct: Record update error', 'color:red', err);
        alertMessage({
          body: 'There was a problem replacing the product.',
          type: 'error',
        });
        setIsReplacingProduct(false);
      });
  };

  return (
    <Drawer
      width={isMobile ? '100%' : 600}
      open={productReplacementDrawerVisible}
      title={`Replacing ${orderItemRecord?.title} Order Item`}
      onClose={() => onDrawerClose()}
    >
      <Row>
        <Col span={24} style={{ display: selectedProduct ? 'none' : 'block' }}>
          <Select
            className="offerSelectInput"
            size="large"
            placeholder={isLoadingOffers ? 'Loading offers...' : 'Select Offer'}
            loading={isLoadingOffers}
            disabled={isLoadingOffers || offerList?.length === 0 || !offerSchema}
            defaultActiveFirstOption={false}
            value={selectedOffer?.id}
            filterOption={false}
            onChange={handleOfferSelection}
            notFoundContent={null}
            showSearch
            style={{ width: '100%' }}
          >
            {offerList?.map((offer: DbRecordEntityTransform) => (
              <Select.Option
                className="offerSelectInputItem"
                label={offer?.title}
                value={offer?.id}
                key={`offer-${offer?.id}`}
              >
                <Row>
                  <Col span={24} style={{ fontWeight: 500 }}>
                    {offer?.title}
                  </Col>
                  <Col span={24} style={{ color: '#8f8f8f', fontSize: '0.8em' }}>
                    <span>
                      {/* Contract Type */}
                      {getProperty(offer, 'ContractType') ? (
                        <Tag
                          style={{
                            fontSize: '0.8em',
                            padding: '0px 2px',
                            lineHeight: '15px',
                            borderRadius: 2,
                          }}
                        >
                          {getProperty(offer, 'ContractType') || '-'}
                        </Tag>
                      ) : (
                        <></>
                      )}
                      {/* Customer Type */}
                      {getProperty(offer, 'CustomerType') ? (
                        <Tag
                          style={{
                            fontSize: '0.8em',
                            padding: '0px 2px',
                            lineHeight: '15px',
                            borderRadius: 2,
                          }}
                        >
                          {getProperty(offer, 'CustomerType') || '-'}
                        </Tag>
                      ) : (
                        <></>
                      )}
                      {/* From, To, Code */}
                      From: {getProperty(offer, 'AvailableFrom')}, To:{' '}
                      {getProperty(offer, 'AvailableTo') || '-'}, Code:{' '}
                      {getProperty(offer, 'Code') || '-'}
                    </span>
                  </Col>
                </Row>
              </Select.Option>
            ))}
          </Select>
        </Col>
        {/* No Offer selected */}
        {!selectedOffer && (
          <Col span={24} style={{ marginTop: 40, textAlign: 'center' }}>
            <Empty
              description={
                <div style={{ marginTop: 20 }}>
                  <span>Please select an offer in the input above</span>
                  <br />
                  <span>in order to see the products.</span>
                </div>
              }
            />
          </Col>
        )}

        {selectedOffer && <span>{selectedOffer!.properties!.ProductCategory}</span>}

        {!areOfferAndOrderItemOfSameCustomerType() && selectedOffer && (
          <Callout intent="warning" title="Offer Mismatch" style={{ marginTop: 20 }}>
            {`We cannot show products, because the offer you selected is ${getProperty(
              selectedOffer,
              'CustomerType',
            )}, and the order item you are trying to replace is ${getProperty(
              orderItemRecord,
              'ProductCustomerType',
            )}.`}
          </Callout>
        )}

        {/* Products */}
        {productList.length > 0 && areOfferAndOrderItemOfSameCustomerType() && !selectedProduct ? (
          <Col span={24}>{renderProductList()}</Col>
        ) : (
          <></>
        )}
        {selectedProduct ? renderSelectedProductView() : <></>}
      </Row>
    </Drawer>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  closeReplacementDrawer: () => dispatch(closeProductReplacementDrawer()),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  sendConfirmation: (payload: any) => dispatch(sendConfirmationEmail(payload)),
});

export default connect(mapState, mapDispatch)(ReplaceOrderItemProduct);
