import { getBuildClosureStyle } from '../../Autosplicing/helpers';

export function getLoopChainEdges(edges: any[]) {
  let response = [];

  const loopChainIds = edges
    .filter((edge: any) => edge.loopChainId !== null)
    .map((edge: any) => Number(edge.loopChainId));

  // @ts-ignore
  const uniqueLoopChainIds = [...new Set(loopChainIds)];

  for (const loopChainId of uniqueLoopChainIds) {
    response.push(edges.filter((edge: any) => edge.loopChainId === loopChainId));
  }

  return response;
}

export function getClosuresForLoops(loopChain: any[], nodes: any[]) {
  let response = [];

  /* Each loop chain*/
  for (const edge of loopChain) {
    for (const node of nodes) {
      if (node.id === edge.source) response.push(node);

      if (node.id === edge.target) response.push(node);
    }
  }

  return response;
}

const constructCableTitle = (cableDiagramData: Array<any>, edge: any) => {
  return `${edge.id} - ${edge.type} - ${getCableLength(cableDiagramData, edge.id)}m ${
    edge.isLoop === 'true' || edge.loopChainId ? ` - LOOP ${edge.loopChainId}` : ''
  }`;
  //return `${edge.id} - ${edge.type} - ${edge.isLoop === 'true' || edge.loopChainId ? ` - LOOP ${edge.loopChainId}` : ''}`
};

export const getChamberStyle = (ClosureType: string) => {
  switch (ClosureType) {
    case 'L0':
      return { fill: '#ff0000', color: '#fff', stroke: '#000' };
    case 'L1':
      return { fill: '#0000ff', color: '#fff', stroke: '#000' };
    case 'L2':
      return { fill: '#00ff00', color: '#000', stroke: '#000' };
    case 'L3':
      return { fill: '#ffff00', color: '#000', stroke: '#000' };
    case 'L4':
      return { fill: '#ffa500', color: '#000', stroke: '#000' };
    case 'LM':
      return { fill: '#e51cc7', color: '#fff', stroke: '#000' };
    default:
      return { fill: '#515151', color: '#fff', stroke: '#000' };
  }
};

export const getStaticColorFromArray = (pos: number | null) => {
  const colors = [
    '#F44336',
    '#9C27B0',
    '#3D5AFE',
    '#00BCD4',
    '#1DE9B6',
    '#43A047',
    '#9E9D24',
    '#607D8B',
    '#FF4081',
    '#651FFF',
    '#2196F3',
    '#F57F17',
    '#8D6E63',
    '#1DE9B6',
    '#43A047',
    '#9E9D24',
    '#F44336',
    '#9C27B0',
    '#3D5AFE',
    '#00BCD4',
    '#1DE9B6',
    '#43A047',
    '#9E9D24',
    '#607D8B',
    '#FF4081',
    '#651FFF',
    '#2196F3',
    '#F57F17',
    '#8D6E63',
    '#1DE9B6',
    '#43A047',
    '#9E9D24',
  ];

  return colors[pos || 0];
};

export const getCableColor = (cableType: string, loopChainId: number | null) => {
  if (loopChainId) {
    return getStaticColorFromArray(loopChainId);
  } else {
    switch (cableType) {
      case 'Spine':
        return '#fe0000';
      case 'Distribution':
        return '#0000fe';
      case 'Access':
        return '#00ff00';
      case 'Feed':
        return '#e61ee0';
      default:
        return '#000';
    }
  }
};

const getCableLength = (cables: any[], edge_id: string) => {
  const targetedCable: any = cables.find((cable: any) => cable.id === edge_id);

  if (targetedCable) {
    return String(Number(targetedCable.length).toFixed(0)) + 'm';
  } else {
    return 'Length unknown';
  }
};

const renderLoopData = (loopNumber: string | undefined) => {
  if (loopNumber && loopNumber !== '0') {
    return `LOOP ${loopNumber} - `;
  } else {
    return '';
  }
};

export function filterCableDiagramData(cableDiagramData: any) {
  let graphEdges = [];
  let graphNodes = [];

  let allLoopIds: Array<string> = [];
  let filteredLoopIds: Array<string> = [];
  for (const edge of cableDiagramData.edges) {
    if (edge.loopChainId) {
      allLoopIds.push(edge.loopChainId);
    }
  }
  allLoopIds?.map((loopId: string) => {
    const foundItem = allLoopIds.filter((loop: any) => loop === loopId);
    if (foundItem && foundItem.length > 1) {
      filteredLoopIds.push(foundItem[0]);
    }
  });

  const uniqueLoopIds = [...new Set([...filteredLoopIds])];

  for (const edge of cableDiagramData.edges) {
    let loopNumber: string | undefined = undefined;

    if (uniqueLoopIds.includes(edge.loopChainId)) {
      loopNumber = String(uniqueLoopIds.indexOf(edge.loopChainId) + 1);
    }
    const cableLength = getCableLength(cableDiagramData.features?.cables, edge.id);

    graphEdges.push({
      source: String(edge.source),
      target: String(edge.target),
      length: getCableLength(cableDiagramData.features?.cables, edge.id),
      label: `${edge.targetOnHold ? 'On Hold' : String(edge.id)} - ${edge.type} - ${renderLoopData(
        loopNumber,
      )} ${cableLength || ''}`,
      size: 4,
      labelCfg: {
        autoRotate: true,
        refY: 18,
        refX: -25,
      },
      style: {
        lineWidth: loopNumber ? 8 : 4,
        stroke: edge.targetOnHold ? '#A7A7A7' : getBuildClosureStyle(edge.buildStatus).fill,
        opacity: 1,
        fontSize: 7,
        lineDash: edge.targetOnHold ? [25] : undefined,
      },
    });
  }

  for (const node of cableDiagramData.nodes) {
    graphNodes.push({
      id: String(node.id),
      recordId: node.recordId ? String(node.recordId) : null,
      closure: node.closure,
      taskUniqueRef: node.closure?.taskUniqueRef,
      nearestAddress: node.closure?.nearest_address,
      buildStatus: node.closure?.build_status_id,
      label: `${node.type} \n ${node.isOnHold ? 'On Hold' : node.id}`,
      type: node.isOnHold ? 'image' : node.type,
      img: '/onhold.png',
      shape: 'image',
      size: 140,
      clipCfg: {
        show: true,
        type: 'circle',
        r: 110,
      },
      style: node.isOnHold ? {} : getBuildClosureStyle(node.buildStatus),
      labelCfg: {
        position: 'center',
        style: {
          fill: node.isOnHold
            ? {
              color: '#000',
            }
            : getBuildClosureStyle(node.buildStatus).color,
        },
      },
    });
  }

  return {
    nodes: graphNodes,
    edges: graphEdges,
  };
}
