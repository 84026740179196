import React from "react";
import {
  Collapse,
  Button,
  Tag,
} from "antd";
import { DbRecordEntityTransform } from "@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform";
import { FeatureDetails } from "./FeatureDetails";
import { getAllRelations, getProperty } from "@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers";

type FeatureListProps = {
  features: DbRecordEntityTransform[];
  extraActions?: React.ReactElement;
}

const getFeatureLabels = (feature: DbRecordEntityTransform) => {
  const labels: React.ReactElement[] = [];
  const files = getAllRelations(feature, 'File') || [];
  if (files.length === 0) labels.push(<Tag key={`${feature.recordNumber}-missing-files`} color="#c10000">Missing Files</Tag>);

  const buildComplete = files.find(file => getProperty(file, 'Category') === 'BUILD_COMPLETE')
  if (files.length > 0 && !buildComplete) labels.push(<Tag key={`${feature.recordNumber}-missing-build-complete`} color="#c10000">Missing Build Complete</Tag>);

  // If we have an empty array here, it means
  // this feature is already valid
  if (labels.length === 0) labels.push(<Tag key={`${feature.recordNumber}-all-set`} color="#00c100">All Set!</Tag>);

  return labels;
}

export const FeatureList = ({ features = [], extraActions }: FeatureListProps) => {
  const [expandedFeatures, setExpandedFeatures] = React.useState<string[]>([]);

  const expandAll = () => {
    const allFeatureKeys = features.map(feature => feature.recordNumber!)
    setExpandedFeatures(allFeatureKeys);
  }

  const collapseAll = () => {
    setExpandedFeatures([]);
  };

  const onCollapseChange = (val: string | string[]) => {
    setExpandedFeatures(Array.isArray(val) ? val : [val]);
  }

  const shouldExpand = expandedFeatures.length === 0 || expandedFeatures.length < features.length;

  return (
    <>
      {features.length > 0 && <Button style={{ marginBottom: '10px' }} onClick={shouldExpand ? expandAll : collapseAll}>{shouldExpand ? 'Expand' : 'Collapse'} All</Button>}
      &nbsp;
      {extraActions}
      <Collapse activeKey={expandedFeatures} onChange={onCollapseChange}>
      {features.map((record: DbRecordEntityTransform) => (
        <Collapse.Panel header={`PIA_STRUCTURE ${record.recordNumber}`} key={record.recordNumber!} extra={getFeatureLabels(record)}>
          <FeatureDetails record={record} />
        </Collapse.Panel>
          )
      )}
      </Collapse>
    </>
  )
}