import { BillingAdjustmentHelper } from '@d19n/temp-fe-d19n-common/dist/billing/helpers/BillingAdjustmentHelper';
import { BillingDatesCalculator } from '@d19n/temp-fe-d19n-common/dist/billing/helpers/BillingDatesCalculator';
import { OrderInvoiceItemCalculations } from '@d19n/temp-fe-d19n-common/dist/billing/helpers/OrderInvoiceItemCalculations';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import moment from 'moment';
import { calculateBillingPeriodEndDateFromOrderItems, computeDueDate } from '../billingHelpers';
import {
  ADD_INVOICE_ITEM,
  REMOVE_INVOICE_ITEM,
  SET_ACCOUNT,
  SET_ADDRESS,
  SET_BILLING_ADJUSTMENT,
  SET_BILLING_PERIOD_END_DATE,
  SET_BILLING_PERIOD_START_DATE,
  SET_BILLING_START_DATE,
  SET_BILLING_TERMS,
  SET_CONTACT,
  SET_DISCOUNT,
  SET_DUE_DATE,
  SET_INVOICE_CREATED,
  SET_INVOICE_ID,
  SET_INVOICE_ITEMS,
  SET_INVOICE_TYPE,
  SET_IS_BILLING_ADJUSTMENT_APPLIED,
  SET_IS_DISCOUNT_APPLIED,
  SET_IS_INVOICE_CREATING,
  SET_LOADING_OFFERS,
  SET_LOADING_PARENT_RECORD_ASSOCIATIONS,
  SET_LOADING_PRODUCTS,
  SET_OFFER_LIST,
  SET_OFFER_PRODUCT_LIST,
  SET_ORDER_RECORD,
  SET_PARENT_RECORD,
  SET_SELECTED_OFFER,
} from './constants';

interface IInvoiceBuilderReducer {
  address: DbRecordEntityTransform | undefined;
  billingAdjustment: DbRecordEntityTransform | undefined;
  billingPeriodEndDate: string | undefined;
  billingPeriodStartDate: string | undefined;
  billingStartDate: string | undefined;
  billingTerms: string | undefined;
  contact: DbRecordEntityTransform | undefined;
  account: DbRecordEntityTransform | undefined;
  discount: DbRecordEntityTransform | undefined;
  dueDate: string | undefined;
  invoiceId: string | undefined;
  invoiceItems: DbRecordEntityTransform[];
  invoiceType: 'BUSINESS' | 'RESIDENTIAL';
  isBillingAdjustmentApplied: boolean;
  isDiscountApplied: boolean;
  isInvoiceCreated: boolean;
  isInvoiceCreating: boolean;
  isLoadingOffers: boolean;
  isLoadingParentRecordAssociations: boolean;
  isLoadingProducts: boolean;
  offerList: DbRecordEntityTransform[];
  offerProductList: DbRecordEntityTransform[];
  orderRecord: DbRecordEntityTransform | undefined;
  parentRecord: DbRecordEntityTransform | undefined;
  selectedOffer: DbRecordEntityTransform | undefined;
}

export const invoiceBuilderInitialState: IInvoiceBuilderReducer = {
  address: undefined,
  billingAdjustment: undefined,
  billingPeriodEndDate: undefined,
  billingPeriodStartDate: undefined,
  billingStartDate: undefined,
  billingTerms: undefined,
  contact: undefined,
  account: undefined,
  discount: undefined,
  dueDate: undefined,
  invoiceId: undefined,
  invoiceItems: [],
  invoiceType: 'RESIDENTIAL',
  isBillingAdjustmentApplied: false,
  isDiscountApplied: false,
  isInvoiceCreated: false,
  isInvoiceCreating: false,
  isLoadingOffers: true,
  isLoadingParentRecordAssociations: true,
  isLoadingProducts: false,
  offerList: [],
  offerProductList: [],
  parentRecord: undefined,
  orderRecord: undefined,
  selectedOffer: undefined,
};

export function invoiceBuilderReducer(
  state: IInvoiceBuilderReducer,
  action: { type: string; payload: any },
) {
  const getRecalculatedItems = (
    invoiceItems: DbRecordEntityTransform[],
    discountApplied: boolean,
    BillingAdjustment: DbRecordEntityTransform | undefined,
    BillingPeriodStartDate: string | undefined,
  ) => {
    let recalculatedItems: any[] = [];
    let orderItems = invoiceItems.filter((item: any) => getProperty(item, 'Type') !== 'ADJUSTMENT');

    let modifiedOrderRecord: DbRecordEntityTransform = JSON.parse(
      JSON.stringify(state.orderRecord),
    );

    // 1. Apply order level discount if available
    if (!discountApplied) {
      modifiedOrderRecord.properties.DiscountValue = '0.00';
    }

    // 2. Apply Billing Adjustment if available
    if (BillingAdjustment) {
      // If there is BA and it is applied.
      const adjustmentInfo =
        BillingAdjustmentHelper.constructBillingAdjustmentInfo(BillingAdjustment);

      const adjustedBillingStartDateToBillingDay =
        BillingDatesCalculator.getAdjustedBillingStartDateToBillingDay(modifiedOrderRecord);

      const nextInvoiceDate = BillingDatesCalculator.getNextInvoiceDateFromOrderItems(
        orderItems,
        adjustedBillingStartDateToBillingDay,
      );

      const { newAdjustmentItem } = OrderInvoiceItemCalculations.constructBillingAdjustmentItem(
        orderItems,
        modifiedOrderRecord,
        adjustmentInfo,
        nextInvoiceDate,
      );

      if (newAdjustmentItem) {
        const properties = [...orderItems, newAdjustmentItem].map((item: any) => item.properties);
        //console.log('%cDebug > What gets sent to recalculation', 'color:hotpink', properties);

        recalculatedItems = OrderInvoiceItemCalculations.recalculateItemsTotals(
          [...orderItems, newAdjustmentItem],
          modifiedOrderRecord,
          BillingPeriodStartDate,
        );
      }
    } else {
      const properties = orderItems.map((item: any) => item.properties);

      recalculatedItems = OrderInvoiceItemCalculations.recalculateItemsTotals(
        orderItems,
        modifiedOrderRecord,
        BillingPeriodStartDate,
      );
    }

    // Debug calculations
    //const properties = recalculatedItems.map((item: any) => item.properties);
    //console.log('%cDebug > getRecalculatedItems', 'color:hotpink', properties);

    return recalculatedItems;
  };

  switch (action.type) {
    case SET_PARENT_RECORD:
      return {
        ...state,
        parentRecord: action.payload,
      };
    case SET_ORDER_RECORD:
      const BillingStartDate =
        getProperty(action.payload, 'BillingStartDate') || moment().format('YYYY-MM-DD');
      return {
        ...state,
        orderRecord: action.payload,
        billingStartDate: BillingStartDate,
        billingTerms: getProperty(action.payload, 'BillingTerms'),
      };
    case SET_LOADING_OFFERS:
      return {
        ...state,
        isLoadingOffers: action.payload,
      };
    case SET_LOADING_PRODUCTS:
      return {
        ...state,
        isLoadingProducts: action.payload,
      };
    case SET_LOADING_PARENT_RECORD_ASSOCIATIONS:
      return {
        ...state,
        isLoadingParentRecordAssociations: action.payload,
      };
    case SET_OFFER_LIST:
      return {
        ...state,
        offerList: action.payload,
      };
    case SET_OFFER_PRODUCT_LIST:
      return {
        ...state,
        offerProductList: action.payload,
      };
    case SET_SELECTED_OFFER:
      return {
        ...state,
        selectedOffer: action.payload,
      };

    case ADD_INVOICE_ITEM:
      let invoiceItems = Object.assign(state.invoiceItems);
      invoiceItems.push(action.payload);

      return {
        ...state,
        invoiceItems: getRecalculatedItems(
          invoiceItems,
          state.isDiscountApplied,
          state.isBillingAdjustmentApplied ? state.billingAdjustment : undefined,
          state.billingPeriodStartDate,
        ),
      };

    case REMOVE_INVOICE_ITEM:
      return {
        ...state,
        invoiceItems: getRecalculatedItems(
          state.invoiceItems.filter((item: DbRecordEntityTransform) => item.id !== action.payload),
          state.isDiscountApplied,
          state.isBillingAdjustmentApplied ? state.billingAdjustment : undefined,
          state.billingPeriodStartDate,
        ),
      };

    case SET_INVOICE_ITEMS:
      if (state.parentRecord && action.payload?.length > 0) {
        let recalculatedItems = getRecalculatedItems(
          action.payload,
          state.isDiscountApplied,
          state.isBillingAdjustmentApplied ? state.billingAdjustment : undefined,
          state.billingPeriodStartDate,
        );

        const adjustedBillingStartDateToBillingDay =
          BillingDatesCalculator.getAdjustedBillingStartDateToBillingDay(state.parentRecord) ||
          moment().format('YYYY-MM-DD');

        const nextInvoiceDate =
          BillingDatesCalculator.getNextInvoiceDateFromOrderItems(
            recalculatedItems,
            adjustedBillingStartDateToBillingDay,
          ) || moment().format('YYYY-MM-DD');

        const BillingPeriodStartDate =
          BillingDatesCalculator.getNextInvoiceDateFromOrderItems(
            recalculatedItems,
            adjustedBillingStartDateToBillingDay,
          ) || moment().format('YYYY-MM-DD');

        // calculate billing period end date
        const BillingPeriodEndDate =
          calculateBillingPeriodEndDateFromOrderItems(
            recalculatedItems,
            adjustedBillingStartDateToBillingDay,
            nextInvoiceDate,
          ) || moment().format('YYYY-MM-DD');

        const BillingStartDate =
          getProperty(state.orderRecord, 'BillingStartDate') || moment().format('YYYY-MM-DD');

        const issuedDate = moment().utc().format('YYYY-MM-DD');

        const DueDate =
          computeDueDate(
            {
              billingStartDate: adjustedBillingStartDateToBillingDay,
              issuedDate,
              nextInvoiceDate,
            },
            getProperty(state.orderRecord, 'BillingTerms'),
          ) || moment().format('YYYY-MM-DD');

        return {
          ...state,
          invoiceItems: recalculatedItems,
          billingPeriodStartDate: BillingPeriodStartDate,
          billingPeriodEndDate: BillingPeriodEndDate,
          billingStartDate: BillingStartDate,
          dueDate: DueDate || moment().format('YYYY-DD-MM'),
          billingTerms: getProperty(state.orderRecord, 'BillingTerms'),
        };
      } else {
        return {
          ...state,
          invoiceItems: [],
        };
      }

    case SET_INVOICE_TYPE:
      return {
        ...state,
        invoiceType: action.payload,
      };
    case SET_BILLING_ADJUSTMENT:
      return {
        ...state,
        billingAdjustment: action.payload,
      };
    case SET_IS_BILLING_ADJUSTMENT_APPLIED:
      return {
        ...state,
        isBillingAdjustmentApplied: action.payload,
        invoiceItems: getRecalculatedItems(
          state.invoiceItems,
          state.isDiscountApplied,
          action.payload ? state.billingAdjustment : undefined,
          state.billingPeriodStartDate,
        ),
      };

    case SET_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      };

    case SET_IS_DISCOUNT_APPLIED:
      const recalculatedItems = getRecalculatedItems(
        state.invoiceItems,
        state.discount && action.payload,
        state.isBillingAdjustmentApplied ? state.billingAdjustment : undefined,
        state.billingPeriodStartDate,
      );
      return {
        ...state,
        invoiceItems: recalculatedItems,
        isDiscountApplied: action.payload,
      };

    case SET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case SET_IS_INVOICE_CREATING:
      return {
        ...state,
        isInvoiceCreating: action.payload,
      };
    case SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };

    case SET_INVOICE_CREATED:
      return {
        ...state,
        isInvoiceCreated: action.payload,
      };
    case SET_INVOICE_ID:
      return {
        ...state,
        invoiceId: action.payload,
      };
    case SET_BILLING_PERIOD_START_DATE:
      return {
        ...state,
        invoiceItems: getRecalculatedItems(
          state.invoiceItems,
          state.isDiscountApplied,
          state.isBillingAdjustmentApplied ? state.billingAdjustment : undefined,
          action.payload,
        ),
        billingPeriodStartDate: action.payload,
      };
    case SET_BILLING_PERIOD_END_DATE:
      return {
        ...state,
        billingPeriodEndDate: action.payload,
      };
    case SET_BILLING_START_DATE:
      return {
        ...state,
        billingStartDate: action.payload,
      };
    case SET_DUE_DATE:
      return {
        ...state,
        dueDate: action.payload,
      };
    case SET_BILLING_TERMS:
      return {
        ...state,
        billingTerms: action.payload,
      };
    default:
      throw new Error();
  }
}
