import React from "react";
import { AFPApprovalServiceContext, AFPApprovalStateContext } from "./context";
import { AFPApprovalService, AFPApprovalState } from "./types";
import { RecordLoaderEffects } from "./effects/RecordLoaderEffects";
import { RecordSelectionEffects } from "./effects/RecordSelectionEffects";
import { useAFPApprovalReducer } from "./hooks/useAFPApprovalReducer";
import { useCreateAFPApprovalService } from "./hooks/useCreateAFPApprovalService";

type UseAFPApprovalResult = {
  state: AFPApprovalState;
  service: AFPApprovalService;
}

const useAFPApproval = (): UseAFPApprovalResult => {
  const { state, actions } = useAFPApprovalReducer();
  const service = useCreateAFPApprovalService(actions);

  return {
    state,
    service,
  };
};

type AFPApprovalProviderProps = {
  afpId: string
};

export const AFPApprovalProvider: React.FC<AFPApprovalProviderProps> = ({ afpId, children }) => {
  const { state, service } = useAFPApproval();
  return (
    <AFPApprovalServiceContext.Provider value={service}>
      <AFPApprovalStateContext.Provider value={state}>
         {children}
        <RecordLoaderEffects afpId={afpId} />
        <RecordSelectionEffects />
      </AFPApprovalStateContext.Provider>
    </AFPApprovalServiceContext.Provider>
  );
}
