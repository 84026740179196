import { CalendarState } from '../../types';
import {
  FETCH_WORK_ORDERS_ERROR,
  FETCH_WORK_ORDERS_REQUEST,
  FETCH_WORK_ORDERS_RESPONSE,
  UPDATE_WORK_ORDER,
} from '../constants';
import {
  CalendarAction,
  FetchWorkOrdersErrorAction,
  FetchWorkOrdersRequestAction,
  FetchWorkOrdersResponseAction,
  UpdateWorkOrderAction,
} from '../types';
import { getErrorState, getLoadingState, getSuccessState } from './utils/fetch';

const isRequestAction = (action: CalendarAction): action is FetchWorkOrdersRequestAction =>
  action.type === FETCH_WORK_ORDERS_REQUEST;
const isResponseAction = (action: CalendarAction): action is FetchWorkOrdersResponseAction =>
  action.type === FETCH_WORK_ORDERS_RESPONSE;
const isErrorAction = (action: CalendarAction): action is FetchWorkOrdersErrorAction =>
  action.type === FETCH_WORK_ORDERS_ERROR;

const updateWorkOrderReducer = (
  state: CalendarState['workOrders'],
  action: UpdateWorkOrderAction,
): CalendarState['workOrders'] => {
  const { workOrder, loading } = action.payload;
  return {
    ...state,
    status: loading ? 'loading' : 'loaded',
    data: state.data.map((wo) => (wo.id === workOrder.id ? workOrder : wo)),
  };
};

const reducers = {
  [FETCH_WORK_ORDERS_REQUEST]: (
    state: CalendarState['workOrders'],
    _action: FetchWorkOrdersRequestAction,
  ): CalendarState['workOrders'] => ({
    ...state,
    ...getLoadingState(),
  }),

  [FETCH_WORK_ORDERS_RESPONSE]: (
    state: CalendarState['workOrders'],
    action: FetchWorkOrdersResponseAction,
  ): CalendarState['workOrders'] => ({
    ...state,
    ...getSuccessState(action.payload),
  }),

  [FETCH_WORK_ORDERS_ERROR]: (
    state: CalendarState['workOrders'],
    action: FetchWorkOrdersErrorAction,
  ): CalendarState['workOrders'] => ({
    ...state,
    ...getErrorState(action.payload),
  }),

  [UPDATE_WORK_ORDER]: updateWorkOrderReducer,
} as const;

export const workOrdersReducer = (
  state: CalendarState['workOrders'],
  action: CalendarAction,
): CalendarState['workOrders'] => {
  if (isRequestAction(action)) return reducers[action.type](state, action);

  if (isResponseAction(action)) return reducers[action.type](state, action);

  if (isErrorAction(action)) return reducers[action.type](state, action);

  if (action.type === 'UPDATE_WORK_ORDER') {
    const data = reducers[action.type](state, action);
    return data;
  }
  return state;
};
