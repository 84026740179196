import { Button, Col, Drawer, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { updateAutosplicingReducerState } from '../../store/actions';
import {
  IAutosplicingReducer,
  TUpdateAutosplicingReducerAction,
} from '../../store/types';
import AutosplicingRulesList from '../AutosplicingRulesList';

interface OwnProps {
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  autosplicingReducer: IAutosplicingReducer;
}

type Props = OwnProps;

const AutosplicingHelpDrawer: FunctionComponent<Props> = (props) => {
  const { autosplicingReducer, updateAutosplicingReducer } = props;

  return (
    <Drawer
      placement="right"
      width={isMobile ? '100%' : 450}
      title="Autosplicing Help"
      open={autosplicingReducer.helpDrawerVisible}
      onClose={() => updateAutosplicingReducer({ helpDrawerVisible: false })}
      destroyOnClose
    >
      <AutosplicingRulesList />
    </Drawer>
  );
};

const mapDispatch = (dispatch: any) => ({
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState, mapDispatch)(AutosplicingHelpDrawer);
