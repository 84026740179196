import { RouteComponentProps, withRouter } from "react-router-dom"
import { AFPApprovalProvider } from "./context/provider";
import { AFPApprovalHome } from "./AFPApprovalHome";

type PathParams = {
  url: string;
  recordId: string;
};

type AFPApprovalScreenProps = RouteComponentProps<PathParams>;

export const AFPApprovalScreen = (props: AFPApprovalScreenProps) => {
  const { match } = props;
  const afpId = match.params.recordId;

  return (
    <AFPApprovalProvider afpId={afpId}>
      <AFPApprovalHome />
    </AFPApprovalProvider>
  );
}

export default withRouter(AFPApprovalScreen);