import { Spinner } from '@blueprintjs/core';
import ChatBody from './ChatBody';
import ChatBottomBar from './ChatBottomBar';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

interface ChatFeedProps {
  isLoading: boolean;
  conversation: any;
  setOpenParticipantDrawer: (value: boolean) => void;
  messagesContainerRef: any;
  messages: any[];
  message: any;
  textareaRef: any;
  sendMessage: (message: any) => void;
  isSendingMessage: boolean;
  setMessage: (value: any) => void;
  userReducer: any;
  handleSendThumbsUp: () => void;
  originalCaseEmail?: DbRecordEntityTransform
}

const ChatFeed = ({
  isLoading,
  conversation,
  setOpenParticipantDrawer,
  messagesContainerRef,
  messages,
  message,
  textareaRef,
  sendMessage,
  isSendingMessage,
  setMessage,
  userReducer,
  handleSendThumbsUp,
  originalCaseEmail,
}: ChatFeedProps) => {
  return (
    <div className="chat-panel">
      {isLoading ? (
        <div className="spinner-container">
          <Spinner />
        </div>
      ) : conversation ? (
        <>
          <ChatBody
            messages={messages}
            messagesContainerRef={messagesContainerRef}
            userReducer={userReducer}
          />
          <ChatBottomBar
            handleSendThumbsUp={handleSendThumbsUp}
            isLoading={isLoading}
            isSendingMessage={isSendingMessage}
            message={message}
            sendMessage={sendMessage}
            setMessage={setMessage}
            textareaRef={textareaRef}
          />
        </>
      ) : !!originalCaseEmail ? (
        <div style={{ flex: 1 }}>
          <p>This case has been created via email, please go to Email tab</p>
        </div>
      ) : (
        <div style={{ flex: 1 }}>
          <p>Select a case</p>
        </div>
      )}
    </div>
  );
};

export default ChatFeed;
