import { defaults as DefaultInteractions } from 'ol/interaction';
import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer';
import ImageWMS from 'ol/source/ImageWMS';
import XYZ from 'ol/source/XYZ';
import View from 'ol/View';
import proj4 from 'proj4';
import { ScaleLine } from 'ol/control';
import Map from 'ol/Map';
import { IBuildPackData } from './index';
import { register } from 'ol/proj/proj4';

/* Build Pack Map */
export const QGIS_SERVER_BUILDPACK =
  'https://api.odin.dev.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs';
export const VITE_QGIS_SERVER_URL =
  import.meta.env.VITE_QGIS_SERVER_URL ||
  'https://api.odin.prod.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs';

/**
 * Pass an array with WMS layer class names to remove them
 *
 * @param layersToRemove
 */
export const cleanMapLayers = (map: any, layersToRemove: Array<string>) => {
  for (const layerName of layersToRemove) {
    map?.getLayers().forEach((layer: any) => {
      if (layer) {
        const isVisible = layer.getVisible();
        if (isVisible) {
          if (layer.className_ === layerName) {
            map?.removeLayer(layer);
          }
        }
      }
    });
  }
};

/**
 * Load overview polygon with overview features for the
 *
 * @param map
 * @param polygonType
 */
export const getOverviewMapForPolygonType = async (
  map: any,
  polygonType: 'L1' | 'L2',
  buildPackData: IBuildPackData,
) => {
  return await new Promise(async (resolve) => {
    let closureIds: number[] = [];
    let cableIds: number[] = [];

    if (polygonType === 'L1') {
      closureIds = buildPackData?.nodes
        .filter((node: any) => ['L0', 'L1', 'L2'].includes(node.type) && !node.isOnHold)
        .map((node: any) => node.id);
    } else {
      closureIds = buildPackData?.nodes
        .filter(
          (node: any) =>
            ['L2', 'L3', 'L4'].includes(node.type) && ['Access', 'Feed'] && !node.isOnHold,
        )
        .map((node: any) => node.id);
    }

    cableIds = buildPackData?.edges
      .filter((edge: any) => closureIds.includes(edge.source) && !edge.targetOnHold)
      .map((edge: any) => edge.id);

    const WMSFilterQuery = `cable_no_scale:"id" IN ( ${cableIds.join(
      ' , ',
    )} );closure_no_scale:"id" IN ( ${closureIds.join(' , ')} )`;

    map?.addLayer(
      new ImageLayer({
        zIndex: 200,
        className: 'buildPack_overviewFeatures',
        source: new ImageWMS({
          url: QGIS_SERVER_BUILDPACK,
          crossOrigin: 'anonymous',
          params: {
            LAYERS: ['cable_no_scale', 'closure_no_scale'],
            FILTER: WMSFilterQuery,
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        visible: true,
      }),
    );

    map?.once('rendercomplete', function() {
      return resolve(true);
    });
  }).catch((err) => {
    console.error(err);
  });
};

/**
 * Setup Openlayers map for Build pack
 *
 * @param updateMap
 */
export const initializeBuildPackMap = (updateMap: Function) => {
  proj4.defs(
    'EPSG:27700',
    '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs',
  );
  register(proj4);

  const map = new Map({
    target: 'buildpack_map',
    controls: [
      new ScaleLine({
        units: 'metric',
        bar: true,
        steps: 3,
        minWidth: 100,
        text: true,
      }),
    ],
    layers: [
      new TileLayer({
        source: new XYZ({
          url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
          crossOrigin: 'Anonymous',
        }),
        className: 'buildPack_googleMaps',
        visible: true,
        zIndex: 100,
      }),
    ],
    view: new View({
      zoom: 10,
      resolution: 150,
      enableRotation: true,
      constrainOnlyCenter: false,
    }),
    interactions: DefaultInteractions({
      pinchRotate: false,
      doubleClickZoom: false,
      dragPan: false,
      mouseWheelZoom: false,
    }),
  });

  updateMap({ map });
};
