export const TOGGLE_QUERY_BUILDER = 'TOGGLE_QUERY_BUILDER';
export const SET_QUERY_BUILDER_FORM_FIELDS = 'SET_QUERY_BUILDER_FORM_FIELDS';
export const RESET_QUERY_BUILDER_STATE = 'RESET_QUERY_BUILDER_STATE';
export const SET_QUERY_BUILDER_SEARCH_QUERY = 'CONFIGURE_QUERY_BUILDER_SEARCH_QUERY';
export const SET_QUERY_BUILDER_STATE = 'SET_QUERY_BUILDER_STATE';
export const ADD_FORM_FIELD = 'ADD_FORM_FIELD';
export const REMOVE_FORM_FIELD = 'REMOVE_FORM_FIELD';
export const SET_FORM_FIELD_ENTITY = 'SET_FORM_FIELD_ENTITY';
export const SET_FORM_FIELD_PROPERTY = 'SET_FORM_FIELD_PROPERTY';
export const SET_FORM_FIELD_CONDITION = 'SET_FORM_FIELD_CONDITION';
export const SET_FORM_FIELD_OPERATOR = 'SET_FORM_FIELD_OPERATOR';
export const SET_FORM_FIELD_AND_OR = 'SET_FORM_FIELD_AND_OR';
export const SET_FORM_FIELD_VALUE = 'SET_FORM_FIELD_VALUE';
export const SHOW_QUERY_BUILDER = 'SHOW_QUERY_BUILDER';
export const SET_QUERY_BUILDER_TAB = 'SET_QUERY_BUILDER_TAB';

export const SET_DATE_RANGE_QUERY = 'SET_DATE_RANGE_QUERY';
