import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Card, Input } from 'antd';
import { debounce } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { getRecordByIdRequest } from '@legacy/core/records/store/actions';
import { getSchemaByModuleAndEntityRequest } from '@legacy/core/schemas/store/actions';
import { httpGet, httpPost } from '../../../../../../core/http/requests';
import { displayMessage as displayMessageAction } from '@legacy/core/messages/store/reducers';
import { useAction } from '../../../../../../core/hooks/useAction';
import { useRequest } from '../../../../../../core/hooks/useRequest';
import { PriceListTable } from './PriceListTable';
import { Classes, Drawer } from '@blueprintjs/core';

const getPriceList = (workListId: string | undefined) => {
  const [priceList, setPriceList] = useState<any>(null);
  const [status, setStatus] = useState<'loading' | 'success' | 'failure'>('loading');

  useEffect(() => {
    if (!workListId) return;

    const query = `ProjectModule/v1.0/WorkList/adjustments?workListId=${workListId}`;

    httpGet(query)
      .then((res) => {
        setPriceList(res.data.data);
        setStatus('success');
      })
      .catch((err) => {
        setStatus('failure');
      });
  }, [workListId]);

  return { priceList, status };
};

type CreateAdjustmentProps = {
  onClose?: () => void;
  project: DbRecordEntityTransform | undefined;
  workListId: string;
  feature?: DbRecordEntityTransform;
  disableAutoOpen?: boolean;
};

export const CreateAdjustment = ({
                                   workListId,
                                   onClose = () => undefined,
                                   feature,
                                   disableAutoOpen,
                                 }: CreateAdjustmentProps) => {
  const [visible, setVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [selectedAdjustments, setSelectedAdjustments] = useState<any[]>([]);
  const { priceList = [], status } = getPriceList(workListId);
  const [filteredPriceList, setFilteredPriceList] = useState<any[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [filter, _setFilter] = useState('');
  const setFilter = useCallback(
    debounce((val) => _setFilter(val), 500),
    [],
  );
  const getSchemaReq = useRequest(getSchemaByModuleAndEntityRequest);
  const getRecordByIdReq = useRequest(getRecordByIdRequest);
  const displayMessage = useAction(displayMessageAction);

  const getRecordById = async ({
                                 moduleName,
                                 entityName,
                                 recordId,
                               }: {
    moduleName: string;
    entityName: string;
    recordId: string;
  }) => {
    const schema = await getSchemaReq({ moduleName, entityName });
    return getRecordByIdReq({ schema, recordId });
  };

  useEffect(() => {
    setVisible((visible) => !visible && !!feature && !disableAutoOpen);
  }, [feature]);

  useEffect(() => {
    if (!filter) {
      setFilteredPriceList(priceList);
      return;
    }

    setFilteredPriceList(
      priceList.filter((item: any) => {
        return item.ITEMID.includes(filter.toUpperCase());
      }),
    );
  }, [filter]);

  const handleButtonClick = () => {
    setVisible(true);
  };

  const handleDrawerClose = () => {
    if (onClose) onClose();
    setVisible(false);
    setInputValue('');
    _setFilter('');
  };

  const addAdjustments = async (sageItems: any[]) => {
    setIsSaving(true);
    try {
      await httpPost(`ProjectModule/v1.0/WorkList/adjustments`, {
        workListId,
        sageIds: sageItems.map((item) => item.item_id),
        featureId: feature?.id,
      });
    } catch (err: any) {
      displayMessage({
        body: err.response?.data?.message ?? err.message,
        type: 'error',
      });
      setIsSaving(false);
      return;
    }
    setSelectedAdjustments([]);
    handleDrawerClose();

    getRecordById({
      moduleName: 'ProjectModule',
      entityName: 'WorkList',
      recordId: workListId,
    }).catch();
    setIsSaving(false);
  };

  return (
    <>
      <Drawer
        usePortal
        style={{ width: isMobile ? '100%' : '70%' }}
        className="featureQuickViewDrawer"
        title={'Create Adjustment' + (feature ? ` for Feature ${feature?.recordNumber}` : '')}
        isOpen={visible}
        onClose={handleDrawerClose}
      >
        <div className={Classes.DRAWER_BODY} style={{ padding: 15 }}>
          <Card
            className="association-table-card"
            size="small"
            title="Add New Adjustments"
            extra={[
              <div>
                <Button
                  onClick={() => addAdjustments(selectedAdjustments)}
                  loading={status === 'loading'}
                >
                  {status === 'loading' ? 'Saving...' : 'Add Adjustments'}
                </Button>
              </div>,
            ]}
          >
            <div style={{ padding: 5 }}>
              <label htmlFor="filter">Filter Items: </label>
              <Input
                style={{ width: '350px' }}
                placeholder="Filter by Item Id"
                type="text"
                id="filter"
                value={inputValue}
                onChange={(event) => {
                  setInputValue(event?.target?.value ?? '');
                  setFilter(event?.target?.value ?? '');
                }}
              />
            </div>
            <PriceListTable
              dataSource={status === 'success' ? filteredPriceList ?? priceList : []}
              loading={status === 'loading' || isSaving}
              selectedRowKeys={selectedAdjustments.map((item) => item.item_id)}
              onRowSelectionChanged={(_record, _selected, selectedRows) => {
                const newSelections = selectedRows.map((item, index) =>
                  item ? item : selectedAdjustments[index],
                );
                console.log('[DebugX]', {
                  selectedAdjustments,
                  selectedRows,
                  newSelections,
                });
                setSelectedAdjustments(newSelections);
              }}
            />
          </Card>
        </div>
      </Drawer>
    </>
  );
};
