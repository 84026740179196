import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Alert, Button } from '@blueprintjs/core';
import { httpPost } from '@core/http/requests';
import history from '@core/helpers/browserHistory';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { displayMessage } from '@legacy/core/messages/store/reducers';

interface Props {
  contactRecord: DbRecordEntityTransform;
  alertMessage: (params: { body: string; type: string }) => void;
  fill?: boolean;
}

const CreateBillingRequestFromContact: React.FC<Props> = (props: Props) => {
  const { contactRecord, alertMessage, fill } = props;
  const [isConfirmAlertVisible, setIsConfirmAlertVisible] = useState<boolean>(false);
  const [isLinking, setIsLinking] = useState<boolean>(false);

  const contact: DbRecordEntityTransform = useMemo(() => contactRecord, [contactRecord]);

  const createBillingRequestFromContact = async () => {
    setIsLinking(true);

    await httpPost(
      `${SchemaModuleTypeEnums.BILLING_MODULE}/v1.0/billing-requests/contact/${contactRecord.id}`,
      undefined,
    )
      .then((res) => {
        setIsLinking(false);
        const billingRequestId = res.data?.data?.id;

        if (billingRequestId) {
          alertMessage({ body: 'Billing request created', type: 'success' });
          history.push(
            `/${SchemaModuleTypeEnums.BILLING_MODULE}/${SchemaModuleEntityTypeEnums.BILLING_REQUEST}/${billingRequestId}`,
          );
        } else {
          setIsLinking(false);
          alertMessage({
            body: 'error creating billing request',
            type: 'error',
          });
        }
      })
      .catch((err: any) => {
        setIsLinking(false);
        const error = err.response ? err.response.data : undefined;
        alertMessage({
          body: (error && error.message) || 'error creating billing request',
          type: 'error',
        });
      });
  };

  return (
    <>
      <Button
        text="Create BRQ"
        outlined
        intent="primary"
        fill={fill}
        onClick={() => setIsConfirmAlertVisible(true)}
      />
      {/* Confirm Alert */}
      <Alert
        intent="primary"
        onCancel={() => setIsConfirmAlertVisible(false)}
        isOpen={isConfirmAlertVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Confirm"
        onConfirm={createBillingRequestFromContact}
        loading={isLinking}
      >
        <p>
          Are you sure you want to create a Billing Request for all unpaid invoices for this
          contact?
        </p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CreateBillingRequestFromContact);
