import React from 'react';

const FacebookIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="50" viewBox="0 0 40 70" fill="none">
      <path
        d="M24.645 40.6953V70H11.1834V40.6953H0V28.8129H11.1834V24.4896C11.1834 8.43935 17.8883 0 32.0747 0C36.4238 0 37.5111 0.698965 39.8928 1.26849V13.0215C37.2263 12.5555 36.4756 12.2966 33.7056 12.2966C30.4179 12.2966 28.6575 13.2286 27.0525 15.0666C25.4475 16.9046 24.645 20.0888 24.645 24.645V28.8388H39.8928L35.8025 40.7212H24.645V40.6953Z"
        fill="white"
      />
    </svg>
  );
};

export default FacebookIcon;
