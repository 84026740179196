import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Button } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Modal } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IdentityUserReducer } from '@legacy/core/identityUser/store/reducer';
import { getRecordByIdRequest, IGetRecordById } from '@legacy/core/records/store/actions';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { IRecordAssociationsReducer } from '@legacy/core/recordsAssociations/store/reducer';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { httpPost } from '@core/http/requests';
import { hasPermissions, isSystemAdmin } from '@core/helpers/rbacRules';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { checkRecordIsLocked } from '@core/helpers/recordHelpers';

interface Props {
  schema: SchemaEntity | undefined;
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  userReducer: IdentityUserReducer;
  alertMessage: any;
  getRecordById: any;
  hidden?: string[];
}

interface State {
  isLoading: boolean;
  confirmDeleteRecord: boolean;
}

class VoidInvoice extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoading: false,
      confirmDeleteRecord: false,
    };
  }

  handleOk = async () => {
    const { schemaReducer, record, alertMessage } = this.props;
    this.setState({
      isLoading: true,
    });

    const body = {};

    await httpPost(`BillingModule/v1.0/invoices/${record.id}/void`, body)
      .then((res) => {
        alertMessage({ body: 'invoice voided', type: 'success' });
      })
      .catch((err) => {
        const error = err.response ? err.response.data : undefined;
        alertMessage({
          body: (error && error.message) || 'error processing payment',
          type: 'error',
        });
      });

    this.setState({
      isLoading: false,
      confirmDeleteRecord: false,
    });
  };

  renderVoidButtonDisabledState() {
    const { userReducer, record } = this.props;

    if (!checkRecordIsLocked(record)) {
      return true;
    } else if (!hasPermissions(userReducer, ['billingmodule.invoice.void'])) {
      return true;
    } else if (getProperty(record, 'Status') === 'PAID' && !isSystemAdmin(userReducer)) {
      return true;
    } else if (getProperty(record, 'Status') === 'VOID' && !isSystemAdmin(userReducer)) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <>
        <Modal
          title="Confirmation"
          confirmLoading={this.state.isLoading}
          open={this.state.confirmDeleteRecord}
          onOk={() => this.handleOk()}
          onCancel={() => this.setState({ confirmDeleteRecord: false })}
          okText="Confirm"
          cancelText="Cancel"
        >
          <ExclamationCircleOutlined style={{ fontSize: '24px', color: '#ff4d4f' }} />
          <p>Are you sure you want to void the invoice?.</p>
        </Modal>
        <Button
          intent="danger"
          outlined
          disabled={this.renderVoidButtonDisabledState()}
          onClick={() => this.setState({ confirmDeleteRecord: true })}
        >
          Void
        </Button>
      </>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(VoidInvoice);
