import {
  InputGroup,
  NonIdealState,
  NonIdealStateIconSize,
  Section,
  Tab,
  Tabs,
} from '@blueprintjs/core';
import { Col, Row, Space } from 'antd';
import React, { ReactNode } from 'react';
import './styles.scss';

// Number of associations required to show quick search on related tabs
const MIN_ASSOCIATIONS_FOR_SEARCH = 15;

interface Props {
  title: string | JSX.Element;
  tabList: { key: string; tab: ReactNode }[]; // List of tabs that go on beginning
  tabContents: { [key: string]: ReactNode }; // Contents of tabs that go on beginning
  relatedAssociations?: any; // Related tab with all associations listed as tables
  customTabList?: { key: string; tab: ReactNode }[]; // List of tabs that go after Related tab
  customTabContents?: { [key: string]: ReactNode }; // Contents of tabs that go after Related tab
  defaultTabKey?: string;
  extra?: any;
  showOnlyTabs?: boolean;
  moduleName?: string;
  entityName?: string;
  recordId?: string;
  hideSchemaActions?: boolean;
  hideCardHeader?: boolean;
}

interface State {
  key: any;
  search: string;
}

class CardWithTabs extends React.Component<Props, State> {
  state = {
    key: undefined,
    search: '',
  };

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (
      (!this.state.key && this.props.defaultTabKey) ||
      prevProps.defaultTabKey !== this.props.defaultTabKey
    ) {
      this.setState({
        key: this.props.defaultTabKey,
      });
    }
  }

  renderTabs = () => {
    const {
      tabList,
      tabContents: contentList,
      relatedAssociations,
      customTabList: customTabs,
      customTabContents: customTabsContents,
    } = this.props;

    const handleTabChange = (key: string) => {
      this.setState({ key });
    };

    const shouldShowSearchOnRelatedTab = () => {
      return (
        relatedAssociations &&
        Object.keys(relatedAssociations).length >= MIN_ASSOCIATIONS_FOR_SEARCH
      );
    };

    let relationKeys = Object.keys(relatedAssociations || {});
    if (this.state.search.length > 0) {
      relationKeys = relationKeys.filter(
        (key) => key.toLowerCase().indexOf(this.state.search.toLowerCase()) > -1,
      );
    }

    return (
      <Tabs selectedTabId={this.state.key} onChange={handleTabChange} renderActiveTabPanelOnly>
        {tabList
          ?.filter((elem) => elem.tab && elem.key)
          .map((elem) => (
            <Tab id={elem.key} title={elem.tab} panel={contentList[elem.key] as any} />
          ))}

        {/* Render related tab with all associations as tables */}
        {relatedAssociations && Object.keys(relatedAssociations)?.length > 0 ? (
          <Tab
            id="Related"
            title="Related"
            key="Related"
            panel={
              <>
                {shouldShowSearchOnRelatedTab() && (
                  <Row style={{ marginBottom: 10 }} justify="end">
                    <Col xs={24} sm={12} lg={8} xl={6}>
                      <InputGroup
                        leftIcon="search"
                        placeholder="Search Relation"
                        fill
                        round
                        onChange={(e: any) => this.setState({ search: e.target.value })}
                      />
                    </Col>
                  </Row>
                )}
                {/* Association tables */}
                {relationKeys?.length > 0 ? (
                  <div>{relationKeys?.map((key: any) => relatedAssociations[key]) as any}</div>
                ) : (
                  <Row>
                    <Col span={24} style={{ padding: '20px 0' }}>
                      <NonIdealState
                        icon="search"
                        description={`Nothing found for "${this.state.search}"`}
                        iconSize={NonIdealStateIconSize.SMALL}
                      />
                    </Col>
                  </Row>
                )}
              </>
            }
          />
        ) : (
          <Tab
            id="Related"
            title="Related"
            key="Related"
            panel={
              <Row>
                <Col span={24} style={{ padding: '20px 0' }}>
                  <NonIdealState
                    icon="search"
                    description="No Relations"
                    iconSize={NonIdealStateIconSize.SMALL}
                  />
                </Col>
              </Row>
            }
          />
        )}

        {/* Render additional custom tabs AFTER Related tab */}
        {customTabs
          ?.filter((elem) => elem.tab && elem.key)
          .map((elem) => (
            <Tab id={elem.key} key={elem.key} panel={customTabsContents![elem.key] as any} />
          ))}
      </Tabs>
    );
  };

  render() {
    const {
      tabContents: contentList,
      extra,
      relatedAssociations: relatedRecords,
      hideCardHeader,
    } = this.props;

    return this.props.showOnlyTabs ? (
      this.renderTabs()
    ) : (
      <div className="bp5-background">
        <Section
          title={hideCardHeader ? undefined : 'Options'}
          className={`cardWithTabs ${hideCardHeader ? 'hideCardHeader' : ''}`}
          rightElement={<Space>{extra}</Space>}
        >
          <div style={{ padding: 12 }}>{this.renderTabs()}</div>
        </Section>

        <div style={{ marginTop: 16 }}>{this.props.children}</div>
      </div>
    );
  }
}

export default CardWithTabs;
