import {LogsUserAuditEventTypesEnum} from '@d19n/temp-fe-d19n-models/dist/logs/audit/logs.user.audit.event.types';
import {ICreateUserAuditEvents} from '../store/actions';
import {IUserAuditReducer} from '../store/reducer';

/**
 * ODN-2129 Logs DB_RECORD_VIEWED audit event if record is not equal to the last viewed
 *
 * @param params
 * @param userAuditReducer
 * @param createUserAuditEventsHandler
 * @param cb
 * @returns
 */
export function logRecordViewedAuditEvent(
  params: {
    recordId: string;
    data?: any;
  },
  userAuditReducer: IUserAuditReducer,
  createUserAuditEventsHandler: (params: ICreateUserAuditEvents, cb?: (resp: any) => void) => void,
  cb?: (resp: any) => void,
) {
  if (!params?.recordId) {
    if (cb) cb(undefined);
    return;
  }

  const lastLogged = userAuditReducer.lastLogged[LogsUserAuditEventTypesEnum.DB_RECORD_VIEWED];
  if (lastLogged?.recordId !== params.recordId) {
    createUserAuditEventsHandler(
      {
        items: [
          {
            type: LogsUserAuditEventTypesEnum.DB_RECORD_VIEWED,
            ...params,
          },
        ],
      },
      cb,
    );
  }
}
