import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { getSchemaByIdRequest } from '@legacy/core/schemas/store/actions';
import ViewConfigurationLeftPanel from '../../components/ViewConfigurationLeftPanel';
import { BigBreadcrumbs } from '@legacy/modules/IdentityManagerModule/components/BigBreadcrumbs';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { getViewConfiguration } from '../../components/ViewConfigurations/api';
import ViewConfigurationBuilder from '../../components/ViewConfigurationBuilder';
import { Icon, Section } from '@blueprintjs/core';
import ViewConfigurationRecordProperties from '../../components/ViewConfigurationRecordProperties';

import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';

type Props = {
  match: any;
  schemaReducer: ISchemaReducer;
  getSchemaById: (payload: any, cb?: any) => void;
};

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

const ViewConfigurationDetailView: FC<Props> = (props: Props) => {
  const { schemaReducer, getSchemaById } = props;
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [viewConfiguration, setViewConfiguration] = useState<SchemaActionEntity | undefined>(
    undefined,
  );

  useEffect(() => {
    // Fetch Schema
    if (props.match.params.schemaId) {
      getSchema(props.match.params.schemaId);
    }

    // Fetch Schema Action
    if (props.match.params.viewConfigurationId) {
      fetchAction(props.match.params.viewConfigurationId);
    }
  }, []);

  const fetchAction = (viewConfigurationId: string) => {
    getViewConfiguration(viewConfigurationId).then((viewConfig: any) => {
      if (viewConfig) {
        setViewConfiguration(viewConfig);
      }
    });
  };

  const getSchema = (schemaId: string) => {
    const shortListSchema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);
    if (shortListSchema) {
      setSchema(shortListSchema);
    } else {
      getSchemaById({ schemaId: schemaId }, (schema: SchemaEntity) => {
        setSchema(schema);
      });
    }
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <Section
            title={
              <>
                <Link to={`/${SCHEMA_MODULE}/Schema`}>
                  <span>Schemas</span>
                </Link>
                <Icon icon="caret-right" />
                <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}`}>
                  <span>{schema?.entityName || ''}</span>
                </Link>
                <Icon icon="caret-right" />
                <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}#ViewConfigs`}>
                  <span>View Configurations</span>
                </Link>
                <Icon icon="caret-right" />
                <span>{viewConfiguration?.name || '...'}</span>
              </>
            }
            subtitle="Schema Manager"
            style={{ marginTop: 1 }}
            icon={
              <ModuleEntityIcon
                moduleName="SchemaModule"
                entityName="Schema"
                overrideIcon="diagram-3-fill"
                overrideIconColor="#1777ff"
                iconContainerStyle={{ padding: '7px 10px' }}
              />
            }
          />
        </Col>
        <Col span={5}>
          <ViewConfigurationLeftPanel
            schema={schema}
            viewConfig={viewConfiguration}
            refreshAction={fetchAction}
          />
        </Col>

        <Col span={13}>
          <ViewConfigurationBuilder schema={schema} viewConfiguration={viewConfiguration} />
        </Col>

        <Col span={6} style={{ height: '100%' }}>
          <ViewConfigurationRecordProperties schema={schema} />
        </Col>
      </Row>
    </>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

export default withRouter(connect(mapState, mapDispatch)(ViewConfigurationDetailView));
