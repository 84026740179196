import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';

export const renderVisibleFormFields = (
  elem: PipelineStageEntity,
  currentStage: string | undefined,
) => {
  if (elem.key === 'OrderStageCancelled') {
    //return ['CancellationReason', 'CancellationReasonNote'];
    return [''];
  } else if (elem.key === 'OrderStageActive') {
    // If user is moving from Deactivated -> Active, don't show BillingStartDate and ContractStartDate form fields.
    if (currentStage && currentStage === 'OrderStageDeactivated') {
      return [''];
    } else {
      return ['ContractStartDate'];
    }
  } else if (elem.key === 'OrderStageDeactivated') {
    return ['DeactivationDescription', 'DeactivationReason'];
  } else if (elem.key === 'OrderStageDeactivated') {
    return ['DeactivationReason', 'DeactivationDescription'];
  }
};
