export const INITIALIZE_FORM = 'INITIALIZE_FORM';

export const UPDATE_FORM_STATE = 'UPDATE_FORM_STATE';

export const UPDATE_FORM_INPUT = 'UPDATE_FORM_INPUT';

export const CLOSE_FORM_MODAL = 'CLOSE_FORM_MODAL';
export const CLOSE_SECONDARY_FORM_MODAL = 'CLOSE_SECONDARY_FORM_MODAL';

export const ADD_ASSOCIATION_TO_FORM_SECTION = 'ADD_ASSOCIATION_TO_FORM_SECTION';
export const REMOVE_ASSOCIATION_FROM_FORM_SECTION = 'REMOVE_ASSOCIATION_FROM_FORM_SECTION';
