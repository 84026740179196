import { Alert, Spin, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { GetRowKey, TableRowSelection } from 'antd/lib/table/interface';
import React from 'react';
import { useVendorDashboard } from './hooks';

import { DashboardLineItem } from './hooks/types';
import { getFilteredLineItems } from './utils';

type VendorDashboardTableProps = {
  lineItems: ReturnType<typeof useVendorDashboard>['state']['lineItems'];
  columns: ColumnsType<DashboardLineItem>;
  rowSelection?: TableRowSelection<DashboardLineItem>;
  rowKey?: string | GetRowKey<DashboardLineItem>;
  loading?: boolean;
  globalLoader?: boolean;
};

export const VendorDashboardTable = ({
  lineItems,
  columns,
  rowKey,
  rowSelection,
  loading = false,
  globalLoader = false,
}: VendorDashboardTableProps) => {
  const filteredLineItems = getFilteredLineItems(lineItems.data?.lineItems ?? [], lineItems.filters);
  const dataSource = filteredLineItems;

  return (
   <Spin spinning={loading || globalLoader}>
     {loading && <Alert
      style={{ margin: 10 }}
      message="Preparing Dashboard Data"
      description="Preparing your dashboard data. Please allow up to 1 min for the process to complete."
      type="info"
      showIcon
     />}
     {!loading && <Table
       size="small"
       dataSource={dataSource}
       columns={columns}
       rowKey={rowKey}
       rowSelection={rowSelection}
       pagination={{}}
     />}
   </Spin>
  );
};
