import { Button, Card, Col, Typography } from 'antd';
import { FC, useContext } from 'react';
import { DataSetBuilderContext } from '../index';
import DataSetContents from './DataSetContents';
type Props = {};

const DataSetContentsPanel: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(DataSetBuilderContext);

  const selectOrDeselectDataSetRecords = () => {
    if (state.selectedDataSetRecords.length > 0) {
      dispatch({ type: 'DESELECT_ALL_DATASET_RECORDS', payload: true });
    } else {
      dispatch({ type: 'SELECT_ALL_DATASET_RECORDS', payload: true });
    }
  };

  return (
    <Card
      className="DataSetBuilderPanelRow"
      title={
        <Typography.Title level={5} style={{ marginTop: 0 }}>
          DataSet Contents
        </Typography.Title>
      }
      extra={
        <>
          <Button
            type="link"
            disabled={state.dataSetRecords.length === 0 || state.isRecordSearching}
            onClick={() => selectOrDeselectDataSetRecords()}
          >
            {state.selectedDataSetRecords.length > 0 ? 'Deselect All' : 'Select All'}
          </Button>
          <Button
            type="primary"
            danger
            ghost
            disabled={state.selectedDataSetRecords.length === 0}
            onClick={() => {
              dispatch({
                type: 'BULK_REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION',
                payload: true,
              });
            }}
          >
            {state.selectedDataSetRecords.length > 0
              ? `Remove (${state.selectedDataSetRecords.length}) ${
                  state.selectedDataSetRecords.length > 1 ? 'Records' : 'Record'
                }`
              : 'Remove Records'}
          </Button>
        </>
      }
    >
      <Col span={24}>
        <DataSetContents />
      </Col>
    </Card>
  );
};

export default DataSetContentsPanel;
