import { Classes, Icon, Popover } from '@blueprintjs/core';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import React, { useState } from 'react';
import { FilterOption } from './types';

type ColumnFilterProps = {
    label: string;
    renderOptions: () => React.ReactElement;
    hasFilters: boolean;
}
export const ColumnFilter = ({ label, renderOptions, hasFilters }: ColumnFilterProps) => {
    const color = hasFilters ? 'blue' : 'gray';
    return (
        <>
            { label }
            <Popover
                popoverClassName={Classes.POPOVER_CONTENT_SIZING}
                position='bottom'
                content={renderOptions()}
            >
                <Icon icon="filter" size={10} color={color} style={{ position: 'relative', top: -3, marginLeft: 5 }}/>
            </Popover>
       </>
    );
}

export const getAssignedUserOptions = (records: DbRecordEntityTransform[]) => {
	const options: { [key: string]: FilterOption } = {};

	records.forEach(record => {
		const label = getProperty(record, 'R_AssignedUserTitle');
		const value = getProperty(record, 'R_AssignedUserId');
		options[label] = {
			label,
			value,
		};
	});

	return Object.values(options).sort((a, b) => a.label?.localeCompare(b?.label));
};

type UseTableFilterParams = {
    options: FilterOption[];
};

export const useMultiSelectFilter = ({ options }: UseTableFilterParams) => {
	const [selectedValues, setSelectedValues] = useState<string[]>([]);

	return {
        options,
        selectedValues,
        setSelectedValues,
	}
};
