import { CloseOutlined } from '@ant-design/icons';
import G6 from '@antv/g6';
import { Button, Menu, MenuDivider, MenuItem, Popover } from '@blueprintjs/core';
import { Badge, Card, Col, Divider, Layout, Result, Row, Spin, Typography } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { httpGet } from '../../../../../core/http/requests';
import { filterCableDiagramData, getChamberStyle } from './helpers';
import './styles.scss';

type cableDiagramData = {
  nodes: any[];
  edges: any[];
};

type Props = {
  polygonId: number;
  columnFullscreen?: any;
  recordId: string;
};

let graph: any = null;

const CableDiagram = (props: Props) => {
  const diagramRef = React.useRef(null);
  const minimapRef = React.useRef(null);
  const { polygonId, columnFullscreen } = props;

  const [loadingData, setLoadingData] = useState<boolean>(true);
  const [generatingPDF, setGeneratingPDF] = useState<boolean>(false);
  const [cableDiagramData, setCableDiagramData] = useState<any>([]);
  const [loopChain, setLoopChain] = useState<'ALL' | number | null>(null);
  const [loadingError, setLoadingError] = useState<boolean | null>(false);
  const [selectedNode, setSelectedNode] = useState<any>(null);
  const [selectedTask, setSelectedTask] = useState<any>(null);

  useEffect(() => {
    return () => {
      if (graph) {
        graph?.destroy();
        graph = null;
      }
    };
  }, []);

  async function drawGraph(diagramData: cableDiagramData): Promise<any> {
    const graphConfig = {
      container: diagramRef?.current!,
      fitView: true,
      zoom: 20,
      modes: {
        default: ['drag-canvas', 'zoom-canvas'],
      },
      layout: {
        type: 'dagre',
        rankdir: 'LR',
        nodesep: 15,
        ranksep: 270,
        controlPoints: true,
      },
      defaultNode: {
        type: 'node',
        size: 90,
        labelCfg: {
          style: {
            fontSize: 28,
            fontWeight: 600,
            padding: 15,
            cursor: 'pointer',
            fontFamily: 'sans-serif',
          },
        },
        style: {
          stroke: '#72CC4A',
          width: 150,
          cursor: 'pointer',
          fontFamily: 'sans-serif',
        },
      },
      defaultEdge: {
        type: 'polyline',
        labelCfg: {
          position: 'end',
          style: {
            fontSize: 20,
            fontFamily: 'sans-serif',
          },
        },
      },
      plugins: [
        new G6.Minimap({
          container: minimapRef?.current!,
          size: [160, 100],
        }),
      ],
    };

    if (graph !== null) {
      graph.destroy();
      graph = new G6.Graph(graphConfig);
      graph.data(diagramData);
      G6.Util.processParallelEdges(diagramData.edges, 120, 'polyline');
      graph.render();
      graph.on('node:click', (e: any) => nodeClickEvent(e));
    } else {
      graph = new G6.Graph(graphConfig);
      graph.data(diagramData);
      G6.Util.processParallelEdges(diagramData.edges, 120, 'polyline');
      graph.render();
      graph.on('node:click', (e: any) => nodeClickEvent(e));
    }
  }

  const nodeClickEvent = (item: any) => {
    console.log('debug -> selected item', item);
    if (item.item._cfg.model.label.indexOf('On Hold') === -1) {
      setSelectedNode(item.item._cfg.model);
      setSelectedTask(null);
    }
  };

  const exportDiagramToPDF = async (
    paperSize: 'a4' | 'a3',
    paperOrientation: 'portrait' | 'landscape',
  ) => {
    if (graph) {
      setGeneratingPDF(true);
      let oldZoom = graph.getZoom();
      graph.zoomTo(0.5);
      graph.fitCenter();

      await new Promise((resolve) => setTimeout(resolve, 2000)).then(() => {
        graph.toFullDataURL((baseImage: any) => {
          const pdfWorker = new Worker(new URL('./CableDiagramWorker.ts', import.meta.url), {
            type: 'module',
          });
          pdfWorker.postMessage({ image: baseImage, paperSize, paperOrientation });
          pdfWorker.addEventListener('message', async (e: any) => {
            const filename = `CABLEDIAGRAM_${moment().format('YYYY-MM-DD_HH-mm-ss')}.pdf`;
            setGeneratingPDF(false);
            /* Very dirty method to force browser into downloading PDF blob. */
            let file = new Blob([e.data], { type: 'application/pdf' });
            let fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = filename;
            link.click();
            graph.zoomTo(oldZoom);
            graph.fitCenter();
          });
        });
      });
    }
  };

  async function fetchCableDiagramData(): Promise<any> {
    return await httpGet(`ProjectModule/v1.0/Buildpack/${props.recordId}`)
      .then((res) => {
        if (res.data?.data) {
          return res.data.data;
        }
      })
      .catch((err) => {
        setLoadingError(true);
        setLoadingData(false);
      });
  }

  /* Fetch all nodes and edges for polygon */
  useEffect(() => {
    fetchCableDiagramData().then((res: any) => {
      if (res) {
        setCableDiagramData(res);
        setLoopChain('ALL');
        setLoadingError(false);
        setLoadingData(false);
      } else {
        setLoadingError(true);
        setLoadingData(false);
      }
    });
  }, []);

  /* Dynamically resize Graph on container resize */
  useEffect(() => {
    if (cableDiagramData) {
      if (diagramRef && diagramRef.current && graph) {
        let width = window.getComputedStyle(diagramRef.current!).width;
        let height = window.getComputedStyle(diagramRef.current!).height;
        if (graph && width && height) {
          graph?.changeSize(Number(width.replace('px', '')), Number(height.replace('px', '')));
        }
      }
    }
  }, [columnFullscreen, selectedNode]);

  /* Display Initial Cable diagram  */
  useEffect(() => {
    if (cableDiagramData && loopChain)
      drawGraph(filterCableDiagramData(cableDiagramData)).then(() => setLoadingData(false));
  }, [cableDiagramData, loopChain]);

  // Get outConnection id for a closure id
  const outConnectionIdForClosureId = (closureId: number) => {
    if (closureId) {
      const outConnection = cableDiagramData?.edges.find((edge: any) => edge.source == closureId);

      if (outConnection) {
        return outConnection?.id;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };

  // For autosplicing to be enabled, we need L0 with outConnection or just an L2 closure
  const canRunAutosplicing = (closure: any) => {
    if (closure && cableDiagramData?.edges) {
      const outConnection = cableDiagramData?.edges.find((edge: any) => edge.source == closure.id);
      if (closure.type === 'L0' && outConnection) {
        return true;
      } else if (closure.type === 'L2') {
        return true;
      }
    } else {
      return false;
    }
  };

  const { Content } = Layout;

  return (
    <Card
      size="small"
      title={
        <Row>
          <Col span={8} style={{ paddingTop: 4 }}>
            <span>Cable Diagram {polygonId}</span>
          </Col>
          <Col span={16} style={{ textAlign: 'right' }}>
            {/* Download Dropdown */}
            <Popover
              content={
                <Menu>
                  <MenuDivider title="Portrait" />
                  <MenuItem
                    text="A4 (21 x 29.7 cm)"
                    onClick={() => exportDiagramToPDF('a4', 'portrait')}
                  />
                  <MenuItem
                    text="A3 (29.7 x 42.0 cm)"
                    onClick={() => exportDiagramToPDF('a3', 'portrait')}
                  />

                  <MenuDivider title="Landscape" />
                  <MenuItem
                    text="A4 (21 x 29.7 cm)"
                    onClick={() => exportDiagramToPDF('a4', 'landscape')}
                  />
                  <MenuItem
                    text="A3 (29.7 x 42.0 cm)"
                    onClick={() => exportDiagramToPDF('a3', 'landscape')}
                  />
                </Menu>
              }
              placement="bottom"
            >
              <Button
                disabled={generatingPDF || loadingData || loadingError === true}
                icon="download"
                rightIcon="caret-down"
                text={generatingPDF ? 'Working...' : 'Download'}
              />
            </Popover>
          </Col>
        </Row>
      }
      className="cableDiagramCard"
    >
      <Layout>
        <Content
          style={{
            overflowY: !cableDiagramData?.length || loadingError ? 'hidden' : 'initial',
          }}
        >
          <Row>
            <Col span={selectedNode ? 16 : 24}>
              <div
                style={{
                  height: '93vh',
                  width: '100%',
                  backgroundColor: '#fff',
                }}
              >
                {loadingData ? (
                  <div
                    style={{
                      height: '78vh',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <Spin size="large" style={{ marginTop: '200px' }} />
                    <h2 style={{ marginTop: '50px', fontWeight: 400 }}>Loading Cable Diagram</h2>
                  </div>
                ) : (
                  <></>
                )}
                {loadingError ? (
                  <div
                    style={{
                      height: '78vh',
                      width: '100%',
                      textAlign: 'center',
                    }}
                  >
                    <Result
                      style={{ paddingTop: 100 }}
                      status="error"
                      title="Error"
                      subTitle={`Could not retrieve cable diagram data for polygon ${polygonId}.`}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {!loadingError ? (
                  <div
                    className="diagramMinimap"
                    ref={minimapRef}
                    style={{ opacity: loadingData ? 0 : 1 }}
                  />
                ) : (
                  <></>
                )}

                <div
                  style={{
                    height: '93vh',
                    width: '100%',
                    opacity: generatingPDF ? 0.2 : 1,
                  }}
                  ref={diagramRef}
                />
              </div>
            </Col>

            {selectedNode ? (
              <Col span={8} style={{ height: '93vh' }}>
                <Card
                  title="Details"
                  size="small"
                  className="CableDiagramSidebarCard"
                  style={{ height: '93vh', overflowY: 'auto', padding: 0 }}
                  extra={<CloseOutlined onClick={() => setSelectedNode(null)} />}
                >
                  <Row
                    style={{
                      padding: 8,
                      marginTop: 5,
                      border: '1px solid #d6d6d6',
                      borderRadius: 8,
                    }}
                  >
                    <Col span={24}>
                      <Row
                        style={{
                          backgroundColor: '#edf4ff',
                          paddingTop: 8,
                          paddingLeft: 10,
                          marginBottom: 10,
                          fontWeight: 600,
                          borderRadius: 4,
                        }}
                      >
                        <Col span={24}>
                          <Typography.Title level={5} style={{ margin: 0 }}>
                            Selected Closure
                          </Typography.Title>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          paddingTop: 3,
                          paddingLeft: 10,
                          paddingRight: 8,
                          marginBottom: 10,
                          fontWeight: 600,
                          borderRadius: 4,
                          fontSize: '1.1em',
                        }}
                      >
                        <Col span={24}>
                          {
                            <>
                              <Badge
                                className="closureTypeCircle"
                                color={getChamberStyle(selectedNode.type).fill}
                              />
                              <span style={{ fontWeight: 600 }}>
                                {selectedNode?.type || ''} Closure
                              </span>
                            </>
                          }
                        </Col>
                      </Row>

                      <Divider style={{ margin: '10px 0' }} />

                      <Row
                        style={{
                          paddingTop: 3,
                          paddingLeft: 10,
                          paddingRight: 8,
                          marginBottom: 10,
                          borderRadius: 4,
                          fontSize: '0.9em',
                        }}
                      >
                        <Col span={24}>
                          <span style={{ fontWeight: 600 }}>Nearest Address</span>
                        </Col>
                        <Col span={24} style={{ marginTop: 4 }}>
                          <span>{selectedNode.closure?.nearest_address || '-'}</span>
                        </Col>
                        <Col span={24}>
                          <Row style={{ marginTop: 20 }}>
                            <Col span={12}>
                              <Button
                                intent="primary"
                                outlined
                                disabled={!canRunAutosplicing(selectedNode)}
                              >
                                <Link
                                  to={`/ProjectModule/Connection/${selectedNode?.id}${
                                    selectedNode?.type === 'L0'
                                      ? '/' + outConnectionIdForClosureId(selectedNode?.id)
                                      : ''
                                  }`}
                                  target="_blank"
                                >
                                  Auto splicing
                                </Link>
                              </Button>
                            </Col>
                            <Col span={12} style={{ textAlign: 'right' }}>
                              <Button
                                intent="primary"
                                disabled={!selectedNode || !selectedNode?.recordId}
                              >
                                <Link
                                  to={`/ProjectModule/Feature/${selectedNode?.recordId}`}
                                  target="_blank"
                                >
                                  Go to Record
                                </Link>
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  {/* <Row
                    style={{
                      padding: 8,
                      marginTop: 18,
                      border: '1px solid #d6d6d6',
                      borderRadius: 8,
                    }}
                  >
                    <Col span={24}>
                      <Row
                        style={{
                          backgroundColor: '#edf4ff',
                          paddingTop: 6,
                          paddingLeft: 10,
                          marginBottom: 10,
                          fontWeight: 600,
                          borderRadius: 4,
                        }}
                      >
                        <Col span={24}>
                          <Typography.Title level={5}>Tasks</Typography.Title>
                        </Col>
                      </Row>
                      <TaskDataLoader
                        setSelectedTask={setSelectedTask}
                        moduleName={'ProjectModule'}
                        entityName={'Task'}
                        taskUniqueRef={selectedNode.taskUniqueRef}
                      />
                    </Col>
                  </Row> */}

                  {/* {selectedTask ? (
                    <Row
                      style={{
                        padding: 8,
                        marginTop: 18,
                        border: '1px solid #d6d6d6',
                        borderRadius: 8,
                      }}
                    >
                      <Col span={24}>
                        <Row
                          style={{
                            backgroundColor: '#edf4ff',
                            padding: '6px 10px 0px 10px',
                            marginBottom: 4,
                            fontWeight: 600,
                            borderRadius: 4,
                          }}
                        >
                          <Col span={16}>
                            <Typography.Title level={5}>
                              Selected Task
                            </Typography.Title>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            {selectedTask ? (
                              <Link
                                to={`/ProjectModule/Task/${selectedTask.id}`}
                                target="_blank"
                              >
                                {selectedTask.recordNumber}
                              </Link>
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>

                        <Row style={{ padding: '8px 4px 3px 4px' }}>
                          <Col span={12}>
                            <span style={{ fontWeight: 600 }}>Task title</span>
                          </Col>
                          <Col span={12} style={{ textAlign: 'right' }}>
                            <span style={{ fontWeight: 600 }}>Stage</span>
                          </Col>
                        </Row>

                        <Row style={{ padding: '0px 4px 8px 4px' }}>
                          <Col span={12}>{selectedTask.title}</Col>
                          <Col span={12} style={{ textAlign: 'right' }}>
                            <StageNameTag
                              record={selectedTask}
                              text={
                                selectedTask.stage
                                  ? selectedTask.stage.name
                                  : ''
                              }
                              size="small"
                            />
                          </Col>
                        </Row>

                        <Divider style={{ margin: '8px 0 10px 0' }} />

                        <Row style={{ padding: '0px 4px 8px 4px' }}>
                          <Col span={8}>
                            <span style={{ fontWeight: 600 }}>Out Closure</span>
                          </Col>
                          <Col span={8} style={{ textAlign: 'center' }}>
                            <span style={{ fontWeight: 600 }}>
                              Cable Connection
                            </span>
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            <span style={{ fontWeight: 600 }}>In Closure</span>
                          </Col>
                        </Row>
                        <Row style={{ padding: '0px 4px 8px 4px' }}>
                          <Col span={8}>
                            {getProperty(selectedTask, 'OutClosure')
                              ? getProperty(selectedTask, 'OutClosure')
                              : 'None'}
                          </Col>
                          <Col span={8} style={{ textAlign: 'center' }}>
                            {getProperty(selectedTask, 'ConnectionCable')
                              ? getProperty(selectedTask, 'ConnectionCable')
                              : 'None'}
                          </Col>
                          <Col span={8} style={{ textAlign: 'right' }}>
                            {getProperty(selectedTask, 'InClosure')
                              ? getProperty(selectedTask, 'InClosure')
                              : 'None'}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )} */}
                  {/* {
                  selectedTask ? (
                    <Row
                      style={{
                        padding: 8,
                        marginTop: 18,
                        border: '1px solid #d6d6d6',
                        borderRadius: 8,
                      }}
                    >
                      <Col span={24}>
                        <Row
                          style={{
                            backgroundColor: '#edf4ff',
                            padding: '6px 10px 0px 10px',
                            marginBottom: 4,
                            fontWeight: 600,
                            borderRadius: 4,
                          }}
                        >
                          <Col span={24}>
                            <Typography.Title level={5}>
                              Jobs for Selected Task
                            </Typography.Title>
                          </Col>
                        </Row>
                        <Row style={{ marginTop: 11 }}>
                          <Col span={24}>
                            <JobSideBarListView
                              moduleName={'ProjectModule'}
                              entityName={'Job'}
                              recordId={selectedTask.id}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )} */}
                </Card>
              </Col>
            ) : (
              <></>
            )}
          </Row>
        </Content>
      </Layout>
    </Card>
  );
};

//@ts-ignore
export default CableDiagram;
