import { useState } from 'react';
import { SubmissionStatus } from './types';

export const useSubmissionState = () => {
  const [status, setStatus] = useState<SubmissionStatus>('idle');

  return {
    state: {
      status,
    },
    actions: {
      setStatus,
    },
  };
};
