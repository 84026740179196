import { Button } from '@blueprintjs/core';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import { Link } from 'react-router-dom';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import DetailView from '@core/views/DetailView';

const { CRM_MODULE } = SchemaModuleTypeEnums;

const CrmDataSetDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);
  return (
    <DetailView
      showCollaborators
      headerExtras={[
        <Link to={`/${CRM_MODULE}/CrmDataset/${record?.id}/Build`}>
          <Button intent="primary" icon={<i className="bi bi-wrench" />} outlined>
            Build
          </Button>
        </Link>,
        <Link to={`/${CRM_MODULE}/CrmDataset/${record?.id}/Work`}>
          <Button intent="primary" icon={<i className="bi bi-person-workspace" />} outlined>
            Work
          </Button>
        </Link>,
      ]}
    />
  );
};

export default CrmDataSetDetailView;
