import axios, { AxiosRequestConfig, CancelToken, CancelTokenSource } from 'axios';
import { getHostName } from './helpers';

function urlMapper(path: string) {
  if (Boolean(import.meta.env.VITE_USE_LOCAL_CRM_API) && path.startsWith('CrmModule/v1.0'))
    return `http://localhost:10104/${path}`;

  if (Boolean(import.meta.env.VITE_USE_LOCAL_SCHEMA_API) && path.startsWith('SchemaModule/v1.0'))
    return `http://localhost:10105/${path}`;

  if (
    Boolean(import.meta.env.VITE_USE_LOCAL_IDENTITY_API) &&
    path.startsWith('IdentityModule/v1.0')
  )
    return `http://localhost:10100/${path}`;

  if (Boolean(import.meta.env.VITE_USE_LOCAL_PROJECT_API) && path.startsWith('ProjectModule/v1.0'))
    return `http://localhost:10119/${path}`;

  if (
    Boolean(import.meta.env.VITE_USE_LOCAL_NOTIFY_API) &&
    path.startsWith('NotificationModule/v1.0')
  )
    return `http://localhost:10113/${path}`;

  if (Boolean(import.meta.env.VITE_USE_LOCAL_SERVICE_API) && path.startsWith('ServiceModule'))
    return `http://localhost:10118/${path}`;

  return `${getHostName()}/${path}`;
}

function buildUrl(path: string): string {
  let url = urlMapper(path);
  return url;
}

export function getRequestAborter() {
  return axios.CancelToken.source();
};

export function httpGet<T>(
  path: string,
  params?: any,
  axiosOptions: Partial<AxiosRequestConfig> = {},
) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'get',
    timeout: 60 * 1000 * 2,
    params: params,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    ...axiosOptions,
  });
}

export function httpGetAbsolute<T>(path: string) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'get',
    timeout: 60 * 1000 * 2,
    url: path,
    headers: {
      Authorization: 'Bearer ' + token,
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    },
  });
}

export function httpPost<T>(path: string, body: T, params?: any, cancelToken?: CancelToken) {
  const token = localStorage.getItem(`token`);

  // allow these routes that do not require a token
  const excludedRoutes =
    path.indexOf('login') === -1 ||
    path.indexOf('forgot-password') === -1 ||
    path.indexOf('reset-password') === -1 ||
    path.indexOf('complete-registration') === -1 ||
    path.indexOf('register') === -1;

  if (!token && !excludedRoutes) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'post',
    timeout: 60 * 1000 * 2,
    params: params,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
    cancelToken,
  });
}

export function httpPut<T>(path: string, body: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'put',
    timeout: 60 * 1000 * 2,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}

export function httpPatch<T>(path: string, body: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'patch',
    timeout: 60 * 1000 * 2,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}

export function httpDelete<T>(path: string, body?: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'delete',
    timeout: 60 * 1000 * 2,
    url: buildUrl(path),
    headers: {
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}

export function httpFileUpload<T>(path: string, body: T) {
  const token = localStorage.getItem(`token`);
  if (!token) {
    return Promise.reject({
      statusCode: 401,
      message: `no access token provided for path ${path}`,
      response: { data: { statusCode: 401 } },
    });
  }
  return axios({
    method: 'post',
    timeout: 60 * 1000 * 2,
    url: buildUrl(path),
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + token,
    },
    data: body,
  });
}
