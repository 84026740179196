export const DefaultSchemaActionTemplate = (moduleName: string, entityName: string) => {
  return {
    version: 2,
    moduleName: moduleName,
    entityName: entityName,
    formDefinition: [
      {
        sectionName: 'Section 1',
        closedByDefault: false,
        order: 1,
        columns: 1,
        formFields: [],
      },
    ],
    settings: {
      actionLabel: undefined,
      showRecordType: false,
      showProgressBar: false,
      showRecordTitle: false,
      overrideRecordNumber: false,
    },
  };
};

export const V2DefaultSchemaActionFlowTemplate = (moduleName: string, entityName: string) => {
  return {
    version: 2,
    settings: {
      actionLabel: null,
    },
    entityName: entityName,
    moduleName: moduleName,
    dialogSteps: [
      {
        name: 'Step 1',
        description: 'This is Step One',
        schemaActions: [''],
        linkType: null,
      },
    ],
  };
};
