export const ADD_ASSOCIATED_RECORDS_TO_DATASET = 'ADD_ASSOCIATED_RECORDS_TO_DATASET';
export const ADD_DATASET_RECORD_TO_DATASET_SELECTION = 'ADD_DATASET_RECORD_TO_DATASET_SELECTION';
export const ADD_RECORD_TO_SELECTION = 'ADD_RECORD_TO_SELECTION';
export const ADD_SELECTED_RECORDS_TO_DATASET = 'ADD_SELECTED_RECORDS_TO_DATASET';
export const ADD_TO_SELECTED_RECORDS = 'ADD_TO_SELECTED_RECORDS';
export const BULK_REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION =
  'BULK_REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION';
export const DESELECT_ALL_DATASET_RECORDS = 'DESELECT_ALL_DATASET_RECORDS';
export const DESELECT_ALL_RECORDS = 'DESELECT_ALL_RECORDS';
export const REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION =
  'REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION';
export const REMOVE_FROM_SELECTED_RECORDS = 'REMOVE_FROM_SELECTED_RECORDS';
export const REMOVE_RECORD_FROM_DATASET = 'ADD_RECORD_TO_DATASET';
export const REMOVE_RECORD_FROM_SELECTION = 'REMOVE_RECORD_FROM_SELECTION';
export const SELECT_ALL_DATASET_RECORDS = 'SELECT_ALL_DATASET_RECORDS';
export const SELECT_ALL_RECORDS = 'SELECT_ALL_RECORDS';
export const SET_ACTIVE_STATUS_TAB = 'SET_ACTIVE_STATUS_TAB';
export const SET_ALL_RECORD_STATUSES = 'SET_ALL_RECORD_STATUSES';
export const SET_DATASET_PAGINATION = 'SET_DATASET_PAGINATION';
export const SET_ELASTIC_QUERY = 'SET_ELASTIC_QUERY';
export const SET_IS_DATASET_UPDATED = 'SET_IS_DATASET_UPDATED';
export const SET_IS_FETCHING_RELATED_RECORDS = 'SET_IS_FETCHING_RELATED_RECORDS';
export const SET_IS_RECORD_SEARCHING = 'SET_IS_RECORD_SEARCHING';
export const SET_IS_UPDATING_DATA_SET = 'SET_IS_UPDATING_DATA_SET';
export const SET_RECORD_SEARCH_RESULTS = 'SET_RECORD_SEARCH_RESULTS';

export const SET_DATASET_PARENT_RECORD = 'SET_DATASET_PARENT_RECORD';
