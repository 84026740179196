import { Button, Section, Tag } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Empty, Row, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet } from '@core/http/requests';
import { usePrevious } from '@core/helpers/reactHelpers';
import TableCellStageTag from '@core/components/TableCellStageTag';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '@legacy/core/userInterface/store/actions';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '@core/helpers/recordHelpers';
interface Props {
  sourceRecord: DbRecordEntityTransform;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;

const AssociatedWorkOrdersCard: React.FC<Props> = (props: Props) => {
  const { sourceRecord, openDrawer } = props;

  const [isLoadingWorkOrders, setIsLoadingWorkOrders] = useState<boolean>(false);
  const [workOrders, setWorkOrders] = useState<DbRecordEntityTransform[]>([]);
  const [recordId, setRecordId] = useState<string | undefined>(undefined);

  const previousRecordId = usePrevious(recordId);

  useEffect(() => {
    if (sourceRecord && sourceRecord?.id !== recordId) {
      setRecordId(sourceRecord?.id);
    }
  }, [sourceRecord]);

  useEffect(() => {
    if (recordId !== previousRecordId && !isLoadingWorkOrders) {
      fetchWorkOrders();
    }
  }, [recordId]);

  const fetchWorkOrders = () => {
    setIsLoadingWorkOrders(true);
    httpGet(
      `${FIELD_SERVICE_MODULE}/v1.0/db-associations/${WORK_ORDER}/${sourceRecord.id}/relations?entities=["${WORK_ORDER}"]&withLinks=false`,
    ).then((res: any) => {
      setIsLoadingWorkOrders(false);
      const WOs = res.data?.data[WORK_ORDER]?.dbRecords || [];
      setWorkOrders(WOs);
    });
  };

  const renderWorkOrderList = () => {
    return workOrders.map((workOrder: DbRecordEntityTransform, i: number) => {
      return (
        <Row align="middle" style={{ marginBottom: i + 1 === workOrders.length ? 0 : 8 }}>
          <Col span={5} key={workOrder.id} style={{ fontSize: 11 }}>
            <Link to={getBrowserPath(workOrder)} target="_blank">
              <span>{workOrder.recordNumber}</span>
            </Link>
          </Col>
          <Col span={6} key={workOrder.id}>
            <Tag
              round
              intent="primary"
              minimal
              style={{ fontSize: 10, verticalAlign: 'middle', padding: '0px 6px' }}
            >
              {workOrder.type}
            </Tag>
          </Col>
          <Col span={11} key={workOrder.id}>
            <TableCellStageTag record={workOrder} small truncate={16} />
          </Col>
          <Col span={2} key={workOrder.id} style={{ textAlign: 'right' }}>
            <Button
              small
              icon="eye-open"
              onClick={() =>
                openDrawer({
                  recordId: workOrder?.id,
                  moduleName: FIELD_SERVICE_MODULE,
                  entityName: WORK_ORDER,
                })
              }
            />
          </Col>
        </Row>
      );
    });
  };

  return (
    <Section title="Linked Work Orders" style={{ marginBottom: 10 }}>
      <Row style={{ padding: 10 }}>
        <Col span={24}>
          {isLoadingWorkOrders && <Skeleton active />}

          {!isLoadingWorkOrders && workOrders.length === 0 && (
            <Empty description="No Linked Work Orders found." />
          )}

          {!isLoadingWorkOrders && workOrders.length > 0 && renderWorkOrderList()}
        </Col>
      </Row>
    </Section>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(AssociatedWorkOrdersCard);
