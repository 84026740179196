import { CaretDownOutlined, DownOutlined } from '@ant-design/icons';
import { QGISClosureTypeEnum } from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Card, Col, Divider, Dropdown, Empty, Menu, Row, Spin } from 'antd';
import { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import {
  addRecordToShortList,
  IAddRecordToShortList,
  ISearchRecords,
  searchRecordsRequest,
} from '@legacy/core/records/store/actions';
import DataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import { updateAutosplicingReducerState } from '../../store/actions';
import { IAutosplicingReducer, TUpdateAutosplicingReducerAction } from '../../store/types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

interface OwnProps {
  autosplicingReducer: IAutosplicingReducer;
  searchRecords: Function;
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  userReducer: any;
  schemaReducer: any;
  getSchema: Function;
  shortListRecord: Function;
}

type Props = OwnProps;

const AutosplicingProject: FunctionComponent<Props> = (props) => {
  const { autosplicingReducer, shortListRecord, searchRecords, getSchema } = props;
  const { selectedEntityOdinRecord } = autosplicingReducer;
  const [projects, setProjects] = useState<DbRecordEntityTransform[] | []>([]);
  const [loadingProject, setLoadingProject] = useState<boolean>(false);

  const fetchProjectsByPolygonId = async (polygonId: string) => {
    if (polygonId) {
      setLoadingProject(true);
      getSchema({
        moduleName: SchemaModuleTypeEnums.PROJECT_MODULE,
        entityName: SchemaModuleEntityTypeEnums.PROJECT
      },
        (response: SchemaEntity) => {
          if (response) {

            searchRecords(
              {
                schema: response,
                searchQuery: {
                  terms: '',
                  schemas: response?.id,
                  boolean: {
                    must: [
                      {
                        query_string: {
                          fields: ['properties.ExternalRef'],
                          query: Number(polygonId),
                          lenient: true,
                          default_operator: 'AND',
                        },
                      },
                    ],
                  },
                },
              },
              (searchResults: any) => {
                if (searchResults?.data?.data?.length > 0) {
                  setLoadingProject(false);
                  setProjects(searchResults?.data?.data);
                } else {
                  setLoadingProject(false);
                  setProjects([]);
                }
              },
            )
          } else {
            setLoadingProject(false);
          }
        })

    } else {
      setLoadingProject(false);
    }
  };

  const renderProjects = () => {
    for (const project of projects) {
      return (
        <>
          <Card
            style={{ marginBottom: 15 }}
            className="autosplicingProjectCard"
            title={
              <Row>
                <Col span={18}>{`${getProperty(project, 'ExternalRef')} Project`}</Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                  <Dropdown
                    trigger={['click']}
                    overlay={
                      <Menu>
                        <Menu.Item
                          onClick={() =>
                            shortListRecord({
                              showPreview: true,
                              record: project,
                            })
                          }
                        >
                          Quick View
                        </Menu.Item>

                        <Divider style={{ margin: '10px 0' }} />

                        <>
                          <Menu.Item>
                            <Link target="_blank" to={`/ProjectModule/BuildPack/${project?.id}`}>
                              <Row>
                                <Col span={24}>Build Pack - Cabling</Col>
                              </Row>
                            </Link>
                          </Menu.Item>
                        </>
                      </Menu>
                    }
                  >
                    <Button
                      icon={<CaretDownOutlined style={{fontSize:'0.9em'}} />}
                      style={{ marginLeft: 5 }}
                      size="small"
                      type="default"
                    />
                  </Dropdown>
                </Col>
              </Row>
            }
          >
            <RecordProperties record={project} columns={2} size="small" />
          </Card>

          <DataTable
            thumbnailSize={8}
            title="Files"
            record={project}
            moduleName="SchemaModule"
            entityName="File"
            location="sidebar"
            disableUpload
          />
        </>
      );
    }
  };

  // On each closure id update check if selected entity type is L1 or L2 CLOSURE.
  useEffect(() => {
    if (selectedEntityOdinRecord) {
      const closureType =
        QGISClosureTypeEnum[
        getProperty(selectedEntityOdinRecord, 'ClosureType')
        ];

      if (closureType === 'L1') {
        fetchProjectsByPolygonId(
          getProperty(selectedEntityOdinRecord, 'L1PolygonId'),
        );
      } else if (closureType === 'L2') {
        fetchProjectsByPolygonId(
          getProperty(selectedEntityOdinRecord, 'L2PolygonId'),
        );
      }
    } else {
      setProjects([]);
    }
  }, [selectedEntityOdinRecord]);

  return loadingProject ? (
    <Row style={{ textAlign: 'center' }}>
      <Col span={24} style={{ marginTop: 20 }}>
        <span>Searching for projects...</span>
      </Col>
      <Col span={24} style={{ marginTop: 20 }}>
        <Spin size="large" />
      </Col>
    </Row>
  ) : (
      <Row>
        <Col span={24}>
          {projects.length > 0 ? (
            renderProjects()
          ) : (
              <Empty style={{ padding: 20 }} description="No projects found." />
            )}
        </Col>
      </Row>
    );
};

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) =>
    dispatch(searchRecordsRequest(params, cb)),
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  shortListRecord: (params: IAddRecordToShortList) =>
    dispatch(addRecordToShortList(params))
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
});

export default connect(mapState, mapDispatch)(AutosplicingProject);
