export const SEARCH_PREMISES_LIST_REQUEST = 'SEARCH_PREMISES_LIST_REQUEST';
export const SEARCH_PREMISES_LIST_SUCCESS = 'SEARCH_PREMISES_LIST_SUCCESS';
export const SEARCH_PREMISES_LIST_ERROR = 'SEARCH_PREMISES_LIST_ERROR';

export const GET_PREMISE_BY_UDPRN_AND_UMPRN_REQUEST = 'GET_PREMISE_BY_UDPRN_AND_UMPRN_REQUEST';
export const GET_PREMISE_BY_UDPRN_AND_UMPRN_SUCCESS = 'GET_PREMISE_BY_UDPRN_AND_UMPRN_SUCCESS';
export const GET_PREMISE_BY_UDPRN_AND_UMPRN_ERROR = 'GET_PREMISE_BY_UDPRN_AND_UMPRN_ERROR';

export const LOG_PREMISE_VISIT_REQUEST = 'LOG_PREMISE_VISIT_REQUEST';
export const LOG_PREMISE_VISIT_SUCCESS = 'LOG_PREMISE_VISIT_SUCCESS';
export const LOG_PREMISE_VISIT_ERROR = 'LOG_PREMISE_VISIT_ERROR';

export const PREMISE_LIST_CANCEL_REQUESTS = 'PREMISE_LIST_CANCEL_REQUESTS';
export const SET_PREMISE_LIST_SEARCH_QUERY = 'SET_PREMISE_LIST_SEARCH_QUERY';
export const SET_SELECTED_PREMISE = 'SET_SELECTED_PREMISE';

export const UPDATE_PREMISES_SALES_STATUS_REQUEST = 'UPDATE_PREMISES_SALES_STATUS_REQUEST';
export const UPDATE_PREMISES_SALES_STATUS_SUCCESS = 'UPDATE_PREMISES_SALES_STATUS_SUCCESS';
export const UPDATE_PREMISES_SALES_STATUS_ERROR = 'UPDATE_PREMISES_SALES_STATUS_ERROR';
