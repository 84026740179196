import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { httpGet } from '@core/http/requests';

const SCHEDULE_SLOT = 'ScheduleSlot';

type UseScheduleSlotsParams = {
  record: DbRecordEntityTransform;
  selectedDate: string;
};

type CalendarUtilization = {
  Config: DbRecordEntityTransform;
  Slot: DbRecordEntityTransform;
  Utilization: number;
  Date: string;
  IsMaxAdvancedBooking: boolean;

  AMConfig: DbRecordEntityTransform;
  AM: boolean;
  AMCount: number;
  AMRemaining: number;
  ScheduleIdsAM: string[];
  MaxAMCount: number;

  PMConfig: DbRecordEntityTransform;
  PM: boolean;
  PMCount: number;
  PMRemaining: number;
  ScheduleIdsPM: string[];
  MaxPMCount: number;
};

type CalendarUtilizationSummary = {
  AMCount: number;
  AMRemaining: number;
  MaxAMCount: number;

  PMCount: number;
  PMRemaining: number;
  MaxPMCount: number;
};

export const getScheduleConfigSummary = (utilizationInfo: CalendarUtilization[]) => {
  const initialSummary: CalendarUtilizationSummary = {
    AMCount: 0,
    AMRemaining: 0,
    MaxAMCount: 0,

    PMCount: 0,
    PMRemaining: 0,
    MaxPMCount: 0,
  };

  return utilizationInfo.reduce<CalendarUtilizationSummary>(
    (summary, utilization) => ({
      AMCount: summary.AMCount + utilization.AMCount,
      AMRemaining: summary.AMRemaining + utilization.AMRemaining,
      MaxAMCount: summary.MaxAMCount + utilization.MaxAMCount,

      PMCount: summary.PMCount + utilization.PMCount,
      PMRemaining: summary.PMRemaining + utilization.PMRemaining,
      MaxPMCount: summary.MaxPMCount + utilization.MaxPMCount,
    }),
    initialSummary,
  );
};

export const useCalendarUtilization = ({ record, selectedDate }: UseScheduleSlotsParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<CalendarUtilization[]>([]);
  const [refetch, setRefetch] = useState(0);

  const monthStart = dayjs(selectedDate).startOf('month').format('YYYY-MM-DD');
  const monthEnd = dayjs(selectedDate).endOf('month').format('YYYY-MM-DD');

  const loadCalendarUtilization = async () => {
    const response = await httpGet('FieldServiceModule/v1.0/ServiceAppointment/calendar', {
      start: monthStart,
      end: monthEnd,
      type: record.type,
      scheduleId: record.id,
      isOverview: true,
    });

    return response.data.data;
  };

  useEffect(() => {
    let mounted = true;
    setIsLoading(true);

    loadCalendarUtilization().then((data = []) => {
      if (!mounted) return;
      setIsLoading(false);
      setData(data);
    });

    return () => {
      mounted = false;
    };
  }, [monthStart, monthEnd, refetch]);

  return {
    loading: isLoading,
    data,
    refetch: () => setRefetch((value) => value + 1),
  };
};
