import { FilterOutlined } from '@ant-design/icons';
import { Button, Icon } from '@blueprintjs/core';
import React from 'react';
import { connect } from 'react-redux';
import { generateModuleAndEntityKeyFromProps } from '@core/helpers/searchHelpers';
import { toggleQueryBuilder } from '../store/actions';
import { getQueryBuilderReducer } from '../store/reducer';

interface Props {
  moduleName: string | undefined;
  entityName: string | undefined;
  toggle: () => {};
  queryBuilderReducer: any;
}

class QueryBuilderToggle extends React.Component<Props> {
  render() {
    const { toggle, moduleName, entityName } = this.props;

    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      moduleName,
      entityName,
    );

    return (
      <Button
        intent={queryBuilderReducer?.isVisible ? 'primary' : undefined}
        onClick={() => toggle()}
        style={{ marginRight: 4 }}
        icon={<Icon icon="filter" />}
      />
    );
  }
}

const mapState = (state: any) => ({
  queryBuilderReducer: state.queryBuilderReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  toggle: () => dispatch(toggleQueryBuilder(generateModuleAndEntityKeyFromProps(ownProps))),
});

export default connect(mapState, mapDispatch)(QueryBuilderToggle);
