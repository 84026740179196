import { CaretDownFilled, LinkOutlined } from '@ant-design/icons';
import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getProperty,
  splitEntityToModuleAndEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  Button,
  Card,
  Col,
  Divider,
  Dropdown,
  Menu,
  Popconfirm,
  Row,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import SwapAddress from '@legacy/modules/CrmModule/containers/Address/SwapAddress';
import ServiceAppointmentCancelModal from '@legacy/modules/FieldServiceModule/containers/ServiceAppointmentCancelModal';
import OdinIcons from '@legacy/components/Navigation/OdinIcons';
import BuildCompleteDrawer from '@netomnia/modules/ProjectModule/components/Openreach/BuildComplete';
import {
  canUserCloneRecord,
  canUserDeleteRecord,
  canUserUpdateRecord,
  hasAnyRoles,
  hasPermissions,
  isSystemAdmin,
} from '@core/helpers/rbacRules';
import { displayMessage } from '../../../messages/store/reducers';
import history from '@core/helpers/browserHistory';
import {
  checkRecordIsLocked,
  getAllSchemaAssociationEntities,
  getBrowserPath,
  getEntityNameFromRecord,
  getModuleAndEntityNameFromRecord,
} from '@core/helpers/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { initailizeCancelAppointmentModal } from '../../../appointments/store/actions';
import { listUsers } from '../../../identity/store/actions';
import { getPipelinesByModuleAndEntity } from '../../../pipelines/store/actions';
import {
  deleteRecordAssociationById,
  getRecordAssociationByIdRequest,
  getRecordAssociationsRequest,
  IDeleteRecordAssociation,
  IGetRecordAssociationById,
  IGetRecordAssociations,
} from '../../../recordsAssociations/store/actions';
import { DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST } from '../../../recordsAssociations/store/constants';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import { IInitializeSwapAddress, initializeSwapAddress } from '../../../workflow/store/actions';
import { initProcessWorkflowForm } from '../../../workflowEngine/components/ProcessWorkflow/store/actions';
import {
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  IDeleteRecordById,
  IGetRecordById,
  IRestoreRecordById,
  restoreRecordByIdRequest,
  toggleQuickView,
} from '../../store/actions';
import { CREATE_DB_RECORD_REQUEST, UPDATE_DB_RECORD_BY_ID_REQUEST } from '../../store/constants';
import { IRecordReducer } from '../../store/reducer';
import CoreForm, { Props as OdinFormModalProps } from '../Forms/CoreForm';
import { InputChangeParams } from '../Forms/FormFields';
import { initializeRecordForm } from '../Forms/store/actions';
import {
  cancelNAOrder,
  submitA55,
  submitNAOrder,
  submitProblemEvidence,
  submitSolutionEvidence,
  submitSPOCompletion,
} from './NetworkAdjustmentActions/actions';
import './styles.scss';
import { toggleAssignRecordToGroupModal } from '../../../userInterface/store/actions';
import AssignGroupsToRecords from '../Groups/AssignGroupsToRecords';
import { parseDateAndTimeLocal } from '@core/helpers/dateHelpers';

const { Text } = Typography;

const { PRODUCT_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { NOTE, PRODUCT } = SchemaModuleEntityTypeEnums;

const excludeFromClone = {
  Offer: ['OrderModule:OrderItem', 'CrmModule:Lead'],
  PriceBook: ['OrderModule:OrderItem', 'ProductModule:Vendor'],
  Contact: [
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Order: [
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Product: [
    'OrderModule:OrderItem',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Discount: [
    'OrderModule:OrderItem',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'BillingModule:Transaction',
    'CrmModule:Lead',
  ],
  Account: [
    'OrderModule:Order',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'OrderModule:BillingAdjustment',
    'CrmModule:Lead',
  ],
  Address: [
    'OrderModule:Order',
    'FieldServiceModule:WorkOrder',
    'BillingModule:Invoice',
    'CrmModule:Lead',
    'CrmModule:Contact',
    'CrmModule:Account',
    'CrmModule:Visit',
    'ServiceModule:CustomerDeviceOnt',
  ],
};

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  match: any;
  userReducer: any;
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  initializeForm: any;
  deleteRecord: (param: IDeleteRecordById, cb: (res: any) => {}) => {};
  restoreRecord: (param: IRestoreRecordById, cb: (res: any) => {}) => {};
  getRecord: any;
  getRelatedRecordById: any;
  getUsers: any;
  getPipelines: any;
  getAssociations: any;
  hasColumnMappings?: boolean;
  visibleProperties?: string[];
  children?: any;
  style?: any;
  onDelete?: any;
  toggleQuickview: Function;
  initializeCancelAppointment: any;
  updateFormDisabledFields?: string[];
  updateFromCustomValidations?: { [key: string]: any };
  updateFormAdditionalInputChangeHandler?: (
    updateFormProps: OdinFormModalProps,
    params: InputChangeParams,
  ) => void;
  initializeSwapAddress: (params: IInitializeSwapAddress) => void;
  disableRelatedProductEdit?: boolean;
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) => void;
  alertMessage: any;
  initProcessWorkflowForm: (params: any) => void;
  entityName?: string;
  toggleAssignGroupModal: () => void;
};

type State = {
  isBuildCompleteDrawerOpen: boolean;
};

const uuid = uuidv4();

class RecordHeader extends React.Component<PropsType, State> {
  state: State = {
    isBuildCompleteDrawerOpen: false,
  };

  async initializeCloneForm() {
    const {
      record,
      schemaReducer,
      initializeForm,
      getUsers,
      getPipelines,
      getAssociations,
      visibleProperties,
    } = this.props;

    getUsers();

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    if (schema) {
      getPipelines({ schema: schema });

      initializeForm({
        formUUID: uuid,
        title: 'Initializing',
        showInitializing: true,
      });

      getAssociations(
        {
          recordId: record.id,
          schema,
          entities: getAllSchemaAssociationEntities(schema.associations, [NOTE]),
        },
        (params: { results: DbRecordAssociationRecordsTransform[] }) => {
          // parse associations into related records

          const sectionAssociations: any[] = [];
          const modifiedAssociations: any[] = [];

          for (const key of Object.keys(params.results)) {
            // @ts-ignore
            if (params.results[key].dbRecords) {
              // @ts-ignore
              params.results[key].dbRecords
                .filter((elem: { entity: string }) =>
                  // @ts-ignore
                  excludeFromClone[schema.entityName]
                    ? // @ts-ignore
                      !excludeFromClone[schema.entityName].includes(elem.entity)
                    : true,
                )
                .map((elem: DbRecordEntityTransform) => {
                  sectionAssociations.push({
                    entity: elem.entity,
                    recordId: elem.id,
                    title: `${elem.entity}: ${elem.title}`,
                  });

                  modifiedAssociations.push({
                    entity: elem.entity,
                    recordId: elem.id,
                    relatedAssociationId: elem.dbRecordAssociation?.relatedAssociationId,
                  });
                });
            }
          }

          initializeForm({
            formUUID: uuid,
            title: `Clone ${schema.entityName}`,
            showFormModal: true,
            showInitializing: false,
            isCreateReq: true,
            isCloning: true,
            schema: schema,
            selected: record,
            recordType: record.type,
            visibleFieldOverride: visibleProperties,
            sections: [
              {
                name: schema.name,
                schema: schema,
                associations: sectionAssociations,
              },
            ],
            modified: [
              {
                schemaId: schema.id,
                type: record.type,
                title: record.title,
                ownerId: record.ownedBy?.id,
                properties: record.properties,
                associations: modifiedAssociations,
              },
            ],
          });
        },
      );
    }
  }

  async initializeUpdateForm() {
    const {
      record,
      schemaReducer,
      initializeForm,
      getUsers,
      hasColumnMappings,
      getPipelines,
      visibleProperties,
      updateFormDisabledFields,
      updateFromCustomValidations,
      updateFormAdditionalInputChangeHandler,
    } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    if (schema) {
      getUsers();
      getPipelines({ schema: schema });

      initializeForm({
        formUUID: uuid,
        title: hasColumnMappings
          ? `Update Linked ${schema.entityName}`
          : `Update ${schema.entityName}`,
        hasColumnMappings,
        visibleFieldOverride: visibleProperties,
        disabledFields: updateFormDisabledFields,
        customValidations: updateFromCustomValidations,
        additionalInputChangeHandler: updateFormAdditionalInputChangeHandler,
        showFormModal: true,
        isUpdateReq: true,
        schema: schema,
        selected: record,
        recordType: record.type,
        sections: [{ name: schema.name, schema: schema }],
        relatedEntityName: this.props?.match?.params?.relatedRecordEntityName,
      });
    }
  }

  showSwapAddressModal() {
    const { initializeSwapAddress, record } = this.props;
    initializeSwapAddress({
      isSwapAddressVisible: true,
      addressRecord: record,
    });
  }

  getSplicingPath = (record: DbRecordEntityTransform) => {
    let path: string = '';
    if (record && record.type === 'CLOSURE') {
      const externalRef = getProperty(record, 'ExternalRef');
      if (externalRef) {
        path = `/ProjectModule/Connection/${externalRef}`;
      }
    }
    return path;
  };

  getConfigureClosurePath = (record: DbRecordEntityTransform) => {
    let path: string = '';

    if (record && record?.type === 'CLOSURE') {
      path = `/ProjectModule/Feature/${record?.id}/configure-closure`;
    }

    return path;
  };

  getViewInMapPath = (record: DbRecordEntityTransform) => {
    let path: string = '';
    let recordType: string = '';

    if (record && getProperty(record!, 'ExternalRef')) {
      const moduleEntity = getModuleAndEntityNameFromRecord(record);

      if (record.type) {
        recordType = record?.type;
      } else {
        if (moduleEntity && moduleEntity.entityName === 'Project') {
          recordType = 'polygon';
        }
      }

      if (recordType.length) {
        path = `/ProjectModule/Map/${recordType}/${getProperty(record, 'ExternalRef')}`;
      }
    }

    return path;
  };

  openBuildCompleteDrawer() {
    this.setState((state) => ({
      isBuildCompleteDrawerOpen: true,
    }));
  }

  closeBuildCompleteDrawer() {
    this.setState((state) => ({
      isBuildCompleteDrawerOpen: false,
    }));
  }

  canAccessBuildPack(record: DbRecordEntityTransform) {
    if (record && getProperty(record, 'ExternalRef')) {
      const moduleEntity = getModuleAndEntityNameFromRecord(record);
      return moduleEntity && moduleEntity.entityName === 'Project';
    } else {
      return false;
    }
  }

  render() {
    const {
      userReducer,
      record,
      schemaReducer,
      hasColumnMappings,
      style,
      entityName,
      disableRelatedProductEdit,
      alertMessage,
      recordReducer,
      toggleAssignGroupModal,
    } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <div className="detail-panel-left" style={style}>
        <AssignGroupsToRecords schema={schema} singleRecord={record} />
        <CoreForm
          type="MODAL"
          formUUID={uuid}
          onSubmitEvent={(params: { event: string; results: any }) => this.handleFormSubmit(params)}
        />
        {
          // Work Orders and Service Appointments show cancellation modal on record delete action.
          schema?.entityName === SchemaModuleEntityTypeEnums.WORK_ORDER ||
          schema?.entityName === SchemaModuleEntityTypeEnums.SERVICE_APPOINTMENT ? (
            <ServiceAppointmentCancelModal record={record} />
          ) : (
            <></>
          )
        }
        <SwapAddress record={record} />
        <BuildCompleteDrawer
          record={record}
          isOpen={this.state.isBuildCompleteDrawerOpen}
          close={() => this.closeBuildCompleteDrawer()}
        />

        <Card
          size="small"
          className="recordHeaderCard"
          title={
            <div>
              {OdinIcons('ProjectOutlined')}
              <span style={{ marginLeft: '7px' }}>{schema?.entityName || entityName}</span>
            </div>
          }
          extra={
            <Row>
              <Col>
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu className="detail-action-menu" defaultActiveFirst={false}>
                      {/* FEATURE ACTIONS *********************/}
                      <div>
                        <Row style={{ padding: '5px 10px' }}>
                          <Col
                            span={24}
                            style={{
                              background: '#f2f2f2',
                              padding: '3px 10px',
                              borderRadius: 2,
                            }}
                          >
                            <span style={{ fontWeight: 600 }}>Feature Actions</span>
                          </Col>
                        </Row>
                      </div>

                      {/* Create Connections */}
                      <Menu.Item
                        key={'autoSplicing'}
                        disabled={!this.getSplicingPath(record).length}
                      >
                        <Link target="_blank" to={this.getSplicingPath(record)}>
                          Auto splicing
                        </Link>
                      </Menu.Item>

                      {/* Configure Closure */}
                      {record && record.type === 'CLOSURE' ? (
                        <Menu.Item disabled={!this.getConfigureClosurePath(record).length}>
                          <Link target="_blank" to={this.getConfigureClosurePath(record)}>
                            Cable Connections
                          </Link>
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {/* View in Map */}
                      <Menu.Item key="viewInMap" disabled={!this.getViewInMapPath(record).length}>
                        <Link target="_blank" to={this.getViewInMapPath(record)}>
                          View in Map
                        </Link>
                      </Menu.Item>

                      {/* Build Complete - PIA Structure*/}
                      {record && record.type === 'PIA_STRUCTURE' ? (
                        <Menu.Item onClick={() => this.openBuildCompleteDrawer()}>
                          Build Complete
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {/* Build Pack V2 */}
                      {this.canAccessBuildPack(record) ? (
                        <>
                          <Divider style={{ margin: '10px 0' }} />
                          <Menu.Item>
                            <Link target="_blank" to={`/ProjectModule/BuildPack/${record?.id}`}>
                              <Row>
                                <Col span={24}>Build Pack - Cabling</Col>
                              </Row>
                            </Link>
                          </Menu.Item>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* OPENREACH ACTIONS *********************/}
                      {[
                        'OpenreachNetworkAdjustment',
                        'OpenreachNetworkAdjustmentLineItem',
                      ].includes(getEntityNameFromRecord(record)!) ? (
                        <div>
                          {/* -------------------------------------------------------------------------- */}
                          <Divider style={{ margin: '10px 0' }} />
                          <Row style={{ padding: '5px 10px' }}>
                            <Col
                              span={24}
                              style={{
                                background: '#f2f2f2',
                                padding: '3px 10px',
                                borderRadius: 2,
                              }}
                            >
                              <span style={{ fontWeight: 600 }}>Openreach Actions</span>
                            </Col>
                          </Row>
                        </div>
                      ) : (
                        <></>
                      )}

                      {getEntityNameFromRecord(record) === 'OpenreachNetworkAdjustment' ? (
                        <Menu.Item
                          key="ORNA_CREATE"
                          onClick={() => submitNAOrder(record?.id, alertMessage)}
                        >
                          Create Order
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {getEntityNameFromRecord(record) === 'OpenreachNetworkAdjustment' &&
                      getProperty(record, 'OpenreachOrderReference') ? (
                        <Menu.Item
                          key="ORNA_CANCEL"
                          onClick={() => cancelNAOrder(record?.id, alertMessage)}
                        >
                          Cancel Order
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {getEntityNameFromRecord(record) === 'OpenreachNetworkAdjustment' &&
                      getProperty(record, 'OpenreachOrderReference') ? (
                        <Menu.Item
                          key="ORNA_A55"
                          onClick={() => submitA55(record?.id, alertMessage)}
                        >
                          Submit A55
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {/* Openreach NALI- Submit Problem Evidence */}
                      {getEntityNameFromRecord(record) === 'OpenreachProductOrderItem' ? (
                        <Menu.Item
                          key="ORNALI1"
                          onClick={() => submitProblemEvidence(record?.id, alertMessage)}
                        >
                          Submit Problem Evidence
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {/* Openreach NALI - Submit Solution Evidence */}
                      {getEntityNameFromRecord(record) === 'OpenreachProductOrderItem' ? (
                        <Menu.Item
                          key="ORNALI2"
                          onClick={() => submitSolutionEvidence(record?.id, alertMessage)}
                        >
                          Submit Solution Evidence
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {/* Openreach NALI - Submit Solution Evidence */}
                      {getEntityNameFromRecord(record) === 'OpenreachProductOrderItem' ? (
                        <Menu.Item
                          key="ORNALI3"
                          onClick={() => submitSPOCompletion(record?.id, alertMessage)}
                        >
                          Submit SPO Completion
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {/* ApplicationForPayment - Approval Screen */}
                      {getEntityNameFromRecord(record) === 'ApplicationForPayment' && (
                        <Menu.Item
                          key="AFP-Approval"
                          disabled={
                            !hasPermissions(userReducer, [
                              'projectmodule.applicationforpayment.approval',
                            ])
                          }
                          onClick={() =>
                            history.push(
                              `/ProjectModule/ApplicationForPayment/${record.id}/approval`,
                            )
                          }
                        >
                          Approve/Reject Items
                        </Menu.Item>
                      )}

                      {/* -------------------------------------------------------------------------- */}
                      <Divider style={{ margin: '10px 0' }} />

                      {/* MANAGE RECORD ACTIONS **********************/}
                      <div>
                        <Row style={{ padding: '5px 10px' }}>
                          <Col
                            span={24}
                            style={{
                              background: '#f2f2f2',
                              padding: '3px 10px',
                              borderRadius: 2,
                            }}
                          >
                            <span style={{ fontWeight: 600 }}>Manage Record</span>
                          </Col>
                        </Row>
                      </div>

                      <Menu.Item
                        disabled={
                          !!record?.dbRecordAssociation?.relatedAssociationId ||
                          !hasPermissions(userReducer, ['master.record.editor']) ||
                          !canUserUpdateRecord(userReducer, schema, record) ||
                          // ODN-1640 prevent editing if record is locked
                          (checkRecordIsLocked(record) &&
                            !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin')) ||
                          disableRelatedProductEdit
                        }
                        onClick={() => this.initializeUpdateForm()}
                      >
                        Edit
                      </Menu.Item>

                      <Menu.Item
                        disabled={
                          !canUserCloneRecord(userReducer, schema, record) ||
                          disableRelatedProductEdit
                        }
                        onClick={() => this.initializeCloneForm()}
                      >
                        Clone
                      </Menu.Item>
                      {record?.entity === 'FieldServiceModule:ServiceAppointment' ? (
                        <Menu.Item
                          disabled={
                            !canUserDeleteRecord(userReducer, schema, record) ||
                            // ODN-1640 prevent deleting if record is locked
                            (checkRecordIsLocked(record) &&
                              !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin'))
                          }
                          onClick={() => this.initializeDeleteAppointmentForm()}
                        >
                          Delete
                        </Menu.Item>
                      ) : (
                        <Menu.Item
                          disabled={
                            !canUserDeleteRecord(userReducer, schema, record) ||
                            // ODN-1640 prevent deleting if record is locked
                            (checkRecordIsLocked(record) &&
                              !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin')) ||
                            disableRelatedProductEdit
                          }
                        >
                          <Popconfirm
                            title="Are you sure you want to delete this record?"
                            onConfirm={() => this.deleteRecord()}
                            disabled={
                              !canUserDeleteRecord(userReducer, schema, record) ||
                              // ODN-1640 prevent deleting if record is locked
                              (checkRecordIsLocked(record) &&
                                !hasAnyRoles(userReducer, 'system.admin', 'BillingModuleAdmin'))
                            }
                            okText="Yes"
                            cancelText="No"
                          >
                            Delete
                          </Popconfirm>
                        </Menu.Item>
                      )}
                      {isSystemAdmin(userReducer) && (
                        <Menu.Item>
                          <Popconfirm
                            title="Are you sure you want to restore this record?"
                            onConfirm={() => this.restoreRecord()}
                            okText="Yes"
                            cancelText="No"
                          >
                            Restore
                          </Popconfirm>
                        </Menu.Item>
                      )}
                      {record?.entity === 'CrmModule:Address' ? (
                        <Menu.Item
                          disabled={!canUserCloneRecord(userReducer, schema, record)}
                          onClick={() => this.showSwapAddressModal()}
                        >
                          Migrate Records
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {hasColumnMappings && (
                        <Menu.Item>
                          <Link target="_blank" to={getBrowserPath(record)}>
                            Full View
                          </Link>
                        </Menu.Item>
                      )}
                      <Menu.Item
                        disabled={!canUserUpdateRecord(userReducer, schema, record)}
                        onClick={() => this.initializeProcessWorkflowForm(record)}
                      >
                        Process Workflow
                      </Menu.Item>

                      {/* Manage Groups on Record */}
                      <Menu.Item
                        key="menuItemViewAssignGroups"
                        disabled={!hasPermissions(userReducer, ['groups.assign'])}
                      >
                        {!hasPermissions(userReducer, ['groups.assign']) ? (
                          <span>Manage Groups</span>
                        ) : (
                          <span onClick={() => toggleAssignGroupModal()}>Manage Groups</span>
                        )}
                      </Menu.Item>

                      {/* View Schema */}
                      {isSystemAdmin(userReducer) && schema ? (
                        <Menu.Item key="menuItemViewSchema">
                          <Link to={`/${SCHEMA_MODULE}/Schema/${schema?.id}`} target="_blank">
                            View Schema
                          </Link>
                        </Menu.Item>
                      ) : (
                        <></>
                      )}
                    </Menu>
                  }
                >
                  <Button icon={<CaretDownFilled style={{ fontSize: '0.9em' }} />} />
                </Dropdown>
              </Col>
            </Row>
          }
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={2} style={{ marginBottom: isMobile ? '10px' : 0 }}>
              <Text className="fieldTitle">Record Number</Text>
              <br />

              {/* Record number should be clickable/link only on quick view drawer */}
              {recordReducer.showPreview ? (
                <Text strong>
                  <Link to={getBrowserPath(record!)} target="_blank">
                    {record?.recordNumber}
                  </Link>
                </Text>
              ) : (
                <Text strong>{record?.recordNumber}</Text>
              )}

              <Tooltip title="Copy link to record">
                <LinkOutlined
                  onClick={() => this.copyLinkToClipboard(record)}
                  style={{ marginLeft: '3px' }}
                />
              </Tooltip>
            </Col>

            <Col xs={24} sm={24} md={24} lg={2} style={{ marginBottom: isMobile ? '10px' : 0 }}>
              <Text className="fieldTitle">Record Type</Text>
              <br />
              <Text strong>{record?.type}</Text>
            </Col>

            <Col xs={24} sm={24} md={24} lg={12} style={{ marginBottom: isMobile ? '10px' : 0 }}>
              <Text className="fieldTitle">Record Title</Text>
              <br />
              <Text strong>{record?.title}</Text>
            </Col>

            <Col xs={24} sm={24} md={24} lg={3} style={{ marginBottom: isMobile ? '10px' : 0 }}>
              <Text className="fieldTitle">
                Created by: {record?.createdBy?.fullName ? record?.createdBy?.fullName : '-'}{' '}
              </Text>
              <br />
              <Text>{parseDateAndTimeLocal(record?.createdAt!.toString())}</Text>
              <br />
            </Col>

            <Col xs={24} sm={24} md={24} lg={3}>
              <Text className="fieldTitle">
                Updated by:{' '}
                {record?.lastModifiedBy?.fullName ? record?.lastModifiedBy?.fullName : '-'}
              </Text>
              <br />
              <Text>{parseDateAndTimeLocal(record?.updatedAt!.toString())}</Text>
            </Col>
          </Row>
        </Card>
      </div>
    );
  }

  private deleteRecord() {
    const {
      record,
      schemaReducer,
      deleteRecord,
      onDelete,
      recordReducer,
      deleteRecordAssociation,
      toggleQuickview,
    } = this.props;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    const dbRecordAssociationId = this.props.match.params.dbRecordAssociationId;

    if (schema) {
      // check if the path matches related product
      // if so, don't delete the product but association with related record
      // the record association id is extracted from the URL
      if (
        window.location.pathname.includes(`/${PRODUCT_MODULE}/${PRODUCT}/`) &&
        window.location.pathname.includes(`/related/`) &&
        dbRecordAssociationId
      ) {
        const parts = window.location.pathname.split('/');
        const dbRecordAssociationId = parts[parts.length - 2];
        const schemaAssociation = schema?.associations.find(
          (elem) => elem.label === 'PriceBook__Product',
        );
        deleteRecordAssociation(
          {
            schema: schema as SchemaEntity,
            schemaAssociation: schemaAssociation as SchemaAssociationEntity,
            dbRecordAssociationId: dbRecordAssociationId,
          },
          () => {
            window.history.back();
          },
        );
      } else {
        if (schema && record) {
          deleteRecord(
            {
              schema: schema,
              recordId: record.id,
            },
            () => {
              // Close quick view drawer if visible
              if (recordReducer.showPreview) {
                toggleQuickview();
              }

              if (onDelete) {
                return onDelete();
              } else {
                history.push(`/${schema.moduleName}/${schema.entityName}`);
              }
            },
          );
        }
      }
    }
  }

  private restoreRecord() {
    const { record, schemaReducer, restoreRecord } = this.props;

    if (record) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

      if (schema) {
        restoreRecord(
          {
            schema,
            recordId: record.id,
          },
          (res) => {
            return res;
          },
        );
      }
    }
  }

  private handleFormSubmit(params: { event: string; results: any }) {
    const { getRecord, getRelatedRecordById, record, schemaReducer } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    switch (params.event) {
      case DB_RECORD_ASSOCIATIONS_UPDATE_REQUEST:
        getRelatedRecordById({
          schema: schema,
          relatedEntityName: this.props.match?.params?.relatedRecordEntityName,
          recordId: record.id,
          dbRecordAssociationId: record?.dbRecordAssociation?.id,
        });
        break;
      case UPDATE_DB_RECORD_BY_ID_REQUEST:
        getRecord({ schema, recordId: params.results.id });
        break;
      case CREATE_DB_RECORD_REQUEST:
        history.push(`${getBrowserPath(params.results)}`);
        break;
    }
  }

  private initializeDeleteAppointmentForm() {
    const { record, initializeCancelAppointment } = this.props;
    initializeCancelAppointment({
      cancelModalVisible: true,
      cancelRelatedRecord: record,
      deleteFromDetail: true,
      schemaType: 'SA_CANCEL',
    });
  }

  private initializeProcessWorkflowForm(record: DbRecordEntityTransform) {
    const { initProcessWorkflowForm } = this.props;

    if (record) {
      const { entityName } = splitEntityToModuleAndEntity(String(record?.entity));
      if (entityName === SchemaModuleEntityTypeEnums.WORKFLOW) {
        initProcessWorkflowForm({
          workflowId: record.id,
          canChangeWorkflow: false,
          canChangeRecord: true,
        });
      } else {
        initProcessWorkflowForm({
          record,
          canChangeRecord: false,
          canChangeWorkflow: true,
        });
      }
    }
  }

  private copyLinkToClipboard(record: DbRecordEntityTransform) {
    const { alertMessage } = this.props;

    if (record) {
      const { moduleName, entityName } = getModuleAndEntityNameFromRecord(record);

      const hostName = window?.location?.host;
      const fullPath = `${hostName}/${moduleName}/${entityName}/${record?.id}`;
      navigator.clipboard.writeText(fullPath).then(() =>
        alertMessage({
          body: 'Link to record copied to clipboard!',
          type: 'success',
        }),
      );
    }
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  toggleQuickview: () => dispatch(toggleQuickView()),
  getUsers: (cb: any) => dispatch(listUsers(cb)),
  getPipelines: (params: { schema: SchemaEntity }) =>
    dispatch(getPipelinesByModuleAndEntity(params)),
  getRelatedRecordById: (payload: IGetRecordAssociationById) =>
    dispatch(getRecordAssociationByIdRequest(payload)),
  getRecord: (payload: IGetRecordById) => dispatch(getRecordByIdRequest(payload)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  restoreRecord: (payload: any, cb: any) => dispatch(restoreRecordByIdRequest(payload, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getAssociations: (params: IGetRecordAssociations, db: any) =>
    dispatch(getRecordAssociationsRequest(params, db)),
  initializeCancelAppointment: (params: any) => dispatch(initailizeCancelAppointmentModal(params)),
  initializeSwapAddress: (params: IInitializeSwapAddress) =>
    dispatch(initializeSwapAddress(params)),
  deleteRecordAssociation: (payload: IDeleteRecordAssociation, cb: any) =>
    dispatch(deleteRecordAssociationById(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  initProcessWorkflowForm: (params: any) => dispatch(initProcessWorkflowForm(params)),
  toggleAssignGroupModal: () => dispatch(toggleAssignRecordToGroupModal()),
});

export default withRouter(connect(mapState, mapDispatch)(RecordHeader));
