import { Section, Tag } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet } from '@core/http/requests';
import PoundSymbol from '@core/components/PoundSymbol';
import Typography from '@core/components/Typography';
import InvoiceOverviewDiscountList from './InvoiceOverviewDiscountList';
import InvoiceOverviewInvoiceItemList from './InvoiceOverviewInvoiceItemList';
import './styles.scss';

interface Props {
  schemaReducer: any;
  record: DbRecordEntityTransform;
}

const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { INVOICE, INVOICE_ITEM, DISCOUNT, BILLING_ADJUSTMENT } = SchemaModuleEntityTypeEnums;

const InvoiceOverview: React.FC<Props> = (props: Props) => {
  const { record } = props;
  const [isLoadingRelations, setIsLoadingRelations] = useState<boolean>(false);
  const [invoiceItems, setInvoiceItems] = useState<DbRecordEntityTransform[]>([]);
  const [discounts, setDiscounts] = useState<DbRecordEntityTransform[]>([]);
  const [billingAdjustments, setBillingAdjustments] = useState<DbRecordEntityTransform[]>([]);

  useEffect(() => {
    setIsLoadingRelations(true);
    if (record) {
      httpGet(
        `${BILLING_MODULE}/v1.0/db-associations/${INVOICE}/${record?.id}/relations?entity=${INVOICE_ITEM}&withLinks=false`,
        {
          entities: [INVOICE_ITEM, DISCOUNT, BILLING_ADJUSTMENT],
        },
      )
        .then((res) => {
          setInvoiceItems(res?.data?.data[INVOICE_ITEM]?.dbRecords || []);
          setBillingAdjustments(res?.data?.data[BILLING_ADJUSTMENT]?.dbRecords || []);
          setDiscounts(res?.data?.data[DISCOUNT]?.dbRecords || []);
          setIsLoadingRelations(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoadingRelations(false);
          setInvoiceItems([]);
        });
    }
  }, [record]);

  const renderTagColor = (status: string) => {
    // success = green
    // primary = blue
    // warning = orange
    // danger = red
    const success = ['PAID', 'REFUNDED', 'SCHEDULED'];
    const primary = ['DRAFT'];
    const danger = ['ERROR', 'VOID', 'DUNNING', 'BAD_DEBT'];
    const warning = [
      'APPROVAL_PENDING',
      'CREDITED',
      'PARTIAL',
      'PAUSED',
      'PAYMENT_PENDING',
      'REFUND_PENDING',
    ];

    if (success.includes(status)) {
      return 'success';
    } else if (warning.includes(status)) {
      return 'warning';
    } else if (primary.includes(status)) {
      return 'primary';
    } else if (danger.includes(status)) {
      return 'danger';
    } else {
      return undefined;
    }
  };

  return (
    <Section style={{ marginBottom: 15 }} className="invoiceOverviewSection">
      <div style={{ padding: 15 }}>
        {/* Header */}
        <Row justify="space-between" align="middle">
          <Col>
            <Typography style={{ fontSize: 20 }} stronger>
              {record?.recordNumber}
            </Typography>
          </Col>
          <Col>
            <Tag round intent={renderTagColor(getProperty(record, 'Status'))} minimal large>
              {getProperty(record, 'Status')}
            </Tag>
          </Col>
        </Row>
        {/* Dates */}
        <Row justify="end" style={{ margin: '30px 0 20px 0' }}>
          <Col xs={24} lg={20} xl={12}>
            {/* Issued Date */}
            <Row style={{ marginBottom: 7 }}>
              <Col span={18} style={{ textAlign: 'right' }}>
                <Typography stronger>Issued Date:</Typography>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography>
                  {getProperty(record, 'IssuedDate')
                    ? dayjs(getProperty(record, 'IssuedDate')).format('DD/MM/YYYY')
                    : 'No Date'}
                </Typography>
              </Col>
            </Row>
            {/* Due Date */}
            <Row style={{ marginBottom: 7 }}>
              <Col span={18} style={{ textAlign: 'right' }}>
                <Typography stronger>Due Date:</Typography>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography>
                  {getProperty(record, 'DueDate')
                    ? dayjs(getProperty(record, 'DueDate')).format('DD/MM/YYYY')
                    : 'No Date'}
                </Typography>
              </Col>
            </Row>

            {/* Billing Period */}
            <Row style={{ marginBottom: 7 }}>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Typography stronger>Billing Period:</Typography>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Typography>
                  {getProperty(record, 'BillingPeriodStart')
                    ? dayjs(getProperty(record, 'BillingPeriodStart')).format('DD/MM/YYYY')
                    : 'No Date'}
                </Typography>{' '}
                -{' '}
                <Typography>
                  {getProperty(record, 'BillingPeriodEnd')
                    ? dayjs(getProperty(record, 'BillingPeriodEnd')).format('DD/MM/YYYY')
                    : 'No Date'}
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Invoice Items */}
        <InvoiceOverviewInvoiceItemList
          invoiceItems={invoiceItems}
          isLoading={isLoadingRelations}
        />

        {/* Discount List */}
        <InvoiceOverviewDiscountList discounts={discounts} isLoading={isLoadingRelations} />

        {/* Invoice Summary */}
        <Row justify="end" style={{ margin: '30px 0 10px 0' }}>
          <Col span={12}>
            {/* Subtotal */}
            <Row style={{ marginBottom: 7 }}>
              <Col span={18} style={{ textAlign: 'right' }}>
                <Typography stronger>Subtotal:</Typography>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography>
                  <PoundSymbol>{getProperty(record, 'Subtotal')}</PoundSymbol>
                </Typography>
              </Col>
            </Row>
            {/* Total Discounts */}
            <Row style={{ marginBottom: 7 }}>
              <Col span={18} style={{ textAlign: 'right' }}>
                <Typography stronger>Total Discounts:</Typography>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography>
                  <PoundSymbol>{getProperty(record, 'TotalDiscounts')}</PoundSymbol>
                </Typography>
              </Col>
            </Row>

            {/* Total Tax Amount */}
            <Row style={{ marginBottom: 7 }}>
              <Col span={18} style={{ textAlign: 'right' }}>
                <Typography stronger>Total Tax Amount:</Typography>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography>
                  <PoundSymbol>{getProperty(record, 'TotalTaxAmount')}</PoundSymbol>
                </Typography>
              </Col>
            </Row>

            {/* Total Price */}
            <Row style={{ marginBottom: 7 }}>
              <Col span={18} style={{ textAlign: 'right' }}>
                <Typography stronger>Total Due:</Typography>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography>
                  <PoundSymbol>{getProperty(record, 'TotalDue') || '0.00'}</PoundSymbol>
                </Typography>
              </Col>
            </Row>

            {/* Balance */}
            <Row style={{ marginBottom: 0, marginTop: 25 }}>
              <Col span={18} style={{ textAlign: 'right' }}>
                <Typography stronger style={{ fontSize: 18 }}>
                  Balance:
                </Typography>
              </Col>
              <Col span={6} style={{ textAlign: 'right' }}>
                <Typography stronger style={{ fontSize: 18, fontWeight: 600 }}>
                  <PoundSymbol>{getProperty(record, 'Balance') || '0.00'}</PoundSymbol>
                </Typography>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </Section>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(InvoiceOverview);
