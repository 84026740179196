import {
  DbRecordAssociationCreateUpdateDto,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import {
  RelationTypeEnum,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  SchemaAssociationEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import AssociationBuilder from '@legacy/core/records/components/AssociationBuilder';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
  updateOrCreateRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { httpGet } from '../../../../../core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '../../../../../core/helpers/schemaHelpers';

interface Props {
  record: DbRecordEntityTransform;
  createAssociations: Function;
  schemaReducer: any;
  getSchema: Function;
  alertMessage: Function;
  getAssociations: Function;
}

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE } = SchemaModuleEntityTypeEnums;

const PIAAssociationBuilder: FC<Props> = (props: Props) => {
  const { record, createAssociations, schemaReducer, getSchema, alertMessage, getAssociations } =
    props;

  const [associatedRecords, setAssociatedRecords] = useState<DbRecordEntityTransform[]>([]);
  const [existingAssociations, setExistingAssociations] = useState<DbRecordEntityTransform[]>([]);
  const [isLoadingExistingAssociations, setIsLoadingExistingAssociations] =
    useState<boolean>(false);
  const [isLoadingAssociations, setIsLoadingAssociations] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  // On component mount get associations
  useEffect(() => {
    if (record) {
      getAssociatedPIAs();
      getExistingAssociations();
    }
  }, []);

  const getExistingAssociations = () => {
    const shortlistSchema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record.schemaId,
    );

    getAssociations(
      {
        recordId: record?.id,
        schema: shortlistSchema,
        entities: [FEATURE],
      },
      (res: any) => {
        if (res && res.results?.[FEATURE]?.dbRecords?.length > 0) {
          const PIAFeatures = res.results?.[FEATURE]?.dbRecords.filter(
            (rec: DbRecordEntityTransform) => rec.type!.indexOf('PIA_') > -1,
          );
          if (PIAFeatures.length > 0) {
            setExistingAssociations(PIAFeatures);
          }
        }
      },
    );
  };

  // This will run on component mount and retrieve all associated PIA features for the main feature
  const getAssociatedPIAs = async () => {
    setIsLoadingAssociations(true);
    return await httpGet(`ProjectModule/v1.0/cst/Feature/pia-features/${record.id}`)
      .then(async (res: any) => {
        setIsLoadingAssociations(false);
        if (res?.data?.data?.length > 0) {
          setAssociatedRecords(res?.data?.data);
        }
      })
      .catch((err: any) => {
        setIsLoadingAssociations(false);
        alertMessage({ body: 'There was an error loading PIA Features.', type: 'error' });
      });
  };

  // On Save get all selected records and associate with main record
  const onSave = (selectedRecords: DbRecordEntityTransform[]) => {
    let body: DbRecordAssociationCreateUpdateDto[] = [];
    setIsSaving(true);

    for (const selectedRecord of selectedRecords) {
      body.push({
        entity: `${PROJECT_MODULE}:${FEATURE}`,
        recordId: selectedRecord.id,
        relatedAssociationId: selectedRecord?.dbRecordAssociation?.id,
        relationType: RelationTypeEnum.CHILD,
      });
    }

    // Get main record schema and create associations
    const moduleName = record.entity?.split(':')[0];
    const entityName = record.entity?.split(':')[1];
    const shortListMainRecordSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      moduleName,
      entityName,
    );
    if (shortListMainRecordSchema) {
      const schemaAssociation = shortListMainRecordSchema.associations?.find(
        (assoc: SchemaAssociationEntity) => assoc.label === 'Feature__Feature',
      );
      createAssociations(
        {
          recordId: record.id,
          schema: shortListMainRecordSchema,
          schemaAssociation,
          createUpdate: body,
        },
        () => {
          setIsSaving(false);
        },
      );
    } else {
      getSchema(
        { moduleName: moduleName, entityName: entityName },
        (responseSchema: SchemaEntity) => {
          if (responseSchema) {
            const schemaAssociation = responseSchema.associations?.find(
              (assoc: SchemaAssociationEntity) => assoc.label === 'Feature__Feature',
            );
            createAssociations(
              {
                recordId: record.id,
                responseSchema,
                schemaAssociation,
                createUpdate: body,
              },
              () => {
                setIsSaving(false);
              },
            );
          }
        },
      );
    }
  };

  return (
    <AssociationBuilder
      mainRecord={record}
      associatedRecords={associatedRecords}
      associationLoading={isLoadingAssociations}
      existingAssociations={existingAssociations}
      onSave={onSave}
      isSaving={isSaving}
      associationTopTitle="PIA Features"
      mainRecordTopTitle={`Blockage ${record?.title}`}
      truncateAssociations={isMobile ? 5 : 20}
      customRecordRowTitleElements={['title', 'recordNumber', 'type']}
      associatedModuleName={PROJECT_MODULE}
      associatedEntityName={FEATURE}
      associationQuickSearchPlaceholder="Enter QGIS ID to search ..."
      mainRecordQuickSearchPlaceholder="Enter QGIS ID to search ..."
      hideSelectedRecords
    />
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  createAssociations: (params: any, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(PIAAssociationBuilder);
