import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Menu, MenuItem, Tooltip } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { Badge, Col, Row, Skeleton } from 'antd';
import State from 'ol/source/State';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import RecordStageChangeDialog from '../SchemaActions/RecordStageChangeDialog';
import './styles.scss';

interface Props {
  className?: string;
  flat?: boolean;
  onSuccess?: Function;
  record: DbRecordEntityTransform;
  small?: boolean;
  stageKey?: string;
  styles?: React.CSSProperties;
  pipeline: PipelineEntity | undefined;
}

interface State {
  pipeline: PipelineEntity | undefined;
  hoveredStage: string | undefined;
  stageKey: string | undefined;
}

// V2 Pipeline component uses pipeline passed as an external prop. We use this
// component on record detail views, since there we pass pipelines along with record and schema.
class RecordStageSequentialPipeline_V2 extends React.Component<Props, State> {
  private pipelineContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);

    this.state = {
      pipeline: undefined,
      hoveredStage: undefined,
      stageKey: undefined,
    };
  }

  componentDidMount(): void {
    if (this.props.pipeline) {
      this.setState({ pipeline: this.props.pipeline });
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.pipeline !== this.props.pipeline) {
      this.setState({ pipeline: this.props.pipeline });
    }
  }

  private getPipelineState(elem: PipelineStageEntity) {
    const { record } = this.props;
    if (record && record.stage?.id === elem?.id && !elem.isFail) {
      return 'active';
    } else if (record && record.stage?.id === elem?.id && elem.isFail) {
      return 'failed';
    } else if (record && record.stage && record.stage.position > elem.position) {
      return 'complete';
    } else {
      return 'inactive';
    }
  }

  private sortColumns(stage1: PipelineStageEntity, stage2: PipelineStageEntity) {
    if (stage1.position && stage2.position) {
      return stage1.position - stage2.position;
    } else {
      return 0;
    }
  }

  // Render Badge or Icon in the mobile Pipeline Select component
  renderBadgeOrIconInSelectBox = (elem: any) => {
    const status = this.getPipelineState(elem);

    switch (status) {
      case 'active':
        return <Badge color="blue" className="compactPipelineBadge" />;
      case 'failed':
        return <CloseOutlined style={{ color: 'red', marginRight: 5 }} />;
      case 'complete':
        return <CheckOutlined style={{ color: 'green', marginRight: 5 }} />;
      case 'inactive':
        return <Badge color="grey" className="compactPipelineBadge" />;
      default:
        return <Badge color="grey" className="compactPipelineBadge" />;
    }
  };

  renderStageIntent = (stage: PipelineStageEntity) => {
    if (stage.isFail) {
      return 'danger';
    } else if (stage.isSuccess) {
      return 'success';
    } else {
      return 'primary';
    }
  };

  // Stage Change Actions - get all stages from a pipeline, filter out stages if
  // the current stage is not allowed to move to that stage, and remove the
  // stage where the record is currently at
  pipelineStageActions = (mode: 'MENU' | 'BUTTON'): JSX.Element[] => {
    const { record } = this.props;
    const { pipeline } = this.state;

    if (pipeline && pipeline?.stages?.length! > 0) {
      const currentStage = pipeline?.stages?.find(
        (elem: PipelineStageEntity) => elem.key === record?.stage?.key,
      );

      return (
        currentStage?.allowedNextStages?.map((elem: PipelineStageEntity, index: number) => {
          return mode === 'MENU' ? (
            <MenuItem
              key={index}
              intent={this.renderStageIntent(elem)}
              text={elem.name}
              onClick={() => this.setState({ stageKey: elem.key })}
            />
          ) : (
            <Button
              fill
              key={index}
              text={elem.name}
              intent={this.renderStageIntent(elem)}
              onClick={() => this.setState({ stageKey: elem.key })}
              style={{
                marginBottom: index === currentStage?.allowedNextStages?.length! - 1 ? 0 : 10,
              }}
            />
          );
        }) || []
      );
    } else {
      return [];
    }
  };

  render() {
    const { small = false, record } = this.props;
    const { pipeline } = this.state;

    return (
      <>
        <RecordStageChangeDialog
          openDialog={this.state.stageKey !== undefined}
          record={record}
          targetStage={this.state.stageKey}
          onClose={() => {
            this.setState({ stageKey: undefined });
          }}
          onConfirm={() => {
            this.setState({ stageKey: undefined });
          }}
        />

        {/* Pipeline Loader */}
        {!pipeline && !isMobile ? (
          <Row style={{ height: 32, maxHeight: 32, padding: 0, margin: 0 }}>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Skeleton paragraph={{ rows: 1 }} style={{ marginTop: -12 }} active />
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {pipeline && (
          <Row
            style={this.props?.styles || { marginTop: 12, marginLeft: 12, marginRight: 12 }}
            className={`PipelineContainer ${this.props.flat ? 'Flat' : ''}`}
          >
            <Col
              xs={24}
              sm={21}
              style={{
                backgroundColor: 'white',
                borderRadius: 8,
                padding: this.props.flat ? 0 : 7,
              }}
            >
              {/* DESKTOP Pipeline */}
              <div
                className="nav-progress"
                ref={this.pipelineContainerRef}
                style={{ display: isMobile || small ? 'none' : 'auto' }}
              >
                {pipeline && pipeline?.stages ? (
                  pipeline.stages
                    .sort((stage1: PipelineStageEntity, stage2: PipelineStageEntity) =>
                      this.sortColumns(stage1, stage2),
                    )
                    .map((elem: PipelineStageEntity, index: number) => (
                      <div
                        key={index}
                        onMouseEnter={() => this.setState({ hoveredStage: elem.name })}
                        onMouseLeave={() => this.setState({ hoveredStage: undefined })}
                        className={`${this.getPipelineState(elem)} 
                      ${pipeline?.stages?.length! > 5 ? 'hoverable' : ''}`}
                        style={{
                          opacity: 1,
                          width:
                            this.pipelineContainerRef?.current?.clientWidth! /
                            pipeline?.stages?.length!,
                          paddingLeft:
                            (isMobile || small || pipeline?.stages?.length! > 5) &&
                            this.getPipelineState(elem) !== 'active' &&
                            this.getPipelineState(elem) !== 'inactive'
                              ? 10
                              : 30,
                        }}
                      >
                        {(isMobile || small || pipeline?.stages?.length! > 5) &&
                        this.getPipelineState(elem) !== 'active' &&
                        this.state.hoveredStage !== elem.name &&
                        this.getPipelineState(elem) !== 'inactive' ? (
                          <CheckOutlined style={{ marginLeft: 20 }} />
                        ) : (
                          <div
                            className="stageTitle"
                            style={{ fontSize: pipeline?.stages?.length! > 5 ? '0.9em' : '1em' }}
                          >
                            {elem?.name?.length < 12 || this.state.hoveredStage === elem.name
                              ? elem?.name
                              : '...'}
                          </div>
                        )}
                        {pipeline?.stages?.length && index < pipeline?.stages?.length ? (
                          <div className="arrow-wrapper">
                            <div className="arrow-cover">
                              <div className="arrow"></div>
                            </div>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ))
                ) : (
                  <></>
                )}
              </div>

              {/* MOBILE Pipeline */}
              <div
                style={{ display: (isMobile || small) && pipeline ? 'block' : 'none', padding: 8 }}
              >
                <Row>
                  <Col span={24}>
                    <span style={{ fontWeight: 500 }}>Record Stage</span>
                  </Col>
                  <Col span={24} style={{ marginTop: 15 }}>
                    <Tooltip content="This is the current stage" position="top" fill>
                      <Button
                        text={record.stage?.name}
                        fill
                        style={{
                          marginBottom: this.pipelineStageActions('BUTTON').length === 0 ? 0 : 10,
                        }}
                        disabled
                      />
                    </Tooltip>
                    {this.pipelineStageActions('BUTTON')}
                  </Col>
                </Row>
              </div>
            </Col>

            <Col xs={24} sm={3} style={{ display: isMobile || small ? 'none' : 'block' }}>
              <Menu
                style={{
                  margin: '2px',
                }}
              >
                <MenuItem
                  disabled={!this.state.pipeline || this.pipelineStageActions('MENU').length === 0}
                  intent="primary"
                  roleStructure="listoption"
                  text="Change Stage"
                  children={this.pipelineStageActions('MENU')}
                />
              </Menu>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(RecordStageSequentialPipeline_V2);
