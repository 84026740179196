import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Badge, Button, Col, Divider, Empty, Input, Row, Spin, Tabs } from 'antd';
import Search from 'antd/lib/input/Search';
import { FC, useContext, useState } from 'react';
import { DataSetBuilderContext } from '../../index';
import RecordList from '@legacy/core/records/components/RecordList';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import {
  ADD_DATASET_RECORD_TO_DATASET_SELECTION,
  REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION,
  SET_ACTIVE_STATUS_TAB,
} from '../../store/constants';
interface Props {}

const { CRM_MODULE } = SchemaModuleTypeEnums;
const { ADDRESS } = SchemaModuleEntityTypeEnums;

const DataSetContents: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(DataSetBuilderContext);
  const [dataSetSearchTerm, setDataSetSearchTerm] = useState<string>('');

  const isDataSetRecordSelected = (record: DbRecordEntityTransform) => {
    return state.selectedDataSetRecords.find(
      (rec: DbRecordEntityTransform) => rec.id === record.id,
    );
  };

  const addOrRemoveRecordFromDataSetSelection = (record: DbRecordEntityTransform) => {
    if (isDataSetRecordSelected(record)) {
      dispatch({ type: REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION, payload: record });
    } else {
      dispatch({ type: ADD_DATASET_RECORD_TO_DATASET_SELECTION, payload: record });
    }
  };

  // Filtering for quick search query
  let filteredResults = Object.assign(state.dataSetRecords);

  if (dataSetSearchTerm) {
    filteredResults = filteredResults.filter(
      (rec: DbRecordEntityTransform) =>
        rec.title!.toLowerCase().indexOf(dataSetSearchTerm.toLowerCase()) > -1,
    );
  }

  // Filter dataset records with status group
  filteredResults = filteredResults.filter(
    (rec: DbRecordEntityTransform) =>
      (state.activeStatusTab === 'TO_DO' && !getProperty(rec, 'DS_Status')) ||
      getProperty(rec, 'DS_Status') === state.activeStatusTab,
  );

  // If dataset records are addresses, sort them by PostalCode
  if (
    filteredResults.length > 0 &&
    filteredResults?.every(
      (rec: DbRecordEntityTransform) => rec.entity === `${CRM_MODULE}:${ADDRESS}`,
    )
  ) {
    filteredResults = filteredResults.sort((a: any, b: any) =>
      getProperty(a, 'PostalCode') > getProperty(b, 'PostalCode')
        ? 1
        : getProperty(b, 'PostalCode') > getProperty(a, 'PostalCode')
        ? -1
        : 0,
    );
  }

  const getRecordCountForStatus = (status: string) => {
    const records = state.dataSetRecords?.filter(
      (rec: DbRecordEntityTransform) =>
        (status === 'TO_DO' && !getProperty(rec, 'DS_Status')) ||
        getProperty(rec, 'DS_Status') === status,
    );
    return records?.length;
  };

  return (
    <>
      <Row
        style={{
          height: '70vh',
          overflowY: 'scroll',
        }}
      >
        <Col span={24}>
          {state.dataSetRecords.length > 0 ? (
            <div>
              <Row>
                {/* Status Tabs */}
                <Col span={24}>
                  <Tabs
                    defaultActiveKey="1"
                    centered
                    activeKey={state.activeStatusTab}
                    onChange={(e: any) => dispatch({ type: SET_ACTIVE_STATUS_TAB, payload: e })}
                  >
                    {state.allRecordStatuses!.length > 0 ? (
                      state.allRecordStatuses?.map((status: any) => (
                        <Tabs.TabPane
                          tab={
                            <>
                              <Badge
                                count={getRecordCountForStatus(status)}
                                showZero
                                style={{
                                  backgroundColor:
                                    getRecordCountForStatus(status) > 0 ? '#ddeeff' : '#f0f0f0',
                                  color: 'black',
                                  marginBottom: 2,
                                  marginRight: 5,
                                }}
                              />
                              {toSentenceCase(status)}
                            </>
                          }
                          key={String(status)}
                        />
                      ))
                    ) : (
                      <></>
                    )}
                  </Tabs>
                </Col>

                <Col span={24} style={{ textAlign: 'right', paddingRight: 13 }}>
                  <Search
                    style={{ borderRadius: 4 }}
                    allowClear
                    value={dataSetSearchTerm}
                    onChange={(e: any) => {
                      setDataSetSearchTerm(e?.target?.value);
                    }}
                    onSearch={(e: any) => {
                      setDataSetSearchTerm(e?.target?.value);
                    }}
                    placeholder={`Quick Search on Title`}
                    disabled={state.dataSetRecords.length === 0}
                  />
                </Col>
              </Row>
              <Divider style={{ marginTop: 10, marginBottom: 10 }} />
              <div style={{ paddingRight: 15 }}>
                <RecordList
                  multipleSelect
                  selectedRecords={state.selectedDataSetRecords}
                  disabledRecords={[]}
                  allRecords={filteredResults}
                  onRecordSelect={addOrRemoveRecordFromDataSetSelection}
                />
              </div>
            </div>
          ) : state.isFetchingRelatedRecords ? (
            <div style={{ marginTop: '20vh', textAlign: 'center' }}>
              <Spin size="large" style={{ marginBottom: 20 }} />
              <br />
              <span>Loading DataSet Records...</span>
            </div>
          ) : (
            <Empty style={{ marginTop: '20vh' }} description="DataSet is Empty" />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DataSetContents;
