import { Col, Row } from 'antd';
import React from 'react';
import './styles.scss';

const AgentTypingMessage: React.FC = () => {
  return (
    <Row key="agentTypingIndicator" justify="start" style={{ padding: '13px 22px 13px 18px' }}>
      <Row className="myCasesAgentTypingMessage">
        {/* Header */}
        <Col span={24} style={{ minWidth: 250 }}>
          <i className="bi bi-person-circle" style={{ marginRight: 5 }} />
          <span>Agent is typing ...</span>
        </Col>
      </Row>
    </Row>
  );
};

export default AgentTypingMessage;
