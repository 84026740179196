import {
  Alert,
  Button,
  Collapse,
  Divider,
  EntityTitle,
  Menu,
  MenuDivider,
  MenuItem,
  Popover,
  Section,
  Spinner,
} from '@blueprintjs/core';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { httpDelete, httpGet, httpPut } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import UpdateCompanySelect from '../../Companies/UpdateCompanySelect';
import UpdateDepartmentSelect from '../../Departments/UpdateDepartmentSelect';
import UpdateRoleSelect from '../../Roles/UpdateRoleSelect';
import UpdateTeamSelect from '../../Teams/UpdateTeamSelect';
import { editTemplateFormFields } from '../FormFields';
import ManageTemplateGroupsDialog from '../ManageTemplateGroupsDialog';
import OnboardUsersByEmailDrawer from '../OnboardUsersByEmailDrawer';
import { getErrorMessage } from '../../../utils/errors';

interface Props {
  template: any;
  initializeForm: (params: any) => void;
  onUpdate: () => void;
  onDelete: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
  userReducer: any;
}

const TemplateDetails: FC<Props> = (props: Props) => {
  const { template, onUpdate, initializeForm, alertMessage, onDelete, userReducer } = props;
  const [isShowingFullDetails, setIsShowingFullDetails] = useState<boolean>(true);
  const [isDeleteTemplateAlertVisible, setIsDeleteTemplateAlertVisible] = useState<boolean>(false);
  const [copiedIdToClipboard, setCopiedIdToClipboard] = useState<boolean>(false);
  const [isOnboardingDrawerVisible, setIsOnboardingDrawerVisible] = useState<boolean>(false);

  const [role, setRole] = useState<any>(undefined);
  const [team, setTeam] = useState<any>(undefined);
  const [company, setCompany] = useState<any>(undefined);
  const [department, setDepartment] = useState<any>(undefined);

  const [groups, setGroups] = useState<any[]>([]);

  const [isLoadingRole, setIsLoadingRole] = useState<boolean>(false);
  const [isLoadingTeam, setIsLoadingTeam] = useState<boolean>(false);
  const [isLoadingCompany, setIsLoadingCompany] = useState<boolean>(false);
  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false);

  const [isLoadingDepartment, setIsLoadingDepartment] = useState<boolean>(false);

  useEffect(() => {
    if (template) {
      setGroups([]);
      setRole(undefined);
      setTeam(undefined);
      getAssociatedGroups(template.id);
    }

    if (template?.team?.id) {
      getAssociatedTeam(template.team?.id);
    } else {
      setTeam(undefined);
    }

    if (template?.role?.id) {
      getAssociatedRole(template.role?.id);
    } else {
      setRole(undefined);
    }

    if (template?.company?.id) {
      getAssociatedCompany(template.company?.id);
    } else {
      setCompany(undefined);
    }

    if (template?.department?.id) {
      getAssociatedDepartment(template.department?.id);
    } else {
      setDepartment(undefined);
    }
  }, [template]);

  const getAssociatedRole = async (roleId: string) => {
    setIsLoadingRole(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/roles/${roleId}`);
      const role = res.data?.data;
      if (role) {
        setRole(role);
      }
      setIsLoadingRole(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated role. ' + message, type: 'error' });
      setIsLoadingRole(false);
      setRole(undefined);
    }
  };

  const getAssociatedTeam = async (teamId: string) => {
    setIsLoadingTeam(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/teams/${teamId}`);
      const team = res.data?.data;
      if (team) {
        setTeam(team);
      }
      setIsLoadingTeam(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated team. ' + message, type: 'error' });
      setIsLoadingTeam(false);
      setTeam(undefined);
    }
  };

  const getAssociatedCompany = async (teamId: string) => {
    setIsLoadingCompany(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/companies/${teamId}`);
      const company = res.data?.data;
      if (company) {
        setCompany(company);
      }
      setIsLoadingCompany(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated company. ' + message, type: 'error' });
      setIsLoadingCompany(false);
      setCompany(undefined);
    }
  };

  const getAssociatedDepartment = async (departmentId: string) => {
    setIsLoadingDepartment(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/departments/${departmentId}`);
      const department = res.data?.data;
      if (department) {
        setDepartment(department);
      }
      setIsLoadingDepartment(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated department. ' + message, type: 'error' });
      setIsLoadingDepartment(false);
      setDepartment(undefined);
    }
  };

  const getAssociatedGroups = async (templateId: string) => {
    setIsLoadingGroups(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/onboarding-templates/${templateId}/groups`);
      const groups = res.data?.data;
      if (groups) {
        setGroups(groups);
      }
      setIsLoadingGroups(false);
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Could not fetch associated groups. ' + message, type: 'error' });
      setIsLoadingGroups(false);
    }
  };

  const showEditTemplateForm = () => {
    const tempEl: any = template;
    let editForm: any[] = [];

    editTemplateFormFields.forEach((element: any) => {
      editForm.push({
        allowClear: element.allowClear,
        allowSearch: element.allowSearch,
        customValidation: element.customValidation,
        customValidationMessage: element.customValidationMessage,
        initialValue: tempEl[element.property],
        isDisabled: element.isDisabled,
        isHidden: element.isHidden,
        isRequired: element.isRequired,
        label: element.label,
        message: element.message,
        options: element.options,
        property: element.property,
        type: element.type,
        value: tempEl[element.property],
      });
    });

    initializeForm({
      formUUID: template.id,
      title: 'Edit Template',
      recordId: template?.id,
      showModal: true,
      formFields: editForm,
      entityName: 'Template',
      isUpdateReq: true,
      columns: 1,
    });
  };

  const handleFormSubmit = (params: any) => {
    if (params.title === 'Edit Template') {
      editTemplate(params.data);
    }
  };

  const editTemplate = async (data: any) => {
    try {
      await httpPut(`IdentityModule/v2.0/onboarding-templates/${template.id}`, data);
      alertMessage({ body: 'Template updated', type: 'success' });
      onUpdate();
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Template could not be updated. ' + message, type: 'error' });
    }
  };

  const deleteTemplate = async () => {
    try {
      await httpDelete(`IdentityModule/v1.0/users/onboarding-templates/${template.id}`);
      alertMessage({ body: 'Template deleted', type: 'success' });
      onDelete();
    } catch (e: any) {
      const message = getErrorMessage(e);
      alertMessage({ body: 'Template could not be deleted. ' + message, type: 'error' });
    }
  };

  const copyIdToClipboard = (id: any) => {
    navigator.clipboard.writeText(id);
    setCopiedIdToClipboard(true);
    setTimeout(() => {
      setCopiedIdToClipboard(false);
    }, 1500);
  };

  const toggleOnboardingDrawer = () => {
    setIsOnboardingDrawerVisible(!isOnboardingDrawerVisible);
  };

  const ActionsMenu = () => {
    return (
      <Menu>
        {/* Onboard Users */}
        <MenuItem text="Onboard Users" icon="people" onClick={toggleOnboardingDrawer} />

        <MenuDivider />

        {/* Delete Template */}
        <MenuItem
          text="Delete Template"
          icon="trash"
          disabled
          intent="danger"
          onClick={() => setIsDeleteTemplateAlertVisible(true)}
        />
      </Menu>
    );
  };

  return (
    <>
      <Row style={{ padding: 15 }}>
        <OnboardUsersByEmailDrawer
          template={template}
          isDrawerOpen={isOnboardingDrawerVisible}
          toggleDrawer={toggleOnboardingDrawer}
        />

        <SharedFormModal
          formUUID={template.id}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />

        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <h3 style={{ marginTop: 0, marginBottom: 5 }}>{template.name}</h3>
              <span>{template.description}</span>
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ marginTop: 20, marginBottom: 0 }}>
          <Row gutter={8}>
            <Col span={12}>
              <Popover content={<ActionsMenu />} placement="bottom" fill>
                <Button rightIcon="caret-down" text="Actions" fill />
              </Popover>
            </Col>

            <Col span={12}>
              <Button
                text="Edit Template"
                fill
                icon="annotation"
                onClick={(e: any) => {
                  showEditTemplateForm();
                }}
              />
            </Col>
          </Row>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Company */}
        <Col span={24} className="detailViewSection">
          <Section title="Company">
            <Row>
              <Col span={24}>
                <UpdateCompanySelect
                  entity="onboarding-templates"
                  defaultCompany={company}
                  sourceRecord={template}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/* Department */}
        <Col span={24} className="detailViewSection">
          <Section title="Department">
            <Row>
              <Col span={24}>
                <UpdateDepartmentSelect
                  entity="onboarding-templates"
                  defaultDepartment={department}
                  sourceRecord={template}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/*  Team */}
        <Col span={24} className="detailViewSection">
          <Section title="Team">
            <Row>
              <Col span={24}>
                <UpdateTeamSelect
                  entity="onboarding-templates"
                  defaultTeam={team}
                  sourceRecord={template}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        {/*  Role */}
        <Col span={24} className="detailViewSection">
          <Section title="Role">
            <Row>
              <Col span={24}>
                <UpdateRoleSelect
                  entity="onboarding-templates"
                  defaultRole={role}
                  sourceRecord={template}
                />
              </Col>
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Groups */}
        <Col span={24} className="detailViewSection">
          <Section
            title={`Groups ${groups.length ? `(${groups.length})` : ''}`}
            rightElement={
              <ManageTemplateGroupsDialog template={template} groups={groups} onUpdate={onUpdate} />
            }
          >
            <Row>
              {/* Groups list */}
              {groups.slice(0, 3)?.map((group: any) => (
                <Col span={24} key={group.id}>
                  <EntityTitle key={group.id} icon="people" title={group.name} />
                </Col>
              ))}
              {/* Show how many more Groups  */}
              {groups.length > 3 && (
                <Col span={24} style={{ marginTop: 8 }}>
                  <span>{`+ ${groups.length - 3} more`}</span>
                </Col>
              )}
              {!groups.length && (
                <Col span={24}>
                  <span>
                    {isLoadingGroups ? (
                      <Spinner size={15} style={{ justifyContent: 'start' }} />
                    ) : (
                      'None'
                    )}
                  </span>
                </Col>
              )}
            </Row>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Role ID */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Template Id"
            rightElement={
              <Button
                minimal
                small
                rightIcon={copiedIdToClipboard ? 'tick' : null}
                text={copiedIdToClipboard ? 'Copied' : 'Copy'}
                intent={copiedIdToClipboard ? 'success' : 'primary'}
                onClick={() => copyIdToClipboard(template.id)}
              />
            }
          >
            <span>{template.id}</span>
          </Section>
        </Col>

        <Col span={24} style={{ margin: '10px 0 0 0' }}>
          <Divider />
        </Col>

        {/* Full Details */}
        <Col span={24} className="detailViewSection">
          <Section
            title="Full Details"
            rightElement={
              <Button
                minimal
                small
                text={isShowingFullDetails ? 'Hide' : 'Show'}
                intent="primary"
                onClick={(e: any) => {
                  e.stopPropagation();
                  setIsShowingFullDetails(!isShowingFullDetails);
                }}
              />
            }
          >
            <Row>
              <Col span={24}>
                <Collapse isOpen={isShowingFullDetails}>
                  <Row>
                    {/* Updated At */}
                    <Col span={24} style={{ marginTop: 5 }}>
                      <EntityTitle
                        key="updatedAt"
                        title="Updated At"
                        subtitle={
                          <span>
                            {dayjs(template.updatedAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>

                    {/* Created At */}
                    <Col span={24} style={{ marginTop: 10, marginBottom: 20 }}>
                      <EntityTitle
                        key="createdAt"
                        title="Created At"
                        subtitle={
                          <span>
                            {dayjs(template.createdAt).format('DD/MM/YYYY HH:mm:ss') || 'None'}
                          </span>
                        }
                      />
                    </Col>
                  </Row>
                </Collapse>
              </Col>
            </Row>
          </Section>
        </Col>
      </Row>

      {/* Delete Template Alert */}
      <Alert
        intent="danger"
        canEscapeKeyCancel
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        isOpen={isDeleteTemplateAlertVisible}
        onCancel={() => setIsDeleteTemplateAlertVisible(false)}
        onClose={() => setIsDeleteTemplateAlertVisible(false)}
        onConfirm={deleteTemplate}
      >
        <p>Are you sure you want to delete this template? This action cannot be undone.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(TemplateDetails);
