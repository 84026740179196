import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';

const { ADDRESS, LEAD } = SchemaModuleEntityTypeEnums;

export const getColorForDSRecordStatus = (status: string) => {
  switch (status) {
    case 'TO_DO':
      return 'grey';
    case 'IN_PROGRESS':
      return 'orange';
    case 'DONE':
      return 'green';
    case 'APPROVED':
      return 'green';
    case 'REJECTED':
      return 'red';
    default:
      return 'grey';
  }
};

export const dataSetConditions = (entity: string) => {
  switch (entity) {
    case ADDRESS:
      return [
        {
          'properties.PostalCode.keyword': { order: 'asc' },
          'properties.BuildingNumber': { order: 'asc' },
        },
      ];
    default:
      return [{ createdAt: { order: 'desc' } }];
  }
};
