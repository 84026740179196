import {PipelineEntity} from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';

/**
 *
 * @param shortList
 * @param moduleName
 * @param entityName
 */
export const getPipelineFromShortListByModuleAndEntity = (
  shortList: { [key: string]: PipelineEntity },
  moduleName: string | undefined,
  entityName: string | undefined,
): PipelineEntity | undefined => {
  const keys = Object.keys(shortList);

  for (const key of keys) {
    const pipeline = shortList[key];
    if (!pipeline) continue;
    if (pipeline.moduleName === moduleName && pipeline.entityName === entityName) {
      return pipeline;
    }
  }
};

export const getPipelineFromShortListBySchemaId = (
  shortList: { [key: string]: PipelineEntity },
  schemaId: string | null | undefined,
): PipelineEntity | undefined => {
  return schemaId ? shortList[schemaId] : undefined;
};
