import { combineActions } from '../utils';

import * as filterActions from './filters';
import * as selectionActions from './selection';

type FPEFilterActionKeys = keyof typeof filterActions;
type FPESelectionActionKeys = keyof typeof selectionActions;

type AllActionsKeys = FPEFilterActionKeys | FPESelectionActionKeys;

export const fpeSelectionActions = combineActions<AllActionsKeys>(filterActions, selectionActions);

export type FPESelectionActions = typeof fpeSelectionActions;
