import { Button, Drawer, InputGroup, Tooltip } from '@blueprintjs/core';
import { Col, Row, Table } from 'antd';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
interface Props {
  schemaId?: string;
  schemaReducer: any;
  onSuccess: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

type TSharedColumn = {
  id: string;
  moduleName: string;
  entityName: string;
  name: string;
  description: string;
  type: string;
};

const ImportSharedColumn: React.FC<Props> = (props: Props) => {
  const { schemaReducer, schemaId, alertMessage, onSuccess } = props;
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const [isSearchingColumns, setIsSearchingColumns] = useState<boolean>(false);
  const [isImportingColumns, setIsImportingColumns] = useState<boolean>(false);
  const [sharedColumns, setSharedColumns] = useState<TSharedColumn[]>([]);
  const [page, setPage] = useState<number>(1);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);

  const pageSize = 300;

  useEffect(() => {
    if (isDrawerVisible) {
      fetchSharedColumns();
    }
  }, [isDrawerVisible]);

  const fetchSharedColumns = () => {
    httpGet(`SchemaModule/v1.0/shared-columns/available-to-share/`).then((response: any) => {
      const sharedColumns: TSharedColumn[] = response.data?.data || [];
      console.log('debug: response', sharedColumns);
      setSharedColumns(sharedColumns);
    });
  };

  let filteredSharedColumns = Object.assign([], sharedColumns);

  filteredSharedColumns = Object.assign(filteredSharedColumns).map((column: TSharedColumn) => ({
    ...column,
    key: column.id,
  }));

  // Handle pagination
  if (filteredSharedColumns.length > 0 && !searchQuery) {
    filteredSharedColumns = filteredSharedColumns.slice((page - 1) * pageSize, page * pageSize);
  }

  // Handle Search Query
  if (filteredSharedColumns.length > 0 && searchQuery) {
    filteredSharedColumns = filteredSharedColumns.filter(
      (column: TSharedColumn) =>
        column.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        column.entityName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        column.moduleName?.toLowerCase().includes(searchQuery.toLowerCase()),
    );
    filteredSharedColumns = filteredSharedColumns.slice(0, pageSize);
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: (a: TSharedColumn, b: TSharedColumn) => a.name.localeCompare(b.name),
      render: (text: string, record: TSharedColumn) => (
        <Tooltip content={record.description} fill hoverOpenDelay={800}>
          <span style={{ fontWeight: 500, cursor: 'pointer' }}>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: 'Module',
      dataIndex: 'moduleName',
      key: 'moduleName',
      sorter: (a: TSharedColumn, b: TSharedColumn) => a.moduleName.localeCompare(b.moduleName),
      render: (text: string) => text,
    },
    {
      title: 'Entity',
      dataIndex: 'entityName',
      key: 'entityName',
      sorter: (a: TSharedColumn, b: TSharedColumn) => a.entityName.localeCompare(b.entityName),
      render: (text: string) => text,
    },
    {
      title: 'Type',
      dataIndex: 'type',
      width: '20%',
      key: 'type',

      render: (text: string) => text,
    },
  ];

  const onSelectRow = (selectedRow: any, selected: boolean) => {
    let newSelectedKeys = [];
    if (selected) {
      newSelectedKeys = [...new Set([...selectedRowKeys, selectedRow.key])];
    } else {
      newSelectedKeys = [...selectedRowKeys];
      const rowIndex = newSelectedKeys.findIndex((key: string) => key === selectedRow.key);
      if (rowIndex >= 0) {
        newSelectedKeys.splice(rowIndex, 1);
      }
    }
    setSelectedRowKeys(newSelectedKeys);
  };

  const onImport = async (onSuccessm: any) => {
    setIsImportingColumns(true);

    Promise.allSettled(
      selectedRowKeys.map(async (columnId: string) => {
        return await httpPost(`SchemaModule/v1.0/shared-columns/${schemaId}/${columnId}`, {});
      }),
    )
      .then((res: any) => {
        const successes = res.filter(
          (item: any) => item.status === 'fulfilled' && item.value?.data?.successful,
        );
        if (successes.length > 0) {
          alertMessage({
            body: `Successfully imported ${successes.length} columns.`,
            type: 'success',
          });
          setIsImportingColumns(false);
          setIsDrawerVisible(false);
          setSelectedRowKeys([]);
        } else {
          alertMessage({ body: `Could not import columns.`, type: 'error' });
          setIsImportingColumns(false);
        }
      })
      .finally(() => {
        onSuccessm();
      });
  };

  return (
    <>
      <Drawer
        title="Shared Columns"
        isOpen={isDrawerVisible}
        style={{ width: isMobile ? '95%' : '55%' }}
        onClose={() => {
          setIsDrawerVisible(false);
          setSearchQuery('');
          setSelectedRowKeys([]);
        }}
        canEscapeKeyClose={true}
      >
        <div style={{ padding: 15 }}>
          <Row style={{ marginBottom: 15 }}>
            <Col span={10} style={{ padding: 2 }}>
              <InputGroup
                round
                leftIcon="search"
                placeholder="Search by Name, Module or Entity"
                disabled={isSearchingColumns || !sharedColumns.length}
                onChange={(e: any) => setSearchQuery(e.target.value)}
              />
            </Col>
            <Col span={14} style={{ textAlign: 'right' }}>
              <Button
                intent="primary"
                icon="plus"
                onClick={() => onImport(onSuccess)}
                text={`Add${
                  selectedRowKeys.length > 0
                    ? ` (${selectedRowKeys.length}) ${
                        selectedRowKeys.length > 1 ? ' Columns' : 'Column'
                      }`
                    : ''
                }`}
                disabled={selectedRowKeys.length === 0}
              />
            </Col>
          </Row>
          <Row style={{ overflowY: 'scroll', padding: 2, height: '86vh' }}>
            <Col span={24}>
              <Table
                size="small"
                columns={columns}
                dataSource={filteredSharedColumns}
                scroll={{ y: 'calc(100vh - 100px)' }}
                pagination={{ pageSize: 20 }}
                rowSelection={{
                  type: 'checkbox',
                  selectedRowKeys,
                  onSelect: (selectedRow: any, selected: boolean) =>
                    onSelectRow(selectedRow, selected),
                }}
              />
            </Col>
          </Row>
        </div>
      </Drawer>
      <Button
        disabled={!schemaId}
        intent="success"
        icon="plus"
        onClick={() => setIsDrawerVisible(true)}
        text="Create Shared Column"
      />
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ImportSharedColumn);
