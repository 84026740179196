import {
  CLOSE_ADD_PRODUCT_DRAWER,
  CLOSE_IMPORT_FILES_DRAWER,
  CLOSE_ORDER_BUILDER_DRAWER,
  CLOSE_PRODUCT_REPLACEMENT_DRAWER,
  CLOSE_RECORD_DRAWER,
  OPEN_ADD_PRODUCT_DRAWER,
  OPEN_IMPORT_FILES_DRAWER,
  OPEN_ORDER_BUILDER_DRAWER,
  OPEN_PRODUCT_REPLACEMENT_DRAWER,
  OPEN_RECORD_DRAWER,
  SET_APPOINTMENT_COUNT_NUMBER,
  SET_LOOKUP_RECORD,
  SET_MENTIONS_COUNT_NUMBER,
  SET_ORDER_BUILDER_DRAWER_RECORD_IDS,
  SET_ORDER_BUILDER_RECONTRACTING,
  SET_RAW_DATA_DRAWER_CONTENTS,
  TOGGLE_ASSIGN_RECORD_TO_GROUP_MODAL,
  TOGGLE_CREATE_UPDATE_TEMPLATE_MODAL,
  TOGGLE_GENERATE_WORK_ORDER_MODAL_VISIBLE,
  TOGGLE_LOOKUP_DRAWER,
  TOGGLE_MANAGE_GROUPS_ON_TEMPLATE_MODAL,
  TOGGLE_MANAGE_ROLES_ON_TEMPLATE_MODAL,
  TOGGLE_NOTIFICATION_DRAWER_VISIBLE,
  TOGGLE_RAW_DATA_DRAWER,
  TOGGLE_SCHEMA_ACTION_CREATE_EDIT_DRAWER,
  TOGGLE_VIEW_CONFIGURATION_CREATE_EDIT_DRAWER,
} from './constants';
import {
  IImportFiles,
  ILookupSetRecord,
  IOpenAddProductDrawer,
  IOpenProductReplaceDrawer,
} from './types';

export interface ISetOrderBuilderDrawerRecordIds {
  accountId?: string | undefined;
  leadId?: string | undefined;
}

export interface IOpenRecordDrawer {
  recordId: string;
  moduleName: string;
  entityName: string;
}

// Import Files
export function openImportFilesDrawer(params: IImportFiles) {
  return {
    type: OPEN_IMPORT_FILES_DRAWER,
    params,
  };
}

export function closeImportFilesDrawer() {
  return {
    type: CLOSE_IMPORT_FILES_DRAWER,
  };
}

// Add Product
export function openAddProductDrawer(params: IOpenAddProductDrawer) {
  return {
    type: OPEN_ADD_PRODUCT_DRAWER,
    params,
  };
}

export function closeAddProductDrawer() {
  return {
    type: CLOSE_ADD_PRODUCT_DRAWER,
  };
}

// Replace Product
export function openProductReplacementDrawer(params: IOpenProductReplaceDrawer) {
  return {
    type: OPEN_PRODUCT_REPLACEMENT_DRAWER,
    params,
  };
}

export function closeProductReplacementDrawer() {
  return {
    type: CLOSE_PRODUCT_REPLACEMENT_DRAWER,
  };
}

// Raw Data
export function toggleRawDataDrawer() {
  return {
    type: TOGGLE_RAW_DATA_DRAWER,
  };
}

export function setRawDataDrawerContents(params: string) {
  return {
    type: SET_RAW_DATA_DRAWER_CONTENTS,
    params,
  };
}

export function toggleLookupDrawer() {
  return {
    type: TOGGLE_LOOKUP_DRAWER,
  };
}

export function setLookupRecord(params: ILookupSetRecord) {
  return {
    type: SET_LOOKUP_RECORD,
    params,
  };
}

export function toggleAssignRecordToGroupModal() {
  return {
    type: TOGGLE_ASSIGN_RECORD_TO_GROUP_MODAL,
  };
}

// Order Builder
export function openOrderBuilderDrawer() {
  return {
    type: OPEN_ORDER_BUILDER_DRAWER,
  };
}

export function closeOrderBuilderDrawer() {
  return {
    type: CLOSE_ORDER_BUILDER_DRAWER,
  };
}

export function setOrderBuilderDrawerRecordIds(params: ISetOrderBuilderDrawerRecordIds) {
  return {
    type: SET_ORDER_BUILDER_DRAWER_RECORD_IDS,
    params,
  };
}

export function setOrderBuilderRecontracting(params: { orderId: string }) {
  return {
    type: SET_ORDER_BUILDER_RECONTRACTING,
    params,
  };
}

export function toggleCreateUpdateTemplateModal() {
  return {
    type: TOGGLE_CREATE_UPDATE_TEMPLATE_MODAL,
  };
}

export function toggleManageRolesOnTemplateModal() {
  return {
    type: TOGGLE_MANAGE_ROLES_ON_TEMPLATE_MODAL,
  };
}

export function toggleManageGroupsOnTemplateModal() {
  return {
    type: TOGGLE_MANAGE_GROUPS_ON_TEMPLATE_MODAL,
  };
}

// Schema Action Create Edit Drawer
export function toggleSchemaActionCreateEditDrawer() {
  return {
    type: TOGGLE_SCHEMA_ACTION_CREATE_EDIT_DRAWER,
  };
}

// View Configuration Create Edit Drawer
export function toggleViewConfigurationCreateEditDrawer() {
  return {
    type: TOGGLE_VIEW_CONFIGURATION_CREATE_EDIT_DRAWER,
  };
}

export function setAppointmentCountNumber(params: { count: number }) {
  return {
    type: SET_APPOINTMENT_COUNT_NUMBER,
    params,
  };
}

export function setMentionsCountNumber(params: { count: number }) {
  return {
    type: SET_MENTIONS_COUNT_NUMBER,
    params,
  };
}

export function toggleNotificationDrawer() {
  return {
    type: TOGGLE_NOTIFICATION_DRAWER_VISIBLE,
  };
}

export function toggleGenerateWorkOrderModal() {
  return {
    type: TOGGLE_GENERATE_WORK_ORDER_MODAL_VISIBLE,
  };
}

export function openRecordDrawer(params: IOpenRecordDrawer) {
  return {
    type: OPEN_RECORD_DRAWER,
    params,
  };
}

export function closeRecordDrawer() {
  return {
    type: CLOSE_RECORD_DRAWER,
  };
}
