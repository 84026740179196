import { Col, Row } from 'antd';

import ActivityCenter from '@legacy/core/records/components/ActivityCenter';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

type RecordActivitiesProps = {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
}
export const RecordActivities = ({ record, schema }: RecordActivitiesProps) => {
  return (
    <Row
      style={{ marginTop: 20, padding: 10 }}
    >
      <Col span={24}>
        <ActivityCenter record={record!} schema={schema!} compact />
      </Col>
    </Row>
  );
};