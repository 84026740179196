declare global {
  namespace JSX {
    interface IntrinsicElements {
      'em-emoji': any;
    }
  }
}

import React from 'react';

interface Props {
  emojiId: string;
}

const NoteFeedEmoji: React.FC<Props> = (props: Props) => {
  const { emojiId } = props;
  return <em-emoji id={emojiId} key={emojiId} />;
};
export default NoteFeedEmoji;
