import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getAllRelations } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Button, Modal, Result, Spin } from 'antd';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getRecordAssociationWithNestedEntitiesRequest } from '@legacy/core/recordsAssociations/store/actions';
import { getSchemaByModuleAndEntityRequest } from '@legacy/core/schemas/store/actions';
import { httpPost } from '../../../../../core/http/requests';
import { DISPLAY_MESSAGE } from '@legacy/core/messages/store/reducers';
import { useAction } from '../../../../../core/hooks/useAction';
import { useRequest } from '../../../../../core/hooks/useRequest';
import { WorkListSelectPanel } from './WorkListSelectPanel';

type AddToWorkListProps = {
  selectedRowRecords: any[];
  // project: DbRecordEntityTransform;S
  projectId: string;
  onFeaturesAdded?: (featureIds: string[], workList?: DbRecordEntityTransform) => void;
  disabled?: boolean;
}

export const AddToWorkList = ({
                                projectId,
                                selectedRowRecords = [],
                                onFeaturesAdded = () => undefined,
                                disabled = false,
                              }: AddToWorkListProps) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [selectedWorkList, setSelectedWorkList] = useState<DbRecordEntityTransform | undefined>();
  const showModal = () => setIsModalVisible(true);
  const handleCancel = () => setIsModalVisible(false);
  const getSchema = useRequest(getSchemaByModuleAndEntityRequest);
  const getRecordAssotications = useRequest(getRecordAssociationWithNestedEntitiesRequest);
  const displayMessage = useAction((message: { body: string, type: 'error' | 'success' }) => ({
    type: DISPLAY_MESSAGE,
    message,
  }));

  const [worklists, setWorkLists] = useState<DbRecordEntityTransform[]>([]);

  const [workListData, setWorkListData] = useState<{
    id: string | undefined;
    isSuccess: boolean;
  }>({
    id: undefined,
    isSuccess: false,
  });

  useEffect(() => {
    setIsModalLoading(true);
    getSchema({
      moduleName: 'ProjectModule',
      entityName: 'Project',
    }).then(schema => {
      return getRecordAssotications({
        recordId: projectId,
        schema,
        entity: 'WorkList',
        nestedEntities: [],
        withLinks: true,
      });
    }).then(({ results }: any) => {
      const projectWorkLists = getAllRelations(results, 'WorkList') ?? [];
      setWorkLists(projectWorkLists);
      setIsModalLoading(false);
    });
  }, []);

  const addFeaturesToWorkList = () => {
    if (!selectedWorkList) return;

    const featureIds = selectedRowRecords.map(row => row.key);

    setIsModalLoading(true);
    httpPost(
      `ProjectModule/v1.0/WorkList/features`,
      {
        workListId: selectedWorkList.id,
        featureIds,
      },
    ).then(() => {
      setIsModalLoading(false);
      setWorkListData({
        id: selectedWorkList.id,
        isSuccess: true,
      });
      onFeaturesAdded(featureIds, selectedWorkList);
    }).catch(err => {
      displayMessage({ body: err.response?.data?.message ?? err.message, type: 'error' });
      setIsModalLoading(false);
      setWorkListData({
        id: undefined,
        isSuccess: false,
      });
    });
  };

  // Reset state when projectId or selectedRowRecords change
  useEffect(() => {
    setIsModalVisible(false);
    setIsModalLoading(false);
    setWorkListData({
      id: undefined,
      isSuccess: false,
    });
  }, [
    projectId,
    selectedRowRecords.map(r => r.key).join(),
  ]);

  return (
    <>
      <Button
        onClick={showModal}
        style={{ marginRight: '8px' }}
        disabled={disabled}
      >
        Add to Existing WorkList ({selectedRowRecords?.length})
      </Button>
      <Modal
        open={isModalVisible}
        title={`Select WorkList to add ${selectedRowRecords?.length} Feature${selectedRowRecords?.length > 1 ? 's' : ''}`}
        width={900}
        // onOk={handleOk}
        footer={null}
        onCancel={handleCancel}
      >
        <div style={{ minHeight: '370px', textAlign: 'center' }}>
          {isModalLoading && <Spin size="large" />}
          {!isModalLoading && !workListData.id && (
            <>
              <WorkListSelectPanel records={worklists} selectedRecord={selectedWorkList}
                                   onRecordSelected={record => setSelectedWorkList(record)} />
              <div style={{ display: 'flex', justifyContent: 'end', marginTop: '8px' }}>
                <Button type="primary" disabled={!selectedWorkList || isModalLoading} onClick={addFeaturesToWorkList}>Add
                  Features</Button>
              </div>
            </>
          )}
          {!isModalLoading && !!workListData.id && (
            <Result
              status="success"
              title="Successfully added Features to WorkList!"
              subTitle="click below to view your WorkList"
              extra={[
                <Link
                  target="_blank"
                  to={`/ProjectModule/WorkList/${workListData?.id}`}
                >
                  View WorkList
                </Link>,
              ]}
            />
          )}
        </div>
      </Modal>
    </>
  );
};