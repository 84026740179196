import { CalendarState } from '../../types';
import {
  FETCH_SCHEDULES_ERROR,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_RESPONSE,
  SELECT_SCHEDULE,
  SET_SCHEDULE_FILTER,
} from '../constants';
import {
  CalendarAction,
  FetchSchedulesErrorAction,
  FetchSchedulesRequestAction,
  FetchSchedulesResponseAction,
  SelectScheduleAction,
  SetScheduleFilterAction,
} from '../types';

const isRequestAction = (action: CalendarAction): action is FetchSchedulesRequestAction =>
  action.type === FETCH_SCHEDULES_REQUEST;
const isResponseAction = (action: CalendarAction): action is FetchSchedulesResponseAction =>
  action.type === FETCH_SCHEDULES_RESPONSE;
const isErrorAction = (action: CalendarAction): action is FetchSchedulesErrorAction =>
  action.type === FETCH_SCHEDULES_ERROR;
const isSelectAction = (action: CalendarAction): action is SelectScheduleAction =>
  action.type === SELECT_SCHEDULE;
const isFilterAction = (action: CalendarAction): action is SetScheduleFilterAction =>
  action.type === SET_SCHEDULE_FILTER;

const reducers = {
  [FETCH_SCHEDULES_REQUEST]: (
    state: CalendarState['schedules'],
    _: FetchSchedulesRequestAction,
  ): CalendarState['schedules'] => ({
    ...state,
    status: 'loading',
    data: [],
    error: undefined,
  }),

  [FETCH_SCHEDULES_RESPONSE]: (
    state: CalendarState['schedules'],
    action: FetchSchedulesResponseAction,
  ): CalendarState['schedules'] => ({
    ...state,
    status: 'loaded',
    data: action.payload,
    error: undefined,
  }),

  [FETCH_SCHEDULES_ERROR]: (
    state: CalendarState['schedules'],
    action: FetchSchedulesErrorAction,
  ): CalendarState['schedules'] => {
    return {
      ...state,
      status: 'error',
      data: [],
      error: action.payload,
    };
  },

  [SET_SCHEDULE_FILTER]: (
    state: CalendarState['schedules'],
    action: SetScheduleFilterAction,
  ): CalendarState['schedules'] => {
    return {
      ...state,
      filter: action.payload,
    };
  },

  [SELECT_SCHEDULE]: (
    state: CalendarState['schedules'],
    action: SelectScheduleAction,
  ): CalendarState['schedules'] => {
    return {
      ...state,
      selectedIds: [...action.payload],
    };
  },
} as const;

export const schedulesReducer = (
  state: CalendarState['schedules'],
  action: CalendarAction,
): CalendarState['schedules'] => {
  if (isRequestAction(action)) return reducers[action.type](state, action);

  if (isResponseAction(action)) return reducers[action.type](state, action);

  if (isErrorAction(action)) return reducers[action.type](state, action);

  if (isSelectAction(action)) return reducers[action.type](state, action);

  if (isFilterAction(action)) return reducers[action.type](state, action);

  return state;
};
