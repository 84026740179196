import { Icon } from '@blueprintjs/core';
import { Handle, Position } from '@xyflow/react';
import { memo } from 'react';

const deleteIconStyle = {
  position: "absolute",
  right: -3,
  top: -3,
  background: "#9c3030",
  color: "white",
  border: "solid 2px #9c3030",
  borderRadius: 50,
  cursor: "pointer",
} as any;

const CurrentNode = memo(({ data, isConnectable }: any) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      {data.label}
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
      <Handle
        type="source"
        position={Position.Right}
        isConnectable={isConnectable}
        id="add-link"
      />
    </>
  );
});

const ParentNode = memo(({ data, isConnectable }: any) => {
  // console.log("[DebugX] ParentNode", data);
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Icon
        icon="cross"
        className="delete-icon"
        style={deleteIconStyle}
      />
      {data.label}
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </>
  );
});

const ChildNode = memo(({ data, isConnectable }: any) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Top}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      <Icon
        icon="cross"
        className="delete-icon"
        style={deleteIconStyle}
      />
      {data.label}
      <Handle
        type="source"
        position={Position.Bottom}
        isConnectable={isConnectable}
      />
    </>
  );
});

const AddNode = memo(({ data, isConnectable }: any) => {
  return (
    <>
      <Handle
        type="target"
        position={Position.Left}
        style={{ background: '#555' }}
        isConnectable={isConnectable}
      />
      {data.label}
    </>
  );
});

export const nodeTypes = {
  current: CurrentNode,
  parent: ParentNode,
  child: ChildNode,
  add: AddNode,
}