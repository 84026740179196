import { Section, Tag } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row, Tooltip } from 'antd';
import { FC, useContext } from 'react';
import { isMobile } from 'react-device-detect';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { DetailViewContext } from '../DetailViewContextProvider';
import ActionMenuDetailView from '../ActionMenuDetailView';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform;
  extra?: JSX.Element[];
  title?: string;
  isQuickView?: boolean;
}

const HeaderDetailView: FC<Props> = (props: Props) => {
  const { record, extra, title, isQuickView } = props;
  const { viewConfigurations, isLoadingConfigurations } = useContext(DetailViewContext);

  const sanitizeRecordTitle = (title: string | undefined) => {
    if (title) {
      if (title.length > 19 && isMobile) {
        return (
          <Tooltip title={title}>
            <span>{`${title.slice(0, 19)}...`}</span>
          </Tooltip>
        );
      } else {
        return title;
      }
    } else {
      return '';
    }
  };

  const moduleName = record?.entity?.split(':')[0];
  const entityName = record?.entity?.split(':')[1];
  const headerTitle = title || record?.title || '';

  return (
    <Section
      key="123"
      style={{ marginBottom: 1 }}
      title={
        <div style={{ paddingTop: isMobile ? 10 : 'auto' }}>
          <span style={{ marginRight: 7 }}>{sanitizeRecordTitle(headerTitle) || 'No Title'}</span>
          {/* Show Type if available */}
          {record?.type && (
            <Tag
              round
              minimal
              intent="primary"
              style={{ fontSize: 10, verticalAlign: 'middle', padding: '0px 6px' }}
            >
              {record?.type}
            </Tag>
          )}
        </div>
      }
      subtitle={
        <>
          <span>
            {`${record?.entity?.split(':')[1]} ${
              record?.recordNumber ? `#${record?.recordNumber}` : ''
            }`}
          </span>
        </>
      }
      rightElement={
        <>
          {(!isMobile && extra) || <></>}
          {record && (
            <ActionMenuDetailView
              record={record!}
              showViewConfiguration={viewConfigurations?.length > 0}
              isQuickView={isQuickView}
            />
          )}
        </>
      }
      icon={
        <ModuleEntityIcon
          moduleName={moduleName!}
          entityName={entityName!}
          iconContainerStyle={{ padding: '10px 13px', marginRight: 4 }}
        />
      }
    >
      {/* On mobile viewport, we want to show all extra actions on the bottom of the header */}
      {isMobile && extra && (
        <Row style={{ padding: 10 }}>
          <Col span={24}>{extra}</Col>
        </Row>
      )}
    </Section>
  );
};

export default HeaderDetailView;
