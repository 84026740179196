import { OrderInvoiceItemCalculations } from '@d19n/temp-fe-d19n-common/dist/billing/helpers/OrderInvoiceItemCalculations';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
  sortDbRecordsByDate,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

const { ORDER_MODULE, BILLING_MODULE, FIELD_SERVICE_MODULE, PRODUCT_MODULE } =
  SchemaModuleTypeEnums;
const { ORDER, ORDER_ITEM, BILLING_ADJUSTMENT, INVOICE, PRODUCT, DISCOUNT, WORK_ORDER } =
  SchemaModuleEntityTypeEnums;

export const isRecordOrder = (record: DbRecordEntityTransform) => {
  return record && record.entity === `${ORDER_MODULE}:${ORDER}`;
};

export const isRecordWorkOrder = (record: DbRecordEntityTransform) => {
  return record && record.entity === `${FIELD_SERVICE_MODULE}:${WORK_ORDER}`;
};

export const isRecordProduct = (record: DbRecordEntityTransform) => {
  return record && record.entity === `${PRODUCT_MODULE}:${PRODUCT}`;
};

export const isRecordOrderItem = (record: DbRecordEntityTransform) => {
  return record && record.entity === `${ORDER_MODULE}:${ORDER_ITEM}`;
};

/**
 * Take a product record and convert to order item
 */
export class CreateOrderItemFromProduct {
  public static construct(
    product: DbRecordEntityTransform,
    quantity: number,
    order: DbRecordEntityTransform,
  ) {
    let orderItem: any = { properties: {}, Product: { dbRecords: [product] } };
    orderItem.entity = 'ProductModule:Product';
    orderItem.id = product.id;
    orderItem.properties.ActivationStatus = 'OPEN';
    orderItem.properties.Description = getProperty(product, 'Description');
    orderItem.properties.UnitPrice = getProperty(product, 'UnitPrice');
    orderItem.properties.DiscountValue = getProperty(product, 'DiscountValue'); // used in calculations
    orderItem.properties.DiscountType = getProperty(product, 'DiscountType'); // used in calculations
    orderItem.properties.TaxRate = getProperty(product, 'TaxRate');
    orderItem.properties.Taxable = getProperty(product, 'Taxable');
    orderItem.properties.TaxIncluded = getProperty(product, 'TaxIncluded');
    orderItem.properties.Quantity = quantity || 1;
    orderItem.properties.ProductRef = product.id;
    orderItem.properties.ProductType = getProperty(product, 'Type');
    orderItem.properties.ProductCategory = getProperty(product, 'Category');
    orderItem.properties.ProductCustomerType = getProperty(product, 'CustomerType');

    //orderItem.Product.dbRecords = [product]

    // ODN-1660 the order level AMOUNT discount can be applied incorrectly
    // need to recalculate all the items on the caller side if the order level AMOUNT discount exists

    // ODN-1542 compute total price ignoring discount expiration
    orderItem.properties.TotalPrice = Number(
      OrderInvoiceItemCalculations.computeAdjustedItemTotalPriceForPeriodType(
        //@ts-ignore
        undefined,
        {
          id: undefined,
          schema: undefined,
          isArchived: false,
          properties: orderItem.properties,
        },
        order,
        {
          DiscountType: order.properties.DiscountType,
          DiscountValue: order.properties.DiscountValue,
        },
      ),
    );

    // ODN-1542 compute tax ignoring discount expiration
    orderItem.properties.TotalTaxAmount = Number(
      OrderInvoiceItemCalculations.computeAdjustedItemTotalTaxAmountForPeriodType(
        //@ts-ignore
        undefined,
        {
          id: undefined,
          schema: undefined,
          isArchived: false,
          properties: orderItem,
        },
        order,
        {
          DiscountType: order.properties.DiscountType,
          DiscountValue: order.properties.DiscountValue,
        },
      ),
    );

    return orderItem;
  }
}

/**
 * Convert all invoice items to products
 *
 * @param invoiceItems
 */
export const getAllInvoiceItemsAsProducts = (invoiceItems: DbRecordEntityTransform[]) => {
  let products: DbRecordEntityTransform[] = [];

  invoiceItems.forEach((invoiceItem: DbRecordEntityTransform) => {
    // For Order items, get the associated products and add them as much time
    // as specified in the order item Quantity property
    if (isRecordOrderItem(invoiceItem)) {
      let associatedProduct = getFirstRelation(invoiceItem, PRODUCT);
      const orderItemQuantity = getProperty(invoiceItem, 'Quantity');
      //const orderItemPrice = getProperty(invoiceItem, 'UnitPrice');

      if (associatedProduct) {
        associatedProduct.properties.Quantity = orderItemQuantity;
        //associatedProduct.properties.UnitPrice = orderItemPrice;
        products.push(associatedProduct);
      }
    }

    // For Products, get back product records, adjust the price as specified in the invoice
    // configuration, and add the product as many times as amount specified
    else if (isRecordProduct(invoiceItem)) {
      products.push(invoiceItem);
    }
  });

  return products;
};

/**
 * Convert all invoice items to order items
 *
 * @param invoiceItems
 * @param parentRecord
 */
export const getAllInvoiceItemsAsOrderItems = (
  invoiceItems: DbRecordEntityTransform[],
  parentRecord: DbRecordEntityTransform,
) => {
  let orderItems: DbRecordEntityTransform[] = [];

  invoiceItems.map((item: DbRecordEntityTransform) => {
    if (isRecordOrderItem(item)) {
      orderItems.push(item);
    } else if (isRecordProduct(item)) {
      let productToOrderItem: any = {
        properties: {},
      };

      productToOrderItem = CreateOrderItemFromProduct.construct(
        item,
        item.properties.Quantity,
        parentRecord,
      );

      orderItems.push(productToOrderItem);
    }
  });

  return orderItems;
};

export const getNextAvailableAdjustment = (adjustments: DbRecordEntityTransform[]) => {
  if (adjustments.length === 0) return undefined;
  let result: any = undefined;

  let sorted = sortDbRecordsByDate(adjustments, 'asc');

  if (sorted) {
    for (let i = 0; i < sorted.length; i++) {
      const freePeriodLength = getProperty(sorted[i], 'FreePeriodLength') || 0;
      const discountLength = getProperty(sorted[i], 'DiscountLength') || 0;
      const discountType = getProperty(sorted[i], 'DiscountType');
      const discountValue = getProperty(sorted[i], 'DiscountValue');
      let adjustmentInvoices = sorted[i][INVOICE]?.dbRecords;
      // check if the billing adjustments have applied to the invoices
      if (adjustmentInvoices) {
        // exclude voided invoices
        const invoicesNotVoided = adjustmentInvoices.filter(
          (elem: DbRecordEntityTransform) => getProperty(elem, 'Status') !== 'VOID',
        );
        if (freePeriodLength && invoicesNotVoided.length < Number(freePeriodLength)) {
          // the adjustment should be applied
          result = sorted[i];
          break;
        } else if (
          discountType &&
          discountValue &&
          invoicesNotVoided.length < Number(freePeriodLength) + Number(discountLength)
        ) {
          result = sorted[i];
          break;
        }
      }
      // adjustment has no invoices add it to this invoice
      else if (Number(freePeriodLength) > 0) {
        result = sorted[i];
        break;
      } else if (discountType && discountValue && Number(discountLength) > 0) {
        result = sorted[i];
        break;
      }
    }
  }

  return result;
};
