import { AimOutlined, MenuOutlined } from '@ant-design/icons';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Button, Col, Descriptions, Layout, Row, Typography } from 'antd';
import { Geolocation } from 'ol';
import LayerSwitcher from 'ol-layerswitcher';
import Feature from 'ol/Feature';
import Point from 'ol/geom/Point';
import { defaults as DefaultInteractions } from 'ol/interaction';
import { Image as ImageLayer } from 'ol/layer';
import VectorLayer from 'ol/layer/Vector';
import Map from 'ol/Map';
import Overlay from 'ol/Overlay';
import { toLonLat, transform } from 'ol/proj';
import { register } from 'ol/proj/proj4';
import ImageWMS from 'ol/source/ImageWMS';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import CircleStyle from 'ol/style/Circle';
import View from 'ol/View';
import proj4 from 'proj4';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  MapReducerUpdate,
  MapSearch,
  setMapSearchQuery,
  updateMapState,
} from '@netomnia/modules/ProjectModule/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getOdinSchemaByEntity } from '../../../../core/helpers/schemaHelpers';
import AddHazardInterface from './components/AddHazard/AddHazardInterface';
import AddLineInterface from './components/AddLine/AddLineInterface';
import AddPointInterface from './components/AddPoint/AddPointInterface';
import FeatureBulkUpdate from './components/FeatureBulkUpdate';
import FeatureSelect from './components/FeatureSelect';
import RFCCreate from './components/RFCCreate';
import { getFeatureByIdAndZoom, removeMapLayersByClassName, transformFeatureNameFromUrl } from './helpers';
import { mapLayers } from './MapLayers';
import MapSideBar from './MapSideBar';
import WorkItemQuickView from './MapSideBar/MapSidebarWorkItems/FeatureQuickView_V2';
import './styles.scss';

const { PROJECT_MODULE, SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, PROJECT, FILE } = SchemaModuleEntityTypeEnums;

interface Props {
  mapReducer: MapReducer;
  alertMessage: any;
  updateMap: (params: MapReducerUpdate) => {};
  match: any;
  searchRecords: Function;
  searchMap: (params: MapSearch) => void;
}

interface State {
  geolocation: Geolocation | undefined;
  lonLat: any;
  XY: any;
  dragging: boolean;
  visibleLayers: Array<string>;
  urlFeatureId: string;
  urlFeatureType: string;
}

class PlanningModuleMap extends React.Component<Props, State> {
  private layerSwitcherRef = React.createRef<HTMLDivElement>();
  private popupContainerRef = React.createRef<HTMLDivElement>();
  private addPointPopupContainerRef = React.createRef<HTMLDivElement>();

  constructor(props: Props) {
    super(props);
    this.state = {
      geolocation: undefined,
      lonLat: '',
      XY: '',
      dragging: false,
      visibleLayers: [],
      urlFeatureId: '',
      urlFeatureType: '',
    };
  }

  componentDidMount() {
    const { match } = this.props;

    this.initializeMap();

    // Prefetch all schemas needed for engineer's work. Files are essential, because in quick views there can be
    // many file upload fields, and if no File schema is preloaded in the shortlist, these components will hit the
    // APIs all at once. This way we make sure that the File schema is always available in the shortlist.
    this.prefetchSchemas();

    /* URL parameters for Feature ID & Feature ID are passed to Map */
    if (match.params.featureId && match.params.featureType) {
      this.setState({
        urlFeatureId: match.params.featureId,
        urlFeatureType: match.params.featureType,
      });
    }
  }

  prefetchSchemas = async () => {
    await getOdinSchemaByEntity(PROJECT_MODULE, FEATURE);
    await getOdinSchemaByEntity(PROJECT_MODULE, PROJECT);
    await getOdinSchemaByEntity(SCHEMA_MODULE, FILE);
  };

  // This will fetch features from elastic search
  async fetchData() {
    const { searchRecords, mapReducer } = this.props;

    let query: any = mapReducer.queries;
    let schema = await getOdinSchemaByEntity(PROJECT_MODULE, FEATURE);

    if (mapReducer.queryLayer === 'polygon') {
      schema = await getOdinSchemaByEntity(PROJECT_MODULE, PROJECT);

      query = {
        must: [
          {
            query_string: {
              fields: ['properties.ExternalRef'],
              query: mapReducer.searchTerms,
              default_operator: 'AND',
              lenient: true,
              analyze_wildcard: true,
              boost: 1,
            },
          },
        ],
        must_not: [],
        should: [],
        filter: [],
      };
    }

    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          terms: null,
          fields: null,
          schemas: schema.id,
          sort: [{ schemaPosition: { order: 'desc' } }],
          boolean: query,
        },
      });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any) {
    const { mapReducer, updateMap, alertMessage, searchMap } = this.props;

    // If URL Params are passed, set to reducer and construct queries
    if (prevState.urlFeatureId !== this.state.urlFeatureId && mapReducer.map) {
      if (this.state.urlFeatureId && this.state.urlFeatureType) {
        updateMap({
          mapSidebarVisible: true,
          mapSidebarSection: 'FEATURES',
          inputFieldFeatureID: this.state.urlFeatureId!,
          query: `type=${transformFeatureNameFromUrl(this.state.urlFeatureType!)}&featureId=${
            this.state.urlFeatureId
          }`,
          queryLayer: transformFeatureNameFromUrl(this.state.urlFeatureType),
        });
        searchMap({
          featureIds: this.state.urlFeatureId,
        });
      }
    }

    // For all updates and changes on map - update the map size
    if (prevProps.mapReducer.map !== this.props.mapReducer.map && mapReducer.map) {
      this.props.mapReducer.map?.updateSize();
    }

    // Configure geolocation when enabled
    if (prevProps.mapReducer.geoEnabled !== this.props.mapReducer.geoEnabled && mapReducer.map) {
      this.configureGeolocator();
      this.props.mapReducer.map?.updateSize();
    }

    // Whenever the elastic queries are set, fetch the data
    if (prevProps.mapReducer.queries !== this.props.mapReducer.queries && mapReducer.map) {
      this.fetchData();
    }

    // Zoom into searched feature
    if (prevProps.mapReducer.query !== this.props.mapReducer.query && mapReducer.map) {
      getFeatureByIdAndZoom(mapReducer, updateMap, alertMessage);
    }

    // Load layer with on hold items or hide, depending on the switch state
    if (
      prevProps.mapReducer.showOnHoldItems !== this.props.mapReducer.showOnHoldItems &&
      mapReducer.map
    ) {
      if (mapReducer.showOnHoldItems) {
        const onHoldLayers: Array<string> = [
          'cable',
          'closure',
          'chamber',
          'pole',
          'duct',
          'rope',
          'polygon',
        ];
        let onHoldFilters: string = '';

        onHoldLayers.forEach((layer: string, i: number) => {
          onHoldFilters += `${layer}:"eco" = 'false' OR "eco" IS NULL${
            onHoldLayers.length === i + 1 ? '' : ';'
          }`;
        });

        mapReducer.map?.addLayer(
          new ImageLayer({
            zIndex: 1000,
            source: new ImageWMS({
              url:
                import.meta.env.VITE_QGIS_SERVER_URL ||
                'https://api.odin.prod.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs',
              params: {
                LAYERS: onHoldLayers,
                FILTER: onHoldFilters,
              },
              ratio: 1,
              serverType: 'qgis',
            }),
            visible: true,
            className: 'onHoldLayers',
            minZoom: 13,
          }),
        );
      } else {
        removeMapLayersByClassName(['onHoldLayers'], mapReducer.map!);
      }
    }
  }

  componentWillUnmount() {
    const { mapReducer } = this.props;
    const { map } = mapReducer;
    map?.dispose();
    updateMapState({
      map: undefined,
    });
  }

  async initializeMap() {
    const { updateMap } = this.props;

    // Define British National Grid Proj4js projection
    proj4.defs(
      'EPSG:27700',
      '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs',
    );
    register(proj4);

    const layerSwitcher = new LayerSwitcher({
      activationMode: 'mouseover',
      startActive: false,
      reverse: false,
      tipLabel: 'Legend',
      collapseTipLabel: 'Collapse legend',
      groupSelectStyle: 'children',
    });

    const overlay = new Overlay({
      id: 1,
      element: this.popupContainerRef.current!,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const addPointOverlay = new Overlay({
      id: 2,
      element: this.addPointPopupContainerRef.current!,
      autoPan: true,
      autoPanAnimation: {
        duration: 250,
      },
    });

    const map = new Map({
      layers: mapLayers,
      target: 'map',
      view: new View({
        center: isMobile ? [-229073, 7034508] : [-248073, 7094508],
        zoom: isMobile ? 6.2 : 6.7,
        minZoom: isMobile ? 6.2 : 6.7,
        enableRotation: false,
        constrainOnlyCenter: false,
      }),
      overlays: [overlay, addPointOverlay],
      controls: [layerSwitcher],
      interactions: DefaultInteractions({
        pinchRotate: false,
      }),
    });

    layerSwitcher?.hidePanel();

    if (this.layerSwitcherRef?.current) {
      LayerSwitcher.renderPanel(map, this.layerSwitcherRef?.current as HTMLDivElement, {});
    }

    /* We want to avoid accidental tap action on the mobile devices. This is why we use 'clickhold0 action on mobile devices. */
    let mousedown_timestamp: any;

    map?.on('singleclick', singleClick);

    function singleClick(e: any) {
      const clickHoldTime = new Date().getTime() - mousedown_timestamp;

      if (isMobile && clickHoldTime > 500) {
        showPopup(e);
      } else if (!isMobile) {
        showPopup(e);
      }
    }

    const showPopup = (e: any) => {
      const { mapReducer } = this.props;
      let coordinate = e.coordinate;

      let gridCoordinates = transform([coordinate[0], coordinate[1]], 'EPSG:3857', 'EPSG:27700');

      if (!mapReducer.addLineEnabled && !mapReducer.drawEnabled && !mapReducer.addEnabled) {
        updateMap({ infoPopupVisible: true });

        this.setState({
          lonLat: toLonLat(e.coordinate),
          XY: gridCoordinates.map((coord: any) => coord.toFixed(2)),
        });
        overlay.setPosition(coordinate);
      }
    };

    /* On each map render event, get a list of visible layers. We need this for the Dynamic Map Legend */
    map.on('postrender', () => {
      let visibleLayers: Array<string> = [];

      const groups = LayerSwitcher.getGroupsAndLayers(map, function(l: any) {
        return l.getLayers && !l.get('combine') && !LayerSwitcher.isBaseGroup(l);
      });
      groups.forEach(function(group: any) {
        LayerSwitcher.forEachRecursive(group, function(layer: any) {
          if (layer && layer.values_?.visible && layer.values_?.legend) {
            visibleLayers.push(layer.values_?.legend);
          }
        });
      });

      this.setState({ visibleLayers: [...new Set(visibleLayers)] });
    });

    map.on('pointerdrag', (e: any) => {
      this.setState({ dragging: true });
    });

    /* Save zoom level */
    map.on('moveend', (e: any) => {
      this.setState({ dragging: false });
      const { mapReducer, updateMap } = this.props;
      let zoomLevel = mapReducer.map?.getView().getZoom();

      setTimeout(() => {
        updateMap({
          zoomLevel: zoomLevel,
        });
      }, 5);
    });

    updateMap({
      map,
    });
  }

  disableGeoLocation() {
    const { updateMap, mapReducer } = this.props;
    const { map } = mapReducer;

    if (map) {
      map.getLayers().forEach((layer: any) => {
        if (layer) {
          const isVisible = layer.getVisible();
          if (isVisible) {
            if (layer.className_ === 'add_geolocation_circle') {
              map.removeLayer(layer);
            }
          }
        }
      });

      updateMap({
        isLocating: false,
        geoEnabled: false,
        geolocation: undefined,
      });

      this.state.geolocation?.setTracking(false);
      this.setState({
        geolocation: undefined,
      });
    }
  }

  enableGeoLocation() {
    const { updateMap } = this.props;
    updateMap({
      isLocating: true,
      geoEnabled: true,
    });
  }

  configureGeolocator() {
    const { updateMap, mapReducer } = this.props;
    const { geoEnabled, map } = mapReducer;

    function el(id: string) {
      return document.getElementById(id);
    }

    if (geoEnabled) {
      var view = new View({
        center: [0, 0],
        zoom: 2,
      });

      var geolocation = new Geolocation({
        // enableHighAccuracy must be set to true to have the heading value.
        trackingOptions: {
          enableHighAccuracy: true,
        },
        projection: view.getProjection(),
      });

      this.setState({
        geolocation,
      });

      geolocation.setTracking(geoEnabled);

      el('track')?.addEventListener('change', () => {
        geolocation.setTracking(geoEnabled);
      });

      geolocation.on('error', (e) => {
        console.error(e);
      });

      // update the HTML page when the position changes.
      geolocation.on('change', () => {
        const accuracy = el('accuracy');
        const altitude = el('altitude');
        const altitudeAccuracy = el('altitudeAccuracy');
        const heading = el('heading');
        const speed = el('speed');

        if (accuracy) {
          accuracy.innerText = geolocation.getAccuracy() + ' [m]';
        }
        if (altitude) {
          altitude.innerText = geolocation.getAltitude() + ' [m]';
        }
        // altitudeAccuracy.innerText = geolocation.getAltitudeAccuracy() + '
        // [m]';
        if (heading) {
          heading.innerText = geolocation.getHeading() + ' [rad]';
        }
        // el('speed').innerText = geolocation.getSpeed() + ' [m/s]';
      });

      geolocation.on('error', (error) => {
        const info = document.getElementById('info');
        if (info) {
          info.innerHTML = error.message;
          info.style.display = '';
        }
      });

      var positionFeature = new Feature();
      positionFeature.setStyle(
        new Style({
          image: new CircleStyle({
            radius: 6,
            fill: new Fill({
              color: '#3399CC',
            }),
            stroke: new Stroke({
              color: '#fff',
              width: 2,
            }),
          }),
        }),
      );

      geolocation.on('change:position', function() {
        var coordinates = geolocation.getPosition();

        if (coordinates) {
          map?.getView().fit(new Point(coordinates).getExtent(), {
            maxZoom: 19.5,
            duration: 500,
          });
          updateMap({
            isLocating: false,
          });
        }

        positionFeature.setGeometry(coordinates ? new Point(coordinates) : undefined);
      });

      const geoLayer = new VectorLayer({
        className: 'add_geolocation_circle',
        source: new VectorSource({
          features: [positionFeature],
        }),
      });

      map?.addLayer(geoLayer);

      updateMap({
        geolocation,
      });
    }
  }

  refreshVisibleLayers() {
    const { mapReducer } = this.props;

    if (mapReducer.map) {
      const getZoom = mapReducer.map.getView().getZoom();
      const newZoomIn = Number(getZoom) + 0.0000001;
      const newZoomOut = Number(getZoom) + 0.0000001;

      mapReducer.map?.getView().setZoom(newZoomIn);
      mapReducer.map?.getView().setZoom(newZoomOut);
    }
  }

  renderMapCursor() {
    const { mapReducer } = this.props;

    if (this.state.dragging) {
      return 'move';
    } else if (mapReducer.addEnabled) {
      return 'crosshair';
    } else {
      return 'default';
    }
  }

  createRibbonMessage = (createType: 'Line' | 'Point') => {
    const { mapReducer } = this.props;

    if (mapReducer.createLinked?.toType) {
      return (
        <span
          style={{ fontWeight: 500 }}
        >{`Adding a new ${mapReducer.createLinked?.toType} to ${mapReducer.createLinked?.fromType} ${mapReducer.createLinked?.fromId}`}</span>
      );
    } else if (mapReducer.isCreatingRFC && mapReducer.addEnabled) {
      return <span style={{ fontWeight: 500 }}>Adding a new Survey structure</span>;
    } else if (mapReducer.isCreatingRFC && mapReducer.addLineEnabled) {
      return <span style={{ fontWeight: 500 }}>Adding a new Survey route</span>;
    } else {
      return (
        <span style={{ fontWeight: 500 }}>
          Adding a new {mapReducer.isAddingHazard ? 'Hazard' : createType}
        </span>
      );
    }
  };

  calculateSidebarHeight = () => {
    return 'calc(100vh - 38px)';
  };

  render() {
    const { mapReducer, updateMap } = this.props;
    const { geoEnabled, isLocating } = mapReducer;
    const { Content } = Layout;

    return (
      <Layout style={{ overflow: 'hidden', height: '100%' }}>
        {/* Map Collapse/Show Floating Button*/}
        <Button
          shape="circle"
          size="large"
          icon={<MenuOutlined style={{ fontSize: '14px' }} />}
          style={{ display: mapReducer.mapSidebarVisible ? 'none' : 'block' }}
          className="floatingSidebarButton floatingSidebarButtonNoTabs"
          onClick={() => updateMap({ mapSidebarVisible: !mapReducer.mapSidebarVisible })}
        />
        <Button
          type="primary"
          shape="circle"
          size="large"
          className={`floatingLocateMeButton ${geoEnabled ? 'activeFloatingButton' : ''}`}
          icon={<AimOutlined />}
          loading={isLocating}
          onClick={() => (geoEnabled ? this.disableGeoLocation() : this.enableGeoLocation())}
        ></Button>

        <RFCCreate />
        <AddPointInterface />
        <AddLineInterface />
        <AddHazardInterface />
        <FeatureSelect />
        <FeatureBulkUpdate />

        <div
          className="ol-popup"
          ref={this.addPointPopupContainerRef}
          style={{
            display: mapReducer.addPointConfirmVisible && mapReducer.addEnabled ? 'block' : 'none',
          }}
        >
          <div onClick={() => updateMap({ addPointConfirmVisible: false })}>
            <a href="#" className="ol-popup-closer" />
          </div>
          <Row className="popup-content" style={{ textAlign: 'center' }}>
            <Col span={24} style={{ marginTop: '10px' }}>
              <Typography.Text>
                {mapReducer.createLinked?.toType
                  ? mapReducer.createLinked?.toType
                  : mapReducer.isAddingHazard
                    ? 'Hazard'
                    : 'Point'}{' '}
                will be located here.
              </Typography.Text>
            </Col>
            <Col span={24} style={{ marginTop: '10px' }}>
              <Button
                style={{ width: '100%' }}
                type="primary"
                onClick={() => updateMap({ addPointFlow: true })}
              >
                OK
              </Button>
            </Col>
          </Row>
        </div>

        <div
          className="ol-popup"
          ref={this.popupContainerRef}
          style={{
            display: mapReducer.infoPopupVisible ? 'block' : 'none',
            minWidth: '290px',
          }}
        >
          <div onClick={() => updateMap({ infoPopupVisible: false })}>
            <a href="#" className="ol-popup-closer" />
          </div>
          <Row className="popup-content" style={{ textAlign: 'center' }}>
            <Col span={24} style={{ marginTop: '10px' }}>
              <Descriptions
                title="Coordinates"
                column={{ xxl: 1, xl: 1, lg: 1, md: 1, sm: 1, xs: 1 }}
                size="small"
                bordered
              >
                <Descriptions.Item label="Eastings">{this.state.XY[0]}</Descriptions.Item>
                <Descriptions.Item label="Northings">{this.state.XY[1]}</Descriptions.Item>
              </Descriptions>
            </Col>
            <Col span={24} style={{ marginTop: '15px' }}>
              <a
                href={`http://maps.google.com/maps?q=&layer=c&cbll=${this.state.lonLat[1]},${this.state.lonLat[0]}`}
                target="_blank"
              >
                <Button style={{ width: '100%' }} type="primary">
                  Open in Street View
                </Button>
              </a>
            </Col>
          </Row>
        </div>

        <Content>
          <Row>
            <Col span={24}>
              {/* Map alerts */}
              {mapReducer.addEnabled && (
                <Alert
                  style={{ zIndex: 100, width: '100%', borderRadius: 0 }}
                  message={
                    <>
                      {mapReducer.isCreatingRFC ? (
                        <span
                          style={{
                            padding: '3px 5px',
                            color: 'white',
                            background: '#1990ff',
                            borderRadius: 3,
                            marginRight: 8,
                          }}
                        >
                          RFC
                        </span>
                      ) : (
                        <></>
                      )}
                      {this.createRibbonMessage('Point')}
                    </>
                  }
                  description={`Click or tap on the map to add a Point`}
                  type="info"
                />
              )}

              {mapReducer.addLineEnabled && (
                <Alert
                  style={{ zIndex: 100, width: '100%' }}
                  message={
                    <>
                      {mapReducer.isCreatingRFC ? (
                        <span
                          style={{
                            padding: '3px 5px',
                            color: 'white',
                            background: '#1990ff',
                            borderRadius: 3,
                            marginRight: 8,
                          }}
                        >
                          RFC
                        </span>
                      ) : (
                        <></>
                      )}
                      {this.createRibbonMessage('Line')}
                    </>
                  }
                  description="Click to add point and click again to end line. Repeat for lines with multiple vertice."
                  type="info"
                />
              )}
            </Col>
          </Row>
          {/* Adjust map sidebar height by subtracting top menu height and/or recent tab height */}
          <Row style={{ height: this.calculateSidebarHeight() }}>
            {/* Map Sidebar */}
            <MapSideBar
              visibleLayers={this.state.visibleLayers}
              layerSwitcherRef={this.layerSwitcherRef}
            />
            {/* Map Sidebar - Flex fill on the right. */}
            <Col flex="auto" style={{ background: 'transparent' }} />
          </Row>
        </Content>
        <div
          className={mapReducer.greyscaleMap ? 'greyscale' : ''}
          id="map"
          style={{
            touchAction: 'none',
            position: 'fixed',
            cursor: this.renderMapCursor(),
          }}
        />
        {/* Quick View Drawer for Work Items */}
        <WorkItemQuickView />
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  searchMap: (params: MapSearch) => dispatch(setMapSearchQuery(params)),
});

export default withRouter(connect(mapState, mapDispatch)(PlanningModuleMap));
