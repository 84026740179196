import { useState } from 'react';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { DashboardData, DashboardLineItem, FetchStatus } from './types';

export type FetchOneActions<T extends DashboardData> = ReturnType<
  typeof useFetchOneState<T>
>['actions'];

export const useFetchState = () => {
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [data, setData] = useState<DbRecordEntityTransform[]>([]);
  const [selected, setSelected] = useState<string>('');
  const state = {
    status,
    data,
    selected,
  };

  const actions = {
    setStatus,
    setData,
    setSelected,
  };

  return { state, actions };
};

export const useFetchOneState = <T extends DashboardData>() => {
  const [status, setStatus] = useState<FetchStatus>('idle');
  const [data, setData] = useState<T | undefined>();
  const [filters, setFilters] = useState<Record<string, string[]>>({
    project_title: [],
    qgis_feature_id: [],
  });
  const [selectedLineItems, setSelectedLineItems] = useState<DashboardLineItem[]>([]);

  const state = {
    status,
    data,
    filters,
    selectedLineItems,
  };

  const setFilter = (name: string, value: string[]) => {
    setFilters((filters) => ({
      ...filters,
      [name]: value,
    }));
  };

  const updateRejectedLineItem = (
    fpeId: string,
    properties: Partial<Record<keyof DashboardLineItem, string>>,
  ) => {
    actions.setData((data) => {
      if (!data) return undefined;

      const updatedLineItems = data.lineItems.map((li) =>
        li.fpe_id === fpeId && li.is_approved === 'false' ? { ...li, ...properties } : li,
      );
      return {
        ...data,
        lineItems: updatedLineItems,
      };
    });
  };

  const updateUnsubmittedLineItemByTitle = (
    title: string,
    properties: Partial<Record<keyof DashboardLineItem, string>>,
  ) => {
    actions.setData((data) => {
      if (!data) return undefined;

      const updatedLineItems = data.lineItems.map((li) =>
        li.fpe_title === title && li.status === 'Not Submitted' ? { ...li, ...properties } : li,
      );
      return {
        ...data,
        lineItems: updatedLineItems,
      };
    });
  };

  const resetFilters = () =>
    setFilters((filters) => {
      return Object.keys(filters).reduce((newFilter, key) => {
        newFilter[key] = [];
        return newFilter;
      }, {} as Record<string, string[]>);
    });

  const actions = {
    setStatus,
    setData,
    setFilter,
    resetFilters,
    setSelectedLineItems,
    updateRejectedLineItem,
    updateUnsubmittedLineItemByTitle,
  };

  return { state, actions };
};
