export const SEARCH_WORKFLOWS_BY_QUERY_REQUEST = 'SEARCH_WORKFLOWS_BY_QUERY_REQUEST';
export const SEARCH_WORKFLOWS_BY_QUERY_SUCCESS = 'SEARCH_WORKFLOWS_BY_QUERY_SUCCESS';
export const SEARCH_WORKFLOWS_BY_QUERY_ERROR = 'SEARCH_WORKFLOWS_BY_QUERY_ERROR';

export const CLEAR_WORKFLOWS_SEARCH = 'CLEAR_WORKFLOWS_SEARCH';

export const GET_WORKFLOW_BY_ID_REQUEST = 'GET_WORKFLOW_BY_ID_REQUEST';
export const GET_WORKFLOW_BY_ID_SUCCESS = 'GET_WORKFLOW_BY_ID_SUCCESS';
export const GET_WORKFLOW_BY_ID_ERROR = 'GET_WORKFLOW_BY_ID_ERROR';

export const PROCESS_WORKFLOW_REQUEST = 'PROCESS_WORKFLOW_REQUEST';
export const PROCESS_WORKFLOW_SUCCESS = 'PROCESS_WORKFLOW_SUCCESS';
export const PROCESS_WORKFLOW_ERROR = 'PROCESS_WORKFLOW_ERROR';
