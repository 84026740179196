import React, { FunctionComponent, useState } from 'react';
import { mapLayersLegend } from '../../mapLayersLegend';
import { Card, Col, Input, Row } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import '../../styles.scss';

interface OwnProps {
  visibleLayers: Array<string>;
}

type Props = OwnProps;

const MapSidebarDynamicLegend: FunctionComponent<Props> = (props) => {
  const { visibleLayers } = props;
  let allVisibleLayers: Array<any> = [];
  const [query, setQuery] = useState<string>('');

  const fetchVisibleLayers = () => {
    if (visibleLayers.length) {
      visibleLayers.forEach((visibleLayer: any) => {
        const filteredLayerCollection = mapLayersLegend.find(
          (layer: any) => layer.layerName === visibleLayer,
        );

        if (filteredLayerCollection && filteredLayerCollection?.layerItems?.length) {
          filteredLayerCollection.layerItems.forEach((layer: any) => {
            allVisibleLayers.push(layer);
          });
        }
      });
    }

    return allVisibleLayers;
  };

  const renderVisibleLayers = () => {
    const layerItems = fetchVisibleLayers();

    if (layerItems.length) {
      return layerItems.map((layer: any) => {
        if (
          !query.length ||
          (query.length && layer.name.toLowerCase().indexOf(query.toLowerCase()) > -1)
        ) {
          return (
            <Col span={24} key={`col-${layer.name}`}>
              <img
                src={`/qgis-symbols/${layer.file}.png`}
                alt={layer.name}
                style={{ marginRight: 8, marginBottom: 4, verticalAlign: 'middle' }}
              />
              <span>{layer.name}</span>
            </Col>
          );
        }
      });
    }
  };

  return (
    <Card size="small" title={'Key'} className="enabledLayersCard">
      <Row className="layerList">{renderVisibleLayers()}</Row>
      <Row className="layerSearch">
        <Col span={24}>
          <Input
            allowClear
            placeholder="Quick search..."
            prefix={<SearchOutlined />}
            disabled={fetchVisibleLayers()?.length === 0}
            onChange={(e: any) => {
              setQuery(e.target.value);
            }}
            style={{ borderRadius: 4 }}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default MapSidebarDynamicLegend;
