import { SchemaCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/dto/schema.create.update.dto';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeCreateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/dto/schema.type.create.dto';
import {
  BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST,
  BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST,
  CLEAN_SCHEMA_SHORTLIST,
  CREATE_SCHEMA_ACTION_REQUEST,
  CREATE_SCHEMA_REQUEST,
  CREATE_SCHEMA_TYPE_REQUEST,
  DELETE_SCHEMA_ACTION_REQUEST,
  DELETE_SCHEMA_BY_ID_REQUEST,
  DELETE_SCHEMA_TYPE_REQUEST,
  GET_SCHEMA_ACTION_LIST_REQUEST,
  GET_SCHEMA_BY_ID_REQUEST,
  GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST,
  GET_SCHEMAS_BY_MODULE_REQUEST,
  GET_SINGLE_SCHEMA_ACTION_REQUEST,
  LIST_SCHEMAS_REQUEST,
  REFRESH_SCHEMA_SHORTLIST_REQUEST,
  UPDATE_SCHEMA_ACTION_REQUEST,
  UPDATE_SCHEMA_BY_ID_REQUEST,
} from './constants';

// Interfaces
export interface ISchemaById {
  schemaId: string;
  format?: string;
}

export interface ISchemaByModule {
  moduleName: string;
}

export interface ISchemaByModuleAndEntity {
  moduleName: string;
  entityName: string;
  withAssociations?: boolean;
}

export interface CreateSchema {
  body: SchemaCreateUpdateDto;
}

export interface CreateSchemaType {
  schemaId: string;
  body: SchemaTypeCreateDto;
}

export interface DeleteSchemaType {
  schemaId: string;
  schemaTypeId: string;
}

// Schema Action Interfaces
export interface ISchemaActionsList {
  schemaId: string;
}

export interface ISchemaActionCreate {
  description: string;
  isCreate: boolean;
  isUpdate: boolean;
  isStepFlow: boolean;
  name: string;
  schemaAssociationId?: string;
  schemaId: string;
  schemaTypeId?: string;
  defaultForm?: boolean;
  userAction?: boolean;
  stages?: string[];
  targetStages?: string[];
}

export interface ISchemaActionUpdate {
  schemaActionId: string;
  description?: string;
  definition?: string;
  isCreate?: boolean;
  isUpdate?: boolean;
  isStepFlow?: boolean;
  name?: string;
  schemaAssociationId?: string;
  schemaId?: string;
  schemaTypeId?: string;
  defaultForm?: boolean;
  userAction?: boolean;
  stages?: any[];
  targetStages?: any[];
}

export function listSchemasRequest(cb?: any) {
  return {
    type: LIST_SCHEMAS_REQUEST,
    cb,
  };
}

export function getSchemasByModuleRequest(params: ISchemaByModule) {
  return {
    type: GET_SCHEMAS_BY_MODULE_REQUEST,
    params,
  };
}

export function getSchemaByModuleAndEntityRequest(
  params: ISchemaByModuleAndEntity,
  cb = (schema: SchemaEntity) => {},
) {
  return {
    type: GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST,
    params,
    cb,
  };
}

export function getSchemaByIdRequest(params: ISchemaById, cb = (_: any) => {}) {
  return {
    type: GET_SCHEMA_BY_ID_REQUEST,
    params,
    cb,
  };
}

export function createSchemaRequest(params: CreateSchema, cb = () => {}) {
  return {
    type: CREATE_SCHEMA_REQUEST,
    params,
    cb,
  };
}

export function updateSchemaRequest(params: any, cb = () => {}) {
  return {
    type: UPDATE_SCHEMA_BY_ID_REQUEST,
    params,
    cb,
  };
}

export function deleteSchemaById(params: any) {
  return {
    type: DELETE_SCHEMA_BY_ID_REQUEST,
    params,
  };
}

export function batchCreatePermissionsBySchemaId(params: any, cb = () => {}) {
  return {
    type: BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST,
    params,
    cb,
  };
}

export function batchDeletePermissionsBySchemaId(params: any, cb = () => {}) {
  return {
    type: BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST,
    params,
    cb,
  };
}

// Schema Types
export function createSchemaTypeRequest(params: CreateSchemaType) {
  return {
    type: CREATE_SCHEMA_TYPE_REQUEST,
    params,
  };
}

export function deleteSchemaTypeRequest(params: DeleteSchemaType) {
  return {
    type: DELETE_SCHEMA_TYPE_REQUEST,
    params,
  };
}

export function cleanSchemaShortlist() {
  return {
    type: CLEAN_SCHEMA_SHORTLIST,
  };
}

export function refreshSchemaShortlist(params: { schemaIds: string[] }) {
  return {
    type: REFRESH_SCHEMA_SHORTLIST_REQUEST,
    params,
  };
}

export function getSchemaActionsList(params: ISchemaActionsList, cb = () => {}) {
  return {
    type: GET_SCHEMA_ACTION_LIST_REQUEST,
    params,
    cb,
  };
}

export function getSingleSchemaAction(params: { schemaActionId: string }, cb = () => {}) {
  return {
    type: GET_SINGLE_SCHEMA_ACTION_REQUEST,
    params,
    cb,
  };
}

export function createSchemaAction(params: ISchemaActionCreate, cb = () => {}) {
  return {
    type: CREATE_SCHEMA_ACTION_REQUEST,
    params,
    cb,
  };
}

export function updateSchemaAction(params: ISchemaActionUpdate, cb = () => {}) {
  return {
    type: UPDATE_SCHEMA_ACTION_REQUEST,
    params,
    cb,
  };
}

export function deleteSchemaAction(params: { schemaActionId: string }, cb = () => {}) {
  return {
    type: DELETE_SCHEMA_ACTION_REQUEST,
    params,
    cb,
  };
}
