import React from 'react';
import { Col, Layout, Row } from 'antd';
import ServiceAppointmentDetails from './components/Calendar';
import { CalendarProvider } from './context/provider';
import './styles.scss';

export const ServiceAppointmentCalendarV2: React.FC = () => {
  return (
    <CalendarProvider>
      <Layout>
        <Row gutter={4}>
          <Col span={24}>
            <ServiceAppointmentDetails />
          </Col>
        </Row>
      </Layout>
    </CalendarProvider>
  );
};
