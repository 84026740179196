import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../../index';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  MY_CASES_SET_CASE_UNREAD_STATUS,
  MY_CASES_SET_SELECTED_CASE,
} from '../../../store/constants';
import CommunicationsIcon from '../../CommunicationsIcon';
import CommunicationsStatusTag from '../../CommunicationsStatusTag';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { getCaseContactFromRecord } from '../../../../SupportDashboard/helpers';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaColumnOptionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import CommunicationsBadge from '../../CommunicationsBadge';
import { ContextMenu, Menu, MenuItem } from '@blueprintjs/core';

interface Props {
  Case: DbRecordEntityTransform;
  index: number;
  schema: SchemaEntity;
}

const MyCasesListItem: React.FC<Props> = (props: Props) => {
  const { index, Case, schema } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const { selectedCase } = state;

  const handleCaseClick = () => {
    dispatch({ type: MY_CASES_SET_SELECTED_CASE, payload: Case });
    dispatch({
      type: MY_CASES_SET_CASE_UNREAD_STATUS,
      payload: { caseId: Case.id, status: 'READ' },
    });
  };

  const isCaseSelected = () => {
    return selectedCase?.id === Case.id;
  };

  const getENUMOptionLabel = (colName: string, value: string) => {
    let response: string = '-';
    if (schema && colName && value) {
      const col: SchemaColumnEntity | undefined = schema.columns.find(
        (c: SchemaColumnEntity) => c.name === colName,
      );
      return (
        col?.options?.find((option: SchemaColumnOptionEntity) => option.value === value)?.label ||
        '-'
      );
    }
    return response;
  };

  return (
    <ContextMenu
      content={
        <Menu>
          <MenuItem
            text={Case.status === 'UNREAD' ? 'Mark as Read' : 'Mark as Unread'}
            onClick={() => {
              if (Case.status === 'UNREAD') {
                dispatch({
                  type: MY_CASES_SET_CASE_UNREAD_STATUS,
                  payload: { caseId: Case.id, status: 'READ' },
                });
              } else {
                dispatch({
                  type: MY_CASES_SET_CASE_UNREAD_STATUS,
                  payload: { caseId: Case.id, status: 'UNREAD' },
                });
              }
            }}
          />
        </Menu>
      }
    >
      <Col span={24} key={`Col1_${Case.id}`}>
        <Row
          align="middle"
          key={`Row1_${Case.id}`}
          onClick={handleCaseClick}
          style={{
            cursor: 'pointer',
            padding: '15px 8px',
            borderLeft: isCaseSelected() ? '3px solid blue' : '3px solid #fff',
            borderTop: index > 0 ? '1px solid #D9DADA' : 'auto',
          }}
        >
          {/* Read / Unread badge */}
          <Col span={1} key={`Col2_${Case.id}`}>
            <CommunicationsBadge Status={Case.status} />
          </Col>
          <Col span={5} style={{ paddingLeft: 5 }} key={`Col3_${Case.id}`}>
            <CommunicationsIcon type={getProperty(Case, 'Channel') || 'Unknown'} />
          </Col>
          <Col span={9} key={`Col4_${Case.id}`}>
            <Row key={`Row2_${Case.id}`}>
              <Col span={24} key={`Col5_${Case.id}`}>
                <span
                  key={`Span1_${Case.id}`}
                  style={{ fontWeight: Case.status === 'UNREAD' ? 600 : 400 }}
                >
                  {getProperty(Case, 'Source') || 'Unknown'}
                </span>
              </Col>
              <Col span={24} key={`Col6_${Case.id}`}>
                <span key={`Span2_${Case.id}`} style={{ color: '#444E56' }}>
                  {getProperty(Case, 'ContactId') ? getCaseContactFromRecord(Case) : 'Unknown'}
                </span>
              </Col>
              <Col span={24} key={`Col7_${Case.id}`} style={{}}>
                <span key={`Span3_${Case.id}`} style={{ color: '#778b9b', fontSize: '0.9em' }}>
                  {getENUMOptionLabel('Category', getProperty(Case, 'Category')) || '-'}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={9} style={{ textAlign: 'right' }} key={`Col8_${Case.id}`}>
            <Row key={`Row3_${Case.id}`}>
              <Col span={24} key={`Col9_${Case.id}`}>
                <CommunicationsStatusTag key={`CommTag1_${Case.id}`} status={Case?.stage?.name} />
              </Col>
              <Col span={24} style={{ paddingRight: 4, marginTop: 10 }} key={`Col10_${Case.id}`}>
                <span key={`Span4_${Case.id}`} style={{ fontSize: '0.9em', color: '#545A5D' }}>
                  {dayjs(Case.createdAt).format('D MMM HH:mm')}
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </ContextMenu>
  );
};

export default MyCasesListItem;
