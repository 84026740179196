import { CaretDownFilled } from '@ant-design/icons';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Descriptions, Dropdown, MenuProps, Skeleton, Space } from 'antd';
import dayjs from 'dayjs';
import { FC, useEffect, useState } from 'react';
import ViewConfigurationUpdateModal from '../ViewConfigurations/ViewConfigurationUpdateModal';
import { connect } from 'react-redux';
import {
  toggleSchemaActionCreateEditDrawer,
  toggleViewConfigurationCreateEditDrawer,
} from '@legacy/core/userInterface/store/actions';
import { getPipelinesByModuleAndEntity } from '@legacy/core/pipelines/store/actions';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { Button, Section } from '@blueprintjs/core';
interface Props {
  schema: SchemaEntity | undefined;
  viewConfig: any;
  refreshAction: (id: string) => void;
  toggleDrawer: () => void;
  getPipeline: (payload: any, cb?: any) => void;
}

const ViewConfigurationLeftPanel: FC<Props> = (props) => {
  const { schema, viewConfig, refreshAction, toggleDrawer, getPipeline } = props;
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);

  const excludedProperties = ['definition'];

  useEffect(() => {
    if (schema) {
      getPipeline({ schema }, (data: any) => {
        if (data.length > 0) {
          setPipeline(data[0]);
        }
      });
    }
  }, [schema]);

  const renderLabel = (key: any, value: any) => {
    if (['isCreate', 'isUpdate'].includes(key)) {
      return JSON.stringify(value);
    } else if (['createdAt', 'updatedAt'].includes(key)) {
      return dayjs(value).format('DD/MM/YYYY HH:mm:ss');
    } else {
      return value;
    }
  };

  const renderViewConfig = (action: any) => {
    const actionObjectKeys: string[] = Object.keys(action);

    // remove excluded properties
    excludedProperties.forEach((property) => {
      const index = actionObjectKeys.indexOf(property);
      if (index > -1) {
        actionObjectKeys.splice(index, 1);
      }
    });

    // sort object keys so that the 'name' is on top
    actionObjectKeys.sort((a: any, b: any) => {
      if (['name'].includes(a)) {
        return -1;
      } else if (['description'].includes(a)) {
        return 0;
      } else {
        return 1;
      }
    });

    return (
      <Descriptions
        layout="horizontal"
        bordered
        column={1}
        size="small"
        labelStyle={{ fontSize: '0.9em', fontWeight: 500 }}
        contentStyle={{ fontSize: '0.9em' }}
      >
        {actionObjectKeys.map((key: string) => {
          return (
            <Descriptions.Item label={key}>
              {renderLabel(key, action[key as keyof SchemaActionEntity])}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    );
  };

  const enableAccessControl = () => {};

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: 'Edit Action',
      onClick: toggleDrawer,
    },
    {
      key: '2',
      label: 'Enable Access Control',
      onClick: enableAccessControl,
    },
  ];

  return (
    <Section
      title="View Configuration"
      rightElement={
        <Dropdown menu={{ items }} trigger={['click']}>
          <Button style={{ padding: '0 8px' }}>
            <Space>
              <CaretDownFilled />
            </Space>
          </Button>
        </Dropdown>
      }
    >
      <Skeleton loading={!schema || !viewConfig}>
        {viewConfig && renderViewConfig(viewConfig)}
      </Skeleton>
      <ViewConfigurationUpdateModal
        isCreate={false}
        configToUpdate={viewConfig}
        schemaId={schema?.id}
        onSuccess={refreshAction}
        pipeline={pipeline}
      />
    </Section>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  toggleDrawer: () => dispatch(toggleViewConfigurationCreateEditDrawer()),
  getPipeline: (params: { schema: SchemaEntity }, cb: any) =>
    dispatch(getPipelinesByModuleAndEntity(params, cb)),
});

export default connect(mapState, mapDispatch)(ViewConfigurationLeftPanel);
