import React, { useEffect, useState } from 'react';
import { Col, Form, Input, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import { SchemaDetailsProps } from '../types';
import { Button, Switch } from '@blueprintjs/core';
import { updateSchemaRequest } from '@legacy/core/schemas/store/actions';
import { useRequest } from '@core/hooks/useRequest';

export const RecordNumbersForm = ({ schema }: SchemaDetailsProps) => {
  const formRef = React.createRef<FormInstance>();
  const updateSchema = useRequest(updateSchemaRequest);

  const [favoriteSchemas, setFavoriteSchemas] = useState<string[]>([]);
  const [canUpdate, setCanUpdate] = useState<boolean>(false);

  useEffect(() => {
    fetchFavoriteSchemas();
  }, []);

  const fetchFavoriteSchemas = () => {
    const favorites = localStorage.getItem('OdinSchemaFavorites');
    if (favorites) {
      setFavoriteSchemas(JSON.parse(favorites));
    }
  };

  const addOrRemoveFavorite = () => {
    const id = schema?.id;
    let favorites = Object.assign([], favoriteSchemas);

    if (favorites.includes(id)) {
      const index = favorites.indexOf(id);
      if (index > -1) {
        favorites.splice(index, 1);
      }
    } else {
      favorites.push(id);
    }
    setFavoriteSchemas(favorites);
    localStorage.setItem('OdinSchemaFavorites', JSON.stringify(favorites));
  };

  const handleFormSubmit = () => {
    const data = formRef.current?.getFieldsValue();
    updateSchema({ schemaId: schema.id, data: { ...schema, ...data } });
    setCanUpdate(false);
  };

  const isSchemaFavorite = favoriteSchemas.includes(schema?.id);

  // Check if form values differ from schema
  const validateForm = () => {
    const data = formRef.current?.getFieldsValue();

    if (
      Number(data?.recordNumber) !== schema?.recordNumber ||
      data?.recordNumberPrefix !== schema?.recordNumberPrefix ||
      data?.isSequential !== schema?.isSequential
    ) {
      setCanUpdate(true);
    } else {
      setCanUpdate(false);
    }
  };

  const initialValues = () => {
    if (schema) {
      return {
        recordNumber: schema.recordNumber,
        recordNumberPrefix: schema.recordNumberPrefix,
        isSequential: schema.isSequential,
      };
    } else {
      return {
        recordNumber: 0,
        recordNumberPrefix: '',
        isSequential: false,
      };
    }
  };

  return (
    <Form
      layout={'vertical'}
      ref={formRef!}
      initialValues={initialValues()}
      onChange={validateForm}
    >
      <Col span={24}>
        <Row>
          <Col span={12}>
            <h3 title="5" style={{ marginTop: 0, marginBottom: 40 }}>
              Record Numbers
            </h3>
          </Col>
        </Row>
        <Row gutter={12}>
          <Col span={10}>
            <Form.Item className="form-item" label="Starting Record Number" name="recordNumber">
              <Input placeholder="Starting Record Number" type="number" min={0} />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item className="form-item" label="Record Number Prefix" name="recordNumberPrefix">
              <Input
                placeholder="Record Number Prefix"
                onChange={(e) => {
                  if (e.target.value === '') {
                    formRef.current?.setFieldValue('recordNumberPrefix', null);
                  } else {
                    formRef.current?.setFieldValue('recordNumberPrefix', e.target.value);
                  }
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item className="form-item" name="isSequential">
              <Switch
                large
                defaultChecked={schema.isSequential}
                onChange={(e) => formRef.current?.setFieldValue('isSequential', e.target.checked)}
              >
                Auto-increment Record Numbers
              </Switch>
            </Form.Item>
          </Col>
        </Row>
        <Row justify="space-between" style={{ marginTop: 10, marginBottom: 10 }}>
          <Col>
            <Button
              minimal
              outlined
              style={{ borderRadius: 5 }}
              intent={isSchemaFavorite ? 'warning' : 'success'}
              onClick={addOrRemoveFavorite}
              text={isSchemaFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
            />
          </Col>
          <Col>
            <Button
              text="Save Changes"
              fill
              intent="primary"
              onClick={handleFormSubmit}
              disabled={!canUpdate}
              style={{ borderRadius: 5 }}
            />
          </Col>
        </Row>
      </Col>
    </Form>
  );
};
