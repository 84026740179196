import { Section, SectionCard } from "@blueprintjs/core";

import { SchemaCardinality } from "./SchemaCardinality";
import { SchemaDropdown } from "./SchemaDropdown";
import { BaseFormProps } from "./types";
import { ActionsDropdown } from "./ActionsDropdown";

interface ChildFormProps extends BaseFormProps {};

export const ChildForm = ({ link, cardinalityProps, schemaProps, actionsProps }: ChildFormProps) => {
  const { value: schema, onChange: setSchema } = schemaProps;
  const subtitle = schema ? schema.name : `Select the Child Schema`;

  const {
    value: cardinality, onChange: setCardinality,
  } = cardinalityProps;

  const {
    value: action, onChange: setAction,
  } = actionsProps

  return (
    <Section
      title='Child'
      subtitle={subtitle}
      icon="diagram-tree"
      className='childForm'
    >
      <SectionCard>
        <h3>Schema</h3>
        <SchemaDropdown
          selectedItem={schema}
          setSelectedItem={setSchema}
          disabled={!!link}
        />
      </SectionCard>

      <SectionCard>
        <h3>Cardinality</h3>
        <SchemaCardinality value={cardinality} onChange={setCardinality} />
      </SectionCard>
      <SectionCard>
        <h3>Actions</h3>
        <ActionsDropdown
          value={action}
          onChange={setAction}
        />
      </SectionCard>
    </Section>
  );
};
