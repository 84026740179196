import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  ADD_FILE_TO_SELECTION,
  CLEAR_FILE_SELECTION,
  REMOVE_FILE_FROM_SELECTION,
  RESET_FILE_IMPORT_REDUCER,
  SET_ALL_RELATED_RECORDS,
  SET_FILE_LIST,
  SET_IS_IMPORTING_FILES,
  SET_LOADING_FILES,
  SET_LOADING_PARENT_ASSOCIATIONS,
  SET_PARENT_RECORD,
  SET_SEARCH_TERM,
  SET_SELECTED_RECORD,
} from './constants';

interface IUploadFileFromAssociationReducer {
  relatedRecords: DbRecordEntityTransform[]; // Directories
  parentRecord: DbRecordEntityTransform | undefined; // Parent record
  selectedRecord: DbRecordEntityTransform | undefined; // Selected directory
  fileList: DbRecordEntityTransform[]; // List of files related to selected record/directory
  selectedFiles: DbRecordEntityTransform[]; // List of selected files that we will re-upload to parent record
  loadingParentAssociations: boolean;
  loadingFiles: boolean;
  searchTerm: string;
  importingFiles: boolean;
}

export const uploadFileFromAssociationInitialState: IUploadFileFromAssociationReducer = {
  relatedRecords: [],
  parentRecord: undefined,
  selectedRecord: undefined,
  loadingParentAssociations: false,
  loadingFiles: false,
  fileList: [],
  selectedFiles: [],
  searchTerm: '',
  importingFiles: false,
};

export function uploadFileFromAssociationReducer(
  state: IUploadFileFromAssociationReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case SET_PARENT_RECORD:
      return {
        ...state,
        parentRecord: action.payload,
      };
    case RESET_FILE_IMPORT_REDUCER:
      return uploadFileFromAssociationInitialState;

    case SET_SELECTED_RECORD:
      return {
        ...state,
        selectedRecord: action.payload,
        fileList: [],
        loadingFiles: true,
        searchTerm: '',
      };

    case SET_ALL_RELATED_RECORDS:
      return {
        ...state,
        relatedRecords: action.payload,
      };

    case SET_FILE_LIST:
      return {
        ...state,
        fileList: action.payload,
      };

    case SET_LOADING_PARENT_ASSOCIATIONS:
      return {
        ...state,
        loadingParentAssociations: action.payload,
      };

    case SET_LOADING_FILES:
      return {
        ...state,
        loadingFiles: action.payload,
      };
    case CLEAR_FILE_SELECTION:
      return {
        ...state,
        selectedFiles: [],
      };

    case ADD_FILE_TO_SELECTION:
      return {
        ...state,
        selectedFiles: [...state.selectedFiles, action.payload],
      };

    case REMOVE_FILE_FROM_SELECTION:
      return {
        ...state,
        selectedFiles: state.selectedFiles.filter((f: any) => f.id !== action.payload.id),
      };

    case SET_SEARCH_TERM:
      return {
        ...state,
        searchTerm: action.payload,
      };

    case SET_IS_IMPORTING_FILES:
      return {
        ...state,
        importingFiles: action.payload,
      };

    default:
      throw new Error();
  }
}
