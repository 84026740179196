import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Popover } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { useDropzone } from 'react-dropzone';

interface Props {
  files: File[];
  setFiles: any;
  disabled?: boolean;
  large?: boolean;
}

const FileUploaderPopoverButton: React.FC<Props> = (props: Props) => {
  const { disabled, files, setFiles, large } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);

  useEffect(() => {
    setFiles(files);
  }, [files]);

  const onDrop = useCallback(
    (acceptedFiles) => {
      setFiles([...files, ...acceptedFiles]);
    },
    [files],
  );

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } = useDropzone({
    accept: {
      'image/*': [],
      'application/pdf': ['.pdf'],
    },
    maxFiles: 5,
    onDrop: onDrop,
  });

  const removeFile = (file: any) => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const baseStyle = {
    alignItems: 'center',
    width: '100%',
    padding: '13px',
    borderWidth: 2,
    borderRadius: 8,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    textAlign: 'center',
    height: '100px',
    lineHeight: '10px',
    cursor: 'pointer',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  };

  const focusedStyle = {
    borderColor: '#2196f3',
  };

  const acceptStyle = {
    borderColor: '#00e676',
  };

  const rejectStyle = {
    borderColor: '#ff1744',
  };

  const style: any = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject],
  );

  const formatByteSize = (bytes: number): string => {
    if (bytes < 1024 * 1024) {
      // Less than 1 MB, show in KB
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else {
      // 1 MB or more, show in MB
      return `${(bytes / (1024 * 1024)).toFixed(2)} MB`;
    }
  };

  const sanitizeFilePath = (path: string) => {
    //   truncate if path is too long, add ... in between in string is longer than 20 characters
    if (path.length > 25) {
      const firstPart = path.slice(0, 12);
      const lastPart = path.slice(-12);
      return `${firstPart}...${lastPart}`;
    } else {
      return path;
    }
  };

  return (
    <Popover
      usePortal={true}
      position="top"
      isOpen={isPopoverOpen}
      onClose={() => setIsPopoverOpen(false)}
      content={
        <Row
          style={{
            minHeight: 120,
            width: 380,
            padding: 8,
            alignContent: 'flex-start',
          }}
        >
          {/* Header */}
          <Col span={24}>
            <Row align="middle">
              <Col span={12} style={{ paddingLeft: 8 }}>
                <span style={{ fontWeight: 500 }}>File Attachments</span>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <Button minimal small icon="cross" onClick={() => setIsPopoverOpen(false)} />
              </Col>
            </Row>
          </Col>

          {/*  Upload */}
          <Col span={24} style={{ padding: 8 }}>
            <div className="fileUploaderPopoverButtonContainer">
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <Row>
                  <Col span={24} style={{ marginBottom: 15 }}>
                    <i className="bi bi-upload" style={{ fontSize: '1.4em' }} />
                  </Col>
                  <Col span={24}>
                    <p>Drag and drop files here</p>
                    <p>or click to select files.</p>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>

          {/*  Attached files */}
          {files.length > 0 && (
            <Col span={24} style={{ padding: 8 }}>
              <Row>
                <Col span={24} style={{ marginBottom: 10 }}>
                  <span>Attached Files</span>
                </Col>

                <Col span={24}>
                  {files.map((file: any, i: number) => (
                    <Row style={{ marginBottom: 5 }}>
                      <Col span={21}>
                        {sanitizeFilePath(file.path)} ({formatByteSize(file.size)})
                      </Col>
                      <Col span={3} style={{ textAlign: 'right' }}>
                        <i
                          className="bi bi-x-circle"
                          style={{ color: 'red', fontSize: '1.1em', cursor: 'pointer' }}
                          onClick={() => removeFile(file)}
                        />
                      </Col>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      }
    >
      <Button
        large={large}
        icon="paperclip"
        className="newEmailSectionAttachIcon"
        disabled={disabled}
        minimal={files.length === 0}
        intent={files.length === 0 ? 'primary' : 'success'}
        style={{ borderRadius: 5 }}
        text={files.length > 0 ? files.length : ''}
        onClick={() => setIsPopoverOpen(true)}
      />
    </Popover>
  );
};

const mapState = (state: any) => ({});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(FileUploaderPopoverButton);
