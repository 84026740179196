import { GET_DB_RECORD_AUDIT_LOGS_REQUEST } from './constants';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

export interface IGetRecordAuditLogs {
  entities?: string[];
  limit?: number;
  recordId: string;
  schema: SchemaEntity;
  skip?: number;
  sort?: { [fieldName: string]: 'ASC' | 'DESC' };
  whereQuery?: { [fieldName: string]: any };
}

export function getRecordAuditLogs(
  params: IGetRecordAuditLogs,
  cb?: (params: { recordId: string; results: any[] }) => void,
) {
  return {
    type: GET_DB_RECORD_AUDIT_LOGS_REQUEST,
    params,
    cb,
  };
}
