import { httpDelete, httpGet, httpPost, httpPut } from '@core/http/requests';
import { IViewConfigurationCreate, IViewConfigurationUpdate } from './types';

// GET all view configurations
export const getViewConfigurations = async (schemaId: string): Promise<any[]> => {
  try {
    let path = 'SchemaModule/v1.0/schemas-views';
    const res = await httpGet(path);

    // Filter by schemaId
    let filteredResponse: any[] = Object.assign(res.data.data);
    if (filteredResponse.length > 0 && schemaId) {
      filteredResponse = filteredResponse.filter((item: any) => item.schemaId === schemaId);
    }

    return filteredResponse;
  } catch (error: any) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

// GET single view configuration
export const getViewConfiguration = async (id: string): Promise<any> => {
  try {
    let path = `SchemaModule/v1.0/schemas-views/${id}`;
    const res = await httpGet(path);
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

// CREATE single view configuration
export const createViewConfiguration = async (payload: IViewConfigurationCreate): Promise<any> => {
  try {
    let path = `SchemaModule/v1.0/schemas-views`;
    const res = await httpPost(path, payload);
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

// UPDATE single view configuration
export const updateViewConfiguration = async (payload: IViewConfigurationUpdate): Promise<any> => {
  const {
    viewConfigId,
    schemaId,
    name,
    description,
    schemaTypeId,
    schemaAssociationId,
    stageId,
    definition,
  } = payload;

  const data = {
    description: description || null,
    definition: definition || null,
    name: name || null,
    schemaAssociationId: schemaAssociationId || null,
    schemaId: schemaId || null,
    schemaTypeId: schemaTypeId || null,
    stageId: stageId || null,
  };

  try {
    let path = `SchemaModule/v1.0/schemas-views/${viewConfigId}`;
    const res = await httpPut(path, data);
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};

// DELETE single view configuration
export const deleteViewConfiguration = async (id: string): Promise<any> => {
  try {
    let path = `SchemaModule/v1.0/schemas-views/${id}`;
    const res = await httpDelete(path);
    return res.data.data;
  } catch (error: any) {
    throw new Error(error.response?.data?.message ?? error.message);
  }
};
