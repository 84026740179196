import { Classes, Drawer } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React from 'react';
import Markdown from 'markdown-to-jsx';
import schemaAction from '../../../../../../../doc/schemaAction.md?raw';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const SchemaActionHelpDrawer: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose } = props;
  return (
    <Drawer title="Schema Action Help" isOpen={isOpen} onClose={onClose}>
      <div className={Classes.DRAWER_BODY}>
        <Row style={{ padding: '10px 20px', overflowY: 'scroll' }}>
          <Col span={24}>
            <Markdown
              options={{
                forceBlock: true,
                overrides: {
                  h1: {
                    props: {
                      style: { marginBottom: '40px' },
                    },
                  },
                  h2: {
                    props: {
                      style: { marginTop: '30px' },
                    },
                  },
                  h3: {
                    props: {
                      style: { marginTop: '30px' },
                    },
                  },
                  h4: {
                    props: {
                      style: { marginTop: '30px' },
                    },
                  },
                },
              }}
            >
              {schemaAction}
            </Markdown>
          </Col>
        </Row>
      </div>
    </Drawer>
  );
};
export default SchemaActionHelpDrawer;
