export const createFormFields = [
  {
    label: '',
    property: 'id',
    type: 'TEXT',
    isRequired: true,
    message: '',
    isHidden: true,
    value: undefined,
  },
  {
    label: 'Child Schema',
    property: 'childSchemaId',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Child Association',
    isHidden: false,
    value: undefined,
    options: [],
    allowSearch: true,
  },
  {
    label: 'Association Type',
    property: 'type',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Association Type',
    isHidden: false,
    value: undefined,
    options: [
      { value: 'ONE_TO_ONE', label: 'ONE_TO_ONE' },
      { value: 'ONE_TO_MANY', label: 'ONE_TO_MANY' },
      { value: 'MANY_TO_ONE', label: 'MANY_TO_ONE' },
      { value: 'MANY_TO_MANY', label: 'MANY_TO_MANY' },
    ],
  },
  {
    label: 'Lookup from (optional)',
    property: 'findInSchema',
    type: 'ENUM',
    isRequired: false,
    message: 'Please input Find in Schema',
    isHidden: false,
    value: undefined,
    options: [],
    allowClear: true,
    allowSearch: true,
    allowSort: true,
  },
  {
    label: 'Lookup from nested (optional)',
    property: 'findInChildSchema',
    type: 'ENUM',
    isRequired: false,
    message: 'Please input Find in Schema Association',
    isHidden: false,
    value: undefined,
    options: [],
    allowClear: true,
    allowSearch: true,
    allowSort: true,
  },
  {
    label: 'Child Actions',
    property: 'childActions',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Child Actions',
    isHidden: false,
    value: undefined,
    options: [
      { value: 'LOOKUP_AND_CREATE', label: 'Look up and create' },
      { value: 'LOOKUP_ONLY', label: 'Look up only' },
      { value: 'CREATE_ONLY', label: 'Create only' },
      { value: 'READ_ONLY', label: 'Read only' },
    ],
  },
  {
    label: 'Parent Actions',
    property: 'parentActions',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Parent Actions',
    isHidden: false,
    value: undefined,
    options: [
      { value: 'LOOKUP_AND_CREATE', label: 'Look up and create' },
      { value: 'LOOKUP_ONLY', label: 'Look up only' },
      { value: 'CREATE_ONLY', label: 'Create only' },
      { value: 'READ_ONLY', label: 'Read only' },
    ],
  },
  {
    label: 'Cascade Delete Child Record',
    property: 'cascadeDeleteChildRecord',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: false,
  },
  {
    label: 'Has Column Mappings',
    property: 'hasColumnMappings',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: false,
  },
];

export const editFormFields = [
  {
    label: '',
    property: 'id',
    type: 'TEXT',
    isRequired: true,
    message: '',
    isHidden: true,
    value: undefined,
  },
  {
    label: 'Child Association',
    property: 'childSchemaId',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Child Association',
    isHidden: false,
    value: undefined,
    options: [],
    isDisabled: true,
  },
  {
    label: 'Schema Types Constraints',
    property: 'schemaTypesConstraints',
    type: 'OBJ_LIST',
    message: '',
    isHidden: false,
  },
  {
    label: 'Association Type',
    property: 'type',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Association Type',
    isHidden: false,
    value: undefined,
    options: [
      { value: 'ONE_TO_ONE', label: 'ONE_TO_ONE' },
      { value: 'ONE_TO_MANY', label: 'ONE_TO_MANY' },
      { value: 'MANY_TO_ONE', label: 'MANY_TO_ONE' },
      { value: 'MANY_TO_MANY', label: 'MANY_TO_MANY' },
    ],
    isDisabled: false,
  },
  {
    label: 'Lookup from (optional)',
    property: 'findInSchema',
    type: 'ENUM',
    isRequired: false,
    message: 'Please input Find in Schema',
    isHidden: false,
    value: undefined,
    options: [],
    allowClear: true,
    allowSearch: true,
    allowSort: true,
  },
  {
    label: 'Lookup from nested (optional)',
    property: 'findInChildSchema',
    type: 'ENUM',
    isRequired: false,
    message: 'Please input Find in Schema Association',
    isHidden: false,
    value: undefined,
    options: [],
    allowClear: true,
    allowSearch: true,
    allowSort: true,
  },
  {
    label: 'Child Actions',
    property: 'childActions',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Child Actions',
    isHidden: false,
    value: undefined,
    options: [
      { value: 'LOOKUP_AND_CREATE', label: 'Look up and create' },
      { value: 'LOOKUP_ONLY', label: 'Look up only' },
      { value: 'CREATE_ONLY', label: 'Create only' },
      { value: 'READ_ONLY', label: 'Read only' },
    ],
  },
  {
    label: 'Parent Actions',
    property: 'parentActions',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input Parent Actions',
    isHidden: false,
    value: undefined,
    options: [
      { value: 'LOOKUP_AND_CREATE', label: 'Look up and create' },
      { value: 'LOOKUP_ONLY', label: 'Look up only' },
      { value: 'CREATE_ONLY', label: 'Create only' },
      { value: 'READ_ONLY', label: 'Read only' },
    ],
  },
  {
    label: 'Cascade Delete Child Record',
    property: 'cascadeDeleteChildRecord',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: false,
  },
  {
    label: 'Has Column Mappings',
    property: 'hasColumnMappings',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: false,
  },
  {
    label: 'Is Searchable',
    property: 'searchable',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: true,
  },
  {
    label: 'Is Deletable',
    property: 'isDeletable',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: true,
  },
  {
    label: 'GET URL',
    property: 'getUrl',
    type: 'TEXT',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'POST URL',
    property: 'postUrl',
    type: 'TEXT',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'PUT URL',
    property: 'putUrl',
    type: 'TEXT',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'DELETE URL',
    property: 'deleteUrl',
    type: 'TEXT',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Position',
    property: 'position',
    type: 'NUMBER',
    isRequired: false,
    message: 'Please input value',
    isHidden: false,
    value: 0,
  },
];
