import {
  BellOutlined,
  DashboardOutlined,
  MenuOutlined,
  QuestionCircleOutlined,
  SearchOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Button, Dialog, DialogBody, DialogFooter, Icon, Spinner } from '@blueprintjs/core';
import { Avatar, Badge, Col, Divider, Menu, Row, Spin, Tag } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NetomniaLogoColor from '@core/assets/images/png/netomnia-logo-color-500.png';
import NetomniaLogoColorDark from '@core/assets/images/png/netomnia-logo.png';
import YouFibreLogoColor from '@core/assets/images/png/youfibre-logo-color-500.png';
import { logoutRequest, updateUserRolesAndPermissionsRequest } from '@legacy/core/identity/store/actions';
import {
  addNavigationStructure,
  addRoutingStructure,
  storeSelectedEntity,
  storeSelectedModule,
} from '@legacy/core/navigation/store/actions';
import { INavigationReducer } from '@legacy/core/navigation/store/reducer';
import { toggleSearchVisibility } from '@legacy/core/records/store/actions';
import { listSchemasRequest } from '@legacy/core/schemas/store/actions';
import { toggleNotificationDrawer } from '@legacy/core/userInterface/store/actions';
import { IUserInterfaceReducer } from '@legacy/core/userInterface/store/types';
import '../../../cst-theme.scss';
import { getOrganizationName } from '@core/http/helpers';
import { canUserAccessModule, isExternalUser } from '@core/helpers/rbacRules';
import history from '@core/helpers/browserHistory';
import NotificationDrawer from '@core/components/NotificationDrawer';
import Typography from '@core/components/Typography';
import OdinIcons from './OdinIcons';
import './styles.scss';
import YouFibreMenuStructure from './yfMenuItems';

interface Props {
  userReducer: any;
  navigationReducer: INavigationReducer;
  toggleSearchVisibility: any;
  logout: any;
  storeSelectedModule: any;
  storeSelectedEntity: any;
  addNavigationStructure: any;
  addRoutingStructure: any;
  toggleOdinSettingsModal: any;
  toggleSupportModal: any;
  userInterfaceReducer: IUserInterfaceReducer;
  toggleAppointments: () => void;
  listSchemas: (cb?: any) => void;
  updateUserRolesAndPermissions: (params: any, cb?: any) => void;
}

const Navigation: FC<Props> = (props: Props) => {
  const {
    userReducer,
    listSchemas,
    navigationReducer,
    logout,
    addNavigationStructure,
    addRoutingStructure,
    userInterfaceReducer,
    toggleSupportModal,
    toggleOdinSettingsModal,
    toggleAppointments,
    toggleSearchVisibility,
    updateUserRolesAndPermissions,
  } = props;
  const [routingStructure, setRoutingStructure] = useState<any[]>([]);
  const [navigationStructure, setNavigationStructure] = useState<any[]>([]);
  const [isLoginAsUserDialogVisible, setIsLoginAsUserDialogVisible] = useState<boolean>(false);
  const [isLoggingBack, setIsLoggingBack] = useState<boolean>(false);

  useEffect(() => {
    listSchemas((res: any) => {
      defineMenuStructure(res);
    });
  }, []);

  /* Over here we compare full organizational menu structure with the permissions we
   got back from Schema Manager and userReducer. The idea is to go fully dependent
   on the Schema Manager in the future. It is not currently possible, hence the
   double crosscheck. */
  const defineMenuStructure = (schemaModulesAndEntities: any) => {
    let fullNavigationStructure = YouFibreMenuStructure(schemaModulesAndEntities);
    const routingStructure = fullNavigationStructure.map((item) => {
      return {
        moduleName: item.moduleName,
        menuModuleName: item.moduleName,
        entities: item.entities.map((entity: any) => entity.entityName),
      };
    });

    setNavigationStructure(fullNavigationStructure);
    setRoutingStructure(routingStructure);
    addNavigationStructure({ navigationStructure: fullNavigationStructure });
    addRoutingStructure({ routingStructure: routingStructure });
  };

  const handleLogout = () => {
    logout();
    history.push('/login');
  };

  /* Set App menu height according to content */
  const getAppMenuHeight = () => {
    if (navigationStructure) {
      const menuModules = navigationStructure.filter((module: any) => module.showInApps);
      if (menuModules.length < 4) {
        return '120px';
      } else if (menuModules.length > 3 && menuModules.length < 7) {
        return '220px';
      } else if (menuModules.length > 6 && menuModules.length < 10) {
        return '320px';
      } else if (menuModules.length > 6 && menuModules.length < 10) {
        return '420px';
      }
    }
  };

  const renderModules = () => {
    if (navigationStructure) {
      const menuModules = navigationStructure.filter((module: any) => module.showInApps);

      return menuModules.map((module: any) => (
        <Col span={8} style={{ border: '1px solid #efefef' }} key={module.moduleName}>
          <Menu.Item
            key={`appSwitcher${module.menuModuleName}icon`}
            style={{ textAlign: 'center', padding: 0, height: 'auto' }}
            onClick={() => {
              history.push(`/${module.moduleName}/${getFirstEntityFromModule(module.moduleName)}`);
              storeSelectedModule({ selectedModule: module.moduleName });
              storeSelectedEntity({
                selectedEntity: getFirstEntityFromModule(module.moduleName),
              });
            }}
          >
            <Row style={{ paddingTop: 0, height: 70 }}>
              <Col span={24} style={{ color: 'black', height: 30 }}>
                {OdinIcons(module.icon, 'moduleIcon')}
              </Col>
              <Col span={24} style={{ color: 'black' }}>
                {module.menuModuleName}
              </Col>
            </Row>
          </Menu.Item>
        </Col>
      ));
    }
  };

  const renderEntitiesMenu = () => {
    const handleRouteChange = (selectedEntity: string) => {
      storeSelectedEntity({ selectedEntity: selectedEntity });
    };

    if (navigationReducer.selectedModule !== 'Home') {
      const targetedModule = navigationStructure.find(
        (module: any) => module.moduleName === navigationReducer.selectedModule,
      );

      if (targetedModule && targetedModule.entities.length > 0) {
        let entitiesWithoutOutcomeForms = targetedModule.entities.filter(
          (entity: any) => !entity.isOutcomeForm,
        );
        let entitiesWithOutcomeForms =
          targetedModule.entities.filter((entity: any) => entity.isOutcomeForm) || [];

        // Remove outcome forms that are hidden from the global nav
        entitiesWithOutcomeForms = entitiesWithOutcomeForms.filter(
          (entity: any) => entity.isVisible,
        );

        // Sort the outcome forms
        if (entitiesWithOutcomeForms.length > 0) {
          entitiesWithOutcomeForms.sort((a: any, b: any) =>
            a.menuEntityName > b.menuEntityName ? 1 : -1,
          );
        }

        return (
          <>
            {entitiesWithOutcomeForms.length > 0 && (
              <SubMenu
                key="OutcomeForms"
                title="Outcome Forms"
                className="outcomeForm"
                style={{
                  color: 'black!important',
                  paddingTop: 1,
                  fontWeight: 400,
                  width: 'auto',
                  paddingInline: '5px',
                }}
              >
                {entitiesWithOutcomeForms.map((entity: any) => (
                  <Menu.Item
                    onClick={() => handleRouteChange(entity.entityName)}
                    key={entity.entityName}
                    className={
                      navigationReducer.selectedEntity === entity.entityName
                        ? 'activeEntity'
                        : 'inactiveEntity'
                    }
                  >
                    <Link to={`/${targetedModule.moduleName}/${entity.entityName}`}>
                      <span>{entity.menuEntityName}</span>
                    </Link>
                  </Menu.Item>
                ))}
              </SubMenu>
            )}

            {/* Regular Entities */}
            {entitiesWithoutOutcomeForms.map((entity: any) =>
              entity.isVisible ? (
                <Menu.Item
                  onClick={() => handleRouteChange(entity.entityName)}
                  key={entity.entityName}
                  className={
                    navigationReducer.selectedEntity === entity.entityName
                      ? 'activeEntity'
                      : 'inactiveEntity'
                  }
                >
                  <Link to={`/${targetedModule.moduleName}/${entity.entityName}`}>
                    <span>{entity.menuEntityName}</span>
                  </Link>
                </Menu.Item>
              ) : null,
            )}
          </>
        );
      } else {
        return <></>;
      }
    }
  };

  const checkIfFilesEnabled = () => {
    if (navigationStructure) {
      const schemaModule = navigationStructure.find(
        (module: any) => module.moduleName === 'SchemaModule',
      );
      return !!(
        schemaModule && schemaModule.entities.filter((entity: any) => entity.entityName === 'File')
      );
    }
  };

  const checkIfUsersEnabled = () => {
    if (navigationStructure) {
      return navigationStructure.find(
        (module: any) => module.moduleName === 'IdentityManagerModule',
      );
    }
  };

  const getFirstEntityFromModule = (moduleName: string) => {
    if (navigationStructure && routingStructure) {
      const targetedModule = navigationStructure.find(
        (module: any) => module.moduleName === moduleName,
      );
      return targetedModule && targetedModule.entities.length > 0
        ? targetedModule.entities[0].entityName
        : '';
    }
  };

  const getLogoForOrganization = () => {
    const organizationName = getOrganizationName();

    switch (organizationName) {
      case 'YouFibre':
        return (
          <img
            src={YouFibreLogoColor}
            alt="Logo"
            style={{ width: 50, marginLeft: 7, verticalAlign: 'middle' }}
          />
        );
      case 'Netomnia':
        return isMobile ? (
          <img
            src={NetomniaLogoColorDark}
            alt="Logo"
            style={{ width: 90, paddingTop: 2, verticalAlign: 'middle' }}
          />
        ) : (
          <img
            src={NetomniaLogoColor}
            alt="Logo"
            style={{ width: 90, paddingTop: 2, verticalAlign: 'middle' }}
          />
        );
      case 'NetX':
        return (
          <Typography style={{ verticalAlign: 'middle' }} stronger>
            NetX
          </Typography>
        );
      default:
        return (
          <Typography style={{ verticalAlign: 'middle' }} stronger>
            Company
          </Typography>
        );
    }
  };

  const notificationNumber = () => {
    return (
      userInterfaceReducer.appointmentNotificationCount +
      userInterfaceReducer.mentionsNotificationCount
    );
  };

  const isLoggedAsAnotherUser = () => {
    return localStorage.getItem('originalToken') !== null;
  };

  const switchBackToPrimaryUser = async () => {
    setIsLoggingBack(true);
    setIsLoginAsUserDialogVisible(true);

    try {
      // 1. copy the current user's token into local storage as originalToken
      const originalToken = localStorage.getItem('originalToken');
      if (originalToken) {
        await localStorage.setItem('token', originalToken);
        await localStorage.removeItem('originalToken');
      }

      // 2. Fetch user /my endpoint to get the user details, this will save the user to shortlist
      updateUserRolesAndPermissions(() => {
        setTimeout(() => {
          setIsLoggingBack(false);
        }, 1000);
        listSchemas((res: any) => {
          defineMenuStructure(res);
        });
      });
    } catch (error) {
      console.error('Error switching back to primary user', error);
      setIsLoggingBack(false);
      setIsLoginAsUserDialogVisible(false);
    }
  };

  return (
    <Row>
      <NotificationDrawer />
      <Col xs={12} sm={10} md={12} lg={13} xl={16} xxl={19}>
        <Menu
          triggerSubMenuAction="hover"
          theme="light"
          mode="horizontal"
          selectable={false}
          overflowedIndicator={<MenuOutlined />}
          className={
            getOrganizationName() === 'Sandbox' || getOrganizationName() === 'Development'
              ? 'dev-sandbox'
              : ''
          }
        >
          {/* APP SWITCHER */}
          {!isExternalUser(userReducer) && (
            <SubMenu
              key="app"
              className="appSwitcher"
              title={
                getOrganizationName() === 'Sandbox' || getOrganizationName() === 'Development' ? (
                  <Typography stronger style={{ verticalAlign: 'middle' }}>
                    {getOrganizationName()}
                  </Typography>
                ) : (
                  getLogoForOrganization()
                )
              }
            >
              <Row
                style={{
                  textAlign: 'center',
                  width: '360px',
                  height: getAppMenuHeight(),
                }}
              >
                {navigationStructure.length > 0 ? renderModules() : <Spin />}
              </Row>

              {/* Main Dashboard */}
              {canUserAccessModule(userReducer, 'fullreporting') ? (
                <>
                  <Divider style={{ margin: '0 0 10px 0' }} />
                  <Menu.Item
                    key="dashboard"
                    style={{ textAlign: 'center' }}
                    onClick={() => storeSelectedModule({ selectedModule: 'Dashboard' })}
                    icon={<DashboardOutlined />}
                  >
                    <span>Dashboard</span>
                    <Link to={'/Dashboard'} />
                  </Menu.Item>
                </>
              ) : (
                <></>
              )}
            </SubMenu>
          )}

          {/* MENU ITEMS */}
          {navigationStructure.length > 0 ? renderEntitiesMenu() : <></>}
        </Menu>
      </Col>

      {/* RIGHT SIDE */}
      <Col xs={12} sm={14} md={12} lg={11} xl={8} xxl={5}>
        <Menu
          triggerSubMenuAction="click"
          theme="light"
          mode="horizontal"
          selectable={false}
          className={
            getOrganizationName() === 'Sandbox' || getOrganizationName() === 'Development'
              ? 'rightMenu dev-sandbox'
              : 'rightMenu'
          }
        >
          {/* USER MANAGEMENT */}
          <SubMenu
            key="userManagement"
            className="navigationTopItem"
            style={{ float: 'right' }}
            icon={
              <UserOutlined
                style={{
                  fontSize: '18px',
                  border: '1px solid white',
                  padding: 3,
                  borderRadius: 100,
                }}
              />
            }
          >
            {/* User Information */}
            <Menu.Item key="UserInfo" style={{ height: 'fit-content', width: 250 }}>
              <Row>
                <Col span={24} style={{ textAlign: 'center', marginTop: 10 }}>
                  <Avatar
                    size="large"
                    style={{
                      backgroundColor: '#334250',
                      cursor: 'pointer',
                    }}
                  >
                    {userReducer?.user?.firstname?.toUpperCase().charAt(0) +
                      userReducer?.user?.lastname?.toUpperCase().charAt(0)}
                  </Avatar>
                </Col>
                <Col span={24} style={{ textAlign: 'center', margin: 0 }}>
                  <span
                    style={{ fontWeight: 500 }}
                  >{`${userReducer?.user?.firstname} ${userReducer?.user?.lastname}`}</span>
                </Col>
                <Col
                  span={24}
                  style={{
                    fontSize: '0.8em',
                    textAlign: 'center',
                    margin: 0,
                    lineHeight: 0,
                    marginBottom: 15,
                  }}
                >
                  <span style={{ opacity: 0.5 }}>{userReducer.user?.email}</span>
                </Col>

                <Col
                  span={24}
                  style={{ textAlign: 'center', margin: 0, padding: 0, lineHeight: '28px' }}
                >
                  {import.meta.env.VITE_VERSION ? (
                    <Tag>v{import.meta.env.VITE_VERSION}</Tag>
                  ) : (
                    <Tag>Development</Tag>
                  )}
                </Col>
              </Row>
            </Menu.Item>

            <Divider style={{ margin: '10px' }} />

            {/* Home */}
            {!isExternalUser(userReducer) && (
              <Menu.Item
                key="Home"
                icon={<Icon size={14} icon="home"  />}
                onClick={() => {
                  storeSelectedModule({ selectedModule: 'Home' });
                  storeSelectedEntity({ selectedEntity: '' });
                  history.push('/');
                }}
              >
                Home
              </Menu.Item>
            )}

            {/* Inbox */}
            {!isExternalUser(userReducer) && (
              <Menu.Item
                key="Inbox"
                icon={<Icon icon="inbox" size={14} />}
                onClick={() => {
                  history.push('/Inbox');
                }}
              >
                Inbox
              </Menu.Item>
            )}

            {/* My Files */}
            {checkIfFilesEnabled() ? (
              <Menu.Item key="MyDrive">
                <Link to="/SchemaModule/File">
                  <Icon icon="folder-close" style={{ marginRight: 12 }} size={14} />
                  <span>My Files</span>
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}

            {/* Schemas */}
            {/* {!isExternalUser(userReducer) ? (
              <Menu.Item key="Schemas">
                <Link to="/SchemaModule/Schema/">
                  <i className="bi bi-diagram-3" style={{ padding: '5px', marginRight: 10 }} />
                  <span>Schemas</span>
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )} */}

            {/* Users & Groups */}
            {/* {!isExternalUser(userReducer) && checkIfUsersEnabled() ? (
              <Menu.Item key="UsersAndPermissions">
                <Link to="/IdentityManagerModule/">
                  <UserOutlined style={{ padding: '5px' }} />
                  <span>Identity Manager</span>
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )} */}

            {/* Control Panel (Identity and Schema Managers) */}
            {!isExternalUser(userReducer) && checkIfUsersEnabled() ? (
              <Menu.Item key="ControlPanel">
                <Link to="/ControlPanelModule/">
                  <Icon icon="settings" style={{ marginRight: 12 }} size={14}/>
                  <span>Control Panel</span>
                </Link>
              </Menu.Item>
            ) : (
              <></>
            )}

            <Menu.Item key="OdinSettings" onClick={() => toggleOdinSettingsModal()}>
              <Icon icon="cog" style={{ marginRight: 12 }} size={14} />
              <span>Settings</span>
            </Menu.Item>

            {checkIfUsersEnabled() ? <Divider style={{ margin: '0 0 10px 0' }} /> : <></>}

            {/* Logout */}
            <Menu.Item key="UserSettings" onClick={() => handleLogout()}>
              <Icon icon="power" color="red" size={14} style={{ marginRight: 12 }} />
              <span style={{ color: 'red' }}>Sign out</span>
            </Menu.Item>
          </SubMenu>

          {/* SEARCH */}
          {!isExternalUser(userReducer) && (
            <Menu.Item
              key="Search"
              className="rightMenuitem"
              onClick={() => toggleSearchVisibility()}
              style={{ padding: 0, float: 'right', color: 'white' }}
            >
              <SearchOutlined style={{ fontSize: '18px' }} />
            </Menu.Item>
          )}

          {/* SUPPORT */}
          {!isExternalUser(userReducer) && (
            <Menu.Item
              className="rightMenuitem"
              key="Support"
              onClick={() => toggleSupportModal()}
              style={{ padding: 0, float: 'right', color: 'white' }}
            >
              <QuestionCircleOutlined style={{ fontSize: '18px' }} />
            </Menu.Item>
          )}

          {/* Notification Drawer */}
          <Menu.Item
            className="rightMenuitem badgeNotification"
            key="Appointments"
            onClick={() => toggleAppointments()}
            style={{ padding: 0, float: 'right', color: 'white' }}
          >
            {userInterfaceReducer.appointmentNotificationCount > 0 ||
            userInterfaceReducer.mentionsNotificationCount > 0 ? (
              <Badge
                color="green"
                className="topMenuNotificationBadge"
                count={notificationNumber()}
                style={{ position: 'absolute', right: 0, top: 2, lineHeight: '13px' }}
              >
                <BellOutlined style={{ fontSize: '18px', color: 'white' }} />
              </Badge>
            ) : (
              <>
                <BellOutlined style={{ marginLeft: 10, fontSize: '18px' }} />
              </>
            )}
          </Menu.Item>

          {/* Log out if logged in as another user */}
          {isLoggedAsAnotherUser() && (
            <Menu.Item
              key="logOutAsAnotherUser"
              style={{ padding: 0, float: 'right', color: 'white' }}
            >
              <Button
                text="Leave User"
                intent="success"
                style={{ marginBottom: 3, borderRadius: 3 }}
                icon="following"
                onClick={switchBackToPrimaryUser}
              />
            </Menu.Item>
          )}
        </Menu>
      </Col>

      {/* Switch User dialog */}
      <Dialog
        icon="log-in"
        title={`Switching back to primary user`}
        isCloseButtonShown={false}
        isOpen={isLoginAsUserDialogVisible}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
      >
        <DialogBody>
          {isLoggingBack ? (
            <Row style={{ textAlign: 'center' }}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <span>Switching back...</span>
              </Col>
              <Col span={24}>
                <Spinner size={32} />
              </Col>
            </Row>
          ) : (
            <Row style={{ textAlign: 'center' }}>
              <Col span={24} style={{ marginBottom: 20 }}>
                <span>Successfully switched back</span>
              </Col>
              <Col span={24}>
                <Icon icon="tick-circle" size={32} intent="success" />
              </Col>
            </Row>
          )}
        </DialogBody>
        <DialogFooter
          actions={[
            <Button
              key="OK"
              intent="primary"
              disabled={isLoggingBack}
              onClick={() => {
                setIsLoginAsUserDialogVisible(false);
                history.push('/');
              }}
            >
              OK
            </Button>,
          ]}
        />
      </Dialog>
    </Row>
  );
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  logout: () => dispatch(logoutRequest()),
  toggleSearchVisibility: () => dispatch(toggleSearchVisibility()),
  storeSelectedModule: (params: { selectedModule: string }) =>
    dispatch(storeSelectedModule(params)),
  storeSelectedEntity: (params: { selectedEntity: string }) =>
    dispatch(storeSelectedEntity(params)),
  addNavigationStructure: (params: { navigationStructure: object }) =>
    dispatch(addNavigationStructure(params)),
  addRoutingStructure: (params: { routingStructure: object }) =>
    dispatch(addRoutingStructure(params)),
  toggleAppointments: () => dispatch(toggleNotificationDrawer()),
  listSchemas: (cb: any) => dispatch(listSchemasRequest(cb)),
  updateUserRolesAndPermissions: (cb: any) => dispatch(updateUserRolesAndPermissionsRequest(cb)),
});

export default connect(mapState, mapDispatch)(Navigation);
