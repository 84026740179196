import React, { FunctionComponent, useState } from 'react';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RecordMainContentWide from '@legacy/core/records/components/DetailViewWide/RecordMainContentWide';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { getRecordFromShortListById } from '../../../../../core/helpers/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '../../../../../core/helpers/schemaHelpers';
import { getAllRelations, getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import RecordQuickViewDrawer from '@legacy/core/records/components/RecordQuickViewDrawer';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  getRecordByIdRequest,
  IGetRecordById,
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '@legacy/core/records/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { useRecordAssociations } from '@legacy/core/records/hooks/useRecordAssociations';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { IdentityUserReducer } from '@legacy/core/identityUser/store/reducer';
import {
  Alert,
  Button,
  Card,
  Col,
  Descriptions,
  Divider,
  Empty,
  message,
  Popover,
  Result,
  Steps,
  Tabs,
  Tag,
  Timeline,
  Typography,
} from 'antd';
import PIAAssociationBuilder from '../PIAAssociationBuilder';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { sortDbRecordsByCreatedAtNewestFirst } from '@d19n/temp-fe-d19n-common/dist/helpers/Utilities';
import dayjs from 'dayjs';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import {
  cancelNAOrder,
  submitA55,
  submitNAOrder,
  submitProblemEvidence,
  submitSolutionEvidence,
  submitSPOCompletion,
} from '@legacy/core/records/components/RecordHeader/NetworkAdjustmentActions/actions';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import CoreLegacyEmbeddedForm from '@legacy/core/records/components/Forms/CoreLegacyEmbeddedForm';

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  match?: any;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  disableClone?: boolean;
  disableEdit?: boolean;
  disableDelete?: boolean;
  excludeRelations?: string[];
  defaultTabKey?: string;
  getSchema: (params: ISchemaByModuleAndEntity, cb?: any) => Promise<any>;
  updateRecord: (params: IUpdateRecordById, cb?: any) => Promise<any>;
  getRecordById: (params: IGetRecordById, cb?: any) => Promise<any>;
  userReducer: IdentityUserReducer;
  initializeForm: Function;
  alertMessage: any;
};

const OpenreachNetworkAdjustmentDetail: FunctionComponent<PropsType> = (props) => {
  const [quickView, setQuickView] = useState<{
    id: string | undefined;
    showDrawer: boolean;
  }>({
    id: undefined,
    showDrawer: false,
  });

  const {
    initializeForm,
    schemaReducer,
    recordReducer,
    updateRecord,
    match,
    defaultTabKey,
    disableEdit,
    alertMessage,
    getSchema,
  } = props;

  const record = getRecordFromShortListById(recordReducer.shortList, match?.params?.recordId);

  const [blockages, setBlockages] = React.useState<DbRecordEntityTransform[]>([]);
  const [productOrderItems, setProductOrderItems] = React.useState<DbRecordEntityTransform[]>([]);
  const [latestNote, setNotes] = React.useState<DbRecordEntityTransform | undefined>(undefined);
  const [kciEvents, setOrderKCIEvents] = React.useState<any[]>([]);
  const [current, setCurrentStep] = useState(0);
  const [approveCostLoading, setApproveCostLoading] = useState(false);

  const next = () => {
    setCurrentStep(current + 1);
  };

  const prev = () => {
    setCurrentStep(current - 1);
  };

  useRecordAssociations(record, [SchemaModuleEntityTypeEnums.FEATURE], ({ results }: any) => {
    const features =
      getAllRelations(results, SchemaModuleEntityTypeEnums.FEATURE)?.filter(
        (elem) => elem.type === 'BLOCKAGE',
      ) ?? [];
    setBlockages(features);
  });

  useRecordAssociations(record, ['OpenreachProductOrderItem'], ({ results }: any) => {
    const orOrderItems = getAllRelations(results, 'OpenreachProductOrderItem') ?? [];
    setProductOrderItems(orOrderItems);
  });

  useRecordAssociations(record, ['Note'], ({ results }: any) => {
    const notes = getAllRelations(results, 'Note') ?? [];
    // get the latest note
    const latest = sortDbRecordsByCreatedAtNewestFirst(notes);
    console.log('latest notes', latest);
    if (latest) {
      setNotes(latest[0]);
    }
  });

  useRecordAssociations(record, ['Activity'], ({ results }: any) => {
    const events = getAllRelations(results, 'Activity') ?? [];

    // get the latest note
    const sorted = sortDbRecordsByCreatedAtNewestFirst(events);
    // console.log('sorted', sorted);
    // Filter out only Openreach activity
    const filtered = sorted.filter((elem) =>
      getProperty(elem, 'JSONData')?.hasOwnProperty('orderEvent'),
    );

    console.log('HERE', current);
    // Only render the latest 50 events
    const latest = filtered?.slice(0, 50);
    // console.log('latest', latest);
    // format data
    const formatted = latest.map((elem) => ({
      description: getProperty(elem, 'JSONData')?.orderEvent?.eventType,
      messages: getProperty(elem, 'JSONData')?.orderEvent?.event?.productOrder
        ?.productOrderMessages,
      date: elem.createdAt,
    }));
    // console.log('formatted', formatted);
    setOrderKCIEvents(formatted);

    // Check for the Submitted A55 activity.. if none then move to Submit A55 step
    if (latest) {
      const isOrderRecieved = latest.find(
        (elem) => getProperty(elem, 'JSONData')?.orderEvent?.eventType === 'Order Received',
      );
      console.log('isOrderRecieved', isOrderRecieved);

      const isAfpSubmitted = sorted.find(
        (elem) => getProperty(elem, 'Description') === 'A55 submitted',
      );
      console.log('isAfpSubmitted', isAfpSubmitted);

      const isCostAccepted = sorted.find((elem) =>
        ['Amend Accepted', 'Network Adjustment Acceptance'].includes(
          getProperty(elem, 'JSONData')?.orderEvent?.eventType,
        ),
      );
      console.log('isCostAccepted', isCostAccepted);

      //eventType: Network Adjustment Notice
      const isReadyForCostApproval = latest.find((elem) => {
        if (getProperty(elem, 'JSONData')?.orderEvent?.event?.productOrder?.productOrderItem) {
          return !!getProperty(elem, 'JSONData')?.orderEvent?.event?.productOrder
            ?.productOrderItem[0]?.product?.productPrice;
        } else {
          return false;
        }
      });
      console.log('isReadyForCostApproval', isReadyForCostApproval);
      console.log('OrderRef', getProperty(record, 'OpenreachOrderReference'));

      if (isCostAccepted && getProperty(record, 'OpenreachOrderReference')) {
        setCurrentStep(4);
        console.log('STEP 5 - SPO Completion');
      } else if (
        (isReadyForCostApproval || isAfpSubmitted) &&
        getProperty(record, 'OpenreachOrderReference')
      ) {
        console.log('STEP 4 - Approve costs');
        setCurrentStep(3);
      } else if (isOrderRecieved && getProperty(record, 'OpenreachOrderReference')) {
        console.log('STEP 3 - Submit A55');
        setCurrentStep(2);
      } else if (!getProperty(record, 'OpenreachOrderReference')) {
        console.log('STEP 2 - Submit order to OR');
        setCurrentStep(1);
      } else {
        console.log('STEP 1 - Configure blockage');
        setCurrentStep(0);
      }

      console.log('current', current);
    }
  });

  const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

  const renderOrder = () => {
    return (
      <div>
        <Descriptions layout="horizontal" column={1} bordered size="small">
          <Descriptions.Item label={'NOI Ref'}>
            {getProperty(record, 'NOIRefOverride') || getProperty(record, 'SubmittedNOIReference')}
          </Descriptions.Item>
          <Descriptions.Item label={'Siebel Ref'}>
            {getProperty(record, 'OpenreachOrderReference')}
          </Descriptions.Item>
          <Descriptions.Item label={'Order State'}>
            <Tag color={'default'}>{getProperty(record, 'OrderState')}</Tag>
          </Descriptions.Item>
          <Descriptions.Item label={'Work Description'}>
            {getProperty(record, 'WorkDescription')}
          </Descriptions.Item>
          <Descriptions.Item label={'Contact Email'}>
            {getProperty(record, 'AlternateNotificationContact')}
          </Descriptions.Item>
          <Descriptions.Item label={'Contact Number'}>
            {getProperty(record, 'JobContactContactNumber')}
          </Descriptions.Item>
        </Descriptions>
      </div>
    );
  };

  const renderBlockages = () => {
    return (
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        {blockages?.map((elem) => (
          <Card title={`${elem.type} ${elem.title}`} size="small">
            <div style={{ padding: 20 }}>
              <Tabs size="small" defaultActiveKey={'Details'}>
                <Tabs.TabPane tab="Details" key="Details">
                  <CoreLegacyEmbeddedForm
                    formUUID={elem.id}
                    record={elem}
                    showFormActions
                    onCancelEvent={() => {
                    }}
                    initializeCb={() => {
                      initializeForm({
                        title: `Edit ${
                          getSchemaFromShortListBySchemaId(schemaReducer.shortList, elem?.schemaId)
                            ?.entityName
                        }`,
                        formUUID: elem.id,
                        selected: elem,
                        visibleFieldOverride: ['PIASelfProvide', 'PIAAdjustmentType'],
                        recordType: elem?.type,
                        showFormModal: true,
                        showInitializing: false,
                        isCreateReq: false,
                        isUpdateReq: true,
                        schema: getSchemaFromShortListBySchemaId(
                          schemaReducer.shortList,
                          elem?.schemaId,
                        ),
                        sections: [
                          {
                            name: getSchemaFromShortListBySchemaId(
                              schemaReducer.shortList,
                              elem?.schemaId,
                            )?.name,
                            schema: getSchemaFromShortListBySchemaId(
                              schemaReducer.shortList,
                              elem?.schemaId,
                            ),
                            associations: [],
                          },
                        ],
                      });
                    }}
                  />
                </Tabs.TabPane>
                <Tabs.TabPane tab="PIA Features" key="PIAFeatures">
                  <PIAAssociationBuilder record={elem} />
                </Tabs.TabPane>
              </Tabs>
            </div>
          </Card>
        ))}
      </div>
    );
  };

  const approveOrProductCost = async (orderItem: DbRecordEntityTransform) => {
    setApproveCostLoading(true);
    await getSchema(
      {
        moduleName: SchemaModuleTypeEnums.PROJECT_MODULE,
        entityName: 'OpenreachProductOrderItem',
      },
      (schema: SchemaEntity) => {
        console.log('schema', schema.entityName);
        return updateRecord(
          {
            schema: schema,
            recordId: orderItem.id,
            createUpdate: {
              schemaId: schema?.id,
              properties: {
                SelfProvide: 'Y',
                CompleteByDateAcceptance: 'Y',
                FundBreakdownAcceptance: 'Y',
                NotToExceedPriceAcceptance: 'Y',
              },
            },
          },
          () => {
            setApproveCostLoading(false);
            alertMessage({
              body: 'Fund breakdown acceptence submitted',
              type: 'success',
            });
            // Move to SPO Completion step
            setCurrentStep(4);
            console.log('[DebugX] getRecordById', {
              schema,
              recordId: orderItem.id,
            });
          },
        );
      },
    );
  };

  const renderOrProductCostApproval = () => {
    return (
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        {productOrderItems?.length < 1 ? (
          <Empty
            description={
              <span>
                Waiting for Openreach to confirm the order and issue products for the order.
              </span>
            }
          />
        ) : (
          <>
            {productOrderItems?.map((elem) => (
              <Card
                title={`${getProperty(elem, 'OrderProductId')} ${elem.title}`}
                size="small"
                extra={[
                  <div>
                    <Button
                      loading={approveCostLoading}
                      type="primary"
                      disabled={getProperty(elem, '_PriceData')?.length < 1}
                      onClick={() => approveOrProductCost(elem)}
                    >
                      Approve Cost
                    </Button>
                  </div>,
                ]}
              >
                <div style={{ padding: 20 }}>
                  <Descriptions layout="horizontal" column={1} bordered size="small">
                    <Descriptions.Item label={'Self Provide'}>
                      {getProperty(elem, 'SelfProvide')}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Complete By Date Acceptance'}>
                      {getProperty(elem, 'CompleteByDateAcceptance')}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Fund Breakdown Acceptance'}>
                      {getProperty(elem, 'FundBreakdownAcceptance')}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Not To Exceed Price Acceptance'}>
                      {getProperty(elem, 'NotToExceedPriceAcceptance')}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Associated Inventory'}>
                      {getProperty(elem, 'AssociatedInventory')}
                    </Descriptions.Item>
                    <Descriptions.Item label={'Quantity'}>
                      {getProperty(elem, 'Quantity')}
                    </Descriptions.Item>
                  </Descriptions>
                </div>
                <div style={{ padding: 20 }}>
                  {getProperty(elem, '_PriceData')?.length < 1 ? (
                    <Empty
                      description={
                        <span>
                          Waiting for Openreach to send pricing information for this product.
                        </span>
                      }
                    />
                  ) : (
                    <Descriptions layout="horizontal" column={1} bordered size="small">
                      {getProperty(elem, '_PriceData')?.map((item: any) => (
                        <Descriptions.Item label={item?.name}>
                          {item?.price?.dutyFreeAmount?.value}
                        </Descriptions.Item>
                      ))}
                    </Descriptions>
                  )}
                </div>
              </Card>
            ))}
          </>
        )}
      </div>
    );
  };

  const renderOrProductSpoCompletion = () => {
    return (
      <div style={{ marginTop: 10, marginBottom: 10 }}>
        {productOrderItems?.map((elem) => (
          <Card
            title={`${getProperty(elem, 'OrderProductId')} ${elem.title}`}
            size="small"
            extra={
              <div style={{ display: 'flex' }}>
                <Button
                  style={{ marginRight: 10 }}
                  type="primary"
                  disabled={getProperty(elem, '_PriceData')?.length < 1}
                  onClick={() => submitProblemEvidence(elem?.id, alertMessage)}
                >
                  Submit Problem Evidence
                </Button>
                <Button
                  style={{ marginRight: 10 }}
                  type="primary"
                  disabled={getProperty(elem, '_PriceData')?.length < 1}
                  onClick={() => submitSolutionEvidence(elem?.id, alertMessage)}
                >
                  Submit Solution Evidence
                </Button>
                <Button
                  style={{ marginRight: 10 }}
                  type="primary"
                  disabled={getProperty(elem, '_PriceData')?.length < 1}
                  onClick={() => submitSPOCompletion(record?.id, alertMessage)}
                >
                  SPO Completion
                </Button>
              </div>
            }
          >
            <div style={{ padding: 20 }}>
              <Descriptions layout="horizontal" column={1} bordered size="small">
                <Descriptions.Item label={'Associated Inventory'}>
                  {getProperty(elem, 'AssociatedInventory')}
                </Descriptions.Item>
                <Descriptions.Item label={'Quantity'}>
                  {getProperty(elem, 'Quantity')}
                </Descriptions.Item>
              </Descriptions>
            </div>

            <div style={{ padding: 20 }}>
              {getProperty(elem, '_PriceData')?.length < 1 ? (
                <Empty
                  description={
                    <span>Waiting for Openreach to send pricing information for this product.</span>
                  }
                />
              ) : (
                <AssociationDataTable
                  thumbnailSize={12}
                  title="Files"
                  record={elem}
                  showFileCategoryForType="DEFAULT"
                  moduleName="SchemaModule"
                  entityName="File"
                  location="sidebar"
                  collapsedByDefault
                />
              )}
            </div>
          </Card>
        ))}
      </div>
    );
  };

  const activityTimeline = () => {
    return kciEvents?.map((elem) => ({
      event: elem.description,
      date: elem.date,
      messages: elem.messages,
    }));
  };

  const renderKciMessages = (messages: any[]) => {
    return (
      <div>
        {messages?.map((elem) => (
          <div>
            <p>{elem.reason}</p>
            <div>
              <Tag>{elem.code}</Tag>
              <Tag>{elem.severity}</Tag>
            </div>
            <Divider />
          </div>
        ))}
      </div>
    );
  };

  const processNext = async (current: number) => {
    console.log('NEXT STEP ', current);
    // Step 2 is submit AFP
    if (current === 2) {
      await submitA55(record?.id, alertMessage);
    }
  };

  const renderSteps = () => {
    return (
      <>
        <Steps
          current={current}
          items={items}
          onChange={(elem) => {
            console.log('step change', elem);
          }}
        />
        <div
          className="steps-content"
          style={{
            maxHeight: 500,
            overflow: 'auto',
          }}
        >
          {steps[current].content}
        </div>
        <div className="steps-action">
          {current < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => {
                next();
                processNext(current);
              }}
            >
              Next
            </Button>
          )}
          {current === steps.length - 1 && (
            <Button type="primary" onClick={() => message.success('Processing complete!')}>
              Done
            </Button>
          )}
          {current > 0 && (
            <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
              Previous
            </Button>
          )}
        </div>
      </>
    );
  };

  const steps = [
    {
      title: 'Configure blockages',
      content: renderBlockages(),
    },
    {
      title: 'Submit OR order',
      content: (
        <div>
          <Result
            icon={<></>}
            title="Submit order to Openreach."
            extra={
              <Button
                type="primary"
                disabled={getProperty(record, 'OpenreachOrderReference')}
                onClick={() => submitNAOrder(record?.id, alertMessage)}
              >
                Submit Order
              </Button>
            }
          />
        </div>
      ),
    },
    {
      title: 'Submit A55',
      content: (
        <AssociationDataTable
          thumbnailSize={12}
          title="Files"
          record={record}
          showFileCategoryForType="DEFAULT"
          moduleName="SchemaModule"
          entityName="File"
          location="sidebar"
          collapsedByDefault
        />
      ),
    },
    {
      title: 'Approve costs',
      content: renderOrProductCostApproval(),
    },
    {
      title: 'SPO completion',
      content: renderOrProductSpoCompletion(),
    },
  ];

  const items = steps.map((item) => ({ key: item.title, title: item.title }));

  return (
    <>
      <RecordQuickViewDrawer
        recordId={quickView?.id!}
        moduleName={SchemaModuleTypeEnums.PROJECT_MODULE}
        entityName={'Feature'}
        visible={quickView.showDrawer && !!quickView?.id}
        onClose={() =>
          setQuickView({
            id: undefined,
            showDrawer: false,
          })
        }
      />
      <div style={{ paddingLeft: 12, paddingRight: 12, paddingTop: 12 }}>
        {latestNote && <Alert message={getProperty(latestNote, 'Body')} type="info" showIcon />}
      </div>
      <RecordMainContentWide
        extra={[
          <Button
            type="primary"
            disabled={getProperty(record, 'OpenreachOrderReference')}
            onClick={() => submitNAOrder(record?.id, alertMessage)}
          >
            Create OR Order
          </Button>,
          <Button danger onClick={() => cancelNAOrder(record?.id, alertMessage)}>
            Cancel OR Order
          </Button>,
        ]}
        disableClone={true}
        disableEdit={disableEdit}
        disableDelete={true}
        hideRecordDetails={true}
        hideFileManager={true}
        defaultTabKey={defaultTabKey}
        record={record}
        schema={schema}
        relatedSchemas={[]}
        toggleColumnState={() => {
        }}
        detailsColumnExpanded={false}
        customTabs={[
          {
            key: 'OrderDetails',
            tab: 'Order Details',
          },
        ]}
        customContent={{
          OrderDetails: renderOrder(),
        }}
        customContentRightPanel={
          <Card title={'Order KCI'}>
            {activityTimeline()?.length < 1 && <Empty description={'No KCI events'} />}
            <Timeline>
              {activityTimeline()?.map((item) => (
                <Timeline.Item>
                  <div
                    style={{
                      fontSize: 12,
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Typography.Text strong>{item.event}</Typography.Text>
                    <Typography.Text>{dayjs(item.date).format('DD/MM/YYYY HH:MM')}</Typography.Text>
                  </div>
                  <div>
                    {item?.messages?.length > 0 && (
                      // <div style={{ paddingLeft: 12 }}>{renderKciMessages(item.messages)}</div>
                      <Popover
                        style={{ width: 350 }}
                        content={renderKciMessages(item.messages)}
                        title="Openreach Messages"
                        trigger="hover"
                      >
                        <Button size="small" type="link">
                          View messages
                        </Button>
                      </Popover>
                    )}
                  </div>
                </Timeline.Item>
              ))}
            </Timeline>
          </Card>
        }
      >
        <Col span={24} style={{ marginBottom: 20 }}>
          <Card title={'Order Steps'}>{renderSteps()}</Card>
        </Col>
      </RecordMainContentWide>
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchema: (params: ISchemaByModuleAndEntity, cb?: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  getRecordById: (params: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default withRouter(connect(mapState, mapDispatch)(OpenreachNetworkAdjustmentDetail));
