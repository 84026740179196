import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  ADD_ASSIGNMENTS_TO_INBOX,
  ADD_MENTIONS_TO_INBOX,
  SET_INBOX_QUICK_SEARCH,
  SET_SELECTED_ASSIGNMENT,
  SET_SELECTED_MENTION,
  TOGGLE_MENTION_READ_STATUS,
} from './constants';
import { TMention } from './types';

export interface IInboxReducer {
  mentions: TMention[];
  selectedMention: TMention | undefined;
  assignments: DbRecordEntityTransform[];
  selectedAssignment: DbRecordEntityTransform | undefined;
  quickSearch: string | undefined;
}

export const InboxReducerInitialState: IInboxReducer = {
  mentions: [],
  selectedMention: undefined,
  quickSearch: undefined,
  assignments: [],
  selectedAssignment: undefined,
};

export function inboxReducer(state: IInboxReducer, action: { type: string; payload: any }) {
  switch (action.type) {
    // Mentions
    case ADD_MENTIONS_TO_INBOX:
      return {
        ...state,
        mentions: action.payload,
      };
    case SET_SELECTED_MENTION:
      return {
        ...state,
        selectedMention:
          state.mentions.find((mention) => mention.id === action.payload) || undefined,
      };
    case TOGGLE_MENTION_READ_STATUS:
      let selectedMention = state.selectedMention
        ? Object.assign({}, state.selectedMention)
        : undefined;

      if (selectedMention && selectedMention?.id === action.payload) {
        selectedMention.metaData.read = !selectedMention.metaData.read;
      }

      return {
        ...state,
        selectedMention,
        mentions: state.mentions.map((mention) => {
          if (mention.id === action.payload) {
            return {
              ...mention,
              metaData: {
                ...mention.metaData,
                read: !mention.metaData?.read,
              },
            };
          }
          return mention;
        }),
      };

    // Assignments
    case ADD_ASSIGNMENTS_TO_INBOX:
      return {
        ...state,
        assignments: action.payload,
      };
    case SET_SELECTED_ASSIGNMENT:
      return {
        ...state,
        selectedAssignment:
          state.assignments.find((assignment) => assignment.id === action.payload) || undefined,
      };

    case SET_INBOX_QUICK_SEARCH:
      return {
        ...state,
        quickSearch: action.payload,
      };
    default:
      return state;
  }
}
