import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  ADD_ASSOCIATED_RECORDS_TO_DATASET,
  ADD_DATASET_RECORD_TO_DATASET_SELECTION,
  ADD_SELECTED_RECORDS_TO_DATASET,
  ADD_TO_SELECTED_RECORDS,
  BULK_REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION,
  DESELECT_ALL_DATASET_RECORDS,
  DESELECT_ALL_RECORDS,
  REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION,
  REMOVE_FROM_SELECTED_RECORDS,
  SELECT_ALL_DATASET_RECORDS,
  SELECT_ALL_RECORDS,
  SET_ACTIVE_STATUS_TAB,
  SET_ALL_RECORD_STATUSES,
  SET_DATASET_PAGINATION,
  SET_DATASET_PARENT_RECORD,
  SET_ELASTIC_QUERY,
  SET_IS_DATASET_UPDATED,
  SET_IS_FETCHING_RELATED_RECORDS,
  SET_IS_RECORD_SEARCHING,
  SET_IS_UPDATING_DATA_SET,
  SET_RECORD_SEARCH_RESULTS,
} from './constants';

interface IDataSetBuilderReducer {
  parentRecord: DbRecordEntityTransform | undefined;
  searchResults: DbRecordEntityTransform[];
  selectedRecords: DbRecordEntityTransform[];
  selectedDataSetRecords: DbRecordEntityTransform[];
  dataSetRecords: DbRecordEntityTransform[];
  isRecordSearching: boolean;
  isUpdatingDataSet: boolean;
  isDataSetUpdated: boolean;
  isFetchingRelatedRecords: boolean;
  elasticQuery: any | undefined;
  activeStatusTab: string | undefined;
  allRecordStatuses: string[];
  pagination: IDataSetPagination;
}

export interface IDataSetPagination {
  totalRecords: number;
  totalPages: number;
  currentPage?: number;
  pageSize?: number;
}

export const dataSetBuilderInitialState: IDataSetBuilderReducer = {
  parentRecord: undefined,
  searchResults: [],
  selectedRecords: [],
  selectedDataSetRecords: [],
  dataSetRecords: [],
  isRecordSearching: false,
  isUpdatingDataSet: false,
  isDataSetUpdated: false,
  isFetchingRelatedRecords: false,
  elasticQuery: undefined,
  activeStatusTab: undefined,
  allRecordStatuses: [],
  pagination: {
    totalRecords: 0,
    totalPages: 0,
    currentPage: 1,
    pageSize: 300,
  },
};

export function dataSetBuilderReducer(
  state: IDataSetBuilderReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case SET_RECORD_SEARCH_RESULTS:
      return {
        ...state,
        searchResults: action.payload,
      };

    case SET_IS_RECORD_SEARCHING:
      return {
        ...state,
        isRecordSearching: action.payload,
      };

    case DESELECT_ALL_RECORDS:
      return {
        ...state,
        selectedRecords: [],
      };

    case SELECT_ALL_RECORDS:
      return {
        ...state,
        selectedRecords: state.searchResults,
      };

    case DESELECT_ALL_DATASET_RECORDS:
      return {
        ...state,
        selectedDataSetRecords: [],
      };

    case SELECT_ALL_DATASET_RECORDS:
      let recordsToSelect: DbRecordEntityTransform[] = [];
      if (state.activeStatusTab === 'TO_DO') {
        recordsToSelect = state.dataSetRecords.filter(
          (rec: DbRecordEntityTransform) =>
            getProperty(rec, 'DS_Status') === 'TO_DO' || !getProperty(rec, 'DS_Status'),
        );
      } else {
        recordsToSelect = state.dataSetRecords.filter(
          (rec: DbRecordEntityTransform) => getProperty(rec, 'DS_Status') === state.activeStatusTab,
        );
      }

      return {
        ...state,
        selectedDataSetRecords: recordsToSelect,
      };

    case ADD_TO_SELECTED_RECORDS:
      return {
        ...state,
        selectedRecords: [...state.selectedRecords, action.payload],
      };

    case ADD_DATASET_RECORD_TO_DATASET_SELECTION:
      return {
        ...state,
        selectedDataSetRecords: [...state.selectedDataSetRecords, action.payload],
      };

    case ADD_ASSOCIATED_RECORDS_TO_DATASET:
      return {
        ...state,
        selectedDataSetRecords: [],
        dataSetRecords: action.payload,
      };

    case REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION:
      return {
        ...state,
        selectedDataSetRecords: state.selectedDataSetRecords?.filter(
          (rec: DbRecordEntityTransform) => rec.id !== action.payload.id,
        ),
      };

    case ADD_SELECTED_RECORDS_TO_DATASET:
      return {
        ...state,
        dataSetRecords: [...state.selectedRecords, ...state.dataSetRecords],
        selectedRecords: [],
      };

    case REMOVE_FROM_SELECTED_RECORDS:
      return {
        ...state,
        selectedRecords: state.selectedRecords?.filter(
          (rec: DbRecordEntityTransform) => rec.id !== action.payload.id,
        ),
      };

    case BULK_REMOVE_DATASET_RECORD_FROM_DATASET_SELECTION:
      let newDataSetRecordsList: DbRecordEntityTransform[] = [];
      state.dataSetRecords.forEach((rec: any) => {
        if (!state.selectedDataSetRecords.find((selItem: any) => selItem.id === rec.id)) {
          newDataSetRecordsList.push(rec);
        }
      });
      return {
        ...state,
        selectedDataSetRecords: [],
        dataSetRecords: newDataSetRecordsList,
      };

    case SET_IS_UPDATING_DATA_SET:
      return {
        ...state,
        isUpdatingDataSet: action.payload,
      };

    case SET_IS_DATASET_UPDATED:
      return {
        ...state,
        isDataSetUpdated: action.payload,
      };

    case SET_IS_FETCHING_RELATED_RECORDS:
      return {
        ...state,
        isFetchingRelatedRecords: action.payload,
      };

    case SET_ELASTIC_QUERY:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          totalRecords: 0,
          totalPages: 0,
          currentPage: 1,
        },
        elasticQuery: action.payload,
      };

    case SET_ACTIVE_STATUS_TAB:
      return {
        ...state,
        activeStatusTab: action.payload,
      };

    case SET_ALL_RECORD_STATUSES:
      return {
        ...state,
        allRecordStatuses: action.payload,
      };

    case SET_DATASET_PAGINATION:
      return {
        ...state,
        pagination: {
          ...state.pagination,
          ...action.payload,
        },
        elasticQuery: {
          ...state.elasticQuery,
          searchQuery: {
            ...state.elasticQuery.searchQuery,
            pageable: {
              ...state.elasticQuery.searchQuery.pageable,
              page: action.payload.currentPage,
            },
          },
        },
      };

    case SET_DATASET_PARENT_RECORD:
      return {
        ...state,
        parentRecord: action.payload,
      };

    default:
      throw new Error();
  }
}
