import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spin } from 'antd';
import { useCalendarService, useCalendarState } from '../../context';
import { MonthView } from './MonthView';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { isMobile } from 'react-device-detect';
import ScheduleSelect from './ScheduleSelect';
import { formatDateFilter } from './utils';
import HourlyView from '../HourlyView';
import { Button, ButtonGroup, Icon, MenuItem, Tooltip } from '@blueprintjs/core';
import dayjs, { Dayjs } from 'dayjs';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { connect } from 'react-redux';
import { IAppointmentReducer } from '@legacy/core/appointments/store/reducer';

interface Props {
  appointmentReducer: IAppointmentReducer;
}

const ServiceAppointmentDetails: React.FC<Props> = (props: Props) => {
  const {
    workOrders,
    earlierAvailability,
    appointments: { view, dateFilter },
    utilization,
    schedules,
  } = useCalendarState();

  const { appointmentReducer } = props;

  const calendarService = useCalendarService();
  const nextClickHandler = () => calendarService.nextDateFilter();
  const prevClickHandler = () => calendarService.previousDateFilter();
  const viewItemDetails = (record: DbRecordEntityTransform) =>
    calendarService.showItemDetails(record);

  const onScheduleSelect = (ids: string[]) => {
    calendarService.selectSchedule(ids);
  };
  const [dailyViewLastRefreshed, setDailyViewLastRefreshed] = useState<
    Dayjs | 'loading' | 'stopped'
  >(dayjs());

  const [monthlyScheduleViewMode, setMonthlyScheduleViewMode] = useState<
    'All Schedules' | 'Overview' | 'By Region'
  >('All Schedules');
  const [dailyScheduleViewMode, setDailyScheduleViewMode] = useState<'All Schedules' | 'By Region'>(
    'All Schedules',
  );

  useEffect(() => {
    calendarService.selectSchedule([]);
  }, [monthlyScheduleViewMode]);

  const isLoading = () => {
    if (monthlyScheduleViewMode === 'Overview') {
      return appointmentReducer.isSearching || earlierAvailability.status === 'loading';
    } else if (monthlyScheduleViewMode === 'By Region') {
      return appointmentReducer.isSearching || earlierAvailability.status === 'loading';
    } else {
      return workOrders.status === 'loading' || earlierAvailability.status === 'loading';
    }
  };

  const renderEventType: ItemRenderer<any> = (event, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={
          view === 'month'
            ? event.label === monthlyScheduleViewMode
            : event.label === dailyScheduleViewMode
        }
        disabled={modifiers.disabled}
        key={event.rank}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={event.label}
      />
    );
  };

  return (
    <Card
      title={
        <Row justify="space-between" align="middle">
          <Col xs={24} md={8}>
            <span style={{ fontSize: '1.2em' }}>Calendar</span>
          </Col>
          <Col
            order={isMobile ? 1 : 0}
            xs={24}
            md={4}
            style={{
              textAlign: 'center',
              marginTop: isMobile ? 20 : undefined,
              marginBottom: isMobile ? 10 : undefined,
            }}
          >
            <Button
              outlined={view !== 'hour'}
              intent="primary"
              onClick={() => {
                setMonthlyScheduleViewMode('All Schedules');
                setDailyScheduleViewMode('All Schedules');
                calendarService.setAppointmentsFilter('hour');
              }}
              style={{
                borderRadius: '4px 0 0 4px',
                borderRight: 0,
                width: 100,
                fontWeight: view === 'hour' ? 600 : 'normal',
              }}
            >
              Daily
            </Button>

            <Button
              intent="primary"
              onClick={() => {
                setMonthlyScheduleViewMode('All Schedules');
                setDailyScheduleViewMode('All Schedules');
                calendarService.setAppointmentsFilter('month');
              }}
              style={{
                borderRadius: '0 4px 4px 0',
                width: 100,
                fontWeight: view === 'month' ? 600 : 'normal',
              }}
              outlined={view !== 'month'}
            >
              Monthly
            </Button>
          </Col>
          <Col
            order={isMobile ? 0 : 1}
            xs={24}
            md={9}
            style={{
              textAlign: isMobile ? 'center' : 'right',
              marginTop: isMobile ? 15 : undefined,
            }}
          >
            <ButtonGroup>
              {view === 'hour' && (
                <>
                  <Tooltip
                    fill
                    content="Refreshing work orders is stopped in the Assign mode."
                    disabled={dailyViewLastRefreshed !== 'stopped'}
                  >
                    <div style={{ fontSize: '10px', marginRight: 18 }}>
                      <span>Last Refreshed</span>
                      <br />
                      <span
                        style={{
                          fontWeight: 400,
                          color: dailyViewLastRefreshed === 'stopped' ? 'red' : 'black',
                          opacity: dailyViewLastRefreshed === 'loading' ? 0.3 : 1,
                        }}
                      >
                        {/* Loading / Stopped indicators */}
                        {dailyViewLastRefreshed === 'loading' && 'Loading...'}
                        {dailyViewLastRefreshed === 'stopped' && 'Stopped'}
                        {/* Date */}
                        {dailyViewLastRefreshed !== 'loading' &&
                          dailyViewLastRefreshed !== 'stopped' &&
                          dayjs(dailyViewLastRefreshed).format('HH:mm:ss')}
                      </span>
                    </div>
                  </Tooltip>
                  <Select
                    items={[
                      { value: 'All Schedules', key: 'All Schedules', label: 'All Schedules' },
                      { value: 'By Region', key: 'By Region', label: 'By Region' },
                    ]}
                    itemRenderer={renderEventType}
                    filterable={false}
                    onItemSelect={(e: any) => setDailyScheduleViewMode(e.value)}
                  >
                    <Button
                      outlined
                      style={{ marginRight: 10, display: 'inline-block' }}
                      text={dailyScheduleViewMode}
                      rightIcon="caret-down"
                    />
                  </Select>
                </>
              )}

              {/* Monthly View - Overview Filter */}
              {view === 'month' && (
                <Select
                  items={[
                    { value: 'All Schedules', key: 'All Schedules', label: 'All Schedules' },
                    { value: 'By Region', key: 'By Region', label: 'By Region' },
                    { value: 'Overview', key: 'Overview', label: 'Overview' },
                  ]}
                  itemRenderer={renderEventType}
                  filterable={false}
                  onItemSelect={(e: any) => setMonthlyScheduleViewMode(e.value)}
                >
                  <Button
                    outlined
                    style={{ marginRight: 10, display: 'inline-block' }}
                    text={monthlyScheduleViewMode}
                    rightIcon="caret-down"
                  />
                </Select>
              )}
              {/* Schedule Select */}
              <ScheduleSelect
                schedules={schedules.data}
                selectedSchedules={schedules.selectedIds}
                onScheduleSelect={onScheduleSelect}
                isOverview={monthlyScheduleViewMode === 'Overview'}
                isViewingByRegion={
                  dailyScheduleViewMode === 'By Region' || monthlyScheduleViewMode === 'By Region'
                }
              />
            </ButtonGroup>
          </Col>
        </Row>
      }
    >
      {/* Hourly view manages it's own loading spinner */}
      <Spin spinning={view === 'month' && isLoading()} size="large" tip="Loading, please wait...">
        <div>
          {view !== 'hour' && (
            <Row
              align="middle"
              style={{
                background: 'white',
                border: '1px solid #DCDCDD',
                textAlign: 'right',
                padding: '16px 10px',
              }}
            >
              {/* Date Indicator */}
              <Col xs={24} md={12} style={{ textAlign: isMobile ? 'center' : 'left' }}>
                <span
                  style={{
                    fontWeight: 600,
                    fontSize: isMobile ? '1.2rem' : '1.5em',
                    color: '#1777ff',
                  }}
                >
                  {formatDateFilter(view, dateFilter)}
                </span>
              </Col>
              {/* Previous / Next */}
              <Col
                xs={24}
                md={12}
                style={{
                  textAlign: isMobile ? 'center' : 'right',
                  marginTop: isMobile ? 15 : undefined,
                }}
              >
                <ButtonGroup>
                  <Button icon="caret-left" onClick={prevClickHandler} />
                  <Button
                    text={dayjs(dateFilter.start).format('MMMM YYYY')}
                    style={{ width: 140 }}
                  />
                  <Button icon="caret-right" onClick={() => nextClickHandler()} />
                </ButtonGroup>
              </Col>
            </Row>
          )}

          {view == 'hour' && <HourlyView setLastRefreshed={setDailyViewLastRefreshed} />}

          {view === 'month' && (
            <MonthView
              isOverview={monthlyScheduleViewMode === 'Overview'}
              workOrders={workOrders.data}
              earlyAvail={earlierAvailability.data}
              range={dateFilter}
              utilization={utilization.data}
              viewItemDetails={viewItemDetails}
            />
          )}
        </div>
      </Spin>
    </Card>
  );
};

const mapState = (state: any) => ({
  appointmentReducer: state.appointmentReducer,
});

export default connect(mapState)(ServiceAppointmentDetails);
