export const TOGGLE_RAW_DATA_DRAWER = 'TOGGLE_RAW_DATA_DRAWER';
export const SET_RAW_DATA_DRAWER_CONTENTS = 'SET_RAW_DATA_DRAWER_CONTENTS';
export const TOGGLE_LOOKUP_DRAWER = 'TOGGLE_LOOKUP_DRAWER';
export const SET_LOOKUP_RECORD = 'SET_LOOKUP_RECORD';

export const OPEN_PRODUCT_REPLACEMENT_DRAWER = 'OPEN_PRODUCT_REPLACEMENT_DRAWER';
export const CLOSE_PRODUCT_REPLACEMENT_DRAWER = 'CLOSE_PRODUCT_REPLACEMENT_DRAWER';

export const OPEN_ADD_PRODUCT_DRAWER = 'OPEN_ADD_PRODUCT_DRAWER';
export const CLOSE_ADD_PRODUCT_DRAWER = 'CLOSE_ADD_PRODUCT_DRAWER';

export const OPEN_IMPORT_FILES_DRAWER = 'OPEN_IMPORT_FILES_DRAWER';
export const CLOSE_IMPORT_FILES_DRAWER = 'CLOSE_IMPORT_FILES_DRAWER';

export const TOGGLE_ASSIGN_RECORD_TO_GROUP_MODAL = 'TOGGLE_ASSIGN_RECORD_TO_GROUP_MODAL';

export const OPEN_ORDER_BUILDER_DRAWER = 'OPEN_ORDER_BUILDER_DRAWER';
export const CLOSE_ORDER_BUILDER_DRAWER = 'CLOSE_ORDER_BUILDER_DRAWER';
export const SET_ORDER_BUILDER_DRAWER_RECORD_IDS = 'SET_ORDER_BUILDER_DRAWER_RECORD_IDS';
export const SET_ORDER_BUILDER_RECONTRACTING = 'SET_ORDER_BUILDER_RECONTRACTING';

export const TOGGLE_CREATE_UPDATE_TEMPLATE_MODAL = 'TOGGLE_CREATE_UPDATE_TEMPLATE_MODAL';
export const TOGGLE_MANAGE_ROLES_ON_TEMPLATE_MODAL = 'TOGGLE_MANAGE_ROLES_ON_TEMPLATE_MODAL';
export const TOGGLE_MANAGE_GROUPS_ON_TEMPLATE_MODAL = 'TOGGLE_MANAGE_GROUPS_ON_TEMPLATE_MODAL';
export const TOGGLE_SCHEMA_ACTION_CREATE_EDIT_DRAWER = 'TOGGLE_SCHEMA_ACTION_CREATE_EDIT_DRAWER';

export const TOGGLE_VIEW_CONFIGURATION_CREATE_EDIT_DRAWER =
  'TOGGLE_VIEW_CONFIGURATION_CREATE_EDIT_DRAWER';

export const SET_APPOINTMENT_COUNT_NUMBER = 'SET_APPOINTMENT_COUNT_NUMBER';
export const SET_MENTIONS_COUNT_NUMBER = 'SET_MENTIONS_COUNT_NUMBER';
export const TOGGLE_NOTIFICATION_DRAWER_VISIBLE = 'TOGGLE_NOTIFICATION_DRAWER_VISIBLE';

export const TOGGLE_GENERATE_WORK_ORDER_MODAL_VISIBLE = 'TOGGLE_GENERATE_WORK_ORDER_MODAL_VISIBLE';

export const OPEN_RECORD_DRAWER = 'OPEN_RECORD_DRAWER';
export const CLOSE_RECORD_DRAWER = 'CLOSE_RECORD_DRAWER';
