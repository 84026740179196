import { DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, InputNumber, Popconfirm, Row } from 'antd';
import {
  addRecordToShortList,
  IAddRecordToShortList,
} from '@legacy/core/records/store/actions';
import { invoiceBuilderContext } from '../../index';
import { REMOVE_INVOICE_ITEM, SET_INVOICE_ITEMS } from '../../store/constants';
import RecordCard from '@legacy/core/records/components/RecordCard';
import { isRecordOrderItem } from '../../helpers';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import '../../styles.scss';
import { Button } from '@blueprintjs/core';

interface Props {
  invoiceProduct: DbRecordEntityTransform;
  shortListRecord: Function;
}

const InvoiceItemCard: React.FC<Props> = (props: Props) => {
  const { invoiceProduct, shortListRecord } = props;
  const { state, dispatch } = useContext(invoiceBuilderContext);

  // Remove invoice product from the invoice
  const removeInvoiceItem = (invoiceItem: DbRecordEntityTransform) => {
    dispatch({ type: REMOVE_INVOICE_ITEM, payload: invoiceItem.id });
  };

  // Find product in the product list and update quantity
  const updateProductQuantity = (invoiceProduct: DbRecordEntityTransform, e: any) => {
    const mutatedInvoiceProducts = state.invoiceItems.map((product: DbRecordEntityTransform) => {
      if (product.id === invoiceProduct.id) {
        return { ...product, properties: { ...product.properties, Quantity: e } };
      }
      return product;
    });
    dispatch({ type: SET_INVOICE_ITEMS, payload: mutatedInvoiceProducts });
  };

  // Find product in the product list and update price
  const updateProductPrice = (invoiceProduct: DbRecordEntityTransform, e: any) => {
    const mutatedInvoiceProducts = state.invoiceItems.map((product: DbRecordEntityTransform) => {
      if (product.id === invoiceProduct.id) {
        return { ...product, properties: { ...product.properties, UnitPrice: e } };
      }
      return product;
    });
    dispatch({ type: SET_INVOICE_ITEMS, payload: mutatedInvoiceProducts });
  };

  const orderItemVisibleProperties = ['ProductCategory', 'ProductType'];

  const productVisibleProperties = ['Category', 'Type', 'TrialUnit', 'TrialLength'];

  return (
    <Col span={24} key={invoiceProduct.title} style={{ padding: 5 }}>
      <RecordCard
        openTitleLinkInNewTab
        headerElement={
          <>
            <Button
              icon={<EyeOutlined />}
              onClick={() =>
                shortListRecord({
                  showPreview: true,
                  record: invoiceProduct,
                })
              }
            />
            <Popconfirm
              placement="topLeft"
              title="Do you want to remove this item from the invoice?"
              onConfirm={() => removeInvoiceItem(invoiceProduct)}
              okText="Yes"
              cancelText="No"
            >
              <Button intent="danger" outlined icon={<DeleteOutlined />} />
            </Popconfirm>
          </>
        }
        record={invoiceProduct}
        visibleProperties={
          isRecordOrderItem(invoiceProduct) ? orderItemVisibleProperties : productVisibleProperties
        }
        footerElements={[
          <Row
            style={{
              marginBottom: 5,
              padding: '15px 15px',
              background: '#f7f7f7',
              borderRadius: 2,
            }}
          >
            <Col span={20}>
              <Row justify="start">
                {/* Qty */}
                <Col>
                  <Row>
                    <Col span={24}>
                      <span>Quantity</span>
                    </Col>
                    <Col span={24} style={{ marginTop: 10 }}>
                      <InputNumber
                        min={1}
                        max={100}
                        disabled={isRecordOrderItem(invoiceProduct)}
                        defaultValue={invoiceProduct.properties.Quantity || 1}
                        onChange={(e: any) => {
                          updateProductQuantity(invoiceProduct, e);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* Unit Price */}
                <Col>
                  <Row>
                    <Col span={24}>
                      <span>Unit Price</span>
                    </Col>
                    <Col span={24} style={{ marginTop: 10 }}>
                      <InputNumber
                        controls
                        min={0}
                        step={0.01}
                        disabled={isRecordOrderItem(invoiceProduct)}
                        defaultValue={getProperty(invoiceProduct, 'UnitPrice')}
                        parser={(value: any) => value!.replace(/\$\s?|(,*)/g, '')}
                        onChange={(e: any) => {
                          updateProductPrice(invoiceProduct, e);
                        }}
                      />
                    </Col>
                  </Row>
                </Col>

                {/* Unit Discount */}
                <Col>
                  <Row>
                    <Col span={24}>
                      <span>Total Discount</span>
                    </Col>

                    <Col span={24} style={{ marginTop: 10 }}>
                      <InputNumber
                        disabled
                        min={0}
                        step={0.01}
                        value={getProperty(invoiceProduct, 'TotalDiscounts')}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>

            <Col span={4}>
              <Row justify="end">
                {/* Total price */}
                <Col style={{ textAlign: 'right' }}>
                  <Row>
                    <Col span={24}>
                      <span style={{ fontWeight: 500, fontSize: '1.2em' }}>Total Price</span>
                    </Col>
                    <Col span={24} style={{ marginTop: 8, fontWeight: 500, fontSize: '1.2em' }}>
                      <span>{getProperty(invoiceProduct, 'TotalPrice')}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>,
        ]}
      />
    </Col>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
});

export default connect(mapState, mapDispatch)(InvoiceItemCard);
