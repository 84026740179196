import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import localizedFormat from 'dayjs/plugin/localizedFormat';

import { AnimatePresence, motion, Variants } from 'framer-motion';
import React, { Ref } from 'react';

dayjs.extend(relativeTime);
dayjs.extend(localizedFormat);
interface Message {
  id: string;
  properties: {
    Body: string;
    From: string;
  };
  hourSent: string;
  dateSent: string;
}

interface UserReducer {
  user: {
    email: string;
  };
}

interface Props {
  messagesContainerRef: Ref<HTMLDivElement>;
  messages: Message[];
  userReducer: UserReducer;
}

const variants: Variants = {
  hidden: { opacity: 0, y: -20 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 20 },
};

const ChatBody = ({ messagesContainerRef, messages, userReducer }: Props) => {
  const sortedMessages = [...messages].sort((a, b) => {
    const dateTimeA = dayjs(`${a.dateSent} ${a.hourSent}`, 'MM/DD/YYYY HH:mm:ss.SSSSSS').valueOf();
    const dateTimeB = dayjs(`${b.dateSent} ${b.hourSent}`, 'MM/DD/YYYY HH:mm:ss.SSSSSS').valueOf();

    return dateTimeA - dateTimeB;
  });
  return (
    <div className="chat-container" ref={messagesContainerRef}>
      <AnimatePresence>
        {sortedMessages.map((message) => (
          <motion.div
            key={message.id}
            className="chat-messages-container"
            variants={variants}
            initial="hidden"
            animate="visible"
            exit="exit"
            transition={{
              duration: 0.3,
              delay: 0.2,
            }}
          >
            <div
              className="chat-messages-card"
              style={{
                flexDirection:
                  message?.properties?.From === userReducer.user.email ? 'row-reverse' : 'row',
                flex: 1,
              }}
            >
              <span className="chat-messages-card-avatar-wrapper">
                <img
                  src={`https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/2048px-User-avatar.svg.png`}
                  alt="User Avatar"
                />
              </span>
              <div
                className="chat-messages-content"
                style={{
                  backgroundColor:
                    message.properties.From === userReducer.user.email
                      ? 'hsl(193.62deg 100% 31.96%)'
                      : '#C5CBD3',
                  color: message.properties.From === userReducer.user.email ? '#fff' : 'inherit',
                  flex: 0.7,
                }}
              >
                <div className="chat-messages-content-header">
                  <p className="chat-messages-content-from">{message.properties.From}</p>
                </div>
                <p
                  className="chat-messages-content-body"
                  style={{
                    color:
                      message.properties.From === userReducer.user.email
                        ? 'rgba(255, 255, 255, 0.78)'
                        : 'inherit',
                  }}
                >
                  {message.properties.Body}
                </p>
                {message.dateSent && (
                  <p className="chat-messages-content-at">
                    {dayjs().isAfter(
                      dayjs(
                        `${message.dateSent} ${message.hourSent}`,
                        'MM/DD/YYYY HH:mm:ss.SSSSSS',
                      ),
                    )
                      ? dayjs(
                          `${message.dateSent} ${message.hourSent}`,
                          'MM/DD/YYYY HH:mm:ss.SSSSSS',
                        ).fromNow()
                      : dayjs().fromNow()}
                  </p>
                )}
              </div>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default ChatBody;
