import { DbRecordAssociationRecordsTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Input, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { resetTableState } from '../../../records/components/DynamicTable/store/actions';
import { TableReducer } from '../../../records/components/DynamicTable/store/reducer';
import { resetRecordsList } from '../../../records/store/actions';
import { IRecordReducer } from '../../../records/store/reducer';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import { ISearchRecordAssociations, searchRecordAssociationsRequest } from '../../store/actions';
import { IRecordAssociationsReducer } from '../../store/reducer';

const { Search } = Input;

interface Props {
  record: DbRecordEntityTransform | undefined;
  relation: DbRecordAssociationRecordsTransform;
  searchAssociations: any;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  resetRecordState: any;
  resetTable: any;
  isControlled?: boolean;
  hideActions?: boolean;
  customFilters?: any[];
}

class RecordAssociationSearch extends React.Component<Props> {
  componentDidMount(): void {
    this.fetchData();
  }

  // We need to reset the search when the relation changes
  componentDidUpdate(prevProps: Props) {
    if (this.props.relation !== prevProps.relation) {
      this.resetSearch();
    }
  }

  resetSearch() {
    const { record, relation, searchAssociations, recordAssociationReducer } = this.props;
    if (relation && record) {
      searchAssociations({
        schema: relation.schema,
        schemaAssociation: relation.schemaAssociation,
        recordId: record.id,
        searchQuery: {
          schemas: relation.schema.id,
          terms: '',
          pageable: {
            page: 1,
            size: 50,
          },
          sort: recordAssociationReducer.searchQuery.sort,
        },
      });
    }
  }

  /**
   * ODN-2340 Builds schema types constraint filter
   *
   * @param record
   * @param relation
   * @returns
   */
  getSchemaTypesConstraintFilter(
    record: DbRecordEntityTransform,
    relation: DbRecordAssociationRecordsTransform,
  ) {
    const { customFilters } = this.props;

    if (
      relation.schemaAssociation &&
      relation.schemaAssociation.schemaTypesConstraints &&
      relation.schemaAssociation.schemaTypesConstraints?.length > 0
    ) {
      const suitableConstraints = relation.schemaAssociation?.schemaTypesConstraints?.filter(
        (c) =>
          (relation.schemaAssociation?.relationType === RelationTypeEnum.CHILD &&
            (!c.parentSchemaTypeId || c.parentSchemaTypeId === record.schemaTypeId)) ||
          (relation.schemaAssociation?.relationType === RelationTypeEnum.PARENT &&
            (!c.childSchemaTypeId || c.childSchemaTypeId === record.schemaTypeId)),
      );

      const queryText = suitableConstraints
        .map((c) =>
          relation.schemaAssociation?.relationType === RelationTypeEnum.CHILD
            ? c.childSchemaType?.name
            : c.parentSchemaType?.name,
        )
        .join(' ');

      const boolean =
        queryText?.length > 0
          ? {
              must: [
                {
                  match: {
                    type: queryText,
                  },
                },
              ],
            }
          : {};

      return boolean;
    } else {
      return customFilters || {};
    }
  }

  fetchData() {
    const { record, relation, searchAssociations, recordAssociationReducer } = this.props;
    if (relation && record) {
      searchAssociations({
        schema: relation.schema,
        schemaAssociation: relation.schemaAssociation,
        recordId: record.id,
        searchQuery: {
          schemas: relation.schema.id,
          terms: recordAssociationReducer.searchQuery.terms,
          boolean: this.getSchemaTypesConstraintFilter(record, relation),
          sort: recordAssociationReducer.searchQuery.sort,
        },
      });
    }
  }

  handleSearchRequest(e: any) {
    const { record, relation, searchAssociations, recordAssociationReducer } = this.props;
    if (!!recordAssociationReducer.searchQuery) {
      if (relation && record) {
        searchAssociations({
          schema: relation.schema,
          schemaAssociation: relation.schemaAssociation,
          recordId: record.id,
          searchQuery: {
            terms: e.target.value,
            boolean: this.getSchemaTypesConstraintFilter(record, relation),
            schemas: recordAssociationReducer.searchQuery.schemas,
            pageable: {
              page: e.current,
              size: e.pageSize,
            },
            sort: recordAssociationReducer.searchQuery.sort,
          },
        });
      }
    }
  }

  setInitialSearchQuery() {
    const { recordAssociationReducer } = this.props;
    if (!!recordAssociationReducer.searchQuery) {
      return !!recordAssociationReducer.searchQuery
        ? recordAssociationReducer.searchQuery.terms
        : '';
    }
  }

  render() {
    const { recordAssociationReducer } = this.props;
    return (
      <Row>
        <Col span={24}>
          <Search
            className="search-input"
            placeholder="Search records"
            value={this.setInitialSearchQuery()}
            loading={recordAssociationReducer.isSearching}
            onChange={(e) => this.handleSearchRequest(e)}
          />
        </Col>
      </Row>
    );
  }
}

const mapState = (state: any) => ({
  recordTableReducer: state.recordTableReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  resetTable: () => dispatch(resetTableState()),
  resetRecordState: () => dispatch(resetRecordsList()),
  searchAssociations: (params: ISearchRecordAssociations) =>
    dispatch(searchRecordAssociationsRequest(params)),
});

export default connect(mapState, mapDispatch)(RecordAssociationSearch);
