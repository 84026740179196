import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

// Create a string array with time slots for the hourly view, from 08:00AM to 20:00PM with one hour interval and AM/PM indicator
export const getHourlyViewTimeSlots = (): string[] => {
  const timeSlots: string[] = [];
  for (let i = 8; i <= 12; i++) {
    timeSlots.push(`${i}:00 AM`);
  }
  for (let i = 13; i <= 20; i++) {
    timeSlots.push(`${i}:00 PM`);
  }
  return timeSlots;
};

export const getWOTypeByLabel = (label: string, WOSchema: SchemaEntity) => {
  return WOSchema?.types?.find((type: any) => type.label === label);
};
