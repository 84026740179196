import {
  Alert,
  Button,
  Card,
  CardList,
  NonIdealState,
  Section,
  SectionCard,
  Tag,
} from '@blueprintjs/core';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import {
  CreateSchemaType,
  createSchemaTypeRequest,
  DeleteSchemaType,
  deleteSchemaTypeRequest,
} from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import FormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { renderBooleanValue } from '@core/helpers/UIHelpers';
import { formFields } from './formFields';

interface Props {
  schema: SchemaEntity | undefined;
  schemaTypeId: string | null;
  schemaReducer: ISchemaReducer;
  formReducer: SharedFormReducer;
  initializeForm: any;
  createType: (params: CreateSchemaType) => void;
  deleteType: (params: DeleteSchemaType) => void;
}

interface State {
  schemaTypeToDelete: string | undefined;
  deleteSchemaAlertVisible: boolean;
}

const uuid = uuidv4();

class TypesSection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      schemaTypeToDelete: undefined,
      deleteSchemaAlertVisible: false,
    };
  }

  showCreateForm() {
    const { schema, initializeForm } = this.props;

    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Schema Type',
      formFields: formFields,
      entityName: 'SchemaType',
      pipelineId: schema?.id,
    });
  }

  handleFormSubmit(params: FormReducerSubmitEvt) {
    const { createType, schema, formReducer } = this.props;

    if (schema && params.data && !formReducer.isUpdateReq) {
      const body = {
        schemaId: schema?.id,
        name: params.data.name,
        label: params.data.label,
        description: params.data.description,
        isDefault: params.data.isDefault,
      };

      createType({ schemaId: schema.id, body });
    }
  }

  handleDelete = () => {
    const { schema, deleteType } = this.props;
    const { schemaTypeToDelete } = this.state;

    if (schema && schemaTypeToDelete) {
      deleteType({ schemaId: schema?.id, schemaTypeId: schemaTypeToDelete });
      this.closeModal();
    } else {
      this.closeModal();
    }
  };

  closeModal = () => {
    this.setState({ deleteSchemaAlertVisible: false, schemaTypeToDelete: undefined });
  };

  render() {
    const { schema } = this.props;

    const columns = [
      { title: 'Name', dataIndex: 'name' },
      { title: 'Label', dataIndex: 'label' },
      { title: 'Description', dataIndex: 'description' },
      {
        title: 'Is Default',
        dataIndex: 'isDefault',
        align: 'center',
        render: (text: any) => renderBooleanValue(String(text)),
      },
      {
        title: '',
        dataIndex: 'operation',
        align: 'right',
        render: (text: any, record: any) => (
          <Button
            icon="trash"
            small
            minimal
            intent="danger"
            onClick={() => {
              this.setState({ deleteSchemaAlertVisible: true, schemaTypeToDelete: record.key });
            }}
          />
        ),
      },
    ];

    const data = schema?.types?.map((type: SchemaTypeEntity) => ({
      key: type.id,
      name: type.name,
      description: type.description,
      label: type.label,
      isDefault: type.isDefault,
    }));

    const renderSchemaTypes = () => {
      return (
        <CardList bordered={false}>
          {schema?.types?.map((type: SchemaTypeEntity) => (
            <Card key={type.id} interactive compact>
              <Row style={{ width: '100%' }} align="middle">
                <Col span={12}>
                  <span style={{ fontWeight: 600 }}>{type.name}</span>
                </Col>
                <Col span={8}>
                  <span>{type.description}</span>
                </Col>
                <Col span={2}>
                  <span>
                    {type.isDefault ? (
                      <Tag intent="primary" round>
                        DEFAULT
                      </Tag>
                    ) : (
                      <></>
                    )}
                  </span>
                </Col>
                <Col span={2} style={{ textAlign: 'right' }}>
                  <Button
                    icon="trash"
                    small
                    minimal
                    intent="danger"
                    onClick={() => {
                      this.setState({
                        deleteSchemaAlertVisible: true,
                        schemaTypeToDelete: type.id,
                      });
                    }}
                  />
                </Col>
              </Row>
            </Card>
          ))}
        </CardList>
      );
    };

    return (
      <div>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => this.handleFormSubmit(params)}
        />

        <Alert
          intent="danger"
          canEscapeKeyCancel
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={this.state.deleteSchemaAlertVisible}
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onConfirm={this.handleDelete}
        >
          <p>Are you sure you want to delete this schema type? This action cannot be undone.</p>
        </Alert>

        <Section
          className="types-section"
          title="Schema Types"
          rightElement={
            <Button
              style={{ borderRadius: 5 }}
              intent="success"
              icon="plus"
              onClick={() => this.showCreateForm()}
              text="Add"
              outlined
            />
          }
        >
          <SectionCard padded={false} className="types-section-card">
            <Row>
              <Col span={24}>
                {schema?.types?.length! > 0 ? (
                  renderSchemaTypes()
                ) : (
                  <NonIdealState
                    icon="search"
                    title="No schema types"
                    description="Use Add button to create a new schema type"
                  />
                )}
              </Col>
            </Row>
          </SectionCard>
        </Section>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  createType: (params: CreateSchemaType) => dispatch(createSchemaTypeRequest(params)),
  deleteType: (params: DeleteSchemaType) => dispatch(deleteSchemaTypeRequest(params)),
});

export default connect(mapState, mapDispatch)(TypesSection);
