export const GET_GROUPS_DATA_REQUEST = 'GET_GROUPS_DATA_REQUEST';
export const GET_GROUPS_DATA_SUCCESS = 'GET_GROUPS_DATA_SUCCESS';
export const GET_GROUPS_DATA_ERROR = 'GET_GROUPS_DATA_ERROR';

export const SET_ASSIGN_GROUPS_MODAL_VISIBLE = 'SET_ASSIGN_GROUPS_MODAL_VISIBLE';
export const SET_SELECTED_GROUP_USERS = 'SET_SELECTED_GROUP_USERS';

export const GET_GROUP_BY_ID_REQUEST = 'GET_GROUP_BY_ID_REQUEST';
export const GET_GROUP_BY_ID_SUCCESS = 'GET_GROUP_BY_ID_SUCCESS';
export const GET_GROUP_BY_ID_ERROR = 'GET_GROUP_BY_ID_ERROR';

export const DELETE_GROUP_REQUEST = 'DELETE_GROUP_REQUEST';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_ERROR = 'DELETE_GROUP_ERROR';

export const CREATE_GROUP_REQUEST = 'CREATE_GROUP_REQUEST';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_ERROR = 'CREATE_GROUP_ERROR';

export const ASSIGN_USERS_TO_GROUP_REQUEST = 'ASSIGN_USERS_TO_GROUP_REQUEST';
export const ASSIGN_USERS_TO_GROUP_SUCCESS = 'ASSIGN_USERS_TO_GROUP_SUCCESS';
export const ASSIGN_USERS_TO_GROUP_ERROR = 'ASSIGN_USERS_TO_GROUP_ERROR';

export const GET_GROUPS_LINKS_REQUEST = 'GET_GROUPS_LINKS_REQUEST';
export const GET_GROUPS_LINKS_SUCCESS = 'GET_GROUPS_LINKS_SUCCESS';
export const GET_GROUPS_LINKS_ERROR = 'GET_GROUPS_LINKS_ERROR';

export const ASSIGN_GROUP_TO_GROUP_REQUEST = 'ASSIGN_GROUP_TO_GROUP_REQUEST';
export const ASSIGN_GROUP_TO_GROUP_SUCCESS = 'ASSIGN_GROUP_TO_GROUP_SUCCESS';
export const ASSIGN_GROUP_TO_GROUP_ERROR = 'ASSIGN_GROUP_TO_GROUP_ERROR';

export const DELETE_GROUP_LINK_REQUEST = 'DELETE_GROUP_LINK_REQUEST';
export const DELETE_GROUP_LINK_SUCCESS = 'DELETE_GROUP_LINK_SUCCESS';
export const DELETE_GROUP_LINK_ERROR = 'DELETE_GROUP_LINK_ERROR';

export const MOVE_USERS_TO_GROUP_REQUEST = 'MOVE_USERS_TO_GROUP_REQUEST';
export const MOVE_USERS_TO_GROUP_SUCCESS = 'MOVE_USERS_TO_GROUP_SUCCESS';
export const MOVE_USERS_TO_GROUP_ERROR = 'MOVE_USERS_TO_GROUP_ERROR';

export const ASSIGN_GROUPS_TO_USERS_REQUEST = 'ASSIGN_GROUPS_TO_USERS_REQUEST';
export const ASSIGN_GROUPS_TO_USERS_SUCCESS = 'ASSIGN_GROUPS_TO_USERS_SUCCESS';
export const ASSIGN_GROUPS_TO_USERS_ERROR = 'ASSIGN_GROUPS_TO_USERS_ERROR';

export const UPDATE_BULK_USERS_GROUPS_REQUEST = 'UPDATE_BULK_USERS_GROUPS_REQUEST';
export const UPDATE_BULK_USERS_GROUPS_SUCCESS = 'UPDATE_BULK_USERS_GROUPS_SUCCESS';
export const UPDATE_BULK_USERS_GROUPS_ERROR = 'UPDATE_BULK_USERS_GROUPS_ERROR';
