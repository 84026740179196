import {
  CaretDownFilled,
  CheckCircleTwoTone,
  CheckOutlined,
  ExclamationCircleTwoTone,
  PhoneOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import { Callout } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Button, Col, Divider, Dropdown, MenuProps, Row, Skeleton, Space, Tag } from 'antd';
import { DateTime } from 'luxon';
import { FC, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { OrderNetworkDevicesContext } from '../index';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { getRecordAssociationsRequest, IGetRecordAssociations } from '@legacy/core/recordsAssociations/store/actions';
import { getSchemaByModuleAndEntityRequest } from '@legacy/core/schemas/store/actions';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { httpGet, httpPost } from '@core/http/requests';
import { hasPermissions } from '@core/helpers/rbacRules';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import {
  SET_NETWORK_DRAWER_DEVICE_RECORD,
  SET_NETWORK_DRAWER_ORDER_ITEM_RECORD,
  SET_NETWORK_DRAWER_VISIBLE,
} from '@core/modules/OrderModule/components/OrderItemDeviceList/store/constants';
import { SupportedMethods } from '../../../OrderItem/NetworkPanel/supported-methods.enum';
import DeviceListSection from '../shared/DeviceListSection';

interface INetworkBaseProductProps {
  record: DbRecordEntityTransform; // OrderItem record
  orderRecord: DbRecordEntityTransform; // Order record
  onUpdated?: () => void;
  userReducer: any;
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
  initializeForm: (params: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  disabled?: boolean;
}

const uuid = uuidv4();
const { SERVICE_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER_ITEM } = SchemaModuleEntityTypeEnums;
const VOICE_CONFIGURATION = 'VoiceConfiguration';

type TServiceStatus = {
  color: string;
  text: 'NOT PROVISIONED' | 'PROVISIONED' | 'CHECKING';
  enabledMethods: number[];
  locked: boolean;
};

let timer: NodeJS.Timeout | undefined = undefined;
const interval = 4000;

const NetworkVoiceConfiguration: FC<INetworkBaseProductProps> = (props) => {
  const {
    record,
    onUpdated,
    initializeForm,
    getAssociations,
    userReducer,
    orderRecord,
    alertMessage,
    disabled,
  } = props;
  const [voiceConfiguration, setVoiceConfiguration] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [voiceConfigurationSchema, setVoiceConfigurationSchema] = useState<
    SchemaEntity | undefined
  >(undefined);
  const [error, setError] = useState<any>(undefined);
  const [serviceStatus, setServiceStatus] = useState<TServiceStatus | undefined>(undefined);
  const [isProvisioning, setIsProvisioning] = useState<boolean>(false);
  const [isDeprovisioning, setIsDeprovisioning] = useState<boolean>(false);
  const [isPorting, setIsPorting] = useState<boolean>(false);
  const [isCheckingStatus, setIsCheckingStatus] = useState<boolean>(false);
  const { state, dispatch } = useContext(OrderNetworkDevicesContext);

  const startTimer = (schema: SchemaEntity) => {
    timer = setInterval(() => {
      if (!document.hidden) {
        getServiceStatus();
        getVoiceConfiguration(schema);
      }
    }, interval);
  };

  // Start timer on component mount, clear timer on unmount
  useEffect(() => {
    fetchSchemas();

    return () => {
      clearInterval(timer);
      timer = undefined;
    };
  }, []);

  // On Component mount, fetch the Service / Data status
  useEffect(() => {
    getServiceStatus();
  }, []);

  const fetchSchemas = async () => {
    const OISchema = await getOdinSchemaByEntity(ORDER_MODULE, ORDER_ITEM);
    if (OISchema) {
      startTimer(OISchema);
      setIsLoading(true);
      getVoiceConfiguration(OISchema);
    }

    const VCSchema = await getOdinSchemaByEntity(SERVICE_MODULE, VOICE_CONFIGURATION);
    if (VCSchema) {
      setVoiceConfigurationSchema(VCSchema);
    }
  };

  const getVoiceConfiguration = (schema: SchemaEntity) => {
    if (schema) {
      getAssociations(
        {
          recordId: record.id,
          key: ORDER_ITEM,
          schema: schema,
          entities: [VOICE_CONFIGURATION],
        },
        (res: any) => {
          if (res && res.results?.[VOICE_CONFIGURATION]?.dbRecords?.length > 0) {
            const voiceConfiguration = res.results?.[VOICE_CONFIGURATION]?.dbRecords?.[0];
            setVoiceConfiguration(voiceConfiguration);

            const lastAction = getProperty(voiceConfiguration, 'LastAction');

            setIsCheckingStatus(
              (isCheckingStatus) => isCheckingStatus && lastAction === 'CHECK_VOICE_REQUEST',
            );
            setIsProvisioning(
              (isProvisioning) => isProvisioning && lastAction === 'ACTIVATE_VOICE_REQUEST',
            );
            setIsDeprovisioning(
              (isDeprovisioning) => isDeprovisioning && lastAction === 'DEACTIVATE_VOICE_REQUEST',
            );

            setIsLoading(false);
          } else {
            setIsLoading(false);
          }
        },
      );
    }
  };

  const initializeUpdateForm = () => {
    if (voiceConfigurationSchema && voiceConfiguration) {
      if (voiceConfigurationSchema) {
        initializeForm({
          formUUID: uuid,
          title: `Update ${voiceConfigurationSchema.description}`,
          selected: voiceConfiguration,
          showFormModal: true,
          isCreateReq: false,
          isUpdateReq: true,
          schema: voiceConfigurationSchema,
          sections: [
            {
              name: voiceConfigurationSchema.name,
              schema: voiceConfigurationSchema,
            },
          ],
        });
      }
    }
  };

  const isMethodEnabled = (method: SupportedMethods) => {
    return serviceStatus && serviceStatus?.enabledMethods?.includes(method);
  };

  const openNetworkDrawer = () => {
    dispatch({ type: SET_NETWORK_DRAWER_VISIBLE, payload: true });
    dispatch({ type: SET_NETWORK_DRAWER_DEVICE_RECORD, payload: record });
    dispatch({ type: SET_NETWORK_DRAWER_ORDER_ITEM_RECORD, payload: record });
  };

  const getServiceStatus = () => {
    const type = 'voice';
    httpGet(`ServiceModule/v2.0/network/onu/${type}/${record.id}/state`)
      .then((res: any) => {
        if (res.data?.data) {
          setServiceStatus(res.data.data);
        }
      })
      .catch((err: any) => {
        setServiceStatus(undefined);
      });
  };

  const provisionService = () => {
    const type = 'voice';
    setError(undefined);
    setIsProvisioning(true);
    httpPost(`ServiceModule/v2.0/network/onu/${type}/${record.id}/activate`, {})
      .then((res: any) => {
        alertMessage({
          body: `Voice is being provisioned, wait a few minutes before running a check.`,
          type: 'success',
        });
        getServiceStatus();
      })
      .catch((err: any) => {
        setIsProvisioning(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not provision service.');
        console.log('debug: provision err', err);
      });
  };

  const checkServiceStatus = () => {
    setIsCheckingStatus(true);
    setError(undefined);
    const type = 'voice';
    httpPost(`ServiceModule/v2.0/network/onu/${type}/${record.id}/check`, {})
      .then((res: any) => {
        alertMessage({
          body: `Voice is being checked, this can take some time.`,
          type: 'success',
        });
      })
      .catch((err) => {
        setIsCheckingStatus(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not check service status.');
        console.log(err);
      });
  };

  const deprovisionService = () => {
    const type = 'voice';
    setError(undefined);
    setIsDeprovisioning(true);
    httpPost(`ServiceModule/v2.0/network/onu/${type}/${record.id}/deactivate`, {})
      .then((res: any) => {
        alertMessage({
          body: `Voice is being deprovisioned, this may take some time.`,
          type: 'success',
        });
        getServiceStatus();
      })
      .catch((err: any) => {
        setIsDeprovisioning(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not deprovision service.');
      });
  };

  const requestPorting = () => {
    setError(undefined);
    setIsPorting(true);
    httpPost(`ServiceModule/v2.0/network/voice/port/${orderRecord.id}`, {})
      .then((res: any) => {
        alertMessage({
          body: `Voice porting request has been submitted.`,
          type: 'success',
        });
        setIsPorting(false);
      })
      .catch((err: any) => {
        setIsPorting(false);
        const error = err.response ? err.response.data : undefined;
        setError(error?.message || 'Could not request porting service.');
      });
  };

  const areActionsDisabled = () => {
    return (
      disabled ||
      getProperty(voiceConfiguration, 'LastAction') === 'CHECK_VOICE_REQUEST' ||
      getProperty(voiceConfiguration, 'LastAction') === 'DEACTIVATE_VOICE_REQUEST' ||
      getProperty(voiceConfiguration, 'LastAction') === 'ACTIVATE_VOICE_REQUEST'
    );
  };

  const isManageLoading = () => {
    return (
      getProperty(voiceConfiguration, 'LastAction') === 'DEACTIVATE_VOICE_REQUEST' ||
      getProperty(voiceConfiguration, 'LastAction') === 'ACTIVATE_VOICE_REQUEST'
    );
  };

  const getItems = () => {
    const items: MenuProps['items'] = [
      {
        key: '0',
        label: 'Edit',
        onClick: initializeUpdateForm,
        icon: <i className="bi bi-pencil-square" />,
        disabled: !voiceConfiguration || !voiceConfigurationSchema,
      },
      {
        key: '1',
        label: 'Provision',
        disabled: !isMethodEnabled(SupportedMethods.PROVISION),
        onClick: provisionService,
        icon: <i className="bi bi-box-arrow-right" />,
      },
      {
        key: '2',
        label: 'Deprovision',
        disabled: !isMethodEnabled(SupportedMethods.DEPROVISION),
        onClick: deprovisionService,
        icon: <i className="bi bi-box-arrow-left" />,
      },
    ];

    if (hasPermissions(userReducer, ['servicemodule.voice.manualport'])) {
      items.push({
        key: '3',
        label: 'Request Porting',
        disabled:
          areActionsDisabled() ||
          getProperty(voiceConfiguration, 'MagratheaOrderId') ||
          getProperty(voiceConfiguration, 'MagratheaOrderStatus') ||
          !getProperty(voiceConfiguration, 'PhoneNumberToPort') ||
          !getProperty(voiceConfiguration, 'PhoneNumberToPortParts'),
        onClick: requestPorting,
        icon: <PhoneOutlined />,
      });
    }

    return items;
  };

  const isLastCheckStatusDateOlderThanOneDay = (record: DbRecordEntityTransform) => {
    // Create a Luxon DateTime object for the current date and time
    const currentDate = DateTime.now();
    // Subtract 1 day from the current date
    const oneDayAgo = currentDate.minus({ days: 1 });
    // Create a Luxon DateTime object for the date you want to compare
    const dateToCompare = DateTime.fromISO(getProperty(record, 'LastCheckStatusDate')); // Replace with your target date
    // Check if dateToCompare is 1 day or more in the past
    return dateToCompare < oneDayAgo;
  };

  const getOverallStatus = () => {
    const phoneNumberToPort = getProperty(voiceConfiguration, 'PhoneNumberToPort');
    const magratheaOrderStatus = getProperty(voiceConfiguration, 'MagratheaOrderStatus');
    const sipwiseCustomerContactId = getProperty(voiceConfiguration, 'SipwiseCustomerContactId');
    const sipwiseCustomerId = getProperty(voiceConfiguration, 'SipwiseCustomerId');
    const sipwiseSubscriberId = getProperty(voiceConfiguration, 'SipwiseSubscriberId');

    let error = false;
    let provisioned = false;

    if (
      phoneNumberToPort &&
      ['Rejected', 'Rejected - Closed', 'Rejected - FOC', 'Rejected - FOC - Closed'].includes(
        magratheaOrderStatus,
      )
    ) {
      error = true;
    }

    if (
      (sipwiseCustomerContactId || sipwiseCustomerId || sipwiseSubscriberId) &&
      (!sipwiseCustomerContactId || !sipwiseCustomerId || !sipwiseSubscriberId)
    ) {
      error = true;
    }

    if (serviceStatus?.color === 'red') {
      error = true;
    }

    if (phoneNumberToPort) {
      provisioned = true;
    }

    if (sipwiseCustomerContactId || sipwiseCustomerId || sipwiseSubscriberId) {
      provisioned = true;
    }

    if (serviceStatus && serviceStatus?.color !== 'gray') {
      provisioned = true;
    }

    if (error) {
      return <ExclamationCircleTwoTone twoToneColor={'red'} />;
    }

    if (provisioned) {
      return <CheckCircleTwoTone twoToneColor={'#52c41a'} />;
    }
  };

  const voicePortingSection = () => {
    let tag = <Tag>PORTING NOT REQUESTED</Tag>;
    let portingRequested = false;

    const phoneNumberToPort = getProperty(voiceConfiguration, 'PhoneNumberToPort');
    const portFromProvider = getProperty(voiceConfiguration, 'PortFromProvider');
    const magratheaOrderId = getProperty(voiceConfiguration, 'MagratheaOrderId');
    const magratheaOrderStatus = getProperty(voiceConfiguration, 'MagratheaOrderStatus');
    const magratheaOrderStatusDate = getProperty(voiceConfiguration, 'MagratheaOrderStatusDate');

    if (phoneNumberToPort) {
      tag = <Tag color={'blue'}>WAITING TO PORT</Tag>;
      portingRequested = true;
    }

    if (phoneNumberToPort && magratheaOrderId) {
      tag = <Tag color={'blue'}>PORTING REQUEST PENDING</Tag>;
      portingRequested = true;
    }

    if (phoneNumberToPort && magratheaOrderStatus === 'Completed') {
      tag = (
        <Tag icon={<CheckOutlined />} color={'green'}>
          PORTED
        </Tag>
      );
      portingRequested = true;
    }

    if (
      phoneNumberToPort &&
      ['Rejected', 'Rejected - Closed', 'Rejected - FOC', 'Rejected - FOC - Closed'].includes(
        magratheaOrderStatus,
      )
    ) {
      tag = <Tag color={'red'}>PORTING FAILED</Tag>;
      portingRequested = true;
    }

    const details = portingRequested
      ? [
        {
          label: 'Porting Phone Number',
          value: phoneNumberToPort,
        },
        {
          label: 'Porting From Provider #',
          value: portFromProvider,
        },
        {
          label: 'Porting Order #',
          value: magratheaOrderId,
        },
        {
          label: 'Porting Status',
          value: magratheaOrderStatus
            ? `${magratheaOrderStatus} ${
              magratheaOrderStatusDate
                ? `(last updated at ${DateTime.fromISO(magratheaOrderStatusDate).toFormat(
                  'd/M/yyyy h:mm a',
                )})`
                : ''
            }`
            : undefined,
        },
      ]
      : [];

    return <DeviceListSection name={'Voice Porting'} tags={[tag]} details={details} />;
  };

  const sipwiseSection = () => {
    let tag = <Tag>NOT CONFIGURED</Tag>;

    const sipwiseCustomerContactId = getProperty(voiceConfiguration, 'SipwiseCustomerContactId');
    const sipwiseCustomerId = getProperty(voiceConfiguration, 'SipwiseCustomerId');
    const sipwiseSubscriberId = getProperty(voiceConfiguration, 'SipwiseSubscriberId');

    if (
      (sipwiseCustomerContactId || sipwiseCustomerId || sipwiseSubscriberId) &&
      (!sipwiseCustomerContactId || !sipwiseCustomerId || !sipwiseSubscriberId)
    ) {
      tag = <Tag color={'red'}>MISSING RECORDS</Tag>;
    }

    if (sipwiseCustomerContactId && sipwiseCustomerId && sipwiseSubscriberId) {
      tag = (
        <Tag icon={<CheckOutlined />} color={'green'}>
          CONFIGURED
        </Tag>
      );
    }

    const details = [
      {
        label: 'Contact Id',
        value: sipwiseCustomerContactId,
      },
      {
        label: 'Customer Id',
        value: sipwiseCustomerId,
      },
      {
        label: 'Subscriber Id',
        value: sipwiseSubscriberId,
      },
    ];

    return <DeviceListSection name={'Sipwise'} tags={[tag]} details={details} />;
  };

  const provisionSection = () => {
    let tag = <Tag>STATE UNKNOWN</Tag>;

    const issuedPhoneNumber = getProperty(voiceConfiguration, 'IssuedPhoneNumber');
    const voiceOperStatus = getProperty(voiceConfiguration, 'VoicePortOperStatus');
    const voiceAdminStatus = getProperty(voiceConfiguration, 'VoicePortAdminStatus');
    const voiceServerStatus = getProperty(voiceConfiguration, 'VoiceServerStatus');
    const voiceLineState = getProperty(voiceConfiguration, 'VoiceLineState');
    const voiceIp = getProperty(voiceConfiguration, 'VoiceIp');
    const phoneNumberToPort = getProperty(voiceConfiguration, 'PhoneNumberToPort');
    const magratheaOrderStatus = getProperty(voiceConfiguration, 'MagratheaOrderStatus');
    const lastAction = getProperty(voiceConfiguration, 'LastAction');
    const lastActionDate = getProperty(voiceConfiguration, 'LastActionDate');

    if (serviceStatus) {
      tag = (
        <Tag
          icon={
            ['CHECKING', 'REBOOTING', 'PROVISIONING', 'DEPROVISIONING'].includes(
              serviceStatus.text,
            ) ? (
              <SyncOutlined spin />
            ) : undefined
          }
          color={serviceStatus.color}
        >
          {serviceStatus.color === 'green' ? <CheckOutlined /> : <></>} SERVICE {serviceStatus.text}
        </Tag>
      );
    }

    let phoneNumber = issuedPhoneNumber
      ? phoneNumberToPort
        ? `${issuedPhoneNumber} (temporary)`
        : issuedPhoneNumber
      : undefined;

    if (phoneNumberToPort && magratheaOrderStatus === 'Completed') {
      phoneNumber = phoneNumberToPort;
    }

    const details = [
      {
        label: 'Phone Number',
        value: phoneNumber,
      },
      {
        label: 'Voice Oper Status',
        value: voiceOperStatus === 'pending' ? undefined : voiceOperStatus,
        validValues: ['up'],
      },
      {
        label: 'Voice Admin Status',
        value: voiceAdminStatus,
        validValues: ['up'],
      },
      {
        label: 'Voice Server Status',
        value: voiceServerStatus,
        validValues: ['registered', 'SUCCESS'],
      },
      {
        label: 'Voice Line State',
        value: voiceLineState,
      },
      {
        label: 'Voice Ip',
        value: voiceIp,
        //invalidValues: ['0.0.0.0'],
      },
    ];

    let notification = undefined;

    if (
      lastActionDate &&
      voiceOperStatus === 'pending' &&
      lastAction === 'ACTIVATE_VOICE_COMPLETE'
    ) {
      const date = DateTime.fromISO(lastActionDate).plus({
        minute: 5,
      });

      notification = `Please wait until ${date.toFormat(
        'h:mm a',
      )} before running a check to get accurate results!`;
    }

    return (
      <DeviceListSection
        name={'Provision'}
        tags={[tag]}
        details={details}
        notification={notification}
      />
    );
  };

  const metaSection = () => {
    const lastAction = getProperty(voiceConfiguration, 'LastAction');
    const lastActionDate = getProperty(voiceConfiguration, 'LastActionDate');
    const lastCheckDate = getProperty(voiceConfiguration, 'LastCheckStatusDate');

    const details = [
      {
        label: 'Last Action',
        value:
          lastAction && lastActionDate
            ? `${lastAction} at ${DateTime.fromISO(lastActionDate).toFormat('d/M/yyyy h:mm a')}`
            : undefined,
      },
      {
        label: 'Last Check Status at',
        value: lastCheckDate
          ? DateTime.fromISO(lastActionDate).toFormat('d/M/yyyy h:mm a')
          : undefined,
      },
    ];

    return <DeviceListSection details={details} columns={1} layout={'horizontal'} />;
  };

  return (
    <>
      {isLoading && !voiceConfiguration && (
        <Skeleton active>
          <Row>
            <Col span={24}></Col>
          </Row>
        </Skeleton>
      )}
      {!isLoading && !voiceConfiguration && !error && (
        <Callout title="Nothing to action" intent="success" />
      )}
      {!isLoading && voiceConfiguration && (
        <div
          style={{
            borderRadius: 2,
            background: '#f5f5f5',
            border: '1px solid #e2e2e2',
            padding: '15px 15px',
          }}
        >
          <Row justify="space-between" align="middle">
            <Col>
              <ModuleEntityIcon
                moduleName={SERVICE_MODULE}
                entityName="VoiceConfiguration"
                overrideIconColor="gray"
              />
              <div style={{ position: 'absolute', left: 18, bottom: -12, fontSize: 20 }}>
                {getOverallStatus()}
              </div>
              <span style={{ fontWeight: 600 }}>Voice Configuration</span>
            </Col>
            <Col style={{ marginTop: isMobile ? 15 : 0 }}>
              {/* Dropdown Button - Manage */}
              <Dropdown menu={{ items: getItems() }} trigger={['click']}>
                <Button
                  type="primary"
                  size="small"
                  ghost
                  style={{ marginRight: 10 }}
                  disabled={
                    areActionsDisabled() ||
                    isProvisioning ||
                    isDeprovisioning ||
                    isCheckingStatus ||
                    isPorting
                  }
                  loading={isManageLoading() || isProvisioning || isDeprovisioning || isPorting}
                >
                  <Space>
                    Manage
                    <CaretDownFilled />
                  </Space>
                </Button>
              </Dropdown>

              {/* Button Details */}
              <Button
                onClick={openNetworkDrawer}
                size="small"
                style={{ marginRight: 10 }}
                type="primary"
                ghost
                disabled={areActionsDisabled()}
              >
                Show Details
              </Button>

              {/* Button - Run Check */}
              <Button
                icon={<i className="bi bi-check2-circle" />}
                type="primary"
                size="small"
                onClick={checkServiceStatus}
                disabled={areActionsDisabled() || isCheckingStatus || state?.runningBulkONTCheck}
                loading={
                  getProperty(voiceConfiguration, 'LastAction') === 'CHECK_VOICE_REQUEST' ||
                  isCheckingStatus ||
                  state?.runningBulkONTCheck
                }
              >
                Run Check
              </Button>
            </Col>
          </Row>
          <Row>
            {/* Divider */}
            <Col span={24}>
              <Divider
                style={{
                  marginTop: 15,
                  marginBottom: 10,
                  borderColor: '#e2e2e2',
                }}
              />
            </Col>

            {/* Error panel */}
            {error && (
              <Col span={24}>
                <Alert
                  style={{ marginBottom: 10 }}
                  type="error"
                  message={error}
                  onClose={() => setError(undefined)}
                  closable
                />
              </Col>
            )}

            <Col span={24}>
              {/* Notifications */}
              {isLastCheckStatusDateOlderThanOneDay(record) && (
                <Alert
                  style={{ marginBottom: 10 }}
                  type={'warning'}
                  message={
                    'The last check status date is older than 1 day. Please run a check to update the status.'
                  }
                />
              )}

              {getProperty(record, 'LastAction')?.indexOf('ERROR') > -1 && (
                <Alert
                  style={{ marginBottom: 10 }}
                  type={'error'}
                  message={`The last action performed had an error: ${getProperty(
                    record,
                    'LastAction',
                  )} at ${DateTime.fromISO(getProperty(record, 'LastActionDate')).toFormat(
                    'd/M/yyyy h:mm a',
                  )}`}
                />
              )}

              {voicePortingSection()}

              <Divider style={{ marginTop: 10, marginBottom: 15 }} />

              {sipwiseSection()}

              <Divider style={{ marginTop: 10, marginBottom: 15 }} />

              {provisionSection()}

              <Divider style={{ marginTop: 10, marginBottom: 15 }} />

              {metaSection()}
            </Col>
          </Row>

          {/* Form */}
          <CoreForm
            type="MODAL"
            formUUID={uuid}
            onSubmitEvent={(params: { event: string; res: any }) => {
              onUpdated && onUpdated();
            }}
          />
        </div>
      )}
    </>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (params: any, cb: any) => dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(NetworkVoiceConfiguration);
