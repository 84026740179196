import {
  Button,
  Callout,
  Card,
  DialogStep,
  Elevation,
  MultistepDialog,
  Section,
  SectionCard,
  Spinner,
} from '@blueprintjs/core';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getAllRelations,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Descriptions } from 'antd';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { httpGet, httpPost } from '@core/http/requests';
import { parseDateToLocalFormat } from '@core/helpers/dateHelpers';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';

type PropsType = {
  record: any;
  recordReducer: IRecordReducer;
  recordFormReducer: any;
  initializeForm: any;
  onClose?: any;
  onConfirm?: any;
  fill?: boolean;
};

const refundFormUUID = uuidv4();
const { BILLING_MODULE } = SchemaModuleTypeEnums;

const PaymentMethodRefund: FunctionComponent<PropsType> = (props) => {
  const { record, recordFormReducer, initializeForm, onConfirm, fill } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [paymentMethodRefundSchema, setPaymentMethodRefundSchema] = React.useState<
    SchemaEntity | undefined
  >(undefined);
  const [dialogState, setDialogState] = React.useState<{
    stepOne: {
      paymentMethod: DbRecordEntityTransform | undefined;
    };
    stepTwo: {
      formData: DbRecordCreateUpdateDto | undefined;
    };
  }>({
    stepOne: {
      paymentMethod: undefined,
    },
    stepTwo: {
      formData: undefined,
    },
  });
  const [contact, setContact] = React.useState<DbRecordEntityTransform | undefined>(undefined);
  const [paymentMethodRefund, setPaymentMethodRefund] = React.useState<
    DbRecordEntityTransform | undefined
  >(undefined);
  const [stepError, setError] = React.useState<any>(undefined);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);
  const [schemaActions, setSchemaActions] = React.useState<SchemaActionEntity[]>([]);

  function resetState() {
    setIsNextDisabled(true);
    setPaymentMethodRefund(undefined);
    setContact(undefined);
    setDialogState({
      stepOne: {
        paymentMethod: undefined,
      },
      stepTwo: {
        formData: undefined,
      },
    });
  }

  useEffect(() => {
    if (record) {
      fetchData();
    }
  }, [record]);

  useEffect(() => {
    if (isDialogOpen) {
      resetState();
      fetchData();
    }
  }, [isDialogOpen]);

  const fetchData = async () => {
    if (record) {
      const schema = await getOdinSchemaByEntity(BILLING_MODULE, 'PaymentMethodRefund');
      setPaymentMethodRefundSchema(schema);

      // Get the order contact and payment methods
      httpGet(
        `OrderModule/v1.0/db-associations/Contact/${record.id}/relations?entities=["PaymentMethod", "PaymentMethodRefund"]&withLinks=false`,
      )
        .then((res) => {
          const contact: DbRecordEntityTransform = {
            ...record,
            ...res.data.data,
          };
          setContact(contact);
        })
        .catch((err) => {
          console.error('Error loading tab data:', err);
        });
    }

    httpGet(`SchemaModule/v1.0/schemas-actions`)
      .then((res) => {
        setSchemaActions(res.data.data);
      })
      .catch((err) => {
        console.error('Error loading table data:', err);
      });
  };

  const mandateRefundCreateAction = schemaActions?.find((action: SchemaActionEntity) => {
    return action.name === 'CreatePaymentMethodRefund';
  });

  const existingRefunds = () => {
    if (contact) {
      return getAllRelations(contact, 'PaymentMethodRefund') || [];
    } else {
      return [];
    }
  };

  function setupStep(newStep: string) {
    let stepNumber = 0;
    if (newStep === 'step-one') {
      stepNumber = 0;
    } else if (newStep === 'step-two') {
      stepNumber = 1;
    } else if (newStep === 'confirm') {
      stepNumber = 2;
    }

    switch (stepNumber) {
      case 0:
        // We might handle some validation here to prevent
        // Double refunds from happening.
        break;
      case 1:
        if (!mandateRefundCreateAction) {
          return;
        }
        // Save the modified data from step 1 locally
        initializeForm({
          formUUID: refundFormUUID,
          schemaActionId: mandateRefundCreateAction?.id,
          showFormModal: true,
          hideRecordFormFields: true,
          showInitializing: false,
          isCreateReq: true,
          schema: paymentMethodRefundSchema,
          modified: dialogState.stepTwo?.formData ? [dialogState.stepTwo?.formData] : [],
          sections: [
            {
              name: paymentMethodRefundSchema?.name,
              schema: paymentMethodRefundSchema,
              associations: [
                {
                  entity: record.entity,
                  recordId: record.id,
                },
                {
                  entity: contact?.entity,
                  recordId: contact?.id,
                },
                {
                  entity: dialogState?.stepOne?.paymentMethod?.entity,
                  recordId: dialogState?.stepOne?.paymentMethod?.id,
                },
              ],
            },
          ],
        });
        break;
      case 2:
        setDialogState({
          ...dialogState,
          stepTwo: {
            formData: {
              ...recordFormReducer.modified[0],
              title: `Refund for ${getProperty(contact, 'FirstName')} ${getProperty(
                contact,
                'LastName',
              )}`,
              associations: [
                ...recordFormReducer.modified[0]?.associations,
                ...recordFormReducer.sections[0]?.associations,
              ],
            },
          },
        });
        break;
      default:
        break;
    }
  }

  async function handleFinalStepSubmit() {
    setError(undefined);
    setIsConfirmLoading(true);
    // Add additional data to the form data
    try {
      if (!paymentMethodRefund) {
        const refundRes = await httpPost(`BillingModule/v1.0/db/bulk-upsert`, {
          recordsToUpsert: [dialogState?.stepTwo?.formData],
        });
        const newPaymentMethodRefund = refundRes.data?.data?.creates?.[0];

        if (newPaymentMethodRefund) {
          const getRefundRes = await httpGet(
            `BillingModule/v1.0/db/PaymentMethodRefund/${newPaymentMethodRefund?.id}`,
          );
          setPaymentMethodRefund(getRefundRes.data?.data);

          setIsConfirmLoading(false);
          // setIsDialogOpen(false);

          if (onConfirm) {
            onConfirm();
          }
        }
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      setError(err?.response?.data?.message);
      console.error('Error:', err);
    } finally {
      setIsConfirmLoading(false);
    }
  }

  return (
    <>
      <Button
        intent="primary"
        outlined
        fill={fill}
        onClick={() => {
          setIsDialogOpen(true);
        }}
      >
        Mandate Refund
      </Button>
      <MultistepDialog
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        className="multistep-dialog-payment-method-refund"
        icon="info-sign"
        navigationPosition="top"
        onClose={() => {
          resetState();
          setIsDialogOpen(false);
        }}
        onOpened={() => setupStep('step-one')}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
        }}
        finalButtonProps={{
          loading: isConfirmLoading,
          disabled: isConfirmLoading || !!paymentMethodRefund,
          onClick: () => handleFinalStepSubmit(),
        }}
        title={'New Refund'}
      >
        <DialogStep
          id="step-one"
          title="Select payment method"
          panel={
            <Section>
              <SectionCard>
                <h3>Existing refunds</h3>
                <Callout
                  intent={existingRefunds()?.length > 0 ? 'warning' : 'success'}
                  title={existingRefunds()?.length > 0 ? 'Existing Refunds' : 'No refunds found'}
                >
                  <div>
                    {existingRefunds()?.map((refund) => (
                      <Link target="_blank" to={getBrowserPath(refund)}>
                        <div>
                          <div>
                            {refund.recordNumber} [{getProperty(refund, 'Status')}] was created on
                            {parseDateToLocalFormat(String(refund.createdAt))} for
                            {getProperty(refund, 'Amount')}
                          </div>
                        </div>
                      </Link>
                    ))}
                  </div>
                </Callout>
              </SectionCard>

              <SectionCard>
                <h3>Select payment method to refund</h3>
                {/*<pre>{contact && JSON.stringify(getAllRelations(contact, 'PaymentMethod'))}</pre>*/}
                {contact &&
                  getAllRelations(contact, 'PaymentMethod')?.map((paymentMethod: any) => (
                    <Card
                      interactive={true}
                      elevation={Elevation.TWO}
                      selected={dialogState?.stepOne?.paymentMethod?.id === paymentMethod?.id}
                      style={{ marginBottom: 10 }}
                    >
                      <h5 style={{ margin: '0 0 15px 0' }}>{paymentMethod?.title}</h5>
                      <p>{getProperty(paymentMethod, 'BankName')}</p>
                      <p>{getProperty(paymentMethod, 'Status')}</p>
                      <p>{getProperty(paymentMethod, 'ExternalRef')}</p>
                      <Button
                        intent={
                          dialogState?.stepOne?.paymentMethod?.id === paymentMethod?.id
                            ? 'primary'
                            : 'none'
                        }
                        onClick={() => {
                          setDialogState({
                            ...dialogState,
                            stepOne: {
                              paymentMethod: paymentMethod,
                            },
                          });
                          setIsNextDisabled(false);
                        }}
                        disabled={['CANCELLED', 'ERROR', 'FAILED'].includes(
                          getProperty(paymentMethod, 'Status'),
                        )}
                      >
                        Select
                      </Button>
                    </Card>
                  ))}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="step-two"
          title="Schedule refund"
          panel={
            <Section>
              <SectionCard>
                {!mandateRefundCreateAction ? (
                  <Callout intent="danger">
                    <p>Could not locate schema action: CreatePaymentMethodRefund</p>
                  </Callout>
                ) : (
                  <CoreForm
                    type="EMBEDDED"
                    formUUID={refundFormUUID}
                    isCreateRecord={true}
                    showFormActions={false}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                  />
                )}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="confirm"
          panel={
            <Section>
              {!paymentMethodRefund && (
                <Callout intent="primary">
                  {isConfirmLoading ? (
                    <Spinner size={50} />
                  ) : (
                    <div>
                      <p>
                        Please confirm that you want to schedule a refund request, it will not
                        refund the customer until the refund is approved by finance.
                      </p>
                    </div>
                  )}
                </Callout>
              )}

              {stepError && (
                <SectionCard>
                  <Callout intent="danger">
                    <p>{stepError}</p>
                  </Callout>
                </SectionCard>
              )}

              {paymentMethodRefund && (
                <>
                  <SectionCard>
                    <Descriptions>
                      <Descriptions.Item label="Reference #">
                        {paymentMethodRefund?.recordNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="Amount">
                        {getProperty(paymentMethodRefund, 'Amount')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Description">
                        {getProperty(paymentMethodRefund, 'Description')}
                      </Descriptions.Item>
                    </Descriptions>
                  </SectionCard>

                  <SectionCard>
                    <p>
                      To complete the refund process and have it submitted to GoCardless please
                      click here and complete the approval process.{' '}
                    </p>
                    <Link target="_blank" to={getBrowserPath(paymentMethodRefund)}>
                      <div>Go to refund</div>
                    </Link>
                  </SectionCard>
                </>
              )}
            </Section>
          }
          title="Confirm"
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
});

export default connect(mapState, mapDispatch)(PaymentMethodRefund);
