import { FunctionComponent, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { IUserInterfaceReducer } from '../../../../userInterface/store/types';
import { toggleRawDataDrawer } from '../../../../userInterface/store/actions';
import RawDataContainer from '../RawDataContainer';
import { Drawer } from '@blueprintjs/core';

interface OwnProps {
  userInterfaceReducer: IUserInterfaceReducer;
  toggleDrawer: Function;
}

type Props = OwnProps;

const RawDataViewerDrawer: FunctionComponent<Props> = (props) => {
  const [maximizedView, setMaximizedView] = useState<boolean>(false);

  const { userInterfaceReducer, toggleDrawer } = props;

  return (
    <Drawer
      title="Raw Data"
      style={{ width: isMobile ? '100%' : maximizedView ? '100%' : '40%' }}
      onClose={() => {
        toggleDrawer();
      }}
      isOpen={userInterfaceReducer.rawDataDrawerVisible}
    >
      <div style={{ padding: 18 }}>
        <RawDataContainer
          type="DRAWER"
          data={userInterfaceReducer.rawDataDrawerContents}
          maximizedView={maximizedView}
          setMaximizedView={setMaximizedView}
        />
      </div>
    </Drawer>
  );
};

const mapDispatch = (dispatch: any) => ({
  toggleDrawer: () => dispatch(toggleRawDataDrawer()),
});

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
});

export default connect(mapState, mapDispatch)(RawDataViewerDrawer);
