import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

const FPE_SELECTION_SELECT_ALL_SET = 'FPE_SELECTION_SELECT_ALL_SET';
const FPE_SELECTION_SELECTED_RECORDS_SET = 'FPE_SELECTION_SELECTED_RECORDS_SET';

type SelectAllSet = {
  type: typeof FPE_SELECTION_SELECT_ALL_SET;
  payload: boolean;
};

type SelectedRecordsSet = {
  type: typeof FPE_SELECTION_SELECTED_RECORDS_SET;
  payload: DbRecordEntityTransform[];
};

export type FPESelectionActions = SelectAllSet | SelectedRecordsSet;

export const setSelectAll = (value: boolean): SelectAllSet => ({
  type: FPE_SELECTION_SELECT_ALL_SET,
  payload: value,
});

export const setSelectedRecords = (records: DbRecordEntityTransform[]): SelectedRecordsSet => ({
  type: FPE_SELECTION_SELECTED_RECORDS_SET,
  payload: records,
});
