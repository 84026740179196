import React, { useEffect, useState } from 'react';
import { Card, Col, DatePicker, Layout, Row, Table } from 'antd';

import { PageHeader } from '../../../../components/PageHeader';
import { httpPost } from '@core/http/requests';
import { capitalCase } from 'change-case';
import dayjs, { Dayjs } from 'dayjs';
import { CompareFn } from 'antd/lib/table/interface';

const { RangePicker } = DatePicker;

type ScoreEntry = {
  id: number;
  firstname: string;
  lastname: string;
  job_title: string;
  sales_region: string;
  sales_territory: string;
  visit_count: number;
  lead_count: number;
  order_count: number;
  orders_per_day: number;
};

const MAX_DAYS_ALLOWED = 30;

export const SalesLeaderBoard: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [scores, setScores] = useState<ScoreEntry[]>([]);
  const [startDate, setStartDate] = useState<string | undefined>();
  const [endDate, setEndDate] = useState<string | undefined>();

  const getSalesLeaderBoardColumns = () => {
    const columnPositions: Record<string, number> = {
      id: 10,
      firstname: 20,
      lastname: 30,
      job_title: 40,
      sales_region: 50,
      sales_territory: 60,
      visit_count: 70,
      lead_count: 90,
      order_count: 90,
      orders_per_day: 100,
    };

    const columnSorters: Record<string, CompareFn<ScoreEntry>> = {
      visit_count: (a: ScoreEntry, b: ScoreEntry) => a.visit_count - b.visit_count,
      lead_count: (a: ScoreEntry, b: ScoreEntry) => a.lead_count - b.lead_count,
      order_count: (a: ScoreEntry, b: ScoreEntry) => a.order_count - b.order_count,
      orders_per_day: (a: ScoreEntry, b: ScoreEntry) => a.orders_per_day - b.orders_per_day,
    };

    const fields = Object.keys(columnPositions);

    return fields.map((key) => ({
      title: key === 'id' ? '#' : capitalCase(key),
      dataIndex: key,
      ellipsis: false,
      position: columnPositions[key],
      sorter: columnSorters[key],
    }));
  };

  // loading scores
  useEffect(() => {
    let mounted = true;

    const loadScores = async () => {
      if (!document.hidden) {
        setIsLoading(true);
        return await httpPost('CrmModule/v1.0/dashboards/sale-agent-leader-board', {
          startDate,
          endDate,
        })
          .then(async (res) => {
            if (!mounted) return;
            setIsLoading(false);
            setScores(
              res.data.data.map((score: Record<string, any>, index: number) => ({
                id: index + 1,
                ...score,
              })),
            );
          })
          .catch((err) => {
            if (!mounted) return;
            setIsLoading(false);
          });
      }
    };

    // updates data every minute
    const interval = setInterval(loadScores, 60000);

    // load initial data
    loadScores();

    return () => {
      mounted = false;
      clearInterval(interval);
    };
  }, [startDate, endDate]);

  const handleDateChanged = (dates: any) => {
    if (!dates) return;

    const [startDate, endDate] = dates.map((date: any) => date?.format('YYYY-MM-DD'));
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const shouldDisableDate = (current: Dayjs) => {
    const tooEarly = startDate && current.diff(startDate, 'days') > MAX_DAYS_ALLOWED;
    const tooLate = endDate && dayjs(endDate).diff(current, 'days') > MAX_DAYS_ALLOWED;

    return !!tooEarly || !!tooLate;
  };

  return (
    <Layout style={{ padding: 10 }}>
      <PageHeader
        title="Sales Leader Board"
        extra={
          <RangePicker
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={handleDateChanged}
            disabledDate={shouldDisableDate}
            changeOnBlur
          />
        }
      />
      <Row>
        <Col span={24} xs={24} md={24} xl={24}>
          <Card size="small" title="" loading={isLoading}>
            <Table size="small" columns={getSalesLeaderBoardColumns()} dataSource={scores} />
          </Card>
        </Col>
      </Row>
    </Layout>
  );
};
