import {useEffect, useRef} from 'react';

/**
 * Access previous props or state with Functional React components
 * Usage: const prevSomething = usePrevious(something)
 *
 * @param value
 * @returns {undefined}
 */
export function usePrevious(value: any) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

export const splitCamelCase = (str: string) => {
  return str?.replace(/([a-z0-9])([A-Z])/g, '$1 $2');
};
