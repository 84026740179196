import { Button, Dialog, DialogBody, DialogFooter } from '@blueprintjs/core';
import { OrganizationUserRbacRoleEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/rbac/role/organization.user.rbac.role.entity';
import { FC, useState } from 'react';
import { connect } from 'react-redux';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { DualPanelAssignment } from '@core/components/DualPanelAssignment';
import { getErrorMessage } from '../../../../utils/errors';
import './styles.scss';

interface Props {
  role: OrganizationUserRbacRoleEntity;
  permissions: any[];
  onUpdate: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

type TPermission = {
  id: string;
  name: string;
  description: string;
};

const ManageRolePermissionsDialog: FC<Props> = (props: Props) => {
  const { role, alertMessage, onUpdate } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const [allPermissions, setAllPermissions] = useState<TPermission[]>([]);
  const [rolePermissions, setRolePermissions] = useState<TPermission[]>([]);
  const [isLoadingPermissions, setIsLoadingPermissions] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const closeModal = () => {
    setIsDialogOpen(false);
    setAllPermissions([]);
    setRolePermissions([]);
  };

  const openModal = () => {
    loadAllPermissions();
    getExistingPermissionsOnTheRole();
  };

  const getExistingPermissionsOnTheRole = () => {
    if (props.permissions) {
      const permissions: TPermission[] =
        props.permissions?.map((role: any) => ({
          id: role.id,
          name: role.name,
          description: role.description,
        })) || [];
      setRolePermissions(permissions);
    }
  };

  const loadAllPermissions = async () => {
    setIsLoadingPermissions(true);
    try {
      const res = await httpGet('IdentityModule/v2.0/permissions?size=10000');
      const permissions: TPermission[] =
        res.data.data?.map((permission: any) => ({
          id: permission.id,
          name: permission.name,
          description: permission.description,
        })) || [];

      // Remove permissions that the Role already has
      const existingRolePermissionsIds = props.permissions?.map((p: any) => p.id) || [];
      const filteredPermissions = permissions.filter(
        (p) => !existingRolePermissionsIds.includes(p.id),
      );

      setAllPermissions(filteredPermissions);
      setIsLoadingPermissions(false);
      setIsDialogOpen(true);
    } catch (e: any) {
      setIsLoadingPermissions(false);
    }
  };

  const addPermissions = async () => {
    const permissionIds =
      rolePermissions
        .filter((p) => !props.permissions?.map((rp) => rp.id).includes(p.id))
        ?.map((p: any) => p.id) || [];

    if (!permissionIds.length || !role) return;
    // console.log('%cdebug: Adding Permissions', 'color:limegreen', permissionIds);
    try {
      await httpPost(`IdentityModule/v2.0/roles/${role.id}/permissions`, {
        permissionIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not add permissions to a role. ' + message,
        type: 'error',
    });
    }
  };

  const removePermissions = async () => {
    const permissionIds =
      props.permissions
        ?.filter((p) => !rolePermissions.map((rp) => rp.id).includes(p.id))
        ?.map((p: any) => p.id) || [];

    if (!permissionIds.length || !role) return;
    // console.log('%cdebug: Removing Permissions', 'color:salmon', permissionIds);
    try {
      await httpDelete(`IdentityModule/v2.0/roles/${role.id}/permissions`, {
        permissionIds,
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not remove permissions from a role. ' + message,
        type: 'error',
      });
    }
  };

  const updatePermissionsOnRole = async () => {
    setIsSaving(true);

    const addPermissionsPromise = addPermissions();
    const removePermissionsPromise = removePermissions();

    try {
      await Promise.all([addPermissionsPromise, removePermissionsPromise]);
      alertMessage({ body: 'Permissions updated successfully', type: 'success' });
      setIsSaving(false);
      closeModal();
      onUpdate();
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update permissions on a role. ' + message,
        type: 'error',
      });
      setIsSaving(false);
    }
  };

  // Prevent saving if there are no changes
  const isSaveButtonDisabled = () => {
    const existingRolePermissionsIds =
      props.permissions?.map((p: any) => p.id)?.sort((a: any, b: any) => a.localeCompare(b)) || [];
    const rolePermissionsIds =
      rolePermissions.map((p) => p.id)?.sort((a: any, b: any) => a.localeCompare(b)) || [];
    return JSON.stringify(existingRolePermissionsIds) === JSON.stringify(rolePermissionsIds);
  };

  // Add Permission to a Role, remove from the All Permissions list
  const onPermissionPanelChange = (permissionId: string) => {
    const permission = allPermissions.find((p) => p.id === permissionId);
    if (permission) {
      setRolePermissions([...rolePermissions, permission]);
      setAllPermissions(allPermissions.filter((p) => p.id !== permissionId));
    }
  };

  // Remove permission from role, add to the left side list
  const onRolePanelChange = (permissionId: string) => {
    const permission: TPermission | undefined = rolePermissions?.find((p) => p.id === permissionId);
    if (permission) {
      setRolePermissions(rolePermissions.filter((p) => p.id !== permissionId));
      setAllPermissions([...allPermissions, permission]);
    }
  };

  const clearRolePermissions = () => {
    setAllPermissions([...allPermissions, ...rolePermissions]);
    setRolePermissions([]);
  };

  return (
    <>
      <Button
        small
        minimal
        intent="primary"
        text="Manage"
        loading={isLoadingPermissions}
        onClick={openModal}
      />
      <Dialog
        title="Manage Permissions on a Role"
        isOpen={isDialogOpen}
        onClose={closeModal}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        style={{ width: '80%' }}
      >
        <DialogBody className="assignRolesDialog">
          <DualPanelAssignment
            // Left panel
            leftPanelTitle="All Permissions"
            leftPanelIcon="key"
            leftPanelSubtitle="All available Permissions"
            leftPanelData={allPermissions}
            onLeftPanelChange={onPermissionPanelChange}
            // Right Panel
            rightPanelTitle={`${role.name}`}
            rightPanelIcon="id-number"
            rightPanelSubtitle="Permissions assigned to this Role"
            rightPanelData={rolePermissions}
            onRightPanelChange={onRolePanelChange}
            onRightPanelClear={clearRolePermissions}
          />
        </DialogBody>
        <DialogFooter
          actions={[
            <Button key="Close" text="Close" onClick={closeModal} />,
            <Button
              key="SaveChanges"
              text="Save Changes"
              disabled={isSaveButtonDisabled()}
              intent="primary"
              onClick={updatePermissionsOnRole}
              loading={isSaving}
            />,
          ]}
        />
      </Dialog>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(ManageRolePermissionsDialog);
