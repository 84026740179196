import React from 'react';

const PersonPlus = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    >
      <path d="M2 21a8 8 0 0 1 13.292-6"></path>
      <circle cx="10" cy="8" r="5"></circle>
      <path d="M19 16v6"></path>
      <path d="M22 19h-6"></path>
    </svg>
  );
};

export default PersonPlus;
