import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Descriptions, Row } from 'antd';
import { FC, useContext } from 'react';
import { orderBuilderContext } from '../index';

interface Props {}

const OrderSummary: FC<Props> = (props: Props) => {
  const { state } = useContext(orderBuilderContext);

  const renderOrderItemsSummary = () => {
    return state.orderSummary?.OrderItems.map((orderItem: DbRecordEntityTransform) => (
      <Descriptions.Item
        key={orderItem.id}
        label={orderItem.title}
        labelStyle={{ fontWeight: 'normal', width: '70%' }}
      >
        {getProperty(orderItem, 'TotalPrice')}
      </Descriptions.Item>
    ));
  };

  return (
    <Row>
      <Col span={24}>
        {state.orderItems.length > 0 ? (
          <>
            <Descriptions bordered size="small" column={1}>
              <Descriptions.Item
                key="price"
                span={2}
                contentStyle={{ padding: 'left', background: '#ececec', borderRight: 'none' }}
                label="Product"
                labelStyle={{ background: '#ececec', fontWeight: 500 }}
              >
                Price
              </Descriptions.Item>
              {renderOrderItemsSummary()}
            </Descriptions>
          </>
        ) : (
          <></>
        )}

        <Descriptions
          bordered
          size="small"
          column={1}
          style={{ marginTop: 10 }}
          labelStyle={{ fontWeight: 500, color: 'black' }}
        >
          <Descriptions.Item label="Subtotal" labelStyle={{ fontWeight: 'normal', width: '70%' }}>
            {state.orderSummary?.Subtotal || '0.00'}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Discounts"
            labelStyle={{ fontWeight: 'normal', width: '70%' }}
          >
            {state.orderSummary?.TotalDiscounts || '0.00'}
          </Descriptions.Item>
          <Descriptions.Item
            label="Total Tax Amount"
            labelStyle={{ fontWeight: 'normal', width: '70%' }}
          >
            {state.orderSummary?.TotalTaxAmount || '0.00'}
          </Descriptions.Item>
        </Descriptions>

        <Descriptions
          bordered
          size="small"
          column={1}
          labelStyle={{ fontWeight: 500, color: 'black' }}
        >
          <Descriptions.Item
            label="Total Price"
            labelStyle={{ width: '70%' }}
            contentStyle={{ fontWeight: 600 }}
          >
            {state.orderSummary?.TotalPrice || '0.00'}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default OrderSummary;
