import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRendererProps, Select } from '@blueprintjs/select';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { JSX } from 'react';

type SchemaTypesSelectProps = {
  schemaTypes: SchemaTypeEntity[];
  selected: string | null;
  onChange: (selected: string | null) => void;
  fill?: boolean;
  hideAll?: boolean;
};

export const SchemaTypesSelect: React.FC<SchemaTypesSelectProps> = ({
  schemaTypes,
  selected,
  onChange,
  fill = false,
  hideAll = false,
}) => {
  const allOption = { id: 'all', name: 'ALL' } as SchemaTypeEntity;
  const allSchemaTypes = hideAll ? [...schemaTypes] : [allOption, ...schemaTypes];
  const selectedItem = allSchemaTypes.find((schemaType) => schemaType.id === selected);
  return (
    <Select<SchemaTypeEntity>
      items={allSchemaTypes}
      activeItem={selectedItem}
      itemRenderer={(
        item: SchemaTypeEntity,
        { handleClick, handleFocus, modifiers }: ItemRendererProps,
      ): JSX.Element | null => (
        <MenuItem
          key={item.id}
          text={item.name}
          roleStructure="listoption"
          active={modifiers.active}
          onClick={handleClick}
          onFocus={handleFocus}
        />
      )}
      onItemSelect={function (
        item: SchemaTypeEntity,
        event?: React.SyntheticEvent<HTMLElement>,
      ): void {
        onChange(item.id);
      }}
    >
      <Button
        fill={fill}
        text={selectedItem ? selectedItem.name : 'Select Schema Type'}
        rightIcon="caret-down"
        alignText="left"
        style={{
          minWidth: '250px',
          borderRadius: 5,
        }}
      />
    </Select>
  );
};
