import { useEffect, useState } from 'react';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getRecordAssociationWithNestedEntitiesRequest } from '../../recordsAssociations/store/actions';
import { useRequest } from '@core/hooks/useRequest';
import { getSchemaByIdRequest } from '../../schemas/store/actions';

export const useRecordAssociations = (
  record: DbRecordEntityTransform | undefined,
  [entity, ...nestedEntities]: string[],
  callback: any,
) => {
  const getSchema = useRequest(getSchemaByIdRequest);
  const getRecordAssociations = useRequest(getRecordAssociationWithNestedEntitiesRequest);
  const [shouldRefetch, setShouldRefetch] = useState(1);

  useEffect(() => {
    const fetchRecordAssociations = async () => {
      if (!record || !record.schemaId) return;

      const schema = await getSchema({ schemaId: record.schemaId });
      const associations = await getRecordAssociations({
        schema,
        recordId: record.id,
        entity,
        withLinks: nestedEntities.length > 0,
        nestedEntities: [],
      });

      callback(associations);
    };

    fetchRecordAssociations();
  }, [record?.id, shouldRefetch]);

  return { refetch: () => setShouldRefetch((curr) => curr + 1) };
};
