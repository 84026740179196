export const LINEAR_FIFO_PROJECT_ID = 'c97f2f5f-bc36-43fa-8ae8-ffde0318132a';
export const LINEAR_D19_TEAM_ID = '404456a6-88a9-41a8-8086-d53582815cfd';
export type TLinearIssueBody = {
  title: string;
  description: string;
  files: string; // comma separated file ids in string
  name: string;
  priority: string;
  label: string;
};
