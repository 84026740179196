import React from 'react';
import { connect } from 'react-redux';
import Typography from '../../Typography';
import { Button, HTMLTable, Section, Tooltip } from '@blueprintjs/core';
import { Avatar, Col, Row } from 'antd';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import dayjs from 'dayjs';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { MetadataLinks } from '@d19n/temp-fe-d19n-models/dist/schema-manager/metadata.links';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SUPPORT_TASK } from '../types';
import { IOpenRecordDrawer, openRecordDrawer } from '@legacy/core/userInterface/store/actions';
import './styles.scss';
import { getInitialsFromName, getPastelColorForUsername } from '../../../helpers/UIHelpers';

interface Props {
  schemaReducer: any;
  taskRecord: DbRecordEntityTransform;
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;

const NoteTask: React.FC<Props> = (props: Props) => {
  const { schemaReducer, taskRecord, openDrawer } = props;

  const getOwnerName = () => {
    let ownerProperty = getProperty(taskRecord, 'Owner');
    if (taskRecord && taskRecord?.links?.length! > 0) {
      const ownerLink = taskRecord?.links?.find((link: MetadataLinks) => link.id === ownerProperty);
      if (ownerLink) {
        return ownerLink.title;
      }
    }
    return ownerProperty;
  };

  return (
    <Row key={taskRecord.id}>
      <Col span={24} style={{ paddingTop: 10, paddingBottom: 15 }}>
        <Typography size="default" style={{ fontWeight: 500, marginRight: 5 }}>
          <Avatar
            size="small"
            style={{
              width: 20,
              height: 20,
              fontSize: 16,
              marginRight: 7,
              lineHeight: '19px',
              backgroundColor: getPastelColorForUsername(
                taskRecord?.lastModifiedBy?.fullName || 'Unknown',
              ),
            }}
          >
            {getInitialsFromName(taskRecord?.lastModifiedBy?.fullName || '')}
          </Avatar>
          <span>{taskRecord.createdBy?.fullName || ''}</span>
          <span> created a Task</span>
        </Typography>
        <Typography size="small" style={{ opacity: 0.5 }}>
          <Tooltip content={<></>} hoverOpenDelay={1200} position="top">
            <span style={{ cursor: 'pointer' }}>{dayjs(taskRecord.createdAt).fromNow()}</span>
          </Tooltip>
        </Typography>
      </Col>

      {/* Task Body */}
      <Col span={24} style={{ paddingBottom: 15, paddingTop: 5 }}>
        <Section className="noteTaskSection">
          <HTMLTable style={{ fontSize: 12 }} className="bp5-compact noteTaskTable">
            <thead>
            <tr>
              <th style={{ width: '20%' }}>Owner</th>
              <th style={{ width: '20%' }}>Completion Date</th>
              <th style={{ width: '45%' }}>Description</th>
              <th style={{ width: '5%' }}></th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>
                <span>{getOwnerName()}</span>
              </td>
              <td>
                  <span>
                    {dayjs(getProperty(taskRecord, 'CompletionDate')).format('DD/MM/YYYY')}
                  </span>
              </td>
              <td>
                <span>{getProperty(taskRecord, 'Description')}</span>
              </td>
              <td style={{ paddingRight: 5 }}>
                <Button
                  icon="eye-open"
                  small
                  onClick={() =>
                    openDrawer({
                      recordId: taskRecord?.id,
                      moduleName: SUPPORT_MODULE,
                      entityName: SUPPORT_TASK,
                    })
                  }
                />
              </td>
            </tr>
            </tbody>
          </HTMLTable>
        </Section>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(NoteTask);
