export const SET_SCHEDULE_ID = 'SET_SCHEDULE_ID';

export const LOAD_AVAILABLE_APPOINTMENTS_REQUEST = 'LOAD_AVAILABLE_APPOINTMENTS_REQUEST';
export const LOAD_AVAILABLE_APPOINTMENTS_SUCCESS = 'LOAD_AVAILABLE_APPOINTMENTS_SUCCESS';
export const LOAD_AVAILABLE_APPOINTMENTS_ERROR = 'LOAD_AVAILABLE_APPOINTMENTS_ERROR';

export const CREATE_APPOINTMENT_REQUEST = 'CREATE_APPOINTMENT_REQUEST';
export const CREATE_APPOINTMENT_SUCCESS = 'CREATE_APPOINTMENT_SUCCESS';
export const CREATE_APPOINTMENT_ERROR = 'CREATE_APPOINTMENT_ERROR';

export const CANCEL_APPOINTMENT_RECORD_REQUEST = 'CANCEL_APPOINTMENT_RECORD_REQUEST';
export const CANCEL_APPOINTMENT_RECORD_SUCCESS = 'CANCEL_APPOINTMENT_RECORD_SUCCESS';
export const CANCEL_APPOINTMENT_RECORD_ERROR = 'CANCEL_APPOINTMENT_RECORD_ERROR';

export const INITIALIZE_CANCEL_APPOINTMENT_MODAL = 'INITIALIZE_CANCEL_APPOINTMENT_MODAL';

export const CLOSE_CANCEL_APPOINTMENT_MODAL = 'CLOSE_CANCEL_APPOINTMENT_MODAL';

export const UPDATE_APPOINTMENT_REDUCER = 'UPDATE_APPOINTMENT_REDUCER';

export const CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST =
  'CREATE_WORK_ORDER_AND_APPOINTMENT_REQUEST';
export const CREATE_WORK_ORDER_AND_APPOINTMENT_SUCCESS =
  'CREATE_WORK_ORDER_AND_APPOINTMENT_SUCCESS';
export const CREATE_WORK_ORDER_AND_APPOINTMENT_ERROR = 'CREATE_WORK_ORDER_AND_APPOINTMENT_ERROR';
