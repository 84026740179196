import { CalendarState } from '../../types';
import { CalendarAction } from '../types';
import {
  APPOINTMENTS_DATE_FILTER_NEXT,
  APPOINTMENTS_DATE_FILTER_PREV,
  APPOINTMENTS_DATE_FILTER_SET,
  APPOINTMENTS_SELECTED_DATE_SET,
  FETCH_EARLIER_AVAILABILITY_ERROR,
  FETCH_EARLIER_AVAILABILITY_REQUEST,
  FETCH_EARLIER_AVAILABILITY_RESPONSE,
  FETCH_ENGINEERS_ERROR,
  FETCH_ENGINEERS_REQUEST,
  FETCH_ENGINEERS_RESPONSE,
  FETCH_SCHEDULES_ERROR,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_RESPONSE,
  FETCH_UTILIZATION_ERROR,
  FETCH_UTILIZATION_REQUEST,
  FETCH_UTILIZATION_RESPONSE,
  FETCH_WORK_ORDERS_ERROR,
  FETCH_WORK_ORDERS_REQUEST,
  FETCH_WORK_ORDERS_RESPONSE,
  SELECT_SCHEDULE,
  SET_APPOINTMENTS_FILTER,
  SET_DETAILS_RECORD,
  SET_SCHEDULE_FILTER,
  UPDATE_WORK_ORDER,
} from '../constants';
import { schedulesReducer } from './schedulesReducer';
import { workOrdersReducer } from './workOrdersReducer';
import { appointmentsReducer } from './appointmentsReducer';
import { utilizationReducer } from './utilizationReducer';
import { earlierAvailabilityReducer } from './earlierAvailabilityReducer';
import { engineersReducer } from './engineersReducer';

export const CalendarReducer = (state: CalendarState, action: CalendarAction): CalendarState => {
  switch (action.type) {
    case FETCH_SCHEDULES_REQUEST:
    case FETCH_SCHEDULES_RESPONSE:
    case FETCH_SCHEDULES_ERROR:
    case SET_SCHEDULE_FILTER:
    case SELECT_SCHEDULE:
      return {
        ...state,
        schedules: schedulesReducer(state.schedules, action),
      };

    case FETCH_WORK_ORDERS_REQUEST:
    case FETCH_WORK_ORDERS_RESPONSE:
    case FETCH_WORK_ORDERS_ERROR:
    case UPDATE_WORK_ORDER:
      return {
        ...state,
        workOrders: workOrdersReducer(state.workOrders, action),
      };

    case FETCH_EARLIER_AVAILABILITY_REQUEST:
    case FETCH_EARLIER_AVAILABILITY_RESPONSE:
    case FETCH_EARLIER_AVAILABILITY_ERROR:
      return {
        ...state,
        earlierAvailability: earlierAvailabilityReducer(state.earlierAvailability, action),
      };

    case SET_APPOINTMENTS_FILTER:
    case APPOINTMENTS_DATE_FILTER_PREV:
    case APPOINTMENTS_DATE_FILTER_NEXT:
    case APPOINTMENTS_DATE_FILTER_SET:
    case APPOINTMENTS_SELECTED_DATE_SET:
      return {
        ...state,
        appointments: appointmentsReducer(state.appointments, action),
      };

    case SET_DETAILS_RECORD:
      return {
        ...state,
        details: {
          record: action.payload,
        },
      };

    case FETCH_UTILIZATION_REQUEST:
    case FETCH_UTILIZATION_RESPONSE:
    case FETCH_UTILIZATION_ERROR:
      return {
        ...state,
        utilization: utilizationReducer(state.utilization, action),
      };

    case FETCH_ENGINEERS_REQUEST:
    case FETCH_ENGINEERS_RESPONSE:
    case FETCH_ENGINEERS_ERROR:
      return {
        ...state,
        engineers: engineersReducer(state.engineers, action),
      };

    default:
      return state;
  }
};
