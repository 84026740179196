import { CalendarState } from '../../types';
import {
  FETCH_UTILIZATION_ERROR,
  FETCH_UTILIZATION_REQUEST,
  FETCH_UTILIZATION_RESPONSE,
} from '../constants';
import {
  CalendarAction,
  FetchUtilizationErrorAction,
  FetchUtilizationRequestAction,
  FetchUtilizationResponseAction,
} from '../types';
import { getErrorState, getLoadingState, getSuccessState } from './utils/fetch';

const isRequestAction = (action: CalendarAction): action is FetchUtilizationRequestAction =>
  action.type === FETCH_UTILIZATION_REQUEST;
const isResponseAction = (action: CalendarAction): action is FetchUtilizationResponseAction =>
  action.type === FETCH_UTILIZATION_RESPONSE;
const isErrorAction = (action: CalendarAction): action is FetchUtilizationErrorAction =>
  action.type === FETCH_UTILIZATION_ERROR;

const reducers = {
  [FETCH_UTILIZATION_REQUEST]: (
    state: CalendarState['utilization'],
    _action: FetchUtilizationRequestAction,
  ): CalendarState['utilization'] => ({
    ...state,
    ...getLoadingState(),
  }),

  [FETCH_UTILIZATION_RESPONSE]: (
    state: CalendarState['utilization'],
    action: FetchUtilizationResponseAction,
  ): CalendarState['utilization'] => ({
    ...state,
    ...getSuccessState(action.payload),
  }),

  [FETCH_UTILIZATION_ERROR]: (
    state: CalendarState['utilization'],
    action: FetchUtilizationErrorAction,
  ): CalendarState['utilization'] => ({
    ...state,
    ...getErrorState(action.payload),
  }),
} as const;

export const utilizationReducer = (
  state: CalendarState['utilization'],
  action: CalendarAction,
): CalendarState['utilization'] => {
  if (isRequestAction(action)) return reducers[action.type](state, action);

  if (isResponseAction(action)) return reducers[action.type](state, action);

  if (isErrorAction(action)) return reducers[action.type](state, action);

  return state;
};
