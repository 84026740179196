import React from 'react';
import { Button, Input, Space } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import {
  getElasticSearchKeysFromSchemaColumn,
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '@core/helpers/schemaHelpers';
import { ISchemaReducer } from '../../../../schemas/store/reducer';
import { getSchemaByIdRequest, getSchemaByModuleAndEntityRequest } from '../../../../schemas/store/actions';
import { formatDbRecordListColumns } from '../../DynamicTable/helpers/configureColumns';
import { formatDbRecordListData } from '../../DynamicTable/helpers/configureRows';

import { TableRowAction } from '../types';
import { recordHasPipeline } from '../helpers';
import { getAllRelations } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Link } from 'react-router-dom';
import { getModuleAndEntityNameFromRecord } from '@core/helpers/recordHelpers';
import { useEditableProperties } from './useEditableProperties';
import {
  addRecordToShortList,
  getRecordByIdRequest,
  IAddRecordToShortList,
  IGetRecordById,
} from '../../../store/actions';
import {
  SchemaColumnTypes,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { hasPermissions } from '@core/helpers/rbacRules';
import { IOpenRecordDrawer, openRecordDrawer } from '../../../../userInterface/store/actions';

export const useSchemaByModuleAndEntity = (module: string, entity: string) => {
  const dispatch = useDispatch();
  const schemaShortList: ISchemaReducer['shortList'] = useSelector(
    (state: any) => state.schemaReducer.shortList,
  );
  const [schema, setSchema] = React.useState<SchemaEntity | undefined>();

  React.useEffect(() => {
    const schema = getSchemaFromShortListByModuleAndEntity(schemaShortList, module, entity);
    if (schema) {
      setSchema(schema);
      return;
    }

    if (!module || !entity) return;

    dispatch(
      getSchemaByModuleAndEntityRequest(
        {
          moduleName: module,
          entityName: entity,
        },
        (schema) => {
          setSchema(schema);
        },
      ),
    );
  });

  return schema;
};

export const useSchema = (schemaId: string) => {
  const dispatch = useDispatch();
  const schemaShortList: ISchemaReducer['shortList'] = useSelector(
    (state: any) => state.schemaReducer.shortList,
  );
  const [schema, setSchema] = React.useState<SchemaEntity | undefined>();

  React.useEffect(() => {
    const schema = getSchemaFromShortListBySchemaId(schemaShortList, schemaId);

    if (schema) {
      setSchema(schema);
      return;
    }

    if (!schemaId) return;

    dispatch(
      getSchemaByIdRequest({ schemaId }, (result: any) => {
        setSchema(result);
      }),
    );
  }, [schemaId]);

  return schema;
};

export const useRecordsToTableRows = (records: DbRecordEntityTransform[]) => {
  const firstRecord = records[0];
  const hasPipelines = recordHasPipeline(firstRecord);

  const schema = useSchema(firstRecord?.schemaId ?? '');

  if (!schema) return [];

  const { tableRows } = formatDbRecordListData(schema, records, hasPipelines);
  return tableRows;
};

type RecordsToTableDataParams = {
  records: DbRecordEntityTransform[];
  actions: TableRowAction[];
  customColumnAttributes: { [key: string]: any };
  editableProperties: string[];
};

export const useRecordsToTableData = ({
                                        records,
                                        actions,
                                        customColumnAttributes,
                                        editableProperties,
                                      }: RecordsToTableDataParams) => {
  const dispatch = useDispatch();
  const shortListRecord = (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params));
  const openDrawer = (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params));
  const getRecordById = (payload: IGetRecordById, cb?: any) =>
    dispatch(getRecordByIdRequest(payload, cb));

  const schemaTypeId = undefined;
  const firstRecord = records[0];
  const schemaReducer: ISchemaReducer = useSelector((state: any) => state.schemaReducer);
  const userReducer = useSelector((state: any) => state.userReducer);
  const [schema, setSchema] = React.useState<SchemaEntity | undefined>();
  const editablePropsService = useEditableProperties();

  React.useEffect(() => {
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, firstRecord?.schemaId);

    if (schema) {
      setSchema(schema);
      return;
    }

    if (!firstRecord?.schemaId) return;

    dispatch(
      getSchemaByIdRequest(
        {
          schemaId: firstRecord?.schemaId ?? '',
        },
        (result: any) => {
          setSchema(result);
        },
      ),
    );
  }, [schema, firstRecord?.schemaId]);
  let columns: any[] = [];

  const hasPipelines = [
    'WorkOrder',
    'Order',
    'ReturnOrder',
    'Lead',
    'Account',
    'Program',
    'Project',
    'Task',
    'Job',
  ].includes(firstRecord?.entity!);

  const { tableRows } = formatDbRecordListData(schema, records, hasPipelines);

  if (schema && schema.columns) {
    let disableDataTableEdit = true;

    const filteredColumns = schema.columns.filter(
      (elem) => elem.schemaTypeId === schemaTypeId || !elem.schemaTypeId,
    );

    const schemaFiltered = Object.assign({}, schema, {
      columns: filteredColumns,
    });

    const defaultColumns = getElasticSearchKeysFromSchemaColumn(schema, schemaTypeId);

    columns = formatDbRecordListColumns(
      schemaFiltered,
      defaultColumns,
      records,
      hasPipelines,
      shortListRecord,
      openDrawer,
      getRecordById,
      undefined,
      disableDataTableEdit,
      true,
      userReducer,
      () => undefined,
    ).map((col) => {
      const [type, fieldName] = col.dataIndex.split('.');
      if (
        editableProperties.length &&
        type === 'properties' &&
        editableProperties.includes(fieldName)
      ) {
        col = {
          ...col,
          render: (text: string | undefined, row: any) => {
            return (
              <Input
                value={editablePropsService.getFieldValue(row.rowRecord.id, fieldName) ?? text}
                onChange={(event) => {
                  editablePropsService.setFieldValue(
                    row.rowRecord.id,
                    fieldName,
                    event.target.value,
                  );
                }}
              />
            );
          },
        };
      }
      // apply custom attributes if any
      if (customColumnAttributes[col.dataIndex]) {
        col = {
          ...col,
          ...customColumnAttributes[col.dataIndex],
        };
      }

      if (
        col.columnType === SchemaColumnTypes.CURRENCY &&
        !hasPermissions(userReducer, ['financial.data.access'])
      ) {
        return {
          ...col,
          render: (_text: string, _row: any) => {
            return '*******';
          },
        };
      }

      if (col.columnType !== 'LOOKUP' || !col.dataIndex.endsWith('Id')) return col;

      // Overhide render for LOOKUP columns ending on Id
      return {
        ...col,
        render: (text: string, row: any) => {
          const record = records.find((record) => record.id === row.key);
          const associationName = col.dataIndex.split('.')[1].slice(0, -2);
          const link = [
            ...(getAllRelations(record!, associationName) ?? []),
            ...(record?.links ?? []),
          ].find((rec) => {
            return rec.id === row[col.dataIndex];
          });

          if (!link) return text;

          const { moduleName, entityName } = getModuleAndEntityNameFromRecord(
            link as DbRecordEntityTransform,
          );

          const linkText = `${link?.recordNumber ? `${link.recordNumber} - ` : ''}${
            link?.title || ''
          }`;
          return (
            <Link to={`/${moduleName}/${entityName}/${link?.id}`} target="_blank">
              {linkText}
            </Link>
          );
        },
      };
    });

    // columns.sort((a, b) => a.position - b.position);
  }

  if (actions.length) {
    columns.push({
      title: 'Action',
      dataIndex: '',
      key: 'x',
      fixed: 'right',
      render: (text: string, record: any) => {
        return actions.map((action) => (
          <Space size="middle">
            <Button
              type="text"
              style={{
                color: '#1890FF',
              }}
              onClick={() => {
                action.onClick(record);
              }}
            >
              {action.title}
            </Button>
          </Space>
        ));
      },
    });
  }

  return {
    dataSource: tableRows,
    columns,
    editablePropertiesService: editablePropsService,
  };
};
