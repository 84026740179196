import { TableOutlined } from '@ant-design/icons';
import {
  QGISBuildStatusEnum,
  QGISCableModelEnum,
  QGISClosureModelEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import {
  RelationTypeEnum,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Col, Modal, notification, Row, Table } from 'antd';
import Search from 'antd/lib/input/Search';
import { ColumnsType } from 'antd/lib/table';
import { FC, Key, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Flow from '../../../../../../core/components/Flow/Flow';
import { MapReducerUpdate, resetWorkItemForm, updateMapState } from '@netomnia/modules/ProjectModule/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getBrowserPath } from '../../../../../../core/helpers/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../core/helpers/schemaHelpers';
import { chamberModels, ductTypes } from '../../helpers';

interface Props {
  recordReducer: any;
  schemaReducer: any;
  updateMap: Function;
  mapReducer: MapReducer;
  alertMessage: Function;
  resetWIForm: Function;
}

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, OPENREACH_NERWORK_ADJUSTMENT } = SchemaModuleEntityTypeEnums;

interface DataType {
  key: string;
  recordNumber: DbRecordEntityTransform;
  type: string;
  buildStatus: string;
  model: string;
  qgisId: string;
}

const RFCCreate: FC<Props> = (props: Props) => {
  const { schemaReducer, recordReducer, updateMap, mapReducer, resetWIForm } = props;
  const [searchedRecords, setSearchedRecords] = useState<DbRecordEntityTransform[]>([]);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [createdNetworkAdjustmentId, setCreatedNetworkAdjustmentId] = useState<string | undefined>(
    undefined,
  );
  const [selectedRecords, setSelectedRecords] = useState<DbRecordEntityTransform[]>([]);
  const [showNANotification, setShowNANotification] = useState<boolean>(false);
  const [flowVisible, setFlowVisible] = useState<boolean>(false);

  const [api, contextHolder] = notification.useNotification();

  const showNotification = (recordId: string) => {
    api['success']({
      key: recordId,
      message: 'Network Adjustment Created',
      duration: 5,
      onClose: () => {
        setCreatedNetworkAdjustmentId(undefined);
        setShowNANotification(false);
      },
      description: (
        <Link target="_blank" to={`/${PROJECT_MODULE}/${OPENREACH_NERWORK_ADJUSTMENT}/${recordId}`}>
          Go to Record
        </Link>
      ),
    });
  };

  // On component mount reset selected ids
  useEffect(() => {
    if (selectedIds?.length > 0) {
      setSelectedIds([]);
    }
  }, []);

  // When Flow Step 1 finishes, set the created ORNA id to the state.
  const fetchCreatedNetworkAdjustmentId = (recordId: string) => {
    if (recordId) {
      setCreatedNetworkAdjustmentId(recordId);
    }
  };

  // When Flow Step 2 finished and there is ORNA id, show the notification
  useEffect(() => {
    if (createdNetworkAdjustmentId && showNANotification) {
      showNotification(createdNetworkAdjustmentId);
    }
  }, [showNANotification]);

  // Close the modal and clear out selected ids
  const closeRFCModal = () => {
    updateMap({ isRFCModalVisible: false });
    setSelectedIds([]);
  };

  // Open modal and clear out notification
  const openRFCModal = () => {
    setShowNANotification(false);
    setCreatedNetworkAdjustmentId(undefined);
    updateMap({ isRFCModalVisible: true });
  };

  // Callback for Flow component - Step 2
  const PIAOrderCompleted = () => {
    closeRFCModal();
    setShowNANotification(true);
  };

  // When Search is done and there are searched records in the record reducer
  // list, set the searched record list to the state
  useEffect(() => {
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      FEATURE,
    );
    if (schema && recordReducer && recordReducer?.list[schema.id]) {
      setSearchedRecords(recordReducer?.list[schema.id]);
    }
  }, [recordReducer.list]);

  const renderFeatureTable = () => {
    const columns: ColumnsType<DataType> = [
      {
        title: 'Record No.',
        dataIndex: 'recordNumber',
        key: 'recordNumber',
        width: '15%',
        render: (record: any) => (
          <a href={getBrowserPath(record)} target="_blank">
            {record.title}
          </a>
        ),
      },
      {
        title: 'QGIS Id',
        dataIndex: 'qgisId',
        key: 'qgisId',
        width: '15%',
        render: (text: string) => text,
        onFilter: (value: any, record: any) => record.qgisId?.indexOf(searchTerm) > -1,
        filteredValue: ['qgisId'],
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: '15%',
        render: (text: string) => text,
      },
      {
        title: 'Model',
        dataIndex: 'model',
        key: 'model',
        width: '30%',
        render: (text: string) => text,
      },
      {
        title: 'Build status',
        dataIndex: 'buildStatus',
        key: 'buildStatus',
        width: '35%',
        render: (text: string) => text,
      },
    ];

    const getModelForFeatureType = (record: DbRecordEntityTransform) => {
      switch (record.type) {
        case 'CABLE':
          return QGISCableModelEnum[getProperty(record, 'CableModel')];
        case 'DUCT':
          return ductTypes[Number(getProperty(record, 'DuctModel'))];
        case 'BLOCKAGE':
          return getProperty(record, 'BlockageModel');
        case 'CLOSURE':
          return QGISClosureModelEnum[getProperty(record, 'ClosureModel')];
        case 'CHAMBER':
          return chamberModels[getProperty(record, 'ChamberModel')];
        default:
          return '-';
      }
    };
    let data: DataType[] = [];

    data = searchedRecords.map((record: DbRecordEntityTransform) => ({
      key: String(record.id),
      recordNumber: record,
      type: String(record.type),
      model: getModelForFeatureType(record),
      buildStatus: String(QGISBuildStatusEnum[getProperty(record, 'BuildStatus')]),
      qgisId: String(getProperty(record, 'ExternalRef')),
    }));

    return (
      <Table
        size="small"
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        columns={columns}
        dataSource={[...data]}
      />
    );
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      setSelectedIds(selectedRowKeys?.map((id: Key) => String(id)));
    },
    selectedRowKeys: selectedIds,
  };

  const closeModalAndEnterRFCCreate = () => {
    // Survey Route - Draw line or point
    if (['duct', 'cable', 'rope'].includes(mapReducer.queryLayer!)) {
      updateMap({
        addLineFlow: false,
        addEnabled: false,
        addLineEnabled: false,
        isRFCSelectFeatureTypeModalVisible: true,
        isCreatingRFC: true,
        creatingRFCFromIds: selectedIds,
        isRFCModalVisible: false,
      });
    }
    // Survey Structure -> Draw point
    else {
      updateMap({
        addPointFlow: false,
        addEnabled: true,
        isRFCSelectFeatureTypeModalVisible: false,
        isCreatingRFC: true,
        creatingRFCFromIds: selectedIds,
        isRFCModalVisible: false,
      });
    }

    resetWIForm();

  };

  // Always maintain list of selected records in the state, we need it for the flows
  useEffect(() => {
    if (selectedIds.length > 0) {
      const selectedRecords = searchedRecords.filter((rec: DbRecordEntityTransform) =>
        selectedIds.includes(rec.id),
      );
      setSelectedRecords(selectedRecords);
    } else {
      setSelectedRecords([]);
    }
  }, [selectedIds]);

  const areAllSelectedFeaturesBlockages = () => {
    if (selectedIds.length > 0) {
      const selectedRecords = searchedRecords.filter((rec: DbRecordEntityTransform) =>
        selectedIds.includes(rec.id),
      );
      return selectedRecords.every((rec: DbRecordEntityTransform) => rec.type === 'BLOCKAGE');
    } else {
      return false;
    }
  };

  const associationsForPIA = () => {
    if (selectedRecords.length > 0) {
      return selectedRecords.map((rec: any) => ({
        entity: rec.entity,
        recordId: rec.id,
        relationType: RelationTypeEnum.CHILD,
      }));
    } else {
      return [];
    }
  };

  const toggleFlowVisibility = () => {
    setFlowVisible(!flowVisible);
  };

  // We show Table button only if these conditions are met.
  return searchedRecords.length > 0 &&
  !mapReducer.isCreatingRFC &&
  mapReducer.queryLayer &&
  !mapReducer.addEnabled &&
  !['survey_structure', 'survey_route', 'pia_duct', 'pia_structure'].includes(
    mapReducer.queryLayer!,
  ) ? (
    <>
      {contextHolder}
      <Button
        icon={<TableOutlined />}
        shape="circle"
        size="large"
        type="primary"
        className="floatingButton floatingRFCButton"
        onClick={() => openRFCModal()}
      />
      <Modal
        centered
        className="RFCModal"
        width={800}
        title="Select features for RFC"
        open={mapReducer.isRFCModalVisible}
        closable
        onCancel={() => closeRFCModal()}
        footer={[
          // Cancel Button
          <Button key="back" onClick={() => closeRFCModal()}>
            Cancel
          </Button>,

          // PIA Order Button
          <Flow
            launchInterface="NONE"
            flowVisible={flowVisible}
            launchInterfaceTitle=""
            toggleFlowVisibility={toggleFlowVisibility}
            flowSteps={[
              // 1. Create Network Adjustment
              {
                flowType: 'RECORD',
                title: 'Create Network Adjustment',
                moduleName: 'ProjectModule',
                entityName: 'OpenreachNetworkAdjustment',
                additionalAssociations: associationsForPIA(),
                runOnSubmit: fetchCreatedNetworkAdjustmentId,
              },
              // 2. Associate files to the newly created record
              {
                flowType: 'ASSOCIATION',
                title: 'Upload Files',
                moduleName: 'SchemaModule',
                entityName: 'File',
                recordType: 'DEFAULT',
                associatedFilesView: 'thumbnails',
                relateRecordFromStep: [1],
                runOnSubmit: PIAOrderCompleted,
                showFileCategoryForType: 'DEFAULT',
              },
            ]}
          />,

          // PIA Order
          <Button
            type="primary"
            disabled={!areAllSelectedFeaturesBlockages()}
            onClick={() => setFlowVisible(true)}
          >
            PIA Order
          </Button>,
          // Create RFC Button
          <Button
            type="primary"
            disabled={!selectedIds.length}
            onClick={() => closeModalAndEnterRFCCreate()}
          >
            Create RFC
          </Button>,
        ]}
      >
        <Row>
          <Col span={24}>
            <Row style={{ marginBottom: 10 }}>
              {/* Selected record count */}
              <Col span={14} style={{ paddingTop: 3, paddingLeft: 5 }}>
                <span>Selected features: {selectedIds.length}</span>
              </Col>
              {/* Quick Search */}
              <Col span={10}>
                <Search
                  allowClear
                  placeholder="Filter records by QGIS id"
                  value={searchTerm}
                  onChange={(e: any) => setSearchTerm(e.target?.value)}
                  onSearch={(e: any) => setSearchTerm(e.target?.value)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row>
          <Col span={24}>{renderFeatureTable()}</Col>
        </Row>
      </Modal>
    </>
  ) : (
    // We show this modal for all line type features, because the user has to
    // decide whether he wants to draw survey structure or survey route
    <Modal
      className="RFCFeatureTypeModal"
      centered
      title="Select RFC Feature Type"
      open={mapReducer.isRFCSelectFeatureTypeModalVisible}
      onCancel={() =>
        updateMap({
          isCreatingRFC: false,
          isRFCSelectFeatureTypeModalVisible: false,
          addEnabled: false,
          isRFCModalVisible: true,
        })
      }
    >
      <Row>
        {/* Survey Route Button */}
        <Col span={24} style={{ marginBottom: 15 }}>
          <Button
            onClick={() =>
              updateMap({
                addLineEnabled: true,
                isRFCSelectFeatureTypeModalVisible: false,
              })
            }
            style={{ width: '100%' }}
            size="large"
          >
            Survey Route
          </Button>
        </Col>
        {/* Survey Structure Button */}
        <Col span={24}>
          <Button
            style={{ width: '100%' }}
            size="large"
            onClick={() =>
              updateMap({
                addPointFlow: false,
                addEnabled: true,
                isRFCSelectFeatureTypeModalVisible: false,
              })
            }
          >
            Survey Structure
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  resetWIForm: () => dispatch(resetWorkItemForm()),
});

export default connect(mapState, mapDispatch)(RFCCreate);
