import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Col, Descriptions, Divider, Empty, Row, Spin, Tag } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import SlateRichText from '@legacy/components/RichEditor/SlateRichText';
import { getRecordFromShortListById } from '@core/helpers/recordHelpers';
import { getSchemaFromShortListByModuleAndEntity, getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;

interface Props {
  schemaReducer: any;
  recordReducer: any;
  recordAssociationReducer: any;
  getAssociations: any;
  match: any;
  searchRecords: any;
}

interface State {
  isLoadingRelatedArticlesByEntity: boolean;
  relatedArticlesByEntity: Array<DbRecordEntityTransform> | [];
  record: DbRecordEntityTransform | null;
}

class KnowledgeArticleView extends Component<Props, State> {
  state = {
    isLoadingRelatedArticlesByEntity: false,
    relatedArticlesByEntity: [],
    record: null,
  };

  private getRecordAssociations() {
    const { getAssociations, schemaReducer, recordReducer, match } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match?.params?.recordId,
    );
    if (record) {
      this.setState({ record: record });
      const schema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record.schemaId,
      );
      if (schema) {
        getAssociations({
          recordId: record.id,
          key: schema.entityName,
          schema,
          entities: [schema.entityName],
        });
      }
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevS: State): void {
    if (prevProps.recordReducer !== this.props.recordReducer) {
      this.getRecordAssociations();
    }

    if (prevS.record !== this.state.record) {
      this.loadRelatedArticlesByEntity();
    }
  }

  componentDidMount = () => this.getRecordAssociations();

  // Search for all Published KB Articles that match the same ModuleName / EntityName pair
  loadRelatedArticlesByEntity = () => {
    const { schemaReducer, searchRecords, recordReducer, match } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match?.params?.recordId,
    );

    if (record) {
      this.setState({ isLoadingRelatedArticlesByEntity: true });

      const KASchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList, SUPPORT_MODULE, 'KnowledgeArticle',
      );

      if (KASchema) {
        searchRecords(
          {
            schema: KASchema,
            searchQuery: {
              terms: '',
              schemas: KASchema?.id,
              boolean: {
                must: [
                  {
                    query_string: {
                      fields: ['type'],
                      query: record?.type,
                      lenient: true,
                      default_operator: 'AND',
                    },
                  },
                  {
                    query_string: {
                      fields: ['properties.Status'],
                      query: 'PUBLISHED',
                      lenient: true,
                      default_operator: 'AND',
                    },
                  },
                  {
                    query_string: {
                      fields: ['properties.ModuleName'],
                      query: getProperty(record, 'ModuleName'),
                      lenient: true,
                      default_operator: 'AND',
                    },
                  },
                  {
                    query_string: {
                      fields: ['properties.EntityName'],
                      query: getProperty(record, 'EntityName'),
                      lenient: true,
                      default_operator: 'AND',
                    },
                  },
                ],
                must_not: [
                  {
                    query_string: {
                      fields: ['id'],
                      query: record?.id,
                      lenient: true,
                      default_operator: 'AND',
                    },
                  },
                ],
              },
              sort: [{ createdAt: { order: 'desc' } }],
            },
          },
          (searchResults: any) => {
            if (searchResults.data.data) {
              this.setState({
                relatedArticlesByEntity: searchResults.data.data,
                isLoadingRelatedArticlesByEntity: false,
              });
            } else {
              this.setState({
                relatedArticlesByEntity: [],
                isLoadingRelatedArticlesByEntity: false,
              });
            }
          },
        );
      }


    }
  };

  // Render basic Information for the Article
  renderArticleInformation = () => {
    const { recordReducer, match } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match?.params?.recordId,
    );

    if (record) {
      const tags = getProperty(record, 'Tags');

      return (
        <Row style={{ padding: 20, backgroundColor: '#fff', borderRadius: 8 }}>
          <Col span={24}>
            <h3 style={{ marginBottom: 15 }}>Details</h3>
          </Col>
          <Col span={24}>
            <Descriptions size="small">
              <Descriptions.Item label="Author" span={3}>
                {record?.createdBy?.fullName}
              </Descriptions.Item>
              <Descriptions.Item label="Date" span={3}>
                {moment(record?.createdAt).format('MM/DD/YYYY | HH:mm:ss')}
              </Descriptions.Item>
              <Descriptions.Item label="Type" span={3}>
                {record?.type ? record?.type : '-'}
              </Descriptions.Item>
              <Descriptions.Item label="Status" span={3}>
                {getProperty(record, 'Status')
                  ? getProperty(record, 'Status')
                  : '-'}
              </Descriptions.Item>

              {record.type !== 'RELEASE_NOTE' ? (
                <>
                  <Descriptions.Item label="ModuleName" span={3}>
                    {getProperty(record, 'ModuleName')
                      ? getProperty(record, 'ModuleName')
                      : '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="EntityName" span={3}>
                    {getProperty(record, 'EntityName')
                      ? getProperty(record, 'EntityName')
                      : '-'}
                  </Descriptions.Item>
                </>
              ) : (
                <></>
              )}
              {record.type === 'RELEASE_NOTE' ? (
                <Descriptions.Item label="Version" span={3}>
                  {getProperty(record, 'Version') ? (
                    <Tag>{getProperty(record, 'Version')}</Tag>
                  ) : (
                    '-'
                  )}
                </Descriptions.Item>
              ) : (
                <></>
              )}
            </Descriptions>
          </Col>

          {/* Render KA tags */}
          {tags?.length > 0 ? (
            <Col span={24} style={{ marginTop: 10 }}>
              <div>
                {tags.split(',').map((tag: string) => (
                  <Tag style={{ marginBottom: 5 }}>{tag}</Tag>
                ))}
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      );
    } else {
      return <></>;
    }
  };

  // UI Handler for the renderRelatedArticlesByEntity function
  renderListOfRelatedEntityArticles = (
    articles: Array<DbRecordEntityTransform>,
  ) => {
    if (articles.length) {
      return (
        <ul>
          {articles.map((article: any) => {
            return (
              <li>
                <a
                  style={{ padding: 0 }}
                  href={`/${SUPPORT_MODULE}/KnowledgeArticle/${article.id}/view`}
                  target={'_blank'}
                >
                  {article.title}
                </a>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <Col span={24} style={{ padding: 20 }}>
          <Empty description="No Articles found." />
        </Col>
      );
    }
  };

  renderRelatedArticlesByEntity = () => {
    const { recordReducer, match } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match?.params?.recordId,
    );

    if (record && getProperty(record, 'EntityName')) {
      return (
        <Row
          style={{
            padding: 20,
            backgroundColor: '#fff',
            borderRadius: 8,
            marginTop: 20,
          }}
        >
          <Col span={24}>
            <h3 style={{ marginBottom: 15 }}>
              Related Articles for {getProperty(record, 'EntityName')}
            </h3>
          </Col>
          <Col span={24}>
            <Row>
              {this.state.isLoadingRelatedArticlesByEntity ? (
                <Col span={24} style={{ textAlign: 'center', padding: 20 }}>
                  <Spin size="large" style={{ marginBottom: 10 }} />
                  <br />
                  <span>Searching for Articles</span>
                </Col>
              ) : (
                this.renderListOfRelatedEntityArticles(
                  this.state.relatedArticlesByEntity,
                )
              )}
            </Row>
          </Col>
        </Row>
      );
    } else {
      return <></>;
    }
  };

  // All General Related Articles that are associated to this record
  renderGeneralRelatedArticles() {
    const { recordReducer, match, schemaReducer, recordAssociationReducer } =
      this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match?.params?.recordId,
    );
    if (!record) {
      return null;
    }
    const schema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record.schemaId,
    );
    if (!schema) {
      return null;
    }
    const associationKey =
      record && record.id ? `${record.id}_${schema?.entityName}` : '';
    const associationObj: any =
      record && record.id
        ? recordAssociationReducer.shortList[associationKey]
        : [];
    const entityName = schema?.entityName;
    if (
      associationObj &&
      associationObj[entityName] &&
      associationObj[entityName].dbRecords
    ) {
      return (
        <div
          style={{
            padding: 20,
            marginTop: 20,
            backgroundColor: '#fff',
            borderRadius: 8,
          }}
        >
          <h3 style={{ marginBottom: 15 }}>General Related Articles</h3>
          {associationObj[entityName].dbRecords.length ? (
            <div>
              {associationObj[entityName].dbRecords.map(
                (item: DbRecordEntityTransform, i: number) => {
                  return (
                    <p>
                      <Button
                        type="link"
                        style={{
                          padding: 0,
                          whiteSpace: 'break-spaces',
                          textAlign: 'left',
                          lineHeight: '1.6em',
                          marginBottom:
                            associationObj[entityName].dbRecords.length ===
                            i + 1
                              ? 0
                              : 10,
                        }}
                        href={`/${schema.moduleName}/${schema.entityName}/${item.id}/view`}
                        target={'_blank'}
                      >
                        {item.title}
                      </Button>
                    </p>
                  );
                },
              )}
            </div>
          ) : (
            <></>
          )}
        </div>
      );
    }
  }

  render() {
    const { recordReducer, match } = this.props;
    const record = getRecordFromShortListById(
      recordReducer.shortList,
      match?.params?.recordId,
    );
    let jsonContent: any;
    try {
      jsonContent = getProperty(record, 'JSONContent') || null;
    } catch (e) {
      jsonContent = null;
    }
    return (
      <Row style={{ padding: isMobile ? 5 : 20 }}>
        <Col xs={24} sm={24} md={18} lg={18} style={{ padding: 8 }}>
          <div
            style={{ padding: 20, backgroundColor: '#fff', borderRadius: 8 }}
          >
            <Row>
              <Col span={24}>
                <h2>{record?.title ? record?.title : 'Untitled'}</h2>
              </Col>
              <Divider style={{ margin: '5px 0 20px 0' }} />
              <Col span={24}>
                {jsonContent && (
                  <SlateRichText
                    initialValue={jsonContent}
                    isViewMode
                    associatedRecordEntity={`${SUPPORT_MODULE}:KnowledgeArticle`}
                  />
                )}
              </Col>
            </Row>
          </div>
        </Col>

        <Col xs={24} sm={24} md={6} lg={6} style={{ padding: 8 }}>
          {this.renderArticleInformation()}
          {this.renderRelatedArticlesByEntity()}
          {this.renderGeneralRelatedArticles()}
        </Col>
      </Row>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  searchRecords: (params: ISearchRecords, cb: any) =>
    dispatch(searchRecordsRequest(params, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(KnowledgeArticleView));
