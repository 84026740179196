import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

export const getInboxViewportHeight = (tabs: number) => {
  if (tabs > 0) {
    return '85vh';
  } else {
    return '89vh';
  }
};

export const getMyAssignments = (assignments: any[], userId: any): DbRecordEntityTransform[] => {
  let response = Object.assign([], assignments);

  response = response.filter(
    (assignment: DbRecordEntityTransform) =>
      assignment.properties?.OwnerUserId === userId || !assignment.properties?.TeamId,
  );

  return response;
};

export const getTeamAssignments = (assignments: any[], userId: any): DbRecordEntityTransform[] => {
  let response = Object.assign([], assignments);

  response = response.filter(
    (assignment: DbRecordEntityTransform) =>
      assignment.properties?.TeamId && assignment.properties?.OwnerUserId !== userId,
  );

  return response;
};
