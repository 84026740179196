import {
  GET_DB_RECORD_AUDIT_LOGS_ERROR,
  GET_DB_RECORD_AUDIT_LOGS_REQUEST,
  GET_DB_RECORD_AUDIT_LOGS_SUCCESS,
} from './constants';

export interface IAuditLogsReducerState {
  isRequesting: boolean;
  isSearching: boolean;
  shortList: { [key: string]: any };
}

export const initialState: IAuditLogsReducerState = {
  isRequesting: false,
  isSearching: false,
  shortList: {},
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case GET_DB_RECORD_AUDIT_LOGS_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        shortList: Object.assign({}, state.shortList, {
          [action.params.recordId]: [],
        }),
      };
    }

    case GET_DB_RECORD_AUDIT_LOGS_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        shortList: Object.assign({}, state.shortList, {
          [action.recordId]: action.results,
        }),
      };
    }

    case GET_DB_RECORD_AUDIT_LOGS_ERROR: {
      return {
        ...state,
        isRequesting: false,
      };
    }

    default:
      return state;
  }
}

export default reducer;
