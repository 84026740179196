import { Callout } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import { parseDateToLocalFormat } from '@core/helpers/dateHelpers';
import { usePrevious } from '@core/helpers/reactHelpers';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { getOdinSchemaByRecord } from '@core/helpers/schemaHelpers';
import './styles.scss';

interface Props {
  record: DbRecordEntityTransform | undefined;
  getAssociations: (params: IGetRecordAssociations, cb: any) => void;
}

const { INVOICE } = SchemaModuleEntityTypeEnums;

const InvoicePendingCallout: FC<Props> = (props: Props) => {
  const { record, getAssociations } = props;
  const [invoiceList, setInvoiceList] = useState<DbRecordEntityTransform[]>([]);
  const [loadingAssociations, setLoadingAssociations] = useState<boolean>(true);
  const [updatingAssociations, setUpdatingAssociations] = useState<boolean>(false);
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

  //   Watch for record change while the component is mounted
  const recordId = record?.id;
  const previousRecordId = usePrevious(recordId);
  useEffect(() => {
    if (previousRecordId && recordId !== previousRecordId && !loadingAssociations) {
      if (record && schema) {
        loadInvoices(schema, record);
      }
    }
  }, [recordId]);

  useEffect(() => {
    if (record && !schema) {
      getSchemas(record.entity);
    }
  }, [record]);

  useEffect(() => {
    if (schema && record) {
      loadInvoices(schema, record);
    }
  }, [schema]);

  const getSchemas = async (entity: string | undefined) => {
    if (entity && record) {
      const recordSchema = await getOdinSchemaByRecord(record);
      setSchema(recordSchema);
    }
  };

  const loadInvoices = (schema: SchemaEntity, record: DbRecordEntityTransform) => {
    setUpdatingAssociations(true);
    getAssociations(
      {
        recordId: record.id,
        key: INVOICE,
        schema: schema,
        entities: [INVOICE],
      },
      (res: any) => {
        if (res && res.results?.[INVOICE]?.dbRecords?.length > 0) {
          // Filter out Invoices that are PAID or VOID
          const filteredInvoiceList = res.results?.[INVOICE]?.dbRecords.filter(
            (invoice: DbRecordEntityTransform) =>
              getProperty(invoice, 'Status') !== 'PAID' &&
              getProperty(invoice, 'Status') !== 'VOID',
          );
          setInvoiceList(filteredInvoiceList);
        }
        setLoadingAssociations(false);
        setUpdatingAssociations(false);
      },
    );
  };
  return (
    <>
      {!loadingAssociations && (
        <Callout
          intent={invoiceList?.length > 0 ? 'warning' : 'success'}
          title={invoiceList?.length > 0 ? 'Pending invoices' : 'No pending invoices'}
        >
          <div style={{ marginTop: invoiceList.length > 0 ? 10 : 0 }}>
            {invoiceList?.map((record) => (
              <Link target="_blank" to={getBrowserPath(record)}>
                <div>
                  <div>
                    {record.recordNumber} [{getProperty(record, 'Status')}] is due on{' '}
                    {parseDateToLocalFormat(getProperty(record, 'DueDate'))} for £
                    {getProperty(record, 'Balance')}
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </Callout>
      )}
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(InvoicePendingCallout);
