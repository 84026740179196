import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import {
  DbRecordAssociationCreateUpdateDto,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import {
  DbRecordAssociationRecordsTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getAllRelations, getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Button, Checkbox, Drawer, Input, List, Row, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { TableReducer } from '@legacy/core/records/components/DynamicTable/store/reducer';
import { getRecordByIdRequest, IGetRecordById } from '@legacy/core/records/store/actions';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
  updateOrCreateRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '@legacy/core/recordsAssociations/store/reducer';
import {
  getSchemaByIdRequest,
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { canUserSearchRecord } from '@core/helpers/rbacRules';
import {
  getSchemaFromShortListByModuleAndEntity,
  getSchemaFromShortListBySchemaId,
} from '@core/helpers/schemaHelpers';

import PriceBookSelector from './PriceBookSelector';

const { Search } = Input;

interface Props {
  record: DbRecordEntityTransform;
  relation: DbRecordAssociationRecordsTransform;
  hidden?: string[];
  userReducer: any;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  pipelinesEnabled?: boolean;
  createAssociations: any;
  getAssociations: any;
  getRecordById: any;
  getSchemaById: Function;
  getSchemaByModuleEntity: Function;
}

const { PRODUCT_MODULE } = SchemaModuleTypeEnums;
const { PRODUCT } = SchemaModuleEntityTypeEnums;

interface State {
  search: string | null;
  visible: boolean;
  selected: any[];
  existingRelations: string[];
  priceBookId: string | undefined;
}

class PriceBookProductSelector extends React.Component<Props, State> {
  state = {
    search: null,
    visible: false,
    selected: [],
    priceBookId: undefined,
    existingRelations: [],
  };

  componentDidMount() {
    const { record, schemaReducer, getSchemaById, getSchemaByModuleEntity } = this.props;

    // Get record schema from shortlist or fallback to API request
    if (record) {
      const recordShortlistSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        record.schemaId,
      );
      if (!recordShortlistSchema) {
        getSchemaById({ schemaId: record?.schemaId });
      }
    }

    const productShortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRODUCT,
    );
    if (!productShortlistSchema) {
      getSchemaByModuleEntity({ moduleName: PRODUCT_MODULE, entityName: PRODUCT });
    }
  }

  private openDrawer() {
    this.setState({
      visible: true,
    });
    this.initializeSelectedItems();
  }

  private initializeSelectedItems() {
    const { record, relation, recordAssociationReducer } = this.props;
    const relationEntityName = relation.schema?.entityName as string;
    const associationKey = `${record?.id}_${relationEntityName}`;
    if (recordAssociationReducer.shortList) {
      const associationObj: any = recordAssociationReducer.shortList[associationKey];
      const relatedRecords = getAllRelations(associationObj, relationEntityName);

      if (relatedRecords) {
        const productIds = relatedRecords.map((elem: DbRecordEntityTransform) =>
          getProperty(elem, 'ProductRef'),
        );
        this.setState({
          existingRelations: productIds,
        });
      }
    }
  }

  addRemoveItem = (item: DbRecordEntityTransform) => {
    if (this.state.selected.find((elem) => elem === item.id)) {
      // remove the item
      this.setState({
        selected: this.state.selected.filter((elem) => elem !== item.id),
      });
    } else {
      this.setState((prevState) => ({
        // @ts-ignore
        selected: [...prevState.selected, ...[item.id]],
      }));
    }
  };

  handleOk = () => {
    const { record, relation, createAssociations, recordAssociationReducer, schemaReducer } =
      this.props;
    const { schemaAssociation } = relation;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    if (schemaAssociation && record && schema && recordAssociationReducer.shortList) {
      const associationKey = `${this.state.priceBookId}_${PRODUCT}`;
      const associationObj: any = recordAssociationReducer.shortList[associationKey];
      const data = associationObj[PRODUCT].dbRecords;

      const body: DbRecordAssociationCreateUpdateDto[] = [];
      for (const prodId of this.state.selected) {
        const matchingProduct = data.find((elem: DbRecordEntityTransform) => elem.id === prodId);

        body.push({
          entity: `${SchemaModuleTypeEnums.PRODUCT_MODULE}:${SchemaModuleEntityTypeEnums.PRODUCT}`,
          recordId: prodId,
          relatedAssociationId: matchingProduct?.dbRecordAssociation.id,
        });
      }

      createAssociations(
        {
          recordId: record.id,
          schema,
          schemaAssociation,
          createUpdate: body,
        },
        () => {
          this.handleCancel();
          // fetch record relations
          this.getRecordAssociations();
        },
      );
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  private getRecordAssociations() {
    const { schemaReducer, getRecordById, getAssociations, record, relation } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    if (record && schema) {
      getRecordById({ schema, recordId: record?.id });
      getAssociations({
        recordId: record.id,
        key: relation.schema.entityName,
        schema: schema,
        entities: [relation.schema.entityName],
      });
    }
  }

  private renderPriceBookProducts() {
    const { recordAssociationReducer, schemaReducer } = this.props;
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRODUCT,
    );
    if (schema && recordAssociationReducer.shortList) {
      // @ts-ignore
      const associationKey = `${this.state.priceBookId}_${PRODUCT}`;
      if (recordAssociationReducer.shortList[associationKey]) {
        const associationObj: any = recordAssociationReducer.shortList[associationKey];
        let data = associationObj[PRODUCT].dbRecords;

        data = data?.filter(
          (elem: DbRecordEntityTransform) =>
            // @ts-ignore
            !this.state.existingRelations.includes(elem.id),
        );

        if (this.state.search) {
          // @ts-ignore
          const searchRegex = new RegExp(this.state.search, 'i');
          data = data?.filter((elem: DbRecordEntityTransform) =>
            // @ts-ignore
            searchRegex.test(elem?.title),
          );
        }

        return (
          <>
            <List
              style={{ height: 400, overflow: 'scroll', width: '100%' }}
              itemLayout="horizontal"
              dataSource={data}
              renderItem={(item: DbRecordEntityTransform) => (
                <List.Item
                  actions={[<Checkbox onChange={() => this.addRemoveItem(item)}>Add</Checkbox>]}
                >
                  <List.Item.Meta
                    title={`${item.recordNumber} ${item.title}`}
                    description={
                      <div>
                        <div style={{ display: 'flex', marginLeft: 24 }}>
                          <Typography.Text style={{ marginRight: 24 }}>
                            <strong>Category: </strong> {getProperty(item, 'Category')}
                          </Typography.Text>
                          <Typography.Text style={{ marginRight: 24 }}>
                            <strong>Type: </strong>
                            {getProperty(item, 'Type')}
                          </Typography.Text>
                          <Typography.Text style={{ marginRight: 24 }}>
                            <strong>Contract: </strong>
                            {getProperty(item, 'ContractType')}
                          </Typography.Text>
                        </div>

                        <div
                          style={{
                            display: 'flex',
                            marginLeft: 24,
                            marginTop: 8,
                          }}
                        >
                          <Typography.Text style={{ marginRight: 24 }}>
                            <strong>Discount: </strong> {getProperty(item, 'DiscountValue')} (
                            {getProperty(item, 'DiscountType')})
                          </Typography.Text>
                          <Typography.Text style={{ marginRight: 24 }}>
                            <strong>Free Period: </strong> {getProperty(item, 'TrialLength')} (
                            {getProperty(item, 'TrialUnit')})
                          </Typography.Text>
                        </div>
                      </div>
                    }
                  />
                  <div>
                    <Typography.Text style={{ marginRight: 24 }}>
                      <strong>Price </strong>
                      {getProperty(item, 'UnitPrice')}
                    </Typography.Text>
                  </div>
                </List.Item>
              )}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: 16,
              }}
            >
              <Button
                type="primary"
                loading={recordAssociationReducer.isCreating}
                disabled={this.state.selected.length < 1}
                onClick={() => this.handleOk()}
              >
                Save{' '}
              </Button>
            </div>
          </>
        );
      } else {
        return;
      }
    }
  }

  render() {
    const { schemaReducer, userReducer, recordReducer } = this.props;
    const productShortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PRODUCT_MODULE,
      PRODUCT,
    );

    return (
      <div>
        <Button
          disabled={!canUserSearchRecord(userReducer, productShortlistSchema)}
          style={{ width: 125 }}
          type="text"
          onClick={() => this.openDrawer()}
        >
          Lookup Product
        </Button>
        <Drawer
          title={`Add Products`}
          open={this.state.visible}
          onClose={this.handleCancel}
          width={1000}
        >
          <Spin spinning={recordReducer.isRequesting} tip="Saving changes...">
            <Row>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div>
                  <Typography.Text>Select Price Book</Typography.Text>
                  {this.state.visible && (
                    <PriceBookSelector
                      onOptionSelected={(params: any) =>
                        this.setState({
                          priceBookId: params,
                        })
                      }
                    />
                  )}
                </div>
                <div style={{ marginLeft: 20 }}>
                  <Typography.Text>Find Products</Typography.Text>
                  <div>
                    <Search
                      placeholder="filter products"
                      onChange={(e) => {
                        this.setState({
                          search: e.target.value,
                        });
                      }}
                      style={{ width: 200 }}
                    />
                  </div>
                </div>
              </div>
              {this.renderPriceBookProducts()}
            </Row>
          </Spin>
        </Drawer>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordTableReducer: state.recordTableReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  createAssociations: (params: any, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
  getSchemaById: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  getSchemaByModuleEntity: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(PriceBookProductSelector);
