import {
  BankOutlined,
  BarcodeOutlined,
  CalendarOutlined,
  DownOutlined,
  IdcardOutlined,
  MessageOutlined,
  ProjectOutlined,
  RightOutlined,
  ShoppingCartOutlined,
  WifiOutlined,
} from '@ant-design/icons';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row, Tooltip } from 'antd';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';

import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import { setRawDataDrawerContents, toggleRawDataDrawer } from '../../../../userInterface/store/actions';
import ActivityBody from '../ActivityBody';
import {
  checkIfActivityIsAnEmail,
  getActivityEntity,
  getActivityModule,
  getAssociatedRecordForAnActivity,
  getChildRecordInformation,
  getParentRecordInformation,
  isActivityAnAssociation,
  isActivityNewGroupUpdate,
  isActivityRecordTitleUpdate,
  isActivityStageUpdate,
} from '../helpers';
import { eventTypesFmtENUM } from '../types';
import './styles.scss';
import { Button } from '@blueprintjs/core';

interface Props {
  activityItem: DbRecordEntityTransform;
  recordEntityName: string | undefined;
  toggleActivityPanel: Function;
  setRawDataDrawer: Function;
  toggleDrawer: Function;
  compact?: boolean;
}

const ActivityItem: React.FC<Props> = (props: Props) => {
  const { setRawDataDrawer, toggleDrawer, compact } = props;
  const [associatedRecord, setAssociatedRecord] = useState<any>(undefined);

  // On component mount, get an associated record
  useEffect(() => {
    if (isActivityAnAssociation(activityItem.type)) {
      setAssociatedRecord(getAssociatedRecordForAnActivity(activityItem));
    }
  }, []);

  const getDefaultIconForModule = (activityItem: DbRecordEntityTransform) => {
    const activityModuleName = getActivityModule(activityItem);

    switch (activityModuleName) {
      case SchemaModuleTypeEnums.PROJECT_MODULE:
        return <ProjectOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      case SchemaModuleTypeEnums.NOTIFICATION_MODULE:
        return <MessageOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      case SchemaModuleTypeEnums.PRODUCT_MODULE:
        return <BarcodeOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      case SchemaModuleTypeEnums.SERVICE_MODULE:
        return <WifiOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      case SchemaModuleTypeEnums.FIELD_SERVICE_MODULE:
        return <CalendarOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      case SchemaModuleTypeEnums.BILLING_MODULE:
        return <BankOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      case SchemaModuleTypeEnums.ORDER_MODULE:
        return <ShoppingCartOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      case SchemaModuleTypeEnums.CRM_MODULE:
        return <IdcardOutlined className={`activityIcon ${activityModuleName}Icon`} />;
      default:
        return <></>;
    }
  };

  const { activityItem } = props;

  const renderActivityTitle = (activityItem: DbRecordEntityTransform) => {
    let activityEntity = getActivityEntity(activityItem);

    // Check if activity is association
    if (activityItem.type && activityItem?.type?.indexOf('ASSOCIATION_CREATED') > -1) {
      if (activityEntity === 'File') {
        if (checkIfActivityIsAnEmail(activityItem)) {
          return <span>sent an Email</span>;
        } else {
          return <span>uploaded a File</span>;
        }
      } else if (activityEntity === 'Note') {
        return <span>created a Note</span>;
      } else {
        const parentRecordInformation = getParentRecordInformation(activityItem);
        const childRecordInformation = getChildRecordInformation(activityItem);

        const { parentEntity, parentRecordNumber, parentRecordUrl, parentRecordTitle } =
          parentRecordInformation;

        const { childEntity, childRecordNumber, childRecordUrl, childRecordTitle } =
          childRecordInformation;

        return (
          <div className={compact ? 'compactSize' : ''} style={{ display: 'contents' }}>
            {/* Action */}
            <span
              style={{ color: activityItem.type.indexOf('DELETED') > -1 ? '#d02d2d' : 'black' }}
            >
              {eventTypesFmtENUM[activityItem.type as keyof typeof eventTypesFmtENUM]}{' '}
            </span>

            {/* Parent Record */}
            {/* <ModuleEntityIcon moduleName={parentModule} entityName={parentEntity} compact /> */}

            {(parentRecordUrl && parentRecordNumber) || (parentRecordUrl && parentRecordTitle) ? (
              <Link to={parentRecordUrl} target="_blank">
                <span>{parentRecordNumber ? `(${parentRecordNumber}) ` : ''}</span>
                <span>{parentRecordTitle}</span>
              </Link>
            ) : (
              <span
                style={{
                  padding: '2px 4px',
                  border: '1px solid #bababa',
                  borderRadius: 4,
                  color: '#363636',
                }}
                className={compact ? 'compactSize' : ''}
              >
                Missing {parentEntity} record
              </span>
            )}

            {/* Arrow */}
            <span style={{ margin: '5px 5px 0px 5px' }}>→</span>

            {/* Child Record */}

            {/* <ModuleEntityIcon moduleName={childModule} entityName={childEntity} compact /> */}
            <Link to={childRecordUrl} target="_blank">
              {['Activity'].includes(childEntity) ? (
                <>
                  <span>Activity</span>
                </>
              ) : (
                <>
                  <span>{childRecordNumber ? `(${childRecordNumber}) ` : ''}</span>
                  <span>{childRecordTitle}</span>
                </>
              )}
            </Link>
          </div>
        );
      }
    }
    // Title update
    else if (isActivityRecordTitleUpdate(activityItem)) {
      return (
        <span className={compact ? 'compactSize' : ''}>
          updated record title to "{activityItem.revision?.title}"
        </span>
      );
    }
    // Stage update
    else if (isActivityStageUpdate(activityItem)) {
      const stageId = activityItem.revision?.stageId;
      const stage = activityItem.associations?.find(
        (association: any) => association.id === stageId,
      );
      return (
        <span className={compact ? 'compactSize' : ''}>
          updated record stage to "{stage?.name}"
        </span>
      );
    }

    // NEW Group update
    else if (isActivityNewGroupUpdate(activityItem)) {
      return (
        <span className={compact ? 'compactSize' : ''}>
          {`updated record group from ${
            activityItem?.revision?.previousGroups?.join(', ') || 'N/A'
          } to ${activityItem?.revision?.nextGroups?.join(', ')}`}
        </span>
      );
    }
    // All the rest...
    else {
      return (
        <span className={compact ? 'compactSize' : ''}>
          {eventTypesFmtENUM[activityItem.type as keyof typeof eventTypesFmtENUM]}
        </span>
      );
    }
  };

  const toggleActivityPanel = (id: string) => {
    props.toggleActivityPanel(id);
  };

  return (
    <Row style={{ marginBottom: 8 }}>
      <Col span={24}>
        <div style={{ display: 'inline-block' }} className="ActivityRow">
          {/* Collapse / Expand caret */}
          {activityItem.open ? (
            <DownOutlined
              style={{ marginRight: 10, fontSize: '0.7em' }}
              className="activityItemToggle"
              onClick={() => toggleActivityPanel(activityItem.id)}
            />
          ) : (
            <RightOutlined
              className="activityItemToggle"
              onClick={() => toggleActivityPanel(activityItem.id)}
              style={{ marginRight: 10, fontSize: '0.7em' }}
            />
          )}

          {/* User name */}
          <Tooltip
            color="white"
            mouseEnterDelay={0.8}
            overlayInnerStyle={{ width: 170, borderRadius: 10 }}
            title={
              <Row style={{ color: 'black', padding: 10 }} className={compact ? 'compactSize' : ''}>
                <Col span={24}>
                  <Row>
                    <Col span={24}>{activityItem.userName}</Col>
                    <Col span={24}>
                      <Link
                        to={`/IdentityManagerModule/Users/${activityItem.userId}`}
                        target="_blank"
                      >
                        View profile
                      </Link>
                    </Col>
                  </Row>
                </Col>
              </Row>
            }
          >
            <span
              style={{ fontWeight: 500 }}
              className={`activityUserName ${compact ? 'compactSize' : ''}`}
              onClick={() => toggleActivityPanel(activityItem.id)}
            >
              {activityItem.userName}&nbsp;
            </span>
          </Tooltip>

          {/* Activity title */}
          <span onClick={() => toggleActivityPanel(activityItem.id)} style={{ cursor: 'pointer' }}>
            {renderActivityTitle(activityItem)}
          </span>

          {/* Time */}
          <span style={{ marginLeft: 5, color: '#a1a1a1' }}>
            {/*@ts-ignore*/}
            {DateTime.fromISO(activityItem.createdAt).toFormat('d/M/yyyy h:mm a ZZZZ')}
          </span>
        </div>
      </Col>

      {/* Body */}
      <Col span={24}>
        {activityItem.open ? (
          <div
            style={{ padding: 10, marginRight: 10, borderLeft: '1px solid #d1d1d1', marginLeft: 4 }}
            key={activityItem.id}
          >
            <ActivityBody activityItem={activityItem} compact={compact} />
            {isSystemAdmin() ? (
              <Row>
                <Col span={24} style={{ textAlign: 'right', margin: 10 }}>
                  <Button
                    small
                    style={{ marginRight: 20 }}
                    onClick={() => {
                      setRawDataDrawer(activityItem);
                      toggleDrawer();
                    }}
                  >
                    View Raw data
                  </Button>
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  setRawDataDrawer: (params: string) => dispatch(setRawDataDrawerContents(params)),
  toggleDrawer: () => dispatch(toggleRawDataDrawer()),
});

export default connect(mapState, mapDispatch)(ActivityItem);
