import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Card, Col, Row } from 'antd';
import { FC } from 'react';
import RecordCard from '@legacy/core/records/components/RecordCard';
import ActivityCenter from '@legacy/core/records/components/ActivityCenter';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
interface IProps {
  selectedRecord: DbRecordEntityTransform;
  schema: SchemaEntity;
}

const { CRM_MODULE, PRODUCT_MODULE, ORDER_MODULE, FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { CONTACT, PRODUCT, OFFER, DISCOUNT, ORDER, WORK_ORDER, LEAD, VISIT } =
  SchemaModuleEntityTypeEnums;

export const DataSetAddressDetailView: FC<IProps> = (props) => {
  const { selectedRecord, schema } = props;

  return (
    <Row gutter={20}>
      <Col span={14}>
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={selectedRecord}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={CONTACT}
          visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
        />
        <Card title="Activity & Notes">
          <ActivityCenter record={selectedRecord} schema={schema!} hideNoteTab />
        </Card>
      </Col>
      <Col span={10}>
        <RecordCard
          showActionMenu
          showRecordStage
          showItemActionMenu
          record={selectedRecord}
          displayQuickView
          associatedRecordModuleName={ORDER_MODULE}
          associatedRecordEntityName={ORDER}
          visibleProperties={['ContractStartDate', 'ContractEndDate']}
        />
        <RecordCard
          showRecordStage
          showActionMenu
          showItemActionMenu
          record={selectedRecord!}
          displayQuickView
          associatedRecordModuleName={FIELD_SERVICE_MODULE}
          associatedRecordEntityName={WORK_ORDER}
          visibleProperties={['Type']}
        />
        <RecordCard
          record={selectedRecord!}
          showActionMenu
          showRecordStage
          showItemActionMenu
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={LEAD}
          visibleProperties={['EmailAddress']}
        />
        <RecordCard
          record={selectedRecord!}
          showActionMenu
          showItemActionMenu
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={VISIT}
          visibleProperties={['Outcome', 'Status', 'FollowUpDate']}
        />
      </Col>
    </Row>
  );
};
