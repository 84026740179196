import React from 'react';
import { Button, MenuItem, Spinner } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';

type OdinDropdownProps = {
  placeholder: string;
  items: OdinDropdownOption[];
  selectedItem: OdinDropdownOption | null;
  onChange: (selected: OdinDropdownOption | null) => void;
  loading?: boolean;
  disabled?: boolean;
  id?: string;
}
export type OdinDropdownOption = {
  id: string;
  text: string;
}

const renderItem: ItemRenderer<OdinDropdownOption> = (item, { handleClick, handleFocus, modifiers }) => {
  return (
    <MenuItem
      key={item.id}
      text={item.text}
      roleStructure="listoption"
      active={modifiers.active}
      onClick={handleClick}
      onFocus={handleFocus}
    />
  );
};

export const OdinDropdown: React.FC<OdinDropdownProps> = ({
                                                            placeholder,
                                                            items,
                                                            selectedItem,
                                                            onChange,
                                                            loading = false,
                                                            disabled,
                                                            id,
                                                          }) => {
  return (
    <Select<OdinDropdownOption>
      // Items
      items={items}
      itemRenderer={renderItem}
      // Selection
      activeItem={selectedItem}
      onItemSelect={onChange}
      // Filter
      itemPredicate={(query, item) => item.text.toLowerCase().includes(query.toLowerCase())}
    >
      <Button
        id={id}
        text={selectedItem ? selectedItem.text : placeholder}
        rightIcon={loading ? <Spinner size={16} /> : 'caret-down'}
        fill
        disabled={loading || disabled}
        className="dropdown__button"
        style={{ borderRadius: 5 }}
      />
    </Select>
  );
};