import { PipelineEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity";
import { SchemaActionEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity";
import { SchemaEntity } from "@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity";

const formFields = [
  {
    label: 'ID',
    property: 'id',
    isRequired: false,
    isHidden: true,
    value: undefined,
  },
  {
    label: 'Schema',
    property: 'schemaId',
    type: 'ENUM',
    isRequired: true,
    value: undefined,
  },
  {
    label: 'Action Name',
    property: 'name',
    type: 'TEXT',
    isRequired: true,
    message: 'Please enter Action name',
    value: undefined,
  },
  {
    label: 'Description',
    property: 'description',
    type: 'TEXT',
    isRequired: true,
    message: 'Please enter description',
    value: undefined,
  },
  {
    label: 'Action Type',
    property: 'type',
    type: 'ENUM',
    isRequired: true,
    message: 'Please select action type',
    value: undefined,
    options: ['Create', 'Update', 'Flow'].map(type => ({ label: type, value: type.toUpperCase() })),
  },
  {
    label: 'Schema Type',
    property: 'schemaTypeId',
    type: 'ENUM',
    isRequired: false,
    message: 'Please select schema type',
    value: undefined,
  },
  {
    label: 'Stages',
    property: 'stages',
    type: 'ENUM',
    isRequired: false,
    allowMultiple: true,
    message: 'Please select stages',
  },
  {
    label: 'Target Stages',
    property: 'targetStages',
    type: 'ENUM',
    isRequired: false,
    allowMultiple: true,
    message: 'Please select target stages',
  },
  {
    label: 'Default Form',
    property: 'defaultForm',
    type: 'SWITCH',
    value: undefined,
  },
  {
    label: 'User Action',
    property: 'userAction',
    type: 'SWITCH',
    value: undefined,
  }
];

const getActionType = (action: SchemaActionEntity) => {
  if (action.isCreate) return 'CREATE';
  if (action.isUpdate) return 'UPDATE';
  return 'FLOW';
};

export const getFormFields = (schema: SchemaEntity, record?: any, currentSchemaTypeId?: string | null, pipeline?: PipelineEntity) => {
  const hasSchemaTypes = schema.types?.length > 1;

  const filteredFields = formFields.filter((field) => {
    // Hide Schema field when editing action
    if (record && field.property === 'schemaId')
      return false;

    // Hide schemaTypeId field if there are no schema types
    if (field.property === 'schemaTypeId')
      return (hasSchemaTypes && !currentSchemaTypeId) || currentSchemaTypeId === 'all';

    // All other fields are included
    return true;
  });

  const fieldsWithValues = filteredFields.map(field => {
    // Add value to field
    const newField: any = { ...field, value: record?.[field.property] };

    // Add initialValue to ENUM fields
    if (field.type === 'ENUM' && record?.[field.property]) {
      newField.initialValue = record?.[field.property];
    }

    // Add schema type options to schemaTypeId field
    if (field.property === 'schemaTypeId') {
      newField.options = schema.types.map(type => ({ label: type.name, value: type.id }));
      newField.value = record ? record.schemaTypeId : currentSchemaTypeId;
      newField.initialValue = record ? record.schemaTypeId : currentSchemaTypeId;
    }

    // Map type from booleans to select
    if (field.property === 'type') {
      const typeValue = record ? getActionType(record) : 'CREATE';
      newField.initialValue = typeValue;
      newField.value = typeValue;
    }

    if (field.property === 'schemaId') {
      newField.value = schema.id;
      newField.initialValue = schema.id;
      newField.options = [{ label: schema.entityName, value: schema.id }];
    }

    if (['stages', 'targetStages'].includes(field.property)) {
      newField.allowMultiple = true;
      newField.isDisabled = !pipeline;
      newField.options = pipeline?.stages
        ? pipeline.stages.map(stage => ({ label: stage.name, value: stage.id }))
        : [];
      newField.value = record ? record[field.property] : [];
    }

    return newField;
  });

  return fieldsWithValues;
};
