import { call, put, takeLatest } from 'redux-saga/effects';
import { httpDelete, httpGet, httpPatch, httpPost, httpPut } from '@core/http/requests';
import {
  CREATE_ONBOARDING_TEMPLATE_ERROR,
  CREATE_ONBOARDING_TEMPLATE_REQUEST,
  CREATE_ONBOARDING_TEMPLATE_SUCCESS,
  DELETE_GROUPS_FROM_TEMPLATE_ERROR,
  DELETE_GROUPS_FROM_TEMPLATE_REQUEST,
  DELETE_GROUPS_FROM_TEMPLATE_SUCCESS,
  DELETE_ONBOARDING_TEMPLATE_ERROR,
  DELETE_ONBOARDING_TEMPLATE_REQUEST,
  DELETE_ONBOARDING_TEMPLATE_SUCCESS,
  DELETE_ROLES_FROM_TEMPLATE_ERROR,
  DELETE_ROLES_FROM_TEMPLATE_REQUEST,
  DELETE_ROLES_FROM_TEMPLATE_SUCCESS,
  GET_ONBOARDING_TEMPLATE_BY_ID_ERROR,
  GET_ONBOARDING_TEMPLATE_BY_ID_REQUEST,
  GET_ONBOARDING_TEMPLATE_BY_ID_SUCCESS,
  GET_ONBOARDING_TEMPLATES_LIST_ERROR,
  GET_ONBOARDING_TEMPLATES_LIST_REQUEST,
  GET_ONBOARDING_TEMPLATES_LIST_SUCCESS,
  UPDATE_ONBOARDING_TEMPLATE_ERROR,
  UPDATE_ONBOARDING_TEMPLATE_GROUPS_ERROR,
  UPDATE_ONBOARDING_TEMPLATE_GROUPS_REQUEST,
  UPDATE_ONBOARDING_TEMPLATE_GROUPS_SUCCESS,
  UPDATE_ONBOARDING_TEMPLATE_REQUEST,
  UPDATE_ONBOARDING_TEMPLATE_ROLES_ERROR,
  UPDATE_ONBOARDING_TEMPLATE_ROLES_REQUEST,
  UPDATE_ONBOARDING_TEMPLATE_ROLES_SUCCESS,
  UPDATE_ONBOARDING_TEMPLATE_SUCCESS,
} from './constants';
import { USER_LOGOUT_REQUEST } from '../identity/store/constants';
import { ERROR_NOTIFICATION } from '../notifications/store/reducers';
import {
  ICreateOnboardingTemplate,
  IDeleteGroupsFromTemplate,
  IDeleteRolesFromTemplate,
  IUpdateOnboardingTemplate,
  IUpdateOnboardingTemplateGroups,
  IUpdateOnboardingTemplateRoles,
} from './actions';

function* getOnboardingTemplatesList(action: { cb: any; type: any }): any {
  try {
    const res = yield call(
      async () => await httpGet('IdentityModule/v1.0/users/onboarding-templates'),
    );
    yield put({ type: GET_ONBOARDING_TEMPLATES_LIST_SUCCESS, results: res.data });

    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_ONBOARDING_TEMPLATES_LIST_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* getOnboardingTemplateById(action: {
  params: { templateId: string };
  cb: any;
  type: any;
}): any {
  try {
    const res = yield call(
      async () =>
        await httpGet(
          `IdentityModule/v1.0/users/onboarding-templates/byId?id=${action.params.templateId}`,
        ),
    );
    yield put({ type: GET_ONBOARDING_TEMPLATE_BY_ID_SUCCESS, results: res.data });
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_ONBOARDING_TEMPLATE_BY_ID_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* createOnboardingTemplate(action: {
  params: ICreateOnboardingTemplate;
  cb: any;
  type: any;
}): any {
  try {
    const { name, description } = action.params;

    const payload = {
      name,
      description,
    };

    const res = yield call(
      async () => await httpPost(`IdentityModule/v1.0/users/onboarding-templates`, payload),
    );
    yield put({ type: CREATE_ONBOARDING_TEMPLATE_SUCCESS, results: res.data });
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CREATE_ONBOARDING_TEMPLATE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* updateOnboardingTemplate(action: {
  params: IUpdateOnboardingTemplate;
  cb: any;
  type: any;
}): any {
  try {
    const { name, description, templateId } = action.params;

    const payload = {
      name,
      description,
    };

    const res = yield call(
      async () =>
        await httpPut(`IdentityModule/v1.0/users/onboarding-templates/${templateId}`, payload),
    );

    yield put({ type: UPDATE_ONBOARDING_TEMPLATE_SUCCESS, results: res.data });

    if (action.cb) {
      yield call(action.cb, res.data);
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: UPDATE_ONBOARDING_TEMPLATE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* deleteRolesFromTemplate(action: {
  params: IDeleteRolesFromTemplate;
  cb: any;
  type: any;
}): any {
  try {
    const { templateId, roleIds } = action.params;

    const payload = {
      roleIds,
    };

    const res = yield call(
      async () =>
        await httpDelete(
          `IdentityModule/v1.0/users/onboarding-templates/${templateId}/roles`,
          payload,
        ),
    );
    yield put({ type: DELETE_ROLES_FROM_TEMPLATE_SUCCESS, results: res.data });
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: DELETE_ROLES_FROM_TEMPLATE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* deleteGroupsFromTemplate(action: {
  params: IDeleteGroupsFromTemplate;
  cb: any;
  type: any;
}): any {
  try {
    const { templateId, groupIds } = action.params;

    const payload = {
      groupIds,
    };

    const res = yield call(
      async () =>
        await httpDelete(
          `IdentityModule/v1.0/users/onboarding-templates/${templateId}/groups`,
          payload,
        ),
    );
    yield put({ type: DELETE_GROUPS_FROM_TEMPLATE_SUCCESS, results: res.data });
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: DELETE_GROUPS_FROM_TEMPLATE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* deleteOnboardingTemplate(action: {
  params: { templateId: string };
  cb: any;
  type: any;
}): any {
  try {
    const { templateId } = action.params;

    const res = yield call(
      async () => await httpDelete(`IdentityModule/v1.0/users/onboarding-templates/${templateId}`),
    );
    yield put({ type: DELETE_ONBOARDING_TEMPLATE_SUCCESS, results: res.data });
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: DELETE_ONBOARDING_TEMPLATE_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* updateOnboardingTemplateRoles(action: {
  params: IUpdateOnboardingTemplateRoles;
  cb: any;
  type: any;
}): any {
  try {
    const { templateId, roleIds } = action.params;

    const res = yield call(
      async () =>
        await httpPatch(`IdentityModule/v1.0/users/onboarding-templates/${templateId}/roles`, {
          roleIds,
        }),
    );
    yield put({ type: UPDATE_ONBOARDING_TEMPLATE_ROLES_SUCCESS, results: res.data });
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: UPDATE_ONBOARDING_TEMPLATE_ROLES_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* updateOnboardingTemplateGroups(action: {
  params: IUpdateOnboardingTemplateGroups;
  cb: any;
  type: any;
}): any {
  try {
    const { templateId, groupIds } = action.params;

    const res = yield call(
      async () =>
        await httpPatch(`IdentityModule/v1.0/users/onboarding-templates/${templateId}/groups`, {
          groupIds,
        }),
    );
    yield put({ type: UPDATE_ONBOARDING_TEMPLATE_GROUPS_SUCCESS, results: res.data });
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
  } catch (e: any) {
    if (action.cb) {
      yield call(action.cb, false);
    }
    const error = e.response ? e.response.data : undefined;
    yield put({ type: UPDATE_ONBOARDING_TEMPLATE_GROUPS_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* rootSaga() {
  yield takeLatest(GET_ONBOARDING_TEMPLATES_LIST_REQUEST, getOnboardingTemplatesList);
  yield takeLatest(GET_ONBOARDING_TEMPLATE_BY_ID_REQUEST, getOnboardingTemplateById);
  yield takeLatest(CREATE_ONBOARDING_TEMPLATE_REQUEST, createOnboardingTemplate);
  yield takeLatest(DELETE_ONBOARDING_TEMPLATE_REQUEST, deleteOnboardingTemplate);
  yield takeLatest(UPDATE_ONBOARDING_TEMPLATE_REQUEST, updateOnboardingTemplate);
  yield takeLatest(UPDATE_ONBOARDING_TEMPLATE_ROLES_REQUEST, updateOnboardingTemplateRoles);
  yield takeLatest(UPDATE_ONBOARDING_TEMPLATE_GROUPS_REQUEST, updateOnboardingTemplateGroups);
  yield takeLatest(DELETE_ROLES_FROM_TEMPLATE_REQUEST, deleteRolesFromTemplate);
  yield takeLatest(DELETE_GROUPS_FROM_TEMPLATE_REQUEST, deleteGroupsFromTemplate);
}

export default rootSaga;
