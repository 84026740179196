import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Badge, Checkbox, Col, Empty, Row, Tag, Tooltip } from 'antd';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { getColorForDSRecordStatus } from '@legacy/modules/CrmModule/containers/DataSet/helpers';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import './styles.scss';
import { getCustomRecordTitle } from './utils';

interface Props {
  multipleSelect?: boolean;
  selectedRecords: DbRecordEntityTransform[];
  disabledRecords: DbRecordEntityTransform[];
  allRecords: DbRecordEntityTransform[];
  onRecordSelect: Function;
  recordDisabledLabel?: string;
  customRightElement?: JSX.Element;
  highlightSelectedRecord?: boolean; // Use this when you want to have static record list on the left
  customRecordRowTitleElements?: string[]; // pass 'title', 'recordNumber' or specific record property 'DiscountValue'
  hideSelectedRecords?: boolean; // We use this when showing records in dual-pane association mode
  showDSStatusTag?: boolean; // Show DS_Status property as a tag in the record row
  showRecordStageTag?: boolean; // Show stage for record
}

const RecordList: FC<Props> = (props: Props) => {
  const {
    allRecords,
    multipleSelect,
    onRecordSelect,
    selectedRecords,
    disabledRecords,
    recordDisabledLabel,
    highlightSelectedRecord,
    customRightElement,
    customRecordRowTitleElements,
    showDSStatusTag,
    showRecordStageTag,
  } = props;

  const isRecordSelected = (record: DbRecordEntityTransform) => {
    return selectedRecords?.find((rec: DbRecordEntityTransform) => rec?.id === record?.id)
      ? true
      : false;
  };

  const isRecordDisabled = (record: DbRecordEntityTransform) => {
    return disabledRecords?.find((rec: DbRecordEntityTransform) => rec?.id === record?.id)
      ? true
      : false;
  };

  const renderRightElement = (record: DbRecordEntityTransform) => {
    // Show Custom element if single record select mode
    if (customRightElement) {
      return customRightElement;
    }
    // Multiple select mode
    else if (multipleSelect) {
      return (
        <Checkbox
          key={record.id}
          checked={isRecordSelected(record) || isRecordDisabled(record)}
          disabled={isRecordDisabled(record)}
        />
      );
    }
    // Single select mode
    else {
      if (isRecordDisabled(record) && recordDisabledLabel) {
        return recordDisabledLabel;
      } else {
        return <></>;
      }
    }
  };

  const renderCustomRecordTitle = (record: DbRecordEntityTransform) => {
    return getCustomRecordTitle(record, customRecordRowTitleElements);
  };

  const sanitizeForMobile = (text: string) => {
    if (text.length > 32 && isMobile) {
      return text.substring(0, 31) + ' ...';
    } else if (text.length > 40 && !isMobile) {
      return (
        <Tooltip title={text} mouseEnterDelay={1}>
          <span>{text.substring(0, 40) + ' ...'}</span>
        </Tooltip>
      );
    } else {
      return text;
    }
  };

  const renderDSStatusTag = (record: DbRecordEntityTransform) => {
    const DS_Status = getProperty(record, 'DS_Status') || 'TODO';

    if (DS_Status) {
      return (
        <Tag
          style={{ marginLeft: 8, padding: '0px 5px', fontSize: '0.9em', lineHeight: '16px' }}
          color={
            ['TO_DO', 'TODO'].includes(DS_Status) ? undefined : getColorForDSRecordStatus(DS_Status)
          }
        >
          {toSentenceCase(DS_Status)}
        </Tag>
      );
    } else {
      return <></>;
    }
  };

  const renderRecordStageTag = (record: DbRecordEntityTransform) => {
    const stage = record.stage;
    const getColorForStage = (record: DbRecordEntityTransform) => {
      if (stage?.isSuccess && !stage.isFail) {
        return 'green';
      } else if (!stage?.isSuccess && stage?.isFail) {
        return 'red';
      } else if (stage?.position === 1) {
        return 'purple';
      } else if (stage?.position === 2) {
        return 'orange';
      } else {
        return 'blue';
      }
    };

    if (record && record?.stage) {
      return (
        <Tag
          style={{ margin: 0, padding: '0px 5px', fontSize: '0.8em', lineHeight: '16px' }}
          color={getColorForStage(record)}
        >
          {toSentenceCase(record.stage.name)}
        </Tag>
      );
    } else {
      return <></>;
    }
  };

  const renderRecordList = () => {
    let filteredRecords: DbRecordEntityTransform[] = Object.assign(allRecords);

    // If hideSelectedRecords === true, don't show records that are already selected (on the right pane)
    if (selectedRecords.length > 0 && props.hideSelectedRecords) {
      const selectedRecordIds = selectedRecords.map((rec: any) => rec.id);
      filteredRecords = filteredRecords.filter(
        (rec: DbRecordEntityTransform) => !selectedRecordIds.includes(rec.id),
      );
    }

    console.log('debug: CRE', customRightElement)

    return filteredRecords.map((record: DbRecordEntityTransform) => (
      <Col
        key={record.id}
        span={24}
        className={`recordListRow ${
          (isRecordSelected(record) && !isRecordDisabled(record)) ||
          (isRecordSelected(record) && highlightSelectedRecord)
            ? 'selected'
            : ''
        } ${isRecordDisabled(record) ? 'disabled' : ''}`}
        onClick={() => {
          onRecordSelect(record);
        }}
      >

        <Row justify="space-between">
          <Col>
            <span style={{ overflowX: 'hidden', fontSize: '0.8em' }}>
              {customRecordRowTitleElements
                ? sanitizeForMobile(renderCustomRecordTitle(record))
                : sanitizeForMobile(record.title!)}
            </span>

            {/* DS_Status Tag */}
            {showDSStatusTag ? renderDSStatusTag(record) : <></>}
          </Col>

          {/* RIGHT */}
          <Col style={{ textAlign: 'right' }} key={record.id}>
            
            <span>{renderRightElement(record)}</span>
            {/* Call Count */}

            <div style={{ display: 'flex' }}>
              {!!getProperty(record, 'CallCount') && !multipleSelect ? (
                <Tag color="red" style={{ padding: '3px 3px', lineHeight: 0 }}>
                  <i style={{ fontSize: '0.8em', marginRight:2 }} className="bi bi-telephone-fill" />
                  <span>{getProperty(record, 'CallCount')}</span>
                </Tag>
              ) : (
                // <Badge style={{ marginLeft: 8 }} count={getProperty(record, 'CallCount') || 0} />
                <></>
              )}
              {showRecordStageTag ? renderRecordStageTag(record) : <></>}
            </div>
          </Col>
        </Row>
      </Col>
    ));
  };

  return (
    <Row>
      {allRecords.length > 0 ? (
        renderRecordList()
      ) : (
        <Col span={24} style={{ textAlign: 'center' }}>
          <Empty
            style={{ marginTop: '5vh', marginBottom: '5vh' }}
            description="No records to show"
          />
        </Col>
      )}
    </Row>
  );
};

export default RecordList;
