import { Card, Col, Input, Row, Switch, Typography } from 'antd';
import * as React from 'react';
import { FC, useContext } from 'react';
import { orderBuilderContext } from '../index';
import {
  SET_IS_LOA_ACCEPTED,
  SET_PHONE_NUM_2,
  SET_PHONE_NUM_3,
  SET_PHONE_PANEL_REF,
} from '../store/constants';
import { InputGroup } from '@blueprintjs/core';

const PhoneTransferPanel: FC = () => {
  const { state, dispatch } = useContext(orderBuilderContext);
  const phoneTransferPanelRef = React.useRef(null);

  React.useEffect(() => {
    if (phoneTransferPanelRef.current) {
      dispatch({ type: SET_PHONE_PANEL_REF, payload: phoneTransferPanelRef });
    }
  }, [phoneTransferPanelRef]);

  return (
    <Card
      style={{ margin: 5 }}
      title={
        <Row ref={phoneTransferPanelRef} align="middle">
          <Col span={12}>
            <div
              className="moduleEntityIcon"
              style={{ backgroundColor: '#5678ed', padding: '2px 8px', display: 'inline-block' }}
            >
              <i className="bi bi-telephone-fill" />
            </div>
            <Typography.Title
              level={5}
              style={{ fontWeight: 500, margin: 0, display: 'inline-block' }}
            >
              Phone Transfer
            </Typography.Title>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            <Switch
              style={{ marginTop: 3 }}
              checked={state.LOAAccepted}
              checkedChildren="LOA"
              unCheckedChildren="LOA"
              onChange={() =>
                dispatch({
                  type: SET_IS_LOA_ACCEPTED,
                  payload: !state.LOAAccepted,
                })
              }
            />
          </Col>
        </Row>
      }
    >
      <Row>
        {/* Input fields */}
        <Col span={12}>
          <Input.Group size="large">
            <Row gutter={8}>
              <Col span={5}>
                <InputGroup value={state.phoneNum1} />
              </Col>
              <Col span={10}>
                <InputGroup
                  placeholder="0700"
                  value={state.phoneNum2}
                  onChange={(e: any) =>
                    dispatch({
                      type: SET_PHONE_NUM_2,
                      payload: e.target.value,
                    })
                  }
                />
              </Col>
              <Col span={8}>
                <InputGroup
                  placeholder="1234567"
                  value={state.phoneNum3}
                  onChange={(e: any) =>
                    dispatch({
                      type: SET_PHONE_NUM_3,
                      payload: e.target.value,
                    })
                  }
                />
              </Col>
            </Row>
          </Input.Group>
        </Col>
      </Row>
    </Card>
  );
};

export default PhoneTransferPanel;
