import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { FC } from 'react';
import NetworkVoiceConfiguration from '../NetworkVoiceConfiguration';
import { getAtaFromOrderItem } from '../helpers';
import ActivatedATA from './ActivatedATA';
import DeactivatedATA from './DeactivatedATA';

interface INetworkAddonVoiceProductProps {
  record: DbRecordEntityTransform; // OrderItem record
  orderRecord: DbRecordEntityTransform; // Order record
  onUpdated?: () => void;
  disabled?: boolean;
}

export const NetworkAddonVoiceProduct: FC<INetworkAddonVoiceProductProps> = (props) => {
  const { record, onUpdated, orderRecord, disabled } = props;

  const ata = getAtaFromOrderItem(record);

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        {ata ? (
          <ActivatedATA
            ataRecord={ata}
            OIRecord={record}
            onUpdated={onUpdated}
            disabled={disabled}
          />
        ) : (
          <DeactivatedATA orderItem={record} onUpdated={onUpdated} disabled={disabled} />
        )}
      </div>

      <NetworkVoiceConfiguration
        record={record}
        onUpdated={onUpdated}
        orderRecord={orderRecord}
        disabled={disabled}
      />
    </>
  );
};
