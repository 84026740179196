import { useReducer } from 'react';
import { AFPApprovalReducer } from './reducer';
import { getInitialState } from './reducer/getInitialState';
import { actions } from './actions';
import { bindActions } from './actions/utils';

export const useAFPApprovalReducer = () => {
  const [state, dispatch] = useReducer(AFPApprovalReducer, getInitialState());

  const boundActions = bindActions(dispatch, actions);

  return { state, actions: boundActions };
};
