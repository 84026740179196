import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';

export const getAllSchemaAssociationsMatchingEntityName = (
  associations: SchemaAssociationEntity[],
  entityName: string,
): SchemaAssociationEntity[] => {
  return (
    associations?.filter(
      (association: SchemaAssociationEntity) => association.childSchema?.entityName === entityName,
    ) || []
  );
};
