import React from 'react';
import { Col, Empty, Row } from 'antd';

import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import { UtilizationData } from '../../../context/types';
import { WorkOrder } from './WorkOrder';
import { WorkOrderHeader } from './WorkOrderHeader';
import { Callout, Section, Tag } from '@blueprintjs/core';
import { useCalendarState } from '../../../context';
import dayjs from 'dayjs';
// import RecordCard from "../../../../../../core/records/components/RecordCard";

type WorkOrderListProps = {
  title: string;
  records: DbRecordEntityTransform[];
  viewItemDetails: (record: DbRecordEntityTransform) => void;
  utilization?: UtilizationData;
  itemWidth?: 'full' | 'auto';
  compact?: boolean;
  weekend?: boolean;
  hideWorkOrders?: boolean;
};

export const WorkOrderList: React.FC<WorkOrderListProps> = ({
  title,
  records,
  utilization,
  viewItemDetails,
  compact,
  weekend,
  hideWorkOrders,
}) => {
  const calendarState = useCalendarState();

  return (
    <Section
      style={{ height: '100%', borderTop: 0 }}
      title={
        !hideWorkOrders ? (
          <span>
            {!hideWorkOrders &&
              dayjs(calendarState.appointments.selectedDate).format('DD MMMM YYYY')}
          </span>
        ) : undefined
      }
      rightElement={<span>{!hideWorkOrders && 'Work Orders'}</span>}
    >
      <Row style={{ margin: 10 }}>
        {hideWorkOrders && (
          <Col span={24}>
            <Callout title="Overview Mode" intent="warning" icon="info-sign">
              <span>
                You are in schedule overview mode. This will show you appointment utilization
                overview for a specific month. It is not possible to view work orders in overview
                mode. In order to see individual work orders, choose individual overviews and select
                a day.
              </span>
            </Callout>
          </Col>
        )}

        {records.length === 0 && !hideWorkOrders && (
          <Col span={24}>
            <Empty style={{ marginTop: 50 }} description="No Work Orders" />
          </Col>
        )}

        {records.length > 0 && !hideWorkOrders && (
          <Col span={24} style={{ marginBottom: 10 }}>
            <Tag fill minimal style={{ padding: '5px 6px' }}>
              <WorkOrderHeader title={title} compact={compact} utilization={utilization} />
            </Tag>
          </Col>
        )}

        {records.length > 0 &&
          !hideWorkOrders &&
          records.map((record) => (
            <WorkOrder
              key={record.id}
              record={record}
              onClick={() => viewItemDetails(record)}
              compact={compact}
              weekend={weekend}
            />
          ))}
      </Row>
    </Section>
  );
};
