import { Checkbox, Classes, Icon, Popover } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React from 'react';

type Option = { label: string; value: string };
interface Props {
  options: Option[];
  onChange: (value: string) => void;
  selectedValues: string[];
  disabled?: boolean;
}

const TableColumnFilter: React.FC<Props> = (props: Props) => {
  const { options, onChange, selectedValues, disabled } = props;

  const isFilterActive = selectedValues.length > 0;

  return (
    <Popover
      popoverClassName={Classes.POPOVER_CONTENT_SIZING}
      position="bottom"
      disabled={disabled}
      content={
        <Row>
          <Col span={24}>
            {options.map((option: Option, i: number) => {
              const checked = selectedValues.includes(option.value);
              return (
                <Checkbox
                  checked={checked}
                  label={option.label}
                  value={option.value}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
                  key={i}
                  style={{ marginBottom: i + 1 === options.length ? 0 : 8 }}
                />
              );
            })}
          </Col>
        </Row>
      }
    >
      <Icon
        icon="filter"
        size={11}
        style={{
          verticalAlign: 'baseline',
          cursor: 'pointer',
          opacity: 1,
          color: isFilterActive ? '#1577ff' : 'grey',
        }}
      />
    </Popover>
  );
};
export default TableColumnFilter;
