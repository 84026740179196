import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { getONTsFromOrderItem, getRoutersFromOrderItem } from '../helpers';
import ActivatedONT from '../ONT/ActivatedONT';
import DeactivatedONT from '../ONT/DeactivatedONT';
import ActivatedRouter from '../Router/ActivatedRouter';
import DeactivatedRouter from '../Router/DeactivatedRouter';

interface INetworkBaseProductProps {
  record: DbRecordEntityTransform; // OrderItem
  onUpdated?: () => void;
  staticIpRecord?: DbRecordEntityTransform;
  disabled?: boolean;
}

export const NetworkBaseProduct: FC<INetworkBaseProductProps> = (props) => {
  const { record, onUpdated, staticIpRecord, disabled } = props;

  const ONTs = getONTsFromOrderItem(record);
  const Routers = getRoutersFromOrderItem(record);

  return (
    <Row>
      {/* Associated ONTs */}
      {ONTs.length > 0 ? (
        <Col span={24}>
          <ActivatedONT
            ONTRecord={ONTs[0]}
            OIRecord={record}
            onUpdated={onUpdated}
            disabled={disabled}
          />
        </Col>
      ) : (
        <Col span={24}>
          <DeactivatedONT orderItem={record} onUpdated={onUpdated} disabled={disabled} />
        </Col>
      )}

      {/* Associated Routers */}
      {Routers.length > 0 ? (
        <Col span={24} style={{ marginTop: 12 }}>
          <ActivatedRouter
            routerRecord={Routers[0]}
            orderItem={record}
            onUpdated={onUpdated}
            staticIpRecord={staticIpRecord}
            disabled={disabled}
          />
        </Col>
      ) : (
        <Col span={24} style={{ marginTop: 12 }}>
          <DeactivatedRouter orderItem={record} onUpdated={onUpdated} disabled={disabled} />
        </Col>
      )}
    </Row>
  );
};
