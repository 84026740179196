import React, { FC, useEffect, useRef, useState } from 'react';
import { Col, Row, Space } from 'antd';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { Button, InputGroup } from '@blueprintjs/core';
import { isMobile } from 'react-device-detect';

interface Props {}

interface ITableData {
  key: string;
  name: string;
  description: string;
}

const CaseManagementTemplates: FC = (props: Props) => {
  const tableRef = useRef<any>();
  const [selectedTemplate, setSelectedTemplate] = useState<any>(undefined);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [templatesList, setTemplatesList] = useState<any[]>([]);
  const [pageSize, setPageSize] = useState<number>(50);
  const [searchKey, setSearchKey] = useState<string>('');

  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };
  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedTemplate, tableRef, tableSlice]);

  return (
    <>
      <Row>
        <Col span={16}>
          <h1 style={{ margin: 0 }}>Templates</h1>
        </Col>
        <Col span={8} style={{ textAlign: 'right' }}>
          <Space>
            <InputGroup
              type="search"
              placeholder="Search Templates"
              // onChange={onSearch}
              intent={searchKey.length > 0 ? 'primary' : 'none'}
              leftIcon="search"
              style={{ width: isMobile ? '100%' : 220 }}
            />
            <Button
              icon="plus"
              intent="success"
              // onClick={showCreateUserForm}
            >
              Create Template
            </Button>
          </Space>
        </Col>
      </Row>
      {/* Table */}
      <Row
        style={{
          marginTop: 30,
          height: 'calc(100vh - 180px)',
          background: '#fff',
        }}
      >
        <Col span={selectedTemplate ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
          {/* Table */}
          <Table2
            ref={tableRef}
            numRows={tableSlice.length}
            defaultRowHeight={30}
            // onSelection={onSelect}
            // selectedRegions={selectedRegions}
            enableMultipleSelection={false}
            enableRowHeader={false}
            renderMode={RenderMode.NONE}
            forceRerenderOnSelectionChange={false}
            cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
            selectionModes={SelectionModes.ROWS_AND_CELLS}
            columnWidths={[getColumnWidthByPercentage(20), getColumnWidthByPercentage(80)]}
          >
            <Column
              key="name"
              name="Name"
              cellRenderer={(rowIndex: number) => (
                <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
              )}
            />
            <Column
              key="description"
              name="Description"
              cellRenderer={(rowIndex: number) => (
                <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
              )}
            />
          </Table2>
        </Col>
      </Row>

      {/* Pagination */}
      <Row style={{ background: 'white' }}>
        <div style={{ padding: '10px 0' }}>
          <BlueprintNavigation
            totalCount={templatesList.length}
            currentPage={currentPage}
            pageSize={pageSize}
            onPaginate={setCurrentPage}
            disabled={searchKey.length > 0}
          />
        </div>
      </Row>
    </>
  );
};

export default CaseManagementTemplates;
