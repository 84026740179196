import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Col, notification, Row } from 'antd';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  RelationTypeEnum,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import Flow from '../../../../../../../core/components/Flow/Flow';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';


import ProjectModuleIntersectingPIA from '../../../../components/ProjectModuleIntersectingPIA';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { OPENREACH_NERWORK_ADJUSTMENT } = SchemaModuleEntityTypeEnums;

type RecordFilesProps = {
  record: DbRecordEntityTransform;
  flowVisible: boolean;
  toggleFlowVisibility: () => void;
}

export const RecordFiles = ({ record, flowVisible, toggleFlowVisibility }: RecordFilesProps) => {
  const [createdNetworkAdjustmentId, setCreatedNetworkAdjustmentId] = useState<string | undefined>(
    undefined,
  );
  const [showNANotification, setShowNANotification] = useState<boolean>(false);
  const [api, contextHolder] = notification.useNotification();

  const associationsForPIA = () => {
    if (record) {
      return [record].map((rec: any) => ({
        entity: rec.entity,
        recordId: rec.id,
        relationType: RelationTypeEnum.CHILD,
      }));
    } else {
      return [];
    }
  };

  // PIA Order - When Flow Step 1 finishes, set the created ORNA id to the state.
  const fetchCreatedNetworkAdjustmentId = (recordId: string) => {
    if (recordId) {
      setCreatedNetworkAdjustmentId(recordId);
    }
  };

  // PIA Order - Callback for Flow component - Step 2
  const PIAOrderCompleted = () => {
    setShowNANotification(true);
  };

  // PIA Order - When Flow Step 2 finished and there is ORNA id, show the notification
  useEffect(() => {
    if (createdNetworkAdjustmentId && showNANotification) {
      showNotification(createdNetworkAdjustmentId);
    }
  }, [showNANotification]);

  // PIA Order - Show Notification when the Flow ends
  const showNotification = (recordId: string) => {
    api['success']({
      key: recordId,
      message: 'Network Adjustment Created',
      duration: 5,
      onClose: () => {
        setCreatedNetworkAdjustmentId(undefined);
        setShowNANotification(false);
      },
      description: (
        <Link target="_blank" to={`/${PROJECT_MODULE}/${OPENREACH_NERWORK_ADJUSTMENT}/${recordId}`}>
          Go to Record
        </Link>
      ),
    });
  };

  return (
    <>
      {contextHolder}
      <Row style={{ marginTop: 20 }}>
        <Col span={24}>
          <AssociationDataTable
            thumbnailSize={12}
            title="Files"
            record={record!}
            showFileCategoryForType="DEFAULT"
            moduleName="SchemaModule"
            entityName="File"
            location="sidebar"
            collapsedByDefault
          />
        </Col>
        {/* If record type is CABLE show PIA Image Uploader  */}
        {record?.type === 'CABLE' && (
          <Col span={24}>
            <ProjectModuleIntersectingPIA record={record} />
          </Col>
        )}

        {/* If record type is DUCT (Type 2-SDuct) show PIA Image Uploader  */}
        {record?.type === 'DUCT' && getProperty(record, 'DuctType') === '2' && (
          <Col span={24}>
            <ProjectModuleIntersectingPIA record={record} />
          </Col>
        )}
      </Row>
      <Flow
        launchInterface="NONE"
        flowVisible={flowVisible}
        launchInterfaceTitle=""
        toggleFlowVisibility={toggleFlowVisibility}
        flowSteps={[
          // 1. Create Network Adjustment
          {
            flowType: 'RECORD',
            title: 'Create Network Adjustment',
            moduleName: 'ProjectModule',
            entityName: 'OpenreachNetworkAdjustment',
            additionalAssociations: associationsForPIA(),
            runOnSubmit: fetchCreatedNetworkAdjustmentId,
          },
          // 2. Associate files to the newly created record
          {
            flowType: 'ASSOCIATION',
            title: 'Upload Files',
            moduleName: 'SchemaModule',
            entityName: 'File',
            recordType: 'DEFAULT',
            associatedFilesView: 'thumbnails',
            relateRecordFromStep: [1],
            runOnSubmit: PIAOrderCompleted,
            showFileCategoryForType: 'DEFAULT',
          },
        ]}
      />
    </>
  );
};