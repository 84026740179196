import { CloseOutlined, DeleteOutlined, UserOutlined } from '@ant-design/icons';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  Button,
  Calendar,
  Card,
  Col,
  Descriptions,
  Empty,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Tag,
} from 'antd';
import { FC, useEffect, useState } from 'react';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import { connect } from 'react-redux';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '@legacy/core/schemas/store/actions';
import {
  IGetRecordAssociations,
  getRecordAssociationsRequest,
} from '@legacy/core/recordsAssociations/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import dayjs, { Dayjs } from 'dayjs';
import RecordCard from '@legacy/core/records/components/RecordCard';
interface Props {
  getSchema: (payload: ISchemaByModuleAndEntity, cb?: any) => void;
  searchRecords: (params: ISearchRecords, cb?: any) => void;
  getAssociations: (params: IGetRecordAssociations, cb?: any) => void;
  schemaReducer: any;
  userReducer: any;
}

const { CRM_MODULE } = SchemaModuleTypeEnums;

const AppointmentsCalendar: FC<Props> = (props: Props) => {
  const { schemaReducer, getSchema, searchRecords, getAssociations, userReducer } = props;

  // state -> UI
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  const [isLoadingTerritories, setIsLoadingTerritories] = useState<boolean>(false);
  const [isLoadingAgents, setIsLoadingAgents] = useState<boolean>(false);
  const [isLoadingAppointments, setIsLoadingAppointments] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string | undefined>(undefined);

  // state -> schemas
  const [territorySchema, setTerritorySchema] = useState<SchemaEntity | undefined>(undefined);
  const [appointmentSchema, setAppointmentSchema] = useState<SchemaEntity | undefined>(undefined);

  // state -> records
  const [territories, setTerritories] = useState<DbRecordEntityTransform[]>([]);
  const [agents, setAgents] = useState<DbRecordEntityTransform[]>([]);
  const [appointments, setAppointments] = useState<DbRecordEntityTransform[]>([]);
  const [selectedTerritory, setSelectedTerritory] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [selectedAgent, setSelectedAgent] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  // On Component mount, fetch territory schema
  useEffect(() => {
    if (!territorySchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        CRM_MODULE,
        'Territory',
      );

      if (shortlistSchema) {
        setTerritorySchema(shortlistSchema);
      } else {
        getSchema(
          {
            moduleName: CRM_MODULE,
            entityName: 'SalesTerritory',
          },
          (schema: SchemaEntity) => {
            setTerritorySchema(schema);
          },
        );
      }
    }
    if (!appointmentSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        CRM_MODULE,
        'Appointment',
      );

      if (shortlistSchema) {
        setAppointmentSchema(shortlistSchema);
      } else {
        getSchema(
          {
            moduleName: CRM_MODULE,
            entityName: 'Appointment',
          },
          (schema: SchemaEntity) => {
            setAppointmentSchema(schema);
          },
        );
      }
    }
  }, []);

  // When territory schema is found, fetch territories
  useEffect(() => {
    if (territorySchema) {
      setIsLoadingTerritories(true);
      searchRecords(
        {
          schema: territorySchema,
          searchQuery: {
            terms: '',
            schemas: territorySchema?.id,
            pageable: {
              size: 500,
            },
            boolean: {},
          },
        },
        (searchResults: any) => {
          setIsLoadingTerritories(false);
          if (searchResults?.data?.data?.length > 0) {
            // sort territories by title
            const sortedTerritories = searchResults.data.data.sort((a: any, b: any) =>
              a.title > b.title ? 1 : b.title > a.title ? -1 : 0,
            );

            setTerritories(sortedTerritories);
          }
        },
      );
    }
  }, [territorySchema]);

  // When user is selected, fetch appointments
  useEffect(() => {
    if (appointmentSchema && selectedAgent) {
      const userId = getProperty(selectedAgent, 'UserId');
      getAppointments([userId]);
    }
  }, [selectedAgent]);

  // On selected territory, fetch agents
  useEffect(() => {
    if (selectedTerritory && territorySchema) {
      setIsLoadingAgents(true);
      getAssociations(
        {
          recordId: selectedTerritory.id,
          key: 'User',
          schema: territorySchema,
          entities: ['User'],
        },
        (associations: any) => {
          setIsLoadingAgents(false);

          const ownUserId = userReducer?.user?.id;
          const ownName = `${userReducer?.user?.firstname} ${userReducer?.user?.lastname}`;
          const users = associations?.results?.User?.dbRecords;

          if (users && users?.length! > 0) {
            // Get all appointments for all users in this territory
            const userIds = users.map((user: any) => getProperty(user, 'UserId'));
            getAppointments(userIds);

            const sortedUsers = users.sort((a: any, b: any) =>
              a.title > b.title ? 1 : b.title > a.title ? -1 : 0,
            );
            setAgents([{ title: ownName, properties: { UserId: ownUserId } }, ...sortedUsers]);
          }
        },
      );
    }
  }, [selectedTerritory]);

  // On selected date, show sidebar if appointments exist
  useEffect(() => {
    const appointmentsForDate = appointments.filter((appointment: DbRecordEntityTransform) => {
      return dayjs(getProperty(appointment, 'Date')).format('DD/MM/YYYY') === selectedDate;
    });

    if (selectedDate && appointmentsForDate?.length > 0) {
      setIsSidebarVisible(true);
    }
  }, [selectedDate]);

  // Method to fetch appointments
  const getAppointments = (userIds: string[]) => {
    if (appointmentSchema) {
      setIsLoadingAppointments(true);

      let dynamicTerms: string = '';
      if (userIds.length > 1) {
        dynamicTerms = `(${userIds.join(' OR ')})`;
      } else {
        dynamicTerms = `"${userIds[0]}"`;
      }

      searchRecords(
        {
          schema: appointmentSchema,
          searchQuery: {
            terms: dynamicTerms,
            fields: 'createdBy.id',
            schemas: appointmentSchema?.id,
            pageable: {
              size: 500,
            },
            boolean: {},
          },
        },
        (searchResults: any) => {
          setIsLoadingAppointments(false);
          if (searchResults?.data?.data?.length > 0) {
            // check if appointments to date
            const date = selectedDate || dayjs().format('DD/MM/YYYY');

            const appointmentsToDate = searchResults.data.data.filter(
              (appointment: DbRecordEntityTransform) => {
                return dayjs(getProperty(appointment, 'Date')).format('DD/MM/YYYY') === date;
              },
            );

            // open sidebar if there are appointments to date
            if (appointmentsToDate.length > 0) {
              setIsSidebarVisible(true);
              setSelectedDate(date);
            }

            setAppointments(searchResults.data.data);
          }
        },
      );
    }
  };

  // Find selected territory by id and set to state
  const handleTerritorySelect = (id: string) => {
    setSelectedAgent(undefined);
    setAppointments([]);
    if (id && territories.length > 0) {
      const territory = territories.find(
        (territory: DbRecordEntityTransform) => territory.id === id,
      );
      if (territory) {
        setSelectedTerritory(territory);
      }
    }
  };

  // Find selected agent by id and set to state
  const handleAgentSelect = (id: string) => {
    setAppointments([]);
    if (id && agents.length > 0) {
      const agent = agents.find(
        (agent: DbRecordEntityTransform) => getProperty(agent, 'UserId') === id,
      );
      if (agent) {
        setSelectedAgent(agent);
      }
    } else {
      setSelectedAgent(undefined);
    }
  };

  const renderTerritorySelect = () => {
    return (
      <Select
        filterOption={(input: string, option: any) =>
          option.label?.toLowerCase().indexOf(input?.toLowerCase()) > -1
        }
        showSearch
        style={{ width: 200 }}
        placeholder="Select sales territory"
        onChange={handleTerritorySelect}
        value={selectedTerritory?.id}
        disabled={isLoadingTerritories}
        allowClear
      >
        {territories.map((territory: DbRecordEntityTransform) => {
          return (
            <Select.Option key={territory.id} value={territory.id} label={territory.title}>
              {territory.title}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const searchForAllAgents = () => {
    const userIds = agents.map((agent: DbRecordEntityTransform) => getProperty(agent, 'UserId'));
    getAppointments(userIds);
  };

  const renderUserSelect = () => {
    return (
      <Select
        filterOption={(input: string, option: any) =>
          option.label?.toLowerCase().indexOf(input?.toLowerCase()) > -1
        }
        showSearch
        style={{ width: 250 }}
        placeholder="Select user"
        value={getProperty(selectedAgent, 'UserId')}
        onChange={handleAgentSelect}
        onClear={searchForAllAgents}
        disabled={!selectedTerritory || isLoadingAgents}
        allowClear
      >
        {agents.map((agent: DbRecordEntityTransform) => {
          return (
            <Select.Option
              key={getProperty(agent, 'UserId')}
              value={getProperty(agent, 'UserId')}
              label={agent.title}
            >
              {userReducer?.user?.id === getProperty(agent, 'UserId') ? (
                <>
                  <UserOutlined style={{ marginRight: 8 }} />
                  <span>Me ({agent.title})</span>
                </>
              ) : (
                <span>{agent.title}</span>
              )}
            </Select.Option>
          );
        })}
      </Select>
    );
  };

  const dateCellRender = (value: Dayjs) => {
    if (appointments?.length > 0) {
      const appointmentsForDate = appointments.filter((appointment: DbRecordEntityTransform) => {
        return (
          dayjs(getProperty(appointment, 'Date')).format('DD/MM/YYYY') ===
          dayjs(value).format('DD/MM/YYYY')
        );
      });

      if (appointmentsForDate.length > 0) {
        return (
          <Row align="middle" justify="center">
            <Col span={24}>
              <Tag color="green" style={{ width: '100%', marginTop: 5 }}>
                <Row justify="space-between">
                  <Col style={{ fontSize: '0.9em' }}>Appointments</Col>
                  <Col style={{ fontSize: '0.9em' }}>{appointmentsForDate.length}</Col>
                </Row>
              </Tag>
            </Col>
          </Row>
        );
      }
    }
  };

  const renderAppointmentsListForDate = () => {
    const filteredAppointments = appointments.filter((appointment: DbRecordEntityTransform) => {
      return dayjs(getProperty(appointment, 'Date')).format('DD/MM/YYYY') === selectedDate;
    });

    if (filteredAppointments.length > 0) {
      return filteredAppointments.map((appointment: DbRecordEntityTransform) => {
        return (
          <RecordCard
            key={appointment.id}
            record={appointment}
            visibleProperties={['Date', 'Address', 'Contact']}
            showCreatedBy
            hideRecordNumber
            displayQuickView
          />
        );
      });
    } else {
      return (
        <Row>
          <Col span={24} style={{ marginTop: 50 }}>
            <Empty description="No appointments for this date" />
          </Col>
        </Row>
      );
    }
  };

  const clearAll = () => {
    setSelectedTerritory(undefined);
    setSelectedAgent(undefined);
    setAppointments([]);
    // setIsSidebarVisible(false);
  };

  return (
    <Layout style={{ padding: 10 }}>
      <Row gutter={8}>
        <Col xs={24} md={isSidebarVisible ? 18 : 24}>
          <Card
            style={{ height: '110vh' }}
            title="Appointments Overview"
            extra={
              <>
                <Space>
                  {renderTerritorySelect()}
                  {renderUserSelect()}
                  <Button onClick={clearAll}>Clear</Button>
                </Space>
              </>
            }
          >
            <Spin spinning={isLoadingAppointments} size="large">
              <Calendar
                // validRange={[dayjs().subtract(1, 'years'), dayjs().add(3, 'years')]}
                // onPanelChange={this.getMonthlyAppointments}
                cellRender={dateCellRender}
                onSelect={(date: any) => setSelectedDate(dayjs(date).format('DD/MM/YYYY'))}
              />
            </Spin>
          </Card>
        </Col>
        {isSidebarVisible && (
          <Col xs={24} md={6}>
            <Card
              style={{ height: '110vh', overflowY: 'scroll' }}
              title={'Appointments - ' + selectedDate}
              extra={
                <CloseOutlined
                  onClick={() => setIsSidebarVisible(false)}
                  style={{ marginRight: 8 }}
                />
              }
            >
              {renderAppointmentsListForDate()}
            </Card>
          </Col>
        )}
      </Row>
    </Layout>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(AppointmentsCalendar);
