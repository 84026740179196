import {
    DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import RecordHeader from '@legacy/core/records/components/RecordHeader';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import { renderCreateUpdateDetails } from '../../../../../../legacy/components/RecordCreateUpdateDetails';

type FeatureDetailsProps = {
  record: DbRecordEntityTransform
};
export const FeatureDetails = ({ record }: FeatureDetailsProps) => {
  return (
    <>
      <RecordHeader
        record={record}
      >
        <RecordProperties columns={1} record={record} />
        {renderCreateUpdateDetails(record)}
      </RecordHeader>
      &nbsp;
      <AssociationDataTable
        thumbnailSize={3}
        title="Files"
        record={record}
        showFileCategoryForType="DEFAULT"
        moduleName="SchemaModule"
        entityName="File"
        location="sidebar"
      />
    </>
  );
};
