import { Button, Checkbox } from 'antd';

type Filter = {
  text: string;
  value: string;
};

type MultiFilterProps = {
  filters: Filter[];
  selectedKeys: string[];
  setSelectedKeys: (keys: string[]) => void;
  close: () => void;
  dataIndex: string;
  setLineItemFilter: (name: string, value: string[]) => void;
};

export const MultiFilter = (props: MultiFilterProps) => {
  if (props.filters.length === 0) return null;

  return (
    <>
      <ul style={{ listStyleType: 'none', paddingLeft: 10 }}>
        {props.filters.map((filter: { text: string; value: string }) => <li key={filter.value}><Checkbox value={filter.value} checked={props.selectedKeys.includes(filter.value)} onChange={({ target }) => {
          if (target.checked) props.setSelectedKeys([...props.selectedKeys, target.value]);
          else props.setSelectedKeys(props.selectedKeys.filter(v => v !== target.value));
        }}>{filter.text}</Checkbox></li>)}
      </ul>
      <hr style={{ opacity: 0.15 }} />
      <div style={{ display: "flex", flexDirection: 'row', justifyContent: 'space-between', padding: '0 10px 10px 10px' }}>
        <Button
          type="default"
          size="small"
          disabled={props.selectedKeys.length === 0}
          onClick={() => {
            props.setSelectedKeys([]);
            props.setLineItemFilter(props.dataIndex, []);
            props.close();
          }}
        >
          Reset
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            props.setLineItemFilter(props.dataIndex, props.selectedKeys);
            props.close();
          }}
        >
          OK
        </Button>
      </div>
    </>
  ) 
}