import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPut } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultTeam: any;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITeam {
  name: string;
  id: string;
  disabled: boolean;
}

const UpdateTeamSelect: FC<Props> = (props: Props) => {
  const { entity, defaultTeam, sourceRecord, alertMessage, onUpdate } = props;

  const [selectedTeam, setSelectedTeam] = useState<ITeam | undefined>();
  const [isLoadingTeams, setIsLoadingTeams] = useState<boolean>(false);
  const [allTeams, setAllTeams] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    loadAllTeams();
  }, []);

  useEffect(() => {
    if (defaultTeam) {
      setSelectedTeam({
        name: defaultTeam?.name,
        id: defaultTeam?.id,
        disabled: false,
      });
    } else {
      setSelectedTeam(undefined);
    }
  }, [defaultTeam, sourceRecord]);

  const loadAllTeams = async () => {
    setIsLoadingTeams(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/teams?size=10000`);
      const teams = res.data?.data || [];
      setAllTeams(teams);
      setIsLoadingTeams(false);
    } catch (error) {
      setIsLoadingTeams(false);
    }
  };

  // Update Team if different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultTeam?.id) {
      updateSourceRecord(item);
    }
  };

  const updateSourceRecord = async (selectedTeam: ITeam) => {
    setIsUpdating(true);

    // Set this to 2.0 once user endpoints are fixed
    let version = '2.0';
    if (entity === 'users') {
      version = '1.0';
    }

    try {
      await httpPut(`IdentityModule/v${version}/${entity}/${sourceRecord.id}`, {
        teamId: selectedTeam.id === 'no-team' ? null : selectedTeam.id,
      });

      if (selectedTeam.id === 'no-team') {
        setSelectedTeam(undefined);
      } else {
        setSelectedTeam(selectedTeam);
      }

      setIsUpdating(false);
      onUpdate && onUpdate();
      alertMessage({
        body: `Team updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update team. ' + message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderTeam: ItemRenderer<ITeam> = (
    team,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (team.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more teams..."
        />
      );
    } else
      return (
        <MenuItem
          active={team.id === selectedTeam?.id}
          disabled={modifiers.disabled}
          key={team.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={team.name}
        />
      );
  };

  let TEAMS: ITeam[] = allTeams.map((f, index) => ({
    id: f.id,
    name: f.name,
    key: f.id,
    disabled: false,
  }));

  // Filter by search query
  if (searchQuery.length > 0) {
    TEAMS = TEAMS.filter((team) => {
      return team.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  TEAMS = TEAMS.slice(0, 100);

  // Append the Search information if there are more than 100 teams
  if (TEAMS.length === 100) {
    TEAMS.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
    });
  }

  // Append "No Team" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    TEAMS.unshift({
      id: 'no-team',
      name: '(No Team)',
      disabled: false,
    });
  }

  return (
    <Select<ITeam>
      items={TEAMS}
      disabled={isLoadingTeams || allTeams.length === 0}
      itemRenderer={renderTeam}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedTeam ? 'team' : null}
        alignText="left"
        disabled={isLoadingTeams || allTeams.length === 0 || isUpdating}
        loading={isLoadingTeams}
        text={selectedTeam?.name || 'Select team'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateTeamSelect);
