import { AnchorButton } from '@blueprintjs/core';
import { Checkbox, Col, List, Row } from 'antd';
import { FC, ReactChild, forwardRef } from 'react';
import './index.scss';
import { useDroppable } from '@dnd-kit/core';

interface Props {
  id: string;
  style: any;
  key?: any;
  children: ReactChild;
  agent: any;
  selectedDataSet: any[];
  checkable?: boolean;
  checked?: boolean;
  isDroppable?: boolean;
  onCheck?: (e: any, agent: any) => void;
  onClickUnassign: () => void;
}

const AgentItem = forwardRef<HTMLDivElement, Props>(
  (
    {
      id,
      key,
      style,
      children,
      checkable,
      checked,
      agent,
      selectedDataSet,
      isDroppable = false,
      onCheck = (e: any, agent: any) => {},
      onClickUnassign = () => {},
    }: Props,
    ref,
  ) => {
    const { isOver, setNodeRef, active } = useDroppable({
      id,
      disabled: !isDroppable,
    });

    const isAgentDataSet = active && active?.id?.toString()?.indexOf('agent-dataset') > -1;
    const isDataSetAgentContainer = id && id.indexOf('agent') > -1;

    return (
      <div key={key} style={style} ref={setNodeRef} className="agent-dataset-item">
        <Col ref={ref}>
          <Row>
            <Col span={1} className="gutter-row">
              {checkable && (
                <Checkbox checked={checked} onChange={(e) => onCheck(e, agent)} value={agent} />
              )}
            </Col>
            <Col span={18}>
              <Row
                gutter={5}
                // className={addHoverClass}
              >
                <Col className="gutter-row">
                  <b>{agent.title}</b>
                </Col>
              </Row>
            </Col>
            {checked && (
              <Col span={5}>
                <Row justify="end">
                  <AnchorButton
                    onClick={onClickUnassign}
                    small
                    outlined
                    text={`Unassign (${selectedDataSet.length})`}
                  />
                </Row>
              </Col>
            )}
          </Row>
          {children}
        </Col>
        {isOver && !isAgentDataSet && isDataSetAgentContainer && (
          <div className="backdrop">Add to agent</div>
        )}
      </div>
    );
  },
);

export default AgentItem;
