import { CalendarState } from '../../types';
import {
  FETCH_ENGINEERS_ERROR,
  FETCH_ENGINEERS_REQUEST,
  FETCH_ENGINEERS_RESPONSE,
} from '../constants';
import {
  CalendarAction,
  FetchEngineersErrorAction,
  FetchEngineersRequestAction,
  FetchEngineersResponseAction,
} from '../types';
import { getErrorState, getLoadingState, getSuccessState } from './utils/fetch';

const isRequestAction = (action: CalendarAction): action is FetchEngineersRequestAction =>
  action.type === FETCH_ENGINEERS_REQUEST;
const isResponseAction = (action: CalendarAction): action is FetchEngineersResponseAction =>
  action.type === FETCH_ENGINEERS_RESPONSE;
const isErrorAction = (action: CalendarAction): action is FetchEngineersErrorAction =>
  action.type === FETCH_ENGINEERS_ERROR;

const reducers = {
  [FETCH_ENGINEERS_REQUEST]: (
    state: CalendarState['engineers'],
    _action: FetchEngineersRequestAction,
  ): CalendarState['engineers'] => ({
    ...state,
    ...getLoadingState(),
  }),

  [FETCH_ENGINEERS_RESPONSE]: (
    state: CalendarState['engineers'],
    action: FetchEngineersResponseAction,
  ): CalendarState['engineers'] => ({
    ...state,
    ...getSuccessState(action.payload),
  }),

  [FETCH_ENGINEERS_ERROR]: (
    state: CalendarState['engineers'],
    action: FetchEngineersErrorAction,
  ): CalendarState['engineers'] => ({
    ...state,
    ...getErrorState(action.payload),
  }),
} as const;

export const engineersReducer = (
  state: CalendarState['engineers'],
  action: CalendarAction,
): CalendarState['engineers'] => {
  if (isRequestAction(action)) return reducers[action.type](state, action);

  if (isResponseAction(action)) return reducers[action.type](state, action);

  if (isErrorAction(action)) return reducers[action.type](state, action);

  return state;
};
