import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import { FC } from 'react';
import { getRoutersFromOrderItem } from '../helpers';
import ActivatedRouter from '../Router/ActivatedRouter';
import DeactivatedRouter from '../Router/DeactivatedRouter';

interface INetworkBaseProductProps {
  record: DbRecordEntityTransform; // OrderItem
  onUpdated?: () => void;
  disabled?: boolean;
}

export const NetworkAddonBroadbandProduct: FC<INetworkBaseProductProps> = (props) => {
  const { record, onUpdated, disabled } = props;

  const Routers = getRoutersFromOrderItem(record);

  function renderRouterForm() {
    // for each of the quantity, render a deactivated router
    const routers = [];
    for (let i = 0; i < Number(getProperty(record, 'Quantity')); i++) {
      routers.push(
        <Col span={24} style={{ marginTop: 12 }}>
          <DeactivatedRouter orderItem={record} onUpdated={onUpdated} disabled={disabled} />
        </Col>,
      );
    }
    return routers;
  }

  return (
    <Row>
      {/* Associated Routers */}
      {Routers.length > 0 ? (
        <>
          {Routers.map((router: DbRecordEntityTransform, index: number) => (
            <Col key={index} span={24} style={{ marginTop: 12 }}>
              <ActivatedRouter
                routerRecord={router}
                orderItem={record}
                onUpdated={onUpdated}
                disabled={disabled}
              />
            </Col>
          ))}

          {Routers?.length < Number(getProperty(record, 'Quantity')) && (
            <Col span={24} style={{ marginTop: 12 }}>
              <DeactivatedRouter orderItem={record} onUpdated={onUpdated} disabled={disabled} />
            </Col>
          )}
        </>
      ) : (
        <>{renderRouterForm()}</>
      )}
    </Row>
  );
};
