import React from 'react';
import { useCalendarService, useCalendarState } from './index';
import dayjs from 'dayjs';
import { CalendarState } from './types';

const getScheduleIds = (schedules: CalendarState['schedules']) => {
  // Preprocess All Install or All Service options
  if (
    schedules.selectedIds.length === 1 &&
    ['INSTALL', 'SERVICE'].includes(schedules.selectedIds[0])
  ) {
    const type = schedules.selectedIds[0];
    return schedules.data.filter((s) => s.type === type).map((s) => s.id);
  }

  return schedules.selectedIds;
};

export const CalendarEffects: React.FC = () => {
  const {
    schedules,
    appointments: { view, dateRange, dateFilter, selectedDate },
  } = useCalendarState();
  const service = useCalendarService();

  // Load Schedules when page is loaded
  React.useEffect(() => {
    service.fetchSchedules();
  }, []);

  // Load WorkOrders and EarlierAvailability for
  // month view when selectedDate changes
  React.useEffect(() => {
    if (schedules.selectedIds.length === 0) {
      return;
    } else if (schedules.selectedIds.length > 0 && schedules.selectedIds.length < 6) {
      const scheduleIds = getScheduleIds(schedules);
      service.fetchScheduleWorkOrders(scheduleIds, selectedDate, selectedDate).then(() => {
        return service.fetchScheduleEarlierAvailability({ scheduleIds });
      });
    } else {
      const scheduleIds = getScheduleIds(schedules);
      const currentSchedule = schedules.data.find((schedule) => scheduleIds.includes(schedule.id));
      service.fetchUtilization({
        scheduleIds: schedules.selectedIds,
        start: dateFilter.start,
        end: dateFilter.end,
        type: currentSchedule!.type || 'INSTALL',
      });
    }
  }, [schedules.selectedIds.length, view, selectedDate]);

  // Load Availability (TimeSlots) when Schedule is selected
  React.useEffect(() => {
    if (schedules.selectedIds.length > 0 && schedules.selectedIds.length < 6) {
      const scheduleIds = getScheduleIds(schedules);
      const currentSchedule = schedules.data.find((schedule) => scheduleIds.includes(schedule.id));
      const end =
        view === 'day' ? dayjs(dateFilter.end).add(1, 'day').format('YYYY-MM-DD') : dateFilter.end;
      if (currentSchedule?.type)
        service.fetchUtilization({
          scheduleIds: scheduleIds,
          start: dateFilter.start,
          end,
          type: currentSchedule.type,
        });
    }
  }, [schedules.selectedIds.length, dateFilter.start, dateFilter.end]);
  return null;
};
