import { Card, Col, Row } from 'antd';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';

import RecordCard from '@legacy/core/records/components/RecordCard';
import { RecordDetailsCard } from './RecordDetailsCard';

type RecordDetailsPanelProps = {
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
};
export const RecordDetailsPanel = (props: RecordDetailsPanelProps) => {
  return (
    <Card title="Survey Details">
      <Row>
        <Col span={24} style={{ marginTop: 15 }}>
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={props.record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={SchemaModuleEntityTypeEnums.PROJECT}
            visibleProperties={[
              'Contractor',
              'PurchaseOrderNumber',
              'EstimatedStart',
              'EstimatedEnd',
            ]}
          />
        </Col>
        <Col span={24}>
          <RecordDetailsCard
            record={props.record}
            schema={props.schema}
          />
        </Col>
      </Row>
    </Card>
  );
};
