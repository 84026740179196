import React from 'react'
import './styles.scss'
import { Button, Dialog } from '@blueprintjs/core'

interface Props {
    showDeleteParticipantAlert: boolean;
    setShowDeleteParticipantAlert: (value: boolean) => void;
    participantName: string;
    handleConfirmDeleteParticipant: () => void;
    isDeleting: boolean;
}

const ConversationDetailsDialog = ({ showDeleteParticipantAlert, setShowDeleteParticipantAlert, participantName, handleConfirmDeleteParticipant, isDeleting }: Props) => {
  return (
    <Dialog
        className="conversation-details-alert"
        isOpen={showDeleteParticipantAlert}
        onClose={() => setShowDeleteParticipantAlert(false)}
      >
        <div className="conversation-details-alert-body">
          <div className="conversation-details-alert-title">
            <h2 className="dialog-title">Are you sure absolutely sure?</h2>
            <p>
              This action cannot be undone. You are about to delete <b>{participantName}</b> from
              this conversation.
            </p>
          </div>
        </div>
        <div className="conversation-details-alert-footer">
          <Button
            className="conversation-details-alert-footer-cancel-btn"
            onClick={() => setShowDeleteParticipantAlert(false)}
          >
            Cancel
          </Button>
          <Button
            className="conversation-details-alert-footer-submit-btn"
            intent="danger"
            onClick={handleConfirmDeleteParticipant}
            style={{
              opacity: isDeleting ? '.5' : '',
            }}
            disabled={isDeleting}
          >
            Delete
          </Button>
        </div>
      </Dialog>
  )
}

export default ConversationDetailsDialog
