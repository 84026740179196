import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

const AFP_APPROVAL_LOAD_FEATURE_REQUEST = 'AFP_APPROVAL_LOAD_FEATURE_REQUEST';
const AFP_APPROVAL_LOAD_FEATURE_RESPONSE = 'AFP_APPROVAL_LOAD_FEATURE_RESPONSE';
const AFP_APPROVAL_LOAD_FEATURE_ERROR = 'AFP_APPROVAL_LOAD_FEATURE_ERROR';

type LoadFeatureRequest = {
  type: typeof AFP_APPROVAL_LOAD_FEATURE_REQUEST;
  payload: undefined;
};

type LoadFeatureResponse = {
  type: typeof AFP_APPROVAL_LOAD_FEATURE_RESPONSE;
  payload: DbRecordEntityTransform;
};

type LoadFeatureError = {
  type: typeof AFP_APPROVAL_LOAD_FEATURE_ERROR;
  payload: { message: string };
};

export type LoadFeatureActions = LoadFeatureRequest | LoadFeatureResponse | LoadFeatureError;

export const loadFeatureRequest = (): LoadFeatureRequest => ({
  type: AFP_APPROVAL_LOAD_FEATURE_REQUEST,
  payload: undefined,
});

export const loadFeatureResponse = (payload: DbRecordEntityTransform): LoadFeatureResponse => ({
  type: AFP_APPROVAL_LOAD_FEATURE_RESPONSE,
  payload,
});

export const loadFeatureError = (payload: { message: string }): LoadFeatureError => ({
  type: AFP_APPROVAL_LOAD_FEATURE_ERROR,
  payload,
});
