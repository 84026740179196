import { CaretDownFilled } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Button, Col, Divider, Dropdown, MenuProps, Row, Space } from 'antd';
import { FC, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import {
  deleteRecordAssociationById,
  IDeleteRecordAssociation,
} from '@legacy/core/recordsAssociations/store/actions';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import DeviceListSection from '../shared/DeviceListSection';

interface Props {
  ataRecord: DbRecordEntityTransform; // ATA Device
  OIRecord: DbRecordEntityTransform; // OrderItem
  onUpdated?: () => void;
  schemaReducer: any;
  initializeForm: (params: any) => void;
  deleteAssociation: (params: IDeleteRecordAssociation, cb?: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
  disabled?: boolean;
}

const uuid = uuidv4();

const { SERVICE_MODULE, ORDER_MODULE } = SchemaModuleTypeEnums;
const { ORDER_ITEM } = SchemaModuleEntityTypeEnums;

const ActivatedATA: FC<Props> = (props: Props) => {
  const { ataRecord, onUpdated, schemaReducer, initializeForm, deleteAssociation, disabled } =
    props;
  const [error, setError] = useState<any>(undefined);

  const initializeUpdateForm = () => {
    const schema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SERVICE_MODULE,
      'CustomerDeviceAta',
    );

    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: `Update ${schema.description}`,
        selected: ataRecord,
        showFormModal: true,
        isCreateReq: false,
        isUpdateReq: true,
        schema: schema,
        sections: [{ name: schema.name, schema: schema }],
      });
    }
  };

  const removeRouterFromOrderItem = () => {
    const OISchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      ORDER_MODULE,
      ORDER_ITEM,
    );
    const routerSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      SERVICE_MODULE,
      'CustomerDeviceAta',
    );

    if (OISchema && routerSchema) {
      const schemaAssociation = OISchema?.associations.find(
        (assoc: any) =>
          assoc.childSchemaId === routerSchema?.id || assoc.parentSchemaId === routerSchema.id,
      );

      if (schemaAssociation) {
        deleteAssociation(
          {
            schema: OISchema,
            schemaAssociation: schemaAssociation,
            dbRecordAssociationId: ataRecord?.dbRecordAssociation?.id || '',
          },
          () => {
            onUpdated && onUpdated();
          },
        );
      }
    }
  };

  const getItems = () => {
    const items: MenuProps['items'] = [
      {
        key: '1',
        label: 'Edit',
        onClick: initializeUpdateForm,
        icon: <i className="bi bi-pencil-square" />,
      },
      {
        key: '2',
        label: 'Remove',
        danger: true,
        onClick: removeRouterFromOrderItem,
        icon: <i className="bi bi-trash3" />,
      },
    ];

    return items;
  };

  const detailsSection = () => {
    const model = getProperty(ataRecord, 'Model');
    const serialNumber = getProperty(ataRecord, 'SerialNumber');

    const details = [
      {
        label: 'Model',
        value: model,
      },
      {
        label: 'Serial #',
        value: serialNumber,
      },
    ];

    return <DeviceListSection details={details} />;
  };

  return (
    <div
      style={{
        borderRadius: 2,
        background: '#f5f5f5',
        border: '1px solid #e2e2e2',
        padding: '15px 15px',
      }}
    >
      <Row justify="space-between" align="middle">
        <Col>
          <ModuleEntityIcon moduleName={SERVICE_MODULE} entityName={'CustomerDeviceAta'} />
          <span style={{ fontWeight: 600 }}>ATA Device</span>
        </Col>
        <Col style={{ marginTop: isMobile ? 15 : 0 }}>
          {/* Dropdown Button - Manage */}
          <Dropdown menu={{ items: getItems() }} trigger={['click']} disabled={disabled}>
            <Button
              type="primary"
              size="small"
              ghost
              style={{ marginRight: 10 }}
              disabled={disabled}
            >
              <Space>
                Manage
                <CaretDownFilled />
              </Space>
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Divider style={{ marginTop: 15, marginBottom: 10, borderColor: '#e2e2e2' }} />
        </Col>
        <Col span={24}>{detailsSection()}</Col>
      </Row>

      {/* Error Message */}
      {error && (
        <Row style={{ marginTop: 15 }}>
          <Col span={24}>
            <Alert
              showIcon
              type="error"
              message={error}
              closable
              onClose={() => setError(undefined)}
            />
          </Col>
        </Row>
      )}

      {/* Form */}
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; res: any }) => {
          onUpdated && onUpdated();
        }}
      />
    </div>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  deleteAssociation: (params: IDeleteRecordAssociation, cb: () => {}) =>
    dispatch(deleteRecordAssociationById(params, cb)),
});

export default connect(mapState, mapDispatch)(ActivatedATA);
