import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { FunctionComponent, useEffect, useState } from 'react';
import { httpGet } from '../../../../../core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { connect } from 'react-redux';
import { Card, Col, Empty, Row, Spin } from 'antd';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { deleteRecordByIdRequest } from '@legacy/core/records/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import {
  DeleteSchemaAssociation,
  deleteSchemaAssociationsRequest,
} from '@legacy/core/schemasAssociations/store/actions';
import SimpleUploadCard from '../../../../../core/views/Files/containers/SimpleUploadCard';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../core/helpers/schemaHelpers';
import { updateOrCreateRecordAssociations } from '@legacy/core/recordsAssociations/store/actions';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { isMobile } from 'react-device-detect';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import Search from 'antd/es/input/Search';
import { Button } from '@blueprintjs/core';

interface Props {
  record: DbRecordEntityTransform | undefined;

  alertMessage(params: { body: string; type: string }): void;

  schemaReducer: any;
  deleteAssociation: Function;
  getSchema: Function;
  deleteRecord: Function;
  createAssociations: (params: any, cb?: any) => void;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FILE } = SchemaModuleEntityTypeEnums;
const MAX_RECORDS_IN_VIEW = 3;

const ProjectModuleIntersectingPIA: FunctionComponent<Props> = (props: Props) => {
  const { record, alertMessage, schemaReducer, getSchema, createAssociations } = props;

  const [parentSchema, setParentSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isLoadingPIAs, setIsLoadingPIAs] = useState<boolean>(false);
  const [PIARecords, setPIARecords] = useState<DbRecordEntityTransform[]>([]);
  const [showMore, setShowMore] = useState<boolean>(false);
  const [quickSearchTerm, setQuickSearchTerm] = useState<string>('');

  // This will run on component mount and retrieve the intersecting PIA records
  useEffect(() => {
    if (record) {
      getIntersectingPIAs();
    }
  }, [record]);

  // Fetch the parent schema on component mount
  useEffect(() => {
    if (!parentSchema && record) {
      const moduleName = record.entity?.split(':')[0];
      const entityName = record.entity?.split(':')[1];

      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer?.shortList,
        moduleName,
        entityName,
      );

      if (shortlistSchema) {
        setParentSchema(shortlistSchema);
      } else if (!shortlistSchema && moduleName && entityName) {
        getSchema({ moduleName: moduleName, entityName: entityName }, (res: SchemaEntity) => {
          setParentSchema(res);
        });
      }
    }
  }, [record]);

  const renderPIACards = () => {
    if (PIARecords.length === 0)
      return (
        <Col span={24} style={{ textAlign: 'center', padding: '20px 0' }}>
          <Empty description="No intersecting PIA Features found." />
        </Col>
      );
    else {
      let piaRecordsToRender = Object.assign(PIARecords);

      // If there is a quick search term, filter the records
      if (quickSearchTerm.length > 0) {
        piaRecordsToRender = piaRecordsToRender.filter(
          (piaRecord: DbRecordEntityTransform) =>
            getProperty(piaRecord, 'ExternalRef').indexOf(quickSearchTerm) > -1,
        );
      }

      // If show more is false, only show the first 3 records
      if (!showMore) {
        piaRecordsToRender = piaRecordsToRender.slice(0, MAX_RECORDS_IN_VIEW);
      }

      // This will check if the record is visible based on the quick search term
      const isVisible = (id: string) => {
        return !!piaRecordsToRender.find(
          (pia: DbRecordEntityTransform) => getProperty(pia, 'ExternalRef') === id,
        );
      };

      return PIARecords.map((piaRecord: DbRecordEntityTransform) => (
        <SimpleUploadCard
          hidden={!isVisible(getProperty(piaRecord, 'ExternalRef'))}
          colSpan={isMobile ? 24 : 24}
          record={piaRecord}
          onUpload={createAssociationForUploadedFile}
        />
      ));
    }
  };

  // This will run on component mount and retrieve all associated PIA features for the main feature
  const getIntersectingPIAs = async () => {
    setIsLoadingPIAs(true);

    let url = '';

    if (record?.type === 'CABLE') {
      url = `ProjectModule/v1.0/cst/Feature/cable-pia-features/${record?.id}`;
    } else {
      url = `ProjectModule/v1.0/cst/Feature/duct-pia-features/${record?.id}`;
    }

    return await httpGet(url)
      .then(async (res: any) => {
        setIsLoadingPIAs(false);
        if (res?.data?.data?.length > 0) {
          // Sort pia by external ref
          const sortedPIA = res?.data?.data?.sort((a: any, b: any) => {
            const aRef = getProperty(a, 'ExternalRef');
            const bRef = getProperty(b, 'ExternalRef');
            if (aRef < bRef) return -1;
            if (aRef > bRef) return 1;
            return 0;
          });
          setPIARecords(sortedPIA);
        }
      })
      .catch((err: any) => {
        setIsLoadingPIAs(false);
        setPIARecords([]);
        alertMessage({ body: 'There was an error loading PIA Features.', type: 'error' });
      });
  };

  // This will run when a file is uploaded to the PIA feature
  const createAssociationForUploadedFile = (id: string) => {
    if (parentSchema && record) {
      const schemaAssociation = parentSchema?.associations?.find(
        (elem: any) => elem?.label === 'Feature__File',
      );
      createAssociations({
        recordId: record.id,
        schema: parentSchema,
        schemaAssociation: schemaAssociation,
        createUpdate: [
          {
            entity: `${SCHEMA_MODULE}:${FILE}`,
            recordId: id,
          },
        ],
      });
    }
  };

  return (
    <Card
      title="PIA Image Upload"
      extra={[
        <Search
          style={{ width: isMobile ? 130 : 150 }}
          allowClear
          size="small"
          disabled={!PIARecords.length}
          placeholder="Filter by ID"
          onChange={(e: any) => setQuickSearchTerm(e.target?.value || '')}
        />,
      ]}
    >
      {isLoadingPIAs ? (
        <Row>
          <Col span={24} style={{ textAlign: 'center', padding: '10px 0' }}>
            <Spin style={{ marginBottom: 20 }} />
            <br />
            <span>Loading PIA Features...</span>
          </Col>
        </Row>
      ) : (
        <Row gutter={12}>
          {renderPIACards()}
          {PIARecords.length > MAX_RECORDS_IN_VIEW && quickSearchTerm.length === 0 && (
            <Col span={24} style={{ textAlign: 'center', marginTop: 20, marginBottom: 10 }}>
              <Button onClick={() => setShowMore(!showMore)} small>
                {showMore ? 'Show Less' : `Show More`}
              </Button>
            </Col>
          )}
        </Row>
      )}
    </Card>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  deleteAssociation: (params: DeleteSchemaAssociation) =>
    dispatch(deleteSchemaAssociationsRequest(params)),
  deleteRecord: (payload: any, cb: any) => dispatch(deleteRecordByIdRequest(payload, cb)),
  createAssociations: (params: any, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
});

export default connect(mapState, mapDispatch)(ProjectModuleIntersectingPIA);
