import { useDispatch } from 'react-redux';

export const useRequest = <
  T extends (params: any, callback: (result?: unknown) => void) => unknown,
>(
  action: T,
) => {
  const dispatch = useDispatch();
  return (params: Parameters<T>[0]): Promise<any> => {
    return new Promise((resolve, reject) => {
      let timeout = setTimeout(() => reject(new Error('Request timed out!')), 180000);
      dispatch(
        action(params, (result) => {
          clearTimeout(timeout);
          resolve(result);
        }),
      );
    });
  };
};
