import { CloseOutlined } from '@ant-design/icons';
import { Divider, Tag } from 'antd';
import { FC } from 'react';
import { TAppliedFilter } from '../index';
import '../styles.scss';
interface Props {
  filter: TAppliedFilter;
  onRemove: Function;
}

const shortenLongFreeText = (value: string) => {
  if (value && value.length > 24) {
    return value.slice(0, 24) + '...';
  } else {
    return value;
  }
};

const ElasticFilterTag: FC<Props> = (props: Props) => {
  const { filter, onRemove } = props;
  
  const removeTheTag = (filter: TAppliedFilter) => {
    if (filter.closable) {
      onRemove(filter);
    }
  };
  
  return (
    <Tag
      onClick={() => removeTheTag(filter)}
      className={`filterTag ${
        filter.operator === 'Must contain' || filter.operator === 'Date is in range'
          ? 'mustFilter'
          : 'mustNotFilter'
      }`}
    >
      {/* Column name */}
      <span>{filter.columnName}</span>

      {/* Left Divider */}
      <Divider type="vertical" style={{ marginLeft: 5, marginRight: 5 }} />

      {/* Operator */}
      {filter.operator === 'Must contain' || filter.operator === 'Date is in range' ? (
        <span>=</span>
      ) : (
        <span>&#8800;</span>
      )}

      {/* Right divider */}
      <Divider type="vertical" style={{ marginLeft: 5, marginRight: 5 }} />

      {/* Column value */}
      <span>{shortenLongFreeText(filter.columnValue?.prettyValue)}</span>

      {/* Close button */}
      {filter.closable ? <CloseOutlined style={{ marginLeft: 5, fontSize: '0.8em' }} /> : <></>}
    </Tag>
  );
};

export default ElasticFilterTag;
