import {
  CREATE_USER_AUDIT_EVENTS_REQUEST,
  GET_USERS_AUDIT_EVENTS_BY_RECORD_REQUEST,
} from './constants';
import { LogsUserAuditEventTypesEnum } from '@d19n/temp-fe-d19n-models/dist/logs/audit/logs.user.audit.event.types';
import { LogsUserAuditCreateDto } from '@d19n/temp-fe-d19n-models/dist/logs/audit/logs.user.audit.create.dto';

export interface IGetUsersAuditEventsByRecord {
  recordId: string;
  types?: LogsUserAuditEventTypesEnum[];
  usersIds?: string[];
}

export interface ICreateUserAuditEvents {
  items: LogsUserAuditCreateDto[];
}

export function getUsersAuditEventsByRecordRequest(
  params: IGetUsersAuditEventsByRecord,
  cb?: (resp: any) => void,
) {
  return {
    type: GET_USERS_AUDIT_EVENTS_BY_RECORD_REQUEST,
    params,
    cb,
  };
}

export function createUserAuditEventsRequest(
  params: ICreateUserAuditEvents,
  cb?: (resp: any) => void,
) {
  return {
    type: CREATE_USER_AUDIT_EVENTS_REQUEST,
    params,
    cb,
  };
}
