import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Select } from 'antd';
import { FC, useEffect, useState } from 'react';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { connect } from 'react-redux';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { getSchemaByModuleAndEntityRequest } from '@legacy/core/schemas/store/actions';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';

interface Props {
  schedules: DbRecordEntityTransform[];
  onScheduleSelect: (ids: string[]) => void;
  selectedSchedules: string[];
  disabled?: boolean;
  onClear?: () => void;
  isOverview: boolean;
  isViewingByRegion: boolean;
  schemaReducer: ISchemaReducer;
  getSchema: (params: any, cb?: any) => void;
  searchRecords: (params: any, cb: any) => void;
}

const { FIELD_SERVICE_MODULE, PROJECT_MODULE } = SchemaModuleTypeEnums;
const { SERVICE_APPOINTMENT_CONFIG, REGION } = SchemaModuleEntityTypeEnums;

const ScheduleSelect: FC<Props> = (props: Props) => {
  const {
    schedules,
    onScheduleSelect,
    selectedSchedules,
    disabled,
    onClear,
    isOverview,
    schemaReducer,
    getSchema,
    isViewingByRegion,
    searchRecords,
  } = props;
  const [SACSchema, setSACSchema] = useState<SchemaEntity | undefined>(undefined);
  const [selectedType, setSelectedType] = useState<string[]>([]);
  const [regionSchema, setRegionSchema] = useState<SchemaEntity | undefined>(undefined);
  const [regions, setRegions] = useState<DbRecordEntityTransform[]>([]);
  const [selectedRegionIds, setSelectedRegionIds] = useState<string[]>([]);
  const [isSearchingForRegions, setIsSearchingForRegions] = useState<boolean>(false);

  // Get WO Schema on component
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      SERVICE_APPOINTMENT_CONFIG,
    );

    if (shortlistSchema) {
      setSACSchema(shortlistSchema);
    } else {
      getSchema(
        {
          moduleName: FIELD_SERVICE_MODULE,
          entityName: SERVICE_APPOINTMENT_CONFIG,
        },
        (res: SchemaEntity) => {
          setSACSchema(res);
        },
      );
    }
  }, []);

  // Get Region schema on component mount
  useEffect(() => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      REGION,
    );

    if (shortlistSchema) {
      setRegionSchema(shortlistSchema);
    } else {
      getSchema(
        {
          moduleName: PROJECT_MODULE,
          entityName: REGION,
        },
        (res: any) => {
          setRegionSchema(res);
        },
      );
    }
  }, []);

  // Clean up selection on mode change
  useEffect(() => {
    if (!isOverview || !isViewingByRegion) {
      setSelectedType([]);
      onScheduleSelect([]);
      setSelectedRegionIds([]);
    }
  }, [isOverview, isViewingByRegion]);
  useEffect(() => {
    if (isOverview) {
      setSelectedType([]);
      setSelectedRegionIds([]);
    }
  }, [isOverview]);
  useEffect(() => {
    if (isViewingByRegion && regions.length > 0) {
      setSelectedRegionIds([]);
      onScheduleSelect([]);
    }
  }, [isViewingByRegion]);

  // When schedules become available, reduce a list of unique region ids and get all regions
  useEffect(() => {
    if (schedules?.length! > 0 && regionSchema) {
      let regionsIds =
        schedules?.reduce((acc: string[], schedule: any) => {
          const regionId = getProperty(schedule, 'RegionId');
          if (regionId) {
            acc.push(regionId);
          }
          return acc;
        }, []) || [];
      regionsIds = [...new Set(regionsIds)];
      getAllRegions(regionsIds);
    }
  }, [schedules]);

  const getAllRegions = (regionIds: string[]) => {
    if (regionIds.length > 0 && regionSchema) {
      setIsSearchingForRegions(true);
      searchRecords(
        {
          schema: regionSchema,
          searchQuery: {
            terms: '',
            schemas: regionSchema?.id,
            boolean: {
              must: [
                {
                  query_string: {
                    fields: ['id'],
                    query: regionIds.map((regionId: string) => `"${regionId}"`).join(' OR '),
                    lenient: true,
                    default_operator: 'AND',
                  },
                },
              ],
            },
          },
        },
        (searchResults: any) => {
          setIsSearchingForRegions(false);
          setRegions(searchResults.data?.data || []);
          // console.log('%cDEBUG: Regions!', 'color: limegreen', searchResults.data?.data);
        },
      );
    }
  };

  // Every schedule has type, so we can use it to filter. Get all distinct types and then filter by them
  const overviewSchedules = Object.assign([], schedules);
  let types = overviewSchedules.map((s: DbRecordEntityTransform) => s.type);
  let distinctTypes = [...new Set(types)];

  const onChange = (value: any) => {
    // Mode - All Schedules
    if (!isOverview && !isViewingByRegion) {
      setSelectedType([]);
      if (value.length <= 5) onScheduleSelect(value);
    }
    // Mode - Overview
    else if (isOverview && !isViewingByRegion) {
      setSelectedType([value]);
      const scheduleIds = schedules
        .filter((s: DbRecordEntityTransform) => value.includes(s.type))
        .map((s: DbRecordEntityTransform) => s.id);
      onScheduleSelect(scheduleIds);
    }
    // Mode - By Region
    else if (isViewingByRegion) {
      setSelectedRegionIds(value);
      const scheduleIds = schedules
        .filter((s: DbRecordEntityTransform) => value.includes(s.properties.RegionId))
        .map((s: DbRecordEntityTransform) => s.id);
      onScheduleSelect(scheduleIds);
    }
  };

  const renderSelectGroup = (type: string) => {
    if (schedules.some((schedule: DbRecordEntityTransform) => schedule.type === type)) {
      return (
        <Select.OptGroup label={type} key={type}>
          {schedules.map((schedule: DbRecordEntityTransform) => {
            if (schedule.type === type) {
              return (
                <Select.Option value={schedule.id} title={schedule.title} key={schedule.title}>
                  {schedule.title}
                </Select.Option>
              );
            }
          })}
        </Select.OptGroup>
      );
    }
  };

  const value = () => {
    if (isOverview) {
      return selectedType;
    } else if (isViewingByRegion) {
      return selectedRegionIds;
    } else {
      return selectedSchedules;
    }
  };

  return (
    <Select
      disabled={disabled || !SACSchema}
      showSearch
      mode={!isOverview ? 'multiple' : undefined}
      onClear={onClear && (() => onClear())}
      style={{ width: 280, textAlign: 'left' }}
      allowClear
      maxTagCount={2}
      maxTagTextLength={selectedSchedules.length > 1 ? 7 : 25}
      placeholder={isViewingByRegion ? 'Select Region' : 'Select Schedule'}
      value={value()}
      onChange={(value: string[]) => {
        onChange(value);
      }}
      filterOption={(input, option) => {
        if (!option?.title) return false; // skip OptGroups
        return option?.title.toLowerCase().includes(input.toLowerCase());
      }}
    >
      {/* All Schedules */}
      {!isOverview && !isViewingByRegion && SACSchema && (
        <>
          {SACSchema.types.map((type: SchemaTypeEntity) => {
            return renderSelectGroup(type.name);
          })}
        </>
      )}

      {/* Overview Schedules */}
      {isOverview &&
        !isViewingByRegion &&
        distinctTypes.map((type: any) => {
          return (
            <Select.Option value={type} title={type} key={type}>
              {type}
            </Select.Option>
          );
        })}

      {/* By Region */}
      {isViewingByRegion &&
        regions.length > 0 &&
        regions.map((region: DbRecordEntityTransform) => {
          return (
            <Select.Option value={region.id} title={region.title} key={region.title}>
              {region.title}
            </Select.Option>
          );
        })}
    </Select>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (params: any, cb: any) => dispatch(getSchemaByModuleAndEntityRequest(params, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(ScheduleSelect);
