import dayjs from 'dayjs';
import { ALL_TIME_BLOCKS, TTimeBlock } from './types';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

export const getEarliestAvailableSlot = (userTimeSlots: TTimeBlock[]): string | null => {
  for (const timeSlot of ALL_TIME_BLOCKS) {
    // Check if this slot is free (not within any user time slots)
    const isFree = !userTimeSlots.some(
      (slot) =>
        dayjs(timeSlot, 'HH:mm').isSameOrAfter(dayjs(slot.startTime, 'HH:mm')) &&
        dayjs(timeSlot, 'HH:mm').isBefore(dayjs(slot.endTime, 'HH:mm')),
    );

    if (isFree) {
      return timeSlot;
    }
  }
  return null;
};

// In the list of time blocks, get the one that starts earliest
export const getLatestTimeBlock = (timeBlocks: TTimeBlock[]): TTimeBlock | null => {
  return timeBlocks.reduce((latestTimeBlock: TTimeBlock | null, timeBlock: TTimeBlock) => {
    if (!latestTimeBlock) {
      return timeBlock;
    }

    const latestEndTime = dayjs(latestTimeBlock.endTime, 'HH:mm');
    const endTime = dayjs(timeBlock.endTime, 'HH:mm');

    return endTime.isAfter(latestEndTime) ? timeBlock : latestTimeBlock;
  }, null);
};

// In list of time blocks, get the one that ends latest
export const getEarliestTimeBlock = (timeBlocks: TTimeBlock[]): TTimeBlock | null => {
  return timeBlocks.reduce((earliestTimeBlock: TTimeBlock | null, timeBlock: TTimeBlock) => {
    if (!earliestTimeBlock) {
      return timeBlock;
    }

    const earliestStartTime = dayjs(earliestTimeBlock.startTime, 'HH:mm');
    const startTime = dayjs(timeBlock.startTime, 'HH:mm');

    return startTime.isBefore(earliestStartTime) ? timeBlock : earliestTimeBlock;
  }, null);
};

// In the list of ALL_TIME_BLOCKS, get only those that are later than the given time
export const getLaterTimeBlocks = (time: string): string[] => {
  return ALL_TIME_BLOCKS.filter((timeBlock) =>
    dayjs(timeBlock, 'HH:mm').isAfter(dayjs(time, 'HH:mm')),
  );
};
