import { httpGet } from '../../http/requests';

export const getAllSchemaActionsForMultipleSchemaIds = async (schemaIds: string[]) => {
  return await httpGet(`SchemaModule/v1.0/schemas-actions`).then((res: any) => {
    const results = res.data?.data;

    if (results) {
      return results.filter((item: any) => schemaIds.includes(item.schemaId));
    } else {
      return [];
    }
  });
};
