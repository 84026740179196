import React, { useState } from 'react';
import { Col, Row, Slider } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { IUserInterfaceReducer } from '../../../../userInterface/store/types';
import { displayMessage } from '../../../../messages/store/reducers';
import { copyTextToClipboard } from '@netomnia/modules/ProjectModule/Autosplicing/helpers';
import { connect } from 'react-redux';
import './styles.scss';
import { Button } from '@blueprintjs/core';

interface Props {
  type: 'CARD' | 'DRAWER';
  data: string;
  userInterfaceReducer: IUserInterfaceReducer;
  alertMessage: Function;
  maximizedView?: boolean;
  setMaximizedView?: Function;
  height?: string;
}

const RawDataContainer: React.FC<Props> = (props: Props) => {
  const [fontSize, setFontSize] = useState<number>(11);
  const {
    alertMessage,
    userInterfaceReducer,
    setMaximizedView,
    maximizedView,
    type,
    data,
    height,
  } = props;

  const onChange = (newValue: number) => {
    setFontSize(newValue);
  };

  const syntaxHighlight = (json: string) => {
    if (!json) return ''; //no JSON from response

    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(
      /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g,
      function (match: any) {
        var cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      },
    );
  };

  return (
    <Row>
      <Col span={24} style={{ display: type === 'CARD' ? 'none' : 'block' }}>
        <Row>
          <Col span={2} style={{ paddingTop: 3 }}>
            <span>{fontSize}px</span>
          </Col>
          <Col span={10} style={{ textAlign: 'left' }}>
            <Slider
              min={7}
              max={15}
              onChange={onChange}
              value={typeof fontSize === 'number' ? fontSize : 0}
            />
          </Col>
          <Col span={12} style={{ textAlign: 'right', marginBottom: 10 }}>
            {/* <Tooltip
                            title={maximizedView ? 'Minimize view' : 'Expand view'}
                            mouseEnterDelay={0.9}
                        >
                            <Button
                                style={{ marginRight: 7 }}
                                type="default"
                                icon={
                                    maximizedView ? (
                                        <FullscreenExitOutlined />
                                    ) : (
                                            <FullscreenOutlined />
                                        )
                                }
                                onClick={() => setMaximizedView ? setMaximizedView(!maximizedView) : false}
                            ></Button>
                        </Tooltip> */}

            <Button
              disabled={!data}
              icon={<CopyOutlined />}
              text="Copy to Clipboard"
              onClick={() =>
                copyTextToClipboard(
                  JSON.stringify(data, null, 2),
                  'Data copied to' + ' clipboard!',
                  alertMessage,
                )
              }
            />
          </Col>
        </Row>
      </Col>

      {/* Raw data container */}
      <Col
        span={24}
        className="rawDataContainer"
        style={{
          fontSize: `${fontSize}px`,
          maxHeight: type === 'DRAWER' ? '82vh' : '500px',
          minHeight: type === 'DRAWER' ? '85vh' : '500px',
        }}
      >
        <pre
          dangerouslySetInnerHTML={{
            __html: syntaxHighlight(JSON.stringify(data, undefined, 4)),
          }}
        />
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
});

export default connect(mapState, mapDispatch)(RawDataContainer);
