import { EditOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Button, Col, Row, Tooltip } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import {
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '../../store/actions';
import './styles.scss';

interface Props {
  schema: SchemaEntity;
  record: DbRecordEntityTransform;
  column: SchemaColumnEntity;
  updateRecord: (params: IUpdateRecordById, cb?: any) => void;
}

const InlineEditableField: React.FC<Props> = (props: Props) => {
  const { schema, record, column } = props;

  const [editing, setEditing] = useState<boolean>(false);
  const [savingContents, setSavingContents] = useState<boolean>(false);
  const [contents, setContents] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);

  // When user starts editing, fill the input with record field contents and focus.
  useEffect(() => {
    if (editing) {
      const propertyContents = getProperty(record, column.name);

      setContents(propertyContents);
      inputRef?.current?.focus();
    }
  }, [editing]);

  const updateField = (
    record: DbRecordEntityTransform,
    text: string,
    schema: SchemaEntity,
    columnName: string,
  ) => {
    const { updateRecord } = props;
    setSavingContents(true);

    if (schema && record) {
      return updateRecord(
        {
          schema: schema,
          recordId: record.id,
          createUpdate: {
            schemaId: schema.id,
            type: record.type,
            entity: record.entity,
            properties: {
              [columnName]: text,
            },
          },
        },
        (res: DbRecordEntityTransform) => {
          setSavingContents(false);
          if (res) {
            setEditing(false);
          }
        },
      );
    }
  };

  return (
    <Row
      className={`inlineEditableFieldContainer ${
        editing ? 'activated' : 'deactivated'
      }`}
    >
      <Col
        span={20}
        style={{ paddingBottom: 5, fontWeight: 500 }}
        onClick={() => setEditing(true)}
      >
        {column.name}
      </Col>
      <Col span={4} style={{ textAlign: 'right' }}>
        {editing ? (
          <></>
        ) : (
          <Tooltip title="Click to edit">
            <EditOutlined onClick={() => setEditing(true)} />
          </Tooltip>
        )}
      </Col>
      <Col span={24}>
        {editing ? (
          <Row>
            <Col span={24}>
              <TextArea
                ref={inputRef}
                rows={2}
                key={`${column.name}-input`}
                id={column.name}
                defaultValue={getProperty(record, column.name) || ''}
                onChange={(e: any) => setContents(e.target.value)}
                value={contents}
                disabled={savingContents}
              />
            </Col>
            <Col span={24} style={{ textAlign: 'right', paddingTop: 10 }}>
              <Button
                size="small"
                type="default"
                style={{ marginRight: 8 }}
                onClick={() => {
                  setEditing(false);
                }}
              >
                Cancel
              </Button>
              <Button
                size="small"
                type="primary"
                loading={savingContents}
                disabled={
                  savingContents || getProperty(record, column.name) == contents
                }
                onClick={() =>
                  updateField(record, contents, schema, column.name)
                }
              >
                Save
              </Button>
            </Col>
          </Row>
        ) : (
          <Row onClick={() => setEditing(true)}>
            <Col span={24}>
              <span
                style={{
                  color: getProperty(record, column.name)
                    ? 'inherit'
                    : '#bbbbbb',
                }}
              >
                {getProperty(record, column.name) || 'Click to edit'}
              </span>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(InlineEditableField);
