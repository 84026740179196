import React, { FC, useContext, useState } from 'react';
import { Alert, Button } from '@blueprintjs/core';
import { orderBuilderContext } from '../index';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { connect } from 'react-redux';

const { ORDER_MODULE } = SchemaModuleTypeEnums;

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
}

const OrderBuilderSendQuote: FC<Props> = (props: Props) => {
  const { alertMessage } = props;
  const { state } = useContext(orderBuilderContext);
  const [isSendingQuote, setIsSendingQuote] = useState<boolean>(false);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);

  const sendQuote = () => {
    setIsSendingQuote(true);

    const quotePayload = {
      contactId: state.contact?.id || null,
      addressId: state.address?.id || null,
      offerId: state.offer?.id || null,
      products: state.orderItems.map((product: any) => ({
        id: product.id,
        properties: product.properties,
      })),
    };

    console.log('%cdebug: Quote payload!', 'color: #bada55', quotePayload);

    httpPost(`${ORDER_MODULE}/v1.0/orders/recontracting/send-quote`, quotePayload)
      .then((res: any) => {
        console.log('%cdebug: Quote response!', 'color: #bada55', res);
        setIsSendingQuote(false);
        setIsDeleteDialogVisible(false);
        alertMessage({
          body: 'Quote successfully sent to the customer.',
          type: 'success',
        });
      })
      .catch((err: any) => {
        setIsSendingQuote(false);
        setIsDeleteDialogVisible(false);

        alertMessage({
          body: `There was a problem sending quote to customer. ${JSON.stringify(
            err.response?.data?.message ?? err.message,
          )}`,
          type: 'error',
        });
        throw new Error(err.response?.data?.message ?? err.message);
      });
  };

  return (
    <>
      <Button
        icon="envelope"
        text="Send Quote"
        intent="primary"
        outlined
        onClick={() => setIsDeleteDialogVisible(true)}
      />

      {/* Confirmation Alert */}
      <Alert
        intent="primary"
        onCancel={() => setIsDeleteDialogVisible(false)}
        isOpen={isDeleteDialogVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Send"
        canEscapeKeyCancel={!isSendingQuote}
        canOutsideClickCancel={!isSendingQuote}
        onConfirm={sendQuote}
        loading={isSendingQuote}
      >
        <p>Please confirm that you want to send the quote to the customer.</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(OrderBuilderSendQuote);
