export const SET_ALL_RELATED_RECORDS = 'SET_ALL_RELATED_RECORDS';
export const SET_PARENT_RECORD = 'SET_PARENT_RECORD';
export const SET_SELECTED_RECORD = 'SET_SELECTED_RECORD';
export const SET_LOADING_PARENT_ASSOCIATIONS = 'SET_LOADING_PARENT_ASSOCIATIONS';
export const SET_LOADING_FILES = 'SET_LOADING_FILES';
export const SET_FILE_LIST = 'SET_FILE_LIST';
export const ADD_FILE_TO_SELECTION = 'ADD_FILE_TO_SELECTION';
export const REMOVE_FILE_FROM_SELECTION = 'REMOVE_FILE_FROM_SELECTION';
export const SET_SEARCH_TERM = 'SET_SEARCH_TERM';
export const RESET_FILE_IMPORT_REDUCER = 'RESET_FILE_IMPORT_REDUCER';
export const CLEAR_FILE_SELECTION = 'CLEAR_FILE_SELECTION';
export const SET_IS_IMPORTING_FILES = 'SET_IS_IMPORTING_FILES';
