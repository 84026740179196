import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Col, Drawer, Result, Row, Spin, Tabs } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { OrderNetworkDevicesContext } from '../index';
import RawDataContainer from '@legacy/core/records/components/RawData/RawDataContainer';
import { httpGet } from '@core/http/requests';
import NetworkWebhookResults from '../../../OrderItem/NetworkPanel/NetworkWebhookResults';
import {
  SET_NETWORK_DRAWER_DEVICE_RECORD,
  SET_NETWORK_DRAWER_ORDER_ITEM_RECORD,
  SET_NETWORK_DRAWER_VISIBLE,
} from '../store/constants';
import Summary from './RouterNetworkDetails/Summary';

interface Props {}

const { SCHEMA_MODULE, SERVICE_MODULE } = SchemaModuleTypeEnums;
const { CUSTOMER_DEVICE_ROUTER, CUSTOMER_DEVICE_ONT } = SchemaModuleEntityTypeEnums;

const NetworkDeviceDrawer: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(OrderNetworkDevicesContext);
  const [tabAutoSelect, setTabAutoSelect] = useState<boolean>(false);
  const [deviceType, setDeviceType] = useState<'ROUTER' | 'ONT' | undefined>(undefined);
  const [routerData, setRouterData] = useState<any>(undefined);
  const [loadingRouterStatus, setLoadingRouterStatus] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  const OIRecord = state.networkDrawerOrderItemRecord;
  const deviceRecord = state.networkDrawerDeviceRecord;

  const closeDrawer = () => {
    dispatch({ type: SET_NETWORK_DRAWER_VISIBLE, payload: false });
    dispatch({ type: SET_NETWORK_DRAWER_DEVICE_RECORD, payload: undefined });
    dispatch({ type: SET_NETWORK_DRAWER_ORDER_ITEM_RECORD, payload: undefined });
    setDeviceType(undefined);
    setRouterData(undefined);
  };

  // Derive device type and store to local state
  useEffect(() => {
    if (deviceRecord && !deviceType) {
      if (deviceRecord.entity === `${SERVICE_MODULE}:${CUSTOMER_DEVICE_ROUTER}`) {
        setDeviceType('ROUTER');
      } else {
        setDeviceType('ONT');
      }
    }
  }, [state.networkDrawerDeviceRecord, state.networkDrawerOrderItemRecord]);

  // If the type is ROUTER, fetch the router status
  useEffect(() => {
    setError(undefined);

    if (deviceType === 'ROUTER' || !routerData) {
      const serialNumber = getProperty(deviceRecord, 'SerialNumber');

      let apiPath = null;
      if (['EERO'].includes(getProperty(deviceRecord, 'Model'))) {
        apiPath = 'ServiceModule/v1.0/network/eero/eeros';
      } else if (['ARRIS'].includes(getProperty(deviceRecord, 'Model'))) {
        apiPath = 'ServiceModule/v1.0/network/cpe';
      }

      if (!apiPath) return;

      setLoadingRouterStatus(true);
      httpGet(`${apiPath}/${serialNumber}`, {})
        .then((res: any) => {
          setLoadingRouterStatus(false);
          setRouterData(res?.data?.data);
        })
        .catch((err) => {
          setLoadingRouterStatus(false);
          setError(
            err?.response?.data?.message ||
              err?.data?.message ||
              err?.message ||
              'An unknown error occurred',
          );
          console.log('debug: router status error', err);
        });
    }
  }, [deviceType]);

  return (
    <Drawer
      width={isMobile ? '90%' : '65%'}
      title={
        getProperty(deviceRecord, 'SerialNumber')
          ? `Device Details - ${getProperty(deviceRecord, 'SerialNumber')}`
          : `Service Details`
      }
      open={state.networkDrawerVisible}
      onClose={closeDrawer}
    >
      {/* Error alert */}
      {error && <Alert type="error" message={error} />}

      {/* ONT detail view */}
      {deviceType === 'ONT' && (
        <NetworkWebhookResults
          title={'Activity'}
          record={OIRecord}
          moduleName={SCHEMA_MODULE}
          entityName="Activity"
          isEditDisabled={true}
          tabAutoSelect={tabAutoSelect}
          setTabAutoSelect={setTabAutoSelect}
        />
      )}

      {/* Router - Loading status */}
      {deviceType === 'ROUTER' && loadingRouterStatus && (
        <Row>
          <Col span={24} style={{ textAlign: 'center', marginTop: 30 }}>
            <Spin size="large" style={{ marginBottom: 30 }} />
            <br />
            <span>Loading device status...</span>
          </Col>
        </Row>
      )}

      {/* Router - Detail view */}
      {deviceType === 'ROUTER' &&
      routerData &&
      !loadingRouterStatus &&
      deviceRecord &&
      ['EERO', 'ARRIS'].includes(getProperty(deviceRecord, 'Model')) ? (
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              key: '1',
              label: 'Network Info',
              children: <Summary router={deviceRecord} data={routerData} />,
            },
            {
              key: '2',
              label: 'Raw Data',
              children: <RawDataContainer type={'DRAWER'} data={routerData?.rawData} />,
            },
          ]}
        />
      ) : (
        <>
          {deviceType === 'ROUTER' &&
            !loadingRouterStatus &&
            !['EERO', 'ARRIS'].includes(getProperty(deviceRecord, 'Model')) && (
              <Result
                title={`Router model: ${getProperty(deviceRecord, 'Model')} is not supported yet.`}
              />
            )}
        </>
      )}
    </Drawer>
  );
};

export default NetworkDeviceDrawer;
