import { useEffect, useState } from 'react';

import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  PipelineStageEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';

import { getViewConfigurations } from '@core/modules/SchemaManagerModule/components/ViewConfigurations/api';

const fetchViewConfigs = async (schema: SchemaEntity) => {
  if (schema) {
    // setIsLoadingConfigurations(true);
    return getViewConfigurations(schema.id).catch((error: any) => {
      throw new Error(error.response?.data?.message ?? error.message);
    });
  }
};

export const useViewConfigurations = (
  schema: SchemaEntity,
  recordStage?: PipelineStageEntity,
  recordType?: string,
) => {
  const [viewConfigurations, setViewConfigurations] = useState<SchemaActionEntity[]>([]);

  const filterViewConfigurations = (actions: SchemaActionEntity[]) => {
    if (recordStage && schema) {
      let filteredConfigs = Object.assign(actions);

      filteredConfigs = filteredConfigs.map((config: SchemaActionEntity) => {
        if (config.stages && !config.stages?.includes(recordStage?.id)) {
          return undefined;
        } else {
          return config;
        }
      });

      // If record has a type, remove configs that are not matching the record type
      if (recordType) {
        const schemaType = schema.types.find((type) => type.name === recordType);
        if (schemaType) {
          filteredConfigs = filteredConfigs.filter((config: SchemaActionEntity) => {
            if (config?.schemaTypeId && config?.schemaTypeId !== schemaType?.id) {
              return false;
            } else {
              return config;
            }
          });
        }
      }
      setViewConfigurations(filteredConfigs);
    }
  };

  useEffect(() => {
    if (!schema || !recordStage) return;

    fetchViewConfigs(schema).then((res) => {
      filterViewConfigurations(res ?? []);
    });
  }, [schema?.id, recordStage?.id, recordType]);

  return viewConfigurations;
};
