import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';

const { ACCOUNT, CONTACT, ADDRESS } = SchemaModuleEntityTypeEnums;

export const getDefaultRecordCardPropertiesForEntity = (entityName: string): string[] => {
  switch (entityName) {
    case CONTACT:
      return ['EmailAddress', 'Phone', 'Mobile'];
    case ADDRESS:
      return ['Type', 'SalesStatus'];
    case ACCOUNT:
      return ['Type', 'Status'];
    default:
      return [''];
  }
};
