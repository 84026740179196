import React, { useState } from 'react';
import { HTMLTable, Spinner } from '@blueprintjs/core';
import { Card, Col, Descriptions, Pagination, PaginationProps, Row, Tag, Tooltip } from 'antd';
import { isMobile } from 'react-device-detect';
import { DateTime } from 'luxon';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import { getCustomRecordTitle } from '@legacy/core/records/components/RecordList/utils';
import { toSentenceCase } from '../../../../../../core/helpers/stringHelpers';

import { getColorForDSRecordStatus } from '@legacy/modules/CrmModule/containers/DataSet/helpers';

import './styles.css';

const renderDSStatusTag = (record: DbRecordEntityTransform) => {
  const DS_Status = getProperty(record, 'DS_Status') || 'TODO';

  if (DS_Status) {
    return (
      <Tag
        style={{ marginLeft: 8, padding: '0px 5px', fontSize: '0.9em', lineHeight: '16px' }}
        color={
          ['TO_DO', 'TODO'].includes(DS_Status) ? undefined : getColorForDSRecordStatus(DS_Status)
        }
      >
        {toSentenceCase(DS_Status)}
      </Tag>
    );
  } else {
    return <></>;
  }
};

const renderRecordStageTag = (record: DbRecordEntityTransform) => {
  const stage = record.stage;
  const getColorForStage = (record: DbRecordEntityTransform) => {
    if (stage?.isSuccess && !stage.isFail) {
      return 'green';
    } else if (!stage?.isSuccess && stage?.isFail) {
      return 'red';
    } else if (stage?.position === 1) {
      return 'purple';
    } else if (stage?.position === 2) {
      return 'orange';
    } else {
      return 'blue';
    }
  };

  if (record && record?.stage) {
    return (
      <Tag
        style={{ margin: 0, padding: '0px 5px', fontSize: '0.8em', lineHeight: '16px' }}
        color={getColorForStage(record)}
      >
        {toSentenceCase(record.stage.name)}
      </Tag>
    );
  } else {
    return <></>;
  }
};

const sanitizeForMobile = (text: string) => {
  if (text.length > 32 && isMobile) {
    return text.substring(0, 31) + ' ...';
  } else if (text.length > 40 && !isMobile) {
    return (
      <Tooltip title={text} mouseEnterDelay={1}>
        <span>{text.substring(0, 40) + ' ...'}</span>
      </Tooltip>
    );
  } else {
    return text;
  }
};

type RecordSelectPanelProps = {
  title: string;
  records: DbRecordEntityTransform[];
  selectedRecords: DbRecordEntityTransform[];
  enableSearch?: boolean;
  loading?: boolean;
  onRecordClicked?: (record: DbRecordEntityTransform) => void;
  style?: React.CSSProperties;
  recordListStyle?: React.CSSProperties;
};

type UseOdinPaginationParams = {
  pageSizeOptions: number[];
}
const useOdinPagination = ({ pageSizeOptions }: UseOdinPaginationParams) => {
  const [pageSize, setPageSize] = useState<number>(pageSizeOptions[0]);
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onPaginationChange = (e: number) => {
    setCurrentPage(e);
  };

  const onShowSizeChange: PaginationProps['onShowSizeChange'] = (current, pageSize) => {
    setPageSize(pageSize);
  };

  const getPageData = (records: DbRecordEntityTransform[]) => records.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return {
    pageSizeOptions: pageSizeOptions.map(String),
    pageSize,
    currentPage,
    onPaginationChange,
    onShowSizeChange,
    getPageData,
  };
};

export const RecordSelectPanel = (props: RecordSelectPanelProps) => {
  const paginationProps = useOdinPagination({ pageSizeOptions: [100, 200, 500] });
  const recordsToDisplay = paginationProps.getPageData(props.records);
  const showDSStatusTag = true;
  const renderRightElement = (_r: DbRecordEntityTransform) => null;
  const customRecordRowTitleElements = ['JiraProjectKey', 'type', 'title'];
  const multipleSelect = false;
  const showRecordStageTag = true;

  const renderRecordInfo = (record: DbRecordEntityTransform) => {
    if (!record) return <></>;
    return (
      <Descriptions
        className="collaboratorsCard"
        bordered
        size="small"
        labelStyle={{ color: 'black', fontSize: 12, padding: '2px 7px' }}
        contentStyle={{ fontSize: 12, padding: '2px 7px' }}
        column={2}
        style={{ marginRight: '5px' }}
      >
        <Descriptions.Item key={`${record.id}-CreatedAt`} label="Created At" span={2}>
          {DateTime.fromISO(record.createdAt as any).toFormat('d/M/yyyy h:mm a ZZZZ')}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const renderRecordDetails = (record: DbRecordEntityTransform) => {
    return (
      <Descriptions
        className="collaboratorsCard"
        bordered
        size="small"
        labelStyle={{ color: 'black', fontSize: 12, padding: '2px 7px' }}
        contentStyle={{ fontSize: 12, padding: '2px 7px' }}
        column={2}
        style={{ marginRight: '5px' }}
      >
        <Descriptions.Item key={`${record.id}-Description`} label="Description" span={2}>
          {getProperty(record, 'Description')}
        </Descriptions.Item>
      </Descriptions>
    );
  };

  const renderRecordData = (record: DbRecordEntityTransform) => {
    const customTitle = getCustomRecordTitle(record, customRecordRowTitleElements);
    const sanitizedCustomTitle = typeof customTitle === 'string' ? sanitizeForMobile(customTitle) : customTitle;

    return (
      <>
        <Row justify="space-between">
          <Col>
            <span style={{ overflowX: 'hidden', fontSize: '0.8em' }}>
              {customRecordRowTitleElements
                ? sanitizedCustomTitle
                : sanitizeForMobile(record.title!)}
            </span>

            {/* DS_Status Tag */}
            {showDSStatusTag ? renderDSStatusTag(record) : <></>}
          </Col>

          {/* RIGHT */}
          <Col style={{ textAlign: 'right' }} key={record.id}>

            <span>{renderRightElement(record)}</span>
            {/* Call Count */}

            <div style={{ display: 'flex' }}>
              {!!getProperty(record, 'CallCount') && !multipleSelect ? (
                <Tag color="red" style={{ padding: '3px 3px', lineHeight: 0 }}>
                  <i style={{ fontSize: '0.8em', marginRight: 2 }} className="bi bi-telephone-fill" />
                  <span>{getProperty(record, 'CallCount')}</span>
                </Tag>
              ) : (
                // <Badge style={{ marginLeft: 8 }} count={getProperty(record, 'CallCount') || 0} />
                <></>
              )}
              {renderRecordInfo(record)}
              {showRecordStageTag ? renderRecordStageTag(record) : <></>}
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: '5px' }}>
          <Col>{renderRecordDetails(record)}</Col>
        </Row>
      </>
    );
  };

  const isRecordSelected = (record: DbRecordEntityTransform) => props.selectedRecords.some(r => r.id === record.id);
  return (
    <Card
      className="DataSetBuilderPanelRow"
      title={props.title}
      style={props.style}
    >
      <Row>
        <Col span={24}>
          <HTMLTable
            interactive
            className="rfcs-table"
          >
            <tbody style={props.recordListStyle}>
            {props.loading ? (
              <tr>
                <td>
                  <Spinner />
                </td>
              </tr>
            ) : (
              recordsToDisplay.map(record => (
                <tr
                  key={record.id}
                  className={isRecordSelected(record) ? 'selected' : ''}
                  onClick={() => props.onRecordClicked?.(record)}
                >
                  <td>{renderRecordData(record)}</td>
                </tr>
              ))
            )}
            </tbody>
          </HTMLTable>
        </Col>
        <Col span={24} style={{ textAlign: 'right', marginTop: 15 }}>
          <Pagination
            size="small"
            disabled={props.loading}
            total={props.records.length}
            pageSizeOptions={paginationProps.pageSizeOptions}
            current={paginationProps.currentPage}
            pageSize={paginationProps.pageSize}
            onChange={paginationProps.onPaginationChange}
            onShowSizeChange={paginationProps.onShowSizeChange}
            showSizeChanger
          />
        </Col>
      </Row>
    </Card>
  );
};
