import { Button, Section } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Modal, Row, Spin } from 'antd';
import React, { useContext } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ClosureFiberSplicingTable from '../../Autosplicing/components/ClosureFiberSplicingTable';
import ProjectModuleIntersectingPIA from '../../components/ProjectModuleIntersectingPIA';
import PIAAssociationBuilder from '../../components/PIAAssociationBuilder';
import PTAListAndCreateCard from '../../components/PTAListAndCreateCard';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import UploadFileFromAssociation from '@legacy/core/recordsAssociations/components/UploadFileFromAssociation';
import { getModuleAndEntityNameFromRecord } from '../../../../../core/helpers/recordHelpers';
import { DetailViewContext } from '../../../../../core/components/DetailViewContextProvider';
import NoteFeed from '../../../../../core/components/NoteFeed';
import DetailView from '../../../../../core/views/DetailView';

interface Props {
  recordReducer: IRecordReducer;
}

const { PROJECT_MODULE } = SchemaModuleTypeEnums;

const FeatureDetailView: React.FC<Props> = (props: Props) => {
  const { recordReducer } = props;

  const { record, schema } = useContext(DetailViewContext);

  const getSplicingPath = (record: DbRecordEntityTransform) => {
    let path: string = '';
    if (record && record.type === 'CLOSURE') {
      const externalRef = getProperty(record, 'ExternalRef');
      if (externalRef) {
        path = `/${PROJECT_MODULE}/Connection/${externalRef}`;
      }
    }
    return path;
  };

  const getConfigureClosurePath = (record: DbRecordEntityTransform) => {
    let path: string = '';
    if (record && record?.type === 'CLOSURE') {
      path = `/${PROJECT_MODULE}/Feature/${record?.id}/configure-closure`;
    }
    return path;
  };

  const getViewInMapPath = (record: DbRecordEntityTransform) => {
    let path: string = '';
    let recordType: string = '';

    if (record && getProperty(record!, 'ExternalRef')) {
      const moduleEntity = getModuleAndEntityNameFromRecord(record);
      if (record.type) {
        recordType = record?.type;
      } else {
        if (moduleEntity && moduleEntity.entityName === 'Project') {
          recordType = 'polygon';
        }
      }
      if (recordType.length) {
        path = `/${PROJECT_MODULE}/Map/${recordType}/${getProperty(record, 'ExternalRef')}`;
      }
    }
    return path;
  };

  const canAccessBuildPack = (record: DbRecordEntityTransform) => {
    if (record && getProperty(record, 'ExternalRef')) {
      const moduleEntity = getModuleAndEntityNameFromRecord(record);
      return moduleEntity && moduleEntity.entityName === 'Project';
    } else {
      return false;
    }
  };

  const headerActions = () => {
    let response: JSX.Element[] = [];

    // AUTO SPLICING (Only L0 and L2 closures)
    if (
      getSplicingPath(record!).length > 0 &&
      [1, 3].includes(Number(getProperty(record, 'ClosureType')))
    ) {
      response.push(
        <Link target="_blank" to={getSplicingPath(record)}>
          <Button text="Auto Splicing" intent="primary" outlined />
        </Link>,
      );
    }

    // CONFIGURE CLOSURE (Only L0 closures)
    if (
      getConfigureClosurePath(record!).length > 0 &&
      [1].includes(Number(getProperty(record, 'ClosureType')))
    ) {
      response.push(
        <Link target="_blank" to={getConfigureClosurePath(record)}>
          <Button text="Cable Connections" intent="primary" outlined />
        </Link>,
      );
    }

    // VIEW IN MAP
    if (getViewInMapPath(record!).length > 0) {
      response.push(
        <Link target="_blank" to={getViewInMapPath(record)}>
          <Button text="View in Map" intent="primary" outlined />
        </Link>,
      );
    }

    // BUILD PACK
    if (canAccessBuildPack(record!)) {
      response.push(
        <Link target="_blank" to={`/${PROJECT_MODULE}/BuildPack/${record?.id}`}>
          <Button text="Build Pack - Cabling" intent="primary" outlined />
        </Link>,
      );
    }

    return response;
  };

  return (
    <>
      <UploadFileFromAssociation />
      <Modal open={recordReducer.isExportingAssociations} centered={true} footer={null}>
        <Spin spinning={recordReducer.isExportingAssociations}>data exporting...</Spin>
      </Modal>
      <DetailView
        headerExtras={headerActions()}
        hideNotesTab
        showGroupsInDetails
        customTabs={[
          // For BLOCKAGE type features, show association builder tab
          record?.type === 'BLOCKAGE'
            ? {
              key: 'PIAFeatures',
              tab: 'PIA Features',
            }
            : { key: '', tab: '' },
          // For CLOSURE type features, show FiberSplicing tab
          record?.type === 'CLOSURE'
            ? {
              key: 'FiberSplicing',
              tab: 'Fiber Splicing',
            }
            : { key: '', tab: '' },
        ]}
        customTabBodies={{
          FiberSplicing: (
            <ClosureFiberSplicingTable
              record={record}
              externalRef={getProperty(record, 'ExternalRef')}
            />
          ),
          PIAFeatures: <PIAAssociationBuilder record={record} />,
        }}
        rightColumn={[
          record?.type === 'CLOSURE' && getProperty(record, 'BuildStatus') === '8' ? (
            <PTAListAndCreateCard parentRecord={record!} />
          ) : (
            <></>
          ),
          <AssociationDataTable
            thumbnailSize={12}
            title="Files"
            record={record}
            showFileCategoryForType="DEFAULT"
            moduleName="SchemaModule"
            entityName="File"
            location="sidebar"
            collapsedByDefault
          />,
        ]}
        middleColumnBottom={[
          <Row>
            {/* If record type is CABLE show PIA Image Uploader  */}
            {record?.type === 'CABLE' && (
              <Col span={24} style={{ marginBottom: 15 }}>
                <ProjectModuleIntersectingPIA record={record} />
              </Col>
            )}

            {/* If record type is DUCT (Type 2-SDuct) show PIA Image Uploader  */}
            {record?.type === 'DUCT' && getProperty(record, 'DuctType') === '2' && (
              <Col span={24} style={{ marginBottom: 15 }}>
                <ProjectModuleIntersectingPIA record={record} />
              </Col>
            )}

            {/* Note Feed at the bottom of the middle section */}
            <Col span={24} style={{ marginBottom: 15 }}>
              <Section title="Notes">
                <div style={{ padding: 15 }}>
                  <NoteFeed record={record!} />
                </div>
              </Section>
            </Col>
          </Row>,
        ]}
      />
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(FeatureDetailView);
