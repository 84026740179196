import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Form, Row } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { connect } from 'react-redux';
import renderFormField, {
  FormField,
} from '@legacy/core/records/components/Forms/FormFields';
import { IAppointmentReducer } from '@legacy/core/appointments/store/reducer';
import {
  getSchemaFromShortListByModuleAndEntity,
  sortByPosition,
} from '@core/helpers/schemaHelpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
interface Props {
  saveData?: any;
  passDataToParent: any;
  appointmentReducer: IAppointmentReducer;
  schemaReducer: any;
  getSchema: Function;
}

const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

const NETOMNIA = [
  'Blockage Clearance Required',
  'Openreach Infrastructure Issues',
  'Dead CBT',
  'Connection Costs Unviable',
  'Dry Installation',
  'MDU - Should not be RFS',
  'No Clear Installation Route',
  'Overlay Required on Lead In',
  'Wayleave/PTD Required',
  'Health & Safety Concern',
  'Calendar Closed Retrospectively',
  'Engineer did not attend',
  'Calendar Overbooked - Drop Off',
  'Remediation Complete - Install Brought Forward',
  'Hoist Required',
  'Remediation not Complete - Install Delayed',
  'RFS Reversed',
  'No CBT/Network Not Complete',
  'Weather Conditions',
];
const CUSTOMER = [
  'Cancellation for Personal Reasons',
  'Request to Bring Forward',
  'Moving Premises',
  'Not Happy with External Installation Route',
  'Request to Delay',
  'Still in Contract',
  'Uncontactable/Nobody Home',
  'Unhappy with Installation Method',
  'Install Reminder Rescheduled',
  'Staying with Existing Provider',
  'Unaware of Installation',
  'Service Visit No Longer Required',
];
const YOUFIBRE = [
  'Claiming Misselling',
  'Duplicate Order / Work Order',
  'No Backhaul',
  'Area not RFS',
  'Work Order Brought Forward',
  'Install Rescheduled',
  'Missing Mandate',
  'Incorrect Address Booked',
  'Incorrect Date Booked',
];

class ChangeReasonForm extends React.Component<Props> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
  }

  componentDidMount() {
    const { schemaReducer, getSchema } = this.props;

    // Check for shortlist schema or fallback to API request
    const shortListChangeReasonSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      'ChangeReason',
    );
    if (!shortListChangeReasonSchema) {
      getSchema({ moduleName: FIELD_SERVICE_MODULE, entityName: 'ChangeReason' });
    }
  }

  constructFormFields = (col: any) => {
    const { appointmentReducer, schemaReducer } = this.props;

    const changeReasonSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      'ChangeReason',
    );

    const appointmentReducerSchemaTypeId = changeReasonSchema?.types.find(
      (item: any) => item.name == appointmentReducer.schemaType,
    )?.id;

    if (col?.schemaTypeId && col?.schemaTypeId !== appointmentReducerSchemaTypeId) {
      return;
    }

    let options = col.options;

    if (col.name === 'CancelReason' || col.name === 'RescheduleReason') {
      let filter: any;
      if (this.formRef.current?.getFieldsValue().RequestedBy === 'NETOMNIA') {
        filter = NETOMNIA;
      } else if (this.formRef.current?.getFieldsValue().RequestedBy === 'CUSTOMER') {
        filter = CUSTOMER;
      } else if (this.formRef.current?.getFieldsValue().RequestedBy === 'YOUFIBRE') {
        filter = YOUFIBRE;
      }

      options = options?.filter((array: any) => filter?.some((el: any) => el === array.label));
    }

    const initialValue = null;

    const field: FormField = {
      id: col.id ? col.id.toString() : col.name,
      schemaId: undefined,
      entity: 'ChangeReason',
      type: col.type,
      isHidden: col.isHidden ? col.isHidden : false,
      name: col.name,
      label: col.label || col.name,
      description: col.description,
      options: options,
      validators: col.validators,
      defaultValue: !initialValue ? col.defaultValue : null,
      initialValue,
      isDisabled: false,
      handleInputChange: this.handleInputChange,
    };
    return renderFormField(field);
  };

  handleInputChange = async (params: any) => {
    const { saveData, passDataToParent } = this.props;
    try {
      await this.formRef.current?.validateFields();
      const formErrors = this.formRef.current ? this.formRef.current.getFieldsError() : [];
      const hasErrors = formErrors.filter(({ errors }) => errors.length).length > 0;
      if (
        !hasErrors &&
        this.formRef.current?.getFieldsValue()?.Reason !== 'select cancellation reason'
      ) {
        passDataToParent(false);
        saveData(this.formRef.current?.getFieldsValue());
      } else {
        passDataToParent(true);
      }
    } catch (e) {
      passDataToParent(true);
      console.error(e);
    }
  };

  renderForm() {
    const changeReasonSchema = getSchemaFromShortListByModuleAndEntity(
      this.props.schemaReducer.shortList,
      FIELD_SERVICE_MODULE,
      'ChangeReason',
    );

    return (
      <Form
        style={{ maxHeight: 500, overflow: 'auto' }}
        ref={this.formRef}
        autoComplete="off"
        key={changeReasonSchema?.id}
        name={changeReasonSchema?.id}
      >
        <Row>
          {changeReasonSchema?.columns?.sort(sortByPosition).map((data: any) => (
            <Col>{this.constructFormFields(data)}</Col>
          ))}
        </Row>
      </Form>
    );
  }

  render() {
    return <>{this.renderForm()}</>;
  }
}

const mapState = (state: any) => ({
  appointmentReducer: state.appointmentReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

// @ts-ignore
export default connect(mapState, mapDispatch)(ChangeReasonForm);
