import React from 'react';
import './styles.scss';

interface Props {
  type: string;
  small?: boolean;
}

const CommunicationsIcon: React.FC<Props> = (props: Props) => {
  const { type, small } = props;
  const iconStyle = { fontSize: small ? '0.8em' : '1.1em' };

  const renderIcon = () => {
    switch (type) {
      case 'CALL':
        return <i className="bi bi-telephone" style={iconStyle} />;
      case 'WEB_CHAT':
        return <i className="bi bi-chat-right-dots" style={iconStyle} />;
      case 'SMS':
        return <i className="bi bi-chat-right-dots" style={iconStyle} />;
      case 'EMAIL':
        return <i className="bi bi-envelope" style={iconStyle} />;
      default:
        return <i className="bi bi-headset" style={iconStyle} />;
    }
  };

  return <div className={`communicationsIcon ${type} ${small ? 'small' : ''}`}>{renderIcon()}</div>;
};
export default CommunicationsIcon;
