import React, {ReactElement, useEffect, useState} from "react";
import {Col, Row} from "antd";
import {Menu, MenuDivider, MenuItem} from "@blueprintjs/core";
import {RouteComponentProps, withRouter} from "react-router-dom";

import SchemasListView from "../Schemas/SchemasListView";
import "./styles.scss";
import {connect} from "react-redux";
import {ISchemaReducer} from "@legacy/core/schemas/store/reducer";

type Props = RouteComponentProps & {
  schemaReducer: ISchemaReducer;
};

const ControlPanelView: React.FC<Props> = (props: Props) => {
  const [favoriteSchemas, setFavoriteSchemas] = useState<string[]>([]);
  const {history, location, schemaReducer} = props;

  useEffect(() => {
    fetchFavoriteSchemas();
  }, []);

  const fetchFavoriteSchemas = async () => {
    const favorites = await localStorage.getItem("OdinSchemaFavorites");
    if (favorites) {
      setFavoriteSchemas(JSON.parse(favorites));
    }
  };

  const renderFavoriteSchemas = () => {
    let favorites: ReactElement[] = [];

    favoriteSchemas.map((schemaId: string) => {
      const schema = schemaReducer.list.find((schema) => schema.id === schemaId);

      if (schema) {
        favorites.push(
          <MenuItem
            text={schema.name}
            onClick={() => {
              history.push(`/ControlPanelModule/SchemaManager/${schema.id}`);
            }}
          />
        );
      }
    });

    // Sort favorites by schema.name
    favorites = favorites.sort((a, b) => a.props.text.localeCompare(b.props.text));

    return favorites;
  };

  return (
    <Row>
      {/* Navbar */}
      <Col
        span={4}
        style={{
          padding: 5,
          borderRight: "1px solid #DCDCDD",
          overflowY: "auto"

        }}
      >
        <Menu style={{background: "transparent"}}>
          <MenuItem
            className="verticalMenuSubitem"
            text="Schema List"
            onClick={() => history.push("/ControlPanelModule/SchemaManager/")}
            active={location.pathname === "/ControlPanelModule/SchemaManager/"}
          />

          {favoriteSchemas.length > 0 && (
            <div style={{maxHeight: "calc(100vh - 88px)", overflowY: "auto", marginTop: 8, overflowX: "hidden"}}>
              <MenuDivider title="Favorites" />
              <div style={{marginBottom: 7}} />
              {renderFavoriteSchemas()}
            </div>
          )}
        </Menu>
      </Col>

      {/* Content */}
      <Col span={20}>
        <SchemasListView
          onSchemaSelected={(schemaId) =>
            history.push(`/ControlPanelModule/SchemaManager/${schemaId}`)
          }
        />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(withRouter(ControlPanelView));
