import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { createContext, FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { getViewConfigurations } from '@core/modules/SchemaManagerModule/components/ViewConfigurations/api';

interface Props {
  children: any;
  record: DbRecordEntityTransform;
  schema: SchemaEntity;
  pipeline: PipelineEntity | undefined;
  recordReducer: IRecordReducer;
  hasColumnMapping?: boolean;
}

export const DetailViewContext = createContext<any>({});

const DetailViewContextProvider: FC<Props> = (props: Props) => {
  const { record, hasColumnMapping, schema, pipeline } = props;
  const [viewConfigurations, setViewConfigurations] = useState<SchemaActionEntity[]>([]);
  const [isLoadingConfigurations, setIsLoadingConfigurations] = useState<boolean>(false);

  // Get View Configurations when schema available
  useEffect(() => {
    fetchViewConfigs();
  }, [schema]);

  const fetchViewConfigs = () => {
    if (schema) {
      setIsLoadingConfigurations(true);
      getViewConfigurations(schema.id)
        .then((res: any) => {
          setIsLoadingConfigurations(false);
          if (res) {
            filterViewConfigurations(res);
          }
        })
        .catch((error: any) => {
          setIsLoadingConfigurations(false);
          throw new Error(error.response?.data?.message ?? error.message);
        });
    }
  };

  const filterViewConfigurations = (actions: SchemaActionEntity[]) => {
    if (record && schema) {
      let filteredConfigs = Object.assign(actions);

      filteredConfigs = filteredConfigs.map((config: SchemaActionEntity) => {
        if (config.stages && !config.stages?.includes(record.stage?.id)) {
          return undefined;
        } else {
          return config;
        }
      });

      // If record has a type, remove configs that are not matching the record type
      const recordType = record?.type;
      if (recordType) {
        const schemaType = schema.types.find((type) => type.name === recordType);
        if (schemaType) {
          filteredConfigs = filteredConfigs.filter((config: SchemaActionEntity) => {
            if (config?.schemaTypeId && config?.schemaTypeId !== schemaType?.id) {
              return false;
            } else {
              return config;
            }
          });
        }
      }
      setViewConfigurations(filteredConfigs);
    }
  };

  return (
    <DetailViewContext.Provider
      value={{
        viewConfigurations,
        isLoadingConfigurations,
        schema,
        record,
        pipeline,
        hasColumnMapping,
      }}
    >
      {props.children || <></>}
    </DetailViewContext.Provider>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(DetailViewContextProvider);
