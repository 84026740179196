import React, { useEffect, useState } from 'react';
import { Col, Image, Row, Spin as AntdSpinner } from 'antd';
import {
  Button,
  ButtonGroup,
  Classes,
  Dialog,
  DialogBody,
  DialogFooter,
  Drawer,
  HTMLTable,
  Icon,
  InputGroup,
  MenuItem,
  NonIdealState,
  Section,
  Spinner,
  Tag,
  Tooltip,
} from '@blueprintjs/core';
import { LINEAR_D19_TEAM_ID, LINEAR_FIFO_PROJECT_ID, TLinearIssueBody } from './constants';
import dayjs from 'dayjs';
import './styles.scss';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { connect } from 'react-redux';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import SharedFormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';
import { v4 as uuidv4 } from 'uuid';
import { linearTicketFormFields } from './linearTicketFormFields';
import Axios from 'axios';
import { hasPermissions, isSystemAdmin } from '@core/helpers/rbacRules';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { httpGet } from '@core/http/requests';
import { isMobile } from 'react-device-detect';
import Markdown from 'markdown-to-jsx';

const uuid = uuidv4();
const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const { FILE } = SchemaModuleEntityTypeEnums;

interface Props {
  formReducer: SharedFormReducer;
  initializeForm: any;
  userReducer: any;
  navigationReducer: any;
}

type TLinearLabel = {
  id: string;
  name: string;
  color: string;
};

type TLinearIssue = {
  id: string;
  identifier: string;
  title: string;
  description: string;
  priorityLabel: string;
  priority: number;
  createdAt: string;
  updatedAt: string;
  state:
    | {
    id: string;
    name: string;
    color: string;
  }
    | undefined;
  labels:
    | {
    nodes: TLinearLabel[];
  }
    | undefined;
};

const LinearIssues: React.FC<Props> = (props: Props) => {
  const { initializeForm, navigationReducer, formReducer, userReducer } = props;
  const [isLoadingIssues, setIsLoadingIssues] = useState<boolean>(false);
  const [issues, setIssues] = useState<TLinearIssue[]>([]);
  const [linearLabels, setLinearLabels] = useState<{ id: string; name: string }[]>([]);
  const [linearStates, setLinearStates] = useState<{ id: string; name: string }[]>([]);
  const [labelFilter, setLabelFilter] = useState<string>('All Labels');
  const [requestedByFilter, setRequestedByFilter] = useState<string>('All Requesters');
  const [textFilter, setTextFilter] = useState<string>('');
  const [isCreatingIssue, setIsCreatingIssue] = useState<boolean>(false);
  const [fileSchema, setFileSchema] = useState<SchemaEntity | undefined>(undefined);
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [selectedIssue, setSelectedIssue] = useState<TLinearIssue | undefined>(undefined);
  const [uniqueRequesters, setUniqueRequesters] = useState<string[]>([]);
  const [uniqueLabels, setUniqueLabels] = useState<{ name: string; id: string }[]>([]);
  const [isDeleteAlertVisible, setIsDeleteAlertVisible] = useState<boolean>(false);
  const [isDeletingIssue, setIsDeletingIssue] = useState<boolean>(false);
  const [isUpdatingIssue, setIsUpdatingIssue] = useState<boolean>(false);

  // Pagination state
  const paginationSize = 100;
  const [totalIssues, setTotalIssues] = useState<number>(0);
  const [hasNextPage, setHasNextPage] = useState<boolean>(false);
  const [hasPreviousPage, setHasPreviousPage] = useState<boolean>(false);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [isLoadingSelectedIssue, setIsLoadingSelectedIssue] = useState<boolean>(false);

  // Fetch team data on component mount
  useEffect(() => {
    getTeam();
    getIssues({ direction: 'NEXT' });
  }, []);

  // Get File Schema on component mount
  useEffect(() => {
    getSchemas();
  }, []);

  const getSchemas = async () => {
    const schema = await getOdinSchemaByEntity(SCHEMA_MODULE, FILE);
    setFileSchema(schema);
  };

  // Get All or filtered issues
  const getIssues = (params: {
    direction: 'PREVIOUS' | 'NEXT';
    labelName?: string;
    requesterName?: string;
    overridePagination?: boolean;
  }) => {
    setIsLoadingIssues(true);

    const { direction, labelName, requesterName, overridePagination } = params;

    let queryAfter: string | null = null;
    let queryBefore: string | null = null;

    if (!overridePagination) {
      queryAfter = direction === 'NEXT' ? endCursor : null;
      queryBefore = direction === 'NEXT' ? null : startCursor;
    }

    Axios.post(
      'https://api.linear.app/graphql',
      {
        query: `query Issues($projectId: ID!, $first: Int, $last: Int, $before: String,  $after: String, $labelName: ID, $requesterName: String) {
        issues(
          sort: {
            createdAt: {
              order: Ascending
            }
          }
          first: $first
          last: $last
          after: $after
          before: $before
          filter: {
            project: {
              id: {
                eq: $projectId
              }
            }
            labels: {
              id: {
                  eq: $labelName
              }
            }
            description: {
              containsIgnoreCase: $requesterName
            }
          }
        ) {
          nodes {
            id
            identifier
            title
            description
            priorityLabel
            priority
            state {
              id
              name
              color
            }
            labels {
              nodes {
                id
                name
                color
              }
            }
            createdAt
            updatedAt
          }
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
        }
      }`,
        variables: {
          first: direction === 'NEXT' ? paginationSize : undefined,
          last: direction === 'NEXT' ? undefined : paginationSize,
          after: queryAfter,
          before: queryBefore,
          projectId: LINEAR_FIFO_PROJECT_ID,
          labelName: labelName,
          requesterName: requesterName ? `\\[${requesterName}` : null,
        },
      },
      {
        headers: {
          Authorization: `${import.meta.env.VITE_LINEAR_API_KEY}`,
        },
      },
    ).then((response: any) => {
      setIsLoadingIssues(false);
      if (response) {
        const issues: TLinearIssue[] = response.data.data?.issues?.nodes || [];
        const pageInfo: any = response.data?.data?.issues?.pageInfo;

        pageInfo?.hasNextPage ? setHasNextPage(true) : setHasNextPage(false);
        pageInfo?.hasPreviousPage ? setHasPreviousPage(true) : setHasPreviousPage(false);

        const startCursor = pageInfo?.startCursor || null;
        const endCursor = pageInfo?.endCursor || null;

        if (direction === 'NEXT') {
          setStartCursor(startCursor);
          setEndCursor(endCursor);
        } else {
          setStartCursor(endCursor);
          setEndCursor(startCursor);
        }

        setIssues(issues);

        // Get a unique list of requested by, we need this for table filtering.
        let uniqueRequestedBy: string[] = [
          ...new Set(
            issues.map((issue: TLinearIssue) => getRequestedByFromDescription(issue.description)),
          ),
        ];
        uniqueRequestedBy.sort((a: any, b: any) => a.localeCompare(b.title));
        setUniqueRequesters(uniqueRequestedBy);
      }
    });
  };

  const deleteIssue = (issue: TLinearIssue) => {
    setIsDeletingIssue(true);
    Axios.post(
      'https://api.linear.app/graphql',
      {
        query: `mutation IssueDelete($issueId: String!)  {
            issueDelete(
              id: $issueId
            ) {
              success
            }
          }`,
        variables: {
          issueId: issue.id,
        },
      },
      {
        headers: { Authorization: `${import.meta.env.VITE_LINEAR_API_KEY}` },
      },
    )
      .then((response: any) => {
        if (response) {
          // Remove the issue from the list by id
          const updatedIssues = issues.filter((item: TLinearIssue) => item.id !== issue.id);
          setIssues(updatedIssues);
          setIsDeletingIssue(false);
          setIsDeleteAlertVisible(false);
          setIsDrawerOpen(false);
          setSelectedIssue(undefined);
        }
      })
      .catch((error: any) => {
        setIsDeletingIssue(false);
      });
  };

  // Retrieve Linear Team Data. Within Team data, there will be a list
  // of all labels and states that are available inside this project.
  const getTeam = () => {
    Axios.post(
      'https://api.linear.app/graphql',
      {
        query: `query Team($teamId: String!, $projectId: ID!) {
          team(
            id: $teamId
          ) {
              id
              name
              labels {
                nodes {
                  id
                  name
                }
              }
              projects(
                filter: {
                  id: {
                    eq: $projectId
                  }
                }
              ) {
                nodes {
                  id
                  name
                  scope
                }
              }
              states {
                nodes {
                  id
                  name
                }
              }
            }
          }`,
        variables: {
          teamId: LINEAR_D19_TEAM_ID,
          projectId: LINEAR_FIFO_PROJECT_ID,
        },
      },
      {
        headers: {
          Authorization: `${import.meta.env.VITE_LINEAR_API_KEY}`,
        },
      },
    ).then((response: any) => {
      const labels = response?.data?.data?.team?.labels?.nodes || [];
      const states = response?.data?.data?.team?.states?.nodes || [];
      const project = response?.data?.data?.team?.projects?.nodes[0] || undefined;

      // Get a unique list of label names from each issue, we need this for table filtering.
      const uniqueLabels: { name: string; id: string }[] =
        labels.filter((label: any) => label.name === 'YouFibre' || label.name == 'Netomnia') || [];
      uniqueLabels.sort((a, b) => a.name.localeCompare(b.name));
      setUniqueLabels(uniqueLabels);

      if (labels.length > 0) setLinearLabels(labels);
      if (states.length > 0) setLinearStates(states);
      if (project) {
        setTotalIssues(project.scope);
      }
    });
  };

  // Parse string and return string enclosed with [] brackets, if found. Also, remove all backslashes.
  const getRequestedByFromDescription = (description: string) => {
    const requestedBy = description?.match(/\[(.*?)\]/)?.[1]?.replace(/\\/g, '') || 'Unknown';
    return requestedBy;
  };

  // Label Select Renderer
  const renderLabelSelect: ItemRenderer<any> = (event, { handleClick, handleFocus, modifiers }) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={labelFilter !== 'All Labels' && labelFilter === event.value}
        disabled={modifiers.disabled}
        key={event.rank}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={event.label}
      />
    );
  };

  // Requested By Select Renderer
  const renderRequestedSelect: ItemRenderer<any> = (
    event,
    { handleClick, handleFocus, modifiers },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={requestedByFilter !== 'All Requesters' && requestedByFilter === event.value}
        disabled={modifiers.disabled}
        key={event.rank}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={event.label}
      />
    );
  };

  // FILTERS /////////////////////////////////////////////////////////////////////////////
  let filteredIssues: TLinearIssue[] = Object.assign([], issues);

  // Sort by Date
  filteredIssues.sort((a: any, b: any) => {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  });

  // If a text filter is entered, filter the issues by the entered text
  if (textFilter.length > 0) {
    filteredIssues = issues.filter(
      (issue: any) =>
        issue.identifier.toLowerCase().includes(textFilter.toLowerCase()) ||
        issue.title.toLowerCase().includes(textFilter.toLowerCase()),
    );
  }

  // Filter used for  grouping issues by state and sorting each group

  // Split the issues into groups based on their state.name.
  // This will allow us to sort the issues within each group.
  // const groupedIssues: any = {};
  // filteredIssues.forEach((issue: any) => {
  //   if (!groupedIssues[issue.state?.name]) {
  //     groupedIssues[issue.state?.name] = [];
  //   }
  //   groupedIssues[issue.state?.name].push(issue);
  // });
  // Sort the issues within each group by their createdAt date.
  // Object.keys(groupedIssues).forEach((key) => {
  //   groupedIssues[key].sort((a: any, b: any) => {
  //     return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  //   });
  // });
  // Merge the sorted groups back into a single array. Make the "In Progress" group come first. Make the "Code Complete" come second. Put all others in between. Put the "Backlog" group last.
  // filteredIssues = [];
  // if (groupedIssues['In Progress']) {
  //   filteredIssues = filteredIssues.concat(groupedIssues['In Progress']);
  // }
  // if (groupedIssues['Code Complete']) {
  //   filteredIssues = filteredIssues.concat(groupedIssues['Code Complete']);
  // }
  // Object.keys(groupedIssues).forEach((key) => {
  //   if (key !== 'In Progress' && key !== 'Code Complete' && key !== 'Backlog') {
  //     filteredIssues = filteredIssues.concat(groupedIssues[key]);
  //   }
  // });
  // if (groupedIssues['Backlog']) {
  //   filteredIssues = filteredIssues.concat(groupedIssues['Backlog']);
  // }

  // Go through all tickets, and get unique values as options for the create ticket form
  let uniqueLabelOptions: { value: string; label: string }[] = [];
  linearLabels.forEach((label: { id: string; name: string }) => {
    uniqueLabelOptions.push({
      value: label.id,
      label: label.name,
    });
  });

  // Label Filter Menu Items
  const getLabelFilters = (): any[] => {
    let menuItems = uniqueLabels.map((label: { name: string; id: string }) => ({
      label: label.name,
      value: label.id,
      key: label.id,
    }));
    menuItems.unshift({
      label: 'All Labels',
      value: 'All Labels',
      key: 'All Labels',
    });
    return menuItems;
  };

  // Requested By Filter Menu Items
  const getRequestedByFilters = (): any[] => {
    let menuItems = uniqueRequesters.map((requestedBy: any) => ({
      label: requestedBy,
      value: requestedBy,
      key: requestedBy,
    }));
    menuItems.unshift({
      label: 'All Requesters',
      value: 'All Requesters',
      key: 'All Requesters',
    });
    return menuItems;
  };

  // Initialize Create Ticket Form
  const showCreateIssueForm = () => {
    const fullName = `${userReducer?.user?.firstname} ${userReducer?.user?.lastname}`;

    // Get default label id based on user's organization
    let defaultLabelId = '';
    const organizationName = userReducer?.user?.organization?.name || '';

    const labelOptions: { value: string; label: string }[] =
      linearLabels?.map((label: { id: string; name: string }) => ({
        value: label.id,
        label: label.name,
      })) || [];

    if (organizationName === 'YouFibre') {
      defaultLabelId = linearLabels?.find((label: any) => label.name === 'YouFibre')?.id || '';
    } else if (organizationName === 'Netomnia Limited') {
      defaultLabelId = linearLabels.find((label: any) => label.name === 'Netomnia')?.id || '';
    }

    initializeForm({
      formUUID: uuid,
      title: 'Create Issue',
      showModal: true,
      formFields: linearTicketFormFields(
        fullName,
        labelOptions,
        defaultLabelId,
        organizationName !== 'D19N',
      ),
      isCreateReq: true,
      isUpdateReq: false,
    });
  };

  // Initialize Edit Ticket Form
  const showEditIssueForm = () => {
    const fullName = `${userReducer?.user?.firstname} ${userReducer?.user?.lastname}`;

    // Get default label id based on user's organization
    let defaultLabelId = selectedIssue?.labels?.nodes[0]?.id || '';

    const labelOptions: { value: string; label: string }[] =
      linearLabels?.map((label: { id: string; name: string }) => ({
        value: label.id,
        label: label.name,
      })) || [];

    // Sanitize description by removing bracketed names
    const description = selectedIssue?.description || '';
    const sanitizedDescription = removeBracketedNames(description);

    initializeForm({
      formUUID: uuid,
      title: 'Edit Issue',
      showModal: true,
      formFields: linearTicketFormFields(
        fullName,
        labelOptions,
        defaultLabelId,
        true,
        sanitizedDescription,
        selectedIssue?.title,
        String(selectedIssue?.priority),
      ),
      isCreateReq: false,
      isUpdateReq: true,
    });
  };

  // Once Create ticket form is submitted, create a new ticket in Linear
  const handleFormSubmitEvent = (params: FormReducerSubmitEvt) => {
    if (params.data) {
      const body: TLinearIssueBody = {
        title: params.data.title,
        description: params.data.description,
        name: params.data.name,
        label: params.data.label,
        priority: params.data.priority,
        files: params.data.files,
      };
      if (body) {
        constructTicketPayload(body);
      }
    }
  };

  const constructTicketPayload = (body: TLinearIssueBody) => {
    if (selectedIssue) {
      setIsUpdatingIssue(true);
    } else {
      setIsCreatingIssue(true);
    }

    try {
      let description = `[${body.name}] \n\n ${body.description}`;

      // a) There are file uploads in the form. Fetch all files by id, and iterate
      //    over results to get file URLs. Once all file URLs are fetched, create a ticket
      if (body.files?.length > 0) {
        const fileIds = body.files.split(',');
        Promise.allSettled(
          fileIds.map(async (fileId: string) => {
            let path =
              fileSchema && fileSchema.getUrl
                ? fileSchema.getUrl.replace('{entityName}', fileSchema.entityName)
                : '';
            path = path.replace('{recordId}', fileId);

            return await httpGet(path);
          }),
        ).then((res: any) => {
          const successes = res.filter(
            (item: any) => item.status === 'fulfilled' && item.value?.data?.successful,
          );

          if (successes.length > 0) {
            let fileUrls: string[] = [];

            successes.forEach((item: any) => {
              const fileURL = item.value?.data?.data?.properties.Url;
              if (fileURL) {
                fileUrls.push(fileURL);
              }
            });

            // If there are files, append them to description as
            // markdown URLs and create the ticket
            if (fileUrls.length > 0) {
              fileUrls.forEach((url: string) => {
                description += `\n\n ![File](${encodeURI(url)})`;
              });
            }

            // Update request with files
            if (selectedIssue) {
              updateTicketInLinear({
                name: body.name,
                title: body.title,
                description: description,
                label: body.label,
                files: body.files,
                priority: body.priority,
              });
            }
            // Create request with files
            else {
              postTicketToLinear({
                name: body.name,
                title: body.title,
                description: description,
                label: body.label,
                files: body.files,
                priority: body.priority,
              });
            }
          } else {
            setIsCreatingIssue(false);
            throw new Error('Failed to fetch files');
          }
        });
      }
      // b) No File uploads, just create the ticket with existing data
      else {
        // Update request without files
        if (selectedIssue) {
          updateTicketInLinear({
            name: body.name,
            title: body.title,
            description: description,
            label: body.label,
            files: body.files,
            priority: body.priority,
          });
        }
        // Create request with files
        else {
          postTicketToLinear({
            name: body.name,
            title: body.title,
            description: description,
            label: body.label,
            files: body.files,
            priority: body.priority,
          });
        }
      }
    } catch (error: any) {
    }
  };

  // Create a new issue in Linear with raw GraphQL using axios
  const postTicketToLinear = (body: TLinearIssueBody) => {
    const BacklogStateId = linearStates.find((state: any) => state.name === 'Backlog')?.id || '';

    // Create a new issue in Linear with raw GraphQL using axios with this mutation
    Axios.post(
      'https://api.linear.app/graphql',
      {
        query: `mutation IssueCreate($title:String!, $description:String!, $teamId:String!, $projectId: String!, $labelIds: [String!], $stateId: String!, $priority: Int!)  {
          issueCreate(
            input: {
              title: $title
              description: $description
              teamId: $teamId
              projectId: $projectId
              labelIds: $labelIds
              stateId: $stateId
              priority: $priority
            }
          ) {
            success
            issue {
              id
              identifier
              title
              description
              priorityLabel
              priority
              state {
                id
                name
                color
              }
              labels {
                nodes {
                  id
                  name
                  color
                }
              }
              createdAt
              updatedAt
            }
          }
        }`,
        variables: {
          title: body.title,
          description: body.description,
          teamId: LINEAR_D19_TEAM_ID,
          projectId: LINEAR_FIFO_PROJECT_ID,
          labelIds: [body.label],
          stateId: BacklogStateId,
          priority: Number(body.priority),
        },
      },
      {
        headers: { Authorization: `${import.meta.env.VITE_LINEAR_API_KEY}` },
      },
    ).then((response: any) => {
      if (response) {
        const issue = response?.data?.data?.issueCreate?.issue || undefined;
        if (issue) {
          setIssues([issue, ...issues]);
        }
        setIsCreatingIssue(false);
      }
    });
  };

  // Update an issue in Linear with raw GraphQL using axios
  const updateTicketInLinear = (body: TLinearIssueBody) => {
    Axios.post(
      'https://api.linear.app/graphql',
      {
        query: `mutation IssueUpdate($issueId: String!, $title:String!, $description:String!, $teamId:String!, $projectId: String!, $labelIds: [String!], $stateId: String!, $priority: Int!)  {
          issueUpdate(
            id: $issueId,
            input: {
              title: $title
              description: $description
              teamId: $teamId
              projectId: $projectId
              labelIds: $labelIds
              stateId: $stateId
              priority: $priority
            }
          ) {
            success
            issue {
              id
              identifier
              title
              description
              priorityLabel
              priority
              state {
                id
                name
                color
              }
              labels {
                nodes {
                  id
                  name
                  color
                }
              }
              createdAt
              updatedAt
            }
          }
        }`,
        variables: {
          title: body.title,
          description: body.description,
          teamId: LINEAR_D19_TEAM_ID,
          projectId: LINEAR_FIFO_PROJECT_ID,
          labelIds: [body.label],
          stateId: selectedIssue?.state?.id,
          priority: Number(body.priority),
          issueId: selectedIssue?.id,
        },
      },
      {
        headers: { Authorization: `${import.meta.env.VITE_LINEAR_API_KEY}` },
      },
    ).then((response: any) => {
      if (response) {
        const issue = response?.data?.data?.issueUpdate?.issue || undefined;

        setIsLoadingSelectedIssue(true);

        // Update the issue in the issues array
        if (issue) {
          const updatedIssues = issues.map((item: TLinearIssue) => {
            if (item.id === selectedIssue?.id) {
              return issue;
            } else {
              return item;
            }
          });
          setIsUpdatingIssue(false);
          setIssues(updatedIssues);

          setTimeout(() => {
            setSelectedIssue(issue);
            setIsLoadingSelectedIssue(false);
          }, 2000);
        }
      }
    });
  };

  const getViewportHeight = () => {
    if (navigationReducer.tabHistory?.length > 0) {
      return '70vh';
    } else {
      return '75vh';
    }
  };

  const renderPriorityIcon = (priority: string) => {
    switch (priority) {
      case 'No priority':
        return 'bi bi-reception-0';
      case 'Low':
        return 'bi bi-reception-1';
      case 'Medium':
        return 'bi bi-reception-2';
      case 'High':
        return 'bi bi-reception-4';
      case 'Urgent':
        return 'bi bi-exclamation-square-fill';
      default:
        return 'bi bi-reception-0';
    }
  };

  function removeBracketedNames(input: string): string {
    const regex = /\\\[.*?\\\]\n\n/g;
    return input.replace(regex, '');
  }

  const resetPagination = () => {
    setStartCursor(null);
    setEndCursor(null);
    setHasNextPage(false);
    setHasPreviousPage(false);
  };

  const isOwnIssue = (issue: TLinearIssue) => {
    const name = getRequestedByFromDescription(issue.description);
    if (name === `${userReducer?.user?.firstname} ${userReducer?.user?.lastname}`) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Row style={{ marginTop: 1 }}>
      {/* Create / Update Issue Loader */}
      <Dialog
        usePortal={true}
        key={
          'createUpdateLoaderDialog' +
          JSON.stringify(isCreatingIssue) +
          JSON.stringify(isUpdatingIssue)
        }
        isOpen={isCreatingIssue || isUpdatingIssue}
        canEscapeKeyClose={false}
        canOutsideClickClose={false}
        isCloseButtonShown={false}
        title={selectedIssue ? 'Updating Issue' : 'Creating Issue'}
      >
        <Row style={{ padding: '40px 0', textAlign: 'center' }}>
          <Col span={24}>
            <Spinner />
          </Col>
          <Col span={24} style={{ paddingTop: 20 }}>
            <span>Please wait...</span>
          </Col>
        </Row>
      </Dialog>

      {/* View / Edit Issue Drawer */}
      <Dialog
        key={'deleteConfirmationDialog'}
        usePortal={true}
        title="Delete Confirmation"
        isOpen={isDeleteAlertVisible}
        onClose={() => setIsDeleteAlertVisible(false)}
      >
        <DialogBody>
          <Row>
            <Col span={24}>
              <span>Please confirm that you want to delete issue {selectedIssue?.identifier}.</span>
            </Col>
          </Row>
        </DialogBody>
        <DialogFooter
          actions={[
            <Button
              text="Cancel"
              disabled={isDeletingIssue}
              onClick={() => setIsDeleteAlertVisible(false)}
            />,
            <Button
              intent="danger"
              text="Confirm"
              onClick={() => deleteIssue(selectedIssue!)}
              loading={isDeletingIssue}
              disabled={isDeletingIssue}
            />,
          ]}
        />
      </Dialog>
      <Drawer
        usePortal={true}
        style={{ width: isMobile ? '95%' : '35%', height: '100vh' }}
        title={
          <>
            {' '}
            {isSystemAdmin(userReducer) ? (
              <a
                href={`https://linear.app/d19n/issue/${selectedIssue?.identifier}`}
                target="_blank"
                rel="noreferrer noopener"
              >
                <span>{selectedIssue?.identifier}</span>
              </a>
            ) : (
              <span>{selectedIssue?.identifier}</span>
            )}
          </>
        }
        isOpen={isDrawerOpen}
        onClose={() => {
          setIsDrawerOpen(false);
          setSelectedIssue(undefined);
        }}
      >
        <div className={Classes.DRAWER_BODY}>
          <AntdSpinner spinning={isLoadingSelectedIssue} tip="Loading Issue...">
            <Row style={{ margin: 15, overflowY: 'scroll', height: '100%' }}>
              {/* Title */}
              <Col span={24} style={{ marginBottom: 15 }}>
                <HTMLTable bordered compact className="linearTicketHTMLTable drawerTable">
                  <thead>
                  <tr>
                    <th>
                      <span>Title</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>{selectedIssue?.title}</td>
                  </tr>
                  </tbody>
                </HTMLTable>
              </Col>

              {/* Requester  */}
              <Col span={24}>
                <HTMLTable
                  bordered
                  compact
                  className="linearTicketHTMLTable drawerTable"
                  style={{ marginBottom: 15 }}
                >
                  <thead>
                  <tr>
                    <th>
                      <span>Requester</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {selectedIssue
                        ? getRequestedByFromDescription(selectedIssue?.description)
                        : ''}
                    </td>
                  </tr>
                  </tbody>
                </HTMLTable>
              </Col>

              {/* Status / Priority / Label */}
              <Col span={24}>
                <HTMLTable
                  bordered
                  compact
                  className="linearTicketHTMLTable drawerTable"
                  style={{ marginBottom: 15 }}
                >
                  <thead>
                  <tr>
                    <th style={{ width: '33%' }}>
                      <span>Status</span>
                    </th>
                    <th style={{ width: '33%' }}>
                      <span>Priority</span>
                    </th>
                    <th>
                      <span>Label</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      <Icon
                        icon="ring"
                        style={{ color: selectedIssue?.state?.color, marginRight: 8 }}
                      />
                      {selectedIssue?.state?.name}
                    </td>
                    <td style={{ verticalAlign: 'middle', lineHeight: 0 }}>
                      <i
                        className={renderPriorityIcon(selectedIssue?.priorityLabel!)}
                        style={{
                          fontSize: 16,
                          marginRight: 7,
                          color:
                            selectedIssue?.priorityLabel === 'Urgent' ? '#FB763F' : '#404040',
                        }}
                      />
                      <span>{selectedIssue?.priorityLabel}</span>
                    </td>
                    <td>
                      {selectedIssue?.labels?.nodes?.map((label: any) => (
                        <Tag style={{ background: label.color }} round>
                          {label.name}
                        </Tag>
                      ))}
                    </td>
                  </tr>
                  </tbody>
                </HTMLTable>
              </Col>

              {/* Created At / Updated At */}
              <Col span={24}>
                <HTMLTable
                  bordered
                  compact
                  className="linearTicketHTMLTable drawerTable"
                  style={{ marginBottom: 15 }}
                >
                  <thead>
                  <tr>
                    <th style={{ width: '50%' }}>
                      <span>Created At</span>
                    </th>
                    <th>
                      <span>Updated At</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    <td>
                      {selectedIssue ? dayjs(selectedIssue.createdAt).format('DD/MM/YYYY') : ''}
                    </td>

                    <td>
                      {selectedIssue ? dayjs(selectedIssue.updatedAt).format('DD/MM/YYYY') : ''}
                    </td>
                  </tr>
                  </tbody>
                </HTMLTable>
              </Col>

              {/* Description */}
              <Col span={24}>
                <HTMLTable
                  bordered
                  compact
                  className="linearTicketHTMLTable drawerTable"
                  style={{ marginBottom: 15 }}
                >
                  <thead>
                  <tr>
                    <th>
                      <span>Description</span>
                    </th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr>
                    {/* <td>{selectedIssue ? removeBracketedNames(selectedIssue.description) : ''}</td> */}
                    <td>
                      {
                        <Markdown
                          options={{
                            forceBlock: true,
                            overrides: {
                              img: {
                                component: Image,
                                props: {
                                  className: 'foo',
                                },
                              },
                            },
                          }}
                        >
                          {removeBracketedNames(selectedIssue?.description || '')}
                        </Markdown>
                      }
                    </td>
                  </tr>
                  </tbody>
                </HTMLTable>
              </Col>
            </Row>
          </AntdSpinner>
        </div>
        <div className={Classes.DRAWER_FOOTER}>
          <Row justify="space-between">
            <Col>
              <Button
                intent="danger"
                icon="trash"
                outlined
                text="Delete issue"
                onClick={() => {
                  setIsDeleteAlertVisible(true);
                  setIsUpdatingIssue(false);
                }}
                disabled={
                  selectedIssue ? !isOwnIssue(selectedIssue) : true || isLoadingSelectedIssue
                }
              />
            </Col>
            <Col>
              <Button
                intent="primary"
                text="Edit Issue"
                icon="edit"
                onClick={() => {
                  showEditIssueForm();
                }}
                disabled={
                  selectedIssue ? !isOwnIssue(selectedIssue) : true || isLoadingSelectedIssue
                }
              />
            </Col>
          </Row>
        </div>
      </Drawer>

      <Col span={24}>
        <Section title="Linear Issues" />
      </Col>
      <Col span={24}>
        <Section>
          <Row style={{ padding: 10 }} justify="space-between">
            {/* Search Input */}
            <Col span={5}>
              <InputGroup
                round
                leftIcon="search"
                placeholder="Search by Issue # or Title"
                disabled={isLoadingIssues || issues.length === 0}
                value={textFilter}
                onChange={(e: any) => {
                  setTextFilter(e.target.value);
                }}
              />
            </Col>

            <Col span={19} style={{ textAlign: 'right' }}>
              <Row justify="end">
                <Col>
                  {/* Label Filter */}
                  <Select
                    activeItem={labelFilter}
                    disabled={uniqueLabels.length === 0 || isLoadingIssues}
                    items={getLabelFilters()}
                    itemRenderer={renderLabelSelect}
                    filterable={false}
                    onItemSelect={(e: any) => {
                      setLabelFilter(e.value);

                      if (e.value === 'All Labels') {
                        setRequestedByFilter('All Requesters');
                        setTextFilter('');
                        resetPagination();
                        getIssues({
                          direction: 'NEXT',
                          labelName: undefined,
                          requesterName: undefined,
                          overridePagination: true,
                        });
                      } else {
                        setRequestedByFilter('All Requesters');
                        setTextFilter('');
                        resetPagination();
                        getIssues({
                          direction: 'NEXT',
                          labelName: e.value,
                          requesterName: undefined,
                          overridePagination: true,
                        });
                      }
                    }}
                  >
                    <Button
                      icon="filter"
                      disabled={uniqueLabels.length === 0 || isLoadingIssues}
                      style={{ marginRight: 10 }}
                      intent={labelFilter === 'All Labels' ? 'none' : 'success'}
                      text={
                        labelFilter !== 'All Labels'
                          ? uniqueLabels.find((label: any) => label.id === labelFilter)?.name || ''
                          : 'All Labels'
                      }
                      rightIcon="caret-down"
                    />
                  </Select>
                </Col>
                <Col>
                  {/* Requested By Filter */}
                  <Select
                    activeItem={requestedByFilter}
                    disabled={uniqueRequesters.length === 0 || isLoadingIssues}
                    items={getRequestedByFilters()}
                    itemRenderer={renderRequestedSelect}
                    filterable={false}
                    onItemSelect={(e: any) => {
                      setRequestedByFilter(e.value);

                      if (e.value === 'All Requesters') {
                        setLabelFilter('All Labels');
                        setTextFilter('');
                        resetPagination();
                        getIssues({
                          direction: 'NEXT',
                          labelName: undefined,
                          requesterName: undefined,
                          overridePagination: true,
                        });
                      } else {
                        setLabelFilter('All Labels');
                        setTextFilter('');
                        resetPagination();
                        getIssues({
                          direction: 'NEXT',
                          requesterName: e.value,
                          overridePagination: true,
                        });
                      }
                    }}
                  >
                    <Button
                      icon="filter"
                      disabled={uniqueRequesters.length === 0 || isLoadingIssues}
                      style={{ marginRight: 10 }}
                      intent={requestedByFilter === 'All Requesters' ? 'none' : 'success'}
                      text={requestedByFilter}
                      rightIcon="caret-down"
                    />
                  </Select>
                </Col>
                <Col>
                  <Button
                    text="Create Issue"
                    intent="primary"
                    icon="plus"
                    disabled={
                      isLoadingIssues ||
                      isCreatingIssue ||
                      linearLabels.length === 0 ||
                      linearStates.length === 0 ||
                      !hasPermissions(userReducer, ['supportmodule.linearissue.create'])
                    }
                    onClick={showCreateIssueForm}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Section>
      </Col>
      <Col span={24}>
        {/* Show Loader */}
        {isLoadingIssues && (
          <Section>
            <Row style={{ height: 'calc(100vh - 185px)' }}>
              <Col span={24} style={{ padding: '250px 0' }}>
                <NonIdealState icon={<Spinner size={38} />}>
                  <div>
                    <span>Loading Issues...</span>
                  </div>
                </NonIdealState>
              </Col>
            </Row>
          </Section>
        )}

        {/* No Issues found Non-Ideal State */}
        {!isLoadingIssues && issues.length === 0 && (
          <Section>
            <Row style={{ height: 'calc(100vh - 185px)' }}>
              <Col span={24} style={{ padding: '250px 0' }}>
                <NonIdealState icon="search">
                  <div>
                    <span>No Issues Found</span>
                  </div>
                </NonIdealState>
              </Col>
            </Row>
          </Section>
        )}

        {/* Issues Table */}
        {!isLoadingIssues && issues.length > 0 ? (
          <Section>
            <Row style={{ height: 'calc(100vh - 185px)', overflowY: 'auto' }}>
              <Col span={24}>
                <HTMLTable compact bordered className="linearTicketHTMLTable mainTable">
                  <thead>
                  <tr>
                    <th style={{ width: '7%' }}>Ticket #</th>
                    <th style={{ width: '8%' }}>Status</th>
                    <th style={{ width: '5%' }}>Priority</th>
                    <th style={{ width: '34%' }}>Title</th>
                    <th style={{ width: '10%' }}>Labels</th>
                    <th style={{ width: '13%' }}>Requested By</th>
                    <th style={{ width: '11%' }}>Updated</th>
                    <th style={{ width: '11%' }}>Created</th>
                    <th style={{ width: '5%' }}>Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  {filteredIssues.map((issue: TLinearIssue, i: number) => (
                    <tr>
                      {/* Ticket # */}
                      <td>
                        {/* System admin can open the ticket straight in Linear */}
                        {isSystemAdmin(userReducer) ? (
                          <a
                            href={`https://linear.app/d19n/issue/${issue.identifier}`}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <span>{issue.identifier}</span>
                          </a>
                        ) : (
                          <span>{issue.identifier}</span>
                        )}
                      </td>
                      {/* Status */}
                      <td>
                        <Icon
                          icon="ring"
                          style={{ color: issue?.state?.color, marginRight: 8 }}
                        />
                        {issue.state?.name}
                      </td>
                      {/* Priority */}
                      <td style={{ textAlign: 'center', verticalAlign: 'middle', lineHeight: 0 }}>
                        <Tooltip
                          content={'Priority: ' + issue.priorityLabel}
                          hoverOpenDelay={1000}
                          key={issue.title}
                        >
                          <i
                            className={renderPriorityIcon(issue.priorityLabel)}
                            style={{
                              cursor: 'pointer',
                              fontSize: 16,
                              color: issue.priorityLabel === 'Urgent' ? '#FB763F' : '#404040',
                            }}
                          />
                        </Tooltip>
                      </td>
                      {/* Title */}
                      <td>{issue.title}</td>
                      {/* Labels */}
                      <td>
                        {issue.labels?.nodes?.map((label: any) => (
                          <Tag style={{ background: label.color }} round>
                            {label.name}
                          </Tag>
                        ))}
                      </td>
                      {/* Requested by */}
                      <td>{getRequestedByFromDescription(issue.description)}</td>
                      {/* Updated At */}
                      <td>{dayjs(issue.updatedAt).format('DD/MM/YYYY')}</td>
                      {/* Created At */}
                      <td>{dayjs(issue.createdAt).format('DD/MM/YYYY')}</td>
                      {/* Action */}
                      <td style={{ textAlign: 'right' }}>
                        <Button
                          disabled={isLoadingIssues || isCreatingIssue}
                          small
                          icon="eye-open"
                          onClick={() => {
                            setSelectedIssue(issue);
                            setIsDrawerOpen(true);
                          }}
                        />
                      </td>
                    </tr>
                  ))}
                  </tbody>
                </HTMLTable>
              </Col>
            </Row>
            <Row>
              {/* Pagination */}
              <Col span={24} style={{ padding: 8, borderTop: '1px solid #DCDCDD' }}>
                <Row>
                  <Col span={12}>
                    <span style={{ fontSize: 12 }}>
                      Issues: {issues.length} / {totalIssues}
                    </span>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <ButtonGroup>
                      {/* Previous Page */}
                      <Button
                        small
                        icon="caret-left"
                        style={{ marginRight: 8 }}
                        disabled={!hasPreviousPage}
                        onClick={() => {
                          getIssues({
                            direction: 'PREVIOUS',
                            labelName: labelFilter === 'All Labels' ? undefined : labelFilter,
                            requesterName:
                              requestedByFilter === 'All Requesters'
                                ? undefined
                                : requestedByFilter,
                          });
                        }}
                      />
                      {/* Next Page */}
                      <Button
                        small
                        icon="caret-right"
                        disabled={!hasNextPage}
                        onClick={() => {
                          getIssues({
                            direction: 'NEXT',
                            labelName: labelFilter === 'All Labels' ? undefined : labelFilter,
                            requesterName:
                              requestedByFilter === 'All Requesters'
                                ? undefined
                                : requestedByFilter,
                          });
                        }}
                      />
                    </ButtonGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Section>
        ) : (
          <></>
        )}
      </Col>
      <SharedFormModal
        formUUID={uuid}
        onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmitEvent(params)}
      />
    </Row>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: SharedFormReducer) => dispatch(initializeSharedForm(params)),
});

export default connect(mapState, mapDispatch)(LinearIssues);
