import { Button, Classes, Drawer, InputGroup, Position } from '@blueprintjs/core';
import React from 'react';
import './styles.scss';

interface CustomDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  position: Position;
  canOutsideClickClose?: boolean;
  canEscapeKeyClose?: boolean;
  title: string;
  hasDescription?: boolean;
  description: string;
  children: React.ReactNode;
  isLoading: boolean;
  hasSaveButton: boolean;
  onSaveButtonClick: () => void;
  onCancelButtonClick: () => void;
  refetch?: boolean;
  refetchCall?: (value: any | any[]) => void;
}

const CustomDrawer = ({
  isOpen,
  onClose,
  position,
  canOutsideClickClose = true,
  canEscapeKeyClose = true,
  title,
  hasDescription = true,
  description,
  children,
  isLoading,
  hasSaveButton = true,
  onSaveButtonClick,
  onCancelButtonClick,
}: CustomDrawerProps) => {
  return (
    <Drawer
      isOpen={isOpen}
      onClose={onClose}
      position={position}
      canOutsideClickClose={canOutsideClickClose}
      canEscapeKeyClose={canEscapeKeyClose}
      className="drawer-container drawer-sm"
    >
      <div className={Classes.DRAWER_BODY}>
        <div className={Classes.DIALOG_BODY}>
          <div className="drawer-header">
            <p className="drawer-title">{title}</p>
            {hasDescription && <span className="drawer-description">{description}</span>}
          </div>
          <div className="drawer-body">
            {children}
            <div className="drawer-footer">
              {hasSaveButton && (
                <Button
                  className="drawer-proceed-btn"
                  style={{
                    opacity: isLoading ? '.5' : '',
                  }}
                  onClick={onSaveButtonClick}
                  disabled={isLoading}
                >
                  Add participant
                </Button>
              )}
              <Button className="drawer-cancel-btn" onClick={onCancelButtonClick}>
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default CustomDrawer;
