import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

import { Button, Card, Col, Descriptions, Divider, Empty, Row, Typography } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import ActivityCenter from '@legacy/core/records/components/ActivityCenter';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import {
  renderDynamicAssociationsAsTables,
} from '@legacy/core/recordsAssociations/helpers/component-helpers';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { getAllSchemaAssociationSchemas } from '../../../../../../core/helpers/recordHelpers';

import { getSchemaFromShortListByModuleAndEntityOrAPI } from '../../../../../../core/helpers/schemaHelpers';

import { useAFPApprovalState } from '../context';
import { getSelectedRecords } from '../context/helpers';
import RecordViewHeader from './RecordView/RecordViewHeader';
import { RelationsDetails } from './RelationsDetails';
import './styles.scss';
import { PIAImageUpload } from './PIAImageUpload';
import { FiberSplicing } from './FiberSplicing';

type RecordViewPanelProps = {
  schemaReducer: any;
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) => void;
};

const RecordViewPanel: React.FC<RecordViewPanelProps> = ({ schemaReducer, getSchema }) => {
  const state = useAFPApprovalState();
  const {
    records: { feature },
  } = state;
  const selectedRecords = getSelectedRecords(state);
  const selectedRecord = selectedRecords[0];

  const [activeTab, setActiveTab] = useState<'Details' | 'Activity' | 'Related'>('Details');
  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);
  const [relatedSchemas, setRelatedSchemas] = useState<any[]>([]);

  const getSelectedRecordSchema = async () => {
    const moduleName = selectedRecord?.entity?.split(':')[0];
    const entityName = selectedRecord?.entity?.split(':')[1];

    try {
      const selectedRecordSchema = await getSchemaFromShortListByModuleAndEntityOrAPI(
        schemaReducer.shortList,
        moduleName!,
        entityName!,
        getSchema,
      );
      setSchema(selectedRecordSchema);
      const allRelatedSchemas = getAllSchemaAssociationSchemas(selectedRecordSchema?.associations, [
        'Note',
      ]);

      if (allRelatedSchemas?.length > 0) {
        setRelatedSchemas(allRelatedSchemas);
      }
    } catch (error: any) {
    }
  };

  useEffect(() => {
    if (!schema && selectedRecord) getSelectedRecordSchema();
  }, [selectedRecord?.id]);

  const getRelatedTables = () => {
    if (schema && selectedRecord && relatedSchemas?.length > 0) {
      const tables = renderDynamicAssociationsAsTables(selectedRecord, relatedSchemas);
      return tables;
    } else {
      return <></>;
    }
  };

  return (
    <Card
      className="DataSetBuilderPanelRow"
      size="small"
      title={<Typography.Title level={5}>Record View</Typography.Title>}
    >
      <Row>
        <Col
          span={24}
          className="panelRow"
          style={{
            position: 'sticky',
            zIndex: 1,
            background: 'white',
            padding: '0 10px',
            // boxShadow: '3px 3px 3px rgba(0, 0, 0, 0.2)',
          }}
        >
          <Row>
            <Col span={24}>
              <RecordViewHeader record={selectedRecord} isLoading={false} hideDivider />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row style={{ height: '67vh', overflow: 'scroll', paddingTop: 10 }}>
        {!selectedRecord ? (
          <Col span={24} style={{ textAlign: 'center' }}>
            <Empty style={{ marginTop: '20vh' }} description="No Record Selected" />
          </Col>
        ) : (
          <Col span={24} style={{ padding: 10 }}>
            <Row>
              {/* Record Tabs */}
              <Col span={24} style={{ textAlign: 'center', marginBottom: 40 }}>
                <Row>
                  {/* Details */}
                  <Col span={8}>
                    <Button
                      ghost={activeTab !== 'Details'}
                      type="primary"
                      size="middle"
                      style={{
                        width: '100%',
                        borderRadius: '5px 0 0 5px',
                        borderRight: 'none',
                        fontWeight: activeTab === 'Details' ? 600 : 400,
                      }}
                      onClick={() => setActiveTab('Details')}
                    >
                      Details
                    </Button>
                  </Col>
                  {/* Activity */}
                  <Col span={8}>
                    <Button
                      ghost={activeTab !== 'Activity'}
                      type="primary"
                      size="middle"
                      style={{
                        width: '100%',
                        borderRadius: 0,
                        fontWeight: activeTab === 'Activity' ? 600 : 400,
                        borderRight: 'none',
                      }}
                      onClick={() => setActiveTab('Activity')}
                    >
                      Activity
                    </Button>
                  </Col>
                  {/* Related */}
                  <Col span={8}>
                    <Button
                      ghost={activeTab !== 'Related'}
                      type="primary"
                      size="middle"
                      style={{
                        width: '100%',
                        borderRadius: '0 5px 5px 0',
                        fontWeight: activeTab === 'Related' ? 600 : 400,
                      }}
                      onClick={() => setActiveTab('Related')}
                    >
                      Related
                    </Button>
                  </Col>
                </Row>
              </Col>

              {/* Tab Contents */}
              <Col span={24} style={{ display: activeTab === 'Details' ? 'block' : 'none' }}>
                <RecordProperties record={selectedRecord!} />
                <Divider />
                {!!feature.data && (
                  <>
                    <FiberSplicing record={feature.data} />
                    <PIAImageUpload record={feature.data} />
                    <RelationsDetails record={feature.data} />
                  </>
                )}
                <Divider />
                <Descriptions size="small" column={2} style={{ padding: 10 }}>
                  <Descriptions.Item label="Created By">
                    {selectedRecord?.createdBy?.fullName || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated By">
                    {selectedRecord?.lastModifiedBy?.fullName || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Created At">
                    {dayjs(selectedRecord?.createdAt).format('DD/MM/YYYY - HH:mm:ss') || '-'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Updated At">
                    {dayjs(selectedRecord?.updatedAt).format('DD/MM/YYYY - HH:mm:ss') || '-'}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={24} style={{ display: activeTab === 'Activity' ? 'block' : 'none' }}>
                <ActivityCenter record={selectedRecord!} schema={schema!} />
              </Col>
              <Col span={24} style={{ display: activeTab === 'Related' ? 'block' : 'none' }}>
                {relatedSchemas?.length > 0 && selectedRecord && schema ? (
                  getRelatedTables()
                ) : (
                  <span>No relations to show.</span>
                )}
              </Col>
            </Row>
          </Col>
        )}
      </Row>
    </Card>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default connect(mapState, mapDispatch)(RecordViewPanel);
