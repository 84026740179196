import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../index';
import {
  MY_CASES_ADD_EMAIL,
  MY_CASES_ADD_MESSAGE,
  MY_CASES_INCREMENT_CURRENT_CASE_UNREAD_COUNT,
  MY_CASES_SET_AGENT_TYPING_TEXT,
  MY_CASES_SET_CASE_UNREAD_STATUS,
  MY_CASES_SET_CLIENT_TYPING_TEXT,
  MY_CASES_SET_SELECTED_CASE_CONVERSATION,
  MY_CASES_UPDATE_CASE,
} from '../../store/constants';
import { io } from 'socket.io-client';
import { getHostName } from '@core/http/helpers';
import dayjs from 'dayjs';

interface Props {
  userReducer: any;
}

const SOCKET_URL = `${getHostName()}:4002/ChatWebsocketModule/TwilioChatWebsocket/v1.0`;

const MyCasesWebSockets: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const { state, dispatch } = useContext(MyCasesContext);
  const [socket, setSocket] = useState<any>(undefined);

  let caseIdRef: any = useRef(undefined);
  let feedContainerRef: any = useRef(null);

  // Make an useEffectHook that will set a timeout everytime state.agentTypingText is updated, and
  // set it to '' after 5 seconds. This will simulate the agent typing event, and remove it
  // appropriately.
  useEffect(() => {
    const timeout = setTimeout(() => {
      dispatch({ type: MY_CASES_SET_AGENT_TYPING_TEXT, payload: '' });
    }, 7000);
    return () => clearTimeout(timeout);
  }, [state.agentTypingText]);

  // Initialize WebSockets
  useEffect(() => {
    const s = io(SOCKET_URL, {
      path: '/ws/socket.io',
      query: {
        clientId: userReducer.user?.id,
      },
    });
    if (s) {
      setSocket(s);
    }
  }, []);

  useEffect(() => {
    // Connect to WS, handle user events and join room for user events
    if (socket) {
      socket.on('connect', () =>
        console.log('%cdebug: [WS] [My Cases] Connected to WebSockets service', 'color:#bada55'),
      );
      socket?.on(`case-event-${userReducer.user.id}`, handleUserEvents);
      socket?.emit('joinRoom', userReducer.user.id);
    }

    // Websocket unmount -> Disconnect everything and leave all rooms
    return () => {
      if (socket) {
        console.log('%cdebug: [WS] [My Cases] Disconnected from WebSockets service', 'color:red');

        socket?.off(`web-chat-input-${caseIdRef.current}`);
        socket?.off(`case-event-${caseIdRef}`);
        socket?.off(`case-event-${userReducer.user.id}`);

        socket?.emit('leaveRoom', caseIdRef.current);
        socket?.emit('leaveRoom', userReducer.user.id);
        socket?.disconnect();
      }
    };
  }, [socket]);

  useEffect(() => {
    // 1. CASE CHANGED -> Remove old / add new listeners
    if (state.selectedCase?.id !== caseIdRef.current && caseIdRef.current) {
      // Goodbye old case...
      socket?.emit('leaveRoom', caseIdRef.current);
      socket?.off(`web-chat-input-${caseIdRef.current}`);
      socket?.off(`case-event-${caseIdRef.current}`);

      // Hello new case!
      socket?.emit('joinRoom', state.selectedCase?.id);
      socket?.on(`web-chat-input-${state.selectedCase?.id}`, handleClientTypingEvent);
      socket?.on(`case-event-${state.selectedCase?.id}`, handleCaseEvents);
    }
    // CHANGED CASE -> No previous case / add new listeners
    else if (state.selectedCase?.id !== caseIdRef.current && !caseIdRef.current) {
      socket?.emit('joinRoom', state.selectedCase?.id);
      socket?.on(`case-event-${state.selectedCase?.id}`, handleCaseEvents);
      socket?.on(`web-chat-input-${state.selectedCase?.id}`, handleClientTypingEvent);
    }
  }, [state.selectedCase, caseIdRef, socket]);

  // Update Selected Case Container Refs for use in events
  useEffect(() => {
    if (state.selectedCase?.id !== caseIdRef.current) {
      caseIdRef.current = state.selectedCase?.id;
    }
  }, [state.selectedCase, caseIdRef]);

  // Update Feed Container Refs for use in events
  useEffect(() => {
    if (state.feedContainerRef?.current?.scrollTop! !== feedContainerRef?.current?.scrollTop) {
      feedContainerRef.current = state.feedContainerRef?.current;
    } else {
    }
  }, [state.feedContainerRef?.current?.scrollTop, feedContainerRef, state.selectedCase]);

  // Issue typing event when agent is typing...
  useEffect(() => {
    if (state.agentInputText.length > 0) {
      socket?.emit(`web-chat-input`, {
        caseId: state.selectedCase?.id,
        conversationId: state.selectedCaseConversation?.id,
        message: state.agentInputText,
        sender: 'AGENT',
      });
    }
  }, [state.agentInputText, socket, state.selectedCase]);


  const handleCaseEvents = (message: any) => {
    //console.log('debug: [WS] [My Cases] ANY Case Event', message);

    const { scrollTop } = feedContainerRef.current;
    const roundedScrollTop = Math.floor(scrollTop);
    const isScrolledUp = roundedScrollTop < 0;

    switch (message.type) {
      case 'MESSAGE_CREATED':
        console.log('%cdebug: [WS] [My Cases] MESSAGE_CREATED', 'color: #bada55', message);
        dispatch({ type: MY_CASES_ADD_MESSAGE, payload: message.data as DbRecordEntityTransform });
        if (isScrolledUp) {
          dispatch({ type: MY_CASES_INCREMENT_CURRENT_CASE_UNREAD_COUNT, payload: true });
        }
        break;
      case 'EMAIL_RECEIVED':
        console.log('%cdebug: [WS] [My Cases] EMAIL_RECEIVED', 'color: #bada55', message);
        dispatch({ type: MY_CASES_ADD_EMAIL, payload: message.data as DbRecordEntityTransform });
        if (isScrolledUp) {
          dispatch({ type: MY_CASES_INCREMENT_CURRENT_CASE_UNREAD_COUNT, payload: true });
        }
        break;
      case 'CONVERSATION_CLOSED':
        console.log('%cdebug: [WS] [My Cases] CONVERSATION_CLOSED', 'color: #bada55', message);
        dispatch({ type: MY_CASES_SET_SELECTED_CASE_CONVERSATION, payload: message.data });
        break;
      default:
        break;
    }
  };

  const handleUserEvents = (message: any) => {
    //console.log('debug: [WS] [My Cases] ANY User Event', message);

    switch (message.type) {
      case 'MESSAGE_CREATED':
        if (!isMessageForSelectedCase(message)) {
          dispatch({
            type: MY_CASES_SET_CASE_UNREAD_STATUS,
            payload: { caseId: message.caseId, status: 'UNREAD' },
          });
        }
        break;
      case 'EMAIL_RECEIVED':
        if (!isMessageForSelectedCase(message)) {
          dispatch({
            type: MY_CASES_SET_CASE_UNREAD_STATUS,
            payload: { caseId: message.caseId, status: 'UNREAD' },
          });
        }
        break;
      default:
        dispatch({ type: MY_CASES_UPDATE_CASE, payload: message.data as DbRecordEntityTransform });
        break;
    }

  };


  const isMessageForSelectedCase = (message: DbRecordEntityTransform) => {
    return message?.caseId === caseIdRef?.current;
  };

  // Handle AGENT and CUSTOMER typing events
  const handleClientTypingEvent = (message: any) => {
    const messageText = message.message?.trim();

    // AGENT
    if (message.sender === 'AGENT') {
      if (messageText !== '') {
        dispatch({ type: MY_CASES_SET_AGENT_TYPING_TEXT, payload: dayjs().format('HH:mm:ss') });
      } else {
        dispatch({ type: MY_CASES_SET_AGENT_TYPING_TEXT, payload: '' });
      }
    }

    // CUSTOMER
    if (message.sender === 'CUSTOMER') {
      if (messageText !== '') {
        dispatch({ type: MY_CASES_SET_CLIENT_TYPING_TEXT, payload: message.message });
      } else {
        dispatch({ type: MY_CASES_SET_CLIENT_TYPING_TEXT, payload: '' });
      }
    }
  };

  return <></>;
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesWebSockets);