import { useEffect } from 'react';
import { useAFPApprovalService } from '../index';

type RecordLoaderEffectsProps = {
  afpId: string;
}

export const RecordLoaderEffects = ({ afpId }: RecordLoaderEffectsProps) => {
  const service = useAFPApprovalService();

  useEffect(() => {
    if (!afpId) return;

    service.loadApplicationForPayment(afpId);
    service.loadFeaturePriceEntries(afpId);
  }, [afpId]);
  return null;
};
