import { Button, Radio } from '@blueprintjs/core';
import { Col, Row } from 'antd';

import { Cardinality } from './types';

type SchemaCardinalityProps = {
  value: Cardinality;
  onChange: (value: Cardinality) => void;
}

const CardinalityOption = ({ value, checked, onChange }: SchemaCardinalityProps & { checked: boolean }) => {
  return (
    <Button fill onClick={() => onChange(value)}>
      <Radio
        label={value}
        value={value}
        checked={checked}
        onChange={() => onChange(value)}
        onClick={() => onChange(value)}
      />
    </Button>
  )
};

export const SchemaCardinality = ({ value, onChange }: SchemaCardinalityProps) => {
  return (
    <Row className="cardinality__radio-group">
      <Col span={12}>
        <CardinalityOption value="One" checked={value === "One"} onChange={onChange} />
      </Col>
      <Col span={12}>
        <CardinalityOption value="Many" checked={value === "Many"} onChange={onChange} />
      </Col>
    </Row>
  );
}