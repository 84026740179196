import { FPEStatusFilter } from '../../types';

const FPE_SELECTION_TEXT_FILTER_SET = 'FPE_SELECTION_TEXT_FILTER_SET';
const FPE_SELECTION_STATUS_FILTER_SET = 'FPE_SELECTION_STATUS_FILTER_SET';
const FPE_SELECTION_FEATURE_TYPE_FILTER_SET = 'FPE_SELECTION_FEATURE_TYPE_FILTER_SET';

type TextFilterSet = {
  type: typeof FPE_SELECTION_TEXT_FILTER_SET;
  payload: string;
};

type StatusFilterSet = {
  type: typeof FPE_SELECTION_STATUS_FILTER_SET;
  payload: FPEStatusFilter;
};

type FeatureTypeFilterSet = {
  type: typeof FPE_SELECTION_FEATURE_TYPE_FILTER_SET;
  payload: string[];
};

export type FPEFilterActions = TextFilterSet | StatusFilterSet | FeatureTypeFilterSet;

export const setTextFilter = (textFilter: string): TextFilterSet => ({
  type: FPE_SELECTION_TEXT_FILTER_SET,
  payload: textFilter,
});

export const setStatusFilter = (status: FPEStatusFilter): StatusFilterSet => ({
  type: FPE_SELECTION_STATUS_FILTER_SET,
  payload: status,
});

export const setFeatureTypeFilter = (featureTypes: string[]): FeatureTypeFilterSet => ({
  type: FPE_SELECTION_FEATURE_TYPE_FILTER_SET,
  payload: featureTypes,
});
