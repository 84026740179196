import { BillingDatesCalculator } from '@d19n/temp-fe-d19n-common/dist/billing/helpers/BillingDatesCalculator';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import moment from 'moment';

export const calculateBillingPeriodEndDateFromOrderItems = (
  orderItems: DbRecordEntityTransform[],
  billingStartDate: string,
  currentNextInvoiceDate: string,
) => {
  // calculate NextInvoiceDates from orderItems relative to current currentNextInvoiceDate+1
  const overriddenCurrentDate = moment(currentNextInvoiceDate).add(1, 'day').format('YYYY-MM-DD');
  const futureNextInvoiceDates = orderItems.map((elem) =>
    BillingDatesCalculator.calculateOrderItemNextInvoiceDate(
      elem,
      billingStartDate,
      overriddenCurrentDate,
    ),
  );

  // get the latest date to calculate billing period end
  const futureNextInvoiceDate = futureNextInvoiceDates.reduce((p, c) => {
    if (p && moment(p).isValid() && c && moment(c).isValid()) {
      return moment(p).isBefore(c) ? c : p;
    }
    return c;
  });

  // get futureNextInvoiceDate-1 date as a resulting billing period end date
  const billingPeriodEndDate = moment(futureNextInvoiceDate).add(-1, 'day').format('YYYY-MM-DD');

  return billingPeriodEndDate;
};

export function computeDueDate(
  params: {
    issuedDate: string;
    nextInvoiceDate: string;
    billingStartDate: string;
  },
  billingTerms: any,
) {
  const { nextInvoiceDate } = params;

  const split = billingTerms.split('_');
  const billingTermsDays = split[1];

  return moment(nextInvoiceDate).add(billingTermsDays, 'days').format('YYYY-MM-DD');
}

export function calculateInvoiceTotals(
  parentRecord: DbRecordEntityTransform,
  invoiceItems: DbRecordEntityTransform[],
) {
  //let modifiedParentRecord: DbRecordEntityTransform = JSON.parse(JSON.stringify(parentRecord));

  let orderItems: any[] = [];

  for (const item of invoiceItems) {
    orderItems.push(item);
  }

  if (orderItems.length > 0) {
    // get the earliest NextInvoiceDate from orderItems
    // const adjustedBillingStartDateToBillingDay =
    //   BillingDatesCalculator.getAdjustedBillingStartDateToBillingDay(modifiedParentRecord);

    // const nextInvoiceDate = BillingDatesCalculator.getNextInvoiceDateFromOrderItems(
    //   orderItems,
    //   adjustedBillingStartDateToBillingDay,
    // );

    // Add Billing Adjustment
    // let adjustmentItem;

    // if (billingAdjustment) {
    //   const adjustmentInfo =
    //     BillingAdjustmentHelper.constructBillingAdjustmentInfo(billingAdjustment);

    //   const { newAdjustmentItem } = OrderInvoiceItemCalculations.constructBillingAdjustmentItem(
    //     orderItems,
    //     modifiedParentRecord,
    //     adjustmentInfo,
    //     nextInvoiceDate,
    //   );

    //   adjustmentItem = newAdjustmentItem;
    // }

    return {
      // Adjustment: adjustmentItem,
      Subtotal: orderItems
        ?.reduce((acc: any, elem: DbRecordEntityTransform) => {
          return acc + Number(getProperty(elem, 'Subtotal'));
        }, 0)
        .toFixed(2),
      TotalDiscounts: orderItems
        ?.reduce((acc: any, elem: DbRecordEntityTransform) => {
          return acc + Number(getProperty(elem, 'TotalDiscounts'));
        }, 0)
        .toFixed(2),
      TotalTaxAmount: orderItems
        ?.reduce((acc: any, elem: DbRecordEntityTransform) => {
          return acc + Number(getProperty(elem, 'TotalTaxAmount'));
        }, 0)
        .toFixed(2),
      TotalPrice: orderItems
        ?.reduce((acc: any, elem: DbRecordEntityTransform) => {
          return acc + Number(getProperty(elem, 'TotalPrice'));
        }, 0)
        .toFixed(2),
    };
  }

  return {
    Adjustment: undefined,
    Subtotal: '0.00',
    TotalDiscounts: '0.00',
    TotalTaxAmount: '0.00',
    TotalPrice: '0.00',
  };
}
