import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PageHeader } from '@legacy/components/PageHeader';
import FormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { searchString } from '@core/helpers/searchHelpers';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { getRecordIdFromHash } from '../../../../utils/hashNavigation';
import { getErrorMessage } from '../../../../utils/errors';
import { formFields } from '../FormFields';
import TerritoryDetails from '../TerritoryDetails';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string;
  name: string;
  description: string;
}

const uuid = uuidv4();

const TerritoriesListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedRegions, setSelectedRegions] = useState<any[]>([]);
  const [selectedTerritory, setSelectedTerritory] = useState<any>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [territoryList, setTerritoryList] = useState<any[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isTerritoryLoading, setIsTerritoryLoading] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();

  useEffect(() => {
    const id = getRecordIdFromHash(window.location.hash);
    if (id) getTerritoryById(id);
  }, [window.location.hash]);

  useEffect(() => {
    getAllTerritoriesList();
  }, []);

  useEffect(() => {
    setSelectedRegions([]);
    setSelectedTerritory(undefined);
  }, [currentPage]);

  const onSelect = (e: any) => {
    setSelectedRegions([
      {
        cols: [0, 1],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (territoryList.length > 0) {
      let tableData: ITableData[] = Object.assign([], territoryList);

      tableData = territoryList.map((t: any) => ({
        key: t.id,
        name: t.name,
        description: t.description,
      }));

      // Apply search
      tableData = tableData.filter((t: any) => {
        return searchString(t.name, searchKey) || searchString(t.description, searchKey);
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [territoryList, currentPage, searchKey, pageSize]);

  // Get selected territory details
  useEffect(() => {
    if (selectedRegions.length > 0 && tableSlice.length > 0) {
      const territory: any = tableSlice[selectedRegions[0].rows[0]];
      if (territory && !isTerritoryLoading) {
        getTerritoryById(territory.key);
      }
    }
  }, [selectedRegions, tableSlice]);

  const getTerritoryById = (id: string) => {
    setIsTerritoryLoading(true);
    httpGet(`IdentityModule/v2.0/territories/${id}`)
      .then((res) => {
        setIsTerritoryLoading(false);
        setSelectedTerritory(res.data.data);
        console.log('%cdebug: Selected territory', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not retrieve territory data. ' + message,
          type: 'error',
        });
        setIsTerritoryLoading(false);
        setSelectedTerritory(undefined);
        setSelectedRegions([]);
      });
  };

  const getAllTerritoriesList = async () => {
    try {
      const res = await httpGet(`IdentityModule/v2.0/territories?size=10000`);
      const territories: any[] = res.data.data || [];
      setTerritoryList(territories);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve territory list. ' + message, type: 'error' });
      setTerritoryList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };
  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedTerritory, tableRef, tableSlice]);

  const showCreateTerritoryForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Territory',
      formFields: formFields,
      entityName: 'Territory',
    });
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
      };
      createTerritory({ body });
    }
  };

  const createTerritory = async (params: { body: any }) => {
    const { body } = params;

    try {
      const res = await httpPost('IdentityModule/v2.0/territories', body);

      const newTerritory = res.data.data;
      setTerritoryList([...territoryList, newTerritory]);
      getTerritoryById(newTerritory.id);

      alertMessage({ body: 'Territory Created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create territory. ' + message,
        type: 'error',
      });
    }
  };

  const onSearch = (e: any) => {
    setSelectedRegions([]);
    setSearchKey(e.target.value);
  };

  const onTerritoryUpdate = () => {
    if (selectedTerritory) {
      getTerritoryById(selectedTerritory.id);
    }
  };

  const onTerritoryDelete = () => {
    setSelectedRegions([]);

    const id = selectedTerritory?.id;
    if (id) {
      setTerritoryList(territoryList.filter((g) => g.id !== id));
      setSelectedTerritory(undefined);
    }
  };

  const renderTerritories = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={4}>
              <h2 style={{ margin: 0 }}>Territories</h2>
            </Col>
            <Col span={20} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Territories"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button
                  icon="plus"
                  intent="success"
                  onClick={showCreateTerritoryForm}
                  style={{ borderRadius: 5 }}
                >
                  Create Territory
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedTerritory ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[getColumnWidthByPercentage(30), getColumnWidthByPercentage(70)]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Region Details */}
          {selectedTerritory && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isTerritoryLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                title="Region Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedRegions([]);
                      setSelectedTerritory(undefined);
                    }}
                  />
                }
              >
                <TerritoryDetails
                  territory={selectedTerritory}
                  onUpdate={onTerritoryUpdate}
                  onDelete={onTerritoryDelete}
                />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={territoryList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderTerritories()}</div>;
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(TerritoriesListView);
