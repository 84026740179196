import { Card, Descriptions, Divider, Typography } from 'antd';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../styles.scss';
import { IAutosplicingReducer } from '../../store/types';

interface OwnProps {
  autosplicingReducer: IAutosplicingReducer;
}

enum QGISCableTypeEnum {
  'Spine' = 1,
  'Distribution' = 2,
  'Access' = 3,
  'Feed' = 4,
  'Drop' = 5,
  'Temp' = 6,
  'Cablelink' = 7,
}

enum QGISClosureTypeEnum {
  'L0' = 1,
  'L1' = 2,
  'L2' = 3,
  'L3' = 4,
  'L4' = 5,
  'LX' = 6,
  'LM' = 11,
  'LT' = 12,
  'ODF' = 9,
  'OLT' = 10,
}

type Props = OwnProps;

const AutosplicingTraceOverview: FunctionComponent<Props> = (props) => {
  const { autosplicingReducer } = props;
  const { formData, closureType, differenceList } = autosplicingReducer;

  const renderTraceSummaryClosures = () => {
    if (differenceList) {
      const agg = getAggByLevel('TRACED_CLOSURES');
      return Object.keys(agg).map((key: string) => (
        <Descriptions size="small" column={1}>
          <Descriptions.Item
            // @ts-ignore
            label={`${closureTypeFromEnum(Number(key))} closures`}
          >
            {agg[key]}
          </Descriptions.Item>
        </Descriptions>
      ));
    }
  };

  const renderTraceSummaryCables = () => {
    if (differenceList) {
      const agg = getAggByLevel('TRACED_CABLES');
      return Object.keys(agg).map((key: string) => (
        <Descriptions size="small" column={1}>
          <Descriptions.Item
            // @ts-ignore
            label={`${cableTypeFromEnum(Number(key))} cables`}
          >
            {agg[key]}
          </Descriptions.Item>
        </Descriptions>
      ));
    }
  };

  // to handle antd type issue
  const dataParser = (agg: any, key: string): any => {
    return agg[key];
  };

  const renderClosuresTracedAggregate = () => {
    if (differenceList) {
      const features = differenceList?.traceData?.features;
      if (features) {
        let agg = {
          '-': 0,
        };
        if (closureType === 'L0') {
          agg = getAggByLevel('L1_CLOSURES');
        } else {
          agg = getAggByLevel('L2_CLOSURES');
        }
        return Object.keys(agg).map((key: string) => (
          <Descriptions size="small" column={1}>
            <Descriptions.Item
              // @ts-ignore
              label={`${closureTypeFromEnum(Number(key))} closures`}
            >
              {dataParser(agg, key)}
            </Descriptions.Item>
          </Descriptions>
        ));
      }
    }
  };

  const renderCablesTracedAggregate = () => {
    if (differenceList) {
      const features = differenceList?.traceData?.features;
      if (features) {
        let agg = {
          '-': 0,
        };
        if (closureType === 'L2') {
          agg = getAggByLevel('L2_CABLES');
        }
        return Object.keys(agg).map((key: string) => (
          <Descriptions size="small" column={1}>
            <Descriptions.Item
              // @ts-ignore
              label={`${cableTypeFromEnum(Number(key))} cables`}
            >
              {dataParser(agg, key)}
            </Descriptions.Item>
          </Descriptions>
        ));
      }
    }
  };

  const renderClosuresMissing = () => {
    if (differenceList) {
      if (closureType !== 'L0' && autosplicingReducer?.formData?.cable_id) {
        return (
          <Typography.Text>
            For {closureType} closures please remove the cableId filter to
            accurately check what features are missing.
          </Typography.Text>
        );
      }

      const features = differenceList?.traceData?.features;
      if (features) {
        let agg: { [key: string]: number[] } = {
          '-': [],
        };
        if (closureType === 'L0') {
          agg = getAggByLevel('L1_MISSING');
        } else {
          agg = getAggByLevel('L2_MISSING');
        }
        return Object.keys(agg).map((key: string) => (
          <div>
            <div>{closureTypeFromEnum(Number(key))} Closures</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {agg[key].map((elem) => (
                <Link target="_blank" to={`/ProjectModule/Map/CLOSURE/${elem}`}>
                  {elem} - view in map
                </Link>
              ))}
            </div>
          </div>
        ));
      }
    }
  };

  const renderCablesMissing = () => {
    if (differenceList) {
      if (closureType !== 'L0' && formData.cable_id) {
        return (
          <Typography.Text>
            For {closureType} closures please remove the cableId filter to
            accurately check what features are missing.
          </Typography.Text>
        );
      }

      const features = differenceList?.traceData?.features;
      if (features) {
        let agg: { [key: string]: number[] } = {
          '-': [],
        };
        if (closureType === 'L2') {
          agg = getAggByLevel('ACCESS_FEED_MISSING');
        }
        return Object.keys(agg).map((key: string) => (
          <div>
            <div>{cableTypeFromEnum(Number(key))} Cables</div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {agg[key].map((elem) => (
                <Link target="_blank" to={`/ProjectModule/Map/CABLE/${elem}`}>
                  {elem} - view in map
                </Link>
              ))}
            </div>
          </div>
        ));
      }
    }
  };

  const getAggByLevel = (
    level:
      | 'L1_CLOSURES'
      | 'L2_CLOSURES'
      | 'L2_CABLES'
      | 'TRACED_CLOSURES'
      | 'TRACED_CABLES'
      | 'L1_MISSING'
      | 'L2_MISSING'
      | 'ACCESS_FEED_MISSING',
  ): any => {
    const features = differenceList?.traceData?.features;

    if (level === 'L2_CLOSURES') {
      return features?.l2PolygonClosures?.reduce(
        (
          acc: { [x: string]: number },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = 1;
          } else {
            acc[obj.type_id]++;
          }

          return acc;
        },
        {},
      );
    } else if (level === 'L1_CLOSURES') {
      return features?.l1PolygonClosures?.reduce(
        (
          acc: { [x: string]: number },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = 1;
          } else {
            acc[obj.type_id]++;
          }

          return acc;
        },
        {},
      );
    } else if (level === 'L2_CABLES') {
      return features?.accessAndFeedCables?.reduce(
        (
          acc: { [x: string]: number },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = 1;
          } else {
            acc[obj.type_id]++;
          }

          return acc;
        },
        {},
      );
    } else if (level === 'TRACED_CLOSURES') {
      return features?.tracedClosures?.reduce(
        (
          acc: { [x: string]: number },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = 1;
          } else {
            acc[obj.type_id]++;
          }

          return acc;
        },
        {},
      );
    } else if (level === 'TRACED_CABLES') {
      return features?.tracedCables?.reduce(
        (
          acc: { [x: string]: number },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = 1;
          } else {
            acc[obj.type_id]++;
          }

          return acc;
        },
        {},
      );
    } else if (level === 'L1_MISSING') {
      return features?.closuresNotInL1Polygon?.reduce(
        (
          acc: { [x: string]: number[] },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = [obj.id];
          } else {
            acc[obj.type_id].push(obj.id);
          }
          return acc;
        },
        {},
      );
    } else if (level === 'L2_MISSING') {
      return features?.closuresNotInL2Polygon?.reduce(
        (
          acc: { [x: string]: number[] },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = [obj.id];
          } else {
            acc[obj.type_id].push(obj.id);
          }
          return acc;
        },
        {},
      );
    } else if (level === 'ACCESS_FEED_MISSING') {
      return features?.accessAndFeedNotInL2Polygon?.reduce(
        (
          acc: { [x: string]: number[] },
          obj: { id: number; type_id: number },
        ) => {
          if (!acc[obj.type_id]) {
            acc[obj.type_id] = [obj.id];
          } else {
            acc[obj.type_id].push(obj.id);
          }
          return acc;
        },
        {},
      );
    }
  };

  const closureTypeFromEnum = (val: number) => {
    const keys: string[] = Object.keys(QGISClosureTypeEnum);
    for (const key of keys) {
      // @ts-ignore
      if (QGISClosureTypeEnum[key] === val) {
        return key;
      }
    }
  };

  const cableTypeFromEnum = (val: number) => {
    const keys: string[] = Object.keys(QGISCableTypeEnum);
    for (const key of keys) {
      // @ts-ignore
      if (QGISCableTypeEnum[key] === val) {
        return key;
      }
    }
  };

  return (
    <>
      <div style={{ marginBottom: 24 }}>
        <Card loading={!differenceList?.traceData} size="small" title={'Closures traced'}>
          {renderTraceSummaryClosures()}
        </Card>
        {closureType === 'L2' && (
          <Card loading={!differenceList?.traceData} size="small" title={'Cables traced'}>
            {renderTraceSummaryCables()}
          </Card>
        )}
        <Divider />
        <Card loading={!differenceList?.traceData} size="small" title={'Closures in cosmos'}>
          {renderClosuresTracedAggregate()}
        </Card>
        {closureType === 'L2' && (
          <Card loading={!differenceList?.traceData} size="small" title={'Cables in cosmos'}>
            {renderCablesTracedAggregate()}
          </Card>
        )}
        <Divider />
        <Card loading={!differenceList?.traceData} size="small" title={'Closure Design Issues'}>
          {renderClosuresMissing()}
        </Card>
        {closureType === 'L2' && (
          <Card loading={!differenceList?.traceData} size="small" title={'Cable Design issues'}>
            {renderCablesMissing()}
          </Card>
        )}
      </div>
    </>
  );
};

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState)(AutosplicingTraceOverview);
