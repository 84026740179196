import { Button, Tag } from '@blueprintjs/core';
import { OrganizationUserGroupEntity } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/group/organization.user.group.entity';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import { FC, useState } from 'react';
import { connect } from 'react-redux';

const maxGroupsInTheView: number = 5;

interface Props {
  record: DbRecordEntityTransform | undefined;
  userReducer: any;
}

const GroupDetails: FC<Props> = (props: Props) => {
  const { record, userReducer } = props;
  const [viewExpanded, setViewExpanded] = useState<boolean>(false);

  const renderGroupTags = () => {
    if (record) {
      let filteredGroups: { id: string; name: string }[] = [];

      // If record has more groups than we allow, place Show More button
      if (record.groups?.length! > maxGroupsInTheView && !viewExpanded) {
        filteredGroups = Object.assign(record.groups!.slice(0, maxGroupsInTheView));
      } else {
        filteredGroups = Object.assign(record.groups!);
      }

      return (
        <>
          {filteredGroups.map((group: OrganizationUserGroupEntity, i: number) => (
            <Tag minimal key={group?.id} style={{ marginBottom: 5, marginRight: 5 }}>
              {group.name}
            </Tag>
          ))}
          {record.groups!.length! > maxGroupsInTheView ? (
            <Button
              small
              intent="primary"
              minimal
              onClick={() => setViewExpanded(!viewExpanded)}
              style={{ fontSize: '0.9em', padding: '0 5px', height: 'auto' }}
            >
              {viewExpanded
                ? 'Show less'
                : `+ ${record.groups!.length! - filteredGroups.length} more`}
            </Button>
          ) : (
            <></>
          )}
        </>
      );
    } else {
      return <></>;
    }
  };

  const renderBody = () => {
    return (
      <Row align="middle">
        <Col span={24}>
          {record?.groups?.length! > 0 ? (
            renderGroupTags()
          ) : (
            <span style={{ fontSize: '0.9em' }}>None</span>
          )}
        </Col>
      </Row>
    );
  };

  return !record ? <></> : renderBody();
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(GroupDetails);
