import React, { FC } from 'react';
import { Col, Row } from 'antd';

interface Props {
  title: string;
  topStats: { title: string; value: number }[];
  properties: { name: string; value: string | number }[];
  fill?: boolean;
}

const CaseManagementDashboardStatCard: FC<Props> = (props: Props) => {
  const { title } = props;

  return (
    <Col xs={24} md={props.fill ? 24 : 12} lg={props.fill ? 24 : 8} style={{ padding: 8, height: '500px' }}>
      <Row
        align="top"
        style={{
          padding: 15,
          height: '100%',
          borderRadius: 8,
          background: '#F7F7F7',
          border: '1px solid #EBEBEB',
          alignContent: 'flex-start',
        }}
      >
        {/* Title and Icon */}
        <Col span={20} style={{ textAlign: 'left' }}>
          <h3 style={{ margin: 0 }}>{title}</h3>
        </Col>
        <Col span={4} style={{ textAlign: 'right' }}>
          <i className="bi bi-arrow-up-right" />
        </Col>
        {/* Top 3 stats */}
        <Col span={24} style={{ marginTop: 20 }}>
          <Row>
            {props.topStats.map((stat, index) => (
              <Col key={index} span={8} style={{ padding: 5 }}>
                <Row style={{ borderRadius: 8, background: '#FFFFFF', padding: 10 }}>
                  <Col span={24}>{stat.title}</Col>
                  <Col style={{ marginTop: 15 }} span={24}>
                    <h2 style={{ margin: 0 }}>{stat.value}</h2>
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </Col>

        {/* Properties */}
        <Col span={24} style={{ marginTop: 15 }}>
          {props.properties.map((property: { name: string; value: any }, index: number) => (
            <Row style={{ padding: '8px', borderBottom: '1px solid #EBEBEB' }}>
              <Col span={8} key={index}>
                <span key={index}>{property.name}</span>
              </Col>
              <Col span={16} key={index}>
                <span style={{ fontWeight: 600 }} key={index}>
                  {property.value}
                </span>
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </Col>
  );
};

export default CaseManagementDashboardStatCard;
