import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

export const getCustomRecordTitle = (
  record: DbRecordEntityTransform,
  customTitleElements: string[] = [],
) => {
  const response: string[] = [];

  if (customTitleElements.length > 0) {
    for (const elem of customTitleElements) {
      if (elem.toLowerCase() === 'title' && record.title) {
        response.push(record.title);
      }

      if (elem.toLowerCase() === 'recordnumber' && record.recordNumber) {
        response.push(record.recordNumber);
      }

      if (elem.toLowerCase() === 'type' && record.type) {
        response.push(record.type);
      }

      if (getProperty(record, elem)) {
        response.push(getProperty(record, elem));
      }
    }
  }

  return response.join(' - ');
};
