export const pipelineStageFormFields = (stage?: any) => [
  {
    label: 'Name',
    property: 'name',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input name',
    value: stage?.name || undefined,
  },
  {
    label: 'Description',
    property: 'description',
    type: 'TEXT',
    isRequired: !stage,
    value: stage?.description || undefined,
    message: 'Please input description',
  },

  {
    label: 'Allowed Next Stages',
    property: 'allowedNextStages',
    type: 'PIPELINE_STAGE_SELECT',
    isRequired: false,
    message: 'Please select stage',
    initialValue: stage?.allowedNextStages?.map((stage: any) => stage.id) || undefined,
    customValidation: true,
    customValidationCondition: 4,
  },
  {
    label: 'Position',
    property: 'position',
    type: 'NUMBER',
    isRequired: stage?.position,
    message: 'Please select position',
    isDisabled: !stage?.position,
    isHidden: !stage?.position,
    value: stage?.position || undefined,
    customValidation: true,
    customValidationCondition: 4,
    customValidationMessage: 'Key must be longer than or equal to 4 characters',
  },
  {
    label: 'Is Default',
    property: 'isDefault',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please check',
    initialValue: stage?.isDefault || undefined,
  },
  {
    label: 'Is Success',
    property: 'isSuccess',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please check',
    initialValue: stage?.isSuccess || undefined,
  },
  {
    label: 'Is Fail',
    property: 'isFail',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please check',
    initialValue: stage?.isFail || undefined,
  },
  {
    label: 'Is Triage',
    property: 'isTriage',
    type: 'CHECKBOX',
    isRequired: false,
    message: 'Please check',
    initialValue: stage?.isTriage || undefined,
  },
  {
    label: 'Stage Color',
    property: 'color',
    type: 'COLOR_PICKER',
    isRequired: false,
    message: 'Please select color',
    initialValue: stage?.color || undefined,
  },
];
