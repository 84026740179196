import { FC, useContext } from 'react';
import GoCardlessActivityFeed from '@legacy/core/billing/GocardlessActivityFeed';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import DetailView from '@core/views/DetailView';
import PaymentMethodCancel from '../../components/PaymentMethodCancel';
import PaymentMethodDisableBankAccount from '../../components/PaymentMethodDisableBankAccount';
import PaymentMethodReinstate from '../../components/PaymentMethodReinstate';

const PaymentMethodDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <DetailView
      showCollaborators
      headerExtras={[
        <PaymentMethodDisableBankAccount record={record} />,
        <PaymentMethodCancel record={record} />,
        <PaymentMethodReinstate record={record} />,
      ]}
      customTabs={[
        {
          key: 'GoCardless',
          tab: 'GoCardless',
        },
      ]}
      customTabBodies={{
        GoCardless: <GoCardlessActivityFeed record={record} filterBy="MANDATE" />,
      }}
    />
  );
};

export default PaymentMethodDetailView;
