import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import React from 'react';
import { Col, Divider, Empty, Layout, Row, Spin, Tag } from 'antd';
import './index.scss';
import { connect } from 'react-redux';
import { ISearchRecords, searchRecordsRequest } from '@legacy/core/records/store/actions';
import { Link } from 'react-router-dom';
import { storeSelectedEntity, storeSelectedModule } from '@legacy/core/navigation/store/actions';
import OdinIcons from '@legacy/components/Navigation/OdinIcons';
import { getOdinSchemaByEntity } from '../../helpers/schemaHelpers';
import Search from '@legacy/components/Search/Search';
import { Button, Section } from '@blueprintjs/core';
import Typography from '../../components/Typography';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;

interface Props {
  userReducer: any;
  navigationReducer: any;
  storeSelectedModule: any;
  storeSelectedEntity: any;
  searchRecords: any;
}

interface State {
  isLoadingReleaseNotes: boolean;
  articleList: Array<any>;
}

class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isLoadingReleaseNotes: false,
      articleList: [],
    };
  }

  componentDidMount() {
    this.getReleaseNoteArticles();
  }

  getFirstEntityFromModule = (moduleName: string) => {
    const { navigationReducer } = this.props;

    if (
      navigationReducer &&
      navigationReducer.navigationStructure &&
      navigationReducer.routingStructure
    ) {
      const targetedModule = navigationReducer.navigationStructure.find(
        (module: any) => module.moduleName === moduleName,
      );
      return targetedModule && targetedModule.entities.length > 0
        ? targetedModule.entities[0].entityName
        : '';
    }
  };

  renderArticleList = () => {
    if (this.state.articleList) {
      return this.state.articleList.map((article: any, i: number) => (
        <Row style={{ marginBottom: 7 }} key={i}>
          <Col span={18} key={i}>
            <Link to={`/${SUPPORT_MODULE}/KnowledgeArticle/${article.id}/view`} target="_blank">
              {article.title}
            </Link>
          </Col>
          <Col span={6} style={{ textAlign: 'right' }}>
            <Tag>{getProperty(article, 'Version') ? getProperty(article, 'Version') : '-'}</Tag>
          </Col>
          {i === this.state.articleList.length - 1 ? (
            <></>
          ) : (
            <Divider style={{ margin: '8px 0 3px 0' }} />
          )}
        </Row>
      ));
    } else {
      return (
        <Col span={24}>
          <Empty description="No Release Notes" />
        </Col>
      );
    }
  };

  getReleaseNoteArticles = async () => {
    const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, 'KnowledgeArticle');
    this.searchArticles(schema);
  };

  searchArticles = (schema: SchemaEntity) => {
    const { searchRecords } = this.props;
    this.setState({ isLoadingReleaseNotes: true });

    searchRecords(
      {
        schema: schema,
        searchQuery: {
          terms: '',
          schemas: schema?.id,
          boolean: {
            must: [
              {
                query_string: {
                  fields: ['type'],
                  query: 'RELEASE_NOTE',
                  lenient: true,
                  default_operator: 'AND',
                },
              },
              {
                query_string: {
                  fields: ['properties.Status'],
                  query: 'PUBLISHED',
                  lenient: true,
                  default_operator: 'AND',
                },
              },
            ],
          },
          sort: [{ createdAt: { order: 'desc' } }],
        },
      },
      (searchResults: any) => {
        if (searchResults.data.data) {
          this.setState({ isLoadingReleaseNotes: false });
          this.setState({ articleList: searchResults.data.data });
        } else {
          this.setState({ isLoadingReleaseNotes: false });
          this.setState({ articleList: [] });
        }
      },
    );
  };

  renderModules = () => {
    const { storeSelectedModule, storeSelectedEntity, navigationReducer } = this.props;

    const menuModules = navigationReducer?.navigationStructure?.filter(
      (module: any) => module.showInApps,
    );

    return menuModules?.map((module: any) => (
      <Col xs={8} sm={8} md={3} lg={4} xl={4} key={module.moduleName}>
        <Link
          to={`/${module.moduleName}/${this.getFirstEntityFromModule(module.moduleName)}`}
          onClick={() => {
            storeSelectedModule({ selectedModule: module.moduleName });
            storeSelectedEntity({
              selectedEntity: this.getFirstEntityFromModule(module.moduleName),
            });
          }}
          className="topMenuAppLink"
        >
          <Button style={{ width: '90%', textAlign: 'center', paddingTop: 30, margin: 5 }} minimal>
            <Col span={24}>{OdinIcons(module.icon, 'moduleIcon')}</Col>
            <Col span={24} style={{ paddingTop: '7px', paddingBottom: '15px' }}>
              <Typography strong>{module.menuModuleName}</Typography>
            </Col>
          </Button>
        </Link>
      </Col>
    ));
  };

  render() {
    const userInformation = this.props.userReducer.user;
    const { navigationReducer } = this.props;

    return (
      <Layout
        style={{
          padding: 8,
          borderTop: '1px solid #dadada',
          background: 'transparent',
          overflow: 'auto',
        }}
      >
        <Row justify="center" align="middle">
          <Col xl={18} lg={24} sm={24} md={24} className="homePageContainer">
            {/* Welcome card */}
            <Section title="Application Modules">
              <Row style={{ textAlign: 'center', padding: 10 }}>
                {navigationReducer.navigationStructure !== null ? this.renderModules() : <Spin />}
              </Row>
            </Section>
          </Col>

          <Col xl={18} lg={24} sm={24} md={24} className="homePageContainer">
            <Section title="Search">
              <Row style={{ padding: 20 }} justify="center">
                <Col xs={24} md={12} lg={12}>
                  <Search
                    entities={
                      window.location.host?.indexOf('netomnia') > -1
                        ? ['ProjectModule:Project', 'ProjectModule:Feature']
                        : ['CrmModule:Contact']
                    }
                    schema={{
                      id: 'GLOBAL_SEARCH_DRAWER',
                      moduleName: 'SchemaModule',
                      entityName: 'ALL',
                    }}
                    renderStyle="card"
                  />
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapDispatch = (dispatch: any) => ({
  storeSelectedModule: (params: { selectedModule: string }) =>
    dispatch(storeSelectedModule(params)),
  storeSelectedEntity: (params: { selectedEntity: string }) =>
    dispatch(storeSelectedEntity(params)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
});

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

export default connect(mapState, mapDispatch)(Home);
