import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

export const getCaseUserFromRecord = (record: DbRecordEntityTransform) => {
  if (record.caseOwner && record.caseOwner?.length! > 0) {
    const user = record.caseOwner[0];
    return user.name || 'No User';
  } else {
    return 'No User';
  }
};

export const getCaseContactFromRecord = (record: DbRecordEntityTransform) => {
  if (record.caseContact && record.caseContact?.length! > 0) {
    const contact = record.caseContact[0];
    return contact.name || 'Unknown';
  } else {
    return 'Unknown';
  }
};
