export type TMessageTemplate = {
  title: string;
  text: string;
};

export const EMAIL_TEMPLATES: TMessageTemplate[] = [
  {
    title: 'DPA - DATA PROTECTION',
    text:
      'Dear {{customerName}},\n\n' +
      'Thanks for reaching out! We’d love to help with your request, but first, we just need to confirm a few details for security.\n\n' +
      'Could you please reply to this email with:\n\n' +
      '• Your full name\n' +
      '• Your address, including postcode\n' +
      '• The speed or cost of your package per month\n\n' +
      'Once we have these details, we’ll be all set to assist you!\n\n' +
      'If you have any other questions, just reply here, or you can check our contact options and hours here.\n\n' +
      'Looking forward to helping you soon!\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'SUPPORT – BILLING DATE CHANGE REQUEST',
    text:
      'Dear {{customerName}},\n\n' +
      'Thank you for reaching out about changing your billing date.\n\n' +
      'Thanks for reaching out about changing your billing date. I’d like to explain what will happen if we change the billing date to [xx/xx]: \n\n' +
      '•Payment Timing: The date the money leaves your account won’t be the same as the billing date. We’ll request the payment from your bank on [XXX], and it may take 3-5 working days for the funds to be processed and taken from your account. \n' +
      '•Higher Next Invoice: Your next invoice will be higher than usual. This is because we’ll adjust the amount pro-rata to cover the extended billing period for the new date. \n' +
      '•Existing Invoices: Please note that this change won’t affect any invoices that have already been generated. If we’ve already emailed you this month’s invoice, we can’t change the date on it since it has already been processed. \n\n' +
      'Please can you confirm you are happy to proceed with this change?\n\n' +
      'If you have any more questions, please feel free to ask\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'SUPPORT – BILLING DATE CHANGE CONFIRMING',
    text:
      'Dear {{customerName}},\n\n' +
      'Thank you for confirming that you would like to proceed with changing your billing date to [xx/xx]. I can confirm that this has been actioned for you. \n\n' +
      'Please be aware that your next invoice is set to be £[xx.xx], based on the direct debit date change. This amount will be requested on the billing date you confirmed.\n\n' +
      'If you have any questions or need further assistance, please don’t hesitate to reach out.\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'SUPPORT – CHURN – POSTAGE DETAIL',
    text:
      'Dear {{customerName}},\n\n' +
      'Thanks for your reply.\n\n' +
      'I have arranged for a free return bag to be sent to you for the return of the equipment, to confirm we are expecting the return of the following devices:\n\n' +
      '• [ROUTER DEVICE NAME] (Serial Number: XXXXXXXXXX)\n' +
      '• [ADDITIONAL ROUTER DEVICE] (Serial Number: XXXXXXXXXX)\n\n' +
      'Once you have received this return bag, please safely return this parcel to any Royal Mail Post Office and retain the proof of postage receipt, this will need to be sent to ourselves either via live chat or this can be e-mail to us at hello@youfibre.com along with your security information (Full name, full address including postcode and the Speed/Price of your package) so as that we can track the return of these devices.\n\n' +
      'Once we have received these devices we will be able to cancel the account for you and confirmation of this will be sent to you via e-mail.\n\n' +
      "If you have any further requests, please contact us at your nearest convenience on 0800 270 0000 (Monday to Sunday 08:00 - 20:00) and we will be more than happy to assist you. Should you require anything in the meantime, please don't hesitate to contact us via our live chat (Monday to Sunday 24/7).\n\n" +
      'Kind regards,\n',
  },
  {
    title: 'SUPPORT – CHURN – CANCELLATION CONFIRMATION',
    text:
      'Dear {{customerName}},\n\n' +
      'Hope you are well,\n\n' +
      'I can confirm that your order number [ADD ORDER NUMBER] has now been cancelled.\n\n' +
      'I  can also confirm the following equipment has been received:' +
      '• [ROUTER DEVICE NAME] (Serial Number: XXXXXXXXXX)\n' +
      '• [ADDITIONAL ROUTER DEVICE] (Serial Number XXXXXXXXXX)\n\n' +
      'If your Direct Debit was already set up, it will be cancelled automatically from our side, but please double check your bank account to remove it.\n\n' +
      "We're always here to help, so please do not hesitate to contact us back if there's anything else we can assist you with.\n\n" +
      'Kind regards,\n',
  },
  {
    title: 'SUPPORT – PIC – CANCELLATION NON-LIVE ORDER REQUEST',
    text:
      'Dear {{customerName}},\n\n' +
      'Hope you are well.\n\n' +
      'Thank you for your reaching out and sorry to hear you are wanting to cancel.\n\n' +
      'We have passed your request over to the relevant team.\n' +
      'They will be in contact shortly to process your request.\n\n' +
      'This ticket will be closed down as the request has a new open case with the relevant team.\n\n' +
      'Please let us know if we can help with anything else.\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'SUPPORT – OUTAGE IN PROGRESS',
    text:
      'Dear {{customerName}},\n\n' +
      'Thanks for reaching out!\n\n' +
      'I wanted to let you know that there’s currently an outage in your area.\n\n' +
      "Our team is actively working to diagnose and resolve the issue as quickly as possible. We'll be providing regular updates to keep you informed on our progress and will let you know as soon as everything is back to normal.\n\n" +
      'Thank you for your patience—we’re sorry for any inconvenience this may cause.\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'SUPPORT – OUTAGE RESOLVED',
    text:
      'Dear {{customerName}}\n\n' +
      'I hope this email finds you well.\n\n' +
      'I am pleased to inform you that the outage in your area has been resolved, and your service should now be functioning normally.\n\n' +
      'Important: If you are experiencing any issues, please turn off both the router (Eero/Arris) and the modem (black box) at the mains socket for 30 seconds. Once you have powered them back on, please allow a few minutes for the connection to restart.\n\n' +
      'If you continue to experience difficulties after rebooting the equipment, kindly reply to this email with the following information:\n\n' +
      '•Your full name\n' +
      '•Your full address and postcode\n' +
      '•Your package speed or monthly cost\n' +
      '•The light sequence on both the black box and your router (you may attach a photo if that is easier)\n\n' +
      'Thank you once again for your patience while we resolved this issue. Have a wonderful day!\n',
  },
  {
    title: 'INSTALLS – COSTS UNAVAILABLE',
    text:
      'Dear {{customerName}}\n\n' +
      'I hope this email finds you well.\n\n' +
      'Our Network Partners have advised us that they recently tried to complete your installation. Unfortunately, our engineers identified some complications in connecting you to the external network. The report has been reviewed by our build team to identify the remediation work needed to get you connected.\n\n' +
      'Unfortunately, they have informed us that the work required will be extensive and costly. With the additional disruption it would cause within the community, our Network Partners have categorised the work as unviable.\n\n' +
      'Our build team have looked at all alternative routes and the only other route belongs to a section of the external network that is still under construction. Regrettably, this means we are unable to complete an installation and we will have to cancel your order.\n\n' +
      "I'm incredibly sorry I couldn't bring better news. Rest assured, our teams are working hard to complete the external network and as soon as we can provide an installation via a viable route, we will be in touch directly.\n\n" +
      'Please let me know if you have any questions or concerns, and I will be happy to address these. Alternatively, you can reach out to us using the methods detailed www.youfibre.com/contact.\n\n' +
      'Once again, our sincerest apologies.\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'INSTALLS – TARGET COMPLETION DATE',
    text:
      'Dear {{customerName}}\n\n' +
      'Many thanks for your email.\n\n' +
      'Our network partners have advised us that the current target completion date for your remediation work is the [DATE]\n\n' +
      'This date has been selected based on the average resolution time for similar issues, and the availability of the team to attend. Please note that this is a target date, and depending on how the work progresses it may be completed sooner or later.\n\n' +
      'Please rest assured we will be in touch as soon as the work has been completed, and we are able to book you back in for install.\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'INSTALLS – SCHEDULED WORKS',
    text:
      'Dear {{customerName}}\n\n' +
      'We hope this email finds you well.\n\n' +
      'First and foremost, we would like to extend our sincerest apologies for any inconvenience you may have experienced during your time with us here at YouFibre.\n\n' +
      'We wanted to provide you with an update regarding the remediation works required for your service. We have scheduled a team to attend to the outstanding works for the [DATE AND TIME]. Please note that while this is not a guaranteed completion date, our teams are working as incredibly quickly and efficiently to resolving this for us.\n\n' +
      'Please also be aware that this date may need to be rescheduled due to permit times and dates, engineer availability and further time and resources needing to be required.\n\n' +
      'Upon the completion of the remediation works, we will notify you to re-schedule your YouFibre installation appointment.\n\n' +
      'If you have any further questions to this, please do not hesitate to contact us.\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'INSTALLS – UNINSTALLABLE',
    text:
      'Dear {{customerName}}\n\n' +
      'I hope this email finds you well. I am writing to provide you with an update on the status of your YouFibre Broadband order.\n\n' +
      'Our Network Partners have informed us that they made an attempt to complete your installation recently. However, during this process, our engineers encountered some complications that hindered the connection. Upon reviewing the report submitted by our engineers, our build team assessed the situation to determine the necessary remediation work required to establish the connection.\n\n' +
      'Unfortunately, despite exploring all possible alternative routes, we regret to inform you that we are currently unable to proceed with the installation of our network to your property. This means that we are unable to fulfil the installation and, as a result, we must cancel your order at this time.\n\n' +
      'I understand that this news may be disappointing, and for that, I sincerely apologise.\n\n' +
      'If you have any questions or concerns regarding this matter, please do not hesitate to contact me. I am more than happy to help.\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'OPERATIONS – CONTRACT BUY OUT - INFORMATION',
    text:
      'Dear {{customerName}}\n\n' +
      'Thank you for your email.\n\n' +
      'To initiate your refund process for the early termination fee from your previous provider, we will need the following information:\n\n' +
      '• Your Order Number and Address (from your YouFibre order)\n' +
      '• Your full, final bill from your previous provider (this should include the amount charged for leaving your contract early; please note that this can sometimes take a couple of weeks to be issued after you cancel your services)\n' +
      '• Your order with us must be active\n' +
      '• You will need to have paid your first invoice with us\n\n' +
      'Once you have gathered these details, please send your final bill to etf@youfibre.com. Make sure to include your full name, order number, and address in the email.\n\n' +
      'Please note that we can only refund up to £300 towards your early termination fee, and we cannot cover any outstanding debts or bills owed to your previous provider.\n\n' +
      'Thank you!\n\n' +
      'Kind regards,\n',
  },
  {
    title: 'OPERATIONS – CONTRACT BUY OUT – REFUND PROCESSED',
    text:
      'Dear {{customerName}}\n\n' +
      'I hope you are well.\n\n' +
      'I’m pleased to inform you that your refund of £[xx.xx] has now been processed. This is to cover your Early Termination Fee with your previous provider.\n\n' +
      'The funds should arrive in the bank account associated with your YouFibre account within the next three days.\n\n' +
      'If you require any further assistance, please don’t hesitate to reach out.\n\n' +
      'Thank you!\n',
  },
  {
    title: 'OPERATIONS – CONTRACT BUY OUT – ESTIMATED FEES',
    text:
      'Dear {{customerName}}\n\n' +
      'I hope you’re well.\n\n' +
      'It seems you’ve sent an email with an estimate of your fees from your current provider. Your final bill, with the complete breakdown, will arrive once your services have been fully ceased with your previous provider.\n\n' +
      'To move forward with your refund, we’ll need a copy of the final bill that shows the full fee breakdown, including your early termination fee (as this is the amount we’ll be refunding). If you’ve used the One Touch Switch Service, we’ll still require the final bill document rather than a screenshot or summary of fees from an email.\n\n' +
      'Once you’ve received the final bill, please send it over to us at etf@youfibre.com, along with your order number and address, so we can start processing your refund.\n\n' +
      'A few things to keep in mind:\n\n' +
      '• We can refund up to £300 towards your Early Termination Fee.\n' +
      '• Your first invoice from YouFibre must be settled before we proceed.\n\n' +
      'For now, we’ll close this ticket until we hear back from you with the final bill.\n\n' +
      'Thank you, and we look forward to assisting you soon!\n\n' +
      'Kind regards\n',
  },
  {
    title: 'OPERATIONS – REFERRALS INFORMATION',
    text:
      'Dear {{customerName}}\n\n' +
      'Thank you for enquiring about our ‘Refer a Friend’ scheme. All information can be found on our website page - www.youfibre.com/refer\n\n' +
      'You could both earn up to £100, depending on the package that is chosen.\n\n' +
      'Please make sure the referred customer follows your unique referral link and takes out their order online. If this process is not followed, the automated payment will not reach your accounts nor will you be entitled to the other.\n\n' +
      'There is no limit on the amount of people you can refer. The more friends and family members you refer, the more money you could earn!\n\n' +
      'Let us know if you have any other questions!\n\n' +
      'Kind regards\n',
  },
  {
    title: 'OPERATIONS – REFERRALS PROCESS INCORRECTLY FOLLOWED',
    text:
      'Dear {{customerName}}\n\n' +
      'I hope you are well.\n\n' +
      'We have been investigating your referral reward.\n\n' +
      'When claiming a referral reward, the referred customer needsto place their order via the website using a unique referral link provided by you. Without this, neither Aklamio nor YouFibre can process the reward to both of your Aklamio accounts.\n\n' +
      'It looks like this step wasn’t followed, so we’re unable to process the cash reward in this instance. However, as a gesture of goodwill, we’re happy to offer one month free for both accounts, applied as credit on your next invoice. Please let us know if you’d like to go ahead with this option.\n\n' +
      'For future reference, you can find full information about our referral process on the following link -  www.youfibre.com/refer\n\n' +
      'Thank you, and please reach out if you have any questions.\n\n' +
      'Kind regards,\n',
  },
];

export const MESSAGE_TEMPLATES: TMessageTemplate[] = [
  {
    title: 'DPA - DATA PROTECTION',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for reaching out. I can see you’ve provided some details, but we weren’t able to match these to an account. Could you confirm your answers to ensure we can securely access your account? We need the following:\n\n' +
      '• Your full name (this is the name on the account)\n' +
      '• Your address, including postcode (where you are receiving service)\n' +
      '• The speed or cost of your package per month\n\n' +
      'If anything’s unclear, just let me know—I’m here to help!\n',
  },
  {
    title: 'SUPPORT – BILLING DATE CHANGE REQUEST',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for reaching out about changing your billing date. Here’s what will happen if we change it to [xx/xx]\n\n' +
      '• Payment Timing: The money will leave your account on a different date. We’ll request it from your bank on [XXX], and it may take 3-5 working days for them to process it.\n' +
      '• Higher Next Invoice: Your next invoice will be higher because we’ll adjust the amount pro-rata for the extended billing period.\n' +
      '• Existing Invoices: This change won’t affect any invoices we’ve already sent. If we’ve emailed you this month’s invoice, we can’t change its date as it’s already been processed.\n\n' +
      'Are you happy for us to change your billing date based on the above?\n',
  },
  {
    title: 'SUPPORT – BILLING DATE CHANGE CONFIRMING',
    text:
      'Thanks for confirming that you’d like to change your billing date to [xx/xx] I’ve gone ahead and actioned that for you!\n\n' +
      'Just a heads up, your next invoice will be £[xx.xx] based on this direct debit date change, and the payment will be requested on the confirmed billing date.\n\n' +
      'Is there anything else I can help you with?\n',
  },
  {
    title: 'SUPPORT – CHURN – POSTAGE DETAIL',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for your reply. I’ve arranged for a free return bag to be sent to you for returning the equipment. We’re expecting the following devices back:\n\n' +
      '• [ROUTER DEVICE NAME] (Serial Number: XXXXXXXXXX)\n' +
      '• [ADDITIONAL ROUTER DEVICE] (Serial Number: XXXXXXXXXX)\n\n' +
      'Once you receive the return bag, please drop the parcel off at any Royal Mail Post Office and keep the proof of postage receipt. You can send that to us via live chat or email it to hello@youfibre.com along with your security info (full name, full address with postcode, and the speed/price of your package) so we can track the return.\n\n' +
      'Once we have the devices, we’ll cancel your account and send you confirmation via email.\n\n' +
      'If you have any other questions, feel free to reach out at 0800 270 0000 (Monday to Sunday, 08:00 - 20:00) or contact us through live chat anytime (24/7). We’re here to help!\n\n' +
      'Is there anything else I can help you with?\n',
  },
  {
    title: 'SUPPORT – CHURN – CANCELLATION CONFIRMATION',
    text:
      'Hi {{customerName}}!\n\n' +
      'Thank you for getting in contact, I can confirm that your order number [ADD ORDER NUMBER] has been cancelled.\n\n' +
      'We’ve also received the following equipment:\n\n' +
      '• [ROUTER DEVICE NAME] (Serial Number: XXXXXXXXXX)\n' +
      '• [ADDITIONAL ROUTER DEVICE] (Serial Number XXXXXXXXXX)\n\n' +
      'If you had a Direct Debit set up, it will be cancelled automatically on our side, but please check your bank account to confirm.\n\n' +
      'Is there anything else I can help you with?\n',
  },
  {
    title: 'SUPPORT – PIC – CANCELLATION NON-LIVE ORDER REQUEST',
    text:
      'Hi {{customerName}}!\n\n' +
      'Thank you for your reaching out and sorry to hear you are wanting to cancel.\n\n' +
      'We have passed your request over to the relevant team. \n' +
      'They will be in contact shortly to process your request.\n\n' +
      'Is there anything else I can help you with?\n',
  },
  {
    title: 'SUPPORT – OUTAGE IN PROGRESS',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for reaching out. Just a quick update: there’s currently an outage in your area, and our team is working hard to resolve it as soon as possible. Right now, we don’t have an exact timeline, but we’ll send regular updates to your email to keep you informed.\n\n' +
      'Thanks so much for your patience—sorry for any inconvenience this may cause!\n',
  },
  {
    title: 'SUPPORT – OUTAGE RESOLVED',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for reaching out to us. I’m sorry to hear you’ve been having trouble with your connection and for the delay in our response.\n\n' +
      'We recently had a brief outage in your area, but your service should now be restored.\n\n' +
      'If you’re still experiencing issues, could you please turn off both your router (Eero/Arris) and modem (black box) at the mains for 30 seconds? After that, turn them back on and wait a few minutes for the connection to restart.\n\n' +
      'If you’re still having problems, please let me know.\n\n' +
      'Thank you for your patience, and I apologise for any inconvenience this may have caused!',
  },
  {
    title: 'INSTALLS – COSTS UNAVAILABLE',
    text:
      'Hi {{customerName}}\n\n' +
      'I have just had a look at your account to understand what has happened during your installation.\n\n' +
      'It seems as though Our Network Partners recently tried to complete it, but our engineers encountered complications connecting to the external network. Unfortunately, the required work is extensive and deemed unviable due to potential community disruption.\n\n' +
      'The only alternative route is still under construction, so we can’t proceed with your installation and will have to cancel your order. I’m really sorry about this.\n\n' +
      'Our teams are working hard to finish the external network, and we’ll reach out if a viable installation route becomes available.\n\n' +
      'Is there anything else I can help you with at all?\n',
  },
  {
    title: 'INSTALLS – TARGET COMPLETION DATE',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for reaching out. Our network partners have set a target completion date for your remediation work on [DATE]. This is based on the usual resolution time and team availability, though it might be completed a bit sooner or later, depending on progress.\n\n' +
      'We’ll be in touch as soon as the work is finished so we can get your installation booked in.\n\n' +
      'Is there anything else I can help you with at all?\n',
  },
  {
    title: 'INSTALLS – SCHEDULED WORKS',
    text:
      'Hi {{customerName}}!\n\n' +
      'Firstly, we’re really sorry for any inconvenience you’ve experienced with joining YouFibre.\n\n' +
      'We have an update on the remediation works: our team is scheduled to attend on [DATE AND TIME].\n' +
      'While this isn’t a guaranteed completion date, please know we’re working as quickly as possible to resolve it.\n\n' +
      'There’s a chance this date may need to shift due to permits, engineer availability, or additional resources. Once the work is complete, we’ll reach out to reschedule your YouFibre installation.\n\n' +
      'Is there anything else I can help you with?\n',
  },
  {
    title: 'INSTALLS – UNINSTALLABLE',
    text:
      'Hi {{customerName}}\n\n' +
      'I’ve just had a look at your account and our Network Partners recently tried to complete your installation, but our engineers ran into some complications that prevented the connection.\n\n' +
      'After reviewing the situation, our build team determined the necessary remediation work, but unfortunately, we can’t proceed with the installation to your property right now.\n\n' +
      'As a result, we’ll need to cancel your order.\n\n' +
      'I know this news is disappointing, and I’m really sorry about that.\n\n' +
      'Please let me know if I can be of any further help\n',
  },
  {
    title: 'OPERATIONS – CONTRACT BUY OUT - INFORMATION',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for your message!\n\n' +
      'To start your refund process for the early termination fee from your previous provider, we’ll need a few things from you:\n\n' +
      '• Your order number and address\n' +
      '• Your full final bill from your previous provider, showing the early termination fee (this can take a couple of weeks to arrive after cancellation)\n' +
      '• Your order with us needs to be active\n' +
      '• You must have paid your first invoice with us\n\n' +
      'Once you have everything, please send your final bill to etf@youfibre.com. Don’t forget to include your full name, order number, and address in the email.\n\n' +
      'Just a quick note: we can refund up to £300 for the early termination fee, but we can’t cover any debts owed to your previous provider.\n\n' +
      'Is there anything else I can help you with?\n',
  },
  {
    title: 'OPERATIONS – CONTRACT BUY OUT – REFUND PROCESSED',
    text:
      'Hi {{customerName}}\n\n' +
      'Your refund of [amount] has been processed. This is to cover your Early Termination Fee with your previous provider.\n\n' +
      'The funds should arrive in the bank account linked to your YouFibre account within the next three days.\n\n' +
      'Is there anything else I can help you with?\n',
  },
  {
    title: 'OPERATIONS – CONTRACT BUY OUT – ESTIMATED FEES',
    text:
      'Hi {{customerName}}\n\n' +
      'Thanks for sending that over, however you have sent through an estimate early termination fee. For us to process your refund, we’ll need the final bill from your previous provider showing the full fee breakdown, including the early termination fee. (If you used the One Touch Switch Service, we still need the actual bill document—not just a screenshot from an email)\n\n' +
      'Once you have it, please email the final bill to etf@youfibre.com with your order number and address. Quick notes:\n\n' +
      '• We refund up to £300 towards the Early Termination Fee. \n' +
      '• Your first YouFibre invoice needs to be paid before we proceed.\n\n' +
      'Let me know if you have any other questions! \n',
  },
  {
    title: 'OPERATIONS – REFERRALS INFORMATION',
    text:
      'Hi {{customerName}}!\n\n' +
      'Thanks for asking about our Refer a Friend scheme! All the details are on our website here: www.youfibre.com/refer.\n\n' +
      'You and your friend could each earn up to £100, depending on the package they choose. Just make sure they follow your unique referral link and complete their order online—if not, the automated payment won’t go through.\n\n' +
      'There’s no limit on referrals, so the more friends and family you refer, the more you can earn!\n',
  },
  {
    title: 'OPERATIONS – REFERRALS PROCESS INCORRECTLY FOLLOWED',
    text:
      'Hi {{customerName}}!\n\n' +
      'We’ve checked into your referral reward, and it seems the order wasn’t placed through the referral link, so we’re unable to process the cash reward this time. However, as a goodwill gesture, we can offer one month free for both accounts, which will be credited on your next invoice.\n\n' +
      'Just let us know if you’d like to go ahead with the free month! For future reference details on our referral process, can be found here www.youfibre.com/refer\n\n' +
      'Are you happy to accept the credit to your accounts?\n',
  },
];
