import { Alert, Button, Card, Col, Drawer, Row, Spin, Statistic, Tag } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet } from '@core/http/requests';
import { CheckCircleTwoTone, CloseCircleOutlined, SyncOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  record: DbRecordEntityTransform;
}

type Check = {
  state: 'SUCCESS' | 'ERROR';
  value: unknown;
  problems: string[];
};

type Summary = {
  lastCheckDate?: string;
  message?: string;
  dataState?: string;
  dataDate?: string;
  data: Record<string, Check>;
  routerState?: string;
  routerDate?: string;
  router: Record<string, Check>;
  voiceState?: string;
  voiceDate?: string;
  voice: Record<string, Check>;
};

let timer: NodeJS.Timeout | undefined = undefined;
const interval = 4000;

const NetworkDeviceDrawer: FC<Props> = ({ open, onClose, record }: Props) => {
  const [runningCheck, setRunningCheck] = useState(false);
  const [checkError, setCheckError] = useState<string>();
  const [loadingSummary, setLoadingSummary] = useState(false);
  const [summary, setSummary] = useState<Summary>();
  const [summaryError, setSummaryError] = useState<string>();

  async function getSummary() {
    try {
      setSummaryError(undefined);
      if (record) {
        const response = await httpGet(`ServiceModule/v2.0/network/full-check/${record?.id}/summary`);
        setSummary(response.data?.data);
      }
    } catch (e: any) {
      console.error(e);
      setSummaryError(e?.response?.data?.message || e?.response?.message || e?.message);
    }

    setLoadingSummary(false);
  }

  useEffect(() => {
    if (open) {
      setLoadingSummary(true);
      void getSummary();
    }
  }, [open]);

  useEffect(() => {
    timer = setInterval(async () => {
      if (!document.hidden) {
        await getSummary();
      }
    }, interval);

    return () => {
      clearInterval(timer);
      timer = undefined;
    };
  }, []);

  async function runFullCheck() {
    setRunningCheck(true);
    setCheckError(undefined);

    try {
      if (record) {
        await httpGet(`ServiceModule/v2.0/network/full-check/${record.id}`);
      }
    } catch (e: any) {
      console.error(e);
      setCheckError(e?.response?.data?.message || e?.response?.message || e?.message);
    }

    setRunningCheck(false);
  }

  function getTagFromState(state: string) {
    let color = 'gray';

    if (state === 'COMPLETE') {
      color = 'green';
    }

    if (state === 'PENDING') {
      color = 'orange';
    }

    if (state === 'ERROR') {
      color = 'red';
    }

    return (
      <Tag color={color}>
        {color === 'orange' && <SyncOutlined spin />} {state}
      </Tag>
    );
  }

  function formatDate(date?: string, prefix?: string) {
    if (date) {
      return (
        <span
          style={{
            color: 'gray',
            fontSize: 12,
          }}
        >
          ({prefix ? `${prefix} ` : ''}
          {DateTime.fromISO(date).toFormat('d/M/yyyy h:mm a')})
        </span>
      );
    }

    return '';
  }

  function renderChecks(data: Record<string, Check>) {
    if (data['message']) {
      return <Alert message={data['message']} type="error" />;
    }

    return (
      <>
        {Object.keys(data)
          .filter((x) => data[x])
          .map((x) => (
            <div key={x}>
              <span style={{ marginRight: 8 }}>
                {data[x].state === 'SUCCESS' ? (
                  <CheckCircleTwoTone twoToneColor="#52c41a" size={100} />
                ) : (
                  <CloseCircleOutlined style={{ color: 'red' }} />
                )}
              </span>
              <strong>{x}:</strong> {data[x].value}
              {data[x].problems?.length > 0 && (
                <div style={{ marginLeft: 22, marginBottom: 8 }}>
                  {data[x].problems.map((y) => (
                    <div style={{ color: 'gray' }} key={y}>
                      {y}
                    </div>
                  ))}
                </div>
              )}
            </div>
          ))}
      </>
    );
  }

  function isRunningChecks() {
    return (
      runningCheck ||
      summary?.dataState === 'PENDING' ||
      summary?.voiceState === 'PENDING' ||
      summary?.routerState === 'PENDING'
    );
  }

  return (
    <Drawer width={isMobile ? '90%' : '65%'} title={'Full Check'} open={open} onClose={onClose}>
      <Card size="small" style={{ marginTop: 12 }}>
        {checkError && <Alert type="error" message={checkError} />}
        <p>
          A full check will check everything on the order in sequence and alert you to any possible
          issues, including: data service, voice service, router and ONT. <br />
          This process will take a few minutes to complete and results will be posted below.
        </p>
        <Button
          type={'primary'}
          onClick={() => runFullCheck()}
          disabled={isRunningChecks()}
          loading={isRunningChecks()}
        >
          Run Full Check
        </Button>
      </Card>
      <h3>Last Check Summary {formatDate(summary?.lastCheckDate)}</h3>

      {summaryError && <Alert type="error" message={summaryError} />}
      {loadingSummary && (
        <Row>
          <Col span={24} style={{ textAlign: 'center', marginTop: 30 }}>
            <Spin size="large" style={{ marginBottom: 30 }} />
            <br />
            <span>Loading summary...</span>
          </Col>
        </Row>
      )}
      {summary?.message && <p>{summary?.message}</p>}
      {summary?.dataState && summary?.dataState !== 'NOT_FOUND' && (
        <Card
          title={<>ONT Data Service {formatDate(summary?.dataDate, 'as of')}</>}
          extra={getTagFromState(summary.dataState)}
          size="small"
          style={{ marginTop: 12 }}
        >
          {renderChecks(summary.data)}
        </Card>
      )}
      {summary?.voiceState && summary?.voiceState !== 'NOT_FOUND' && (
        <Card
          title={<>Voice Service {formatDate(summary?.voiceDate, 'as of')}</>}
          extra={getTagFromState(summary.voiceState)}
          size="small"
          style={{ marginTop: 12 }}
        >
          {renderChecks(summary.voice)}
        </Card>
      )}
      {summary?.routerState && summary?.routerState !== 'NOT_FOUND' && (
        <Card
          title={<>Router {formatDate(summary?.routerDate, 'as of')}</>}
          extra={getTagFromState(summary.routerState)}
          size="small"
          style={{ marginTop: 12 }}
        >
          {renderChecks(summary.router)}
        </Card>
      )}
    </Drawer>
  );
};

export default NetworkDeviceDrawer;
