import { useReducer } from 'react';

export type EditablePropertiesState = { [recordId: string]: { [field: string]: string } };
const eidtablePropertiesInitialState: EditablePropertiesState = {};

interface EditablePropertiesSetFieldAction {
  type: 'set_field';
  payload: {
    recordId: string;
    field: string;
    value: string;
  };
}

const editablePropertiesReducer = (
  state = eidtablePropertiesInitialState,
  action: EditablePropertiesSetFieldAction,
) => {
  switch (action.type) {
    case 'set_field':
      return {
        ...state,
        [action.payload.recordId]: {
          ...(state[action.payload.recordId] ?? {}),
          [action.payload.field]: action.payload.value,
        },
      };
    default:
      return state;
  }
};

export const useEditableProperties = () => {
  const [state, localDispatch] = useReducer(
    editablePropertiesReducer,
    eidtablePropertiesInitialState,
  );
  const getState = () => state;
  const getFieldValue = (recordId: string, field: string) =>
    state[recordId] ? state[recordId][field] ?? null : null;
  const setFieldValue = (recordId: string, field: string, value: string) =>
    localDispatch({
      type: 'set_field',
      payload: { recordId, field, value },
    });

  return { getState, getFieldValue, setFieldValue };
};
