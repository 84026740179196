import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

// If a record belongs to a certain schema type, we need to filter out those related schemas that don't fulfill the record schema requirements.
export const getSchemasForDefaultDetailView = (
  schema: SchemaEntity,
  record: DbRecordEntityTransform,
) => {
  let allowedSchemas: string[] = [];
  const schemaAssociations = schema.associations;

  // If a record belongs to a certain schema type, we need to filter out those related schemas that don't fulfill the record schema requirements.
  if (schemaAssociations?.length > 0 && record) {
    for (const schemaAssociation of schemaAssociations) {
      // Type constraints available
      if (schemaAssociation.schemaTypesConstraints.length > 0) {
        // For each constraint
        schemaAssociation.schemaTypesConstraints.forEach((constraint: any) => {
          if (constraint.parentSchemaType.name === record.type) {
            allowedSchemas.push(schemaAssociation.childSchema?.name);
          } else if (constraint.childSchemaType.name === record.type) {
            allowedSchemas.push(schemaAssociation.parentSchema?.name as string);
          }
        });
      }
      // No type constraints
      else {
        if (schemaAssociation.childSchema) {
          allowedSchemas.push(schemaAssociation.childSchema?.name);
        } else if (schemaAssociation.parentSchema) {
          allowedSchemas.push(schemaAssociation.parentSchema?.name);
        }
      }
    }
  }

  return allowedSchemas;
};
