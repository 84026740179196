import { getTemplateVersionFromPathName } from '../helpers';
import {
  CHECK_AUTOSPLICING_ACCESS_ERROR,
  CHECK_AUTOSPLICING_ACCESS_REQUEST,
  CHECK_AUTOSPLICING_ACCESS_SUCCESS,
  CHECK_AUTOSPLICING_DISTRIBUTION_ERROR,
  CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST,
  CHECK_AUTOSPLICING_DISTRIBUTION_SUCCESS,
  CHECK_AUTOSPLICING_RESULTS_ERROR,
  CHECK_AUTOSPLICING_RESULTS_REQUEST,
  CHECK_AUTOSPLICING_RESULTS_SUCCESS,
  CHECK_AUTOSPLICING_SPINE_ERROR,
  CHECK_AUTOSPLICING_SPINE_REQUEST,
  CHECK_AUTOSPLICING_SPINE_SUCCESS,
  CLEAN_AUTOSPLICING_ISSUES_ERROR,
  CLEAN_AUTOSPLICING_ISSUES_REQUEST,
  CLEAN_AUTOSPLICING_ISSUES_SUCCESS,
  GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_ERROR,
  GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST,
  GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_SUCCESS,
  GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_ERROR,
  GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST,
  GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_SUCCESS,
  GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_ERROR,
  GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST,
  GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_SUCCESS,
  GET_AUTOSPLICING_TEMPLATES_ERROR,
  GET_AUTOSPLICING_TEMPLATES_REQUEST,
  GET_AUTOSPLICING_TEMPLATES_SUCCESS,
  GET_SPLICING_SHORTHAND_BY_CLOSUREID_ERROR,
  GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST,
  GET_SPLICING_SHORTHAND_BY_CLOSUREID_SUCCESS,
  RESET_AUTOSPLICING_BY_CABLE_ERROR,
  RESET_AUTOSPLICING_BY_CABLE_REQUEST,
  RESET_AUTOSPLICING_BY_CABLE_SUCCESS,
  RESET_AUTOSPLICING_BY_TEMPLATE_ERROR,
  RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST,
  RESET_AUTOSPLICING_BY_TEMPLATE_SUCCESS,
  RESET_AUTOSPLICING_POLYGON_REQUEST,
  TOGGLE_AUTOSPLICING_GRAPH_MAXIMIZED_VIEW,
  UPDATE_AUTOSPLICING_LOADING_INDICATORS,
  UPDATE_AUTOSPLICING_REDUCER_STATE,
} from './constants';
import { IAutosplicingReducer } from './types';

// Autosplicing Reducer: Initial State
export const initialState: IAutosplicingReducer = {
  activeTab: '1',
  allJobs: [],
  cableDiagramMaximized: false,
  closureType: '',
  cosmosGraphTooltipVisible: false,
  currentTemplateVersionIndicator: '',
  diagramLayout: 'COSMOS',
  differenceList: undefined,
  enteredCableId: undefined,
  enteredClosureId: undefined,
  featureQuickViewDrawerVisible: false,
  fiberDifferences: false,
  focusedGraphEntity: undefined,
  formData: undefined,
  graphDisplayMode: 'PLAN',
  helpDrawerVisible: false,
  latestData: [],
  latestTemplateVersion: '',
  previousData: [],
  resetDrawerAction: null,
  resetDrawerVisible: false,
  resetProcessing: false,
  selectedEntity: undefined,
  selectedEntityOdinRecord: undefined,
  showSplicingRequestForChangeForm: true,
  tracedClosureRecordId: undefined,
  historicData: [],
  cableIdsWithIssues: [],
  loadingIndicators: {
    isLoadingTemplates: false,
    isLoadingTemplatesError: false,
    isLoadingSelectedGraphEntity: false,
    isLoadingJobs: false,
    isLoadingMatrices: true,
    isCheckingFiber: false,
    isCheckingResults: false,
    isCleaningIssues: false,
    isLoadingShorthandSplicing: false,
  },
};

function autosplicingReducer(state = initialState, action: any) {
  switch (action.type) {
    case UPDATE_AUTOSPLICING_REDUCER_STATE: {
      return {
        ...state,
        ...action.params,
      };
    }

    case UPDATE_AUTOSPLICING_LOADING_INDICATORS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          ...action.params,
        },
      };
    }

    case TOGGLE_AUTOSPLICING_GRAPH_MAXIMIZED_VIEW: {
      return {
        ...state,
        cableDiagramMaximized: !state.cableDiagramMaximized,
      };
    }

    case RESET_AUTOSPLICING_POLYGON_REQUEST: {
      return {
        ...state,
        resetDrawerVisible: false,
        resetDrawerAction: null,
      };
    }

    case RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST: {
      return {
        ...state,
        resetProcessing: true,
        resetDrawerVisible: false,
        resetDrawerAction: null,
      };
    }

    case RESET_AUTOSPLICING_BY_TEMPLATE_SUCCESS: {
      return {
        ...state,
        resetProcessing: false,
        resetDrawerVisible: false,
        resetDrawerAction: null,
      };
    }

    case RESET_AUTOSPLICING_BY_TEMPLATE_ERROR: {
      return {
        ...state,
        resetProcessing: false,
        resetDrawerVisible: false,
        resetDrawerAction: null,
      };
    }

    case RESET_AUTOSPLICING_BY_CABLE_REQUEST: {
      return {
        ...state,
        resetProcessing: true,
        resetDrawerVisible: false,
        resetDrawerAction: null,
      };
    }

    case RESET_AUTOSPLICING_BY_CABLE_SUCCESS: {
      return {
        ...state,
        resetProcessing: false,
        resetDrawerVisible: false,
        resetDrawerAction: null,
      };
    }

    case RESET_AUTOSPLICING_BY_CABLE_ERROR: {
      return {
        ...state,
        resetProcessing: false,
        resetDrawerVisible: false,
        resetDrawerAction: null,
      };
    }

    case GET_AUTOSPLICING_TEMPLATES_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingTemplates: true,
        },
      };
    }

    case GET_AUTOSPLICING_TEMPLATES_SUCCESS: {
      let ver: string = getTemplateVersionFromPathName(
        action.results?.data?.data?.cableConnectionTemplate?.pathName,
      );

      return {
        ...state,
        selectedEntity: {
          id: state.formData?.closure_id,
          type: 'CLOSURE',
          source: 'COSMOS',
        },
        currentTemplateVersionIndicator: ver,
      };
    }

    case GET_AUTOSPLICING_TEMPLATES_ERROR: {
      return {
        ...state,
        latestData: [],
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingTemplates: false,
          isLoadingTemplatesError: true,
        },
      };
    }

    case GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST: {
      return {
        ...state,
      };
    }

    case GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_SUCCESS: {
      return {
        ...state,
        latestData: action.results?.data?.data?.data,
        previousData: [],
        diagramLayout: 'COSMOS',
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingTemplates: false,
          isLoadingTemplatesError: false,
        },
      };
    }

    case GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_ERROR: {
      return {
        ...state,
        latestData: [],
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingTemplates: false,
          isLoadingTemplatesError: true,
        },
      };
    }

    case GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST: {
      return {
        ...state,
      };
    }

    case GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_SUCCESS: {
      return {
        ...state,
        latestData: action.results?.data?.data?.latestFile?.data,
        previousData: action.results?.data?.data?.previousFile?.data,
        differenceList: action.results?.data?.data,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingTemplates: false,
          isLoadingTemplatesError: false,
        },
      };
    }

    case GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_ERROR: {
      return {
        ...state,
        latestData: [],
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingTemplates: false,
          isLoadingTemplatesError: true,
        },
      };
    }

    case GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingMatrices: true,
        },
      };
    }
    case GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_SUCCESS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingMatrices: false,
        },
      };
    }
    case GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_ERROR: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingMatrices: false,
        },
      };
    }

    case GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingShorthandSplicing: true,
        },
      };
    }
    case GET_SPLICING_SHORTHAND_BY_CLOSUREID_SUCCESS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingShorthandSplicing: false,
        },
      };
    }
    case GET_SPLICING_SHORTHAND_BY_CLOSUREID_ERROR: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isLoadingShorthandSplicing: false,
        },
      };
    }

    case CHECK_AUTOSPLICING_SPINE_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: true,
        },
      };
    }
    case CHECK_AUTOSPLICING_SPINE_SUCCESS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: false,
        },
      };
    }
    case CHECK_AUTOSPLICING_SPINE_ERROR: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: false,
        },
      };
    }

    case CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: true,
        },
      };
    }
    case CHECK_AUTOSPLICING_DISTRIBUTION_SUCCESS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: true,
        },
      };
    }
    case CHECK_AUTOSPLICING_DISTRIBUTION_ERROR: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: true,
        },
      };
    }

    case CHECK_AUTOSPLICING_ACCESS_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: true,
        },
      };
    }
    case CHECK_AUTOSPLICING_ACCESS_SUCCESS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: true,
        },
      };
    }
    case CHECK_AUTOSPLICING_ACCESS_ERROR: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingFiber: true,
        },
      };
    }

    case CHECK_AUTOSPLICING_RESULTS_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingResults: true,
        },
      };
    }
    case CHECK_AUTOSPLICING_RESULTS_SUCCESS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingResults: false,
        },
      };
    }
    case CHECK_AUTOSPLICING_RESULTS_ERROR: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCheckingResults: false,
        },
      };
    }

    case CLEAN_AUTOSPLICING_ISSUES_REQUEST: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCleaningIssues: true,
        },
      };
    }
    case CLEAN_AUTOSPLICING_ISSUES_SUCCESS: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCleaningIssues: false,
        },
      };
    }
    case CLEAN_AUTOSPLICING_ISSUES_ERROR: {
      return {
        ...state,
        loadingIndicators: {
          ...state.loadingIndicators,
          isCleaningIssues: false,
        },
      };
    }

    default:
      return state;
  }
}

export default autosplicingReducer;
