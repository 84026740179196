import ArrowLeftOutlined from '@ant-design/icons/ArrowLeftOutlined';
import ArrowRightOutlined from '@ant-design/icons/ArrowRightOutlined';
import type { AvatarProps, TagType } from 'antd';
import { Avatar, Col, ConfigProvider, Row, Space } from 'antd';
import type { DirectionType } from 'antd/lib/config-provider';
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import * as React from 'react';
import 'antd/lib/breadcrumb/style';
import './index.scss';

export type ContentWidth = 'Fluid' | 'Fixed';

export interface PageHeaderProps {
  backIcon?: React.ReactNode;
  prefixCls?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  style?: React.CSSProperties;
  childrenContentStyle?: React.CSSProperties;
  tags?: React.ReactElement<TagType> | React.ReactElement<TagType>[];
  footer?: React.ReactNode;
  extra?: React.ReactNode;
  avatar?: AvatarProps;
  onBack?: (e?: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  contentWidth?: ContentWidth;
  ghost?: boolean;
  children?: React.ReactNode;
  breadcrumbRender?: () => React.ReactNode;
}

const renderBack = (
  prefixCls: string,
  hashId: string,
  backIcon?: React.ReactNode,
  onBack?: (e?: React.MouseEvent<HTMLElement>) => void,
) => {
  if (!backIcon || !onBack) {
    return null;
  }
  return (
    <div className={`${prefixCls}-back ${hashId}`}>
      <div
        role="button"
        onClick={(e) => {
          onBack?.(e);
        }}
        className={`${prefixCls}-back-button ${hashId}`}
        aria-label="back"
      >
        {backIcon}
      </div>
    </div>
  );
};

const getBackIcon = (props: PageHeaderProps, direction: DirectionType = 'ltr') => {
  if (props.backIcon !== undefined) {
    return props.backIcon;
  }
  return direction === 'rtl' ? <ArrowRightOutlined /> : <ArrowLeftOutlined />;
};

const renderTitle = (
  prefixCls: string,
  props: PageHeaderProps,
  direction: DirectionType = 'ltr',
  hashId: string,
) => {
  const { title, avatar, subTitle, tags, extra, onBack } = props;
  const headingPrefixCls = `${prefixCls}-heading`;
  const hasHeading = title || subTitle || tags || extra;
  // If there is nothing, return a null
  if (!hasHeading) {
    return null;
  }
  const backIcon = getBackIcon(props, direction);
  const backIconDom = renderBack(prefixCls, hashId, backIcon, onBack);
  const hasTitle = backIconDom || avatar || hasHeading;
  return (
    <div className={headingPrefixCls + ' ' + hashId}>
      {props.breadcrumbRender && (
        <div className={`${headingPrefixCls}-left ${hashId}`}>
          <Col span={24}>
            <Space>{props.breadcrumbRender()}</Space>
          </Col>
        </div>
      )}
      {hasTitle && (
        <div className={`${headingPrefixCls}-left ${hashId}`}>
          {backIconDom}
          {avatar && (
            <Avatar
              className={classNames(`${headingPrefixCls}-avatar`, hashId, avatar.className)}
              {...avatar}
            />
          )}
          {title && (
            <span
              className={`${headingPrefixCls}-title ${hashId}`}
              title={typeof title === 'string' ? title : undefined}
            >
              {title}
            </span>
          )}
          {subTitle && (
            <span
              className={`${headingPrefixCls}-sub-title ${hashId}`}
              title={typeof subTitle === 'string' ? subTitle : undefined}
            >
              {subTitle}
            </span>
          )}
          {tags && <span className={`${headingPrefixCls}-tags ${hashId}`}>{tags}</span>}
        </div>
      )}
      {extra && (
        <span className={`${headingPrefixCls}-extra ${hashId}`}>
          <Space>{extra}</Space>
        </span>
      )}
    </div>
  );
};

const renderFooter = (prefixCls: string, footer: React.ReactNode, hashId: string) => {
  if (footer) {
    return <div className={`${prefixCls}-footer ${hashId}`}>{footer}</div>;
  }
  return null;
};

const renderChildren = (prefixCls: string, children: React.ReactNode, hashId: string) => (
  <div className={`${prefixCls}-content ${hashId}`}>{children}</div>
);

const PageHeader: React.FC<PageHeaderProps> = (props) => {
  const [compact, updateCompact] = React.useState<boolean>(false);
  const onResize = ({ width }: { width: number }) => {
    updateCompact(width < 768);
  };

  const { getPrefixCls, direction } = React.useContext(ConfigProvider.ConfigContext);

  const {
    prefixCls: customizePrefixCls,
    style,
    footer,
    children,
    className: customizeClassName,
    contentWidth,
  } = props;

  const prefixCls = getPrefixCls('page-header', customizePrefixCls);
  //   const { wrapSSR, hashId } = useStyle(prefixCls);

  const className = classNames(prefixCls, '123', customizeClassName, {
    [`${prefixCls}-has-footer`]: !!footer,
    [`${prefixCls}-rtl`]: direction === 'rtl',
    [`${prefixCls}-compact`]: compact,
    [`${prefixCls}-wide`]: contentWidth === 'Fixed',
    [`${prefixCls}-ghost`]: true,
  });
  const title = renderTitle(prefixCls, props, direction, '123');
  const childDom = children && renderChildren(prefixCls, children, '123');
  const footerDom = renderFooter(prefixCls, footer, '123');

  if (!title && !footerDom && !childDom) {
    return null;
  }

  return (
    <ResizeObserver onResize={onResize}>
      <div className={className} style={style}>
        {title}
        {childDom}
        {footerDom}
      </div>
    </ResizeObserver>
  );
};

export { PageHeader };
