import {
  DbRecordAssociationRecordsTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/transform/db.record.association.records.transform';
import {
  RelationTypeEnum,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Button, Checkbox, Col, List, Popover, Row, Select, Spin, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { canUserSearchRecord } from '@core/helpers/rbacRules';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { TableReducer } from '../../../records/components/DynamicTable/store/reducer';
import RecordProperties from '../../../records/components/RecordProperties';
import { getRecordByIdRequest, IGetRecordById } from '../../../records/store/actions';
import { IRecordReducer } from '../../../records/store/reducer';
import { IRecordAssociationsReducer } from '../../store/reducer';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
  updateOrCreateRecordAssociations,
} from '../../store/actions';
import RecordAssociationSearch from '../Search';
import { Drawer } from '@blueprintjs/core';
import { isMobile } from 'react-device-detect';
import {
  SchemaAssociationEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { getAllSchemaAssociationsMatchingEntityName } from '../AssociationDataTable/helpers';

interface Props {
  record: DbRecordEntityTransform;
  relation: DbRecordAssociationRecordsTransform;
  relationType?: RelationTypeEnum;
  hidden?: string[];
  userReducer: any;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordTableReducer: TableReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  pipelinesEnabled?: boolean;
  createAssociations: any;
  getAssociations: any;
  getRecordById: any;
  onLookupSuccess?: Function;
  customText?: string;
}

interface State {
  visible: boolean;
  selected: { entity?: string; recordId: string }[];
  selectedRelation: any;
}

class LookUpDrawer extends React.Component<Props, State> {
  state = { visible: false, selected: [], selectedRelation: undefined };

  componentDidMount() {
    this.getAllSchemaAssociation();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (this.props.relation !== prevProps.relation) {
      this.getAllSchemaAssociation();
    }
  }

  getAllAssociationsForEntity = () => {
    const { schemaReducer, record, relation } = this.props;
    const shortlistParentSchema = getSchemaFromShortListBySchemaId(
      schemaReducer.shortList,
      record.schemaId,
    );
    const relatedEntity = relation.schema?.entityName;

    if (shortlistParentSchema && relatedEntity) {
      return getAllSchemaAssociationsMatchingEntityName(
        shortlistParentSchema.associations,
        relatedEntity,
      );
    } else {
      return [];
    }
  };

  // There can be many schema associations per one entity, here we check if that's the case,
  // and preselect the first one if there is only one.
  getAllSchemaAssociation() {
    const allAssociations = this.getAllAssociationsForEntity();

    // Many schema associations per entity
    if (allAssociations.length > 1) {
      this.setState({ selectedRelation: undefined });
    }
    // One schema association per entity
    else {
      this.setState({ selectedRelation: this.props.relation });
    }
  }

  private openDrawer() {
    this.setState({
      visible: true,
    });
  }

  // This is a callback function for dependent components
  private onLookupSuccess = () => {
    if (this.props.onLookupSuccess) {
      this.props.onLookupSuccess();
    }
  };

  addRemoveItem = (item: DbRecordEntityTransform) => {
    if (
      this.state.selected.find(
        (elem: { entity?: string; recordId: string }) => elem.recordId === item.id,
      )
    ) {
      // remove the item
      this.setState({
        selected: this.state.selected.filter(
          (elem: { entity?: string; recordId: string }) => elem.recordId !== item.id,
        ),
      });
    } else {
      this.setState((prevState) => ({
        selected: [
          ...prevState.selected,
          {
            entity: item.entity,
            recordId: item.id,
          },
        ],
      }));
    }
  };

  handleOk = () => {
    const { record, relation, createAssociations, schemaReducer } = this.props;
    const { schemaAssociation } = relation;
    if (schemaAssociation && record) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
      const associationEntities = schemaAssociation.label?.split('__');
      const isSelfAssociation = associationEntities
        ? associationEntities[0] === associationEntities[1]
        : false;
      const body = this.state.selected.map((elem: { entity?: string; recordId: string }) => ({
        entity: elem.entity,
        recordId: elem.recordId,
        quantity: 1,
        relationType: isSelfAssociation ? RelationTypeEnum.CHILD : null,
      }));
      createAssociations(
        {
          recordId: record.id,
          schema,
          schemaAssociation,
          createUpdate: body,
        },
        () => {
          this.handleCancel();
          this.onLookupSuccess();
          // fetch record relations
          this.getRecordAssociations();
        },
      );
    }
  };

  handleCancel = () => {
    this.setState({
      visible: false,
      selected: [],
    });
  };

  private renderListItemTitle(item: DbRecordEntityTransform) {
    if (item.type) {
      return (
        <div>
          <div>
            <Typography.Text>{item.type}</Typography.Text>
          </div>
          <div>
            <Typography.Text>
              {item.recordNumber ? `${item.recordNumber} - ` : ''} {item.title}
            </Typography.Text>
          </div>
        </div>
      );
    } else if (item.title && item.recordNumber) {
      return `${item.recordNumber} ${item.title}`;
    } else if (item.title && !item.recordNumber) {
      return item.type ? `${item.type} ${item.title}` : item.title;
    }
  }

  renderRelatedRecordsList = () => {
    const { recordAssociationReducer } = this.props;
    return (
      <>
        <List
          style={{ height: '73vh', overflow: 'auto', width: '100%' }}
          loading={recordAssociationReducer.isSearching}
          itemLayout="horizontal"
          dataSource={recordAssociationReducer.list as any[]}
          renderItem={(item: DbRecordEntityTransform) => (
            <List.Item
              key={item.id}
              actions={[
                <Checkbox onChange={() => this.addRemoveItem(item)}>Add</Checkbox>,
                <Popover
                  title={item.type ? `${item.type} ${item.title}` : item.title}
                  content={
                    <div
                      style={{
                        width: 300,
                      }}
                    >
                      <RecordProperties record={item} columns={1} size="small" />
                    </div>
                  }
                >
                  Details
                </Popover>,
              ]}
            >
              <List.Item.Meta
                title={this.renderListItemTitle(item)}
                description={getProperty(item, 'Description')}
              />
              <div>{item.properties?.UnitPrice}</div>
            </List.Item>
          )}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            width: '100%',
            marginTop: 16,
          }}
        >
          <Button
            type="primary"
            loading={recordAssociationReducer.isCreating}
            disabled={this.state.selected.length < 1}
            onClick={() => this.handleOk()}
          >
            Save{' '}
          </Button>
        </div>
      </>
    );
  };

  private getRecordAssociations() {
    const { getAssociations, record, schemaReducer, relation, getRecordById } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);
    if (record && schema) {
      getRecordById({ schema, recordId: record.id });
      getAssociations({
        recordId: record.id,
        key: relation.schema.entityName,
        schema: schema,
        entities: [relation.schema.entityName],
      });
    }
    return <div>data fetched</div>;
  }

  setRelation = (relationId: string) => {
    const allRelations = this.getAllAssociationsForEntity();
    const selectedRelation = allRelations.find(
      (relation: SchemaAssociationEntity) => relation.id === relationId,
    );
    if (selectedRelation) {
      this.setState({
        selectedRelation: {
          schemaAssociation: selectedRelation,
          schema: this.props.relation.schema,
        },
      });
    }
  };

  render() {
    const { userReducer, record, relation, recordReducer } = this.props;
    const { schema } = relation;
    const associations = this.getAllAssociationsForEntity();

    let customFilters: any = {};

    // Custom Filter 1: Fetch only users with Status = ACTIVE
    if (relation && relation?.schema?.entityName === 'User') {
      customFilters = {
        must: [
          {
            query_string: {
              fields: ['properties.Status'],
              query: 'ACTIVE',
              lenient: true,
              default_operator: 'AND',
            },
          },
        ],
      };
    }

    return (
      <>
        <span
          key="lookupDrawer"
          style={{
            opacity: canUserSearchRecord(userReducer, schema) ? 1 : 0.3,
            cursor: canUserSearchRecord(userReducer, schema) ? 'pointer' : 'no-drop',
          }}
          onClick={() => {
            if (canUserSearchRecord(userReducer, schema)) {
              this.openDrawer();
            }
          }}
        >
          {this.props.customText || 'Lookup'}
        </span>

        <Drawer
          title={this.props.customText || `Add ${schema.entityName}`}
          isOpen={this.state.visible}
          onClose={this.handleCancel}
          style={{ width: isMobile ? '95%' : 500 }}
        >
          <Spin spinning={recordReducer.isRequesting} tip="Saving changes...">
            <Row style={{ padding: '20px 15px 0 15px' }}>
              {/* Multiple associations per entity */}
              <Col span={24} style={{ marginBottom: 10 }}>
                <Select
                  defaultValue={associations.length === 1 ? associations[0].id : undefined}
                  placeholder="Select Association Label"
                  style={{ width: '100%' }}
                  onChange={(e: any) => this.setRelation(e)}
                  disabled={associations.length === 1}
                >
                  {/* Many Schema Associations */}
                  {associations.length > 1 &&
                    associations.map((association: SchemaAssociationEntity) => (
                      <Select.Option key={association.id} value={association.id}>
                        {association.label}
                      </Select.Option>
                    ))}
                  {/* One Schema Association */}
                  {associations.length === 1 && (
                    <Select.Option key={associations[0].id} value={associations[0].id}>
                      {associations[0].label}
                    </Select.Option>
                  )}
                </Select>
              </Col>
              <Col span={24} style={{ marginBottom: 10 }}>
                {this.state.selectedRelation && (
                  <RecordAssociationSearch
                    record={record}
                    relation={this.state.selectedRelation}
                    hideActions
                    customFilters={customFilters}
                  />
                )}
              </Col>
              {this.state.selectedRelation && this.renderRelatedRecordsList()}
            </Row>
          </Spin>
        </Drawer>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  recordTableReducer: state.recordTableReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  createAssociations: (params: any, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
});

export default connect(mapState, mapDispatch)(LookUpDrawer);
