import React, { useEffect } from 'react';
import { Calendar, Col, Row } from 'antd';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { WorkOrderList } from './WorkOrderList';
import { UtilizationData } from '../../context/types';
import { useCalendarService, useCalendarState } from '../../context';
import { isMobile } from 'react-device-detect';
import { Tag } from '@blueprintjs/core';

dayjs.extend(isBetween);

type Props = {
  workOrders: DbRecordEntityTransform[];
  earlyAvail: DbRecordEntityTransform[];
  range: { start: string; end: string };
  viewItemDetails: (record: DbRecordEntityTransform) => void;
  utilization: UtilizationData[];
  isOverview: boolean;
};

type TimeBlock = 'AM' | 'PM';

const getAvailabilityColor = (utilization: UtilizationData, timeBlock: TimeBlock) => {
  const total = utilization[`${timeBlock}Count`] + utilization[`${timeBlock}Remaining`];
  const timeBlockUsage = utilization[`${timeBlock}Count`] / total;

  if (timeBlockUsage < 0.5) return '#61bd61'; // green
  if (timeBlockUsage >= 0.5 && timeBlockUsage < 0.75) return '#ffa167'; // orange
  if (timeBlockUsage >= 0.75) return '#ff4747'; // red
};

const getSelectedDate = (
  range: { start: string; end: string },
  date: string = dayjs().format('YYYY-MM-DD'),
) => {
  const rangeStart = dayjs(range.start);
  const rangeEnd = dayjs(range.end);
  const day = dayjs(date);

  if (day.isBetween(rangeStart, rangeEnd)) return date;

  const currentDay = day.get('date');
  return rangeStart.add(currentDay - 1, 'days').format('YYYY-MM-DD');
};

export const MonthView: React.FC<Props> = (props: Props) => {
  const calendarService = useCalendarService();
  const {
    appointments: { selectedDate },
  } = useCalendarState();

  const { workOrders, range, viewItemDetails, utilization, isOverview } = props;

  useEffect(() => {
    const newSelectedDate = getSelectedDate(range, selectedDate);
    if (newSelectedDate !== selectedDate) calendarService.setSelectedDate(newSelectedDate);
  }, [selectedDate, JSON.stringify(range)]);

  const getUtilizationForDate = (date: string) => utilization.find((u) => u.Date === date);

  const calendarDateChanged = (date: string) => {
    calendarService.setSelectedDate(date);
    calendarService.setDateFilter(date);
  };

  const renderDay = (date: string) => {
    const utilizationForDate = getUtilizationForDate(date);
    if (!utilizationForDate) return null;

    return (
      <Row justify="center" style={{ height: '60%', paddingTop: 3 }} align="middle">
        <Col span={19}>
          <Tag
            minimal
            fill
            style={{ padding: 3 }}
            rightIcon={
              <span
                style={{
                  fontWeight: 700,
                  background: getAvailabilityColor(utilizationForDate, 'AM') || '#215DB0',
                  color: 'white',
                  padding: '0px 4px',
                  borderRadius: 3,
                }}
              >
                {utilizationForDate.AMCount || 0} / {utilizationForDate.MaxAMCount || 0}
              </span>
            }
          >
            <span style={{ fontWeight: 500 }}>AM</span>
          </Tag>
        </Col>
        <Col span={19} style={{ marginTop: 3 }}>
          <Tag
            fill
            minimal
            style={{ padding: 3 }}
            rightIcon={
              <span
                style={{
                  fontWeight: 700,
                  background: '#215DB0',
                  color: 'white',
                  padding: '0px 4px',
                  borderRadius: 3,
                }}
              >
                {utilizationForDate.PMCount || 0} / {utilizationForDate.MaxPMCount || 0}
              </span>
            }
          >
            <span style={{ fontWeight: 500 }}>PM</span>
          </Tag>
        </Col>
        <Col span={19} style={{ marginTop: 3 }}>
          <Tag
            fill
            minimal
            style={{ padding: 3 }}
            rightIcon={
              <span
                style={{
                  padding: '1px 4px',
                  borderRadius: 3,
                }}
              >
                {utilizationForDate.Utilization
                  ? utilizationForDate.Utilization.toFixed(1) + '%'
                  : '0%'}
              </span>
            }
          >
            <span style={{ fontWeight: 500 }}>Util.</span>
          </Tag>
        </Col>
      </Row>
    );
  };

  return (
    <Row>
      <Col xs={24} md={17} style={{ border: '1px solid #DCDCDD', borderTop: 0, borderRight: 0 }}>
        <Calendar
          validRange={[dayjs().subtract(1, 'years'), dayjs().add(3, 'years')]}
          value={dayjs(selectedDate)}
          onSelect={(dayjsDate) => {
            if (!isOverview) {
              calendarService.setSelectedDate(dayjsDate.format('YYYY-MM-DD'));
            }
          }}
          onChange={(dayjsDate) => {
            if (!isOverview) {
              calendarDateChanged(dayjsDate.format('YYYY-MM-DD'));
            }
          }}
          cellRender={(dayjsDate) => renderDay(dayjsDate.format('YYYY-MM-DD'))}
          headerRender={() => <></>}
        />
      </Col>
      <Col xs={24} md={7} style={{ paddingLeft: 10 }}>
        <WorkOrderList
          title=""
          records={workOrders}
          utilization={getUtilizationForDate(selectedDate)}
          viewItemDetails={viewItemDetails}
          hideWorkOrders={isOverview}
        />
      </Col>
    </Row>
  );
};
