import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import React from 'react';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Section } from '@blueprintjs/core';

interface Props {
  contactRecord: DbRecordEntityTransform;
}

const ContactDetailsColumn: React.FC<Props> = (props: Props) => {
  const { contactRecord } = props;

  const getInitialsFromUserName = (userName: string) => {
    const splitUserName = userName.split(' ');
    const firstName = splitUserName[0]?.toUpperCase() || '-';
    const lastName = splitUserName[1]?.toUpperCase() || '-';
    return firstName[0] + lastName[0];
  };

  return (
    <Section title="Contact Details">
      <Row
        style={{
          padding: 10,
          overflowX: 'hidden',
          alignContent: 'flex-start',
          minHeight: 'calc(100vh - 180px)',
        }}
        justify="start"
        align="middle"
      >
        <Col span={24} style={{ marginTop: 10, textAlign: 'left' }}>
          {/* Avatar */}

          <div
            style={{
              display: 'inline-block',
              background: '#EFEFEF',
              padding: 6,
              fontSize: '1em',
              height: 32,
              width: 32,
              borderRadius: 50,
            }}
          >
            <span>
              {contactRecord?.title ? getInitialsFromUserName(contactRecord.title) : '--'}
            </span>
          </div>

          <span
            style={{
              marginLeft: 8,
              lineHeight: 0,
              fontSize: '1.3em',
              fontWeight: 600,
            }}
          >
            {contactRecord?.title || 'Unknown User'}
          </span>
        </Col>

        {/* Personal Information + Communications History */}
        <>
          <Col span={24} style={{ marginTop: 30 }}>
            <span style={{ fontWeight: 700 }}>Personal Information</span>
          </Col>

          <Col span={24} style={{ marginTop: 15 }}>
            <span style={{ opacity: 0.5 }}>Email</span>
          </Col>
          <Col span={24} style={{ marginTop: 5 }}>
            <span>{getProperty(contactRecord, 'EmailAddress') || '-'}</span>
          </Col>

          {/* Phone Number */}
          <Col span={24} style={{ marginTop: 20 }}>
            <span style={{ opacity: 0.5 }}>Phone Number</span>
          </Col>
          <Col span={24} style={{ marginTop: 5 }}>
            <span>{getProperty(contactRecord, 'Phone') || '-'}</span>
          </Col>
        </>
      </Row>
    </Section>
  );
};

export default ContactDetailsColumn;
