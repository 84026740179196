import { Icon, Section, Tab, Tabs } from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { getSchemaByIdRequest } from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import ViewConfigurationListView from '@core/modules/SchemaManagerModule/views/ViewConfigurationListView';
import SchemaAssociationsListView from '../../Associations/DetailView';
import SchemaColumnListView from '../../Columns/ListView';
import SchemaPipelineDetailView from '../../Pipeline/DetailView';
import SchemaActionList from '../../SchemaActions/SchemaActionListView';
import SchemasPermissions from '../../SchemasPermissions/DetailView';
import SchemaSettingsDetailView from '../../Settings/DetailView';
import SchemaTypesList from '../../Types/ListView';
import './styles.scss';

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

type PathParams = {
  schemaId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  match: any;
  schemaReducer: ISchemaReducer;
  getSchema: any;
};

interface State {
  activeTab: string;
}

// const { TabPane } = Tabs;

class SchemasDetailView extends React.Component<PropsType, State> {
  constructor(props: PropsType) {
    super(props);

    this.state = {
      activeTab: 'Columns',
    };
  }

  componentDidMount() {
    this.fetchData();

    var hash = window.location.hash;

    if (hash) {
      var tab = hash.split('#')[1];
      this.setState({ activeTab: tab });
    } else {
      this.setState({ activeTab: 'Columns' });
    }
  }

  fetchData() {
    const { getSchema, match } = this.props;
    const schemaId = match.params.schemaId;

    if (schemaId) {
      getSchema({ schemaId: schemaId });
    }
  }

  componentDidUpdate(prevProps: Readonly<PropsType>, prevState: Readonly<State>): void {
    if (prevState.activeTab !== this.state.activeTab) {
      window.history.pushState('#' + this.state.activeTab, '', '#' + this.state.activeTab);
    }
  }

  render() {
    const { schemaReducer, match } = this.props;
    const schemaId = match.params.schemaId;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, schemaId);

    return (
      <>
        <Row>
          <Col span={24}>
            <Section
              title={
                <>
                  <Link to={`/${SCHEMA_MODULE}/Schema`}>
                    <span>Schemas</span>
                  </Link>
                  <Icon icon="caret-right" />
                  <span>{schema?.entityName || ''}</span>
                </>
              }
              subtitle="Schema Manager"
              style={{ marginTop: 1 }}
              icon={
                <ModuleEntityIcon
                  moduleName="SchemaModule"
                  entityName="Schema"
                  overrideIcon="diagram-3-fill"
                  overrideIconColor="#1777ff"
                  iconContainerStyle={{ padding: '7px 10px' }}
                />
              }
            />
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ marginTop: 1 }}>
            <Tabs
              className="schemaTabs"
              selectedTabId={this.state.activeTab}
              onChange={(tab: string) => this.setState({ activeTab: tab })}
              renderActiveTabPanelOnly={true}
            >
              <Tab
                // icon="list-columns"
                title="Columns"
                id="Columns"
                panel={<SchemaColumnListView schema={schema} />}
              />

              <Tab
                // icon="application"
                title="Types"
                id="Types"
                panel={<SchemaTypesList schema={schema} />}
              />

              <Tab
                // icon="unresolve"
                title="Associations"
                id="Associations"
                panel={<SchemaAssociationsListView schema={schema} />}
              />

              <Tab
                // icon="flows"
                title="Pipelines"
                id="Pipelines"
                panel={<SchemaPipelineDetailView schema={schema} />}
              />

              <Tab
                // icon="shield"
                title="Permissions"
                id="Permissions"
                panel={<SchemasPermissions schema={schema} />}
              />

              <Tab
                // icon="lightning"
                title="Actions"
                id="Actions"
                panel={<SchemaActionList schema={schema} />}
              />

              <Tab
                // icon="eye-open"
                title="View Configs"
                id="ViewConfigs"
                panel={<ViewConfigurationListView schema={schema} />}
              />

              <Tab
                // icon="settings"
                title="Settings"
                id="Settings"
                panel={schema ? <SchemaSettingsDetailView data={schema} /> : <></>}
              />
            </Tabs>
          </Col>
        </Row>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(SchemasDetailView));
