import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  ADD_COLUMN_TO_SHORTLIST_SCHEMA,
  ADD_SCHEMA_TYPE_TO_SHORTLIST_SCHEMA,
  BATCH_CREATE_SCHEMA_PERMISSIONS_ERROR,
  BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST,
  BATCH_CREATE_SCHEMA_PERMISSIONS_SUCCESS,
  BATCH_DELETE_SCHEMA_PERMISSIONS_ERROR,
  BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST,
  BATCH_DELETE_SCHEMA_PERMISSIONS_SUCCESS,
  CLEAN_SCHEMA_SHORTLIST,
  GET_SCHEMA_BY_ID_ERROR,
  GET_SCHEMA_BY_ID_REQUEST,
  GET_SCHEMA_BY_ID_SUCCESS,
  GET_SCHEMA_BY_MODULE_AND_ENTITY_ERROR,
  GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST,
  GET_SCHEMA_BY_MODULE_AND_ENTITY_SUCCESS,
  GET_SCHEMAS_BY_MODULE_ERROR,
  GET_SCHEMAS_BY_MODULE_REQUEST,
  GET_SCHEMAS_BY_MODULE_SUCCESS,
  LIST_SCHEMAS_ERROR,
  LIST_SCHEMAS_REQUEST,
  LIST_SCHEMAS_SUCCESS,
  REFRESH_SCHEMA_SHORTLIST_SUCCESS,
  REMOVE_COLUMN_FROM_SHORTLIST_SCHEMA,
  REMOVE_SCHEMA_TYPE_FROM_SHORTLIST_SCHEMA,
  UPDATE_COLUMN_IN_SHORTLIST_SCHEMA,
  UPDATE_SCHEMA_BY_ID_SUCCESS,
} from './constants';

export interface ISchemaReducer {
  isRequesting: boolean;
  isRequestingByEntity: { [key: string]: boolean };
  isSuccessful: boolean;
  isSuccessfulByEntity: { [key: string]: boolean };
  format: 'transform' | undefined; // query format=transform | raw
  list: SchemaEntity[];
  shortList: {
    [key: string]: SchemaEntity;
  };
}

export const initialState: ISchemaReducer = {
  isRequesting: false,
  isRequestingByEntity: {},
  isSuccessful: false,
  isSuccessfulByEntity: {},
  format: undefined, // query format=transform | raw
  list: [],
  shortList: {},
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    // Get all schemas
    case LIST_SCHEMAS_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        isSuccessful: false,
      };
    }
    case LIST_SCHEMAS_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: true,
        list: action.results.data,
      };
    }
    case LIST_SCHEMAS_ERROR: {
      return {
        isRequesting: false,
        isSuccessful: false,
        list: [],
      };
    }

    // Get a list of schemas by module and overwrite the shortList
    // We run this every time a module changes, and let other requests
    // simply append schemas to the default module shortlist.
    case GET_SCHEMAS_BY_MODULE_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        isSuccessful: false,
        searchQuery: action.searchQuery,
      };
    }
    case GET_SCHEMAS_BY_MODULE_SUCCESS: {
      let shortList: any = {};
      action.results?.data?.map((schema: SchemaEntity) => {
        shortList[schema?.id] = schema;
      });

      return {
        ...state,
        isRequesting: false,
        isSuccessful: true,
        shortList: { ...state.shortList, ...shortList },
      };
    }
    case GET_SCHEMAS_BY_MODULE_ERROR: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: false,
        selected: undefined,
      };
    }

    // Get a single schema by module and entity
    case GET_SCHEMA_BY_MODULE_AND_ENTITY_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        isRequestingByEntity: Object.assign({}, state.isRequestingByEntity, {
          [action?.params?.entityName]: true,
        }),
        isSuccessful: false,
        isSuccessfulByEntity: Object.assign({}, state.isSuccessfulByEntity, {
          [action?.params?.entityName]: false,
        }),
        searchQuery: action.searchQuery,
      };
    }
    case GET_SCHEMA_BY_MODULE_AND_ENTITY_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        isRequestingByEntity: Object.assign({}, state.isRequestingByEntity, {
          [action?.results?.entityName]: false,
        }),
        isSuccessful: true,
        isSuccessfulByEntity: Object.assign({}, state.isSuccessfulByEntity, {
          [action?.results?.entityName]: true,
        }),
        shortList: Object.assign(
          {},
          state.shortList,
          action.results?.id ? { [action.results.id]: action.results } : {},
        ),
      };
    }
    case GET_SCHEMA_BY_MODULE_AND_ENTITY_ERROR: {
      return {
        ...state,
        isRequesting: false,
        isRequestingByEntity: Object.assign({}, state.isRequestingByEntity, {
          [action?.params?.entityName]: false,
        }),
        isSuccessful: false,
        isSuccessfulByEntity: Object.assign({}, state.isSuccessfulByEntity, {
          [action?.params?.entityName]: false,
        }),
      };
    }

    // Get a single schema by id
    case GET_SCHEMA_BY_ID_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        isSuccessful: false,
        format: action.format,
      };
    }
    case GET_SCHEMA_BY_ID_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: true,
        shortList: Object.assign({}, state.shortList, {
          [action.results.id]: action.results,
        }),
      };
    }
    case GET_SCHEMA_BY_ID_ERROR: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: false,
      };
    }

    case BATCH_CREATE_SCHEMA_PERMISSIONS_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        isSuccessful: false,
      };
    }
    case BATCH_CREATE_SCHEMA_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: true,
      };
    }
    case BATCH_CREATE_SCHEMA_PERMISSIONS_ERROR: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: false,
      };
    }

    case BATCH_DELETE_SCHEMA_PERMISSIONS_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        isSuccessful: false,
      };
    }
    case BATCH_DELETE_SCHEMA_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: true,
      };
    }
    case BATCH_DELETE_SCHEMA_PERMISSIONS_ERROR: {
      return {
        ...state,
        isRequesting: false,
        isSuccessful: false,
      };
    }

    case CLEAN_SCHEMA_SHORTLIST: {
      return {
        ...state,
        shortList: {},
      };
    }

    case REFRESH_SCHEMA_SHORTLIST_SUCCESS: {
      const refreshedSchemas: any[] = action.results;
      let newShortList: any = Object.assign({}, state.shortList);

      // Go over state shortlist, and if there is a match in the refreshedSchemas and it's newer
      Object.keys(newShortList).forEach((key: any) => {
        const schema: any = state.shortList[key];
        const match: any = refreshedSchemas?.find((s: any) => s.id === key);

        const schemaDate = new Date(schema?.updatedAt);
        const matchDate = new Date(match?.updatedAt);

        if (schemaDate < matchDate) {
          newShortList[key] = match;
        }
      });

      return {
        ...state,
        shortList: newShortList,
      };
    }

    case ADD_COLUMN_TO_SHORTLIST_SCHEMA: {
      const schemaId = action.results?.schemaId;
      if (state.shortList[schemaId]) {
        let newShortList = Object.assign({}, state.shortList);
        let schema = newShortList[schemaId];
        schema.columns = schema.columns || [];
        schema.columns.push(action.results);
        return {
          ...state,
          shortList: newShortList,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case REMOVE_COLUMN_FROM_SHORTLIST_SCHEMA: {
      const schemaId = action.results?.schemaId;
      const schemaColumnId = action.results?.schemaColumnId;

      console.log('debug: REMOVE_COLUMN_FROM_SHORTLIST_SCHEMA', action.results);
      if (state.shortList[schemaId]) {
        let newShortList = Object.assign({}, state.shortList);
        let schema = newShortList[schemaId];
        schema.columns = schema.columns || [];
        schema.columns = schema.columns.filter((col: any) => col.id !== schemaColumnId);
        return {
          ...state,
          shortList: newShortList,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case UPDATE_COLUMN_IN_SHORTLIST_SCHEMA: {
      const schemaId = action.results?.schemaId;

      if (state.shortList[schemaId]) {
        let newShortList = Object.assign({}, state.shortList);
        let schema = newShortList[schemaId];
        schema.columns = schema.columns || [];
        schema.columns = schema.columns.map((col: any) => {
          if (col.id === action.results.id) {
            return action.results;
          }
          return col;
        });
        return {
          ...state,
          shortList: newShortList,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case ADD_SCHEMA_TYPE_TO_SHORTLIST_SCHEMA: {
      const schemaId = action.results?.schemaId;

      console.log('debug: ADD_SCHEMA_TYPE_TO_SHORTLIST_SCHEMA', action.results);

      if (state.shortList[schemaId]) {
        let newShortList = Object.assign({}, state.shortList);
        let schema = newShortList[schemaId];
        schema.types = schema.types || [];
        schema.types.push(action.results.type);
        return {
          ...state,
          shortList: newShortList,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case REMOVE_SCHEMA_TYPE_FROM_SHORTLIST_SCHEMA: {
      const schemaId = action.results?.schemaId;
      const schemaTypeId = action.results?.schemaTypeId;

      if (state.shortList[schemaId]) {
        let newShortList = Object.assign({}, state.shortList);
        let schema = newShortList[schemaId];
        schema.types = schema.types || [];
        schema.types = schema.types.filter((type: any) => type.id !== schemaTypeId);
        return {
          ...state,
          shortList: newShortList,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    case UPDATE_SCHEMA_BY_ID_SUCCESS: {
      const schemaId = action.results?.id;
      if (state.shortList[schemaId]) {
        let newShortList = Object.assign({}, state.shortList);
        newShortList[schemaId] = action.results;
        return {
          ...state,
          shortList: newShortList,
        };
      } else {
        return {
          ...state,
        };
      }
    }

    default:
      return state;
  }
}

export default reducer;
