import React, { Suspense } from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import history from '@core/helpers/browserHistory';
import {
  canUserAccessDesktopApp,
  isExternalUser as checkExternalUser,
  isUserTokenExpired,
} from '@core/helpers/rbacRules';
import Inbox from '@core/views/Inbox';
import ProtectedRoute from '@legacy/core/navigation/ProtectedRoute';
import AppLoadingPage from '@core/pages/AppLoading';
import OrderBuilder from '@core/modules/OrderModule/OrderBuilder';
import OrderModuleDashboard from '@legacy/modules/OrderModule/containers/Dashboard';
import DetailViewContext from '@legacy/core/records/components/DetailViewContext';
import RecordDetailView from '@legacy/core/records/components/DetailViewContext';
import DetailView from '@legacy/core/records/components/DetailViewContext';
import OrderDetailView from '@core/modules/OrderModule/views/OrderDetailView';
import BillingAdjustmentDetailView from '@core/modules/OrderModule/views/BillingAdjustmentDetailView';
import ChurnRequestDetailView from '@core/modules/OrderModule/views/ChurnRequestDetailView';
import PICRequestDetailView from '@core/modules/OrderModule/views/PICRequestDetailView';
import DefaultDetailView from '@core/views/DetailView';
import OrderItemReplaceProductView from '@legacy/modules/OrderModule/containers/OrderItem/OrderItemReplaceProductView';
import RecordListView from '@legacy/core/records/components/ListView';
import InvoiceBuilder from '@legacy/modules/OrderModule/containers/Order/InvoiceBuilder';
import CrmModuleDashboard from '@legacy/modules/CrmModule/containers/Dashboard';
import { SalesLeaderBoard } from '@legacy/modules/CrmModule/containers/SalesLeaderBoard';
import { TelesalesDashboard } from '@legacy/modules/CrmModule/containers/TelesalesDashboard';
import AppointmentsCalendar from '@legacy/modules/CrmModule/containers/Appointments/AppointmentsCalendar';
import VisitDetailView from '@core/modules/CrmModule/views/VisitDetailView';
import LeadDetailView from '@core/modules/CrmModule/views/LeadDetailView';
import AccountDetailView from '@core/modules/CrmModule/views/AccountDetailView';
import ContactDetailView from '@core/modules/CrmModule/views/ContactDetailView';
import DataSetAssign from '@legacy/modules/CrmModule/containers/DataSet/DataSetAssign';
import CrmDataSetDetailView from '@core/modules/CrmModule/views/CrmDataSetDetailView';
import ContactIdentityDetailView from '@core/modules/CrmModule/views/ContactIdentityDetailView';
import AddressDetailView from '@core/modules/CrmModule/views/AddressDetailView';
import DataSetBuild from '@legacy/modules/CrmModule/containers/DataSet/DataSetBuild';
import DataSetWork from '@legacy/modules/CrmModule/containers/DataSet/DataSetWork';
import FieldServiceModuleDashboard from '@legacy/modules/FieldServiceModule/containers/Dashboard';
import WorkOrderDetailView from '@core/modules/FieldServiceModule/views/WorkOrderDetailView';
import { ServiceAppointmentCalendarV2 } from '@legacy/modules/FieldServiceModule/containers/ServiceAppointmentCalendarV2';
import ServiceAppointmentConfigDetailView from '@core/modules/FieldServiceModule/views/ServiceAppointmentConfigDetailView';
import PermissionToFailDetailView from '@core/modules/FieldServiceModule/views/PermissionToFailDetailView';
import WorkOrderRemediationView from '@core/modules/FieldServiceModule/views/WorkOrderRemediationView';
import { DownloadRecordPdf } from '@core/components/DownloadRecordPdf';
import BillingModuleDashboard from '@legacy/modules/BillingModule/containers/Dashboard';
import InvoiceDetailView from '@core/modules/BillingModule/views/InvoiceDetailView';
import TransactionDetailView from '@core/modules/BillingModule/views/TransactionDetailView';
import PaymentMethodRefundDetailView from '@core/modules/BillingModule/views/PaymentMethodRefundDetailView';
import InvoiceItemDetailView from '@core/modules/BillingModule/views/InvoiceItemDetailView';
import BillingRequestDetailView from '@core/modules/BillingModule/views/BillingRequestDetailView';
import PaymentMethodDetailView from '@core/modules/BillingModule/views/PaymentMethodDetailView';
import CreditNoteDetailView from '@core/modules/BillingModule/views/CreditNoteDetailView';
import WorkflowDetailView from '@legacy/modules/SchemaManagerModule/containers/Workflow/DetailView';
import RoleBasedProtectedRoute from '@legacy/core/navigation/RoleBasedProtectedRoute';
import SchemaListView from '@legacy/modules/SchemaManagerModule/containers/Schemas/ListView';
import SchemasDetailView from '@legacy/modules/SchemaManagerModule/containers/Schemas/DetailView';
import SchemaActionDetailView from '@legacy/modules/SchemaManagerModule/containers/SchemaActions/SchemaActionDetailView';
import ViewConfigurationDetailView from '@core/modules/SchemaManagerModule/views/ViewConfigurationDetailView';
import FileListFeedView from '@legacy/core/records/components/ListView/FileListFeedView';
import FileDetailView from '@core/modules/FileModule/views/FileDetailView';
import JSONGraphBrowser from '@netomnia/modules/ProjectModule/components/JSONGraphBrowser';
import Autosplicing from '@netomnia/modules/ProjectModule/Autosplicing';
import VendorDashboard from '@netomnia/modules/ProjectModule/VendorDashboard';
import ProjectModuleDashboard from '@netomnia/modules/ProjectModule/views/Dashboard';
import RegionDetailView from '@netomnia/modules/ProjectModule/views/RegionDetailView';
import FeaturePriceEntryDetailView from '@netomnia/modules/ProjectModule/views/FeaturePriceEntryDetailView';
import FeatureDetailView from '@netomnia/modules/ProjectModule/views/FeatureDetailView';
import ExchangeDetailView from '@netomnia/modules/ProjectModule/views/ExchangeDetailView';
import ProgramDetailView from '@netomnia/modules/ProjectModule/views/ProgramDetailView';
import ProjectDetailView from '@netomnia/modules/ProjectModule/views/ProjectDetailView';
import ClosureConfigurator from '@netomnia/modules/ProjectModule/components/Feature/ClosureConfigurator';
import Noi from '@netomnia/modules/ProjectModule/components/Openreach/NoticeOfIntent';
import Inventory from '@netomnia/modules/ProjectModule/components/Openreach/Inventory';
import OpenreachNetworkAdjustmentDetail from '@netomnia/modules/ProjectModule/components/OpenreachNetworkAdjustmentDetail';
import ProjectModuleRecordDetailViewWide from '@netomnia/modules/ProjectModule/views/ProjectModuleRecordDetailViewWide';
import BulkBuildComplete from '@netomnia/modules/ProjectModule/components/Openreach/BulkBuildComplete';
import ProjectModuleCableConnectionDetailViewWide from '@netomnia/modules/ProjectModule/views/ProjectModuleCableConnectionDetailViewWide';
import PlanningModuleMap from '@netomnia/modules/ProjectModule/Map';
import BuildPackV2 from '@netomnia/modules/ProjectModule/BuildPack';
import ApplicationForPaymentDetailView from '@netomnia/modules/ProjectModule/views/ApplicationForPaymentDetailView';
import ProjectModuleApplicationForPaymentApprovals from '@netomnia/modules/ProjectModule/components/ProjectModuleApplicationForPaymentApprovals';
import WorkListDetailView from '@netomnia/modules/ProjectModule/views/WorkListDetailView';
import PaymentNoticeDetailView from '@netomnia/modules/ProjectModule/views/PaymentNoticeDetailView';
import { RFCManagementView } from '@netomnia/modules/ProjectModule/views/RFCManagementView';
import ForgotPassword from '@legacy/modules/IdentityManagerModule/containers/UserLogin/containers/ForgotPassword';
import ResetPassword from '@legacy/modules/IdentityManagerModule/containers/UserLogin/containers/ResetPassword';
import Register from '@legacy/modules/IdentityManagerModule/containers/UserLogin/containers/Register';
import Login from '@legacy/modules/IdentityManagerModule/containers/UserLogin';
import MFASettings from '@legacy/modules/IdentityManagerModule/containers/MFASettings';
import Error500 from '@core/pages/500';
import Error403 from '@core/pages/403';
import Error404 from '@core/pages/404';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { connect } from 'react-redux';
import AppUnavailable from '@core/pages/AppUnavailable';
import LinearIssues from '@core/modules/SupportModule/views/LinearIssues';
import SupportDashboard from '@core/modules/SupportModule/views/SupportDashboard';
import MyCasesView from '@core/modules/SupportModule/views/MyCasesView';
import HtmlRecordDetailViewWithSlate from '@legacy/components/DefaultViews/KnowledgeArticleRecordDetailView';
import HtmlContentViewWithSlate from '@legacy/components/DefaultViews/KnowledgeArticleView';
import AgentCaseDashboard from '@core/modules/SupportModule/views/AgentCaseDashboard';
import CaseDetailView from '@core/modules/SupportModule/views/CaseDetailView';
import Home from '@core/views/Home/Home';
import PriceBookDetailView from '@legacy/modules/ProductModule/PriceBook';
import OfferDetailView from '@legacy/modules/ProductModule/Offer';
import ProductDetailView from '@legacy/modules/ProductModule/Product';
import RelatedRecordDetailView from '@legacy/components/DefaultViews/RelatedRecordDetailView';
import UserDetailView from '@core/modules/IdentityModule/views/UserDetailView';
import IdentityManagerView from '@legacy/modules/IdentityManagerModule/containers/IdentityManagerView';
import ControlPanelView from '@core/modules/ControlPanelModule/containers/ControlPanelView';
import GanttProgramDetailView from '@core/modules/ProjectModule/views/GanttProgramDetailView';
import OutageList from '@legacy/modules/ServiceModule/Outage/OutageList';
import SalesDashboard from '@legacy/components/Dashboard/SalesDashboard';
import MergeRecords from '@legacy/components/Merge';

const {
  ORDER,
  ORDER_ITEM,
  PRODUCT,
  PROGRAM,
  PROJECT,
  REGION,
  WORK_ORDER,
  ACCOUNT,
  ADDRESS,
  CONTACT,
  LEAD,
  CONTACT_IDENTITY,
  INVOICE,
  INVOICE_ITEM,
  TRANSACTION,
  PAYMENT_METHOD,
  CREDIT_NOTE,
  BILLING_REQUEST,
  BILLING_ADJUSTMENT,
  SERVICE_APPOINTMENT_CONFIG,
} = SchemaModuleEntityTypeEnums;
const {
  SCHEMA_MODULE,
  PRODUCT_MODULE,
  PROJECT_MODULE,
  FIELD_SERVICE_MODULE,
  CRM_MODULE,
  IDENTITY_MODULE,
  ORDER_MODULE,
  BILLING_MODULE,
  NOTIFICATION_MODULE,
  SUPPORT_MODULE,
  SERVICE_MODULE,
} = SchemaModuleTypeEnums;

const EXCHANGE = 'Exchange';
const PAYMENT_METHOD_REFUND = 'PaymentMethodRefund';
const IDENTITY_MANAGER_MODULE = 'IdentityManagerModule';
const SCHEMA_MANAGER_MODULE = 'SchemaModule';

interface Props {
  userReducer: any;
}

const DefaultRouter: React.FC<Props> = (props: Props) => {
  const { userReducer } = props;

  const isExternalUser = checkExternalUser(userReducer);

  return (
    <Router history={history}>
      {canUserAccessDesktopApp(userReducer) ? (
        // 1. USERS WITH DESKTOP ACCESS
        <Switch>
          {/* HOME */}
          <Route exact path="/">
            <Switch>
              {!isExternalUser && (
                <ProtectedRoute
                  path="/"
                  moduleName={'OVERRIDE'}
                  entityName="Dashboard"
                  exact
                  component={<Home />}
                />
              )}
            </Switch>
          </Route>

          {/* INBOX */}
          <Route exact path="/Inbox/:selectedMention?">
            <Inbox />
          </Route>

          {/* NOTIFICATION MODULE */}
          <Route path="/NotificationModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${NOTIFICATION_MODULE}/:entityName`}
                moduleName={NOTIFICATION_MODULE}
                component={<RecordListView moduleName={NOTIFICATION_MODULE} />}
              />
              <ProtectedRoute
                exact
                path={`/${NOTIFICATION_MODULE}/:entityName/:recordId`}
                moduleName={NOTIFICATION_MODULE}
                component={
                  <RecordDetailView moduleName={NOTIFICATION_MODULE}>
                    <DefaultDetailView />
                  </RecordDetailView>
                }
              />
            </Switch>
          </Route>

          {/* SUPPORT MODULE */}
          <Route path="/SupportModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/LinearIssue`}
                moduleName={SUPPORT_MODULE}
                entityName="LinearIssue"
                component={<LinearIssues />}
              />

              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/Dashboard`}
                moduleName={SUPPORT_MODULE}
                entityName="Dashboard"
                component={<SupportDashboard />}
              />
              {/* CASES V1 */}
              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/MyCases`}
                moduleName={SUPPORT_MODULE}
                entityName="MyCases"
                component={<MyCasesView />}
              />

              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/KnowledgeArticle/:recordId`}
                moduleName={SUPPORT_MODULE}
                entityName="KnowledgeArticle"
                component={
                  <DetailView moduleName={SUPPORT_MODULE} entityName="KnowledgeArticle">
                    <HtmlRecordDetailViewWithSlate />
                  </DetailView>
                }
              />
              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/KnowledgeArticle/:recordId/view`}
                moduleName={SUPPORT_MODULE}
                entityName="KnowledgeArticle"
                component={
                  <DetailView moduleName={SUPPORT_MODULE} entityName="KnowledgeArticle">
                    <HtmlContentViewWithSlate />
                  </DetailView>
                }
              />
              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/CaseQueueListView`}
                moduleName={SUPPORT_MODULE}
                entityName="CaseQueueListView"
                component={<AgentCaseDashboard />}
              />
              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/Case/:recordId`}
                moduleName={SUPPORT_MODULE}
                entityName="Case"
                component={
                  <DetailView moduleName={SUPPORT_MODULE} entityName="Case">
                    <CaseDetailView />
                  </DetailView>
                }
              />
              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/:entityName`}
                moduleName={SUPPORT_MODULE}
                component={<RecordListView moduleName={SUPPORT_MODULE} />}
              />
              <ProtectedRoute
                exact
                path={`/${SUPPORT_MODULE}/:entityName/:recordId`}
                moduleName={SUPPORT_MODULE}
                component={
                  <DetailView moduleName={SUPPORT_MODULE}>
                    <DefaultDetailView />
                  </DetailView>
                }
              />
            </Switch>
          </Route>

          {/* PRODUCT MODULE */}
          <Route path="/ProductModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${PRODUCT_MODULE}/PriceBook/:recordId`}
                moduleName={PRODUCT_MODULE}
                entityName="PriceBook"
                component={
                  <DetailView moduleName={PRODUCT_MODULE} entityName="PriceBook">
                    <PriceBookDetailView />
                  </DetailView>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PRODUCT_MODULE}/Offer/:recordId`}
                moduleName={PRODUCT_MODULE}
                entityName="Offer"
                component={
                  <DetailView moduleName={PRODUCT_MODULE} entityName="Offer">
                    <OfferDetailView />
                  </DetailView>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PRODUCT_MODULE}/Product/:recordId`}
                moduleName={PRODUCT_MODULE}
                entityName="Product"
                component={
                  <DetailView moduleName={PRODUCT_MODULE} entityName="Product">
                    <ProductDetailView
                      hasColumnMappings={false}
                      excludeRelations={[
                        'Lead',
                        'OrderItem',
                        'WorkOrder',
                        'Task',
                        'ConsumptionSchedule',
                        'Feature',
                      ]}
                    />
                  </DetailView>
                }
              />
              <ProtectedRoute
                exact
                path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/${PRODUCT_MODULE}/Product/:recordId`}
                moduleName={PRODUCT_MODULE}
                entityName="Product"
                component={
                  <DetailView moduleName={PRODUCT_MODULE} entityName="Product">
                    <ProductDetailView
                      hasColumnMappings={true}
                      excludeRelations={[
                        'Lead',
                        'OrderItem',
                        'WorkOrder',
                        'Task',
                        'ConsumptionSchedule',
                        'Feature',
                      ]}
                      visibleProperties={[
                        'ContractType',
                        'UnitPrice',
                        'DiscountValue',
                        'DiscountLength',
                        'DiscountType',
                        'DiscountUnit',
                        'TrialLength',
                        'TrialUnit',
                        'IntervalUnit',
                        'IntervalLength',
                        'PromoTextLabelDown',
                        'PromoTextLabelUp',
                        'DisplayName',
                        'IsTrialCancelable',
                        'LegalTerms',
                        'Description',
                        'Discount2Value',
                        'Discount2Length',
                        'Discount2Type',
                        'Discount2Unit',
                        'TermsAndConditions',
                      ]}
                      relatedProduct
                    />
                  </DetailView>
                }
              />
              <ProtectedRoute
                exact
                path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/${PRODUCT_MODULE}/ProductComponent/:recordId`}
                moduleName={PRODUCT_MODULE}
                entityName="Product"
                component={
                  <DetailView moduleName={PRODUCT_MODULE} entityName="Product">
                    <RelatedRecordDetailView
                      excludeRelations={[
                        'Lead',
                        'OrderItem',
                        'WorkOrder',
                        'Task',
                        'ConsumptionSchedule',
                      ]}
                      visibleProperties={['Quantity']}
                    />
                  </DetailView>
                }
              />
              ,
              <ProtectedRoute
                exact
                path={`/${PRODUCT_MODULE}/:entityName`}
                moduleName={PRODUCT_MODULE}
                component={<RecordListView moduleName={PRODUCT_MODULE} />}
              />
              ,
              <ProtectedRoute
                exact
                path={`/${PRODUCT_MODULE}/:entityName/:recordId`}
                moduleName={PRODUCT_MODULE}
                component={
                  <DetailView moduleName={PRODUCT_MODULE}>
                    <DefaultDetailView />
                  </DetailView>
                }
              />
            </Switch>
          </Route>

          {/* ORDER MODULE */}
          <Route path="/OrderModule">
            <Switch>
              {/* Order Builder */}
              <ProtectedRoute
                exact
                path={`/${ORDER_MODULE}/OrderBuilder/`}
                moduleName={ORDER_MODULE}
                entityName={'Order'}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <div style={{ padding: 15 }}>
                      <OrderBuilder />
                    </div>
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={'/OrderModule/Dashboard'}
                moduleName={ORDER_MODULE}
                entityName="Dashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <OrderModuleDashboard />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/OrderModule/Order/:recordId`}
                moduleName={ORDER_MODULE}
                entityName="Order"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER}>
                      <OrderDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/OrderModule/BillingAdjustment/:recordId`}
                moduleName={ORDER_MODULE}
                entityName={BILLING_ADJUSTMENT}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={ORDER_MODULE} entityName={BILLING_ADJUSTMENT}>
                      <BillingAdjustmentDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/OrderModule/ChurnRequest/:recordId`}
                moduleName={ORDER_MODULE}
                entityName={'ChurnRequest'}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={ORDER_MODULE} entityName={'ChurnRequest'}>
                      <ChurnRequestDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/OrderModule/PICRequest/:recordId`}
                moduleName={ORDER_MODULE}
                entityName={'PICRequest'}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={ORDER_MODULE} entityName={'PICRequest'}>
                      <PICRequestDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/OrderModule/OrderItem/:recordId`}
                moduleName={ORDER_MODULE}
                entityName="OrderItem"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER_ITEM}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/OrderModule/OrderItem/:recordId/w`}
                moduleName={ORDER_MODULE}
                entityName="OrderItem"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={ORDER_MODULE} entityName={ORDER_ITEM}>
                      <OrderItemReplaceProductView />
                    </DetailViewContext>
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/OrderModule/:entityName`}
                moduleName={ORDER_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <RecordListView moduleName={ORDER_MODULE} />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/OrderModule/:entityName/:recordId`}
                moduleName={ORDER_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={ORDER_MODULE}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/${PRODUCT_MODULE}/${PRODUCT}/:recordId`}
                moduleName={PRODUCT_MODULE}
                entityName={PRODUCT}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PRODUCT_MODULE} entityName={PRODUCT}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />

              {/* Invoice Builder */}
              <ProtectedRoute
                exact
                path={`/OrderModule/Order/:recordId/invoice`}
                moduleName={ORDER_MODULE}
                entityName={ORDER}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <InvoiceBuilder moduleName={ORDER_MODULE} entityName={ORDER} />
                  </Suspense>
                }
              />
            </Switch>
          </Route>

          {/* AUDIT MODULE */}
          <Route path="/AuditModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/AuditModule/:entityName`}
                moduleName={'AuditModule'}
                entityName="ActivityLog"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <RecordListView moduleName={'AuditModule'} />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/AuditModule/:entityName/:recordId`}
                moduleName={'AuditModule'}
                entityName="ActivityLog"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={'AuditModule'} entityName={'ActivityLog'}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />
            </Switch>
          </Route>

          {/* CRM MODULE */}
          <Route path="/CrmModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/CrmModule/Dashboard`}
                moduleName={CRM_MODULE}
                entityName="Dashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <CrmModuleDashboard />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path="/CrmModule/SalesLeaderBoard"
                moduleName={CRM_MODULE}
                entityName="SalesLeaderBoard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <SalesLeaderBoard />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path="/CrmModule/TelesalesDashboard"
                moduleName={CRM_MODULE}
                entityName="TelesalesDashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <TelesalesDashboard />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${CRM_MODULE}/AppointmentCalendar`}
                moduleName={CRM_MODULE}
                entityName="TelesalesDashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <AppointmentsCalendar />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/CrmModule/Visit/:recordId`}
                moduleName={CRM_MODULE}
                entityName="Visit"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName="Visit">
                      <VisitDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/CrmModule/Lead/:recordId`}
                moduleName={CRM_MODULE}
                entityName="Lead"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName={LEAD}>
                      <LeadDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/CrmModule/Account/:recordId`}
                moduleName={CRM_MODULE}
                entityName="Account"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName={ACCOUNT}>
                      <AccountDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/CrmModule/Contact/:recordId`}
                moduleName={CRM_MODULE}
                entityName="Contact"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName={CONTACT}>
                      <ContactDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${CRM_MODULE}/CrmDataset/Assign/`}
                moduleName={CRM_MODULE}
                accessPermissions={['crmmodule.crmdataset.assign.read']}
                component={<DataSetAssign />}
              />

              <ProtectedRoute
                exact
                path={`/CrmModule/CrmDataSet/:recordId`}
                moduleName={CRM_MODULE}
                entityName="CrmDataset"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName={'CrmDataset'}>
                      <CrmDataSetDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/CrmModule/ContactIdentity/:recordId`}
                moduleName={CRM_MODULE}
                entityName="ContactIdentity"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName={CONTACT_IDENTITY}>
                      <ContactIdentityDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/CrmModule/Address/:recordId`}
                moduleName={CRM_MODULE}
                entityName="Address"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName={ADDRESS}>
                      <AddressDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/:relatedRecordModuleName/:relatedRecordEntityName/:relatedRecordId/related/:dbRecordAssociationId/CrmModule/Contact/:recordId/`}
                moduleName={CRM_MODULE}
                entityName="Contact"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE} entityName="Contact">
                      <ContactDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${CRM_MODULE}/:entityName`}
                moduleName={CRM_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <RecordListView moduleName={CRM_MODULE} />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${CRM_MODULE}/CrmDataset/:recordId/Build`}
                moduleName={CRM_MODULE}
                entityName="CrmDataset"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DataSetBuild />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${CRM_MODULE}/CrmDataset/:recordId/Work`}
                moduleName={CRM_MODULE}
                entityName="CrmDataset"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DataSetWork />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${CRM_MODULE}/:entityName/:recordId`}
                moduleName={CRM_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={CRM_MODULE}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />
            </Switch>
          </Route>

          {/* FIELD SERVICE MODULE */}
          <Route path="/FieldServiceModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/Dashboard`}
                moduleName={FIELD_SERVICE_MODULE}
                entityName="Dashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <FieldServiceModuleDashboard />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/WorkOrder/:recordId`}
                moduleName={FIELD_SERVICE_MODULE}
                entityName="WorkOrder"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={FIELD_SERVICE_MODULE} entityName={WORK_ORDER}>
                      <WorkOrderDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/Calendar`}
                moduleName={FIELD_SERVICE_MODULE}
                entityName="WorkOrder"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <ServiceAppointmentCalendarV2 />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/ServiceAppointmentConfig/:recordId`}
                moduleName={FIELD_SERVICE_MODULE}
                entityName="ServiceAppointmentConfig"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName={SERVICE_APPOINTMENT_CONFIG}
                      skipLoadingUserActivity
                    >
                      <ServiceAppointmentConfigDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/PermissionToFail/:recordId`}
                moduleName={FIELD_SERVICE_MODULE}
                entityName="PermissionToFail"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext
                      moduleName={FIELD_SERVICE_MODULE}
                      entityName={'PermissionToFail'}
                    >
                      <PermissionToFailDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/WorkOrderRemediationView`}
                moduleName={FIELD_SERVICE_MODULE}
                entityName="WorkOrderRemediationView"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <WorkOrderRemediationView />
                  </Suspense>
                }
              />

              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/:entityName`}
                moduleName={FIELD_SERVICE_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <RecordListView moduleName={FIELD_SERVICE_MODULE} />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/:entityName/:recordId`}
                moduleName={FIELD_SERVICE_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={FIELD_SERVICE_MODULE}>
                      <DefaultDetailView showCollaborators headerExtras={[<DownloadRecordPdf />]} />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              {/* Invoice Builder */}
              <ProtectedRoute
                exact
                path={`/${FIELD_SERVICE_MODULE}/${WORK_ORDER}/:recordId/invoice`}
                moduleName={FIELD_SERVICE_MODULE}
                entityName={WORK_ORDER}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <InvoiceBuilder moduleName={FIELD_SERVICE_MODULE} entityName={WORK_ORDER} />
                  </Suspense>
                }
              />
            </Switch>
          </Route>

          {/* BILLING MODULE */}
          <Route path="/BillingModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/Dashboard`}
                moduleName={BILLING_MODULE}
                entityName="Dashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <BillingModuleDashboard />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/${INVOICE}/:recordId`}
                moduleName={BILLING_MODULE}
                entityName="Invoice"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={BILLING_MODULE} entityName={INVOICE}>
                      <InvoiceDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/${TRANSACTION}/:recordId`}
                moduleName={BILLING_MODULE}
                entityName={TRANSACTION}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={BILLING_MODULE} entityName={TRANSACTION}>
                      <TransactionDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/${PAYMENT_METHOD_REFUND}/:recordId`}
                moduleName={BILLING_MODULE}
                entityName={PAYMENT_METHOD_REFUND}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext
                      moduleName={BILLING_MODULE}
                      entityName={PAYMENT_METHOD_REFUND}
                    >
                      <PaymentMethodRefundDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/${INVOICE_ITEM}/:recordId`}
                moduleName={BILLING_MODULE}
                entityName={INVOICE_ITEM}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={BILLING_MODULE} entityName={INVOICE_ITEM}>
                      <InvoiceItemDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/${BILLING_REQUEST}/:recordId`}
                moduleName={BILLING_MODULE}
                entityName={BILLING_REQUEST}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={BILLING_MODULE} entityName={BILLING_REQUEST}>
                      <BillingRequestDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/PaymentMethod/:recordId`}
                moduleName={BILLING_MODULE}
                entityName="PaymentMethod"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={BILLING_MODULE} entityName={PAYMENT_METHOD}>
                      <PaymentMethodDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/${CREDIT_NOTE}/:recordId`}
                moduleName={BILLING_MODULE}
                entityName={CREDIT_NOTE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={BILLING_MODULE} entityName={CREDIT_NOTE}>
                      <CreditNoteDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/:entityName`}
                moduleName={BILLING_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <RecordListView moduleName={BILLING_MODULE} />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${BILLING_MODULE}/:entityName/:recordId`}
                moduleName={BILLING_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={BILLING_MODULE}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />
            </Switch>
          </Route>

          {/* IDENTITY MODULE */}
          <Route path="/IdentityModule">
            <Switch>
              <ProtectedRoute
                path={`/${IDENTITY_MODULE}/User/:recordId`}
                moduleName={IDENTITY_MODULE}
                entityName="User"
                component={
                  <DetailViewContext moduleName={IDENTITY_MODULE} entityName={'User'}>
                    <UserDetailView />
                  </DetailViewContext>
                }
              />
              <ProtectedRoute
                exact
                path={`/${IDENTITY_MODULE}/:entityName`}
                moduleName={IDENTITY_MODULE}
                component={<RecordListView moduleName={IDENTITY_MODULE} />}
              />
              <ProtectedRoute
                exact
                path={`/${IDENTITY_MODULE}/:entityName/:recordId`}
                moduleName={IDENTITY_MODULE}
                component={
                  <RecordDetailView moduleName={IDENTITY_MODULE}>
                    <DefaultDetailView />
                  </RecordDetailView>
                }
              />
            </Switch>
          </Route>

          {/* IDENTITY MANAGER MODULE */}
          <Route path="/IdentityManagerModule">
            <Switch>
              <RoleBasedProtectedRoute
                exact
                path={`/${IDENTITY_MANAGER_MODULE}`}
                moduleName={IDENTITY_MANAGER_MODULE}
                component={<IdentityManagerView />}
              />
            </Switch>
          </Route>

          {/* CONTROL PANEL */}
          <Route path="/ControlPanelModule">
            <Switch>
              <RoleBasedProtectedRoute
                exact
                path={`/ControlPanelModule`}
                moduleName={IDENTITY_MANAGER_MODULE}
                component={<ControlPanelView />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/ControlPanelModule/IdentityManager`}
                moduleName={IDENTITY_MANAGER_MODULE}
                // component={<IdentityManagerView />}
                component={<ControlPanelView moduleName="IdentityManager" />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/ControlPanelModule/SchemaManager`}
                moduleName={SCHEMA_MANAGER_MODULE}
                // component={<SchemaManagerView />}
                component={<ControlPanelView moduleName="SchemaManager" />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/ControlPanelModule/SchemaManager/:id`}
                moduleName={SCHEMA_MANAGER_MODULE}
                // component={<SchemaDetailsView />}
                component={<ControlPanelView moduleName="SchemaManagerDetailView" />}
              />
            </Switch>
          </Route>

          {/* SCHEMA MODULE */}
          <Route path="/SchemaModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/Workflow/:recordId`}
                moduleName={SCHEMA_MODULE}
                entityName="Workflow"
                component={
                  <DetailViewContext
                    moduleName={SCHEMA_MODULE}
                    entityName="Workflow"
                    skipLoadingUserActivity
                  >
                    <WorkflowDetailView />
                  </DetailViewContext>
                }
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/Schema`}
                moduleName={SCHEMA_MODULE}
                component={<SchemaListView />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/Schema/:schemaId`}
                moduleName={SCHEMA_MODULE}
                component={<SchemasDetailView />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/SchemaAction/:schemaId/:schemaActionId`}
                moduleName={SCHEMA_MODULE}
                component={<SchemaActionDetailView />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/ViewConfiguration/:schemaId/:viewConfigurationId`}
                moduleName={SCHEMA_MODULE}
                component={<ViewConfigurationDetailView />}
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/File`}
                moduleName={SCHEMA_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <FileListFeedView moduleName={SCHEMA_MODULE} entityName={'File'} />
                  </Suspense>
                }
              />
              <RoleBasedProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/File/:recordId`}
                moduleName={SCHEMA_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={SCHEMA_MODULE} entityName="File">
                      <FileDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/:entityName`}
                moduleName={SCHEMA_MODULE}
                component={<RecordListView moduleName={SCHEMA_MODULE} />}
              />
              <ProtectedRoute
                exact
                path={`/${SCHEMA_MODULE}/:entityName/:recordId`}
                moduleName={SCHEMA_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={SCHEMA_MODULE}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />
            </Switch>
          </Route>

          {/* SERVICE MODULE */}
          <Route path="/ServiceModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${SERVICE_MODULE}`}
                moduleName={SERVICE_MODULE}
                entityName="Service"
                component={<RecordListView moduleName={SERVICE_MODULE} entityName="Service" />}
              />
              <ProtectedRoute
                exact
                path={`/${SERVICE_MODULE}/Outage`}
                moduleName={SERVICE_MODULE}
                entityName="Outage"
                component={<OutageList />}
              />
              <ProtectedRoute
                exact
                path={`/${SERVICE_MODULE}/:entityName`}
                moduleName={SERVICE_MODULE}
                component={<RecordListView moduleName={SERVICE_MODULE} />}
              />
              <ProtectedRoute
                exact
                path={`/${SERVICE_MODULE}/:entityName/:recordId`}
                moduleName={SERVICE_MODULE}
                component={
                  <DetailView moduleName={SERVICE_MODULE}>
                    <DefaultDetailView showCollaborators />
                  </DetailView>
                }
              />
              ]
            </Switch>
          </Route>

          {/* PROJECT MODULE */}
          <Route path="/ProjectModule">
            <Switch>
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Connection/RawData`}
                moduleName={PROJECT_MODULE}
                entityName="Connection"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <JSONGraphBrowser />
                  </Suspense>
                }
              />
              {/* NETOMNIA ProjectModule Routes */}
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Connection/:closureId?/:cableId?`}
                moduleName={PROJECT_MODULE}
                entityName="Connection"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <Autosplicing />
                  </Suspense>
                }
              />
              ,
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Autosplicing/:closureId?/:cableId?`}
                moduleName={PROJECT_MODULE}
                entityName="Map"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <Autosplicing />
                  </Suspense>
                }
              />
              ,
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/VendorDashboard`}
                moduleName={PROJECT_MODULE}
                entityName="VendorDashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <VendorDashboard />
                  </Suspense>
                }
              />
              {/* END NETOMNIA ProjectModule Routes */}
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Dashboard`}
                moduleName={PROJECT_MODULE}
                entityName="Dashboard"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <ProjectModuleDashboard />
                  </Suspense>
                }
              />
              ,
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Connection`}
                moduleName={PROJECT_MODULE}
                entityName="Connection"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <Autosplicing />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/${REGION}/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Region"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={REGION}>
                      <RegionDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/FeaturePriceEntry/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="FeaturePriceEntry"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'FeaturePriceEntry'}>
                      <FeaturePriceEntryDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Feature/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Feature"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
                      <FeatureDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/${EXCHANGE}/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Exchange"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={EXCHANGE}>
                      <ExchangeDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/${PROGRAM}/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Program"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={PROGRAM}>
                      <ProgramDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/${PROJECT}/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Project"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={PROJECT}>
                      <ProjectDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Feature/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Feature"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
                      <FeatureDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Feature/:recordId/configure-closure`}
                moduleName={PROJECT_MODULE}
                entityName="Feature"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'Feature'}>
                      <ClosureConfigurator />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/ChangeRequest/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="ChangeRequest"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName="ChangeRequest">
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/OpenreachNoi`}
                moduleName={PROJECT_MODULE}
                entityName="OpenreachNoi"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <Noi />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/OpenreachInventory`}
                moduleName={PROJECT_MODULE}
                entityName="OpenreachInventory"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <Inventory />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/OpenreachNetworkAdjustment/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="OpenreachNetworkAdjustment"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext
                      moduleName={PROJECT_MODULE}
                      entityName={'OpenreachNetworkAdjustment'}
                    >
                      <OpenreachNetworkAdjustmentDetail defaultTabKey={'OrderDetails'} />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/BuildComplete/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="BuildComplete"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'BuildComplete'}>
                      <ProjectModuleRecordDetailViewWide moduleName={PROJECT_MODULE} />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/OpenreachBuildComplete`}
                moduleName={PROJECT_MODULE}
                entityName="OpenreachBuildComplete"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <BulkBuildComplete />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/CableConnection/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="CableConnection"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'CableConnection'}>
                      <ProjectModuleCableConnectionDetailViewWide />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/Map/:featureType?/:featureId?`}
                moduleName={PROJECT_MODULE}
                entityName="Map"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <PlanningModuleMap />
                  </Suspense>
                }
              />
              {/* Build Pack -------------------------------------------------------- */}
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/BuildPack/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Map"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <BuildPackV2 />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/BuildPack/Splicing/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="Map"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <BuildPackV2 withSplicing />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/ApplicationForPayment/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="ApplicationForPayment"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext
                      moduleName={PROJECT_MODULE}
                      entityName={'ApplicationForPayment'}
                    >
                      <ApplicationForPaymentDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/ApplicationForPayment/:recordId/approval`}
                moduleName={PROJECT_MODULE}
                entityName="ApplicationForPayment"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <ProjectModuleApplicationForPaymentApprovals />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/WorkList/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="WorkList"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'WorkList'}>
                      <WorkListDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/PaymentNotice/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="PaymentNotice"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName={'PaymentNotice'}>
                      <PaymentNoticeDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/RFCManagementView`}
                moduleName={PROJECT_MODULE}
                entityName="RFCManagementView"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <RFCManagementView />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/GanttProgram/:recordId`}
                moduleName={PROJECT_MODULE}
                entityName="GanttProgram"
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE} entityName="GanttProgram">
                      <GanttProgramDetailView />
                    </DetailViewContext>
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/:entityName`}
                moduleName={PROJECT_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <RecordListView moduleName={PROJECT_MODULE} />
                  </Suspense>
                }
              />
              <ProtectedRoute
                exact
                path={`/${PROJECT_MODULE}/:entityName/:recordId`}
                moduleName={PROJECT_MODULE}
                component={
                  <Suspense fallback={<AppLoadingPage />}>
                    <DetailViewContext moduleName={PROJECT_MODULE}>
                      <DefaultDetailView showCollaborators />
                    </DetailViewContext>
                  </Suspense>
                }
              />
            </Switch>
          </Route>

          {/* REPORTING DASHBOARD */}
          <Route path="/Dashboard">
            <Suspense fallback={<AppLoadingPage />}>
              <Switch>
                <ProtectedRoute
                  exact
                  path={`/Dashboard`}
                  moduleName={'REPORTING'}
                  component={<SalesDashboard />}
                />
              </Switch>
            </Suspense>
          </Route>

          {/* MERGE RECORDS */}
          <Route path="/merge">
            <Suspense fallback={<AppLoadingPage />}>
              <Switch>
                <ProtectedRoute
                  exact
                  path="/merge/:moduleName/:entityName"
                  moduleName={'OVERRIDE'}
                  component={<MergeRecords />}
                />
              </Switch>
            </Suspense>
          </Route>

          {/* FORGOT PASSWORD */}
          <Route exact path="/forgot-password" component={ForgotPassword} />

          {/* RESET PASSWORD */}
          <Route exact path="/reset-password/:token" component={ResetPassword} />

          {/* REGISTER USER */}
          <Route exact path="/register/:token" component={Register} />

          {/* REGISTER USER WITH CONTACT ID*/}
          <Route exact path="/register/:token/:apiToken/:contactId" component={Register} />

          {/* LOGIN */}
          <Route path="/login" exact>
            <Login />
          </Route>

          {/* LOGIN / MFA */}
          <Route path="/login/mfa" exact>
            <MFASettings />
          </Route>

          {/* 500 */}
          <Route path="/500">
            <Error500 />
          </Route>

          {/* 403 */}
          <Route path="/403">
            <Error403 />
          </Route>

          {/* 404 */}
          <Route>
            <Error404 />
          </Route>
        </Switch>
      ) : (
        // 2. USERS WITHOUT DESKTOP ACCESS
        <Switch>
          {/* FORGOT PASSWORD */}
          <Route exact path="/forgot-password" component={ForgotPassword} />

          {/* RESET PASSWORD */}
          <Route exact path="/reset-password/:token" component={ResetPassword} />

          {/* REGISTER USER */}
          <Route exact path="/register/:token" component={Register} />

          {/* REGISTER USER WITH CONTACT ID*/}
          <Route exact path="/register/:token/:apiToken/:contactId" component={Register} />

          {/* LOGIN */}
          <Route path="/login" exact>
            <Login />
          </Route>

          {/* LOGIN / MFA */}
          <Route path="/login/mfa" exact>
            <MFASettings />
          </Route>

          {/* APP UNAVAILABLE */}
          <Route>
            {!isUserTokenExpired() &&
            userReducer.user?.roles?.length! > 0 &&
            !canUserAccessDesktopApp(userReducer) ? (
              <AppUnavailable />
            ) : (
              <></>
            )}
          </Route>
          {/* APP LOADING */}
          <Route>
            {!isUserTokenExpired() && canUserAccessDesktopApp(userReducer) ? (
              <AppLoadingPage />
            ) : (
              <></>
            )}
          </Route>
        </Switch>
      )}
    </Router>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(DefaultRouter);
