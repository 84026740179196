import { CalendarState } from '../../types';
import {
  FETCH_EARLIER_AVAILABILITY_ERROR,
  FETCH_EARLIER_AVAILABILITY_REQUEST,
  FETCH_EARLIER_AVAILABILITY_RESPONSE,
} from '../constants';
import {
  CalendarAction,
  FetchEarlierAvailabilityErrorAction,
  FetchEarlierAvailabilityRequestAction,
  FetchEarlierAvailabilityResponseAction,
} from '../types';
import { getErrorState, getLoadingState, getSuccessState } from './utils/fetch';

const isRequestAction = (action: CalendarAction): action is FetchEarlierAvailabilityRequestAction =>
  action.type === FETCH_EARLIER_AVAILABILITY_REQUEST;
const isResponseAction = (
  action: CalendarAction,
): action is FetchEarlierAvailabilityResponseAction =>
  action.type === FETCH_EARLIER_AVAILABILITY_RESPONSE;
const isErrorAction = (action: CalendarAction): action is FetchEarlierAvailabilityErrorAction =>
  action.type === FETCH_EARLIER_AVAILABILITY_ERROR;

const reducers = {
  [FETCH_EARLIER_AVAILABILITY_REQUEST]: (
    state: CalendarState['workOrders'],
    _action: FetchEarlierAvailabilityRequestAction,
  ): CalendarState['workOrders'] => ({
    ...state,
    ...getLoadingState(),
  }),

  [FETCH_EARLIER_AVAILABILITY_RESPONSE]: (
    state: CalendarState['workOrders'],
    action: FetchEarlierAvailabilityResponseAction,
  ): CalendarState['workOrders'] => ({
    ...state,
    ...getSuccessState(action.payload),
  }),

  [FETCH_EARLIER_AVAILABILITY_ERROR]: (
    state: CalendarState['workOrders'],
    action: FetchEarlierAvailabilityErrorAction,
  ): CalendarState['workOrders'] => ({
    ...state,
    ...getErrorState(action.payload),
  }),
} as const;

export const earlierAvailabilityReducer = (
  state: CalendarState['workOrders'],
  action: CalendarAction,
): CalendarState['workOrders'] => {
  if (isRequestAction(action)) return reducers[action.type](state, action);

  if (isResponseAction(action)) return reducers[action.type](state, action);

  if (isErrorAction(action)) return reducers[action.type](state, action);

  return state;
};
