import dayjs from 'dayjs';
import { AppointmentView, CalendarState } from '../../types';
import {
  APPOINTMENTS_DATE_FILTER_NEXT,
  APPOINTMENTS_DATE_FILTER_PREV,
  APPOINTMENTS_DATE_FILTER_SET,
  APPOINTMENTS_SELECTED_DATE_SET,
  SET_APPOINTMENTS_FILTER,
} from '../constants';
import { CalendarAction } from '../types';

const getDateFilter = (
  type: AppointmentView,
  start?: string,
  previousView?: AppointmentView,
): CalendarState['appointments']['dateFilter'] => {
  if (type === 'day' && previousView && previousView !== 'day') {
    return {
      start: dayjs().startOf(type).format('YYYY-MM-DD'),
      end: dayjs().endOf(type).format('YYYY-MM-DD'),
    };
  }

  if (!start)
    return {
      start: dayjs().startOf(type).format('YYYY-MM-DD'),
      end: dayjs().endOf(type).format('YYYY-MM-DD'),
    };

  return {
    start: dayjs(start).startOf(type).format('YYYY-MM-DD'),
    end: dayjs(start).endOf(type).format('YYYY-MM-DD'),
  };
};

const min = (a: string, b: string) => (a < b ? a : b);

const max = (a: string, b: string) => (a > b ? a : b);

type Range = { start: string; end: string };
const getDateRange = (dateFilter: Range, dateRange: Range): Range => {
  // const start = min(dateFilter.start, dateRange.start);
  // const end = max(dateFilter.end, dateRange.end);
  const { start, end } = dateFilter;

  return { start, end };
};
const getPreviousDate = (type: AppointmentView, currentDate: string) =>
  dayjs(currentDate).subtract(1, type).format('YYYY-MM-DD');

const getNextDate = (type: AppointmentView, currentDate: string) =>
  dayjs(currentDate).add(1, type).format('YYYY-MM-DD');

export const appointmentsReducer = (
  state: CalendarState['appointments'],
  action: CalendarAction,
): CalendarState['appointments'] => {
  if (action.type === SET_APPOINTMENTS_FILTER) {
    return {
      ...state,
      view: action.payload,
      dateFilter: getDateFilter(action.payload, state.dateFilter.start, state.view),
      dateRange: getDateFilter(action.payload, state.dateFilter.start),
    };
  }

  if (action.type === APPOINTMENTS_DATE_FILTER_PREV) {
    const dateFilter = getDateFilter(
      state.view,
      getPreviousDate(state.view, state.dateFilter.start),
    );
    return {
      ...state,
      dateFilter,
      dateRange: getDateRange(dateFilter, state.dateRange),
    };
  }

  if (action.type === APPOINTMENTS_DATE_FILTER_NEXT) {
    const dateFilter = getDateFilter(state.view, getNextDate(state.view, state.dateFilter.start));
    return {
      ...state,
      dateFilter,
      dateRange: getDateRange(dateFilter, state.dateRange),
    };
  }

  if (action.type === APPOINTMENTS_DATE_FILTER_SET) {
    const dateFilter = getDateFilter(state.view, action.payload);
    return {
      ...state,
      dateFilter,
      dateRange: dateFilter,
    };
  }

  if (action.type === APPOINTMENTS_SELECTED_DATE_SET) {
    return {
      ...state,
      selectedDate: action.payload,
    };
  }
  return state;
};
