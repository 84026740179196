import React, { Component } from 'react';

interface OwnProps {
  allJobs: Array<any>;
  callbackFn: Function;
}

type Props = OwnProps;

/**
 *  Watch for jobs running, retrace the closure when jobs finish.
 */
class AutosplicingJobListener extends Component<Props> {


  componentDidUpdate(prevProps: Props) {
    const { callbackFn } = this.props;

    if (prevProps.allJobs.length > 0 && this.props.allJobs.length === 0) {
      callbackFn()
    }
  }

  render() {
    return <></>;
  }
}

export default AutosplicingJobListener;
