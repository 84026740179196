import { Col, Modal, Row, Spin } from "antd";
import { FC } from "react";
import './styles.scss'

interface ILoaderModalProps {
    visible: boolean;
    title?:'';
    loaderMessage?: string
};

export const LoaderModal: FC<ILoaderModalProps> = (props) => {

    const {title, visible, loaderMessage} = props

    return (
        <Modal
            centered
            open={visible}
            title={title || 'Please wait...'}
            className="loaderModal"
            closable={false}
        >
            <Row style={{padding:40, textAlign:'center'}}>
                <Col span={24}>
                    <Spin size="large"/>
                </Col>
                <Col span={24} style={{marginTop:20}}>
                    <span>{loaderMessage || 'Loading...'}</span>
                </Col>

            </Row>
        </Modal>
    );
}
