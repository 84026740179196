import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import React, { FunctionComponent } from 'react';
import {
  IAutosplicingGraphRefs,
  IAutosplicingReducer,
} from '../../../store/types';
import '../../../styles.scss';

interface OwnProps {
  autosplicingReducer: IAutosplicingReducer;
  updateAutosplicingReducer: Function;
  graphRefs: IAutosplicingGraphRefs;
}

type Props = OwnProps;

const OdinGraph: FunctionComponent<Props> = (props) => {
  const { autosplicingReducer, graphRefs, updateAutosplicingReducer } = props;
  const { loadingIndicators, allJobs } = autosplicingReducer;
  const { odinGraphMinimapRef, odinGraphRef } = graphRefs;

  return (
    <Col
      span={autosplicingReducer.diagramLayout === 'ODIN' ? 24 : 12}
      style={{
        display:
          allJobs!.length > 0 ||
          loadingIndicators?.isLoadingTemplatesError ||
          !autosplicingReducer.latestData?.length ||
          autosplicingReducer.diagramLayout === 'COSMOS'
            ? 'none'
            : 'block',
      }}
    >
      <span
        className="odinGraphContainerTitle"
        style={{
          display:
            loadingIndicators?.isLoadingTemplatesError ||
            !autosplicingReducer.latestData?.length
              ? 'none'
              : 'block',
        }}
      >
        <Row>
          <Col span={24}>Odin</Col>
        </Row>
      </span>
      <div
        className="odinGraphContainer"
        style={{
          overflow: 'hidden',
          height: '80vh',
          padding: 0,
          display:
            loadingIndicators?.isLoadingTemplatesError ||
            !autosplicingReducer.previousData ||
            autosplicingReducer.diagramLayout === 'COSMOS'
              ? 'none'
              : 'block',
        }}
        ref={odinGraphRef}
      >
        <div className="odinGraphMinimap" ref={odinGraphMinimapRef} />

        {/*<div ref={odinGraphToolbarRef} className="odinGraphToolbar"*/}
        {/*     style={{ display: autosplicingReducer.odinGraphTooltipVisible ? 'block' : 'none' }}>*/}
        {/*  <Row style={{*/}
        {/*    backgroundColor: '#ec8e42',*/}
        {/*    color: '#fff',*/}
        {/*    padding: '1px 3px',*/}
        {/*  }}>*/}
        {/*    <Col*/}
        {/*      span={18}*/}
        {/*      style={{ textTransform: 'capitalize' }}>Selected {autosplicingReducer.selectedEntity?.type === 'CABLE' ? 'Cable' : 'Closure'}</Col>*/}
        {/*    <Col span={6} style={{ textAlign: 'right', marginTop: '-2px' }}>*/}
        {/*      <CloseOutlined*/}
        {/*        style={{ fontSize: '0.8em', marginBottom: 2 }}*/}
        {/*        onClick={() => updateAutosplicingReducer({ odinGraphTooltipVisible: false })}/>*/}
        {/*    </Col>*/}
        {/*  </Row>*/}
        {/*  <Row style={{ padding: '5px' }}>*/}
        {/*    <Col span={24} style={{*/}
        {/*      marginBottom: 7,*/}
        {/*      textAlign: 'center',*/}
        {/*    }}>{autosplicingReducer.selectedEntity?.id}</Col>*/}

        {/*    <Col span={24} style={{ textAlign: 'right' }}>*/}
        {/*      <Button*/}
        {/*        type="primary"*/}
        {/*        style={{*/}
        {/*          width: '100%',*/}
        {/*          borderColor: '#ff9c4b',*/}
        {/*          color: '#ff9c4b',*/}
        {/*        }}*/}
        {/*        size="small" ghost*/}
        {/*        onClick={() =>*/}
        {/*        {*/}
        {/*          updateAutosplicingReducer({*/}
        {/*            enteredCableId: undefined,*/}
        {/*            enteredClosureId: String(autosplicingReducer.selectedEntity?.id),*/}
        {/*            formData: {*/}
        {/*              closure_id: autosplicingReducer.selectedEntity?.id,*/}
        {/*              cable_id: '',*/}
        {/*            },*/}
        {/*          })*/}
        {/*        }}*/}
        {/*      >Trace*/}
        {/*      </Button>*/}
        {/*    </Col>*/}

        {/*  </Row>*/}
        {/*</div>*/}
      </div>
    </Col>
  );
};

export default OdinGraph;
