import { Button, Card, Drawer, Table } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { httpGet } from '@core/http/requests';
import { FileOutlined, ReloadOutlined } from '@ant-design/icons';
import moment from 'moment';
import RawDataContainer from '../../../records/components/RawData/RawDataContainer';
import { IAutosplicingReducer } from '@netomnia/modules/ProjectModule/Autosplicing/store/types';
import { updateAutosplicingReducerState } from '@netomnia/modules/ProjectModule/Autosplicing/store/actions';

type Props = {
  title: string;
  pathNames: string[]; // :bucketName/:pathName
  recordReducer: any;
  fileType: string;
  updateAutosplicingReducer: Function;
  listAll?: boolean;
};

interface State {
  isDownloading: boolean;
  isLoadingJSON: boolean;
  data: any[];
  jsonData: any;
  jsonViewerOpen: boolean;
}

class AwsS3BucketList extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isDownloading: false,
      isLoadingJSON: false,
      data: [],
      jsonData: null,
      jsonViewerOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps: any) {
    for (const path of this.props.pathNames) {
      if (!prevProps.pathNames.includes(path)) {
        this.fetchData();
        return;
      }
    }
  }

  private async downloadFile(bucketName: string, pathName: string, versionId?: string) {
    let path = `SchemaModule/v1.0/s3/buckets/presigned/${bucketName}/?pathName=${pathName}`;
    if (versionId) {
      path = path.concat(`&versionId=${versionId}`);
    }
    httpGet(path)
      .then((res) => {
        this.setState({
          isDownloading: false,
        });
        let fileName = pathName?.replace(/\//g, '-');
        fileName = fileName.concat(`.${this.props.fileType}`);
        // trigger file download here.
        const link = document.createElement('a');
        // does not work for cross origin site downloads.
        link.download = fileName;
        link.href = res.data.data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) => {
        this.setState({
          isDownloading: false,
        });
        // show error message
      })
      .finally(() => {});
  }

  private async viewJSON(bucketName: string, pathName: string, versionId?: string) {
    const { updateAutosplicingReducer } = this.props;

    let path = `SchemaModule/v1.0/s3/buckets/presigned/${bucketName}/?pathName=${pathName}`;
    if (versionId) {
      path = path.concat(`&versionId=${versionId}`);
    }

    this.setState({ isLoadingJSON: true });

    httpGet(path)
      .then((res: any) => {
        //Fetch data with presigned url
        fetch(res.data.data)
          .then((s3Res: any) => s3Res.json())
          .then((data: any) => {
            this.setState({ isLoadingJSON: false });

            if (pathName?.indexOf('-cable-connections-temp') > -1) {
              updateAutosplicingReducer({
                historicData: data.data,
              });
            }

            if (data?.data?.length! > 0) {
            } else {
              this.setState({
                isDownloading: false,
                jsonViewerOpen: true,
                jsonData: data,
              });
            }
          });
      })
      .catch(() => {
        this.setState({
          isDownloading: false,
          jsonViewerOpen: false,
          jsonData: null,
          isLoadingJSON: false,
        });
        // show error message
      })
      .finally(() => {});
  }

  private async fetchData() {
    const { pathNames, listAll } = this.props;

    this.setState({
      data: [],
      isDownloading: true,
    });

    for (const pathName of pathNames) {
      httpGet(`SchemaModule/v1.0/s3/buckets/${pathName}`)
        .then((res) => {
          const bucketContents = res?.data?.data?.Versions || res?.data?.data?.Contents || [];
          let uniqueBucketContents: any = [];

          // Get the latest version from each group
          const latestFromGroup: any[] = [];

          // filter out duplicate records in ETag
          let seenETags: string[] = [];
          uniqueBucketContents = bucketContents.reduce((acc: any, item: any) => {
            if (!seenETags.includes(item.ETag)) {
              seenETags.push(item.ETag);
              acc.push(item);
            }
            return acc;
          }, []);

          if (listAll) {
            latestFromGroup.push(...uniqueBucketContents);
          } else {
            // group data by Year,Month, Day
            const grouped: { [key: string]: any[] } = {};
            for (const item of uniqueBucketContents) {
              const key = item.Key;
              if (!grouped[key]) {
                grouped[key] = [item];
              } else {
                grouped[key].push(item);
              }
            }

            for (const key of Object.keys(grouped)) {
              // @ts-ignore
              const latest = new Date(
                Math.max.apply(
                  null,
                  // @ts-ignore
                  grouped[key].map(function (e) {
                    return new Date(e.LastModified);
                  }),
                ),
              );

              latestFromGroup.push(
                grouped[key]?.find(
                  (e) =>
                    new Date(e.LastModified).getMilliseconds() ===
                    new Date(latest).getMilliseconds(),
                ),
              );
            }
          }

          // sort all items asc
          const modifiedContents: any[] = [];

          for (const item of latestFromGroup) {
            item.BucketName = res.data.data.Name;
            modifiedContents.push(item);
          }

          this.setState((prevState) => ({
            data: [...prevState.data, ...modifiedContents],
          }));
          // show success message
        })
        .catch((err) => {
          this.setState({
            isDownloading: false,
          });
          // show error message
        })
        .finally(() => {});
    }

    this.setState({
      isDownloading: false,
    });
  }

  render() {
    const { data } = this.state;

    const columns = [
      {
        title: 'Name',
        dataIndex: 'Key',
        key: 'Key',
      },
      // {
      //   title: 'ETag',
      //   dataIndex: 'ETag',
      //   key: 'ETag',
      // },
      // {
      //   title: 'VersionId',
      //   dataIndex: 'VersionId',
      //   key: 'VersionId',
      // },
      {
        title: 'Date',
        dataIndex: 'LastModified',
        key: 'LastModified',
        sorter: (a: any, b: any) => moment(a.LastModified).unix() - moment(b.LastModified).unix(),
        defaultSortOrder: 'ascend',
      },
      {
        title: 'Action',
        key: 'action',
        render: (text: string, record: any) => (
          <>
            <Button
              style={{ marginRight: 8 }}
              disabled={this.props.fileType !== 'json' || this.state.isLoadingJSON}
              onClick={() => {
                this.viewJSON(record.BucketName, record.Key, record.VersionId);
              }}
              icon={
                record?.Key?.indexOf('-cable-connections-temp') > -1 ? (
                  <i className="bi bi-diagram-2" style={{ marginRight: 5 }} />
                ) : (
                  <i className="bi bi-braces" style={{ marginRight: 5 }} />
                )
              }
            >
              {record?.Key?.indexOf('-cable-connections-temp') > -1 ? 'View Graph' : 'View JSON'}
            </Button>
            <Button
              onClick={() => this.downloadFile(record.BucketName, record.Key, record.VersionId)}
            >
              Download
            </Button>
          </>
        ),
      },
    ];

    return (
      <>
        <Drawer
          title="JSON Data"
          placement="right"
          width={900}
          onClose={() => this.setState({ jsonViewerOpen: false })}
          open={this.state.jsonViewerOpen}
        >
          <RawDataContainer type="DRAWER" data={this.state.jsonData} />
        </Drawer>

        <Card
          title={
            <>
              <FileOutlined style={{ marginRight: 7 }} />
              <span>{this.props.title}</span>
            </>
          }
          size="small"
          style={{ marginTop: 16 }}
          extra={[
            <Button size="small" onClick={() => this.fetchData()} icon={<ReloadOutlined />} />,
          ]}
        >
          <Table
            className="fiberSplicingMatrixTable"
            size="small"
            loading={this.state.isDownloading}
            pagination={false}
            tableLayout="auto"
            //@ts-ignore
            columns={columns}
            // rowClassName={(record: any) => this.setRowColorForSplitType(record)}
            dataSource={data}
          />
        </Card>
      </>
    );
  }
}

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
});

export default connect(mapState, mapDispatch)(AwsS3BucketList);
