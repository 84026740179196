import { OverlayToaster, Position, Toaster } from '@blueprintjs/core';

let toasterInstance: Toaster | null = null;

/** Singleton toaster instance. Create separate instances for different options. */

export const AppToaster = ({ toasterPosition }: { toasterPosition?: Position }) => {
  if (!toasterInstance) {
    toasterInstance = OverlayToaster.create({
      className: 'app-toaster',
      // @ts-ignore
      position: toasterPosition || Position.TOP_RIGHT, // Utiliza la posición proporcionada o una por defecto
    });
  }

  return toasterInstance;
};