import { ButtonGroup } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React from 'react';
import CaseBlockedButton from './CaseBlockedButton';
import RequestReviewButton from './RequestReviewButton';
import SubmitCaseSolvedButton from './SubmitCaseSolvedButton';
import CloseConversationButton from './CloseConversationButton';

const MyCasesBottomRowControl: React.FC = () => {
  return (
    <Row align="middle" style={{ height: '100%', padding: '0 15px' }}>
      <Col span={12}>
        <CloseConversationButton />
      </Col>

      <Col span={12} style={{ textAlign: 'right' }}>
        <ButtonGroup>
          <CaseBlockedButton />
          <RequestReviewButton />
          <SubmitCaseSolvedButton />
        </ButtonGroup>
      </Col>
    </Row>
  );
};

export default MyCasesBottomRowControl;
