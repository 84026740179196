import { useEffect, useState } from 'react';

import { useFetchOneState, useFetchState } from './fetchStates';
import { DashboardData } from './types';
import { useVendorDashboardService } from './useVendorDashboardService';
import { useSubmissionState } from './useSubmissionState';

export type VendorDashboardView =
  | 'pending_submissions'
  | 'pending_review'
  | 'pending_rejections'
  | 'all_fpes';

export const useVendorDashboard = () => {
  const { state: exchanges, actions: exchangesActions } = useFetchState();
  const { state: lineItems, actions: lineItemsActions } = useFetchOneState<DashboardData>();
  const { state: submission, actions: submissionActions } = useSubmissionState();
  const [selectedView, setSelectedView] = useState<VendorDashboardView>('pending_submissions');
  const [isTableLoading, setTableLoading] = useState(false);

  const tableActions = { setTableLoading, setSelectedView };

  const service = useVendorDashboardService({
    exchangesActions,
    lineItemsActions,
    tableActions,
    submissionActions,
  });

  // Side Effects - Begin

  // Fetch Exchanges
  useEffect(() => {
    service.fetchExchanges();
  }, []);

  // Fetch LineItems
  useEffect(() => {
    if (!exchanges.selected) return;
    service.fetchExchangeLineItems(exchanges.selected, selectedView);
  }, [exchanges.selected, selectedView]);
  // Side Effects - End

  return {
    state: { exchanges, lineItems, isTableLoading, submission, selectedView },
    service,
  };
};
