import { Alert } from 'antd';
import React from 'react';

const ConnectionWarning = (closureType: string, closureId: string) => {
  if (closureType === 'L0') {
    return (
      <Alert
        style={{ marginBottom: 20 }}
        message={<span style={{ fontWeight: 500 }}>L0 Closure Error</span>}
        description={
          <span>
            There are no IN connections to L0 Closure {closureId}. Add ODF to
            CableLink connection. Please contact{' '}
            <a href="mailto:amar@netomnia.com" target="_blank">
              Amar
            </a>{' '}
            or{' '}
            <a href="mailto:pieter@netomnia.com" target="_blank">
              Pieter
            </a>{' '}
            for more information.
          </span>
        }
        type="error"
      />
    );
  } else if (closureType === 'L2') {
    return (
      <Alert
        style={{ marginBottom: 20 }}
        message={<span style={{ fontWeight: 500 }}>L2 Closure Error</span>}
        description={
          <span>
            There are no IN cable connection to L2 Closure {closureId}, trace
            from the L0 closure and splice the SND cables first.{' '}
            <a
              href="https://app.prod.netomnia.com/SupportModule/KnowledgeArticle/eb887cdb-f217-4080-a38d-d2340dab1530/view"
              target="_blank"
            >
              Click here to view the how to guide
            </a>
            . If you still are unsure contact{' '}
            <a href="mailto:amar@netomnia.com" target="_blank">
              Amar
            </a>{' '}
            or{' '}
            <a href="mailto:pieter@netomnia.com" target="_blank">
              Pieter
            </a>{' '}
            for more information.
          </span>
        }
        type="error"
      />
    );
  } else return <></>;
};

export default ConnectionWarning;
