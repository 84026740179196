import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';

import './styles.scss';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import DOMPurify from 'dompurify';
import MyCaseAttachmentList from '../MyCaseAttachmentList';

interface Props {
  message: any;
  caseSchema: SchemaEntity;
}

const MyCasesMessage: React.FC<Props> = (props: Props) => {
  const { message } = props;

  let messageBody = getProperty(message, 'Body');
  messageBody = messageBody?.replace(/(?:\r\n|\r|\n)/g, '<br>');

  const replaceLinksWithATags = (str: string) => {
    return str?.replace(/\b(?:https?:\/\/|www\.)[^\s<>\"]+\b/g, (url) => {
      const href = url?.startsWith('www.') ? `http://${url}` : url;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };

  messageBody = replaceLinksWithATags(messageBody);

  const sanitizedMessage = DOMPurify.sanitize(messageBody, { ADD_ATTR: ['target'] });

  let fileIds: string = getProperty(message, 'Files') || '';

  const isAgentsMessage: boolean = getProperty(message, 'SenderType') === 'AGENT';

  return (
    <Row
      key={`Row1-message-${message.id}`}
      justify={`${isAgentsMessage ? 'end' : 'start'}`}
      style={{ padding: '18px 22px 18px 18px' }}
    >
      {/* CLIENT Message */}
      {!isAgentsMessage ? (
        <Col key={`Col1-message-${message.id}`}>
          <Row key={`Row2-message-${message.id}`}>
            {/* Message */}
            <Col span={24} className="myCasesClientMessage" key={`Col2-message-${message.id}`}>
              <span
                style={{ overflowWrap: 'break-word' }}
                key={`Span1-message-${message.id}`}
                dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
              />

              {fileIds.length > 0 && (
                <Col
                  span={24}
                  style={{ marginTop: 15, marginBottom: 5 }}
                  key={`Col3-message-${message.id}`}
                >
                  <MyCaseAttachmentList
                    fileIds={fileIds}
                    thumbSize={60}
                    justify={isAgentsMessage ? 'end' : 'start'}
                  />
                </Col>
              )}
            </Col>

            {/* Client Name and Timestamp */}
            <Col span={24} style={{ color: '#727c93' }} key={`Col4-message-${message.id}`}>
              <Row justify="space-between" key={`Row3-message-${message.id}`}>
                <Col key={`Col5-message-${message.id}`}>
                  <span key={`Span2-message-${message.id}`}>
                    {getProperty(message, 'From') || 'New Account'}
                  </span>
                </Col>
                <Col key={`Col6-message-${message.id}`}>
                  <span key={`Span3-message-${message.id}`}>
                    {dayjs(message.createdAt).format('h:mm:ss A')}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : (
        // AGENT Message
        <Col key={`Col1-message-${message.id}`}>
          <Row key={`Row2-message-${message.id}`}>
            {/* Message */}
            <Col
              span={24}
              className="myCasesUserMessage"
              key={`Col3-message-${message.id}`}
              style={{ textAlign: sanitizedMessage?.length > 100 ? 'left' : 'right' }}
            >
              <span
                key={`Span1-message-${message.id}`}
                style={{
                  overflowWrap: 'break-word',
                }}
                dangerouslySetInnerHTML={{ __html: sanitizedMessage }}
              />
              {/* File Attachments */}
              {fileIds.length > 0 && (
                <Col
                  span={24}
                  style={{ marginTop: messageBody ? 15 : 0, marginBottom: 5 }}
                  key={`Col4-message-${message.id}`}
                >
                  <MyCaseAttachmentList fileIds={fileIds} thumbSize={60} />
                </Col>
              )}
            </Col>
            {/* Timestamp */}
            <Col span={24} key={`Col5-message-${message.id}`}>
              <Col style={{ color: '#727c93' }} key={`Col6-message-${message.id}`}>
                <Row justify="space-between" key={`Row3-message-${message.id}`}>
                  <Col key={`Col7-message-${message.id}`}>
                    <span key={`Span2-message-${message.id}`}>
                      {message.createdBy?.fullName || 'Agent'}
                    </span>
                  </Col>
                  <Col key={`Col8-message-${message.id}`}>
                    <span key={`Span3-message-${message.id}`}>
                      {dayjs(message.createdAt).format('h:mm:ss A')}
                    </span>
                  </Col>
                </Row>
              </Col>
            </Col>
          </Row>
        </Col>
      )}
    </Row>
  );
};

export default MyCasesMessage;
