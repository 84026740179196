import { HTMLTable } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Descriptions, Image, Row } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import RecordProperties from '../../RecordProperties';
import {
  getActivityEntity,
  getChildRecordInformation,
  getParentRecordInformation,
  isActivityNewGroupUpdate,
  isActivityOldGroupUpdate,
  isActivityRecordTitleUpdate,
  isActivityStageUpdate,
} from '../helpers';
import { parseDateAndTimeLocal } from '@core/helpers/dateHelpers';
import NoteEditor from '@core/components/NoteFeed/NoteEditor';

interface Props {
  activityItem: DbRecordEntityTransform;
  compact?: boolean;
}

const ActivityBody: React.FC<Props> = (props: Props) => {
  const { activityItem, compact } = props;
  const [associatedRecord, setAssociatedRecord] = useState<any>(undefined);
  const [htmlContents, setHtmlContents] = useState<any>(undefined);

  // On component mount, get an associated record
  useEffect(() => {
    if (isActivityAnAssociation(activityItem.type)) {
      setAssociatedRecord(getAssociatedRecordForAnActivity(activityItem));
    }
  }, []);

  const isActivityAnAssociation = (activityType: string | undefined) => {
    if (activityType && activityType?.indexOf('ASSOCIATION') > -1) {
      return true;
    } else {
      return false;
    }
  };

  const renderPropertiesForRecord = (activityItem: any) => {
    // Record properties update
    if (
      (activityItem.type === 'DB_RECORD_CREATED' || activityItem.type === 'DB_RECORD_UPDATED') &&
      activityItem.revision?.properties &&
      !activityItem.revision?.groups &&
      !isActivityRecordTitleUpdate(activityItem)
    ) {
      return (
        <HTMLTable
          bordered
          // striped
          style={{ width: '100%', border: '1px solid #D5D6D8' }}
        >
          <thead>
            <tr>
              <th>Property</th>
              <th>Modification</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(activityItem.revision?.properties || {})?.map((key) => (
              <tr>
                <td>{key}</td>
                <td>{JSON.stringify(activityItem.revision?.properties[key])}</td>
              </tr>
            ))}
          </tbody>
        </HTMLTable>
      );
    }

    // OLD Record group update
    else if (isActivityOldGroupUpdate(activityItem)) {
      return (
        <Row style={{ paddingLeft: 5, paddingTop: 5 }}>
          <Col span={24}>
            <Descriptions column={1} bordered size="small" layout="horizontal">
              <Descriptions.Item label="Next Groups" labelStyle={{ width: '30%' }}>
                {activityItem?.revision?.groups?.map((elem: any) => elem?.name || elem)?.join()}
              </Descriptions.Item>
              <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
                {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      );
    }

    // NEW Record group update
    else if (isActivityNewGroupUpdate(activityItem)) {
      return (
        <Row style={{ paddingLeft: 5, paddingTop: 5 }}>
          <Col span={24}>
            <Descriptions column={1} bordered size="small" layout="horizontal">
              <Descriptions.Item label="Previous Groups" labelStyle={{ width: '30%' }}>
                {activityItem?.revision?.previousGroups?.join(', ')}
              </Descriptions.Item>
              <Descriptions.Item label="Next Groups" labelStyle={{ width: '30%' }}>
                {activityItem?.revision?.nextGroups?.join(', ')}
              </Descriptions.Item>
              <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
                {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      );
    }

    // Record stage update
    else if (isActivityStageUpdate(activityItem)) {
      const stageId = activityItem.revision?.stageId;
      const stage = activityItem.associations?.find(
        (association: any) => association.id === stageId,
      );

      if (stage && stage?.name) {
        return (
          <Row style={{ paddingLeft: 5, paddingTop: 5 }}>
            <Col span={24}>
              <Descriptions column={1} bordered size="small" layout="horizontal">
                <Descriptions.Item label="Stage" labelStyle={{ width: '30%' }}>
                  {stage.name}
                </Descriptions.Item>
                <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
                  {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
                </Descriptions.Item>
                {activityItem.revision?.properties &&
                Object.keys(activityItem.revision?.properties)?.length > 0 ? (
                  Object.entries(activityItem.revision?.properties).map((item: any) => (
                    <Descriptions.Item label={item[0]} labelStyle={{ width: '30%' }}>
                      {item[1]}
                    </Descriptions.Item>
                  ))
                ) : (
                  // <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
                  //   {}
                  // </Descriptions.Item>

                  // <span>{JSON.stringify(Object.entries(activityItem.revision?.properties))}</span>
                  <></>
                )}
              </Descriptions>
            </Col>
          </Row>
        );
      }
    }
    // Order amendment
    else if (activityItem.revision && activityItem.type === 'ORDER_AMENDMENT') {
      return renderOrderAmendmentBody(activityItem);
    }
    // Record title updated
    else if (isActivityRecordTitleUpdate(activityItem)) {
      return (
        <Row style={{ paddingLeft: 5, paddingTop: 5 }}>
          <Col span={24}>
            <Descriptions column={1} bordered size="small" layout="horizontal">
              <Descriptions.Item label="Title" labelStyle={{ width: '30%' }}>
                {activityItem.revision?.title}
              </Descriptions.Item>
              <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
                {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      );
    }
  };

  const renderOrderAmendmentBody = (activityItem: DbRecordEntityTransform) => {
    const amendedOrderId = activityItem?.revision?.amendedOrderId;
    const amendedOrderTitle = activityItem?.associations.find(
      (assoc: any) => assoc.id === amendedOrderId,
    )?.title;
    const amendedOrderUrl = `/${SchemaModuleTypeEnums.ORDER_MODULE}/${SchemaModuleEntityTypeEnums.ORDER}/${amendedOrderId}`;

    const cancelledOrderId = activityItem?.revision?.cancelledOrderId;
    const cancelledOrderTitle = activityItem?.associations.find(
      (assoc: any) => assoc.id === cancelledOrderId,
    )?.title;
    const cancelledOrderUrl = `/${SchemaModuleTypeEnums.ORDER_MODULE}/${SchemaModuleEntityTypeEnums.ORDER}/${cancelledOrderId}`;

    return (
      <Col span={24} style={{ paddingLeft: 5, paddingTop: 5 }}>
        <Descriptions column={1} bordered size="small" layout="horizontal">
          <Descriptions.Item label={`New Order`}>
            {amendedOrderId ? (
              <Link to={amendedOrderUrl} target="_blank">
                {amendedOrderTitle || '-'}
              </Link>
            ) : (
              <span>Unavailable</span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`Old Order`}>
            {cancelledOrderId ? (
              <Link to={cancelledOrderUrl} target="_blank">
                {cancelledOrderTitle || '-'}
              </Link>
            ) : (
              <span>Unavailable</span>
            )}
          </Descriptions.Item>
          <Descriptions.Item label={`Activity recorded`}>
            {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    );
  };

  const renderAssociationBody = (activityItem: DbRecordEntityTransform) => {
    const parentRecordInformation = getParentRecordInformation(activityItem);
    const childRecordInformation = getChildRecordInformation(activityItem);

    const { parentEntity, parentRecordNumber, parentRecordUrl, parentRecordTitle } =
      parentRecordInformation;
    const { childEntity, childRecordNumber, childRecordUrl, childRecordTitle } =
      childRecordInformation;

    const associatedRecord = getAssociatedRecordForAnActivity(activityItem);

    return (
      <Col
        span={24}
        style={{ paddingLeft: 5, paddingTop: 5 }}
        className={compact ? 'compactSize' : ''}
      >
        <Descriptions
          column={1}
          bordered
          size="small"
          layout="horizontal"
          className={compact ? 'compactSize' : ''}
        >
          <Descriptions.Item label={`Parent ${parentEntity} Record`} labelStyle={{ width: '30%' }}>
            <Link to={parentRecordUrl} target="_blank">
              {parentRecordNumber || parentRecordTitle}
            </Link>
          </Descriptions.Item>
          <Descriptions.Item label={`Child ${childEntity} Record`} labelStyle={{ width: '30%' }}>
            <Link to={childRecordUrl} target="_blank">
              {childRecordNumber || childRecordTitle}
            </Link>
          </Descriptions.Item>

          {/* Show an associated record Description property */}
          {getProperty(associatedRecord, 'Description') ? (
            <Descriptions.Item label="Description">
              {getProperty(associatedRecord, 'Description')}
            </Descriptions.Item>
          ) : (
            <></>
          )}

          <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
            {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
          </Descriptions.Item>
        </Descriptions>
      </Col>
    );
  };

  const renderActivityBody = (activityItem: DbRecordEntityTransform) => {
    if (isActivityAnAssociation(activityItem.type)) {
      const activityEntityName = getActivityEntity(activityItem);
      const parentRecordInformation = getParentRecordInformation(activityItem);
      const childRecordInformation = getChildRecordInformation(activityItem);
      const { parentEntity, parentRecordNumber, parentRecordUrl, parentRecordTitle } =
        parentRecordInformation;
      const { childEntity, childRecordNumber, childRecordUrl, childRecordTitle } =
        childRecordInformation;

      switch (activityEntityName) {
        case 'SmsMessage':
          return (
            <>
              <Col span={24}>
                <Descriptions column={1} bordered size="small" layout="horizontal">
                  <Descriptions.Item label={`SMS Record`} labelStyle={{ width: '30%' }}>
                    <Link to={childRecordUrl} target="_blank">
                      {childRecordTitle || '-'}
                    </Link>
                  </Descriptions.Item>
                  <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
                    {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
                  </Descriptions.Item>
                </Descriptions>
              </Col>
              <Col span={24}>
                <span>
                  {associatedRecord?.properties ? (
                    <Col span={24}>
                      {associatedRecord?.properties?.Body || associatedRecord?.title}
                    </Col>
                  ) : (
                    <></>
                  )}
                </span>
              </Col>
            </>
          );
        case 'File':
          // EMAIL FILE
          if (associatedRecord?.title?.indexOf('SENDGRID') > -1) {
            const emailURL = getProperty(associatedRecord, 'Url');
            fetch(emailURL).then(async function (response) {
              const text = await response.text();
              var doc = new DOMParser().parseFromString(text, 'text/html');
              setHtmlContents(doc.body.innerHTML);
            });

            return (
              <>
                <Col span={24}>
                  <Descriptions column={1} bordered size="small" layout="horizontal">
                    <Descriptions.Item label={`Email Record`} labelStyle={{ width: '30%' }}>
                      <Link to={childRecordUrl} target="_blank">
                        {childRecordNumber || childRecordTitle || 'Email'}
                      </Link>
                    </Descriptions.Item>
                    <Descriptions.Item label={`Activity recorded`} labelStyle={{ width: '30%' }}>
                      {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24} className="FileBody" style={{ marginTop: 10 }}>
                  {htmlContents ? (
                    <div dangerouslySetInnerHTML={{ __html: htmlContents }} />
                  ) : (
                    <span>Could not access html file.</span>
                  )}
                </Col>
              </>
            );
          }

          // REGULAR FILE
          else {
            return (
              <>
                <Col span={24}>
                  <Descriptions column={1} bordered size="small" layout="horizontal">
                    <Descriptions.Item label={`File Record`} labelStyle={{ width: 200 }}>
                      <Link to={childRecordUrl} target="_blank">
                        {childRecordTitle || '-'}
                      </Link>
                    </Descriptions.Item>
                    <Descriptions.Item label={`Activity recorded`}>
                      {parseDateAndTimeLocal(activityItem.createdAt!.toString())}
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
                <Col span={24} className="FileBody">
                  {associatedRecord?.properties ? (
                    <div style={{ marginTop: 10 }}>
                      <Image src={associatedRecord?.properties?.Url} width={50} />
                    </div>
                  ) : (
                    <></>
                  )}
                </Col>
              </>
            );
          }
        case 'Note':
          return (
            <Col span={24} key={activityItem.id}>
              {getProperty(associatedRecord, 'JSONContent') ||
              getProperty(associatedRecord, 'Body') ? (
                <div style={{ background: '#F7F7F7', padding: 10 }}>
                  <NoteEditor
                    value={
                      getProperty(associatedRecord, 'JSONContent') || [
                        {
                          type: 'paragraph',
                          children: [{ text: getProperty(associatedRecord, 'Body') }],
                        },
                      ]
                    }
                    isViewMode={true}
                  />
                </div>
              ) : (
                <></>
              )}
            </Col>
          );
        default:
          return renderAssociationBody(activityItem);
      }
    } else {
      return (
        <Col span={24} style={{ paddingLeft: 5, paddingTop: 5 }}>
          {renderPropertiesForRecord(activityItem)}
        </Col>
      );
    }
  };

  // On component mount, get an associated record
  useEffect(() => {
    if (isActivityAnAssociation(activityItem.type)) {
      setAssociatedRecord(getAssociatedRecordForAnActivity(activityItem));
    }
  }, []);

  // Filter out associated record from the activity associations
  const getAssociatedRecordForAnActivity = (activityItem: DbRecordEntityTransform) => {
    if (
      activityItem &&
      activityItem.associations?.length > 0 &&
      activityItem.revision?.childRecordId
    ) {
      const foundChildAssociation = activityItem.associations.find(
        (associationRecord: DbRecordEntityTransform) =>
          associationRecord.id === activityItem.revision?.childRecordId,
      );
      if (foundChildAssociation) {
        return foundChildAssociation;
      }
    }
  };

  return <Row className="activityBodyContainer">{renderActivityBody(activityItem)}</Row>;
};

export default ActivityBody;
