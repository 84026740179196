import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, InputGroup, Section } from '@blueprintjs/core';
import { SchemaAssociationEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/schema.association.entity';
import { SchemaAssociationCardinalityTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/types/schema.association.cardinality.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Form, FormInstance, Row, Select, Space, Table, Typography } from 'antd';
import { constantCase } from 'change-case';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import {
  CreateSchemaAssociation,
  createSchemaAssociationRequest,
  DeleteSchemaAssociation,
  deleteSchemaAssociationsRequest,
  GetAssociationBySchemaId,
  getSchemaAssociationsRequest,
  UpdateSchemaAssociation,
  updateSchemaAssociationRequest,
} from '@legacy/core/schemasAssociations/store/actions';
import { SchemaAssociationReducer } from '@legacy/core/schemasAssociations/store/reducer';
import { SharedFormField } from '@legacy/components/SharedForm/FormFields';
import FormModal, {
  FormReducerSubmitEvt,
} from '@legacy/components/SharedForm/SharedFormModal';
import {
  initializeSharedForm,
  updateSharedFormInput,
} from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { renderBooleanValue } from '@core/helpers/UIHelpers';
import { createFormFields, editFormFields } from '../FormFields';

interface Props {
  schema: any;
  formReducer: SharedFormReducer;
  schemaReducer: ISchemaReducer;
  schemaAssociationReducer: SchemaAssociationReducer;
  initializeForm: any;
  getSchemaAssociations: any;
  createAssociation: (params: CreateSchemaAssociation) => void;
  updateAssociation: (params: UpdateSchemaAssociation) => void;
  deleteAssociation: (params: DeleteSchemaAssociation, cb?: any) => void;
  updateForm: (params: any) => void;
}
interface State {
  searchTerm: string;
  deleteColumnModalIsVisible: boolean;
  associationIdToDelete: any;
}

const uuid = uuidv4();

class SchemaAssociationsListView extends React.Component<Props, State> {
  formRef = React.createRef<FormInstance>();

  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: '',
      deleteColumnModalIsVisible: false,
      associationIdToDelete: undefined,
    };
  }

  componentDidMount() {
    this.fetchAssociations();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any) {
    if (prevProps.schema !== this.props.schema) {
      this.fetchAssociations();
    }

    if (
      prevProps.formReducer.saveData.childSchemaId !== this.props.formReducer.saveData.childSchemaId
    ) {
      this.getLabelValue();
    }
  }

  fetchAssociations() {
    const { getSchemaAssociations, schema } = this.props;

    if (schema) {
      getSchemaAssociations({ schemaId: schema.id });
    }
  }

  getLabelValue = () => {
    const { schemaReducer, updateForm, schema } = this.props;

    if (this.formRef.current) {
      const childSchemaId = this.formRef.current.getFieldValue('childSchemaId');

      if (childSchemaId) {
        schemaReducer.list.forEach((element: any) => {
          if (element.id === childSchemaId) {
            this.formRef?.current?.setFieldsValue({
              label: `${schema.entityName}_${element.entityName}`,
            });
            updateForm({
              property: 'label',
              value: `${schema.entityName}_${element.entityName}`,
            });
          }
        });
      }
    }
  };

  createAssociation() {
    const { initializeForm, schemaReducer } = this.props;

    let tempArr = new Array();

    createFormFields.forEach((element: any) => {
      if (
        element.property === 'childSchemaId' ||
        element.property === 'findInSchema' ||
        element.property === 'findInChildSchema'
      ) {
        element.options = schemaReducer.list
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((elem) => ({
            label: elem.name,
            value: elem.id,
          }));
      }

      tempArr.push(element);
    });

    // Add Label to the second place
    tempArr.splice(2, 0, {
      label: 'Label',
      type: 'TEXT',
      property: 'label',
      isRequired: true,
      message: 'Please enter a label',
      isHidden: false,
      isDisabled: false,
    });

    initializeForm({
      formUUID: uuid,
      showModal: true,
      title: 'Create Association',
      formFields: tempArr,
      entityName: 'SchemaAssociation',
      isUpdateReq: false,
    });
  }

  handleDelete() {
    const { schema, deleteAssociation } = this.props;
    const associationId = this.state.associationIdToDelete;
    if (schema && associationId) {
      deleteAssociation({ schemaId: schema?.id, associationId }, () => {
        this.closeModal();
      });
    }
  }

  handleEdit(record: any) {
    const { initializeForm, schemaReducer } = this.props;

    let editForm = new Array();

    editFormFields.forEach((element: any) => {
      if (
        element.property === 'childSchemaId' ||
        element.property === 'findInSchema' ||
        element.property === 'findInChildSchema'
      ) {
        element.options = schemaReducer.list.map((elem) => ({
          label: elem.name,
          value: elem.id,
        }));
      }

      if (element.property === 'childSchemaId') {
        editForm.push({
          allowClear: element.allowClear,
          allowSearch: element.allowSearch,
          allowSort: element.allowSort,
          initialValue: record.childSchema.id,
          isDeletable: element.isDeletable,
          isDisabled: element.isDisabled,
          isHidden: element.isHidden,
          isRequired: element.isRequired,
          label: element.label,
          message: element.message,
          options: element.options,
          property: element.property,
          searchable: element.searchable,
          type: element.type,
          value: record.childSchema.id,
        });
      }
      // ODN-2340 render schema types constraints edit fields
      else if (element.property === 'schemaTypesConstraints') {
        element.render = (
          field: SharedFormField,
          savedData: { [key: string]: any },
          formRef: any,
        ) => {
          return this.renderSchemaTypesConstraints(field, savedData, formRef);
        };

        editForm.push({
          allowClear: element.allowClear,
          allowSearch: element.allowSearch,
          allowSort: element.allowSort,
          initialValue: record.schemaTypesConstraints,
          isHidden: element.isHidden,
          label: element.label,
          message: element.message,
          property: element.property,
          render: element.render,
          type: element.type,
          value: record.schemaTypesConstraints,
        });
      } else {
        editForm.push({
          allowClear: element.allowClear,
          allowSearch: element.allowSearch,
          allowSort: element.allowSort,
          initialValue: record[element.property],
          isDeletable: element.isDeletable,
          isDisabled: element.isDisabled,
          isHidden: element.isHidden,
          isRequired: element.isRequired,
          label: element.label,
          message: element.message,
          options: element.options,
          property: element.property,
          searchable: element.searchable,
          type: element.type,
          value: record[element.property],
        });
      }
    });

    // Add Label
    editForm.unshift({
      label: 'Label',
      type: 'TEXT',
      property: 'label',
      isRequired: true,
      message: 'Please enter a label',
      isHidden: false,
      isDisabled: false,
      initialValue: record.label,
      value: record.label,
    });

    initializeForm({
      formUUID: uuid,
      title: 'Edit Association',
      recordId: record.id,
      showModal: true,
      formFields: editForm,
      saveData: {
        // add any properties that should be set even if the user does not update
        childSchemaId: record.childSchema.id,
      },
      entityName: 'SchemaAssociation',
      isUpdateReq: true,
    });
  }

  /**
   * ODN-2340 render schema types constraints edit fields
   *
   * @param field
   * @param savedData
   * @param formRef
   * @returns
   */
  renderSchemaTypesConstraints(
    field: SharedFormField,
    savedData: { [key: string]: any },
    formRef: any,
  ) {
    const { schemaReducer, schema } = this.props;

    const parentSchema: SchemaEntity = schema;

    const childSchemaId = savedData?.childSchemaId;
    const childSchema = schemaReducer.list?.find((s) => s.id == childSchemaId);

    if (parentSchema?.types?.length > 0 || (childSchema && childSchema?.types?.length > 0)) {
      let initialValue = savedData?.schemaTypesConstraints ?? field.initialValue;

      return (
        <Space
          direction="vertical"
          style={{
            width: '98%',
            border: '1px solid #d9d9d9',
            padding: '.5rem',
            marginBottom: 15,
          }}
        >
          <h4 style={{ margin: '5px 0' }}>{field.label}</h4>
          <Form.List name={field.property} initialValue={initialValue}>
            {(fields, { add, remove }) => {
              return (
                <div>
                  {fields.map((field: any, index) => (
                    <Row key={field.key} gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                      <Col span={11}>
                        <Form.Item
                          name={[field.name, 'parentSchemaTypeId']}
                          fieldKey={[field.fieldKey, 'parentSchemaTypeId']}
                          label="Parent Schema Type"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            allowClear={true}
                            placeholder="Select"
                            style={{ width: '100%' }}
                            onChange={() => this.handleConstraintChange(formRef)}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {parentSchema?.types ? (
                              parentSchema?.types?.map((t) => (
                                <Select.Option value={t.id}>{t.name}</Select.Option>
                              ))
                            ) : (
                              <Select.Option value="">no options</Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={11}>
                        <Form.Item
                          name={[field.name, 'childSchemaTypeId']}
                          fieldKey={[field.fieldKey, 'childSchemaTypeId']}
                          label="Child Schema Type"
                          labelCol={{ span: 24 }}
                        >
                          <Select
                            allowClear={true}
                            placeholder="Select"
                            style={{ width: '100%' }}
                            onChange={() => this.handleConstraintChange(formRef)}
                            getPopupContainer={(trigger) => trigger.parentNode}
                          >
                            {childSchema?.types ? (
                              childSchema?.types?.map((t) => (
                                <Select.Option value={t.id}>{t.name}</Select.Option>
                              ))
                            ) : (
                              <Select.Option value="">no options</Select.Option>
                            )}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={1} style={{ marginTop: '37px', paddingLeft: '0' }}>
                        <DeleteOutlined
                          className="dynamic-delete-button"
                          onClick={() => this.removeConstraint(formRef, index)}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Form.Item>
                    <Button
                      onClick={() => {
                        this.addConstraint(formRef);
                      }}
                      style={{ width: '100%' }}
                    >
                      <PlusOutlined /> Add Constraint
                    </Button>
                  </Form.Item>
                </div>
              );
            }}
          </Form.List>
        </Space>
      );
    }
  }

  handleConstraintChange(formRef: any) {
    const { updateForm } = this.props;

    const fieldValues = formRef.current?.getFieldsValue();

    updateForm({
      property: 'schemaTypesConstraints',
      value: fieldValues?.schemaTypesConstraints ?? [],
    });
  }

  removeConstraint(formRef: any, index: number) {
    const { updateForm } = this.props;

    const fieldsValues = formRef.current?.getFieldsValue();

    const existingConstraints: any[] = fieldsValues?.schemaTypesConstraints ?? [];

    const newConstraints = existingConstraints.filter((c, i) => i !== index);

    formRef.current?.setFieldsValue({
      schemaTypesConstraints: newConstraints,
    });

    updateForm({
      property: 'schemaTypesConstraints',
      value: newConstraints,
    });
  }

  addConstraint(formRef: any) {
    const { updateForm } = this.props;

    const fieldsValues = formRef.current?.getFieldsValue();

    const existingConstraints = fieldsValues?.schemaTypesConstraints ?? [];

    const newOneConstraint = {
      id: undefined,
      parentSchemaTypeId: undefined,
      childSchemaTypeId: undefined,
    };

    const newConstraints = [...existingConstraints, newOneConstraint];

    formRef.current?.setFieldsValue({
      schemaTypesConstraints: newConstraints,
    });

    updateForm({
      property: 'schemaTypesConstraints',
      value: newConstraints,
    });
  }

  renderSchemaColumnFromSchemaId(schemaId: any) {
    const { schemaReducer } = this.props;

    const selected = schemaReducer.list.find((elem: any) => elem.id === schemaId);

    if (selected !== undefined) {
      const label = schemaReducer.list.find((elem: any) => elem.id === schemaId)?.name;

      return (
        <Link to={`/SchemaModule/Schema/${schemaId}`} component={Typography.Link}>
          {label}
        </Link>
      );
    } else {
      return '';
    }
  }

  handleFormSubmit(params: FormReducerSubmitEvt) {
    const { schema, createAssociation, updateAssociation, formReducer } = this.props;

    // Create request
    if (schema && params.data && !formReducer.isUpdateReq) {
      const body = {
        label: params.data.label,
        childSchemaId: params.data.childSchemaId,
        findInSchema: params.data.findInSchema,
        findInChildSchema: params.data.findInChildSchema,
        childActions: params.data.childActions,
        parentActions: params.data.parentActions,
        cascadeDeleteChildRecord: params.data.cascadeDeleteChildRecord,
        hasColumnMappings: params.data.hasColumnMappings,
        // @ts-ignore
        type: SchemaAssociationCardinalityTypes[constantCase(params.data.type) as string],
      };

      createAssociation({ schemaId: schema?.id, body });
    } else if (schema && params.data && formReducer.isUpdateReq) {
      const body = {
        label: params.data.label,
        childSchemaId: params.data.childSchemaId,
        schemaTypesConstraints: params.data.schemaTypesConstraints, // ODN-2340
        findInSchema: params.data.findInSchema,
        findInChildSchema: params.data.findInChildSchema,
        childActions: params.data.childActions,
        parentActions: params.data.parentActions,
        cascadeDeleteChildRecord: params.data.cascadeDeleteChildRecord,
        hasColumnMappings: params.data.hasColumnMappings,
        getUrl: params.data.getUrl,
        postUrl: params.data.postUrl,
        putUrl: params.data.putUrl,
        deleteUrl: params.data.deleteUrl,
        position: params.data.position,
        searchable: params.data.searchable,
        isDeletable: params.data.isDeletable,
        // @ts-ignore
        type: SchemaAssociationCardinalityTypes[constantCase(params.data.type) as string],
      };

      updateAssociation({
        schemaId: schema?.id,
        associationId: String(formReducer.recordId),
        body,
      });
    }
  }

  /**
   * ODN-2340 renders schema type constraints info
   *
   * @param association
   * @returns
   */
  renderSchemaTypesConstraintsCell(association: SchemaAssociationEntity) {
    const { schema } = this.props;

    if (association && association?.schemaTypesConstraints?.length > 0) {
      const associationWithFullConstraints: SchemaAssociationEntity = schema?.associations?.find(
        (a: SchemaAssociationEntity) => a.id === association.id,
      );

      const constraintsElements = [];

      for (let i = 0; i < association?.schemaTypesConstraints.length; i++) {
        const item = association?.schemaTypesConstraints[i];
        const constraint: any =
          associationWithFullConstraints?.schemaTypesConstraints?.find((c) => c.id === item.id) ??
          item;

        if (i !== 0) {
          constraintsElements.push(<br />);
        }

        constraintsElements.push(
          <span>
            {constraint.parentSchemaType?.name ?? 'ANY'}-{constraint.childSchemaType?.name ?? 'ANY'}
          </span>,
        );
      }

      return constraintsElements;
    } else {
      return '';
    }
  }

  closeModal = () => {
    this.setState({ deleteColumnModalIsVisible: false, associationIdToDelete: undefined });
  };

  render() {
    const { schema, schemaAssociationReducer } = this.props;

    const childTableColumns: any[] = [
      { title: 'Label', dataIndex: 'label' },
      {
        title: 'Child Association',
        dataIndex: 'childSchema',
        render: (text: any, record: SchemaAssociationEntity) => {
          return (
            <Link
              to={`/SchemaModule/Schema/${record?.childSchema?.id}`}
              component={Typography.Link}
            >
              {record?.childSchema?.name}
            </Link>
          );
        },
      },
    ];

    // ODN-2340 render schema types constraints info
    if (
      schema?.types?.length! > 0 ||
      schemaAssociationReducer.childAssociations.some((a) => a.schemaTypesConstraints?.length > 0)
    ) {
      childTableColumns.push({
        title: 'Schema Types Constraints',
        dataIndex: 'schemaTypesConstraints',
        render: (text: any, association: SchemaAssociationEntity) => {
          return this.renderSchemaTypesConstraintsCell(association);
        },
      });
    }

    childTableColumns.push(
      { title: 'Association Type', dataIndex: 'type' },
      {
        title: 'Find in Schema',
        dataIndex: 'findInSchema',
        render: (text: any) => {
          return this.renderSchemaColumnFromSchemaId(text);
        },
      },
      {
        title: 'Find in Child Schema',
        dataIndex: 'findInChildSchema',
        render: (text: any) => {
          return this.renderSchemaColumnFromSchemaId(text);
        },
      },
      { title: 'Child Actions', dataIndex: 'childActions' },
      { title: 'Parent Actions', dataIndex: 'parentActions' },
      {
        title: 'Cascade Delete Child Record',
        dataIndex: 'cascadeDeleteChildRecord',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Has Column Mappings',
        dataIndex: 'hasColumnMappings',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Is Searchable',
        dataIndex: 'searchable',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Is Deletable',
        dataIndex: 'isDeletable',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Master',
        dataIndex: 'master',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },

      { title: 'Position', dataIndex: 'position' },
      {
        title: '',
        dataIndex: 'operation',
        render: (text: any, record: any) => (
          <Button
            icon="edit"
            intent="primary"
            minimal
            small
            onClick={() => this.handleEdit(record)}
          />
        ),
      },
      {
        title: '',
        dataIndex: 'operation',
        render: (text: any, record: any) => (
          <Button
            icon="trash"
            small
            intent="danger"
            minimal
            onClick={() =>
              this.setState({
                deleteColumnModalIsVisible: true,
                associationIdToDelete: record.id,
              })
            }
          />
        ),
      },
    );

    const parentTableColumns: any[] = [
      { title: 'Label', dataIndex: 'label' },
      {
        title: 'Parent Association',
        dataIndex: 'parentSchema',
        render: (text: any, record: any) => {
          return (
            <Link
              to={`/SchemaModule/Schema/${record.parentSchema.id}`}
              component={Typography.Link}
              target="_blank"
            >
              {record.parentSchema.name}
            </Link>
          );
        },
      },
    ];

    // ODN-2340 render schema types constraints info
    if (
      schema?.types?.length! > 0 ||
      schemaAssociationReducer.parentAssociations.some((a) => a.schemaTypesConstraints?.length > 0)
    ) {
      parentTableColumns.push({
        title: 'Schema Types Constraints',
        dataIndex: 'schemaTypesConstraints',
        render: (text: any, association: SchemaAssociationEntity) => {
          return this.renderSchemaTypesConstraintsCell(association);
        },
      });
    }

    parentTableColumns.push(
      { title: 'Association Type', dataIndex: 'type' },
      {
        title: 'Find in Schema',
        dataIndex: 'findInSchema',
        render: (data: any) => {
          return this.renderSchemaColumnFromSchemaId(data);
        },
      },
      {
        title: 'Find in Child Schema',
        dataIndex: 'findInChildSchema',
        render: (text: any, record: any) => {
          return this.renderSchemaColumnFromSchemaId(text);
        },
      },
      { title: 'Child Actions', dataIndex: 'childActions' },
      { title: 'Parent Actions', dataIndex: 'parentActions' },
      {
        title: 'Cascade Delete Child Record',
        dataIndex: 'cascadeDeleteChildRecord',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Has Column Mappings',
        dataIndex: 'hasColumnMappings',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Is Searchable',
        dataIndex: 'searchable',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Is Deletable',
        dataIndex: 'isDeletable',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },
      {
        title: 'Master',
        dataIndex: 'master',
        render: (data: any) => {
          return renderBooleanValue(data ? 'true' : 'false');
        },
      },

      { title: 'Position', dataIndex: 'position' },
    );

    const filteredChildAssociations = schemaAssociationReducer.childAssociations.filter(
      (association: SchemaAssociationEntity) => {
        return (
          association.label.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
          association.childSchema.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())
        );
      },
    );

    return (
      <>
        <Alert
          intent="danger"
          canEscapeKeyCancel
          cancelButtonText="Cancel"
          confirmButtonText="Delete"
          isOpen={this.state.deleteColumnModalIsVisible}
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onConfirm={() => this.handleDelete()}
        >
          <p>Are you sure you want to delete this association? This action cannot be undone.</p>
        </Alert>

        <FormModal
          externalRef={this.formRef as any}
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => this.handleFormSubmit(params)}
        />

        <Row>
          {/* Child Associations */}
          <Col span={24} style={{ marginTop: 5 }}>
            <Section
              title="Child Associations"
              rightElement={
                <>
                  <InputGroup
                    round
                    id="text-input"
                    type="search"
                    intent={this.state.searchTerm ? 'primary' : 'none'}
                    style={{ width: 170 }}
                    placeholder="Search"
                    leftIcon="search"
                    value={this.state.searchTerm}
                    onChange={(e: any) => this.setState({ searchTerm: e.target.value })}
                  />
                  <Button
                    icon="plus"
                    key="1"
                    style={{ marginLeft: 'auto' }}
                    onClick={() => this.createAssociation()}
                    intent="success"
                  >
                    Create Association
                  </Button>
                </>
              }
            >
              <Row>
                <Col span={24}>
                  <Table
                    size="small"
                    dataSource={filteredChildAssociations}
                    columns={childTableColumns}
                    loading={schemaAssociationReducer.isRequesting}
                  />
                </Col>
              </Row>
            </Section>
          </Col>

          {/* Parent Associations */}
          <Col span={24} style={{ marginTop: 15 }}>
            <Section title="Parent Associations">
              <Row>
                <Col span={24}>
                  <Table
                    size="small"
                    pagination={false}
                    dataSource={schemaAssociationReducer?.parentAssociations}
                    columns={parentTableColumns}
                    loading={schemaAssociationReducer.isRequesting}
                  />
                </Col>
              </Row>
            </Section>
          </Col>
        </Row>
      </>
    );
  }
}

const mapState = (state: any) => ({
  formReducer: state.formReducer,
  schemaReducer: state.schemaReducer,
  schemaAssociationReducer: state.schemaAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  getSchemaAssociations: (params: GetAssociationBySchemaId) =>
    dispatch(getSchemaAssociationsRequest(params)),
  createAssociation: (params: CreateSchemaAssociation) =>
    dispatch(createSchemaAssociationRequest(params)),
  updateAssociation: (params: UpdateSchemaAssociation) =>
    dispatch(updateSchemaAssociationRequest(params)),
  deleteAssociation: (params: DeleteSchemaAssociation, cb?: any) =>
    dispatch(deleteSchemaAssociationsRequest(params, cb)),
  updateForm: (params: any) => dispatch(updateSharedFormInput(params)),
});

export default connect(mapState, mapDispatch)(SchemaAssociationsListView);
