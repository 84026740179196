import { Descriptions } from 'antd';
import moment from 'moment';
import React, { FunctionComponent } from 'react';
import './styles.scss';

interface OwnProps {
  feature: any;
}

type Props = OwnProps;

const RenderGisFeature: FunctionComponent<Props> = (props) => {
  const { feature } = props;

  // Show different id props depending on openreach type
  const renderIdPlaceholder = () => {
    
  }

  return (
    <Descriptions
      size="small"
      bordered={true}
      layout="horizontal"
      className="featureDescriptions"
    >
      {/* Id */}
      <Descriptions.Item span={3} label="Id">
        {feature.id ? feature.id.split('.')[1] : '-'}
      </Descriptions.Item>

      {/* Eco */}
      <Descriptions.Item span={3} label="Eco">
        {feature.properties?.eco ? 'true' : 'false'}
      </Descriptions.Item>

      {/* Noi Ref - Show only on Cables */}
      {feature.id?.split('.')[0] === 'cable' ? (
        <Descriptions.Item span={3} label="Noi Ref">
          {feature.properties?.noi_ref ? feature.properties?.noi_ref : '-'}
        </Descriptions.Item>
      ) : (
        <></>
      )}

      {/* Section Number - Show only on PIA DUCT */}
      {feature.id?.split('.')[0] === 'pia_duct' ? (
        <Descriptions.Item span={3} label="Section No.">
          {feature.properties?.section_number || '-'}
        </Descriptions.Item>
      ) : (
        <></>
      )}

      {/* Updated By */}
      <Descriptions.Item span={3} label="Updated By">
        {feature.properties?.modified_by
          ? feature.properties?.modified_by
          : '-'}
      </Descriptions.Item>

      {/* Updated At */}
      <Descriptions.Item span={3} label="Updated At">
        {feature.properties?.modified_at
          ? moment(feature.properties?.modified_at).format('DD/MM/YYYY')
          : '-'}
        &nbsp;
        {feature.properties?.modified_at
          ? moment(feature.properties?.modified_at).format('hh:mm A')
          : '-'}
      </Descriptions.Item>
      <Descriptions.Item span={3} label="Source">
        GIS
      </Descriptions.Item>
    </Descriptions>
  );
};

export default RenderGisFeature;
