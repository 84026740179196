import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { AppointmentView, UtilizationData } from '../types';
import {
  APPOINTMENTS_DATE_FILTER_NEXT,
  APPOINTMENTS_DATE_FILTER_PREV,
  APPOINTMENTS_DATE_FILTER_SET,
  APPOINTMENTS_SELECTED_DATE_SET,
  FETCH_EARLIER_AVAILABILITY_ERROR,
  FETCH_EARLIER_AVAILABILITY_REQUEST,
  FETCH_EARLIER_AVAILABILITY_RESPONSE,
  FETCH_ENGINEERS_ERROR,
  FETCH_ENGINEERS_REQUEST,
  FETCH_ENGINEERS_RESPONSE,
  FETCH_SCHEDULES_ERROR,
  FETCH_SCHEDULES_REQUEST,
  FETCH_SCHEDULES_RESPONSE,
  FETCH_UTILIZATION_ERROR,
  FETCH_UTILIZATION_REQUEST,
  FETCH_UTILIZATION_RESPONSE,
  FETCH_WORK_ORDERS_ERROR,
  FETCH_WORK_ORDERS_REQUEST,
  FETCH_WORK_ORDERS_RESPONSE,
  SELECT_SCHEDULE,
  SET_APPOINTMENTS_FILTER,
  SET_DETAILS_RECORD,
  SET_ENGINEERS_FILTER,
  SET_SCHEDULE_FILTER,
  UPDATE_WORK_ORDER,
} from './constants';

import {
  FetchEarlierAvailabilityErrorAction,
  FetchEarlierAvailabilityRequestAction,
  FetchEarlierAvailabilityResponseAction,
  FetchEngineersErrorAction,
  FetchEngineersRequestAction,
  FetchEngineersResponseAction,
  FetchSchedulesErrorAction,
  FetchSchedulesRequestAction,
  FetchSchedulesResponseAction,
  FetchUtilizationErrorAction,
  FetchUtilizationRequestAction,
  FetchUtilizationResponseAction,
  FetchWorkOrdersErrorAction,
  FetchWorkOrdersRequestAction,
  FetchWorkOrdersResponseAction,
  NextAppointmentsDateFilterAction,
  PreviousAppointmentsDateFilterAction,
  SelectScheduleAction,
  SetAppointmentsDateFilterAction,
  SetAppointmentSelectedDateAction,
  SetAppointmentsFilterAction,
  SetDetailsRecordAction,
  SetEngineersFilterAction,
  SetScheduleFilterAction,
  UpdateWorkOrderAction,
} from './types';

// Fetch Schedules Request (async)
export const fetchSchedulesRequest = (): FetchSchedulesRequestAction => ({
  type: FETCH_SCHEDULES_REQUEST,
  payload: undefined,
});

export const fetchSchedulesResponse = (
  payload: DbRecordEntityTransform[],
): FetchSchedulesResponseAction => ({
  type: FETCH_SCHEDULES_RESPONSE,
  payload,
});

export const fetchSchedulesError = (payload: any): FetchSchedulesErrorAction => ({
  type: FETCH_SCHEDULES_ERROR,
  payload,
});

// Sync Schedules State Updates
export const selectSchedule = (recordIds: string[]): SelectScheduleAction => ({
  type: SELECT_SCHEDULE,
  payload: recordIds,
});

export const setScheduleFilter = (filter: string): SetScheduleFilterAction => ({
  type: SET_SCHEDULE_FILTER,
  payload: filter,
});

// Fetch Work Orders (async)
export const fetchWorkOrdersRequest = (): FetchWorkOrdersRequestAction => ({
  type: FETCH_WORK_ORDERS_REQUEST,
  payload: undefined,
});

export const fetchWorkOrdersResponse = (
  payload: DbRecordEntityTransform[],
): FetchWorkOrdersResponseAction => ({
  type: FETCH_WORK_ORDERS_RESPONSE,
  payload,
});

export const fetchWorkOrdersError = (payload: any): FetchWorkOrdersErrorAction => ({
  type: FETCH_WORK_ORDERS_ERROR,
  payload,
});

// Optimistic WorkOrder Updates
export const updateWorkOrder = (
  workOrder: DbRecordEntityTransform,
  loading: boolean,
): UpdateWorkOrderAction => ({
  type: UPDATE_WORK_ORDER,
  payload: { workOrder, loading },
});

// Fetch Earlier Availability (async)
export const fetchEarlierAvailabilityRequest = (): FetchEarlierAvailabilityRequestAction => ({
  type: FETCH_EARLIER_AVAILABILITY_REQUEST,
  payload: undefined,
});

export const fetchEarlierAvailabilityResponse = (
  payload: DbRecordEntityTransform[],
): FetchEarlierAvailabilityResponseAction => ({
  type: FETCH_EARLIER_AVAILABILITY_RESPONSE,
  payload,
});

export const fetchEarlierAvailabilityError = (
  payload: any,
): FetchEarlierAvailabilityErrorAction => ({
  type: FETCH_EARLIER_AVAILABILITY_ERROR,
  payload,
});

export const setAppointmentsFilter = (payload: AppointmentView): SetAppointmentsFilterAction => ({
  type: SET_APPOINTMENTS_FILTER,
  payload,
});

export const previousDateFilter = (): PreviousAppointmentsDateFilterAction => ({
  type: APPOINTMENTS_DATE_FILTER_PREV,
  payload: undefined,
});

export const nextDateFilter = (): NextAppointmentsDateFilterAction => ({
  type: APPOINTMENTS_DATE_FILTER_NEXT,
  payload: undefined,
});

export const setDateFilter = (start: string): SetAppointmentsDateFilterAction => ({
  type: APPOINTMENTS_DATE_FILTER_SET,
  payload: start,
});

export const setSelectedDate = (date: string): SetAppointmentSelectedDateAction => ({
  type: APPOINTMENTS_SELECTED_DATE_SET,
  payload: date,
});

export const setDetailsRecord = (record?: DbRecordEntityTransform): SetDetailsRecordAction => ({
  type: SET_DETAILS_RECORD,
  payload: record,
});

// Fetch Utilization (TimeSlots) Request (async)
export const fetchUtilizationRequest = (): FetchUtilizationRequestAction => ({
  type: FETCH_UTILIZATION_REQUEST,
  payload: undefined,
});

export const fetchUtilizationResponse = (
  payload: UtilizationData[],
): FetchUtilizationResponseAction => ({
  type: FETCH_UTILIZATION_RESPONSE,
  payload,
});

export const fetchUtilizationError = (payload: any): FetchUtilizationErrorAction => ({
  type: FETCH_UTILIZATION_ERROR,
  payload,
});

// Fetch Engineers Request (async)
export const fetchEngineersRequest = (): FetchEngineersRequestAction => ({
  type: FETCH_ENGINEERS_REQUEST,
  payload: undefined,
});

export const fetchEngineersResponse = (
  payload: DbRecordEntityTransform[],
): FetchEngineersResponseAction => ({
  type: FETCH_ENGINEERS_RESPONSE,
  payload,
});

export const fetchEngineersError = (payload: { message: string }): FetchEngineersErrorAction => ({
  type: FETCH_ENGINEERS_ERROR,
  payload,
});

export const setEngineersFilter = (payload: string[]): SetEngineersFilterAction => ({
  type: SET_ENGINEERS_FILTER,
  payload,
});
