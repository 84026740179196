import { OdinSearchParams } from "./types";

const getAllParamsKeyName = (schemaId: string) => `odin_filters_${schemaId}`;
const getSelectedParamsKeyName = (schemaId: string) => `odin_current_filter_${schemaId}`;

export const loadAllParams = (schemaId: string) => {
  const allParamsString = localStorage.getItem(getAllParamsKeyName(schemaId)) || '[]';
  const allParams = JSON.parse(allParamsString) as OdinSearchParams[];
  return allParams;
};

export const loadSelectedParams = (schemaId: string) => {
  const selectedParamsString = localStorage.getItem(getSelectedParamsKeyName(schemaId)) || '{ "filters": [] }';
  const selectedParams = JSON.parse(selectedParamsString) as OdinSearchParams;
  return selectedParams;
};

export const saveSelectedParams = (schemaId: string, params: OdinSearchParams) => {
  const paramsString = JSON.stringify(params);
  localStorage.setItem(getSelectedParamsKeyName(schemaId), paramsString);
  return params;
}

export const saveParams = (schemaId: string, params: OdinSearchParams) => {
  const allParams = loadAllParams(schemaId);

  const newParams = [...allParams, params];
  const newParamsString = JSON.stringify(newParams);
  localStorage.setItem(getAllParamsKeyName(schemaId), newParamsString);
  return newParams;
};

export const deleteParams = (schemaId: string, params: OdinSearchParams) => {
  const allParams = loadAllParams(schemaId);
  const newAllParams = allParams.filter((p) => p.name !== params.name);
  const allParamsString = JSON.stringify(newAllParams);
  localStorage.setItem(getAllParamsKeyName(schemaId), allParamsString);
  return newAllParams;
  // setSavedFilters(newAllParams);
};