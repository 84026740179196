export type TDataSetApprovalStatus = 'TO_DO' | 'APPROVED' | 'REJECTED';

export enum DataSetApprovalStatusesENUM {
  TO_DO = 'Todo',
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
}

export type TDataSetTaskStatus = 'TO_DO' | 'IN_PROGRESS' | 'DONE';

export enum DataSetTaskStatusesENUM {
  TO_DO = 'Todo',
  IN_PROGRESS = 'In Progress',
  DONE = 'Done',
}
