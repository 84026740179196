import React from 'react';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { MobileOutlined, PhoneOutlined } from '@ant-design/icons';
import { Button, Col, Descriptions, Row } from 'antd';

interface Props {
  result: DbRecordEntityTransform;
  onClose: any;
  globalCollapsed: boolean;
  onNumberClick?: Function;
}

interface State {
  collapsed: boolean;
}

class ContactLayout extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { collapsed: false };
  }

  render() {
    const result = this.props.result;

    return (
      <>
        <Descriptions
          column={1}
          bordered
          labelStyle={{ color: 'black', fontWeight: 400, fontSize: 12, padding: '2px 7px' }}
          contentStyle={{ fontSize: 12, padding: '2px 7px' }}
        >
          {/* Name */}
          <Descriptions.Item label="Name">
            <span>{`${getProperty(result, 'FirstName')} ${getProperty(result, 'LastName')}`}</span>
          </Descriptions.Item>
          {/* E-mail */}
          <Descriptions.Item label="E-mail">
            <span>{getProperty(result, 'EmailAddress')}</span>
          </Descriptions.Item>
          {/* Landline */}
          {getProperty(result, 'Phone') && (
            <Descriptions.Item label="Landline">
              <span>{getProperty(result, 'Phone')}</span>
            </Descriptions.Item>
          )}
          {/* Mobile */}
          {getProperty(result, 'Mobile') && (
            <Descriptions.Item label="Mobile">
              <span>{getProperty(result, 'Mobile')}</span>
            </Descriptions.Item>
          )}
        </Descriptions>

        {this.props.onNumberClick && (
          <Row>
            <Col span={12}>
              <Button
                style={{ width: '95%' }}
                type="primary"
                icon={<PhoneOutlined />}
                onClick={() => this.props.onNumberClick?.(result.properties.Phone)}
                disabled={!result?.properties?.Phone}
              >
                Call Landline
              </Button>
            </Col>
            <Col span={12} style={{ textAlign: 'right' }}>
              <Button
                style={{ width: '95%' }}
                type="primary"
                icon={<MobileOutlined />}
                onClick={() => this.props.onNumberClick?.(result.properties.Mobile)}
                disabled={!result?.properties?.Mobile}
              >
                Call Mobile
              </Button>
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default ContactLayout;
