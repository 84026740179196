import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import RecordCard from '@legacy/core/records/components/RecordCard';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import { getFirstRelation } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  ISetOrderBuilderDrawerRecordIds,
  openOrderBuilderDrawer,
  setOrderBuilderDrawerRecordIds,
} from '@legacy/core/userInterface/store/actions';
import { Button } from '@blueprintjs/core';
import DetailView from '@core/views/DetailView';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import AddProduct from '@legacy/modules/ProductModule/AddProduct';
import OrderBuilderDrawer from '../../../OrderModule/OrderBuilder/OrderBuilderDrawer';

interface Props {
  getAssociations: (params: IGetRecordAssociations, cb?: any) => void;
  openDrawer: () => void;
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) => void;
}

const { PRODUCT_MODULE, CRM_MODULE } = SchemaModuleTypeEnums;
const { ADDRESS, CONTACT, PRODUCT, OFFER } = SchemaModuleEntityTypeEnums;

const LeadDetailView: FC<Props> = (props: Props) => {
  const { record, schema } = useContext(DetailViewContext);
  const { getAssociations, openDrawer, setOrderBuilderDrawerRecordIds } = props;
  const [isLoadingRelations, setIsLoadingRelations] = useState<boolean>(false);
  const [relatedContact, setRelatedContact] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [relatedAddress, setRelatedAddress] = useState<DbRecordEntityTransform | undefined>(
    undefined,
  );

  useEffect(() => {
    fetchAssociatedContactAndAddressRecord();
  }, [record]);

  const fetchAssociatedContactAndAddressRecord = () => {
    setIsLoadingRelations(true);
    if (schema && record) {
      getAssociations(
        {
          recordId: record?.id,
          schema: schema,
          entities: [CONTACT, ADDRESS],
        },
        (res: any) => {
          // Get associated Contact and use the first one for now
          const relatedC = getFirstRelation(res.results, CONTACT);
          if (relatedC) {
            setRelatedContact(relatedC);
          }

          // Get associated Address and use the first one for now
          const relatedA = getFirstRelation(res.results, ADDRESS);
          if (relatedA) {
            setRelatedAddress(relatedA);
          }
          setIsLoadingRelations(false);
        },
      );
    }
  };

  const startOrderBuilder = () => {
    setOrderBuilderDrawerRecordIds({
      leadId: record?.id,
    });
    openDrawer();
  };

  return (
    <>
      <OrderBuilderDrawer />
      <AddProduct />
      <DetailView
        showCollaborators
        showCommunicationHistory
        defaultTabKey="Summary"
        customTabs={[{ key: 'Summary', tab: 'Summary' }]}
        headerExtras={[<Button onClick={startOrderBuilder}>Create Order</Button>]}
        customTabBodies={{
          Summary: (
            <>
              <RecordCard
                showActionMenu
                showItemActionMenu
                record={record!}
                displayQuickView
                associatedRecordModuleName={PRODUCT_MODULE}
                associatedRecordEntityName={PRODUCT}
                visibleProperties={[
                  'Category',
                  'ContractType',
                  'CustomerType',
                  'Type',
                  'UnitPrice',
                ]}
              />
              <RecordCard
                propertyColumns={3}
                showWithoutPagination
                showActionMenu
                showItemActionMenu
                record={record!}
                displayQuickView
                associatedRecordModuleName={PRODUCT_MODULE}
                associatedRecordEntityName={OFFER}
                visibleProperties={[
                  'AvailableFrom',
                  'AvailableTo',
                  'ContractType',
                  'CustomerType',
                  'IsDefault',
                ]}
              />
              <RecordCard
                record={record!}
                showActionMenu
                showItemActionMenu
                propertyColumns={2}
                displayQuickView
                associatedRecordEntityName={'Discount'}
                associatedRecordModuleName={'ProductModule'}
                visibleProperties={[
                  'DiscountLength',
                  'DiscountType',
                  'DiscountValue',
                  'Description',
                ]}
              />
            </>
          ),
        }}
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record!}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={ADDRESS}
            visibleProperties={['Type', 'SalesStatus']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record!}
            displayQuickView
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName={CONTACT}
            visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
          />,
          <RecordCard
            title="Appointments"
            record={record}
            visibleProperties={['Date', 'Address', 'Contact']}
            associatedRecordModuleName={CRM_MODULE}
            associatedRecordEntityName="Appointment"
            displayQuickView
            showCreatedBy
            hideRecordNumber
          />,
        ]}
      />
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: () => dispatch(openOrderBuilderDrawer()),
  setOrderBuilderDrawerRecordIds: (params: ISetOrderBuilderDrawerRecordIds) =>
    dispatch(setOrderBuilderDrawerRecordIds(params)),
  getAssociations: (params: IGetRecordAssociations, cb?: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(LeadDetailView);
