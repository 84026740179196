import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, NonIdealState, Section } from '@blueprintjs/core';
import { Col, Row, Skeleton } from 'antd';
import { v4 as uuidv4 } from 'uuid';

import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import SharedFormModal, {
  FormReducerSubmitEvt,
} from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '@core/http/requests';

import { pipelineFormFields } from './formFields';
import PipelineDetails from './PipelineDetails';

interface Props {
  schema: SchemaEntity | undefined;
  schemaTypeId: string | null;
  formReducer: SharedFormReducer;
  initializeForm: any;
}

const uuid = uuidv4();

const SchemaPipelineDetailView: FC<Props> = (props: Props) => {
  const { schema, initializeForm, formReducer } = props;
  const [pipelines, setPipelines] = useState<PipelineEntity[]>([]);
  const [isLoadingPipelines, setIsLoadingPipelines] = useState<boolean>(false);
  const [isCreatingPipelines, setIsCreatingPipelines] = useState<boolean>(false);

  // On component mount, fetch pipelines
  useEffect(() => {
    if (schema) {
      fetchData();
    }
  }, [schema]);

  const fetchData = () => {
    setIsLoadingPipelines(true);
    httpGet(`SchemaModule/v1.0/pipelines/list/${schema?.moduleName}/${schema?.entityName}`).then(
      (response) => {
        setIsLoadingPipelines(false);
        const pipelines = response.data?.data || [];
        setPipelines(pipelines);

        console.log('%cDebug: Pipelines', 'color:yellow', pipelines);
      },
    );
  };

  const showCreatePipelineForm = () => {
    initializeForm({
      formUUID: uuid,
      title: 'Create Pipeline',
      showModal: true,
      formFields: pipelineFormFields(),
      entityName: 'Pipeline',
      isUpdateReq: false,
      isCreateReq: true,
      schema: schema,
    });
  };

  const handleCreatePipelineFormSubmit = (params: FormReducerSubmitEvt) => {
    if (schema && params.data && !formReducer.isUpdateReq) {
      setIsCreatingPipelines(true);
      const body: any = {
        name: params.data.name,
        key: params.data.key,
        isSequential: params.data.isSequential,
        description: params.data.description,
        moduleName: schema.moduleName,
        entityName: schema.entityName,
        schema: schema,
      };

      httpPost(`SchemaModule/v1.0/pipelines`, body).then((response: any) => {
        setIsCreatingPipelines(false);
        fetchData();
      });
    }
  };

  const renderPipelineSections = () => {
    return pipelines.map((pipeline: PipelineEntity, i: number) => {
      return (
        <PipelineDetails
          last={i === pipelines.length - 1}
          pipeline={pipeline}
          key={pipeline.id}
          schema={schema}
          disableDelete={pipelines.length === 1}
          onUpdate={() => fetchData()}
        />
      );
    });
  };

  return (
    <div className="pipeline-section">
      {/* Create/Edit Pipeline Form */}
      <SharedFormModal
        formUUID={uuid}
        onSubmitEvent={(params: FormReducerSubmitEvt) => handleCreatePipelineFormSubmit(params)}
      />

      {/* Header */}
      <Section
        title="Pipelines"
        rightElement={
          <Button
            icon="plus"
            outlined
            style={{ borderRadius: 5 }}
            text="Add"
            intent="success"
            loading={isCreatingPipelines}
            onClick={showCreatePipelineForm}
          />
        }
      >
        {/* Loading Pipelines */}
        {isLoadingPipelines && (
          <Row>
            <Col span={24} style={{ padding: '30px 0' }}>
              <Skeleton active />
            </Col>
          </Row>
        )}

        {/* No Pipelines found */}
        {!isLoadingPipelines && pipelines.length === 0 && (
          <Row>
            <Col span={24} style={{ padding: '30px 0' }}>
              <NonIdealState
                icon="search"
                title={'No Pipeline Found'}
                description="Create new pipeline for this entity"
                // action={
                //   <Button
                //     key="1"
                //     style={{ width: 'auto', borderRadius: 5 }}
                //     onClick={showCreatePipelineForm}
                //     intent="primary"
                //   >
                //     Create Pipeline
                //   </Button>
                // }
              />
            </Col>
          </Row>
        )}

        {/* Pipelines Found! */}
        {!isLoadingPipelines && pipelines.length > 0 && renderPipelineSections()}
      </Section>
    </div>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: SharedFormReducer) => dispatch(initializeSharedForm(params)),
});

export default connect(mapState, mapDispatch)(SchemaPipelineDetailView);
