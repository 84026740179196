import { all } from 'redux-saga/effects';
import userLoginSaga from '@legacy/core/identity/store/sagas';
import premiseSaga from '@legacy/modules/CrmModule/containers/Premise/store/sagas';
import schemaSaga from '@legacy/core/schemas/store/sagas';
import dbRecordSaga from '@legacy/core/records/store/sagas';
import dbRecordAssociationsSaga from '@legacy/core/recordsAssociations/store/sagas';
import auditLogsSaga from '@legacy/core/records/auditLogs/store/sagas';
import pipelineSaga from '@legacy/core/pipelines/store/sagas';
import reportSaga from '@legacy/core/reporting/store/sagas';
import emailNotificationSaga from '@legacy/core/notifications/email/store/sagas';
import appointmentSaga from '@legacy/core/appointments/store/sagas';
import serviceSaga from '@legacy/core/service/store/sagas';
import schemasColumnSaga from '@legacy/core/schemasColumns/store/sagas';
import schemasAssociationsSaga from '@legacy/core/schemasAssociations/store/sagas';
import identityUserSaga from '@legacy/core/identityUser/store/sagas';
import identityRbacRolesSaga from '@legacy/core/identityRoles/store/sagas';
import identityGroups from '@legacy/core/identityGroups/store/sagas';
import identityRbacPermission from '@legacy/core/identityPermissions/store/sagas';
import workflowSaga from '@legacy/core/workflow/store/sagas';
import workflowEngineSaga from '@legacy/core/workflowEngine/store/sagas';
import userAuditSaga from '@legacy/core/userAudit/store/sagas';
import autosplicingSaga from '../com.netomnia/modules/ProjectModule/Autosplicing/store/sagas';
import mapSaga from '@netomnia/modules/ProjectModule/Map/store/sagas';
import userOnboardingSaga from '@legacy/core/identityTemplates/sagas';

export default function * rootSaga () {
  yield all([
    userLoginSaga(),
    premiseSaga(),
    schemaSaga(),
    schemasColumnSaga(),
    schemasAssociationsSaga(),
    dbRecordSaga(),
    dbRecordAssociationsSaga(),
    pipelineSaga(),
    auditLogsSaga(),
    reportSaga(),
    emailNotificationSaga(),
    appointmentSaga(),
    serviceSaga(),
    identityUserSaga(),
    identityRbacRolesSaga(),
    identityGroups(),
    identityRbacPermission(),
    workflowSaga(),
    workflowEngineSaga(),
    userAuditSaga(),
    autosplicingSaga(),
    mapSaga(),
    userOnboardingSaga(),
  ]);
}
