import { PaperClipOutlined } from '@ant-design/icons';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Button, Card, Col, Layout, Row, Space, Spin } from 'antd';
import { ContentState, EditorState } from 'draft-js';
import htmlToDraft from 'html-to-draftjs';
import { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import DetailPanelLeft from '@legacy/core/records/components/DetailPanelLeft';
import { updateFormInput } from '@legacy/core/records/components/Forms/store/actions';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import {
  createRecordsRequest,
  ICreateRecords,
  IUpdateRecordById,
  updateRecordByIdRequest,
} from '@legacy/core/records/store/actions';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import CardWithTabs from '../CardWithTabs';
import { renderCreateUpdateDetails } from '../RecordCreateUpdateDetails';
import SlateRichText from '@legacy/components/RichEditor/SlateRichText';
import { getRecordFromShortListById } from '@core/helpers/recordHelpers';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import './styles.scss';
import ActivityCenter from '@legacy/core/records/components/ActivityCenter';
import NoteFeed from '@core/components/NoteFeed';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;

interface Props {
  schemaReducer: any;
  recordReducer: any;
  match: any;
  updateRecord: any;
}

interface State {
  isEditing: boolean;
  editorState: EditorState | null;
  currentContent: any;
  isChanged: any;
  isUploadingFile: boolean;
}

class KnowledgeArticleRecordDetailView extends Component<Props, State> {
  state = {
    isEditing: false,
    editorState: EditorState.createEmpty(),
    currentContent: {},
    isChanged: false,
    isUploadingFile: false,
  };

  toggleEdit = () => {
    this.setState({ isEditing: !this.state.isEditing }, () => {
      if (this.state.isEditing) {
        const { recordReducer, match } = this.props;
        const record = getRecordFromShortListById(recordReducer.shortList, match?.params?.recordId);
        const contentBlock = htmlToDraft(getProperty(record, 'HTMLContent') || '');
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          const editorState = EditorState.createWithContent(contentState);
          this.setState({ editorState });
        }
      } else {
        this.handleUpdateRecord();
      }
    });
  };

  handleUpdateRecord = () => {
    const { currentContent } = this.state;
    const { schemaReducer, recordReducer, match, updateRecord } = this.props;
    const record = getRecordFromShortListById(recordReducer.shortList, match?.params?.recordId);
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    record.properties['JSONContent'] = JSON.stringify(currentContent);
    updateRecord(
      {
        schema,
        recordId: record.id,
        createUpdate: record,
      },
      () => {
        this.setState({ isChanged: false });
      },
    );
  };

  onEditorStateChange = (state: EditorState) => {
    this.setState({ editorState: state });
  };

  handleChange = (value: any) => {
    this.setState({ currentContent: value, isChanged: true });
  };

  toggleFileUploadLoader = (loaderState: boolean) =>
    this.setState({ isUploadingFile: loaderState });

  getViewPath = (record: DbRecordEntityTransform) => {
    if (record) return `/${SUPPORT_MODULE}/KnowledgeArticle/${record.id}/view`;
    else return '#';
  };

  render() {
    const { isEditing, isChanged, currentContent } = this.state;
    const { schemaReducer, recordReducer, match } = this.props;
    const record = getRecordFromShortListById(recordReducer.shortList, match?.params?.recordId);
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    if (!schema) {
      return null;
    }
    let jsonContent = null;
    try {
      jsonContent = getProperty(record, 'JSONContent') || null;
    } catch (e) {
      jsonContent = null;
    }
    return (
      <Layout className="record-detail-view">
        <Row gutter={{ xs: 8, sm: 14, md: 14, lg: 14 }}>
          <Col xs={24} sm={24} md={24} lg={6}>
            <div className="record-detail-left-panel">
              <DetailPanelLeft record={record}>
                <RecordProperties columns={1} record={record} size="small" />
                {renderCreateUpdateDetails(record)}
              </DetailPanelLeft>
              <br />
              <AssociationDataTable
                title={schema.entityName}
                record={record}
                moduleName={schema.moduleName}
                entityName={schema.entityName}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12}>
            <div className="record-detail-left-panel">
              <Card
                extra={
                  <Space>
                    {!isEditing && (
                      <Link to={this.getViewPath(record!)} target="_blank">
                        <Button type="primary" ghost>
                          View
                        </Button>
                      </Link>
                    )}
                    {isEditing && (
                      <Button type="default" onClick={() => this.setState({ isEditing: false })}>
                        Cancel
                      </Button>
                    )}
                    <Button
                      type="primary"
                      onClick={this.toggleEdit}
                      disabled={!isChanged && isEditing}
                    >
                      {isEditing ? 'Save' : 'Edit'}
                    </Button>
                  </Space>
                }
              >
                <Row>
                  <Col
                    span={24}
                    style={{
                      padding: 10,
                      border: isEditing ? '1px solid #1890ff' : 'none',
                      borderRadius: 2,
                    }}
                  >
                    {this.state.isUploadingFile ? (
                      <Row>
                        <Col span={24} className="editor-loader">
                          <Row style={{ textAlign: 'center' }}>
                            <Col span={24} style={{ marginBottom: 20 }}>
                              <Spin size="large" />
                            </Col>
                            <Col span={24}>
                              <h4>Uploading Image File...</h4>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}
                    <SlateRichText
                      onChange={this.handleChange}
                      initialValue={isEditing ? currentContent : jsonContent}
                      isViewMode={!isEditing}
                      associatedRecordId={record?.id}
                      toggleFileUploadLoader={this.toggleFileUploadLoader}
                      isUploadingFile={this.state.isUploadingFile}
                      associatedRecordEntity={`${SUPPORT_MODULE}:KnowledgeArticle`}
                    />
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>

          <Col xs={24} sm={24} md={24} lg={6}>
            <div className="record-detail-right-panel">
              {/* Media Upload Guide */}
              <Alert
                style={{ marginBottom: 15, borderRadius: 7 }}
                message={<span style={{ fontWeight: 600 }}>Adding images to Articles</span>}
                description="You can upload media to your Article in two ways: 1. In Edit mode, simply drag and drop image to the Editor, or 2. In Edit mode, click on Insert image button in Toolbar and paste image URL."
                type="info"
                icon={<PaperClipOutlined />}
                showIcon
                closable
              />

              {/* Associated Files*/}
              <Card style={{ marginBottom: 15 }} title="Associated Files">
                <div>
                  <AssociationDataTable
                    title="Files"
                    record={record}
                    moduleName="SchemaModule"
                    entityName="File"
                  />
                </div>
              </Card>

              {/* Updates & Notes */}
              <CardWithTabs
                title="Updates"
                defaultTabKey="Notes"
                tabList={[
                  {
                    key: 'Notes',
                    tab: 'Notes',
                  },
                  {
                    key: 'Activity',
                    tab: 'Activity',
                  },
                ]}
                tabContents={{
                  Notes: <NoteFeed record={record} />,
                  Activity: <ActivityCenter record={record!} schema={schema!} />,
                }}
              />
            </div>
          </Col>
        </Row>
      </Layout>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
});

const mapDispatch = (dispatch: any) => ({
  createRecord: (params: ICreateRecords, cb: any) => dispatch(createRecordsRequest(params, cb)),
  updateRecord: (params: IUpdateRecordById, cb: any) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  updateFormProperties: (value: any) => dispatch(updateFormInput(value)),
});

export default withRouter(connect(mapState, mapDispatch)(KnowledgeArticleRecordDetailView));
