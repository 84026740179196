import { LogsUserAuditEntity } from '@d19n/temp-fe-d19n-models/dist/logs/audit/logs.user.audit.entity';
import { IGetUsersAuditEventsByRecord } from './actions';
import {
  CREATE_USER_AUDIT_EVENTS_ERROR,
  CREATE_USER_AUDIT_EVENTS_REQUEST,
  CREATE_USER_AUDIT_EVENTS_SUCCESS,
  GET_USERS_AUDIT_EVENTS_BY_RECORD_ERROR,
  GET_USERS_AUDIT_EVENTS_BY_RECORD_REQUEST,
  GET_USERS_AUDIT_EVENTS_BY_RECORD_SUCCESS,
} from './constants';

export interface IUserAuditReducer {
  isRequesting: boolean;
  isCreating: boolean;
  isSearching: boolean;
  lastLogged: { [type: string]: LogsUserAuditEntity };
  shortListByRecords: {
    [recordId: string]: {
      params: IGetUsersAuditEventsByRecord;
      respondedAt: any;
      events: LogsUserAuditEntity[];
    };
  };
}

export const initialState: IUserAuditReducer = {
  isRequesting: false,
  isCreating: false,
  isSearching: false,
  lastLogged: {},
  shortListByRecords: {},
};

function reducer(state = initialState, action: any): IUserAuditReducer {
  switch (action.type) {
    case GET_USERS_AUDIT_EVENTS_BY_RECORD_REQUEST: {
      return {
        ...state,
        isRequesting: true,
        isSearching: true,
        shortListByRecords: {
          ...state.shortListByRecords,
          [action.params.recordId]: undefined,
        },
      };
    }

    case GET_USERS_AUDIT_EVENTS_BY_RECORD_SUCCESS: {
      return {
        ...state,
        isRequesting: false,
        isSearching: false,
        shortListByRecords: {
          ...state.shortListByRecords,
          [action.params.recordId]: {
            params: action.params,
            respondedAt: action.results.respondedAt,
            events: action.results.data?.events,
          },
        },
      };
    }

    case GET_USERS_AUDIT_EVENTS_BY_RECORD_ERROR: {
      return {
        ...state,
        isRequesting: false,
        isSearching: false,
      };
    }

    case CREATE_USER_AUDIT_EVENTS_REQUEST: {
      // remember last events
      const newLastLogged = state.lastLogged;
      const items = action.params.items;
      if (items) {
        for (const item of items) {
          newLastLogged[item.type] = item;
        }
      }

      return {
        ...state,
        isRequesting: true,
        isCreating: true,
        lastLogged: newLastLogged,
      };
    }

    case CREATE_USER_AUDIT_EVENTS_SUCCESS: {
      // remember last events
      const newLastLogged = state.lastLogged;
      if (action.results) {
        const events: LogsUserAuditEntity[] = action.results?.data?.events;
        for (const event of events) {
          newLastLogged[event.type] = event;
        }
      }

      // update records last audit events
      const newShortListByRecords = state.shortListByRecords;
      if (action.results) {
        const events: LogsUserAuditEntity[] = action.results?.data?.events;
        for (const event of events) {
          if (event.recordId) {
            const current = newShortListByRecords[event.recordId];
            if (current?.events) {
              current.respondedAt = action.results.respondedAt;
              current.events.unshift(event);
            }
          }
        }
      }

      return {
        ...state,
        isRequesting: false,
        isCreating: false,
        lastLogged: newLastLogged,
        shortListByRecords: newShortListByRecords,
      };
    }

    case CREATE_USER_AUDIT_EVENTS_ERROR: {
      return {
        ...state,
        isRequesting: false,
        isCreating: false,
      };
    }

    default: {
      return state;
    }
  }
}

export default reducer;
