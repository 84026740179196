import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import {
  AMEND_ORDER_ERROR,
  AMEND_ORDER_REQUEST,
  AMEND_ORDER_SUCCESS,
  CREATE_ACCOUNT_MODAL_VISIBILE,
  CREATE_ORDER_FROM_ACCOUNT_DRAWER_VISIBILE,
  CREATE_ORDER_FROM_LEAD_DRAWER_VISIBILE,
  CREATE_ORDER_MODAL_VISIBILE,
  GET_ACTIVE_OFFER_SUCCESS,
  INITIALIZE_SWAP_ADDRESS_MODAL,
  ORDER_AMEND_WORKFLOW,
  UPDATE_ADDRESS_WORKFLOW_OBJECT,
  UPDATE_CONTACT_IDENTITY_WORKFLOW_OBJECT,
  UPDATE_LEAD_WORKFLOW_OBJECT,
  UPDATE_NETWORK_MANAGE_WORKFLOW_OBJECT,
  UPDATE_ORDER_WORKFLOW,
} from './constants';
import { updateObject } from '@core/helpers/reducerHelpers';

const { ORDER, ADDRESS, ACCOUNT, CONTACT_IDENTITY, LEAD, OFFER } = SchemaModuleEntityTypeEnums;

export interface WorkflowReducer {
  [ORDER]: {
    isCreateOrderVisible: boolean;
    selectedProductItems: any;
    selectedBaseProductItems: any;
    isCreateOrderFromLeadVisible: boolean;
    selectedOfferId?: string;
    isCreateOrderFromAccountVisible?: boolean;
    addressRecord: DbRecordEntityTransform | undefined;
    cppModalVisible: boolean;
    createCPP: boolean;
    AreaCode: string | undefined;
    CountryCode: string | undefined;
    SubscriberNumber: string | undefined;
    AuthorizedLOA: boolean;
  };
  AmendOrder: {
    orderId: string;
    isAmendOrderVisible: boolean;
    isRequesting: boolean;
  };
  [ADDRESS]: {
    isSwapAddressVisible: boolean;
    addressRecord: DbRecordEntityTransform | undefined;
    associatedRecords: any;
  };
  [ACCOUNT]: {
    isCreateAccountVisible: boolean;
  };
  [CONTACT_IDENTITY]: {
    isUpdateMandateModalVisible: boolean;
    records: DbRecordEntityTransform[];
    contactRecord: DbRecordEntityTransform | undefined;
  };
  [LEAD]: {
    relatedRecord: DbRecordEntityTransform | undefined;
  };
  NetworkManage: {
    networkManageDrawerVisible: boolean;
    record: DbRecordEntityTransform | undefined;
    phoneRecord?: DbRecordEntityTransform | undefined;
  };
  [OFFER]: {
    activeOffer: DbRecordEntityTransform | undefined;
  };
}

const amendOrderInitialState = {
  orderId: undefined as any,
  isAmendOrderVisible: false,
  isRequesting: false,
};

export const initialState: WorkflowReducer = {
  [ORDER]: {
    isCreateOrderVisible: false,
    selectedProductItems: [],
    selectedBaseProductItems: [],
    isCreateOrderFromLeadVisible: false,
    isCreateOrderFromAccountVisible: false,
    addressRecord: undefined,
    cppModalVisible: false,
    createCPP: false,
    AreaCode: undefined,
    CountryCode: undefined,
    SubscriberNumber: undefined,
    AuthorizedLOA: false,
  },
  AmendOrder: amendOrderInitialState,
  [ACCOUNT]: {
    isCreateAccountVisible: false,
  },
  [ADDRESS]: {
    isSwapAddressVisible: false,
    addressRecord: undefined,
    associatedRecords: [],
  },
  [CONTACT_IDENTITY]: {
    isUpdateMandateModalVisible: false,
    records: [],
    contactRecord: undefined,
  },
  [LEAD]: {
    relatedRecord: undefined,
  },
  NetworkManage: {
    networkManageDrawerVisible: false,
    record: undefined,
    phoneRecord: undefined,
  },
  [OFFER]: {
    activeOffer: undefined,
  },
};

function reducer(state = initialState, action: any) {
  switch (action.type) {
    case CREATE_ORDER_MODAL_VISIBILE: {
      return {
        ...state,
        [ORDER]: {
          ...state[ORDER],
          isCreateOrderVisible: !state[ORDER].isCreateOrderVisible,
        },
      };
    }

    case CREATE_ORDER_FROM_LEAD_DRAWER_VISIBILE: {
      return {
        ...state,
        [ORDER]: {
          ...state[ORDER],
          isCreateOrderFromLeadVisible: !state[ORDER]?.isCreateOrderFromLeadVisible,
        },
      };
    }

    case CREATE_ORDER_FROM_ACCOUNT_DRAWER_VISIBILE: {
      return {
        ...state,
        [ORDER]: {
          ...state[ORDER],
          isCreateOrderFromAccountVisible: !state[ORDER].isCreateOrderFromAccountVisible,
        },
      };
    }

    case UPDATE_ORDER_WORKFLOW: {
      return {
        ...state,
        [ORDER]: {
          ...state[ORDER],
          ...action.params,
        },
      };
    }

    case ORDER_AMEND_WORKFLOW: {
      const amendOrderState = action.params.init ? amendOrderInitialState : state.AmendOrder;
      return {
        ...state,
        AmendOrder: {
          ...amendOrderState,
          ...action.params,
        },
      };
    }

    case AMEND_ORDER_REQUEST:
      return {
        ...state,
        AmendOrder: {
          ...state.AmendOrder,
          isRequesting: true,
        },
      };

    case AMEND_ORDER_SUCCESS:
      return {
        ...state,
        AmendOrder: {
          ...state.AmendOrder,
          isRequesting: false,
        },
      };

    case AMEND_ORDER_ERROR:
      return {
        ...state,
        AmendOrder: {
          ...state.AmendOrder,
          isRequesting: false,
        },
      };

    case CREATE_ACCOUNT_MODAL_VISIBILE: {
      return {
        ...state,
        [ACCOUNT]: {
          ...state[ACCOUNT],
          isCreateAccountVisible: !state[ACCOUNT].isCreateAccountVisible,
        },
      };
    }

    case INITIALIZE_SWAP_ADDRESS_MODAL: {
      return {
        ...state,
        [ADDRESS]: {
          ...state[ADDRESS],
          isSwapAddressVisible: action.params.isSwapAddressVisible,
          addressRecord: action.params.addressRecord,
        },
      };
    }

    case UPDATE_ADDRESS_WORKFLOW_OBJECT: {
      return {
        ...state,
        [ADDRESS]: updateObject(state[ADDRESS], action.params),
      };
    }

    case UPDATE_CONTACT_IDENTITY_WORKFLOW_OBJECT: {
      return {
        ...state,
        [CONTACT_IDENTITY]: updateObject(state[CONTACT_IDENTITY], action.params),
      };
    }

    case UPDATE_NETWORK_MANAGE_WORKFLOW_OBJECT: {
      return {
        NetworkManage: {
          ...state.NetworkManage,
          ...action.params,
        },
      };
    }

    case UPDATE_LEAD_WORKFLOW_OBJECT: {
      return {
        ...state,
        [LEAD]: updateObject(state[LEAD], action.params),
      };
    }

    case GET_ACTIVE_OFFER_SUCCESS: {
      return {
        ...state,
        [OFFER]: {
          ...state[OFFER],
          activeOffer: action.results.data,
        },
      };
    }

    default:
      return state;
  }
}

export default reducer;
