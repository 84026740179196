export enum eventTypesFmtENUM {
  'DB_RECORD_CREATED' = 'created record',
  'DB_RECORD_UPDATED' = 'updated record',
  'DB_RECORD_DELETED' = 'deleted record',
  'DB_RECORD_RESTORED' = 'restored record',
  'DB_RECORD_MERGED' = 'merged record',
  'DB_RECORD_STAGE_UPDATED' = 'updated stage',
  'DB_RECORD_ASSOCIATION_CREATED' = 'created relation',
  'DB_RECORD_ASSOCIATION_UPDATED' = 'updated relation ',
  'DB_RECORD_ASSOCIATION_DELETED' = 'deleted relation ',
  'DB_RECORD_ASSOCIATION_RESTORED' = 'restored relation ',
  'DB_RECORD_ASSOCIATION_TRANSFERRED' = 'transferred relation ',
  'DB_RECORD_GROUP_ACCESS_MODIFIED' = 'modified group access',
  'ORDER_AMENDMENT' = 're-contracted order',
  'WORKFLOW_EXECUTED' = 'executed workflow',
}
