import { Col, List, Result, Row } from 'antd';

const OVERRIDE_QC: boolean = false;

/**
 *  Remove all nodes with on-hold status and edges where cable is on-hold.
 * @param edges
 */
export const removeOnHoldNodesAndEdges = (
  edges: Array<any>,
  nodes: Array<any>,
) => {
  let filteredEdges: Array<any> = [];
  let filteredNodes: Array<any> = [];

  if (edges?.length > 0 && nodes.length > 0) {
    filteredEdges = edges.filter((edge: any) => !edge.sourceOnHold);
    filteredNodes = nodes.filter((node: any) => !node.isOnHold);
  }

  return {
    edges: filteredEdges,
    nodes: filteredNodes,
  };
};

/**
 * Return an array of error messages if there are connections where cable and closure have mixed on-hold status.
 * @param edges
 */
export const returnMixedOnHoldErrorMessages = (edges: Array<any>) => {
  let errorMessages: Array<string> = [];

  if (edges?.length > 0) {
    edges.map((edge: any) => {
      if (edge.cableOnHold && !edge.targetOnHold) {
        errorMessages.push(
          `Mixed on-hold state: Set ${edge.targetClosureType} closure ${edge.target} to on-hold, or remove ${edge.type} cable ${edge.id} from on-hold.`,
        );
      } else if (!edge.cableOnHold && edge.targetOnHold) {
        errorMessages.push(
          `Mixed on-hold state: Remove ${edge.targetClosureType} closure ${edge.target} from on-hold, or set ${edge.type} cable ${edge.id} to on-hold.`,
        );
      }
    });
  }

  return OVERRIDE_QC ? [] : errorMessages;
};

/**
 * Render Error panel for design errors
 * @param errorMessages
 */
export const renderBuildPackDesignErrorPanel = (
  errorMessages: Array<string>,
) => {
  if (errorMessages?.length > 0) {
    return (
      <Row
        id="buildpack_map"
        style={{
          backgroundColor: 'white',
          padding: 25,
          textAlign: 'center',
          borderRadius: 8,
        }}
      >
        <Col span={24}>
          <Result
            status="warning"
            title="There are errors with the Build pack."
            extra={
              <List
                bordered
                dataSource={errorMessages}
                renderItem={(item) => <List.Item>{item}</List.Item>}
              />
            }
          />
        </Col>
      </Row>
    );
  } else {
    return <></>;
  }
};
