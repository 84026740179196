import { Button, InputGroup, Section } from '@blueprintjs/core';
import { Cell, Column, RenderMode, SelectionModes, Table2 } from '@blueprintjs/table';
import { Col, Row, Space } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { PageHeader } from '../../../../../components/PageHeader';
import FormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { httpGet, httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { searchString } from '@core/helpers/searchHelpers';
import { BlueprintNavigation } from '@core/components/BlueprintPagination';
import { formFields } from '../FormFields';
import RegionDetails from '../RegionDetails';
import { getRecordIdFromHash } from '../../../utils/hashNavigation';
import { getErrorMessage } from '../../../utils/errors';

interface Props {
  initializeForm: any;
  formReducer: SharedFormReducer;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface ITableData {
  key: string;
  name: string;
  description: string;
}

const uuid = uuidv4();

const RegionsListView: FC<Props> = (props: Props) => {
  const { formReducer, initializeForm, alertMessage } = props;
  const [searchKey, setSearchKey] = useState<string>('');
  const [tableWidth, setTableWidth] = useState<number>(1);
  const [selectedTableRegions, setSelectedTableRegions] = useState<any[]>([]);
  const [selectedRegion, setSelectedRegion] = useState<any>(undefined);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(33);
  const [regionList, setRegionList] = useState<any[]>([]);
  const [tableSlice, setTableSlice] = useState<ITableData[]>([]);
  const [isRegionLoading, setIsRegionLoading] = useState<boolean>(false);

  // create ref for html element
  const tableRef = React.createRef<any>();

  useEffect(() => {
    const id = getRecordIdFromHash(window.location.hash);
    if (id) getRegionById(id);
  }, [window.location.hash]);

  useEffect(() => {
    getAllRegionsList();
  }, []);

  useEffect(() => {
    setSelectedTableRegions([]);
    setSelectedRegion(undefined);
  }, [currentPage]);

  const onSelect = (e: any) => {
    setSelectedTableRegions([
      {
        cols: [0, 1],
        rows: [e[0].rows[0], e[0].rows[0]],
      },
    ]);
  };

  useEffect(() => {
    if (regionList.length > 0) {
      let tableData: ITableData[] = Object.assign([], regionList);

      tableData = regionList.map((t: any) => ({
        key: t.id,
        name: t.name,
        description: t.description,
      }));

      // Apply search
      tableData = tableData.filter((t: any) => {
        return searchString(t.name, searchKey) || searchString(t.description, searchKey);
      });

      // Apply pagination
      const start = currentPage * pageSize - pageSize;
      const end = start + pageSize - 1;
      if (!searchKey) {
        tableData = tableData.slice(start, end);
      }

      setTableSlice(tableData);
    }
  }, [regionList, currentPage, searchKey, pageSize]);

  // Get selected region details
  useEffect(() => {
    if (selectedTableRegions.length > 0 && tableSlice.length > 0) {
      const user: any = tableSlice[selectedTableRegions[0].rows[0]];
      if (user && !isRegionLoading) {
        getRegionById(user.key);
      }
    }
  }, [selectedTableRegions, tableSlice]);

  const getRegionById = (id: string) => {
    setIsRegionLoading(true);
    httpGet(`IdentityModule/v2.0/regions/${id}`)
      .then((res) => {
        setIsRegionLoading(false);
        setSelectedRegion(res.data.data);
        console.log('%cdebug: Selected region', 'color:limegreen', res.data.data);
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not retrieve region. ' + message,
          type: 'error',
        });
        setIsRegionLoading(false);
        setSelectedRegion(undefined);
        setSelectedTableRegions([]);
      });
  };

  const getAllRegionsList = async () => {
    try {
      const res = await httpGet(`IdentityModule/v2.0/regions?size=10000`);
      const regions: any[] = res.data.data || [];
      setRegionList(regions);
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({ body: 'Could not retrieve region list. ' + message, type: 'error' });
      setRegionList([]);
    }
  };

  // Table Width Calculation
  const getColumnWidthByPercentage = (percentage: number): number => {
    return (percentage / 100) * tableWidth;
  };

  const updateWidth = () => {
    if (tableRef.current) {
      const width = tableRef.current.scrollContainerElement?.clientWidth;
      setTableWidth(width);
    }
  };
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);
    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [selectedRegion, tableRef, tableSlice]);

  const showCreateRegionForm = () => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Region',
      formFields: formFields,
      entityName: 'Region',
    });
  };

  const handleFormSubmit = (params: FormReducerSubmitEvt) => {
    if (params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        description: params.data.description,
      };
      createRegion({ body });
    }
  };

  const createRegion = async (params: { body: any }) => {
    const { body } = params;

    try {
      const res = await httpPost('IdentityModule/v2.0/regions', body);
      const newRegion = res.data.data;
      setRegionList([...regionList, newRegion]);

      getRegionById(newRegion.id);
      alertMessage({ body: 'Region Created', type: 'success' });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create region. ' + message,
        type: 'error',
      });
    }
  };

  const onSearch = (e: any) => {
    setSelectedTableRegions([]);
    setSearchKey(e.target.value);
  };

  const onRegionUpdate = () => {
    if (selectedRegion) {
      getRegionById(selectedRegion.id);
    }
  };

  const onRegionDelete = () => {
    setSelectedTableRegions([]);

    const id = selectedRegion?.id;
    if (id) {
      setRegionList(regionList.filter((r) => r.id !== id));
      setSelectedRegion(undefined);
    }
  };

  const renderRegions = () => {
    return (
      <>
        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => handleFormSubmit(params)}
        />
        <PageHeader
          className="page-tool-bar"
          style={{ background: 'white', padding: 0, margin: 0 }}
          ghost
        >
          <Row style={{ marginBottom: 15, marginTop: 5 }} justify="end">
            <Col span={4}>
              <h2 style={{margin:0}}>Regions</h2>
            </Col>
            <Col span={20} style={{ textAlign: 'right' }}>
              <Space>
                <InputGroup
                  type="search"
                  placeholder="Search Regions"
                  intent={searchKey.length > 0 ? 'primary' : 'none'}
                  onChange={onSearch}
                  leftIcon="search"
                  style={{ width: isMobile ? '100%' : 220 }}
                />
                <Button icon="plus" intent="success" onClick={showCreateRegionForm}>
                  Create Region
                </Button>
              </Space>
            </Col>
          </Row>
        </PageHeader>

        {/* Row with dynamically calculated height */}
        <Row className="listViewContainer">
          <Col span={selectedRegion ? 17 : 24} style={{ height: '100%', width: 0, padding: 1 }}>
            {/* Table */}
            <Table2
              ref={tableRef}
              numRows={tableSlice.length}
              defaultRowHeight={30}
              onSelection={onSelect}
              selectedRegions={selectedTableRegions}
              enableMultipleSelection={false}
              enableRowHeader={false}
              renderMode={RenderMode.NONE}
              forceRerenderOnSelectionChange={false}
              cellRendererDependencies={[tableWidth, currentPage, tableSlice]}
              selectionModes={SelectionModes.ROWS_AND_CELLS}
              columnWidths={[getColumnWidthByPercentage(30), getColumnWidthByPercentage(70)]}
            >
              <Column
                key="name"
                name="Name"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].name}</Cell>
                )}
              />
              <Column
                key="description"
                name="Description"
                cellRenderer={(rowIndex: number) => (
                  <Cell key={tableSlice[rowIndex].key}>{tableSlice[rowIndex].description}</Cell>
                )}
              />
            </Table2>
          </Col>

          {/* Region Details */}
          {selectedRegion && (
            <Col
              className="listViewDetailsColumn"
              span={7}
              style={{ opacity: isRegionLoading ? 0.6 : 1 }}
            >
              <Section
                compact
                title="Region Details"
                rightElement={
                  <Button
                    icon="cross"
                    small
                    minimal
                    onClick={() => {
                      setSelectedTableRegions([]);
                      setSelectedRegion(undefined);
                    }}
                  />
                }
              >
                <RegionDetails
                  region={selectedRegion}
                  onUpdate={onRegionUpdate}
                  onDelete={onRegionDelete}
                />
              </Section>
            </Col>
          )}
        </Row>

        {/* Pagination */}
        <Row style={{ background: 'white' }}>
          <div style={{ padding: '10px 0' }}>
            <BlueprintNavigation
              totalCount={regionList.length}
              currentPage={currentPage}
              pageSize={pageSize}
              onPaginate={setCurrentPage}
              disabled={searchKey.length > 0}
            />
          </div>
        </Row>
      </>
    );
  };

  return <div style={{ background: 'white', padding: '0 15px' }}>{renderRegions()}</div>;
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(RegionsListView);
