import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import OdinTable from '@core/components/OdinTable';
import { Button } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '@legacy/core/userInterface/store/actions';
import { connect } from 'react-redux';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { Link } from 'react-router-dom';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  paymentMethods: DbRecordEntityTransform[];
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const ContactAccountPaymentMethodTable: React.FC<Props> = (props: Props) => {
  const { paymentMethods, openDrawer } = props;

  const tablePaymentMethods = paymentMethods.map((pm: DbRecordEntityTransform) => ({
    key: pm.id,
    title: (
      <Link key={pm.id} to={getBrowserPath(pm)} target="_blank">
        {pm.title}
      </Link>
    ),
    status: getProperty(pm, 'Status'),
    action: (
      <Button
        key={pm.id}
        onClick={() =>
          openDrawer({
            recordId: pm?.id || '',
            moduleName: 'BillingModule',
            entityName: 'PaymentMethod',
          })
        }
        small
        icon={<i className="bi bi-eye" />}
        minimal
        intent="primary"
      />
    ),
  }));

  return (
    <Row>
      <Col span={24}>
        <OdinTable
          showEmpty
          height="auto"
          emptyDescription="No Payment Methods found"
          data={tablePaymentMethods}
          columns={[
            {
              key: 'title',
              title: 'Title',
              width: 14,
            },
            {
              key: 'status',
              title: 'Status',
              align: 'center',
              width: 8,
            },
            {
              key: 'action',
              title: '',
              width: 2,
              align: 'right',
            },
          ]}
        />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(ContactAccountPaymentMethodTable);
