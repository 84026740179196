import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Alert, Button, Menu, MenuItem, Popover, Tooltip } from '@blueprintjs/core';
import '../index';
import { isSystemAdmin } from '../../../helpers/rbacRules';
import { isNoteOlderThanSevenDays, isUserAuthorOfNote, removeReactionOnNote, updateReactionsOnNote } from '../helpers';
import NoteEditor from '../NoteEditor';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Avatar, Col, Row } from 'antd';
import Typography from '../../Typography';
import { parseDateForNoteFeed } from '../../../helpers/dateHelpers';
import './styles.scss';
import {
  deleteRecordByIdRequest,
  IDeleteRecordById,
  updateRecordByIdRequest,
} from '@legacy/core/records/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '../../../helpers/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import relativeTime from 'dayjs/plugin/relativeTime';

import NoteAttachments from '../NoteAttachments';
import dayjs from 'dayjs';
import { getInitialsFromName, getPastelColorForUsername } from '../../../helpers/UIHelpers';
import { NoteFeedContext } from '../index';
import NoteReactionsList from '../NoteReactionsList';
import NoteReactionsPicker from '../NoteReactionsPicker';
import { isMobile } from 'react-device-detect';

dayjs.extend(relativeTime);

interface Props {
  noteRecord: DbRecordEntityTransform;
  noteAttachments: DbRecordEntityTransform[];
  schemaReducer: any;
  deleteRecord: (payload: IDeleteRecordById, cb: any) => void;
  updateRecord: (params: any, cb: any) => void;
  userReducer: any;
}

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const { NOTE, FILE } = SchemaModuleEntityTypeEnums;

const ReplyBody: React.FC<Props> = (props: Props) => {
  const { noteRecord, noteAttachments, deleteRecord, updateRecord, schemaReducer, userReducer } =
    props;
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isReplying, setIsReplying] = useState<boolean>(false);
  const [updatedReplyBody, setUpdatedReplyBody] = useState<any>(undefined);
  const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [attachedFileIds, setAttachedFileIds] = useState<string[]>([]);

  const { updateNote, deleteNote, updateReaction } = useContext(NoteFeedContext);

  const noteSchema = getSchemaFromShortListByModuleAndEntity(
    schemaReducer.shortList,
    SUPPORT_MODULE,
    NOTE,
  );

  const canUserEditNote = () => {
    if (isSystemAdmin(userReducer)) {
      return true;
    } else {
      return isUserAuthorOfNote(noteRecord, userReducer) && isNoteOlderThanSevenDays(noteRecord);
    }
  };

  useEffect(() => {
    setAttachedFileIds(noteAttachments.map((file: any) => file.id));
  }, []);

  useEffect(() => {
    setAttachedFileIds(noteAttachments.map((file: any) => file.id));
  }, [noteAttachments]);

  const updateNoteRequest = () => {
    if (noteSchema) {
      setIsSaving(true);

      return updateRecord(
        {
          schema: noteSchema,
          recordId: noteRecord?.id,
          schemaAssociation: undefined,
          createUpdate: {
            entity: `${SUPPORT_MODULE}:${NOTE}`,
            properties: {
              JSONContent: updatedReplyBody,
            },
          },
        },
        () => {
          // Fetch relations and close the editor
          updateNote(noteRecord.id, updatedReplyBody);
          setIsSaving(false);
          setIsEditing(false);
          setUpdatedReplyBody(undefined);
        },
      );
    }
  };

  const deleteNoteRequest = () => {
    if (noteSchema) {
      setIsDeleting(true);
      deleteRecord(
        {
          schema: noteSchema,
          recordId: noteRecord?.id,
        },
        () => {
          setIsDeleting(false);
          deleteNote(noteRecord.id);
          setIsDeleteDialogVisible(false);
        },
      );
    }
  };

  const removeReactionRequest = () => {
    if (noteSchema) {
      const newReactions = removeReactionOnNote(
        noteRecord.properties.Reactions,
        userReducer.user?.id,
      );

      return updateRecord(
        {
          schema: noteSchema,
          recordId: noteRecord.id,
          schemaAssociation: undefined,
          createUpdate: {
            entity: `${SUPPORT_MODULE}:${NOTE}`,
            properties: {
              Reactions: newReactions,
            },
          },
        },
        () => {
          updateReaction(noteRecord.id, newReactions);
          setIsSaving(false);
          setIsEditing(false);
          setUpdatedReplyBody(undefined);
        },
      );
    }
  };

  const addReactionRequest = (emojiId: string, userId: string) => {
    if (noteSchema) {
      const newReactions = updateReactionsOnNote(noteRecord.properties.Reactions, emojiId, userId);
      return updateRecord(
        {
          schema: noteSchema,
          recordId: noteRecord.id,
          schemaAssociation: undefined,
          createUpdate: {
            entity: `${SUPPORT_MODULE}:${NOTE}`,
            properties: {
              Reactions: newReactions,
            },
          },
        },
        () => {
          updateReaction(noteRecord.id, newReactions);
          setIsSaving(false);
          setIsEditing(false);
          setUpdatedReplyBody(undefined);
        },
      );
    }
  };

  const menuItems = () => {
    return (
      <Menu>
        <Tooltip
          fill
          hoverOpenDelay={800}
          disabled={!isNoteOlderThanSevenDays(noteRecord)}
          content="Only notes older than 7 days can be deleted."
        >
          <MenuItem
            icon="trash"
            intent="danger"
            text={'Delete Note'}
            key="menuItemDelete"
            disabled={!canUserEditNote() || isReplying}
            onClick={() => {
              setIsDeleteDialogVisible(true);
            }}
          />
        </Tooltip>
      </Menu>
    );
  };

  const onReactionAdded = (emojiId: string) => {
    const userId = userReducer.user?.id;
    addReactionRequest(emojiId, userId);
  };

  return (
    <>
      <div
        key={`${noteRecord?.id}ReplyBodySection`}
        className={`replyBodySection ${isEditing ? 'isEditing' : ''}`}
        style={{ borderRadius: 8 }}
      >
        <Row>
          {isEditing ? (
            <Col span={24} style={{ marginTop: 10, padding: 10, textAlign: 'left' }}>
              <NoteEditor
                onChange={(newValue: string) => setUpdatedReplyBody(newValue)}
                value={getProperty(noteRecord, 'JSONContent') || []}
                isViewMode={false}
              />
            </Col>
          ) : (
            <>
              <Col
                span={24}
                style={{
                  textAlign: 'left',
                  padding: '2px 0px',
                  borderRadius: 4,
                  marginTop: 5,
                  marginBottom: noteAttachments.length > 0 ? 0 : 8,
                }}
              >
                <Row>
                  <Col span={24}>
                    {!isEditing && (
                      <Row align="middle" style={{ marginBottom: 10 }}>
                        <Col span={16} style={{ textAlign: 'left' }}>
                          <Avatar
                            size="small"
                            style={{
                              width: 20,
                              height: 20,
                              fontSize: 16,
                              marginRight: 7,
                              lineHeight: '19px',
                              backgroundColor: getPastelColorForUsername(
                                noteRecord?.lastModifiedBy?.fullName || 'Unknown',
                              ),
                            }}
                          >
                            {getInitialsFromName(noteRecord?.lastModifiedBy?.fullName || '')}
                          </Avatar>
                          <Typography size="default" style={{ fontWeight: 500, marginRight: 5 }}>
                            {noteRecord?.lastModifiedBy?.fullName || ''} replied
                          </Typography>
                          {!isMobile && (
                            <Typography size="small" style={{ opacity: 0.5 }}>
                              <Tooltip
                                content={parseDateForNoteFeed(noteRecord.createdAt as any)}
                                hoverOpenDelay={1200}
                                position="top"
                              >
                                <span style={{ cursor: 'pointer' }}>
                                  {dayjs(noteRecord.createdAt).fromNow()}
                                </span>
                              </Tooltip>
                            </Typography>
                          )}
                        </Col>
                        <Col span={8} style={{ textAlign: 'right' }} className="replyBodyToolbar">
                          <Button
                            minimal
                            icon="edit"
                            disabled={!canUserEditNote() || isReplying}
                            onClick={() => setIsEditing(true)}
                          />
                          <Popover content={menuItems()} placement="bottom">
                            <Button small alignText="left" rightIcon="more" minimal />
                          </Popover>
                        </Col>
                      </Row>
                    )}
                  </Col>
                  <Col span={24}>
                    <NoteEditor
                      isViewMode={true}
                      value={getProperty(noteRecord, 'JSONContent') || []}
                    />
                  </Col>
                  <Col span={24}>
                    {/* Attachments in view mode */}
                    {!isEditing && attachedFileIds.length > 0 && (
                      <Col span={24} style={{ textAlign: 'left', marginTop: 5 }}>
                        <NoteAttachments
                          parentNote={noteRecord}
                          viewMode={true}
                          fileIds={attachedFileIds}
                          key={attachedFileIds.length}
                        />
                      </Col>
                    )}
                  </Col>
                </Row>

                {/* Reactions */}
                <Row align="middle" justify="end">
                  <NoteReactionsList
                    noteRecord={noteRecord}
                    key={noteRecord.id}
                    reactions={noteRecord.properties.Reactions}
                    onReactionRemoved={removeReactionRequest}
                  />
                  <NoteReactionsPicker
                    key={noteRecord.properties.Reactions}
                    disabled={isReplying}
                    noteRecord={noteRecord}
                    onReactionAdded={onReactionAdded}
                  />
                </Row>
              </Col>
            </>
          )}

          {/* Attachments in edit mode */}
          {isEditing && (
            <Col span={24} style={{ textAlign: 'left', padding: '0 10px' }}>
              <NoteAttachments parentNote={noteRecord} viewMode={false} fileIds={attachedFileIds} />
            </Col>
          )}

          {isEditing && (
            <Col span={24} style={{ textAlign: 'right', padding: 10 }}>
              <Button
                text="Cancel"
                disabled={isSaving}
                style={{ marginRight: 8 }}
                onClick={() => {
                  setIsEditing(false);
                }}
              />
              <Button
                intent="primary"
                text="Save"
                disabled={isSaving}
                onClick={updateNoteRequest}
                loading={isSaving}
              />
            </Col>
          )}
        </Row>
      </div>
      <Alert
        intent="danger"
        onCancel={() => setIsDeleteDialogVisible(false)}
        isOpen={isDeleteDialogVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        canEscapeKeyCancel={!isDeleting}
        canOutsideClickCancel={!isDeleting}
        onConfirm={deleteNoteRequest}
        loading={isDeleting}
      >
        <p>Are you sure you want to delete this note?</p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  deleteRecord: (payload: IDeleteRecordById, cb: any) =>
    dispatch(deleteRecordByIdRequest(payload, cb)),
  updateRecord: (params: any, cb: any) => dispatch(updateRecordByIdRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(ReplyBody);
