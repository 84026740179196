import React from 'react';

const SendMessageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{
        color: 'hsl(215.4 16.3% 46.9%)',
      }}
    >
      <path d="m3 3 3 9-3 9 19-9Z"></path>
      <path d="M6 12h16"></path>
    </svg>
  );
};

export default SendMessageIcon;
