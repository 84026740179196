export const UPDATE_MAP_STATE = 'UPDATE_MAP_STATE';
export const UPDATE_MAP_COORDINATES_STATE = 'UPDATE_MAP_COORDINATES_STATE';
export const SET_MAP_SEARCH_QUERY = 'SET_MAP_SEARCH_QUERY';
export const RESET_MAP_SEARCH_QUERY = 'RESET_MAP_SEARCH_QUERY';
export const SET_WORK_ITEM_QUICKVIEW = 'SET_WORK_ITEM_QUICKVIEW';
export const RESET_WORK_ITEM_FORM = 'RESET_WORK_ITEM_FORM';
export const UPDATE_WORK_ITEMS = 'UPDATE_WORK_ITEMS';

export const RUN_WFS_QUERY_REQUEST = 'RUN_WFS_QUERY_REQUEST';
export const RUN_WFS_QUERY_SUCCESS = 'RUN_WFS_QUERY_SUCCESS';
export const RUN_WFS_QUERY_ERROR = 'RUN_WFS_QUERY_ERROR';

export const SEARCH_BY_POSTCODE_REQUEST = 'SEARCH_BY_POSTCODE_REQUEST';
export const SEARCH_BY_POSTCODE_SUCCESS = 'SEARCH_BY_POSTCODE_SUCCESS';
export const SEARCH_BY_POSTCODE_ERROR = 'SEARCH_BY_POSTCODE_ERROR';

export const SEARCH_BY_NOI_REF_REQUEST = 'SEARCH_BY_NOI_REF_REQUEST';
export const SEARCH_BY_NOI_REF_SUCCESS = 'SEARCH_BY_NOI_REF_SUCCESS';
export const SEARCH_BY_NOI_REF_ERROR = 'SEARCH_BY_NOI_REF_ERROR';
