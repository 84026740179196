import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import React from 'react';
import { connect } from 'react-redux';
import { Select } from 'antd';
interface Props {
  initialValue: any;
  onChange: (value: any) => void;
  formReducer: any;
}

const PipelineStageSelect: React.FC<Props> = (props: Props) => {
  const { formReducer, onChange, initialValue } = props;
  const { selected } = formReducer;
  const pipeline = selected;

  // Filter out current stage
  let filteredStages: PipelineStageEntity[] = Object.assign([], pipeline?.stages);
  const updatedStage = formReducer.formFields.find((field: any) => field.property === 'name');

  if (updatedStage) {
    filteredStages = filteredStages.filter(
      (stage: PipelineStageEntity) => stage.name !== updatedStage.value,
    );
  }

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Select Stages"
      disabled={!pipeline?.stages?.length || !filteredStages?.length}
      maxTagCount={3}
      maxTagTextLength={13}
      allowClear
      mode="multiple"
      onChange={onChange}
      defaultValue={initialValue || []}
    >
      {pipeline &&
        filteredStages?.map((stage: PipelineStageEntity) => (
          <Select.Option key={stage.id} value={stage.id}>
            {stage.name}
          </Select.Option>
        ))}
    </Select>
  );
};

const mapState = (state: any) => ({
  formReducer: state.formReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(PipelineStageSelect);
