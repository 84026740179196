import { AFPApprovalState } from '../../../types';

import { LoadAFPActions } from '../actions/afp';
import { LoadAllFpesActions } from '../actions/allFpes';
import { LoadFeatureActions } from '../actions/feature';
import { FPEFilterActions } from '../actions/fpeSelection/filters';
import { FPESelectionActions } from '../actions/fpeSelection/selection';
import { fpeSelectionReducer } from './fpeSelectionReducer';

import { recordReducer } from './recordReducer';

export type AFPApprovalActions =
  | LoadAFPActions
  | LoadAllFpesActions
  | LoadFeatureActions
  | FPEFilterActions
  | FPESelectionActions;

export const AFPApprovalReducer = (
  state: AFPApprovalState,
  action: AFPApprovalActions,
): AFPApprovalState => {
  switch (action?.type) {
    case 'AFP_APPROVAL_LOAD_AFP_REQUEST':
    case 'AFP_APPROVAL_LOAD_AFP_RESPONSE':
    case 'AFP_APPROVAL_LOAD_AFP_ERROR':
    case 'AFP_APPROVAL_LOAD_ALL_FPES_REQUEST':
    case 'AFP_APPROVAL_LOAD_ALL_FPES_RESPONSE':
    case 'AFP_APPROVAL_LOAD_ALL_FPES_ERROR':
    case 'AFP_APPROVAL_LOAD_FEATURE_REQUEST':
    case 'AFP_APPROVAL_LOAD_FEATURE_RESPONSE':
    case 'AFP_APPROVAL_LOAD_FEATURE_ERROR':
      return {
        ...state,
        records: recordReducer(state.records, action),
      };

    case 'FPE_SELECTION_STATUS_FILTER_SET':
    case 'FPE_SELECTION_TEXT_FILTER_SET':
    case 'FPE_SELECTION_FEATURE_TYPE_FILTER_SET':
    case 'FPE_SELECTION_SELECT_ALL_SET':
    case 'FPE_SELECTION_SELECTED_RECORDS_SET':
      return {
        ...state,
        fpeSelection: fpeSelectionReducer(state.fpeSelection, action),
      };

    default:
      return state;
  }
};
