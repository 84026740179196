import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Layout } from 'antd';
import { FC, useContext } from 'react';
import RecordCard from '@legacy/core/records/components/RecordCard';
import { DetailViewContext } from '../../../../../core/components/DetailViewContextProvider';
import DetailView from '../../../../../core/views/DetailView';

const FeaturePriceEntryDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <Layout>
      <DetailView
        showCollaborators
        leftColumn={[
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={SchemaModuleEntityTypeEnums.FEATURE}
            visibleProperties={['ProjectType', 'ProjectId']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={'ApplicationForPayment'}
            visibleProperties={['ClosingDate', 'ExchangeName', 'Contractor', 'DataRecordSource']}
          />,
          <RecordCard
            showActionMenu
            showItemActionMenu
            record={record}
            displayQuickView
            associatedRecordModuleName={SchemaModuleTypeEnums.PROJECT_MODULE}
            associatedRecordEntityName={'PaymentNotice'}
            visibleProperties={[]}
          />,
        ]}
      />
    </Layout>
  );
};

export default FeaturePriceEntryDetailView;
