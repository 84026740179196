import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { httpGet } from '../../http/requests';
import { ISearchRecordAssociations } from '@legacy/core/recordsAssociations/store/actions';

export const lookupSearchById = async (
  schema: SchemaEntity,
  parentRecordId: string | undefined,
  associatedRecordId: string,
): Promise<DbRecordEntityTransform[]> => {
  return httpGet(
    `${schema.moduleName}/v1.0/db/${schema.entityName}/search?terms=*&schemas=${schema?.id}&page=0&size=50&recordId=${parentRecordId}&boolean={%22must%22:[{%22match%22:{%22id%22:%22${associatedRecordId}%22}}]}&sort=[{%22createdAt%22:{%22order%22:%22desc%22}}]`,
  )
    .then((res: any) => {
      return res?.data?.data || [];
    })
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};

export const lookupSearchByTerm = async (params: ISearchRecordAssociations) => {
  const { recordId, schema, schemaAssociation, searchQuery } = params;
  const { terms, schemas, sort, pageable, boolean } = searchQuery;

  const pageNum = !!pageable && !!pageable.page ? Number(pageable.page) - 1 : 0;
  const sizeNum = !!pageable && !!pageable.size ? Number(pageable.size) : 50;

  let path = `${schema.moduleName}/v1.0/db/${schema.entityName}/search?`;
  path = path + `terms=${terms || '*'}`;
  path = path + `&schemas=${schemas}`;
  path = path + `&page=${pageNum}`;
  path = path + `&size=${sizeNum}`;
  if (!!schemaAssociation.findInSchema) {
    path = path + `&findInSchema=${schemaAssociation.findInSchema}`;
  }
  if (!!schemaAssociation.findInChildSchema) {
    path = path + `&findInChildSchema=${schemaAssociation.findInChildSchema}`;
  }
  if (recordId) {
    path = path + `&recordId=${recordId}`;
  }
  if (boolean) {
    path = path + `&boolean=${JSON.stringify(boolean)}`;
  }
  path = path + `&sort=${JSON.stringify(sort)}`;

  return httpGet(`${path}`)
    .then((res: any) => {
      return res?.data?.data || [];
    })
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};
