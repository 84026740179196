export const getHostName = () => {
  let host: string = window.location.host;

  if (host.indexOf('localhost') === -1) {
    if (host.indexOf('app') > -1) {
      host = `https://${host.replace('app', 'api')}`;
    } else {
      host = `https://api.${host}`;
    }
  } else {
    host = import.meta.env.VITE_ODIN_API_URL;
  }

  return host;
};

export const getOrganizationName = () => {
  const hostName = window.location.host;

  if (hostName && hostName.indexOf('youfibre') > -1) {
    return 'YouFibre';
  } else if (hostName && hostName.indexOf('netomnia') > -1) {
    return 'Netomnia';
  } else if (hostName && hostName.indexOf('netx') > -1) {
    return 'NetX';
  } else if (hostName && hostName.indexOf('sandbox') > -1) {
    return 'Sandbox';
  } else {
    return 'Development';
  }
};

export const isDevelopmentOrSandbox = () => {
  const hostName = window.location.host;
  return hostName?.indexOf('localhost') > -1 || hostName?.indexOf('sandbox') > -1;
};
