import { Alert, Button, InputGroup, Section, Tooltip } from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaColumnTypes } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row, Space, Table } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { getSchemaByIdRequest } from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import {
  CreateSchemaColumn,
  createSchemaColumnRequest,
  DeleteSchemaColumn,
  deleteSchemaColumnRequest,
  GetSchemaColumnById,
  getSchemaColumnByIdRequest,
} from '@legacy/core/schemasColumns/store/actions';
import FormModal, {
  FormReducerSubmitEvt,
} from '@legacy/components/SharedForm/SharedFormModal';
import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { SharedFormReducer } from '@legacy/components/SharedForm/store/reducer';
import { httpDelete } from '@core/http/requests';
import EditableSchemaProperty from '../EditableSchemaProperty';
import { formFields } from '../FormFields';
import ImportSharedColumn from '../ImportSharedColumn';
import SchemaColumnUpdateDrawer from '../SchemaColumnUpdateDrawer';

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;

interface Props {
  schema: SchemaEntity | undefined;
  formReducer: SharedFormReducer;
  schemaReducer: ISchemaReducer;
  initializeForm: any;
  createColumn: (params: CreateSchemaColumn, cb?: any) => void;
  getSchema: (payload: any, cb?: any) => void;
  deleteColumn: (params: DeleteSchemaColumn, cb: any) => void;
  getColumn: (params: GetSchemaColumnById, cb: any) => void;
}

interface State {
  searchTerm: string;
  deleteColumnModalIsVisible: boolean;
  deletedColumn: any;
  isLoadingSchema: boolean;
}

const uuid = uuidv4();

class SchemaColumnListView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchTerm: '',
      deleteColumnModalIsVisible: false,
      deletedColumn: undefined,
      isLoadingSchema: false,
    };
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { getSchema, schema } = this.props;
    if (schema) {
      this.setState({ isLoadingSchema: true });
      getSchema({ schemaId: schema!.id }, (res: any) => {
        if (res) {
          this.setState({ isLoadingSchema: false });
        } else {
          this.setState({ isLoadingSchema: false });
        }
      });
    }
  };

  showCreateForm() {
    const { initializeForm, schema } = this.props;

    let parsedFormFields: any[] = [];
    const hasTypes = schema && schema?.types?.length > 0;

    formFields.forEach((field: any) => {
      if (hasTypes && field.property === 'schemaTypeId') {
        field.options = schema?.types?.map((type) => ({
          label: type.name,
          value: type.id,
        }));

        parsedFormFields.push(field);
      } else if (!hasTypes && field.property === 'schemaTypeId') {
        // do not add the form field for selecting types
      } else {
        parsedFormFields.push(field);
      }
    });

    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: 'Create Column',
      formFields: parsedFormFields,
      entityName: 'SchemaColumn',
    });
  }

  handleFormSubmit(params: FormReducerSubmitEvt) {
    const { createColumn, schema, formReducer, getSchema } = this.props;

    // Created column event
    if (schema && params.data && !formReducer.isUpdateReq) {
      const body = {
        name: params.data.name,
        label: params.data.label,
        schemaTypeId: params.data.schemaTypeId,
        description: params.data.description,
        type: SchemaColumnTypes.TEXT,
        position: 0,
      };

      createColumn({ schemaId: schema.id, body });
    }
  }

  renderSchemaTypeName(col: SchemaColumnEntity) {
    const { schema } = this.props;

    if (schema && schema.types && schema.types.length > 0) {
      const typeMatch = schema.types.find((type) => type.id === col.schemaTypeId);

      if (typeMatch) {
        return typeMatch.name;
      }
    }
  }

  getValidatorTagColor(validator: string) {
    switch (validator) {
      case 'REQUIRED':
        return 'red';
      case 'UNIQUE':
        return 'blue';
      default:
        return '';
    }
  }

  renderSchemaColumns() {
    const { schema } = this.props;

    const typeFilters = Object.keys(SchemaColumnTypes).map((key) => ({
      text: SchemaColumnTypes[key as keyof typeof SchemaColumnTypes],
      value: SchemaColumnTypes[key as keyof typeof SchemaColumnTypes],
    }));

    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 200,
        sorter: (a: any, b: any) => a?.name.localeCompare(b?.name),
        render: (text: any, record: any) => (
          <Space size="small">
            {/* <Link
              to={
                record.isShared
                  ? `/${SCHEMA_MODULE}/SchemaColumn/${record.columnSchemaId}/${record.key}`
                  : `/${SCHEMA_MODULE}/SchemaColumn/${schema?.id}/${record.key}`
              }
            > */}
            {text}
            {/* </Link> */}
            {record.isShared && (
              <Tooltip hoverOpenDelay={800} content="This Column is shared">
                <i className="bi bi-globe" style={{ opacity: 0.9 }} />
              </Tooltip>
            )}
          </Space>
        ),
      },
      {
        title: 'Mapping',
        dataIndex: 'mapping',
        key: 'mapping',
        sorter: (a: any, b: any) => (a.mapping ? a?.mapping.localeCompare(b?.mapping) : 0),
      },
      {
        title: 'Label',
        dataIndex: 'label',
        key: 'label',
        sorter: (a: any, b: any) => a?.label.localeCompare(b?.label),
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="label"
            value={text}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="description"
            value={text}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Schema Type',
        dataIndex: 'schemaType',
        key: 'schemaType',
        sorter: (a: any, b: any) => (a?.label ? a?.schemaType?.localeCompare(b?.schemaType) : 0),
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="schemaTypeId"
            value={text}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        filters: typeFilters,
        onFilter: (value: any, record: any) => record.type.indexOf(value) === 0,
        sorter: (a: any, b: any) => a?.type?.localeCompare(b?.type),
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="type"
            value={text}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Default',
        dataIndex: 'defaultValue',
        key: 'defaultValue',
        sorter: (a: any, b: any) =>
          a?.defaultValue ? a?.defaultValue.localeCompare(b?.defaultValue) : 0,
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="defaultValue"
            value={text}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        align: 'center',
        sorter: (a: any, b: any) => a?.position - b?.position,
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="position"
            value={text}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Hidden',
        dataIndex: 'isHidden',
        align: 'center',
        key: 'isHidden',
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="isHidden"
            value={String(text)}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Visible In Tables',
        dataIndex: 'isVisibleInTables',
        key: 'isVisibleInTables',
        width: '8%',
        align: 'center',
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="isVisibleInTables"
            value={String(text)}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Is Title',
        dataIndex: 'isTitleColumn',
        key: 'isTitleColumn',
        align: 'center',
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="isTitle"
            value={String(text)}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Is Searchable',
        dataIndex: 'isSearchable',
        key: 'isSearchable',
        align: 'center',
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="isSearchable"
            value={String(text ?? false)}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Validators',
        dataIndex: 'validators',
        key: 'validators',
        width: '14%',
        render: (text: any, record: any) => (
          <EditableSchemaProperty
            columnId={record.key}
            property="validators"
            value={text}
            schema={this.props.schema}
            onUpdate={this.fetchData}
          />
        ),
      },
      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'right',
        render: (text: any, record: any) => (
          <Space>
            {record.isShared ? (
              <SchemaColumnUpdateDrawer
                schemaColumnId={record.key}
                schemaId={record.columnSchemaId}
              />
            ) : (
              <SchemaColumnUpdateDrawer schemaColumnId={record.key} schemaId={schema!.id} />
            )}

            <Button
              intent="danger"
              minimal
              icon={record.isShared ? 'unlink' : 'trash'}
              small
              onClick={() => {
                this.setState({ deleteColumnModalIsVisible: true, deletedColumn: record });
              }}
            />
          </Space>
        ),
      },
    ];

    const dataSource = schema?.columns
      ?.map((elem: any) => ({
        key: elem.id,
        name: elem.name,
        type: elem.type,
        label: elem.label,
        mapping: elem.mapping,
        schemaType: this.renderSchemaTypeName(elem),
        description: elem.description,
        isShared: elem.isShared,
        defaultValue: elem.defaultValue,
        position: elem.position,
        isHidden: elem.isHidden,
        isVisibleInTables: elem.isVisibleInTables,
        isTitleColumn: elem.isTitleColumn,
        validators: elem.validators,
        columnSchemaId: elem.schemaId,
      }))
      .filter(
        (elem: any) => elem.name?.toLowerCase()?.indexOf(this.state.searchTerm?.toLowerCase()) > -1,
      )
      .sort((a: any, b: any) => a.name.localeCompare(b.name));

    return (
      <Table
        size="small"
        loading={this.state.isLoadingSchema}
        scroll={{ y: 'calc(100vh - 280px)' }}
        style={{ minHeight: '100%' }}
        pagination={false}
        dataSource={dataSource}
        columns={columns as any}
      />
    );
  }

  closeModal = () => {
    this.setState({ deleteColumnModalIsVisible: false, deletedColumn: undefined });
  };

  deleteColumn = () => {
    const { schema, deleteColumn, getSchema } = this.props;
    const { deletedColumn } = this.state;

    if (schema && deletedColumn) {
      const schemaId = schema?.id;

      // Once Column is deleted, fetch the schema back so we can update the shortlist.
      if (deletedColumn.isShared) {
        httpDelete(`SchemaModule/v1.0/shared-columns/${schema.id}/${deletedColumn.key}`).then(
          () => {
            this.closeModal();
            getSchema({ schemaId: schemaId });
          },
        );
      } else {
        deleteColumn({ schemaId, schemaColumnId: deletedColumn.key }, () => {
          this.closeModal();
        });
      }
    }
  };

  render() {
    return (
      <>
        <Alert
          intent="danger"
          canEscapeKeyCancel={true}
          cancelButtonText="Cancel"
          confirmButtonText={this.state.deletedColumn?.isShared ? 'Remove' : 'Delete'}
          isOpen={this.state.deleteColumnModalIsVisible}
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onConfirm={this.deleteColumn}
        >
          {this.state.deletedColumn?.isShared ? (
            <p>Are you sure you want remove this shared column? This action cannot be undone.</p>
          ) : (
            <p>Are you sure you want to delete this schema column? This action cannot be undone.</p>
          )}
        </Alert>

        <FormModal
          formUUID={uuid}
          onSubmitEvent={(params: FormReducerSubmitEvt) => this.handleFormSubmit(params)}
        />
        <Section
          title="Schema Columns"
          rightElement={
            <>
              <InputGroup
                type="search"
                round
                id="text-input"
                style={{ width: 180 }}
                placeholder="Search"
                intent={this.state.searchTerm ? 'primary' : 'none'}
                leftIcon="search"
                value={this.state.searchTerm}
                onChange={(e: any) => this.setState({ searchTerm: e.target.value })}
              />
              <ImportSharedColumn schemaId={this.props.schema?.id} onSuccess={this.fetchData} />
              <Button
                intent="success"
                icon="plus"
                onClick={() => this.showCreateForm()}
                text="Create Column"
              />
            </>
          }
        >
          <Row>
            <Col span={24}>{this.renderSchemaColumns()}</Col>
          </Row>
        </Section>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  formReducer: state.formReducer,
});

const mapDispatch = (dispatch: any) => ({
  getColumn: (params: GetSchemaColumnById, cb: any) =>
    dispatch(getSchemaColumnByIdRequest(params, cb)),
  initializeForm: (params: any) => dispatch(initializeSharedForm(params)),
  createColumn: (params: CreateSchemaColumn, cb: any) =>
    dispatch(createSchemaColumnRequest(params, cb)),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  deleteColumn: (params: DeleteSchemaColumn, cb: any) =>
    dispatch(deleteSchemaColumnRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(SchemaColumnListView);
