import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Card, Col, Row, Spin, Tooltip } from 'antd';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { IGetSplicingShorthandByClosureId } from '../../../../../../../com.netomnia/modules/ProjectModule/Autosplicing/store/types';
import { getSplicingShorthandByClosureId } from '../../../../../../../com.netomnia/modules/ProjectModule/Autosplicing/store/actions';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { ReloadOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
interface Props {
  parentRecord: DbRecordEntityTransform | undefined; // CLOSURE type Feature record
  getSplicingShorthand: Function;
}

const SplicingSummaryWidget: FC<Props> = (props: Props) => {
  const { parentRecord, getSplicingShorthand } = props;
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [splicingData, setSplicingData] = useState<any>(null);

  // On Component mount fetch the Splicing Summary
  useEffect(() => {
    if (parentRecord) {
      const closureId = getProperty(parentRecord, 'ExternalRef');
      getSplicingSummary(closureId);
    }
  }, [parentRecord]);

  // Fetch splicing summary for Closure Id
  const getSplicingSummary = (closureId: number) => {
    setIsLoading(true);
    getSplicingShorthand({ closureId: closureId }, (res: any) => {
      setIsLoading(false);
      setSplicingData(res.data.data);
    });
  };

  // Convert splicing API response to shorthand in/out code
  const parseSplicingData = (splicingData: any) => {
    let output: string = '';

    const {
      in_cable,
      in_tube_fibre_first,
      in_tube_fibre_last,
      out_cable,
      out_tube_fibre_first,
      out_tube_fibre_last,
      type,
    } = splicingData;

    // In
    if (in_cable && in_tube_fibre_first && in_tube_fibre_last) {
      output += `${in_cable} (${in_tube_fibre_first}-${in_tube_fibre_last}) `;
    }

    // Out
    if (out_cable && out_tube_fibre_first && out_tube_fibre_last) {
      output += `${type} ${out_cable} (${out_tube_fibre_first}-${out_tube_fibre_last}) `;
    } else {
      if (type) {
        output += 'available';
      }
    }

    return output;
  };

  const renderList = () => {
    if (splicingData?.length! > 0) {
      return splicingData.map((splicing: any) => (
        <Col span={24} style={{fontSize: isMobile ? '0.8em': 'normal' }}>{parseSplicingData(splicing)}</Col>
      ));
    } else {
      return (
        <Col
          span={24}
          style={{ paddingTop: 5, paddingBottom: 10, textAlign: 'center', opacity: 0.3 }}
        >
          <span style={{ padding: 0, fontSize: '0.9em' }}>No splicing data available.</span>
        </Col>
      );
    }
  };

  return (
    <Card
      size="small"
      extra={
        <Tooltip title="Refresh" mouseEnterDelay={2}>
        <Button
          disabled={!parentRecord || isLoading}
          onClick={() => getSplicingSummary(getProperty(parentRecord, 'ExternalRef'))}
          icon={<ReloadOutlined />}
        />
        </Tooltip>
      }
      title={
        <>
          <ModuleEntityIcon
            iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
            overrideIcon='diagram-3-fill'
            moduleName="ProjectModule"
            entityName="Feature"
          />
          <span style={{ fontSize: '1.1em', marginTop: 2 }}>Splicing Summary</span>
        </>
      }
    >
      <Row>
        {isLoading ? (
          <>
            <Col span={24} style={{ textAlign: 'center' }}>
              <Spin />
              <br />
              <span>Loading...</span>
            </Col>
          </>
        ) : (
          renderList()
        )}
      </Row>
    </Card>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  getSplicingShorthand: (params: IGetSplicingShorthandByClosureId, cb: any) =>
    dispatch(getSplicingShorthandByClosureId(params, cb)),
});

export default connect(mapState, mapDispatch)(SplicingSummaryWidget);
