import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';
import { Alert, Button, Drawer, Icon, Tag } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getFirstRelation, getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Divider, Row, Select, Spin } from 'antd';
import dayjs from 'dayjs';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { httpGet } from '@core/http/requests';
import { canUserUpdateRecord, hasPermissions } from '@core/helpers/rbacRules';
import { displayMessage, goCardlessErrorMessage } from '../../messages/store/reducers';
import { parseDateToLocalFormat } from '@core/helpers/dateHelpers';
import { getRecordLink } from '@core/helpers/recordHelpers';
import { getOdinSchemaByEntity, getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import {
  deleteRecordByIdRequest,
  getRecordByIdRequest,
  IDeleteRecordById,
  IGetRecordById,
  IRecordLookup,
  lookupRecords,
} from '../../records/store/actions';
import { IRecordReducer } from '../../records/store/reducer';
import { getRecordAssociationsRequest, IGetRecordAssociations } from '../../recordsAssociations/store/actions';
import { IRecordAssociationsReducer } from '../../recordsAssociations/store/reducer';
import { getSchemaByModuleAndEntityRequest, ISchemaByModuleAndEntity } from '../../schemas/store/actions';
import { ISchemaReducer } from '../../schemas/store/reducer';
import {
  createAppointmentRequest,
  creteWorkOrderAndAppointmentRequest,
  ICreateServiceAppointment,
  initailizeCancelAppointmentModal,
  loadTimeSlotsRequest,
  setScheduleId,
  updateAppointmentReducer,
} from '../store/actions';
import { ISetScheduleId } from '../store/reducer';
import { getBadgeStyleForAppointmentNumber } from './helpers';
import RescheduleWorkOrderFlow from './RescheduleWorkOrderFlow';

interface Props {
  recordId: string;
  launcherType: 'BUTTON' | 'MENU_ITEM';
  launcherTitle?: string;
  alertMessage: any;
  appointmentReducer: any;
  updateAppointmentReducer: any;
  createAppointment: any;
  creteWorkOrderAndAppointment: any;
  deleteRecord: any;
  getAssociations: any;
  getRecordById: (payload: IGetRecordById, cb?: any) => void;
  getSchema: Function;
  getTimeSlots: any;
  goCardlessErrorMessage: any;
  hidden?: string[];
  initializeCancelAppointment: any;
  lookup: any;
  onBookingSuccess?: Function;
  recordAssociationReducer: IRecordAssociationsReducer;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  setScheduleId: Function;
  userReducer: any;
  scheduleType: string;
  addressRecord?: DbRecordEntityTransform;
  readOnly?: boolean;
  drawerTitle?: string;
}

export type TAppointment = {
  Date: string;
  AM: boolean;
  PM: boolean;
  Config: any;
};

interface State {
  accountNumber: string;
  appointmentToConfirm: TAppointment | undefined;
  branchCode: string;
  contactId: string | undefined;
  currentEndDate: string;
  currentStartDate: string;
  initialStartDate: string;
  isConfirmModalVisible: boolean;
  isCreatingAppointment: boolean;
  isLoadingSchedules: boolean;
  paymentMethodFormVisible: boolean;
  record: DbRecordEntityTransform | undefined;
  schedules: DbRecordEntityTransform[];
  selectedAppointment: TAppointment | undefined;
  currentAppointment: DbRecordEntityTransform | undefined;
  selectedSchedule: DbRecordEntityTransform | undefined;
  visible: boolean;
}

const { CRM_MODULE, FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;

const { SERVICE_APPOINTMENT, ADDRESS, WORK_ORDER, SERVICE_APPOINTMENT_CONFIG } =
  SchemaModuleEntityTypeEnums;

class BookingModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      accountNumber: '',
      appointmentToConfirm: undefined,
      branchCode: '',
      contactId: '',
      currentEndDate: '',
      currentStartDate: '',
      initialStartDate: '',
      isConfirmModalVisible: false,
      isCreatingAppointment: false,
      isLoadingSchedules: false,
      paymentMethodFormVisible: false,
      record: undefined,
      schedules: [],
      selectedAppointment: undefined,
      selectedSchedule: undefined,
      visible: false,
      currentAppointment: undefined,
    };
    this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
  }

  private handleCloseDrawer() {
    this.setState({
      visible: false,
      paymentMethodFormVisible: false,
      selectedAppointment: undefined,
      appointmentToConfirm: undefined,
      isCreatingAppointment: false,
      isConfirmModalVisible: false,
    });

    this.props.updateAppointmentReducer({
      isCreating: false,
      isSearching: false,
      list: [],
      selectedDate: '',
      start: undefined,
      end: undefined,
      type: undefined,
      addressId: undefined,
      scheduleId: undefined,
      contractor: undefined,
    });
  }

  // 1. Load Schemas on component mount
  componentDidMount() {
    this.loadSchemas();
  }

  // 2. Load Contact and WO schema
  loadSchemas = async () => {
    const workOrderSchema = await getOdinSchemaByEntity(FIELD_SERVICE_MODULE, WORK_ORDER);
    this.fetchWORecordAndSetToState(workOrderSchema);
    this.fetchCurrentAppointment();
  };

  // 3. Fetch WO record by id and set to state
  fetchWORecordAndSetToState = (schema: SchemaEntity) => {
    const { getRecordById, recordId, addressRecord } = this.props;

    // If addressRecord is passed, use it to fetch Schedules, bypassing the need for WO
    if (addressRecord) {
      this.fetchServiceAppointmentConfig();
    } else if (!addressRecord && schema && recordId) {
      getRecordById({ schema, recordId }, (res: any) => {
        if (res) {
          this.setState({
            record: res,
          });
          // Fetch record by id and Appointment config
          this.fetchServiceAppointmentConfig(res);
        }
      });
    }
  };

  private onBookingSuccess = () => {
    if (this.props.onBookingSuccess) {
      this.props.onBookingSuccess();
    }
  };

  private async initializeBookingModal(schedule?: DbRecordEntityTransform) {
    const { getTimeSlots, getAssociations, schemaReducer, alertMessage, userReducer } = this.props;
    const { record } = this.state;

    if (record || this.props.addressRecord) {
      const addressRecord = record
        ? getRecordLink(record, `${CRM_MODULE}:${ADDRESS}`)
        : this.props.addressRecord;

      let scheduleId = schedule?.id;
      let contractor = getProperty(record, 'Contractor')
        ? {
          operator: 'eq',
          value: getProperty(record, 'Contractor'),
        }
        : undefined;

      if (schedule) {
        contractor = {
          operator: 'eq',
          value: getProperty(schedule, 'Contractor'),
        };
      }

      const woType: string = this.props.scheduleType;

      // D19-1491 - Pass the scheduleId for all users if the
      // work order is type SERVICE regardless of permissions.
      if (
        woType === 'INSTALL' &&
        hasPermissions(userReducer, ['fieldservicemodule.admin']) &&
        this.state.selectedSchedule
      ) {
        scheduleId = this.state.selectedSchedule?.id;
      } else if (
        this.state.selectedSchedule &&
        (hasPermissions(userReducer, ['fieldservicemodule.admin']) ||
          ['SERVICE', 'COLLECTION', 'REMEDIATION', 'INSPECTION'].includes(
            getProperty(record, 'Type'),
          ))
      ) {
        scheduleId = this.state.selectedSchedule?.id;
      }

      // Fetch appointment slots using current date as start, and +6 days as an end date. Keep the initial start date, so we can limit how far the user can go back in time.
      getTimeSlots(
        {
          start: moment().format('YYYY-MM-DD'),
          end: moment().add(6, 'days').format('YYYY-MM-DD'),
          type: woType,
          addressId: addressRecord ? addressRecord.id : undefined,
          scheduleId: scheduleId,
          contractor: hasPermissions(userReducer, [
            'fieldservicemodule.scheduleappointments.allcalendars',
          ])
            ? null
            : contractor,
        },
        (res: any) => {
          if (res?.data?.length > 0) {
            this.setState({
              initialStartDate: res.data.at(0).Date,
              currentStartDate: res.data.at(0).Date,
              currentEndDate: res.data.at(-1).Date,
            });
          }
        },
      );

      // 16-Aug-2024 - So, instead just show the modal to everyone...
      this.setState({ visible: true });
    }
  }

  private handleDateChange(direction: 'PREVIOUS_WEEK' | 'NEXT_WEEK') {
    const { getTimeSlots, userReducer } = this.props;
    const { record } = this.state;

    let newStartDate: string = '';
    let newEndDate: string = '';

    // Previous week
    if (direction === 'PREVIOUS_WEEK') {
      newStartDate = moment(this.state.currentStartDate)
        .subtract(1, 'day')
        .subtract(6, 'days')
        .format('YYYY-MM-DD');
      newEndDate = moment(this.state.currentStartDate).subtract(1, 'day').format('YYYY-MM-DD');
    }
    // Next week
    else {
      newStartDate = this.state.currentEndDate;
      newEndDate = moment(this.state.currentEndDate).add(6, 'days').format('YYYY-MM-DD');
    }

    let scheduleId = undefined;
    let contractor = getProperty(record, 'Contractor')
      ? {
        operator: 'eq',
        value: getProperty(record, 'Contractor'),
      }
      : undefined;

    const woType: string = this.props.scheduleType;

    // D19-1491 - Pass the scheduleId for all users if the
    // work order is type SERVICE regardless of permissions.
    if (
      woType === 'INSTALL' &&
      hasPermissions(userReducer, ['fieldservicemodule.admin']) &&
      this.state.selectedSchedule
    ) {
      scheduleId = this.state.selectedSchedule?.id;
      contractor = {
        operator: 'eq',
        value: getProperty(this.state.selectedSchedule, 'Contractor'),
      };
    } else if (
      this.state.selectedSchedule &&
      (hasPermissions(userReducer, ['fieldservicemodule.admin']) ||
        ['SERVICE', 'COLLECTION', 'REMEDIATION', 'INSPECTION'].includes(this.props.scheduleType))
    ) {
      scheduleId = this.state.selectedSchedule?.id;
      contractor = {
        operator: 'eq',
        value: getProperty(this.state.selectedSchedule, 'Contractor'),
      };
    }

    let addressRecord: DbRecordEntityTransform | undefined = undefined;

    if (this.state.record && !this.props.addressRecord) {
      addressRecord = getRecordLink(this.state.record, `${CRM_MODULE}:${ADDRESS}`);
    } else if (this.props.addressRecord) {
      addressRecord = this.props.addressRecord;
    }

    console.log('CONTRACTOR: ', contractor);

    getTimeSlots(
      {
        start: newStartDate,
        end: newEndDate,
        type: this.props.scheduleType,
        addressId: addressRecord ? addressRecord.id : undefined,
        scheduleId: scheduleId,
        contractor: hasPermissions(userReducer, [
          'fieldservicemodule.scheduleappointments.allcalendars',
        ])
          ? null
          : contractor,
      },
      (res: any) => {
        if (res?.data?.length > 0) {
          this.setState({
            currentStartDate: res.data.at(0).Date,
            currentEndDate: res.data.at(-1).Date,
          });
        }
      },
    );
  }

  private async handleConfirm(apt: TAppointment | undefined) {
    const { setScheduleId } = this.props;
    const { record } = this.state;

    setScheduleId({ id: apt?.Config?.id });

    if (apt && record && record?.id) {
      const res = await httpGet(
        `FieldServiceModule/v1.0/db-associations/WorkOrder/${record.id}/relations?entities=["ServiceAppointment"]&withLinks=false`,
      );

      const serviceAppointment = res.data.data['ServiceAppointment']?.dbRecords;

      // Appointment already exists
      if (serviceAppointment) {
        this.setState({
          isConfirmModalVisible: false,
          appointmentToConfirm: undefined,
          selectedAppointment: {
            Date: apt.Date,
            AM: apt.AM,
            PM: apt.PM,
            Config: apt.Config,
          },
        });
      }
      // Create fresh appointment
      else {
        this.createNewAppointment(apt);
        this.onBookingSuccess();
      }
    }
  }

  private createNewAppointment(apt: {
    Date: string;
    AM: boolean;
    PM: boolean;
    Config: DbRecordEntityTransform;
  }) {
    const { createAppointment } = this.props;
    const { record } = this.state;

    if (record) {
      this.setState({ isCreatingAppointment: true });

      createAppointment(
        {
          workOrderId: record?.id,
          createUpdate: {
            scheduleId: apt.Config?.id,
            Date: apt.Date,
            TimeBlock: apt.AM ? 'AM' : 'PM',
            Optimizer: apt.Config?.optimizer,
          },
        },
        (res: any) => {
          this.setState({ isCreatingAppointment: false });
          this.handleCloseDrawer();
          this.getRecordAssociations();
          console.log('debug: appointment res', res);
        },
      );
    }
  }

  private getRecordAssociations() {
    const { getAssociations, schemaReducer, getRecordById } = this.props;

    const { record } = this.state;

    if (record) {
      const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

      if (schema) {
        getRecordById({ schema, recordId: record.id });
        getAssociations({
          recordId: record.id,
          key: SERVICE_APPOINTMENT,
          schema,
          entities: [SERVICE_APPOINTMENT],
        });
      }
    }

    return <div>data fetched</div>;
  }

  async fetchCurrentAppointment() {
    // Example fetch using the Fetch API
    const appointments = await httpGet(
      `FieldServiceModule/v2.0/records/WorkOrder/${this.props.recordId}/links?targetEntities=["ServiceAppointment"]`,
    );
    const data = appointments.data.data['ServiceAppointment']?.dbRecords;
    if (data) {
      this.setState({ currentAppointment: data[0] });
    }
  }

  /**
   * D19-541 - Fetch appointments that match the current date in availableFrom/availableTo time span
   */
  async fetchServiceAppointmentConfig(record?: DbRecordEntityTransform) {
    const { lookup } = this.props;

    this.setState({ isLoadingSchedules: true });

    let addressRecord: DbRecordEntityTransform | undefined = undefined;

    // Check if address was passed through props, if not, get it out of the Work Order
    if (this.props.addressRecord) {
      addressRecord = this.props.addressRecord;
    } else if (!this.props.addressRecord && record) {
      const res = await httpGet(
        `FieldServiceModule/v1.0/db-associations/WorkOrder/${record.id}/one-relation?entity=${ADDRESS}&withLinks=false`,
      );
      addressRecord = getFirstRelation(res?.data, ADDRESS) || undefined;
    }

    if (!addressRecord) {
      this.setState({ isLoadingSchedules: false, schedules: [] });
      throw new Error('Related Address record not found for the Work Order');
    }

    const SASchema = await getOdinSchemaByEntity(FIELD_SERVICE_MODULE, SERVICE_APPOINTMENT_CONFIG);

    if (SASchema) {
      lookup(
        {
          schema: SASchema,
          query: {
            entity: `${FIELD_SERVICE_MODULE}:${SERVICE_APPOINTMENT_CONFIG}`,
            properties: [],
          },
          skipReducerUpdate: true,
        },
        (res: DbRecordEntityTransform[]) => {
          const allowedSchedules = [this.props.scheduleType];

          if (['COLLECTION', 'REMEDIATION'].includes(this.props.scheduleType)) {
            allowedSchedules.push('SERVICE');
          } else {
            allowedSchedules.push('INSTALL_SERVICE');
          }

          // Get all schedules that are available before current date and last
          // either indefinite or after the current date. Filter schedules to
          // include the same exPolygonId as the Address record that we fetched.
          const filteredResults = res?.filter((schedule: DbRecordEntityTransform) => {
            const today = moment();
            const availableFrom = getProperty(schedule, 'AvailableFrom');
            const availableTo = getProperty(schedule, 'AvailableTo');

            const canScheduleInArea = () => {
              let isValid = false;
              if (getProperty(schedule, 'ExPolygonId')) {
                isValid =
                  getProperty(schedule, 'ExPolygonId')?.indexOf(
                    getProperty(addressRecord, 'ExPolygonId'),
                  ) > -1;
              }
              if (!getProperty(schedule, 'ExPolygonId')) {
                isValid = true;
              }
              return isValid;
            };

            if (
              moment(availableFrom, 'YYYY-MM-DD').isSameOrBefore(today) &&
              (!availableTo || moment(availableTo, 'YYYY-MM-DD').isSameOrAfter(today)) &&
              allowedSchedules.includes(schedule?.type!) &&
              canScheduleInArea()
            ) {
              return schedule;
            }
          });

          // Set the state
          this.setState({
            isLoadingSchedules: false,
            schedules: filteredResults,
          });
        },
      );
    }
  }

  handleSelectedSchedule = (id: any) => {
    const { setScheduleId } = this.props;

    if (id !== 'None' && this.state.schedules?.length > 0) {
      const matchedSchedule = this.state.schedules.find(
        (schedule: DbRecordEntityTransform) => schedule.id === id,
      );

      // If there is an appointment with the id from the select box
      if (matchedSchedule) {
        setScheduleId({ id: matchedSchedule.id });
        this.setState(
          {
            selectedSchedule: matchedSchedule,
          },
          () => {
            this.initializeBookingModal(matchedSchedule);
          },
        );
      } else {
        setScheduleId({ id: undefined });
        this.setState(
          {
            selectedSchedule: undefined,
          },
          () => {
            this.initializeBookingModal();
          },
        );
      }
    } else {
      setScheduleId({ id: undefined });
      this.setState(
        {
          selectedSchedule: undefined,
        },
        () => {
          this.initializeBookingModal();
        },
      );
    }
  };

  private renderListTitle() {
    const { recordAssociationReducer } = this.props;
    if (!!recordAssociationReducer.selected && recordAssociationReducer.selected.schema) {
      return `Book ${recordAssociationReducer.selected.schema.entityName}`;
    } else {
      return 'Book Appointment';
    }
  }

  private renderAppointments() {
    const { appointmentReducer } = this.props;
    if (!!appointmentReducer.list) {
      return appointmentReducer.list.map(
        (elem: {
          Date: string;
          AM: boolean;
          PM: boolean;
          AMRemaining: number;
          PMRemaining: number;
          AMCount: number;
          MaxAMCount: number;
          PMCount: number;
          MaxPMCount: number;
          AMConfig: DbRecordEntityTransform;
          PMConfig: DbRecordEntityTransform;
          IsOverBookingAM: boolean;
          IsOverBookingPM: boolean;
          Utilization: number;
        }) => (
          <Row style={{ marginBottom: 8 }}>
            <Col span={24}>
              <Row>
                {/* Date */}
                <Col span={24}>
                  <Divider
                    orientation="left"
                    orientationMargin={0}
                    style={{ marginBottom: 10, fontSize: '1em' }}
                  >
                    {parseDateToLocalFormat(elem.Date)} - {dayjs(elem.Date).format('dddd')}
                  </Divider>
                </Col>

                {/* Time slot buttons */}
                <Col span={24}>
                  <Row>
                    {/* AM ////////////////////////////////////////////// */}
                    <Col span={12}>
                      <Button
                        text="AM"
                        rightIcon={
                          <div
                            style={
                              elem.AMRemaining !== undefined
                                ? getBadgeStyleForAppointmentNumber(
                                  elem.AMRemaining,
                                  elem.IsOverBookingAM,
                                )
                                : {
                                  color: elem.AM ? '#61BD61' : '#FF4647',
                                  marginLeft: 81,
                                  borderRadius: 3,
                                  fontWeight: 500,
                                }
                            }
                          >
                            <span>
                              {elem.AMRemaining !== undefined ? (
                                <div>
                                  <div>
                                    {elem.AMCount || 0} / {elem.MaxAMCount || 0}
                                  </div>
                                </div>
                              ) : elem.AM ? (
                                <Icon icon="tick" />
                              ) : (
                                <Icon icon="cross" />
                              )}
                            </span>
                          </div>
                        }
                        disabled={
                          !elem.AM || this.props.readOnly || this.state.isCreatingAppointment
                        }
                        style={{ width: '93%' }}
                        onClick={() => {
                          this.setState({
                            isConfirmModalVisible: true,
                            appointmentToConfirm: {
                              Date: elem.Date,
                              AM: true,
                              PM: false,
                              Config: elem.AMConfig,
                            },
                          });
                        }}
                      />
                      <div style={{ fontSize: 10 }}>{elem?.AMConfig?.title}</div>
                    </Col>

                    {/* PM ////////////////////////////////////////////// */}
                    <Col span={12} style={{ textAlign: 'right' }}>
                      <Button
                        text="PM"
                        disabled={
                          !elem.PM || this.props.readOnly || this.state.isCreatingAppointment
                        }
                        style={{ width: '93%' }}
                        rightIcon={
                          <div
                            style={
                              elem.PMRemaining !== undefined
                                ? getBadgeStyleForAppointmentNumber(
                                  elem.PMRemaining,
                                  elem.IsOverBookingPM,
                                )
                                : {
                                  color: elem.PM ? '#61BD61' : '#FF4647',
                                  marginLeft: 81,
                                  borderRadius: 3,
                                  fontWeight: 500,
                                }
                            }
                          >
                            <span>
                              {elem.PMRemaining !== undefined ? (
                                  // Regular mode
                                  <div>
                                    <div>
                                      {elem.PMCount || 0} / {elem.MaxPMCount || 0}
                                    </div>
                                  </div>
                                ) : // Overview mode
                                elem.PM ? (
                                  <Icon icon="tick" />
                                ) : (
                                  <Icon icon="cross" />
                                )}
                            </span>
                          </div>
                        }
                        onClick={() => {
                          this.setState({
                            isConfirmModalVisible: true,
                            appointmentToConfirm: {
                              Date: elem.Date,
                              AM: false,
                              PM: true,
                              Config: elem.PMConfig,
                            },
                          });
                        }}
                      />
                      <div style={{ fontSize: 10 }}>{elem?.PMConfig?.title}</div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      );
    }
  }

  canBookingModalLaunch = () => {
    const { userReducer, schemaReducer } = this.props;
    const { record } = this.state;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    if (this.props.addressRecord) {
      return !this.state.isLoadingSchedules;
    } else {
      return canUserUpdateRecord(userReducer, schema) && !this.state.isLoadingSchedules;
    }
  };

  closeRescheduleModal = () => {
    this.handleCloseDrawer();
    this.getRecordAssociations();
  };

  render() {
    const { schemaReducer, userReducer, appointmentReducer, launcherType, launcherTitle } =
      this.props;
    const { record, selectedAppointment } = this.state;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <>
        {/* Reschedule Change Reason Flow */}
        <RescheduleWorkOrderFlow
          record={record!}
          isDialogOpen={!!selectedAppointment}
          selectedAppointment={selectedAppointment}
          onClose={() => {
            this.closeRescheduleModal();
          }}
          onConfirm={() => {
            this.closeRescheduleModal();
          }}
        />
        {/* BUTTON */}
        {launcherType === 'BUTTON' && (
          <Button
            outlined
            intent="primary"
            text={launcherTitle || (this.state.currentAppointment ? 'Re-schedule' : 'Schedule')}
            disabled={!this.canBookingModalLaunch()}
            onClick={() => this.initializeBookingModal()}
          />
        )}
        {/* MENU ITEM */}
        {launcherType === 'MENU_ITEM' && (
          <span
            key="bookingModal"
            style={{
              cursor: this.canBookingModalLaunch() ? 'pointer' : 'not-allowed',
              opacity: this.canBookingModalLaunch() ? 1 : 0.3,
            }}
            onClick={() => {
              if (
                this.canBookingModalLaunch() &&
                canUserUpdateRecord(userReducer, schema) &&
                !this.state.isLoadingSchedules
              ) {
                this.initializeBookingModal();
              }
            }}
          >
            {this.state.isLoadingSchedules && <Spin size="small" style={{ marginRight: 8 }} />}
            {launcherTitle || (this.state.currentAppointment ? 'Re-schedule' : 'Schedule')}
          </span>
        )}
        <Drawer
          title={this.props.drawerTitle || this.renderListTitle()}
          isOpen={this.state.visible}
          onClose={() => this.handleCloseDrawer()}
          style={{ width: 360 }}
        >
          <div style={{ padding: 20, overflowY: 'auto' }}>
            <Spin spinning={appointmentReducer.isSearching} tip="Finding Appointments...">
              <Spin spinning={appointmentReducer.isCreating} tip="Saving Appointment...">
                <div>
                  {/* Select Schedule */}
                  <Row
                    style={{
                      marginBottom: 15,
                      display:
                      // D19-1461 Show the schedule select option for all users if
                      // the work order is type SERVICE regardless of permissions.
                        hasPermissions(userReducer, ['fieldservicemodule.admin']) ||
                        ['SERVICE', 'COLLECTION', 'REMEDIATION', 'INSPECTION'].includes(
                          getProperty(record, 'Type'),
                        )
                          ? 'block'
                          : 'none',
                    }}
                  >
                    <Col span={24} style={{ marginBottom: 10 }}>
                      <span style={{ fontWeight: 500 }}>Schedule</span>
                    </Col>
                    <Col span={24}>
                      <Select
                        style={{ width: '100%' }}
                        allowClear={true}
                        disabled={this.state.isLoadingSchedules}
                        loading={this.state.isLoadingSchedules}
                        defaultValue={this.state.selectedSchedule?.id}
                        value={this.state.selectedSchedule?.id}
                        onChange={(e: any) => this.handleSelectedSchedule(e)}
                      >
                        {this.state.schedules?.map((appointment: DbRecordEntityTransform) => {
                          return (
                            <Select.Option key={appointment.id} value={appointment.id}>
                              {appointment.title}
                            </Select.Option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>

                  {/* Week navigation */}
                  <Row style={{ marginTop: 10, marginBottom: 15 }}>
                    <Col span={24}>
                      <Row>
                        <Col span={12}>
                          <Button
                            intent="primary"
                            icon={<CaretLeftOutlined />}
                            style={{ width: '95%' }}
                            onClick={() => this.handleDateChange('PREVIOUS_WEEK')}
                          >
                            Previous week
                          </Button>
                        </Col>
                        <Col span={12} style={{ textAlign: 'right' }}>
                          <Button
                            intent="primary"
                            style={{ width: '95%' }}
                            onClick={() => this.handleDateChange('NEXT_WEEK')}
                          >
                            Next week <CaretRightOutlined />
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <Tag style={{ margin: 2 }} intent={'primary'} minimal>
                        {appointmentReducer?.start
                          ? `${parseDateToLocalFormat(
                            appointmentReducer.start,
                          )} - ${parseDateToLocalFormat(appointmentReducer.end)}`
                          : ''}
                      </Tag>
                      <Tag style={{ margin: 2 }} intent={'primary'} minimal>
                        {appointmentReducer?.type ? appointmentReducer.type : ''}
                      </Tag>
                      <Tag style={{ margin: 2 }} intent={'primary'} minimal>
                        {appointmentReducer?.contractor
                          ? `${appointmentReducer.contractor?.operator} ${appointmentReducer.contractor?.value}`
                          : 'ALL'}
                      </Tag>
                    </div>
                  </Row>
                </div>
                {this.renderAppointments()}
              </Spin>
            </Spin>
          </div>
          <Alert
            intent="primary"
            icon="confirm"
            isOpen={this.state.isConfirmModalVisible}
            loading={this.state.isCreatingAppointment}
            cancelButtonText="Cancel"
            onCancel={() => {
              this.setState({
                isConfirmModalVisible: false,
                appointmentToConfirm: undefined,
              });
            }}
            onConfirm={() => {
              this.handleConfirm(this.state.appointmentToConfirm);
            }}
            confirmButtonText="Confirm"
          >
            <p>
              <strong>Booking Confirmation</strong>
            </p>
            <p>
              <span>
                Please confirm that you want to book an appointment on{' '}
                {this.state.appointmentToConfirm?.Date
                  ? dayjs(this.state.appointmentToConfirm.Date).format('MMMM D, YYYY')
                  : ''}{' '}
                ({this.state.appointmentToConfirm?.AM ? 'AM' : 'PM'}).
              </span>
            </p>
          </Alert>
        </Drawer>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
  appointmentReducer: state.appointmentReducer,
});

const mapDispatch = (dispatch: any) => ({
  lookup: (params: IRecordLookup, cb: () => {}) => dispatch(lookupRecords(params, cb)),
  getRecordById: (payload: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(payload, cb)),
  deleteRecord: (payload: IDeleteRecordById, cb: any) =>
    dispatch(deleteRecordByIdRequest(payload, cb)),
  getTimeSlots: (params: any, cb: any) => dispatch(loadTimeSlotsRequest(params, cb)),
  createAppointment: (params: ICreateServiceAppointment, cb: any) =>
    dispatch(createAppointmentRequest(params, cb)),
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  goCardlessErrorMessage: (params: any) => dispatch(goCardlessErrorMessage(params)),
  initializeCancelAppointment: (params: any) => dispatch(initailizeCancelAppointmentModal(params)),
  creteWorkOrderAndAppointment: (params: any, cb: () => {}) =>
    dispatch(creteWorkOrderAndAppointmentRequest(params, cb)),
  setScheduleId: (params: ISetScheduleId) => dispatch(setScheduleId(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  updateAppointmentReducer: (params: any) => dispatch(updateAppointmentReducer(params)),
});

export default connect(mapState, mapDispatch)(BookingModal);
