import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import './styles.scss';
import NoteEditor from '@core/components/NoteFeed/NoteEditor';

interface Props {
  note: DbRecordEntityTransform;
}

const MyCasesNote: React.FC<Props> = (props: Props) => {
  const { note } = props;

  const isJSONContent = getProperty(note, 'JSONContent')?.length! > 0;
  const isStringContent = getProperty(note, 'Body')?.length! > 0;

  return (
    <Row justify="end" style={{ padding: 15 }}>
      <Col span={24} className="myCasesUsersNote">
        <Row>
          {/* Note Title & Toolbar */}
          <Col span={12}>
            <i className="bi bi-sticky" style={{ marginRight: 5 }} />
            <span style={{ fontWeight: 500 }}>Note</span>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {/*<Button small minimal icon={<i className="bi bi-pencil" />} />*/}
            {/*<Button*/}
            {/*  small*/}
            {/*  minimal*/}
            {/*  icon={<i className="bi bi-link-45deg" style={{ fontSize: '1.4em' }} />}*/}
            {/*/>*/}
          </Col>

          <Col span={24} style={{ marginTop: 15 }}>
            {/* JSON Note Contents */}
            {isJSONContent && (
              <NoteEditor
                key={note.id}
                isViewMode={true}
                value={getProperty(note, 'JSONContent')}
                minHeight="auto"
              />
            )}
            {/* String Contents */}
            {!isJSONContent && isStringContent && (
              <span dangerouslySetInnerHTML={{ __html: getProperty(note, 'Body') }} />
            )}
          </Col>

          {/* User / Timestamp */}
          <Col span={24} style={{ marginTop: 12, opacity: 0.6 }}>
            <Row>
              <Col span={12}>
                <span>{note.createdBy?.fullName}</span>
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                <span>{dayjs(note.createdAt).format('D MMM HH:mm')}</span>
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
export default MyCasesNote;
