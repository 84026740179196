import { EntityTitle, Section, SectionCard } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import { SchemaDetailsProps } from '../types';
import React from 'react';
import { RecordNumbersForm } from './RecordNumbersForm';

export const DetailsSection = ({
  schema,
  rightElement,
}: SchemaDetailsProps & {
  rightElement?: React.ReactElement;
}) => {
  return (
    <Section
      className="top-section"
      title={<span style={{ fontSize: '1.2em', lineHeight: '24px' }}>{schema.name}</span>}
      subtitle="Schema Details"
      rightElement={rightElement}
    >
      <Row>
        <Col span={12}>
          <SectionCard className="auto">
            <Row>
              <Col span={12} style={{ marginTop: 5 }}>
                <EntityTitle title="Schema Name" subtitle={<span>{schema.name}</span>} />
                <br />
                <EntityTitle title="Module Name" subtitle={<span>{schema.moduleName}</span>} />
              </Col>

              <Col span={12} style={{ marginTop: 10 }}>
                <EntityTitle title="Description" subtitle={<span>{schema.description}</span>} />
                <br />
                <EntityTitle title="Entity Name" subtitle={<span>{schema.entityName}</span>} />
              </Col>
            </Row>
          </SectionCard>
        </Col>
        <Col span={12} style={{ borderLeft: 'solid 1px rgba(17, 20, 24, 0.15)' }}>
          <SectionCard className="auto">
            <RecordNumbersForm schema={schema} />
          </SectionCard>
        </Col>
      </Row>
    </Section>
  );
};
