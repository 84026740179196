export const mapLayersLegend = [
  {
    layerName: 'Closure: L0',
    layerItems: [{ name: 'Closure: L0', file: 'closure L0' }],
  },
  {
    layerName: 'Closure: L1',
    layerItems: [{ name: 'Closure: L1', file: 'closure L1' }],
  },
  {
    layerName: 'Closure: L2',
    layerItems: [{ name: 'Closure: L2', file: 'closure L2' }],
  },
  {
    layerName: 'Closure: L3',
    layerItems: [{ name: 'Closure: L3', file: 'closure L3' }],
  },
  {
    layerName: 'Closure: L4',
    layerItems: [{ name: 'Closure: L4', file: 'closure L4' }],
  },
  {
    layerName: 'Closure: LM',
    layerItems: [{ name: 'Closure: LM', file: 'closure LM' }],
  },
  {
    layerName: 'Closure: LX/C',
    layerItems: [{ name: 'Closure: LX/C', file: 'closure LXC' }],
  },
  {
    layerName: 'Closure: LX/Cnew',
    layerItems: [{ name: 'Closure: LX/Cnew', file: 'closure LXCnew' }],
  },
  {
    layerName: 'Chamber',
    layerItems: [
      { name: 'Chamber: FW2', file: 'chamber fw2' },
      { name: 'Chamber: FW4', file: 'chamber fw4_cw1' },
      { name: 'Chamber: FW10', file: 'chamber fw10' },
      { name: 'Chamber: FW6', file: 'chamber fw6' },
      { name: 'Chamber: CW2', file: 'chamber cw2' },
      { name: 'Chamber: Toby', file: 'chamber toby' },
      { name: 'Chamber: Openreach', file: 'chamber openreach' },
      { name: 'Chamber: Commsworld', file: 'chamber commsworld' },
    ],
  },

  // Plan -> Point -> Pole SDEDS
  {
    layerName: 'Pole SDEDS',
    layerItems: [
      { name: 'Pole SDEDS: OK Pole', file: 'pole_sdeds okpole' },
      { name: 'Pole SDEDS: D-Pole Non Policy', file: 'pole_sdeds nonpolicy' },
      { name: 'Pole SDEDS: D-Pole Policy', file: 'pole_sdeds policy' },
      { name: 'Pole SDEDS: Out of Test Cycle', file: 'pole_sdeds ootc' },
    ],
  },

  // Plan -> Point -> Pole
  {
    layerName: 'Pole: Medium',
    layerItems: [{ name: 'Pole: Medium', file: 'pole Medium' }],
  },
  {
    layerName: 'Pole: Light',
    layerItems: [{ name: 'Pole: Light', file: 'pole Light' }],
  },
  {
    layerName: 'Pole: Stout',
    layerItems: [{ name: 'Pole: Stout', file: 'pole Stout' }],
  },
  {
    layerName: 'Pole: JUP',
    layerItems: [{ name: 'Pole: JUP', file: 'pole JUP' }],
  },
  // {
  //     layerName: 'Pole: Openreach - UI',
  //     layerItems: [
  //         {name: 'Pole: Openreach - UI', file: 'pole Openreach'}
  //     ]
  // },
  // {
  //     layerName: 'Pole: No Street View URL',
  //     layerItems: [
  //         {name: 'Pole: No Street View URL', file: 'pole NoStreetView'}
  //     ]
  // },

  {
    layerName: 'Spine',
    layerItems: [{ name: 'Cable: Spine', file: 'cable Spine' }],
  },
  {
    layerName: 'Distribution',
    layerItems: [{ name: 'Cable: Distribution', file: 'cable Distribution' }],
  },
  {
    layerName: 'Access',
    layerItems: [
      { name: 'Cable: Access UG', file: 'cable Access_UG' },
      { name: 'Cable: Access OH', file: 'cable Access_OH' },
    ],
  },
  {
    layerName: 'Feed',
    layerItems: [
      { name: 'Cable: Feed UG', file: 'cable Feed_UG' },
      { name: 'Cable: Feed OH', file: 'cable Feed_OH' },
    ],
  },
  {
    layerName: 'CableLink',
    layerItems: [{ name: 'Cable: CableLink', file: 'cable CableLink' }],
  },
  {
    layerName: 'Backbone',
    layerItems: [{ name: 'Cable: Backbone', file: 'cable Backbone' }],
  },
  {
    layerName: 'On Hold',
    layerItems: [{ name: 'Cable: On Hold', file: 'cable On Hold' }],
  },

  {
    layerName: 'Duct',
    layerItems: [{ name: 'Ducts: Duct', file: 'duct Duct' }],
  },
  {
    layerName: 'Subduct',
    layerItems: [{ name: 'Ducts: Subduct', file: 'duct Sduct' }],
  },
  {
    layerName: 'Openreach',
    layerItems: [{ name: 'Duct: Openreach', file: 'duct Openreach' }],
  },
  {
    layerName: 'Backbone',
    layerItems: [{ name: 'Duct: Backbone', file: 'duct Backbone' }],
  },
  {
    layerName: 'Commsworld',
    layerItems: [{ name: 'Duct: Commsworld', file: 'duct Commsworld' }],
  },

  // Plan -> Line -> Rope
  {
    layerName: 'Rope: SND',
    layerItems: [{ name: 'Rope: SND', file: 'rope SND Rope' }],
  },
  {
    layerName: 'Rope: Access',
    layerItems: [{ name: 'Rope: Access', file: 'rope Access' }],
  },
  {
    layerName: 'Rope: Feed',
    layerItems: [{ name: 'Rope: Feed', file: 'rope Feed' }],
  },

  {
    layerName: 'Build Closure',
    layerItems: [
      { name: 'Build Closure: 0-Backlog', file: 'build_closure 0-Backlog (9)' },
      { name: 'Build Closure: 1-Plan', file: 'build_closure 1-Plan' },
      { name: 'Build Closure: 2-Survey', file: 'build_closure 2-Survey' },
      { name: 'Build Closure: 3-Design', file: 'build_closure 3-Design' },
      { name: 'Build Closure: 4-Plan Done', file: 'build_closure 4-Plan Done' },
      { name: 'Build Closure: 5-ToDo', file: 'build_closure 5-ToDo' },
      { name: 'Build Closure: 6-In Progress', file: 'build_closure 6-In Progress' },
      { name: 'Build Closure: 7-Build Done', file: 'build_closure 7-Build Done' },
      { name: 'Build Closure: 8-RFS', file: 'build_closure 8-RFS' },
    ],
  },
  {
    layerName: 'Build Chamber',
    layerItems: [
      { name: 'Build Chamber: FW2', file: 'chamber fw2' },
      { name: 'Build Chamber: FW4', file: 'chamber fw4_cw1' },
      { name: 'Build Chamber: FW10', file: 'chamber fw10' },
      { name: 'Build Chamber: FW6', file: 'chamber fw6' },
      { name: 'Build Chamber: CW1', file: 'chamber fw4_cw1' },
      { name: 'Build Chamber: CW2', file: 'chamber cw2' },
      { name: 'Build Chamber: Toby', file: 'chamber toby' },
      { name: 'Build Chamber: Openreach', file: 'chamber openreach' },
    ],
  },
  {
    layerName: 'Build Pole',
    layerItems: [
      { name: 'Build Pole: Medium', file: 'build_pole_medium' },
      { name: 'Build Pole: Light', file: 'build_pole_light' },
      { name: 'Build Pole: Stout/Generic', file: 'build_pole_stout' },
      { name: 'Build Pole: Openreach', file: 'build_pole Openreach' },
      { name: 'Build Pole: No Photo', file: 'build_pole_nophoto' },
    ],
  },
  {
    layerName: 'Build Cable',
    layerItems: [
      { name: 'Build Cable: Spine', file: 'build_cable_spine' },
      { name: 'Build Cable: Distribution', file: 'build_cable_distribution' },
      { name: 'Build Cable: Access_UG', file: 'build_cable_accessUG' },
      { name: 'Build Cable: Access_OH', file: 'build_cable_accessOH' },
      { name: 'Build Cable: Feed_UG', file: 'build_cable_feedUG' },
      { name: 'Build Cable: Feed_OH', file: 'build_cable_feedOH' },
      { name: 'Build Cable: Temp', file: 'build_cable_temp' },
      { name: 'Build Cable: CableLink', file: 'build_cable_cablelink' },
    ],
  },
  {
    layerName: 'Hazard',
    layerItems: [
      { name: 'Hazard: Section 58', file: 'hazard Section 58' },
      { name: 'Hazard: Wayleave', file: 'hazard Wayleave' },
      { name: 'Hazard: No Access to Asset', file: 'hazard No Access to Asset' },
      { name: 'Hazard: Lead in Duct', file: 'hazard_lead_in_duct' },
      { name: 'Hazard: Congested Chamber', file: 'hazard_congested_chamber' },
      { name: 'Hazard: Other', file: 'hazard Other' },

      { name: 'Hazard: TM Bus stop', file: 'hazard TM - Bus Stop' },
      { name: 'Hazard: TM Construction works', file: 'hazard TM - Constructions Works' },
      { name: 'Hazard: TM Emergency entrance', file: 'hazard TM - Emergency Entrance' },
      { name: 'Hazard: TM Pedestrians diversion', file: 'hazard TM - Pedestrians Diversion' },
      { name: 'Hazard: TM Road closure', file: 'hazard TM - Road Closure' },
    ],
  },
  {
    layerName: 'Blockage',
    layerItems: [
      { name: 'Blockage: First Blockage: ', file: 'field_work First Blockage' },
      { name: 'Blockage: Next Blockage', file: 'field_work Next Blockage' },
      { name: 'Blockage: Deslit', file: 'field_work Deslit' },
      { name: 'Blockage: Water', file: 'field_work Water' },
      { name: 'Blockage: Trial Hole', file: 'field_work Trial Hole' },
      { name: 'Blockage: Alternative Found', file: 'field_work Alternative Found' },
      { name: 'Blockage: Other', file: 'field_work Other' },
      { name: 'Blockage: No Photo', file: 'build_pole_nophoto' },
    ],
  },
  {
    layerName: 'Survey Structure',
    layerItems: [
      { name: 'Survey Structure: Relocate Asset', file: 'survey_structure Relocate Asset' },
      { name: 'Survey Structure: Missing Inventory', file: 'survey_structure Missing Inventory' },
      { name: 'Survey Structure: Non Existent', file: 'survey_structure Non Existent' },
      { name: 'Survey Structure: New Asset', file: 'survey_structure New Asset' },
      { name: 'Survey Structure: Not Needed', file: 'survey_structure_notneeded' },
      { name: 'Survey Structure: Resolved', file: 'survey_structure_resolved' },
    ],
  },
  {
    layerName: 'Survey Route',
    layerItems: [
      { name: 'Survey Route: Relocate Asset', file: 'survey_route Relocate Asset' },
      { name: 'Survey Route: Missing Inventory', file: 'survey_route Missing Inventory' },
      { name: 'Survey Route: Non Existent', file: 'survey_route Non Existent' },
      { name: 'Survey Route: New Asset', file: 'survey_route_newasset' },
      { name: 'Survey Route: Not Needed', file: 'survey_route_notneeded' },
      { name: 'Survey Route: Resolved', file: 'survey_structure_resolved' },
    ],
  },
  {
    layerName: 'Odin',
    layerItems: [
      { name: 'Odin: Lead', file: 'orders Lead' },
      { name: 'Odin: Pre-Order', file: 'orders Pre-Order' },
      { name: 'Odin: Install Scheduled', file: 'orders Install Scheduled' },
      { name: 'Odin: Active', file: 'orders Active' },
    ],
  },
  {
    layerName: 'PIA Structure',
    layerItems: [
      { name: 'PIA Structure: Exchange', file: 'pia_structure Exchange' },
      { name: 'PIA Structure: Cabinet', file: 'pia_structure Cabinet' },
      { name: 'PIA Structure: Manhole', file: 'pia_structure Manhole' },
      { name: 'PIA Structure: Jointing Chamber', file: 'pia_structure Jointing Chamber' },
      { name: 'PIA Structure: Small Jointing Chamber', file: 'pia_structure_smjointingchamber' },
      { name: 'PIA Structure: Pole', file: 'pia_structure Pole' },
      { name: 'PIA Structure: Pole - Hollow', file: 'pia_structure_polehollow' },
      { name: 'PIA Structure: Duct Tee', file: 'pia_structure Duct Tee' },
      { name: 'PIA Structure: Reducer', file: 'pia_structure Reducer' },
      { name: 'PIA Structure: Split Coupling', file: 'pia_structure Split coupling' },
      { name: 'PIA Structure: Change of State', file: 'pia_structure Change of State' },
    ],
  },
  {
    layerName: 'PIA Duct',
    layerItems: [
      { name: 'PIA Duct: ND', file: 'pia_duct ND' },
      { name: 'PIA Duct: Other', file: 'pia_duct other' },
    ],
  },
  {
    layerName: 'Residential',
    layerItems: [{ name: 'Addresses: Residential', file: 'Addresses Residential' }],
  },
  {
    layerName: 'Business',
    layerItems: [{ name: 'Addresses: Business', file: 'Addresses Business' }],
  },
  {
    layerName: 'Other',
    layerItems: [{ name: 'Addresses: Other', file: 'Addresses Other' }],
  },
  {
    layerName: 'Newly Added',
    layerItems: [{ name: 'Addresses: Newly Added', file: 'Addresses Newly Added' }],
  },
  {
    layerName: 'Topographic Line',
    layerItems: [{ name: 'Topographic Line', file: 'topographicline' }],
  },
];
