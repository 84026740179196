import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Button, Modal, Select, Spin } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import {
  ISearchRecords,
  IUpdateRecordById,
  searchRecordsRequest,
  updateRecordByIdRequest,
} from '@legacy/core/records/store/actions';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import AssociationDataTable from '@legacy/core/recordsAssociations/components/AssociationDataTable';
import { IRecordAssociationsReducer } from '@legacy/core/recordsAssociations/store/reducer';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import {
  getSchemaFromShortListBySchemaId,
  sortOptions,
} from '../../../../../../core/helpers/schemaHelpers';
import './styles.scss';

const { Option } = Select;

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  match: any;
  record: DbRecordEntityTransform;
  schemaReducer: ISchemaReducer;
  recordReducer: IRecordReducer;
  recordAssociationReducer: IRecordAssociationsReducer;
  updateRecord: any;
  alertMessage: any;
  searchRecords: any;
};

type State = {
  loading: boolean;
  isModalVisible: boolean;
  isConfirmModalVisible: boolean;
  buildStatus: string | null;
};

class FeatureBuildDoneModal extends React.Component<PropsType, State> {
  state: State = {
    loading: false,
    isModalVisible: false,
    isConfirmModalVisible: false,
    buildStatus: '7',
  };

  constructor(props: PropsType) {
    super(props);
  }

  showModal = () => {
    this.setState((prevState) => ({
      isModalVisible: !prevState.isModalVisible,
    }));
  };

  handleOk = () => {
    // if record has a BuildStatus property
    // set build status for feature to 7-build done
    const { schemaReducer, record, updateRecord, alertMessage } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    if (schema && this.state.buildStatus) {
      this.setState({
        loading: true,
      });
      return updateRecord(
        {
          schema: schema,
          recordId: record.id,
          createUpdate: {
            entity: record.entity,
            type: record.type,
            properties: {
              BuildStatus: this.state.buildStatus,
            },
          },
        },
        (res: DbRecordEntityTransform) => {
          this.handleCancel();
          this.fetchData();
        },
      );
    } else {
      this.handleCancel();
    }
  };

  handleCancel = () => {
    this.setState((prevState) => ({
      loading: false,
      isModalVisible: false,
      isConfirmModalVisible: false,
      buildStatus: '7',
    }));
  };

  renderBuildStatusForm = () => {
    const { schemaReducer, record } = this.props;
    const { buildStatus } = this.state;

    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    if (schema && schema.columns) {
      const column = schema?.columns?.find((elem) => elem.name === 'BuildStatus');
      return (
        <Select
          allowClear
          placeholder="select build status"
          key={column?.id}
          defaultValue={String(buildStatus)}
          style={{ width: '100%', marginBottom: 16, zIndex: 500 }}
          onChange={(val, option) => this.setState({ buildStatus: val })}
          getPopupContainer={(trigger) => {
            return trigger.parentNode;
          }}
        >
          {column?.options.sort(sortOptions).map((opt) => (
            <Option value={opt.value}>{opt.label}</Option>
          ))}
        </Select>
      );
    }
  };

  fetchData() {
    const { schemaReducer, searchRecords, record } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    if (schema) {
      searchRecords({
        schema: schema,
        searchQuery: {
          terms: null,
          fields: null,
          schemas: schema.id,
          sort: [{ schemaPosition: { order: 'desc' } }],
          boolean: {
            must: [
              {
                query_string: {
                  fields: ['properties.ExternalRef'],
                  query: getProperty(record, 'ExternalRef'),
                  default_operator: 'AND',
                  lenient: true,
                  analyze_wildcard: true,
                  boost: 1,
                },
              },
            ],
            must_not: [],
            should: [],
            filter: [],
          },
        },
      });
    }
  }

  render() {
    const { record } = this.props;
    const { isModalVisible, isConfirmModalVisible, loading } = this.state;
    return (
      <>
        <Modal
          className="build-change-confirm-modal"
          title={'Confirmation'}
          centered
          onOk={() => this.handleOk()}
          open={isConfirmModalVisible}
          confirmLoading={loading}
          onCancel={() =>
            this.setState({
              loading: false,
              isConfirmModalVisible: false,
            })
          }
        >
          {loading ? (
            <Spin>processing changes...</Spin>
          ) : (
            <p>By submitting this modal the build status is going to be updated</p>
          )}
        </Modal>

        {['CLOSURE', 'CABLE', 'DUCT', 'CHAMBER', 'ROPE', 'POLE', 'BLOCKAGE'].includes(
          String(record?.type),
        ) && (
          <Button disabled={isModalVisible} onClick={this.showModal}>
            Build Change
          </Button>
        )}
        <Modal
          className="build-change-modal"
          title="Build Change"
          centered
          destroyOnClose={true}
          maskClosable={false}
          open={isModalVisible}
          confirmLoading={loading}
          onOk={() => {
            this.setState({
              isConfirmModalVisible: true,
            });
          }}
          onCancel={this.handleCancel}
        >
          <RecordProperties columns={1} record={record} visibleProperties={['ExternalRef']} />
          <div style={{ marginTop: 20 }}>{this.renderBuildStatusForm()}</div>

          <div>
            <AssociationDataTable
              title="Files"
              record={record}
              moduleName="SchemaModule"
              entityName="File"
            />
          </div>
        </Modal>
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateRecord: (params: IUpdateRecordById, cb = () => {}) =>
    dispatch(updateRecordByIdRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  searchRecords: (params: ISearchRecords) => dispatch(searchRecordsRequest(params)),
});

export default withRouter(connect(mapState, mapDispatch)(FeatureBuildDoneModal));
