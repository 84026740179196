export const combineActions = <T extends string>(...actions: Record<string, Function>[]) =>
  actions.reduce<Record<T, Function>>(
    (combined, currentActions) => ({
      ...combined,
      ...currentActions,
    }),
    {} as Record<T, Function>,
  );

export const bindActions = <T extends string>(dispatch: Function, actions: Record<T, Function>) => {
  return Object.entries<Function>(actions).reduce((result, [key, action]) => {
    return {
      ...result,
      [key]: (...params: any[]) => dispatch(action(...params)),
    };
  }, {} as Record<T, Function>);
};
