import { IconProps, NonIdealState } from "@blueprintjs/core";

type EmptyProps = {
  icon: IconProps['icon'];
  text: string;
  minHeight?: number | string;
};

export const Empty = ({ icon, text, minHeight = 300 }: EmptyProps) => {
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      color: '#aaa',
      minHeight,
    }}>
      <NonIdealState icon={icon} title={text} />
    </div>
  )
}
