import { Provider } from 'react-redux';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';
import { ConfigProvider } from 'antd';
import en_GB from 'antd/es/locale/en_GB';
import en_US from 'antd/es/locale/en_US';
import sr_RS from 'antd/es/locale/sr_RS';
import { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import { persistor, store } from './store/configureStore';
import { ErrorBoundary } from '@sentry/react';

// Build the global object for Vite
window.global ||= window;

const userLang = navigator.language ? navigator.language.toLowerCase() : undefined;

// Set the global locale for antd components
// these are the supported locales, default is en_US
const setLocale = () => {
  if (userLang === 'en_gb') {
    return en_GB;
  } else if (userLang === 'sr_rs') {
    return sr_RS;
  } else {
    return en_US;
  }
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ErrorBoundary>
    <ConfigProvider locale={setLocale()}>
      <PersistGate loading={null} persistor={persistor}>
        <Provider store={store}>
          <Suspense fallback={<></>}>
            <App />
          </Suspense>
        </Provider>
      </PersistGate>
    </ConfigProvider>
  </ErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
