import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Empty, Row } from 'antd';
import { Alert, Button, Icon, InputGroup, Menu, MenuItem, Popover, Section, Spinner } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet, httpPatch, httpPost } from '@core/http/requests';
import { ItemRenderer, Select } from '@blueprintjs/select';
import DataSetList from './DataSetList';
import DataSetItem from './DataSetItem';
import AgentList from './AgentList';
import AgentItem from './AgentItem';
import { DndContext } from '@dnd-kit/core';
import DragOverlayItems from './DragOverlayItems';
import './index.scss';
import { INavigationReducer } from '@legacy/core/navigation/store/reducer';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List, VariableSizeList } from 'react-window';
import { useDebounceCallback } from '@core/hooks/useDebouceCallback';
import { IOpenRecordDrawer, openRecordDrawer } from '@legacy/core/userInterface/store/actions';
import { hasPermissions } from '@core/helpers/rbacRules';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

const { CRM_MODULE } = SchemaModuleTypeEnums;

interface Props {
  navigationReducer: INavigationReducer;
  openDrawer: (params: IOpenRecordDrawer) => void;
  userReducer: any;
  alertMessage: (params: { body: string; type: string }) => void;
}

const DataSetAssign: FC<Props> = (props: Props) => {
  const { navigationReducer, openDrawer, userReducer, alertMessage } = props;

  const [salesTerritories, setSalesTerritories] = useState<DbRecordEntityTransform[]>([]);
  const [dataSets, setDataSets] = useState<DbRecordEntityTransform[]>([]);
  const [agents, setAgents] = useState<DbRecordEntityTransform[]>([]);
  const [filteredDtaSets, setFilteredDataSets] = useState<DbRecordEntityTransform[]>([]);
  const [filteredAgents, setFilteredAgents] = useState<DbRecordEntityTransform[]>([]);

  const [isLoadingSalesTerritories, setIsLoadingSalesTerritories] = useState<boolean>(false);
  const [isLoadingData, setIsLoadinData] = useState<boolean>(false);
  const [isLoadingUnassignAll, setIsLoadingUnassignAll] = useState<boolean>(false);

  const [isUnassignConfirmationDialogVisible, setIsUnassignConfirmationDialogVisible] =
    useState<boolean>(false);

  const [selectedSalesTerritory, setSelectedSalesTerritory] = useState<
    DbRecordEntityTransform | undefined
  >(undefined);

  const [selectedDataSets, setSelectedDataSets] = useState<any[]>([]);
  const [selectedAgentDataSets, setSelectedAgentDataSets] = useState<any[]>([]);
  const [isDragging, setIsDragging] = useState<any>(null);
  const listRef = useRef<VariableSizeList<any>>(null);
  const rowHeights = useRef<any>(null);

  const [searchAgent, cancelSearchAgent, loadingSearchAgent] = useDebounceCallback((query) => {
    const lowercasedQuery = query.toLowerCase();
    const filtered = agents.filter((agent) => agent.title?.toLowerCase().includes(lowercasedQuery));
    setFilteredAgents(filtered);
  }, 500);

  const [searchDataset, cancelSearchDataset, loadingSearchDataset] = useDebounceCallback(
    (query) => {
      const lowercasedQuery = query.toLowerCase();
      const filtered = dataSets.filter((dataSet) =>
        dataSet.title?.toLowerCase().includes(lowercasedQuery),
      );
      setFilteredDataSets(filtered);
    },
    500,
  );

  const setRowHeight = useCallback((index, size) => {
    rowHeights.current = { ...rowHeights.current, [index]: size };
    listRef?.current?.resetAfterIndex(index);
  }, []);

  const getRowHeight = (index: number) => {
    const currentRowHeight = rowHeights.current?.[index];

    return currentRowHeight || 0;
  };
  // On Component mount fetch sales territories
  useEffect(() => {
    fetchSalesTerritories();
  }, []);
  const fetchSalesTerritories = async () => {
    setIsLoadingSalesTerritories(true);
    const response = await httpPost('CrmModule/v2.0/records/search', {
      query: {
        entity: 'CrmModule:SalesTerritory',
        type: 'and',
        value: [],
        returnProperties: ['id', 'title', 'createdAt', 'properties.*', 'createdBy'],
        pageSize: 1000,
        pageNumber: 0,
      },
    });
    const data = response.data.data.records;
    setSalesTerritories(data);
    setIsLoadingSalesTerritories(false);
  };

  const fetchSalesTerritoryDataSets = async (salesTerritoryId: string) => {
    return httpPost('CrmModule/v2.0/records/search', {
      query: {
        entity: 'CrmModule:CrmDataset',
        type: 'and',
        value: [
          {
            columnName: 'SalesTerritory',
            operator: 'eq',
            value: salesTerritoryId,
          },
        ],
        returnProperties: ['id', 'title', 'createdAt', 'properties.Owner'],
        pageSize: 1000,
        pageNumber: 0,
      },
    });
  };

  const fetchSalesTerritoryAgents = async (
    territory: DbRecordEntityTransform,
  ): Promise<DbRecordEntityTransform[]> => {
    return httpGet(
      `${CRM_MODULE}/v1.0/db-associations/SalesTerritory/${territory.id}/one-relation?entity=User&withLinks=false`,
    )
      .then((res: any) => {
        return res.data['User']?.dbRecords || [];
      })
      .catch((err: any) => {
        console.error('Error loading Users for the selected Sales territory.', err);
        return [];
      });
  };

  const filterSalesTerritories = (query: string, territory: any, _index: any, exactMatch: any) => {
    if (territory.properties) {
      const normalizedTitle = territory.title.toLowerCase();
      const normalizedQuery = query.toLowerCase();

      if (exactMatch) {
        return normalizedTitle === normalizedQuery;
      } else {
        return normalizedTitle.indexOf(normalizedQuery) >= 0;
      }
    }

    return false;
  };

  const handleSelectSalesTerritory = async (territory: DbRecordEntityTransform) => {
    setSelectedSalesTerritory(territory);
    setIsLoadinData(true);
    const stResponse = await fetchSalesTerritoryDataSets(territory.id);
    const agentResponse = await fetchSalesTerritoryAgents(territory);
    const datasets = stResponse.data.data.records;
    const agents: DbRecordEntityTransform[] = agentResponse;

    setDataSets(datasets);
    setFilteredDataSets(datasets);
    setAgents(agents);
    setFilteredAgents(agents);
    setIsLoadinData(false);
  };

  const renderSalesTerritory: ItemRenderer<any> = (
    territory: any,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }
    return (
      <MenuItem
        active={modifiers.active}
        disabled={modifiers.disabled}
        key={territory.id}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="listoption"
        text={territory.title}
      />
    );
  };

  const getDataSetPerAgent = (agentId: string) => {
    return filteredDtaSets.filter((ds) => ds.properties.Owner === agentId);
  };

  const getAgentPerDataSet = (agentId: string) => {
    return filteredAgents.filter((a) => a.id === agentId).map((agent) => agent.title);
  };

  const getAgentDatasetSelected = (agentId: string) => {
    return selectedAgentDataSets.filter((ds) => ds.properties.Owner === agentId);
  };

  function handleSelectAgentDataSet(values: any[]) {
    if (selectedDataSets.length > 0) {
      setSelectedDataSets([]);
    }
    setSelectedAgentDataSets(values);
  }

  function handleSelectDataSet(values: any[]) {
    if (selectedAgentDataSets.length > 0) {
      setSelectedAgentDataSets([]);
    }
    setSelectedDataSets(values);
  }

  function handleDragStart({ active }: { active: any }) {
    setIsDragging(active);
  }

  function handleDragEnd(event: any) {
    setIsDragging(null);
    if (event.over) {
      const removeDatasetFromAgent =
        event.active.id.indexOf('agent-dataset') > -1 &&
        event.over.id.indexOf('dataset-list') > -1 &&
        event.over.id.indexOf('assigned') === -1;
      const assignDataSetToAgent =
        event.over.id.indexOf('agent-dataset') > -1 && event.active.id.indexOf('dataset-item') > -1;
      if (assignDataSetToAgent) {
        addDataSetToAgent(selectedDataSets, event.over.id.split('agent-dataset-')[1]);
      } else if (removeDatasetFromAgent) {
        unAssignDataSet();
      }
      return;
    }
  }

  async function addDataSetToAgent(dataSets: any[], agentId: string) {
    const updateDtos = dataSets.map((el) => ({
      id: el.id,
      entity: 'CrmModule:CrmDataset',
      properties: {
        Owner: agentId,
      },
    }));

    const updatedRecords = await httpPatch('CrmModule/v2.0/records/update', {
      recordsToUpdate: updateDtos,
    });

    setSelectedDataSets([]);
    updateDataSets(updatedRecords.data.data, agentId);
  }

  async function unAssignDataSet() {
    const updateDtos = selectedAgentDataSets.map((el) => ({
      id: el.id,
      entity: 'CrmModule:CrmDataset',
      properties: {
        Owner: null,
      },
    }));

    const updatedRecords = await httpPatch('CrmModule/v2.0/records/update', {
      recordsToUpdate: updateDtos,
    });

    setSelectedAgentDataSets([]);
    updateDataSets(updatedRecords.data.data, null);
  }

  const updateDataSets = (updatedDataSets: any[], agentId: string | null) => {
    setFilteredDataSets((preDataSets) =>
      preDataSets.map((dataSet) => {
        const updatedItem = updatedDataSets.find((ud) => ud.id === dataSet.id);
        return updatedItem ? { ...dataSet, properties: { Owner: agentId } } : dataSet;
      }),
    );
  };

  const getListContainerHeight = () => {
    if (navigationReducer.tabHistory.length === 0) {
      return 'calc(100vh - 180px - 49px)';
    } else {
      return 'calc(100vh - 220px - 49px)';
    }
  };

  const selectAllAgentDatasets = (e: any, agent: any) => {
    if (e.target.checked) {
      const selectedValues = getDataSetPerAgent(agent.id);
      setSelectedAgentDataSets(selectedValues);
    } else {
      setSelectedAgentDataSets([]);
    }
  };

  const unassignAll = async () => {
    setIsLoadingUnassignAll(true);
    const updateDtos = dataSets.map((el) => ({
      id: el.id,
      entity: 'CrmModule:CrmDataset',
      properties: {
        Owner: null,
      },
    }));

    const updatedRecords = await httpPatch('CrmModule/v2.0/records/update', {
      recordsToUpdate: updateDtos,
    });

    setSelectedAgentDataSets([]);
    updateDataSets(updatedRecords.data.data, null);
    setIsLoadingUnassignAll(false);
    setIsUnassignConfirmationDialogVisible(false);
    alertMessage({ body: 'All Datasets successfully unassigned', type: 'success' });
  };

  const DataSetItemRow = ({ index, style }: any) => {
    const dataset = filteredDtaSets[index];

    return (
      <DataSetItem
        openDrawer={openDrawer}
        style={{ ...style, width: '100%', padding: 10 }}
        draggable={
          selectedDataSets.some((el) => el.id === dataset.id) &&
          hasPermissions(userReducer, ['crmmodule.crmdataset.assign.write'])
        }
        id="dataset-item"
        dataset={dataset}
        agents={getAgentPerDataSet(dataset.properties.Owner)}
        checkable={hasPermissions(userReducer, ['crmmodule.crmdataset.assign.write'])}
      />
    );
  };

  const DataSetAgentRow = (dataset: DbRecordEntityTransform, style: any) => {
    return (
      <DataSetItem
        openDrawer={openDrawer}
        style={{ ...style, width: '100%', padding: '4px 0' }}
        draggable={
          selectedAgentDataSets.some((el) => el.id === dataset.id) &&
          !isAllDataSetChecked(dataset.properties.Owner) &&
          hasPermissions(userReducer, ['crmmodule.crmdataset.assign.write'])
        }
        id="agent-dataset-item"
        dataset={dataset}
        key={dataset.id}
      />
    );
  };

  const isAllDataSetChecked = (agentId: string) =>
    getAgentDatasetSelected(agentId).length === getDataSetPerAgent(agentId).length &&
    getDataSetPerAgent(agentId).length > 0;

  const AgentRow = ({ index, style }: any) => {
    const agent = filteredAgents[index];

    const datasets = getDataSetPerAgent(agent.id);

    const rowRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      setRowHeight(index, rowRef.current?.scrollHeight);
    }, [rowRef, index]);

    return (
      <AgentItem
        style={{ ...style, padding: 10 }}
        checkable={
          datasets.length > 0 && hasPermissions(userReducer, ['crmmodule.crmdataset.assign.write'])
        }
        onCheck={selectAllAgentDatasets}
        selectedDataSet={getAgentDatasetSelected(agent.id)}
        onClickUnassign={unAssignDataSet}
        id={`agent-dataset-${agent.id}`}
        agent={agent}
        checked={isAllDataSetChecked(agent.id)}
        ref={rowRef}
        isDroppable={hasPermissions(userReducer, ['crmmodule.crmdataset.assign.write'])}
      >
        <DataSetList
          id="agent-dataset-list-assigned"
          style={{ width: '100%', height: '100%', paddingLeft: 10 }}
          selected={selectedAgentDataSets}
          onSelect={handleSelectAgentDataSet}
        >
          {datasets.map((dataset, index) =>
            DataSetAgentRow(dataset, {
              marginTop: index === 0 ? 5 : 0,
              marginBottom: index === datasets.length - 1 ? 10 : 0,
            }),
          )}
        </DataSetList>
      </AgentItem>
    );
  };

  if (isLoadingSalesTerritories) return <></>;
  return (
    <Row>
      {/* Header */}
      <Col span={24}>
        {/* Alert - Unassign All Datasets */}{' '}
        <Alert
          intent="danger"
          onCancel={() => setIsUnassignConfirmationDialogVisible(false)}
          isOpen={isUnassignConfirmationDialogVisible}
          cancelButtonText="Cancel"
          confirmButtonText="Unassign"
          canEscapeKeyCancel={!isLoadingUnassignAll}
          canOutsideClickCancel={!isLoadingUnassignAll}
          loading={isLoadingUnassignAll}
          onConfirm={unassignAll}
        >
          <p>Are you sure you want to unassign all Datasets?</p>
        </Alert>
        <Section
          title="Dataset Assign"
          rightElement={
            <Row gutter={10}>
              {/* Button - Unassign All Datasets */}
              {selectedSalesTerritory?.title && (
                <Col>
                  <Popover
                    content={
                      <Menu>
                        <MenuItem
                          intent="danger"
                          text="Unassign all Datasets"
                          disabled={
                            !hasPermissions(userReducer, ['crmmodule.crmdataset.assign.write'])
                          }
                          onClick={() => setIsUnassignConfirmationDialogVisible(true)}
                        />
                      </Menu>
                    }
                    fill={true}
                    placement="bottom"
                  >
                    <Button icon="caret-down" />
                  </Popover>
                  {/*<Button*/}
                  {/*  outlined*/}
                  {/*  loading={isLoadingUnassignAll}*/}
                  {/*  text="Unassign all Datasets"*/}
                  {/*  rightIcon="remove"*/}
                  {/*  intent="danger"*/}
                  {/*  onClick={() => setIsUnassignConfirmationDialogVisible(true)}*/}
                  {/*  disabled={!hasPermissions(userReducer, ['crmmodule.crmdataset.assign.write'])}*/}
                  {/*/>*/}
                </Col>
              )}

              {/* Select - Sales Territory Selection */}
              <Col>
                <Select
                  items={salesTerritories}
                  itemPredicate={filterSalesTerritories}
                  itemRenderer={renderSalesTerritory}
                  noResults={
                    <MenuItem disabled={true} text="No results." roleStructure="listoption" />
                  }
                  onItemSelect={handleSelectSalesTerritory}
                >
                  <Button
                    text={
                      selectedSalesTerritory?.title
                        ? `${selectedSalesTerritory.title}`
                        : 'Sales Territory'
                    }
                    rightIcon="double-caret-vertical"
                    placeholder="Select a territory"
                  />
                </Select>
              </Col>
            </Row>
          }
        />
      </Col>
      {/* Bottom Panel */}
      <Col span={24} style={{ padding: 15 }}>
        <Row gutter={12}>
          <DndContext onDragEnd={handleDragEnd} onDragStart={handleDragStart}>
            <DragOverlayItems items={selectedDataSets} />
            <DragOverlayItems items={selectedAgentDataSets} />
            <Col span={12}>
              <Section
                title="Datasets"
                rightElement={
                  <InputGroup
                    round
                    small
                    placeholder="Quick Search"
                    type="search"
                    leftElement={
                      loadingSearchDataset ? (
                        <Spinner className="bp5-icon" size={16} />
                      ) : (
                        <Icon icon="search" />
                      )
                    }
                    onValueChange={searchDataset}
                    onReset={() => searchDataset('')}
                    disabled={dataSets.length === 0}
                  />
                }
              >
                <div
                  style={{
                    height: getListContainerHeight(),
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                  }}
                >
                  {filteredDtaSets.length && !isLoadingData ? (
                    <AutoSizer>
                      {({ height, width }: any) => (
                        <DataSetList
                          id="dataset-list"
                          selected={selectedDataSets}
                          onSelect={handleSelectDataSet}
                          style={{ width, height }}
                          isDropable={hasPermissions(userReducer, [
                            'crmmodule.crmdataset.assign.write',
                          ])}
                        >
                          <List
                            itemSize={30}
                            height={height}
                            itemCount={filteredDtaSets.length}
                            width={width}
                            overscanCount={8}
                          >
                            {DataSetItemRow}
                          </List>
                        </DataSetList>
                      )}
                    </AutoSizer>
                  ) : (
                    <Row style={{ height: '100%' }} justify="center" align="middle">
                      {isLoadingData ? (
                        <Spinner size={25} />
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </Row>
                  )}
                </div>
              </Section>
            </Col>

            <Col span={12}>
              <Section
                title="Users"
                rightElement={
                  <InputGroup
                    round
                    small
                    placeholder="Quick Search"
                    type="search"
                    // leftIcon={!loadingSearchAgent ? "search": undefined}
                    leftElement={
                      loadingSearchAgent ? (
                        <Spinner className="bp5-icon" size={16} />
                      ) : (
                        <Icon icon="search" />
                      )
                    }
                    onValueChange={searchAgent}
                    onReset={() => searchAgent('')}
                    disabled={agents.length === 0}
                  />
                }
              >
                <div
                  style={{
                    height: getListContainerHeight(),
                    overflowY: 'hidden',
                    overflowX: 'hidden',
                  }}
                >
                  {filteredDtaSets.length && !isLoadingData ? (
                    <AutoSizer>
                      {({ height, width }: any) => (
                        <AgentList
                          width={width}
                          height={height}
                          itemSize={getRowHeight}
                          ref={listRef}
                          itemCount={filteredAgents.length}
                        >
                          {AgentRow}
                        </AgentList>
                      )}
                    </AutoSizer>
                  ) : (
                    <Row style={{ height: '100%' }} justify="center" align="middle">
                      {isLoadingData ? (
                        <Spinner size={25} />
                      ) : (
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                      )}
                    </Row>
                  )}
                </div>
              </Section>
            </Col>
          </DndContext>
        </Row>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  navigationReducer: state.navigationReducer,
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(DataSetAssign);
