import { Image as ImageLayer, Tile as TileLayer } from 'ol/layer';
import LayerGroup from 'ol/layer/Group';
import ImageWMS from 'ol/source/ImageWMS';
import XYZ from 'ol/source/XYZ';

const VITE_QGIS_SERVER_URL =
  import.meta.env.VITE_QGIS_SERVER_URL ||
  'https://api.odin.prod.netomnia.com/cgi-bin/qgis_mapserv.fcgi?map=/home/qgis/projects/project.qgs';

const minZoom = {
  polygon: 5,
  line: 10,
  point: 10,
};

const debounce = 300;

export const mapLayers = [
  /* PLAN ***********************/
  new LayerGroup({
    title: 'Plan',
    fold: 'close',
    zIndex: 9999,
    layers: [
      new LayerGroup({
        title: 'Point',
        fold: 'close',
        layers: [
          // Plan -> Closure
          new LayerGroup({
            title: 'Closure',
            fold: 'close',
            layers: [
              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 1 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'L0',
                legend: 'Closure: L0',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 2 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'L1',
                legend: 'Closure: L1',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 3 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'L2',
                legend: 'Closure: L2',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 4 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'L3',
                legend: 'Closure: L3',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 5 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'L4',
                legend: 'Closure: L4',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 11 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'LM',
                legend: 'Closure: LM',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 7 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'LC',
                legend: 'Closure: LC',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 6 AND "eco" = 'true' AND "created_at" < '2023-03-01'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'LX/C',
                legend: 'Closure: LX/C',
                visible: false,
              }),

              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['closure'],
                    FILTER: `closure:"type_id" = 6 AND "eco" = 'true' AND "created_at" > '2023-03-01'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'LX/Cnew',
                legend: 'Closure: LX/Cnew',
                visible: false,
              }),
            ],
          }),

          // Plan -> Point -> Pole
          new LayerGroup({
            title: 'Pole',
            fold: 'close',
            layers: [
              // Plan -> Pole -> Medium
              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['pole'],
                    FILTER: `pole:"model_id" = 3 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Medium',
                legend: 'Pole: Medium',
                visible: false,
              }),

              // Plan -> Pole -> Light
              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['pole'],
                    FILTER: `pole:"model_id" = 4 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Light',
                legend: 'Pole: Light',
                visible: false,
              }),

              // Plan -> Pole -> Stout
              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['pole'],
                    FILTER: `pole:"model_id" = 5 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Stout',
                legend: 'Pole: Stout',
                visible: false,
              }),

              // Plan -> Pole -> Openreach
              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['pole'],
                    FILTER: `pole:"model_id" = '6' AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Openreach - UI',
                legend: 'Pole: Openreach - UI',
                visible: false,
              }),

              // Plan -> Pole -> NoStreetView
              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['pole'],
                    FILTER: `pole:"model_id" != 6 AND "eco" = 'true' AND "streetview_url" IS NULL`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'No Street View URL',
                legend: 'Pole: No Street View URL',
                visible: false,
              }),

              // Plan -> Pole -> JUP
              new ImageLayer({
                zIndex: 9989,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['pole'],
                    FILTER: `pole:"model_id" = 2 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'JUP',
                legend: 'Pole: JUP',
                visible: false,
              }),
            ],
          }),

          // Plan - Point -> Pole SDED
          new ImageLayer({
            zIndex: 9989,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['sdeds_poles'],
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Pole SDEDS',
            legend: 'Pole SDEDS',
            visible: false,
          }),

          new ImageLayer({
            zIndex: 9979,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['chamber'],
                FILTER: `chamber:"eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Chamber',
            legend: 'Chamber',
            visible: false,
          }),

          new ImageLayer({
            zIndex: 9989,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['exchange'],
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Exchange',
            legend: 'Exchange',
            visible: true,
          }),
        ],
      }),
      new LayerGroup({
        title: 'Line',
        fold: 'close',
        zIndex: 8888,
        minZoom: minZoom.line,
        layers: [
          new LayerGroup({
            title: 'Cable',
            fold: 'close',
            zIndex: 8878,
            layers: [
              /* Plan -> Cable -> Spine */
              new ImageLayer({
                zIndex: 8878,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['cable'],
                    FILTER: `cable:"type_id" = 1 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Spine',
                legend: 'Spine',
                visible: false,
              }),

              /* Plan -> Cable -> Distribution */
              new ImageLayer({
                zIndex: 8878,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['cable'],
                    FILTER: `cable:"type_id" = 2 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Distribution',
                legend: 'Distribution',
                visible: false,
              }),

              /* Plan -> Cable -> Access */
              new ImageLayer({
                zIndex: 8878,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['cable'],
                    FILTER: `cable:"type_id" = 3 AND "ground_id" IN ( 1 , 2 ) AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Access',
                legend: 'Access',
                visible: false,
              }),

              /* Plan -> Cable -> Feed */
              new ImageLayer({
                zIndex: 8878,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['cable'],
                    FILTER: `cable:"type_id" = 4 AND "ground_id" IN ( 1 , 2 ) AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Feed',
                legend: 'Feed',
                visible: false,
              }),

              /* Plan -> Cable -> CableLink */
              new ImageLayer({
                zIndex: 8878,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['cable'],
                    FILTER: `cable:"type_id" = 7`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'CableLink',
                legend: 'CableLink',
                visible: false,
              }),

              /* Plan -> Cable -> Backbone */
              new ImageLayer({
                zIndex: 8878,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['cable'],
                    FILTER: `cable:"type_id" = 8`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Backbone',
                legend: 'Backbone',
                visible: false,
              }),
            ],
          }),

          /* Plan -> Ducts */
          new LayerGroup({
            title: 'Ducts',
            fold: 'close',
            zIndex: 7777,
            layers: [
              /* Plan -> Ducts -> Duct */
              new ImageLayer({
                zIndex: 8868,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['duct'],
                    FILTER: `duct:"type_id" = 1 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Duct',
                legend: 'Duct',
                visible: false,
              }),

              /* Plan -> Ducts -> Subduct */
              new ImageLayer({
                zIndex: 8868,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['duct'],
                    FILTER: `duct:"type_id" = 2 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Subduct',
                legend: 'Subduct',
                visible: false,
              }),

              /* Plan -> Ducts -> Lead-in */
              new ImageLayer({
                zIndex: 8868,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['duct'],
                    FILTER: `duct:"type_id" = 3 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Lead-in',
                legend: 'Lead-in',
                visible: false,
              }),

              /* Plan -> Ducts -> Openreach */
              new ImageLayer({
                zIndex: 8868,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['duct'],
                    FILTER: `duct:"type_id" = 4 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Openreach',
                legend: 'Openreach',
                visible: false,
              }),

              /* Plan -> Ducts -> Backbone */
              new ImageLayer({
                zIndex: 8868,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['duct'],
                    FILTER: `duct:"type_id" = 5 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Backbone',
                legend: 'Backbone',
                visible: false,
              }),

              /* Plan -> Ducts -> Commsworld */
              new ImageLayer({
                zIndex: 8868,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['duct'],
                    FILTER: `duct:"type_id" = 6 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Commsworld',
                legend: 'Commsworld',
                visible: false,
              }),
            ],
          }),

          /* Plan -> Line -> Rope */
          new LayerGroup({
            title: 'Rope',
            fold: 'close',
            zIndex: 7777,
            layers: [
              /* Plan -> Line -> Rope -> SND */
              new ImageLayer({
                zIndex: 8858,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['rope'],
                    FILTER: `rope:"type_id" IN ( 1 , 2 ) AND "eco" = 'true' AND ( "on_hold_category" != 12 OR "on_hold_category" IS NULL )`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'SND Rope',
                legend: 'Rope: SND',
                visible: false,
              }),

              /* Plan -> Line -> Rope -> Access */
              new ImageLayer({
                zIndex: 8858,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['rope'],
                    FILTER: `rope:"type_id" = 3 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Access Rope',
                legend: 'Rope: Access',
                visible: false,
              }),

              /* Plan -> Line -> Rope -> Feed */
              new ImageLayer({
                zIndex: 8858,
                source: new ImageWMS({
                  url: VITE_QGIS_SERVER_URL,
                  params: {
                    LAYERS: ['rope'],
                    FILTER: `rope:"type_id" = 4 AND "eco" = 'true'`,
                  },
                  ratio: 1,
                  serverType: 'qgis',
                  imageLoadFunction: function (image, src) {
                    this.timeout && clearTimeout(this.timeout);
                    this.timeout = setTimeout(() => {
                      image.getImage().src = src;
                    }, debounce);
                  },
                }),
                title: 'Feed Rope',
                legend: 'Rope: Feed',
                visible: false,
              }),
            ],
          }),
        ],
      }),
    ],
  }),

  /* BUILD ***********************/
  new LayerGroup({
    title: 'Build',
    fold: 'close',
    zIndex: 7777,
    layers: [
      new LayerGroup({
        title: 'Point',
        fold: 'close',
        minZoom: minZoom.point,
        layers: [
          new ImageLayer({
            zIndex: 7767,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['build_closure'],
                FILTER: `build_closure:"eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Build Closure',
            legend: 'Build Closure',
            visible: false,
          }),
          new ImageLayer({
            zIndex: 7757,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['build_chamber'],
                FILTER: `build_chamber:"eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Build Chamber',
            legend: 'Build Chamber',
            visible: false,
          }),
          new ImageLayer({
            zIndex: 7747,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['build_pole'],
                FILTER: `build_pole:"eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Build Pole',
            legend: 'Build Pole',
            visible: false,
          }),
        ],
      }),
      new LayerGroup({
        title: 'Line',
        fold: 'close',
        zIndex: 6666,
        minZoom: minZoom.line,
        layers: [
          new ImageLayer({
            zIndex: 6656,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['build_cable'],
                FILTER: `build_cable:"eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Build Cable',
            legend: 'Build Cable',
            visible: false,
          }),
          new ImageLayer({
            zIndex: 6646,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['build_duct'],
                FILTER: `build_duct:"type_id" = 1 AND "eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Build Duct',
            legend: 'Build Duct',
            visible: false,
          }),
          new ImageLayer({
            zIndex: 6646,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['build_duct'],
                FILTER: `build_duct:"type_id" = 2 AND "eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Build Subduct',
            legend: 'Build Subduct',
            visible: false,
          }),
          new ImageLayer({
            zIndex: 6636,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['build_rope'],
                FILTER: `build_rope:"eco" = 'true'`,
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'Build Rope',
            legend: 'Build Rope',
            visible: false,
          }),
        ],
      }),
    ],
  }),

  /* POLYGON ***********************/
  new LayerGroup({
    title: 'Polygon',
    fold: 'close',
    zIndex: 1000,
    layers: [
      new ImageLayer({
        zIndex: 1000,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['polygon'],
            FILTER: `polygon:"name" = 'EX' AND "eco" = 'true'`,
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'EX',
        legend: 'Polygon: EX',
        visible: false,
      }),

      new ImageLayer({
        zIndex: 1000,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['polygon'],
            FILTER: `polygon:"name" = 'L1' AND "eco" = 'true'`,
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'L1',
        legend: 'Polygon: L1',
        visible: false,
      }),

      new ImageLayer({
        zIndex: 1000,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['polygon'],
            FILTER: `polygon:"name" = 'L2' AND "eco" = 'true'`,
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'L2',
        legend: 'Polygon: L2',
        visible: false,
      }),

      new ImageLayer({
        zIndex: 1000,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['polygon'],
            FILTER: `polygon:"name" = 'L3' AND "eco" = 'true'`,
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'L3',
        legend: 'Polygon: L3',
        visible: false,
      }),

      new ImageLayer({
        zIndex: 1000,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['polygon'],
            FILTER: `polygon:"name" = 'L4' OR "name" = 'L4JUP' AND "eco" = 'true'`,
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'L4',
        legend: 'Polygon: L4',
        visible: false,
      }),

      new ImageLayer({
        zIndex: 1000,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['polygon'],
            FILTER: `polygon:"name" = 'LM' AND "eco" = 'true'`,
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'LM',
        legend: 'Polygon: LM',
        visible: false,
      }),
    ],
  }),

  /* SURVEY ***********************/
  new LayerGroup({
    title: 'Survey',
    fold: 'close',
    zIndex: 4500,
    layers: [
      /* SURVEY / HAZARD */
      new ImageLayer({
        zIndex: 4400,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: 'hazard',
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Hazard',
        legend: 'Hazard',
        visible: false,
      }),

      /* SURVEY / BLOCKAGE */
      new ImageLayer({
        zIndex: 4300,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: 'blockage',
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Blockage',
        legend: 'Blockage',
        visible: false,
      }),

      /* SURVEY / SURVEY STRUCTURE */
      new ImageLayer({
        zIndex: 4200,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: 'survey_structure',
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Survey Structure',
        legend: 'Survey Structure',
        visible: false,
      }),

      /* SURVEY / SURVEY ROUTE */
      new ImageLayer({
        zIndex: 4100,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: 'survey_route',
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Survey Route',
        legend: 'Survey Route',
        visible: false,
      }),
    ],
  }),

  /* ODIN ***********************/
  new ImageLayer({
    zIndex: 3000,
    source: new ImageWMS({
      url: VITE_QGIS_SERVER_URL,
      params: {
        LAYERS: ['orders'],
      },
      ratio: 1,
      serverType: 'qgis',
      imageLoadFunction: function (image, src) {
        this.timeout && clearTimeout(this.timeout);
        this.timeout = setTimeout(() => {
          image.getImage().src = src;
        }, debounce);
      },
    }),
    title: 'Odin',
    legend: 'Odin',
    visible: false,
  }),

  /* OPENREACH ***********************/
  new LayerGroup({
    title: 'Openreach',
    visible: false,
    fold: 'close',
    zIndex: 2500,
    layers: [
      new ImageLayer({
        zIndex: 2400,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['pia_structure'],
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'PIA Structure',
        legend: 'PIA Structure',
        visible: false,
      }),
      new ImageLayer({
        zIndex: 2300,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['pia_duct'],
          },
          ratio: 1,
          serverType: 'qgis',
          imageLoadFunction: function (image, src) {
            this.timeout && clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
              image.getImage().src = src;
            }, debounce);
          },
        }),
        title: 'PIA Duct',
        legend: 'PIA Duct',
        visible: false,
      }),
    ],
  }),

  /* PLAN ***********************/
  new LayerGroup({
    title: 'Misc',
    fold: 'close',
    zIndex: 9999,
    layers: [
      // Misc -> Commsworld
      new LayerGroup({
        title: 'Temp',
        fold: 'close',
        layers: [
          new ImageLayer({
            zIndex: 9989,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['temp_cw_chamber'],
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'temp_cw_chamber',
            visible: false,
          }),
          new ImageLayer({
            zIndex: 9989,
            source: new ImageWMS({
              url: VITE_QGIS_SERVER_URL,
              params: {
                LAYERS: ['temp_cw_duct'],
              },
              ratio: 1,
              serverType: 'qgis',
              imageLoadFunction: function (image, src) {
                this.timeout && clearTimeout(this.timeout);
                this.timeout = setTimeout(() => {
                  image.getImage().src = src;
                }, debounce);
              },
            }),
            title: 'temp_cw_duct',
            visible: false,
          }),
        ],
      }),
    ],
  }),

  /* ADDRESSES ***********************/
  new LayerGroup({
    title: 'Addresses',
    fold: 'close',
    zIndex: 1500,
    layers: [
      new ImageLayer({
        zIndex: 1300,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['Addresses'],
            FILTER: `Addresses:"class_type" = 'Residential' AND "sprn" < 38900137`,
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Residential',
        legend: 'Residential',
        visible: false,
      }),
      new ImageLayer({
        zIndex: 1300,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['Addresses'],
            FILTER: `Addresses:"class_type" = 'Business' AND "sprn" < 38900137`,
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Business',
        legend: 'Business',
        visible: false,
      }),
      new ImageLayer({
        zIndex: 1300,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['Addresses'],
            FILTER: `Addresses:NOT "class_type" = 'Residential' AND NOT "class_type" = 'Business'`,
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Other',
        legend: 'Other',
        visible: false,
      }),
      new ImageLayer({
        zIndex: 1300,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: {
            LAYERS: ['Addresses'],
            FILTER: `Addresses:"sprn" > 38900137`,
          },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Newly Added',
        legend: 'Newly Added',
        visible: false,
      }),
    ],
  }),

  /* OS ***********************/
  new LayerGroup({
    title: 'OS',
    fold: 'close',
    zIndex: 1500,
    layers: [
      new ImageLayer({
        zIndex: 1300,
        source: new ImageWMS({
          url: VITE_QGIS_SERVER_URL,
          params: { LAYERS: ['topographicline'] },
          ratio: 1,
          serverType: 'qgis',
        }),
        title: 'Topographic Line',
        legend: 'Topographic Line',
        visible: false,
      }),
    ],
  }),

  /* MAPS ***********************/
  new LayerGroup({
    title: 'Maps',
    zIndex: -100,
    fold: 'close',
    layers: [
      new TileLayer({
        source: new XYZ({
          url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
        }),
        title: 'Google Roads',
        visible: true,
        type: 'base',
        zIndex: -100,
        className: 'googleMaps',
      }),
      new TileLayer({
        source: new XYZ({
          url: 'https://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
        }),
        title: 'Google Satelite',
        visible: false,
        type: 'base',
        zIndex: -100,
        className: 'googleMaps',
      }),
      new TileLayer({
        source: new XYZ({
          url: 'https://mt0.google.com/vt/lyrs=y&hl=en&x={x}&y={y}&z={z}',
        }),
        title: 'Google Hybrid',
        visible: false,
        type: 'base',
        zIndex: -100,
        className: 'googleMaps',
      }),
      new TileLayer({
        source: new XYZ({
          url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
        }),
        title: 'OpenStreetMap',
        visible: false,
        type: 'base',
        zIndex: -100,
        className: 'googleMaps',
      }),
    ],
  }),
];
