export const linearTicketFormFields = (
  fullName: string,
  uniqueLabels: { value: string; label: string }[],
  defaultLabelValue: string,
  isLabelDisabled: boolean,
  descriptionDefaultValue?: string,
  titleDefaultValue?: string,
  priorityDefaultValue?: string,
) => [
  {
    label: 'What is the objective?',
    property: 'title',
    type: 'TEXT',
    isRequired: true,
    description: 'Short objective of the work to be done',
    message: 'Please enter the objective of the ticket',
    value: titleDefaultValue || undefined,
    initialValue: titleDefaultValue || undefined,
    defaultValue: titleDefaultValue || undefined,
  },
  {
    label: 'What needs to be done?',
    property: 'description',
    type: 'TEXT_LONG',
    isRequired: true,
    message:
      'Please explain the issue in detail\n\n• Why this has been created\n• What needs to be done',
    value: descriptionDefaultValue || undefined,
    initialValue: descriptionDefaultValue || undefined,
    defaultValue: descriptionDefaultValue || undefined,
  },
  {
    label: 'Upload files if needed',
    property: 'files',
    type: 'FILE_MULTIPLE',
    isRequired: false,
    message:
      'Please explain the ticket in detail\n\n• Why this has been created\n• What needs to be done',
    value: undefined,
  },
  {
    label: 'Priority',
    property: 'priority',
    type: 'ENUM',
    isRequired: false,
    message: 'Please select ticket Priority',
    initialValue: priorityDefaultValue || '0',
    value: priorityDefaultValue || '0',
    defaultValue: priorityDefaultValue || '0',
    options: [
      {
        value: '4',
        label: 'Low',
      },
      {
        value: '3',
        label: 'Medium',
      },
      {
        value: '2',
        label: 'High',
      },
      {
        value: '1',
        label: 'Urgent',
      },
      {
        value: '0',
        label: 'No Priority',
      },
    ],
  },
  {
    label: 'Your Name',
    property: 'name',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input your name',
    value: fullName || undefined,
    isDisabled: true,
  },
  {
    label: 'Label',
    property: 'label',
    type: 'ENUM',
    isRequired: true,
    isDisabled: isLabelDisabled,
    message: 'Please select ticket label',
    initialValue: defaultLabelValue,
    value: defaultLabelValue,
    defaultValue: defaultLabelValue,
    options: uniqueLabels,
  },
];
