import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

const AFP_APPROVAL_LOAD_AFP_REQUEST = 'AFP_APPROVAL_LOAD_AFP_REQUEST';
const AFP_APPROVAL_LOAD_AFP_RESPONSE = 'AFP_APPROVAL_LOAD_AFP_RESPONSE';
const AFP_APPROVAL_LOAD_AFP_ERROR = 'AFP_APPROVAL_LOAD_AFP_ERROR';

type LoadAFPRequest = {
  type: typeof AFP_APPROVAL_LOAD_AFP_REQUEST;
  payload: undefined;
};

type LoadAFPResponse = {
  type: typeof AFP_APPROVAL_LOAD_AFP_RESPONSE;
  payload: DbRecordEntityTransform;
};

type LoadAFPError = {
  type: typeof AFP_APPROVAL_LOAD_AFP_ERROR;
  payload: { message: string };
};

export type LoadAFPActions = LoadAFPRequest | LoadAFPResponse | LoadAFPError;

export const loadAFPRequest = (): LoadAFPRequest => ({
  type: AFP_APPROVAL_LOAD_AFP_REQUEST,
  payload: undefined,
});

export const loadAFPResponse = (payload: DbRecordEntityTransform): LoadAFPResponse => ({
  type: AFP_APPROVAL_LOAD_AFP_RESPONSE,
  payload,
});

export const loadAFPError = (payload: { message: string }): LoadAFPError => ({
  type: AFP_APPROVAL_LOAD_AFP_ERROR,
  payload,
});
