import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { initialOrderSummary, TOrderSummary } from '../types';
import {
  REMOVE_ORDER_ITEM,
  RESET_ORDER_BUILDER_STATE,
  SET_ACCOUNT,
  SET_ADDRESS,
  SET_CONTACT,
  SET_DISCOUNT,
  SET_DISCOUNT_LIST,
  SET_IS_APPOINTMENT_CONFIRMED,
  SET_IS_CREATING_ORDER,
  SET_IS_DISCOUNT_APPLIED,
  SET_IS_LOA_ACCEPTED,
  SET_IS_ORDER_CREATED,
  SET_LOADING_OFFERS,
  SET_LOADING_PARENT_RECORD_ASSOCIATIONS,
  SET_LOADING_PRODUCTS,
  SET_OFFER_LIST,
  SET_OFFER_PRODUCT_LIST,
  SET_ORDER_ID,
  SET_ORDER_ITEMS,
  SET_ORDER_SUMMARY,
  SET_PAYMENT_METHOD,
  SET_PHONE_NUM_2,
  SET_PHONE_NUM_3,
  SET_PHONE_PANEL_REF,
  SET_RECONTRACTED_ORDER_ID,
  SET_RECONTRACTING_IS_RESET,
  SET_RECONTRACTING_QUOTE,
  SET_SELECTED_OFFER,
} from './constants';

type TRecontractingQuote = {
  billingStartDate: string;
  nextOffer: {
    id: string;
    title: string;
    Product: {
      dbRecords: DbRecordEntityTransform[];
    };
  };
  nextProducts: DbRecordEntityTransform[];
  previousProducts: DbRecordEntityTransform[];
  serviceStartDate: string;
  skuDeviceMap: any;
  skuPriceChangeMap: any;
};

interface IOrderBuilderReducer {
  account: DbRecordEntityTransform | undefined;
  address: DbRecordEntityTransform | undefined;
  contact: DbRecordEntityTransform | undefined;
  discount: DbRecordEntityTransform | undefined;
  discountList: DbRecordEntityTransform[];
  isCreatingOrder: boolean;
  isLoadingOffers: boolean;
  isLoadingParentRecordAssociations: boolean;
  isLoadingProducts: boolean;
  isOrderCreated: boolean;
  LOAAccepted: boolean;
  offer: DbRecordEntityTransform | undefined;
  offerList: DbRecordEntityTransform[];
  offerProductList: DbRecordEntityTransform[];
  orderId: string | undefined;
  orderItems: DbRecordEntityTransform[];
  orderRecord: DbRecordEntityTransform | undefined;
  orderSummary: TOrderSummary | undefined;
  recontractedOrderId: string | undefined;
  paymentMethod: DbRecordEntityTransform | undefined;
  phoneNum1: string;
  phoneNum2: string;
  phoneNum3: string;
  phoneTransferPanelRef: HTMLDivElement | undefined;
  isAppointmentConfirmed: boolean;
  recontractingQuote: TRecontractingQuote | undefined;
  recontractingIsReset: boolean; // Still in recontracting, but order offer changed
}

export const OrderBuilderInitialState: IOrderBuilderReducer = {
  account: undefined,
  address: undefined,
  contact: undefined,
  discount: undefined,
  discountList: [],
  isCreatingOrder: false,
  isLoadingOffers: true,
  isLoadingParentRecordAssociations: true,
  isLoadingProducts: false,
  isOrderCreated: false,
  LOAAccepted: false,
  offer: undefined,
  offerList: [],
  offerProductList: [],
  orderId: undefined,
  orderItems: [],
  orderRecord: undefined,
  orderSummary: initialOrderSummary,
  paymentMethod: undefined,
  recontractedOrderId: undefined,
  phoneNum1: '+44',
  phoneNum2: '',
  phoneNum3: '',
  phoneTransferPanelRef: undefined,
  isAppointmentConfirmed: false,
  recontractingQuote: undefined,
  recontractingIsReset: false,
};

export function orderBuilderReducer(
  state: IOrderBuilderReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case SET_LOADING_OFFERS:
      return {
        ...state,
        isLoadingOffers: action.payload,
      };

    case SET_LOADING_PARENT_RECORD_ASSOCIATIONS:
      return {
        ...state,
        isLoadingParentRecordAssociations: action.payload,
      };

    case SET_LOADING_PRODUCTS:
      return {
        ...state,
        isLoadingProducts: action.payload,
      };

    case SET_OFFER_LIST:
      return {
        ...state,
        offerList: action.payload,
      };

    case SET_OFFER_PRODUCT_LIST:
      return {
        ...state,
        offerProductList: action.payload,
      };

    case SET_SELECTED_OFFER:
      return {
        ...state,
        offer: action.payload,
      };

    case REMOVE_ORDER_ITEM:
      return {
        ...state,
        orderItems: state.orderItems.filter(
          (item: DbRecordEntityTransform) => item.id !== action.payload,
        ),
      };

    case SET_ORDER_ITEMS:
      return {
        ...state,
        orderItems: action.payload,
      };

    case SET_ORDER_SUMMARY:
      return {
        ...state,
        orderSummary: action.payload,
      };

    case SET_DISCOUNT:
      return {
        ...state,
        discount: action.payload,
      };

    case SET_IS_DISCOUNT_APPLIED:
      return {
        ...state,
        isDiscountApplied: action.payload,
      };

    case SET_CONTACT:
      return {
        ...state,
        contact: action.payload,
      };

    case SET_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };

    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };

    case SET_IS_ORDER_CREATED:
      return {
        ...state,
        isCreatingOrder: false,
        isOrderCreated: action.payload,
        offerProductList: [],
        discountList: [],
        offerList: [],
        offer: undefined,
      };

    case SET_ORDER_ID:
      return {
        ...state,
        orderId: action.payload,
      };

    case SET_RECONTRACTED_ORDER_ID:
      return {
        ...state,
        recontractedOrderId: action.payload,
      };

    case SET_IS_CREATING_ORDER:
      return {
        ...state,
        isCreatingOrder: action.payload,
      };

    case SET_IS_LOA_ACCEPTED:
      return {
        ...state,
        LOAAccepted: action.payload,
      };

    case SET_PHONE_NUM_2:
      return {
        ...state,
        phoneNum2: action.payload,
      };

    case SET_PHONE_NUM_3:
      return {
        ...state,
        phoneNum3: action.payload,
      };

    case SET_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };

    case SET_PHONE_PANEL_REF:
      return {
        ...state,
        phoneTransferPanelRef: action.payload,
      };

    case SET_DISCOUNT_LIST:
      return {
        ...state,
        discountList: action.payload,
      };

    case SET_IS_APPOINTMENT_CONFIRMED:
      return {
        ...state,
        isAppointmentConfirmed: action.payload,
      };

    case SET_RECONTRACTING_QUOTE:
      return {
        ...state,
        recontractingQuote: action.payload,
      };

    case SET_RECONTRACTING_IS_RESET:
      return {
        ...state,
        recontractingIsReset: action.payload,
      };

    case RESET_ORDER_BUILDER_STATE: {
      return OrderBuilderInitialState;
    }

    default:
      throw new Error();
  }
}
