import dayjs from 'dayjs';
import { CalendarState } from '../types';

export const getInitialState = (): CalendarState => ({
  schedules: {
    status: 'idle',
    data: [],
    filter: '',
    selectedIds: [],
  },

  workOrders: {
    status: 'idle',
    data: [],
  },

  earlierAvailability: {
    status: 'idle',
    data: [],
  },

  appointments: {
    view: 'hour',
    // Range used to fetch data from BE
    dateRange: {
      start: dayjs().startOf('day').format('YYYY-MM-DD'),
      end: dayjs().endOf('day').format('YYYY-MM-DD'),
    },
    // Range used to filter data on FE
    dateFilter: {
      start: dayjs().format('YYYY-MM-DD'),
      end: dayjs().format('YYYY-MM-DD'),
    },

    selectedDate: dayjs().format('YYYY-MM-DD'),
  },

  details: {
    record: undefined,
  },

  engineers: {
    status: 'idle',
    data: [],
    selected: [],
  },

  utilization: {
    status: 'idle',
    data: [],
  },
});
