import React, { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Button, Section, SectionCard } from '@blueprintjs/core';

import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
import {
  SchemaAssociationSchemaTypesConstraintEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/association/constraint/schema.association.schema.types.constraint.entity';

import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { getSchemaByIdRequest } from '@legacy/core/schemas/store/actions';

import { useRequest } from '@core/hooks/useRequest';
import { OdinDropdown, OdinDropdownOption } from '../../../../../../../OdinDropdown';

const useSchema = (schemaId: string) => {
  // Redux-related hooks
  const schemaShortList = useSelector((state: any) => state.schemaReducer.shortList as ISchemaReducer['shortList']);
  const getSchemaByIdReq = useRequest(getSchemaByIdRequest);

  // Local state hooks
  const [isLoading, setIsLoading] = React.useState(false);
  const [schema, setSchema] = React.useState<SchemaEntity>(schemaShortList[schemaId]);

  const getSchemaById = useCallback(async () => {
    if (!schemaId) return;

    if (schemaShortList?.[schemaId]) return schemaShortList[schemaId];

    const response = await getSchemaByIdReq({ schemaId });
    console.log('[DebugX]', { response });
    return response.data.data;
  }, [schemaId, schemaShortList, getSchemaByIdReq]);

  useEffect(() => {
    if (schemaId === schema?.id) return;

    let mounted = true;
    getSchemaById().then((schema) => {
      if (!mounted) return;
      setSchema(schema);
    });

    return () => {
      mounted = false;
    };
  }, [schemaId, schema?.id, getSchemaById]);

  return { schema, isLoading };
};

type TypeConstraintsFormProps = {
  parentSchema: SchemaEntity;
  childSchema: SchemaEntity;
  linkId: string;
  schemaTypesConstraints: SchemaAssociationSchemaTypesConstraintEntity[];
  setSchemaTypesConstraints: (constraints: SchemaAssociationSchemaTypesConstraintEntity[]) => void;
};

const schemaTypeToOption = (schemaType: SchemaTypeEntity) => ({
  id: schemaType.id,
  text: schemaType.name,
});

export const TypeConstraintsForm: React.FC<TypeConstraintsFormProps> = ({
                                                                          parentSchema,
                                                                          childSchema,
                                                                          linkId,
                                                                          schemaTypesConstraints,
                                                                          setSchemaTypesConstraints,
                                                                        }) => {
  // Form State
  const [parentSchemaType, setParentSchemaType] = React.useState<OdinDropdownOption | null>(null);
  const [childSchemaType, setChildSchemaType] = React.useState<OdinDropdownOption | null>(null);

  const parentSchemaState = useSchema(parentSchema?.id);
  const childSchemaState = useSchema(childSchema?.id);

  const parentSchemaTypes = parentSchemaState.schema?.types || [];
  const childSchemaTypes = childSchemaState.schema?.types || [];

  const updateConstraint = (parentTypeId?: string, childTypeId?: string, constraintId?: string) => {
    const newConstraints = schemaTypesConstraints.map(constraint => {
      if (constraint.id !== constraintId) return constraint;

      return {
        ...constraint,
        parentSchemaTypeId: parentTypeId,
        childSchemaTypeId: childTypeId,
      };
    });
    setSchemaTypesConstraints(newConstraints);
  };

  const deleteConstraint = (constraintId: string) => {
    setSchemaTypesConstraints(schemaTypesConstraints.filter(constraint => constraint.id !== constraintId));
  };

  const addConstraint = () => {
    const newConstraint = {
      id: undefined,
      parentSchemaTypeId: parentSchemaType?.id || '',
      childSchemaTypeId: childSchemaType?.id || '',
    } as any;

    setSchemaTypesConstraints([...schemaTypesConstraints, newConstraint]);
    setParentSchemaType(null);
    setChildSchemaType(null);
  };

  return (
    <Section title="Type Constraints">
      {/* Existing Schema Type Constraints */}
      {schemaTypesConstraints?.map((constraint) => {
        const selectedParentSchemaType = parentSchemaTypes.find(type => type.id === constraint.parentSchemaTypeId);
        const selectedParentOption = selectedParentSchemaType ? schemaTypeToOption(selectedParentSchemaType) : null;

        const selectedChildSchemaType = childSchemaTypes.find(type => type.id === constraint.childSchemaTypeId);
        const selectedChildOption = selectedChildSchemaType ? schemaTypeToOption(selectedChildSchemaType) : null;

        return (
          <SectionCard key={constraint.id} id={constraint.id}>
            <div className="link-settings type-constraints">
              <div className="link-settings__item">
                <h4>Parent Type</h4>
                <OdinDropdown
                  placeholder="Select Schema Type"
                  items={parentSchemaTypes.map(schemaTypeToOption)}
                  selectedItem={selectedParentOption}
                  onChange={(parentType) => updateConstraint(
                    parentType?.id,
                    constraint.childSchemaTypeId,
                    constraint.id,
                  )}
                />
              </div>
              <div className="link-settings__item">
                <h4>Child Type</h4>
                <OdinDropdown
                  placeholder="Select Schema Type"
                  items={childSchemaTypes.map(schemaTypeToOption)}
                  selectedItem={selectedChildOption}
                  onChange={(childType) => updateConstraint(
                    constraint.parentSchemaTypeId,
                    childType?.id,
                    constraint.id,
                  )} />
              </div>
              <div className="link-settings__item">
                <Button icon="trash" onClick={() => deleteConstraint(constraint.id)} />
              </div>
            </div>
          </SectionCard>
        );
      })}

      {/* Add New Schema Type Constraint */}
      <SectionCard>
        <div className="link-settings type-constraints">
          <div className="link-settings__item">
            <h4>Parent Type</h4>
            <OdinDropdown
              placeholder="Select Schema Type"
              items={parentSchemaTypes.map(schemaTypeToOption)}
              selectedItem={parentSchemaType}
              onChange={setParentSchemaType}
            />
          </div>
          <div className="link-settings__item">
            <h4>Child Type</h4>
            <OdinDropdown
              placeholder="Select Schema Type"
              items={childSchemaTypes.map(schemaTypeToOption)}
              selectedItem={childSchemaType}
              onChange={setChildSchemaType}
            />
          </div>
          <div className="link-settings__item">
            &nbsp;
          </div>
        </div>
        <div className="link-settings">
          <div className="link-settings__item">
            <Button
              fill
              icon="plus"
              text="Add Constraint"
              onClick={() => addConstraint()}
              disabled={!(parentSchemaType || childSchemaType)}
            />
          </div>
        </div>
      </SectionCard>
    </Section>
  );
};
