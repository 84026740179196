import React, { useCallback } from 'react';
import { Button, ButtonGroup } from '@blueprintjs/core';

import { OdinDropdown, OdinDropdownOption } from '@core/modules/ControlPanelModule/OdinDropdown';
import {
  useOdinSearchActions,
  useOdinSearchState,
} from '@core/modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/provider';
import {
  OdinSearchParams,
} from '@core/modules/SupportModule/views/SupportDashboard/views/CaseManagementCaseListView/context/types';

const paramsToOption = (filter: OdinSearchParams): OdinDropdownOption => ({
  id: filter.name || 'Unnamed',
  text: filter.name || 'Unnamed',
});

export const ParamsDropdown: React.FC = () => {
  const { selectedParams, savedParams } = useOdinSearchState();
  const { deleteParams, selectParams } = useOdinSearchActions();

  const filterOptions = savedParams.map(paramsToOption);

  const isEmptySelectedParams = !selectedParams?.name && selectedParams?.filters.length === 0;
  const savedMatch = savedParams.find(
    f => !isEmptySelectedParams && (
      JSON.stringify(f.filters) === JSON.stringify(selectedParams?.filters)
    ),
  );

  const selectedOption = savedMatch ? paramsToOption(savedMatch) : null;

  // Event handlers
  const changeFilterHandler = useCallback((selected: OdinDropdownOption | null) => {
    if (!selected) return;

    const selectedFilter = savedParams.find((f) => f.name === selected.text);

    if (selectedFilter) selectParams(selectedFilter);
  }, [savedParams, selectParams]);

  const handleDeleteFilterClick = () => {
    if (!selectedParams) return;

    deleteParams(selectedParams);
  };

  return (
    <ButtonGroup>
      {savedParams.length > 0 && (
        <OdinDropdown
          placeholder={'Select a filter'}
          items={filterOptions}
          selectedItem={selectedOption}
          onChange={changeFilterHandler}
        />
      )}
      {savedParams.length > 0 && !!savedMatch && (
        <Button icon="trash" intent="danger" onClick={handleDeleteFilterClick} />
      )}
    </ButtonGroup>
  );
};