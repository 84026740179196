import React, { useEffect, useState } from "react";
import { Alert, Spin } from "antd";

import { DbRecordEntityTransform } from "@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform";

import { DashboardLineItem } from "../../hooks/types";
import { WorkListSubmissionStatus } from "../types"
import { getSubmissionStatuses, statusesSubmitting, statusesWithErrors, submitAdjustments, submitWorkList } from "../utils";
import { getFpeOverrideFromLineItem, getUniqueValues } from "../../utils";
import { LineItemInformation, SubmissionCard } from "./SubmissionCard";

type SubmissionStatusProps = {
  lineItemsPerWorkList: Record<string, DashboardLineItem[]>;
  onSubmissionSuccess: (
    afp: DbRecordEntityTransform,
    lineItems: DashboardLineItem[]
  ) => void;
  onSubmissionFailed: () => void;
}

export const SubmissionStatus = ({ lineItemsPerWorkList, onSubmissionSuccess, onSubmissionFailed }: SubmissionStatusProps) => {
  const [statuses, updateStatuses] = useState<Record<string, WorkListSubmissionStatus>>(
    getSubmissionStatuses(Object.keys(lineItemsPerWorkList))
  );

  const setStatus = (
    workListId: string,
    status: 'queued' | 'submitting' | 'submitted' | 'failed',
    error?: { message: string },
    data?: DbRecordEntityTransform,
  ) => {
    updateStatuses((currentStatuses) => {
      return {
        ...currentStatuses,
        [workListId]: { status, data, error }
      }
    });
  };

  // Submission Effects
  useEffect(() => {
    const submitWorkItems = async () => {
      const workListIds = Object.keys(lineItemsPerWorkList).filter(Boolean);
      for (const workListId of workListIds) {
        const lineItemsToSubmit = lineItemsPerWorkList[workListId];
        const featuresToInclude = getUniqueValues(lineItemsToSubmit, 'feature_id').filter(Boolean);

        const adjustments = lineItemsToSubmit.filter(fpe => fpe.fpe_type === 'ADJUSTMENT')
        const fpeOverrides = lineItemsToSubmit
          .filter(fpe => fpe.fpe_type === 'DEFAULT')
          .map(getFpeOverrideFromLineItem);

        const adjustmentsToInclude = adjustments.filter(fpe => !fpe.afp_id).map(fpe => fpe.fpe_id).filter(Boolean) as string[];
        const adjustmentsToCreate = adjustments.filter(fpe => fpe.afp_id);
        if (adjustmentsToCreate.length) {
          await submitAdjustments(workListId, adjustmentsToCreate, setStatus);
        }

        await submitWorkList(
          workListId,
          featuresToInclude,
          adjustmentsToInclude,
          fpeOverrides,
          setStatus,
        );
      }
    };

    submitWorkItems();
  }, []);

  useEffect(() => {
    const stillSubmitting = statusesSubmitting(statuses);
    if (stillSubmitting) return;

    for (const [workListId, submission] of Object.entries(statuses)) {
      if (submission.status === 'submitted' && submission.data) {
        const afp = submission.data;
        onSubmissionSuccess(afp, lineItemsPerWorkList[workListId]);
      }
    }

    if (!statusesSubmitting(statuses) && statusesWithErrors(statuses))
      onSubmissionFailed();
  }, [statuses])

  return (
    <>
      {statusesSubmitting(statuses) && <Alert
        message="Preparing AFP Data"
        description="Preparing your AFP data. Please allow up to 1 min for the process to complete."
        type="info"
        style={{ marginBottom: 10 }}
        showIcon
      />}
      {!statusesSubmitting(statuses) && statusesWithErrors(statuses) && <Alert
        message="Submission Issues"
        description="Some of your line items could not be submitted! Check below for details."
        type="warning"
        style={{ marginBottom: 10 }}
        showIcon
      />}
      {!statusesSubmitting(statuses) && !statusesWithErrors(statuses) && <Alert
        message="AFP Ready"
        description="Your line items were submitted!"
        type="success"
        style={{ marginBottom: 10 }}
        showIcon
      />}
      {Object.entries(lineItemsPerWorkList).map(([workListId, lineItems], index) => {
        const currentWLStatus: WorkListSubmissionStatus = statuses[workListId];

        return (
          <Spin spinning={currentWLStatus?.status === 'submitting'}>
            <SubmissionCard key={workListId}>
              <LineItemInformation
                title={`${lineItems[0].project_title} WorkList #${index + 1}`}
                workListId={workListId}
                lineItems={lineItems}
                status={currentWLStatus.status}
                error={currentWLStatus.error}
              />
            </SubmissionCard>
          </Spin>
        );
      })}
    </>
  );
}