import {
  DbRecordAssociationCreateUpdateDto,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { call, put, takeLatest } from 'redux-saga/effects';
import { httpGet, httpPost, httpPut } from '@core/http/requests';
import { DISPLAY_MESSAGE } from '../../messages/store/reducers';
import { ERROR_NOTIFICATION } from '../../notifications/store/reducers';
import { USER_LOGOUT_REQUEST } from '../../identity/store/constants';
import { IAmendOrderParams, IOrderCheckout } from './actions';
import {
  AMEND_ORDER_ERROR,
  AMEND_ORDER_REQUEST,
  AMEND_ORDER_SUCCESS,
  CANCEL_WORKORDER_REQUEST,
  GET_ACTIVE_OFFER_ERROR,
  GET_ACTIVE_OFFER_REQUEST,
  GET_ACTIVE_OFFER_SUCCESS,
  GET_ETF_ERROR,
  GET_ETF_REQUEST,
  GET_ETF_SUCCESS,
  MIGRATE_ADDRESS_ASSOCIATIONS_REQUEST,
  ORDER_WORKFOLOW_CHECKOUT_ERROR,
  ORDER_WORKFOLOW_CHECKOUT_REQUEST,
  ORDER_WORKFOLOW_CHECKOUT_SUCCESS,
} from './constants';

function* getETF(action: { params: { orderId: string }; cb: any }) {
  try {
    //@ts-ignore
    const res = yield call(
      async () =>
        await httpGet(`OrderModule/v1.0/orders/${action.params.orderId}/early-termination-fee`),
    );

    yield put({
      type: GET_ETF_SUCCESS,
      results: res.data?.data,
    });
    action.cb(res.data?.data);
  } catch (e: any) {
    action.cb(false);
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_ETF_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* orderCheckout(action: { params: IOrderCheckout; cb: any }) {
  try {
    //@ts-ignore
    const res = yield call(async () => await httpPost(`OrderModule/v1.0/checkout`, action.params));

    yield put({
      type: ORDER_WORKFOLOW_CHECKOUT_SUCCESS,
      results: res.data.data,
    });
    action.cb(res.data.data);
  } catch (e: any) {
    action.cb(false);
    const error = e.response ? e.response.data : undefined;
    yield put({ type: ORDER_WORKFOLOW_CHECKOUT_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* amendOrder(action: { params: IAmendOrderParams; cb?: (resp: any) => void }) {
  try {
    //@ts-ignore
    const res = yield call(
      async () =>
        await httpPost(
          `OrderModule/v1.0/orders/${action.params.orderId}/amend`,
          action.params.body,
        ),
    );

    yield put({ type: AMEND_ORDER_SUCCESS, results: res.data });

    if (action.cb) {
      yield call(action.cb, res.data);
    }
  } catch (e: any) {
    const error = e.response?.data ?? e;
    yield put({ type: AMEND_ORDER_ERROR, error });

    if (action.cb) {
      yield call(action.cb, undefined);
    }

    if (error?.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else if (error?.statusCode !== 404) {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* cancelWorkOrder(action: {
  params: { body: any; workOrderId: string };
  cb?: (resp: any) => void;
}) {
  try {
    //@ts-ignore
    const res = yield call(
      async () =>
        await httpPost(
          `FieldServiceModule/v1.0/WorkOrder/${action.params.workOrderId}/cancel`,
          action.params.body,
        ),
    );

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'WorkOrder cancelled', type: 'success' },
    });

    if (action.cb) {
      yield call(action.cb, res.data);
    }
  } catch (e: any) {
    const error = e.response?.data ?? e;
    if (action.cb) {
      yield call(action.cb, undefined);
    }
    if (error?.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else if (error?.statusCode !== 404) {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* getActiveOffer() {
  try {
    // @ts-ignore
    const res = yield call(
      async () =>
        await httpGet(
          'ProductModule/v1.0/cst/Offer/active/RESIDENTIAL?filters=ContractType:CONTRACT,IsDefault:true',
        ),
    );
    yield put({ type: GET_ACTIVE_OFFER_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_ACTIVE_OFFER_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* migrateRecords(action: {
  params: {
    fromModuleName: string;
    fromEntityName: string;
    fromRecordId: string;
    toModuleName: string;
    toEntityName: string;
    toRecordId: string;
    body: DbRecordAssociationCreateUpdateDto[];
  };
  cb?: (resp: any) => any;
}) {
  const {
    fromModuleName,
    fromEntityName,
    fromRecordId,
    toModuleName,
    toEntityName,
    toRecordId,
    body,
  } = action.params;
  try {
    //@ts-ignore
    const res = yield call(
      async () =>
        await httpPut(
          `SchemaModule/v1.0/db-associations/transfer/${fromModuleName}/${fromEntityName}/${fromRecordId}/${toModuleName}/${toEntityName}/${toRecordId}`,
          body,
        ),
    );

    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'Records migrated', type: 'success' },
    });

    if (action.cb) {
      yield call(action.cb, res.data.data);
    }
  } catch (e: any) {
    const error = e.response?.data ?? e;
    if (action.cb) {
      yield call(action.cb, undefined);
    }
    if (error?.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else if (error?.statusCode !== 404) {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* rootSaga() {
  yield takeLatest<any>(GET_ETF_REQUEST, getETF);
  yield takeLatest<any>(ORDER_WORKFOLOW_CHECKOUT_REQUEST, orderCheckout);
  yield takeLatest<any>(AMEND_ORDER_REQUEST, amendOrder);
  yield takeLatest<any>(CANCEL_WORKORDER_REQUEST, cancelWorkOrder);
  yield takeLatest(GET_ACTIVE_OFFER_REQUEST, getActiveOffer);
  yield takeLatest<any>(MIGRATE_ADDRESS_ASSOCIATIONS_REQUEST, migrateRecords);
}

export default rootSaga;
