import { IAutosplicingReducer } from '../../store/types';

export const shouldDisableFiberSplicing = (autosplicingReducer: IAutosplicingReducer) => {
  const { formData } = autosplicingReducer;

  if (!['L0', 'L2'].includes(autosplicingReducer.closureType!)) {
    return true;
  } else if (autosplicingReducer.closureType === 'L0' && !autosplicingReducer.enteredCableId) {
    return true;
  } else {
    if (autosplicingReducer.differenceList) {
      return (
        !formData?.closure_id ||
        autosplicingReducer.differenceList?.validationErrors?.length > 0 ||
        autosplicingReducer.differenceList?.data?.length > 0
      );
    } else {
      return true;
    }
  }
};

export const shouldShowDifferenceList = (autosplicingReducer: IAutosplicingReducer) => {
  const { formData, allJobs } = autosplicingReducer;
  const cableId = formData?.cable_id;
  return (
    autosplicingReducer.previousData &&
    !(['L0'].includes(autosplicingReducer.closureType!) && !cableId) &&
    allJobs!.length === 0
  );
};

export const hasJobsRunning = (autosplicingReducer: IAutosplicingReducer) => {
  const { allJobs } = autosplicingReducer;
  return allJobs!.length !== 0;
};

export const shouldDisableCableConnections = (autosplicingReducer: IAutosplicingReducer) => {
  return (
    !autosplicingReducer.previousData ||
    (autosplicingReducer.closureType === 'L0' && !autosplicingReducer.enteredCableId)
  );
};
