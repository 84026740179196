import { ReactNode } from 'react';
import { Alert, Col, Descriptions, Row, Tag } from 'antd';
import { isMobile } from 'react-device-detect';
import { CheckCircleTwoTone, CloseCircleOutlined } from '@ant-design/icons';

type Props = {
  name?: string;
  tags?: ReactNode[];
  details: {
    label: string;
    value?: string;
    validValues?: string[];
    invalidValues?: string[];
    tag?: { text: string; color: string };
  }[];
  columns?: number;
  notification?: string;
  layout?: 'horizontal' | 'vertical';
};

const DeviceListSection = ({ name, tags, details, columns, notification, layout }: Props) => {
  return (
    <>
      {(name || tags) && (
        <Row justify="space-between">
          <Col>
            <span style={{ fontWeight: 600 }}>{name}</span>
          </Col>
          <Col>{tags && tags.map((x) => x)}</Col>
        </Row>
      )}

      {notification && (
        <Alert style={{ marginBottom: 10, marginTop: 10 }} type={'info'} message={notification} />
      )}

      <Descriptions
        column={columns || (isMobile ? 2 : 3)}
        size="small"
        labelStyle={{
          fontWeight: 600,
          color: 'black',
          marginBottom: 0,
          marginTop: (layout || 'vertical') === 'vertical' ? 15 : 0,
          fontSize: '0.9em',
        }}
        contentStyle={{ fontSize: '0.9em' }}
        layout={layout || 'vertical'}
      >
        {details.map((x) => {
          const error =
            x.value &&
            ((x.validValues && !x.validValues.includes(x.value)) ||
              (x.invalidValues && x.invalidValues.includes(x.value)));

          const renderIcon = (error || x.validValues || x.invalidValues) && x.value;

          if (x.value === 'n/a') {
            return <></>;
          }

          return (
            <Descriptions.Item
              key={x.label}
              label={x.label}
              contentStyle={error ? { color: 'red' } : {}}
            >
              <span>
                {error ? (
                  <>
                    <CloseCircleOutlined />
                    &nbsp;
                  </>
                ) : renderIcon ? (
                  <>
                    <CheckCircleTwoTone twoToneColor="#52c41a" />
                    &nbsp;
                  </>
                ) : (
                  <></>
                )}
                {x.value || '-'}
              </span>
              {x.tag && (
                <Tag color={x.tag.color} style={{ marginLeft: 5 }}>
                  {x.tag.text}
                </Tag>
              )}
            </Descriptions.Item>
          );
        })}
      </Descriptions>
    </>
  );
};

export default DeviceListSection;
