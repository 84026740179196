import {
  RelationTypeEnum,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Alert, Button, Col, notification, Row } from 'antd';
import { constantCase } from 'change-case';
import React from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Flow from '../../../../../../core/components/Flow/Flow';
import {
  IMapUpdateWorkItems,
  MapReducerUpdate,
  updateMapState,
  updateWorkItems,
} from '@netomnia/modules/ProjectModule/Map/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import {
  addRecordToShortList,
  getRecordByIdRequest,
  IAddRecordToShortList,
  IGetRecordById,
} from '@legacy/core/records/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../core/helpers/schemaHelpers';
import '../styles.scss';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE } = SchemaModuleEntityTypeEnums;

interface Props {
  schemaReducer: ISchemaReducer;
  mapReducer: MapReducer;
  updateMap: (params: MapReducerUpdate) => {};
  initializeForm: any;
  getRecordById: Function;
  shortListRecord: Function;
  updateWI: (params: IMapUpdateWorkItems) => void;
}

interface State {
  showError: boolean;
  flowVisible: boolean;
  createdRecordId: string | undefined;
  selectedFeature: any;
}

class AddLineFlow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      showError: false,
      createdRecordId: undefined,
      flowVisible: false,
      selectedFeature: undefined,
    };
  }

  componentDidUpdate(prevProps: Props) {
    const { mapReducer, updateMap } = this.props;

    // Creating RFC?
    if (!prevProps.mapReducer.addLineFlow && mapReducer.addLineFlow && mapReducer.isCreatingRFC) {
      this.setState({
        flowVisible: true,
        selectedFeature: 'SURVEY_ROUTE',
      });

      setTimeout(() => {
        updateMap({ addLineFlow: false });
      }, 300);
    }
  }

  openNotification = (createdRecord: DbRecordEntityTransform) => {
    const { shortListRecord } = this.props;

    notification.open({
      message: `${createdRecord?.type} Feature Created`,
      type: 'success',
      duration: 5,
      description: (
        <Row>
          <Col span={24}>
            <Button
              type="link"
              style={{ padding: 0 }}
              onClick={() =>
                shortListRecord({
                  showPreview: true,
                  record: createdRecord,
                })
              }
            >
              Quick View
            </Button>
          </Col>
        </Row>
      ),
    });
  };

  private handleMapAfterFeatureCreate() {
    const { updateMap, getRecordById, schemaReducer, updateWI } = this.props;

    // Fetch Schema, search record with id and zoom into feature.
    if (this.state.createdRecordId) {
      const featureSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        PROJECT_MODULE,
        FEATURE,
      );

      if (featureSchema) {
        getRecordById(
          { schema: featureSchema, recordId: this.state.createdRecordId },
          (res: any) => {
            if (res && getProperty(res, 'ExternalRef')) {
              this.openNotification(res);
              updateMap({
                queryLayer: res.type.toLowerCase(),
                query: `type=${res.type.toLowerCase()}&featureId=${getProperty(
                  res,
                  'ExternalRef',
                )}`,
              });
            }
          },
        );
      }
    }

    // Reset map reducer
    updateMap({
      addEnabled: false,
      addLineEnabled: false,
      addLineFlow: false,
      coordinates: [],
      isCreatingRFC: false,
      creatingRFCFromIds: [],
      createLinked: {
        fromType: undefined,
        fromId: undefined,
        toType: undefined,
      },
    });

    // Reset any remaining associations
    updateWI({
      creatingBlockageFromId: undefined,
    });
  }

  toggleFlowVisibility = () => {
    if (this.state.flowVisible) {
      this.props.updateMap({
        addPointFlow: false,
      });
    }

    this.setState({ flowVisible: !this.state.flowVisible });
  };

  checkCoordinatesAndLaunchFlow = (feature: string) => {
    const { updateMap, mapReducer } = this.props;
    const { coordinates } = mapReducer;

    // There are no Feature coordinates - show error panel and don't launch the Flow.
    if (!coordinates || coordinates?.length < 1) {
      this.setState({
        showError: true,
        flowVisible: false,
      });
      updateMap({
        addLineFlow: false,
      });
    }
    // There are Feature coordinates - launch the Flow.
    else {
      this.setState({
        flowVisible: true,
        selectedFeature: constantCase(feature),
      });
    }
  };

  handleStepSubmit = (res: any) => {
    this.handleMapAfterFeatureCreate();
  };

  fetchCreatedRecordId = (id: string) => {
    if (id) {
      this.setState({ createdRecordId: id });
    }
  };

  getAssociationsForRecordCreate = () => {
    const { mapReducer } = this.props;
    const { workItems } = mapReducer;

    type association = {
      entity: string;
      recordId: string;
      relationType?: any;
    };

    let associations: association[] = [];

    if (mapReducer.isCreatingRFC && mapReducer.creatingRFCFromIds.length > 0) {
      mapReducer.creatingRFCFromIds.map((id: string) => {
        associations.push({
          entity: `${PROJECT_MODULE}:${FEATURE}`,
          recordId: id,
          relationType: RelationTypeEnum.CHILD,
        });
      });
    }

    // If Blockage is being created from Work Item
    if (workItems.creatingBlockageFromId) {
      associations.push({
        entity: `${PROJECT_MODULE}:${FEATURE}`,
        recordId: workItems.creatingBlockageFromId,
        relationType: RelationTypeEnum.CHILD,
      });
    }

    // If there is a WorkList in the mapReducer
    if (workItems.selectedWorkList) {
      associations.push({
        entity: `${PROJECT_MODULE}:WorkList`,
        recordId: workItems.selectedWorkList?.id,
      });
    }

    // If there is a Project in the mapReducer
    if (workItems.selectedProject) {
      associations.push({
        entity: `${PROJECT_MODULE}:Project`,
        recordId: workItems.selectedProject?.id,
      });
    }

    return associations;
  };

  render() {
    const { mapReducer } = this.props;

    return (
      <>
        <Flow
          flowVisible={this.state.flowVisible}
          toggleFlowVisibility={this.toggleFlowVisibility}
          launchInterface="NONE"
          launchInterfaceStyle={{ width: '100%', margin: 10 }}
          launchInterfaceProps={{ type: 'default', size: 'large' }}
          launchInterfaceTitle=""
          flowSteps={[
            // 1 - Create Feature record
            {
              flowType: 'RECORD',
              title: `Create Feature`,
              moduleName: 'ProjectModule',
              entityName: 'Feature',
              recordType: this.state.selectedFeature,
              hideRecordFormFields: true,
              runOnSubmit: this.fetchCreatedRecordId,
              additionalAssociations: this.getAssociationsForRecordCreate(),
              modified: {
                properties: {
                  // we set a temporary UUID on create which is later updated
                  // with the id of the feature created in the external database
                  ExternalRef: uuidv4(),
                  Coordinates: mapReducer.coordinates || undefined,
                },
              },
            },

            // 2 - Associate files
            {
              flowType: 'ASSOCIATION',
              title: 'Upload Files',
              moduleName: 'SchemaModule',
              entityName: 'File',
              recordType: 'DEFAULT',
              associatedFilesView: 'thumbnails',
              relateRecordFromStep: [1],
              runOnSubmit: this.handleStepSubmit,
              showFileCategoryForType: 'DEFAULT',
            },
          ]}
        />

        {this.state.showError && (
          <Alert
            style={{ zIndex: 100, width: '100%' }}
            message="Cannot create feature"
            description="missing coordinates, please re-draw feature on map"
            type="error"
            showIcon
            onClose={() => {
              this.setState({
                showError: false,
              });
            }}
          />
        )}
      </>
    );
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  mapReducer: state.mapReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getRecordById: (params: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(params, cb)),
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
});

export default connect(mapState, mapDispatch)(AddLineFlow);
