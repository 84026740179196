export const GET_ONBOARDING_TEMPLATES_LIST_REQUEST = 'GET_ONBOARDING_TEMPLATES_LIST_REQUEST';
export const GET_ONBOARDING_TEMPLATES_LIST_SUCCESS = 'GET_ONBOARDING_TEMPLATES_LIST_SUCCESS';
export const GET_ONBOARDING_TEMPLATES_LIST_ERROR = 'GET_ONBOARDING_TEMPLATES_LIST_ERROR';

export const GET_ONBOARDING_TEMPLATE_BY_ID_REQUEST = 'GET_ONBOARDING_TEMPLATE_BY_ID_REQUEST';
export const GET_ONBOARDING_TEMPLATE_BY_ID_SUCCESS = 'GET_ONBOARDING_TEMPLATE_BY_ID_SUCCESS';
export const GET_ONBOARDING_TEMPLATE_BY_ID_ERROR = 'GET_ONBOARDING_TEMPLATE_BY_ID_ERROR';

export const CREATE_ONBOARDING_TEMPLATE_REQUEST = 'CREATE_ONBOARDING_TEMPLATE_REQUEST';
export const CREATE_ONBOARDING_TEMPLATE_SUCCESS = 'CREATE_ONBOARDING_TEMPLATE_SUCCESS';
export const CREATE_ONBOARDING_TEMPLATE_ERROR = 'CREATE_ONBOARDING_TEMPLATE_ERROR';

export const DELETE_ONBOARDING_TEMPLATE_REQUEST = 'DELETE_ONBOARDING_TEMPLATE_REQUEST';
export const DELETE_ONBOARDING_TEMPLATE_SUCCESS = 'DELETE_ONBOARDING_TEMPLATE_SUCCESS';
export const DELETE_ONBOARDING_TEMPLATE_ERROR = 'DELETE_ONBOARDING_TEMPLATE_ERROR';

export const UPDATE_ONBOARDING_TEMPLATE_REQUEST = 'UPDATE_ONBOARDING_TEMPLATE_REQUEST';
export const UPDATE_ONBOARDING_TEMPLATE_SUCCESS = 'UPDATE_ONBOARDING_TEMPLATE_SUCCESS';
export const UPDATE_ONBOARDING_TEMPLATE_ERROR = 'UPDATE_ONBOARDING_TEMPLATE_ERROR';

export const UPDATE_ONBOARDING_TEMPLATE_ROLES_REQUEST = 'UPDATE_ONBOARDING_TEMPLATE_ROLES_REQUEST';
export const UPDATE_ONBOARDING_TEMPLATE_ROLES_SUCCESS = 'UPDATE_ONBOARDING_TEMPLATE_ROLES_SUCCESS';
export const UPDATE_ONBOARDING_TEMPLATE_ROLES_ERROR = 'UPDATE_ONBOARDING_TEMPLATE_ROLES_ERROR';

export const UPDATE_ONBOARDING_TEMPLATE_GROUPS_REQUEST =
  'UPDATE_ONBOARDING_TEMPLATE_GROUPS_REQUEST';
export const UPDATE_ONBOARDING_TEMPLATE_GROUPS_SUCCESS =
  'UPDATE_ONBOARDING_TEMPLATE_GROUPS_SUCCESS';
export const UPDATE_ONBOARDING_TEMPLATE_GROUPS_ERROR = 'UPDATE_ONBOARDING_TEMPLATE_GROUPS_ERROR';

export const DELETE_ROLES_FROM_TEMPLATE_REQUEST = 'DELETE_ROLES_FROM_TEMPLATE_REQUEST';
export const DELETE_ROLES_FROM_TEMPLATE_SUCCESS = 'DELETE_ROLES_FROM_TEMPLATE_SUCCESS';
export const DELETE_ROLES_FROM_TEMPLATE_ERROR = 'DELETE_ROLES_FROM_TEMPLATE_ERROR';

export const DELETE_GROUPS_FROM_TEMPLATE_REQUEST = 'DELETE_GROUPS_FROM_TEMPLATE_REQUEST';
export const DELETE_GROUPS_FROM_TEMPLATE_SUCCESS = 'DELETE_GROUPS_FROM_TEMPLATE_SUCCESS';
export const DELETE_GROUPS_FROM_TEMPLATE_ERROR = 'DELETE_GROUPS_FROM_TEMPLATE_ERROR';
