import { Button, MenuItem } from '@blueprintjs/core';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { httpGet, httpPut } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getErrorMessage } from '../../../../utils/errors';

interface Props {
  entity: 'users' | 'onboarding-templates';
  sourceRecord: any;
  defaultRole: any;
  onUpdate?: () => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface IRole {
  name: string;
  id: string;
  disabled: boolean; // if role is disabled
}

const UpdateRoleSelect: FC<Props> = (props: Props) => {
  const { entity, defaultRole, sourceRecord, alertMessage, onUpdate } = props;

  const [selectedRole, setSelectedRole] = useState<IRole | undefined>();
  const [isLoadingRoles, setIsLoadingRoles] = useState<boolean>(false);
  const [allRoles, setAllRoles] = useState<any[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  useEffect(() => {
    if (defaultRole) {
      setSelectedRole({
        name: defaultRole?.name,
        id: defaultRole?.id,
        disabled: false,
      });
    } else {
      setSelectedRole(undefined);
    }
  }, [defaultRole, sourceRecord]);

  useEffect(() => {
    loadAllRoles();
  }, []);

  const loadAllRoles = async () => {
    setIsLoadingRoles(true);
    try {
      const res = await httpGet(`IdentityModule/v2.0/roles?size=10000`);
      const roles = res.data?.data || [];
      setAllRoles(roles);
      setIsLoadingRoles(false);
    } catch (error) {
      setIsLoadingRoles(false);
    }
  };

  // Update Role if different one is selected
  const handleItemSelect = (item: any) => {
    if (item.id !== defaultRole?.id) {
      updateEntityWithRole(item);
    }
  };

  const updateEntityWithRole = async (selectedRole: IRole) => {
    setIsUpdating(true);

    let version = entity === 'users' ? 'v1.0' : 'v2.0';

    try {
      await httpPut(`IdentityModule/${version}/${entity}/${sourceRecord.id}`, {
        roleId: selectedRole.id === 'no-role' ? null : selectedRole.id,
      });

      if (selectedRole.id === 'no-role') {
        setSelectedRole(undefined);
      } else {
        setSelectedRole(selectedRole);
      }
      setIsUpdating(false);
      onUpdate && onUpdate();
      alertMessage({
        body: `Role updated`,
        type: 'success',
      });
    } catch (error: any) {
      setIsUpdating(false);
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not update role. ' + message,
        type: 'error',
      });
    }
  };

  const handleQueryChange = (e: any) => {
    setSearchQuery(e);
  };

  const renderRole: ItemRenderer<IRole> = (
    role,
    { handleClick, handleFocus, modifiers, query },
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    } else if (role.id === 'search-info') {
      return (
        <MenuItem
          disabled={true}
          key="load-more"
          roleStructure="menuitem"
          text="Use Filter to find more roles..."
        />
      );
    } else
      return (
        <MenuItem
          active={role.id === selectedRole?.id}
          disabled={modifiers.disabled}
          key={role.id}
          onClick={handleClick}
          onFocus={handleFocus}
          roleStructure="menuitem"
          text={role.name}
        />
      );
  };

  let ROLES: IRole[] = allRoles.map((f, index) => ({
    id: f.id,
    name: f.name,
    key: f.id,
    disabled: false,
  }));

  // Filter by search query
  if (searchQuery.length > 0) {
    ROLES = ROLES.filter((role) => {
      return role.name?.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  ROLES = ROLES.slice(0, 100);

  // Append the Search information if there are more than 100 roles
  if (ROLES.length === 100) {
    ROLES.push({
      id: 'search-info',
      name: 'search-info',
      disabled: true,
    });
  }

  // Append "No Role" option to the top of the list when the user is not searching
  if (searchQuery.length === 0) {
    ROLES.unshift({
      id: 'no-role',
      name: '(No Role)',
      disabled: false,
    });
  }

  return (
    <Select<IRole>
      items={ROLES}
      disabled={isLoadingRoles || allRoles.length === 0}
      itemRenderer={renderRole}
      noResults={<MenuItem disabled={true} text="No results." roleStructure="menuitem" />}
      onItemSelect={handleItemSelect}
      query={searchQuery}
      scrollToActiveItem
      onQueryChange={(e: any) => handleQueryChange(e)}
    >
      <Button
        icon={selectedRole ? 'id-number' : null}
        alignText="left"
        disabled={isLoadingRoles || allRoles.length === 0 || isUpdating}
        loading={isLoadingRoles}
        text={selectedRole?.name || 'Select role'}
        rightIcon="caret-down"
        fill
      />
    </Select>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(UpdateRoleSelect);
