import React, { FC } from 'react';
import { Col, Row } from 'antd';
import { connect } from 'react-redux';
import {
  ISearchRecords,
  searchRecordsRequest,
} from '@legacy/core/records/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import CaseManagementDashboardStatCard from './CaseManagementDashboardStatCard';
import CaseManagementDashboardChart from './CaseManagementDashboardChart';
import { DUMMY_STAGES } from './types';
import { httpPost } from '@core/http/requests';
import dayjs from 'dayjs';

interface Props {
  searchRecords: (params: ISearchRecords, cb: any) => void;
  alertMessage: (params: { body: string; type: string }) => void;
}

const ORDERED_STAGES = [
  "Open",
  "Solved",
  "Closed",
  "Pending Agent",
  "Pending Reply",
  "Pending Review",
  "Blocked",
];

const CaseManagementDashboard: FC<Props> = (props: Props) => {
  const [todayCallCases, setTodayCallCases] = React.useState<number>(0);
  const [todayCallsStageAggregation, setTodayCallsStageAggregation] = React.useState<any[]>([]);

  const [todayConversationCases, setTodayConversationCases] = React.useState<number>(0);
  const [todayConversationStageAggregation, setTodayConversationStageAggregation] = React.useState<any[]>([]);

  const [todayEmailCases, setTodayEmailCases] = React.useState<number>(0);
  const [todayEmailsStageAggregation, setTodayEmailsStageAggregation] = React.useState<any[]>([]);

  const [todayTrustPilotCases, setTodayTrustPilotCases] = React.useState<number>(0);
  const [todayTrustPilotStageAggregation, setTodayTrustPilotStageAggregation] = React.useState<any[]>([]);

  const [todayFacebookCases, setTodayFacebookCases] = React.useState<number>(0);
  const [todayFacebookStageAggregation, setTodayFacebookStageAggregation] = React.useState<any[]>([]);

  const [allTimeCases, setAllTimeCases] = React.useState<number>(0);
  const [allTimeCallCases, setAllTimeCallCases] = React.useState<number>(0);
  const [allTimeConversationCases, setAllTimeConversationCases] = React.useState<number>(0);
  const [allTimeEmailCases, setAllTimeEmailCases] = React.useState<number>(0);
  const [allTimeFacebookCases, setAllTimeFacebookCases] = React.useState<number>(0);
  const [allTimeTrustPilotCases, setAllTimeTrustPilotCases] = React.useState<number>(0);

  const fetchAllDataFromAllTime = async (): Promise<void> => {
      const calls = await Promise.resolve(fetchAllTimeCalls());
      const conversations = await Promise.resolve(fetchAllTimeConversations());
      const emails = await Promise.resolve(fetchAllTimeEmails());
      const facebookPagePosts = await Promise.resolve(fetchAllTimeFacebookPagePostCases());
      const trustPilotCases = await Promise.resolve(fetchAllTimeTrustPilotCases());

      setAllTimeCases(calls + conversations + emails + facebookPagePosts + trustPilotCases);
  }

  const fetchAllData = () => {
    fetchTodayCalls();
    fetchTodayConversations();
    fetchTodayEmails();
    fetchTodayFacebookPagePostCases();
    fetchTodayTrustPilotCases();
  };

  React.useEffect(() => {
    fetchAllDataFromAllTime().finally(() => {
      fetchAllData();
    });

    const interval = setInterval(() => {
      fetchAllDataFromAllTime().finally(() => {
        fetchAllData();
      });
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  React.useEffect(() => {
    fetchAllDataFromAllTime();
    fetchAllData();
  }, []);

  const fetchTodayCalls = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'CALL',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTodayCallCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const orderedStageAggs = ORDERED_STAGES.map((stage) =>
            stageAggs.find((agg: { name: string; value: number }) => agg.name === stage) || { name: stage, value: 0 }
        );

        setTodayCallsStageAggregation(orderedStageAggs);
      }
    });
  };

  const fetchTodayConversations = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'WEB_CHAT',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTodayConversationCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const orderedStageAggs: { name: string; value: number }[] = ORDERED_STAGES.map((stage) =>
            stageAggs.find((agg: { name: string; value: number }) => agg.name === stage) || { name: stage, value: 0 }
        );

        setTodayConversationStageAggregation(orderedStageAggs);
      }
    });
  };

  const fetchTodayEmails = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Source',
            operator: 'eq',
            value: 'EMAIL',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTodayEmailCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const orderedStageAggs: { name: string; value: number }[] = ORDERED_STAGES.map((stage) =>
            stageAggs.find((agg: { name: string; value: number }) => agg.name === stage) || { name: stage, value: 0 }
        );

        setTodayEmailsStageAggregation(orderedStageAggs);
      }
    });
  };

  const fetchTodayFacebookPagePostCases = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'FACEBOOK_PAGE_POST',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTodayFacebookCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const orderedStageAggs: { name: string; value: number }[] = ORDERED_STAGES.map((stage) =>
            stageAggs.find((agg: { name: string; value: number }) => agg.name === stage) || { name: stage, value: 0 }
        );

        setTodayFacebookStageAggregation(orderedStageAggs);
      }
    });
  };

  const fetchTodayTrustPilotCases = () => {
    httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'TRUSTPILOT_SERVICE_REVIEW',
          },
          {
            columnName: 'UpdatedAt',
            operator: 'gte',
            value: dayjs().startOf('day').format('YYYY-MM-DD'),
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { totalRecords, aggregations } = result.data.data;

      setTodayTrustPilotCases(totalRecords);

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
          (bucket: { key: string; doc_count: number }) => ({
            name: bucket.key,
            value: bucket.doc_count,
          }),
        );

        const orderedStageAggs: { name: string; value: number }[] = ORDERED_STAGES.map((stage) =>
            stageAggs.find((agg: { name: string; value: number }) => agg.name === stage) || { name: stage, value: 0 }
        );

        setTodayTrustPilotStageAggregation(orderedStageAggs);
      }
    });
  };


  const fetchAllTimeCalls = (): Promise<number> => {
    return httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'CALL',
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {

      const { aggregations } = result.data.data;

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
            (bucket: { key: string; doc_count: number }) => ({
              name: bucket.key,
              value: bucket.doc_count,
            }),
        );

        const totalDocCount = stageAggs.reduce((sum: number, stage: { name: string; value: number }) => sum + stage.value, 0);
        setAllTimeCallCases(totalDocCount);
        return totalDocCount;
      }
    });
  };

  const fetchAllTimeConversations = (): Promise<number> => {
    return httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'WEB_CHAT',
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { aggregations } = result.data.data;

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
            (bucket: { key: string; doc_count: number }) => ({
              name: bucket.key,
              value: bucket.doc_count,
            }),
        );

        const totalDocCount = stageAggs.reduce((sum: number, stage: { name: string; value: number }) => sum + stage.value, 0);
        setAllTimeConversationCases(totalDocCount);
        return totalDocCount
      }
    });
  };

  const fetchAllTimeEmails = (): Promise<number> => {
    return httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Source',
            operator: 'eq',
            value: 'EMAIL',
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { aggregations } = result.data.data;

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
            (bucket: { key: string; doc_count: number }) => ({
              name: bucket.key,
              value: bucket.doc_count,
            }),
        );

        const totalDocCount = stageAggs.reduce((sum: number, stage: { name: string; value: number }) => sum + stage.value, 0);
        setAllTimeEmailCases(totalDocCount);
        return totalDocCount;
      }
    });
  };

  const fetchAllTimeFacebookPagePostCases = (): Promise<number> => {
    return httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'FACEBOOK_PAGE_POST',
          },
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { aggregations } = result.data.data;

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
            (bucket: { key: string; doc_count: number }) => ({
              name: bucket.key,
              value: bucket.doc_count,
            }),
        );

        const totalDocCount = stageAggs.reduce((sum: number, stage: { name: string; value: number }) => sum + stage.value, 0);
        setAllTimeFacebookCases(totalDocCount);
        return totalDocCount;
      }
    });
  };

  const fetchAllTimeTrustPilotCases = (): Promise<number> => {
    return httpPost(`SupportModule/v2.0/records/search`, {
      returnQueryPlan: false,
      query: {
        entity: 'SupportModule:Case',
        type: 'and',
        value: [
          {
            columnName: 'Channel',
            operator: 'eq',
            value: 'TRUSTPILOT_SERVICE_REVIEW',
          }
        ],
        returnProperties: ['id', 'title', 'recordNumber'],
        aggs: {
          'stage-agg': {
            terms: {
              field: 'stage.name.keyword',
            },
          },
        },
        pageSize: 50,
      },
    }).then((result) => {
      const { aggregations } = result.data.data;

      if (aggregations && aggregations['stage-agg']) {
        const stageAggs = aggregations['stage-agg'].buckets.map(
            (bucket: { key: string; doc_count: number }) => ({
              name: bucket.key,
              value: bucket.doc_count,
            }),
        );

        const totalDocCount = stageAggs.reduce((sum: number, stage: { name: string; value: number }) => sum + stage.value, 0);
        setAllTimeTrustPilotCases(totalDocCount);
        return totalDocCount;
      }
    });
  };


  return (
    <>
      {/* Header */}
      <Row>
        <Col span={24}>
          <h1 style={{ margin: 0 }}>Dashboard</h1>
        </Col>
      </Row>
      {/* Dashboard */}
      <Row
        style={{
          marginTop: 30,
          height: 'calc(100vh - 140px)',
          overflowY: 'auto',
          alignContent: 'flex-start',
        }}
      >
        {/* SLA Score Chart */}
        {/*<CaseManagementDashboardChart />*/}
        <CaseManagementDashboardStatCard
            title={"Total Cases (All Time)"}
            topStats={
            [{ title: 'Total Cases', value: allTimeCases }]}
            properties={[
              { name: 'Total Calls', value: allTimeCallCases },
              { name: 'Total Chats', value: allTimeConversationCases },
              { name: 'Total Emails', value: allTimeEmailCases },
              { name: 'Total Social Media', value: allTimeFacebookCases },
              { name: 'Total Trust Pilot', value: allTimeTrustPilotCases },
            ]} />

        {/* Tickets Stat */}

        {/* Calls Stat */}
        <CaseManagementDashboardStatCard
          title="Calls (Today)"
          topStats={[{ title: 'Total Cases', value: todayCallCases }]}
          properties={todayCallsStageAggregation}
        />

        {/* Chats Stat */}
        <CaseManagementDashboardStatCard
          title="Chats (Today)"
          topStats={[{ title: 'Total Cases', value: todayConversationCases }]}
          properties={todayConversationStageAggregation}
        />

        {/* Email Stat */}
        <CaseManagementDashboardStatCard
          title="Email (Today)"
          topStats={[{ title: 'Total Cases', value: todayEmailCases }]}
          properties={todayEmailsStageAggregation}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="Social Media (Today)"
          topStats={[{ title: 'Total Cases', value: todayFacebookCases }]}
          properties={todayFacebookStageAggregation}
        />

        {/* Social Media */}
        <CaseManagementDashboardStatCard
          title="TrustPilot (Today)"
          topStats={[{ title: 'Total Cases', value: todayTrustPilotCases }]}
          properties={todayTrustPilotStageAggregation}
        />
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementDashboard);
