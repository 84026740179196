import {
  CaretLeftOutlined,
  CaretRightOutlined,
  CopyOutlined,
  QuestionOutlined,
} from '@ant-design/icons';
import { QGISClosureTypeEnum } from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Alert, Button, Card, Col, Result, Row, Tabs, Tooltip } from 'antd';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { copyTextToClipboard } from '../../helpers';
import {
  toggleAutosplicingMaximizedView,
  updateAutosplicingReducerState,
} from '../../store/actions';
import { IAutosplicingReducer, TUpdateAutosplicingReducerAction } from '../../store/types';
import DifferenceList from '../AutosplicingCableConnection';
import FiberConnections from '../AutosplicingFiberConnections';
import SelectedGraphEntityDetails from '../AutosplicingGraphFeatureDetails';
import AutosplicingL4Sync from '../AutosplicingL4Sync';
import AutosplicingProject from '../AutosplicingProject';
import AutosplicingForm from '../AutosplicingSearch';
import TraceOverview from '../AutosplicingTraceOverview';
import SplicingRequestForm from '../SplicingRequestForm';
import {
  hasJobsRunning,
  shouldDisableCableConnections,
  shouldDisableFiberSplicing,
  shouldShowDifferenceList,
} from './helpers';

interface OwnProps {
  alertMessage: Function;
  form: any;
  updateURLParams: Function;
  fetchSplicingTemplates: Function;
  getTemplateDifference: Function;
  toggleMaximizedView: Function;
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  autosplicingReducer: IAutosplicingReducer;
}

type Props = OwnProps;
const { TabPane } = Tabs;

const AutosplicingSidebar: FunctionComponent<Props> = (props) => {
  const {
    autosplicingReducer,
    toggleMaximizedView,
    updateAutosplicingReducer,
    form,
    updateURLParams,
    fetchSplicingTemplates,
    getTemplateDifference,
  } = props;
  const { allJobs, formData } = autosplicingReducer;

  return (
    <Col span={autosplicingReducer.cableDiagramMaximized ? 1 : 7}>
      <Card
        className={`connectionsSidebar ${
          autosplicingReducer.cableDiagramMaximized ? 'minimized' : ''
        }`}
        style={{
          marginBottom: 20,
          marginLeft: 10,
        }}
        title={
          !autosplicingReducer.cableDiagramMaximized ? (
            <Row>
              <Col span={16}>
                <span style={{ fontSize: '1.1em' }}>Autosplicing</span>
              </Col>
              <Col span={8} style={{ textAlign: 'right' }}>
                <Tooltip title="Copy URL to Clipboard">
                  <Button
                    size="small"
                    disabled={!autosplicingReducer.enteredClosureId}
                    icon={<CopyOutlined />}
                    style={{ marginRight: 8 }}
                    onClick={() =>
                      copyTextToClipboard(
                        window.location?.href,
                        'Link to this' + ' Autosplicing is copied to clipboard!',
                        props.alertMessage,
                      )
                    }
                  />
                </Tooltip>

                <Tooltip title="Autosplicing Help">
                  <Button
                    size="small"
                    icon={<QuestionOutlined />}
                    style={{ marginRight: 8 }}
                    onClick={() => updateAutosplicingReducer({ helpDrawerVisible: true })}
                  />
                </Tooltip>

                <Button
                  className="collapseButton"
                  size="small"
                  icon={
                    autosplicingReducer.cableDiagramMaximized ? (
                      <CaretLeftOutlined />
                    ) : (
                      <CaretRightOutlined />
                    )
                  }
                  onClick={() => toggleMaximizedView()}
                />
              </Col>
            </Row>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Button
                className="collapseButton"
                size="small"
                icon={
                  autosplicingReducer.cableDiagramMaximized ? (
                    <CaretLeftOutlined />
                  ) : (
                    <CaretRightOutlined />
                  )
                }
                onClick={() => toggleMaximizedView()}
              />
            </div>
          )
        }
      >
        {
          // Just a height placeholder for when the diagram is maximized.
          autosplicingReducer.cableDiagramMaximized ? <div style={{ height: '82vh' }} /> : <></>
        }

        <Row
          style={{
            height: '82vh',
            paddingLeft: 7,
            paddingRight: 7,
            overflowY: 'auto',
          }}
        >
          <Col span={24} style={{ overflowX: 'hidden' }}>
            <Tabs
              defaultActiveKey="1"
              size="small"
              activeKey={autosplicingReducer.activeTab}
              onTabClick={(e: any) => updateAutosplicingReducer({ activeTab: e })}
              style={{ fontSize: '0.8em' }}
            >
              <TabPane tab="Trace" key="1" destroyInactiveTabPane>
                <Row>
                  <Col span={24}>
                    <AutosplicingForm form={form} updateURLParams={updateURLParams} />
                  </Col>
                  <Col span={24} style={{ marginTop: 20 }}>
                    {autosplicingReducer.selectedEntity ? (
                      <SelectedGraphEntityDetails
                        record={autosplicingReducer.selectedEntityOdinRecord!}
                      />
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
              </TabPane>

              <TabPane
                tab="Overview"
                key="2"
                disabled={
                  (!formData?.cable_id && autosplicingReducer.closureType === 'L0') || !formData
                }
                destroyInactiveTabPane
              >
                <TraceOverview />
              </TabPane>

              <TabPane
                tab="Cable"
                key="3"
                disabled={shouldDisableCableConnections(autosplicingReducer)}
              >
                {
                  // Show Splicing request if
                  autosplicingReducer.differenceList &&
                  autosplicingReducer.differenceList?.data?.length > 0 &&
                  autosplicingReducer.showSplicingRequestForChangeForm ? (
                    <SplicingRequestForm />
                  ) : (
                    <div>
                      {/* Difference List */}
                      <div
                        style={{
                          display:
                            shouldShowDifferenceList(autosplicingReducer) &&
                            !hasJobsRunning(autosplicingReducer)
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <DifferenceList
                          getTemplateDifference={getTemplateDifference}
                          fetchSplicingTemplates={fetchSplicingTemplates}
                        />
                      </div>

                      {/* Jobs Running */}
                      <div
                        style={{
                          display:
                            !shouldShowDifferenceList(autosplicingReducer) &&
                            hasJobsRunning(autosplicingReducer)
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <Result status="warning" title="There are Jobs running" />
                      </div>

                      {/* Cable Connections are not supported */}
                      <div
                        style={{
                          display:
                            !shouldShowDifferenceList(autosplicingReducer) &&
                            !hasJobsRunning(autosplicingReducer)
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <Alert
                          message="Warning"
                          description="Cable connections are not supported for this query"
                          type="warning"
                        />
                      </div>
                    </div>
                  )
                }
              </TabPane>

              <TabPane
                tab="Fiber"
                key="4"
                disabled={shouldDisableFiberSplicing(autosplicingReducer)}
              >
                {
                  // Show Splicing request for change if there is a fibre difference list
                  autosplicingReducer.showSplicingRequestForChangeForm &&
                  autosplicingReducer.fiberDifferences ? (
                    <SplicingRequestForm />
                  ) : (
                    <div>
                      {/* Fiber Connections */}
                      <div
                        style={{
                          display: allJobs!.length === 0 ? 'block' : 'none',
                        }}
                      >
                        <FiberConnections />
                      </div>

                      {/* Jobs Running */}
                      <div
                        style={{
                          display:
                            !shouldShowDifferenceList(autosplicingReducer) &&
                            hasJobsRunning(autosplicingReducer)
                              ? 'block'
                              : 'none',
                        }}
                      >
                        <Result status="warning" title="There are Jobs running" />
                      </div>
                    </div>
                  )
                }
              </TabPane>

              <TabPane
                tab="Project"
                key="5"
                disabled={
                  !['L1', 'L2'].includes(
                    QGISClosureTypeEnum[
                      getProperty(autosplicingReducer.selectedEntityOdinRecord!, 'ClosureType')
                    ],
                  )
                }
                destroyInactiveTabPane
              >
                {allJobs!.length === 0 ? <AutosplicingProject /> : <></>}
              </TabPane>
              {autosplicingReducer.closureType === 'L2' ? (
                <TabPane tab="L4 Sync" key="6" destroyInactiveTabPane>
                  <AutosplicingL4Sync />
                </TabPane>
              ) : (
                <></>
              )}
            </Tabs>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

const mapDispatch = (dispatch: any) => ({
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  toggleMaximizedView: () => dispatch(toggleAutosplicingMaximizedView()),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState, mapDispatch)(AutosplicingSidebar);
