import { Button } from '@blueprintjs/core';
import { Form, InputNumber } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import TextArea from 'antd/lib/input/TextArea';
import { useEffect } from 'react';

import { SlotInfo } from './hooks/useCalendarState';

type EditSlotInfoProps = {
  slotInfo: SlotInfo;
  onSubmit: (_: any) => void;
  onCancel: () => void;
};

export const EditSlotInfo = ({ slotInfo, onSubmit, onCancel }: EditSlotInfoProps) => {
  const [form] = useForm();

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(slotInfo)]);

  const handleSubmit = () => {
    const { AMQuantity, PMQuantity, MgmtComment } = form.getFieldsValue();

    const StartDate = slotInfo.date;
    const EndDate = slotInfo.date;

    onSubmit({
      StartDate,
      EndDate,
      AMQuantity,
      PMQuantity,
      MgmtComment,
    });
  };

  return (
    <Form form={form} onFinish={handleSubmit} className="edit-slot-info-form">
      <Form.Item name="AMQuantity" label="AM Quantity" initialValue={slotInfo?.AMTotal ?? 0}>
        <InputNumber placeholder="AM Quantity" min={0} />
      </Form.Item>
      <Form.Item name="PMQuantity" label="PM Quantity" initialValue={slotInfo?.PMTotal ?? 0}>
        <InputNumber placeholder="PM Quantity" min={0} />
      </Form.Item>
      <Form.Item name="MgmtComment" label="Comment" initialValue={slotInfo?.MgmtComment ?? ''}>
        <TextArea />
      </Form.Item>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button onClick={() => onCancel()}>Close</Button>
        <Button intent="primary" onClick={() => form.submit()}>
          Update
        </Button>
      </div>
    </Form>
  );
};
