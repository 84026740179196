import { Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { SchemaTypeEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.type.entity';
interface Props {
  schemaReducer: any;
  defaultValue: any;
  onChange: (value: any) => void;
  formReducer: any;
}

const SchemaTypeFormSelect: React.FC<Props> = (props: Props) => {
  const { formReducer, onChange, defaultValue } = props;
  const { schema } = formReducer;
  const [schemaTypes, setSchemaTypes] = useState<SchemaTypeEntity[]>([]);

  // On Component mount, fetch list of users
  useEffect(() => {
    if (schema?.types && schema.types?.length > 0) {
      setSchemaTypes(schema.types);
    }
  }, []);

  return (
    <Select
      style={{ width: '100%' }}
      placeholder="Select Type"
      disabled={schemaTypes.length === 0}
      maxTagCount={3}
      maxTagTextLength={13}
      allowClear
      mode="multiple"
      onChange={onChange}
      defaultValue={defaultValue}
    >
      {schemaTypes.map((schemaType: SchemaTypeEntity) => (
        <Select.Option key={schemaType.id} value={schemaType.name}>
          {schemaType.name}
        </Select.Option>
      ))}
    </Select>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  formReducer: state.formReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(SchemaTypeFormSelect);
