import { SearchOutlined } from "@ant-design/icons";
import Search from "antd/es/input/Search";

type UseSearchParams = {
  dataIndex: string;
  value: string;
  onSearch: (value: string) => void;
}

export const useSearch = ({ dataIndex, value, onSearch }: UseSearchParams) => {
  return {
    filterDropdown: () => <Search onSearch={onSearch} />,
    filterIcon: () => <SearchOutlined style={{ color: value ? '#1890ff' : undefined }} />
  }
};
