import {
  QGISCableTypeEnum,
  QGISClosureTypeEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Alert, Button, Col, Drawer, Row, Select, Tooltip, Typography } from 'antd';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  IResetFibersByCableRequest,
  IResetFibersByTemplateRequest,
  resetFibersByCableRequest,
  resetFibersByTemplateRequest,
  resetPolygon,
  updateAutosplicingReducerState,
} from '../../store/actions';
import {
  IAutosplicingReducer,
  IResetPolygonPayload,
  TResetPolygonAction,
  TUpdateAutosplicingReducerAction,
} from '../../store/types';

interface OwnProps {
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  autosplicingReducer: IAutosplicingReducer;
  resetPolygonRequest: TResetPolygonAction;
  resetCable: any;
  resetTemplate: any;
}

type Props = OwnProps;

const AutosplicingResetDrawer: FunctionComponent<Props> = (props) => {
  const {
    autosplicingReducer,
    updateAutosplicingReducer,
    resetPolygonRequest,
    resetCable,
    resetTemplate,
  } = props;
  const record = autosplicingReducer.selectedEntityOdinRecord;
  const [canResetPolygon, setCanResetPolygon] = useState<boolean>(true);
  const [closureId, setClosureId] = useState<string>('');
  const [cableId, setCableId] = useState<string>('');
  const [closureType, setClosureType] = useState<string>('');
  const [polygonId, setPolygonId] = useState<string>('');
  const [cableList, setCableList] = useState<Array<string>>([]);
  const [selectedCableList, setSelectedCableList] = useState<Array<string>>([]);

  // Post the Polygon reset data to API
  const resetConfirmed = () => {
    if (
      autosplicingReducer.selectedEntityOdinRecord?.type === 'CABLE' &&
      autosplicingReducer.resetDrawerAction === 'RESET_CABLE'
    ) {
      resetCable({
        cableId: Number(
          autosplicingReducer.selectedEntityOdinRecord?.externalId,
        ),
        rootClosureId: autosplicingReducer.formData.closure_id,
        rootCableId: autosplicingReducer.formData.cable_id,
      });
    }

    if (
      closureType === 'L0' &&
      closureId &&
      polygonId &&
      autosplicingReducer.resetDrawerAction === 'RESET_CABLELINK'
    ) {
      // For L0 closures we still need to reset using the V1 reset API
      // because we do not trace ODF cable link cables in the template files.
      let selectedCables: Array<number> = [];
      // Parse selected items to QGIS Cable type numbers
      selectedCableList.forEach((selectedCable: any) => {
        if (selectedCable === 'Access/Feed') {
          selectedCables.push(3, 4);
        } else {
          selectedCables.push(Number(QGISCableTypeEnum[selectedCable]));
        }
      });
      //
      // resetCable({
      //   cableId: Number(autosplicingReducer.selectedEntityOdinRecord?.externalId),
      //   rootClosureId: autosplicingReducer.formData.closure_id,
      //   rootCableId: autosplicingReducer.formData.cable_id,
      // });

      resetPolygonRequest({
        polygonId: polygonId,
        closureId: closureId,
        cableId: autosplicingReducer.enteredCableId
          ? autosplicingReducer.enteredCableId
          : undefined,
        cableTypes: selectedCables,
      });
    }

    if (
      closureId &&
      ['L0', 'L2'].includes(closureType) &&
      autosplicingReducer.resetDrawerAction === 'RESET_ALL_FIBERS'
    ) {
      resetTemplate({
        closureId: Number(closureId),
        cableId: cableId ? Number(cableId) : undefined,
      });
    }
  };

  // Get Closure & Polygon properties, handle supported Closure type logic.
  useEffect(() => {
    if (record) {
      let closureType = getProperty(record, 'ClosureType');
      closureType = QGISClosureTypeEnum[Number(closureType)];

      setClosureType(closureType);
      setClosureId(autosplicingReducer.formData!.closure_id);
      setCableId(autosplicingReducer.formData!.cable_id);

      if (record.type === 'CABLE') {
        setCanResetPolygon(true);
      } else if (closureType === 'L0') {
        setPolygonId(getProperty(record, 'ExPolygonId'));
        setCableList(['Cablelink']);
        setCanResetPolygon(true);
        setSelectedCableList(['Cablelink']);
      } else if (closureType === 'L1') {
        setCableList(['Spine', 'Distribution', 'Access/Feed']);
        setCanResetPolygon(true);
        setPolygonId(getProperty(record, 'L1PolygonId'));
        setSelectedCableList([]);
      } else if (closureType === 'L2') {
        setCableList(['Access/Feed']);
        setCanResetPolygon(true);
        setPolygonId(getProperty(record, 'L2PolygonId'));
        setSelectedCableList(['Access/Feed']);
      } else {
        setCanResetPolygon(false);
        setSelectedCableList([]);
      }
    }
  }, [record, autosplicingReducer.resetDrawerVisible]);

  const resetPolygonForm = () => {
    return (
      <>
        <Row>
          {/* Cable Types */}
          {autosplicingReducer.resetDrawerAction === 'RESET_CABLELINK' && (
            <div>
              <Col span={24} style={{ marginBottom: 10 }}>
                <span style={{ fontWeight: 500 }}>Select cable type</span>
              </Col>
              <Col span={24} style={{ marginBottom: 30 }}>
                <Select
                  mode="tags"
                  placeholder="Cable types"
                  value={selectedCableList}
                  disabled={closureType === 'L0' || closureType === 'L2'}
                  onChange={(e: any) => setSelectedCableList(e)}
                  style={{ width: '100%' }}
                >
                  {cableList?.map((cable: string) => (
                    <Select.Option key={`cable-${cable}`} value={cable}>
                      {cable}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </div>
          )}

          {autosplicingReducer.resetDrawerAction === 'RESET_CABLELINK' && (
            <div>
              <Typography.Text>
                By clicking apply, you will be resetting fibers for cablelinks
                only.
              </Typography.Text>
            </div>
          )}

          {autosplicingReducer.resetDrawerAction === 'RESET_ALL_FIBERS' &&
          closureType === 'L0' ? (
            <div>
              <Typography.Text>
                By clicking apply, you will be resetting fibers for all spine
                and distribution cables starting from closure {closureType}-
                {closureId} and spine leg {cableId}
              </Typography.Text>
            </div>
          ) : (
            ''
          )}

          {autosplicingReducer.resetDrawerAction === 'RESET_ALL_FIBERS' &&
          closureType === 'L2' ? (
            <div>
              <Typography.Text>
                By clicking apply, you will be resetting fibers for all access
                and feed cables starting from closure {closureType}-{closureId}
              </Typography.Text>
            </div>
          ) : (
            ''
          )}

          {autosplicingReducer.resetDrawerAction === 'RESET_CABLE' && (
            <div>
              <Typography.Text>
                By clicking apply, you will be resetting fibers for cable{' '}
                {cableId}.
              </Typography.Text>
            </div>
          )}
        </Row>

        {/* Form action bar */}
        <Row>
          <Col span={24} style={{ marginTop: 30 }}>
            <Tooltip
              title={
                selectedCableList.length === 0 ? 'No cable types selected.' : ''
              }
            >
              <Button
                type="primary"
                onClick={() => resetConfirmed()}
                style={{ marginRight: 10 }}
              >
                Apply
              </Button>
            </Tooltip>

            <Button
              type="default"
              onClick={() =>
                updateAutosplicingReducer({ resetDrawerVisible: false })
              }
            >
              Cancel
            </Button>
          </Col>
        </Row>
      </>
    );
  };

  return (
    <Drawer
      placement="right"
      width={isMobile ? '100%' : 450}
      title="Reset Connections"
      open={autosplicingReducer.resetDrawerVisible}
      onClose={() => updateAutosplicingReducer({ resetDrawerVisible: false })}
      destroyOnClose
    >
      <Row>
        {canResetPolygon ? (
          resetPolygonForm()
        ) : (
          <Row>
            <Col span={24}>
              <Alert
                message={`You tried to reset polygon from ${
                  QGISClosureTypeEnum[
                    Number(getProperty(record, 'ClosureType'))
                  ]
                } closure, but reset polygon feature is possible from L1 and L2 closures only. Please go to next L1 closure and try this again.`}
                type="error"
              />
            </Col>
            <Col span={24} style={{ marginTop: 20 }}>
              <Button
                type="default"
                onClick={() =>
                  updateAutosplicingReducer({
                    resetDrawerVisible: false,
                  })
                }
              >
                Back
              </Button>
            </Col>
          </Row>
        )}
      </Row>
    </Drawer>
  );
};

const mapDispatch = (dispatch: any) => ({
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  resetCable: (params: IResetFibersByCableRequest, cb: any) =>
    dispatch(resetFibersByCableRequest(params, cb)),
  resetTemplate: (params: IResetFibersByTemplateRequest, cb: any) =>
    dispatch(resetFibersByTemplateRequest(params, cb)),
  resetPolygonRequest: (params: IResetPolygonPayload, cb: any) =>
    dispatch(resetPolygon(params, cb)),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
});

export default connect(mapState, mapDispatch)(AutosplicingResetDrawer);
