import { useEffect, useState } from 'react';
import { Alert, Button, Card, Table } from 'antd';
import Papa from 'papaparse';

import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

import { getTableColumns, previewAFP } from './utils';

type AFPPreviewProps = {
  workList: DbRecordEntityTransform;
  todoOnly?: boolean;
};

export const AFPPreview = (props: AFPPreviewProps) => {
  const [isLoading, setIsLoading] = useState(true);
  const [fpes, setFpes] = useState<DbRecordEntityTransform[]>([]);
  const [adjustments, setAdjustmets] = useState<DbRecordEntityTransform[]>([]);
  const [summary, setSummary] = useState<string[]>([]);
  const [errors, setErrors] = useState<string[]>([]);
  const todoOnly = props.todoOnly ?? false;

  useEffect(() => {
    setIsLoading(true);
    previewAFP({ workList: props.workList, todoOnly })
      .then(lineItems => {
        setIsLoading(false);

        const fpes: DbRecordEntityTransform[] = [];
        const adjustments: DbRecordEntityTransform[] = [];

        lineItems.forEach((lineItem: any) => {
          if (lineItem.fpe_type === 'DEFAULT')
            fpes.push(lineItem);
          else
            adjustments.push(lineItem);
        });

        setFpes(fpes);
        setAdjustmets(adjustments);
      })
      .catch((error) => {
        const errorMessage =
          (error.response ? error.response.data?.message : error.message) ??
          'Error retrieving data.';
        setIsLoading(false);
        setErrors([errorMessage]);
      });
  }, [props.workList.id]);

  const handleExportCsv = () => {
    const tableData = [...fpes, ...(todoOnly ? [] : adjustments)];
    const csvData = Papa.unparse(tableData);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${props.workList.title?.replace(/\s+/, '_')}_${todoOnly ? 'Build_5-6' : 'Build_7-8'}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <>
      {errors.length === 0 &&
        <>
          <div className="association-data-table-wrapper">
            <Card
              size="small"
              title="Line Items"
              extra={[
                <Button onClick={handleExportCsv} disabled={isLoading || fpes.length === 0}>Export CSV</Button>,
              ]}
            >
              <Table
                loading={isLoading}
                showSorterTooltip={false}
                bordered
                tableLayout={'auto'}
                size="small"
                dataSource={fpes}
                columns={getTableColumns()}
              />
            </Card>
          </div>
          {!todoOnly && <div className="association-data-table-wrapper">
            <Card
              size="small"
              title="Adjustments"
            >
              <Table
                loading={isLoading}
                showSorterTooltip={false}
                bordered
                tableLayout={'auto'}
                size="small"
                dataSource={adjustments}
                columns={getTableColumns()}
              />
            </Card>
          </div>}
        </>
      }
      {summary.length > 0 && (
        <Alert
          type="warning"
          message="Issues"
          description={summary.map((msg, i) => (
            <p key={i}>{msg}</p>
          ))}
        />
      )}
      {errors.length > 0 && (
        <Alert
          type="error"
          message="Error"
          description={errors.map((msg, i) => (
            <p key={i}>{msg}</p>
          ))}
        />
      )}
    </>
  );
};
