import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { FC, useContext } from 'react';
import RecordCard from '@legacy/core/records/components/RecordCard';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import DetailView from '@core/views/DetailView';
import { DetailViewContext } from '@core/components/DetailViewContextProvider';
import BookingModal from '@legacy/core/appointments/components/BookingModal';

const { CRM_MODULE } = SchemaModuleTypeEnums;
const { ACCOUNT, CONTACT } = SchemaModuleEntityTypeEnums;

const AddressDetailView: FC = () => {
  const { record } = useContext(DetailViewContext);

  return (
    <DetailView
      showCollaborators
      headerExtras={[
        <BookingModal
          recordId={record?.id}
          launcherType="BUTTON"
          addressRecord={record!}
          scheduleType="INSTALL"
          readOnly
          launcherTitle="View Availability"
          drawerTitle="Availability Overview"
        />,
      ]}
      leftColumn={[
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={ACCOUNT}
          visibleProperties={['Status', 'Type']}
        />,
        <RecordCard
          showActionMenu
          showItemActionMenu
          record={record}
          displayQuickView
          associatedRecordModuleName={CRM_MODULE}
          associatedRecordEntityName={CONTACT}
          visibleProperties={['EmailAddress', 'Phone', 'Mobile']}
        />,
      ]}
    />
  );
};

export default AddressDetailView;
