import { Button, ButtonGroup, Card, CardList, Intent, Tag } from '@blueprintjs/core';
import { LinksViewProps } from './types';
import { Col, Row } from 'antd';

export const ListView = ({ links, onLinkClick, onLinkRemove }: LinksViewProps) => {
  // console.log("[DebugX] links", links);
  return (
    <CardList bordered={false}>
      {links.map((link) => {
        const linkedSchema = link.childSchema || link.parentSchema;
        return (
          <Card key={link.id} interactive>
            {/* V2*/}
            <Row justify="space-between" align="middle" style={{ width: '100%' }}>
              {/* Left */}
              <Col>
                {/* Linked Schema Entity name */}
                <span style={{ fontWeight: 500 }}>{linkedSchema?.entityName}</span>

                {/* Link label */}
                <Tag
                  intent="primary"
                  minimal
                  round
                  style={{
                    marginLeft: 15,
                    fontWeight: 400,
                    fontSize: '0.9em',
                    verticalAlign: 'middle',
                  }}
                >
                  {link.label}
                </Tag>

                {/* Link type */}
                <Tag
                  intent="success"
                  round
                  minimal
                  fill={false}
                  style={{ verticalAlign: 'middle', marginLeft: 8 }}
                >
                  {link.type}
                </Tag>
              </Col>

              {/* Right */}
              <Col>
                <ButtonGroup>
                  <Button small minimal icon="annotation" onClick={() => onLinkClick(link)} />
                  <Button
                    icon="trash"
                    intent={Intent.DANGER}
                    minimal
                    small
                    onClick={() => onLinkRemove(link)}
                  />
                </ButtonGroup>
              </Col>
            </Row>
            x{' '}
          </Card>
        );
      })}
    </CardList>
  );
};
