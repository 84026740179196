import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

export const TIME_SLOT_WIDTH_PX = 150;

export type WorkOrderBundle = {
  engineer: {
    id: string;
    properties: {
      EmailAddress: string;
    };
    schedules: any[];
    title: string;
  };
  workOrders: DbRecordEntityTransform[];
  slaSummary: any;
  completionSummary: any;
};
