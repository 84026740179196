import { DbRecordAssociationCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/dto/db.record.association.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';

export type TProductQuota = {
  type: 'BASE_PRODUCT' | 'ADD_ON_PRODUCT';
  maxAmount: number;
};

export const productQuotas: TProductQuota[] = [
  {
    type: 'BASE_PRODUCT',
    maxAmount: 1,
  },
  {
    type: 'ADD_ON_PRODUCT',
    maxAmount: 3,
  },
];

export type TOrderSummary = {
  BillingStartDate: string;
  ContractEndDate: string;
  ContractStartDate: string;
  ContractType: string;
  Date: string;
  OrderItems: DbRecordEntityTransform[];
  Subtotal: string;
  TotalDiscounts: string;
  TotalPrice: string;
  TotalTaxAmount: string;
};

export const initialOrderSummary = {
  BillingStartDate: '',
  ContractEndDate: '',
  ContractStartDate: '',
  ContractType: '',
  Date: '',
  OrderItems: [],
  Subtotal: '0.00',
  TotalDiscounts: '0.00',
  TotalPrice: '0.00',
  TotalTaxAmount: '0.00',
};

export type IOrderBuilderApplyRecontractQuoteDto =
  | {
      orderId: string;
      billingStartDate: string; // YYYY-MM-DD
      serviceStartDate: string; // YYYY-MM-DD
      nextOffer: any;
      nextProducts: DbRecordAssociationCreateUpdateDto[]; //<-- existing products
      additionalProducts: DbRecordAssociationCreateUpdateDto[]; // <-- new products added
      customerPhonePorting?: {
        AreaCode: string;
        CountryCode: string;
        SubscriberNumber: string;
        AuthorizedLOA: string;
        PortFromProvider: string;
      };
      skuDeviceMap: any;
      skuPriceChangeMap: any;
    }
  | {};
