import {
  CHECK_AUTOSPLICING_ACCESS_REQUEST,
  CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST,
  CHECK_AUTOSPLICING_RESULTS_REQUEST,
  CHECK_AUTOSPLICING_SPINE_REQUEST,
  CLEAN_AUTOSPLICING_ISSUES_REQUEST,
  GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST,
  GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST,
  GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST,
  GET_AUTOSPLICING_TEMPLATES_REQUEST,
  GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST,
  RESET_AUTOSPLICING_BY_CABLE_REQUEST,
  RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST,
  RESET_AUTOSPLICING_POLYGON_REQUEST,
  TOGGLE_AUTOSPLICING_GRAPH_MAXIMIZED_VIEW,
  UPDATE_AUTOSPLICING_LOADING_INDICATORS,
  UPDATE_AUTOSPLICING_REDUCER_STATE,
} from './constants';
import {
  IAutosplicingLoadingIndicators,
  IAutosplicingReducer,
  ICheckAutosplicingAccess,
  ICheckAutosplicingDistribution,
  ICheckAutosplicingResults,
  ICheckAutosplicingSpine,
  ICleanAutosplicingIssues,
  IGetAutosplicingMatricesByClosureId,
  IGetAutosplicingTemplateByPathname,
  IGetAutosplicingTemplateDifference,
  IGetAutosplicingTemplates,
  IGetSplicingShorthandByClosureId,
  IResetPolygonPayload,
} from './types';

export function updateAutosplicingReducerState(params: IAutosplicingReducer) {
  return {
    type: UPDATE_AUTOSPLICING_REDUCER_STATE,
    params,
  };
}

export function updateAutosplicingLoadingIndicators(params: IAutosplicingLoadingIndicators) {
  return {
    type: UPDATE_AUTOSPLICING_LOADING_INDICATORS,
    params,
  };
}

export function toggleAutosplicingMaximizedView() {
  return {
    type: TOGGLE_AUTOSPLICING_GRAPH_MAXIMIZED_VIEW,
  };
}

export function getAutosplicingTemplates(params: IGetAutosplicingTemplates, cb = () => {}) {
  return {
    type: GET_AUTOSPLICING_TEMPLATES_REQUEST,
    params,
    cb,
  };
}

export function getAutosplicingTemplateByPathname(
  params: IGetAutosplicingTemplateByPathname,
  cb = () => {},
) {
  return {
    type: GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST,
    params,
    cb,
  };
}

export function getAutosplicingTemplateDifference(
  params: IGetAutosplicingTemplateDifference,
  cb = () => {},
) {
  return {
    type: GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST,
    params,
    cb,
  };
}

export function resetPolygon(params: IResetPolygonPayload, cb = () => {}) {
  return {
    type: RESET_AUTOSPLICING_POLYGON_REQUEST,
    params,
    cb,
  };
}

export interface IResetFibersByTemplateRequest {
  closureId: number;
  cableId?: number;
}

export function resetFibersByTemplateRequest(params: IResetFibersByTemplateRequest, cb = () => {}) {
  return {
    type: RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST,
    params,
    cb,
  };
}

export interface IResetFibersByCableRequest {
  cableId: number;
  rootClosureId: number;
  rootCableId: number;
}

export function resetFibersByCableRequest(params: IResetFibersByCableRequest, cb = () => {}) {
  return {
    type: RESET_AUTOSPLICING_BY_CABLE_REQUEST,
    params,
    cb,
  };
}

export function getAutosplicingMatricesByClosureId(
  params: IGetAutosplicingMatricesByClosureId,
  cb = () => {},
) {
  return {
    type: GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST,
    params,
    cb,
  };
}

export function getSplicingShorthandByClosureId(
  params: IGetSplicingShorthandByClosureId,
  cb = () => {},
) {
  return {
    type: GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST,
    params,
    cb,
  };
}

export function checkAutosplicingSpine(params: ICheckAutosplicingSpine, cb = () => {}) {
  return {
    type: CHECK_AUTOSPLICING_SPINE_REQUEST,
    params,
    cb,
  };
}

export function checkAutosplicingDistribution(
  params: ICheckAutosplicingDistribution,
  cb = () => {},
) {
  return {
    type: CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST,
    params,
    cb,
  };
}

export function checkAutosplicingAccess(params: ICheckAutosplicingAccess, cb = () => {}) {
  return {
    type: CHECK_AUTOSPLICING_ACCESS_REQUEST,
    params,
    cb,
  };
}

export function checkAutosplicingResults(params: ICheckAutosplicingResults, cb = () => {}) {
  return {
    type: CHECK_AUTOSPLICING_RESULTS_REQUEST,
    params,
    cb,
  };
}

export function cleanAutosplicingIssues(params: ICleanAutosplicingIssues, cb = () => {}) {
  return {
    type: CLEAN_AUTOSPLICING_ISSUES_REQUEST,
    params,
    cb,
  };
}
