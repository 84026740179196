import { Alert, Button } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row, Typography } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import { httpPost } from '@core/http/requests';
import { canUserDeleteRecord } from '@core/helpers/rbacRules';
import { displayMessage, goCardlessErrorMessage } from '@legacy/core/messages/store/reducers';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';

interface Props {
  record: DbRecordEntityTransform;
  recordReducer: IRecordReducer;
  schemaReducer: ISchemaReducer;
  userReducer: any;
  alertMessage: any;
  hidden?: string[];
  goCardlessErrorMessage: any;
}

class PaymentMethodDisableBankAccount extends React.Component<Props> {
  state = { visible: false, isLoading: false, amount: 0, refundId: null };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleOk = async (e: any) => {
    const { schemaReducer, record, alertMessage, goCardlessErrorMessage } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record.schemaId);

    this.setState({
      isLoading: true,
    });

    if (record && schema) {
      await httpPost(
        `BillingModule/v1.0/gocardless/bank-accounts/${getProperty(
          record,
          'BankAccountId',
        )}/actions/disable`,
        {},
      )
        .then(() => {
          this.setState({
            visible: false,
            isLoading: false,
          });

          alertMessage({
            body: 'disable bank account requested',
            type: 'success',
          });
        })
        .catch((err) => {
          this.setState({
            isLoading: false,
          });
          goCardlessErrorMessage(err);
        });
    }
  };

  handleCancel = (e: any) => {
    this.setState({
      visible: false,
      isLoading: false,
    });
  };

  render() {
    const { userReducer, schemaReducer, record } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);

    return (
      <div>
        <Button
          disabled={!canUserDeleteRecord(userReducer, schema, record)}
          style={{ marginLeft: 4, marginRight: 4 }}
          intent="danger"
          outlined
          onClick={this.showModal}
        >
          Disable Bank Account
        </Button>
        <Alert
          isOpen={this.state.visible}
          onConfirm={this.handleOk}
          onCancel={this.handleCancel}
          loading={this.state.isLoading}
          cancelButtonText="Cancel"
          intent="danger"
          confirmButtonText="Disable Bank Account"
          canEscapeKeyCancel
        >
          <Row>
            <Col span={24}>
              <span style={{ fontWeight: 500 }}>Disable Bank Account</span>
            </Col>
            <Col span={24}>
              <ul>
                <li>
                  <Typography.Text>
                    Immediately cancels all associated mandates and cancellable payments.
                  </Typography.Text>
                </li>
                <li>
                  <Typography.Text>
                    A disabled bank account can be re-enabled by creating a new bank account
                    resource with the same details.
                  </Typography.Text>
                </li>
              </ul>
            </Col>
          </Row>
        </Alert>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  recordReducer: state.recordReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  goCardlessErrorMessage: (params: any) => dispatch(goCardlessErrorMessage(params)),
});

export default connect(mapState, mapDispatch)(PaymentMethodDisableBankAccount);
