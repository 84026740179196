import { OrganizationUserType } from '@d19n/temp-fe-d19n-models/dist/identity/organization/user/organization.user.type';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/transform/schema.entity.transform';
import moment from 'moment';

export const canUserAccessDesktopApp = (authUser: any) => {
  return hasPermissions(authUser, ['desktop.app.access']);
};

/**
 * Check for module in routingStructure and if found - check also for the entity.
 *
 * @param routingStructure
 * @param moduleName
 * @param entityName
 */
export const canUserAccessModuleAndEntityInRoutes = (
  routingStructure: any,
  moduleName: string,
  entityName: string,
) => {
  if (routingStructure) {
    const foundModule = routingStructure.find(
      (module: any, i: number) => module.moduleName === moduleName,
    );

    if (!!foundModule) {
      if (entityName !== 'Dashboard') {
        const foundEntity = foundModule.entities.find((entity: string) => entity == entityName);
        return !!foundEntity;
      } else {
        return true;
      }
    } else return false;
  } else return false;
};

/**
 *
 * @param authUser
 * @param moduleName
 */
export const canUserAccessModule = (authUser: any, moduleName: string) => {
  if (!!authUser && !!authUser.permissions && authUser.permissions.length > 0)
    return authUser.permissions.includes(`${moduleName.toLowerCase()}.access`);
  else return false;
};

/**
 * returns true if the user has the necessary permissions
 * returns false if the user does not have necessary permissions
 *
 * @param authUser
 * @param schema
 * @param recordAction
 */
export const canUserPerformAction = (
  authUser: any,
  schema: SchemaEntity | SchemaEntityTransform | undefined,
  recordAction: 'search' | 'get' | 'create' | 'update' | 'delete' | 'merge',
): boolean => {
  // If the user has no permissions do not allow access to anything
  if (authUser && authUser.permissions && authUser.permissions.length < 1) {
    return false;
  }

  if (!schema) {
    return false;
  }

  // we only want to check permissions if the schema has permissions
  if (schema && schema.permissions && schema.permissions.length > 0) {
    if (schema as SchemaEntity) {
      return authUser.permissions
        .map((elem: any) => elem)
        .includes(
          `${schema?.moduleName?.toLowerCase()}.${schema?.entityName?.toLowerCase()}.${recordAction}`,
        );
    } else if (schema as SchemaEntityTransform) {
      return authUser.permissions.includes(
        `${schema?.moduleName?.toLowerCase()}.${schema?.entityName?.toLowerCase()}.${recordAction}`,
      );
    }
  }

  return true;
};

export const canUserSearchRecord = (
  authUser: any,
  schema: SchemaEntity | SchemaEntityTransform | undefined,
) => {
  return canUserPerformAction(authUser, schema, 'search');
};

export const canUserGetRecord = (
  authUser: any,
  schema: SchemaEntity | SchemaEntityTransform | undefined,
) => {
  return canUserPerformAction(authUser, schema, 'get');
};

export const canUserCreateRecord = (
  authUser: any,
  schema: SchemaEntity | SchemaEntityTransform | undefined,
) => {
  return canUserPerformAction(authUser, schema, 'create');
};

/**
 * Uses the merge permission
 * @param authUser
 * @param schema
 * @param dbRecord
 */
export const canUserCloneRecord = (
  authUser: any,
  schema: SchemaEntity | SchemaEntityTransform | undefined,
  dbRecord?: DbRecordEntityTransform,
) => {
  const canActivate = canUserPerformAction(authUser, schema, 'merge');
  if (dbRecord && canActivate) {
    // if there is a record and the user canActivate
    // then we want to verify the record is in an actionable state
    return isRecordActionable(authUser, dbRecord);
  }

  return canActivate;
};

/**
 *
 * @param authUser
 * @param schema
 * @param dbRecord
 */
export const canUserUpdateRecord = (
  authUser: any,
  schema: SchemaEntity | SchemaEntityTransform | undefined,
  dbRecord?: DbRecordEntityTransform,
) => {
  const canActivate = canUserPerformAction(authUser, schema, 'update');
  if (dbRecord && canActivate) {
    // if there is a record and the user canActivate
    // then we want to verify the record is in an actionable state
    return isRecordActionable(authUser, dbRecord);
  }

  return canActivate;
};
/**
 *
 * @param authUser
 * @param schema
 * @param dbRecord
 */
export const canUserDeleteRecord = (
  authUser: any,
  schema: SchemaEntity | SchemaEntityTransform | undefined,
  dbRecord?: DbRecordEntityTransform,
) => {
  const canActivate = canUserPerformAction(authUser, schema, 'delete');

  if (dbRecord && canActivate) {
    // if there is a record and the user canActivate
    // then we want to verify the record is in an actionable state
    return isRecordActionable(authUser, dbRecord);
  }

  return canActivate;
};

export const canUserMergeRecord = (authUser: any, schema: SchemaEntity | SchemaEntityTransform) => {
  return canUserPerformAction(authUser, schema, 'merge');
};

/**
 *
 * @param authUser
 * @param schema
 * @param propertyName
 */
export const canUserDeleteProperty = (authUser: any, schema: any, propertyName: string) => {
  if (!!authUser && !!authUser.roles && authUser.roles.length > 0) {
    return authUser.roles.includes(
      '${schema.moduleName}.${schema.entityName}.${propertyName}.${action}',
    );
  }
  return false;
};

/**
 *
 * @param authUser
 * @param schema
 * @param propertyName
 */
export const canUserCreateProperty = (authUser: any, schema: any, propertyName: string) => {
  if (!!authUser && !!authUser.roles && authUser.roles.length > 0) {
    return authUser.roles.includes(
      '${schema.moduleName}.${schema.entityName}.${propertyName}.${action}',
    );
  }

  return false;
};

/**
 *
 * @param authUser
 */
export const isUserTokenExpired = () => {
  const tokenExpiresAt = localStorage.getItem(`tokenExpiresAt`);
  const isAfter = moment(moment().add(10, 'minutes').toISOString()).isAfter(tokenExpiresAt);
  const userToken = localStorage.getItem(`token`);
  if (isAfter || !tokenExpiresAt || !userToken) {
    localStorage.removeItem(`token`);
    localStorage.removeItem(`originalToken`);
    localStorage.removeItem(`tokenExpiresAt`);
    return true;
  }

  return false;
};
/**
 *
 * @param authUser
 */
export const isUserAuthenticated = (authUser?: any) => {
  const tokenExpiresAt = localStorage.getItem(`tokenExpiresAt`);
  const isAfter = moment(moment().add(10, 'minutes').toISOString()).isAfter(tokenExpiresAt);
  const userToken = localStorage.getItem(`token`);

  if (!authUser.user) {
    return false;
  }
  if (isAfter || !tokenExpiresAt || !userToken) {
    localStorage.removeItem(`token`);
    localStorage.removeItem(`originalToken`);
    localStorage.removeItem(`tokenExpiresAt`);
    return false;
  }

  return true;
};

/**
 *
 * @param authUser
 */
export const isSystemAdmin = (authUser?: any) => {
  if (!!authUser && !!authUser.roles && authUser.roles.length > 0) {
    return authUser.roles.includes('system.admin');
  }

  return false;
};

/**
 *
 * @param authUser
 */
export const isExternalUser = (authUser?: any) => {
  if (
    !!authUser &&
    !!authUser.roles &&
    authUser.roles.length > 0 &&
    authUser.user.userType === OrganizationUserType.EXTERNAL
  ) {
    return authUser.roles.includes('ExternalCustomerAccess');
  }

  return false;
};

export const hasPermissions = (authUser: any, permissionsToCheck: string[]): boolean => {
  // by default return false if user or his permissions undefined
  if (!authUser?.permissions) {
    return false;
  }

  // by default return true if permissions for check are empty
  if (permissionsToCheck?.length < 1) {
    return true;
  }

  // check all required permissions
  let hasAll: boolean = true;
  permissionsToCheck.forEach((perm) => {
    if (!authUser.permissions.includes(perm)) {
      hasAll = false;
    }
  });
  return hasAll;
};

export const hasAnyRoles: (authUser: any, ...roles: string[]) => boolean = (
  authUser: any,
  ...roles: string[]
): boolean => {
  if (!authUser?.roles) {
    // by default return false if user or his roles undefined
    return false;
  }

  if (roles?.length < 1) {
    // by default return true if roles for check are empty
    return true;
  }

  // check if has any of required roles
  let hasAny = false;
  for (const role of roles) {
    if (authUser.roles.includes(role)) {
      hasAny = true;
      break;
    }
  }

  return hasAny;
};
export const hasRole = (authUser: any, role: string) => {
  if (!authUser?.roles) {
    // by default return false if user or his roles undefined
    return false;
  }

  return authUser.roles.includes(role);
};

/**
 *
 * @param userReducer
 * @param record
 */
const isRecordActionable = (userReducer: any, record: DbRecordEntityTransform) => {
  if (record?.stage?.isFail) {
    return false;
  }

  return true;
};

/**
 *
 * @param authUser
 */
export const isBetaTester = (authUser?: any) => {
  return !!authUser.user?.isBetaTester;
};
