import { Button, Drawer } from '@blueprintjs/core';
import React, { useContext, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Col, Row } from 'antd';
import { HourlyViewContext } from '../index';
import dayjs from 'dayjs';

interface Props {}

const HourlyViewStatsDrawer: React.FC<Props> = (props: Props) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState<boolean>(false);
  const { state } = useContext(HourlyViewContext);

  // Convert to Title case and replace underscores with space
  const sanitizeText = (text: string) => {
    return text
      .toLowerCase()
      .split('_')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ');
  };

  const renderStat = (title: string, value: string | number | undefined) => (
    <Row style={{ padding: '8px 20px', border: '1px solid #efefef' }}>
      <Col span={20}>
        <span>{sanitizeText(title)}</span>
      </Col>
      <Col span={4} style={{ textAlign: 'right' }}>
        <span>{value || '-'}</span>
      </Col>
    </Row>
  );

  return (
    <>
      <Drawer
        title="Statistics"
        icon="stacked-chart"
        style={{ width: isMobile ? '95%' : '30%' }}
        isOpen={isDrawerVisible}
        onClose={() => setIsDrawerVisible(false)}
      >
        <div style={{ overflow: 'auto' }}>
          {renderStat('Total Cancellations', state.remoteStats?.totalCancellations)}
          {renderStat('Total Reschedules', state.remoteStats?.totalReschedules)}
          {renderStat('Total Delays', state.remoteStats?.totalDelays)}
          {renderStat('Requested By Supplier', state.remoteStats?.requestedBySupplier)}
          {renderStat('Requested By Tenant', state.remoteStats?.requestedByTenant)}

          {/* Reschedule Reasons */}
          {state.remoteStats?.rescheduleReasonSummary && (
            <Row>
              <Col span={24} style={{ padding: '8px 20px', marginTop: 20, marginBottom: 5 }}>
                <span style={{ fontWeight: 600 }}>Reschedule Reason Summary</span>
              </Col>
              <Col span={24}>
                {Object.keys(state.remoteStats.rescheduleReasonSummary).map((key) => {
                  return renderStat(key, state.remoteStats?.rescheduleReasonSummary[key]);
                })}
              </Col>
            </Row>
          )}

          {/* Cancel Reasons */}
          {state.remoteStats?.cancelReasonSummary && (
            <Row>
              <Col span={24} style={{ padding: '8px 20px', marginTop: 20, marginBottom: 5 }}>
                <span style={{ fontWeight: 600 }}>Cancel Reason Summary</span>
              </Col>
              <Col span={24}>
                {Object.keys(state.remoteStats.cancelReasonSummary).map((key) => {
                  return renderStat(key, state.remoteStats?.cancelReasonSummary[key]);
                })}
              </Col>
            </Row>
          )}
          {/* Delay Reasons */}
          {state.remoteStats?.delayReasonSummary && (
            <Row>
              <Col span={24} style={{ padding: '8px 20px', marginTop: 20, marginBottom: 5 }}>
                <span style={{ fontWeight: 600 }}>Delay Reason Summary</span>
              </Col>
              <Col span={24}>
                {Object.keys(state.remoteStats.delayReasonSummary).map((key) => {
                  return renderStat(key, state.remoteStats?.delayReasonSummary[key]);
                })}
              </Col>
            </Row>
          )}

          {/* Date Shift Summary */}
          {state.remoteStats?.dateShiftSummary && (
            <Row style={{ marginBottom: 20 }}>
              <Col span={24} style={{ padding: '8px 20px', marginTop: 20, marginBottom: 5 }}>
                <span style={{ fontWeight: 600 }}>Date Shift Summary</span>
              </Col>
              <Col span={24}>
                {Object.keys(state.remoteStats.dateShiftSummary).map((key) => {
                  return renderStat(
                    dayjs(key).format('DD/MM/YYYY'),
                    state.remoteStats?.dateShiftSummary[key],
                  );
                })}
              </Col>
            </Row>
          )}
        </div>
      </Drawer>
      <Button
        disabled={!state.remoteStats}
        icon="stacked-chart"
        style={{ marginRight: 8 }}
        onClick={() => setIsDrawerVisible(true)}
      />
    </>
  );
};
export default HourlyViewStatsDrawer;
