import { Button, Callout, NonIdealState } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { MyCasesContext } from '../../index';
import { MY_CASES_SET_FEED_CONTAINER_REF } from '../../store/constants';
import MyCasesCall from './MyCasesCall';
import MyCasesEmail from './MyCasesEmail';
import MyCasesMessage from './MyCasesMessage';
import MyCasesNote from './MyCasesNote';
import './styles.scss';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import MyCasesMessageEditorWidget from './MyCasesMessageEditorWidget';
import MyCasesEmailEditorWidget from '../MyCasesEmailEditorWidget';
import MyCasesNoteEditorWidget from '../MyCasesNoteEditorWidget';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import CustomerTypingMessage from './CustomerTypingMessage';
import AgentTypingMessage from './AgentTypingMessage';
import { sentenceCase } from 'change-case';
import { toSentenceCase } from '@core/helpers/stringHelpers';

interface Props {
}

const MyCasesConversationFeed: React.FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(MyCasesContext);
  const selectedCase: DbRecordEntityTransform | undefined = state.selectedCase;
  const notes: DbRecordEntityTransform[] = state.selectedCaseNotes;
  const messages: DbRecordEntityTransform[] = state.selectedCaseMessages;
  const emails: DbRecordEntityTransform[] = state.selectedCaseEmails;
  const calls: DbRecordEntityTransform[] = state.selectedCaseCalls;
  const { isSingleCaseMode } = state;

  const feedContainerRef = useRef<HTMLDivElement | null>(null);
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);

  useEffect(() => {
    getCaseSchema();
  }, []);

  const getCaseSchema = async () => {
    const schema = await getOdinSchemaByEntity('SupportModule', 'Case');
    setCaseSchema(schema);
  };

  useEffect(() => {
    if (feedContainerRef.current) {
      dispatch({ type: MY_CASES_SET_FEED_CONTAINER_REF, payload: feedContainerRef });
    }
  }, [feedContainerRef?.current?.scrollHeight]);

  // We need to merge the conversations and messages into a single feed, and sort by createdBy date
  let Feed: DbRecordEntityTransform[] = [];
  if (messages.length > 0) {
    Feed = Feed.concat(messages);
  }
  if (notes && notes.length > 0) {
    Feed = Feed.concat(notes);
  }
  if (emails && emails.length > 0) {
    Feed = Feed.concat(emails);
  }
  if (calls && calls.length > 0) {
    Feed = Feed.concat(calls);
  }
  Feed.sort((a, b) => {
    return dayjs(b.createdAt).isAfter(dayjs(a.createdAt)) ? 1 : -1;
  });

  const isRecordMessage = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Message') > -1;
  };

  const isRecordNote = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('SupportModule:Note') > -1;
  };

  const isRecordEmail = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Email') > -1;
  };

  const isRecordCall = (record: DbRecordEntityTransform) => {
    return record.entity && record.entity.indexOf('NotificationModule:Call') > -1;
  };

  const smoothScrollFeedToBottom = () => {
    const feedContainer = state.feedContainerRef?.current;
    if (feedContainer) {
      feedContainer.scrollTo({
        top: feedContainer.scrollTop + feedContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  const renderEmail = (email: DbRecordEntityTransform) => {
    return (
      <div style={{ marginLeft: 15 }}>
        <Row>
          <Col span={24}>
            <MyCasesEmail email={email} />
          </Col>
        </Row>
      </div>
    );
  };

  const conversationClosedBy: string | undefined = getProperty(state.selectedCaseConversation, 'ClosedBy');
  const conversationClosedAt: string | undefined = getProperty(state.selectedCaseConversation, 'ClosedAt');

  let conversationClosedText = 'This conversation is closed';

  if (conversationClosedBy && conversationClosedAt) {
    conversationClosedText = `This conversation was closed by ${toSentenceCase(conversationClosedBy)} on ${dayjs(conversationClosedAt).format('DD/MM/YYYY')} at ${dayjs(conversationClosedAt).format('HH:mm:ss')}`;
  }


  return (
    <Col
      span={state.isContactPanelOpen ? 23 : 16}
      style={{
        background: 'white',
        height: `calc(100vh - ${state.isSingleCaseMode ? '180px' : '180px'})`,
        borderLeft: '1px solid #D9DADA',
      }}
    >
      <Row
        style={{
          backgroundColor: 'white',
          height: `calc(100vh - ${isSingleCaseMode ? '240px' : '240px'})`,
        }}
      >
        {/* No Selected Case */}
        {!state.selectedCase && (
          <Col span={24} style={{ textAlign: 'center', padding: 20 }}>
            <NonIdealState
              icon="chat"
              title="Select a Case"
              description="Select a case from the list to view the conversation"
            />
          </Col>
        )}

        {/* FEED *******************************************************************/}
        <Col
          ref={feedContainerRef}
          span={24}
          style={{
            overflowY: 'auto',
            height: '100%',
            flexDirection: 'column-reverse',
            paddingTop: 10,
            display: !state.selectedCase ? 'none' : 'flex',
            boxShadow: 'inset 0px 10px 9px -10px #D9DADA',
          }}
        >
          {/* Bottom Feed Buttons */}
          {selectedCase && (
            <Row style={{ padding: 15, marginRight: 10 }} justify="end">
              <Col>
                {/* Email Customer Button */}
                <MyCasesEmailEditorWidget />

                {/* Add Internal Comment */}
                <MyCasesNoteEditorWidget />
              </Col>
            </Row>
          )}

          {/* Closed Conversation Message */}
          {state.selectedCaseConversation &&
            getProperty(state.selectedCaseConversation, 'Status') === 'CLOSED' && (
              <Row style={{ padding: 15 }}>
                <Col span={24}>
                  <Callout
                    style={{ borderRadius: 10 }}
                    intent="warning"
                    icon="info-sign"
                    title={conversationClosedBy ? `Conversation closed by ${sentenceCase(conversationClosedBy)}` : 'Conversation Closed'}
                  >
                    {conversationClosedText}. You can no longer send messages.
                  </Callout>
                </Col>
              </Row>
            )}

          {/* Customer is typing text */}
          {state.clientTypingText.length > 0 && <CustomerTypingMessage />}

          {/* Agent is typing text */}
          {state.agentTypingText.length > 0 && <AgentTypingMessage />}

          {/* Feed Items */}
          {Feed.map((item: DbRecordEntityTransform, i: number) => {
            if (isRecordMessage(item)) {
              return <MyCasesMessage message={item} caseSchema={caseSchema!} key={i} />;
            } else if (isRecordNote(item)) {
              return <MyCasesNote note={item} key={i} />;
            } else if (isRecordCall(item)) {
              return <MyCasesCall call={item} key={i} />;
            } else if (isRecordEmail(item)) {
              return renderEmail(item);
            } else return <></>;
          })}
        </Col>
      </Row>

      <Row style={{ borderTop: '1px solid #D9DADA' }} align="middle">
        {state.isScrollDownButtonVisible && (
          <Col span={24}>
            <Row
              style={{
                bottom: 15,
                right: 0,
                position: 'absolute',
                display: 'flex',
                paddingRight: 20,
                flexShrink: 0,
                flexDirection: 'row',
              }}
            >
              <Col span={24} style={{ textAlign: 'right' }}>
                <Button
                  className={state.currentCaseUnreadCount > 0 ? 'scrollDownButton' : ''}
                  icon="arrow-down"
                  onClick={smoothScrollFeedToBottom}
                  outlined={state.currentCaseUnreadCount === 0}
                  intent={state.currentCaseUnreadCount > 0 ? 'success' : 'primary'}
                  text={state.currentCaseUnreadCount > 0 ? state.currentCaseUnreadCount : ''}
                  style={{
                    borderRadius: 50,
                    background: state.currentCaseUnreadCount === 0 ? 'white' : '#248551',
                  }}
                />
              </Col>
            </Row>
          </Col>
        )}

        <Col span={24}>
          <MyCasesMessageEditorWidget />
        </Col>
      </Row>
    </Col>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesConversationFeed);
