import React from 'react';

interface Props {
  children?: any;
}

const PoundSymbol: React.FC<Props> = (props: Props) => {
  const { children } = props;
  return (
    <>
      <span>£</span>
      {children}
    </>
  );
};
export default PoundSymbol;
