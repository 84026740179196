import { Tag } from '@blueprintjs/core';
import React from 'react';
import { sanitizeCaseStageName } from '../../helpers';
import './styles.scss';

interface Props {
  status: string | undefined;
}

const CommunicationsStatusTag: React.FC<Props> = (props: Props) => {
  const { status } = props;

  const sanitizedName = sanitizeCaseStageName(status);

  return <Tag className={`communicationsStatusTag ${sanitizedName}`}>{status || 'Unknown'}</Tag>;
};
export default CommunicationsStatusTag;
