import { AimOutlined, EyeOutlined, TableOutlined } from '@ant-design/icons';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  Button,
  Col,
  Empty,
  Form,
  Row,
  Select,
  Spin,
  Table,
  Tooltip,
} from 'antd';
import { FC, useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  IMapSetWorkItemQuickView,
  IMapUpdateWorkItems,
  MapReducerUpdate,
  resetWorkItemForm,
  setWorkItemQuickView,
  updateMapState,
  updateWorkItems,
} from '@netomnia/modules/ProjectModule/Map/store/actions';
import {
  ISchemaByModuleAndEntity,
  getSchemaByModuleAndEntityRequest,
} from '@legacy/core/schemas/store/actions';
import {
  IAddRecordToShortList,
  ISearchRecords,
  addRecordToShortList,
  searchRecordsRequest,
} from '@legacy/core/records/store/actions';
import { MapReducer } from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../../core/helpers/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import './styles.scss';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { ColumnsType } from 'antd/lib/table';
import {
  QGISBuildStatusEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import { getFeatureByIdAndZoom } from '../../helpers';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { httpGet } from '../../../../../../core/http/requests';
import { SearchQueryType } from '@d19n/temp-fe-d19n-models/dist/search/search.query.type';
import { Link, withRouter } from 'react-router-dom';

interface Props {
  updateMap: Function;
  getSchema: Function;
  mapReducer: MapReducer;
  schemaReducer: any;
  alertMessage: Function;
  shortListRecord: Function;
  setQuickView: Function;
  resetForm: Function;
  updateWI: (payload: IMapUpdateWorkItems) => void;
  searchRecords: (params: ISearchRecords, cb?: any) => void;
}

interface TableDataType {
  key: string;
  featureType: string;
  recordId: string;
  buildStatus: string;
  actions: DbRecordEntityTransform;
}

export type TSavedViewRecord = {
  createdAt: string;
  deletedAt: string;
  entityName: string;
  id: string;
  key: string;
  moduleName: string;
  title: string;
  updatedAt: string;
  userId: string;
  view: {
    columns: any[];
    queryBuilder: any;
    search: any;
  };
};

type TElasticPagination = {
  page: number;
  size: number;
  totalRecords: number;
};

const { PROJECT_MODULE } = SchemaModuleTypeEnums;
const { FEATURE, PROJECT } = SchemaModuleEntityTypeEnums;

const MapSidebarSavedViews: FC<Props> = (props: Props) => {
  const {
    setQuickView,
    getSchema,
    searchRecords,
    schemaReducer,
    mapReducer,
    updateMap,
    alertMessage,
    resetForm,
    updateWI,
  } = props;

  const [searchingSavedViews, setSearchingSavedViews] = useState<boolean>(false);
  const [savedViewsList, setSavedViewsList] = useState<any[] | undefined>(undefined);
  const [selectedSavedView, setSelectedSavedView] = useState<TSavedViewRecord | undefined>(
    undefined,
  );
  const [elasticPagination, setElasticPagination] = useState<TElasticPagination | undefined>(
    undefined,
  );
  const [featureList, setFeatureList] = useState<DbRecordEntityTransform[]>([]);
  const [loadingFeatureList, setLoadingFeatureList] = useState<boolean>(false);
  const [featureSchema, setFeatureSchema] = useState<SchemaEntity | undefined>(undefined);
  const [savedViewsForm] = Form.useForm();


  // On component mount fetch Feature schema from shortlist or fallback to API request
  useEffect(() => {
    searchSavedViews();

    if (!featureSchema) {
      const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        PROJECT_MODULE,
        FEATURE,
      );

      if (shortlistSchema) {
        setFeatureSchema(shortlistSchema);
      } else {
        getSchema(
          { moduleName: PROJECT_MODULE, entityName: FEATURE },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setFeatureSchema(responseSchema);
            }
          },
        );
      }
    }
  }, []);

  // Search for Saved lists on component launch
  const searchSavedViews = async () => {
    setSearchingSavedViews(true);

    await httpGet(`${PROJECT_MODULE}/v1.0/views/byModule/${PROJECT_MODULE}/${FEATURE}`)
      .then((res) => {
        setSavedViewsList(res.data.data);
        setSearchingSavedViews(false);
      })
      .catch((err) => {
        const error = err.response ? err.response.data : undefined;
        setSearchingSavedViews(false);
        setSavedViewsList([]);
        console.error(error);
      });
  };

  // When Saved view is selected, fetch the Features.
  useEffect(() => {
    if (selectedSavedView && featureSchema) {
      setLoadingFeatureList(true);
      let query: SearchQueryType = selectedSavedView.view?.search[featureSchema.id];

      searchRecords(
        {
          schema: featureSchema,
          searchQuery: query,
        },
        (results: any) => {
          setLoadingFeatureList(false);
          const records = results?.data.data;

          if (records.length > 0) {
            setFeatureList(results?.data.data);
            setElasticPagination({
              page: results?.data?.search?.pageable?.page
                ? Number(results?.data?.search?.pageable?.page) + 1
                : 1,
              size: results?.data?.search?.pageable?.size || 0,
              totalRecords: results?.data?.pageable?.totalRecords || 0,
            });
          } else {
            setFeatureList([]);
            setElasticPagination(undefined);
          }
        },
      );
    }
  }, [selectedSavedView]);

  // When Pagination is updated, search the features again
  const searchFeaturesByPagination = (
    current: number | undefined,
    pageSize: number | undefined,
  ) => {
    if (selectedSavedView && featureSchema) {
      setLoadingFeatureList(true);

      let query: SearchQueryType = selectedSavedView.view?.search[featureSchema.id];
      query.pageable = {
        page: current,
        size: pageSize,
      };

      searchRecords(
        {
          schema: featureSchema,
          searchQuery: query,
        },
        (results: any) => {
          setLoadingFeatureList(false);
          const records = results?.data.data;

          if (records.length > 0) {
            setFeatureList(results?.data.data);
            setElasticPagination({
              page: results?.data?.search?.pageable?.page
                ? Number(results?.data?.search?.pageable?.page) + 1
                : 1,
              size: results?.data?.search?.pageable?.size || 0,
              totalRecords: results?.data?.pageable?.totalRecords || 0,
            });
          } else {
            setFeatureList([]);
            setElasticPagination(undefined);
          }
        },
      );
    }
  };


  // Find the selected Saved list and save to local state
  const handleSavedListSelection = (selectedSavedList: string) => {
    const selected = savedViewsList?.find(
      (item: TSavedViewRecord) => item.id === selectedSavedList,
    );
    if (selected) {
      setSelectedSavedView(selected);
    }
  };

  // Construct columns for Work item related features
  const getTableColumns = () => {
    const columns: ColumnsType<TableDataType> = [
      {
        title: 'Build status',
        dataIndex: 'buildStatus',
        key: 'buildStatus',
        render: (text) => <span>{text}</span>,
        sorter: (a: any, b: any) => a.buildStatus.length - b.buildStatus.length,
        onFilter: (value: any, record) => record.buildStatus.indexOf(value) === 0,
        filters: Object.keys(QGISBuildStatusEnum).map((key: any, i: number) => ({
          text: QGISBuildStatusEnum[key],
          value: QGISBuildStatusEnum[key],
        })),
      },
      {
        title: 'Type',
        dataIndex: 'featureType',
        key: 'featureType',
        render: (text) => <span>{text}</span>,
        sorter: (a: any, b: any) => a.featureType.length - b.featureType.length,
      },
      {
        title: 'Id',
        dataIndex: 'recordId',
        key: 'recordId',
        render: (id: string) => <span>{id}</span>,
        sorter: (a: any, b: any) => Number(a.recordId) - Number(b.recordId),
      },

      {
        title: 'Actions',
        dataIndex: 'actions',
        key: 'actions',
        align: 'right',
        render: (record: DbRecordEntityTransform) => (
          <div key={record.id}>
            <Button
              key={`zoomInBtn-${record.id}`}
              style={{ marginRight: 8 }}
              icon={<AimOutlined />}
              onClick={() => {
                const query = `type=${record.type?.toLowerCase()}&featureId=${getProperty(
                  record,
                  'ExternalRef',
                )}`;
                updateMap({
                  mapSidebarVisible: !isMobile,
                });
                getFeatureByIdAndZoom(mapReducer, updateMap, alertMessage, query);
              }}
            />
            <Button
              key={`quickViewBtn-${record.id}`}
              icon={
                <EyeOutlined
                  onClick={() =>
                    setQuickView({
                      record: record,
                      visible: true,
                    })
                  }
                />
              }
            />
          </div>
        ),
      },
    ];

    return columns;
  };

  // Table helper methods
  const renderPagination = () => {
    if (elasticPagination) {
      return {
        current: elasticPagination.page,
        pageSize: elasticPagination.size,
        total: elasticPagination.totalRecords,
      };
    }
  };
  const getTableData = (): TableDataType[] => {
    return featureList.map((feature: DbRecordEntityTransform, i: number) => ({
      key: String(i),
      featureType: feature.type || '-',
      recordId: getProperty(feature, 'ExternalRef'),
      buildStatus: String(QGISBuildStatusEnum[getProperty(feature, 'BuildStatus')]),
      actions: feature,
    }));
  };

  return (
    <Row>
      <Col span={selectedSavedView ? 21 : 24}>
        <Form name="savedViewsForm" form={savedViewsForm} onFinish={(values: any) => {
        }}>
          <Form.Item name="savedListSelect" style={{ marginBottom: 3 }}>
            <Select
              showSearch
              defaultActiveFirstOption={false}
              notFoundContent={null}
              disabled={searchingSavedViews || (savedViewsList && savedViewsList.length === 0)}
              loading={searchingSavedViews}
              placeholder="Select Saved View"
              onChange={handleSavedListSelection}
              style={{ width: '100%', marginTop: 5 }}
              filterOption={(input: string, option: any) =>
                option?.label?.toLowerCase().indexOf(input.toLowerCase()) > -1
              }
            >
              {savedViewsList && savedViewsList?.length! > 0 ? (
                savedViewsList?.map((item: DbRecordEntityTransform) => {
                  return (
                    <Select.Option
                      className="savedViewOption"
                      label={item?.title}
                      value={item?.id}
                      key={item.key}
                    >
                      {item?.title}
                    </Select.Option>
                  );
                })
              ) : (
                <></>
              )}
            </Select>
          </Form.Item>
        </Form>
      </Col>

      {selectedSavedView ? (
        <Col span={3} style={{ textAlign: 'right' }}>
          <Tooltip title="Open in Table View" mouseEnterDelay={0.8}>
            <Link
              to={`/${PROJECT_MODULE}/${FEATURE}#View_${selectedSavedView.key}`}
              target="_blank"
            >
              <Button style={{ marginTop: 5 }} icon={<TableOutlined />} />
            </Link>
          </Tooltip>
        </Col>
      ) : (
        <></>
      )}

      <Col span={24} style={{ marginTop: 10 }}>
        {/* There are no associated features -> Show Empty */}
        {selectedSavedView && !loadingFeatureList && featureList.length === 0 ? (
          <Empty style={{ marginTop: 20 }} description="No associated Features found" />
        ) : (
          <></>
        )}

        {/* There are associated features -> render table */}
        {featureList.length > 0 ? (
          <Table
            loading={loadingFeatureList}
            pagination={renderPagination()}
            bordered
            className="featureListTable"
            size="small"
            columns={getTableColumns()}
            dataSource={getTableData()}
            onChange={(pagination) =>
              searchFeaturesByPagination(pagination.current!, pagination.pageSize)
            }
          />
        ) : (
          <></>
        )}

        {/* User doesn't have any saved views */}
        {savedViewsList && savedViewsList.length === 0 ? (
          <Row>
            <Col span={24} style={{ padding: '10px 0' }}>
              <Empty description="You don't have saved views."></Empty>
            </Col>
          </Row>
        ) : (
          <></>
        )}

        {/* Loading feature list... */}
        {loadingFeatureList && featureList.length < 1 ? (
          <div style={{ textAlign: 'center', padding: '10px 0' }}>
            <Spin style={{ marginBottom: 10 }} />
            <br />
            <span>Loading ...</span>
          </div>
        ) : (
          <></>
        )}
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  mapReducer: state.mapReducer,
  schemaReducer: state.schemaReducer,
});
const mapDispatch = (dispatch: any) => ({
  updateMap: (params: MapReducerUpdate) => dispatch(updateMapState(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsRequest(params, cb)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
  setQuickView: (params: IMapSetWorkItemQuickView) => dispatch(setWorkItemQuickView(params)),
  resetForm: () => dispatch(resetWorkItemForm()),
  updateWI: (params: IMapUpdateWorkItems) => dispatch(updateWorkItems(params)),
});

export default withRouter(connect(mapState, mapDispatch)(MapSidebarSavedViews));
