export const GET_DAILY_SALES_METRICS_REQUEST = 'GET_DAILY_SALES_METRICS_REQUEST';
export const GET_DAILY_SALES_METRICS_SUCCESS = 'GET_DAILY_SALES_METRICS_SUCCESS';
export const GET_DAILY_SALES_METRICS_ERROR = 'GET_DAILY_SALES_METRICS_ERROR';

export const GET_WEEKLY_SALES_METRICS_REQUEST = 'GET_WEEKLY_SALES_METRICS_REQUEST';
export const GET_WEEKLY_SALES_METRICS_SUCCESS = 'GET_WEEKLY_SALES_METRICS_SUCCESS';
export const GET_WEEKLY_SALES_METRICS_ERROR = 'GET_WEEKLY_SALES_METRICS_ERROR';

export const GET_PIPELINES_OVERVIEW_REQUEST = 'GET_PIPELINES_OVERVIEW_REQUEST';
export const GET_PIPELINES_OVERVIEW_SUCCESS = 'GET_PIPELINES_OVERVIEW_SUCCESS';
export const GET_PIPELINES_OVERVIEW_ERROR = 'GET_PIPELINES_OVERVIEW_ERROR';

export const GET_PIPELINES_RAG_OVERVIEW_REQUEST = 'GET_PIPELINES_RAG_OVERVIEW_REQUEST';
export const GET_PIPELINES_RAG_OVERVIEW_SUCCESS = 'GET_PIPELINES_RAG_OVERVIEW_SUCCESS';
export const GET_PIPELINES_RAG_OVERVIEW_ERROR = 'GET_PIPELINES_RAG_OVERVIEW_ERROR';

export const GET_ORDERS_OVERVIEW_REQUEST = 'GET_ORDERS_OVERVIEW_REQUEST';
export const GET_ORDERS_OVERVIEW_SUCCESS = 'GET_ORDERS_OVERVIEW_SUCCESS';
export const GET_ORDERS_OVERVIEW_ERROR = 'GET_ORDERS_OVERVIEW_ERROR';

export const GET_BILLING_OVERVIEW_REQUEST = 'GET_BILLING_OVERVIEW_REQUEST';
export const GET_BILLING_OVERVIEW_SUCCESS = 'GET_BILLING_OVERVIEW_SUCCESS';
export const GET_BILLING_OVERVIEW_ERROR = 'GET_BILLING_OVERVIEW_ERROR';

export const GET_RECORD_COUNTS_REQUEST = 'GET_RECORD_COUNTS_REQUEST';
export const GET_RECORD_COUNTS_SUCCESS = 'GET_RECORD_COUNTS_SUCCESS';
export const GET_RECORD_COUNTS_ERROR = 'GET_RECORD_COUNTS_ERROR';

export const GET_INVESTIGATION_OVERVIEW_REQUEST = 'GET_INVESTIGATION_OVERVIEW_REQUEST';
export const GET_INVESTIGATION_OVERVIEW_SUCCESS = 'GET_INVESTIGATION_OVERVIEW_SUCCESS';
export const GET_INVESTIGATION_OVERVIEW_ERROR = 'GET_INVESTIGATION_OVERVIEW_ERROR';

export const GET_TRANSACTION_OVERVIEW_REQUEST = 'GET_TRANSACTION_OVERVIEW_REQUEST';
export const GET_TRANSACTION_OVERVIEW_SUCCESS = 'GET_TRANSACTION_OVERVIEW_SUCCESS';
export const GET_TRANSACTION_OVERVIEW_ERROR = 'GET_TRANSACTION_OVERVIEW_ERROR';
