import { Spinner } from '@blueprintjs/core';
import React, { Ref } from 'react';
import SendMessageIcon from '../Icons/SendMessageIcon';
import ThumbsUpIcon from '../Icons/ThumbsUpIcon';
import { motion } from 'framer-motion';
// import { EmojiPicker } from '../../../modules/SupportModule/views/MyCasesView/emoji-picker';

interface ChatBottomBarProps {
  textareaRef: Ref<any>;
  isLoading: boolean;
  setMessage: (value: any) => void;
  sendMessage: (value: any) => void;
  message: any;
  isSendingMessage: boolean;
  handleSendThumbsUp: () => void;
}

const ChatBottomBar = ({
  textareaRef,
  isLoading,
  setMessage,
  sendMessage,
  message,
  isSendingMessage,
  handleSendThumbsUp,
}: ChatBottomBarProps) => {
  return (
    <div className="chat-bottom-bar">
      <div className="chat-bottom-bar-input-wrapper">
        <motion.div
          key="input"
          layout
          initial={{ opacity: 0, scale: 1 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 1 }}
          transition={{
            opacity: { duration: 0.05 },
            layout: {
              type: 'spring',
              bounce: 0.15,
            },
          }}
          style={{ width: '100%', position: 'relative' }}
        >
          <textarea
            ref={textareaRef}
            disabled={isLoading}
            placeholder="Aa"
            readOnly={isLoading}
            className="chat-bottom-bar-input-field"
            onChange={(e) => {
              setMessage(e.currentTarget.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendMessage(e.currentTarget.value);
                e.currentTarget.value = '';
              }
              if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault();
                setMessage((prev: any) => prev + '\n');
              }
            }}
          />
          {/* <div className="chat-bottom-bar-emoji-picker-container">
            <EmojiPicker
              onChange={(value) => {
                console.log('value --> ', value);
                setMessage(message + value);
                if (textareaRef && (textareaRef as any).current) {
                  (textareaRef as any).current.focus();
                }
              }}
            />
          </div> */}
        </motion.div>
      </div>
      {message.trim() && !isSendingMessage ? (
        <button type="button" className="chat-button" onClick={() => sendMessage(message)}>
          <SendMessageIcon />
        </button>
      ) : !isSendingMessage ? (
        <button type="button" className="chat-button" onClick={handleSendThumbsUp}>
          <ThumbsUpIcon />
        </button>
      ) : (
        <Spinner size={10} style={{ marginLeft: '10px' }} />
      )}
    </div>
  );
};

export default ChatBottomBar;
