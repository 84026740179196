import {
  SchemaModuleTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';

const modules = Object.values(SchemaModuleTypeEnums).map((module: any) => {
  return { value: module, label: module };
});

export const formFields = [
  {
    label: 'Name',
    property: 'name',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input name',
    value: undefined,
  },
  {
    label: 'Description',
    property: 'description',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input description',
    value: undefined,
  },
  {
    label: 'Module Name',
    property: 'moduleName',
    type: 'ENUM',
    isRequired: true,
    message: 'Please input module name',
    isHidden: false,
    value: undefined,
    isDisabled: false,
    options: modules,
    allowSort: true,
    allowSearch: true,
  },
  {
    label: 'Entity Name',
    property: 'entityName',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input entity name',
    isHidden: false,
    value: undefined,
    isDisabled: false,
  },
];
