import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Empty, Row } from 'antd';
import React from 'react';
import RecordCard from '@legacy/core/records/components/RecordCard';

interface Props {
  appointments: DbRecordEntityTransform[];
}

const NotificationDrawerAppointmentsList: React.FC<Props> = (props: Props) => {
  const { appointments } = props;
  return (
    <Row>
      {appointments.length > 0 ? (
        appointments.map((appointment: DbRecordEntityTransform) => {
          return (
            <Col span={24} key={appointment.id}>
              <RecordCard
                record={appointment}
                visibleProperties={['Date', 'Address', 'Contact']}
                displayQuickView
                showCreatedBy
                hideRecordNumber
              />
            </Col>
          );
        })
      ) : (
        <Col span={24} style={{ marginTop: 50 }}>
          <Empty description="No appointments for today" />
        </Col>
      )}
    </Row>
  );
};
export default NotificationDrawerAppointmentsList;
