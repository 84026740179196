export const SEND_CONFIRMATION_EMAIL_REQUEST = 'SEND_CONFIRMATION_EMAIL_REQUEST';
export const SEND_CONFIRMATION_EMAIL_SUCCESS = 'SEND_CONFIRMATION_EMAIL_SUCCESS';
export const SEND_CONFIRMATION_EMAIL_ERROR = 'SEND_CONFIRMATION_EMAIL_ERROR';

// ODN-866 request email data
export const GET_EMAIL_DATA_REQUEST = 'GET_EMAIL_DATA_REQUEST';
export const GET_EMAIL_DATA_SUCCESS = 'GET_EMAIL_DATA_SUCCESS';
export const GET_EMAIL_DATA_ERROR = 'GET_EMAIL_DATA_ERROR';

// ODN-866 request email preview
export const PREVIEW_EMAIL_REQUEST = 'PREVIEW_EMAIL_REQUEST';
export const PREVIEW_EMAIL_SUCCESS = 'PREVIEW_EMAIL_SUCCESS';
export const PREVIEW_EMAIL_ERROR = 'PREVIEW_EMAIL_ERROR';
