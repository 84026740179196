import { useState } from 'react';
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  CardList,
  InputGroup,
  Intent,
  Section,
  SectionCard,
  Tag,
} from '@blueprintjs/core';
import { v4 as uuidV4 } from 'uuid';

import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';

import {
  createSchemaColumnRequest,
  deleteSchemaColumnRequest,
} from '@legacy/core/schemasColumns/store/actions';

import { initializeSharedForm } from '@legacy/components/SharedForm/store/actions';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import FormModal, { FormReducerSubmitEvt } from '@legacy/components/SharedForm/SharedFormModal';

import { getErrorMessage } from '../../../../../utils/errors';

import { useAction } from '@core/hooks/useAction';
import { useRequest } from '@core/hooks/useRequest';

import { Empty } from '../Empty';
import { SchemaDetailsProps } from '../types';
import SchemaColumnUpdateDrawer from './SchemaColumnUpdateDrawer';
import { getFormFields } from './formFields';
import { SchemaColumnCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/dto/schema.column.create.update.dto';

const uuid = uuidV4();

export const PropertiesSection = ({ schema, schemaTypeId }: SchemaDetailsProps) => {
  const [selectedColumnId, setSelectedColumnId] = useState('');
  const [columnToRemove, setColumnToRemove] = useState<SchemaColumnEntity>();
  const [isRequesting, setIsRequesting] = useState(false);
  const [search, setSearch] = useState('');
  const [isPreviewingColumn, setIsPreviewingColumn] = useState<boolean>(false);

  const createProperty = useRequest(createSchemaColumnRequest);
  const deleteProperty = useRequest(deleteSchemaColumnRequest);
  const initializeForm = useAction(initializeSharedForm);
  const alertMessage = useAction(displayMessage);

  const columns = schema.columns || [];

  const filteredColumns = columns
    .filter((col) => {
      const schemaTypeFilter =
        schemaTypeId === 'all' ? true : !col.schemaTypeId || col.schemaTypeId === schemaTypeId;
      const nameFilter = col.name.toLocaleLowerCase().includes(search?.toLowerCase());
      return schemaTypeFilter && nameFilter;
    })
    .sort((a, b) => a.name.localeCompare(b.name));

  const showPropertiesForm = (property?: SchemaColumnEntity) => {
    initializeForm({
      showModal: true,
      formUUID: uuid,
      title: property?.id ? 'Edit Property' : 'Create Property',
      formFields: getFormFields(schema, property, schemaTypeId === 'all' ? null : schemaTypeId),
      entityName: 'SchemaColumn',
    });
  };

  const handleFormSubmit = async (params: FormReducerSubmitEvt) => {
    // create property
    try {
      await createProperty({
        schemaId: schema.id,
        body: params.data as SchemaColumnCreateUpdateDto,
      });
      alertMessage({
        body: 'Property created',
        type: 'success',
      });
    } catch (error: any) {
      const message = getErrorMessage(error);
      alertMessage({
        body: 'Could not create property. ' + message,
        type: 'error',
      });
    }
  };

  const handleDeleteProperty = async () => {
    if (!schema || !columnToRemove) return;

    setIsRequesting(true);
    await deleteProperty({
      schemaId: schema.id,
      schemaColumnId: columnToRemove.id,
    });
    setIsRequesting(false);
  };

  return (
    <>
      <Section
        className="properties-section"
        title="Properties"
        rightElement={
          <>
            <InputGroup
              round
              style={{ maxWidth: 170 }}
              id="schema-properties"
              type="search"
              leftIcon="search"
              placeholder="Search"
              value={search}
              onChange={(e: any) => setSearch(e.target.value)}
            />
            <Button
              outlined
              intent="success"
              icon="plus"
              onClick={() => showPropertiesForm()}
              text="Add"
              style={{ borderRadius: 5 }}
            />
          </>
        }
      >
        <SectionCard padded={false} className="limited-height">
          {columns.length === 0 ? (
            <Empty icon="search" text="No columns" />
          ) : (
            <CardList bordered={false} compact>
              {filteredColumns.map((column) => {
                const uniqueValidators = [
                  ...new Set(column.validators.map((validator) => validator.type)),
                ];
                return (
                  <Card
                    key={column.id}
                    interactive
                    onClick={() => {
                      setSelectedColumnId(column.id);
                      setIsPreviewingColumn(true);
                    }}
                  >
                    <span>{column.name}</span>
                    <span className="validators">
                      {uniqueValidators.map((validator) => (
                        <Tag round key={validator} fill={false} minimal>
                          {validator}
                        </Tag>
                      ))}
                    </span>
                    <Tag round={true} intent="primary" minimal fill={false}>
                      {column.type}
                    </Tag>
                    <span className="actions">
                      <ButtonGroup>
                        <Button
                          minimal
                          small
                          icon="annotation"
                          onClick={(e: any) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setSelectedColumnId(column.id);
                          }}
                        />

                        <Button
                          intent="danger"
                          icon="trash"
                          minimal
                          small
                          onClick={() => setColumnToRemove(column)}
                        />
                      </ButtonGroup>
                    </span>
                  </Card>
                );
              })}
            </CardList>
          )}
        </SectionCard>
      </Section>

      <FormModal formUUID={uuid} onSubmitEvent={handleFormSubmit} />

      {/* Drawer display is controlled by selected state */}
      <SchemaColumnUpdateDrawer
        schemaId={schema?.id}
        schemaColumnId={selectedColumnId}
        previewMode={isPreviewingColumn}
        onClose={() => {
          setSelectedColumnId('');
          setIsPreviewingColumn(false);
        }}
      />

      {!!columnToRemove && (
        <Alert
          isOpen={true}
          canEscapeKeyCancel
          canOutsideClickCancel
          intent={Intent.DANGER}
          loading={isRequesting}
          icon="trash"
          cancelButtonText="Cancel"
          confirmButtonText="Remove"
          onClose={() => setColumnToRemove(undefined)}
          onConfirm={handleDeleteProperty}
        >
          <h3 style={{ marginTop: 0, marginBottom: '1rem' }}>Remove Property</h3>
          <p>Are you sure you want to remove this property?</p>
        </Alert>
      )}
    </>
  );
};
