import { Divider, NonIdealState, Spinner, Tab, Tabs } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import React, { useContext, useState } from 'react';
import { MyCasesContext } from '../../index';
import './styles.scss';
import MyCasesContactLookup from '../MyCasesContactDrawer/MyCasesContactLookup';
import MyCasesContactDetails from '../MyCasesContactDrawer/MyCasesContactDetails';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import GroupDetails from '@legacy/components/GroupDetails';
import { MetadataLinks } from '@d19n/temp-fe-d19n-models/dist/schema-manager/metadata.links';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import { connect } from 'react-redux';
import { hasPermissions } from '@core/helpers/rbacRules';
import ActivityCenter from '@legacy/core/records/components/ActivityCenter';

interface Props {
  schema: SchemaEntity;
  userReducer: any;
}

const MyCasesContactDrawerTabbed: React.FC<Props> = (props: Props) => {
  const { schema, userReducer } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [selectedTab, setSelectedTab] = useState<'Contact' | 'Details'>('Contact');
  const { selectedCaseContact } = state;

  const renderLinks = () => {
    const record = state.selectedCase;
    if (record && record.links && record.links?.length! > 0) {
      return record.links.map((link: MetadataLinks, index: number) => (
        <Row
          justify="space-between"
          align="top"
          key={`metadataRow${link.id}`}
          style={{ marginBottom: 10 }}
        >
          <Col key={`metadataCol1${link.id}`}>{link.entity?.split(':')[1]}</Col>
          <Col key={`metadataCol2${link.id}`}>
            <Link key={`metadataLink${link.id}`} target="_blank" to={getBrowserPath(link as any)}>
              {link.title}
            </Link>
          </Col>
          {
            // if last link add divider
            index === record?.links?.length! - 1 ? null : (
              <Col span={24} key={`metadataDivider${link.id}`} style={{ marginTop: 10 }}>
                <Divider style={{ margin: 0, padding: 0 }} />
              </Col>
            )
          }
        </Row>
      ));
    } else {
      return <span>None</span>;
    }
  };

  return (
    <Col
      span={8}
      className={`myCasesContactDetailsCol ${state.isSingleCaseMode ? 'singleCase' : ''}`}
    >
      <Row style={{ padding: 10 }}>
        <Col span={24}>
          <Tabs selectedTabId={selectedTab} onChange={(e: any) => setSelectedTab(e)}>
            {/* Tab -> Contact */}
            <Tab
              id="Contact"
              title="Contact"
              style={{ marginTop: 0 }}
              panel={
                <Col
                  span={24}
                  style={{
                    height: `calc(100vh - 250px)`,
                    overflowY: 'auto',
                  }}
                >
                  {/* Initial Search for Associated Contact */}
                  {state.isSearchingForAssociatedContact && (
                    <div style={{ marginTop: 240 }}>
                      <NonIdealState icon={<Spinner size={40} />} title="Searching for Contact" />
                    </div>
                  )}

                  {/* Missing Contact */}
                  {!selectedCaseContact &&
                    !state.isContactPanelOpen &&
                    !state.isSearchingForAssociatedContact && <MyCasesContactLookup />}

                  {/* Existing Contact */}
                  {selectedCaseContact && !state.isSearchingForAssociatedContact && (
                    <MyCasesContactDetails contact={state.selectedCaseContact!} />
                  )}
                </Col>
              }
            />

            {/* Tab -> Details */}
            <Tab
              id="Details"
              title="Details"
              disabled={!state.selectedCase}
              panel={
                <Col
                  span={24}
                  style={{
                    height: state.isSingleCaseMode ? `calc(100vh - 250px)` : `calc(100vh - 250px)`,
                    overflowY: 'auto',
                  }}
                >
                  <Row style={{ padding: 10 }}>
                    {/* Record Properties */}
                    <Col span={24}>
                      <h3 style={{ marginTop: 0, marginBottom: 15 }}>Case Details</h3>
                      <RecordProperties record={state.selectedCase!} schema={schema} />
                    </Col>

                    {/* Group Details */}
                    <Col span={24} style={{ marginTop: 25 }}>
                      <h3 style={{ marginTop: 0, marginBottom: 15 }}>Case Groups</h3>
                      <GroupDetails record={state.selectedCase} />
                    </Col>

                    {/* Record Links */}
                    <Col span={24} style={{ marginTop: 25 }}>
                      <h3 style={{ marginTop: 0, marginBottom: 20 }}>Case Links</h3>
                    </Col>

                    <Col span={24}>{renderLinks()}</Col>
                  </Row>
                </Col>
              }
            />

            {/* Tab -> Activity (Permission guarded) */}
            <Tab
              id="Activity"
              title="Activity"
              disabled={
                !state.selectedCase ||
                !hasPermissions(userReducer, [
                  'schemamodule.activity.get',
                  'schemamodule.activity.search',
                ])
              }
              panel={
                <Col
                  span={24}
                  style={{
                    height: state.isSingleCaseMode ? `calc(100vh - 250px)` : `calc(100vh - 250px)`,
                    overflowY: 'auto',
                  }}
                >
                  <ActivityCenter
                    compact
                    record={state.selectedCase!}
                    schema={schema}
                    maxHeight={'auto'}
                  />
                </Col>
              }
            />
          </Tabs>
        </Col>
      </Row>
    </Col>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MyCasesContactDrawerTabbed);
