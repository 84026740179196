import dayjs from 'dayjs';

import { DbRecordEntityTransform } from "@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform";
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import { AppointmentView } from '../../context/types';

const isToday = (date: string) => dayjs().format('YYYY-MM-DD') === date;

const formatDate = (date: string) => <span>{dayjs(date).format('DD MMMM YYYY')}</span>;

const formatRange = (start: string, end: string) => (
  <div style={{ letterSpacing: '-1px' }}>
    <span>{dayjs(start).format('DD MMMM YYYY')}</span>
    <span style={{ margin: '0 8px' }}> - </span>
    <span>{dayjs(end).format('DD MMMM YYYY')}</span>
  </div>
);

export const formatDateFilter = (type: AppointmentView, dateFilter: { start: string; end: string }) => {
  if (type === 'day') return isToday(dateFilter.start) ? 'Today' : formatDate(dateFilter.start);
  return formatRange(dateFilter.start, dateFilter.end);
};


export const groupRecordsByEngineer = (records: DbRecordEntityTransform[]) => {
  const groupedRecords: Record<string, DbRecordEntityTransform[]> = {};

  records.forEach(record => {
    const engineerId = getProperty(record, 'EngineerId') ?? 'UNASSIGNED';

    if (engineerId in groupedRecords)
      groupedRecords[engineerId].push(record);

    else
      groupedRecords[engineerId] = [record];
  });

  return groupedRecords;
};
