import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { orderBuilderContext } from '../index';
import { SET_PAYMENT_METHOD } from '../store/constants';
import { getRecordByIdRequest, IGetRecordById } from '@legacy/core/records/store/actions';
import { getSchemaFromShortListByModuleAndEntity } from '@core/helpers/schemaHelpers';
import RecordCard from '@legacy/core/records/components/RecordCard';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '@legacy/core/recordsAssociations/store/actions';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Callout } from '@blueprintjs/core';

type Props = {
  getAssociations: Function;
  schemaReducer: any;
  getSchema: Function;
  getRecordById: (params: IGetRecordById, cb?: any) => void;
};

const { BILLING_MODULE } = SchemaModuleTypeEnums;
const { PAYMENT_METHOD } = SchemaModuleEntityTypeEnums;

const OrderDetails: React.FC<Props> = (props: Props) => {
  const { schemaReducer, getSchema, getRecordById } = props;
  const { state, dispatch } = useContext(orderBuilderContext);
  const [PMSchema, setPMSchema] = useState<SchemaEntity | undefined>(undefined);

  // Fetch PM schema on component mount
  useEffect(() => {
    if (!PMSchema) {
      const shortlistPMSchema = getSchemaFromShortListByModuleAndEntity(
        schemaReducer.shortList,
        BILLING_MODULE,
        PAYMENT_METHOD,
      );
      if (shortlistPMSchema) {
        setPMSchema(shortlistPMSchema);
      } else {
        getSchema(
          { moduleName: BILLING_MODULE, entityName: PAYMENT_METHOD },
          (responseSchema: SchemaEntity) => {
            if (responseSchema) {
              setPMSchema(responseSchema);
            }
          },
        );
      }
    }
  }, []);

  const updatePaymentMethod = (paymentMethodId: string) => {
    if (paymentMethodId && PMSchema) {
      getRecordById({ schema: PMSchema, recordId: paymentMethodId }, (res: any) => {
        if (res) {
          dispatch({ type: SET_PAYMENT_METHOD, payload: res });
        }
      });
    }
  };

  const addressOrderStatusAlert = () => {
    if (getProperty(state.address, 'SalesStatus') !== 'ORDER') {
      return [
        <Callout compact={true} intent="danger" icon={null}>
          This address is not in ORDER status. Please update the address in order to proceed.
        </Callout>,
      ];
    } else {
      return undefined;
    }
  };

  return (
    <Row gutter={12}>
      {/* Account  */}
      <Col xs={24} lg={6} style={{ padding: 5 }}>
        <RecordCard
          borderless
          fullHeightCard
          hideIcon
          openTitleLinkInNewTab
          record={state.account!}
          showActionMenu
          visibleProperties={['Type', 'Status', 'PreviousProvider', 'Name']}
        />
      </Col>

      {/* Contact */}
      <Col xs={24} lg={6} style={{ padding: 5 }}>
        <RecordCard
          borderless
          fullHeightCard
          getOnlyFirstAssociation
          hideIcon
          openTitleLinkInNewTab
          record={state.contact!}
          showActionMenu={false}
          visibleProperties={['FirstName', 'LastName', 'EmailAddress', 'Phone']}
        />
      </Col>

      {/* Address */}
      <Col xs={24} lg={6} style={{ padding: 5 }}>
        <RecordCard
          borderless
          getOnlyFirstAssociation
          fullHeightCard
          hideIcon
          openTitleLinkInNewTab
          record={state.address!}
          showActionMenu={false}
          visibleProperties={['PostalCode', 'Region', 'SalesStatus', 'Type']}
          footerElements={addressOrderStatusAlert()}
        />
      </Col>

      {/* Payment Method */}
      <Col xs={24} lg={6} style={{ padding: 5 }}>
        <RecordCard
          borderless
          fullHeightCard
          associatedRecordEntityName="PaymentMethod"
          associatedRecordModuleName="BillingModule"
          getOnlyFirstAssociation
          hideIcon
          onCreateActionSuccess={updatePaymentMethod}
          associatedRecordCondition={`PaymentMethod:Status == ACTIVE`}
          openTitleLinkInNewTab
          record={state.contact}
          showActionMenu={true}
          visibleProperties={[
            'Default',
            'Status',
            'BankAccountNumberEnding',
            'AuthorizedDirectDebit',
          ]}
          paymentMethodAddressId={state.address?.id}
        />
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations, cb: any) =>
    dispatch(getRecordAssociationsRequest(params, cb)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
  getRecordById: (params: IGetRecordById, cb: any) => dispatch(getRecordByIdRequest(params, cb)),
});

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

export default connect(mapState, mapDispatch)(OrderDetails);
