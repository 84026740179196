import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import _, { isEmpty } from 'lodash';
import moment from 'moment';

const { SUPPORT_MODULE, SCHEMA_MODULE, NOTIFICATION_MODULE } = SchemaModuleTypeEnums;
const { NOTE, FILE } = SchemaModuleEntityTypeEnums;

// This is the way we used to check if the activity is a record update
export const isActivityOldGroupUpdate = (activity: DbRecordEntityTransform) => {
  return (
    activity.revision &&
    activity?.revision?.groups &&
    activity?.revision?.groups?.length > 0 &&
    activity?.type !== 'DB_RECORD_GROUP_ACCESS_MODIFIED'
  );
};

// This is the way we now check if the activity is a record update
export const isActivityNewGroupUpdate = (activity: DbRecordEntityTransform) => {
  return activity.revision && activity?.type === 'DB_RECORD_GROUP_ACCESS_MODIFIED';
};

export const isActivityStageUpdate = (activity: DbRecordEntityTransform) => {
  if (activity.revision && activity.type === 'DB_RECORD_STAGE_UPDATED') {
    return true;
  } else {
    return false;
  }
};

export const getParentRecordInformation = (activity: DbRecordEntityTransform) => {
  const parentModule = activity?.revision?.parentEntity?.split(':')[0];
  const parentEntity = activity?.revision?.parentEntity?.split(':')[1];
  const parentRecordId = activity?.revision?.parentRecordId;
  const parentRecordNumber = activity?.associations.find(
    (assoc: any) => assoc.id === parentRecordId,
  )?.record_number;
  const parentRecordUrl = `/${parentModule}/${parentEntity}/${parentRecordId}`;
  const parentRecordTitle = activity?.associations.find(
    (assoc: any) => assoc.id === parentRecordId,
  )?.title;

  return {
    parentModule,
    parentEntity,
    parentRecordId,
    parentRecordNumber,
    parentRecordUrl,
    parentRecordTitle,
  };
};

export const getChildRecordInformation = (activity: DbRecordEntityTransform) => {
  const childModule = activity?.revision?.childEntity?.split(':')[0];
  const childEntity = activity?.revision?.childEntity?.split(':')[1];
  const childRecordId = activity?.revision?.childRecordId;
  const childRecordNumber = activity?.associations.find(
    (assoc: any) => assoc.id === childRecordId,
  )?.record_number;
  const childRecordUrl = `/${childModule}/${childEntity}/${childRecordId}`;
  const childRecordTitle = activity?.associations.find(
    (assoc: any) => assoc.id === childRecordId,
  )?.title;

  return {
    childModule,
    childEntity,
    childRecordId,
    childRecordNumber,
    childRecordUrl,
    childRecordTitle,
  };
};

// Checks if activity is a record title update
export const isActivityRecordTitleUpdate = (activity: any) => {
  if (
    activity?.revision?.title &&
    isEmpty(activity?.revision?.properties) &&
    activity.type?.indexOf('DB_RECORD') > -1
  ) {
    return true;
  } else {
    return false;
  }
};

// Iterate through activities and filter out by provided Activity type
export const applyActivityTypeFilter = (
  activityList: DbRecordEntityTransform[],
  activityType: string,
) => {
  return activityList.filter((activity: any) => activity.type === activityType);
};

// Checks if activity is an email
export const checkIfActivityIsAnEmail = (activity: DbRecordEntityTransform) => {
  let response = false;

  const childRecordId = activity?.revision?.childRecordId;
  const associations = activity?.associations;

  if (childRecordId && associations?.length > 0) {
    const association = associations.find(
      (assoc: any) => assoc.id === childRecordId && assoc?.title?.indexOf('SENDGRID') > -1,
    );
    response = !!association;
  }

  return response;
};

// Iterate through activities and filter out by provided Record type
export const applyRecordTypeFilter = (
  activityList: DbRecordEntityTransform[],
  recordTypes: string[],
) => {
  if (activityList.length > 0 && recordTypes.length > 0) {
    let response: DbRecordEntityTransform[] = [];

    // Go over each record filter type and get it to response
    for (const recordType of recordTypes) {
      activityList.map((activity: DbRecordEntityTransform) => {
        // Notes
        if (
          recordType === 'Note' &&
          activity.revision?.childEntity === `${SUPPORT_MODULE}:${NOTE}`
        ) {
          response.push(activity);
        }

        // Files
        if (
          recordType === 'File' &&
          activity.revision?.childEntity === `${SCHEMA_MODULE}:${FILE}` &&
          !checkIfActivityIsAnEmail(activity)
        ) {
          response.push(activity);
        }

        // Emails are classified as files, so be careful there.
        if (
          recordType === 'Email' &&
          activity.revision?.childEntity === `${SCHEMA_MODULE}:${FILE}` &&
          checkIfActivityIsAnEmail(activity)
        ) {
          response.push(activity);
        }

        // SMS
        if (
          recordType === 'SMS' &&
          activity.revision?.childEntity === `${NOTIFICATION_MODULE}:SmsMessage`
        ) {
          response.push(activity);
        }
      });
    }

    return response;
  } else {
    return activityList;
  }
};

// Filter out associated record from the activity associations
export const getAssociatedRecordForAnActivity = (activityItem: DbRecordEntityTransform) => {
  if (
    activityItem &&
    activityItem.associations?.length > 0 &&
    activityItem.revision?.childRecordId
  ) {
    const foundChildAssociation = activityItem.associations.find(
      (associationRecord: DbRecordEntityTransform) =>
        associationRecord.id === activityItem.revision?.childRecordId,
    );
    if (foundChildAssociation) {
      return foundChildAssociation;
    }
  }
};

export const isActivityAnAssociation = (activityType: string | undefined) => {
  if (activityType && activityType?.indexOf('ASSOCIATION_CREATED') > -1) {
    return true;
  } else {
    return false;
  }
};

export const sortActivitiesByDate = (activityList: DbRecordEntityTransform[]) => {
  let newSort: any = {};

  if (activityList?.length > 0) {
    // Group activities by day, return object with date as keys, each day with array of activities
    let group = _.groupBy(activityList!, (result) => moment(result['createdAt']).startOf('day'));

    // Sort days in the group
    Object.keys(group)
      .sort((a, b) => {
        return moment(a).isBefore(b) ? 1 : -1;
      })
      .forEach((key: string) => {
        // Sort activities inside each day
        group[key].sort((a: any, b: any) => {
          return moment(a.createdAt).isBefore(b.createdAt) ? 1 : -1;
        });

        newSort[key] = group[key];
      });
  }

  return newSort;
};

export const constructPathFromActivity = (activityItem: DbRecordEntityTransform) => {
  if (activityItem && activityItem.revision?.childEntity) {
    const moduleName = activityItem.revision?.childEntity?.split(':')[0];
    const entityName = activityItem.revision?.childEntity?.split(':')[1];
    const childRecordId = activityItem.revision?.childRecordId;

    return `/${moduleName}/${entityName}/${childRecordId}`;
  } else {
    return undefined;
  }
};

export const getActivityEntity = (activityItem: DbRecordEntityTransform) => {
  if (activityItem && activityItem?.revision?.childEntity) {
    return activityItem?.revision?.childEntity.split(':')[1];
  } else return 'Record';
};

export const getActivityModule = (activityItem: DbRecordEntityTransform) => {
  if (activityItem && activityItem?.revision?.childEntity) {
    return activityItem?.revision?.childEntity.split(':')[0];
  } else return 'Record';
};
