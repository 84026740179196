export const UPDATE_AUTOSPLICING_REDUCER_STATE = 'UPDATE_AUTOSPLICING_REDUCER_STATE';
export const UPDATE_AUTOSPLICING_LOADING_INDICATORS = 'UPDATE_AUTOSPLICING_LOADING_INDICATORS';

export const GET_AUTOSPLICING_TEMPLATES_REQUEST = 'GET_AUTOSPLICING_TEMPLATES_REQUEST';
export const GET_AUTOSPLICING_TEMPLATES_SUCCESS = 'GET_AUTOSPLICING_TEMPLATES_SUCCESS';
export const GET_AUTOSPLICING_TEMPLATES_ERROR = 'GET_AUTOSPLICING_TEMPLATES_ERROR';

export const GET_AUTOSPLICING_TEMPLATE_VERSIONS_REQUEST =
  'GET_AUTOSPLICING_TEMPLATE_VERSIONS_REQUEST';
export const GET_AUTOSPLICING_TEMPLATE_VERSIONS_SUCCESS =
  'GET_AUTOSPLICING_TEMPLATE_VERSIONS_SUCCESS';
export const GET_AUTOSPLICING_TEMPLATE_VERSIONS_ERROR = 'GET_AUTOSPLICING_TEMPLATE_VERSIONS_ERROR';

export const GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST =
  'GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_REQUEST';
export const GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_SUCCESS =
  'GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_SUCCESS';
export const GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_ERROR =
  'GET_AUTOSPLICING_TEMPLATE_BY_PATHNAME_ERROR';

export const GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST =
  'GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_REQUEST';
export const GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_SUCCESS =
  'GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_SUCCESS';
export const GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_ERROR =
  'GET_AUTOSPLICING_TEMPLATE_DIFFERENCE_ERROR';

export const RESET_AUTOSPLICING_POLYGON_REQUEST = 'RESET_AUTOSPLICING_POLYGON_REQUEST';
export const RESET_AUTOSPLICING_POLYGON_SUCCESS = 'RESET_AUTOSPLICING_POLYGON_SUCCESS';
export const RESET_AUTOSPLICING_POLYGON_ERROR = 'RESET_AUTOSPLICING_POLYGON_ERROR';

export const RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST = 'RESET_AUTOSPLICING_BY_TEMPLATE_REQUEST';
export const RESET_AUTOSPLICING_BY_TEMPLATE_SUCCESS = 'RESET_AUTOSPLICING_BY_TEMPLATE_SUCCESS';
export const RESET_AUTOSPLICING_BY_TEMPLATE_ERROR = 'RESET_AUTOSPLICING_BY_TEMPLATE_ERROR';

export const RESET_AUTOSPLICING_BY_CABLE_REQUEST = 'RESET_AUTOSPLICING_BY_CABLE_REQUEST';
export const RESET_AUTOSPLICING_BY_CABLE_SUCCESS = 'RESET_AUTOSPLICING_BY_CABLE_SUCCESS';
export const RESET_AUTOSPLICING_BY_CABLE_ERROR = 'RESET_AUTOSPLICING_BY_CABLE_ERROR';

export const GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST =
  'GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_REQUEST';
export const GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_SUCCESS =
  'GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_SUCCESS';
export const GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_ERROR =
  'GET_AUTOSPLICING_MATRICES_BY_CLOSUREID_ERROR';

export const GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST =
  'GET_SPLICING_SHORTHAND_BY_CLOSUREID_REQUEST';
export const GET_SPLICING_SHORTHAND_BY_CLOSUREID_SUCCESS =
  'GET_SPLICING_SHORTHAND_BY_CLOSUREID_SUCCESS';
export const GET_SPLICING_SHORTHAND_BY_CLOSUREID_ERROR =
  'GET_SPLICING_SHORTHAND_BY_CLOSUREID_ERROR';

export const CHECK_AUTOSPLICING_SPINE_REQUEST = 'CHECK_AUTOSPLICING_SPINE_REQUEST';
export const CHECK_AUTOSPLICING_SPINE_SUCCESS = 'CHECK_AUTOSPLICING_SPINE_SUCCESS';
export const CHECK_AUTOSPLICING_SPINE_ERROR = 'CHECK_AUTOSPLICING_SPINE_ERROR';

export const CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST = 'CHECK_AUTOSPLICING_DISTRIBUTION_REQUEST';
export const CHECK_AUTOSPLICING_DISTRIBUTION_SUCCESS = 'CHECK_AUTOSPLICING_DISTRIBUTION_SUCCESS';
export const CHECK_AUTOSPLICING_DISTRIBUTION_ERROR = 'CHECK_AUTOSPLICING_DISTRIBUTION_ERROR';

export const CHECK_AUTOSPLICING_ACCESS_REQUEST = 'CHECK_AUTOSPLICING_ACCESS_REQUEST';
export const CHECK_AUTOSPLICING_ACCESS_SUCCESS = 'CHECK_AUTOSPLICING_ACCESS_SUCCESS';
export const CHECK_AUTOSPLICING_ACCESS_ERROR = 'CHECK_AUTOSPLICING_ACCESS_ERROR';

export const CHECK_AUTOSPLICING_RESULTS_REQUEST = 'CHECK_AUTOSPLICING_RESULTS_REQUEST';
export const CHECK_AUTOSPLICING_RESULTS_SUCCESS = 'CHECK_AUTOSPLICING_RESULTS_SUCCESS';
export const CHECK_AUTOSPLICING_RESULTS_ERROR = 'CHECK_AUTOSPLICING_RESULTS_ERROR';

export const CLEAN_AUTOSPLICING_ISSUES_REQUEST = 'CLEAN_AUTOSPLICING_ISSUES_REQUEST';
export const CLEAN_AUTOSPLICING_ISSUES_SUCCESS = 'CLEAN_AUTOSPLICING_ISSUES_SUCCESS';
export const CLEAN_AUTOSPLICING_ISSUES_ERROR = 'CLEAN_AUTOSPLICING_ISSUES_ERROR';

export const TOGGLE_AUTOSPLICING_GRAPH_MAXIMIZED_VIEW = 'TOGGLE_AUTOSPLICING_GRAPH_MAXIMIZED_VIEW';
