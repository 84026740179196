import {
  Button,
  Callout,
  DialogStep,
  MultistepDialog,
  Section,
  SectionCard,
  Spinner,
} from '@blueprintjs/core';
import { DbRecordCreateUpdateDto } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/dto/db.record.create.update.dto';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Descriptions } from 'antd';
import { DateTime } from 'luxon';
import React, { FunctionComponent, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { creteWorkOrderAndAppointmentRequest } from '@legacy/core/appointments/store/actions';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { IRecordReducer } from '@legacy/core/records/store/reducer';
import { httpGet } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import AppointmentScheduler from '../../OrderBuilder/AppointmentScheduler';

const stepOneFormUUID = uuidv4();
const { FIELD_SERVICE_MODULE } = SchemaModuleTypeEnums;
const { WORK_ORDER } = SchemaModuleEntityTypeEnums;

type PropsType = {
  order: any;
  recordReducer: IRecordReducer;
  recordFormReducer: any;
  initializeForm: any;
  onClose?: any;
  onConfirm?: any;
  alertMessage: (params: { body: string; type: string }) => void;
  createWOAndAppointment: any;
  triggerButtonStyle?: { [key: string]: string };
};

const CreateInstallWorkOrder: FunctionComponent<PropsType> = (props) => {
  const {
    order,
    recordFormReducer,
    initializeForm,
    onClose,
    onConfirm,
    alertMessage,
    createWOAndAppointment,
  } = props;

  const [isDialogOpen, setIsDialogOpen] = React.useState<boolean>(false);
  const [isNextDisabled, setIsNextDisabled] = React.useState<boolean>(true);
  const [workOrderSchema, setWorkOrderSchema] = React.useState<SchemaEntity | undefined>(undefined);
  const [dialogState, setDialogState] = React.useState<{
    stepOne: {
      formData: DbRecordCreateUpdateDto | undefined;
    };
  }>({
    stepOne: {
      formData: undefined,
    },
  });
  const [orderItems, setOrderItems] = React.useState<DbRecordEntityTransform[]>([]);
  const [workOrder, setNewWorkOrder] = React.useState<DbRecordEntityTransform | undefined>(
    undefined,
  );
  const [appointmentData, setAppointmentData] = React.useState<
    | {
        slot: {
          Date: string;
          Config: DbRecordEntityTransform;
        };
        timeBlock: 'AM' | 'PM';
      }
    | undefined
  >(undefined);
  const [schemaActions, setSchemaActions] = React.useState<SchemaActionEntity[]>([]);
  const [stepError, setError] = React.useState<any>(undefined);
  const [isConfirmLoading, setIsConfirmLoading] = React.useState<boolean>(false);

  function resetState() {
    setError(undefined);
    setIsNextDisabled(true);
    setIsConfirmLoading(false);
    setNewWorkOrder(undefined);
    setOrderItems([]);
    setDialogState({
      stepOne: {
        formData: undefined,
      },
    });
  }

  useEffect(() => {
    if (isDialogOpen) {
      resetState();
      fetchData();
    }
  }, [isDialogOpen]);

  useEffect(() => {
    fetchData();
  }, [order]);

  // Step 2: initialize your form schemas
  async function fetchData() {
    if (order) {
      const WOSchema = await getOdinSchemaByEntity(FIELD_SERVICE_MODULE, WORK_ORDER);
      setWorkOrderSchema(WOSchema);

      // Get the order contact and payment methods
      httpGet(
        `OrderModule/v1.0/db-associations/Order/${order.id}/one-relation?entity=OrderItem&withLinks=false`,
      )
        .then((res) => {
          setOrderItems(res.data['OrderItem']?.dbRecords || []);
        })
        .catch((err) => {
          console.error('Error:', err);
        });
    }
    httpGet(`SchemaModule/v1.0/schemas-actions`)
      .then((res) => {
        setSchemaActions(res.data.data);
      })
      .catch((err) => {
        console.error('Error loading table data:', err);
      });
  }

  useEffect(() => {
    if (orderItems?.length < 1) {
      setIsNextDisabled(false);
    }
  }, [orderItems]);

  const createWorkOrderAction = schemaActions?.find((action: SchemaActionEntity) => {
    return action.name === 'CreateInstallWorkOrder';
  });

  function setupStep(newStep: string) {
    let stepNumber = 0;
    if (newStep === 'step-one') {
      stepNumber = 0;
    } else if (newStep === 'step-two') {
      stepNumber = 1;
    } else if (newStep === 'confirm') {
      stepNumber = 2;
    }

    switch (stepNumber) {
      case 0:
        // We might handle some validation here to prevent
        // Double refunds from happening.
        if (!createWorkOrderAction) {
          return;
        }
        // Save the modified data from step 1 locally
        initializeForm({
          formUUID: stepOneFormUUID,
          showFormModal: true,
          hideRecordFormFields: true,
          schemaActionId: createWorkOrderAction?.id,
          showInitializing: false,
          isCreateReq: true,
          recordType: 'INSTALL',
          schema: workOrderSchema,
          modified: dialogState.stepOne?.formData ? [dialogState.stepOne?.formData] : [],
          sections: [
            {
              name: workOrderSchema?.name,
              schema: workOrderSchema,
              associations: [
                {
                  entity: order.entity,
                  recordId: order.id,
                },
              ],
            },
          ],
        });
        break;
      case 1:
        setDialogState({
          ...dialogState,
          stepOne: {
            formData: {
              ...recordFormReducer.modified[0],
              entity: `${workOrderSchema?.moduleName}:${workOrderSchema?.entityName}`,
              associations: [
                ...(recordFormReducer.modified[0]?.associations || []),
                ...(recordFormReducer.sections[0]?.associations || []),
              ],
            },
          },
        });
        break;
      case 2:
        console.log('CONFIRM STEP');

        break;
      default:
        break;
    }
  }

  async function handleFinalStepSubmit() {
    setError(undefined);
    setIsConfirmLoading(true);
    try {
      if (!workOrder) {
        let workOrderId = undefined;
        await new Promise((resolve, reject) => {
          createWOAndAppointment(
            {
              orderId: order.id,
              createUpdate: {
                Date: appointmentData?.slot.Date,
                scheduleId: appointmentData?.slot?.Config?.id,
                TimeBlock: appointmentData?.timeBlock,
                Optimizer: appointmentData?.slot?.Config?.optimizer,
                Type: 'INSTALL',
                skipCustomerNotification: true,
                orderItems: orderItems.map((elem) => ({
                  entity: elem.entity,
                  recordId: elem.id,
                })),
                properties: dialogState.stepOne?.formData?.properties,
              },
            },
            (res: any, err: any) => {
              if (res) {
                console.log('res', res);
                workOrderId = res[0]?.id;
                return resolve(res[0]?.id);
              } else if (err) {
                console.log('ERROR: ', err);
                setIsConfirmLoading(false);
                setError(err?.message);
                return resolve(err?.message);
              }
            },
          );
        });

        console.log('FETCH THE NEW WORK ORDER', workOrderId);
        if (workOrderId) {
          const getWorkOrder = await httpGet(`FieldServiceModule/v1.0/db/WorkOrder/${workOrderId}`);
          console.log('getWorkOrder', getWorkOrder);
          setNewWorkOrder(getWorkOrder.data?.data);
          setIsConfirmLoading(false);

          alertMessage({
            body: `Work Order ${getWorkOrder.data?.data?.recordNumber} was created successfully.`,
            type: 'success',
          });

          if (onConfirm) {
            onConfirm();
          }
        }
      }
    } catch (err: any) {
      setIsConfirmLoading(false);
      setError(err?.response?.data?.message);
      console.error('Error:', err);
    }
  }

  return (
    <>
      <Button
        intent="primary"
        outlined
        onClick={() => setIsDialogOpen(true)}
        disabled={isConfirmLoading}
        style={{ ...props?.triggerButtonStyle }}
      >
        Schedule installation
      </Button>
      <MultistepDialog
        resetOnClose
        isOpen={isDialogOpen}
        canOutsideClickClose={false}
        showCloseButtonInFooter={true}
        className="multistep-dialog-service-work-order"
        icon="info-sign"
        navigationPosition="top"
        onClose={() => {
          onClose && onClose();
          resetState();
          setIsDialogOpen(false);
        }}
        onOpened={() => setupStep('step-one')}
        onChange={(newStep: string) => {
          setupStep(newStep);
          setIsNextDisabled(true);
        }}
        nextButtonProps={{
          disabled: isNextDisabled,
          // tooltipContent: this.state.value === undefined ? "Select an option to continue" : undefined,
        }}
        finalButtonProps={{
          disabled: isConfirmLoading || !!workOrder,
          onClick: () => handleFinalStepSubmit(),
        }}
        title={'Install work order'}
      >
        <DialogStep
          id="step-one"
          title="Create work order"
          panel={
            <Section>
              <SectionCard>
                <Callout intent="primary">
                  <p>
                    You are scheduling a <strong>install</strong> work order at: {order.title}
                  </p>
                </Callout>
              </SectionCard>
              <SectionCard style={{ overflowY: 'scroll' }}>
                {!createWorkOrderAction ? (
                  <Callout intent="danger">
                    <p>Could not locate schema action: CreateInstallWorkOrder</p>
                  </Callout>
                ) : (
                  <CoreForm
                    type="EMBEDDED"
                    formUUID={stepOneFormUUID}
                    isCreateRecord={true}
                    showFormActions={false}
                    isNextDisabled={(isNextDisabled: boolean) => {
                      setIsNextDisabled(isNextDisabled);
                    }}
                  />
                )}
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="step-two"
          title="Schedule Appointment"
          panel={
            <Section>
              <SectionCard>
                <AppointmentScheduler
                  workOrderType={'INSTALL'}
                  orderId={order?.id}
                  bringBackDate
                  orderItems={orderItems}
                  selectedAppointment={appointmentData}
                  handleOnSubmit={(params) => {
                    setupStep('confirm');
                    setIsNextDisabled(false);
                    setAppointmentData({
                      slot: params?.slot,
                      timeBlock: params.timeBlock,
                    });
                  }}
                />
              </SectionCard>
            </Section>
          }
        />
        <DialogStep
          id="confirm"
          panel={
            <Section>
              {!workOrder && (
                <SectionCard>
                  <Callout intent="primary">
                    {isConfirmLoading ? (
                      <Spinner size={50} />
                    ) : (
                      <div>
                        <p>
                          Please confirm that you want to create a install work order and schedule
                          it.
                        </p>
                      </div>
                    )}
                  </Callout>
                </SectionCard>
              )}

              {stepError && (
                <SectionCard>
                  <Callout intent="danger">
                    <p>{stepError}</p>
                  </Callout>
                </SectionCard>
              )}

              {appointmentData && !workOrder ? (
                <>
                  <SectionCard>
                    <Descriptions column={1}>
                      <Descriptions.Item label="Sub type">
                        {dialogState?.stepOne?.formData?.properties?.SubType}
                      </Descriptions.Item>
                      <Descriptions.Item label="Description">
                        {dialogState?.stepOne?.formData?.properties?.Description}
                      </Descriptions.Item>
                      <Descriptions.Item label="Schedule For:">
                        {DateTime.fromISO(String(appointmentData?.slot?.Date)).toFormat('d/M/yyyy')}{' '}
                        {appointmentData?.timeBlock}
                      </Descriptions.Item>
                    </Descriptions>
                  </SectionCard>
                </>
              ) : (
                <></>
              )}

              {workOrder && (
                <>
                  <SectionCard>
                    <Descriptions column={1}>
                      <Descriptions.Item label="Reference #">
                        {workOrder?.recordNumber}
                      </Descriptions.Item>
                      <Descriptions.Item label="SubType">
                        {getProperty(workOrder, 'SubType')}
                      </Descriptions.Item>
                      <Descriptions.Item label="Description">
                        {getProperty(workOrder, 'Description')}
                      </Descriptions.Item>
                    </Descriptions>
                  </SectionCard>

                  <SectionCard>
                    <Link target="_blank" to={getBrowserPath(workOrder)}>
                      <div>Go to Work Order</div>
                    </Link>
                  </SectionCard>
                </>
              )}
            </Section>
          }
          title="Confirm"
        />
      </MultistepDialog>
    </>
  );
};

const mapState = (state: any) => ({
  recordReducer: state.recordReducer,
  recordFormReducer: state.recordFormReducer,
});

const mapDispatch = (dispatch: any) => ({
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  createWOAndAppointment: (params: any, cb: (data: any, err: any) => {}) =>
    dispatch(creteWorkOrderAndAppointmentRequest(params, cb)),
});

export default connect(mapState, mapDispatch)(CreateInstallWorkOrder);
