import React, { FC, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import { InputGroup, Spinner, Tab, Tabs, TabsExpander } from '@blueprintjs/core';
import { connect } from 'react-redux';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet, httpPost } from '@core/http/requests';
import TeamUsersTable from './TeamUsersTable';
import './styles.scss';
import { SchemaActionEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/action/schema.action.entity';
import { getOdinSchemaByEntity } from '@core/helpers/schemaHelpers';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';

const { SUPPORT_MODULE } = SchemaModuleTypeEnums;
const CASE = 'Case';

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
}

const CaseManagementCaseLoadView: FC<Props> = (props: Props) => {
  const { alertMessage } = props;
  const [searchInput, setSearchInput] = useState<string>('');
  const [teamList, setTeamList] = useState<DbRecordEntityTransform[]>([]);
  const [isSearchingTeams, setIsSearchingTeams] = useState<boolean>(false);
  const [selectedTeam, setSelectedTeam] = useState<DbRecordEntityTransform | undefined>(undefined);
  const [caseSchema, setCaseSchema] = useState<SchemaEntity | undefined>(undefined);
  const [assignOwnerAction, setAssignOwnerAction] = useState<SchemaActionEntity | undefined>(
    undefined,
  );

  useEffect(() => {
    getTeamList();
    getCaseSchema();
  }, []);

  useEffect(() => {
    if (caseSchema && !assignOwnerAction) {
      getAssignOwnerAction(caseSchema.id);
    }
  }, [caseSchema, assignOwnerAction]);

  const getCaseSchema = async () => {
    try {
      const schema = await getOdinSchemaByEntity(SUPPORT_MODULE, CASE);
      if (schema) {
        setCaseSchema(schema);
      }
    } catch (error) {}
  };

  // The Schema action we need to assign a case to a user
  const getAssignOwnerAction = async (schemaId: string) => {
    try {
      const res = await httpGet(`SchemaModule/v1.0/schemas-actions/schema/${schemaId}`);
      const action = res?.data?.data?.find(
        (item: SchemaActionEntity) => item.name === 'AssignCase',
      );
      if (action) {
        setAssignOwnerAction(action);
      }
    } catch (error) {}
  };

  const getTeamList = async () => {
    setIsSearchingTeams(true);
    const query = {
      returnQueryPlan: false,
      query: {
        entity: 'IdentityModule:Team',
        type: 'and',
        value: [
          {
            columnName: 'SupportTeam',
            operator: 'eq',
            value: 'YES',
          },
        ],
        returnProperties: ['id', 'title'],
        sort: {
          createdAt: {
            order: 'asc',
          },
        },
        pageSize: 100,
        pageNumber: 0,
      },
    };

    try {
      await httpPost(`IdentityModule/v2.0/records/search`, query).then((res) => {
        const records = res?.data?.data?.records || [];
        console.log('%cdebug: Team Search Results', 'color:limegreen', records);
        setTeamList(records);
        setSelectedTeam(records[0]);
        setIsSearchingTeams(false);
      });
    } catch (error: any) {
      setIsSearchingTeams(false);
      alertMessage({
        body: 'Failed to fetch teams. ' + (error && error.message),
        type: 'error',
      });
    }
  };

  const handleTeamSelect = (teamId: string) => {
    const team = teamList.find((item) => item.id === teamId);
    setSelectedTeam(team);
    setSearchInput('');
  };

  return (
    <>
      <Row>
        <Col span={24}>
          <h1 style={{ margin: 0 }}>Case Load</h1>
        </Col>

        {/* Loader */}
        {isSearchingTeams && (
          <Col span={24} style={{ padding: '10px 3px 0 3px' }}>
            <Spinner style={{ paddingTop: 300 }} />
          </Col>
        )}

        {/* Tabs */}
        {!isSearchingTeams && (
          <Col span={24} style={{ padding: '10px 3px 0 3px' }}>
            {teamList.length > 0 && (
              <Tabs
                id="TeamsTabs"
                selectedTabId={selectedTeam?.id}
                renderActiveTabPanelOnly={true}
                onChange={(e: any) => handleTeamSelect(e)}
              >
                {teamList.map((team: DbRecordEntityTransform) => (
                  <Tab
                    id={team.id}
                    title={team.title}
                    key={team.id}
                    panel={<TeamUsersTable team={team} searchInput={searchInput} />}
                  />
                ))}
                <TabsExpander />
                <InputGroup
                  type="search"
                  leftIcon="search"
                  placeholder="Search Users"
                  value={searchInput}
                  disabled={!teamList.length}
                  onChange={(e: any) => setSearchInput(e.target.value)}
                />
              </Tabs>
            )}
          </Col>
        )}
      </Row>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(CaseManagementCaseLoadView);
