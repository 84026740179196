import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Empty, Row } from 'antd';
import { FC } from 'react';
import RecordList from '@legacy/core/records/components/RecordList';

interface SearchResultsProps {
  records: DbRecordEntityTransform[];
  textFilter?: string;
  selectedRecord?: DbRecordEntityTransform;
  onRecordSelected: (selectedRecord?: DbRecordEntityTransform) => void;
}

export const SearchResults: FC<SearchResultsProps> = ({
                                                        selectedRecord,
                                                        records = [],
                                                        textFilter = '',
                                                        onRecordSelected,
                                                      }: SearchResultsProps) => {

  const isRecordSelected = (record: DbRecordEntityTransform) => {
    return selectedRecord?.id === record.id;
  };

  const setOrClearSelectedRecord = (record: DbRecordEntityTransform) => {
    onRecordSelected(isRecordSelected(record) ? undefined : record);
  };

  // Filter dataset records with quick search text
  const filteredResults = records.filter(
    (rec: DbRecordEntityTransform) =>
      rec.title!.toLowerCase().includes(textFilter.toLowerCase()) ||
      rec.recordNumber?.toLowerCase().includes(textFilter.toLowerCase()),
  );

  const selectedRecords: DbRecordEntityTransform[] = selectedRecord ? [selectedRecord] : [];

  return (
    <>
      <Row
        style={{
          height: '49vh',
          overflowY: 'scroll',
        }}
      >
        <Col span={24}>
          {filteredResults.length > 0 ? (
            <div style={{ paddingLeft: 10, paddingRight: 10 }}>
              <RecordList
                selectedRecords={selectedRecords}
                disabledRecords={[]}
                allRecords={filteredResults}
                onRecordSelect={setOrClearSelectedRecord}
                highlightSelectedRecord
                customRecordRowTitleElements={['recordnumber', 'title']}
              />
            </div>
          ) : (
            <Empty style={{ marginTop: '20vh' }} description="No records found" />
          )}
        </Col>
      </Row>

      {/* Search Results */}
      <Row
        style={{
          fontSize: '0.8em',
          padding: 3,
          marginTop: 5,
        }}
      >
        <Col span={12} style={{ textAlign: 'left', color: '#888888' }}>
          <span>Total Records: </span>
          <span>{records.length}</span>
        </Col>
      </Row>
    </>
  );
};
