// Schedules
export const FETCH_SCHEDULES_REQUEST = 'FETCH_SCHEDULES_REQUEST';
export const FETCH_SCHEDULES_RESPONSE = 'FETCH_SCHEDULES_RESPONSE';
export const FETCH_SCHEDULES_ERROR = 'FETCH_SCHEDULES_ERROR';
export const SELECT_SCHEDULE = 'SELECT_SCHEDULE';
export const SET_SCHEDULE_FILTER = 'SET_SCHEDULE_FILTER';

// Work Orders
export const FETCH_WORK_ORDERS_REQUEST = 'FETCH_WORK_ORDERS_REQUEST';
export const FETCH_WORK_ORDERS_RESPONSE = 'FETCH_WORK_ORDERS_RESPONSE';
export const FETCH_WORK_ORDERS_ERROR = 'FETCH_WORK_ORDERS_ERROR';
export const UPDATE_WORK_ORDER = 'UPDATE_WORK_ORDER';

// Earlier Availability
export const FETCH_EARLIER_AVAILABILITY_REQUEST = 'FETCH_EARLIER_AVAILABILITY_REQUEST';
export const FETCH_EARLIER_AVAILABILITY_RESPONSE = 'FETCH_EARLIER_AVAILABILITY_RESPONSE';
export const FETCH_EARLIER_AVAILABILITY_ERROR = 'FETCH_EARLIER_AVAILABILITY_ERROR';

// Appointment View
export const SET_APPOINTMENTS_FILTER = 'SET_APPOINTMENTS_FILTER';
export const APPOINTMENTS_DATE_FILTER_NEXT = 'APPOINTMENTS_DATE_FILTER_NEXT';
export const APPOINTMENTS_DATE_FILTER_PREV = 'APPOINTMENTS_DATE_FILTER_PREV';
export const APPOINTMENTS_DATE_FILTER_SET = 'APPOINTMENTS_DATE_FILTER_SET';
export const APPOINTMENTS_SELECTED_DATE_SET = 'APPOINTMENTS_SELECTED_DATE_SET';

// Details
export const SET_DETAILS_RECORD = 'SET_DETAILS_RECORD';

// Availability
export const FETCH_UTILIZATION_REQUEST = 'FETCH_UTILIZATION_REQUEST';
export const FETCH_UTILIZATION_RESPONSE = 'FETCH_UTILIZATION_RESPONSE';
export const FETCH_UTILIZATION_ERROR = 'FETCH_UTILIZATION_ERROR';

// Engineers
export const FETCH_ENGINEERS_REQUEST = 'FETCH_ENGINEERS_REQUEST';
export const FETCH_ENGINEERS_RESPONSE = 'FETCH_ENGINEERS_RESPONSE';
export const FETCH_ENGINEERS_ERROR = 'FETCH_ENGINEERS_ERROR';
export const SET_ENGINEERS_FILTER = 'SET_ENGINEERS_FILTER';
