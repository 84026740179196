import { call, put, takeLatest } from 'redux-saga/effects';
import { httpDelete, httpGet, httpPost } from '@core/http/requests';
import { DISPLAY_MESSAGE } from '../../messages/store/reducers';
import { ERROR_NOTIFICATION } from '../../notifications/store/reducers';
import history from '@core/helpers/browserHistory';
import { USER_LOGOUT_REQUEST } from '../../identity/store/constants';
import { SET_ASSIGN_USER_MODAL_VISIBLE, SET_MOVE_USERS_MODAL_VISIBLE } from '../../identityUser/store/constants';
import {
  AssignGroupsToUsers,
  AssignGroupToGroup,
  AssignUsersToGroup,
  BulkUpdateUsersGroups,
  CreateNewGroup,
  DeleteGroup,
  MoveUsersToGroup,
} from './actions';
import {
  ASSIGN_GROUP_TO_GROUP_REQUEST,
  ASSIGN_GROUPS_TO_USERS_REQUEST,
  ASSIGN_USERS_TO_GROUP_ERROR,
  ASSIGN_USERS_TO_GROUP_REQUEST,
  ASSIGN_USERS_TO_GROUP_SUCCESS,
  CREATE_GROUP_ERROR,
  CREATE_GROUP_REQUEST,
  CREATE_GROUP_SUCCESS,
  DELETE_GROUP_ERROR,
  DELETE_GROUP_LINK_REQUEST,
  DELETE_GROUP_REQUEST,
  DELETE_GROUP_SUCCESS,
  GET_GROUP_BY_ID_ERROR,
  GET_GROUP_BY_ID_REQUEST,
  GET_GROUP_BY_ID_SUCCESS,
  GET_GROUPS_DATA_ERROR,
  GET_GROUPS_DATA_REQUEST,
  GET_GROUPS_DATA_SUCCESS,
  GET_GROUPS_LINKS_ERROR,
  GET_GROUPS_LINKS_REQUEST,
  GET_GROUPS_LINKS_SUCCESS,
  MOVE_USERS_TO_GROUP_REQUEST,
  SET_ASSIGN_GROUPS_MODAL_VISIBLE,
  UPDATE_BULK_USERS_GROUPS_REQUEST,
} from './constants';

function* getGroups(): any {
  try {
    const res = yield call(
      // v2.0
      async () => await httpGet('IdentityModule/v2.0/groups?size=10000'),

      // v1.0
      // async () => await httpGet('IdentityModule/v1.0/rbac/groups'),
    );
    yield put({ type: GET_GROUPS_DATA_SUCCESS, results: res.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_GROUPS_DATA_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* getGroupById(params: any): any {
  try {
    const res = yield call(
      async () => await httpGet(`IdentityModule/v1.0/rbac/groups/${params.params.groupId}`),
    );
    yield put({
      type: GET_GROUP_BY_ID_SUCCESS,
      results: res.data.data,
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_GROUP_BY_ID_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* deleteGroup(action: { type: any; take: any; params: DeleteGroup; cb: any }): any {
  try {
    yield call(
      async () => await httpDelete(`IdentityModule/v1.0/rbac/groups/${action.params.groupId}`),
    );
    yield history.goBack();
    yield put({ type: DELETE_GROUP_SUCCESS, results: action.params.groupId });
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'successfully deleted group', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: DELETE_GROUP_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* createGroup(action: { type: any; take: any; params: CreateNewGroup; cb: any }): any {
  try {
    const url = `IdentityModule/v1.0/rbac/groups`;
    const { body } = action.params;
    let res = yield call(async () => await httpPost(url, body));
    yield put({ type: CREATE_GROUP_SUCCESS, results: res.data.data });
    history.push(`/IdentityManagerModule/Groups/${res.data.data.id}`);
    if (action.cb) {
      yield call(action.cb, { data: res.data.data });
    }
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'successfully created group', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: CREATE_GROUP_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* assignUsersToGroup(action: {
  type: any;
  take: any;
  params: AssignUsersToGroup;
  cb: any;
}): any {
  try {
    const res = yield call(
      async () =>
        await httpPost(`IdentityModule/v1.0/rbac/groups/${action.params.id}/users`, {
          userIds: action.params.userIds,
        }),
    );
    yield put({ type: ASSIGN_USERS_TO_GROUP_SUCCESS, results: res.data.data });
    yield put({ type: SET_ASSIGN_USER_MODAL_VISIBLE, visible: false });
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: `users successfully linked to group`, type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: ASSIGN_USERS_TO_GROUP_ERROR, error });
    if (!!error && e.response.data.statusCode === 401) {
      yield put({ type: USER_LOGOUT_REQUEST, error });
    } else {
      yield put({ type: ERROR_NOTIFICATION, error });
    }
  }
}

function* getGroupsLinks(action: { type: any; take: any; params: any; cb: any }): any {
  if (action.params === undefined) {
    return;
  }
  try {
    const res = yield call(
      async () => await httpGet(`IdentityModule/v1.0/rbac/groups/${action.params.groupId}/links`),
    );

    yield put({ type: GET_GROUPS_LINKS_SUCCESS, results: res.data.data });

    yield call(action.cb, { results: res.data.data });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: GET_GROUPS_LINKS_ERROR, error });
    yield put({ type: ERROR_NOTIFICATION, error });
  }
}

function* unassignGroup(action: any): any {
  if (action.params === undefined) {
    return;
  }
  try {
    yield call(
      async () =>
        await httpDelete(
          `IdentityModule/v1.0/rbac/groups/${action.params.groupId}/links/${action.params.groupToLinkId}`,
        ),
    );
    const res = yield call(
      async () => await httpGet(`IdentityModule/v1.0/rbac/groups/${action.params.groupId}/links`),
    );
    yield put({ type: GET_GROUPS_LINKS_SUCCESS, results: res.data.data });
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'link successfully deleted', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: ERROR_NOTIFICATION, error });
  }
}

function* assignGroupToGroup(action: {
  type: any;
  take: any;
  params: AssignGroupToGroup;
  cb: any;
}): any {
  if (action.params === undefined) {
    return;
  }
  try {
    yield call(
      async () =>
        await httpPost(`IdentityModule/v1.0/rbac/groups/${action.params.groupId}/links`, {
          groupIds: action.params.groupIds,
        }),
    );
    const res = yield call(
      async () => await httpGet(`IdentityModule/v1.0/rbac/groups/${action.params.groupId}/links`),
    );
    yield put({ type: GET_GROUPS_LINKS_SUCCESS, results: res.data.data });
    yield put({ type: SET_ASSIGN_GROUPS_MODAL_VISIBLE, visible: false });
    yield put({
      type: DISPLAY_MESSAGE,
      message: { body: 'group successfully linked', type: 'success' },
    });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: ERROR_NOTIFICATION, error });
  }
}

function* moveUsersToGroup(action: {
  type: any;
  take: any;
  params: MoveUsersToGroup;
  cb: any;
}): any {
  try {
    yield call(
      async () =>
        await httpPost(
          `IdentityModule/v1.0/rbac/groups/${action.params.sourceGroupId}/move-users`,
          { groupIds: action.params.groupIds, userIds: action.params.userIds },
        ),
    );
    const res = yield call(
      async () =>
        await httpGet(`IdentityModule/v1.0/rbac/groups/${action.params.sourceGroupId}/links`),
    );
    yield put({ type: GET_GROUPS_LINKS_SUCCESS, results: res.data.data });
    yield put({ type: SET_MOVE_USERS_MODAL_VISIBLE, visible: false });
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: ERROR_NOTIFICATION, error });
  }
}

function* assignGroupsToUsers(action: {
  type: any;
  take: any;
  params: AssignGroupsToUsers;
  cb: any;
}): any {
  try {
    yield call(
      async () =>
        await httpPost(`IdentityModule/v1.0/rbac/groups/assign-users`, {
          groupIds: action.params.groupIds,
          userIds: action.params.userIds,
        }),
    );
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: ERROR_NOTIFICATION, error });
  }
}

function* bulkUpdateUsers(action: {
  type: any;
  take: any;
  params: BulkUpdateUsersGroups;
  cb: any;
}): any {
  try {
    yield call(
      async () =>
        await httpPost(`IdentityModule/v1.0/rbac/groups/update-bulk-users`, {
          userIds: action.params.userIds,
          overrideGroups: action.params.overrideGroups,
          addGroups: action.params.addGroups,
          removeGroups: action.params.removeGroups,
        }),
    );
  } catch (e: any) {
    const error = e.response ? e.response.data : undefined;
    yield put({ type: ERROR_NOTIFICATION, error });
  }
}

function* rootSaga() {
  yield takeLatest(GET_GROUPS_DATA_REQUEST, getGroups);
  yield takeLatest(GET_GROUP_BY_ID_REQUEST, getGroupById);
  yield takeLatest(DELETE_GROUP_REQUEST, deleteGroup);
  yield takeLatest(CREATE_GROUP_REQUEST, createGroup);
  yield takeLatest(ASSIGN_USERS_TO_GROUP_REQUEST, assignUsersToGroup);
  yield takeLatest(GET_GROUPS_LINKS_REQUEST, getGroupsLinks);
  yield takeLatest(ASSIGN_GROUP_TO_GROUP_REQUEST, assignGroupToGroup);
  yield takeLatest(DELETE_GROUP_LINK_REQUEST, unassignGroup);
  yield takeLatest(MOVE_USERS_TO_GROUP_REQUEST, moveUsersToGroup);
  yield takeLatest(ASSIGN_GROUPS_TO_USERS_REQUEST, assignGroupsToUsers);
  yield takeLatest(UPDATE_BULK_USERS_GROUPS_REQUEST, bulkUpdateUsers);
}

export default rootSaga;
