import { Col, Row } from 'antd';
import React, { useContext } from 'react';
import { MyCasesContext } from '../../../index';
import DOMPurify from 'dompurify';
import './styles.scss';

const CustomerTypingMessage: React.FC = () => {
  const { state } = useContext(MyCasesContext);

  const messageText: string = state.clientTypingText;
  const sanitizedMessage = DOMPurify.sanitize(messageText || '');

  return (
    <Row key={messageText} justify="start" style={{ padding: '13px 22px 13px 18px' }}>
      <Row className="myCasesCustomerTypingMessage">
        {/* Header */}
        <Col span={24}>
          <i className="bi bi-chat-dots" style={{ marginRight: 5 }} />
          <span>Customer is typing ...</span>
        </Col>

        {/* Text */}
        <Col span={24} style={{ padding: 8, marginLeft: 5, color: '#989797' }}>
          <span dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
        </Col>
      </Row>
    </Row>
  );
};

export default CustomerTypingMessage;
