import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row, Timeline } from 'antd';
import { FC, useEffect, useState } from 'react';
import './styles.scss';
import { connect } from 'react-redux';
import { getRecordAuditLogs, IGetRecordAuditLogs } from '../../auditLogs/store/actions';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getPipelinesByModuleAndEntity } from '../../../pipelines/store/actions';
import dayjs from 'dayjs';
import {
  PipelineStageEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { usePrevious } from '@core/helpers/reactHelpers';
import { Button, Icon, NonIdealState, Section } from '@blueprintjs/core';
import Typography from '@core/components/Typography';
import { httpGet } from '@core/http/requests';
import { Link } from 'react-router-dom';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';

interface IStageHistoryProps {
  record: DbRecordEntityTransform;
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => void;
  getPipelines: (params: any, cb: any) => void;
  schema: SchemaEntity;
}

const { SCHEMA_MODULE } = SchemaModuleTypeEnums;
const MAX_VISIBLE_EVENTS = 5;

type StageHistoryItem = {
  name: string;
  children: any;
  color: string;
};

const StageHistory: FC<IStageHistoryProps> = (props: IStageHistoryProps) => {
  const { record, getAuditLogs, schema } = props;
  const [loadingActivities, setLoadingActivities] = useState<boolean>(false);
  const [recordId, setRecordId] = useState<string | undefined>(undefined);
  const [stageHistory, setStageHistory] = useState<StageHistoryItem[]>([]);
  const [pipelineStages, setPipelineStages] = useState<PipelineStageEntity[]>([]);
  const [pipeline, setPipeline] = useState<PipelineEntity | undefined>(undefined);
  const [isShowingMore, setIsShowingMore] = useState<boolean>(false);

  const previousRecordId = usePrevious(recordId);

  useEffect(() => {
    if (record && record?.id !== recordId) {
      setRecordId(record?.id);
    }
  }, [record]);

  useEffect(() => {
    if (
      recordId &&
      schema &&
      previousRecordId &&
      recordId !== previousRecordId &&
      !loadingActivities &&
      schema
    ) {
      getActivity(recordId, schema);
    }
  }, [recordId]);

  // When schema is available, get pipeline stages
  useEffect(() => {
    if (schema && pipelineStages?.length! === 0 && record) {
      httpGet(
        `SchemaModule/v1.0/pipelines/bymodule/${schema.moduleName}/${schema?.entityName}${
          record.type ? `?schemaType=${record.type}` : '?schemaType='
        }`,
      ).then((res: any) => {
        const pipelines = res.data?.data || [];
        if (pipelines.length > 0) {
          setPipeline(pipelines[0]);
          setPipelineStages(pipelines[0]?.stages);
        }
      });
    }
  }, [schema]);

  // Fetch Activity audit logs when schema is available
  useEffect(() => {
    if (record && schema && pipelineStages?.length! > 0 && !loadingActivities) {
      getActivity(record?.id, schema);
    }
  }, [pipelineStages, schema]);

  // Fetch activities from the UserActivity API
  const getActivity = async (parentRecordId: string, schema: SchemaEntity) => {
    if (parentRecordId) {
      setLoadingActivities(true);
      getAuditLogs(
        {
          schema: schema,
          recordId: parentRecordId,
          whereQuery: { type: 'DB_RECORD_STAGE_UPDATED' },
          sort: { createdAt: 'DESC' },
        },
        (res: any) => {
          setLoadingActivities(false);
          if (res) {
            let activities = Object.assign(res?.results);

            activities = activities.sort((a: any, b: any) => {
              return dayjs(b.createdAt).diff(dayjs(a.createdAt));
            });

            const getColorForStage = (stage: PipelineStageEntity | undefined) => {
              if (record?.stage?.id === stage?.id) {
                if (stage?.isFail) {
                  return 'red';
                } else {
                  return 'blue';
                }
              } else {
                if (stage?.isFail) {
                  return 'red';
                } else {
                  return 'green';
                }
              }
            };

            // 1. Set All Stage events
            activities.forEach((activity: any, index: number) => {
              const stage = pipelineStages.find(
                (stage: PipelineStageEntity) => stage?.id === activity?.revision?.stageId,
              );

              const isLastStage = (i: number) => {
                if (i === activities.length - 1) {
                  return true;
                }
              };

              const getEndDateForActivity = () => {
                if (isLastStage(index)) {
                  return dayjs(record.createdAt).format('DD/MM/YYYY');
                } else {
                  return dayjs(activities[index + 1]?.createdAt).format('DD/MM/YYYY');
                }
              };

              const getDiff = (activity: any) => {
                return dayjs(activity?.createdAt).diff(
                  dayjs(getEndDateForActivity(), 'DD/MM/YYYY'),
                  'day',
                );
              };

              if (stage) {
                setStageHistory((prevState: any) => [
                  ...prevState,
                  {
                    children: (
                      <>
                        <Typography stronger>{stage?.name}</Typography>
                        <br />
                        <Typography size="small">
                          {getEndDateForActivity()} -{' '}
                          {dayjs(activity?.createdAt).format('DD/MM/YYYY')}
                          <br />
                          <span style={{ opacity: 0.4 }}>
                            {getDiff(activity)} {getDiff(activity) === 1 ? 'day' : 'days'}
                          </span>
                        </Typography>
                      </>
                    ),
                    name: stage?.name + stage?.isFail ? ' (Failed)' : '',
                    color: getColorForStage(stage),
                  },
                ]);
              }
            });

            // 2. If there are no activities, we set the current stage as the first
            if (activities.length === 0) {
              const getEndDateForFirstStage = () => {
                return dayjs().format('DD/MM/YYYY');
              };
              const getStartDateForFirstStage = () => {
                return dayjs(record?.createdAt).format('DD/MM/YYYY');
              };
              const getDiff = () => {
                return dayjs(getEndDateForFirstStage(), 'DD/MM/YYYY').diff(
                  dayjs(record.createdAt),
                  'day',
                );
              };

              setStageHistory((prevState: any) => [
                ...prevState,
                {
                  children: (
                    <>
                      <Typography stronger>{record?.stage?.name}</Typography>
                      <br />
                      <Typography size="small">
                        {record?.createdAt ? getStartDateForFirstStage() : ''} -{' '}
                        {getEndDateForFirstStage()}
                        <br />
                        <span style={{ opacity: 0.4 }}>
                          {getDiff()}
                          {getDiff() === 1 ? ' day' : ' days'}
                        </span>
                      </Typography>
                    </>
                  ),
                  name: record?.stage?.name,
                  color: getColorForStage(record?.stage),
                },
              ]);
            }
          } else {
            console.error('Activity center: Error while fetching Activities.');
          }
        },
      );
    }
  };

  let filteredActivities = Object.assign(stageHistory);

  if (filteredActivities.length > MAX_VISIBLE_EVENTS && !isShowingMore) {
    filteredActivities = filteredActivities.slice(0, MAX_VISIBLE_EVENTS);
  }

  return (
    <Row>
      <Col span={24}>
        <Section icon={<Icon icon="history" />} title="Stage History" compact>
          <Row style={{ margin: '10px 10px 0 10px', opacity: loadingActivities ? 0.3 : 1 }}>
            <Col span={24}>
              {/* Activities are initially DESC oldest -> newest, we reverse them to see the newest on the top  */}
              <Timeline
                className="stageHistoryTimeline"
                style={{ fontSize: '0.8em' }}
                items={filteredActivities}
              />
            </Col>
            {stageHistory.length > MAX_VISIBLE_EVENTS && (
              <Col span={24} style={{ textAlign: 'center', margin: '10px 0' }}>
                <Button
                  small
                  onClick={() => setIsShowingMore(!isShowingMore)}
                  minimal
                  intent="primary"
                >
                  {isShowingMore ? 'Show less' : 'Show more'}
                </Button>
              </Col>
            )}

            {pipelineStages.length > 0 &&
              stageHistory.length === 0 &&
              !pipelineStages.some(
                (stage: PipelineStageEntity) => stage.id === record.stage?.id,
              ) && (
                <Col span={24} style={{ padding: 20 }}>
                  <NonIdealState
                    icon="warning-sign"
                    title="Incompatible Stage"
                    description={`This record has been assigned to a "${record.stage?.key}" stage that doesn't exist in "${pipeline?.name}" pipeline.`}
                    action={
                      <Link target="_blank" to={`/${SCHEMA_MODULE}/Schema/${schema?.id}#Pipelines`}>
                        <Button intent="primary" text="Go to Pipelines" />
                      </Link>
                    }
                  />
                </Col>
              )}
          </Row>
        </Section>
      </Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any) => ({
  getAuditLogs: (
    params: IGetRecordAuditLogs,
    cb?: (params: { recordId: string; results: any[] }) => void,
  ) => dispatch(getRecordAuditLogs(params, cb)),
  getPipelines: (params: { schema: SchemaEntity }, cb: any) =>
    dispatch(getPipelinesByModuleAndEntity(params, cb)),
});

const mapState = (state: any) => ({});

export default connect(mapState, mapDispatch)(StageHistory);
