import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Descriptions } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import '../index.scss';
import TableCellStageTag from '@core/components/TableCellStageTag';

interface Props {
  result: DbRecordEntityTransform;
  onClose: any;
  globalCollapsed: boolean;
}

interface State {
  collapsed: boolean;
}

class WorkOrderLayout extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = { collapsed: true };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (!prevProps.globalCollapsed && this.props.globalCollapsed) {
      this.setState({ collapsed: true });
    }
    if (prevProps.globalCollapsed && !this.props.globalCollapsed) {
      this.setState({ collapsed: false });
    }
  }

  renderOrderItemsAsCommaSeparatedStrings = (result: DbRecordEntityTransform) => {
    if (result.OrderItem && result.OrderItem.dbRecords) {
      return result.OrderItem.dbRecords.map((elem: DbRecordEntityTransform) => (
        <Link
          key={elem.title}
          to={getBrowserPath(elem)}
          onClick={() => this.props.onClose()}
          target="_blank"
        >
          {elem.title}
        </Link>
      ));
    } else return '-';
  };

  renderCommaSeparatedRelatedEntitiesWithLinks = (
    result: DbRecordEntityTransform,
    entityName: string,
    propertyName: string,
  ) => {
    if (result[entityName] && result[entityName].dbRecords) {
      return result[entityName].dbRecords
        .map((elem: DbRecordEntityTransform) => (
          <Link to={getBrowserPath(elem)} onClick={() => this.props.onClose()} target="_blank">
            {elem[propertyName]}
          </Link>
        ))
        .reduce((prev: JSX.Element, current: JSX.Element): any => [prev, <span>, </span>, current]);
    } else return '-';
  };

  renderServiceAppointment = (result: DbRecordEntityTransform) => {
    if (result.ServiceAppointment && result.ServiceAppointment.dbRecords) {
      return result.ServiceAppointment.dbRecords.map((elem: DbRecordEntityTransform) => {
        return `${elem.properties.Date} (${elem.properties.TimeBlock})`;
      });
    } else return '-';
  };

  render() {
    const result = this.props.result;

    return (
      <Descriptions
        column={1}
        bordered
        labelStyle={{ color: 'black', fontWeight: 400, fontSize: 12, padding: '2px 7px' }}
        contentStyle={{ fontSize: 12, padding: '2px 7px' }}
      >
        {/* Address */}
        <Descriptions.Item label="Address">
          <span>
            {this.renderCommaSeparatedRelatedEntitiesWithLinks(result, 'Address', 'title')}
          </span>
        </Descriptions.Item>

        {/* Appointment date */}
        <Descriptions.Item label="Appointment date">
          <span>{this.renderServiceAppointment(result)}</span>
        </Descriptions.Item>

        {/* Order items */}
        <Descriptions.Item label="Order items">
          <span>{this.renderOrderItemsAsCommaSeparatedStrings(result)}</span>
        </Descriptions.Item>

        {/* Type */}
        <Descriptions.Item label="Type">
          <span>{result.type || '-'}</span>
        </Descriptions.Item>

        {/* Stage */}
        <Descriptions.Item label="Stage">
          <Descriptions.Item label="Stage">
            <TableCellStageTag record={result} small />
          </Descriptions.Item>
        </Descriptions.Item>
      </Descriptions>
    );
  }
}

export default WorkOrderLayout;
