import { Button, Section } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { SchemaModuleTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.types';
import { Col, Row } from 'antd';
import { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import CoreForm from '@legacy/core/records/components/Forms/CoreForm';
import { initializeRecordForm } from '@legacy/core/records/components/Forms/store/actions';
import { addRecordToShortList, IAddRecordToShortList } from '@legacy/core/records/store/actions';
import AssociationSimpleList from '@legacy/core/recordsAssociations/components/AssociationSimpleList';
import {
  getSchemaByModuleAndEntityRequest,
  ISchemaByModuleAndEntity,
} from '@legacy/core/schemas/store/actions';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { getSchemaFromShortListByModuleAndEntity } from '../../../../../core/helpers/schemaHelpers';

type PathParams = {
  url: string;
  recordId: string;
};

type PropsType = RouteComponentProps<PathParams> & {
  parentRecord: DbRecordEntityTransform;
  initializeForm: Function;
  schemaReducer: any;
  getSchema: Function;
  shortListRecord: Function;
};

const { PROJECT_MODULE } = SchemaModuleTypeEnums;

const uuid = uuidv4();

const PTAListAndCreateCard: FC<PropsType> = (props: PropsType) => {
  const { parentRecord, initializeForm, schemaReducer, getSchema, shortListRecord } = props;

  const [schema, setSchema] = useState<SchemaEntity | undefined>(undefined);

  // On Component mount fetch the schema
  useEffect(() => {
    if (parentRecord) {
      getSchemas();
    }
  }, [parentRecord]);

  const getSchemas = () => {
    const shortlistSchema = getSchemaFromShortListByModuleAndEntity(
      schemaReducer.shortList,
      PROJECT_MODULE,
      'PermitToAccess',
    );

    if (shortlistSchema) {
      setSchema(shortlistSchema);
    } else {
      getSchema(
        {
          moduleName: PROJECT_MODULE,
          entityName: 'PermitToAccess',
        },
        (res: SchemaEntity) => {
          setSchema(res);
        },
      );
    }
  };

  const initializeRequestPermitForm = () => {
    if (schema) {
      initializeForm({
        formUUID: uuid,
        title: `Create ${schema.description}`,
        showFormModal: true,
        isCreateReq: true,
        schema: schema,
        selected: null,
        recordType: 'DEFAULT',
        sections: [{ name: schema.name, schema: schema }],
        modified: [
          {
            schemaId: schema?.id,
            associations: [
              {
                entity: parentRecord.entity,
                recordId: parentRecord.id,
              },
            ],
          },
        ],
      });
    }
  };

  const showRecordInQuickView = (record: DbRecordEntityTransform) => {
    shortListRecord({
      showPreview: true,
      record: record!,
    });
  };

  return (
    <Section
      style={{ marginBottom: 10 }}
      icon={
        <ModuleEntityIcon
          iconContainerStyle={{ padding: '4px 6px', fontSize: 13, marginRight: 0 }}
          overrideIcon="shield-lock"
          overrideIconColor="#1981f5"
          moduleName=""
          entityName=""
        />
      }
      title="Permit to Access"
    >
      <Row style={{ padding: 15 }}>
        {/* Actions here */}
        <Col span={24}>
          <Button
            large
            intent="primary"
            outlined
            fill
            disabled={!schema}
            onClick={() => initializeRequestPermitForm()}
          >
            Request Permit
          </Button>
        </Col>
      </Row>
      <Row style={{ marginTop: 0, padding: 15 }}>
        <Col span={24}>
          <AssociationSimpleList
            customRowAction={showRecordInQuickView}
            overrideTitle="List of Requests"
            parentRecord={parentRecord}
            associationModuleName={PROJECT_MODULE}
            associationEntityName={'PermitToAccess'}
            recordColumns={['stage', 'RequestedBy']}
          />
        </Col>
      </Row>
      <CoreForm
        type="MODAL"
        formUUID={uuid}
        onSubmitEvent={(params: { event: string; res: any }) => {
          // Callback function after the record was created
        }}
      />
    </Section>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
  initializeForm: (params: any) => dispatch(initializeRecordForm(params)),
  getSchema: (payload: ISchemaByModuleAndEntity, cb: any) =>
    dispatch(getSchemaByModuleAndEntityRequest(payload, cb)),
});

export default withRouter(connect(mapState, mapDispatch)(PTAListAndCreateCard));
