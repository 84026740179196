import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { PipelineStageEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/stage/pipeline.stage.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Row, Select } from 'antd';
import React from 'react';
import { connect } from 'react-redux';
import {
  generateModuleAndEntityKeyFromProps,
  getSavedFilter,
} from '@core/helpers/searchHelpers';
import { setPipelineReducerState } from '../../../../../pipelines/store/actions';
import { PipelineReducerState } from '../../../../../pipelines/store/reducer';
import { ISchemaReducer } from '../../../../../schemas/store/reducer';
import { TableReducer } from '../../store/reducer';
import { parsePipelineFilterForQuery } from '../helpers/pipelineFilterParsers';
import {
  resetQueryBuilderState,
  setQueryBuilderFormFields,
  setQueryBuilderState,
  setSearchQuery,
} from '../store/actions';
import {
  getQueryBuilderReducer,
  IQueryBuilderByModuleAndEntityReducer,
  QueryBuilderReducer,
} from '../store/reducer';
import { httpGet } from '@core/http/requests';
import { parseTypeFilterForQuery } from '../helpers/typeFilterParsers';
import { parseGroupsFilterForQuery } from '../helpers/groupsFilterParsers';

const { Option } = Select;

interface Props {
  schema: SchemaEntity | undefined;
  schemaReducer: ISchemaReducer;
  pipelineReducer: PipelineReducerState;
  queryBuilderReducer: QueryBuilderReducer;
  recordTableReducer: TableReducer;
  setBuilderState: (params: any) => {};
  setPipelineState: any;
  configure: (params: any) => {};
}

interface State {
  isAllSelected: boolean;
  pipeline: PipelineEntity | undefined;
  isLoadingPipeline: boolean;
}

const sortColumns = (stage1: PipelineStageEntity, stage2: PipelineStageEntity) => {
  if (stage1.position && stage2.position) {
    return stage1.position - stage2.position;
  } else {
    return 0;
  }
};

class PipelineFilterDropdown extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isAllSelected: false,
      pipeline: undefined,
      isLoadingPipeline: false,
    };
  }

  componentDidMount() {
    const { schema } = this.props;

    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );

    this.loadSavedQueries();

    // If there are no schema types, load the pipeline filters for a default list of stages
    if (!schema?.types?.length) {
      this.loadPipelineFilters();
    }

    // There is an already set type filter on component mount
    if (queryBuilderReducer.formFields?.typeFilters?.length! > 0) {
      this.loadPipelineFilters();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<{}>, snapshot?: any): void {
    // Watch for schema type updates, and reload the pipeline filters
    const prevQbr = getQueryBuilderReducer(
      prevProps.queryBuilderReducer,
      this.props.schema?.moduleName,
      this.props.schema?.entityName,
    );
    const currentQbr = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      this.props.schema?.moduleName,
      this.props.schema?.entityName,
    );
    if (prevQbr?.formFields?.typeFilters !== currentQbr?.formFields?.typeFilters) {
      this.loadPipelineFilters();
      this.loadSavedQueries();
    }
  }

  private loadPipelineFilters() {
    const { schema } = this.props;
    if (schema) {
      this.setState({ isLoadingPipeline: true });

      const queryBuilderReducer = getQueryBuilderReducer(
        this.props.queryBuilderReducer,
        schema?.moduleName,
        schema?.entityName,
      );

      if (queryBuilderReducer.formFields.typeFilters) {
        const schemaTypes = queryBuilderReducer.formFields.typeFilters[0]?.value || [];
        httpGet(
          `SchemaModule/v1.0/pipelines/bymodule/${schema.moduleName}/${
            schema.entityName
          }?schemaType=${schemaTypes.length > 0 ? `${schemaTypes}` : ''}`,
        )
          .then((res: any) => {
            this.setState({ isLoadingPipeline: false });
            const pipelines = res.data?.data || [];
            if (pipelines.length > 0) {
              this.setState({ pipeline: pipelines[0] });
            }
          })
          .catch((err) => {
            this.setState({ isLoadingPipeline: false });
            console.error('Error loading pipelines', err);
          });
      }
    }
  }

  private loadSavedQueries() {
    const { setBuilderState, schema, schemaReducer, recordTableReducer } = this.props;
    const savedFilter = getSavedFilter(
      schemaReducer,
      recordTableReducer,
      schema?.moduleName ?? '',
      schema?.entityName ?? '',
    );
    if (!!savedFilter) {
      setBuilderState({
        formFields: savedFilter.formFields,
      });
    }
  }

  private renderStageFilterOptions() {
    const { schema } = this.props;
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );

    // Schema type filters
    const formFields = queryBuilderReducer.formFields;
    const schemaTypesNotApplied: boolean =
      !formFields?.typeFilters?.[0]?.value ||
      formFields?.typeFilters?.[0]?.value.every((elem: any) => !elem);

    return (
      <>
        <Select
          key="stage"
          mode="multiple"
          defaultValue={[]}
          style={{ width: '100%' }}
          loading={this.state.isLoadingPipeline}
          value={queryBuilderReducer.formFields?.pipelineFilters?.[0]?.value || []}
          onChange={(val) => this.applyFilters(val)}
        >
          {this.state.pipeline ? (
            this.state
              .pipeline!.stages!.sort((stage1: PipelineStageEntity, stage2: PipelineStageEntity) =>
                sortColumns(stage1, stage2),
              )
              .map((elem: PipelineStageEntity) => (
                <Option key={elem.key} value={elem.key ? elem.key.toString() : ''}>
                  {elem.name}
                </Option>
              ))
          ) : (
            <></>
          )}
          <Option key="1" value={''}>
            All stages
          </Option>
        </Select>
        {
          // Show Pipeline name
          !this.state.isLoadingPipeline && this.state.pipeline && !schemaTypesNotApplied && (
            <Row>
              <Col>
                <small>
                  <b>Pipeline:</b> {this.state.pipeline?.name}
                </small>
              </Col>
            </Row>
          )
        }

        {
          // No Pipeline Found
          !this.state.isLoadingPipeline && !this.state.pipeline && !schemaTypesNotApplied && (
            <Row>
              <Col>
                <small>
                  <b>Pipeline:</b> <span style={{ color: 'red' }}>No Pipeline found</span>
                </small>
              </Col>
            </Row>
          )
        }

        {
          // No Schema Type Selected
          schemaTypesNotApplied && schema?.types?.length! > 0 && (
            <Row>
              <Col>
                <small>
                  <span style={{ color: 'red' }}>Select Type</span>
                </small>
              </Col>
            </Row>
          )
        }
      </>
    );
  }

  private applyFilters(stages: string[] | null | undefined) {
    const { configure, schema, setBuilderState } = this.props;
    const queryBuilderReducer = getQueryBuilderReducer(
      this.props.queryBuilderReducer,
      schema?.moduleName,
      schema?.entityName,
    );
    const formFields = queryBuilderReducer.formFields;
    let queries = [];

    if (
      (stages && typeof stages === 'string') ||
      (stages && Array.isArray(stages) && (stages[0] || stages.length > 1))
    ) {
      // add pipeline filter to query
      const pipelineFilterForQuery = parsePipelineFilterForQuery(stages);
      if (pipelineFilterForQuery) {
        queries.push(pipelineFilterForQuery);
      }

      // add pipeline filter to the query builder state
      const pipelineFilter = {
        esPropPath: 'stage.key.keyword',
        condition: 'filter',
        value: stages,
      };
      setBuilderState({
        formFields: { ...formFields, pipelineFilters: [pipelineFilter] },
      });
    } else {
      setBuilderState({ formFields: { ...formFields, pipelineFilters: [] } });
    }

    // 1. Schema type filter
    const typeFilterForQuery = parseTypeFilterForQuery(formFields?.typeFilters?.[0]?.value);
    if (typeFilterForQuery) {
      queries.push(typeFilterForQuery);
    }

    // 2. Group filters
    const groupsFilterForQuery = parseGroupsFilterForQuery(formFields?.groupsFilters?.[0]?.value);
    if (groupsFilterForQuery) {
      queries.push(groupsFilterForQuery);
    }

    // 3. Property filters
    formFields.propertyFilters.map((elem) => queries.push(elem));

    // set search query
    configure({ schema: schema, query: queries, queryType: 'query_string' });
  }

  render() {
    return <div style={{ width: '100%' }}>{this.renderStageFilterOptions()}</div>;
  }
}

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
  queryBuilderReducer: state.queryBuilderReducer,
  pipelineReducer: state.pipelineReducer,
  recordTableReducer: state.recordTableReducer,
});
const mapDispatch = (dispatch: any, ownProps: any) => ({
  reset: () => dispatch(resetQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps))),
  setBuilderState: (params: IQueryBuilderByModuleAndEntityReducer) =>
    dispatch(setQueryBuilderState(generateModuleAndEntityKeyFromProps(ownProps), params)),
  configure: (params: any) =>
    dispatch(setSearchQuery(generateModuleAndEntityKeyFromProps(ownProps), params)),
  setFormFields: (params: any) =>
    dispatch(setQueryBuilderFormFields(generateModuleAndEntityKeyFromProps(ownProps), params)),
  setPipelineState: (params: PipelineReducerState) => dispatch(setPipelineReducerState(params)),
});
export default connect(mapState, mapDispatch)(PipelineFilterDropdown);
