import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import {
  SchemaColumnOptionEntity,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/option/schema.column.option.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import {
  SchemaColumnTypes,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/types/schema.column.types';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Col, Descriptions, Empty, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { displayMessage } from '../../../messages/store/reducers';
import { changeToCapitalCase } from '@core/helpers/dataTransformationHelpers';
import { getOdinSchemaByRecord } from '@core/helpers/schemaHelpers';
import { setRawDataDrawerContents, toggleRawDataDrawer } from '../../../userInterface/store/actions';
import './styles.scss';
import { hasPermissions, isSystemAdmin } from '@core/helpers/rbacRules';
import { IdentityUserReducer } from '../../../identityUser/store/reducer';
import utc from 'dayjs/plugin/utc';
import { DateTime } from 'luxon'; // Import the UTC plugin
import { Button } from '@blueprintjs/core';
import { renderBooleanValue } from '@core/helpers/UIHelpers';
import CollapsibleText from './CollapsibleText';

dayjs.extend(utc); // Extend Day.js with the UTC plugin

interface Props {
  additionalFields?: additionalField[];
  alertMessage: Function;
  columns?: number;
  contentStyle?: any;
  enableCopyToClipboard?: boolean;
  hasColumnMappings?: boolean;
  hiddenProperties?: string[];
  labelStyle?: any;
  onUpdate: () => void;
  record: DbRecordEntityTransform | undefined;
  setRawDataDrawer: Function;
  showCreatedAt?: boolean;
  showMoreByDefault?: boolean;
  showUpdatedAt?: boolean;
  size?: 'small' | 'smaller';
  toggleDrawer: Function;
  userReducer: IdentityUserReducer;
  visibleProperties?: string[];
}

type additionalField = {
  label: string;
  value: string;
};

interface State {
  schema: SchemaEntity | undefined;
  propertiesExpanded: boolean;
  openedTooltip: string | undefined;
  openedWarning: string | undefined;
  changedProperty: any;
  isSavingEdit: boolean;
}

const defaultPropertiesNum = isMobile ? 5 : 15;

class RecordProperties extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      schema: undefined,
      propertiesExpanded: this.props.showMoreByDefault || false,
      openedTooltip: undefined,
      changedProperty: undefined,
      isSavingEdit: false,
      openedWarning: undefined,
    };
  }

  async loadSchema() {
    const { record } = this.props;
    if (record) {
      const schema = await getOdinSchemaByRecord(record);
      this.setState({ schema: schema });
    }
  }

  componentDidMount() {
    this.loadSchema();
  }

  // Record passed somewhere after the component mount
  componentDidUpdate(prevProps: any, prevState: any) {
    if (!prevProps.record && this.props.record && !this.state.schema) {
      this.loadSchema();
    }

    if (!prevState.openedWarning && this.state.openedWarning) {
      setTimeout(() => {
        this.setState({ openedWarning: undefined });
      }, 1500);
    }
  }

  copyValueToClipboard = (value: any, message: string) => {
    const { alertMessage } = this.props;
    navigator.clipboard.writeText(value);
    alertMessage({ body: message, type: 'success' });
  };

  // If no column argument is passed, check if mobile and use 2 columns there.
  getNumberOfColumns = (columns: number) => {
    if (!columns) {
      return isMobile ? 2 : 4;
    } else {
      return columns;
    }
  };

  renderListItemContent() {
    const {
      record,
      columns,
      visibleProperties,
      additionalFields,
      hiddenProperties,
      labelStyle,
      contentStyle,
    } = this.props;

    if (record && record.properties && this.state.schema) {
      let propsToRender;
      const { schema } = this.state;

      if (visibleProperties && visibleProperties?.length > 0) {
        propsToRender = Object.keys(record?.properties).filter((elem) =>
          visibleProperties ? visibleProperties.includes(elem) : true,
        );
      } else if (hiddenProperties && hiddenProperties?.length > 0) {
        propsToRender = Object.keys(record?.properties).filter((elem) =>
          hiddenProperties ? !hiddenProperties.includes(elem) : true,
        );
      } else {
        propsToRender = Object.keys(record?.properties);
      }

      // We want to show Contract Start and End date in proper sequence, until we figure out
      // how to move away from alphabetically sorted record properties.
      if (
        propsToRender.includes('ContractStartDate') &&
        propsToRender.includes('ContractEndDate')
      ) {
        const ContractStartDatePos = propsToRender.indexOf('ContractStartDate');
        const ContractEndDatePos = propsToRender.indexOf('ContractEndDate');
        propsToRender.splice(ContractEndDatePos, 1);
        propsToRender.splice(ContractStartDatePos, 0, 'ContractEndDate');
      }

      return (
        <Row>
          <Col span={24}>
            <Descriptions
              labelStyle={{
                fontSize: '0.9em',
                padding: '4px 6px',
                fontWeight: 500,
                width: '20%',
                ...labelStyle,
              }}
              contentStyle={{
                fontSize: '0.9em',
                padding: '4px 6px',
                width: '80%',
                ...contentStyle,
              }}
              className={`recordProperties_${this.props.size}`}
              column={this.getNumberOfColumns(columns!)}
              layout="horizontal"
              size="small"
              bordered={true}
            >
              {propsToRender
                .slice(
                  0,
                  this.state.propertiesExpanded || propsToRender.length <= defaultPropertiesNum
                    ? propsToRender.length
                    : defaultPropertiesNum,
                )
                .map((elem) =>
                  this.renderDescriptionItemSimple(elem, getProperty(record, elem, true)),
                )}

              {additionalFields && additionalFields?.length > 0 ? (
                additionalFields.map((field: additionalField) => (
                  <Descriptions.Item key={field.label} label={field.label}>
                    {field.value || '-'}
                  </Descriptions.Item>
                ))
              ) : (
                <></>
              )}

              {/* Show Created at */}
              {this.props.showCreatedAt ? (
                <Descriptions.Item key={'CreatedAtKey'} label="Created at">
                  {/*@ts-ignore*/}
                  {DateTime.fromISO(record.createdAt).toFormat('M/d/yyyy h:mm a ZZZZ')}
                </Descriptions.Item>
              ) : (
                <></>
              )}

              {/* Show Updated at */}
              {this.props.showUpdatedAt ? (
                <Descriptions.Item key={'UpdatedAtKey'} label="Updated at">
                  {/*@ts-ignore*/}
                  {DateTime.fromISO(record.updatedAt).toFormat('M/d/yyyy h:mm a ZZZZ')}
                </Descriptions.Item>
              ) : (
                <></>
              )}
            </Descriptions>

            {/* Show More / Less Button */}
            <Col
              span={24}
              style={{
                textAlign: 'center',
                padding: 10,
                display: propsToRender?.length <= defaultPropertiesNum ? 'none' : 'block',
              }}
            >
              <Button
                small
                onClick={() =>
                  this.setState({ propertiesExpanded: !this.state.propertiesExpanded })
                }
              >
                {this.state.propertiesExpanded ? 'Show Less' : 'Show More'}
              </Button>
            </Col>
          </Col>
        </Row>
      );
    } else {
      return <Empty />;
    }
  }

  handleDoubleClick = (key: any) => {
    const { userReducer } = this.props;

    // Only System Admins can edit inline 20-Feb-2024
    if (isSystemAdmin(userReducer)) {
      this.setState({ openedTooltip: key });
    }
  };

  handleFieldChange = (newValue: any) => {
    this.setState({ changedProperty: newValue.value });
  };

  private renderDescriptionItemSimple(key: string, value: any) {
    const { columns } = this.props;

    const renderDescription = () => {
      const { schema } = this.state;
      const column = schema?.columns?.find((col: SchemaColumnEntity) => col.name === key);

      return (
        <Descriptions.Item
          key={key}
          span={this.getNumberOfColumns(columns!) === 4 ? 2 : 1}
          contentStyle={{
            width: this.getNumberOfColumns(columns!) === 4 ? '30%' : '50%',
          }}
          label={
            column && column.label ? changeToCapitalCase(column?.label) : changeToCapitalCase(key)
          }
        >
          <span>{this.renderValue(key, value) || <>&nbsp;</>}</span>
        </Descriptions.Item>
      );
    };

    /* We want to show coordinates for points, but exclude coordinates for lines and polygons (too long) */
    if (key === 'Coordinates' && value?.length < 3) {
      return renderDescription();
    }

    return renderDescription();
  }

  private showJSONInRawDataDrawer = (jsonValue: string) => {
    const { toggleDrawer, setRawDataDrawer } = this.props;

    setRawDataDrawer(jsonValue);
    toggleDrawer();
  };

  isStringURL = (value: string) => {
    const regex = /^https:\/\/\S+$/;
    return regex.test(value);
  };

  private renderValue(key: string, value: any) {
    const { record, userReducer } = this.props;
    const { schema } = this.state;

    if (schema) {
      const column = schema?.columns?.find((elem: SchemaColumnEntity) => elem.name === key);

      switch (column?.type) {
        case SchemaColumnTypes.JSON:
          return (
            <Button
              intent="primary"
              style={{ padding: '0 3px' }}
              minimal
              onClick={() => this.showJSONInRawDataDrawer(value)}
            >
              View data
            </Button>
          );

        case SchemaColumnTypes.FILE_SINGLE:
          return <img style={{ height: 150, width: 150 }} src={value} />;

        case SchemaColumnTypes.PHONE_NUMBER_E164_GB:
          return value;
        case SchemaColumnTypes.PHONE_NUMBER:
          return value;

        case SchemaColumnTypes.ENUM:
          // For enum values we want to show the label instead of the value
          const option = column.options.find(
            (opt: SchemaColumnOptionEntity) => opt.value === value,
          );

          if (option) {
            return option.label;
          } else {
            return value;
          }

        case SchemaColumnTypes.BOOLEAN:
          return value ? renderBooleanValue(value) : <div>&nbsp;</div>;

        case SchemaColumnTypes.LOOKUP:
          const link = record?.links?.find((l) => l.id === value);
          if (link) {
            return `${link.recordNumber ? `${link.recordNumber} - ` : ''}${link.title || ''}`;
          } else {
            return value;
          }

        // Currency fields should only be rendered if user has permission.
        case SchemaColumnTypes.CURRENCY:
          return hasPermissions(userReducer, ['financial.data.access']) ? value : '*******';

        default:
          if (value !== undefined || value !== null) {
            // For cable length, shorten to two decimals and add unit
            if (key === 'CableLength') {
              return Number(value).toFixed(2) + ' m';
            }
            // For string values that match URL, show as link
            else if (typeof value === 'string' && this.isStringURL(value)) {
              return (
                <a href={value} target="_blank" rel="noopener noreferrer" key={value}>
                  {value}
                </a>
              );
            }
            // For Email Messages, shorten the thing
            else if (key === 'Message' && schema.entityName === 'Email') {
              return <CollapsibleText text={value} maxLetters={200} />;
            } else {
              // If a value is a date/datetime, convert it
              if (dayjs(value, 'YYYY-MM-DD', true).isValid()) {
                return value;
              }
              // Else, just show the value
              else {
                return value;
              }
            }
          } else {
            return '-';
          }
      }
    } else {
      return <Spin size="small" />;
    }
  }

  render() {
    return this.renderListItemContent();
  }
}

const mapDispatch = (dispatch: any, ownProps: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
  setRawDataDrawer: (params: string) => dispatch(setRawDataDrawerContents(params)),
  toggleDrawer: () => dispatch(toggleRawDataDrawer()),
});

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});

export default connect(mapState, mapDispatch)(RecordProperties);
