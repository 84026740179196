import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Alert, message, Upload } from 'antd';
import React from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import { getHostName } from '@core/http/helpers';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import {
  getRecordAssociationsRequest,
  IGetRecordAssociations,
} from '../../../recordsAssociations/store/actions';
import { ISchemaReducer } from '../../../schemas/store/reducer';
import { beforeUploadWithWatermark } from './helpers';
import { View, Geolocation } from 'ol';
import { toLonLat } from 'ol/proj';
import { Icon } from '@blueprintjs/core';
import { displayMessage } from '../../../messages/store/reducers';

const { Dragger } = Upload;
const MAX_SIMULTANEOUS_UPLOADS = 10;

// Init Geolocation
const view = new View({
  center: [0, 0],
  zoom: 2,
});

const geolocation = new Geolocation({
  trackingOptions: {
    enableHighAccuracy: true,
  },
  projection: view?.getProjection(),
});
if (geolocation) {
  geolocation.setTracking(true);
}

interface Props {
  schemaReducer: ISchemaReducer;
  record: DbRecordEntityTransform;
  getAssociations: any;
  categorySelectorHidden?: boolean;
  fileSchemaType: string;
  alertMessage: (params: { body: string; type: string }) => void;
}

interface State {
  category: string | null;
  coordinates: number[];
}

class FileTypeUploaderDragAndDrop extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      category: null,
      coordinates: [],
    };
    this.fileUploadProps = this.fileUploadProps.bind(this);
  }

  componentDidMount() {
    this.getCoordinates();
  }

  getCoordinates = () => {
    if (geolocation) {
      const coordinates = geolocation?.getPosition();
      if (coordinates && coordinates?.length! > 0) {
        this.setState({ coordinates: toLonLat(coordinates!) });
      }
      geolocation?.on('change:position', () => {
        const coordinates = geolocation?.getPosition();
        if (coordinates && coordinates?.length! > 0) {
          this.setState({ coordinates: toLonLat(coordinates!) });
        }
      });
    }
  };

  public fileUploadProps() {
    const { schemaReducer, getAssociations, record, fileSchemaType } = this.props;
    const schema = getSchemaFromShortListBySchemaId(schemaReducer.shortList, record?.schemaId);
    return {
      name: 'file',
      data: {
        device: isMobile ? 'MOBILE' : 'DESKTOP',
        category: this.state.category,
        fileSchemaType: fileSchemaType || 'DEFAULT',
      },
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem(`token`),
      },
      action: `${getHostName()}/SchemaModule/v1.0/s3/files/${record.entity}/${record?.id}/upload`,
      onChange(info: any) {
        const { status } = info.file;

        if (status !== 'uploading') {
        }

        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
          getAssociations({
            recordId: record.id,
            key: 'File',
            schema: schema,
            entities: ['File'],
          });
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      progress: {
        strokeColor: {
          '0%': '#108ee9',
          '100%': '#87d068',
        },
        format: (percent: any) => `${parseFloat(percent.toFixed(2))}%`,
      },
    };
  }

  render() {
    const { alertMessage } = this.props;

    return (
      <>
        {isMobile && this.state.coordinates.length === 0 && (
          <Alert
            message="Geolocation is unavailable"
            description="We could not retrieve your geolocation. Check if GPS is enabled on your device, and see that browser allows location access."
            type="warning"
            showIcon
            style={{ marginBottom: 10 }}
          />
        )}
        <Dragger
          multiple
          maxCount={MAX_SIMULTANEOUS_UPLOADS}
          beforeUpload={(file: any, fileList: any[]) => {
            // Allow max uploads per batch
            if (fileList.length > MAX_SIMULTANEOUS_UPLOADS) {
              alertMessage({
                body: `You can upload maximum ${MAX_SIMULTANEOUS_UPLOADS} files at a time.`,
                type: 'error',
              });
              return Upload.LIST_IGNORE;
            }
            // Else, just upload and add watermark
            else {
              beforeUploadWithWatermark({
                file: file,
                record: this.props.record,
                coordinates: this.state.coordinates,
              }) as any;
            }
          }}
          {...this.fileUploadProps()}
        >
          <p className="ant-upload-drag-icon">
            <Icon icon="upload" size={24} color="#2D72D2" />
          </p>
          <p className="ant-upload-text">Click or drag files to this area to upload</p>
          <p className="ant-upload-hint">
            Multiple file upload. Max {MAX_SIMULTANEOUS_UPLOADS} uploads at a time.
          </p>
        </Dragger>
      </>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
  recordReducer: state.recordReducer,
  recordAssociationReducer: state.recordAssociationReducer,
});

const mapDispatch = (dispatch: any) => ({
  getAssociations: (params: IGetRecordAssociations) =>
    dispatch(getRecordAssociationsRequest(params)),
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(FileTypeUploaderDragAndDrop);
