import React from 'react';

import { CalendarServiceContext, CalendarStateContext } from '../context';
import { CalendarEffects } from '../effects';
import { CalendarService } from '../types';
import { useCalendarReducer } from './useCalendarReducer';
import { useCalendarRequests } from './useCalendarRequests';

const useCalendarService = () => {
  const { state, actions } = useCalendarReducer();
  const service: CalendarService = {
    ...useCalendarRequests(actions),
    selectSchedule: actions.selectSchedule,
    setScheduleFilter: actions.setScheduleFilter,
    setAppointmentsFilter: actions.setAppointmentsFilter,
    nextDateFilter: actions.nextDateFilter,
    previousDateFilter: actions.previousDateFilter,
    setDateFilter: actions.setDateFilter,
    setSelectedDate: actions.setSelectedDate,
    showItemDetails: actions.showItemDetails,
    hideItemDetails: actions.hideItemDetails,
  };

  return { state, service };
};

export const CalendarProvider: React.FC = ({ children }) => {
  const { state, service } = useCalendarService();
  return (
    <CalendarServiceContext.Provider value={service}>
      <CalendarStateContext.Provider value={state}>
        {children}
        <CalendarEffects />
      </CalendarStateContext.Provider>
    </CalendarServiceContext.Provider>
  );
};
