import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { Button, Card, Col, Divider, Row, Typography } from 'antd';
import { FC, useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { DataSetBuilderContext } from '../index';
import { getPipelinesByModuleAndEntity } from '@legacy/core/pipelines/store/actions';
import {
  ISearchRecords,
  searchRecordsDebounced,
} from '@legacy/core/records/store/actions';
import DataSetPagination from '../../commons/DataSetPagination';
import ElasticFilterBuilder from '../ElasticFilterBuilder';
import {
  SET_DATASET_PAGINATION,
  SET_ELASTIC_QUERY,
  SET_IS_RECORD_SEARCHING,
  SET_RECORD_SEARCH_RESULTS,
} from '../store/constants';
import { IDataSetPagination } from '../store/reducer';
import DataSetSearchResults from './SearchResults';
type Props = {
  schema: SchemaEntity | undefined;
  searchRecords: Function;
  getPipelines: Function;
};

const RecordSearchPanel: FC<Props> = (props: Props) => {
  const { schema, searchRecords, getPipelines } = props;
  const { state, dispatch } = useContext(DataSetBuilderContext);
  const [pipelines, setPipelines] = useState<PipelineEntity[]>([]);

  // When schema is available, get pipeline stages if any
  useEffect(() => {
    if (schema && !pipelines.length) {
      getPipelines({ schema: schema }, (response: any) => {
        if (response?.length > 0 && response[0]?.stages?.length! > 0) {
          setPipelines(response[0]?.stages);
        }
      });
    }
  }, [schema]);

  // Search on page next/prev update
  useEffect(() => {
    if (state.elasticQuery && state.elasticQuery?.searchQuery?.pageable?.page > 0) {
      searchRecordsByElasticQuery(state.elasticQuery);
    }
  }, [state.pagination.currentPage]);

  const setDatasetPagination = (payload: IDataSetPagination) => {
    dispatch({ type: SET_DATASET_PAGINATION, payload: payload });
  };

  const updateDataSetPaginationPage = (payload: { currentPage: number }) => {
    dispatch({ type: SET_DATASET_PAGINATION, payload: payload });
  };

  // This is a callback from child component that sets the Elastic filters
  const updateElasticQuery = (query: any) => {
    dispatch({ type: SET_ELASTIC_QUERY, payload: query });
    if (query) {
      searchRecordsByElasticQuery(query);
    } else {
      dispatch({ type: 'DESELECT_ALL_RECORDS', payload: true });
    }
  };

  // Search the records
  const searchRecordsByElasticQuery = (searchQuery: any) => {
    dispatch({ type: SET_IS_RECORD_SEARCHING, payload: true });
    if (schema) {
      searchRecords(searchQuery, (searchResults: any) => {
        dispatch({ type: SET_IS_RECORD_SEARCHING, payload: false });

        // Set search results -> reducer
        if (searchResults?.data?.data?.length > 0) {
          dispatch({ type: SET_RECORD_SEARCH_RESULTS, payload: searchResults?.data?.data });
        } else {
          dispatch({ type: SET_RECORD_SEARCH_RESULTS, payload: [] });
        }

        // Set pagination -> reducer
        const pageable = searchResults.data?.pageable;
        if (pageable) {
          setDatasetPagination({
            totalRecords: pageable?.totalRecords,
            totalPages:
              pageable && pageable.totalRecords > state.pagination.pageSize
                ? Math.ceil(pageable.totalRecords / state.pagination.pageSize)
                : 1,
          });
        }
      });
    }
  };

  return (
    <Card
      className="DataSetBuilderPanelRow"
      extra={
        <>
          <Button
            type="link"
            disabled={
              state.searchResults.length === 0 ||
              state.isRecordSearching ||
              state.searchResults.length === state.dataSetRecords.length
            }
            onClick={() => {
              state.selectedRecords.length === state.searchResults.length
                ? dispatch({ type: 'DESELECT_ALL_RECORDS', payload: true })
                : dispatch({ type: 'SELECT_ALL_RECORDS', payload: true });
            }}
          >
            {state.selectedRecords.length === state.searchResults.length
              ? 'Deselect All'
              : 'Select All'}
          </Button>
          <Button
            type="primary"
            disabled={state.selectedRecords.length === 0}
            onClick={() => dispatch({ type: 'ADD_SELECTED_RECORDS_TO_DATASET', payload: true })}
          >
            {state.selectedRecords.length > 0
              ? `Add (${state.selectedRecords.length}) ${
                  state.selectedRecords.length > 1 ? 'Records' : 'Record'
                }`
              : 'Add Records'}
          </Button>
        </>
      }
      title={
        <Typography.Title level={5} style={{ marginTop: 0 }}>
          Search Records
        </Typography.Title>
      }
    >
      <Row>
        <Col span={24}>
          {/* Filter builder */}
          <Row gutter={12}>
            <Col span={24}>
              <ElasticFilterBuilder
                schema={schema!}
                onFilterUpdate={updateElasticQuery}
                currentSearchPage={state.pagination.currentPage}
                searchPageSize={state.pagination.pageSize}
                pipelines={pipelines}
              />
            </Col>
          </Row>
          {/* Divider 1 */}
          <Row>
            <Col span={24}>
              <Divider style={{ marginTop: 4, marginBottom: 10 }} />
            </Col>
          </Row>

          {state.elasticQuery ? (
            <>
              {/* Pagination */}
              <Row
                style={{ fontSize: '0.9em', opacity: state.isRecordSearching ? 0.3 : 1 }}
                align="middle"
              >
                <Col span={16} style={{ paddingTop: 2, paddingLeft: 2 }}>
                  <div>
                    <span style={{ fontWeight: 500 }}>Total records: </span>
                    <span>{state.pagination.totalRecords}</span>
                  </div>
                </Col>
                <Col span={8} style={{ textAlign: 'right', paddingRight: 15 }}>
                  <DataSetPagination
                    disabled={state.isRecordSearching}
                    pagination={state.pagination}
                    onPageUpdate={updateDataSetPaginationPage}
                  />
                </Col>
              </Row>
              {/* Divider 2 */}
              <Row>
                <Col span={24}>
                  <Divider style={{ marginTop: 4, marginBottom: 10 }} />
                </Col>
              </Row>
            </>
          ) : (
            <></>
          )}
        </Col>
      </Row>
      <DataSetSearchResults />
    </Card>
  );
};

const mapDispatch = (dispatch: any) => ({
  searchRecords: (params: ISearchRecords, cb: any) => dispatch(searchRecordsDebounced(params, cb)),
  getPipelines: (params: { schema: SchemaEntity }, cb: any) =>
    dispatch(getPipelinesByModuleAndEntity(params, cb)),
});

const mapState = (state: any) => ({});

export default connect(mapState, mapDispatch)(RecordSearchPanel);
