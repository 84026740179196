import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { AFPApprovalState } from './types';
import { FPEFilters, FPEStatusFilter } from './hooks/useAFPApprovalReducer/types';

export const groupFpesByStatusAndType = (allFpes: DbRecordEntityTransform[] = []) => {
  const todo: DbRecordEntityTransform[] = [];
  const approved: DbRecordEntityTransform[] = [];
  const rejected: DbRecordEntityTransform[] = [];

  const workItems: DbRecordEntityTransform[] = [];
  const adjustments: DbRecordEntityTransform[] = [];

  allFpes.forEach((fpe) => {
    const isApproved = String(getProperty(fpe, 'IsApproved'));
    if (['true'].includes(isApproved)) approved.push(fpe);
    else if (['false'].includes(isApproved)) rejected.push(fpe);
    else todo.push(fpe);

    if (fpe.type === 'DEFAULT') workItems.push(fpe);
    if (fpe.type === 'ADJUSTMENT') adjustments.push(fpe);
  });

  const fpesByStatus: Record<FPEStatusFilter, DbRecordEntityTransform[]> = {
    todo,
    approved,
    rejected,
  };
  const fpesByType = { workItems, adjustments };

  return { fpesByStatus, fpesByType };
};

export const getFeatureTypeOptionsFromFpes = (fpes: DbRecordEntityTransform[]) => {
  const featureTypes = new Set();

  fpes.forEach((fpe) => {
    const featureType = getProperty(fpe, 'FeatureType') ?? '';
    featureTypes.add(featureType);
  });

  return [...featureTypes].map((featureType) => ({ label: featureType, value: featureType }));
};

export const filterFpes = (fpes: DbRecordEntityTransform[], filters: Omit<FPEFilters, 'status'>) =>
  fpes.filter(
    (rec: DbRecordEntityTransform) =>
      rec.title!.toLowerCase().includes(filters.text.toLowerCase()) &&
      (filters.featureType.includes(getProperty(rec, 'FeatureType')) ||
        filters.featureType.length === 0),
  );

export const getSelectedRecords = (state: AFPApprovalState) => {
  const {
    records: { allFpes },
    fpeSelection,
  } = state;

  const { fpesByStatus } = groupFpesByStatusAndType(allFpes.data);

  // If selectAll is checked, all FPEs displayed should be
  // considered as selected;
  if (fpeSelection.selectAll)
    return filterFpes(fpesByStatus[fpeSelection.filters.status], fpeSelection.filters);

  return fpeSelection.selectedRecords;
};

export const getUniqueFeatureIds = (fpes: DbRecordEntityTransform[]) => {
  const featureIds = new Set<string>();

  fpes.forEach((fpe) => {
    const featureId = getProperty(fpe, 'FeatureId');
    if (featureId) featureIds.add(featureId as string);
  });

  return [...featureIds];
};
