import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons'; // Adjust the import path
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Alert, Card, Col, Row, Statistic } from 'antd';
import React from 'react';
import MeshNodeList from './MeshNodeList';
import NetworkDeviceList from './NetworkDevices';

function Summary(params: {
  router: DbRecordEntityTransform;
  data: {
    network: any;
    networkDevices: any[];
    networkProfiles: any[];
    networkNodes: any[];
  };
}) {
  const { router, data } = params;

  return (
    <div>
      <Card title={'Speed'} size="small" style={{ marginTop: 12 }}>
        {!data?.network?.speed ? (
          <Alert type="error" message={`Failed to perform speed test`} />
        ) : (
          <Row gutter={16}>
            <Col span={8}>
              <Statistic
                title={`Upload ${data?.network?.speed?.up?.units}`}
                value={data?.network?.speed?.up?.value}
                prefix={<ArrowUpOutlined />}
                precision={2}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title={`Download ${data?.network?.speed?.up?.units}`}
                value={data?.network?.speed?.down?.value}
                prefix={<ArrowDownOutlined />}
                precision={2}
              />
            </Col>
          </Row>
        )}
      </Card>
      <Card title={'Mesh Nodes'} size="small" style={{ marginTop: 12 }}>
        <MeshNodeList data={data?.networkNodes} />
      </Card>
      <Card title={'Devices'} size="small" style={{ marginTop: 12 }}>
        <NetworkDeviceList data={data?.networkDevices} />
      </Card>
    </div>
  );
}

export default Summary;
