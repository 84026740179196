import { PlusOutlined } from '@ant-design/icons';
import { PipelineEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/pipeline/pipeline.entity';
import { SchemaColumnEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/column/schema.column.entity';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Divider,
  Dropdown,
  Input,
  Row,
  Select,
  Tag,
} from 'antd';
import dayjs from 'dayjs';
import { FC, useContext, useEffect, useState } from 'react';
import { dataSetConditions } from '../../helpers';
import ElasticFilterTag from './ElasticFilterTag';
import './styles.scss';
import { DataSetBuilderContext } from '../index';
import { debugLog } from '@core/helpers/developmentHelpers';
import { set } from 'lodash';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  schema: SchemaEntity;
  onFilterUpdate: Function; // This will return back must/must_not filters for the elastic search
  currentSearchPage: number; // Current page for elastic pagination
  searchPageSize: number; // Page size for elastic pagination
  pipelines?: PipelineEntity[];
}

type TElasticOperator = 'Must contain' | 'Must not contain' | 'Date is in range';
export type TAppliedFilter = {
  columnName: string;
  operator: TElasticOperator;
  isStage: boolean;
  columnValue: { realValue: string; prettyValue: string };
  closable: boolean;
};

const ElasticFilterBuilder: FC<Props> = (props: Props) => {
  const { schema, onFilterUpdate, pipelines } = props;
  const { state } = useContext(DataSetBuilderContext);

  // Local state
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [selectedColumn, setSelectedColumn] = useState<SchemaColumnEntity | undefined>(undefined);
  const [isFilteringByStage, setIsFilteringByStage] = useState<boolean>(false);
  const [selectedOperator, setSelectedOperator] = useState<TElasticOperator | undefined>(undefined);
  const [selectedColumnValue, setSelectedColumnValue] = useState<
    { realValue: string; prettyValue: string } | undefined
  >(undefined);
  const [appliedFilters, setAppliedFilters] = useState<TAppliedFilter[]>([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  // Custom rule: If dataset is ADDRESS type, we want to
  // add two static filters that cannot be removed by the user.
  useEffect(() => {
    if (state.parentRecord && state.parentRecord?.type === 'ADDRESS') {
      setAppliedFilters([
        {
          columnName: 'Region',
          operator: 'Must contain',
          isStage: false,
          columnValue: {
            realValue: getProperty(state.parentRecord, 'Region'),
            prettyValue: getProperty(state.parentRecord, 'Region'),
          },
          closable: false,
        },
        {
          columnName: 'ExchangeName',
          operator: 'Must contain',
          isStage: false,
          columnValue: {
            realValue: getProperty(state.parentRecord, 'Exchange'),
            prettyValue: getProperty(state.parentRecord, 'Exchange'),
          },
          closable: false,
        },
      ]);
    }
  }, [state.parentRecord]);

  // Clear everything and close the Dropdown
  const clearFilterSteps = () => {
    setIsDropdownOpen(false);
    setTimeout(() => {
      setSelectedColumn(undefined);
      setIsFilteringByStage(false);
      setSelectedOperator(undefined);
      setSelectedColumnValue(undefined);
      setSearchQuery('');
    }, 500);
  };

  // Depending on column type, cover as much types as possible
  const renderColumnProperties = (selectedColumn: SchemaColumnEntity) => {
    // Filtering by record Stage
    if (isFilteringByStage && pipelines && pipelines.length > 0) {
      return pipelines.map((pipeline: any) => (
        <Col
          span={24}
          style={{ cursor: 'pointer' }}
          onClick={(e: any) => {
            setSelectedColumnValue({ realValue: pipeline.key, prettyValue: pipeline.name });
          }}
        >
          <Checkbox
            key={pipeline.label}
            value={pipeline.label}
            checked={selectedColumnValue?.realValue === pipeline.key}
          />
          <span style={{ marginLeft: 8 }}>{pipeline.name}</span>
        </Col>
      ));
    } else if (selectedColumn.type === 'ENUM') {
      return selectedColumn.options?.map((option: any) => (
        <Col
          style={{ cursor: 'pointer' }}
          span={24}
          onClick={(e: any) => {
            setSelectedColumnValue({ realValue: option.value, prettyValue: option.label });
          }}
        >
          <Checkbox
            key={option.value}
            value={option.value}
            checked={selectedColumnValue?.realValue === option.value}
          />
          <span style={{ marginLeft: 8 }}>{option.label}</span>
        </Col>
      ));
    } else if (['TEXT', 'EMAIL', 'NUMBER'].includes(selectedColumn.type)) {
      return (
        <Col span={24}>
          <Input
            maxLength={50}
            placeholder={`Enter ${selectedColumn.type.toLocaleLowerCase()} here`}
            allowClear
            onChange={(e: any) => {
              if (e.target.value?.length! > 0) {
                setSelectedColumnValue({ realValue: e.target?.value, prettyValue: e.target.value });
              } else {
                setSelectedColumnValue(undefined);
              }
            }}
          />
        </Col>
      );
    }
    // Single Date
    else if (selectedColumn.type === 'DATE' && selectedOperator !== 'Date is in range') {
      return (
        <Col span={24}>
          <DatePicker
            placeholder="Select Date to filter"
            style={{ width: '100%', marginBottom: 8 }}
            onChange={(e: any) =>
              setSelectedColumnValue({
                realValue: dayjs(e).format('YYYY-MM-DD'),
                prettyValue: dayjs(e).format('DD/MM/YYYY'),
              })
            }
          />
        </Col>
      );
    }
    // Date Range
    else if (selectedColumn.type === 'DATE' && selectedOperator === 'Date is in range') {
      return (
        <Col span={24}>
          <DatePicker.RangePicker
            style={{ width: '100%', marginBottom: 8 }}
            onChange={(e: any) =>
              setSelectedColumnValue({
                realValue: `${dayjs(e[0]).format('YYYY-MM-DD')}/${dayjs(e[1]).format(
                  'YYYY-MM-DD',
                )}`,
                prettyValue: `${dayjs(e[0]).format('DD/MM/YYYY')} - ${dayjs(e[1]).format(
                  'DD/MM/YYYY',
                )}`,
              })
            }
          />
        </Col>
      );
    } else if (selectedColumn.type === 'BOOLEAN') {
      if (!selectedColumnValue) {
        // Preset to true
        setSelectedColumnValue({
          realValue: 'true',
          prettyValue: 'TRUE',
        });
      }

      return (
        <Col span={24}>
          <Select
            defaultValue={'TRUE'}
            style={{ width: '100%' }}
            onChange={(e: any) =>
              setSelectedColumnValue({
                realValue: e,
                prettyValue: e.toUpperCase(),
              })
            }
          >
            <Select.Option key="true" value="false">
              TRUE
            </Select.Option>
            <Select.Option key="false" value="false">
              FALSE
            </Select.Option>
          </Select>
        </Col>
      );
    } else {
      return (
        <Col span={24}>
          <Alert
            style={{ margin: 8 }}
            type="warning"
            description={`Filtering on ${selectedColumn.type} type is not yet supported.`}
          />
        </Col>
      );
    }
  };

  // Step 1 - Select column name to use in filter
  const renderFirstStep = () => {
    if (
      schema &&
      !selectedColumn &&
      !isFilteringByStage &&
      !selectedOperator &&
      !selectedColumnValue
    ) {
      let filteredColumns: SchemaColumnEntity[] = Object.assign(schema?.columns);

      // Quick search
      if (searchQuery.length > 0) {
        filteredColumns = filteredColumns.filter(
          (column: SchemaColumnEntity) =>
            column.label && column.label!.toLowerCase().indexOf(searchQuery.toLowerCase()) > -1,
        );
      }

      return (
        <Col span={24}>
          <Row className="elasticFilterBody">
            {/* Add record stage if there is pipeline */}
            {pipelines && pipelines.length > 0 && !searchQuery.length ? (
              <>
                <Col
                  span={24}
                  onClick={() => setIsFilteringByStage(true)}
                  style={{ cursor: 'pointer', marginTop: 5 }}
                >
                  <Checkbox />
                  <span style={{ marginLeft: 8 }}>{schema?.entityName} Stage</span>
                </Col>
                <Divider style={{ marginTop: 8, marginBottom: 8 }} />
              </>
            ) : (
              <></>
            )}
            {/* Render list of columns */}
            {filteredColumns
              ?.sort((a: any, b: any) => (a.name > b.name ? 1 : -1))
              .map((column: SchemaColumnEntity) => (
                <Col
                  span={24}
                  style={{ paddingTop: 3, cursor: 'pointer' }}
                  onClick={() => setSelectedColumn(column)}
                >
                  <Checkbox />
                  <span style={{ marginLeft: 8 }}>{column.label}</span>
                </Col>
              ))}
          </Row>
        </Col>
      );
    }
  };

  // Step 2 - Choose search operator (Must / Must not)
  const renderSecondStep = () => {
    if ((selectedColumn || isFilteringByStage) && !selectedOperator && !selectedColumnValue) {
      return (
        <Col span={24}>
          <Row className="elasticFilterBody">
            <Col
              span={24}
              style={{ paddingTop: 3, cursor: 'pointer' }}
              onClick={() => setSelectedOperator('Must contain')}
            >
              <Checkbox key="operator_must" />
              <span style={{ marginLeft: 8 }}>Must contain</span>
            </Col>
            <Col
              span={24}
              style={{ paddingTop: 3, cursor: 'pointer' }}
              onClick={() => setSelectedOperator('Must not contain')}
            >
              <Checkbox key="operator_mustnot" />
              <span style={{ marginLeft: 8 }}>Must not contain</span>
            </Col>

            {/* If Column is DATE */}
            {selectedColumn?.type === 'DATE' && (
              <Col
                span={24}
                style={{ paddingTop: 3, cursor: 'pointer' }}
                onClick={() => setSelectedOperator('Date is in range')}
              >
                <Checkbox key="operator_isdateinrange" />
                <span style={{ marginLeft: 8 }}>Date is in range</span>
              </Col>
            )}
          </Row>
        </Col>
      );
    }
  };

  // Step 3 - Select or enter column value
  const renderThirdStep = () => {
    if ((selectedColumn || isFilteringByStage) && selectedOperator) {
      return (
        <Col span={24}>
          <Row>{renderColumnProperties(selectedColumn!)}</Row>
        </Col>
      );
    }
  };

  // Every time filters are updated, refresh Elastic query
  useEffect(() => {
    if (appliedFilters.length > 0) {
      generateElasticQuery();
    } else {
      onFilterUpdate(undefined);
    }
  }, [appliedFilters]);

  // Generate elastic query using applied filters
  const generateElasticQuery = () => {
    let mustFilters: TAppliedFilter[] = appliedFilters.filter(
      (filter: TAppliedFilter) => filter.operator === 'Must contain',
    );
    let mustNotFilters: TAppliedFilter[] = appliedFilters.filter(
      (filter: TAppliedFilter) => filter.operator === 'Must not contain',
    );
    let rangeFilters: TAppliedFilter[] = appliedFilters.filter(
      (filter: TAppliedFilter) => filter.operator === 'Date is in range',
    );

    const constructMustFilters = () => {
      let response: any[] = [];
      if (rangeFilters.length > 0) {
        const range = rangeFilters.map((filter: TAppliedFilter) => ({
          range: {
            [`properties.${filter.columnName}`]: {
              gte: filter.columnValue?.realValue?.split('/')[0],
              lte: filter.columnValue?.realValue?.split('/')[1],
            },
          },
        }));
        response.push(...range);
      }

      if (mustFilters.length > 0) {
        const must = mustFilters.map((filter: TAppliedFilter) => ({
          query_string: {
            fields: filter.isStage ? [`stage.key.keyword`] : [`properties.${filter.columnName}`],
            query: filter.isStage
              ? `(${filter.columnValue?.realValue})`
              : `(${filter.columnValue?.realValue})`,
          },
        }));
        response.push(...must);
      }

      return response;
    };

    if (mustFilters.length > 0 || mustNotFilters.length > 0 || rangeFilters.length > 0) {
      let elasticQuery = {
        schema: schema,
        searchQuery: {
          schemas: schema?.id,
          boolean: {
            must:
              rangeFilters.length > 0 || mustFilters.length > 0
                ? constructMustFilters()
                : undefined,
            must_not:
              mustNotFilters.length > 0
                ? mustNotFilters.map((filter: TAppliedFilter) => ({
                    query_string: {
                      fields: filter.isStage
                        ? [`stage.key.keyword`]
                        : [`properties.${filter.columnName}`],
                      query: filter.isStage
                        ? `(${filter.columnValue?.realValue})`
                        : filter.columnValue?.realValue,
                      lenient: true,
                      default_operator: 'AND',
                    },
                  }))
                : undefined,
          },
          pageable: {
            page: props.currentSearchPage,
            size: props.searchPageSize,
          },
          sort: dataSetConditions(schema?.entityName),
        },
      };
      onFilterUpdate(elasticQuery);
    } else {
      onFilterUpdate(undefined);
    }
  };

  const addNewFilter = () => {
    if ((selectedColumn || isFilteringByStage) && selectedOperator && selectedColumnValue) {
      setAppliedFilters([
        ...appliedFilters,
        {
          columnName: isFilteringByStage ? 'Stage' : selectedColumn?.name!,
          columnValue: selectedColumnValue,
          operator: selectedOperator,
          isStage: isFilteringByStage,
          closable: true,
        },
      ]);
      clearFilterSteps();
    }
  };

  const removeFilter = (filterToRemove: TAppliedFilter) => {
    if (filterToRemove) {
      setAppliedFilters(
        appliedFilters.filter((filter: TAppliedFilter) => filter !== filterToRemove),
      );
    }
  };

  // Cancel / Apply button row
  const renderFilterActions = () => {
    return (
      <>
        <Col span={24}>
          <Divider style={{ marginTop: 8, marginBottom: 10 }} />
        </Col>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="default" onClick={() => clearFilterSteps()} style={{ marginRight: 10 }}>
            Cancel
          </Button>
          <Button
            disabled={
              (!selectedColumn && !isFilteringByStage) || !selectedColumnValue || !selectedOperator
            }
            type="primary"
            onClick={() => addNewFilter()}
          >
            Apply
          </Button>
        </Col>
      </>
    );
  };

  return (
    <Row>
      <Col span={24}>
        <Dropdown
          open={isDropdownOpen}
          overlay={
            <Row className="elasticFilterContainer">
              {!selectedColumn ? (
                <Col
                  span={24}
                  style={{ paddingBottom: 8, display: isFilteringByStage ? 'none' : 'block' }}
                >
                  <Input
                    allowClear
                    style={{ width: '100%' }}
                    placeholder="Filter..."
                    value={searchQuery}
                    onChange={(e: any) => setSearchQuery(e.target.value)}
                  />
                </Col>
              ) : (
                <></>
              )}
              <Col span={24}>
                {/* Stage tag */}
                {isFilteringByStage ? (
                  <Tag className="filterTag" style={{ cursor: 'default' }}>
                    Stage
                  </Tag>
                ) : (
                  <></>
                )}

                {/* Column Name Tag */}
                {selectedColumn ? (
                  <Tag className="filterTag" style={{ cursor: 'default' }}>
                    {selectedColumn.label}
                  </Tag>
                ) : (
                  <></>
                )}

                {/* Operator Tag */}
                {selectedOperator ? (
                  <Tag
                    className={`filterTag ${
                      selectedOperator === 'Must contain' || selectedOperator === 'Date is in range'
                        ? 'mustFilter'
                        : 'mustNotFilter'
                    }`}
                  >
                    {selectedOperator}
                  </Tag>
                ) : (
                  <></>
                )}

                {/* Divider, if needed. */}
                {selectedColumn || selectedOperator ? (
                  <Divider style={{ marginTop: 3, marginBottom: 10 }} />
                ) : (
                  <></>
                )}
              </Col>

              {/* Step 1 - Choose Column */}
              {renderFirstStep()}
              {/* Step 2 - Choose Search Operator */}
              {renderSecondStep()}
              {/* Step 3 - Choose Search Value */}
              {renderThirdStep()}
              {/* Render Cancel/Apply Button row */}
              {renderFilterActions()}
            </Row>
          }
        >
          <Button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            type="dashed"
            size="small"
            icon={<PlusOutlined />}
            style={{ marginRight: 8, marginBottom: 7 }}
            disabled={!schema}
          >
            Filter
          </Button>
        </Dropdown>
        {/* Render Filters as tags */}
        {appliedFilters.map((filter: TAppliedFilter) => (
          <ElasticFilterTag filter={filter} onRemove={removeFilter} />
        ))}
      </Col>
    </Row>
  );
};

export default ElasticFilterBuilder;
