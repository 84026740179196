export const formFields = [
  {
    label: 'First Name',
    property: 'firstname',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input First Name',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Last Name',
    property: 'lastname',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input Last Name',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Email',
    property: 'email',
    type: 'EMAIL',
    isRequired: true,
    message: 'Please input Email',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Password',
    property: 'password',
    type: 'PASSWORD',
    isRequired: true,
    message: 'Please input Password',
    isHidden: false,
    value: undefined,
    customValidation: true,
    customValidationMessage:
      'Password must be longer than or equal to 8 characters and shorter than or equal to 20 characters',
  },
];

export const userEditForm = [
  {
    label: '',
    property: 'id',
    type: 'TEXT',
    isRequired: true,
    message: '',
    isHidden: true,
    value: undefined,
  },
  {
    label: 'First Name',
    property: 'firstname',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input your first name',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Last Name',
    property: 'lastname',
    type: 'TEXT',
    isRequired: true,
    message: 'Please input your last name',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Email',
    property: 'email',
    type: 'EMAIL',
    isRequired: true,
    message: 'Please input your email',
    isHidden: false,
    value: undefined,
  },
];
export const changePasswordForm = [
  {
    label: '',
    property: 'id',
    type: 'TEXT',
    isRequired: true,
    message: '',
    isHidden: true,
    value: undefined,
  },
  {
    label: 'Email',
    property: 'email',
    type: 'EMAIL',
    isRequired: true,
    message: 'Please input your email',
    isHidden: false,
    value: undefined,
  },
  {
    label: 'Password',
    property: 'password',
    type: 'PASSWORD',
    isRequired: true,
    message: 'Please input your password',
    isHidden: false,
    value: undefined,
    customValidation: true,
    customValidationMessage:
      'Password must be longer than or equal to 8 characters and shorter than or equal to 15 characters',
  },
  {
    label: 'Confirm Password',
    property: 'confirmPassword',
    type: 'PASSWORD',
    isRequired: true,
    message: 'Please confirm your password',
    isHidden: false,
    value: undefined,
    customValidation: true,
    customValidationMessage:
      'Password must be longer than or equal to 8 characters and shorter than or equal to 15 characters',
  },
];
