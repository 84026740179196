import { AFPApprovalState } from '../../types';
import { AFPApprovalActions } from '../index';

export const fpeSelectionReducer = (
  state: AFPApprovalState['fpeSelection'],
  action: AFPApprovalActions,
): AFPApprovalState['fpeSelection'] => {
  switch (action.type) {
    case 'FPE_SELECTION_STATUS_FILTER_SET':
      return {
        ...state,
        filters: {
          ...state.filters,
          status: action.payload,
        },
      };

    case 'FPE_SELECTION_TEXT_FILTER_SET':
      return {
        ...state,
        filters: {
          ...state.filters,
          text: action.payload,
        },
      };

    case 'FPE_SELECTION_FEATURE_TYPE_FILTER_SET':
      return {
        ...state,
        filters: {
          ...state.filters,
          featureType: action.payload,
        },
      };

    case 'FPE_SELECTION_SELECT_ALL_SET':
      return {
        ...state,
        selectAll: action.payload,
      };

    case 'FPE_SELECTION_SELECTED_RECORDS_SET':
      return {
        ...state,
        selectedRecords: action.payload,
      };
    default:
      return state;
  }
};
