import { NonIdealState } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  SchemaModuleEntityTypeEnums,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import React from 'react';
import { splitCamelCase } from '@core/helpers/reactHelpers';
import CreateGocardlessMandate from '@core/modules/BillingModule/components/CreateGocardlessMandate';

interface Props {
  record: DbRecordEntityTransform;
  associatedRecordEntityName: string | undefined;
  associatedRecordModuleName: string | undefined;
  onSuccess: any;
}

const { PAYMENT_METHOD } = SchemaModuleEntityTypeEnums;

const RecordCardMissing: React.FC<Props> = (props: Props) => {
  const { record, associatedRecordEntityName, associatedRecordModuleName, onSuccess } = props;

  const showMissingPanel = () => {
    switch (associatedRecordEntityName) {
      case PAYMENT_METHOD:
        return (
          <NonIdealState
            icon={'search'}
            iconSize={20}
            description="No Payment method found. You can create a new one with the button below."
            action={<CreateGocardlessMandate record={record!} onConfirm={onSuccess} />}
            layout="vertical"
          />
        );
      default:
        return (
          <NonIdealState
            icon={'search'}
            iconSize={20}
            description={
              associatedRecordEntityName && associatedRecordModuleName
                ? `Associated ${splitCamelCase(associatedRecordEntityName)} could not be found.`
                : 'Record could not be found.'
            }
            layout="vertical"
          />
        );
    }
  };

  return showMissingPanel();
};
export default RecordCardMissing;
