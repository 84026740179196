import React, { HTMLAttributes, TdHTMLAttributes, useState } from 'react';
import { Table } from 'antd';
import { getTableColumns } from './utils';
import { JSONTableRowAction } from './types';

type JSONTableProps = {
  jsonDataSource: any[];
  loading: boolean;
  columnPositions: { [key: string]: number };
  columnRenderers?: { [key: string]: (text: string, record: any) => React.ReactElement | string };
  editableProperties?: string[];
  editableColumnRenderers?: {
    [key: string]: (
      text: string,
      record: any,
      onChange: (event: React.ChangeEvent<HTMLInputElement> | string) => void,
    ) => React.ReactElement | string;
  };
  onDataSourceUpdated?: (dataSourc: any) => void;
  getRowKey: (record: any) => string;
  rowClassName: (record: any, index: number) => string;
  rowActions?: JSONTableRowAction[];
  rowSelection?: any;
  onRow?: (record: any, index?: number) => HTMLAttributes<any> | TdHTMLAttributes<any>;
};

export const JSONTable = ({
  jsonDataSource,
  loading = false,
  columnPositions,
  editableProperties = [],
  columnRenderers = {},
  editableColumnRenderers = {},
  onDataSourceUpdated,
  getRowKey,
  rowClassName,
  rowActions = [],
  rowSelection,
  onRow = (_r, _i) => ({}),
}: JSONTableProps) => {
  const [dataSource, setDataSource] = useState(jsonDataSource);

  React.useEffect(() => {
    if (jsonDataSource?.length !== dataSource?.length) setDataSource(jsonDataSource);
  }, [jsonDataSource?.length]);

  const getOnChangeHandler =
    (key: string, index: number) => (event: React.ChangeEvent<HTMLInputElement> | string) => {
      const value = typeof event === 'object' ? event.target?.value ?? '' : event; // Select is not triggering events, but passing the value directly

      if (typeof event === 'object' && event.persist) event.persist();
      setDataSource((currentDataSource) => {
        const updatedDataSource = currentDataSource.map((item, itemIndex) => {
          if (itemIndex === index)
            return {
              ...item,
              [key]: value,
            };
          return item;
        });

        if (onDataSourceUpdated) onDataSourceUpdated(updatedDataSource);

        return updatedDataSource;
      });
    };

  const columns = getTableColumns(
    jsonDataSource,
    {
      editableProperties,
      columnPositions,
      columnRenderers,
      editableColumnRenderers,
      rowActions,
    },
    { getOnChangeHandler },
  );

  return (
    <Table
      bordered
      loading={loading}
      tableLayout={'auto'}
      size="small"
      // pagination={false}
      dataSource={dataSource}
      rowKey={getRowKey}
      rowClassName={rowClassName}
      // scroll={{ y: 600 }}
      // @ts-ignore
      columns={columns.sort((A, B) => A.position - B.position)}
      rowSelection={rowSelection}
      onRow={onRow}
    />
  );
};
