import {
  CLOSE_ADD_PRODUCT_DRAWER,
  CLOSE_IMPORT_FILES_DRAWER,
  CLOSE_ORDER_BUILDER_DRAWER,
  CLOSE_PRODUCT_REPLACEMENT_DRAWER,
  CLOSE_RECORD_DRAWER,
  OPEN_ADD_PRODUCT_DRAWER,
  OPEN_IMPORT_FILES_DRAWER,
  OPEN_ORDER_BUILDER_DRAWER,
  OPEN_PRODUCT_REPLACEMENT_DRAWER,
  OPEN_RECORD_DRAWER,
  SET_APPOINTMENT_COUNT_NUMBER,
  SET_LOOKUP_RECORD,
  SET_MENTIONS_COUNT_NUMBER,
  SET_ORDER_BUILDER_DRAWER_RECORD_IDS,
  SET_ORDER_BUILDER_RECONTRACTING,
  SET_RAW_DATA_DRAWER_CONTENTS,
  TOGGLE_ASSIGN_RECORD_TO_GROUP_MODAL,
  TOGGLE_CREATE_UPDATE_TEMPLATE_MODAL,
  TOGGLE_GENERATE_WORK_ORDER_MODAL_VISIBLE,
  TOGGLE_LOOKUP_DRAWER,
  TOGGLE_MANAGE_GROUPS_ON_TEMPLATE_MODAL,
  TOGGLE_MANAGE_ROLES_ON_TEMPLATE_MODAL,
  TOGGLE_NOTIFICATION_DRAWER_VISIBLE,
  TOGGLE_RAW_DATA_DRAWER,
  TOGGLE_SCHEMA_ACTION_CREATE_EDIT_DRAWER,
  TOGGLE_VIEW_CONFIGURATION_CREATE_EDIT_DRAWER,
} from './constants';
import { IUserInterfaceReducer } from './types';

export const initialState: IUserInterfaceReducer = {
  importFiles: {
    importFileDrawerVisible: false,
    importParentRecord: undefined,
    importFileType: undefined,
  },
  addProduct: {
    addProductDrawerVisible: false,
    parentRecord: undefined,
  },
  productReplacement: {
    productReplacementDrawerVisible: false,
    parentRecord: undefined,
    orderItemRecord: undefined,
  },
  rawDataDrawerVisible: false,
  rawDataDrawerContents: null,
  lookup: {
    lookupDrawerVisible: false,
    lookupRecord: undefined,
  },
  assignRecordToGroupModalVisible: false,
  orderBuilderDrawerVisible: false,
  orderBuilderRecontractingId: undefined,
  orderBuilderDrawerRecordIds: {
    accountId: undefined,
    leadId: undefined,
  },
  createUpdateTemplateModalVisible: false,
  manageRolesOnTemplateModalVisible: false,
  manageGroupsOnTemplateModalVisible: false,
  schemaActionCreateEditDrawerVisible: false,
  viewConfigurationCreateEditDrawerVisible: false,
  onBoardByEmailModalVisible: false,
  appointmentNotificationCount: 0,
  mentionsNotificationCount: 0,
  notificationDrawerVisible: false,
  generateWorkOrderModalVisible: false,
  recordDrawerRecordId: undefined,
  recordDrawerVisible: false,
  recordDrawerModuleName: undefined,
  recordDrawerEntityName: undefined,
};

export default function userInterfaceReducer(
  state: IUserInterfaceReducer = initialState,
  action: any,
) {
  switch (action.type) {
    case TOGGLE_RAW_DATA_DRAWER: {
      return {
        ...state,
        rawDataDrawerVisible: !state.rawDataDrawerVisible,
      };
    }

    case SET_RAW_DATA_DRAWER_CONTENTS: {
      return {
        ...state,
        rawDataDrawerContents: action.params,
      };
    }

    case TOGGLE_LOOKUP_DRAWER: {
      return {
        ...state,
        lookup: {
          ...state.lookup,
          lookupRecord: undefined,
          lookupDrawerVisible: !state.lookup.lookupDrawerVisible,
        },
      };
    }

    case TOGGLE_ASSIGN_RECORD_TO_GROUP_MODAL: {
      return {
        ...state,
        assignRecordToGroupModalVisible: !state.assignRecordToGroupModalVisible,
      };
    }

    case SET_LOOKUP_RECORD: {
      return {
        ...state,
        lookup: {
          ...state.lookup,
          lookupRecord: action.params?.record || undefined,
        },
      };
    }

    case OPEN_PRODUCT_REPLACEMENT_DRAWER: {
      return {
        ...state,
        productReplacement: {
          productReplacementDrawerVisible: true,
          parentRecord: action.params?.parentRecord,
          orderItemRecord: action.params?.orderItemRecord,
        },
      };
    }

    case CLOSE_PRODUCT_REPLACEMENT_DRAWER: {
      return {
        ...state,
        productReplacement: {
          productReplacementDrawerVisible: false,
          parentRecord: undefined,
          orderItemRecord: undefined,
        },
      };
    }

    case OPEN_ADD_PRODUCT_DRAWER: {
      return {
        ...state,
        addProduct: {
          addProductDrawerVisible: true,
          parentRecord: action.params?.parentRecord,
        },
      };
    }

    case CLOSE_ADD_PRODUCT_DRAWER: {
      return {
        ...state,
        addProduct: {
          addProductDrawerVisible: false,
          parentRecord: undefined,
        },
      };
    }

    case OPEN_IMPORT_FILES_DRAWER: {
      return {
        ...state,
        importFiles: {
          importFileDrawerVisible: true,
          importParentRecord: action.params?.parentRecord,
          importFileType: action.params?.type,
        },
      };
    }

    case CLOSE_IMPORT_FILES_DRAWER: {
      return {
        ...state,
        importFiles: {
          importFileDrawerVisible: false,
          importFileType: undefined,
        },
      };
    }

    case OPEN_ORDER_BUILDER_DRAWER: {
      return {
        ...state,
        orderBuilderDrawerVisible: true,
      };
    }

    case SET_ORDER_BUILDER_RECONTRACTING: {
      return {
        ...state,
        orderBuilderRecontractingId: action.params.orderId,
      };
    }

    case CLOSE_ORDER_BUILDER_DRAWER: {
      return {
        ...state,
        orderBuilderDrawerVisible: false,
        orderBuilderRecontractingId: undefined,
        orderBuilderDrawerRecordIds: {
          accountId: undefined,
          contactId: undefined,
          addressId: undefined,
          leadId: undefined,
        },
      };
    }

    case SET_ORDER_BUILDER_DRAWER_RECORD_IDS: {
      return {
        ...state,
        orderBuilderDrawerRecordIds: {
          ...state.orderBuilderDrawerRecordIds,
          ...action.params,
        },
      };
    }

    case TOGGLE_CREATE_UPDATE_TEMPLATE_MODAL: {
      return {
        ...state,
        createUpdateTemplateModalVisible: !state.createUpdateTemplateModalVisible,
      };
    }

    case TOGGLE_MANAGE_ROLES_ON_TEMPLATE_MODAL: {
      return {
        ...state,
        manageRolesOnTemplateModalVisible: !state.manageRolesOnTemplateModalVisible,
      };
    }

    case TOGGLE_MANAGE_GROUPS_ON_TEMPLATE_MODAL: {
      return {
        ...state,
        manageGroupsOnTemplateModalVisible: !state.manageGroupsOnTemplateModalVisible,
      };
    }

    case TOGGLE_SCHEMA_ACTION_CREATE_EDIT_DRAWER: {
      return {
        ...state,
        schemaActionCreateEditDrawerVisible: !state.schemaActionCreateEditDrawerVisible,
      };
    }

    case TOGGLE_VIEW_CONFIGURATION_CREATE_EDIT_DRAWER: {
      return {
        ...state,
        viewConfigurationCreateEditDrawerVisible: !state.viewConfigurationCreateEditDrawerVisible,
      };
    }

    case SET_APPOINTMENT_COUNT_NUMBER: {
      return {
        ...state,
        appointmentNotificationCount: action.params.count,
      };
    }

    case SET_MENTIONS_COUNT_NUMBER: {
      return {
        ...state,
        mentionsNotificationCount: action.params.count,
      };
    }

    case TOGGLE_NOTIFICATION_DRAWER_VISIBLE: {
      return {
        ...state,
        notificationDrawerVisible: !state.notificationDrawerVisible,
      };
    }

    case TOGGLE_GENERATE_WORK_ORDER_MODAL_VISIBLE: {
      return {
        ...state,
        generateWorkOrderModalVisible: !state.generateWorkOrderModalVisible,
      };
    }

    case OPEN_RECORD_DRAWER: {
      return {
        ...state,
        recordDrawerRecordId: action.params.recordId,
        recordDrawerVisible: true,
        recordDrawerModuleName: action.params.moduleName,
        recordDrawerEntityName: action.params.entityName,
      };
    }

    case CLOSE_RECORD_DRAWER: {
      return {
        ...state,
        recordDrawerRecordId: undefined,
        recordDrawerVisible: false,
        recordDrawerModuleName: undefined,
        recordDrawerEntityName: undefined,
      };
    }

    default:
      return state;
  }
}
