import React, { useContext, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'antd';
import { Button, InputGroup, Section, TextArea } from '@blueprintjs/core';
import {
  MY_CASES_SET_AGENT_INPUT_TEXT,
  MY_CASES_TOGGLE_MESSAGE_EDITOR,
} from '../../../store/constants';
import MessageTemplateHandler from '@core/components/MessageTemplateHandler';
import FileUploaderPopoverButton from '@core/components/FileUploaderPopoverButton';
import { httpPost } from '@core/http/requests';
import { MyCasesContext } from '../../../index';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import './styles.scss';

interface Props {
  userReducer: any;
  alertMessage: (params: { body: string; type: string }) => void;
}

const MyCasesMessageEditorWidget: React.FC<Props> = (props: Props) => {
  const { userReducer, alertMessage } = props;
  const { state, dispatch } = useContext(MyCasesContext);
  const [attachments, setAttachments] = useState<File[]>([]);
  const [isSendingMessage, setIsSendingMessage] = useState<boolean>(false);
  const isModalOpen: boolean = state.isComposingMessage;

  const [newMessageBody, setNewMessageBody] = useState<string>('');
  const [newWidgetMessageBody, setNewWidgetMessageBody] = useState<string>('');

  const messageText = state.agentInputText;
  const inputRef = useRef<any>(null);

  useEffect(() => {
    if (state.selectedCase && messageText.length > 0) {
      dispatch({ type: MY_CASES_SET_AGENT_INPUT_TEXT, payload: '' });
      setIsSendingMessage(false);
    }
  }, [state.selectedCase]);

  // This should refocus the input when the user sends a message
  useEffect(() => {
    if (!isSendingMessage) {
      inputRef.current?.focus();
    }
  }, [isSendingMessage]);

  const caseIsSolvedOrClosed = (): boolean => {
    return (
      state.selectedCase?.stage?.name === 'Closed' || state.selectedCase?.stage?.name === 'Solved'
    );
  };

  const isConversationClosed = (): boolean => {
    return (
      !state.selectedCaseConversation ||
      getProperty(state.selectedCaseConversation, 'Status') === 'CLOSED'
    );
  };

  const onTemplateInsert = (template: string) => {
    if (!state.isComposingMessage) {
      dispatch({ type: MY_CASES_TOGGLE_MESSAGE_EDITOR, payload: null });
    }
    setNewWidgetMessageBody(template);
  };

  const isCaseOwn = getProperty(state.selectedCase, 'OwnerId') === userReducer.user.id;

  const sendChatMessage = async (messageBody: string) => {
    if ((messageBody.length > 0 || attachments.length > 0) && state.selectedCaseConversation) {
      setIsSendingMessage(true);

      let formData = new FormData();
      formData.append('message', messageBody || '');
      formData.append('from', userReducer.user?.firstname);
      formData.append('sender', 'AGENT');
      if (attachments.length > 0) {
        attachments.forEach((file: File) => {
          formData.append('files', file);
        });
      }

      try {
        await httpPost(
          `ChatModule/v1.0/gateway/conversation/${state.selectedCaseConversation.id}/message/send`,
          formData,
        );

        setIsSendingMessage(false);
        dispatch({ type: MY_CASES_SET_AGENT_INPUT_TEXT, payload: '' });
        setAttachments([]);
        if (state.isComposingMessage) {
          toggleMessageWidget();
        }
      } catch (error: any) {
        alertMessage({
          body: error.response?.data?.message || error.message,
          type: 'error',
        });
        setIsSendingMessage(false);
      }
    }
  };

  const toggleMessageWidget = () => {
    dispatch({ type: MY_CASES_TOGGLE_MESSAGE_EDITOR, payload: null });
    setNewWidgetMessageBody('');
    setIsSendingMessage(false);
  };

  return (
    <Row style={{ margin: 10 }}>
      <Col sm={15} md={15} xl={17} xxl={19}>
        <InputGroup
          fill
          large
          type="text"
          inputRef={inputRef}
          style={{ border: 'none', boxShadow: 'none', borderRadius: 5 }}
          placeholder={isCaseOwn ? 'Chat text' : 'Chat is available only for case owners'}
          value={state.agentInputText}
          disabled={
            state.isAddingNote ||
            !state.selectedCase ||
            !state.selectedCaseConversation ||
            !isCaseOwn ||
            isSendingMessage ||
            isConversationClosed() ||
            caseIsSolvedOrClosed()
          }
          onChange={(e: any) => {
            dispatch({ type: MY_CASES_SET_AGENT_INPUT_TEXT, payload: e.target.value });
          }}
          // If enter key is pressed, send the message
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              sendChatMessage(state.agentInputText);
            }
          }}
        />
      </Col>
      <Col sm={9} md={9} xl={7} xxl={5} style={{ textAlign: 'right' }}>
        <MessageTemplateHandler
          context="MESSAGE"
          large
          record={state.selectedCaseContact}
          onTemplateSelected={onTemplateInsert}
          disabled={
            state.isAddingNote ||
            state.isComposingMessage ||
            !state.selectedCase ||
            !state.selectedCaseConversation ||
            isSendingMessage ||
            isConversationClosed() ||
            caseIsSolvedOrClosed()
          }
        />

        <FileUploaderPopoverButton
          large={true}
          disabled={
            state.isAddingNote ||
            !state.selectedCase ||
            state.isComposingEmail ||
            state.isComposingMessage ||
            !state.selectedCaseConversation ||
            isSendingMessage ||
            isConversationClosed() ||
            caseIsSolvedOrClosed()
          }
          files={attachments}
          setFiles={setAttachments}
        />
        <Button
          intent="primary"
          disabled={
            (!messageText.length && attachments.length === 0) ||
            state.isAddingNote ||
            state.isComposingMessage ||
            state.isComposingEmail ||
            isConversationClosed() ||
            !isCaseOwn
          }
          text="Send"
          loading={isSendingMessage}
          onClick={() => sendChatMessage(messageText)}
          large
          style={{ borderRadius: 5, marginLeft: 8 }}
        />
      </Col>

      {/* Email Widget */}
      {isModalOpen && (
        <div className="newMessageWidgetContainer">
          <Section
            compact
            className="newMessageWidgetSection"
            title="Compose Message"
            icon={<i className="bi bi-chat-dots " />}
            rightElement={
              <Button
                minimal
                icon="cross"
                style={{ opacity: isSendingMessage ? 0.3 : 1 }}
                onClick={() => {
                  if (!isSendingMessage) {
                    toggleMessageWidget();
                  }
                }}
              />
            }
          >
            <div
              style={{
                // background: 'green',
                height: 390,
                overflowY: 'auto',
                padding: 8,
              }}
            >
              <Row>
                <Col span={24}>
                  <div
                    key="messageWidgetTextAreaDiv"
                    style={{
                      // background: 'red',
                      height: 370,
                      overflowY: 'auto',
                      padding: '3px 1px',
                    }}
                  >
                    <TextArea
                      fill
                      autoFocus
                      id="emailTextArea"
                      key="emailTextArea"
                      inputRef={inputRef}
                      disabled={isSendingMessage}
                      placeholder={'Write your message here'}
                      value={newWidgetMessageBody}
                      onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setNewWidgetMessageBody(e.target.value);
                      }}
                      style={{ height: '100%', boxShadow: 'none', padding: 0 }}
                    />
                  </div>
                </Col>
              </Row>
            </div>
            <div
              style={{
                background: 'white',
                borderTop: '1px solid #D9DADA',
              }}
            >
              <Row
                style={{
                  padding: 10,
                  height: 50,
                }}
                align="middle"
              >
                {/* Send button / Attach Button */}
                <Col span={8}>
                  <Row>
                    <Col style={{ marginRight: 8 }}>
                      <Button
                        intent="primary"
                        text="Send"
                        onClick={() => sendChatMessage(newWidgetMessageBody)}
                        disabled={isSendingMessage || newWidgetMessageBody.length === 0}
                        loading={isSendingMessage}
                        style={{ borderRadius: 5 }}
                      />
                    </Col>
                    <Col style={{ marginRight: 8 }}>
                      <FileUploaderPopoverButton
                        disabled={isSendingMessage}
                        files={attachments}
                        setFiles={setAttachments}
                      />
                    </Col>
                    <Col>
                      <MessageTemplateHandler
                        context="EMAIL"
                        record={state.selectedCaseContact}
                        onTemplateSelected={onTemplateInsert}
                        disabled={isSendingMessage}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Section>
        </div>
      )}
    </Row>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(MyCasesMessageEditorWidget);
