import { AFPApprovalActions } from './index';
import * as FetcherService from '../../../../../../../../../core/hooks/fetchOne';
import * as MultiFetcherService from '../../../../../../../../../core/hooks/fetchMany';
import { AFPApprovalState } from '../types';

export const recordReducer = (
  state: AFPApprovalState['records'],
  action: AFPApprovalActions,
): AFPApprovalState['records'] => {
  switch (action.type) {
    // Load AFP
    case 'AFP_APPROVAL_LOAD_AFP_REQUEST':
      return {
        ...state,
        afp: FetcherService.getLoadingState(),
      };
    case 'AFP_APPROVAL_LOAD_AFP_RESPONSE':
      return {
        ...state,
        afp: FetcherService.getSuccessState(action.payload),
      };
    case 'AFP_APPROVAL_LOAD_AFP_ERROR':
      return {
        ...state,
        afp: FetcherService.getErrorState(action.payload),
      };

    // Load FPEs
    case 'AFP_APPROVAL_LOAD_ALL_FPES_REQUEST':
      return {
        ...state,
        allFpes: MultiFetcherService.getLoadingState(),
      };
    case 'AFP_APPROVAL_LOAD_ALL_FPES_RESPONSE':
      return {
        ...state,
        allFpes: MultiFetcherService.getSuccessState(action.payload),
      };
    case 'AFP_APPROVAL_LOAD_ALL_FPES_ERROR':
      return {
        ...state,
        allFpes: MultiFetcherService.getErrorState(action.payload),
      };

    // Load Feature
    case 'AFP_APPROVAL_LOAD_FEATURE_REQUEST':
      return {
        ...state,
        feature: FetcherService.getLoadingState(),
      };
    case 'AFP_APPROVAL_LOAD_FEATURE_RESPONSE':
      return {
        ...state,
        feature: FetcherService.getSuccessState(action.payload),
      };
    case 'AFP_APPROVAL_LOAD_FEATURE_ERROR':
      return {
        ...state,
        feature: FetcherService.getErrorState(action.payload),
      };

    default:
      return state;
  }
};
