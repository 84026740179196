import { useCallback, useRef, useState } from 'react';

export function useDebounceCallback(callback: (value: any) => void, timeout: number) {
  let timeoutRef = useRef<any>(null);
  const [loading, setLoading] = useState(false);

  const cancel = function () {
    if (!!timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  const debounceCallback = useCallback(
    (value) => {
      cancel();
      setLoading(true);
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        callback(value);
        setLoading(false);
      }, timeout);
    },
    [callback, timeout],
  );

  return [debounceCallback, cancel, loading] as const;
}
