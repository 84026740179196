import { Button, Space } from 'antd';
import { capitalCase } from 'change-case';
import { JSONTableActions } from './JSONTableActions';
import { JSONTableColumnOptions, JSONTableRowAction } from './types';

type JSONTableEventBinders = {
  getOnChangeHandler: (key: string, index: number) => (event: React.ChangeEvent<HTMLInputElement> | string) => void;
};

const defaultColumnRenderer = (text: string) => text ? String(text) : null;

const buildActionsColumn = (actions: JSONTableRowAction[]) => ({
  title: 'Action',
  dataIndex: '',
  key: 'action',
  fixed: 'right',
  render: (_text: string, record: any, index: number) => (
    actions.map(action => (<JSONTableActions title={action.title} onClick={() => action.handler(record, index)} />))
  ),
});

export const getTableColumns = (data: any[], {
  editableProperties = [],
  columnPositions = {},
  columnRenderers = {},
  editableColumnRenderers = {},
  rowActions = [],
}: JSONTableColumnOptions = {},
{ getOnChangeHandler }: JSONTableEventBinders) => {
  let columns: any[] = [];
  if (data?.length > 0) {
    const uniqueKeys: string[] = [];
    for (const item of data) {
      for (const key of Object.keys(item)) {
        if (!uniqueKeys.includes(key)) {
          uniqueKeys.push(key);
        }
      }
    }

    columns = uniqueKeys.map((key) => ({
      title: capitalCase(key),
      dataIndex: key,
      ellipsis: false,
      position: columnPositions[key] || 0,
      onFilter: (value: string, record: any) => record[key]?.includes(value),
      render: (text: string, record: any, index: number) => {
        const renderer = columnRenderers[key] || defaultColumnRenderer;
        // support for editable properties
        if (editableProperties.includes(key)) {
          const editableColumnRenderer = editableColumnRenderers[key]
          if (editableColumnRenderer) return editableColumnRenderer(text, record, getOnChangeHandler(key, index));
          return <input name={key} value={record[key]} onChange={getOnChangeHandler(key, index)} />
        } else {
          return renderer(text, record);
        }
      },
    }));

  }

  if (rowActions.length)
    columns.push(buildActionsColumn(rowActions))
  return columns;
};
