import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Row } from 'antd';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from 'react';
import './styles.scss';
import { toSentenceCase } from '@core/helpers/stringHelpers';
import MyCaseAttachmentList from '../MyCaseAttachmentList';
import DOMPurify from 'dompurify';

dayjs.extend(relativeTime);

interface Props {
  email: DbRecordEntityTransform;
}

const MyCasesEmail: React.FC<Props> = (props: Props) => {
  const { email } = props;

  const message = getProperty(email, 'Message');
  let formattedMessage = message?.replace(/\r\n|\r|\n/g, '<br />');

  const replaceLinksWithATags = (str: string) => {
    return str?.replace(/\b(?:https?:\/\/|www\.)[^\s<>\"]+\b/g, (url) => {
      const href = url?.startsWith('www.') ? `http://${url}` : url;
      return `<a href="${href}" target="_blank" rel="noopener noreferrer">${url}</a>`;
    });
  };
  formattedMessage = replaceLinksWithATags(formattedMessage);

  const sanitizedMessage = DOMPurify.sanitize(formattedMessage, { ADD_ATTR: ['target'] });
  let fileIds: string = getProperty(email, 'Files') || '';

  const senderType = getProperty(email, 'SenderType');

  return (
    <Row justify="end" style={{ padding: '5px 20px 5px 0' }}>
      <Col
        span={24}
        className={`myCasesUsersEmail ${senderType === 'CUSTOMER' ? 'customerEmail' : ''}`}
      >
        <Row>
          {/* Email Title & Toolbar */}
          <Col span={24}>
            <i className={'bi bi-envelope'} style={{ marginRight: 5 }} />
            <span style={{ fontWeight: 500 }}>{toSentenceCase(senderType)} Email</span>
          </Col>

          {/* From and Timestamp */}
          <Col span={14} style={{ marginTop: 5 }}>
            <span style={{ fontSize: '0.8em', fontWeight: 600 }}>{getProperty(email, 'From')}</span>
          </Col>
          <Col span={10} style={{ textAlign: 'right', marginTop: 5 }}>
            <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
              {dayjs(email.createdAt).format('ddd, MMM DD, HH:mm')} ({dayjs(email.createdAt).fromNow()})
            </span>
          </Col>
          <Col span={24}>
            <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
              To: {getProperty(email, 'To')}
            </span>
            {getProperty(email, 'Cc') && (
              <span style={{ fontSize: '0.8em', color: '#545A5D' }}>
                , Cc: {getProperty(email, 'Cc')}
              </span>
            )}
          </Col>

          {/* User / Timestamp */}
          <Col span={24} style={{ marginTop: 10 }}>
            <Col span={24}>
              <span style={{ fontWeight: 600 }}>{getProperty(email, 'Subject')}</span>
            </Col>
            <Col span={24} style={{ marginTop: 5, overflowWrap: 'break-word' }}>
              <span dangerouslySetInnerHTML={{ __html: sanitizedMessage }} />
            </Col>
          </Col>

          {/* Attachments */}
          {fileIds.length > 0 && (
            <Col span={24} style={{ marginTop: 15, marginBottom: 5 }}>
              <MyCaseAttachmentList fileIds={fileIds} thumbSize={60} justify="start" />
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default MyCasesEmail;
