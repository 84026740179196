import React from 'react';
import { Card, Col, Descriptions, Divider, Row, Tooltip } from 'antd';
import dayjs from 'dayjs';
import { isMobile } from 'react-device-detect';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  getFirstRelation,
  getProperty,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Button, Intent, Tag } from '@blueprintjs/core';

type RecordProps = {
  record: DbRecordEntityTransform;
  onClick?: React.MouseEventHandler;
  width?: 'full' | 'auto';
  compact?: boolean;
  weekend?: boolean;
};
export const WorkOrder: React.FC<RecordProps> = ({
  record,
  onClick,
  width = 'full',
  weekend,
  compact,
}) => {
  const serviceAppointment = getFirstRelation(record, 'ServiceAppointment');
  const engineerId = getProperty(record, 'EngineerId');
  const engineerName = engineerId ? getProperty(record, 'EngineerName') : '-';

  const truncateTitle = (str: string) => {
    const n = compact ? 12 : 22;

    return (
      <Tooltip title={str} mouseEnterDelay={0.8}>
        {str?.length > n ? str.substr(0, n) + '... ' + str.substr(str.length - n, str.length) : str}
      </Tooltip>
    );
  };

  // Record stage can be 'complete', 'in-progress','scheduled' or 'remediation-required'. Create a function that will return a color based on the stage.
  const getStageColor = (stage: string | undefined) => {
    switch (stage) {
      case 'complete':
        return Intent.SUCCESS;
      case 'in-progress':
        return Intent.WARNING;
      case 'scheduled':
        return Intent.PRIMARY;
      case 'remediation-required':
        return Intent.DANGER;
      default:
        return Intent.NONE;
    }
  };

  const confirmationStatus = getProperty(record, 'ConfirmationStatus') ?? '';
  const lg = width === 'full' ? 24 : 10;
  return (
    <Col sm={24} lg={lg} style={{ padding: 0 }}>
      <Card style={{ marginBottom: 10 }} className={`work-order`}>
        {/* Header */}
        <Row justify="space-between" className={`work-order ${weekend ? 'weekend' : ''}`}>
          <Col>
            <span className="work-order__number" style={{ fontWeight: 600 }}>
              #{record.recordNumber}
            </span>
          </Col>
          <Col style={{ padding: 0, textAlign: 'right' }}>
            {/* Show Description indicator */}
            {getProperty(record, 'Description') && (
              <Tooltip title="Description available" mouseEnterDelay={0.8}>
                <Tag style={{ marginRight: 8, padding: '4px 6px' }} minimal>
                  <i className="bi bi-chat-left-text" />
                </Tag>
              </Tooltip>
            )}

            {/* WO Stage */}
            <Tooltip title="Work order Stage" mouseEnterDelay={0.8}>
              <Tag
                minimal
                intent={getStageColor(record?.stage?.name?.toLowerCase().replace(/\s/g, '-'))}
                style={{
                  padding: 4,
                  marginRight: 8,
                }}
              >
                {record.stage?.name}
              </Tag>
            </Tooltip>
            <Button small icon="eye-open" onClick={onClick} />
          </Col>
        </Row>

        {/* Divider */}
        <Divider style={{ marginTop: 8, marginBottom: 7 }} />

        {/* Body */}
        <Row>
          {/* record title */}
          <Col span={24} style={{ marginBottom: 8 }}>
            <span className={`work-order__title ${compact && 'compact'}`}>
              {truncateTitle(record.title!)}
            </span>
          </Col>
          <Col span={24}>
            {Boolean(serviceAppointment) && (
              <Descriptions
                size="small"
                column={isMobile ? 2 : 1}
                layout={isMobile ? 'vertical' : 'horizontal'}
                bordered
                className="work-order__descriptions"
                labelStyle={{
                  padding: '3px 5px',
                  width: '20%',
                  fontSize: compact ? '0.8em' : '0.9em',
                }}
                contentStyle={{ padding: '3px 5px', fontSize: compact ? '0.8em' : '0.9em' }}
              >
                <Descriptions.Item label="Time">
                  {/* {dayjs(getProperty(serviceAppointment, 'Date')).format('DD/MM/YYYY')}
                  {' - '} */}
                  {getProperty(serviceAppointment, 'TimeBlock')}
                </Descriptions.Item>
                <Descriptions.Item label="Confirmation">
                  <span className={`work-order__confirmation ${confirmationStatus.toLowerCase()}`}>
                    {confirmationStatus}
                  </span>
                </Descriptions.Item>
              </Descriptions>
            )}
          </Col>
        </Row>
      </Card>
    </Col>
  );
};
