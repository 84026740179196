import React from "react";
import { Drawer } from "antd";

import { DbRecordEntityTransform } from "@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform";

import { DashboardLineItem, SubmissionStatus } from "../hooks/types";
import { groupLineItemsByWorkList } from "../utils";
import { SubmissionStatus as SubmissionStatusComponent } from "./SubmissionStatus";

type SubmissionStatusDrawerProps = {
  status: SubmissionStatus;
  onClose: () => void;
  selectedLineItems: DashboardLineItem[];
  onSubmissionSuccess: (
    afp: DbRecordEntityTransform,
    lineItems: DashboardLineItem[]
  ) => void;
  onSubmissionFailed: () => void;
};

export const SubmissionStatusDrawer = ({ status, onClose, onSubmissionSuccess, onSubmissionFailed, selectedLineItems }: SubmissionStatusDrawerProps) => {
  const lineItemsPerWorkList = groupLineItemsByWorkList(selectedLineItems);

  return (
    <Drawer
      destroyOnClose
      open={status !== 'idle'}
      onClose={onClose}
      title="Adding Entries to AFP"
    >
      <SubmissionStatusComponent
        lineItemsPerWorkList={lineItemsPerWorkList}
        onSubmissionSuccess={onSubmissionSuccess}
        onSubmissionFailed={onSubmissionFailed}
      />
    </Drawer>
  );
};
