import { Callout } from '@blueprintjs/core';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

import ClosureFiberSplicingTable from '../../../Autosplicing/components/ClosureFiberSplicingTable';
import { Card, Col, Row } from 'antd';
import { httpGet } from '../../../../../../core/http/requests';
import { useEffect, useState } from 'react';


const SplicingSummary = ({ feature }: { feature: DbRecordEntityTransform }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [splicingSummary, setSplicingSummary] = useState<any>(undefined);

  useEffect(() => {
    setLoading(true);
    // Get the closure splicing status
    if (feature) {
      httpGet(`ProjectModule/v1.0/WorkItem/${feature.id}/splicing-status`).then(res => {
        console.log(' res.data.data: ', res.data.data);
        setSplicingSummary(res.data.data);
        setLoading(false);
      }).catch(err => {
        setLoading(false);
      });
    }
  }, [feature]);

  return (
    <Card loading={loading}>
      <Row>
        <h3>Splicing Build & Payment Status</h3>
      </Row>
      <Row>
        <Col span={5} style={{ marginLeft: 8, marginRight: 8 }}>
          <Callout>
            <h5>Trays Used:</h5>
            <p>{splicingSummary?.trayCount}</p>
          </Callout>
        </Col>
        <Col span={5} style={{ marginLeft: 8, marginRight: 8 }}>
          <Callout style={{ marginBottom: 8 }}>
            <h5>Splices (Complete / Total):</h5>
            <p>{splicingSummary?.completedSplices} / {splicingSummary?.spliceCount}</p>
          </Callout>
        </Col>
        <Col span={5} style={{ marginLeft: 8, marginRight: 8 }}>
          <Callout style={{ marginBottom: 8 }}>
            <h5>Splitters (Complete / Total):</h5>
            <p>{splicingSummary?.completedSplitters} / {splicingSummary?.totalActualSplitters?.value}</p>
          </Callout>
        </Col>
      </Row>
      <Row>
        <Col span={5} style={{ marginLeft: 8, marginRight: 8 }}>
          <Callout>
            <h5>SPL0001 Pending:</h5>
            <p>{splicingSummary?.spl0001AdjustmentPendingQuantity + splicingSummary?.spl0001DefaultPendingQuantity}</p>
          </Callout>
        </Col>
        <Col span={5} style={{ marginLeft: 8, marginRight: 8 }}>
          <Callout>
            <h5>SPL0001 Paid:</h5>
            <p>{splicingSummary?.spl0001AdjustmentPaidQuantity + splicingSummary?.spl0001DefaultPaidQuantity} / {splicingSummary?.trayCount}</p>
          </Callout>
        </Col>
        <Col span={5} style={{ marginLeft: 8, marginRight: 8 }}>
          <Callout>
            <h5>SPL0002 Pending:</h5>
            <p>{splicingSummary?.spl0002AdjustmentPendingQuantity + splicingSummary?.spl0002DefaultPendingQuantity}</p>
          </Callout>
        </Col>
        <Col span={5} style={{ marginLeft: 8, marginRight: 8 }}>
          <Callout>
            <h5>SPL0002 Paid:</h5>
            <p>{splicingSummary?.spl0002AdjustmentPaidQuantity + splicingSummary?.spl0002DefaultPaidQuantity} / {splicingSummary?.totalActualSplitters?.value + splicingSummary?.spliceCount} </p>
          </Callout>
        </Col>
      </Row>
    </Card>
  );
};

export const FiberSplicing = ({ record }: { record: DbRecordEntityTransform }) => {
  if (record.type !== 'CLOSURE') return null;

  return (
    <>
      {!!record && <div style={{ marginBottom: 10 }}>
        <SplicingSummary feature={record} />
      </div>}
      <div style={{ marginBottom: 10 }}>
        <ClosureFiberSplicingTable
          record={record}
          externalRef={getProperty(record, 'ExternalRef')}
          // featureType={record?.type!}
        />
      </div>
    </>
  );
};
