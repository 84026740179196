/* Render Record management details for Detail View -> Left panel */

import { Col, Descriptions, Divider, Row } from 'antd';
import React from 'react';
import dayjs from 'dayjs';

import utc from 'dayjs/plugin/utc';
import { DateTime } from 'luxon'; // Import the UTC plugin

dayjs.extend(utc); // Extend Day.js with the UTC plugin


export function renderCreateUpdateDetails(record: any) {

  if (record) {
    return (
      <>
        <Divider style={{ marginTop: '7px', marginBottom: '10px' }} />
        <Row>
          <Col span={24}>
            <Descriptions
              size="small"
              layout="vertical"
              column={1}
              labelStyle={{ fontWeight: 500, color: 'black' }}
            >
              <Descriptions.Item label="Created by">
                {record?.createdBy ? (
                  <span>{record.createdBy!.fullName}</span>
                ) : (
                  <span>Unknown</span>
                )}{' '}
                <span style={{ marginLeft: 8 }}>
                  {DateTime.fromISO(record.createdAt).toFormat('M/d/yyyy h:mm a ZZZZ')}
                </span>
              </Descriptions.Item>
              <Descriptions.Item label="Updated by">
                {record?.lastModifiedBy ? (
                  <span>{record.lastModifiedBy!.fullName}</span>
                ) : (
                  <span>Unknown</span>
                )}
                <span style={{ marginLeft: 8 }}>
                  {/*{dayjs(record.updatedAt!).utc().format('M/D/YYYY h:mm A')}*/}
                  {/*@ts-ignore*/}
                  {DateTime.fromISO(record.updatedAt).toFormat('M/d/yyyy h:mm a ZZZZ')}
                </span>
              </Descriptions.Item>
            </Descriptions>
          </Col>
        </Row>
      </>
    );
  }
}
