import React from 'react';
import { Col, Divider, Row, Tooltip } from 'antd';
import { UtilizationData } from '../../../context/types';

type WorkOrderHeaderProps = {
  title: string;
  utilization?: UtilizationData;
  compact?: boolean;
  weekend?: boolean;
  filter?: React.ReactNode;
};

export const WorkOrderHeader: React.FC<WorkOrderHeaderProps> = ({
  title,
  utilization,
  compact,
  weekend = false,
  filter,
}) => {
  return (
    <Row className={`work-order-list__header ${weekend ? 'weekend' : ''}`}>
      {/* <Col span={16}>
        <span
          style={{
            padding: '4px 0',
            fontSize: '1rem',
          }}
        >
          {title}
        </span>
      </Col> */}
      {/* <Col span={8} style={{ textAlign: 'right' }}>
        <span
          style={{
            display: 'inline-block',
            margin: 10,
            color: 'rgb(23, 119, 255)',
            cursor: 'pointer',
          }}
        >
          {filter}
        </span>
      </Col> */}
      {title && utilization ? <Divider style={{ marginTop: 7, marginBottom: 10 }} /> : <></>}
      <Col span={24}>
        {utilization !== undefined && (
          <Row justify="space-between">
            <Col
              style={{ marginRight: !compact ? 20 : 'auto' }}
              className="work-order__utilization"
            >
              {/* Show tooltip on compact version */}
              <Tooltip title={compact && 'Utilization'} mouseEnterDelay={0.5}>
                <span style={{ fontWeight: 600 }}>{compact ? 'Util: ' : 'Utilization: '}</span>
                <span style={{ fontWeight: 400 }}>
                  {utilization?.Utilization ? utilization.Utilization.toFixed(2) + '%' : '-'}
                </span>
              </Tooltip>
            </Col>
            <Col
              style={{ marginRight: !compact ? 20 : 'auto' }}
              className="work-order__utilization"
            >
              {/* Show tooltip on compact version */}
              <Tooltip title={compact && 'Remaining AM'} mouseEnterDelay={0.5}>
                <span style={{ fontWeight: 600 }}>{compact ? 'Rem. AM: ' : 'Remaining AM: '}</span>
                <span style={{ fontWeight: 400 }}>{utilization?.AMRemaining ?? '-'}</span>
              </Tooltip>
            </Col>
            <Col className="work-order__utilization">
              {/* Show tooltip on compact version */}
              <Tooltip title={compact && 'Remaining PM'} mouseEnterDelay={0.5}>
                <span style={{ fontWeight: 600 }}>{compact ? 'Rem. PM: ' : 'Remaining PM: '}</span>
                <span style={{ fontWeight: 400 }}>{utilization?.PMRemaining ?? '-'}</span>
              </Tooltip>
            </Col>
          </Row>
        )}
      </Col>
    </Row>
  );
};
