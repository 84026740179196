import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import {
  SET_ACTIVE_STATUS_TAB,
  SET_ALL_RECORD_STATUSES,
  SET_ASSOCIATED_DATASET_RECORDS,
  SET_DATASET_RECORD,
  SET_DATASET_SEARCH_TERM,
  SET_IS_SEARCHING_DATASET_ASSOCIATIONS,
  SET_SELECTED_RECORD,
} from './constants';

interface IDataSetWorkReducer {
  selectedRecord: DbRecordEntityTransform | undefined;
  dataSetRecord: DbRecordEntityTransform | undefined;
  associatedRecords: DbRecordEntityTransform[];
  isSearchingDataSetAssociations: boolean;
  dataSetSearchTerm: string;
  activeStatusTab: string | undefined;
  allRecordStatuses: string[];
}

export const dataSetWorkInitialState: IDataSetWorkReducer = {
  dataSetRecord: undefined,
  selectedRecord: undefined,
  associatedRecords: [],
  isSearchingDataSetAssociations: false,
  dataSetSearchTerm: '',
  activeStatusTab: undefined,
  allRecordStatuses: [],
};

export function dataSetWorkReducer(
  state: IDataSetWorkReducer,
  action: { type: string; payload: any },
) {
  switch (action.type) {
    case SET_SELECTED_RECORD:
      return {
        ...state,
        selectedRecord: action.payload,
      };

    case SET_ACTIVE_STATUS_TAB:
      return {
        ...state,
        activeStatusTab: action.payload,
      };

    case SET_ASSOCIATED_DATASET_RECORDS:
      return {
        ...state,
        associatedRecords: action.payload,
      };

    case SET_DATASET_SEARCH_TERM:
      return {
        ...state,
        dataSetSearchTerm: action.payload,
      };

    case SET_IS_SEARCHING_DATASET_ASSOCIATIONS:
      return {
        ...state,
        isSearchingDataSetAssociations: action.payload,
      };
    case SET_DATASET_RECORD:
      return {
        ...state,
        dataSetRecord: action.payload,
      };

    case SET_ALL_RECORD_STATUSES:
      return {
        ...state,
        allRecordStatuses: action.payload,
      };

    default:
      throw new Error();
  }
}
