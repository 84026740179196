import { ExclamationCircleOutlined } from '@ant-design/icons';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Input, Modal, notification, Row } from 'antd';
import axios from 'axios';
import isHotkey from 'is-hotkey';
import React, { useCallback, useMemo, useState } from 'react';
import { createEditor, Descendant, Editor, Element as SlateElement, Transforms } from 'slate';
import { withHistory } from 'slate-history';
import { Editable, ReactEditor, Slate, useFocused, useSelected, useSlate, useSlateStatic, withReact } from 'slate-react';
import { getHostName } from '@core/http/helpers';
import { httpGet } from '@core/http/requests';
import { Button, Icon, Toolbar } from './SlateRichEditorComponents';
import './style.scss';

const LIST_TYPES = ['numbered-list', 'bulleted-list'];
const HOTKEYS: any = {
  'mod+b': 'bold',
  'mod+i': 'italic',
  'mod+u': 'underline',
  'mod+`': 'code',
};

interface Props {
  onChange?: any;
  initialValue?: any;
  isViewMode?: boolean;
  associatedRecordId?: string;
  toggleFileUploadLoader?: Function;
  isUploadingFile?: boolean;
  associatedRecordEntity: string;
}

const openNotification = (title: string, body: string) => {
  notification.open({
    message: title,
    description: body,
    icon: <ExclamationCircleOutlined style={{ color: '#108ee9' }} />,
  });
};

function SlateRichText(props: Props) {
  const initialValue: Descendant[] = [
    {
      type: 'paragraph',
      children: [{ text: '' }],
    },
  ];

  const {
    isViewMode,
    associatedRecordId,
    toggleFileUploadLoader,
    isUploadingFile,
    associatedRecordEntity,
  } = props;
  const editor = useMemo(
    () =>
      withImages(
        withHistory(withReact(createEditor())),
        associatedRecordId!,
        toggleFileUploadLoader!,
        associatedRecordEntity,
      ),
    [],
  );
  const [value, setValue] = useState<Descendant[]>(props.initialValue || initialValue);
  const renderElement = useCallback(
    (props) => <Element {...props} isViewMode={isViewMode} />,
    [isViewMode],
  );
  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const handleChange = (value: any) => {
    setValue(value);
    if (props.onChange) {
      props.onChange(value);
    }
  };

  return (
    <div style={{ opacity: isUploadingFile ? 0.1 : 1 }}>
      <Slate editor={editor} initialValue={value} onChange={handleChange}>
        {!props.isViewMode && (
          <Toolbar>
            <MarkButton format="bold" icon="format_bold" />
            <MarkButton format="italic" icon="format_italic" />
            <MarkButton format="underline" icon="format_underlined" />
            <MarkButton format="code" icon="code" />
            <BlockButton format="heading-one" icon="looks_one" />
            <BlockButton format="heading-two" icon="looks_two" />
            <BlockButton format="block-quote" icon="format_quote" />
            <BlockButton format="numbered-list" icon="format_list_numbered" />
            <BlockButton format="bulleted-list" icon="format_list_bulleted" />
            <InsertLinkButton />
            <InsertImageButton />
          </Toolbar>
        )}
        <Editable
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          className={'slate-rich-editor'}
          readOnly={props.isViewMode}
          onKeyDown={(event) => {
            for (const hotkey in HOTKEYS) {
              if (isHotkey(hotkey, event as any)) {
                event.preventDefault();
                const mark = HOTKEYS[hotkey];
                toggleMark(editor, mark);
              }
            }
          }}
        />
      </Slate>
    </div>
  );
}

const MarkButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <Button
      active={isMarkActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      <Icon className="editorButton">{icon}</Icon>
    </Button>
  );
};

const BlockButton = ({ format, icon }: any) => {
  const editor = useSlate();
  return (
    <Button
      active={isBlockActive(editor, format)}
      onMouseDown={(event: any) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      <Icon className="editorButton">{icon}</Icon>
    </Button>
  );
};

const InsertImageButton = () => {
  const [urlModalVisible, setUrlModalVisible] = useState<boolean>(false);
  const [enteredUrl, setEnteredUrl] = useState<string>('');
  const editor = useSlateStatic();

  const insertImageFromModal = () => {
    if (enteredUrl && isImageUrl(enteredUrl)) {
      insertImage(editor, enteredUrl, null);
      setUrlModalVisible(false);
      setEnteredUrl('');
    } else {
      alert('Entered Image URL is not valid');
      setUrlModalVisible(false);
    }
  };

  return (
    <>
      <Button
        onMouseDown={(event: any) => {
          event.preventDefault();
          setUrlModalVisible(true);
        }}
      >
        <Icon className="editorButton">image</Icon>
      </Button>
      <Modal
        title="Insert Image with URL"
        style={{ top: 20 }}
        open={urlModalVisible}
        onOk={() => insertImageFromModal()}
        onCancel={() => {
          setEnteredUrl('');
          setUrlModalVisible(false);
        }}
      >
        <Row style={{ padding: 10 }}>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Input
              placeholder="Image URL"
              value={enteredUrl}
              onChange={(e: any) => setEnteredUrl(e.target.value)}
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const InsertLinkButton = () => {
  const [urlModalVisible, setUrlModalVisible] = useState<boolean>(false);
  const [enteredUrl, setEnteredUrl] = useState<string>('');
  const [enteredLinkText, setEnteredLinkText] = useState<string>('');
  const editor = useSlateStatic();

  const insertLinkFromModal = () => {
    if (enteredUrl && isUrl(enteredUrl)) {
      insertLink(editor, enteredUrl, enteredLinkText);
      setUrlModalVisible(false);
      setEnteredLinkText('');
      setEnteredUrl('');
    } else {
      alert('Entered URL is not valid');
      setUrlModalVisible(false);
    }
  };

  return (
    <>
      <Button
        onMouseDown={(event: any) => {
          event.preventDefault();
          setUrlModalVisible(true);
        }}
      >
        <Icon className="editorButton">link</Icon>
      </Button>
      <Modal
        title="Insert Link"
        style={{ top: 20 }}
        open={urlModalVisible}
        onOk={() => insertLinkFromModal()}
        onCancel={() => {
          setEnteredLinkText('');
          setEnteredUrl('');
          setUrlModalVisible(false);
        }}
      >
        <Row style={{ padding: 10 }}>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Input
              addonBefore="https://"
              value={enteredUrl}
              onChange={(e: any) => setEnteredUrl(e.target.value)}
            />
          </Col>
          <Col span={24}>
            <Input
              value={enteredLinkText}
              onChange={(e: any) => setEnteredLinkText(e.target.value)}
              placeholder="Link Text in Article."
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};

const isBlockActive = (editor: any, format: any) => {
  const { selection } = editor;
  if (!selection) {
    return false;
  }

  const [match] = Editor.nodes(editor, {
    at: Editor.unhangRange(editor, selection),
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === format,
  });

  return !!match;
};

const toggleBlock = (editor: any, format: any) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && LIST_TYPES.includes(n.type),
    split: true,
  });
  const newProperties: Partial<SlateElement> = {
    type: isActive ? 'paragraph' : isList ? 'list-item' : format,
  };
  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const toggleMark = (editor: any, format: any) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const isMarkActive = (editor: any, format: any) => {
  const marks: any = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

const Element = (props: any) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case 'block-quote':
      return <blockquote {...attributes}>{children}</blockquote>;
    case 'bulleted-list':
      return <ul {...attributes}>{children}</ul>;
    case 'heading-one':
      return <h1 {...attributes}>{children}</h1>;
    case 'heading-two':
      return <h2 {...attributes}>{children}</h2>;
    case 'list-item':
      return <li {...attributes}>{children}</li>;
    case 'numbered-list':
      return <ol {...attributes}>{children}</ol>;
    case 'image':
      return <Image {...props} />;
    case 'link':
      return <Link {...props} />;
    default:
      return <p {...attributes}>{children}</p>;
  }
};

const Image = ({ attributes, children, element, isViewMode }: any) => {
  const editor = useSlateStatic();
  const path = ReactEditor.findPath(editor, element);
  const selected = useSelected();
  const focused = useFocused();

  return (
    <div {...attributes}>
      {children}
      <div contentEditable={false}>
        <img
          src={element.url}
          className="knowledgeArticleImage"
          style={{
            border: selected && focused ? '1px solid #1890ff' : '1px solid white',
          }}
        />
        {!isViewMode && selected && focused ? (
          <Button
            active
            onClick={() => Transforms.removeNodes(editor, { at: path })}
            style={{
              display: focused ? 'block' : 'none',
              position: 'absolute',
              top: 10,
              left: 0,
            }}
          >
            <Icon style={{ margin: 0 }}>delete</Icon> Delete Image
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

const Leaf = ({ attributes, children, leaf }: any) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

const withImages = (
  editor: any,
  associatedRecordId: string,
  toggleFileUploadLoader: Function,
  associatedRecordEntity: string,
) => {
  const { insertData, isVoid } = editor;

  editor.isVoid = (element: any) => {
    return element.type === 'image' ? true : isVoid(element);
  };

  editor.insertData = (data: any) => {
    const text = data.getData('text/plain');
    const { files } = data;

    if (files && files.length > 0) {
      for (const file of files) {
        const reader = new FileReader();
        const [mime] = file.type.split('/');

        if (mime === 'image') {
          reader.addEventListener('load', async () => {
            // Try to parse image, upload to S3, return image URL and insert the URL into Editor.
            try {
              toggleFileUploadLoader(true);
              const formData = new FormData();
              formData.append('file', file);

              await axios({
                method: 'POST',
                url: `${getHostName()}/SchemaModule/v1.0/s3/files/${associatedRecordEntity}/${associatedRecordId!}/upload`,
                data: formData,
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('token')}`,
                  'Content-Type': 'multipart/form-data',
                },
              }).then(async (res) => {
                const uploadedFileRecord = await httpGet(
                  `SchemaModule/v1.0/db/File/${res.data.data.id}`,
                );

                if (uploadedFileRecord && uploadedFileRecord?.data?.data) {
                  const fileRecord = uploadedFileRecord.data.data;
                  const url = getProperty(fileRecord, 'Url');
                  insertImage(editor, url, fileRecord.id);
                  toggleFileUploadLoader(false);
                }
              });
            } catch (e) {
              openNotification(
                'Warning',
                'Sorry, there was an error uploading your image to the' + ' Editor.',
              );
            }
          });

          reader.readAsDataURL(file);
        }
      }
    } else if (isImageUrl(text)) {
      insertImage(editor, text, null);
    } else {
      insertData(data);
    }
  };

  return editor;
};

const insertImage = (editor: any, url: any, recordId: string | null) => {
  const text = { text: '' };

  const image: any = [
    {
      type: 'image',
      url,
      children: [text],
      recordId: recordId,
    },
  ];
  Transforms.insertNodes(editor, image);

  // Add empty paragraph after image.
  Editor.insertNode(editor, {
    type: 'paragraph',
    children: [{ text: '' }],
  });
};
const isImageUrl = (url: any) => {
  if (!url) {
    return false;
  }
  if (!isUrl(url)) {
    return false;
  }
  const ext = new URL(url).pathname.split('.').pop() || '';
  return ['png', 'jpg', 'jpeg', 'webp'].includes(ext);
};

const isUrl = (str: string) => {
  return str.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g,
  );
};

const createLinkNode = (href: string, text: string) => ({
  type: 'link',
  href,
  children: [{ text }],
});

const insertLink = (editor: any, url: string, linkText: string) => {
  const { selection } = editor;
  const link: any = createLinkNode(url, linkText ? linkText : url);

  ReactEditor.focus(editor);

  if (!!selection)
    Editor.insertNode(editor, {
      type: 'paragraph',
      children: [link],
    });
};

const Link = ({ attributes, element, children }: any) => (
  <a {...attributes} href={'https://' + element.href} target="_blank">
    {children}
  </a>
);

export default SlateRichText;
