import { AppstoreOutlined, CaretDownOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Empty, Row, Radio, Dropdown, Menu } from 'antd';
import Button from 'antd/es/button';
import { FunctionComponent, useState } from 'react';
import FileTypeUploaderDragAndDrop from '@legacy/core/records/components/Files/FileTypeUploaderDragAndDrop';
import FileManagerOverview from '@legacy/core/recordsAssociations/components/FileManagerOverview/FileManagerOverview';
import { AssociatedFilesDisplayType } from '../../types';
import { connect } from 'react-redux';
import { openImportFilesDrawer } from '@legacy/core/userInterface/store/actions';
import { IImportFiles } from '@legacy/core/userInterface/store/types';
import './styles.scss';

interface OwnProps {
  files: Array<DbRecordEntityTransform>;
  schemaType: string;
  record: DbRecordEntityTransform;
  dataSource: any;
  columns: any;
  disableUpload?: boolean;
  displayType?: AssociatedFilesDisplayType;
  editableFileFields?: Array<string>;
  showFileCategoryForType?: string | undefined;
  thumbnailSize?: number;
  openImportDrawer: Function;
}

type Props = OwnProps;

const FileByTypeAssociation: FunctionComponent<Props> = (props) => {
  const { files, schemaType, record, disableUpload, openImportDrawer } = props;

  const [fileView, setFileView] = useState<AssociatedFilesDisplayType>(props.displayType || 'list');

  const filterDataColumnsByType = (dataColumns: Array<any>) => {
    if (dataColumns?.length > 0) {
      return dataColumns.filter((column: any) => column.type === schemaType);
    }
  };

  const filteredFilesByType = () => {
    if (files?.length > 0 && schemaType) {
      return files?.filter((record: DbRecordEntityTransform) => schemaType === record.type);
    } else {
      return [];
    }
  };

  const renderAssociatedFileCards = () => {
    const viewOptions = [
      { label: <UnorderedListOutlined />, value: 'list' },
      { label: <AppstoreOutlined />, value: 'thumbnails' },
    ];

    return (
      <Col span={24} style={{ padding: 10 }}>
        <Row style={{ marginBottom: 10 }}>
          <Col span={12}>
            <Dropdown
              trigger={['click']}
              overlay={
                <Menu>
                  <Menu.Item
                    key="Import Files"
                    onClick={() => openImportDrawer({ type: schemaType, parentRecord: record! })}
                  >
                    Import Files
                  </Menu.Item>
                </Menu>
              }
            >
              <Button
                icon={<CaretDownOutlined style={{ fontSize: '0.9em' }} />}
                size="small"
                type="default"
              />
            </Dropdown>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}>
            {/* Button - Gallery */}
            <Radio.Group
              disabled={filteredFilesByType()?.length === 0}
              size="small"
              options={viewOptions}
              onChange={(e: any) => {
                setFileView(e.target.value);
              }}
              value={fileView}
              optionType="button"
            />
          </Col>
        </Row>
        {/* File Type Upload Body */}
        <Row>
          {/* Upload area */}
          {disableUpload ? (
            <></>
          ) : (
            <Col span={24} style={{ marginBottom: 10 }}>
              <FileTypeUploaderDragAndDrop record={record} fileSchemaType={schemaType} />
            </Col>
          )}

          {/* Display Files Area */}
          <Col span={24}>
            {filteredFilesByType().length > 0 ? (
              <FileManagerOverview
                hideViewOptions
                files={filteredFilesByType()} // Filter this by type
                thumbnailSize={props.thumbnailSize}
                dataSource={filterDataColumnsByType(props.dataSource)}
                columns={props.columns}
                fileView={fileView}
                editableFileFields={props.editableFileFields}
                showFileCategoryForType={props.showFileCategoryForType}
              />
            ) : (
              <Col span={24} style={{ textAlign: 'center', margin: '60px 0' }}>
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={<span>No Files to show</span>}
                />
              </Col>
            )}
          </Col>
        </Row>
      </Col>
    );

    // return (
    //   <Col span={24} style={{ padding: 10 }}>
    //     <Card
    //       className="fileTypeCard"
    //       title={
    //         <Row>
    //           {/* <Col xs={24} lg={16}> */}
    //           {/* <FileOutlined style={{ marginRight: 7 }} />
    //             <span>Files ({schemaType})</span> */}
    //           {/* </Col> */}

    //           <Col span={24} style={{ textAlign: 'right' }}>
    //             {/* Button - Gallery */}
    //             <Radio.Group
    //               disabled={filteredFilesByType()?.length === 0}
    //               size="small"
    //               options={viewOptions}
    //               onChange={(e: any) => {
    //                 setFileView(e.target.value);
    //               }}
    //               value={fileView}
    //               optionType="button"
    //             />
    //           </Col>
    //         </Row>
    //       }
    //     >
    //       {/* File Type Upload Body */}
    //       <Row>
    //         {/* Upload area */}
    //         {disableUpload ? (
    //           <></>
    //         ) : (
    //           <Col span={24} style={{ marginBottom: 10 }}>
    //             <FileTypeUploaderDragAndDrop record={record} fileSchemaType={schemaType} />
    //           </Col>
    //         )}

    //         {/* Display Files Area */}
    //         <Col span={24}>
    //           {filteredFilesByType().length > 0 ? (
    //             <FileManagerOverview
    //               hideViewOptions
    //               files={filteredFilesByType()} // Filter this by type
    //               thumbnailSize={props.thumbnailSize}
    //               dataSource={filterDataColumnsByType(props.dataSource)}
    //               columns={props.columns}
    //               fileView={fileView}
    //               editableFileFields={props.editableFileFields}
    //               showFileCategoryForType={props.showFileCategoryForType}
    //             />
    //           ) : (
    //             <Col span={24} style={{ textAlign: 'center', margin: '60px 0' }}>
    //               <Empty
    //                 image={Empty.PRESENTED_IMAGE_SIMPLE}
    //                 description={<span>No Files to show</span>}
    //               />
    //             </Col>
    //           )}
    //         </Col>
    //       </Row>
    //     </Card>
    //   </Col>
    //);
  };

  return <Row>{renderAssociatedFileCards()}</Row>;
};

const mapState = (state: any) => ({
  userInterfaceReducer: state.userInterfaceReducer,
});

const mapDispatch = (dispatch: any) => ({
  openImportDrawer: (payload: IImportFiles) => dispatch(openImportFilesDrawer(payload)),
});

export default connect(mapState, mapDispatch)(FileByTypeAssociation);
