import { Col } from 'antd';
import React, { FunctionComponent } from 'react';
import {
  IAutosplicingGraphRefs,
  IAutosplicingReducer,
} from '../../../store/types';
import '../../../styles.scss';

interface OwnProps {
  autosplicingReducer: IAutosplicingReducer;
  updateAutosplicingReducer: Function;
  graphRefs: IAutosplicingGraphRefs;
}

type Props = OwnProps;

const CosmosGraph: FunctionComponent<Props> = (props) => {
  const { autosplicingReducer, graphRefs } = props;
  const { loadingIndicators, allJobs } = autosplicingReducer;
  const { cosmosGraphMinimapRef, cosmosGraphRef } = graphRefs;

  return (
    <Col
      span={autosplicingReducer.diagramLayout === 'COSMOS' ? 24 : 12}
      style={{
        display:
          allJobs!.length > 0 ||
          loadingIndicators?.isLoadingTemplatesError ||
          !autosplicingReducer.latestData?.length ||
          autosplicingReducer.diagramLayout === 'ODIN'
            ? 'none'
            : 'block',
      }}
    >
      <div
        className={`cosmosGraphContainer ${autosplicingReducer.historicData?.length! > 0 ?'historic': ''}`}
        style={{
          overflow: 'hidden',
          height: '82vh',
          padding: 0,
          display:
            loadingIndicators?.isLoadingTemplatesError ||
            !autosplicingReducer.latestData?.length ||
            autosplicingReducer.diagramLayout === 'ODIN'
              ? 'none'
              : 'block',
        }}
        ref={cosmosGraphRef}
      >
        <div className="cosmosGraphMinimap" ref={cosmosGraphMinimapRef} />
      </div>
    </Col>
  );
};

export default CosmosGraph;
