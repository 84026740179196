import * as afpActions from './afp';
import * as allFpesActions from './allFpes';
import * as featureActions from './feature';
import { fpeSelectionActions } from './fpeSelection';

import { combineActions } from './utils';

type AFPActionKeys = keyof typeof afpActions;
type AllFPESActionKeys = keyof typeof allFpesActions;
type FeatureActionKeys = keyof typeof featureActions;
type FPESelectionActionKeys = keyof typeof fpeSelectionActions;

type AllActionsKeys =
  | AFPActionKeys
  | AllFPESActionKeys
  | FeatureActionKeys
  | FPESelectionActionKeys;

export const actions = combineActions<AllActionsKeys>(
  afpActions,
  allFpesActions,
  featureActions,
  fpeSelectionActions,
);

export type AFPApprovalActions = typeof actions;
