import { combineReducers } from 'redux';
import autosplicingReducer from '../com.netomnia/modules/ProjectModule/Autosplicing/store/reducer';
import premiseReducer from '@legacy/modules/CrmModule/containers/Premise/store/reducer';
import appointmentReducer from '@legacy/core/appointments/store/reducer';
import mapReducer from '@netomnia/modules/ProjectModule/Map/store/reducer';
import { USER_LOGOUT_REQUEST } from '@legacy/core/identity/store/constants';
import userReducer from '@legacy/core/identity/store/reducers';
import identityGroupsReducer from '@legacy/core/identityGroups/store/reducer';
import identityRbacPermissionReducer from '@legacy/core/identityPermissions/store/reducer';
import identityRbacRoleReducer from '@legacy/core/identityRoles/store/reducer';
import identityUserReducer from '@legacy/core/identityUser/store/reducer';
import navigationReducer from '@legacy/core/navigation/store/reducer';
import emailNotificationReducer from '@legacy/core/notifications/email/store/reducer';
import pipelineReducer from '@legacy/core/pipelines/store/reducer';
import auditLogsReducer from '@legacy/core/records/auditLogs/store/reducer';
import queryBuilderReducer from '@legacy/core/records/components/DynamicTable/QueryBuilder/store/reducer';
import recordTableReducer from '@legacy/core/records/components/DynamicTable/store/reducer';
import recordFormReducer from '@legacy/core/records/components/Forms/store/reducer';
import recordReducer from '@legacy/core/records/store/reducer';
import swapModalReducer from '@legacy/core/recordsAssociations/components/SwapModal/store/reducer';
import recordAssociationReducer from '@legacy/core/recordsAssociations/store/reducer';
import reportReducer from '@legacy/core/reporting/store/reducer';
import schemaReducer from '@legacy/core/schemas/store/reducer';
import schemaAssociationReducer from '@legacy/core/schemasAssociations/store/reducer';
import schemaColumnReducer from '@legacy/core/schemasColumns/store/reducer';
import serviceReducer from '@legacy/core/service/store/reducer';
import userAuditReducer from '@legacy/core/userAudit/store/reducer';
import userInterfaceReducer from '@legacy/core/userInterface/store/reducer';
import workflowReducer from '@legacy/core/workflow/store/reducer';
import processWorkflowFormReducer from '@legacy/core/workflowEngine/components/ProcessWorkflow/store/reducer';
import workflowEngineReducer from '@legacy/core/workflowEngine/store/reducer';
import formReducer from '@legacy/components/SharedForm/store/reducer';
import stepViewReducer from '@legacy/components/StepView/store/reducer';
import messageReducer from '@legacy/core/messages/store/reducers';
import notificationReducer from '@legacy/core/notifications/store/reducers';

const rootReducer = combineReducers({
  autosplicingReducer,
  userReducer,
  notificationReducer,
  messageReducer,
  premiseReducer,
  schemaReducer,
  schemaColumnReducer,
  schemaAssociationReducer,
  recordReducer,
  recordFormReducer,
  recordAssociationReducer,
  pipelineReducer,
  auditLogsReducer,
  reportReducer,
  recordTableReducer,
  emailNotificationReducer,
  appointmentReducer,
  queryBuilderReducer,
  navigationReducer,
  formReducer,
  stepViewReducer,
  serviceReducer,
  mapReducer,
  identityUserReducer,
  identityRbacRoleReducer,
  identityGroupsReducer,
  identityRbacPermissionReducer,
  swapModalReducer,
  workflowReducer,
  workflowEngineReducer,
  processWorkflowFormReducer,
  userAuditReducer,
  userInterfaceReducer,
});

// Handle cleanup / reset persisted state on logout
const appReducer = (state, action) => {
  let newState = state;

  if (action.type === USER_LOGOUT_REQUEST) {
    sessionStorage.clear();
    localStorage.removeItem('token');
    localStorage.removeItem('originalToken');
    localStorage.removeItem('expiresIn');
    localStorage.removeItem('timestamp');
    localStorage.removeItem(`${import.meta.env.VITE_ODIN_REDUX_STORE_NAME}`);
    newState = undefined;
    newState = {
      navigationReducer: state.navigationReducer,
      schemaReducer: state.schemaReducer,
    };
  }
  return rootReducer(newState, action);
};

export default appReducer;
