import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Divider, Row, Spin } from 'antd';
import { FC, useContext } from 'react';
import { uploadFileFromAssociationContext } from '../index';
import './styles.scss';
import folderImg from '@core/assets/images/png/folder-3.png';
import { SET_SELECTED_RECORD } from '../store/constants';
import { isMobile } from 'react-device-detect';

interface Props {
}

export const DirectoryList: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(uploadFileFromAssociationContext);

  const browserListHeader = () => {
    return (
      <>
        <Row align="middle" style={{ fontWeight: 500, background: '#fafafa', padding: '5px' }}>
          <Col span={2} />
          <Col span={10}>
            <span>Type</span>
          </Col>
          <Col span={12}>
            <span>Record #</span>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Divider style={{ marginTop: 0, marginBottom: 15 }} />
          </Col>
        </Row>
      </>
    );
  };

  const renderDirectoryStructure = () => {
    let filteredDirectories = Object.assign(state.relatedRecords);

    // Apply Quick search term to record title
    if (state.searchTerm.length > 0) {
      filteredDirectories = filteredDirectories.filter(
        (dir: any) => dir.recordNumber?.indexOf(state.searchTerm) > -1,
      );
    }
    if (!state.loadingParentAssociations) {
      return filteredDirectories?.map((record: DbRecordEntityTransform, i: number) => (
        <>
          <Row
            align="middle"
            className="directory"
            style={{ paddingTop: isMobile ? 5 : 0, paddingBottom: isMobile ? 5 : 0 }}
            onClick={() => {
              dispatch({ type: SET_SELECTED_RECORD, payload: record });
            }}
          >
            <Col span={2}>
              <img src={folderImg} style={{ width: '70%' }} />
            </Col>
            <Col span={10}>{record.type || '-'}</Col>
            <Col span={12}>{record.recordNumber || '-'}</Col>
          </Row>
          {filteredDirectories?.length > i + 1 ? (
            <Row>
              <Col span={24}>
                <Divider style={{ marginTop: 0, marginBottom: 0 }} />
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </>
      ));
    } else {
      return (
        <Row>
          <Col span={24} style={{ textAlign: 'center', padding: 80 }}>
            <Spin size="large" />
          </Col>
        </Row>
      );
    }
  };

  return (
    <div>
      {state.relatedRecords.length > 0 && !state.selectedRecord || state.loadingParentAssociations ? (
        <>
          {browserListHeader()}
          {renderDirectoryStructure()}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
