export const GET_SIPWISE_FULL_PROFILE_REQUEST = 'GET_SIPWISE_FULL_PROFILE_REQUEST';
export const GET_SIPWISE_FULL_PROFILE_SUCCESS = 'GET_SIPWISE_FULL_PROFILE_SUCCESS';
export const GET_SIPWISE_FULL_PROFILE_ERROR = 'GET_SIPWISE_FULL_PROFILE_ERROR';

export const GET_SIPWISE_CUSTOMER_CONTACT_REQUEST = 'GET_SIPWISE_CUSTOMER_CONTACT_REQUEST';
export const GET_SIPWISE_CUSTOMER_CONTACT_SUCCESS = 'GET_SIPWISE_CUSTOMER_CONTACT_SUCCESS';
export const GET_SIPWISE_CUSTOMER_CONTACT_ERROR = 'GET_SIPWISE_CUSTOMER_CONTACT_ERROR';

export const DELETE_SIPWISE_CUSTOMER_CONTACT_REQUEST = 'DELETE_SIPWISE_CUSTOMER_CONTACT_REQUEST';
export const DELETE_SIPWISE_CUSTOMER_CONTACT_SUCCESS = 'DELETE_SIPWISE_CUSTOMER_CONTACT_SUCCESS';
export const DELETE_SIPWISE_CUSTOMER_CONTACT_ERROR = 'DELETE_SIPWISE_CUSTOMER_CONTACT_ERROR';

export const GET_SIPWISE_CUSTOMER_REQUEST = 'GET_SIPWISE_CUSTOMER_REQUEST';
export const GET_SIPWISE_CUSTOMER_SUCCESS = 'GET_SIPWISE_CUSTOMER_SUCCESS';
export const GET_SIPWISE_CUSTOMER_ERROR = 'GET_SIPWISE_CUSTOMER_ERROR';

export const DELETE_SIPWISE_CUSTOMER_REQUEST = 'DELETE_SIPWISE_CUSTOMER_REQUEST';
export const DELETE_SIPWISE_CUSTOMER_SUCCESS = 'DELETE_SIPWISE_CUSTOMER_SUCCESS';
export const DELETE_SIPWISE_CUSTOMER_ERROR = 'DELETE_SIPWISE_CUSTOMER_ERROR';

export const GET_SUBSCRIBER_REQUEST = 'GET_SUBSCRIBER_REQUEST';
export const GET_SUBSCRIBER_SUCCESS = 'GET_SUBSCRIBER_SUCCESS';
export const GET_SUBSCRIBER_ERROR = 'GET_SUBSCRIBER_ERROR';

export const DELETE_SIPWISE_SUBSCRIBER_REQUEST = 'DELETE_SIPWISE_SUBSCRIBER_REQUEST';
export const DELETE_SIPWISE_SUBSCRIBER_SUCCESS = 'DELETE_SIPWISE_SUBSCRIBER_SUCCESS';
export const DELETE_SIPWISE_SUBSCRIBER_ERROR = 'DELETE_SIPWISE_SUBSCRIBER_ERROR';
