import React, { FC, useContext, useEffect, useState } from 'react';
import { CaseManagementSchedulingContext } from '../index';
import { Alert, Button, Dialog, DialogBody, DialogFooter, MenuItem } from '@blueprintjs/core';
import {
  CASE_MANAGEMENT_SCHEDULING_REMOVE_TIME_BLOCK_FROM_USER,
  CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG,
  CASE_MANAGEMENT_SCHEDULING_UPDATE_USERS_TIMEBLOCKS,
} from '../store/constants';
import { ALL_TIME_BLOCKS, TScheduleUser, TTimeBlock } from '../types';
import { Col, Row } from 'antd';
import { getInitialsFromName } from '@core/helpers/UIHelpers';
import { ItemRenderer, Select } from '@blueprintjs/select';
import { getEarliestAvailableSlot, getEarliestTimeBlock, getLatestTimeBlock } from '../helpers';
import dayjs from 'dayjs';
import { httpDelete, httpPost } from '@core/http/requests';
import { displayMessage } from '@legacy/core/messages/store/reducers';
import { connect } from 'react-redux';

interface Props {
  alertMessage: (params: { body: string; type: string }) => void;
}

const SchedulingUserDialog: FC<Props> = (props: Props) => {
  const { state, dispatch } = useContext(CaseManagementSchedulingContext);

  const { alertMessage } = props;

  const user: TScheduleUser | undefined = state.selectedSchedulingUser;

  const [timeBlocks, setTimeBlocks] = useState<TTimeBlock[]>([]);
  const [isValidating, setIsValidating] = useState<boolean>(false);
  const [isUpdatingTimeBlocks, setIsUpdatingTimeBlocks] = useState<boolean>(false);

  const [timeBlockToDelete, setTimeBlockToDelete] = useState<TTimeBlock | undefined>(undefined);
  const [isRemoveAlertVisible, setIsRemoveAlertVisible] = useState<boolean>(false);
  const [isDeletingTimeBlock, setIsDeletingTimeBlock] = useState<boolean>(false);

  const toggleDialog = () => {
    dispatch({ type: CASE_MANAGEMENT_SCHEDULING_TOGGLE_USER_DIALOG, payload: null });
    setTimeBlocks([]);
    setTimeBlockToDelete(undefined);
    setIsValidating(false);
  };

  // On component mount, check if the user has any schedules and map them to the state time blocks
  useEffect(() => {
    if (state.isSchedulingUserDialogOpen && state.selectedSchedulingUser) {
      const user = state.selectedSchedulingUser;

      if (user.timeBlocks && user.timeBlocks.length > 0) {
        const tbs = user.timeBlocks.map((schedule: any) => {
          return {
            id: schedule.id,
            type: schedule.type,
            startTime: schedule.startTime,
            endTime: schedule.endTime,
            focus: schedule.focus,
          };
        });

        setTimeBlocks(tbs);
      }
    }
  }, [state.isSchedulingUserDialogOpen, state.selectedSchedulingUser]);

  // Time block Types
  let TYPES: any[] = ['BILLABLE', 'CALL']
    .sort((a: string, b: string) => a.localeCompare(b))
    .map((type: string) => ({
      id: type,
      name: type,
      key: type,
      disabled: false,
    }));

  let TIME: any[] = ALL_TIME_BLOCKS.map((time: string) => ({
    id: time,
    name: time,
    key: time,
    disabled: false,
  }));

  const renderSelectItems: ItemRenderer<any> = (item, { handleClick, handleFocus, modifiers }) => {
    return (
      <MenuItem
        active={modifiers.active}
        // disabled={isTimeSlotDisabled(item.name)}
        key={item.key}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="menuitem"
        text={item.name}
      />
    );
  };

  const renderSelectStartTime: ItemRenderer<any> = (
    item,
    { handleClick, handleFocus, modifiers },
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.key}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="menuitem"
        text={item.name}
      />
    );
  };

  const renderSelectEndTime: ItemRenderer<any> = (
    item,
    { handleClick, handleFocus, modifiers },
  ) => {
    return (
      <MenuItem
        active={modifiers.active}
        key={item.key}
        onClick={handleClick}
        onFocus={handleFocus}
        roleStructure="menuitem"
        text={item.name}
      />
    );
  };

  const getTimeBlockEndTime = (startTime: string): string => {
    return dayjs(startTime, 'HH:mm').add(30, 'minute').format('HH:mm');
  };

  const updateTimeBlocks = () => {
    setIsValidating(false);

    const isValidationFailed = timeBlocks.some((timeBlock) => {
      return timeBlock.type === '';
    });

    if (isValidationFailed) {
      setIsValidating(true);
      return false;
    }

    setIsUpdatingTimeBlocks(true);

    console.log('🛠️ %cdebug: Time blocks for update', 'color: salmon', timeBlocks);

    httpPost(`IdentityModule/v1.0/schedules/time-blocks/${user?.userId}`, timeBlocks)
      .then((res: any) => {
        const updatedTimeBlocks = res?.data?.data || [];
        console.log('🛠️ %cdebug: Time blocks update response', 'color: limegreen', res?.data?.data);

        setIsUpdatingTimeBlocks(false);
        dispatch({
          type: CASE_MANAGEMENT_SCHEDULING_UPDATE_USERS_TIMEBLOCKS,
          payload: {
            userId: user?.userId,
            timeBlocks: updatedTimeBlocks,
          },
        });
        toggleDialog();
      })
      .catch((err: any) => {
        const error = err.response ? err.response.data : undefined;
        alertMessage({
          body: (error && error.message) || 'Error updating user time blocks',
          type: 'error',
        });
        setIsUpdatingTimeBlocks(false);
      });
  };

  const deleteTimeBlock = async () => {
    setIsDeletingTimeBlock(true);

    try {
      const res = await httpDelete(
        `IdentityModule/v1.0/schedules/time-blocks/${timeBlockToDelete?.id}`,
      );

      // Remove time block from local component state and reducer
      const newTimeBlocks = timeBlocks.filter((tb) => tb.id !== timeBlockToDelete?.id);
      setTimeBlocks(newTimeBlocks);
      dispatch({
        type: CASE_MANAGEMENT_SCHEDULING_REMOVE_TIME_BLOCK_FROM_USER,
        payload: { userId: user?.userId, timeBlockId: timeBlockToDelete?.id },
      });

      setIsDeletingTimeBlock(false);
      setIsRemoveAlertVisible(false);
      setTimeBlockToDelete(undefined);

      alertMessage({
        body: 'Time block deleted',
        type: 'success',
      });
    } catch (err: any) {
      const error = err.response ? err.response.data : undefined;
      alertMessage({
        body: (error && error.message) || 'Error deleting time block',
        type: 'error',
      });
      setIsDeletingTimeBlock(false);
    }
  };

  const latestTimeBlock = getLatestTimeBlock(timeBlocks);
  const earliestTimeBlock = getEarliestTimeBlock(timeBlocks);

  const changesCount = () => {
    // Check if there are any changes between the current time blocks and the user's time blocks only if time block has id. If there is at least one change on startTime, endTime or type, return 1 change count per time block.
    let changes = 0;
    timeBlocks.forEach((timeBlock) => {
      const userTimeBlock = user?.timeBlocks?.find((tb: any) => tb.id === timeBlock.id);
      if (
        userTimeBlock &&
        (userTimeBlock.startTime !== timeBlock.startTime ||
          userTimeBlock.endTime !== timeBlock.endTime ||
          userTimeBlock.type !== timeBlock.type)
      ) {
        changes++;
      }
    });

    // Each time block without id is one change per time block
    changes += timeBlocks.filter((tb) => !tb.id).length;

    return changes;
  };

  return (
    <>
      <Dialog
        isOpen={state.isSchedulingUserDialogOpen}
        onClose={toggleDialog}
        canEscapeKeyClose={!isUpdatingTimeBlocks}
        style={{ width: 650, minHeight: 600, background: 'white' }}
        canOutsideClickClose={!isUpdatingTimeBlocks}
        isCloseButtonShown={!isUpdatingTimeBlocks}
        usePortal={true}
      >
        <DialogBody>
          {/* Header */}
          <Row>
            <Col span={20}>
              <Row>
                <Col>
                  <div
                    style={{
                      background: '#E6E7E8',
                      padding: '6px 5px',
                      width: 30,
                      height: 30,
                      borderRadius: 50,
                      textAlign: 'center',
                    }}
                  >
                    {getInitialsFromName(`${user?.firstName} ${user?.lastName}`)}
                  </div>
                </Col>
                <Col>
                  <h2 style={{ margin: 0, marginLeft: 10 }}>
                    {user?.firstName} {user?.lastName}
                  </h2>
                </Col>
              </Row>
            </Col>
            <Col span={4} style={{ textAlign: 'right' }}>
              <Button minimal icon="share" intent="primary" />
              <Button minimal icon="cross" onClick={() => toggleDialog()} />
            </Col>
          </Row>

          {/* User Info */}
          <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <span style={{ color: '#666666' }}>Scheduled</span>
            </Col>
            <Col span={16}>
              <span style={{ color: '#666666' }}>Specialties</span>
            </Col>
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col span={8}>
              <span>
                {earliestTimeBlock?.startTime} - {latestTimeBlock?.endTime}
              </span>
            </Col>
            <Col span={16}>
              <span>Billing, Connection Issues</span>
            </Col>
          </Row>

          {/* Shifts */}
          <Row style={{ marginTop: 20 }}>
            <Col span={8}>
              <span style={{ fontWeight: 600 }}>Type</span>
            </Col>
            <Col span={4}>
              <span style={{ fontWeight: 600 }}>Start</span>
            </Col>
            <Col span={4}>
              <span style={{ fontWeight: 600 }}>End</span>
            </Col>
            <Col span={7}>
              <span style={{ fontWeight: 600 }}>Focus</span>
            </Col>
            <Col span={1}>
              <span style={{ fontWeight: 600 }}></span>
            </Col>
          </Row>

          {/* List all shifts */}
          <Row>
            <Col span={24}>
              {timeBlocks.map((timeBlock: TTimeBlock, i: number) => {
                return (
                  <Row style={{ marginTop: 10 }} key={`Row1${i}`}>
                    <Col span={8} key={`Col1${i}`} style={{ paddingRight: 5 }}>
                      {/* Type */}
                      <Select
                        disabled={isUpdatingTimeBlocks}
                        activeItem={TYPES.find((type) => type.name === timeBlock.type)}
                        key={i}
                        items={TYPES}
                        itemRenderer={renderSelectItems}
                        onItemSelect={(e: any) => {
                          const newBlocks = [...timeBlocks];
                          newBlocks[i].type = e.name;
                          setTimeBlocks(newBlocks);
                          setIsValidating(false);
                        }}
                        filterable={false}
                      >
                        <Button
                          disabled={isUpdatingTimeBlocks}
                          className={`schedulingTimeSlotColor ${timeBlock.type}`}
                          alignText="left"
                          style={{
                            borderRadius: 5,
                            border: !timeBlock.type && isValidating ? '1px solid red' : 'inherit',
                            boxShadow: !timeBlock.type ? 'auto' : 'none',
                          }}
                          fill
                          text={timeBlock.type || 'Select Type'}
                          rightIcon="caret-down"
                        />
                      </Select>
                    </Col>
                    {/* Start Time */}
                    <Col span={4} key={`Col2${i}`} style={{ paddingRight: 5 }}>
                      <Select
                        disabled={isUpdatingTimeBlocks}
                        activeItem={TIME.find((t) => t.name === timeBlock.startTime)}
                        key={i}
                        items={TIME.filter((t) => t.name !== '21:00')}
                        itemRenderer={renderSelectStartTime}
                        onItemSelect={(e: any) => {
                          const newBlocks = [...timeBlocks];
                          newBlocks[i].startTime = e.name;
                          setTimeBlocks(newBlocks);
                        }}
                        filterable={false}
                      >
                        <Button
                          disabled={isUpdatingTimeBlocks}
                          style={{ borderRadius: 5 }}
                          alignText="left"
                          fill
                          text={timeBlock.startTime}
                          rightIcon="caret-down"
                        />
                      </Select>
                    </Col>

                    {/* End Time */}
                    <Col span={4} key={`Col3${i}`} style={{ paddingRight: 5 }}>
                      <Select
                        disabled={isUpdatingTimeBlocks}
                        activeItem={TIME.find((t) => t.name === timeBlock.endTime)}
                        key={i}
                        items={TIME}
                        itemRenderer={renderSelectEndTime}
                        onItemSelect={(e: any) => {
                          const newBlocks = [...timeBlocks];
                          newBlocks[i].endTime = e.name;
                          setTimeBlocks(newBlocks);
                        }}
                        filterable={false}
                      >
                        <Button
                          disabled={isUpdatingTimeBlocks}
                          alignText="left"
                          fill
                          text={timeBlock.endTime}
                          rightIcon="caret-down"
                          style={{ borderRadius: 5 }}
                        />
                      </Select>
                    </Col>

                    {/* Focus */}
                    <Col span={7} key={`Col4${i}`} style={{ paddingRight: 5 }}>
                      <Select
                        items={[]}
                        itemRenderer={() => <></>}
                        onItemSelect={() => {}}
                        disabled={isUpdatingTimeBlocks}
                      >
                        <Button
                          alignText="left"
                          fill
                          text={timeBlock.focus || 'Select Focus'}
                          rightIcon="caret-down"
                          // disabled={timeBlock.focus === 'N/A' || isUpdatingTimeBlocks}
                          disabled={true}
                          style={{ borderRadius: 5 }}
                        />
                      </Select>
                    </Col>

                    {/* Action */}
                    <Col span={1} key={`Col5${i}`}>
                      <Button
                        disabled={isUpdatingTimeBlocks}
                        icon={
                          timeBlock.id ? (
                            <i className="bi bi-trash" />
                          ) : (
                            <i className="bi bi-x-lg" />
                          )
                        }
                        minimal
                        intent="danger"
                        onClick={() => {
                          if (timeBlock.id) {
                            setTimeBlockToDelete(timeBlock);
                            setIsRemoveAlertVisible(true);
                          } else {
                            const newBlocks = [...timeBlocks];
                            newBlocks.splice(i, 1);
                            setTimeBlocks(newBlocks);
                          }
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
            </Col>
          </Row>

          <Row style={{ marginTop: 20, paddingRight: 5 }}>
            <Col span={23}>
              <Button
                text="Add Time Block"
                icon="plus"
                disabled={isUpdatingTimeBlocks}
                fill
                intent="primary"
                minimal
                style={{ background: '#F3F5FD', borderRadius: 5 }}
                onClick={() => {
                  setIsValidating(false);
                  setTimeBlocks([
                    ...timeBlocks,
                    {
                      type: 'BILLABLE',
                      startTime: getEarliestAvailableSlot(timeBlocks) || '08:00',
                      endTime: getTimeBlockEndTime(getEarliestAvailableSlot(timeBlocks) || '08:00'),
                      focus: '',
                    },
                  ]);
                }}
              />
            </Col>
          </Row>
        </DialogBody>
        <DialogFooter
          actions={[
            <Button
              text="Close"
              key="close"
              onClick={() => toggleDialog()}
              disabled={isUpdatingTimeBlocks}
            />,
            <Button
              loading={isUpdatingTimeBlocks}
              text={
                changesCount() === 0
                  ? 'Save Changes'
                  : `Save ${changesCount()} ${changesCount() > 1 ? 'Changes' : 'Change'}`
              }
              disabled={changesCount() === 0}
              intent="primary"
              key="save"
              onClick={() => updateTimeBlocks()}
            />,
          ]}
        >
          {changesCount() > 0 && (
            <Button
              text="Undo All Changes"
              icon="undo"
              minimal
              onClick={() => {
                setTimeBlocks(user?.timeBlocks || []);
              }}
            />
          )}
        </DialogFooter>
      </Dialog>

      {/*  Remove time block alert */}
      <Alert
        intent="danger"
        onCancel={() => setIsRemoveAlertVisible(false)}
        isOpen={isRemoveAlertVisible}
        cancelButtonText="Cancel"
        confirmButtonText="Delete"
        onConfirm={deleteTimeBlock}
        loading={isDeletingTimeBlock}
      >
        <p>
          Please confirm that you want to delete the <b>{timeBlockToDelete?.startTime}</b> -{' '}
          <b>{timeBlockToDelete?.endTime}</b> time block. This action cannot be undone.
        </p>
      </Alert>
    </>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  alertMessage: (params: { body: string; type: string }) => dispatch(displayMessage(params)),
});

export default connect(mapState, mapDispatch)(SchedulingUserDialog);
