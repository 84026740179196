import { AxiosError } from 'axios';

interface AxiosErrorResponse {
  message: string;
}

export const getErrorMessage = (error: Error | AxiosError): string => {
  if (error && (error as AxiosError).response) {
    const axiosError = error as AxiosError;
    if (axiosError.response && axiosError.response.data) {
      const data = axiosError.response.data as AxiosErrorResponse;
      return data.message;
    }
  }

  return error.message;
};