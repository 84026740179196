export type FetchStatus = 'idle' | 'loading' | 'loaded' | 'error';

export interface FetchState<T> {
  status: FetchStatus;
  data: T | undefined;
  error?: { message: string };
}

export const getIdleState = <T>(): FetchState<T> => ({
  status: 'idle',
  data: undefined,
  error: undefined,
});

export const getLoadingState = <T>(): FetchState<T> => ({
  status: 'loading',
  data: undefined,
  error: undefined,
});

export const getSuccessState = <T>(data: T): FetchState<T> => ({
  status: 'loaded',
  data,
  error: undefined,
});

export const getErrorState = <T>(error: { message: string }): FetchState<T> => ({
  status: 'error',
  data: undefined,
  error,
});
