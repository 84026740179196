export const CREATE_ORDER_MODAL_VISIBILE = 'CREATE_ORDER_MODAL_VISIBILE';

export const UPDATE_ORDER_WORKFLOW = 'UPDATE_ORDER_WORKFLOW';

export const ORDER_WORKFOLOW_CHECKOUT_REQUEST = 'ORDER_WORKFOLOW_CHECKOUT_REQUEST';
export const ORDER_WORKFOLOW_CHECKOUT_SUCCESS = 'ORDER_WORKFOLOW_CHECKOUT_SUCCESS';
export const ORDER_WORKFOLOW_CHECKOUT_ERROR = 'ORDER_WORKFOLOW_CHECKOUT_ERROR';

export const ORDER_AMEND_WORKFLOW = 'ORDER_AMEND_WORKFLOW';
export const AMEND_ORDER_REQUEST = 'AMEND_ORDER_REQUEST';
export const AMEND_ORDER_SUCCESS = 'AMEND_ORDER_SUCCESS';
export const AMEND_ORDER_ERROR = 'AMEND_ORDER_ERROR';

export const CREATE_ORDER_FROM_LEAD_DRAWER_VISIBILE = 'CREATE_ORDER_FROM_LEAD_DRAWER_VISIBILE';

export const CREATE_ORDER_FROM_ACCOUNT_DRAWER_VISIBILE =
  'CREATE_ORDER_FROM_ACCOUNT_DRAWER_VISIBILE';

export const CREATE_ACCOUNT_MODAL_VISIBILE = 'CREATE_ACCOUNT_MODAL_VISIBILE';

export const INITIALIZE_SWAP_ADDRESS_MODAL = 'INITIALIZE_SWAP_ADDRESS_MODAL';

export const UPDATE_ADDRESS_WORKFLOW_OBJECT = 'UPDATE_ADDRESS_WORKFLOW_OBJECT';

export const UPDATE_CONTACT_IDENTITY_WORKFLOW_OBJECT = 'UPDATE_CONTACT_IDENTITY_WORKFLOW_OBJECT';

export const UPDATE_NETWORK_MANAGE_WORKFLOW_OBJECT = 'UPDATE_NETWORK_MANAGE_WORKFLOW_OBJECT';

export const UPDATE_LEAD_WORKFLOW_OBJECT = 'UPDATE_LEAD_WORKFLOW_OBJECT';

export const CANCEL_WORKORDER_REQUEST = 'CANCEL_WORKORDER_REQUEST';

export const GET_ACTIVE_OFFER_REQUEST = 'GET_ACTIVE_OFFER_REQUEST';
export const GET_ACTIVE_OFFER_SUCCESS = 'GET_ACTIVE_OFFER_SUCCESS';
export const GET_ACTIVE_OFFER_ERROR = 'GET_ACTIVE_OFFER_ERROR';

export const MIGRATE_ADDRESS_ASSOCIATIONS_REQUEST = 'MIGRATE_ADDRESS_ASSOCIATIONS_REQUEST';
export const MIGRATE_ADDRESS_ASSOCIATIONS_SUCCESS = 'MIGRATE_ADDRESS_ASSOCIATIONS_SUCCESS';
export const MIGRATE_ADDRESS_ASSOCIATIONS_ERROR = 'MIGRATE_ADDRESS_ASSOCIATIONS_ERROR';

export const GET_ETF_REQUEST = 'GET_ETF_REQUEST';
export const GET_ETF_SUCCESS = 'GET_ETF_SUCCESS';
export const GET_ETF_ERROR = 'GET_ETF_ERROR';
