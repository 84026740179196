import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Button, InputGroup, NonIdealState, Popover } from '@blueprintjs/core';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Col, Row } from 'antd';
import { EMAIL_TEMPLATES, MESSAGE_TEMPLATES, TMessageTemplate } from './templates';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';

interface Props {
  context: 'MESSAGE' | 'EMAIL';
  onTemplateSelected: (template: string) => void;
  userReducer: any;
  record?: DbRecordEntityTransform;
  disabled?: boolean;
  large?: boolean;
}

const MessageTemplateHandler: React.FC<Props> = (props: Props) => {
  const { record, userReducer, onTemplateSelected, disabled, large } = props;
  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<string>('');

  const customerName: string | undefined = record?.title;
  const customerEmail: string | undefined = getProperty(record, 'EmailAddress');
  const agentName: string | undefined = userReducer?.user?.firstname;
  const agentEmail: string | undefined = userReducer?.user?.email;

  const enrichTemplateText = (templateText: string): string => {
    if (customerName) {
      templateText = templateText.replace(/{{customerName}}/g, customerName);
    }
    if (customerEmail) {
      templateText = templateText.replace(/{{customerEmail}}/g, customerEmail);
    }
    if (agentName) {
      templateText = templateText.replace(/{{agentName}}/g, agentName);
    }
    if (agentEmail) {
      templateText = templateText.replace(/{{agentEmail}}/g, agentEmail);
    }

    return templateText;
  };

  const closePopover = () => {
    setSearchQuery('');
    setIsPopoverOpen(false);
  };

  const handleSelectedTemplate = (template: string) => {
    //  Here do a callback
    onTemplateSelected(enrichTemplateText(template));
    closePopover();
  };

  let templates: TMessageTemplate[] = [];

  if (props.context === 'EMAIL') {
    Object.assign(templates, EMAIL_TEMPLATES);
  } else if (props.context === 'MESSAGE') {
    Object.assign(templates, MESSAGE_TEMPLATES);
  }

  if (searchQuery) {
    templates = templates.filter((template) =>
      template.title.toLowerCase().includes(searchQuery.toLowerCase()),
    );
  }

  return (
    <Popover
      usePortal={true}
      position="top"
      isOpen={isPopoverOpen}
      onClose={() => setIsPopoverOpen(false)}
      content={
        <Row
          style={{
            height: 280,
            width: 400,
            padding: 12,
            alignContent: 'flex-start',
          }}
        >
          {/* Header */}
          <Col span={24}>
            <Row align="middle">
              <Col span={21}>
                <InputGroup
                  leftIcon="search"
                  type="search"
                  placeholder="Search templates"
                  autoFocus={true}
                  value={searchQuery}
                  onChange={(e: any) => setSearchQuery(e.target.value)}
                />
              </Col>
              <Col span={3} style={{ textAlign: 'right' }}>
                <Button minimal small icon="cross" onClick={() => setIsPopoverOpen(false)} />
              </Col>
            </Row>
          </Col>

          {/* List */}
          <Col span={24} style={{ paddingTop: 15, height: '95%', overflowY: 'auto' }}>
            <Row style={{ overflowY: 'auto', height: 'inherit', alignContent: 'flex-start' }}>
              {/* Empty List */}
              {templates.length === 0 && (
                <Col span={24} style={{ height: '85%', alignContent: 'center' }}>
                  <NonIdealState
                    description={`No templates found for search query "${searchQuery}"`}
                    icon="search"
                  />
                </Col>
              )}

              {/* Templates :ist */}
              {templates.length > 0 &&
                templates?.map((template: TMessageTemplate, index: number) => (
                  <Col
                    span={24}
                    key={index}
                    onClick={() => {
                      handleSelectedTemplate(template.text);
                    }}
                    style={{
                      marginBottom: 5,
                      cursor: 'pointer',
                    }}
                  >
                    {/*<span style={{ fontWeight: 500 }}>{template.title}</span>*/}
                    <Button
                      fill
                      style={{
                        padding: 8,
                        borderRadius: 5,
                        marginTop: 2,
                        // add bottom margin if not the last item
                      }}
                      alignText="left"
                      // outlined={true}

                      small
                      text={template.title}
                    />
                  </Col>
                ))}
            </Row>
          </Col>
        </Row>
      }
    >
      <Button
        disabled={disabled}
        intent="primary"
        minimal
        style={{ borderRadius: 5 }}
        icon="search-template"
        large={large}
        onClick={() => setIsPopoverOpen(true)}
      />
    </Popover>
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
});
const mapDispatch = (dispatch: any) => ({});

export default connect(mapState, mapDispatch)(MessageTemplateHandler);
