import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Col, Image, Row } from 'antd';

import React, { useEffect, useState } from 'react';
import { NonIdealState, Section } from '@blueprintjs/core';

interface Props {
  file: DbRecordEntityTransform;
}

const FileDetailViewPreview: React.FC<Props> = (props: Props) => {
  const { file } = props;
  const [fileContent, setFileContent] = useState<string | null>(null);

  useEffect(() => {
    const MimeType = getProperty(file, 'Mimetype');

    if (MimeType === 'text/plain') {
      fetchTextFile();
    }
  }, [file]);

  const fetchTextFile = async () => {
    console.log('debug: fetchTextFile');
    try {
      const response = await fetch(getProperty(file, 'Url'));
      const text = await response.text();

      console.log('debug: response', response);
      console.log('debug: text', text);
      setFileContent(text);
    } catch (error) {
      console.error('Error fetching the file:', error);
      setFileContent('Failed to load the file content.');
    }
  };

  const renderFile = () => {
    const MimeType = getProperty(file, 'Mimetype');
    const URL = getProperty(file, 'Url');
    
    // IMAGE
    if (MimeType?.indexOf('image') > -1) {
      return <Image src={getProperty(file, 'Url')} />;
    }
    // TXT
    if (MimeType === 'text/plain') {
      // retrieve text file
      return (
        <div style={{ padding: 20, minHeight: 200 }}>
          {fileContent ? fileContent : 'Loading...'}
        </div>
      );
    }
    // PDF
    else if (MimeType === 'application/pdf') {
      return (
        <object width="100%" style={{ minHeight: 800 }} data={URL} type="application/pdf"></object>
      );
    }
    // UNSUPPORTED
    else {
      return (
        <div style={{ height: 200 }}>
          <NonIdealState
            title="Unsupported File Extension"
            description={`Mimetype ${getProperty(
              file,
              'Mimetype',
            )} is currently unsupported in a file preview mode.`}
            icon="search"
          />
        </div>
      );
    }
  };

  return (
    <Row>
      <Col span={24}>
        <Section title={'File Preview'}>{renderFile()}</Section>
      </Col>
    </Row>
  );
};

export default FileDetailViewPreview;
