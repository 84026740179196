import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import {
  canUserAccessModule,
  canUserAccessModuleAndEntityInRoutes,
  hasPermissions,
} from '@core/helpers/rbacRules';
import Error403 from '@core/pages/403';
import { INavigationReducer } from './store/reducer';

interface Props {
  accessPermissions?: string[];
  component: React.ReactNode;
  entityName?: string;
  exact?: boolean;
  moduleName: string;
  navigationReducer: INavigationReducer;
  path: string;
  userReducer: any;
}

const ProtectedRoute = ({
                          moduleName,
                          entityName,
                          path,
                          userReducer,
                          navigationReducer,
                          component,
                          accessPermissions,
                          ...rest
                        }: Props) => {
  return (
    <Route
      {...rest}
      exact
      path={path}
      render={(props) => {
        let canAccess: boolean = false;

        // Complete Override
        if (moduleName === 'OVERRIDE') {
          canAccess = true;
        }
        // Override for reporting roles
        else if (moduleName === 'REPORTING') {
          canAccess = canUserAccessModule(userReducer, 'fullreporting');
        }
        // Custom Access Permission
        else if (accessPermissions && accessPermissions?.length! > 0) {
          canAccess = hasPermissions(userReducer, accessPermissions);
        }

        // Schema + user permission based access
        else {
          if (navigationReducer && navigationReducer.routingStructure && moduleName && entityName) {
            canAccess = canUserAccessModuleAndEntityInRoutes(
              navigationReducer.routingStructure,
              moduleName,
              entityName,
            );
          } else if (
            navigationReducer &&
            navigationReducer.routingStructure &&
            moduleName &&
            props.match.params.entityName
          ) {
            canAccess = canUserAccessModuleAndEntityInRoutes(
              navigationReducer.routingStructure,
              moduleName,
              props.match.params.entityName,
            );
          } else {
            canAccess = false;
          }
        }

        // Routing
        if (accessPermissions?.length! > 0 && canAccess) {
          return component;
        } else if (accessPermissions?.length! > 0 && !canAccess) {
          return <Error403 missingPermission={JSON.stringify(accessPermissions)} />;
        } else if (navigationReducer?.routingStructure?.length > 0 && canAccess) {
          return component;
        } else if (navigationReducer?.routingStructure?.length > 0 && !canAccess) {
          return <Redirect to={{ pathname: '/403', state: { from: props.location } }} />;
        } else {
          return <></>;
        }
      }}
    />
  );
};

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  navigationReducer: state.navigationReducer,
});

export default connect(mapState)(ProtectedRoute);
