import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import React from 'react';
import OdinTable from '@core/components/OdinTable';
import { Button } from '@blueprintjs/core';
import { Col, Row } from 'antd';
import {
  IOpenRecordDrawer,
  openRecordDrawer,
} from '@legacy/core/userInterface/store/actions';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { getBrowserPath } from '@core/helpers/recordHelpers';
import TableCellStageTag from '@core/components/TableCellStageTag';

interface Props {
  orders: DbRecordEntityTransform[];
  openDrawer: (params: IOpenRecordDrawer) => void;
}

const ContactAccountOrderTable: React.FC<Props> = (props: Props) => {
  const { orders, openDrawer } = props;

  const tableOrders = orders.map((o: DbRecordEntityTransform) => ({
    key: o.id,
    recordNumber: (
      <Link key={o.id} to={getBrowserPath(o)} target="_blank">
        {o.recordNumber}
      </Link>
    ),
    title: o.title,
    status: <TableCellStageTag small key={o.id} record={o} />,
    action: (
      <Button
        key={o.id}
        small
        icon={<i className="bi bi-eye" />}
        minimal
        intent="primary"
        onClick={() =>
          openDrawer({
            recordId: o?.id || '',
            moduleName: 'OrderModule',
            entityName: 'Order',
          })
        }
      />
    ),
  }));

  return (
    <Row>
      <Col span={24}>
        <OdinTable
          height="auto"
          data={tableOrders}
          columns={[
            {
              key: 'recordNumber',
              title: 'Order #',
              width: 3,
            },
            {
              key: 'title',
              title: 'Title',
              width: 15,
            },
            {
              key: 'status',
              title: 'Status',
              width: 4,
            },
            {
              key: 'action',
              title: '',
              width: 2,
              align: 'right',
            },
          ]}
        />
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({});

const mapDispatch = (dispatch: any) => ({
  openDrawer: (params: IOpenRecordDrawer) => dispatch(openRecordDrawer(params)),
});

export default connect(mapState, mapDispatch)(ContactAccountOrderTable);
