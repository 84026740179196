import { useMemo, useReducer } from 'react';
import QRCode from 'qrcode';
import { httpGet, httpPost } from '@core/http/requests';
import { getErrorMessage } from '../../utils/errors';

const getQRCodeAsDataURL = (url: string) =>
  new Promise((resolve, reject) => {
    QRCode.toDataURL(url, (err, result) => {
      if (err) reject(err);
      else resolve(result);
    });
  });

type MFAAction<T extends any> = {
  type: 'set-status' | 'set-response' | 'set-error';
  payload?: T;
};

type MFAStatus =
  | 'idle'
  | 'generating'
  | 'generated'
  | 'user-setup-completed'
  | 'validating'
  | 'valid'
  | 'failed';

export type MFASelectors = ReturnType<typeof useMFA>['selectors'];

export type MFAState = {
  status: MFAStatus;
  mfaSettings?: {
    secret: string;
    url: string;
    qrCode: string;
    backupCode: string;
  };
  error?: any;
};

const initialState: MFAState = {
  status: 'idle',
};

const mfaReducer = (state: MFAState, action: MFAAction<any>): MFAState => {
  switch (action.type) {
    case 'set-status':
      return {
        ...state,
        status: action.payload,
      };
    case 'set-response':
      return {
        ...state,
        status: 'generated',
        mfaSettings: action.payload,
      };
    case 'set-error':
      return {
        ...state,
        status: 'failed',
        error: action.payload,
      };
    default:
      return state;
  }
};

export const useMFA = () => {
  const [state, dispatch] = useReducer(mfaReducer, initialState);

  const service = useMemo(
    () => ({
      generateSecret: async () => {
        if (state.mfaSettings?.secret) return;
        dispatch({ type: 'set-status', payload: 'generating' });
        try {
          const result = await httpGet('IdentityModule/v1.0/users/mfa').then(
            (response) => response.data.data,
          );
          const qrCode = await getQRCodeAsDataURL(result.url);
          dispatch({
            type: 'set-response',
            payload: {
              ...result,
              qrCode,
            },
          });
        } catch (e) {
          dispatch({ type: 'set-error', payload: e });
        }
      },

      userSetupCompleted: () => {
        dispatch({ type: 'set-status', payload: 'user-setup-completed' });
      },

      verifyPIN: async (pin: string) => {
        dispatch({ type: 'set-status', payload: 'validating' });
        try {
          const result = await httpPost('IdentityModule/v1.0/users/mfa', { pin })
            .then((response) => Boolean(response.data.data))
            .catch((err) => {
              const message = getErrorMessage(err);
              throw new Error(message);
            });
          dispatch({ type: 'set-status', payload: 'valid' });
          return result;
        } catch (e) {
          dispatch({ type: 'set-error', payload: e });
          return false;
        }
      },
    }),
    [dispatch],
  );

  const selectors = useMemo(
    () => ({
      isLoading: () => ['generating', 'validating'].indexOf(state.status) > -1,
      hasError: () => state.status === 'failed',
      getMFAData: () => state.mfaSettings,
    }),
    [state],
  );

  return { state, service, selectors };
};
