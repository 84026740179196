import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Button, Card, Table } from 'antd';
import React, { HTMLAttributes, TdHTMLAttributes } from 'react';
import { useRecordsToTableData } from './hooks';
import { EditablePropertiesState } from './hooks/useEditableProperties';
import { TableRowAction } from './types';

export type RecordDataTableProps = {
  title: string;
  dataSource: DbRecordEntityTransform[];
  rowKey?: (record: DbRecordEntityTransform) => string;
  rowClassName?: (record: DbRecordEntityTransform, index?: number) => string;
  rowSelection?: {};
  rowActions?: TableRowAction[];
  onRow?: (record: any, index?: number) => HTMLAttributes<any> | TdHTMLAttributes<any>;
  cardExtraContent?: React.ReactElement;
  cardExtraActions?: React.ReactElement;
  loading?: boolean;
  columns?: any[];
  customColumnAttributes?: { [key: string]: any };
  editableProperties?: string[];
  onSaveEditableProperties?: (values: EditablePropertiesState) => void;
  disableSaveChanges?: boolean;
  collapsed?: boolean;
  onCollapseChange?: (value: boolean) => void;
  collapsable?: boolean;
};

export const RecordDataTable = (props: RecordDataTableProps) => {
  const { collapsed = true, onCollapseChange = () => undefined, collapsable = false } = props;
  const tableData = useRecordsToTableData({
    records: props.dataSource,
    actions: props.rowActions ?? [],
    customColumnAttributes: props.customColumnAttributes ?? {},
    editableProperties: props.editableProperties ?? [],
  });
  const { onRow = () => ({}) } = props;

  const { columns, dataSource } = props.columns ? props : tableData;

  // Make this a custom hook
  const editablePropertiesService = (props.editableProperties ?? []).length > 0 ? tableData.editablePropertiesService : null;
  const handleSaveEditableProperties = () => {
    if (!editablePropertiesService) return;
    const data = editablePropertiesService.getState();
    if (props.onSaveEditableProperties)
      props.onSaveEditableProperties(data);
  }

  return (
   <div className="association-data-table-wrapper">
      <Card
        size="small"
        title={props.title}
        extra={
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            {props.cardExtraContent ?? null}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {props.cardExtraActions ?? null}
              {editablePropertiesService && (
                <Button disabled={props.disableSaveChanges} onClick={handleSaveEditableProperties}>
                  Save
                </Button>
              )}
            </div>
            { collapsable && <div><Button onClick={() => onCollapseChange(!collapsed)}>{collapsed ? 'Expand' : 'Collapse'}</Button></div> }
          </div>
        }
      >
        <Table
          loading={props.loading ?? false}
          showSorterTooltip={false}
          bordered
          tableLayout={'auto'}
          size="small"
          dataSource={collapsable && collapsed ? [] : dataSource}
          columns={columns}
          rowKey={props.rowKey}
          rowClassName={props.rowClassName}
          rowSelection={props.rowSelection}
          onRow={onRow}
        />
      </Card>
    </div>
  );
}
