const HASH_TAB = 0;
const HASH_RECORD_ID = 1;

const getHashParts = (hash: string = '#'): string[] => {
  const hashPath = hash.split('#')[1];
  if (!hashPath) return [];
  return hashPath.split('/');
}

export const getTabFromHash = (hash: string, defaultTab: string): string => {
  if (!hash) return defaultTab
  const hashParts = getHashParts(hash)
  return hashParts[HASH_TAB] ?? defaultTab;
}

export const getRecordIdFromHash = (hash: string): string => {
  const hashParts = getHashParts(hash)
  return hashParts[HASH_RECORD_ID];
}
