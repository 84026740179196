import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { httpGet } from '../../http/requests';
import { SchemaModuleEntityTypeEnums } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/types/schema.module.entity.types';
import dayjs from 'dayjs';

const { NOTE, FILE } = SchemaModuleEntityTypeEnums;

export type TNote = {
  parentRecord: DbRecordEntityTransform;
  noteRecord: DbRecordEntityTransform;
};

// Get all users in the system
export const getUsersForMention = async () => {
  return httpGet(`IdentityModule/v2.0/users/byorg?size=10000`)
    .then((res: any) => {
      return res.data?.data || [];
    })
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};

// For a given record, fetch all a) associated files and b) associated notes with their associated files
export const getAssociatedAttachmentsAndNotesWithAttachments = async (
  parentRecord: DbRecordEntityTransform,
): Promise<DbRecordEntityTransform[]> => {
  return httpGet(
    `SupportModule/v1.0/Notes/record/${parentRecord.entity}/${parentRecord?.id}?withLinks=true`,
  )
    .then((res: any) => {
      let response: DbRecordEntityTransform[] = res.data?.data || [];

      if (response.length > 0) {
        response = response.sort((a: DbRecordEntityTransform, b: DbRecordEntityTransform) =>
          dayjs(a.createdAt).isBefore(dayjs(b.createdAt)) ? 1 : -1,
        );
      }

      return response;
    })
    .catch((err: any) => {
      console.log('debug: err', err);
      return [];
    });
};
