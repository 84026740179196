import { Button, Col, Divider, Dropdown, MenuProps, Row, Steps } from 'antd';
import React, { ReactElement } from 'react';
import { connect } from 'react-redux';
import {
  changeStepNumber,
  IStepViewChangeStepNumber,
  IStepViewValidation,
  setStepValidationArray,
} from './store/actions';

const { Step } = Steps;

interface IStep {
  name: string;
  content: ReactElement;
  entityName?: string;
  isNextDisabled?: boolean;
  isPreviousDisabled?: boolean;
}

interface Props {
  steps: IStep[];
  stepViewReducer: any;
  onSubmit: any;
  previousDisabled?: boolean;
  onNextActionClick?: any;
  isLookupCreate?: boolean;
  setValidationData: (params: IStepViewValidation[]) => void;
  changeStep: (params: IStepViewChangeStepNumber) => void;
  onPrevActionClick?: any;
  hideCancel?: boolean;
  submitDisabled?: boolean;
}

interface State {
  submitLoading: boolean;
  isNextLoading: boolean;
}

class StepView extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.setInitailStepData(this.props.steps);
  }

  setInitailStepData = (steps: IStep[]) => {
    const { setValidationData, changeStep } = this.props;
    let tempArr: any = [];

    for (let i = 0; i < steps.length; i++) {
      const stepItem = steps[i];
      if (i === 0) {
        tempArr.push({
          isNextDisabled: stepItem.isNextDisabled ?? true,
          isPreviousDisabled: stepItem.isPreviousDisabled ?? true,
        });
      } else {
        tempArr.push({
          isNextDisabled: stepItem.isNextDisabled ?? false,
          isPreviousDisabled: stepItem.isPreviousDisabled ?? true,
        });
      }
    }
    setValidationData(tempArr);
    changeStep({ stepNumber: 0 });
  };

  state = {
    submitLoading: false,
    isNextLoading: false,
  };

  next = () => {
    const { onNextActionClick, isLookupCreate, stepViewReducer, steps, changeStep } = this.props;
    if (isLookupCreate) {
      this.setState({
        isNextLoading: true,
      });
      onNextActionClick(
        {
          step: stepViewReducer.currentStep,
          entityName: steps[stepViewReducer.currentStep].entityName,
        },
        (result: any) => {
          if (result) {
            // move to the next step
            const currentStep = stepViewReducer.currentStep;
            changeStep({ stepNumber: currentStep + 1 });
          }

          this.setState({
            isNextLoading: false,
          });
        },
      );
    } else {
      const currentStep = stepViewReducer.currentStep;
      changeStep({ stepNumber: currentStep + 1 });
    }
  };

  prev = () => {
    const { onPrevActionClick, isLookupCreate, stepViewReducer, steps, changeStep } = this.props;
    if (isLookupCreate) {
      onPrevActionClick(
        {
          step: stepViewReducer.currentStep,
          entityName: steps[stepViewReducer.currentStep].entityName,
        },
        (result: any) => {
          if (result) {
            // move to the next step
            const currentStep = stepViewReducer.currentStep;
            changeStep({ stepNumber: currentStep - 1 });
          }
        },
      );
    } else {
      const currentStep = stepViewReducer.currentStep;
      changeStep({ stepNumber: currentStep - 1 });
    }
  };

  submitClick() {
    const { onSubmit, stepViewReducer, setValidationData, changeStep } = this.props;
    this.setState({
      submitLoading: true,
    });
    onSubmit((cb: any) => {
      if (cb) {
        this.setState({
          submitLoading: false,
        });
        const tempArr = stepViewReducer.stepComponentsData;
        setValidationData(tempArr);
        changeStep({ stepNumber: 1 });
      } else {
        this.setState({
          submitLoading: false,
        });
      }
    });
  }

  render() {
    const { steps, stepViewReducer, previousDisabled, isLookupCreate, submitDisabled } = this.props;

    const items: MenuProps['items'] = steps?.map((step: any, i: number) => ({
      key: String(i + 1),
      label: `${i + 1}. ${step.name}`,
      disabled: i !== stepViewReducer.currentStep,
    }));

    return (
      <div style={{ marginTop: 18 }}>
        <Row>
          <Col span={20}>
            <div style={{ fontSize: '1.1em' }}>
              <span
                className="ant-steps-item-icon"
                style={{
                  background: '#1677ff',
                  color: 'white',
                  border: 'none',
                  marginRight: 10,
                  padding: '5px 10px',
                  borderRadius: 80,
                  verticalAlign: 'middle',
                }}
              >
                {stepViewReducer.currentStep + 1}
              </span>
              <span style={{ lineHeight: 0, verticalAlign: 'middle' }}>
                {steps[stepViewReducer.currentStep]?.name}
              </span>
            </div>
          </Col>
          <Col span={4} style={{ textAlign: 'right' }}>
            <Dropdown menu={{ items }} placement="bottom" arrow>
              <Button style={{ borderRadius: 4 }}>
                Step {`${stepViewReducer.currentStep + 1} / ${steps.length}`}
              </Button>
            </Dropdown>
          </Col>
          <Col span={24}>
            <Divider style={{ marginTop: 15, marginBottom: 0 }} />
          </Col>
        </Row>
        <div className="steps-content">{steps[stepViewReducer.currentStep]?.content}</div>
        <div className="steps-action">
          {stepViewReducer.currentStep > 0 && (
            <Button
              style={{ margin: '0 8px' }}
              onClick={() => this.prev()}
              disabled={
                stepViewReducer.stepComponentsData[stepViewReducer.currentStep]
                  ?.isPreviousDisabled || previousDisabled
              }
            >
              Previous
            </Button>
          )}
          {stepViewReducer.currentStep < steps.length - 1 && (
            <Button
              type="primary"
              onClick={() => this.next()}
              disabled={
                stepViewReducer.stepComponentsData[stepViewReducer.currentStep]?.isNextDisabled
              }
              loading={this.state.isNextLoading}
            >
              Next
            </Button>
          )}
          {stepViewReducer.currentStep === steps.length - 1 && (
            <div style={{ display: 'flex' }}>
              <Button
                style={{ marginRight: 10 }}
                type="primary"
                ghost
                onClick={() => {
                  this.setState({ submitLoading: false });
                }}
                disabled={!this.state.submitLoading}
                hidden={this.props.hideCancel}
              >
                Cancel
              </Button>

              <Button
                type="primary"
                onClick={() => {
                  this.submitClick();
                }}
                disabled={
                  (stepViewReducer.stepComponentsData[stepViewReducer.currentStep]
                    ?.isNextDisabled &&
                    isLookupCreate) ||
                  submitDisabled
                }
                loading={this.state.submitLoading}
              >
                Submit
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapState = (state: any) => ({
  stepViewReducer: state.stepViewReducer,
});

const mapDispatch = (dispatch: any) => ({
  setValidationData: (params: IStepViewValidation[]) => dispatch(setStepValidationArray(params)),
  changeStep: (params: IStepViewChangeStepNumber) => dispatch(changeStepNumber(params)),
});

export default connect(mapState, mapDispatch)(StepView);
