import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import { Menu, MenuDivider, MenuItem } from '@blueprintjs/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { getSchemaByIdRequest } from '@legacy/core/schemas/store/actions';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { displayMessage } from '@legacy/core/messages/store/reducers';

import { useAction } from '@core/hooks/useAction';
import { useRequest } from '@core/hooks/useRequest';
import { getErrorMessage } from '../../../utils/errors';

import { SchemaDetails } from './SchemaDetails';
import './styles.scss';
import { toSentenceCase } from '@core/helpers/stringHelpers';

type TActiveSection =
  | 'top-section'
  | 'schema-types'
  | 'schema-details'
  | 'properties-actions'
  | 'schema-links'
  | 'permissions'
  | 'pipelines'
  | 'settings';

const sectionClasses = [
  { name: 'top-section', className: '.top-section' },
  { name: 'schema-types', className: '.types-section' },
  { name: 'properties-actions', className: '.properties-section' },
  { name: 'schema-links', className: '.schema-links' },
  { name: 'permissions', className: '.permissions-section' },
  { name: 'pipelines', className: '.pipeline-section' },
  { name: 'settings', className: '.settings-section' },
];

let isScrolling = false;

const ControlPanelView: React.FC<RouteComponentProps<{ id: string }>> = ({ history, match }) => {
  const schemaShortList = useSelector((state: any) => state.schemaReducer.shortList);

  const schemaDetailsRef = React.useRef<HTMLDivElement>(null);
  const [isSchemaLoading, setIsSchemaLoading] = useState(false);
  // const [schema, setSchema] = useState<SchemaEntity>();
  const [currentView, setCurrentView] = useState<TActiveSection>('top-section');
  const alertMessage = useAction(displayMessage);
  const getSchemaById = useRequest(getSchemaByIdRequest);

  const schema = getSchemaFromShortListBySchemaId(schemaShortList, match.params.id);

  useEffect(() => {
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      if (isScrolling) return; // Ignore updates during programmatic scrolling
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const section = sectionClasses.find((s) =>
            entry.target.classList.contains(s.className.slice(1)),
          );
          if (section) {
            setCurrentView(section.name as TActiveSection);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      root: schemaDetailsRef.current,
      rootMargin: '0px 0px -95% 0px',
      threshold: 0,
    });

    const setupObserver = () => {
      sectionClasses.forEach((section) => {
        const element = document.querySelector(section.className);
        if (element) {
          observer.observe(element);
        }
      });
    };

    const onUserScroll = () => {
      if (!isScrolling) {
        // Reset the observer to handle user scrolling
        observer.disconnect();
        setupObserver();
      }
    };

    if (schemaDetailsRef.current) {
      setupObserver();
      schemaDetailsRef.current.addEventListener('scroll', onUserScroll);
    }

    return () => {
      observer.disconnect();
      schemaDetailsRef.current?.removeEventListener('scroll', onUserScroll);
    };
  }, [schemaDetailsRef.current, schema, isScrolling]);

  // Handle programmatic scrolling
  const programmaticScroll = (targetSelector: string) => {
    const target = document.querySelector(targetSelector);
    if (!target) return;

    isScrolling = true;
    target.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest',
    });

    // Reset the `isScrolling` flag after a delay
    setTimeout(() => {
      isScrolling = false;
    }, 1000);
  };

  useEffect(() => {
    let mounted = true;
    if (!match.params?.id) return;

    setIsSchemaLoading(true);
    //
    // if (schema) {
    //   setSchema(schema);
    //   setIsSchemaLoading(false);
    //   return;
    // }

    getSchemaById({ schemaId: match.params.id })
      .then(() => {
        if (!mounted) return;

        // setSchema(schema);
        setIsSchemaLoading(false);
      })
      .catch((error) => {
        if (!mounted) return;

        const message = getErrorMessage(error);
        alertMessage({
          body: 'Could not retrieve schema. ' + message,
          type: 'error',
        });
      });

    return () => {
      mounted = false;
    };
  }, [match.params?.id]);

  return (
    <Row>
      {/* Navbar */}
      <Col span={4} style={{ padding: 5, borderRight: '1px solid #DCDCDD' }}>
        <Menu style={{ background: 'transparent', position: 'sticky' }}>
          <MenuItem
            icon="arrow-left"
            text="Schema List"
            onClick={() => history.push('/ControlPanelModule/SchemaManager/')}
          />

          <MenuDivider title={schema?.name ? toSentenceCase(schema?.name) : ''} />

          <MenuItem
            style={{ marginTop: 7 }}
            text="Overview"
            disabled={isSchemaLoading}
            active={currentView === 'top-section'}
            onClick={() => {
              setCurrentView('top-section');
              programmaticScroll('.top-section');
            }}
          />

          <MenuItem
            text="Schema Types"
            disabled={isSchemaLoading}
            active={currentView === 'schema-types'}
            onClick={() => {
              setCurrentView('schema-types');
              programmaticScroll('.types-section');
            }}
          />

          <MenuItem
            text="Properties & Actions"
            disabled={isSchemaLoading}
            active={currentView === 'properties-actions'}
            onClick={() => {
              setCurrentView('properties-actions');
              programmaticScroll('.properties-section');
            }}
          />

          <MenuItem
            text="Links"
            disabled={isSchemaLoading}
            active={currentView === 'schema-links'}
            onClick={() => {
              setCurrentView('schema-links');
              programmaticScroll('.schema-links');
            }}
          />

          {/* Permissions */}
          <MenuItem
            text="Permissions"
            disabled={isSchemaLoading}
            active={currentView === 'permissions'}
            onClick={() => {
              setCurrentView('permissions');
              programmaticScroll('.permissions-section');
            }}
          />

          <MenuItem
            text="Process Pipelines"
            disabled={isSchemaLoading}
            active={currentView === 'pipelines'}
            onClick={() => {
              setCurrentView('pipelines');
              programmaticScroll('.pipeline-section');
            }}
          />
          <MenuItem
            text="Settings"
            disabled={isSchemaLoading}
            active={currentView === 'settings'}
            onClick={() => {
              setCurrentView('settings');
              programmaticScroll('.settings-section');
            }}
          />
        </Menu>
      </Col>

      {/* Content */}
      <Col span={20} style={{ height: '95vh' }}>
        <SchemaDetails
          schema={schema}
          loading={isSchemaLoading}
          schemaDetailsRef={schemaDetailsRef}
        />
      </Col>
    </Row>
  );
};

export default withRouter(ControlPanelView);
