import { UndoOutlined } from '@ant-design/icons';
import { Button, Section } from '@blueprintjs/core';
import { IGetSchemaById } from '@d19n/temp-fe-d19n-models/dist/rabbitmq/rabbitmq.interfaces';
import { Checkbox, Col, Divider, Form, Input, Popconfirm, Row, Select } from 'antd';
import { FormInstance } from 'antd/lib/form';
import React from 'react';
import { HexColorPicker } from 'react-colorful';
import { connect } from 'react-redux';
import { bootstrapIcons } from '@core/assets/icons/bootstrapIcons';
import userReducer from '@legacy/core/identity/store/reducers';
import {
  deleteSchemaById,
  getSchemaByIdRequest,
  listSchemasRequest,
  updateSchemaRequest,
} from '@legacy/core/schemas/store/actions';
import { ISchemaReducer } from '@legacy/core/schemas/store/reducer';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { isSystemAdmin } from '@core/helpers/rbacRules';
import { errorNotification } from '@legacy/core/notifications/store/reducers';
import { titleCaseList } from '../../Schemas/FormFields';
import './styles.scss';
import SyncElasticIndexAPI from './SyncElasticIndexAPI';

const { Option } = Select;

interface Props {
  notifyError: any;
  updateSchema: any;
  data: any;
  deleteSchema: any;
  getSchema: any;
  userReducer: any;
  listSchemas: () => void;
  schemaReducer: ISchemaReducer;
}

class SchemaSettingsDetailView extends React.Component<Props> {
  state = {
    name: '',
    description: '',
    moduleName: '',
    entityName: '',
    recordNumber: 0,
    recordNumberPrefix: '',
    recordDefaultOwnerId: '',
    searchUrl: '',
    getUrl: '',
    postUrl: '',
    putUrl: '',
    deleteUrl: '',
    upsertOnCreate: true,
    assignable: false,
    isSequential: false,
    isStatic: false,
    isHidden: false,
    isVisibleInTabs: false,
    isVisibleInGlobalNav: false,
    hasTitle: false,
    position: 0,
    isTitleUnique: false,
    isTitleRequired: false,
    queryable: false,
    replicateable: false,
    retrievable: false,
    searchable: false,
    triggerable: false,
    undeletable: false,
    updateable: false,
    titleCase: '',
    isRequesting: false,
    menuLabel: '',
    icon: '',
    iconColor: '',
  };

  constructor(props: Props) {
    super(props);

    this.setStateValue(this.props.data);
  }

  componentDidMount() {
    if (this.props.data?.data?.icon) {
      this.setState({ icon: this.props.data?.data?.icon });
    }

    if (this.props.data?.data?.iconColor) {
      this.setState({ iconColor: this.props.data?.data?.iconColor });
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    if (prevProps.data !== this.props.data) {
      this.setStateValue(this.props.data);
    }
  }

  formRef = React.createRef<FormInstance>();

  saveChanges = async () => {
    const { notifyError, updateSchema, getSchema, data, listSchemas } = this.props;
    try {
      if (!!this.formRef.current) {
        await this.formRef.current.validateFields();
        const formErrors = this.formRef.current ? this.formRef.current.getFieldsError() : [];
        const hasErrors = formErrors.filter(({ errors }) => errors.length).length > 0;
        if (hasErrors) {
          return notifyError({
            message: 'form has errors, fix them and resubmit',
            validation: null,
            data: null,
          });
        } else {
          this.setState({ isRequesting: true });
          updateSchema({ data: this.state, schemaId: data.id }, (result: any) => {
            this.setStateValue(result);
            this.setState({ isRequesting: false });

            // Fetch back schema, so we can store it back to shortlist
            getSchema({ schemaId: data?.id });
            // Fetch back schema list, this is important to update the navigation structure
            listSchemas();
          });
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  setStateValue(data: any) {
    if (data) {
      let initialData = data;
      // delete initialData.columns;
      // delete initialData.associations;

      this.state = initialData;
    }
  }

  handleDeleteSchema() {
    const { deleteSchema, data } = this.props;
    deleteSchema({ schemaId: data.id });
  }

  handleColorChange = (hexColor: string) => {
    if (hexColor) {
      this.setState({ iconColor: hexColor });
    }
  };

  renderForm() {
    const { data } = this.props;

    return (
      <Form layout={'vertical'} ref={this.formRef} initialValues={data}>
        <Col span={24} offset={4} style={{ marginBottom: 50 }}>
          <Row gutter={12} style={{ marginTop: 15 }}>
            <Col span={20}>
              <div style={{ float: 'left' }}>
                <h2 title="5">Schema Definition</h2>
              </div>
            </Col>
          </Row>

          <Row gutter={12}>
            <Col span={10}>
              <Form.Item className="form-item" label="Name" initialValue={data?.name}>
                <Input
                  placeholder="Name"
                  defaultValue={data?.name}
                  onChange={(e) => this.setState({ name: e.target.value })}
                />
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.description} label="Description">
                <Input
                  placeholder="Description"
                  defaultValue={data?.description}
                  onChange={(e) => this.setState({ description: e.target.value })}
                />
              </Form.Item>
              <Form.Item className="form-item" initialValue={0} label="Position">
                <Input
                  placeholder="Position"
                  defaultValue={data?.position}
                  type="number"
                  onChange={(e) => this.setState({ position: parseInt(e.target.value) })}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item className="form-item" initialValue={data?.moduleName} label="Module Name">
                <Input placeholder="Module Name" defaultValue={data?.moduleName} disabled={true} />
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.entityName} label="Entity Name">
                <Input placeholder="Entity Name" defaultValue={data?.entityName} disabled={true} />
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.titleCase} label="Title Case">
                <Select
                  defaultValue={data?.titleCase}
                  onChange={(value) => {
                    this.setState({ titleCase: value });
                  }}
                  allowClear
                >
                  {titleCaseList.map((it: any) => (
                    <Option value={it.value}>{it.label}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <h2 title="5">Record Numbers</h2>
          <Row gutter={12}>
            <Col span={10}>
              <Form.Item
                className="form-item"
                initialValue={data?.recordNumber}
                label="Starting Record Number"
              >
                <Input
                  placeholder="Starting Record Number"
                  defaultValue={data?.recordNumber}
                  type="number"
                  onChange={(e) => this.setState({ recordNumber: parseInt(e.target.value) })}
                />
              </Form.Item>

              <Form.Item
                className="form-item"
                initialValue={data?.recordNumberPrefix}
                label="Record Number Prefix"
              >
                <Input
                  placeholder="Record Number Prefix"
                  defaultValue={data?.recordNumberPrefix}
                  onChange={(e) => this.setState({ recordNumberPrefix: e.target.value })}
                />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item className="form-item" initialValue={data?.isSequential}>
                <Checkbox
                  checked={this.state.isSequential}
                  onChange={(e) => this.setState({ isSequential: e.target.checked })}
                >
                  Auto-increment Record Numbers
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <h2 title="5">API Routing</h2>
          <Row gutter={12} style={{ marginTop: 24 }}>
            <Col span={10}>
              <Form.Item className="form-item" initialValue={data?.getUrl} label="GET URL">
                <Input
                  placeholder="GET URL"
                  defaultValue={data?.getUrl}
                  onChange={(e) => this.setState({ getUrl: e.target.value })}
                />
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.postUrl} label="POST URL">
                <Input
                  placeholder="POST URL"
                  defaultValue={data?.postUrl}
                  onChange={(e) => this.setState({ postUrl: e.target.value })}
                />
              </Form.Item>
            </Col>

            <Col span={10}>
              <Form.Item className="form-item" initialValue={data?.putUrl} label="PUT URL">
                <Input
                  placeholder="PUT URL"
                  defaultValue={data?.putUrl}
                  onChange={(e) => this.setState({ putUrl: e.target.value })}
                />
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.deleteUrl} label="DELETE URL">
                <Input
                  placeholder="DELETE URL"
                  defaultValue={data?.deleteUrl}
                  onChange={(e) => this.setState({ deleteUrl: e.target.value })}
                />
              </Form.Item>

              <Form.Item className="form-item" initialValue={data?.searchUrl} label="SEARCH URL">
                <Input
                  placeholder="SEARCH URL"
                  defaultValue={data?.searchUrl}
                  onChange={(e) => this.setState({ searchUrl: e.target.value })}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item className="form-item" initialValue={data?.upsertOnCreate}>
                <Checkbox
                  checked={this.state.upsertOnCreate}
                  onChange={(e) => {
                    this.setState({ upsertOnCreate: e.target.checked });
                  }}
                >
                  Upsert on create
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Divider />
          <h2 title="5" style={{ marginBottom: 20 }}>
            Sync elastic index settings
          </h2>
          <Row gutter={12}>
            <Col span={10}>
              <SyncElasticIndexAPI schemaId={data?.id} />
            </Col>
          </Row>

          <Divider />
          <h2 title="5" style={{ marginBottom: 20 }}>
            Schema Configuration
          </h2>

          <Row gutter={12}>
            <Col span={6}>
              <Form.Item className="form-item" initialValue={data?.hasTitle}>
                <Checkbox
                  checked={this.state.hasTitle}
                  onChange={(e) => this.setState({ hasTitle: e.target.checked })}
                >
                  Has Title
                </Checkbox>
              </Form.Item>

              <Form.Item className="form-item" initialValue={data?.isTitleUnique}>
                <Checkbox
                  checked={this.state.isTitleUnique}
                  onChange={(e) => this.setState({ isTitleUnique: e.target.checked })}
                >
                  Is Title Unique
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item className="form-item" initialValue={data?.isTitleRequired}>
                <Checkbox
                  checked={this.state.isTitleRequired}
                  onChange={(e) => this.setState({ isTitleRequired: e.target.checked })}
                >
                  Is Title Required
                </Checkbox>
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.queryable}>
                <Checkbox
                  checked={this.state.queryable}
                  onChange={(e) => this.setState({ queryable: e.target.checked })}
                >
                  Queryable
                </Checkbox>
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.replicateable}>
                <Checkbox
                  checked={this.state.replicateable}
                  onChange={(e) => this.setState({ replicateable: e.target.checked })}
                >
                  Replicateable
                </Checkbox>
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.retrievable}>
                <Checkbox
                  checked={this.state.retrievable}
                  onChange={(e) => this.setState({ retrievable: e.target.checked })}
                >
                  Retrievable
                </Checkbox>
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.searchable}>
                <Checkbox
                  checked={this.state.searchable}
                  onChange={(e) => this.setState({ searchable: e.target.checked })}
                >
                  Searchable
                </Checkbox>
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.triggerable}>
                <Checkbox
                  checked={this.state.triggerable}
                  onChange={(e) => this.setState({ triggerable: e.target.checked })}
                >
                  Triggerable
                </Checkbox>
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.undeletable}>
                <Checkbox
                  checked={this.state.undeletable}
                  onChange={(e) => this.setState({ undeletable: e.target.checked })}
                >
                  Undeletable
                </Checkbox>
              </Form.Item>
              <Form.Item className="form-item" initialValue={data?.updateable}>
                <Checkbox
                  checked={this.state.updateable}
                  onChange={(e) => {
                    this.setState({ updateable: e.target.checked });
                  }}
                >
                  Updateable
                </Checkbox>
              </Form.Item>
            </Col>

            <Col span={6}>
              <Form.Item className="form-item" initialValue={data?.isVisibleInTabs}>
                <Checkbox
                  checked={this.state.isVisibleInTabs}
                  onChange={(e) => this.setState({ isVisibleInTabs: e.target.checked })}
                >
                  Is Visible In Tabs
                </Checkbox>
              </Form.Item>

              <Form.Item className="form-item" initialValue={data?.isVisibleInGlobalNav}>
                <Checkbox
                  checked={this.state.isVisibleInGlobalNav}
                  onChange={(e) => this.setState({ isVisibleInGlobalNav: e.target.checked })}
                >
                  Is Visible In Navbar
                </Checkbox>
              </Form.Item>

              {this.state.isVisibleInGlobalNav && (
                <Form.Item
                  label="Menu Label"
                  className="form-item"
                  initialValue={data?.isVisibleInGlobalNav}
                >
                  <Input
                    placeholder="Menu Label"
                    defaultValue={data?.menuLabel}
                    onChange={(e) => this.setState({ menuLabel: e.target.value })}
                  />
                </Form.Item>
              )}

              {isSystemAdmin(userReducer) ? (
                <Row>
                  <Col span={24} style={{ marginBottom: 10 }}>
                    <label>Schema icon</label>
                  </Col>

                  <Col span={24}>
                    <Row>
                      <Col span={12}>
                        <ModuleEntityIcon
                          overrideIconColor={this.state.iconColor}
                          overrideIcon={this.state.icon}
                          moduleName={data?.moduleName}
                          entityName={data?.entityName}
                        />
                      </Col>
                      <Col span={12} style={{ textAlign: 'right' }}>
                        {this.state.icon !== data?.icon ||
                        this.state.iconColor !== data?.iconColor ? (
                          <Button
                            icon={<UndoOutlined />}
                            onClick={() => {
                              this.setState({
                                icon: data?.icon,
                                iconColor: data?.iconColor,
                              });
                            }}
                          >
                            Undo Changes
                          </Button>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </Row>
                  </Col>

                  <Col span={24} style={{ marginBottom: 5, marginTop: 20 }}>
                    <label>Icon type</label>
                  </Col>

                  <Col span={24}>
                    <Select
                      size="large"
                      style={{ width: '100%', marginTop: 4 }}
                      showSearch
                      placeholder="Select an icon"
                      defaultValue={data?.icon}
                      optionFilterProp="children"
                      onChange={(e: any) => {
                        this.setState({ icon: e });
                      }}
                      filterOption={(input: any, option: any) =>
                        option?.value?.indexOf(input.toLowerCase()) > -1
                      }
                      dropdownRender={(menu) => (
                        <>
                          {menu}
                          <Divider style={{ margin: '4px 0' }} />
                          <Row>
                            <Col span={24} style={{ textAlign: 'center' }}>
                              <a
                                href="https://icons.getbootstrap.com"
                                target="_blank"
                                rel="noreferrer"
                              >
                                <Button>View all icons</Button>
                              </a>
                            </Col>
                          </Row>
                        </>
                      )}
                    >
                      {bootstrapIcons.map((icon: string) => {
                        return (
                          <Select.Option key={icon} value={icon}>
                            <i style={{ marginRight: 5 }} className={`bi bi-${icon}`} /> {icon}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Col>

                  <Col span={24} style={{ marginTop: 20 }}>
                    <Row>
                      <Col span={24}>
                        <label>Icon color</label>
                      </Col>
                      <Col span={24} style={{ paddingTop: 10 }}>
                        <HexColorPicker
                          color={this.state.iconColor || 'white'}
                          onChange={this.handleColorChange}
                          style={{ width: '100%' }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Col>
      </Form>
    );
  }

  render() {
    return (
      <Section
        title="Schema Settings"
        rightElement={
          <>
            <Popconfirm
              title="Are you sure you want to delete this schema?"
              onConfirm={() => this.handleDeleteSchema()}
              okText="Yes"
              cancelText="No"
            >
              <Button key="2" intent="danger" outlined text="Delete Schema" />
            </Popconfirm>
            <Button
              key="1"
              intent="primary"
              loading={this.state.isRequesting}
              onClick={() => this.saveChanges()}
            >
              Update Schema
            </Button>
          </>
        }
      >
        {this.renderForm()}
      </Section>
    );
  }
}

const mapState = (state: any) => ({
  userReducer: state.userReducer,
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  updateSchema: (params: any, cb: any) => dispatch(updateSchemaRequest(params, cb)),
  getSchema: (payload: IGetSchemaById, cb: any) => dispatch(getSchemaByIdRequest(payload, cb)),
  deleteSchema: (params: any) => dispatch(deleteSchemaById(params)),
  notifyError: (params: any) => dispatch(errorNotification(params)),
  listSchemas: () => dispatch(listSchemasRequest()),
});

export default connect(mapState, mapDispatch)(SchemaSettingsDetailView);
