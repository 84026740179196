// lists
export const SET_ALL_WORK_ORDERS = 'SET_ALL_WORK_ORDERS';
export const SET_UNASSIGNED_WORK_ORDERS = 'SET_UNASSIGNED_WORK_ORDERS';

// assigning
export const TOGGLE_ASSIGN_MODE = 'TOGGLE_ASSIGN_MODE';
export const SET_WORK_ORDERS_TO_ASSIGN = 'SET_WORK_ORDERS_TO_ASSIGN';
export const SET_ASSIGNING_TO_ENGINEER_ID = 'SET_ASSIGNING_TO_ENGINEER_ID';

export const SET_ASSIGN_MODAL_VISIBLE = 'SET_ASSIGN_MODAL_VISIBLE';

// Reducer update
export const ADD_WORK_ORDERS_TO_ENGINEER = 'ADD_WORK_ORDERS_TO_ENGINEER';

// stats
export const SET_REMOTE_STATS = 'SET_REMOTE_STATS';
