import { FC, useEffect, useState } from 'react';
import proj4 from 'proj4';
import View from 'ol/View';
import { defaults as DefaultInteractions } from 'ol/interaction';
import { Button, Card, Col, Row } from 'antd';
import { Tile as TileLayer } from 'ol/layer';
import Map from 'ol/Map';
import XYZ from 'ol/source/XYZ';
import './styles.scss';
import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getFeatureByIdAndZoom } from '../../../helpers';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import ModuleEntityIcon from '@legacy/core/theme/ModuleEntityIcon';
import { register } from 'ol/proj/proj4';

interface Props {
  feature: DbRecordEntityTransform | undefined;
}

export const FeatureMapWidget: FC<Props> = (props: Props) => {
  const { feature } = props;
  const [map, setMap] = useState<any>(undefined);

  // When feature comes into the component, initialize map, add Google layer,
  // fetch the feature information from QGIS, add layer to the map and zoom into it
  useEffect(() => {
    if (!map && feature) {
      register(proj4);
      proj4.defs(
        'EPSG:27700',
        '+proj=tmerc +lat_0=49 +lon_0=-2 +k=0.9996012717 +x_0=400000 +y_0=-100000 +ellps=airy +towgs84=446.448,-125.157,542.06,0.15,0.247,0.842,-20.489 +units=m +no_defs',
      );

      let widgetMap: any;

      widgetMap = new Map({
        layers: [
          new TileLayer({
            source: new XYZ({
              url: 'https://mt0.google.com/vt/lyrs=m&hl=en&x={x}&y={y}&z={z}',
              crossOrigin: 'Anonymous',
            }),
            className: 'googleRoads',
            visible: true,
            zIndex: 100,
          }),
        ],
        target: 'widgetMap',
        view: new View({
          enableRotation: false,
          constrainOnlyCenter: false,
        }),
        controls: [],
        interactions: DefaultInteractions({
          pinchRotate: true,
        }),
      });

      setMap(widgetMap);

      const query = `type=${feature?.type?.toLowerCase()}&featureId=${getProperty(
        feature,
        'ExternalRef',
      )}`;

      getFeatureByIdAndZoom({ map: widgetMap }, undefined, undefined, query, true);
    } else if (map && feature) {
      const query = `type=${feature?.type?.toLowerCase()}&featureId=${getProperty(
        feature,
        'ExternalRef',
      )}`;
      getFeatureByIdAndZoom({ map: map }, undefined, undefined, query, true);
    }
  }, [feature]);

  return (
    <Card
      size="small"
      style={{ marginTop: 15 }}
      extra={
        <Button
          onClick={() =>
            getFeatureByIdAndZoom(
              { map: map },
              undefined,
              undefined,
              `type=${feature?.type?.toLowerCase()}&featureId=${getProperty(
                feature,
                'ExternalRef',
              )}`,
              true,
            )
          }
        >
          Re-center
        </Button>
      }
      title={
        <Row>
          <Col span={24}>
            <ModuleEntityIcon
              iconContainerStyle={{ padding: '5px 9px', fontSize: '1.1em' }}
              overrideIcon="geo-fill"
              overrideIconColor="#ff9044"
              moduleName=""
              entityName=""
            />
            <span style={{ fontSize: '1.1em', marginTop: 2 }}>Location</span>
          </Col>
        </Row>
      }
    >
      <div id="widgetMap" style={{ width: '100%', height: '250px' }}></div>
    </Card>
  );
};
