export const REMOVE_ORDER_ITEM = 'REMOVE_ORDER_ITEM';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_ADDRESS = 'SET_ADDRESS';
export const SET_CONTACT = 'SET_CONTACT';
export const SET_IS_DISCOUNT_APPLIED = 'SET_IS_DISCOUNT_APPLIED';
export const SET_IS_LOA_ACCEPTED = 'SET_IS_LOA_ACCEPTED';
export const SET_LOADING_OFFERS = 'SET_LOADING_OFFERS';
export const SET_LOADING_PARENT_RECORD_ASSOCIATIONS = 'SET_LOADING_PARENT_RECORD_ASSOCIATIONS';
export const SET_LOADING_PRODUCTS = 'SET_LOADING_PRODUCTS';
export const SET_OFFER_LIST = 'SET_OFFER_LIST';
export const SET_OFFER_PRODUCT_LIST = 'SET_OFFER_PRODUCT_LIST';
export const SET_IS_ORDER_CREATED = 'SET_IS_ORDER_CREATED';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_ORDER_ITEMS = 'SET_ORDER_ITEMS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
export const SET_PHONE_NUM_2 = 'SET_PHONE_NUM_2';
export const SET_PHONE_NUM_3 = 'SET_PHONE_NUM_3';
export const SET_SELECTED_OFFER = 'SET_SELECTED_OFFER';
export const SET_PHONE_PANEL_REF = 'SET_PHONE_PANEL_REF';
export const SET_DISCOUNT_LIST = 'SET_DISCOUNT_LIST';
export const SET_DISCOUNT = 'SET_DISCOUNT';
export const SET_IS_CREATING_ORDER = 'SET_IS_CREATING_ORDER';
export const SET_IS_APPOINTMENT_CONFIRMED = 'SET_IS_APPOINTMENT_CONFIRMED';
export const SET_ORDER_SUMMARY = 'SET_ORDER_SUMMARY';
export const SET_RECONTRACTED_ORDER_ID = 'SET_RECONTRACTED_ORDER_ID';
export const SET_RECONTRACTING_QUOTE = 'SET_RECONTRACTING_QUOTE';
export const SET_RECONTRACTING_IS_RESET = 'SET_RECONTRACTING_IS_RESET';
export const RESET_ORDER_BUILDER_STATE = 'RESET_ORDER_BUILDER_STATE';
