import React from 'react';

interface Props {
  number: number;
  bold?: boolean;
  color?: string;
  backgroundColor?: string;
}

const BlueprintBadge: React.FC<Props> = (props: Props) => {
  const { number, color, backgroundColor, bold } = props;
  return (
    <div
      style={{
        backgroundColor: backgroundColor || '#eeefef',
        borderRadius: 50,
        color: color || '#000',
        display: 'inline-block',
        fontWeight: bold ? 600 : 'normal',
        fontSize: '0.9em',
        height: 20,
        lineHeight: '7px',
        marginBottom: 2,
        marginLeft: 5,
        padding: 7,
        verticalAlign: 'middle',
        width: 'auto',
      }}
    >
      <span>{number}</span>
    </div>
  );
};
export default BlueprintBadge;
