import { Checkbox, ListProps } from 'antd';
import { FC, ReactChild, ReactNode } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { FixedSizeListProps, FixedSizeList as List } from 'react-window';
import './index.scss';

interface Props {
  id?: string;
  selected: any;
  onSelect: (values: any[]) => void;
  isDropable?: boolean;
  children: ReactNode;
  style?: any;
}

const DataSetList: FC<Props> = ({
  id,
  selected,
  onSelect,
  isDropable = false,
  children,
  style = {},
}: Props) => {
  const { isOver, setNodeRef, active, over } = useDroppable({
    id: id || '',
    disabled: !isDropable,
  });

  const isDataSetItem = active && active?.id?.toString() === 'dataset-item';
  const isDataSetContainer = id && id.indexOf('dataset-list') > -1;
  const isSameContainer = isDataSetItem && isDataSetContainer;

  const customStyle = {
    opacity: isOver && !isSameContainer ? 0.2 : 1,
  };

  return (
    <div style={{ ...style }} ref={setNodeRef} className="dataset-list">
      <Checkbox.Group
        style={style}
        key={id}
        value={selected}
        onChange={(checkedValues) => {
          onSelect(checkedValues);
        }}
      >
        <div style={{ ...style, ...customStyle }}>{children}</div>
        {over && !isDataSetItem && !isSameContainer && isDropable && (
          <div className="backdrop">Unassign Dataset</div>
        )}
      </Checkbox.Group>
    </div>
  );
};

export default DataSetList;
