import { CaretDownOutlined } from '@ant-design/icons';
import {
  QGISCableTypeEnum,
  QGISClosureTypeEnum,
} from '@d19n/temp-fe-d19n-common/dist/com.netomnia/auto-splicing/interfaces/qgis.interfaces';
import {
  DbRecordEntityTransform,
} from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { getProperty } from '@d19n/temp-fe-d19n-models/dist/schema-manager/helpers/dbRecordHelpers';
import { Badge, Button, Card, Col, Dropdown, Menu, Row, Spin, Tag } from 'antd';
import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import RecordProperties from '@legacy/core/records/components/RecordProperties';
import { addRecordToShortList, IAddRecordToShortList } from '@legacy/core/records/store/actions';
import { hasPermissions } from '../../../../../../core/helpers/rbacRules';
import { closureTypes } from '../../../Map/helpers';
import { getBuildClosureStyle, getPlanClosureStyle } from '../../helpers';
import { updateAutosplicingReducerState } from '../../store/actions';
import { IAutosplicingReducer, TUpdateAutosplicingReducerAction } from '../../store/types';
import '../../styles.scss';

interface OwnProps {
  record: DbRecordEntityTransform;
  autosplicingReducer: IAutosplicingReducer;
  updateAutosplicingReducer: TUpdateAutosplicingReducerAction;
  shortListRecord: Function;
  userReducer: any;
}

type Props = OwnProps;

const AutosplicingGraphFeatureDetails: FunctionComponent<Props> = (props) => {
  const renderEntityDetails = () => {
    const { record, userReducer, autosplicingReducer, updateAutosplicingReducer, shortListRecord } =
      props;
    const { loadingIndicators } = autosplicingReducer;

    if (record && !loadingIndicators?.isLoadingSelectedGraphEntity) {
      const closureType = closureTypes[Number(getProperty(record, 'ClosureType'))];

      return (
        <Card
          style={{
            borderRadius: 2,
            display: autosplicingReducer.allJobs!.length > 0 ? 'none' : 'block',
          }}
          className={getProperty(record, 'source')}
          title={
            <Row>
              <Col span={12}>
                {record?.type === 'CLOSURE' ? (
                  <span>
                    <Badge
                      style={{ paddingRight: 2 }}
                      className="closureTypeCircle"
                      color={
                        autosplicingReducer.graphDisplayMode === 'PLAN'
                          ? getPlanClosureStyle(closureType).fill
                          : getBuildClosureStyle(Number(getProperty(record, 'BuildStatus'))).fill
                      }
                    />
                    {closureType} Closure
                  </span>
                ) : (
                  <span>
                    {getProperty(record, 'CableType')
                      ? QGISCableTypeEnum[getProperty(record, 'CableType')]
                      : ''}
                    &nbsp;Cable
                  </span>
                )}
              </Col>
              <Col span={12} style={{ textAlign: 'right' }}>
                {/* Source Tag */}
                <Tag color={getProperty(record, 'source') === 'COSMOS' ? 'blue' : 'orange'}>
                  {getProperty(record, 'source').toUpperCase()}
                </Tag>

                {/* Header Action Dropdown */}
                <Dropdown
                  trigger={['click']}
                  overlay={
                    <Menu>
                      {/* Feature Actions */}
                      <Row style={{ padding: '2px 6px' }}>
                        <Col
                          span={24}
                          style={{
                            background: '#f2f2f2',
                            padding: '3px 7px',
                            borderRadius: 4,
                          }}
                        >
                          <span style={{ fontWeight: 600 }}>Feature Actions</span>
                        </Col>
                      </Row>

                      <Menu.Item
                        key="1"
                        onClick={() =>
                          shortListRecord({
                            showPreview: true,
                            record: record!,
                          })
                        }
                      >
                        Quick View
                      </Menu.Item>

                      {record.type === 'CABLE' ? (
                        <Menu.Item
                          key="2"
                          onClick={() =>
                            updateAutosplicingReducer({
                              resetDrawerVisible: true,
                              resetDrawerAction: 'RESET_CABLE',
                              selectedEntityOdinRecord: record,
                            })
                          }
                        >
                          Reset Cable Fibers
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {record?.type === 'CLOSURE' &&
                      ['1', '3'].includes(getProperty(record, 'ClosureType')) &&
                      autosplicingReducer.formData!.closure_id === record.externalId ? (
                        <Menu.Item
                          key="3"
                          disabled={
                            !hasPermissions(userReducer, ['projectmodule.fiberconnection.delete'])
                          }
                          onClick={() =>
                            updateAutosplicingReducer({
                              resetDrawerVisible: true,
                              resetDrawerAction: 'RESET_ALL_FIBERS',
                              selectedEntityOdinRecord: record,
                            })
                          }
                        >
                          Reset All Fibers
                        </Menu.Item>
                      ) : (
                        <></>
                      )}

                      {record?.type === 'CLOSURE' &&
                      getProperty(record, 'ClosureType') === '1' &&
                      autosplicingReducer.formData!.closure_id === record.externalId ? (
                        <Menu.Item
                          key="4"
                          onClick={() =>
                            updateAutosplicingReducer({
                              resetDrawerVisible: true,
                              resetDrawerAction: 'RESET_CABLELINK',
                              selectedEntityOdinRecord: record,
                            })
                          }
                        >
                          Reset Cablelink
                        </Menu.Item>
                      ) : (
                        <></>
                      )}
                    </Menu>
                  }
                >
                  <Button
                    icon={<CaretDownOutlined />}
                    style={{ marginLeft: 5 }}
                    size="small"
                    type="default"
                  />
                </Dropdown>
              </Col>
            </Row>
          }
        >
          <Row gutter={16}>
            <Col span={24} style={{ fontSize: '0.9em' }}>
              <RecordProperties
                columns={1}
                enableCopyToClipboard
                size="small"
                record={props.record}
                visibleProperties={
                  record.type === 'CLOSURE'
                    ? [
                      'ExternalRef',
                      'ClosureType',
                      'ClosureModel',
                      'BuildStatus',
                      'ExPolygonId',
                      'L1PolygonId',
                      'L2PolygonId',
                    ]
                    : [
                      'ExternalRef',
                      'CableType',
                      'CableModel',
                      'BuildStatus',
                      'ExPolygonId',
                      'L1PolygonId',
                      'L2PolygonId',
                    ]
                }
              />
            </Col>

            {/* Trace in new tab */}
            <Col sm={24} lg={12} style={{ marginTop: 15 }}>
              <Link
                to={`/ProjectModule/Connection/${getProperty(record, 'ExternalRef')}`}
                target="_blank"
              >
                <Button
                  disabled={
                    !['L0', 'L2'].includes(
                      QGISClosureTypeEnum[getProperty(record, 'ClosureType')],
                    ) ||
                    !getProperty(record, 'ExternalRef') ||
                    record.type === 'CABLE'
                  }
                  ghost
                  type="primary"
                  style={{ width: '100%' }}
                >
                  Trace {getProperty(record, 'ExternalRef')}
                </Button>
              </Link>
            </Col>

            {/* Show in Map */}
            <Col sm={24} lg={12} style={{ marginTop: 15, textAlign: 'right' }}>
              <Link
                target="_blank"
                to={`/ProjectModule/Map/${record.type}/${getProperty(record, 'ExternalRef')}`}
              >
                <Button ghost type="primary" style={{ width: '100%' }}>
                  Show in Map
                </Button>
              </Link>
            </Col>
          </Row>
        </Card>
      );
    } else {
      return <></>;
    }
  };

  const { loadingIndicators } = props.autosplicingReducer;

  return loadingIndicators?.isLoadingSelectedGraphEntity ? (
    <Row style={{ textAlign: 'center' }}>
      <Col span={24} style={{ marginTop: 20 }}>
        <span>Searching for selected item...</span>
      </Col>
      <Col span={24} style={{ marginTop: 20 }}>
        <Spin size="large" />
      </Col>
    </Row>
  ) : (
    <Row>
      <Col span={24}>{renderEntityDetails()}</Col>
    </Row>
  );
};

const mapDispatch = (dispatch: any, ownProps: any) => ({
  updateAutosplicingReducer: (params: IAutosplicingReducer) =>
    dispatch(updateAutosplicingReducerState(params)),
  shortListRecord: (params: IAddRecordToShortList) => dispatch(addRecordToShortList(params)),
});

const mapState = (state: any) => ({
  autosplicingReducer: state.autosplicingReducer,
  userReducer: state.userReducer,
});

export default connect(mapState, mapDispatch)(AutosplicingGraphFeatureDetails);
