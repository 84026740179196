import {
  CaretLeftOutlined,
  CaretRightOutlined,
} from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import State from 'ol/source/State';
import { FC } from 'react';
import { IDataSetPagination } from '../../DataSetBuild/store/reducer';
interface Props {
  pagination: IDataSetPagination;
  onPageUpdate: Function;
  disabled?: boolean
}

const DataSetPagination: FC<Props> = (props: Props) => {
  const { pagination, onPageUpdate, disabled } = props;

  const handlePrevious = () => {
    if (onPageUpdate) {
      onPageUpdate({
        currentPage: pagination.currentPage! - 1,
      });
    }
  };

  const handleNext = () => {
    if (onPageUpdate) {
      onPageUpdate({
        currentPage: pagination.currentPage! + 1,
      });
    }
  };

  return (
    <div>
      <Button
        disabled={pagination.currentPage! <= 1 || disabled}
        type="link"
        size="small"
        icon={<CaretLeftOutlined />}
        onClick={() => handlePrevious()}
      />

      <span>{`Page ${pagination.currentPage} / ${pagination.totalPages}`}</span>
      <Button
        disabled={pagination.currentPage! >= pagination.totalPages || disabled}
        type="link"
        size="small"
        icon={<CaretRightOutlined />}
        onClick={() => handleNext()}
      ></Button>
    </div>
  );
};
export default DataSetPagination;
