import { DbRecordEntityTransform } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/transform/db.record.entity.transform';
import { Card, Col, Divider, Input, Row, Spin } from 'antd';
import ModuleEntityIcon from '../../../theme/ModuleEntityIcon';
import RecordList from '../RecordList';
import { FC, useEffect, useState } from 'react';
import './styles.scss';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import {
  deleteRecordAssociationById,
  ICreateOrUpdateRecordAssociation,
  IDeleteRecordAssociation,
  updateOrCreateRecordAssociations,
} from '../../../recordsAssociations/store/actions';
import { SchemaEntity } from '@d19n/temp-fe-d19n-models/dist/schema-manager/schema/schema.entity';
import { getSchemaFromShortListBySchemaId } from '@core/helpers/schemaHelpers';
import { RelationTypeEnum } from '@d19n/temp-fe-d19n-models/dist/schema-manager/db/record/association/types/db.record.association.constants';
import { getRecordByIdRequest, IGetRecordById } from '../../store/actions';

interface Props {
  mainRecord: DbRecordEntityTransform;
  existingAssociations?: DbRecordEntityTransform[];
  associatedRecords: DbRecordEntityTransform[];
  onSave: Function;
  isSaving: boolean;
  associationLoading?: boolean;
  parentRecordAssociationsLoading?: boolean;
  associationQuickSearchPlaceholder?: string;
  mainRecordQuickSearchPlaceholder?: string;
  associationTopTitle?: string; // Title shown in Visual cue of Associated record
  mainRecordTopTitle?: string; // Title shown in Visual cue of Main record
  truncateAssociations?: number; // Show only x number of associations - mainly for mobile use
  customRecordRowTitleElements?: string[]; // pass 'title', 'type', 'recordNumber' or specific record property 'DiscountValue'
  associatedEntityName: string;
  associatedModuleName: string;
  createAssociation: Function;
  deleteAssociation: Function;
  schemaReducer: any;
  getRecordById: Function;
  hideSelectedRecords?:boolean;
}

const AssociationBuilder: FC<Props> = (props: Props) => {
  const {
    mainRecord,
    associatedRecords,
    associationLoading,
    isSaving,
    onSave,
    getRecordById,
    associationTopTitle,
    mainRecordTopTitle,
    truncateAssociations,
    associatedEntityName,
    associatedModuleName,
    mainRecordQuickSearchPlaceholder,
    associationQuickSearchPlaceholder,
    existingAssociations,
    createAssociation,
    deleteAssociation,
    schemaReducer,
  } = props;

  const [selectedRecords, setSelectedRecords] = useState<DbRecordEntityTransform[]>([]);
  const [associationSearchTerm, setAssociationSearchTerm] = useState<string>('');
  const [mainRecordSearchTerm, setMainRecordSearchTerm] = useState<string>('');
  const [mainRecordSchema, setMainRecordSchema] = useState<SchemaEntity | undefined>(undefined);

  // When main record comes into component, get schema from shortlist
  useEffect(() => {
    if (mainRecord && !mainRecordSchema) {
      const shortlistSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        mainRecord.schemaId,
      );
      if (shortlistSchema) {
        setMainRecordSchema(shortlistSchema);
      }
    }
  }, [mainRecord]);

  // When existing associations come into component, set them as selected (on the right pane)
  useEffect(() => {
    if (existingAssociations) {
      setSelectedRecords(existingAssociations);
    }
  }, [existingAssociations]);

  const addToSelection = (record: DbRecordEntityTransform) => {
    if (!selectedRecords.find((rec: DbRecordEntityTransform) => rec.id === record?.id)) {
      // Get schemaAssociation
      const schemaAssociation = mainRecordSchema?.associations.find(
        (assoc: any) =>
          (assoc.childSchemaId === record?.schemaId &&
            assoc.parentSchemaId === mainRecord.schemaId) ||
          (assoc.parentSchemaId === record?.schemaId &&
            assoc.childSchemaId === mainRecord.schemaId),
      );

      const shortListMainRecordSchema = getSchemaFromShortListBySchemaId(
        schemaReducer.shortList,
        mainRecord.schemaId,
      );

      // Create association
      createAssociation(
        {
          recordId: mainRecord?.id,
          schema: shortListMainRecordSchema,
          schemaAssociation,
          createUpdate: [
            {
              entity: record?.entity,
              recordId: record?.id,
              relatedAssociationId: record?.dbRecordAssociation?.id,
              relationType: RelationTypeEnum.CHILD,
            },
          ],
        },
        (res: any) => {
          // Creating association will return the association id. We need to store this id
          // in the list, because this id will enable us to delete the association right away.
          const relatedAssociationId = res.result[0]?.id;
          let mutatedRecord: any = Object.assign(record);
          mutatedRecord.dbRecordAssociation = {
            id: relatedAssociationId,
          };
          setSelectedRecords([...selectedRecords, mutatedRecord]);
        },
      );
    }
  };

  const removeFromSelection = (record: DbRecordEntityTransform) => {
    // Get schemaAssociation
    const schemaAssociation = mainRecordSchema?.associations.find(
      (assoc: any) =>
        (assoc.childSchemaId === record?.schemaId &&
          assoc.parentSchemaId === mainRecord.schemaId) ||
        (assoc.parentSchemaId === record?.schemaId && assoc.childSchemaId === mainRecord.schemaId),
    );

    // Delete the association
    deleteAssociation(
      {
        schema: mainRecordSchema,
        schemaAssociation: schemaAssociation,
        dbRecordAssociationId: record.dbRecordAssociation?.id,
      },
      () => {
        setSelectedRecords(
          selectedRecords.filter((rec: DbRecordEntityTransform) => rec.id !== record.id),
        );
      },
    );
  };

  // Filter Associated records with quick search text
  let filteredAssociationRecords = Object.assign(associatedRecords);
  if (associationSearchTerm) {
    filteredAssociationRecords = filteredAssociationRecords.filter(
      (rec: DbRecordEntityTransform) =>
        rec?.title && rec.title?.toLowerCase().indexOf(associationSearchTerm?.toLowerCase()) > -1,
    );
  }

  // User can reduce number of visible records, this won't affect quick search
  if (truncateAssociations && filteredAssociationRecords?.length > truncateAssociations) {
    filteredAssociationRecords = filteredAssociationRecords.slice(0, truncateAssociations);
  }

  // Filter associated records added to main record with quick search text
  let filteredMainRecordAssociations = Object.assign(selectedRecords);
  if (mainRecordSearchTerm) {
    filteredMainRecordAssociations = filteredMainRecordAssociations.filter(
      (rec: DbRecordEntityTransform) =>
        rec?.title && rec.title?.toLowerCase().indexOf(mainRecordSearchTerm?.toLowerCase()) > -1,
    );
  }
  return (
    <Row>
      <Col span={24}>
        <Card size="small" title="Association Builder">
          <Row align="middle" style={{ margin: '5px 5px 20px 5px' }}>
            {/* Stacked Associations Visual Cue */}
            <Col xs={24} md={11} style={{ paddingRight: 10 }}>
              <div className="assocCard" />
              <div className="assocCard dummy1" />
              <div className="assocCard dummy2" />
              <div className="assocCard dummy3">
                <Row align="middle" style={{ height: '100%', paddingLeft: 10 }}>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <ModuleEntityIcon
                          moduleName={associatedModuleName}
                          entityName={associatedEntityName}
                        />
                        <span>
                          {associationTopTitle ||
                            `${
                              associatedEntityName +
                              (associatedRecords[0]?.type ? ` [${associatedRecords[0]?.type}]` : '')
                            }`}
                        </span>
                      </Col>
                      {/* <Col span={4} style={{ marginTop: 3, textAlign: 'right', paddingRight: 15 }}> */}
                      {/* {selectedRecords.length > 0 ? (
                          <Badge
                            count={selectedRecords.length}
                            showZero
                            style={{
                              marginBottom: 2,
                              backgroundColor: '#ddeeff',
                              color: '#262626',
                            }}
                          />
                        ) : (
                          <></>
                        )} */}
                      {/* </Col> */}
                    </Row>
                  </Col>
                </Row>
              </div>
            </Col>

            {/* Right Arrow */}
            <Col
              xs={24}
              md={2}
              style={{
                textAlign: 'center',
                marginTop: 25,
                marginBottom: isMobile ? 20 : 'auto',
                paddingLeft: 5,
                display: isMobile ? 'none' : 'block',
              }}
            >
              <i
                className={isMobile ? 'bi bi-arrow-down' : 'bi bi-arrow-right'}
                style={{ fontSize: '1.3em' }}
              />
            </Col>

            {/* Main Record Visual Cue */}
            <Col
              xs={24}
              md={11}
              style={{
                textAlign: isMobile ? 'center' : 'right',
                display: isMobile ? 'none' : 'block',
              }}
            >
              <div className="assocCard" style={{ position: 'absolute', top: -10 }}>
                <Row align="middle" style={{ height: '100%', paddingLeft: 10, textAlign: 'left' }}>
                  <Col span={24}>
                    <ModuleEntityIcon
                      moduleName={mainRecord?.entity?.split(':')[0]!}
                      entityName={mainRecord?.entity?.split(':')[1]!}
                    />
                    <span>
                      {mainRecordTopTitle ||
                        `${
                          mainRecord?.entity?.split(':')[1] +
                          (mainRecord?.type ? ` [${mainRecord?.type}]` : '')
                        }`}
                    </span>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          {/* Horizontal Divider */}
          <Row style={{ display: isMobile ? 'none' : 'block' }}>
            <Col span={24}>
              <Divider style={{ marginTop: 10, marginBottom: 20 }} />
            </Col>
          </Row>

          <Row>
            <Col span={isMobile ? 24 : 11} style={{ paddingLeft: isMobile ? 0 : 10 }}>
              <Row>
                {/* Associations - Quick Search */}
                <Col span={24}>
                  <Input.Search
                    allowClear
                    disabled={associationLoading || !associatedRecords.length || isSaving}
                    style={{
                      paddingBottom: 10,
                      opacity: isSaving ? 0.3 : 1,
                      paddingTop: isMobile ? 12 : 0,
                    }}
                    placeholder={associationQuickSearchPlaceholder || 'Quick Search'}
                    onChange={(e: any) => {
                      setAssociationSearchTerm(e?.target?.value);
                    }}
                  />
                </Col>
                {/* Association Record List */}
                <Col span={24}>
                  {associationLoading ? (
                    <div style={{ textAlign: 'center' }}>
                      <Spin style={{ paddingBottom: 20, marginTop: 40 }} />
                      <br />
                      <span>Loading Records...</span>
                    </div>
                  ) : (
                    <>
                      <RecordList
                        customRecordRowTitleElements={props.customRecordRowTitleElements}
                        selectedRecords={selectedRecords}
                        disabledRecords={selectedRecords}
                        allRecords={filteredAssociationRecords}
                        onRecordSelect={addToSelection}
                        hideSelectedRecords={props.hideSelectedRecords}
                        customRightElement={
                          <i
                            className="bi bi-plus-lg"
                            style={{ color: '#1890ff', fontSize: '1em', lineHeight: 0 }}
                          />
                        }
                      />
                      {truncateAssociations &&
                      associatedRecords.length > truncateAssociations &&
                      !associationSearchTerm.length ? (
                        <Row
                          style={{ marginTop: 8, marginLeft: 5, fontSize: '0.9em', color: 'grey' }}
                        >
                          <Col span={24} style={{ textAlign: 'center' }}>
                            {associatedRecords.length - truncateAssociations} more...
                          </Col>
                        </Row>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>

            {/* Mobile Only -  Horizontal Separator */}
            <Col span={isMobile ? 24 : 2} style={{ textAlign: 'center', padding: 10 }}>
              {isMobile ? (
                <Divider style={{ marginTop: 10 }}>
                  <i className="bi bi-arrow-down" style={{ fontSize: '1.3em' }} />
                </Divider>
              ) : (
                <></>
              )}
            </Col>

            {/* Mobile Only - Main record Visual Cue */}
            {isMobile ? (
              <Col span={24}>
                <div className="assocCard" style={{ marginBottom: 10 }}>
                  <Row
                    align="middle"
                    style={{ height: '100%', paddingLeft: 10, textAlign: 'left' }}
                  >
                    <Col span={24}>
                      <ModuleEntityIcon moduleName="ProjectModule" entityName="Feature" />
                      <span>
                        {mainRecordTopTitle ||
                          `${
                            mainRecord?.entity?.split(':')[1] +
                            (mainRecord?.type ? ` [${mainRecord?.type}]` : '')
                          }`}
                      </span>
                    </Col>
                  </Row>
                </div>
              </Col>
            ) : (
              <></>
            )}

            <Col span={isMobile ? 24 : 11} style={{ paddingRight: isMobile ? 0 : 10 }}>
              <Row>
                {/* Main Record - Quick Search */}
                <Col span={24}>
                  <Input.Search
                    allowClear
                    disabled={!selectedRecords.length || isSaving}
                    placeholder={mainRecordQuickSearchPlaceholder || 'Quick Search'}
                    style={{
                      paddingBottom: 10,
                      opacity: isSaving ? 0.3 : 1,
                      marginTop: isMobile ? 10 : 0,
                    }}
                    onChange={(e: any) => {
                      setMainRecordSearchTerm(e?.target?.value);
                    }}
                  />
                </Col>

                {/* Main Record - Selected record list */}
                <Col span={24}>
                  <RecordList
                    hideSelectedRecords={props.hideSelectedRecords}
                    customRecordRowTitleElements={props.customRecordRowTitleElements}
                    selectedRecords={[]}
                    disabledRecords={[]}
                    allRecords={filteredMainRecordAssociations}
                    onRecordSelect={removeFromSelection}
                    customRightElement={
                      <i
                        className="bi bi-x"
                        style={{ color: 'red', fontSize: '1.3em', lineHeight: 0 }}
                      />
                    }
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
    </Row>
  );
};

const mapState = (state: any) => ({
  schemaReducer: state.schemaReducer,
});

const mapDispatch = (dispatch: any) => ({
  getRecordById: (payload: IGetRecordById, cb?: any) => dispatch(getRecordByIdRequest(payload, cb)),
  createAssociation: (params: ICreateOrUpdateRecordAssociation, cb: () => {}) =>
    dispatch(updateOrCreateRecordAssociations(params, cb)),
  deleteAssociation: (params: IDeleteRecordAssociation, cb: () => {}) =>
    dispatch(deleteRecordAssociationById(params, cb)),
});

export default connect(mapState, mapDispatch)(AssociationBuilder);
